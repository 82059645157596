import classNames from "classnames";
import { RentalAgreement } from "../../../apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import CBRentalAgreementEntry from "../../../apps/tatar/cashBudget/views/tenants/components/rental-agreements/CBRentalAgreementEntry";
import ListComponent from "../../../configurable/components/ListComponent/ListComponent";
import i18n from "../../../i18n";
import { AssetTypes } from "../../../model/AssetTypes";
import { Contact } from "../../../model/db/Contact";
import MQ from "../../../utils/MatchQueryUtils";
import ContactDetailsSection from "./ContactDetailsSection";
import "./ContactDetailsTenantSection.scss";

interface ContactDetailsTenantSectionProps {
  contact: Contact;
}
const ContactDetailsTenantSection = (
  props: ContactDetailsTenantSectionProps
) => {
  return (
    <ContactDetailsSection
      title={i18n.t("Contact.View.Invoice.Tenant", "Mieter")}
    >
      <div className={classNames(`contact-details-tenant-section`)}>
        <div className={`list-container`}>
          <ListComponent
            identifier="contact-tenant-rental-agreements"
            asPost
            dataUrl={`/api/asset/list/${AssetTypes.Rental.RentalAgreement}`}
            additionalMatchQuery={MQ.and(
              MQ.eq("data.tenant", props.contact._id)
            )}
            render={(node: RentalAgreement) => (
              <div className={`list-entry`}>
                <CBRentalAgreementEntry rentalAgreement={node} />
              </div>
            )}
          />
        </div>
      </div>
    </ContactDetailsSection>
  );
};

export default ContactDetailsTenantSection;
