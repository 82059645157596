import Axios, {
  AxiosBasicCredentials,
  CancelToken,
  Method,
  ResponseType,
} from "axios";
import { nanoid } from "nanoid";
import Log from "../debug/Log";
import DataBus from "../services/DataBus";
import { DataBusSubKeys } from "./Constants";
import { isDevelopment } from "./Properties";

export const METHOD = {
  GET: "GET",
  POST: "POST",
  INSERT: "INSERT",
  UPDATE: "UPDATE",
};
export type TARGET =
  | "API"
  | "CDN"
  | "AUTH"
  | "STATISTIC"
  | "INVOICE"
  | "PAYMENT"
  | "EMPTY";

export const HTTP_DELAYED_CANCEL = "HTTP_DELAYED_CANCEL";
export interface HttpInterface {
  delayId?: string;
  delay?: number;
  url: string;
  target?: TARGET;
  queryParams?: { [key: string]: any };
  bodyParams?: { [key: string]: any } | FormData;
  headers?: any;
  auth?: AxiosBasicCredentials;
  cancelToken?: CancelToken;
  withCredentials?: boolean;
  timeout?: number;
  responseType?: ResponseType;
  overwriteUrl?: boolean;
  resolveWithFullResponse?: boolean;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}
export interface HttpInterfaceDatabus extends HttpInterface {
  method: Method;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}

export interface ApiResponse {
  data?: any;
  errorIdentifier?: string;
  errorParams?: any;
  debug?: any;
}

// (window as any).axios.defaults.headers.common = {
//     'X-CSRF-TOKEN': (window as any).Laravel.csrfToken,
//     'X-Requested-With': 'XMLHttpRequest'
//   };

// const API_URL = Properties.serverUrl + "/api";

const serverURL = "https://" + window.location.hostname;
export const UNIQUE_REQUEST_ID = nanoid();

class HTTPClass {
  delayedTasks: {
    [delayId: string]: { timeout: NodeJS.Timeout; reject: (err?: any) => void };
  } = {};
  targetToUrl(target: TARGET) {
    return isDevelopment()
      ? process.env[`REACT_APP_URL_${target}`]
      : `${serverURL}/${target === "EMPTY" ? "" : target.toLowerCase() + "/"}`;
  }

  constructor() {
    setTimeout(() => {
      DataBus.subscribe(
        DataBusSubKeys.HTTP,
        (data: HttpInterfaceDatabus & { ignore?: boolean }) => {
          const { ignore, method, onSuccess, onError, ...httpObj } = data;
          if (ignore) {
            onSuccess(null);
          } else {
            this.http(method, httpObj)
              .then((data) => {
                if (onSuccess) {
                  onSuccess(data);
                }
              })
              .catch((err) => {
                if (onError) {
                  onError(err);
                }
              });
          }
        }
      );
    });
  }

  http = (method: Method, httpObj: HttpInterface) => {
    return new Promise<any>((resolve, reject) => {
      const doApiCall = () => {
        const isApiCall = httpObj.url.indexOf(":") === -1;

        // let bodyParams = HTTP.convertParams(httpObj.bodyParams);
        // if (bodyParams === "") {
        //     bodyParams = undefined;
        // }
        if (!httpObj.target) {
          httpObj.target = "API";
        }

        const baseUrl = httpObj.overwriteUrl
          ? undefined
          : this.targetToUrl(httpObj.target);
        const url = httpObj.url;
        Axios.request({
          baseURL: baseUrl,
          method: method,
          params: httpObj.queryParams,
          url: url,
          responseType: httpObj.responseType ? httpObj.responseType : "json",
          data: httpObj.bodyParams,
          ...httpObj,

          headers: {
            "Content-Type": "application/json",
            "Unique-Session-Id": UNIQUE_REQUEST_ID,
            ...(httpObj.headers ? httpObj.headers : {}),
          },
          withCredentials:
            httpObj.withCredentials !== undefined
              ? httpObj.withCredentials
              : true,
        })
          .then((data) => {
            if (httpObj.resolveWithFullResponse) {
              resolve(data);
              return;
            }
            if (data.data) {
              resolve(data.data);
            } else {
              resolve(data);
            }
          })
          .catch((err) => {
            //wrap error handling here
            Log.error(err);

            reject(err);
          });
      };

      if (httpObj.delayId) {
        if (this.delayedTasks[httpObj.delayId]) {
          clearTimeout(this.delayedTasks[httpObj.delayId].timeout);
          this.delayedTasks[httpObj.delayId].reject(HTTP_DELAYED_CANCEL);
        }
        this.delayedTasks[httpObj.delayId] = {
          reject: reject,
          timeout: setTimeout(doApiCall, httpObj.delay || 500),
        };
      } else {
        doApiCall();
      }
    });
  };

  get = (httpObj: HttpInterface) => {
    return this.http("GET", httpObj);
  };
  post = (httpObj: HttpInterface) => {
    return this.http("POST", httpObj);
  };
  patch = (httpObj: HttpInterface) => {
    return this.http("PATCH", httpObj);
  };
  put = (httpObj: HttpInterface) => {
    return this.http("PUT", httpObj);
  };
  delete = (httpObj: HttpInterface) => {
    return this.http("DELETE", httpObj);
  };
}
export const HTTP = new HTTPClass();
