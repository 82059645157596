import AccountingRentalInfo from "@/apps/tatar/accounting/views/rental/form/info/AccountingRentalInfo";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import ModalManager from "@/components/ModalComponent/ModalManager";
import StructLoader from "@/components/StructLoader/StructLoader";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import AssetListAssign from "@/modules/abstract-ui/assets/asset-list-assign/AssetListAssign";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import MQ from "@/utils/MatchQueryUtils";
import moment from "moment";
import { Field } from "react-final-form";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";
import { CBRentalAgreementsFilterOptions } from "../CBRentalAgreementsFilterOptions";
import { RENTAL_AGREEMENT_FIELDS } from "../CBRentalAgreementsList";
import "./CBAgreementMergeModal.scss";

export const openAgreementMergeModal = (
  agreement: RentalAgreement,
  onSucess: () => void
) => {
  ModalManager.show({
    size: "fluid",
    noPadding: true,
    content: (state, setState, close) => {
      return (
        <CBAgreementMergeModal
          onClose={close}
          onSuccess={onSucess}
          agreement={agreement}
        />
      );
    },
  });
};

interface CBAgreementMergeModalProps {
  agreement: RentalAgreement;
  onSuccess: () => void;
  onClose: () => void;
}
const CBAgreementMergeModal = (props: CBAgreementMergeModalProps) => {
  return (
    <StructLoader
      structTypes={["orga", "objectKind"]}
      unitType={props.agreement.data.type}
      render={() => {
        const object = OrgaStruct.getObject(props.agreement.data.objectId);
        const kind = ObjectKindStruct.getKind(object?.objectKindId);
        return (
          <FormStruct
            className={`cb-agreement-merge-modal`}
            initialValues={{
              mergeAgreements: [],
            }}
            onSubmit={async (values) => {
              await CBRentalService.mergeRentalAgreements(
                props.agreement._id,
                values.mergeAgreements.map((e) => e._id)
              );
              props.onSuccess();
              props.onClose();
            }}
            submitText={i18n.t(
              "cb:RentalAgreement.Form.Fields.mergeAgreement",
              "Mietvertrag zusammenführen"
            )}
            title={i18n.t(
              "cb:RentalAgreement.Form.Fields.mergeAgreement",
              "Mietvertrag zusammenführen"
            )}
            onAbort={props.onClose}
            description={i18n.t(
              "cb:RentalAgreement.Form.Fields.mergeAgreementDescription",
              "Bitte wählen Sie die Mietverträge aus, mit welchen dieser Mietvertrag zusammengeführt werden soll. Die ausgewählten Mietverträge werden entfernt und die Mieteinheiten werden zu dem Mietvertrag hinzugefügt."
            )}
            render={(formProps) => (
              <>
                <div className={`basic-data`}>
                  <AccountingRentalInfo
                    rentalAgreementId={props.agreement._id}
                    ignoreAccounting
                  />
                </div>
                <div className={`selection-data`}>
                  <Field name="mergeAgreements" validate={FV.min(1)}>
                    {({ input, meta }) => (
                      <AssetListAssign
                        matchQuery={MQ.and(
                          MQ.eq("data.objectId", props.agreement.data.objectId),
                          MQ.ne("_id", props.agreement._id),
                          MQ.lte("data.moveIn", moment().startOf("day")),
                          MQ.or(
                            MQ.gte("data.moveOut", moment().startOf("day")),
                            MQ.isNull("data.moveOut")
                          )
                        )}
                        expandKeys={["data.tenant"]}
                        identifier="rentalAgreementToMerge"
                        filterOptions={CBRentalAgreementsFilterOptions(kind)}
                        assetType={AssetTypes.Rental.RentalAgreement}
                        columns={RENTAL_AGREEMENT_FIELDS(true)}
                        infoText={i18n.t(
                          "cb:RentalAgreement.Form.Fields.mergeInfo",
                          "Bitte fügen Sie die Mietverträge aus der untenstehenden Liste hinzu, die mit dem oben aufgeführten Mietvertrag zusammengeführt werden sollen."
                        )}
                        selectedTitle={i18n.t(
                          "cb:RentalAgreement.Form.Fields.mergeSelectedTitle",
                          "Ausgewählte Mietverträge"
                        )}
                        {...input}
                        {...FV.getValidation(meta)}
                      />
                    )}
                  </Field>
                </div>
              </>
            )}
          />
        );
      }}
    />
  );
};

export default CBAgreementMergeModal;
