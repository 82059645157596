import { getRestrictions } from "@/services/RestrictionService";
import {
  ACUnit,
  TagConfig,
  TagConfigWithUnit,
} from "../../../../apps/AppConfigInterfaces";
import { ListResponse } from "../../../../model/common/HttpModel";
import LanguageService from "../../../../services/LanguageService";
import { HTTP } from "../../../../utils/Http";
import MQ from "../../../../utils/MatchQueryUtils";
import { StructType } from "../../../reducers/struct/StructInterface";
import {
  AbstractStructSelectors,
  DataByUnitType,
} from "../AbstractStructSelectors";

class UnitStructClass extends AbstractStructSelectors<ACUnit> {
  getStructType(): StructType {
    return "unit";
  }
  loadStructData(types: string[]): Promise<DataByUnitType<ACUnit>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: `/asset/cashbudget-config`,
          target: "API",
          queryParams: {
            param: {
              matchQuery: MQ.in("data.identifier", types),
            },
          },
        })) as ListResponse<ACUnit>;
        const result: DataByUnitType<ACUnit> = {};
        types.forEach((type) => {
          result[type] = data.data.find(
            (config) => config.data.identifier === type
          );
        });
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }

  getTagConfig(unit: string, assetType: string) {
    return this.useCache<TagConfig[]>(
      "getTagConfig",
      arguments,
      () => this.getData(unit)?.data?.objectConfig?.[assetType]?.tags
    );
  }

  getAllTagConfigs(assetType: string, groupId?: string) {
    return this.useCache<TagConfigWithUnit[]>(
      "getAllTagConfigs",
      arguments,
      () => {
        const tags: TagConfigWithUnit[] = [];
        this.getUnits()?.forEach((unit) => {
          tags.push(
            ...(this.getTagConfig(unit, assetType) || [])?.map((e) => ({
              ...e,
              unit,
            }))
          );
        });
        return tags.filter((e) => (groupId ? e.group === groupId : true));
      }
    );
  }
  getTagConfigForUnits(units: string[], assetType: string, groupId?: string) {
    return this.useCache<TagConfigWithUnit[]>(
      "getTagConfigForUnits",
      arguments,
      () => {
        const tags: TagConfigWithUnit[] = [];
        units.forEach((unit) => {
          tags.push(
            ...(this.getTagConfig(unit, assetType) || [])?.map((e) => ({
              ...e,
              unit,
            }))
          );
        });
        return tags.filter((e) => (groupId ? e.group === groupId : true));
      }
    );
  }
  getUnitLabel(unit: string) {
    return this.useCache<string>("getUnitLabel", arguments, () =>
      LanguageService.translateLabel(this.getData(unit).data.label)
    );
  }
  getUnit(identifier: string) {
    return this.useCache<ACUnit>("getUnit", arguments, () =>
      this.getAllData()?.find((e) => e.data.identifier === identifier)
    );
  }

  getUnitSelectOptions(filter?: string[]) {
    return this.useCache<{ label: string; value: string }[]>(
      "getUnitSelectOptions",
      arguments,
      () =>
        this.getAllData()
          ?.filter((e) => (filter ? filter.includes(e.data.identifier) : true))
          .map((e) => ({
            label: LanguageService.translateLabel(e.data.label),
            value: e.data.identifier,
          }))
    );
  }
  getUnits(considerRestrictions = false) {
    return this.useCache<string[]>("getUnits", arguments, () => {
      const units = this.getAllData()?.map((e) => e.data.identifier);

      if (considerRestrictions) {
        const restrictions = getRestrictions();
        if (restrictions) {
          return units?.filter((e) => restrictions.units.includes(e)) || [];
        }
      }
      return units;
    });
  }
  hasUnitIdentifier(unit: string) {
    return this.useCache<boolean>("hasUnitIdentifier", arguments, () =>
      this.getUnits()?.includes(unit)
    );
  }
}
const UnitStruct = new UnitStructClass();
(window as any).UnitStruct = UnitStruct;
export default UnitStruct;
