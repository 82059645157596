import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { Loader } from "rsuite";
import { CacheData } from "../../redux/reducers/application/ApplicationInterface";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import CacheService, { CacheLoadData } from "../../services/CacheService";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../utils/abstracts/AbstractStylableComponent";
import { IComponent } from "../layouts/IComponent";

type Props = {
  oType: "asset" | "user" | "group";
  assetType?: string;
  components: { [key: string]: IComponent };
  cacheData: { [id: string]: CacheData<any> };
  overwritePath: string;
  forceReload?: boolean;
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {
  selectedId: string;
} & AbstractStylableStates;

class AssetLoaderRoute extends AbstractStylableComponent<Props, States> {
  static defaultProps = {
    type: "asset",
  };
  readonly state: States = {
    selectedId: null,
  };

  //componentDidMount() { v
  //}

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  renderLoading() {
    return <Loader center={true} size="md" />;
  }
  renderError() {
    return <Trans i18nKey={"Global.Labels.loadingError"} />;
  }
  renderComponents(data: any) {
    return Object.entries(this.props.components).map(([key, e]) =>
      (window as any).ComponentsMapper.createElement(
        e,
        { ...this.props.params, assetData: data },
        key
      )
    );
  }
  renderContent() {
    const { oType, assetType, cacheData } = this.props;
    const { selectedId } = this.state;
    const data =
      cacheData && cacheData[selectedId] ? cacheData[selectedId] : null;

    if (!data || data.state === "loading") {
      return this.renderLoading();
    }
    if (data.state === "error") {
      return this.renderError();
    }

    return this.renderComponents(data.data);
  }
  render() {
    const {
      oType,
      assetType,

      cacheData,
      match,
      location,
      i18n,
      overwritePath,
    } = this.props;
    return (
      <Switch>
        <Route
          path={`${overwritePath ? overwritePath : match.url}/:id`}
          children={({ match }) => {
            const { selectedId } = this.state;
            const id = match.params.id;
            if (id !== selectedId) {
              this.setState({ selectedId: id }, () =>
                CacheService.getData({
                  oType,
                  assetType,
                  id,
                  forceReload: this.props.forceReload,
                } as CacheLoadData)
              );
            }

            return this.renderContent();
          }}
        />

        <Route
          path={`${overwritePath ? overwritePath : match.url}`}
          children={({ match }) => {
            return null;
          }}
        />
      </Switch>
      // <div className={`AssetLoader ${this.state.usedStyle ? css(this.state.usedStyle as any) : ""}`}>
      // 	{!data || status === "loading" ? this.renderLoading() : status === "error" ? this.renderError() : this.renderComponents()}
      // </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
  cacheData:
    state.application.cache[
      props.oType === "asset" ? props.assetType : props.oType
    ],
});

export default withTranslation()(
  connect(mapStateToProps, {})(withRouter(AssetLoaderRoute))
);
