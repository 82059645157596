import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import ModalManager from "@/components/ModalComponent/ModalManager";
import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { Contact } from "@/model/db/Contact";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFDetailsButton from "@/modules/abstract-ui/general/Button/BFDetailsButton";
import ContactUtils from "@/modules/contacts-module/ContactUtils";
import { RentalAgreement } from "../../../TenantsInterfaces";
import { CBRentalAgreementContactOptionUpdateForm } from "../form-components/CBRentalAgreementContactForm";
// import "./CBAgreementTenant.scss";

export type TenantType = {
  tenant: string;
  contactOption: {
    addressId: string;
    contactPersonId: string | null;
    mail: string[] | null;
    phone: string[] | null;
    sms: string[] | null;
  };
};
interface CBAgreementTenantProps {
  marks: PDFEditRect[] | PDFDocumentRect[];
  data: TenantType;
  kind: ObjectKind;
  rentalAgreement?: RentalAgreement;
  editable?: boolean;
}
const CBAgreementTenant = (props: CBAgreementTenantProps) => {
  return (
    <BFPDFMarkerSection
      className={`cb-agreement-tenant`}
      marginBottom={20}
      value={props.marks}
      title={
        <>
          <span className={`padding-right-5`}>
            {i18n.t(
              "cb:RentalAgreement.Form.Sections.tenant",
              "Vertragspartner"
            )}
          </span>
          {props.rentalAgreement && props.editable && (
            <BFButton
              noPadding
              inline
              appearance="link"
              onClick={() => {
                ModalManager.show({
                  size: "fluid",
                  noPadding: true,
                  content: (state, setState, close) => {
                    return (
                      <CBRentalAgreementContactOptionUpdateForm
                        rentalAgreement={props.rentalAgreement}
                        onClose={close}
                      />
                    );
                  },
                });
              }}
              size="sm"
            >
              {i18n.t("Global.Buttons.Customize", "Anpassen")}
            </BFButton>
          )}
        </>
      }
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.tenant.viewHint",
        "Informationen zu Mieter"
      )}
      readonly
      identifier="tenant"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      <AssetLoader
        assetType={AssetTypes.Contact}
        id={props.data.tenant}
        inline
        render={(tenant: Contact) => {
          const contactOption = props.data.contactOption;
          return (
            <>
              <div className={`__flex-wrap`}>
                <BFValueDisplay
                  label={i18n.t("cb:Tenant.Labels.Name", "Mieter")}
                  value={
                    <BFDetailsButton
                      appearance="link"
                      noPadding
                      data={{
                        assetType: AssetTypes.Contact,
                        assetId: props.data.tenant,
                        type: props.kind.data.type,
                        params: {
                          contactType: "TENANT",
                        },
                      }}
                    >
                      {tenant.data.displayName}
                    </BFDetailsButton>
                  }
                />

                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Contact.addressId",
                    "Kontaktadresse"
                  )}
                  value={contactOption?.addressId}
                  formatter={(value) =>
                    ContactUtils.getAdressSelectOptions(tenant)?.find(
                      (e) => e.value === value
                    )?.label
                  }
                />

                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Contact.contactPersonId",
                    "Kontaktperson"
                  )}
                  value={contactOption?.contactPersonId}
                  formatter={(value) =>
                    ContactUtils.getContactPersonSelectOptions(tenant)?.find(
                      (e) => e.value === value
                    )?.label
                  }
                />

                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Contact.mail",
                    "Kontakt E-Mail"
                  )}
                  value={contactOption?.mail}
                  formatter={(value) =>
                    ContactUtils.getEmailSelectOptions(tenant)?.find(
                      (e) => e.value === value
                    )?.label
                  }
                />

                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Contact.phone",
                    "Kontakt Telefon"
                  )}
                  value={contactOption?.phone}
                  formatter={(value) =>
                    ContactUtils.getPhoneSelectOptions(tenant)?.find(
                      (e) => e.value === value
                    )?.label
                  }
                />
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Contact.sms",
                    "Kontakt SMS"
                  )}
                  value={contactOption?.sms}
                  formatter={(value) =>
                    ContactUtils.getPhoneSelectOptions(tenant)?.find(
                      (e) => e.value === value
                    )?.label
                  }
                />
              </div>
            </>
          );
        }}
      />
    </BFPDFMarkerSection>
  );
};

export default CBAgreementTenant;
