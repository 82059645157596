import { MatchQuery } from "../../../../../services/DataService";
import StringUtils from "../../../../../utils/StringUtils";
import { BFInputProps } from "../../../forms/input/BFInput";
import { BfIconProps } from "../../../icon/BfIcon";
import { FilterOptionWithMatchQueryConverter } from "../BFTableFilterModel";
import OverlayNumberSelection, {
  DefaultNumberSelectionValue,
  generateLabelForNumberFilter,
  generateMatchQueryForNumberFilter,
  getDefaultNumberOptions,
} from "../overlays/OverlayNumberSelection";

export const NumberFilterOption: (opts: {
  field: string;
  label: string;
  icon?: BfIconProps;
  numberFormat?: (value: number) => string;
  matchQueryConverter?: (value: any) => MatchQuery | null;
  type?: "number" | "currency" | "area" | "percentage";
  inputProps?: Partial<BFInputProps>;
}) => FilterOptionWithMatchQueryConverter = (opts) => {
  const { field, label, icon } = opts;
  const type = opts.type || "number";
  const isCurrency = type === "currency";
  const isArea = type === "area";
  const isPercentage = type === "percentage";
  const numberFormat =
    opts.numberFormat ||
    (isCurrency
      ? (value) => StringUtils.formatCurrency(value)
      : isPercentage
      ? (value) => `${value}%`
      : isArea
      ? (value) =>
          `${StringUtils.formatNumber(value)}${StringUtils.getAreaUnit()}`
      : (value) => StringUtils.formatNumber(value, false, undefined, 0, 2));
  const matchQueryConverter =
    opts.matchQueryConverter ||
    ((value: any) => generateMatchQueryForNumberFilter(field, value));
  return {
    matchQueryConverter: matchQueryConverter,
    key: field,
    label: label,
    icon: {
      type: icon?.type || "light",
      data:
        icon?.data ||
        (isCurrency
          ? "accounting-bill"
          : isPercentage
          ? "discount-1"
          : isArea
          ? "real-estate-dimensions-house"
          : "number"),
    },
    defaultValue: DefaultNumberSelectionValue,
    renderOverlay: (value, onChange) => (
      <OverlayNumberSelection
        title={label}
        value={value}
        onChange={onChange}
        isPercentage={isPercentage}
        inputProps={{
          ...(isArea ? { suffix: StringUtils.getAreaUnit() } : {}),
          ...(isCurrency
            ? {
                type: "priceInput",
                suffix: StringUtils.getCurrencySymbol(),
                placeholder: label,
                textAlign: "right",
                allowNull: true,
              }
            : {}),
          ...(isPercentage ? { suffix: "%" } : {}),
          ...(opts.inputProps || {}),
        }}
      />
    ),
    renderValueData: (value) => ({
      typeLabel: label,
      valueLabel: generateLabelForNumberFilter(value, numberFormat),
    }),
    getOptions: (currentValue, searchTerm) =>
      getDefaultNumberOptions(
        currentValue,
        searchTerm,
        field,
        label,
        numberFormat
      ),
  };
};
