import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import CacheService from "@/services/CacheService";
import { HTTP } from "@/utils/Http";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import React from "react";
import { Field } from "react-final-form";
import { OAObject } from "../../../types/object.interface";
// import './AssetValueForm.scss';

interface AssetValueFormProps {
  onClose: () => void;
  onSuccess?: (asset: OAObject) => void;
  asset: OAObject;
}

const transformFormdataToSubmitdata = (values: AssetValueFormValue) => {
  return {};
};

export type AssetValueFormValue = {
  value: number;
  date: Date;
};

const AssetValueForm: React.FC<AssetValueFormProps> = (props) => {
  return (
    <FormStruct
      className={classNames("asset-value-form")}
      onSubmit={async (values: AssetValueFormValue) => {
        const asset = (await HTTP.post({
          url: `/api/portfolioObject/${props.asset._id}/setAssetValue`,
          target: "EMPTY",
          bodyParams: {
            value: values.value,
            date: values.date,
          },
        })) as OAObject;
        CacheService.update(asset);

        props.onSuccess?.(asset);
        props.onClose();
      }}
      title={i18n.t("cb:Portfolio.AssetValue.title", "Verkehrswert erfassen")}
      description={i18n.t(
        "cb:Portfolio.AssetValue.description",
        "Geben Sie an, welcher Wert zu dem Asset hinzugefügt werden soll. Geben Sie das Datum der Feststellung mit an."
      )}
      ignoreSubmitOnEnter
      usePrompt
      //			 description={props.asset ? i18n.t("CBBookingCategoryRuleView.UpdateDescription", "Ändern Sie die Daten des Assets und speichern Sie.") : i18n.t("CBBookingCategoryRuleView.CreateDescription", "Erstellen Sie ein neues Asset und speichern Sie.")}
      submitText={i18n.t(
        "cb:Portfolio.AssetValue.submit",
        "Verkehrswert speichern"
      )}
      onAbort={props.onClose}
      initialValues={{
        value: props.asset?.data.feature?.assetValue?.current?.value,
        date: new Date(),
      }}
      render={(form) => (
        <>
          <Field
            name="value"
            validate={FormValidators.compose(FormValidators.required())}
          >
            {({ input, meta }) => (
              <div className="__field">
                <BFInput
                  type={"priceInput"}
                  {...input}
                  prefix={StringUtils.getCurrencySymbol()}
                  label={i18n.t(
                    "cb:Portfolio.AssetValue.value",
                    "Neuer Vermögenswert"
                  )}
                  {...FormValidators.getValidation(meta)}
                />
              </div>
            )}
          </Field>
          <Field
            name="date"
            validate={FormValidators.compose(FormValidators.required())}
          >
            {({ input, meta }) => (
              <div className="__field">
                <BFDatefield
                  {...input}
                  label={i18n.t(
                    "cb:Portfolio.AssetValue.date",
                    "Datum der Feststellung"
                  )}
                  {...FormValidators.getValidation(meta)}
                />
              </div>
            )}
          </Field>
        </>
      )}
    />
  );
};

export default AssetValueForm;
