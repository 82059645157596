import { useEffect } from "react";
import APSupplyUnitCard from "../../../apps/tatar/activityApp/specific/supplyContracts/supply-units/details/APSupplyUnitCard";
import { PortfolioSupplyUnit } from "../../../apps/tatar/cashBudget/views/config/supply-unit-config/SupplyUnitConfigInterfaces";
import AssetDetailModal from "../../../components/AssetDetailModal/AssetDetailModal";
import { AssetTypes } from "../../../model/AssetTypes";
import { openDetailsAction } from "../../GlobalDetailsFunctions";
import "./APSupplyUnitDetailModal.scss";

interface APSupplyUnitDetailModalProps {}
const ROUTE_NAME = "__asset_supply-unit";
const APSupplyUnitDetailModal = (props: APSupplyUnitDetailModalProps) => {
  useEffect(() => {
    openDetailsAction(AssetTypes.Portfolio.SupplyUnit, ROUTE_NAME);
  }, []);
  return (
    <AssetDetailModal
      assetType={AssetTypes.Portfolio.SupplyUnit}
      routeName={ROUTE_NAME}
      className="ap-supply-unit-detail-modal"
      render={(node: PortfolioSupplyUnit) => <APSupplyUnitCard asset={node} />}
    />
  );
};

export default APSupplyUnitDetailModal;
