import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import classNames from "classnames";
import { useEffect, useState } from "react";
import i18n from "../../../../../i18n";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import StringUtils from "../../../../../utils/StringUtils";
import ACInvoiceUtils from "../../../../utils/ACInvoiceUtils";
import { RAIncomingInvoice, RAInvoiceCostCenter } from "../../RAInterfaces";
import "./RACostCenterView.scss";
import RAUpdateInvoiceCostCenter from "./RAUpdateInvoiceCostCenter";

interface RACostCenterViewProps {
  initialCollapseActive?: boolean;
  disabled?: boolean;
  invoice: RAIncomingInvoice;
  selected?: boolean;
  onCostCenterActiveChange?: (active: boolean) => void;
}
const RACostCenterView = (props: RACostCenterViewProps) => {
  const [editActive, setEditActive] = useState(false);

  useEffect(() => {
    // disable edit form, when selection is changing - only  trigger when selected is not undefined
    if (props.selected === false && editActive) setEditActive(false);
  }, [props.selected, editActive]);

  useEffect(() => {
    if (props.onCostCenterActiveChange) {
      props.onCostCenterActiveChange(editActive);
    }
  }, [editActive]);

  const costCenters: RAInvoiceCostCenter[] =
    props.invoice?.data.invoice.costCenter;
  //   const costCenterCount = 4 as number; // todo get from asset
  if (editActive) {
    return (
      <div>
        <RAUpdateInvoiceCostCenter
          onFinish={() => setEditActive(false)}
          invoice={props.invoice}
        />
      </div>
    );
  }
  return (
    <div className={classNames(`ra-cost-center-view`)}>
      <div>
        <div className={`table-wrapper`}>
          <div className={`action`}>
            {props.onCostCenterActiveChange && (
              <BFButton
                appearance="link"
                size="xs"
                onClick={() => setEditActive(true)}
              >
                {i18n.t("Global.Buttons.edit")}
              </BFButton>
            )}
          </div>

          <div className="cost-centers">
            {(costCenters || []).length === 0 && (
              <div className="empty-text">
                {i18n.t("ra:CostCenter.noCostCenterAssigned")}
              </div>
            )}
            {costCenters?.map((costCenter, index) => (
              <RACostCenterEntry
                invoiceTypeId={props.invoice?.data.invoice.invoiceType}
                key={index}
                costCenterEntry={costCenter}
                index={index}
              />
            ))}
          </div>
          {/* <div className="cost-center-table">
            <div className="header">
              <div className="cost-center">Kostenstelle</div>
              <div className="amount">Betrag</div>
              <div className="object">Objekt</div>
              <div className="info">Info</div>
            </div>
            <div className={`cost-centers`}>
              {costCenters.map((costCenter, index) => (
                <RACostCenterEntry key={index} costCenterEntry={costCenter} />
              ))}
            </div>
            <div className="inset" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default RACostCenterView;

interface EntryProps {
  invoiceTypeId: string;
  costCenterEntry: RAInvoiceCostCenter;
  index: number;
}
const RACostCenterEntry = (props: EntryProps) => {
  return (
    <div className={`ra-cost-center-entry`}>
      <div className="cost-center-data">
        <div className="cost-center data-entry">
          <div className="label">{i18n.t("ra:CostCenter.Title")}</div>
          <div className={`value`}>
            {ACInvoiceUtils.getInvoiceTypeLabel(
              props.invoiceTypeId,
              props.costCenterEntry.costCenterId
            )}
          </div>
        </div>
        <div className="amount data-entry">
          <div className="label">{i18n.t("ra:CostCenter.amount")}</div>
          <div className={`value`}>
            {StringUtils.formatCurrency(props.costCenterEntry.value)}
          </div>
        </div>
        <div className="object data-entry">
          <div className="label">{i18n.t("ra:CostCenter.object")}</div>
          <div className={`value`}>
            {props.costCenterEntry.objectId
              ? OrgaStruct.getObject(props.costCenterEntry.objectId)
                  ?.displayName
              : "-"}
          </div>
        </div>
        <div className="info data-entry">
          <div className="label">{i18n.t("ra:CostCenter.info")}</div>
          <div className={`value`}>{props.costCenterEntry.info || "-"}</div>
        </div>
      </div>
    </div>
  );
};
