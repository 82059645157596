import { flatten } from "flat";
import moment from "moment";
import { MatchQuery, MatchQueryOP } from "../services/DataService";
import { hasValue, isDefined } from "./Helpers";

(window as any).flatten = flatten;
/**
 * This class is a helper class for matchqueries. You can evaluate matchqueries and you have help methods to combine and create matchqueries.
 */
class MatchQueryUtilsClass {
  filterTogetherWithSearchTerm(
    data: any[],
    matchQuery: MatchQuery,
    searchTerm: string,
    searchFields: string[]
  ) {
    if (!data) return undefined;

    const tokens = searchTerm?.trim().split(" ");

    return this.filter(data, matchQuery).filter((e) => {
      if (!searchTerm) return data;
      const flat = flatten(e, {
        safe: true,
      });

      return tokens.every((token) => {
        return searchFields.some((field) => {
          const value = flat[field];
          if (value === undefined || typeof value !== "string") return false;
          return value.toLowerCase().indexOf(token.toLowerCase()) !== -1;
        });
      });
    });
  }
  filter(data: any[], matchQuery: MatchQuery) {
    if (!data) return undefined;
    if (!matchQuery) return data;

    return data.filter((item) => {
      return this.check(item, matchQuery);
    });
  }
  check(data: any, matchQuery: MatchQuery) {
    if (!data) return false;
    if (!matchQuery) return true;

    return this.checkData(flatten(data, { safe: true }), matchQuery);
  }
  private checkData(data: { [key: string]: any }, matchQuery: MatchQuery) {
    if (matchQuery.type === "and") {
      return matchQuery.query.every((query) => {
        return this.checkData(data, query);
      });
    } else if (matchQuery.type === "or") {
      return matchQuery.query.some((query) => {
        return this.checkData(data, query);
      });
    } else if (matchQuery.type === "op") {
      let value = hasValue(data[matchQuery.name])
        ? data[matchQuery.name]
        : null;

      // FIXME currently facing issue with field structures inside arrays example: {arr: [{field1: true}]} => name: "arr.field1" value: true
      // fields in arrays are not supported yet, so in cacheservice if you have this case, please use delayedQuery:false flag - then the matchqueryUtils
      // will not be used, since the queries are not gathered
      let matchQueryValue = matchQuery.value;
      if (
        moment(matchQuery.value).isValid() &&
        value &&
        moment(value).isValid()
      ) {
        value = new Date(value).getTime();
        matchQueryValue = new Date(matchQuery.value).getTime();
      }
      if (matchQuery.op === "eq") {
        if (Array.isArray(value)) {
          return value.includes(matchQueryValue);
        } else {
          return value === matchQueryValue;
        }
      } else if (matchQuery.op === "ne") {
        if (Array.isArray(value)) {
          return !value.includes(matchQueryValue);
        } else {
          return value !== matchQueryValue;
        }
      } else if (matchQuery.op === "gt") {
        return value > matchQueryValue;
      } else if (matchQuery.op === "gte") {
        return value >= matchQueryValue;
      } else if (matchQuery.op === "lt") {
        return value < matchQueryValue;
      } else if (matchQuery.op === "lte") {
        return value <= matchQueryValue;
      } else if (matchQuery.op === "in") {
        return matchQueryValue.indexOf(value) !== -1;
      } else if (matchQuery.op === "nin") {
        return matchQueryValue.indexOf(value) === -1;
        // }
        // these doesnt exists in the original matchquery, but would make sense to extends
        // else if (matchQuery.op === "contains") {
        //   return value.indexOf(matchQueryValue) !== -1;
        // } else if (matchQuery.op === "ncontains") {
        //   return value.indexOf(matchQueryValue) === -1;
        // } else if (matchQuery.op === "exists") {
        //   return value !== undefined;
        // } else if (matchQuery.op === "nexists") {
        //   return value === undefined;
      } else if (matchQuery.op === "regex") {
        return new RegExp(matchQueryValue).test(value);
      } else if (matchQuery.op === "nregex") {
        return !new RegExp(matchQueryValue).test(value);
      } else {
        throw new Error("Unknown operator: " + matchQuery.op);
      }
    } else {
      throw new Error("Unknown query type: " + matchQuery);
    }
  }

  getAffectedFieldsOfQueries(queries: MatchQuery[]) {
    if (!queries) return [];
    const fields = new Set<string>();

    queries.forEach((query) => {
      this.getAffectedFields(query).forEach((field) => {
        fields.add(field);
      });
    });

    return Array.from(fields);
  }
  getAffectedFields(query: MatchQuery): string[] {
    if (!query) return [];
    const fields = new Set<string>();

    if (query.type === "and" || query.type === "or") {
      query.query.forEach((subQuery) => {
        MQ.getAffectedFields(subQuery).forEach((field) => {
          fields.add(field);
        });
      });
    } else if (query.type === "op") {
      fields.add(query.name);
    }
    return Array.from(fields);
  }
  combineSpread(type: "and" | "or", ...matchQueries: MatchQuery[]) {
    return this.combine(type, matchQueries);
  }
  combine(type: "and" | "or", matchQueries: MatchQuery[]) {
    if (!matchQueries) return undefined;
    const filtered = matchQueries.filter((e) => isDefined(e));
    if (filtered.length === 0) return undefined;
    if (filtered.length === 1) return filtered[0];
    return {
      type: type,
      query: filtered,
    };
  }
  and(...matchQueries: MatchQuery[]) {
    return this.combine("and", matchQueries);
  }
  or(...matchQueries: MatchQuery[]) {
    return this.combine("or", matchQueries);
  }
  when(value: any, mq: MatchQuery | ((value: any) => MatchQuery)) {
    if (value) {
      if (typeof mq === "function") {
        return mq(value);
      } else {
        return mq;
      }
    } else {
      return null;
    }
  }
  isNull(field: string) {
    return {
      type: "op",
      op: "in",
      value: [null],
      name: field,
    } as MatchQueryOP;
  }
  isNotNull(field: string) {
    return {
      type: "op",
      op: "nin",
      value: [null],
      name: field,
    } as MatchQueryOP;
  }
  in<T>(field: string, value: T[]) {
    return {
      type: "op",
      op: "in",
      name: field,
      value: value,
    } as MatchQueryOP;
  }
  nin<T>(field: string, value: T[]) {
    return {
      type: "op",
      op: "nin",
      name: field,
      value: value,
    } as MatchQueryOP;
  }
  eq<T>(field: string, value: T) {
    return {
      type: "op",
      op: "eq",
      name: field,
      value: value,
    } as MatchQueryOP;
  }
  ne<T>(field: string, value: T) {
    return {
      type: "op",
      op: "ne",
      name: field,
      value: value,
    } as MatchQueryOP;
  }
  gt<T>(field: string, value: T) {
    return {
      type: "op",
      op: "gt",
      name: field,
      value: value,
    } as MatchQueryOP;
  }
  gte<T>(field: string, value: T) {
    return {
      type: "op",
      op: "gte",
      name: field,
      value: value,
    } as MatchQueryOP;
  }
  lt<T>(field: string, value: T) {
    return {
      type: "op",
      op: "lt",
      name: field,
      value: value,
    } as MatchQueryOP;
  }
  lte<T>(field: string, value: T) {
    return {
      type: "op",
      op: "lte",
      name: field,
      value: value,
    } as MatchQueryOP;
  }

  between(field: string, from: any, to: any) {
    return this.and(this.gte(field, from), this.lte(field, to));
  }
}
const MQ = new MatchQueryUtilsClass();
(window as any).MatchQueryUtils = MQ;
export default MQ;
