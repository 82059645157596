import classNames from "classnames";
import i18n from "../../../../../i18n";
import BfIcon from "../../../../../modules/abstract-ui/icon/BfIcon";
import { InvoiceDirection } from "../../RAInterfaces";
import "./RAInvoiceDirectionIndicator.scss";

interface RAInvoiceDirectionIndicatorProps {
  direction: InvoiceDirection;
  hideLabel?: boolean;
  marginBottom?: number;
}
const RAInvoiceDirectionIndicator = (
  props: RAInvoiceDirectionIndicatorProps
) => {
  return (
    <div
      className={classNames(`ra-invoice-direction-indicator`, {
        incoming: props.direction === "INCOMING",
        outgoing: props.direction === "OUTGOING",
      })}
      style={{
        marginBottom: props.marginBottom || 10,
      }}
    >
      <div className={`icon`}>
        <BfIcon
          type="light"
          size="xxs"
          data={
            props.direction === "INCOMING"
              ? "arrow-thick-down"
              : "arrow-thick-up"
          }
        />
      </div>
      {!props.hideLabel && (
        <div className={`label`}>
          {props.direction === "INCOMING"
            ? i18n.t("ra:InvoiceForm.Direction.Incoming", "Eingehende Rechnung")
            : i18n.t(
                "ra:InvoiceForm.Direction.Outgoing",
                "Ausgehende Rechnung"
              )}
        </div>
      )}
    </div>
  );
};

export default RAInvoiceDirectionIndicator;
