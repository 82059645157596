import CacheService from "../../../services/CacheService";
import { MatchQuery } from "../../../services/DataService";
import { AppState } from "../../store";
import { getQueryId } from "./application-utils";

export const getQueryData = (
  state: AppState,
  filterName: string,
  version: number | null,
  variables?: any,
  referenceId?: string
) => {
  return (
    state.application.cache.query[
      getQueryId(filterName, version, variables, referenceId)
    ] || null
  );
};

export const selectFlexConfig = (
  state: AppState,
  flexKey: string,
  defaultValue?: any
) => {
  const data = state.application.cache.flex[flexKey];

  if (defaultValue) {
    return {
      ...defaultValue,
      ...(data || {}),
    };
  } else {
    return data;
  }
};

export const selectCacheAsset = (
  state: AppState,
  assetType: string,
  idOrQuery: string | MatchQuery
) => {
  return state.application.cache[assetType]?.[
    CacheService.generateQueryId(assetType, idOrQuery)
  ];
};

export const selectCacheUser = (state: AppState, id: string) => {
  return state.application.cache?.user?.[id];
};
export const selectCacheTeam = (state: AppState, id: string) => {
  return state.application.cache?.team?.[id];
};

export const selectCacheGroup = (state: AppState, id: string) => {
  return state.application.cache?.group?.[id];
};
