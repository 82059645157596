import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import PermissionService from "@/services/PermissionService";
import classNames from "classnames";
import ModalManager from "../../../../../../../../components/ModalComponent/ModalManager";
import ListComponent from "../../../../../../../../configurable/components/ListComponent/ListComponent";
import i18n from "../../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../../model/AssetTypes";
import BFButton from "../../../../../../../../modules/abstract-ui/general/Button/BFButton";
import DataBusDefaults from "../../../../../../../../services/DataBusDefaults";
import MQ from "../../../../../../../../utils/MatchQueryUtils";
import RiskScenarioCard from "./RiskScenarioCard";
import RiskScenarioForm from "./RiskScenarioForm";
import { RiskScenario } from "./RiskScenarioInterfaces";
import { RISK_SCENARIO_TABLE } from "./RiskScenarioService";
import "./RiskScenarioView.scss";

type PropsObject = {
  assets: string[];
  refId: string;
  kind: ObjectKind;
};
type Props = PropsObject;

const RiskScenarioView = (props: Props) => {
  return (
    <div className={classNames(`risk-scenario-view`)}>
      <div className={`header`}>
        {PermissionService.hasObjectKindPermission(
          props.kind,
          "risk-management.createEdit"
        ) && (
          <BFButton
            onClick={() => {
              ModalManager.show({
                backdrop: "static",
                size: "xxl",
                noPadding: true,
                content: (states, setStates, closeModal) => (
                  <RiskScenarioForm
                    referenceId={props.refId}
                    type="create"
                    onClose={closeModal}
                    onError={(error) => {
                      DataBusDefaults.toast({
                        type: "error",
                        text: i18n.t(
                          "cb:RiskScenarioView.errorCreation",
                          "Erstellen des Szenarios fehlgeschlagen"
                        ),
                      });
                    }}
                    onSuccess={(data) => {
                      DataBusDefaults.toast({
                        type: "success",
                        text: i18n.t(
                          "cb:RiskScenarioView.successCreation",
                          "Szenario erfolgreich erstellt"
                        ),
                      });
                      DataBusDefaults.reload({
                        identifiers: [RISK_SCENARIO_TABLE],
                      });
                    }}
                    objectIds={props.assets}
                  />
                ),
              });
            }}
          >
            {i18n.t(
              "cb:RiskScenarioView.header.createNewScenario",
              "Neues Szenario erstellen"
            )}
          </BFButton>
        )}
      </div>
      <div className={`content`}>
        <ListComponent
          identifier={RISK_SCENARIO_TABLE}
          asPost
          assetType={AssetTypes.Portfolio.RiskScenario}
          emptyText={i18n.t(
            "cb:RiskScenarioView.noData",
            "Bisher wurden keine Risiko Szenarien angelegt"
          )}
          additionalMatchQuery={MQ.and(MQ.eq("data.referenceId", props.refId))}
          limitPerRequest={3}
          // hiddenSort={props.sort}
          render={(node: RiskScenario) => {
            return (
              <RiskScenarioCard
                riskScenario={node}
                className="margin-bottom-10"
                editable={PermissionService.hasObjectKindPermission(
                  props.kind,
                  "risk-management.createEdit"
                )}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default RiskScenarioView;
