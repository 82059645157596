import { CommonStructLoader } from "../../../../components/StructLoader/StructLoader";
import CurrencyStruct from "../../../../redux/actions/struct/implemented/CurrencyStruct";
import StringUtils from "../../../../utils/StringUtils";
import { CurrencyValueWithConversion } from "./BFCurrency";
import CurrencyLabel from "./CurrencyLabel";

interface CurrencyConvertLabelProps {
  value: number;
  fromCurrency?: string;
  toCurrency: string;
}
const CurrencyConvertLabel = (props: CurrencyConvertLabelProps) => {
  return (
    <CommonStructLoader
      structType="currency"
      render={() => {
        const converted = CurrencyStruct.convert(
          props.value,
          props.fromCurrency || StringUtils.getCurrency(),
          props.toCurrency
        );
        const conversionDate = CurrencyStruct.getConversionDate();
        const conversionRate = CurrencyStruct.getConversionRate(
          props.fromCurrency || StringUtils.getCurrency(),
          props.toCurrency
        );

        const value: CurrencyValueWithConversion = {
          amount: converted,
          currency: props.toCurrency,
          converted: {
            amount: props.value,
            conversionDate,
            conversionRate,
            currency: props.fromCurrency || StringUtils.getCurrency(),
          },
        };
        return <CurrencyLabel value={value} />;
      }}
    />
  );
};

export default CurrencyConvertLabel;
