import React, { useEffect, useRef, useState } from "react";
import i18n from "../../../../../i18n";
import StringUtils from "../../../../../utils/StringUtils";
import { BarTask } from "../../types/bar-task";
import { Task } from "../../types/public-types";
import styles from "./tooltip.module.css";

export type TooltipProps = {
  task: BarTask;
  arrowIndent: number;
  rtl: boolean;
  svgContainerHeight: number;
  svgContainerWidth: number;
  svgWidth: number;
  headerHeight: number;
  taskListWidth: number;
  rowHeight: number;
  fontSize: string;
  fontFamily: string;
  TooltipContent: React.FC<{
    task: Task;
    fontSize: string;
    fontFamily: string;
  }>;
  onOpen: (ref: HTMLDivElement | null) => void;
};
export const Tooltip: React.FC<TooltipProps> = ({
  task,
  onOpen,
  rowHeight,
  rtl,
  svgContainerHeight,
  svgContainerWidth,
  arrowIndent,
  fontSize,
  fontFamily,
  headerHeight,
  taskListWidth,
  TooltipContent,
}) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [relatedY, setRelatedY] = useState(0);
  const [relatedX, setRelatedX] = useState(0);
  useEffect(() => {
    onOpen(tooltipRef.current);
  }, []);
  useEffect(() => {
    const scrollY = 0;
    const scrollX = 0;
    if (tooltipRef.current) {
      const tooltipHeight = tooltipRef.current.offsetHeight * 1.1;
      const tooltipWidth = tooltipRef.current.offsetWidth * 1.1;

      let newRelatedY = task.index * rowHeight - scrollY + headerHeight;
      let newRelatedX: number;
      if (rtl) {
        newRelatedX = task.x1 - arrowIndent * 1.5 - tooltipWidth - scrollX;
        if (newRelatedX < 0) {
          newRelatedX = task.x2 + arrowIndent * 1.5 - scrollX;
        }
        const tooltipLeftmostPoint = tooltipWidth + newRelatedX;
        if (tooltipLeftmostPoint > svgContainerWidth) {
          newRelatedX = svgContainerWidth - tooltipWidth;
          newRelatedY += rowHeight;
        }
      } else {
        newRelatedX = task.x2 + arrowIndent * 1.5 + taskListWidth - scrollX;
        const tooltipLeftmostPoint = tooltipWidth + newRelatedX;
        const fullChartWidth = taskListWidth + svgContainerWidth;
        // if (tooltipLeftmostPoint > fullChartWidth) {
        //   newRelatedX =
        //     task.x1 +
        //     taskListWidth -
        //     arrowIndent * 1.5 -
        //     scrollX -
        //     tooltipWidth;
        // }
        // if (newRelatedX < taskListWidth) {
        //   newRelatedX = svgContainerWidth + taskListWidth - tooltipWidth;
        //   newRelatedY += rowHeight;
        // }
      }

      const tooltipLowerPoint = tooltipHeight + newRelatedY - scrollY;
      // if (tooltipLowerPoint > svgContainerHeight - scrollY) {
      //   newRelatedY = svgContainerHeight - tooltipHeight;
      // }
      setRelatedY(newRelatedY);
      setRelatedX(newRelatedX);
    }
  }, [
    tooltipRef,
    task,
    arrowIndent,
    headerHeight,
    taskListWidth,
    rowHeight,
    svgContainerHeight,
    svgContainerWidth,
    rtl,
  ]);

  return (
    <div
      ref={tooltipRef}
      className={
        relatedX
          ? styles.tooltipDetailsContainer
          : styles.tooltipDetailsContainerHidden
      }
      style={{ left: relatedX, top: relatedY }}
    >
      <TooltipContent task={task} fontSize={fontSize} fontFamily={fontFamily} />
    </div>
  );
};

export const StandardTooltipContent: React.FC<{
  task: Task;
  fontSize: string;
  fontFamily: string;
}> = ({ task, fontSize, fontFamily }) => {
  const style = {
    fontSize,
    fontFamily,
  };
  return (
    <div className={styles.tooltipDefaultContainer} style={style}>
      <b style={{ fontSize: fontSize + 6 }}>{`${
        task.name
      }: ${StringUtils.formatDate(task.start)} - ${StringUtils.formatDate(
        task.end
      )}`}</b>
      {task.end.getTime() - task.start.getTime() !== 0 && (
        <p className={styles.tooltipDefaultContainerParagraph}>{`${i18n.t(
          "GanttChart.Duration",
          "Dauer"
        )}: ${~~(
          (task.end.getTime() - task.start.getTime()) /
          (1000 * 60 * 60 * 24)
        )} ${i18n.t("GanttChart.Days", "Tag(e)")}`}</p>
      )}

      <p className={styles.tooltipDefaultContainerParagraph}>
        {!!task.progress &&
          `${i18n.t(
            "GanttChart.Progress",
            "Fortschritt"
          )}: ${StringUtils.formatPercent(task.progress)}`}
      </p>
    </div>
  );
};
