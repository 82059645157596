import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import { ObjectKindPermission } from "@/modules/abstract-ui/forms/app-permission/BFObjectKindPermission";
import { isDefined } from "@/utils/Helpers";
import _ from "lodash";
import { Permissions } from "../model/db/Permission";
import { User } from "../model/db/User";

class PermissionServiceClass {
  activeApp: string;
  userPermissions: ({ APP: string } & Permissions)[];
  activePermissions: { APP: string } & Permissions;

  filteredBusinessUnits: string[] = null;

  setApplication(appId?: string) {
    this.activeApp = appId;
    this.activePermissions = this.userPermissions?.find((e) => e.APP === appId);
    this.filteredBusinessUnits = null;
  }
  setUser(user: User) {
    if (user) {
      this.userPermissions = user.permissions;
    } else {
      this.activeApp = null;
      this.userPermissions = null;
      this.activePermissions = null;
    }
  }

  getAllPermissionUnits() {
    return _.uniq(
      this.userPermissions
        ?.map((e) => Object.values(e.ROLES || {}))
        .flat()
        .filter((e) => Array.isArray(e))
        .flat()
    );
  }

  // default boolean type, but can also be any structure at all
  getRole<T = boolean>(role: string, activePermission = true) {
    if (activePermission) {
      return this.activePermissions?.ROLES?.[role] as T;
    } else {
      return _.uniq(
        this.userPermissions
          .map((e) => e.ROLES?.[role])
          .filter((e) => e)
          .flat() || []
      ) as T;
    }
  }

  setBusinessUnitFilters(units?: string[]) {
    this.filteredBusinessUnits = units;
  }
  getBusinessUnitFilters() {
    return this.filteredBusinessUnits;
  }

  hasObjectKindPermission(objectKind: ObjectKind, permission?: string) {
    const objectKindPermissions =
      this.getRole<ObjectKindPermission[]>("objectKindPermissions") || [];
    const permObj = objectKindPermissions.filter(
      (e) => e.objectKindId === objectKind._id
    );
    if (permObj.length > 0) {
      if (isDefined(permission)) {
        const permissions = permObj.map((o) => o.permissions).flat();
        return permissions.includes(permission);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  hasSpecificBusinessUnitRoles(role: string, businessUnits: string[]) {
    return businessUnits.some((e) => this.hasSpecificBusinessUnitRole(role, e));
  }

  hasSpecificBusinessUnitRole(
    role: string,
    businessUnit: string,
    ignoreGlobalBusinessUnitFilter = false,
    activePermission = true
  ) {
    return this.hasBusinessUnitRole(
      role,
      ignoreGlobalBusinessUnitFilter,
      activePermission
    )?.includes(businessUnit);
  }

  hasBusinessUnitRole(
    role: string,
    ignoreGlobalBusinessUnitFilter = false,
    activePermission = true
  ) {
    const permissionRole = this.getRole<string[]>(role, activePermission);
    if (permissionRole) {
      let roles = _.intersection(
        (window as any).UnitStruct.getUnits(),
        permissionRole
      );

      if (!ignoreGlobalBusinessUnitFilter) {
        if (this.filteredBusinessUnits) {
          roles = roles.filter((e) => this.filteredBusinessUnits.includes(e));
        }
      }

      if (roles.length === 0) {
        return undefined;
      }

      return roles;
    }
    return undefined;
  }

  hasBusinessUnitByApp(role: string, appId: string) {
    const app = this.userPermissions?.find((e) => e.APP == appId);
    if (app) {
      return app.ROLES[role];
    } else {
      return undefined;
    }
  }
}
const PermissionService = new PermissionServiceClass();
(window as any).PermissionService = PermissionService;
export default PermissionService;
