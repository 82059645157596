import { css } from "emotion";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";

export type Props = {
  text?: string;
  textKey?: string;
  _text?: string;
  className?: string;
  type: "span" | "p" | "h1" | "h2" | "h3" | "h4" | "h5";
} & AbstractStylableProps &
  WithTranslation;

export type States = {} & AbstractStylableStates;

class TextComponent extends AbstractStylableComponent<Props, States> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    if (!this.shoudBeRendered()) {
      return null;
    }
    const { i18n, type, text, textKey, className, _text } = this.props;

    let usedClassName = `${className ? className : ""} ${
      this.state.usedStyle ? css(this.state.usedStyle as any) : ""
    }`;
    let usedText = null;

    if (text) {
      usedText = text;
    } else if (textKey) {
      usedText = i18n.t(textKey);
    } else if (_text) {
      usedText = this.evaluateExpression(_text);
    }

    switch (type) {
      case "h1":
        return (
          <h1 style={this.state.usedStyle} className={usedClassName}>
            {usedText}
          </h1>
        );
      case "h2":
        return (
          <h2 style={this.state.usedStyle} className={usedClassName}>
            {usedText}
          </h2>
        );
      case "h3":
        return (
          <h3 style={this.state.usedStyle} className={usedClassName}>
            {usedText}
          </h3>
        );
      case "h4":
        return (
          <h4 style={this.state.usedStyle} className={usedClassName}>
            {usedText}
          </h4>
        );
      case "h5":
        return (
          <h5 style={this.state.usedStyle} className={usedClassName}>
            {usedText}
          </h5>
        );
      case "span":
        return (
          <span style={this.state.usedStyle} className={usedClassName}>
            {usedText}
          </span>
        );
      case "p":
        return (
          <p style={this.state.usedStyle} className={usedClassName}>
            {usedText}
          </p>
        );
      default:
        return usedText;
    }
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(TextComponent)) as any;
