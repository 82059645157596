import { get, set } from "local-storage";
import { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
import { Field, Form } from "react-final-form";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Animation } from "rsuite";
import { TranslationProps } from "../../../../model/common/TranslationProps";
import { ContextData } from "../../../../model/db/ContextData";
import { User } from "../../../../model/db/User";
import BFInput from "../../../../modules/abstract-ui/forms/input/BFInput";
import BfButton from "../../../../modules/abstract-ui/general/Button/BFButton";
import BFMessage from "../../../../modules/abstract-ui/general/Message/BFMessage";
import BfIcon from "../../../../modules/abstract-ui/icon/BfIcon";
import ValidatorsUtils from "../../../../modules/generic-forms/util/ValidatorsUtils";
import { AppState } from "../../../../redux/store";
import { loginUser } from "../../../../services/AuthenticationService";
import DataBus from "../../../../services/DataBus";
import {
  ConstantMisc,
  LSTrustedDeviceModel,
} from "../../../../utils/Constants";
import StorageUtils from "../../../../utils/StorageUtils";
import "./LoginForm.scss";
import TrustedDeviceLoginForm from "./TrustedDeviceLoginForm";

const { Collapse } = Animation;

interface Props extends TranslationProps {
  loginUser: (
    email: string,
    password: string,
    mandator: string,
    onSuccess: (data: User) => void,
    onError: (err: any) => void
  ) => void;
  cookies: Cookies;
  mandatorName: string;
  cookiesAccepted: boolean;
  context: ContextData;
}

interface States {
  loading: boolean;
  showFurtherLogin: boolean;
  showErrors: boolean;
  localTrustedDevices: LSTrustedDeviceModel[];
  mode: "normal" | "trustedDevices";
  loginTried: boolean;
}

class LoginForm extends Component<Props, States> {
  constructor(props) {
    super(props);

    let showFurtherLogin = props.mandatorName ? true : false;

    if (
      props.context &&
      props.context.login &&
      props.context.login.ignoreMandatorFieldAnimation
    ) {
      showFurtherLogin = true;
    }

    const localTrustedDevices = (StorageUtils.LocalStorage.get(
      ConstantMisc.LS_KEY_TRUSTED_DEVICES
    ) || []) as LSTrustedDeviceModel[];
    this.state = {
      loginTried: false,
      loading: false,
      showFurtherLogin: showFurtherLogin,
      showErrors: false,
      localTrustedDevices,
      mode: localTrustedDevices.length > 0 ? "trustedDevices" : "normal",
    };
  }

  componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ): void {
    if (this.props.mandatorName !== nextProps.mandatorName) {
      this.setState({ showFurtherLogin: true });
    }
  }

  componentDidMount() {
    if (!this.props.mandatorName) {
      const el = document.querySelector(
        `.form-row.mandator input`
      ) as HTMLElement;
      if (el) {
        el.onkeydown = (ev) => {
          if (ev.key === "Tab" && !this.state.showFurtherLogin) {
            ev.preventDefault();
            this.collapseFurtherInfo();
          }
        };
      }
      this.focusInputfield("mandator");
    } else {
      this.focusInputfield("email");
    }
  }

  focusInputfield(fieldName: string, ignoreDelay = false) {
    const el = document.querySelector(
      `.form-row.${fieldName} input`
    ) as HTMLElement;
    if (el) {
      if (ignoreDelay) {
        el.focus();
      } else {
        setTimeout(() => {
          el.focus();
        }, 100);
      }
    }
  }

  onSubmit = (form) => {
    const { t } = this.props;

    if (!this.state.showFurtherLogin) {
      this.collapseFurtherInfo();
    } else {
      if (
        !form.email ||
        !form.password ||
        (!this.props.mandatorName && !form.mandator)
      ) {
        this.setState({
          showErrors: true,
        });
        // BFToast.open({
        //     type: "error",
        //     content: t("views.login.errors.noValidCredentialsMsg")
        // })

        if (!form.mandator) {
          this.focusInputfield("mandator", true);
        } else if (!form.email && !ValidatorsUtils.isEmail(form.email)) {
          this.focusInputfield("email", true);
        } else if (!form.password) {
          this.focusInputfield("password", true);
        }
      } else {
        if (!this.props.cookiesAccepted) {
          this.setState({ loginTried: true });
          DataBus.emit("ShowCookieArrow", {});
          // BFToast.open({
          //   type: "error",
          //   content: t("views.login.errors.noCookiesAccepted"),
          // });
        } else if (!ValidatorsUtils.isEmail(form.email)) {
          this.setState({
            showErrors: true,
          });
        } else {
          this.setState({ loading: true });
          this.props.loginUser(
            form.email,
            form.password,
            this.props.mandatorName ? this.props.mandatorName : form.mandator,
            (data) => {
              if (form.mandator) {
                set("mandator", form.mandator);
              }
            },
            (err) => {
              this.setState({ loading: false });
            }
          );
        }
      }
    }
  };
  validate = (form) => {
    const errors = {};

    return errors;
  };

  collapseFurtherInfo() {
    this.setState({ showFurtherLogin: true }, () => {
      setTimeout(() => {
        this.focusInputfield("email");
      }, 100);
    });
  }
  onTrustedDeviceRemove(device: LSTrustedDeviceModel) {
    const newTrustedDevices = this.state.localTrustedDevices.filter(
      (entry) => entry !== device
    );
    StorageUtils.LocalStorage.save(
      ConstantMisc.LS_KEY_TRUSTED_DEVICES,
      newTrustedDevices
    );
    this.setState({
      localTrustedDevices: newTrustedDevices,
      mode: newTrustedDevices.length === 0 ? "normal" : "trustedDevices",
    });
  }
  render() {
    const { t, mandatorName } = this.props;
    const { mode, localTrustedDevices } = this.state;
    // if (mode === "trustedDevices")
    // 	return (
    // 		<TrustedDeviceLoginForm onTrustedDeviceRemove={(device) => this.onTrustedDeviceRemove(device)} onGoBack={() => this.setState({ mode: "normal" })} trustedDevices={localTrustedDevices} />
    // 	);
    return (
      <>
        <Collapse in={mode === "trustedDevices"}>
          <div>
            <TrustedDeviceLoginForm
              onTrustedDeviceRemove={(device) =>
                this.onTrustedDeviceRemove(device)
              }
              onGoBack={() => this.setState({ mode: "normal" })}
              trustedDevices={localTrustedDevices}
            />
          </div>
        </Collapse>
        <Collapse in={mode === "normal"}>
          <div>
            <div
              className={`login-form ${
                this.state.showFurtherLogin ? "collapsed" : "not-collapsed"
              } ${mandatorName ? "no-mandator" : ""}`}
            >
              <Form
                initialValues={{
                  mandator: mandatorName,
                }}
                onSubmit={this.onSubmit}
                validate={this.validate}
                render={({ handleSubmit, pristine, invalid }) => (
                  <form onSubmit={handleSubmit}>
                    {!mandatorName ? (
                      <Field
                        name="mandator"
                        defaultValue={get("mandator") as string}
                      >
                        {({ input, meta }) => (
                          <div className="form-row mandator">
                            <BFInput
                              {...input}
                              size="lg"
                              className="mandator-field"
                              autoComplete="off"
                              prefix={
                                <BfIcon type={"bf"} data={"new-construction"} />
                              }
                              placeholder={t("views.login.labels.mandator")}
                              addonsInside={true}
                              autoCorrect={"off"}
                              autoCapitalize={"off"}
                              spellCheck={false}
                              validation={
                                this.state.showErrors &&
                                (!input.value || input.value.trim() === "")
                                  ? {
                                      message: this.props.i18n.t(
                                        "common.errors.required"
                                      ),
                                      level: "error",
                                    }
                                  : undefined
                              }
                              suffix={
                                !this.state.showFurtherLogin ? (
                                  <BfIcon
                                    style={{
                                      fontSize: "1.5em",
                                      lineHeight: "1em",
                                    }}
                                    type="bf"
                                    data="arrow-circle-right"
                                  />
                                ) : undefined
                              }
                              suffixOnClick={() => this.collapseFurtherInfo()}
                            />
                          </div>
                        )}
                      </Field>
                    ) : null}

                    <Collapse in={this.state.showFurtherLogin}>
                      <div>
                        <Field name="email" defaultValue={""}>
                          {({ input, meta }) => (
                            <div className="form-row email">
                              <BFInput
                                {...input}
                                size="lg"
                                autoComplete="username"
                                autoCorrect={"off"}
                                className="mail-field"
                                autoCapitalize={"off"}
                                spellCheck={false}
                                prefix={<BfIcon type={"bf"} data={"profile"} />}
                                placeholder={t("views.login.labels.email")}
                                validation={
                                  this.state.showErrors &&
                                  (!input.value ||
                                    input.value.trim() === "" ||
                                    !ValidatorsUtils.isEmail(input.value))
                                    ? {
                                        message:
                                          !input.value ||
                                          input.value.trim() === ""
                                            ? this.props.i18n.t(
                                                "common.errors.required"
                                              )
                                            : this.props.i18n.t(
                                                "common.errors.emailInvalid"
                                              ),
                                        level: "error",
                                      }
                                    : undefined
                                }
                                addonsInside={true}
                              />
                            </div>
                          )}
                        </Field>
                        <Field name="password" defaultValue={""}>
                          {({ input, meta }) => (
                            <div className="form-row password">
                              <BFInput
                                {...input}
                                size="lg"
                                prefix={<BfIcon type={"bf"} data={"lock"} />}
                                type="password"
                                className="password-field"
                                placeholder={t("views.login.labels.password")}
                                validation={
                                  this.state.showErrors &&
                                  (!input.value || input.value.trim() === "")
                                    ? {
                                        message: this.props.i18n.t(
                                          "common.errors.required"
                                        ),
                                        level: "error",
                                      }
                                    : undefined
                                }
                                addonsInside={true}
                              />
                            </div>
                          )}
                        </Field>

                        {localTrustedDevices &&
                        localTrustedDevices.length > 0 ? (
                          <div className={`switch-to-pin-action`}>
                            <BfButton
                              onClick={() =>
                                this.setState({ mode: "trustedDevices" })
                              }
                              textKey={"views.login.labels.SwitchToPinView"}
                              icon={{
                                type: "bf",
                                data: "dial-pad",
                              }}
                              type="button"
                              appearance="clear-on-white"
                            />
                          </div>
                        ) : null}
                        <div className="button-row">
                          <BfButton
                            className={"reset-password"}
                            type="button"
                            appearance="clear-on-white"
                            size="lg"
                            href="/resetPassword"
                          >
                            {t("views.login.labels.passwordReset")}
                          </BfButton>

                          <div className="fill" />
                          <BfButton
                            className={"login"}
                            type="submit"
                            loading={this.state.loading}
                            appearance="primary"
                            size="lg"
                            onClick={handleSubmit}
                          >
                            {t("views.login.labels.login")}
                          </BfButton>
                        </div>
                      </div>
                    </Collapse>
                  </form>
                )}
              />
              <Collapse
                in={this.state.loginTried && !this.props.cookiesAccepted}
              >
                <div>
                  <div className={`login-error`}>
                    <BFMessage
                      type="error"
                      text={t("views.login.errors.noCookiesAccepted")}
                    />
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </Collapse>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  context: state.global.context,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps: any
) => ({
  loginUser: (
    email: string,
    password: string,
    mandator: string,
    onSuccess: (data: User) => void,
    onError: (err: any) => void
  ) => {
    dispatch(loginUser(email, password, mandator, onSuccess, onError));
  },
  // setUserData: (user: User, apps: Application[], permissions: Permission[]) => { dispatch(GlobalActions.setUserData(user, apps, permissions)) }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(withTranslation()(LoginForm))) as any;
