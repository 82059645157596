import { Component } from "react";
import { Nav } from "rsuite";
import TableComponent from "../../../../configurable/components/TableComponent/TableComponent";
import i18n from "../../../../i18n";
import DataBus from "../../../../services/DataBus";
import { MatchQuery } from "../../../../services/DataService";
import { DataBusSubKeys } from "../../../../utils/Constants";
import CashBudgetUtils from "../CashBudgetUtils";
import { CashBudgetCategory } from "../model/CashBudgetCofiguration";
import { AssetCashBudgetEntry } from "../model/CashBudgetEntry";
import CashBudgetService from "../services/CashBudgetService";
import { CashBudgetSpan } from "../views/tableview/TatarCashBudgetTableComponent";
import CashBudgetBookingCard from "./CashBudgetBookingCard";
import "./CashBudgetCategoryDetail.scss";

type Props = {
  requestType: "starmoney" | "datev";
  span: CashBudgetSpan;
  category: CashBudgetCategory;
  filteredEntities: string[];
  filteredObject: string;
  filterBy: { name: string; sortType: "desc" | "asc" };
  assetType: string;
  ignoreCategoryChildren?: boolean;
};

type States = {
  openTab: "should" | "current";
};
export const CASHBUDGET_CATEGORY_DETAIL_TABLE = "cash-budget-list-overlay";
class CashBudgetCategoryDetail extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    openTab: "current",
  };

  componentDidUpdate(prevProps: Props, prevStates: States) {
    if (prevStates.openTab !== this.state.openTab) {
      DataBus.emit(DataBusSubKeys.RELOAD, {
        identifiers: [CASHBUDGET_CATEGORY_DETAIL_TABLE],
      });
    }
  }
  renderEntry(entry: AssetCashBudgetEntry) {
    const { assetType, category } = this.props;

    // [
    //   category._id,
    //   ...CashBudgetUtils.getChildCategories(
    //     category._id,
    //     this.props.configuration
    //   ).map((e) => e._id),
    // ],

    const filteredCategories = [
      category._id,
      ...CashBudgetUtils.getChildCategories(category._id).map((e) => e._id),
    ];
    return (
      <CashBudgetBookingCard
        booking={entry}
        showCategory
        expanded
        allowRevertCategoryChange
      />
      // <CashBudgetCategoryDetailEntry
      //   requestType={this.props.requestType}
      //   assetType={assetType}
      //   category={category._id}
      //   entry={entry}
      //   // showCategory={filteredCategories.length > 1}
      //   showCategory={true}
      //   expanded={true}
      //   tableToUpdate={CASHBUDGET_CATEGORY_DETAIL_TABLE}
      //   filteredCategories={filteredCategories}
      // />
    );
  }

  buildMatchQuery(): MatchQuery {
    const { openTab } = this.state;
    const { span, category } = this.props;

    const filter = CashBudgetService.convertFilterData(
      this.props.filteredObject
    );
    const { filterObjects, filterAccounts } =
      CashBudgetService.getAccountAndObjectByFilter(
        this.props.filteredEntities,
        filter.accounts,
        filter.objects
      );
    const matchQuery: MatchQuery = {
      type: "and",
      query: [
        {
          name: "data.date",
          op: "gte",
          type: "op",
          value: span.dateFrom,
        },
        {
          name: "data.date",
          op: "lte",
          type: "op",
          value: span.dateTo,
        },
        {
          name: "data.category",
          op: "in",
          type: "op",
          value: [
            category._id,
            ...(this.props.ignoreCategoryChildren
              ? []
              : CashBudgetUtils.getChildCategories(category._id).map(
                  (e) => e._id
                )),
          ],
        },
        {
          name: "data.bankAccount",
          op: "in",
          type: "op",
          value: filterObjects
            ? filterObjects.reduce((prev, cur) => {
                const account = CashBudgetUtils.getBankAccountByObject(cur);
                if (account) {
                  return [...prev, account._id];
                } else {
                  return prev;
                }
              }, [])
            : filterAccounts,
        },
        {
          name: "data.type",
          op: "in",
          type: "op",
          value:
            openTab === "current"
              ? ["import"]
              : [
                  "manual",
                  "damageClaim",
                  "forecast",
                  "rent_forecast",
                  "invoice",
                ],
        },
      ],
    };

    return matchQuery;
  }
  render() {
    const { span, category, assetType } = this.props;
    const relevantEntries = []; //span.cashBudgetEntries.filter(entry => entry.category === category);
    // TODO request data and show single bookings
    if (!span) {
      return null;
    }
    return (
      <div className="cash-budget-category-detail">
        <Nav
          className="cashbudget-type-tab"
          activeKey={this.state.openTab}
          onSelect={(key) => this.setState({ openTab: key })}
          appearance="subtle"
        >
          <Nav.Item eventKey="current">
            {i18n.t("CategoryDetail.booked", "Gebucht")}
          </Nav.Item>
          <Nav.Item eventKey="should">
            {i18n.t("CategoryDetail.planValues", "Planwerte")}
          </Nav.Item>
        </Nav>

        <div className={`cash-budget-category-detail-table-container`}>
          <TableComponent
            initialReload={true}
            appearance={"clear"}
            ignoreInteractionHighlights={true}
            ignoreTableSelection={true}
            useEndlessScrolling={true}
            dataUrl={`/api/asset/list/${assetType}`}
            post
            sortInterceptorFc={(sort) => [
              ...sort,
              {
                dataKey: "data.sortKey",
                sortType: "desc",
              },
            ]}
            hideColumnHeaders={true}
            hideConfigMenu={true}
            pageSize={15}
            identifier={CASHBUDGET_CATEGORY_DETAIL_TABLE}
            hideSelectionControls={true}
            striped={false}
            initialSort={[
              { ...this.props.filterBy, dataKey: this.props.filterBy.name },
              { dataKey: "data.sortKey", sortType: "desc" },
            ]}
            matchQuery={this.buildMatchQuery()}
            columns={{
              column1: {
                orderIndex: 1,
                columnHeaderTextKey: "Application.Asset.Todo.Name",
                resizable: false,
                sortable: true,
                component: (params: any) => {
                  return this.renderEntry(params.row);
                },
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default CashBudgetCategoryDetail;
