class DataBusClass {
  private subIdSeq = 0;
  private subs: {
    [key: string]: {
      [subId: number]: (data, isState: boolean) => void;
    };
  } = {};

  private subState: {
    [key: string]: any;
  } = {};

  public getDatabusDebug() {
    return {
      states: this.subState,
      subs: this.subs,
    };
  }

  public emit<T>(key, val: T, isState = false) {
    // Log.state("DATABUS EMIT", key, val, isState);
    if (isState) {
      this.subState[key] = val;
    }
    if (this.subs[key]) {
      Object.values(this.subs[key]).forEach((subCallback) =>
        subCallback ? subCallback(val, isState) : null
      );
    }
  }

  public subscribe<T>(
    key: string,
    callback: (val: T, isState: boolean) => void
  ): number {
    if (typeof callback !== "function") {
      throw "not a valid subscription callback";
    }

    if (!this.subs[key]) {
      this.subs[key] = {};
    }
    const subId = ++this.subIdSeq;
    this.subs[key][subId] = callback;

    //Send current state if there is any available
    if (this.subState[key]) {
      callback(this.subState[key], true);
    }

    return subId;
  }

  public subscribeReduxActions<T>(keys: string[]): void {
    keys.forEach((key) =>
      this.subscribe(key, (val, isState) => {
        // Log.debug("DATABUS REDUX ACTION", key, val);
        (window as any).store.dispatch({
          type: key,
          ...(val as any),
        });
      })
    );
  }

  public unsubscribe(subId) {
    Object.values(this.subs).forEach((subEntry) => {
      if (subEntry[subId]) {
        subEntry[subId] = undefined;
      }
    });
  }
}

const DataBus = new DataBusClass();
(window as any).DataBus = DataBus;
export default DataBus;
