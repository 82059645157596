import axios from "axios";
import { ThunkDispatch } from "redux-thunk";
import {
  setLogData,
  setLogLoading
} from "../redux/actions/application/application-actions";
import { store } from "./../redux/store";
import { HTTP } from "./../utils/Http";

export interface RequestLogParam {
  logIdentifier: string;
  oType: ("group" | "user" | "asset")[];
  targetID?: string;
  type?: string[];
  userID?: string;
  types?: string[];
  mode: "prepend" | "append" | "replace";
  cursor?: string;
  before?: boolean;
  limit?: number;
  onSuccess?: (data: any) => void;
  onError?: (err: any) => void;
}

export const requestLogData = (
  requestParam: RequestLogParam,
  cancelObj: { cancel?: () => void }
) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    requestLogDataHelper(requestParam, cancelObj, dispatch);
  };
};
const requestLogDataHelper = (
  requestParam: RequestLogParam,
  cancelObj: { cancel?: () => void },
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  const logEntry = store.getState().application.logs[
    requestParam.logIdentifier
  ];

  if (logEntry && logEntry.loading !== null) {
    return;
  }

  const params = {};

  params["oType"] = requestParam.oType;
  if (requestParam.cursor) {
    params["cursor"] = requestParam.cursor;
  }
  if (requestParam.before) {
    params["before"] = requestParam.before;
  }
  if (requestParam.limit) {
    params["limit"] = requestParam.limit;
  }
  if (requestParam.targetID) {
    params["targetID"] = requestParam.targetID;
  }
  if (requestParam.userID) {
    params["userID"] = requestParam.userID;
  }
  if (requestParam.type) {
    params["type"] = requestParam.type;
  }

  dispatch(setLogLoading(requestParam.logIdentifier, requestParam.mode));

  HTTP.get({
    url: "logging",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    },
    queryParams: {
      param: {
        ...params
      }
    },
    cancelToken: cancelObj
      ? new axios.CancelToken(cancel => (cancelObj.cancel = cancel))
      : undefined
  })
    .then(data => {
      if (cancelObj) {
        cancelObj.cancel = undefined;
      }
      const { before, cursosr, limit, ...eqParams } = params as any;
      dispatch(
        setLogData(
          requestParam.logIdentifier,
          requestParam.mode,
          data,
          eqParams
        )
      );

      if (requestParam.onSuccess) {
        requestParam.onSuccess(data);
      }
    })
    .catch(err => {
      if (cancelObj) {
        cancelObj.cancel = undefined;
      }
      if (err instanceof axios.Cancel) {
        return;
      }

      if (requestParam.onError) {
        requestParam.onError(err);
      }
    });
};
