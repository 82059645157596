import moment from "moment";
import { useEffect, useState } from "react";
import { Nav } from "rsuite";
import ListComponent from "../../../../configurable/components/ListComponent/ListComponent";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import { PaymentAsset } from "../../../../model/db/Payment";
import CurrencyConvertLabel from "../../../../modules/abstract-ui/forms/input/CurrencyConvertLabel";
import CommentsModule from "../../../../modules/comments-module/CommentsModule";
import { useConstants } from "../../../../redux/hooks";
import { RAConstants, RAInvoice } from "../RAInterfaces";
import { RADocumentsView } from "./Documents/RADocumentsView";
import "./RAInvoiceDetails.scss";
import RAInvoiceHistory from "./RAInvoiceHistory";
import { PaymentOverlayData } from "./RAInvoicePaymentLabel";

interface Props {
  invoice: RAInvoice;
  initialActive?: "comments" | "history" | "documents";
}

const RAInvoiceDetails = (props: Props) => {
  const [active, setActive] = useState<"comments" | "history" | "documents">(
    "comments"
  );

  useEffect(() => {
    if (props.initialActive) {
      setActive(props.initialActive);
    }
  }, [props.initialActive]);

  if (!props.invoice) {
    return null;
  }
  return (
    <div className="ra-invoice-details">
      <Nav appearance="subtle" activeKey={active} onSelect={setActive}>
        <Nav.Item eventKey="comments">
          {i18n.t("ra:InvoiceDetails.Tabs.Comment")} (
          {props.invoice.communication?.numComments || 0})
        </Nav.Item>
        <Nav.Item eventKey="history">
          {i18n.t("ra:InvoiceDetails.Tabs.History")}
        </Nav.Item>
        <Nav.Item eventKey="documents">
          {i18n.t("ra:InvoiceDetails.Tabs.Documents", "Dokumente")} (
          {(props.invoice?.data.attachments || []).length})
        </Nav.Item>
      </Nav>
      {active === "comments" && <RACommentsView {...props} />}
      {active === "history" && <RAHistoryView {...props} />}
      {active === "documents" && <RAPaymentsDocumentViewContainer {...props} />}
    </div>
  );
};
export default RAInvoiceDetails;

export const RACommentsView = (props: Props) => {
  return (
    <div className="comments-container">
      <CommentsModule
        assetId={props.invoice._id}
        assetType={AssetTypes.Invoice}
        identifier={`ra-invoice-details-comments`}
        disableEmailCommunication
        type={props.invoice.data.type}
      />
    </div>
  );
};

export const RAHistoryView = (props: Props) => {
  return (
    <div className="history-container">
      <RAInvoiceHistory
        entries={props.invoice?.data.history || []}
        asset={props.invoice}
      />
    </div>
  );
};

export const RAPaymentsView = (props: Props) => {
  const constants = useConstants<RAConstants>();
  return (
    <div className={`ra-payments-container`}>
      <ListComponent
        asPost
        dataUrl={`/api/asset/list/payment`}
        additionalMatchQuery={{
          type: "op",
          op: "eq",
          name: "data.linkedAsset.id",
          value: props.invoice?._id,
        }}
        identifier="ra-invoice-details-payments"
        render={(node: PaymentAsset) => (
          <div className={`payment-entry`}>
            <div className={`main-row`}>
              <div className={`pay-state`}>
                {node.data.payedInfo
                  ? `${i18n.t(
                      "ra:InvoiceDetails.Payments.PayedAt",
                      "Bezahlt am"
                    )} ${moment(node.data.payedInfo.date).format(
                      i18n.t("Formats.dateFormat")
                    )}`
                  : `${i18n.t(
                      "ra:InvoiceDetails.Payments.NotPayed",
                      "Nicht bezahlt"
                    )} - ${i18n.t(
                      "ra:InvoiceDetails.Payments.DueOn",
                      "Fällig bis"
                    )} ${moment(
                      (node.data.paymentTypeData as any)?.paymentDueDate
                    ).format(i18n.t("Formats.dateFormat"))}`}
              </div>
              <div className={`amount`}>
                <CurrencyConvertLabel
                  value={node.data.value.amount}
                  toCurrency={node.data.value.currency}
                />
              </div>
            </div>
            <div className={`field`}>{node.data.recipient}</div>
            <div className={`field`}>
              <PaymentOverlayData paymentData={node.data.paymentTypeData} />
            </div>
          </div>
        )}
      />
    </div>
  );
};

const RAPaymentsDocumentViewContainer = ({ invoice }: Props) => {
  return (
    <div className={`ra-payments-document-container`}>
      <RADocumentsView invoice={invoice} />
    </div>
  );
};
