import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import i18n from "../../../../i18n";
import GlobalActions from "../../../../services/GlobalActions";
import BfIcon from "../../icon/BfIcon";
import BFButton, { BFButtonProps } from "./BFButton";
// import "./BFDetailsButton.scss";

export type HrefData = {
  assetId: string;
  assetType: string;
  type?: string;
  params?: any;
};
type BFDetailsButtonProps = BFButtonProps & {
  data: HrefData;
} & any; // todo temproray type fix
const BFDetailsButton = (props: BFDetailsButtonProps) => {
  const location = useLocation();

  const [href, setHref] = useState<string>(null);

  useEffect(() => {
    const newHref = GlobalActions.linkDetails(
      props.data.assetType,
      props.data.assetId,
      props.data.type,
      props.data.params
    );
    setHref(newHref);
  }, [props.data, location.pathname]);

  const { data, ...buttonProps } = props;

  if (!href) {
    buttonProps.disabled = true;
    buttonProps.tooltip = {
      tooltip: i18n.t(
        "BFDetailsButtons.disabledNoAccess",
        "Sie haben kein Zugriff auf dieses Element."
      ),
    };
  } else {
    buttonProps.href = href;
  }

  return <BFButton {...buttonProps} />;
};

export default BFDetailsButton;

export const BFDetailsIconButton = (props: BFDetailsButtonProps) => {
  return (
    <BFDetailsButton appearance="link" {...props}>
      <BfIcon type="light" data="expand-6" size="xs" />
    </BFDetailsButton>
  );
};
