import CashBudgetBookingCard from "@/apps/tatar/cashBudget/components/CashBudgetBookingCard";
import { AssetCashBudgetEntry } from "@/apps/tatar/cashBudget/model/CashBudgetEntry";
import AssetDetailModal from "@/components/AssetDetailModal/AssetDetailModal";
import { openDetailsAction } from "@/modals/GlobalDetailsFunctions";
import { AssetTypes } from "@/model/AssetTypes";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

interface Props {}
const ROUTE_NAME = "__asset_booking";
const BookingDetailsModal = (props: Props) => {
  const history = useHistory();
  useEffect(() => {
    openDetailsAction(AssetTypes.CashBudget.Booking, ROUTE_NAME);
  }, []);
  return (
    <AssetDetailModal
      assetType={AssetTypes.CashBudget.Booking}
      routeName={ROUTE_NAME}
      size="md"
      className="booking-modal"
      render={(node: AssetCashBudgetEntry) => (
        <CashBudgetBookingCard booking={node} showCategory expanded={true} />
      )}
    />
  );
};

export default BookingDetailsModal;
