import classNames from "classnames";
import _ from "lodash";
import i18n from "../../../../../i18n";
import LabeledInput from "../../../../abstract-ui/forms/LabeledInput";
import {
  DocumentStoreAssetParams,
  DocumentStoreDocument,
} from "../../../../document-store/DSInterfaces";
import DSService from "../../../../document-store/DSService";
import DSListEntry from "../../../../document-store/components/DSListEntry/DSListEntry";
import DSUploadInfoPrefix from "../../../../document-store/components/DSUploadInfoPrefix/DSUploadInfoPrefix";
import "./RSDocumentsDetails.scss";

interface RSDocumentsDetailsProps {
  assetParams: DocumentStoreAssetParams;
  documentLinkToCdn: string;
}
const RSDocumentsDetails = (props: RSDocumentsDetailsProps) => {
  const documents =
    (_.property(props.assetParams.documentsFieldPath)(
      props.assetParams.asset
    ) as DocumentStoreDocument[]) || [];

  let document = undefined;
  if (Array.isArray(documents)) {
    document = documents.find((doc) => {
      return doc.linkToCdn === props.documentLinkToCdn;
    });
  }

  const renderDSListEntry = (document: DocumentStoreDocument) => {
    const iconName = DSService.getIconFromContentType(
      DSService.getContentTypeFromDocument(document, props.assetParams.asset)
    );

    const openDocument = (document: DocumentStoreDocument) => {
      const assetParams: DocumentStoreAssetParams = {
        assetType: props.assetParams.assetType,
        asset: props.assetParams.asset,
        type: props.assetParams.asset?.["data"]?.["type"],
        documentsFieldPath: "data.attachments",
      };

      DSService.openDocument(document, assetParams);
    };

    return (
      <div className="resubmission-detail-document">
        <LabeledInput
          type="sub"
          label={i18n.t("Resubmission.Title.Documents", "Dokument")}
        >
          <DSListEntry
            type="document"
            entryObject={document}
            iconName={iconName}
            name={document.name}
            onClick={() => openDocument(document)}
            postFixedElements={<DSUploadInfoPrefix document={document} />}
          />
        </LabeledInput>
      </div>
    );
  };

  return (
    <div className={classNames("rs-documents-details")}>
      {document ? (
        renderDSListEntry(document)
      ) : (
        <div className="rs-document-not-found">
          {i18n.t(
            "DocumentStore.DocumentNotFound",
            "Das Dokument konnte nicht gefunden werden."
          )}
        </div>
      )}
    </div>
  );
};

export default RSDocumentsDetails;
