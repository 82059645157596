import CashBudgetInitWrapper from "@/apps/tatar/cashBudget/CashBudgetInitWrapper";
import TatarCashBudgetTableComponent from "@/apps/tatar/cashBudget/views/tableview/TatarCashBudgetTableComponent";
import i18n from "@/i18n";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFMessage from "@/modules/abstract-ui/general/Message/BFMessage";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import DataBusDefaults from "@/services/DataBusDefaults";
import classNames from "classnames";
import { OAObject } from "../../../types/object.interface";
import "./ObjectDetailsLQPlan.scss";

interface CBPortfolioObjectDetailsLQPlanProps {
  asset: OAObject;
}
const ObjectDetailsLQPlan = (props: CBPortfolioObjectDetailsLQPlanProps) => {
  const account = OrgaStruct.getBankAccount(
    props.asset?.data?.bankInfo?.mainBankAccount
  );
  const allObjects =
    account && OrgaStruct.getAllObjectsByBankAccount(account._id);

  return (
    <div className={classNames(`object-details-lq-plan`)}>
      {(allObjects?.length || 1) > 1 && (
        <div className="info-container">
          <BFMessage
            type={"info"}
            text={
              <div className={`more-objects-info`}>
                <span>
                  {i18n.t(
                    "cb:Portfolio.Object.LQPlan.multipleObjectAffectingLqPlan",
                    "Der Liquiditätsplan beinhalten ebenfalls die Buchungen weiterer Objekte, da sie demselben Bankkonto zugewiesen sind:"
                  )}
                </span>
                {allObjects.map((e) => (
                  <BFButton
                    appearance="jump"
                    size="small"
                    onClick={() => {
                      DataBusDefaults.route({
                        route: `/objects/${e._id}`,
                        stayInApp: true,
                      });
                    }}
                  >
                    {e.displayName}
                  </BFButton>
                ))}
              </div>
            }
          />
        </div>
      )}
      <div className={`lq-plan-container`}>
        <CashBudgetInitWrapper businessUnits={[props.asset.data.type]}>
          <TatarCashBudgetTableComponent
            comparison={false}
            unitType={props.asset.data.type}
            displayType={"MONTHLY"}
            requestType="starmoney"
            paintCategories={false}
            selectedEntities={[props.asset?.data.entity]}
            selectedFilter={`object#${props.asset?._id}`}
            hideHeader
            {...({} as any)}
          />
        </CashBudgetInitWrapper>
      </div>
    </div>
  );
};

export default ObjectDetailsLQPlan;
