import { Field } from "react-final-form";
import FilePreviewComponent from "../../../../components/FilePreviewComponent/FilePreviewComponent";
import i18n from "../../../../i18n";
import BaseAsset from "../../../../model/general-assets/BaseAsset";
import BFInput from "../../../abstract-ui/forms/input/BFInput";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import "./DSUploadDialogEntry.scss";

type DsUploadDialogEntryProps = {
  name: string;
  asset: BaseAsset;
  onDelete: () => void;
};

const DSUploadDialogEntry = (props: DsUploadDialogEntryProps) => {
  return (
    <div className={"ds-upload-entry"}>
      <Field name={`${props.name}.file`}>
        {({ input: { value: file } }) => (
          <FilePreviewComponent
            type="file"
            file={file}
            width={120}
            height={120}
          />
        )}
      </Field>
      <div className={"form"}>
        <div className={"row-1"}>
          <div className={"field"}>
            <Field
              name={`${props.name}.name`}
              validate={(value) =>
                !value ? i18n.t("Global.Labels.required") : undefined
              }
            >
              {({ input, meta }) => (
                <BFInput
                  validation={
                    meta.error && meta.touched
                      ? {
                          level: "error",
                          message: meta.error,
                        }
                      : undefined
                  }
                  label={`${i18n.t(
                    "ds:DocumentStore.UploadDialog.fileName",
                    "Name"
                  )}*`}
                  {...input}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={"actions"}>
          <BFButton appearance="link" onClick={props.onDelete} size="xs">
            {i18n.t("Global.Buttons.remove")}
          </BFButton>
        </div>
      </div>
    </div>
  );
};

export default DSUploadDialogEntry;
