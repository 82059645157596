import { APActivity } from "@/apps/tatar/activityApp/ActivityInterfaces";
import { ActivityStructContext } from "@/apps/tatar/activityApp/ActivityStructContext";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import UserAvatar from "@/components/AvatarComponent/UserAvatar";
import Username from "@/components/AvatarComponent/Username";
import i18n from "@/i18n";
import BFStatus from "@/modules/abstract-ui/data/status/BFStatus";
import { ActivityAbstractStructClass } from "@/redux/actions/struct/implemented/ActivityAbstractStruct";
import LanguageService from "@/services/LanguageService";
import moment from "moment";
import { SystemComment, SystemMessages } from "../../Comments";

interface SystemCommentProps {
  comment: SystemComment;
}
export const SystemCommentEntry = (props: SystemCommentProps) => {
  return (
    <div className={`system-comment`}>
      <div className={`line`} />
      <div className={`comment-content`}>
        <div className={`comment-text`}>
          {(props.comment.data?.message ===
            SystemMessages.DAMAGECLAIM_OFFER_STARTED ||
            props.comment.data?.message ===
              SystemMessages.ACTIVITY_OFFER_STARTED) && (
            <div>
              {i18n.t(
                "CommentsModule.SystemMessages.ACTIVITY_OFFER_STARTED",
                "Aktivität in Genehmigung gestellt"
              )}
            </div>
          )}
          {(props.comment.data?.message ===
            SystemMessages.DAMAGECLAIM_ACTIVITY_CREATED ||
            props.comment.data?.message ===
              SystemMessages.ACTIVITY_CREATED) && (
            <div>
              {i18n.t(
                "CommentsModule.SystemMessages.ACTIVITY_CREATED",
                "Aktivität wurde erstellt"
              )}
            </div>
          )}
          {(props.comment.data?.message ===
            SystemMessages.DAMAGECLAIM_STATUS_CHANGE ||
            props.comment.data?.message ===
              SystemMessages.ACTIVITY_STATUS_CHANGE) && (
            <ActivityStructContext.Consumer>
              {(activityStruct: ActivityAbstractStructClass<any>) => (
                <AssetLoader
                  assetType={props.comment.data?.linkedAsset?.type}
                  id={props.comment.data?.linkedAsset.id}
                  render={(asset: APActivity) => {
                    let label = "";
                    let color = "";
                    if (
                      typeof props.comment.data?.typeData.extra === "string"
                    ) {
                      const statusEntry = activityStruct.getStatus(
                        asset.data?.type,
                        props.comment.data?.typeData.extra
                      );

                      label = LanguageService.translateLabel(
                        statusEntry?.displayName
                      );
                      color = statusEntry?.color;
                    } else {
                      const statusEntry = activityStruct.getStatus(
                        asset.data?.type,
                        props.comment.data?.typeData.extra?.newStatus
                      );
                      label = LanguageService.translateLabel(
                        statusEntry?.displayName
                      );
                      color = statusEntry?.color;
                      const subStatus = props.comment.data?.typeData.extra
                        ?.newSubStatus
                        ? statusEntry?.subStatus?.find(
                            (e) =>
                              e.id ===
                              props.comment.data?.typeData.extra?.newSubStatus
                          )
                        : null;
                      if (subStatus) {
                        label = `${label} - ${LanguageService.translateLabel(
                          subStatus?.displayName
                        )}`;
                      }
                    }

                    return (
                      <div className={`state-change`}>
                        <div className={`label`}>
                          {i18n.t(
                            "CommentsModule.SystemMessages.ACTIVITY_STATUS_CHANGE",
                            "Status wurde geändert zu"
                          )}
                        </div>
                        <BFStatus label={label} color={color} />
                      </div>
                    );
                  }}
                />
              )}
            </ActivityStructContext.Consumer>
          )}
        </div>
        <div className={`meta`}>
          {props.comment.data?.typeData.userId ? (
            <div className={`user`}>
              <UserAvatar id={props.comment.data?.typeData.userId} size={16} />
              <Username id={props.comment.data?.typeData.userId} />
            </div>
          ) : (
            <div className={`system`}>
              {i18n.t("Labels.changedBySystem", "Vom System geändert")}
            </div>
          )}
          <div className={`timestamp`}>
            {moment(props.comment.data?.date).format(
              i18n.t("Formats.dateTimeFormat")
            )}
          </div>
        </div>
      </div>
      <div className={`line`} />
    </div>
  );
};
