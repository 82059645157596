import i18n from "@/i18n";
import moment from "moment";

class DateFieldUtilsClass {
  getFormat(
    format:
      | "date"
      | "time"
      | "datetime"
      | "dateReadable"
      | "month"
      | string = "date"
  ) {
    let useFormat = format;
    if (format === "date") {
      useFormat = i18n.t("Formats.dateFormat");
    }
    if (format === "time") {
      useFormat = i18n.t("Formats.timeFormat");
    }
    if (format === "datetime") {
      useFormat = i18n.t("Formats.dateTimeFormat");
    }
    if (format === "dateReadable") {
      useFormat = i18n.t("Formats.dateFormatReadable");
    }
    if (format === "month") {
      useFormat = i18n.t("Formats.monthFormat");
    }

    return useFormat;
  }
  getDateOfString(datestring: string) {
    let date = null;
    if (datestring) {
      date = moment(datestring, this.getFormat());
      if (date.isValid()) {
        return date.utc(true).toDate();
      }
      date = moment(datestring);
      if (date.isValid()) {
        return date.utc(true).toDate();
      }
    }
    return null;
  }
}
const DateFieldUtils = new DateFieldUtilsClass();
export default DateFieldUtils;
