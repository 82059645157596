import { AccountingBookingGroup } from "@/apps/tatar/accounting/interfaces/account.interface";
import { AssetCashBudgetAttachment } from "@/apps/tatar/cashBudget/model/CashBudgetEntry";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import Userlabel from "@/components/AvatarComponent/Userlabel";
import WrappedText from "@/components/WrappedText/WrappedText";
import { AssetTypes } from "@/model/AssetTypes";
import { ActivityAsset } from "@/model/general-assets/BaseAsset";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFOverlay from "@/modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { openOrDownloadDocument } from "@/redux/actions/ui-config/ui-config-actions";
import CDNService from "@/services/CDNService";
import GlobalActions from "@/services/GlobalActions";
import FileUtils from "@/utils/FileUtils";
import { hasValue } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import "./CBRentalAgreementPositionInfo.scss";

interface CBRentalAgreementPositionInfoProps {
  pos: AccountingBookingGroup;
}
const CBRentalAgreementPositionInfo = (
  props: CBRentalAgreementPositionInfoProps
) => {
  const dispatch = useDispatch();
  const hasNote = hasValue(props.pos.data.note?.text);
  const attachments = (props.pos.data.linkedAsset || []).filter(
    (e) => e.assetType === AssetTypes.CashBudget.Attachment
  );
  const hasAttachment = hasValue(attachments);
  const activities = (props.pos.data.linkedAsset || []).filter(
    (e) => e.assetType !== AssetTypes.CashBudget.Attachment
  );
  const hasActivitiy = hasValue(activities);
  return (
    <div className={classNames(`cb-rental-agreement-position-info`)}>
      <div className={`slot`}>
        {hasNote && (
          <BFOverlay
            enterable
            speaker={
              <div className={`cb-rental-agreement-position-info__note`}>
                <div className={`head`}>
                  <div className={`user-container`}>
                    <Userlabel id={props.pos.data.note.userId} />
                  </div>
                  <div className={`date`}>
                    {StringUtils.formatDate(props.pos.data.note.date)}
                  </div>
                </div>
                <div className={`content`}>
                  <WrappedText text={props.pos.data.note.text} />
                </div>
              </div>
            }
          >
            <div>
              <BFButton noPadding appearance="link" size="xxs">
                <BfIcon type="light" data={"messages-bubble"} size="xs" />
              </BFButton>
            </div>
          </BFOverlay>
        )}
      </div>
      <div className={`slot`}>
        {hasAttachment && (
          <BFOverlay
            enterable
            speaker={
              <div className={`cb-rental-agreement-position-info__attachments`}>
                {attachments.map((attachment) => (
                  <AssetLoader
                    assetType={attachment.assetType}
                    id={attachment.assetId}
                    render={(asset: AssetCashBudgetAttachment) => {
                      const [cdnId, data] = Object.entries(
                        asset?.data.files
                      )[0];

                      return (
                        <BFButton
                          className={`link-button`}
                          noPadding
                          appearance="link"
                          onClick={async () => {
                            const url = await CDNService.fetchCDNLink({
                              assetType: attachment.assetType,
                              assetId:
                                attachment.assetId || (attachment as any).value,
                              assetField: "data.files",
                              cdnId: cdnId,
                              hasFolderReadPermissions: true,
                              fileKey: data.key,
                            });
                            dispatch(
                              openOrDownloadDocument(
                                url,
                                FileUtils.mimeToExt(data.content_type)
                              )
                            );
                          }}
                        >
                          {data.filename}
                        </BFButton>
                      );
                    }}
                  />
                ))}
              </div>
            }
          >
            <div>
              <BFButton noPadding appearance="link" size="xxs">
                <BfIcon type="light" data={"attachment"} size="xs" />
              </BFButton>
            </div>
          </BFOverlay>
        )}
      </div>
      <div className={`slot`}>
        {hasActivitiy && (
          <BFOverlay
            enterable
            speaker={
              <div className={`cb-rental-agreement-position-info__activities`}>
                {activities.map((activity) => (
                  <AssetLoader
                    assetType={activity.assetType}
                    id={activity.assetId}
                    render={(asset: ActivityAsset) => {
                      return (
                        <BFButton
                          className={`link-button`}
                          noPadding
                          appearance="link"
                          onClick={async () => {
                            GlobalActions.openDetails(
                              activity.assetType,
                              activity.assetId,
                              asset?.data.type
                            );
                          }}
                        >
                          {asset?.data.activityId} - {asset?.data.displayName}
                        </BFButton>
                      );
                    }}
                  />
                ))}
              </div>
            }
          >
            <div>
              <BFButton noPadding appearance="link" size="xxs">
                <BfIcon type="light" data={"hyperlink-2"} size="xs" />
              </BFButton>
            </div>
          </BFOverlay>
        )}
      </div>
    </div>
  );
};

export default CBRentalAgreementPositionInfo;
