import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import { DocumentStoreDocument } from "../../../../../../../modules/document-store/DSInterfaces";
import DocumentStoreRouted from "../../../../../../../modules/document-store/DocumentStoreRouted";
import { RSLinkedAssetFieldIdentifier } from "../../../../../../../modules/resubmission/RSConstants";
import { RSAssetBaseParams } from "../../../../../../../modules/resubmission/RSInterfaces";
import useOpenResubmissionForm from "../../../../../../../modules/resubmission/hooks/useOpenResubmissionForm";
import { CB_RENTALAGREEMENT_DOCUMENTS_FIELD_PATH } from "../../../../CashBudgetUtils";
import { RentalAgreement } from "../../TenantsInterfaces";

interface CBRentalAgreementDocumentsViewProps {
  rentalAgreement: RentalAgreement;
}
const CBRentalAgreementDocumentsView = (
  props: CBRentalAgreementDocumentsViewProps
) => {
  const { openResubmissionBtnIconProps, openResubmissionDialog } =
    useOpenResubmissionForm();

  return (
    <div>
      <DocumentStoreRouted
        assetParams={{
          asset: props.rentalAgreement,
          assetType: AssetTypes.Rental.RentalAgreement,
          type: props.rentalAgreement.data.type,
          documentsFieldPath: "data.attachments",
        }}
        documentEntryActions={[
          {
            type: "button",
            text: i18n.t(
              "Resubmission.Labels.Create",
              "Wiedervorlage erstellen"
            ),
            onSelect: (document: DocumentStoreDocument) => {
              const type = props.rentalAgreement.data.type;

              const assetParams: RSAssetBaseParams = {
                assetType: AssetTypes.Rental.RentalAgreement,
                assetId: props.rentalAgreement._id,
                assetDescription: `${i18n.t(
                  "AssetTypes.CB.Labels.RentalAgreement",
                  "Mietvertrag"
                )} - ${i18n.t("DocumentStore.Labels.Document", "Dokument:")} ${
                  document.name
                }`,
                assetField: {
                  identifier: RSLinkedAssetFieldIdentifier.DSDocument,
                  fieldPath: CB_RENTALAGREEMENT_DOCUMENTS_FIELD_PATH,
                  id: document.linkToCdn,
                },
              };

              openResubmissionDialog({
                linkedAsset: assetParams,
                typePermissions: [type],
              });
            },
            icon: openResubmissionBtnIconProps,
          },
        ]}
      />
    </div>
  );
};

export default CBRentalAgreementDocumentsView;
