import { AssetTypes } from "@/model/AssetTypes";
import GlobalActions from "@/services/GlobalActions";
import { useDispatch } from "react-redux";
import { Drawer } from "rsuite";
import { useTypedSelector } from "../../redux/hooks";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { setUiConfig } from "../../services/ApplicationService";
import "./ContactDrawer.scss";
import ContactSearch from "./contact-search/ContactSearch";

const TABLE_IDENTIFIER = "CONTACTS_GLOBAL_LISt";
interface ContactDrawerProps {}
const ContactDrawer = (props: ContactDrawerProps) => {
  const dispatch = useDispatch();
  const open = useTypedSelector(
    (state) => state.uiConfig.general[DefaultUIConfigs.OPEN_CONTACT_DRAWER]
  );

  const onClose = () => {
    dispatch(setUiConfig(DefaultUIConfigs.OPEN_CONTACT_DRAWER, false));
  };
  return (
    <Drawer
      backdropClassName={"contact-drawer-backdrop"}
      placement={"right"}
      size={"sm"}
      open={open}
      onClose={onClose}
    >
      <ContactSearch
        identifier={TABLE_IDENTIFIER}
        onResultItemClick={(contact) => {
          GlobalActions.openDetails(AssetTypes.Contact, contact._id);
        }}
      />
    </Drawer>
  );
};

export default ContactDrawer;
