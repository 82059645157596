import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import Tools from "../utils/Tools";
import application from "./reducers/application/Application";
import { ApplicationReducer } from "./reducers/application/ApplicationInterface";
import customization from "./reducers/customization/Customization";
import { CustomizationReducer } from "./reducers/customization/CustomizationInterface";
import global from "./reducers/global/Global";
import { GlobalReducer } from "./reducers/global/GlobalInterface";
import notifications from "./reducers/notifications/Notifications";
import { NotificationsReducer } from "./reducers/notifications/NotificationsInterface";
import struct from "./reducers/struct/Struct";
import { StructReducer } from "./reducers/struct/StructInterface";
import uiConfig from "./reducers/ui-config/UiConfig";
import { UiConfigReducer } from "./reducers/ui-config/UiConfigInterface";
export interface StoreInterface {
  application: ApplicationReducer;
  uiConfig: UiConfigReducer;
  global: GlobalReducer;
  notifications: NotificationsReducer;
  customizations: CustomizationReducer;
  struct: StructReducer;
}

const rootReducer = (state: AppState, action) => {
  const newState = {} as AppState;

  newState["global"] = global(state ? state.global : undefined, action, state);
  newState["uiConfig"] = uiConfig(
    state ? state.uiConfig : undefined,
    action,
    state
  );
  newState["application"] = application(
    state ? state.application : undefined,
    action,
    state
  );

  newState["notifications"] = notifications(
    state ? state.notifications : undefined,
    action,
    state
  );
  newState["customizations"] = customization(
    state ? state.customizations : undefined,
    action,
    state
  );
  newState["struct"] = struct(state ? state.struct : undefined, action, state);
  return newState;
};

// combineReducers({
//     global: global,
//     uiConfig: uiConfig,
//     application: application
// })

export type AppState = StoreInterface;

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunk))
);
Tools.registerWindowObject("store", store);
