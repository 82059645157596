import { Field } from "react-final-form";
import { Rate } from "rsuite";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import BFInput from "../../../../../../modules/abstract-ui/forms/input/BFInput";
import EZAssignment from "../../../../../../modules/ez-form/form-elements/ez-assignment/EZAssignment";
import SubmitService from "../../../../../../services/SubmitService";
import StringUtils from "../../../../../../utils/StringUtils";
import { useActivityConstants } from "../../../ActivityHooks";
import { APActivity, APActivityRating } from "../../../ActivityInterfaces";
import "./APRatingEditDialog.scss";

interface Props {
  rating?: APActivityRating;
  activity: APActivity;

  onClose: () => void;
  onSuccess: (data: APActivityRating) => void;
  onError?: (error: any) => void;
}

const validate = (values: any) => {
  const errors = {};

  if (!values.rating) {
    errors["rating"] = i18n.t(
      "apTemplate:Rating.Error.RatingRequired",
      "Bitte geben Sie eine Bewertung an."
    );
  }
  return errors;
};
const APRatingEditDialog = (props: Props) => {
  const constants = useActivityConstants();
  return (
    <FormStruct
      className={"ap-rating-edit-dialog"}
      initialValues={{
        rating: props.rating?.data.rating,
        comment: props.rating?.data.comment,
        potentialSavings: props.rating?.data.potentialSavings,
        ratedAssignee:
          props.rating?.data.ratedAssignee ||
          props.activity.data.assignee ||
          null,
      }}
      title={
        props.rating
          ? i18n.t("apTemplate:Rating.Edit.Title", "Bewertung bearbeiten")
          : i18n.t("apTemplate:Rating.Create.Title", "Bewertung erstellen")
      }
      description={i18n.t(
        "apTemplate:Rating.Description",
        "Bitte füllen Sie die Bewertung für die Aktivität aus. Bitte beachten Sie, dass der korrekte Bearbeiter ausgewählt ist."
      )}
      submitText={
        props.rating
          ? i18n.t("Global.Buttons.save")
          : i18n.t("Global.Buttons.create")
      }
      onAbort={props.onClose}
      onSubmit={async (values) => {
        try {
          const result = (await SubmitService.submitDataAsync({
            type: "asset",
            assetType: AssetTypes.Activity.Rating,
            data: {
              _id: props.rating?._id,
              data: {
                ...values,
                linkedAsset: {
                  assetType: constants?.assetType,
                  assetId: props.activity._id,
                },
              },
            },
            ignorePropChecks: true,
            ignoreSubmitValidation: true,
          })) as APActivityRating;
          props.onSuccess(result);
          props.onClose();
        } catch (err) {
          props.onError?.(err);
        }
      }}
      validate={validate}
      render={({ form }) => (
        <>
          <div className={`__field`}>
            <Field name="rating">
              {({ input, meta }) => (
                <>
                  <Rate {...input} />
                  {meta.error && meta.touched && (
                    <div className={`error`}>{meta.error}</div>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="comment">
              {({ input, meta }) => (
                <BFInput
                  type="textarea"
                  {...input}
                  label={`${i18n.t("apTemplate:Rating.Comment", "Kommentar")}`}
                  validation={
                    meta.error && meta.touched
                      ? {
                          level: "error",
                          message: meta.error,
                        }
                      : undefined
                  }
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="potentialSavings">
              {({ input, meta }) => (
                <BFInput
                  type="priceInput"
                  {...input}
                  allowNull
                  label={`${i18n.t(
                    "apTemplate:Rating.PotentialSavings",
                    "Mögliche Einsparung"
                  )}`}
                  prefix={StringUtils.getCurrencySymbol()}
                  validation={
                    meta.error && meta.touched
                      ? {
                          level: "error",
                          message: meta.error,
                        }
                      : undefined
                  }
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <div>{i18n.t("apTemplate:Rating.Assignee", "Bearbeiter")}</div>
            <Field name="ratedAssignee">
              {({ input, meta }) => (
                <EZAssignment
                  {...input}
                  identifier="ap-rating-edit-dialog-assignee"
                  type="user"
                />
              )}
            </Field>
          </div>
        </>
      )}
    />
  );
};

export default APRatingEditDialog;
