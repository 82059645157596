import _ from "lodash";
import moment from "moment";
import { APProjectBudget } from "../APProjectBudgetInterfaces";

type ProjectBudgetDataEntry = {
  date: { month: number; year: number };
  budget: {
    [categoryId: string]: number;
  };
  budgetAll: number;
  budgetAccumulated: {
    [categoryId: string]: number;
  };
  budgetAccumulatedAll: number;
  actual: {
    [categoryId: string]: number;
  };
  actualAll: number;
  actualAccumulated: {
    [categoryId: string]: number;
  };
  actualAccumulatedAll: number;
};
export const calculateProjectBudgetData = (
  budget: APProjectBudget,
  actual: {
    _id: { month: number; year: number; categoryId: string };
    value: number;
  }[]
) => {
  const data: ProjectBudgetDataEntry[] = [];

  const dates = [
    moment(budget.data.fromDate).toDate(),
    ...budget.data.entries.map((e) => moment(e.date).toDate()),
    ...actual.map((e) =>
      moment(`${e._id.year}-${e._id.month}`).utc(true).toDate()
    ),
  ];
  const minDate = _.min(dates);
  const maxDate = _.max(dates);

  let currentDate = moment(minDate).utc(true).startOf("month");
  const endDate = moment(maxDate).utc(true).endOf("month");

  const groups = budget.data.groups
    .map((group) => group.children.map((e) => ({ group: group.id, ...e })))
    .flat();

  let index = 0;

  while (currentDate.isSameOrBefore(endDate)) {
    const resultEntry: ProjectBudgetDataEntry = {
      date: {
        month: currentDate.month() + 1,
        year: currentDate.year(),
      },
      budget: {},
      budgetAll: 0,
      actual: {},
      actualAll: 0,
      actualAccumulated: {
        ...(index > 0 ? data[index - 1].actualAccumulated : {}),
      },
      actualAccumulatedAll:
        index > 0 ? data[index - 1].actualAccumulatedAll : 0,
      budgetAccumulated: {
        ...(index > 0 ? data[index - 1].budgetAccumulated : {}),
      },
      budgetAccumulatedAll:
        index > 0 ? data[index - 1].budgetAccumulatedAll : 0,
    };

    const budgetEntry = budget.data.entries.find((e) =>
      moment(e.date).utc(true).isSame(currentDate, "month")
    );
    if (budgetEntry) {
      budgetEntry.values.forEach((value) => {
        const entryGroup = groups.find((e) => e.id === value.groupId)?.group;

        if (entryGroup) {
          resultEntry.budget[value.groupId] = value.value;
          resultEntry.budget[entryGroup] = value.value;
          resultEntry.budgetAccumulated[value.groupId] =
            (resultEntry.budgetAccumulated[value.groupId] || 0) + value.value;

          resultEntry.budgetAccumulated[entryGroup] =
            (resultEntry.budgetAccumulated[entryGroup] || 0) + value.value;
          resultEntry.budgetAccumulatedAll += value.value;
          resultEntry.budgetAll += value.value;
        }
      });
    }

    const actualEntries = actual.filter((e) =>
      moment()
        .year(e._id.year)
        .month(e._id.month)
        .utc(true)
        .isSame(currentDate, "month")
    );
    if (actualEntries.length > 0) {
      actualEntries.forEach((entry) => {
        const entryGroup = groups.find(
          (e) => e.id === entry._id.categoryId
        )?.group;
        if (entryGroup) {
          resultEntry.actual[entry._id.categoryId] = entry.value;
          resultEntry.actual[entryGroup] = entry.value;
          resultEntry.actualAll += entry.value;

          resultEntry.actualAccumulated[entry._id.categoryId] =
            (resultEntry.actualAccumulated[entry._id.categoryId] || 0) +
            entry.value;
          resultEntry.actualAccumulated[entryGroup] =
            (resultEntry.actualAccumulated[entryGroup] || 0) + entry.value;
          resultEntry.actualAccumulatedAll =
            (resultEntry.actualAccumulatedAll || 0) + entry.value;
        }
      });
    }

    data.push(resultEntry);
    currentDate = currentDate.add(1, "month");
    index++;
  }

  return data;
};
