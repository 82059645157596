import ImageUtils from "@/utils/ImageUtils";
import axios from "axios";
import { nanoid } from "nanoid";
import Log from "../debug/Log";
import BaseAsset from "../model/general-assets/BaseAsset";
import { DocumentStoreFileMetaData } from "../modules/document-store/DSInterfaces";
import { store } from "../redux/store";
import { DataBusSubKeys } from "./../utils/Constants";
import { HTTP } from "./../utils/Http";
import CacheService from "./CacheService";
import DataBus from "./DataBus";
import SocketService from "./socket/SocketService";

const WAIT_FOR_SOCKET_MSG = 1000 * 10;

export interface CDNBucketObject {
  bucket: string;
  content_type: string;
  field: string;
  filename: string;
  key: string;
  size: number;
  uploader: string;
}

export interface CDNGenerateDownloadLink {
  assetType: string;
  assetId: string;
  assetField: string;
  cdnId?: string;
  fileKey: string;
  hasFolderReadPermissions: boolean;
  cancelObj?: {
    cancel: () => void;
  };
}

export interface CDNUploadAssetFile {
  tempID: string;
  assetType: string;
  assetId?: string;
  assetField: string;
  filename: string;
  file: Blob;
  cancelObj?: {
    cancel: () => void;
  };
  onProgress?: (progress: number) => void;
}
interface GenerateUploadLinkResponse {
  url: string;
  fields: {
    [key: string]: string;
  };
}
export interface CDNUploadProgressEvent {
  tempID: string;
  progress: number;
}
export interface CDNUploadSuccessEvent {
  tempID: string;
  cdnID: string;
}
export interface CDNUploadFailedEvent {
  tempID: string;
  cdnID?: string;
  reason: any;
}
class CDNServiceClass {
  folderPermissions: {
    AssetType: string;
    AsssetField: string;
    url?: string;
    loading: boolean;
    waiting?: {
      resolve: (data: any) => void;
      reject: () => void;
      fileKey: string;
    }[];
  }[] = [];
  currentWaiting: {
    [id: string]: {
      timeoutId: NodeJS.Timeout;
      resolveFc: (val: any) => void;
    };
  } = {};

  constructor() {
    SocketService.subscribe("CDNUploadFinish", (data) => {
      Log.info("CDNUploadFinish", data);

      if (data.obj && data.obj.id) {
        const entry = this.currentWaiting[data.obj.id];
        if (entry) {
          entry.resolveFc(null);
          clearTimeout(entry.timeoutId);
        }
      }
    });
  }

  init() {
    // this.sub
  }

  private waitForAsyncCDNPostTasks(cdnId: string) {
    return new Promise((resolve, reject) => {
      const waitForSocketMsg = setTimeout(() => {
        delete this.currentWaiting[cdnId];
        let tries = 0;
        const intervalId = setInterval(() => {
          HTTP.get({
            url: `pollFinishUpload/${cdnId}`,
            target: "CDN",
            withCredentials: true,
          })
            .then(() => {
              clearInterval(intervalId);
              resolve(null);
            })
            .catch(() => {
              if (++tries > 20) {
                clearInterval(intervalId);
                reject();
              }
            });
        }, 300);
      }, WAIT_FOR_SOCKET_MSG);
      this.currentWaiting[cdnId] = {
        resolveFc: resolve,
        timeoutId: waitForSocketMsg,
      };
    });
  }

  linkFile(
    assetType: string,
    assetId: string,
    cdnId: string,
    meta: {
      path: string;
      type: "array";
      value?: any;
    }
  ) {
    return new Promise((resolve, reject) => {
      HTTP.post({
        url: "linkCDN",
        target: "CDN",
        bodyParams: {
          assetType,
          assetId,
          cdnId,
          meta,
        },
        withCredentials: true,
      })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  uploadFile(data: CDNUploadAssetFile) {
    return new Promise<{
      tempID: string;
      cdnID: string;
    }>(async (resolve, reject) => {
      if (ImageUtils.checkConvertImageFile(data.file, data.filename)) {
        const { file, fileName } = await ImageUtils.convertImage(
          data.file,
          data.filename
        );
        data.file = file;
        data.filename = fileName;
      }

      DataBus.emit(DataBusSubKeys.CDN_UPLOAD_FILE_STARTED, data);
      HTTP.post({
        url: "generateUpload",
        target: "CDN",
        bodyParams: {
          AssetType: data.assetType,
          AssetField: data.assetField.replace(/\|/g, "."),
          Filename: data.filename,
        },
        withCredentials: true,
        cancelToken: data.cancelObj
          ? new axios.CancelToken((cancel) => (data.cancelObj.cancel = cancel))
          : undefined,
      })
        .then((linkReq: GenerateUploadLinkResponse) => {
          var bodyFormData = new FormData();

          Object.entries(linkReq.fields).forEach(([key, value]) => {
            bodyFormData.append(key, value);
          });
          bodyFormData.append("file", data.file);

          const cdnID = linkReq.fields["x-amz-meta-id"];

          HTTP.post({
            overwriteUrl: true,
            url: linkReq.url,
            headers: { "Content-Type": "multipart/form-data" },
            bodyParams: bodyFormData,
            withCredentials: false,
            cancelToken: data.cancelObj
              ? new axios.CancelToken(
                  (cancel) => (data.cancelObj.cancel = cancel)
                )
              : undefined,
            onUploadProgress: (progressEvent) => {
              // var progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              var progress = progressEvent.loaded / progressEvent.total;
              if (data.onProgress) {
                data.onProgress(progress);
              }
              DataBus.emit(DataBusSubKeys.CDN_UPLOAD_FILE_PROGRESS, {
                tempID: data.tempID,
                cdnID: cdnID,
                progress: progress,
              } as CDNUploadProgressEvent);
            },
          })
            .then((resp) => {
              HTTP.post({
                target: "CDN",
                url: "finishUpload",
                bodyParams: {
                  BucketName: linkReq.fields["bucket"],
                  BucketKey: linkReq.fields["key"],
                },
              })
                .then((resp2) => {
                  DataBus.emit(DataBusSubKeys.CDN_UPLOAD_FILE_SUCCESS, {
                    tempID: data.tempID,
                    cdnID,
                  } as CDNUploadSuccessEvent);
                  resolve({
                    tempID: data.tempID,
                    cdnID: cdnID,
                  });
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              Log.error("###CDN uploading file", err);

              DataBus.emit(DataBusSubKeys.CDN_UPLOAD_FILE_FAILED, {
                tempID: data.tempID,
                cdnID,
                reason: err,
              } as CDNUploadFailedEvent);
              reject(err);
            });
        })
        .catch((err) => {
          Log.error("###CDN generating cdn upload link", err);
          DataBus.emit(DataBusSubKeys.CDN_UPLOAD_FILE_FAILED, {
            tempID: data.tempID,
            reason: err,
          } as CDNUploadFailedEvent);
          reject(err);
        });
    });
  }

  fetchCDNLink(data: CDNGenerateDownloadLink) {
    return new Promise<string>((resolve, reject) => {
      if (data.hasFolderReadPermissions) {
        const assetField = data.assetField.replace(/\|/g, ".");

        const folderPermissionEntry = this.folderPermissions.find(
          (entry) =>
            entry.AsssetField === assetField &&
            entry.AssetType === data.assetType
        );

        if (folderPermissionEntry) {
          if (folderPermissionEntry.loading) {
            folderPermissionEntry.waiting.push({
              resolve,
              reject,
              fileKey: data.fileKey,
            });
          } else {
            const splitted = data.fileKey.split("/");
            resolve(
              folderPermissionEntry.url.replace(
                "*",
                splitted[splitted.length - 1]
              )
            );
          }
        } else {
          const permission: any = {
            AssetType: data.assetType,
            AsssetField: assetField,
            // url: response.url,
            loading: true,
            waiting: [
              {
                resolve,
                reject,
                fileKey: data.fileKey,
              },
            ],
          };
          this.folderPermissions.push(permission);

          HTTP.post({
            url: "generateDownloadField",
            target: "CDN",
            bodyParams: {
              AssetType: data.assetType,
              AssetField: assetField,
            },
            withCredentials: true,
            cancelToken: data.cancelObj
              ? new axios.CancelToken(
                  (cancel) => (data.cancelObj.cancel = cancel)
                )
              : undefined,
          })
            .then((response: { url: string }) => {
              Log.debug(
                "###CDN success generating cdn folder link",
                response.url
              );
              permission.loading = false;
              permission.waiting.forEach((waiting) => {
                const splitted = waiting.fileKey.split("/");
                waiting.resolve(
                  response.url.replace("*", splitted[splitted.length - 1])
                );
              });
              permission.url = response.url;
              permission.waiting = [];
            })
            .catch((err) => {
              Log.error("###CDN failed generating cdn folder link", err);

              permission.loading = false;
              permission.waiting.forEach((waiting) => {
                waiting.reject(err);
              });
              permission.waiting = [];
              this.folderPermissions = this.folderPermissions.filter(
                (e) => e !== permission
              );
            });
        }
      } else {
        HTTP.post({
          url: "generateDownload",
          target: "CDN",
          bodyParams: {
            AssetType: data.assetType,
            AssetID: data.assetId,
            AssetFieldWithKey:
              data.assetField.replace(/\|/g, ".") + "." + data.cdnId,
          },
          withCredentials: true,
          cancelToken: data.cancelObj
            ? new axios.CancelToken(
                (cancel) => (data.cancelObj.cancel = cancel)
              )
            : undefined,
        })
          .then((data: { url: string }) => {
            Log.debug("###CDN success generating cdn download link", data.url);
            resolve(data.url);
          })
          .catch((err) => {
            Log.error("###CDN failed generating cdn download link", err);
            reject(err);
          });
      }
    });
  }

  /**
   * Upload function for the AttachmentModule
   *
   * This function is used to upload general attachments for any asset type.
   *
   * @param assetId
   * @param data
   * @param assetType
   * @param assetField
   * @returns
   */
  async uploadAttachment(
    assetId: string,
    data: DocumentStoreFileMetaData & { [key: string]: any },
    assetType: string,
    assetField: string,
    useTempId?: string,
    onProgress?: (progress: number) => void,
    returnCdnIdToo = false
  ) {
    const me = store.getState().global.user._id;
    const { file, ...attachmentData } = data;
    const tempId = useTempId || nanoid();
    const cdnData = (await CDNService.uploadFile({
      file: file,
      filename: file.name,
      assetType,
      assetField,
      assetId,
      tempID: tempId,
      onProgress: onProgress,
    })) as { cdnID: string };

    const output = (await CDNService.linkFile(
      assetType,
      assetId,
      cdnData.cdnID,
      {
        path: assetField,
        type: "array",
        value: {
          ...attachmentData,
          date: new Date().toISOString(),
          uploadedBy: me,
        },
      }
    )) as BaseAsset;
    CacheService.updateDataInCaches(output._id, output);
    if (returnCdnIdToo) {
      return {
        asset: output,
        cdnID: cdnData.cdnID,
        entryId: output.cdn?.find((e) => e.id === cdnData.cdnID)?._id,
      };
    } else {
      return output;
    }
  }

  /**
   * Upload function for the AttachmentModule
   *
   * This function is used to upload general attachments for any asset type.
   *
   * @param assetId
   * @param data
   * @param assetType
   * @param assetField
   * @returns
   */
  async uploadAttachmentBeforeAssetCreation(
    data: DocumentStoreFileMetaData & { [key: string]: any },
    assetType: string,
    assetField: string,
    useTempId?: string,
    onProgress?: (progress: number) => void,
    returnCdnIdToo = false
  ) {
    const me = store.getState().global.user._id;
    const { file, ...attachmentData } = data;
    const tempId = useTempId || nanoid();
    const cdnData = (await CDNService.uploadFile({
      file: file,
      filename: file.name,
      assetType,
      assetField,
      // assetId,
      tempID: tempId,
      onProgress: onProgress,
    })) as { cdnID: string };

    const link = async (assetId: string) => {
      const output = (await CDNService.linkFile(
        assetType,
        assetId,
        cdnData.cdnID,
        {
          path: assetField,
          type: "array",
          value: {
            ...attachmentData,
            date: new Date().toISOString(),
            uploadedBy: me,
          },
        }
      )) as BaseAsset;
      CacheService.updateDataInCaches(output._id, output);
      if (returnCdnIdToo) {
        return {
          asset: output,
          cdnID: cdnData.cdnID,
          entryId: output.cdn?.find((e) => e.id === cdnData.cdnID)?._id,
        };
      } else {
        return output;
      }
    };

    return { link, cdnID: cdnData.cdnID };
  }

  async updateDocument(
    assetType: string,
    assetId: string,
    cdnId: string,
    fieldPath: string,
    data: any
  ) {
    const result = await HTTP.post({
      url: "updateCDN",
      target: "CDN",
      bodyParams: {
        assetType: assetType,
        assetId: assetId,
        cdnId: cdnId,
        meta: {
          path: fieldPath,
          type: "array",
          value: data,
        },
      },
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }
  async deleteDocument(
    assetType: string,
    assetId: string,
    cdnId: string,
    fieldPath: string,
    data: any
  ) {
    return await CDNService.updateDocument(
      assetType,
      assetId,
      cdnId,
      fieldPath,
      {
        ...data,
        status: "archived",
      }
    );
  }
}

const CDNService = new CDNServiceClass();

export default CDNService;
