import flatten from "flat";
import _ from "lodash";
import React, { CSSProperties } from "react";
import { FieldInputProps } from "react-final-form";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import DebugDataComponent from "../../../debug/DebugDataComponent";
import Log from "../../../debug/Log";
import { Application } from "../../../model/db/Application";
import { AssetType } from "../../../model/db/AssetType";
import { Permissions } from "../../../model/db/Permission";
import { setUiConfig } from "../../../redux/actions/ui-config/ui-config-actions";
import { AppState } from "../../../redux/store";
import BFTag from "../../abstract-ui/data/tag/BFTag";
import BFSelect from "../../abstract-ui/forms/select/BFSelect";
import ActionSheetDrawer from "../../abstract-ui/general/ActionSheetDrawer/ActionSheetDrawer";
import {
  ActionSheetEntries,
  ActionSheetEntry,
} from "../../abstract-ui/general/ActionSheetDrawer/ActionSheetEntries";
import BfButton from "../../abstract-ui/general/Button/BFButton";
import BfIcon from "../../abstract-ui/icon/BfIcon";
import GenericFormField from "../../generic-forms/GenericFormField";
import { GenericFormsLayoutProps } from "../../generic-forms/GFBaseElement";
import "./PermissionFormFieldSimple.scss";

type PermissionsArray = ({ APP: string } & Permissions)[];
type Props = {
  name: string;
  readonly?: boolean;
  mandatorApps: Application[];
  assetTypes: AssetType[];
  style?: CSSProperties;
} & GenericFormsLayoutProps &
  WithTranslation;

type States = {
  appSelectionActive;
};

class PermissionFormFieldSimple extends React.Component<Props, States> {
  oldState = {
    hasValidationErrors: null,
  };

  static defaultProps = {};

  readonly state: States = {
    appSelectionActive: false,
  };

  renderModal(value: PermissionsArray, input: FieldInputProps<any, any>) {
    const { mandatorApps, i18n } = this.props;
    let currentValue = (input.value as PermissionsArray) || [];
    currentValue = currentValue.filter((app) =>
      mandatorApps.find((entry) => entry._id === app.APP)
    );

    const data: ActionSheetEntry[] = [];
    mandatorApps
      .filter(
        (mandatorApp) =>
          value.find((valEntry) => valEntry.APP === mandatorApp._id) ===
          undefined
      )
      .forEach((app) => {
        const appConfig = this.props.mandatorApps.find(
          (appConfig) => appConfig._id === app._id
        );

        data.push({
          key: app._id,
          label: app.displayName,
          value: app,
          disabled: false,
          preRender: () => (
            <div
              className={"logo"}
              style={{
                backgroundImage: `url(${
                  appConfig.logo ? appConfig.logo.drawer : ""
                })`,
              }}
            />
          ),
          // postRender?: () => React.ReactNode,
        });
      });

    return (
      <ActionSheetDrawer
        onHide={() => this.setState({ appSelectionActive: false })}
        active={this.state.appSelectionActive}
        title={i18n.t(
          "BFComponents.PermissionsFormField.ApplicationPermission.ModalAddApplication"
        )}
      >
        <ActionSheetEntries
          className={"permission-field-simple-appselection"}
          data={data}
          onClick={(value) => {
            //TODO change here
            input.onChange([
              ...currentValue,
              {
                APP: value._id,
              },
            ]);

            this.setState({ appSelectionActive: false });
          }}
        />
      </ActionSheetDrawer>
    );
  }

  isSamePermission(appPermission: Permissions, presetPermission: Permissions) {
    const appPermissionsFlattened = flatten(appPermission, {
      safe: true,
    }) as any;
    delete appPermissionsFlattened["APP"];
    const presetPermissionsFlattened = flatten(presetPermission, {
      safe: true,
    });

    const appModGroups = appPermissionsFlattened["USER.modGroups"];
    delete appPermissionsFlattened["USER.modGroups"];

    const presetModGroups = presetPermissionsFlattened["USER.modGroups"];
    delete presetPermissionsFlattened["USER.modGroups"];

    if (_.isEqual(appPermissionsFlattened, presetPermissionsFlattened)) {
      if (
        (!appModGroups && !presetModGroups) ||
        (appModGroups && presetModGroups)
      ) {
        return true;
      }
    }

    Log.debug(appPermissionsFlattened, presetPermissionsFlattened);
    return false;
  }
  renderAppPermissions(
    input: FieldInputProps<any, any>,
    value: PermissionsArray
  ) {
    const { mandatorApps } = this.props;
    let currentValue = (input.value as PermissionsArray) || [];
    currentValue = currentValue.filter((app) =>
      mandatorApps.find((entry) => entry._id === app.APP)
    );

    if (value.length === 0) {
      return (
        <div className={`no-entries-label`}>
          {this.props.i18n.t(
            "Application.Administration.Group.noAppPermissions"
          )}
        </div>
      );
    } else {
      const data: ActionSheetEntry[] = [];

      value.forEach((appPermissions) => {
        const appConfig = this.props.mandatorApps.find(
          (appConfig) => appConfig._id === appPermissions.APP
        );

        if (appConfig) {
          const showRole =
            appConfig.permPreset &&
            Object.values(appConfig.permPreset).length !== 0;

          let selected = "none";
          if (showRole) {
            Object.entries(appConfig.permPreset).forEach(([key, config]) => {
              if (this.isSamePermission(appPermissions, config)) {
                selected = key;
              }
            });
          }

          data.push({
            key: appConfig._id,
            label: appConfig.displayName,
            value: appConfig,
            disabled: false,
            contentRender: (label) => (
              <div className={`name-with-options`}>
                <div className={`name`}>{label}</div>
                {showRole ? (
                  this.props.readonly ? (
                    <BFTag
                      textKey={"Permissions." + selected}
                      icon={{
                        data: "shield-key",
                        type: "bf",
                      }}
                    />
                  ) : (
                    <div className={`role-selection`}>
                      <BFSelect
                        type={"search"}
                        searchable={false}
                        cleanable={false}
                        value={selected}
                        icon={{
                          data: "shield-key",
                          type: "bf",
                        }}
                        onSelect={(val) => {
                          input.onChange(
                            value.map((entry) => {
                              if (entry.APP === appConfig._id) {
                                return {
                                  APP: appConfig._id,
                                  ...(val === "none"
                                    ? {}
                                    : appConfig.permPreset[val]),
                                };
                              } else {
                                return entry;
                              }
                            })
                          );
                        }}
                        data={[
                          {
                            label: this.props.i18n.t("Permissions.none"),
                            value: "none",
                          },
                          ...Object.entries(appConfig.permPreset).map(
                            ([key, config]) => {
                              return {
                                label: this.props.i18n.t("Permissions." + key),
                                value: key,
                              };
                            }
                          ),
                        ]}
                      />
                    </div>
                  )
                ) : null}
              </div>
            ),
            preRender: () => (
              <div
                className={"logo"}
                style={{
                  backgroundImage: `url(${
                    appConfig.logo ? appConfig.logo.drawer : ""
                  })`,
                }}
              />
            ),
            postRender: () => (
              <>
                <DebugDataComponent data={appConfig} />

                <div className={"actions"}>
                  {!this.props.readonly ? (
                    <BfButton
                      onClick={() => {
                        input.onChange(
                          currentValue.filter((a) => a.APP !== appConfig._id)
                        );
                      }}
                      appearance={"clear-on-white"}
                    >
                      <BfIcon data={"close"} />
                    </BfButton>
                  ) : null}
                </div>
              </>
            ),
          });
        }
      });

      return (
        <ActionSheetEntries
          data={data}
          className={"permission-field-simple-appselection no-overflow"}
        />
      );
    }
  }

  render() {
    const { mandatorApps, assetTypes, name, params, i18n, style } = this.props;

    return (
      <GenericFormField
        allProperties={params.allProperties}
        formRoot={params.formProps}
        name={name}
        prefix={params.prefix}
        jsonProperty={this.props as any}
        render={(input, meta, name1, jsonProperty, currentValues) => {
          let value = (input.value as PermissionsArray) || [];

          value = value.filter((app) =>
            mandatorApps.find((entry) => entry._id === app.APP)
          );

          return (
            <div className={"permissions-form-field-simple"} style={style}>
              <div className={"app-permissions"}>
                <div className={"header"}>
                  <div className={"title"}>
                    {i18n.t(
                      "BFComponents.PermissionsFormField.ApplicationPermission.Header"
                    )}
                  </div>

                  {!this.props.readonly &&
                  mandatorApps.filter(
                    (mandatorApp) =>
                      value.find(
                        (valEntry) => valEntry.APP === mandatorApp._id
                      ) === undefined
                  ).length !== 0 ? (
                    <div className={"actions"}>
                      <BfButton
                        appearance={"clear-highlight"}
                        onClick={() =>
                          this.setState({ appSelectionActive: true })
                        }
                      >
                        {i18n.t("Global.Buttons.add")}
                      </BfButton>
                    </div>
                  ) : null}
                </div>
                <div className={"content"}>
                  {this.renderAppPermissions(input, value)}
                </div>

                {this.renderModal(value, input)}
              </div>
            </div>
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  mandatorApps:
    state.global.cache &&
    state.global.cache.mandatorApps &&
    state.global.cache.mandatorApps.data
      ? state.global.cache.mandatorApps.data.apps
      : [],
  assetTypes:
    state.global.cache &&
    state.global.cache.assetTypes &&
    state.global.cache.assetTypes
      ? state.global.cache.assetTypes.data
      : [],
});

export default connect(mapStateToProps, { setUiConfig })(
  withTranslation()(PermissionFormFieldSimple)
) as any;
