import { APActivity } from "@/apps/tatar/activityApp/ActivityInterfaces";
import Username from "@/components/AvatarComponent/Username";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFStatus from "@/modules/abstract-ui/data/status/BFStatus";
import useOpenResubmissionForm from "@/modules/resubmission/hooks/useOpenResubmissionForm";
import { RSAssetBaseParams } from "@/modules/resubmission/RSInterfaces";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import CacheService from "@/services/CacheService";
import DataBus from "@/services/DataBus";
import { DataBusSubKeys } from "@/utils/Constants";
import moment from "moment";
import { Trans } from "react-i18next";
import { Comment, MailOutgoingComment } from "../../Comments";
import CommentUtils, { EMAIL_TAG_COLOR } from "../../CommentUtils";
import { CommentContainer } from "../CommentContainer";
import CommentEntryContent from "../CommentEntryContent";
import { CommentAttachmentEntry } from "../CommentInput";
import { EmailAddressFieldModel } from "../EmailAddressFields";
import MailOutgoingStatus from "../MailOutgoingStatus";

interface MailOutgoingCommentProps {
  onPinChange?: () => void;
  comment: MailOutgoingComment;
  commentModuleIdentifier: string;
  context?: {
    id: number;
    name: string;
  };
  onContextClick?: (contextId: number) => void;
  defaultSignature?: string;
  allowFullscreen?: boolean;
  disableFocusMode?: boolean;
  hideAllActions?: boolean;
  onClose?: () => void;
  onRedirect?: () => void;
  hideResubmissionActionForMail?: boolean;
  apperance?: "normal" | "slim";
  renderMailDuplicateAction?: (
    comment: Comment,
    onClose: () => void
  ) => JSX.Element;
  collapsible?: boolean;
}
export const MailOutgoingCommentEntry = (props: MailOutgoingCommentProps) => {
  const { openResubmissionDialog } = useOpenResubmissionForm();

  const possibleTags = (
    UnitStruct.getAllTagConfigs(AssetTypes.Comment) || []
  ).filter((e) => e.status !== "archived");

  const openResubmissionAction = async () => {
    const linkedAsset: RSAssetBaseParams = {
      assetType: props.comment.data?.linkedAsset.type,
      assetId: props.comment.data?.linkedAsset.id,
      assetDescription: `${i18n.t(
        "CommentsModule.OutgoingMail.Label",
        "E-Mail (ausgehend)"
      )}: ${props.comment.data?.typeData.subject}`,
      subAsset: {
        assetType: AssetTypes.Comment,
        assetId: props.comment._id,
      },
    };
    const activity = (await CacheService.getData({
      oType: "asset",
      assetType: props.comment.data?.linkedAsset.type,
      id: props.comment.data?.linkedAsset.id,
      forceReload: true,
    })) as APActivity;

    openResubmissionDialog({
      linkedAsset,
      typePermissions: [activity.data.type],
    });
  };

  const renderMailDuplicateAction = props.renderMailDuplicateAction
    ? (onClose: () => void) => {
        if (props.renderMailDuplicateAction) {
          return props.renderMailDuplicateAction(
            props.comment as Comment,
            onClose
          );
        }
      }
    : undefined;

  return (
    <CommentContainer
      collapsible={props.collapsible}
      pinned={props.comment.data.pin}
      onPinChange={props.onPinChange}
      archived={props.comment.data?.archived}
      commentId={props.comment._id}
      tagData={{
        possibleTags,
        tags: props.comment.data?.tags,
      }}
      hideAllActions={props.hideAllActions}
      onClose={props.onClose}
      onContextClick={props.onContextClick}
      className={`outgoing-mail`}
      disableFocusMode={props.disableFocusMode}
      apperance={props.apperance}
      onPrint={() => ({
        subject: props.comment.data?.typeData.subject,
        cc: props.comment.data?.typeData.cc,
        to: props.comment.data?.typeData.recipients,
        date: props.comment.data?.date,
      })}
      allowFullscreen={props.allowFullscreen}
      title={
        <>
          <strong>
            <Username id={props.comment.data?.typeData.userId} />
          </strong>{" "}
          <Trans i18nKey="CommentsModule.OutgoingMail.headerLabel">
            hat eine <span className="weight-600">Mail versendet</span>
          </Trans>
        </>
      }
      context={props.context}
      sender={props.comment.data?.typeData.userId}
      date={props.comment.data?.date}
      content={<CommentEntryContent comment={props.comment} />}
      subData={
        <div className={`mail-data`}>
          <div className={`recipients field`}>
            <div className={`label`}>
              {i18n.t("CommentsModule.OutgoingMail.recipients", "Empfänger")}:
            </div>
            <div className="value">
              {props.comment.data?.typeData.recipients.map(
                (recipient, index) => (
                  <BFStatus
                    key={index}
                    label={CommentUtils.formatEmailUser(recipient)}
                    size="sm"
                    color={EMAIL_TAG_COLOR}
                    style={{ fontWeight: 400 }}
                  />
                )
              )}
            </div>
          </div>
          {props.comment.data?.typeData.cc?.length !== 0 && (
            <div className={`cc field`}>
              <div className={`label`}>
                {i18n.t("CommentsModule.OutgoingMail.cc", "CC")}:
              </div>
              <div className="value">
                {props.comment.data?.typeData.cc.map((cc, index) => (
                  <BFStatus
                    key={index}
                    label={CommentUtils.formatEmailUser(cc)}
                    size="sm"
                    color={EMAIL_TAG_COLOR}
                    style={{ fontWeight: 400 }}
                  />
                ))}
              </div>
            </div>
          )}
          {props.comment.data?.typeData.bcc?.length !== 0 && (
            <div className={`bcc field`}>
              <div className={`label`}>
                {i18n.t("CommentsModule.OutgoingMail.bcc", "BCC")}:
              </div>
              <div className="value">
                {props.comment.data?.typeData.bcc.map((bcc, index) => (
                  <BFStatus
                    key={index}
                    label={CommentUtils.formatEmailUser(bcc)}
                    size="sm"
                    color={EMAIL_TAG_COLOR}
                    style={{ fontWeight: 400 }}
                  />
                ))}
              </div>
            </div>
          )}
          <div className={`subject field`}>
            <div className={`label`}>
              {i18n.t("CommentsModule.OutgoingMail.subject", "Betreff")}:
            </div>
            <div className="value">{props.comment.data?.typeData.subject}</div>
          </div>
        </div>
      }
      footer={{
        attachment: {
          asset: props.comment,
          assetType: "comment",
          attachmentFieldPath: "data.attachments",
          attachments: props.comment.data?.attachments,
        },
        center: (
          <MailOutgoingStatus status={props.comment.data?.typeData.status} />
        ),
        onCreateResubmission:
          props.hideResubmissionActionForMail || props.hideAllActions
            ? undefined
            : openResubmissionAction,
        onReply:
          props.commentModuleIdentifier && !props.hideAllActions
            ? async () => {
                DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                  identifier: props.commentModuleIdentifier,
                  cursorPosition: "start",
                  comment: `
              <p>
              </p>
              ${props.defaultSignature || ""}
              <br>
              <br>
              <div class="iberio-cite-prefix">${i18n.t(
                "CommentsModule.OutgoingMail.citePrefix",
                "Am {{date}}, Sie schrieben: ",
                {
                  date: moment(props.comment.data?.date).format(
                    i18n.t("Formats.dateTimeFormat")
                  ),
                }
              )}<br></div>
              <blockquote type="cite" cite="mid:${props.comment._id}">
              ${CommentUtils.stripImagesBase64FromHTML(
                CommentUtils.stripCommentsFromHTML(
                  props.comment.cdnHtml ||
                    props.comment.data?.message.split("\n").join("<br>")
                )
              )}
              </blockquote>
  
                    `,
                  sendAsMail: true,
                  subactivity: props.comment.data?.subactivityId,
                  emailAddressFields: {
                    recipients: props.comment.data?.typeData.recipients,
                    cc: props.comment.data?.typeData.cc,
                    subject: props.comment.data?.typeData.subject,
                  } as EmailAddressFieldModel,
                });
              }
            : undefined,

        onRedirect:
          props.onRedirect && !props.hideAllActions
            ? props.onRedirect
            : props.commentModuleIdentifier && !props.hideAllActions
            ? async () => {
                DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                  identifier: props.commentModuleIdentifier,
                  cursorPosition: "start",
                  comment: `
        <p>
        </p>
        ${props.defaultSignature || ""}
        <br>
        <br>
        
        <div class="iberio-cite-prefix">${i18n.t(
          "CommentsModule.OutgoingMail.citePrefix",
          "{{date}}: ",
          {
            date: moment(props.comment.data?.date).format(
              i18n.t("Formats.dateTimeFormat")
            ),
          }
        )}<br></div>
        <blockquote type="cite">
        ${CommentUtils.stripImagesBase64FromHTML(
          CommentUtils.stripCommentsFromHTML(
            props.comment.cdnHtml ||
              props.comment.data?.message.split("\n").join("<br>")
          )
        )}
        </blockquote>
      `,
                  sendAsMail: true,
                  attachments: props.comment.cdn
                    .filter((cdn) => {
                      return props.comment.data?.attachments?.find(
                        (attachment) => attachment.linkToCdn === cdn._id
                      );
                    })
                    .map(
                      (cdn) =>
                        ({
                          cdnId: cdn._id,
                          filename: cdn.filename,
                          fromtAssetId: props.comment._id,
                        } as CommentAttachmentEntry)
                    ),
                  subactivity: props.comment.data?.subactivityId,
                  emailAddressFields: {
                    recipients: [],
                    cc: [],
                    subject: props.comment.data?.typeData.subject,
                  } as EmailAddressFieldModel,
                });
              }
            : undefined,
      }}
      renderMailDuplicateAction={renderMailDuplicateAction}
    />
  );
};
