import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import Log from "../../../../debug/Log";
import i18n from "../../../../i18n";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import { HTTP } from "../../../../utils/Http";

interface EZFieldProps {
  value: any;

  url: string | ((values: any) => string);
  transformSubmitData?: (value: any) => any;
  submitErrorMsg?: string;
  submitSuccessMsg?: string;

  onSubmitSuccess: (result: any) => void;
  children: Function;
  submitOnChange?: boolean;
  delayedSubmit?: number;
  shouldSubmit?: (value: any) => boolean;
}

const EZField = (props: EZFieldProps) => {
  const delayRef = useRef(null);
  const [tempValue, setTempValue] = useState(props.value);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTempValue(props.value);
  }, [props.value]);

  const triggerSubmit = async (overwriteValue?) => {
    if (props.delayedSubmit) {
      if (delayRef.current) {
        clearTimeout(delayRef.current);
      }
      delayRef.current = setTimeout(() => {
        submitData(overwriteValue);
      }, props.delayedSubmit);
    } else {
      submitData(overwriteValue);
    }
  };
  const submitData = async (overwriteValue?) => {
    const value = props.transformSubmitData
      ? props.transformSubmitData(
          overwriteValue !== undefined ? overwriteValue : tempValue
        )
      : {
          value: overwriteValue !== undefined ? overwriteValue : tempValue,
        };

    if (_.isEqual(value, props.value)) {
      return;
    }
    if (props.shouldSubmit && !props.shouldSubmit(value)) {
      return;
    }
    setLoading(true);
    try {
      const result = await HTTP.post({
        url:
          typeof props.url === "string"
            ? props.url
            : props.url(
                overwriteValue !== undefined ? overwriteValue : tempValue
              ),
        target: "EMPTY",
        bodyParams: value,
      });
      if (props.submitSuccessMsg) {
        DataBusDefaults.toast({
          type: "success",
          text: props.submitSuccessMsg,
          duration: 2000,
        });
      }
      props.onSubmitSuccess(result);
    } catch (e) {
      Log.error(e);
      DataBusDefaults.toast({
        type: "error",
        text:
          props.submitErrorMsg ||
          i18n.t("EZ.Field.SubmitError", "Fehler beim Speichern der Daten"),
        duration: 3000,
      });
      setTempValue(props.value);
    } finally {
      setLoading(false);
    }
  };

  const onBlur = (e: any, overwriteValue: any) => {
    if (overwriteValue !== undefined) {
      setTempValue(overwriteValue);

      if (props.value !== overwriteValue) {
        triggerSubmit(overwriteValue);
      }
    } else {
      if (props.value !== tempValue) {
        triggerSubmit();
      }
    }
  };

  const onChange = (value: any, e: any) => {
    setTempValue(value);
    if (props.submitOnChange) {
      triggerSubmit(value);
    }
  };

  return props.children({
    onBlur,
    value: tempValue,
    onChange,
    loading,
    submitData,
  });
};

export default EZField;
