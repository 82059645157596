import BookingDetailsModal from "./impl/booking/BookingDetailModal";
import ContactDetailsModal from "./impl/contact/ContactDetailModal";
import InvoiceDetailsModal from "./impl/invoice/InvoiceDetailModal";
import LoanDetailsModal from "./impl/loan/LoanDetailsModal";
import CBObjectDetailModal from "./impl/object/CBObjectDetailModal";
import RentalAgreementDetailsModal from "./impl/rental/RentalAgreementDetailsModal";
import RentalUnitDetailsModal from "./impl/rental/RentalUnitDetailsModal";
import APSupplyUnitDetailModal from "./impl/supply-unit/APSupplyUnitDetailModal";
import APTechnicalUnitDetailModal from "./impl/technical-unit/APTechnicalUnitDetailModal";
import APVacancyDetailModal from "./impl/vacancy/APVacancyDetailModal";

interface DetailModalsProps {}
const GlobalDetailModals = (props: DetailModalsProps) => {
  return (
    <>
      <APTechnicalUnitDetailModal />
      <APSupplyUnitDetailModal />
      <CBObjectDetailModal />
      <RentalAgreementDetailsModal />
      <RentalUnitDetailsModal />

      <ContactDetailsModal />
      <BookingDetailsModal />
      <LoanDetailsModal />
      <InvoiceDetailsModal />
      <APVacancyDetailModal />
    </>
  );
};

export default GlobalDetailModals;
