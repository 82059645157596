import { clear, get, remove, set } from "local-storage";

class StorageUtilsClass {
	LocalStorage = {
		save: (key: string, value: any) => {
			set(key, value);
		},
		get: (key: string) => {
			return get(key);
		},
		delete: (key: string) => {
			remove(key);
		},
		clear: () => {
			clear();
		}
	};
	SessionStorage = {
		save: (key: string, value: any) => {
			sessionStorage.setItem(key, value);
		},
		get: (key: string) => {
			return sessionStorage.getItem(key);
		},
		delete: (key: string) => {
			sessionStorage.removeItem(key);
		},
		clear: () => {
			sessionStorage.clear();
		}
	};
}
const StorageUtils = new StorageUtilsClass();
export default StorageUtils;
