class DeviceUtilsClass {
  isApple() {
    var macOsPattern = /mac|ipod|iphone|ipad/;
    if ((window?.navigator as any)?.userAgentData) {
      const { platform } = (window?.navigator as any)?.userAgentData;
      return macOsPattern.test(platform.toLowerCase());
    } else {
      return macOsPattern.test(window.navigator.platform.toLowerCase());
    }
  }
}

const DeviceUtils = new DeviceUtilsClass();
export default DeviceUtils;
