import { when } from "@/utils/Helpers";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { APActivity } from "../../apps/tatar/activityApp/ActivityInterfaces";
import i18n from "../../i18n";
import { AssetTypes } from "../../model/AssetTypes";
import { MatchQuery } from "../../services/DataService";
import MQ from "../../utils/MatchQueryUtils";
import { ValidatorPopoverStyle } from "../abstract-ui/general/ValidationPopover/ValidationPopover";
import EZAssetSelect, {
  AssetSelectProps,
  AssetSelectStyle,
  AssetValue,
} from "../ez-form/form-elements/ez-asset-select/EZAssetSelect";
import EZAssetSelectMultiple from "../ez-form/form-elements/ez-asset-select/EZAssetSelectMultiple";
import "./ActivitySelect.scss";
import ActivityUtils from "./ActivityUtils";

export const ASSET_CONFIGS: () => {
  [key: string]: AssetSelectProps;
} = () => ({
  [AssetTypes.Activity.DamageClaim]: {
    id: AssetTypes.Activity.DamageClaim,
    label: ActivityUtils.getNameOfActivityType(AssetTypes.Activity.DamageClaim),
    assetType: AssetTypes.Activity.DamageClaim,
    renderValue: (value: APActivity) => {
      if (!value) {
        return (
          <div className={`__error`}>
            {i18n.t("Error.LoadingData", "Fehler beim Laden der Daten")}
          </div>
        );
      }

      return (
        <div className={`activity-value damage`}>
          <span className={`sub`}>{value.data.activityId}</span>{" "}
          <span className={`main`}>{value.data.displayName}</span>
        </div>
      );
    },
    renderMenu: (value: APActivity) => (
      <div className={`activity-menu damage`}>
        <div className={`sub`}>{value.data.activityId}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },

  [AssetTypes.Activity.Project]: {
    id: AssetTypes.Activity.Project,
    label: ActivityUtils.getNameOfActivityType(AssetTypes.Activity.Project),
    assetType: AssetTypes.Activity.Project,
    renderValue: (value: APActivity) => {
      if (!value) {
        return (
          <div className={`__error`}>
            {i18n.t("Error.LoadingData", "Fehler beim Laden der Daten")}
          </div>
        );
      }

      return (
        <div className={`activity-value damage`}>
          <span className={`sub`}>{value.data.activityId}</span>{" "}
          <span className={`main`}>{value.data.displayName}</span>
        </div>
      );
    },
    renderMenu: (value: APActivity) => (
      <div className={`activity-menu damage`}>
        <div className={`sub`}>{value.data.activityId}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },

  [AssetTypes.Activity.Maintenance]: {
    id: AssetTypes.Activity.Maintenance,
    label: ActivityUtils.getNameOfActivityType(AssetTypes.Activity.Maintenance),
    assetType: AssetTypes.Activity.Maintenance,
    renderValue: (value: APActivity) => {
      if (!value) {
        return (
          <div className={`__error`}>
            {i18n.t("Error.LoadingData", "Fehler beim Laden der Daten")}
          </div>
        );
      }

      return (
        <div className={`activity-value damage`}>
          <span className={`sub`}>{value.data.activityId}</span>{" "}
          <span className={`main`}>{value.data.displayName}</span>
        </div>
      );
    },
    renderMenu: (value: APActivity) => (
      <div className={`activity-menu maintenance`}>
        <div className={`sub`}>{value.data.activityId}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },

  [AssetTypes.Activity.SupplyContract]: {
    id: AssetTypes.Activity.SupplyContract,
    label: ActivityUtils.getNameOfActivityType(
      AssetTypes.Activity.SupplyContract
    ),
    assetType: AssetTypes.Activity.SupplyContract,
    renderValue: (value: APActivity) => {
      if (!value) {
        return (
          <div className={`__error`}>
            {i18n.t("Error.LoadingData", "Fehler beim Laden der Daten")}
          </div>
        );
      }

      return (
        <div className={`activity-value damage`}>
          <span className={`sub`}>{value.data.activityId}</span>{" "}
          <span className={`main`}>{value.data.displayName}</span>
        </div>
      );
    },
    renderMenu: (value: APActivity) => (
      <div className={`activity-menu maintenance`}>
        <div className={`sub`}>{value.data.activityId}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },
  [AssetTypes.Activity.Insurance]: {
    id: AssetTypes.Activity.Insurance,
    label: ActivityUtils.getNameOfActivityType(AssetTypes.Activity.Insurance),
    assetType: AssetTypes.Activity.Insurance,
    renderValue: (value: APActivity) => {
      if (!value) {
        return (
          <div className={`__error`}>
            {i18n.t("Error.LoadingData", "Fehler beim Laden der Daten")}
          </div>
        );
      }

      return (
        <div className={`activity-value damage`}>
          <span className={`sub`}>{value.data.activityId}</span>{" "}
          <span className={`main`}>{value.data.displayName}</span>
        </div>
      );
    },
    renderMenu: (value: APActivity) => (
      <div className={`activity-menu maintenance`}>
        <div className={`sub`}>{value.data.activityId}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },
  [AssetTypes.Activity.OrderingProcess]: {
    id: AssetTypes.Activity.OrderingProcess,
    label: ActivityUtils.getNameOfActivityType(
      AssetTypes.Activity.OrderingProcess
    ),
    assetType: AssetTypes.Activity.OrderingProcess,
    renderValue: (value: APActivity) => {
      if (!value) {
        return (
          <div className={`__error`}>
            {i18n.t("Error.LoadingData", "Fehler beim Laden der Daten")}
          </div>
        );
      }

      return (
        <div className={`activity-value damage`}>
          <span className={`sub`}>{value.data.activityId}</span>{" "}
          <span className={`main`}>{value.data.displayName}</span>
        </div>
      );
    },
    renderMenu: (value: APActivity) => (
      <div className={`activity-menu maintenance`}>
        <div className={`sub`}>{value.data.activityId}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },
});
type BaseActivitySelectProps = {
  initialAssetType?: string;
  disabled?: boolean;
  block?: boolean;
  businessUnits?: string[];
  className?: string;
  additionMatchQuery?: MatchQuery;
  objectId?: string;
  entityId?: string;
  identifier: string;
  assets?: string[];
  cleanable?: boolean;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  label?: string;
  labelPosition?: "top" | "left";
  appearance?: AssetSelectStyle;
  validatorStyle?: ValidatorPopoverStyle;
  showJump?: boolean;
};
type SingleActivitySelectProps = BaseActivitySelectProps & {
  multiple: false;
  value: AssetValue;
  onChange: (value: AssetValue) => void;
};

type MultipleActivitySelectProps = BaseActivitySelectProps & {
  multiple: true;
  value: AssetValue[];
  onValueAdded?: (value: AssetValue) => void;
  onValueRemoved?: (value: AssetValue) => void;
  onChange?: (value: AssetValue[]) => void;
};
type ActviitySelectProps =
  | SingleActivitySelectProps
  | MultipleActivitySelectProps;

const ActivitySelect = (props: ActviitySelectProps) => {
  const [configs, setConfigs] = useState<AssetSelectProps[]>([]);

  useEffect(() => {
    const availableConfigs = ASSET_CONFIGS();
    setConfigs(
      (props.assets || Object.keys(availableConfigs))
        .map((asset) => availableConfigs[asset])
        .filter((e) => !!e)
    );
  }, props.assets);

  if (props.multiple === true) {
    return (
      <EZAssetSelectMultiple
        initialAssetType={props.initialAssetType}
        disabled={props.disabled}
        appearance={props.appearance}
        className={classNames("activity-select", props.className)}
        identifier={props.identifier}
        onValueAdded={props.onValueAdded}
        onValueRemoved={props.onValueRemoved}
        onChange={props.onChange}
        value={props.value}
        assetTypes={configs}
        cleanable={props.cleanable}
        label={props.label}
        labelPosition={props.labelPosition}
        validatorStyle={props.validatorStyle}
        validation={props.validation}
        block={props.block}
        showJump={props.showJump}
        additionalMatchQuery={MQ.and(
          when(props.entityId, MQ.eq("data.entity", props.entityId)),
          when(props.objectId, MQ.eq("data.objectId", props.objectId)),
          props.businessUnits?.length > 0
            ? MQ.in("data.type", props.businessUnits)
            : undefined,
          props.additionMatchQuery
        )}
      />
    );
  } else {
    return (
      <EZAssetSelect
        initialAssetType={props.initialAssetType}
        disabled={props.disabled}
        appearance={props.appearance}
        className={classNames("activity-select", props.className)}
        identifier={props.identifier}
        onChange={props.onChange}
        value={props.value}
        assetTypes={configs}
        block={props.block}
        cleanable={props.cleanable}
        label={props.label}
        labelPosition={props.labelPosition}
        validatorStyle={props.validatorStyle}
        validation={props.validation}
        additionalMatchQuery={MQ.and(
          when(props.entityId, MQ.eq("data.entity", props.entityId)),
          when(props.objectId, MQ.eq("data.objectId", props.objectId)),
          props.businessUnits?.length > 0
            ? MQ.in("data.type", props.businessUnits)
            : undefined,
          props.additionMatchQuery
        )}
      />
    );
  }
};

export default ActivitySelect;
