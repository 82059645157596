import * as ECharts from "echarts";
import { css } from "emotion";
import { connect } from "react-redux";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import ChartService from "../../../services/ChartService";
import { DataBusSubKeys } from "../../../utils/Constants";
import StringUtils from "../../../utils/StringUtils";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";

export type ChartComponentProps = {
  ignoreDelay?: boolean;
  options: any; // | EChartsResponsiveOption;
  onChartClick?: (params: any, chart: any) => void;
  onOptionsChange?: (chart: any) => void;
  onLegendChange?: (chart: any, param: any) => void;
  onMounted?: (identifier: string, chart: any) => void;
  clearOnChange?: boolean;
  viewportHeight?: number;
  renderer: "svg" | "canvas";
  loading?: boolean;
  err?: any;
  syncZoomID?: string;
  map?: any;
};

type States = {} & AbstractStylableStates;

const CHART_CREATION_DELAY = 600;
const CHART_RESIZE_TIMEOUT = 200;

class ChartComponent extends AbstractStylableComponent<
  ChartComponentProps & AbstractStylableProps,
  States
> {
  identifierBackup = `chart-${StringUtils.random(30)}`;
  chart: any;
  resizeTimeout: NodeJS.Timeout;

  _mounted;
  static defaultProps = { renderer: "canvas" };
  readonly state: States = {};

  componentDidMount() {
    this._mounted = true;
    if (this.props.map) {
      ECharts.registerMap(
        this.props.identifier || this.identifierBackup,
        this.props.map
      );
    }
    this.chart = ECharts.init(
      document.querySelector(
        "#" + (this.props.identifier || this.identifierBackup)
      ),
      "default",
      {
        renderer: this.props.renderer,
      }
    );
    if (this.props.onChartClick) {
      this.chart.on("click", (params: any) =>
        this.props.onChartClick?.(params, this.chart)
      );
    }
    if (this.props.onMounted) {
      this.props.onMounted(
        this.props.identifier || this.identifierBackup,
        this.chart
      );
    }
    setTimeout(() => {
      // this.chart.resize();
      this.chart.resize({
        silent: true,
      });
    });
    this.chart.showLoading("default", {
      text: "",
    });
    if (this.props.onLegendChange) {
      this.chart.on("legendselectchanged", (param) =>
        this.props.onLegendChange(this.chart, param)
      );
    }

    this.subscribe(DataBusSubKeys.CHART_RESIZE, ({ identifier }) => {
      if ((this.props.identifier || this.identifierBackup) === identifier) {
        this.chart.resize({
          silent: true,
        });
      }
    });

    setTimeout(
      () => {
        if (this._mounted) {
          this.chart.setOption(this.props.options);
          if (
            this.props.loading === undefined ||
            this.props.loading === false
          ) {
            this.chart.hideLoading();
          }
        }
      },
      this.props.ignoreDelay ? 0 : CHART_CREATION_DELAY
    );

    if (this.props.syncZoomID) {
      ChartService.registerSyncDatazoom(this.props.syncZoomID, this.chart);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this.props.syncZoomID) {
      ChartService.unregisterSyncDatazoom(this.props.syncZoomID, this.chart);
    }
    if (this.chart) {
      this.chart.dispose();
    }
    super.componentWillUnmount();
  }

  componentDidUpdate(
    prevProps: ChartComponentProps & AbstractStylableProps,
    prevState: States,
    snapshot
  ) {
    if (this.props.loading && !prevProps.loading) {
      this.chart.showLoading("default", { text: "" });
    }
    if (!this.props.loading && prevProps.loading) {
      this.chart.hideLoading();
    }

    if (prevProps.options !== this.props.options) {
      setTimeout(() => {
        if (this.props.clearOnChange) {
          this.chart.clear();
        }
        this.chart.setOption(this.props.options, {
          ...(this.props.clearOnChange ? { replaceMerge: "series" } : {}),
        });
        if (this.props.onOptionsChange) {
          this.props.onOptionsChange(this.chart);
        }
        // this.chart.resize({
        // 	silent: true
        // });
      });
    }

    if (
      prevProps.viewportWidth !== this.props.viewportWidth ||
      prevProps.viewportHeight !== this.props.viewportHeight
    ) {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.chart.resize({
          silent: false,
        });
        this.resizeTimeout = null;
      }, CHART_RESIZE_TIMEOUT);
    }
  }

  //

  shouldComponentUpdate(
    nextProps: ChartComponentProps & AbstractStylableProps,
    nextState: States
  ) {
    if (nextProps.viewportWidth !== this.props.viewportWidth) {
      return true;
    }
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  render() {
    return (
      <div
        style={{ width: "100%", height: "100%", position: "relative" }}
        className={`ChartComponent ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
        onMouseOut={() => {
          // fix bug where tooltips are not hidden when mouse moved out too fast
          this.chart?.dispatchAction({
            type: "hideTip",
          });
        }}
      >
        <div
          id={this.props.identifier || this.identifierBackup}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (
  state: AppState,
  props: ChartComponentProps & AbstractStylableProps
) => ({
  viewportWidth: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH],
  viewportHeight: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_HEIGHT],
});

export default connect(mapStateToProps, {})(ChartComponent) as any;
