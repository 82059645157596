import classNames from "classnames";
import React from "react";
import { Message } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";
import "./BFMessageBox.scss";

interface MessageProps {
  inline?: boolean;
  small?: boolean;
  type?: TypeAttributes.Status;
  bordered?: boolean;
  centered?: boolean;
  closable?: boolean;
  duration?: number;
  header?: React.ReactNode;
  showIcon?: boolean;
  full?: boolean;
  children: React.ReactNode;
  className?: string;
  onClose?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}

const BFMessageBox = (props: MessageProps) => {
  return (
    <Message
      className={classNames(`bf-message-box`, props.className, {
        inline: props.inline,
        small: props.small,
      })}
      {...props}
    />
  );
};

export default BFMessageBox;
