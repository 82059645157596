import { css } from "emotion";
import moment from "moment";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dropdown, Modal } from "rsuite";
import ModalManager from "../../../components/ModalComponent/ModalManager";
import { TrustedDeviceModel } from "../../../model/db/User";
import { removeTrustedDevice } from "../../../redux/actions/global/global-actions";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import SocketService from "../../../services/socket/SocketService";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import { ConstantMisc, LSTrustedDeviceModel } from "../../../utils/Constants";
import StorageUtils from "../../../utils/StorageUtils";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import BfIcon from "../../abstract-ui/icon/BfIcon";
import RegisterMobileDeviceQRForm from "./RegisterMobileDeviceQRForm";
import RegisterPinLoginForm from "./RegisterPinLoginForm";
import "./TrustedDevices.scss";
import { TrustedDevicesTypes } from "./TrustedDevicesTypes";

type Props = {
  trustedDevices: TrustedDeviceModel[];
  removeTrustedDevice: (
    deviceID: string,
    onSuccess: () => void,
    onError: () => void
  ) => void;
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {
  modalState: "pin" | "qr";
  modalOpen: boolean;
  runningDeletionRequests: string[];
  lsTrustedDevices: LSTrustedDeviceModel[];
} & AbstractStylableStates;

class TrustedDevices extends AbstractStylableComponent<Props, States> {
  subId;
  static defaultProps = {};
  readonly state: States = {
    modalState: null,
    modalOpen: false,
    runningDeletionRequests: [],
    lsTrustedDevices:
      (StorageUtils.LocalStorage.get(
        ConstantMisc.LS_KEY_TRUSTED_DEVICES
      ) as LSTrustedDeviceModel[]) || [],
  };
  componentDidMount() {
    this.subId = SocketService.subscribe("QR_CODE_ACTIVATED", (msg) => {
      this.setState({ modalOpen: false });
    });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    SocketService.unsubscribe(this.subId);
  }
  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.trustedDevices !== this.props.trustedDevices) {
      this.setState({
        lsTrustedDevices:
          (StorageUtils.LocalStorage.get(
            ConstantMisc.LS_KEY_TRUSTED_DEVICES
          ) as LSTrustedDeviceModel[]) || [],
      });
    }
  }
  onDeleteTrustedDevice(device: TrustedDeviceModel) {
    const { t, removeTrustedDevice } = this.props;

    ModalManager.confirm({
      title: t("UserModal.Devices.RemoveDevice.Title"),
      message: t("UserModal.Devices.RemoveDevice.Description"),
      onConfirm: () => {
        removeTrustedDevice(
          device.deviceID,
          () =>
            this.setState({
              runningDeletionRequests:
                this.state.runningDeletionRequests.filter(
                  (e) => e !== device.deviceID
                ),
            }),
          () =>
            this.setState({
              runningDeletionRequests:
                this.state.runningDeletionRequests.filter(
                  (e) => e !== device.deviceID
                ),
            })
        );
      },
      confirmButtonText: t("UserModal.Devices.RemoveDevice.Confirm"),
    });
  }
  onRegisterPinLoginClick() {
    this.setState({ modalOpen: true, modalState: "pin" });
  }
  onRegisterQRLogin() {
    this.setState({ modalOpen: true, modalState: "qr" });
  }
  renderDeviceEntry(device: TrustedDeviceModel) {
    const { lsTrustedDevices } = this.state;
    const { t, removeTrustedDevice } = this.props;
    const isLocal = !!lsTrustedDevices.find(
      (entry) => entry.deviceID === device.deviceID
    );
    return (
      <div className={`trusted-device-entry ${isLocal ? "local" : ""}`}>
        <div className={`device-name-wrapper`}>
          <div className={`deviceName`}>
            {isLocal ? (
              <div className={`local-identifier`}>
                <BfIcon type="bf" data="pin-monitor" />{" "}
                {t("UserModal.Devices.LocalDevice")}{" "}
              </div>
            ) : null}{" "}
            {device.deviceName}
          </div>
          <div className={`sub-lines`}>
            <div className={`creationTime`}>
              <span className={`ident`}>{t("UserModal.Devices.Created")}</span>
              {device.creationTime
                ? moment(device.creationTime).format(
                    t("Formats.dateTimeFormat")
                  )
                : t("UserModal.Devices.notUsed")}
            </div>
            <div className={`last-used`}>
              <span className={`ident`}>{t("UserModal.Devices.LastUsed")}</span>
              {device.lastUsed
                ? moment(device.lastUsed).format(t("Formats.dateTimeFormat"))
                : t("UserModal.Devices.notUsed")}
            </div>
          </div>
        </div>
        <BFButton
          appearance={"primary"}
          onClick={() => this.onDeleteTrustedDevice(device)}
        >
          Löschen
        </BFButton>
      </div>
    );
  }
  renderCategoryHeader(category: string) {
    const { t } = this.props;

    let icon;
    switch (category) {
      case TrustedDevicesTypes.PIN:
        icon = <BfIcon size="lg" data="dial-pad" type="bf" />;
        break;
      case TrustedDevicesTypes.NATIVE_APP_BASE:
        icon = <BfIcon size="lg" data="mobile-phone-1" type="bf" />;
        break;
    }
    return (
      <div className={`category-header`}>
        {icon ? icon : null}
        {t(`UserModal.Devices.Category.${category}`)}
      </div>
    );
  }
  renderDevices() {
    const { trustedDevices, t, removeTrustedDevice } = this.props;
    const categories: { [key: string]: TrustedDeviceModel[] } = {};
    trustedDevices.forEach((device) => {
      if (!categories[device.appName]) {
        categories[device.appName] = [];
      }
      categories[device.appName].push(device);
    });

    return Object.entries(categories).map(([category, devices]) => {
      return (
        <div className={`category-wrapper`}>
          {this.renderCategoryHeader(category)}
          <div className={`category-content`}>
            {devices.map((device) => this.renderDeviceEntry(device))}
          </div>
        </div>
      );
    });
  }
  render() {
    const { modalOpen, modalState, lsTrustedDevices } = this.state;
    const { trustedDevices, t } = this.props;

    const localFound = !!lsTrustedDevices.find((entry) =>
      trustedDevices.find((device) => device.deviceID === entry.deviceID)
    );

    return (
      <div
        className={`TrustedDevices ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
      >
        <div className={`introText`}>{t("UserModal.Devices.introText")}</div>
        <div className={`devices-list-header`}>
          <div className={`devices-list-header-title`}>
            {t("UserModal.Devices.listHeader")}
          </div>
          <div className={`actions`}>
            <Dropdown
              placement="bottomEnd"
              title={t("UserModal.Devices.addDevice")}
            >
              <Dropdown.Item
                className={`sub-icon`}
                disabled={localFound}
                onSelect={() => this.onRegisterPinLoginClick()}
              >
                <BfIcon data="dial-pad" type="bf" />{" "}
                {t("UserModal.Devices.registerPinLogin")}
              </Dropdown.Item>
              <Dropdown.Item
                className={`sub-icon`}
                disabled={false}
                onSelect={() => this.onRegisterQRLogin()}
              >
                <BfIcon data="qr-code" type="bf" />{" "}
                {t("UserModal.Devices.registerMobileDevice")}
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
        <div className={`devices-list`}>
          {trustedDevices.length > 0 ? (
            this.renderDevices()
          ) : (
            <div className={`no-devices`}>
              {t("UserModal.Devices.noDevices")}
            </div>
          )}
        </div>

        <Modal
          size="xs"
          open={modalOpen}
          onExited={() => this.setState({ modalState: null })}
          onClose={() => this.setState({ modalOpen: false })}
        >
          {modalState === "pin" && (
            <RegisterPinLoginForm
              onFinish={() => this.setState({ modalOpen: false })}
              onCancel={() => this.setState({ modalOpen: false })}
            />
          )}
          {modalState === "qr" && (
            <RegisterMobileDeviceQRForm
              onFinish={() => this.setState({ modalOpen: false })}
              onCancel={() => this.setState({ modalOpen: false })}
            />
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
  trustedDevices: state.global.user.devices || [],
});

export default withTranslation()(
  connect(mapStateToProps, { removeTrustedDevice })(withRouter(TrustedDevices))
);
