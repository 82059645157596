class NumberUtilsClass {
  normalizeDecimal(number: number, decimalPoints = 2) {
    return parseFloat((number || 0).toFixed(decimalPoints));
  }

  round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  equalsNormalized(numberA: number, numberB: number) {
    if (numberA === null || numberB === null) {
      return false;
    }
    return this.normalizeDecimal(numberA) === this.normalizeDecimal(numberB);
  }
  isBetween(check: number, numberA: number, numberB: number) {
    const from = Math.min(numberA, numberB);
    const to = Math.max(numberA, numberB);
    return check >= from && check <= to;
  }

  isEven(number: number) {
    return number % 2 === 0;
  }

  isOdd(number: number) {
    return number % 2 !== 0;
  }

  getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getPercentageChange(oldValue: number, newValue: number) {
    return ((newValue - oldValue) / oldValue) * 100;
  }

  getSum(numbers: number[]) {
    return numbers.reduce((acc, curr) => acc + curr, 0);
  }

  getAverage(numbers: number[]) {
    return this.getSum(numbers) / numbers.length;
  }

  getMax(numbers: number[]) {
    return Math.max(...numbers);
  }

  getMin(numbers: number[]) {
    return Math.min(...numbers);
  }
}
const NumberUtils = new NumberUtilsClass();
(window as any).NumberUtils = NumberUtils;
export default NumberUtils;
