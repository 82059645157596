import moment from "moment";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../utils/abstracts/AbstractStylableComponent";

type Props = {
  orderIndex?: number;
  metaObject: string;
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class MetaRow extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //componentDidUpdate(prevProps:Props, prevState:States, snapshot) {}

  //

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  render() {
    const { i18n, orderIndex, metaObject, style } = this.props;
    const obj = this.evaluateExpression(metaObject);
    if (!obj) {
      return null;
    }

    const config = {
      _component: "div",
      orderIndex: orderIndex,
      style: [
        {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          padding: 10,
          color: "#999",
          fontSize: "0.8em",
          ...(!Array.isArray(style) ? style : {}),
        },
        ...(Array.isArray(style) ? style : []),
      ],
      components: {
        cf: {
          _component: "div",
          style: [
            {
              display: "flex",
              alignItems: "center",
            },
          ],
          components: {
            cf1: {
              _component: "simpleText",
              type: "span",
              textKey: "BFComponents.MetaRow.CreatedBy",
              style: {
                marginRight: 5,
              },
            },
            cf2: {
              _component: "avatar",
              size: "xs",
              displayName: obj.cf ? obj.cf.name : "",
              avatar: obj.cf ? obj.cf.avatar : null,
              style: {
                marginRight: 5,
              },
            },
            cf3: {
              _component: "simpleText",
              type: "span",
              text: obj.cf ? obj.cf.name : "",
              style: {
                marginRight: 5,
                fontWeight: "bold",
              },
            },
            cf4: {
              _component: "simpleText",
              type: "span",
              text: moment(obj.ca).format(i18n.t("Formats.dateFormat")),
              tooltipText: moment(obj.ca).format(
                i18n.t("Formats.dateTimeFormat")
              ),
              tooltipPlacement: "auto",
              tooltipTrigger: "hover",
              style: {
                marginRight: 10,
                fontWeight: "bold",
              },
            },
          },
        },
        mf: {
          _component: "div",
          tooltipTrigger: "hover",
          style: [
            {
              display: "flex",
              alignItems: "center",
            },
          ],
          components: {
            mf1: {
              _component: "simpleText",
              type: "span",
              textKey: "BFComponents.MetaRow.ModifiedBy",
              style: {
                marginRight: 5,
              },
            },
            mf2: {
              _component: "avatar",
              size: "xs",
              displayName: obj.mf.name,
              avatar: obj.mf.avatar,
              style: {
                marginRight: 5,
              },
            },
            mf3: {
              _component: "simpleText",
              type: "span",
              text: obj.mf.name,
              style: {
                marginRight: 5,
                fontWeight: "bold",
              },
            },
            mf4: {
              _component: "simpleText",
              type: "span",
              style: {
                fontWeight: "bold",
              },
              tooltipPlacement: "auto",
              tooltipText: moment(obj.ma).format(
                i18n.t("Formats.dateTimeFormat")
              ),
              text: moment(obj.ma).format(i18n.t("Formats.dateFormat")),
            },
          },
        },
      },
    };

    return (window as any).ComponentsMapper.createElement(
      config,
      this.props.params
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(MetaRow))
);
