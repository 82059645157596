import { translateNonGenerate } from "../../../utils/Helpers";
import BFLongLat from "../../abstract-ui/forms/longlat/BFLongLat";
import GenericFormField from "../../generic-forms/GenericFormField";
import { GenericFormsLayoutProps } from "../../generic-forms/GFBaseElement";
import { JsonPropertyCommon } from "../../generic-forms/util/JsonValidation";

export interface JsonPropertyLatLong extends JsonPropertyCommon {
  _component: "latlong";
  identifier?: string;
  infoText?: string;
}

type GFLatLongProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonPropertyLatLong;
export const GFLatLong: React.FC<GFLatLongProps> = (props) => {
  const { name, properties } = props;
  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      forceFormSpy={true}
      jsonProperty={props as any}
      render={(input, meta, name1, jsonProperty, currentValues, prefix) => {
        return (
          <BFLongLat
            identifier={props.identifier}
            value={input.value}
            onChange={(value) => input.onChange(value)}
            infoText={translateNonGenerate(props.infoText)}
            validation={{
              level: "error",
              message:
                !meta.active &&
                (meta.touched || meta.submitError || meta.submitFailed)
                  ? meta.error
                  : undefined,
            }}
          />
        );
      }}
    />
  );
};

// const mapStateToProps = (state: AppState, props: Props) => ({
// 	viewportWidth: Array.isArray(props.style) ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH] : null
// });

export default GFLatLong;
