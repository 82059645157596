import _ from "lodash";
import moment from "moment";
import { hasNoValue } from "../../utils/Helpers";
import { GanttTask } from "./BFGanttChart.interface";
import { Task } from "./lib";

const DEPTH_COLORS = ["#2d3f7c", "#66702f", "#356b6f", "#673a32"];
export const convertGantTasksToTasks = (tasks: GanttTask[]) => {
  const output: Task[] = [];

  tasks
    .filter((task) => !task.parent)
    .forEach((task) => {
      const toAdd = convertGanttTaskToTasksChilds(task, tasks);
      output.push(...toAdd);
    });
  return output;
};

const convertGanttTaskToTasksChilds = (task: GanttTask, tasks: GanttTask[]) => {
  const children: Task[] = [];

  const newTask: Task = {
    type: null,
    start: task.start,
    end: task.end,
    name: task.name,
    progress: task.progress,
    id: task.id,
    dependencies: task.dependsOn,
    project: task.parent,
    hideChildren: task.collapsed,
  };

  const depth = hasNoValue(task.depth)
    ? evaluateDepthOfGanttTask(task, tasks)
    : task.depth;

  tasks
    .filter((taskEntry) => taskEntry.parent === task.id)
    .forEach((taskEntry) => {
      children.push(...convertGanttTaskToTasksChilds(taskEntry, tasks));
    });

  if (children.length > 0) {
    newTask.start = _.min(children.map((t) => t.start));
    newTask.end = _.max(children.map((t) => t.end));
    newTask.type = "project";
    newTask.progress = getProgressOfGanttTask(task, tasks);
    if (hasNoValue(newTask.hideChildren)) {
      newTask.hideChildren = false;
    }
    newTask.styles = {
      backgroundColor: DEPTH_COLORS[depth % DEPTH_COLORS.length],
      backgroundSelectedColor: DEPTH_COLORS[depth % DEPTH_COLORS.length],
    };
  } else {
    newTask.type = "task";
  }
  return [newTask, ...children];
};

export const convertTasksToGantTasks = (tasks: Task[]) => {
  const output: GanttTask[] = [];

  tasks
    .filter((task) => !task.project)
    .forEach((task) => {
      const toAdd = convertTaskToGanttTasksChilds(task, tasks);
      output.push(...toAdd);
    });

  return output;
};

const convertTaskToGanttTasksChilds = (task: Task, tasks: Task[]) => {
  const children: GanttTask[] = [];

  const newTask: GanttTask = {
    start: task.start,
    end: task.end,
    name: task.name,
    progress: task.progress,
    id: task.id,
    dependsOn: task.dependencies,
    parent: task.project,
    collapsed: task.hideChildren,
    depth: evaluateDepthOfTask(task, tasks),
  };

  tasks
    .filter((taskEntry) => taskEntry.project === task.id)
    .forEach((taskEntry) => {
      children.push(...convertTaskToGanttTasksChilds(taskEntry, tasks));
    });

  if (children.length > 0) {
    newTask.start = _.min(children.map((t) => t.start));
    newTask.end = _.max(children.map((t) => t.end));
    newTask.progress = getProgressOfTask(task, tasks);
    if (hasNoValue(newTask.collapsed)) {
      newTask.collapsed = false;
    }
  }
  return [newTask, ...children];
};

const getProgressOfTask = (task: Task, tasks: Task[]) => {
  const tasksToEvaluate = getAllPlainTasksOf(task, tasks);

  const results = tasksToEvaluate.map((e) => {
    const daysDiff = moment(e.start).diff(moment(e.end), "days");

    return [daysDiff, e.progress * daysDiff];
  });

  return _.sum(results.map((e) => e[1])) / _.sum(results.map((e) => e[0]));
};

const getAllPlainGanttTasksOf = (task: GanttTask, tasks: GanttTask[]) => {
  const output: GanttTask[] = [];

  tasks
    .filter((e) => e.parent === task.id)
    .forEach((e) => {
      const toAdd = getAllPlainGanttTasksOf(e, tasks);
      if (toAdd.length === 0) {
        output.push(e);
      } else {
        output.push(...toAdd);
      }
    });

  if (output.length === 0) {
    return [task];
  } else {
    return output;
  }
};

export const getParentIdsOf = (id: string, groups: Task[]) => {
  const parents: string[] = [];

  function traverse(id: string) {
    const data = groups.find((e) => e && e.id === id);
    if (data.project) {
      const parent = groups.find((e) => e && e.id === data.project);
      if (parent) {
        parents.push(parent.id);
        traverse(parent.id);
      }
    }
  }

  traverse(id);
  return parents;
};

const getProgressOfGanttTask = (task: GanttTask, tasks: GanttTask[]) => {
  const tasksToEvaluate = getAllPlainGanttTasksOf(task, tasks);

  const results = tasksToEvaluate.map((e) => {
    const daysDiff = moment(e.start).diff(moment(e.end), "days");

    return [daysDiff, e.progress * daysDiff];
  });

  return _.sum(results.map((e) => e[1])) / _.sum(results.map((e) => e[0]));
};
const getAllPlainTasksOf = (task: Task, tasks: Task[]) => {
  const output: Task[] = [];

  tasks
    .filter((e) => e.project === task.id)
    .forEach((e) => {
      const toAdd = getAllPlainTasksOf(e, tasks);
      if (toAdd.length === 0) {
        output.push(e);
      } else {
        output.push(...toAdd);
      }
    });

  if (output.length === 0) {
    return [task];
  } else {
    return output;
  }
};

export const evaluateDepthOfTask = (task: Task, tasks: Task[]) => {
  if (task.project) {
    return (
      1 +
      evaluateDepthOfTask(
        tasks.find((t) => t.id === task.project),
        tasks
      )
    );
  } else {
    return 0;
  }
};
export const evaluateDepthOfGanttTask = (
  task: GanttTask,
  tasks: GanttTask[]
) => {
  if (task.parent) {
    return (
      1 +
      evaluateDepthOfGanttTask(
        tasks.find((t) => t.id === task.parent),
        tasks
      )
    );
  } else {
    return 0;
  }
};

export const evaluateEndDateOfTask = (
  start: Date,
  countDays: number,
  includeSaturdays = false,
  includeSundays = false
) => {
  const mStart = moment(start);
  if (includeSaturdays && includeSundays) {
    return mStart.clone().add(countDays, "days").toDate();
  }

  let count = 0;
  const run = mStart.clone();
  while (count < countDays) {
    if (run.day() === 0) {
      if (includeSundays) {
        count++;
      }
    } else if (run.day() === 6) {
      if (includeSaturdays) {
        count++;
      }
    } else {
      count++;
    }
    run.add(1, "days");
  }
  return run.toDate();
};

export const differenceOfDates = (
  from: Date,
  to: Date,
  includeSaturdays = false,
  includeSundays = false
) => {
  const mFrom = moment(from);
  const mTo = moment(to);

  // check between mFrom and mTo if saturdays and sundays exists, count days depending of that

  if (includeSaturdays && includeSundays) {
    return mTo.diff(mFrom, "days") + 1;
  }

  let count = 0;
  const run = mFrom.clone();

  while (run.isBefore(mTo, "day")) {
    if (run.day() === 0) {
      if (includeSundays) {
        count++;
      }
    } else if (run.day() === 6) {
      if (includeSaturdays) {
        count++;
      }
    } else {
      count++;
    }
    run.add(1, "days");
  }
  return count;
};
