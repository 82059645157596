import axios, { Canceler } from "axios";
import _ from "lodash";
import { ThunkDispatch } from "redux-thunk";
import Log from "../../../debug/Log";
import { TableSort } from "../../../model/common/CommonInterfaces";
import { MatchQuery } from "../../../services/DataService";
import { UpdateCommand } from "../../../services/socket/NotificationInterface";
import SocketService from "../../../services/socket/SocketService";
import { valueOrDefault } from "../../../utils/Helpers";
import { HTTP } from "../../../utils/Http";
import { InfiniteTableFilterValue } from "../../reducers/application/ApplicationInterface";
import { AppState } from "../../store";
import { ApplicationAction } from "./application-action-types";
import {
  INFINITE_TABLE_ADD_NEW_DATA_ID,
  INFINITE_TABLE_CLEAR_ALL,
  INFINITE_TABLE_CLEAR_TABLE,
  INFINITE_TABLE_INITIALIZE,
  INFINITE_TABLE_MARK_FOR_RELOAD,
  INFINITE_TABLE_PATCH_DATA,
  INFINITE_TABLE_REMOVE_NEW_DATA_ID,
  INFINITE_TABLE_SET_CUSTOMIZE_PARAMS,
  INFINITE_TABLE_SET_ERROR,
  INFINITE_TABLE_SET_FILTER,
  INFINITE_TABLE_SET_LOADING,
  INFINITE_TABLE_SET_MATCHQUERY,
  INFINITE_TABLE_SET_SCROLL,
  INFINITE_TABLE_SET_SEARCH,
  INFINITE_TABLE_SET_SELECTION,
  INFINITE_TABLE_SET_SORT,
  INFINITE_TABLE_SET_SUCCESS,
  InfiniteTableActions,
} from "./application-infinite-table-action-types";

export type ExpandKey = {
  key: string;
  assetType?: string;
};
export type InfiniteCustomizeParams = {
  addFields?: {
    [key: string]: any;
  };
  removeFields?: string[];
};
interface InitializeInfiniteTable {
  url: string;
  visibleSort?: TableSort;
  hiddenSort?: TableSort[];
  loadDelay?: number;
  additionalMatchQuery?: MatchQuery;
  searchTerm?: string;
  asPost?: boolean;
  limitPerRequest?: number;
  filterStatus?: InfiniteTableFilterValue[];
  expandKeys?: ExpandKey[];
  hiddenSortFirst?: boolean;
  customizeParams?: InfiniteCustomizeParams;
}
type DelayState = {
  timeout?: NodeJS.Timeout;
  cancelObj?: { cancel?: Canceler };
};
class InfiniteTableActionsClass {
  runningRequests: {
    [tableIdentifier: string]: DelayState;
  } = {};
  registeredSocketListeners: { [url: string]: boolean } = {};
  checkFunctions: {
    [tableIdentifier: string]: (cmd: UpdateCommand) => boolean;
  } = {};

  clearAll() {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      this.runningRequests = {};
      dispatch({
        type: INFINITE_TABLE_CLEAR_ALL,
      });
    };
  }
  clearTable(identifier: string) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      dispatch({
        type: INFINITE_TABLE_CLEAR_TABLE,
        tableIdentifier: identifier,
      });
    };
  }
  initialize(tableIdentifier: string, config: InitializeInfiniteTable) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      dispatch({
        type: INFINITE_TABLE_INITIALIZE,
        tableIdentifier,
        ...config,
      });
      // after reducer load initial data
      setTimeout(() => {
        this.requestData(
          tableIdentifier,
          "reload",
          true,
          undefined,
          dispatch,
          getState
        );
      });
    };
  }

  setTableScroll(tableIdentifier: string, scrollX: number, scrollY: number) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      dispatch({
        type: INFINITE_TABLE_SET_SCROLL,
        tableIdentifier,
        scrollX,
        scrollY,
      });
    };
  }

  setTableSelection(tableIdentifier: string, selection: string[]) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      dispatch({
        type: INFINITE_TABLE_SET_SELECTION,
        tableIdentifier,
        selection,
      });
    };
  }

  loadNextDataUntilEnd(tableIdentifier: string, overwriteSkip?: number) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      return new Promise((resolve, reject) => {
        const tableState =
          getState().application.infiniteTables[tableIdentifier];
        if (
          tableState &&
          !tableState.loading &&
          tableState.data &&
          tableState.data.length < tableState.totalCount
        ) {
          this.requestData(
            tableIdentifier,
            "nextPage",
            true,
            overwriteSkip,
            dispatch,
            getState
          )
            .then(() => {
              dispatch(
                this.loadNextDataUntilEnd(tableIdentifier, overwriteSkip)
              )
                .then((data) => {
                  resolve(data);
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          resolve(tableState.data);
        }
      });
    };
  }
  loadNextData(tableIdentifier: string, overwriteSkip?: number) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      const tableState = getState().application.infiniteTables[tableIdentifier];
      if (
        tableState &&
        !tableState.loading &&
        tableState.data &&
        tableState.data.length < tableState.totalCount
      ) {
        this.requestData(
          tableIdentifier,
          "nextPage",
          true,
          overwriteSkip,
          dispatch,
          getState
        );
      }
    };
  }

  setCustomizeParams(
    tableIdentifier: string,
    customizeParams: InfiniteCustomizeParams,
    ignoreDelay: boolean = false
  ) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      const prevFilter =
        getState().application.infiniteTables[tableIdentifier]?.customizeParams;
      dispatch({
        type: INFINITE_TABLE_SET_CUSTOMIZE_PARAMS,
        tableIdentifier,
        customizeParams,
      } as InfiniteTableActions);
      const postFilter =
        getState().application.infiniteTables[tableIdentifier]?.customizeParams;

      if (!_.isEqual(prevFilter, postFilter)) {
        dispatch(this.reloadData(tableIdentifier, ignoreDelay));
      }
    };
  }
  setMatchQuery(
    tableIdentifier: string,
    matchQuery?: MatchQuery,
    ignoreDelay: boolean = false
  ) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      const prevFilter =
        getState().application.infiniteTables[tableIdentifier]
          ?.additionalMatchQuery;
      dispatch({
        type: INFINITE_TABLE_SET_MATCHQUERY,
        tableIdentifier,
        matchQuery,
      } as InfiniteTableActions);
      const postFilter =
        getState().application.infiniteTables[tableIdentifier]
          ?.additionalMatchQuery;

      if (!_.isEqual(prevFilter, postFilter)) {
        dispatch(this.reloadData(tableIdentifier, ignoreDelay));
      }
    };
  }

  reloadDataPromise(tableIdentifier: string, ignoreDelay: boolean = false) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) =>
      new Promise((resolve, reject) => {
        this.requestData(
          tableIdentifier,
          "reload",
          ignoreDelay,
          undefined,
          dispatch,
          getState
        )
          .then((value) => resolve(value))
          .catch((err) => reject(err));
      });
  }
  reloadData(tableIdentifier: string, ignoreDelay: boolean = false) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      this.requestData(
        tableIdentifier,
        "reload",
        ignoreDelay,
        undefined,
        dispatch,
        getState
      );
    };
  }

  markForReload(tableIdentifier: string) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      dispatch({
        type: INFINITE_TABLE_MARK_FOR_RELOAD,
        tableIdentifier,
      } as InfiniteTableActions);
    };
  }

  setFilter(tableIdentifier: string, filter: InfiniteTableFilterValue[]) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      const prevFilters =
        getState()
          .application.infiniteTables[tableIdentifier]?.filterStatus?.map(
            (e) => e.matchQuery
          )
          .filter((e) => !!e) || [];

      dispatch({
        type: INFINITE_TABLE_SET_FILTER,
        tableIdentifier,
        filter,
      } as InfiniteTableActions);

      const postFilters =
        getState()
          .application.infiniteTables[tableIdentifier]?.filterStatus?.map(
            (e) => e.matchQuery
          )
          .filter((e) => !!e) || [];

      if (!_.isEqual(prevFilters, postFilters)) {
        this.requestData(
          tableIdentifier,
          "reload",
          false,
          undefined,
          dispatch,
          getState
        );
      }
    };
  }
  setSearch(tableIdentifier: string, searchTerm: string) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      const state = getState().application.infiniteTables[tableIdentifier];
      // ignore update if the data is already there
      if (state?.searchTerm === searchTerm) return;

      dispatch({
        type: INFINITE_TABLE_SET_SEARCH,
        tableIdentifier,
        searchTerm,
      } as InfiniteTableActions);

      this.requestData(
        tableIdentifier,
        "reload",
        false,
        undefined,
        dispatch,
        getState
      );
    };
  }
  setVisibleSort(tableIdentifier: string, visibleSort: TableSort) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      dispatch({
        type: INFINITE_TABLE_SET_SORT,
        tableIdentifier,
        visibleSort,
      } as InfiniteTableActions);

      this.requestData(
        tableIdentifier,
        "reload",
        false,
        undefined,
        dispatch,
        getState
      );
    };
  }
  patchData(
    tableIdentifier: string,
    dataId: string,
    data: any,
    mode: "overwrite" | "patchRoot" | "merge"
  ) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      dispatch({
        type: INFINITE_TABLE_PATCH_DATA,
        tableIdentifier,
        dataId,
        data,
        mode,
      } as InfiniteTableActions);
    };
  }
  searchInTablesAnPatchData(
    dataId: string,
    data: any,
    mode: "overwrite" | "patchRoot" | "merge" = "patchRoot"
  ) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      const tables = getState().application.infiniteTables;
      Object.entries(tables || {}).forEach(([tableIdentifier, tableData]) => {
        if (
          tableData &&
          tableData.data &&
          tableData.data.find((e) => e._id === dataId)
        ) {
          dispatch({
            type: INFINITE_TABLE_PATCH_DATA,
            tableIdentifier,
            dataId,
            data,
            mode,
          } as InfiniteTableActions);
        }
      });
    };
  }
  addNewDataId(tableIdentifier: string, dataId: string) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      dispatch({
        type: INFINITE_TABLE_ADD_NEW_DATA_ID,
        tableIdentifier,
        dataId,
      } as InfiniteTableActions);
    };
  }
  removeDataId(tableIdentifier: string, dataId: string) {
    return (
      dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
      getState: () => AppState
    ) => {
      dispatch({
        type: INFINITE_TABLE_REMOVE_NEW_DATA_ID,
        tableIdentifier,
        dataId,
      } as InfiniteTableActions);
    };
  }

  private requestData(
    tableIdentifier: string,
    type: "reload" | "nextPage",
    ignoreDelay: boolean,
    overwriteSkip: number | undefined,
    dispatch: ThunkDispatch<{}, {}, any>,
    getState: () => AppState
  ) {
    return new Promise((resolve, reject) => {
      const tableState = getState().application.infiniteTables[tableIdentifier];
      dispatch({
        type: INFINITE_TABLE_SET_LOADING,
        tableIdentifier,
        loading: type === "reload" ? "general" : "append",
      } as InfiniteTableActions);

      //cancel requests that have been done before
      const timeoutObj = this.runningRequests[tableIdentifier];
      if (timeoutObj) {
        if (timeoutObj.cancelObj && timeoutObj.cancelObj.cancel) {
          timeoutObj.cancelObj.cancel();
        }
        if (timeoutObj.timeout) {
          clearTimeout(timeoutObj.timeout);
        }
      }

      const doRequest = () => {
        if (!tableState?.url) {
          Log.warning(
            "Problem at InfiniteTableActions: No url for table " +
              tableIdentifier
          );
          return;
        }
        this.registerSocketListener(tableState.url, dispatch, getState);
        const timeoutObj = this.runningRequests[tableIdentifier];

        const sort = [];
        if (tableState.hiddenSortFirst && tableState.hiddenSort) {
          sort.push(
            ...(tableState.hiddenSort?.map((sortEntry) => ({
              fieldName: sortEntry.key,
              sortKey: sortEntry.dir === "asc" ? 1 : -1,
            })) || [])
          );
        }

        if (tableState.visibleSort) {
          sort.push({
            fieldName: tableState.visibleSort.key,
            sortKey: tableState.visibleSort.dir === "asc" ? 1 : -1,
          });
        }
        if (!tableState.hiddenSortFirst && tableState.hiddenSort) {
          sort.push(
            ...(tableState.hiddenSort?.map((sortEntry) => ({
              fieldName: sortEntry.key,
              sortKey: sortEntry.dir === "asc" ? 1 : -1,
            })) || [])
          );
        }

        const searchTerm = tableState.searchTerm;
        const filtersQueries = Object.values(tableState.filterStatus || {})
          .map((filter) => filter.matchQuery)
          .filter((matchQuery) => !!matchQuery);
        const additionalMatchQuery = tableState.additionalMatchQuery;

        let useMatchQuery: MatchQuery = undefined;
        if (filtersQueries.length > 0 && additionalMatchQuery) {
          useMatchQuery = {
            type: "and",
            query: [additionalMatchQuery, ...filtersQueries],
          };
        } else if (additionalMatchQuery) {
          useMatchQuery = additionalMatchQuery;
        } else if (filtersQueries.length > 0) {
          if (filtersQueries.length === 1) {
            useMatchQuery = filtersQueries[0];
          } else {
            useMatchQuery = {
              type: "and",
              query: filtersQueries,
            };
          }
        }

        const limit = tableState.limitPerRequest;
        //FIXME => overwrite offset for deleted elements from table
        const skip =
          type === "reload"
            ? 0
            : valueOrDefault(overwriteSkip, tableState.data.length);

        const params = {
          limit,
          skip,
          sort: sort.length === 0 ? undefined : sort,
          textQuery: searchTerm,
          matchQuery: useMatchQuery,
          expandKeys: tableState.expandKeys,
        };

        if (tableState.customizeParams) {
          if (tableState.customizeParams.removeFields) {
            tableState.customizeParams.removeFields.forEach(
              (field) => delete params[field]
            );
          }
          if (tableState.customizeParams.addFields) {
            Object.entries(tableState.customizeParams.addFields).forEach(
              ([key, value]) => {
                params[key] = value;
              }
            );
          }
        }

        HTTP[tableState.asPost ? "post" : "get"]({
          url: tableState.url,
          target: "EMPTY",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
          queryParams: tableState.asPost
            ? undefined
            : {
                param: params,
              },
          bodyParams: tableState.asPost ? params : undefined,
          cancelToken:
            timeoutObj && timeoutObj.cancelObj
              ? new axios.CancelToken(
                  (cancel) => (timeoutObj.cancelObj.cancel = cancel)
                )
              : undefined,
        })
          .then((result) => {
            dispatch({
              type: INFINITE_TABLE_SET_SUCCESS,
              tableIdentifier,
              data: result.data,
              totalCount: result.count,
              lastSkip: skip,
              append: type === "nextPage",
            } as InfiniteTableActions);

            // further actions here ...
            this.runningRequests[tableIdentifier] = null;
            resolve(result);
          })
          .catch((error) => {
            if (error instanceof axios.Cancel) {
              Log.info("canceled request", error);
              return;
            }

            this.runningRequests[tableIdentifier] = null;
            Log.error("error at loading data", tableState, error);
            dispatch({
              type: INFINITE_TABLE_SET_ERROR,
              error: error,
            } as InfiniteTableActions);
            reject(error);
          })
          .finally(() => {});
      };

      if (ignoreDelay) {
        // create request cancel token
        this.runningRequests[tableIdentifier] = {
          cancelObj: { cancel: null },
        };
        // directly request data
        doRequest();
      } else {
        // delay the request by the saved load delay
        const timeoutId = setTimeout(doRequest, tableState.loadDelay);
        // create request cancel token + timeout
        this.runningRequests[tableIdentifier] = {
          timeout: timeoutId,
          cancelObj: { cancel: null },
        };
      }
    });
  }

  private registerSocketListener = (
    url: string,
    dispatch: ThunkDispatch<{}, {}, any>,
    getState: () => AppState
  ) => {
    if (!this.registeredSocketListeners[url]) {
      this.registeredSocketListeners[url] = true;

      const subFC = (cmd: UpdateCommand) => {
        const storeState = getState();
        let url: string;
        if (cmd.type === "asset") {
          url = `asset/${cmd.assetType}`;
        } else {
          url = cmd.type;
        }
        Object.entries(storeState.application.infiniteTables)
          .filter(([identifier, table]) => table?.url === url)
          .forEach(([identifier, table]) => {
            (Array.isArray(cmd.objectID)
              ? cmd.objectID
              : [cmd.objectID]
            ).forEach((objectId) => {
              if (
                this.checkFunctions[identifier] &&
                !this.checkFunctions[identifier](cmd)
              ) {
                return;
              }

              if (cmd.uType === "created") {
                dispatch(InfiniteTable.addNewDataId(identifier, objectId));
              } else {
                if (cmd.uType === "deleted") {
                  dispatch(InfiniteTable.removeDataId(identifier, objectId));
                }

                const obj = table.data?.find((e) => e["_id"] === objectId);
                if (obj) {
                  dispatch(
                    InfiniteTable.patchData(
                      identifier,
                      objectId,
                      {
                        _dirty: cmd,
                        _dirtyTime: Date.now(),
                        [`_${cmd.uType}`]: true,
                      },
                      "patchRoot"
                    )
                  );
                }
              }
            });
          });
      };

      if (url.toLocaleLowerCase().indexOf("asset") === 0) {
        SocketService.subscribeAsset(
          url.toLocaleLowerCase().split("/")[1],
          subFC
        );
      } else {
        switch (url.toLocaleLowerCase()) {
          case "user":
            SocketService.subscribeUser(subFC);
            break;
          case "team":
            SocketService.subscribeTeam(subFC);
            break;
          case "group":
            SocketService.subscribeGroup(subFC);
            break;
        }
      }
    }
  };
  registerSocketFilterChangeCommandForTables = (
    tableIdentifier: string,
    checkFC: (cmd: UpdateCommand) => boolean
  ) => {
    this.checkFunctions[tableIdentifier] = checkFC;
  };
  unregisterSocketFilterChangeCommandForTables = (tableIdentifier: string) => {
    delete this.checkFunctions[tableIdentifier];
  };
}
const InfiniteTable = new InfiniteTableActionsClass();
export default InfiniteTable;
