import _ from "lodash";
import AssetLoader from "../../../components/AssetLoader/AssetLoader";
import { AssetTypes } from "../../../model/AssetTypes";
import {
  Signature,
  Textblock,
  UserConfigAsset,
} from "../../../model/db/UserConfigAsset";
import BaseAsset, {
  CommunicationSubActivity,
} from "../../../model/general-assets/BaseAsset";
import { useTypedSelector } from "../../../redux/hooks";
import CommentInput, { CommentAttachmentEntry } from "./CommentInput";
import { EmailAddressFieldModel } from "./EmailAddressFields";

interface CommentInputWrapperProps {
  identifier: string;

  placeholder?: string;
  allowMentions?: boolean;
  onlyAsMail?: boolean;
  onlyActive?: boolean;
  ignoreDraft?: boolean;
  onCancel?: () => void;
  subactivity?: "allow" | "force" | "force-on-mail";
  possibleContexts?: CommunicationSubActivity[];
  localStorageIdentifier?: string;
  onSend: (
    comment: string,
    mailAddressFields: EmailAddressFieldModel,
    subactivity?: string,
    attachments?: CommentAttachmentEntry[]
  ) => Promise<void>;
  onActiveChange?: (active: boolean) => void;
  linkedAsset?: {
    assetId: string;
    assetType: string;
  };
  type: string;
  onDraftLoaded?: () => void;
}
const CommentInputWrapper = (props: CommentInputWrapperProps) => {
  const userId = useTypedSelector((state) => state.global.user._id);
  const content = (asset?: BaseAsset) => (
    <AssetLoader
      assetType={AssetTypes.UserConfig}
      ignoreViewportDelay
      query={{
        type: "op",
        name: "data.user",
        op: "eq",
        value: userId,
      }}
      render={(userConfig: UserConfigAsset) => {
        const signatures: Signature[] = userConfig?.data.signatures || [];
        const defaultSignature = userConfig?.data.defaultSignature;
        const textblocks: Textblock[] = userConfig?.data.textblocks || [];
        return (
          <CommentInput
            onDraftLoaded={props.onDraftLoaded}
            localStorageIdentifier={
              props.ignoreDraft
                ? undefined
                : props.localStorageIdentifier ||
                  `${props.identifier}-${props.linkedAsset?.assetId || "null"}`
            }
            onlyActive={props.onlyActive}
            onlyAsMail={props.onlyAsMail}
            linkedAsset={props.linkedAsset}
            onActiveChange={props.onActiveChange}
            signatures={signatures}
            defaultSignature={defaultSignature}
            subactivity={props.subactivity}
            textblocks={textblocks}
            possibleContexts={_.uniq(asset?.communication?.subactivities)}
            identifier={props.identifier}
            allowMentions={props.allowMentions}
            placeholder={props.placeholder}
            onSend={props.onSend}
            onCancel={props.onCancel}
            type={props.type}
          />
        );
      }}
    />
  );

  if (props.linkedAsset) {
    return (
      <AssetLoader
        ignoreViewportDelay
        assetType={props.linkedAsset.assetType}
        id={props.linkedAsset.assetId}
        render={(asset) => content(asset)}
      />
    );
  } else {
    return content();
  }
};

export default CommentInputWrapper;
