import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dropdown } from "rsuite";
import AppDrawer from "../../components/AppDrawer/AppDrawer";
import { setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import BfIcon from "../abstract-ui/icon/BfIcon";
import { seenAllNotifications } from "./../../redux/actions/notifications/notifications-actions";
import "./AppDrawerButton.scss";

type Props = {
  appDrawerOverlayActive: boolean;
  setUiConfig: (key: string, value: any) => void;
} & WithTranslation;

type States = {};

class AppDrawerButton extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  // omponentDidMount() {}

  // componentWillUnmount() {}

  // componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return true;
  }

  toggleNotification(open) {
    this.props.setUiConfig(DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE, open);
  }

  render() {
    const { appDrawerOverlayActive } = this.props;

    return (
      <Dropdown
        className="header-dropdown app-drawer-dropdown"
        open={appDrawerOverlayActive}
        onToggle={(open) => this.toggleNotification(open)}
        title={
          <div className="font-color-white">
            <BfIcon size="lg" type="bf" data={"layout-module-3"} />
          </div>
        }
        noCaret
        placement="bottomEnd"
      >
        <div className="wrapper">
          <AppDrawer smallIcons={true} />
        </div>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  appDrawerOverlayActive:
    state.uiConfig.general[DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE],
});

export default connect(mapStateToProps, { setUiConfig, seenAllNotifications })(
  withTranslation()(AppDrawerButton)
) as any;
