import { Loader } from "rsuite";
import "./LoadPage.scss";

interface LoadPageProps {
  size: "sm" | "md" | "lg";
}
const LoadPage = (props: LoadPageProps) => {
  return (
    <div className="load-page">
      <Loader size={props.size || "lg"} />
    </div>
  );
};

LoadPage.defaultProps = {} as Partial<LoadPageProps>;

export default LoadPage;
