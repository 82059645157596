import { Application } from "../../../model/db/Application";

export const SET_UI_CONFIG = "SET_UI_CONFIG";
export const SET_ACTIVE_APPLICATION = "SET_ACTIVE_APPLICATION";
export const ADD_DEBUG_ACTION = "ADD_DEBUG_ACTION";
export const OPEN_DOCUMENT_VIEWER = "OPEN_DOCUMENT_VIEWER";
export const CLOSE_DOCUMENT_VIEWER = "CLOSE_DOCUMENT_VIEWER";

interface SetUiConfigAction {
  type: typeof SET_UI_CONFIG;
  key: string;
  value: any;
}
interface OpenDocumentViewerAction {
  type: typeof OPEN_DOCUMENT_VIEWER;
  data: string | object;
  fileName?: string;
  fileType?: string;
  viewerType?: string;
}
interface CloseDocumentViewerAction {
  type: typeof CLOSE_DOCUMENT_VIEWER;
}

interface SetActiveApplication {
  type: typeof SET_ACTIVE_APPLICATION;
  application: Application;
}

interface AddDebugAction {
  type: typeof ADD_DEBUG_ACTION;
  name: string;
  action: () => void;
}

export type UIConfigAction =
  | OpenDocumentViewerAction
  | CloseDocumentViewerAction
  | SetUiConfigAction
  | SetActiveApplication
  | AddDebugAction;
