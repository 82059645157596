import { RAInvoice } from "@/apps/tatar/invoiceApp/RAInterfaces";
import RAInvoiceDetailsComponent from "@/apps/tatar/invoiceApp/components/InvoiceDetails/RAInvoiceDetailsComponent";
import AssetDetailModal from "@/components/AssetDetailModal/AssetDetailModal";
import StructLoader from "@/components/StructLoader/StructLoader";
import { openDetailsAction } from "@/modals/GlobalDetailsFunctions";
import { AssetTypes } from "@/model/AssetTypes";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

interface Props {}
const ROUTE_NAME = "__asset_invoice";
const InvoiceDetailsModal = (props: Props) => {
  const history = useHistory();
  useEffect(() => {
    openDetailsAction(AssetTypes.Invoice, ROUTE_NAME);
  }, []);
  return (
    <AssetDetailModal
      assetType={AssetTypes.Invoice}
      routeName={ROUTE_NAME}
      size="full"
      className="invoice-modal"
      render={(asset: RAInvoice) => (
        <StructLoader
          structTypes={["invoice", "orga", "unit", "category"]}
          unitType={asset.data.type}
          render={() => (
            <div
              style={{
                height: "calc(100vh - 50px)",
                padding: "0px 20px 20px 20px",
              }}
            >
              <RAInvoiceDetailsComponent
                invoice={asset}
                editable={false}
                card
              />
              {/* <CBRentalAgreementDetails
            goBack={() => {
              // DataBusDefaults.closeModal(ROUTE_NAME);
              history.goBack();
            }}
            rentalAgreement={asset}
            integrated
          /> */}
            </div>
          )}
        />
      )}
    />
  );
};

export default InvoiceDetailsModal;
