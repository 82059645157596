import { LexoRank } from "lexorank";
import { useDispatch } from "react-redux";
import i18n from "../../../../i18n";
import Customization from "../../../../redux/actions/customization/customization-actions";
import BFButton from "../../general/Button/BFButton";
import BFWhisper from "../../general/whisper/BFWhisper";
import BfIcon from "../../icon/BfIcon";
import { ColumnConfigWithIdentifier } from "./BFVirtualizedTable";

interface BFVirtualizedTableHeaderContextMenuProps {
  tableIdentifier: string;
  column: ColumnConfigWithIdentifier;
  persistCustomizations?: boolean;
  allDependingColumns: ColumnConfigWithIdentifier[];
  hiddenColumns: ColumnConfigWithIdentifier[];
}
const BFVirtualizedTableHeaderContextMenu = (
  props: BFVirtualizedTableHeaderContextMenuProps
) => {
  const dispatch = useDispatch();
  const changePosition = (change: 1 | -1) => {
    const indexOfColumn = props.allDependingColumns.findIndex(
      (c) => c.identifier === props.column.identifier
    );
    const indexOfColumnToSwap = indexOfColumn + change;
    if (
      indexOfColumnToSwap < 0 ||
      indexOfColumnToSwap >= props.allDependingColumns.length
    ) {
      return;
    }
    const lexoRankToSwap = LexoRank.parse(
      props.allDependingColumns[indexOfColumnToSwap].orderIndex
    );

    const indexSecondNext = indexOfColumnToSwap + change;
    let lexoRankNext = null;
    if (
      indexSecondNext >= 0 &&
      indexSecondNext < props.allDependingColumns.length
    ) {
      lexoRankNext = LexoRank.parse(
        props.allDependingColumns[indexSecondNext].orderIndex
      );
    }

    let newLexoRank = null;
    if (lexoRankNext) {
      newLexoRank = lexoRankNext.between(lexoRankToSwap);
    } else {
      newLexoRank =
        change === 1 ? lexoRankToSwap.genNext() : lexoRankToSwap.genPrev();
    }

    dispatch(
      Customization.customizeInfiniteTableColumns(
        props.tableIdentifier,
        {
          [props.column.identifier]: {
            orderIndex: newLexoRank.toString(),
          },
        },
        props.persistCustomizations
      )
    );
  };
  const moveLeft = () => changePosition(-1);
  const moveRight = () => changePosition(1);
  const fixate = (fixed: "left" | "right" | null) => {
    dispatch(
      Customization.customizeInfiniteTableColumns(
        props.tableIdentifier,
        {
          [props.column.identifier]: {
            fixed: fixed,
            ...(fixed === null
              ? {
                  flexWidth: undefined,
                  fixedWidth: undefined,
                }
              : {
                  flexWidth: null,
                  fixedWidth: props.column.flexWidth || props.column.fixedWidth,
                }),
          },
        },
        props.persistCustomizations
      )
    );
  };
  const fixateLeft = () => fixate("left");
  const fixateRight = () => fixate("right");
  const unfixate = () => fixate(null);

  const restoreDefault = () => {
    dispatch(
      Customization.revertInfiniteTable(
        props.tableIdentifier,
        props.persistCustomizations
      )
    );
  };

  const setHideColumnState = (
    columnIdentifier: string,
    hiddenState: boolean
  ) => {
    dispatch(
      Customization.customizeInfiniteTableColumns(
        props.tableIdentifier,
        {
          [columnIdentifier]: {
            hidden: hiddenState,
          },
        },
        props.persistCustomizations
      )
    );
  };
  const hideColumn = () => {
    setHideColumnState(props.column.identifier, true);
  };
  const canMoveLeft = true;
  const canMoveRight = true;

  return (
    <BFWhisper
      placement={"bottomEnd"}
      trigger="click"
      delay={0}
      enterable={true}
      // trigger={"clic÷k"}
      speaker={
        <div style={{ position: "absolute", zIndex: 9999 }}>
          <div
            className={`__card bf-virutalized-table-header-context-menu-options`}
            style={{ background: "#fff" }}
          >
            <div className={`column-name`}>
              {props.column.label || props.column.identifier}
            </div>
            <BFButton disabled={!canMoveLeft} onClick={moveLeft}>
              {i18n.t(
                "BFVirtualizedTable.Header.Context.MoveLeft",
                "Spalte nach links verschieben"
              )}
            </BFButton>
            <BFButton disabled={!canMoveRight} onClick={moveRight}>
              {i18n.t(
                "BFVirtualizedTable.Header.Context.MoveRight",
                "Spalte nach rechts verschieben"
              )}
            </BFButton>
            <div className={`divider`} />

            {props.column.fixed !== "left" && (
              <BFButton onClick={fixateLeft}>
                {i18n.t(
                  "BFVirtualizedTable.Header.Context.FixateLeft",
                  "Links fixieren"
                )}
              </BFButton>
            )}
            {props.column.fixed !== "right" && (
              <BFButton onClick={fixateRight}>
                {i18n.t(
                  "BFVirtualizedTable.Header.Context.FixateRight",
                  "Rechts fixieren"
                )}
              </BFButton>
            )}
            {props.column.fixed !== null &&
              props.column.fixed !== undefined && (
                <BFButton onClick={unfixate}>
                  {i18n.t(
                    "BFVirtualizedTable.Header.Context.Unfixate",
                    "Fixierung aufheben"
                  )}
                </BFButton>
              )}

            <div className={`divider`} />
            <BFButton onClick={hideColumn}>
              {i18n.t(
                "BFVirtualizedTable.Header.Context.HideColumn",
                "Spalte ausblenden"
              )}
            </BFButton>

            <div className={`divider`} />
            <BFButton onClick={restoreDefault}>
              {i18n.t(
                "BFVirtualizedTable.Header.Context.RestoreDefault",
                "Standard wiederherstellen"
              )}
            </BFButton>
            {props.hiddenColumns.length > 0 && (
              <>
                <div className={`divider`} />
                <div className={`subtitle`}>
                  {i18n.t(
                    "BFVirtualizedTable.Header.Context.HiddenColumns",
                    "Ausgeblendete Spalten wieder anzeigen"
                  )}
                </div>
                {props.hiddenColumns.map((column) => (
                  <BFButton
                    onClick={() => setHideColumnState(column.identifier, false)}
                  >
                    {column.label || column.identifier}
                  </BFButton>
                ))}
              </>
            )}
          </div>
        </div>
      }
    >
      <button
        type="button"
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
        }}
        className={`bf-virtualized-table-header-context-menu`}
      >
        <BfIcon data="navigation-menu-vertical" type="light" size="xs" />
      </button>
    </BFWhisper>
  );
};

export default BFVirtualizedTableHeaderContextMenu;
