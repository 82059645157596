import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import "./FocusedEditComponent.scss";

interface FocusedEditComponentProps {
  children: React.ReactElement;
  editActive: boolean;
}
const FocusedEditComponent = (props: FocusedEditComponentProps) => {
  const ref = useRef<HTMLDivElement>();
  const changedElements = useRef<Element[]>([]);

  useEffect(() => {
    if (ref.current) {
      if (props.editActive) {
        if (ref.current.parentElement) {
          inertAllParentSiblings(ref.current);
        }
      } else {
        revertInert();
      }
    }
  }, [props.editActive]);

  const inertAllParentSiblings = (node: Element) => {
    const parentOfNode = node.parentElement;

    if (parentOfNode) {
      const otherChilds = parentOfNode.children;

      for (let i = 0; i < otherChilds.length; i++) {
        if (otherChilds[i] !== node && !otherChilds[i].hasAttribute("inert")) {
          otherChilds[i].setAttribute("inert", "true");
          changedElements.current.push(otherChilds[i]);
        }
      }

      inertAllParentSiblings(parentOfNode);
    }
  };

  const revertInert = () => {
    for (let i = 0; i < changedElements.current.length; i++) {
      changedElements.current[i].removeAttribute("inert");
    }
    changedElements.current = [];
  };

  return (
    <div ref={ref} className={classNames(`focused-edit-component`)}>
      {props.editActive && <div className={`focus-overlay`}></div>}
      <div
        className={classNames(`edit-component-wrapper`, {
          "to-top": props.editActive,
        })}
      >
        {props.children}
      </div>
    </div>
  );
};

export default FocusedEditComponent;
