import moment from "moment";
import i18n from "../../i18n";
import { Meta } from "../../model/db/Meta";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import UserAvatar from "../AvatarComponent/UserAvatar";
import Username from "../AvatarComponent/Username";
import "./MetaRow.scss";

interface MetaProps {
  meta: Meta;
  pretext?: string;
  showModified?: boolean;
}
const MetaRow = (props: MetaProps) => {
  if (!props.meta) {
    return null;
  }
  const showModified = props.showModified && props.meta.mf;
  const user = props.showModified
    ? props.meta.mf || props.meta.cf
    : props.meta.cf;
  const date = props.showModified
    ? props.meta.ma || props.meta.ca
    : props.meta.ca;

  return (
    <div className="meta-row">
      <div className="text">
        {props.pretext ||
          (showModified
            ? i18n.t("MetaRow.modifiedBy", "Bearbeitet von")
            : i18n.t("MetaRow.createdBy", "Erstellt von"))}
      </div>

      <div className="user">
        {typeof user === "string" && (
          <>
            <UserAvatar id={user} size={20} />
            <div className="username">
              <Username id={user} />
            </div>
          </>
        )}
        {typeof user !== "string" && (
          <>
            <AvatarComponent
              avatar={user.avatar}
              displayName={user.name}
              size={20}
            />
            <div className="username">{user.name}</div>
          </>
        )}
      </div>
      <div className="text">
        {i18n.t("MetaRow.at", "am")}{" "}
        <strong>{moment(date).format(i18n.t("Formats.dateTimeFormat"))}</strong>
      </div>
    </div>
  );
};

export default MetaRow;
