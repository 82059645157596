import SuccessAnimation from "@/components/LottieAnimations/SendLetter/SendLetterAnimation";
import classNames from "classnames";
import "./SuccessModal.scss";

interface SuccessModalProps {
  title?: string;
  message: string;
}
const SuccessModal = (props: SuccessModalProps) => {
  return (
    <div className={classNames(`success-modal`)}>
      <div className={`animation`}>
        <SuccessAnimation style={{ width: 100, height: 100 }} />
      </div>
      <div className={`success-info`}>
        {props.title && <div className={`success-title`}>{props.title}</div>}
        <div className={`success-message`}>{props.message}</div>
      </div>
    </div>
  );
};

export default SuccessModal;
