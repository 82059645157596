import ImageResizor from "image-resizor";
import { Options } from "image-resizor/dist/imageResizor";
import pica from "pica";
import FileUtils from "./FileUtils";

const fileTypesToConvert = ["image/heic", "image/heif", "image/webp"];
const extensionsToConvert = [{ ext: "jfif", replaceWith: "jpeg" }];

class ImageUtilsClass {
  checkConvertImageFile(file: File | Blob, fileName: string) {
    const isFileTypeToConvert = fileTypesToConvert.includes(file.type);
    const hasExtensionToReplace =
      extensionsToConvert.find(
        (e) => e.ext === FileUtils.getFiletype(fileName)
      ) !== undefined;
    return isFileTypeToConvert || hasExtensionToReplace;
  }

  async convertImage(blob: Blob, fileName: string, options?: Partial<Options>) {
    let newFile = blob;
    let newFileName = fileName;

    const replaceExtension = extensionsToConvert.find(
      (e) => e.ext === FileUtils.getFiletype(fileName)
    );

    if (replaceExtension) {
      fileName = FileUtils.replaceFileExtension(
        fileName,
        replaceExtension.replaceWith
      );
    }

    try {
      let file: File = FileUtils.blobToFile(blob, fileName);
      let resizor = await new ImageResizor(file, options).init();
      newFile = await resizor.toBlob();
      newFileName = FileUtils.replaceFileExtension(
        fileName,
        FileUtils.mimeToExt(newFile.type)
      );
    } catch (err) {
      console.error(err);
    }
    return { file: newFile, fileName: newFileName };
  }
  resizeBase64Image(sourceBase64: string, maxWidth: number, maxHeight: number) {
    return new Promise<string>((resolve, reject) => {
      var img = document.createElement("img");
      img.setAttribute("src", sourceBase64);
      img.onload = () => {
        var canvas = document.createElement("canvas");
        var destCanvas = document.createElement("canvas");

        // reduce image size if greater than max size
        if (img.width > maxWidth || img.height > maxHeight) {
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, img.width, img.height);

          var ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
          destCanvas.width = img.width * ratio;
          destCanvas.height = img.height * ratio;
          pica()
            .resize(canvas, destCanvas, {})
            .then((result) => {
              resolve(result.toDataURL());
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          resolve(sourceBase64);
        }
      };
    });
  }
}
const ImageUtils = new ImageUtilsClass();
export default ImageUtils;
(window as any).ImageUtils = ImageUtils;
