import { connect } from "react-redux";
import { TableCache } from "../../../redux/reducers/application/ApplicationInterface";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractComponent,
  AbstractProps,
  AbstractStates,
} from "../../../utils/abstracts/AbstractComponent";
import { setTableFulltext } from "./../../../redux/actions/application/application-actions";
import SearchField from "./SearchField";

type Props = {
  appearance: "on-white" | "default";
  placeholderKey?: string;
  placeholder?: string;
  tableIdentifier?: string;
  _tableIdentifier?: string;
  sizes?: {
    breakpoint?: string;
    focusWidth: string | number;
    blurWidth: string | number;
  }[];
  // fulltextSearch: string;
  tableCache: { [tableIdentifier: string]: TableCache };
  setTableFulltext: (tableIdentifier: string, fulltextSearch: string) => void;
  animate: "left" | "right" | "expand";
} & AbstractProps;

type States = {} & AbstractStates;

class TableSearchField extends AbstractComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    if (this.getFulltextSearch() === this.getFulltextSearch(nextProps)) {
      return false;
    }
    if (super.shouldComponentUpdate(nextProps, nextState)) {
      return true;
    }
    return true;
  }
  getFulltextSearch(props?: Props) {
    const useProps = props ? props : this.props;

    const { tableIdentifier, _tableIdentifier, tableCache } = useProps;

    const table =
      tableCache[
        _tableIdentifier
          ? this.evaluateExpression(_tableIdentifier)
          : tableIdentifier
      ];
    return table && table.fulltextSearch ? table.fulltextSearch : "";
  }

  render() {
    const {
      tableIdentifier,
      _tableIdentifier,
      setTableFulltext,
      ...fieldProps
    } = this.props;
    return (
      <SearchField
        {...fieldProps}
        onChanged={(value) => {
          if (value !== this.getFulltextSearch()) {
            setTableFulltext(
              _tableIdentifier
                ? this.evaluateExpression(_tableIdentifier)
                : tableIdentifier,
              value
            );
          }
        }}
        value={this.getFulltextSearch()}
      />
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH],
  tableCache: state.application.tables,
});

export default connect(mapStateToProps, {
  setTableFulltext,
})(TableSearchField) as any;
