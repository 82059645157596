import { Properties } from "../utils/Properties";

class LogClass {
	get error() {
		if (!Properties.logLevels.error) {
			return () => {};
		}
		return console.error.bind(window.console);
	}
	get state() {
		if (!Properties.logLevels.state) {
			return () => {};
		}
		return console.log.bind(window.console);
	}
	get info() {
		if (!Properties.logLevels.info) {
			return () => {};
		}
		return console.info.bind(window.console);
	}
	get debug() {
		if (!Properties.logLevels.debug) {
			return () => {};
		}
		return console.debug.bind(window.console);
	}
	get warning() {
		if (!Properties.logLevels.warning) {
			return () => {};
		}
		return console.warn.bind(window.console);
	}
}
const Log = new LogClass();
export default Log;
