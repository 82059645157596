import classNames from "classnames";
import {
  GlobalWorkerOptions,
  PDFDocumentLoadingTask,
  getDocument,
} from "pdfjs-dist";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Log from "../../debug/Log";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import { openOrDownloadDocument } from "../../redux/actions/ui-config/ui-config-actions";
import "./PDFPreview.scss";

interface PDFPreviewProps {
  url: string;
  width: number;
  height: number;
}
const PDFPreview = (props: PDFPreviewProps) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLCanvasElement>();
  const refLoadingTask = useRef<PDFDocumentLoadingTask>(null);

  useEffect(() => {
    loadPdf(props.url);
  }, [props.url]);

  useEffect(() => {
    return () => {
      if (refLoadingTask.current) {
        Log.info("PDFViewer.unmount found refLoadingTask");
        try {
          refLoadingTask.current.destroy().then(() => {
            Log.info("PDFViewer.unmount destroyed refLoadingTask");
          });
        } catch (err) {
          Log.info("PDFViewer.unmount err refLoadingTask", err);
        }
      }
    };
  }, []);

  const loadPdf = async (url: string) => {
    let pdfInstance = null;
    try {
      if (refLoadingTask.current) {
        Log.info("PDFPreview.loadPdf found refLoadingTask");
        try {
          await refLoadingTask.current.destroy();
          Log.info("PDFPreview.loadPdf destroyed refLoadingTask");
        } catch (err) {
          Log.info("PDFPreview.loadPdf err refLoadingTask", err);
        }
      }

      GlobalWorkerOptions.workerSrc = "/scripts/pdf.worker.min.js";
      const loadingTask = getDocument(url);
      refLoadingTask.current = loadingTask;

      pdfInstance = await loadingTask.promise;
      refLoadingTask.current = null;

      const firstPage = await pdfInstance.getPage(1);

      let pdfViewport = firstPage.getViewport({
        scale: 1,
      });
      const useScale = props.width / pdfViewport.width;

      pdfViewport = firstPage.getViewport({
        scale: useScale,
      });

      const canvas = ref.current;
      const context = canvas.getContext("2d");
      canvas.height = props.height;
      canvas.width = props.width;

      await firstPage.render({
        canvasContext: context,
        viewport: pdfViewport,
      }).promise;
    } catch (err) {
      Log.error(err);
    } finally {
      if (pdfInstance) {
        await pdfInstance.cleanup();
        await pdfInstance.destroy();
      }
    }
  };
  return (
    <div
      className={classNames(`pdf-preview`)}
      style={{
        width: props.width,
        height: props.height,
      }}
    >
      <canvas
        onError={(err) => {
          //   Log.info(`error in canvas`, err);
        }}
        ref={ref}
      ></canvas>
      <BFButton
        className={`open-preview`}
        onClick={() => {
          dispatch(openOrDownloadDocument(props.url, "pdf"));
        }}
      >
        <BfIcon type="light" data="expand-5" />
      </BFButton>
    </div>
  );
};

export default PDFPreview;
