import classNames from "classnames";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ListComponent from "../../../../../configurable/components/ListComponent/ListComponent";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { Contact } from "../../../../../model/db/Contact";
import InfiniteTable from "../../../../../redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "../../../../../redux/hooks";
import { when } from "../../../../../utils/Helpers";
import MQ from "../../../../../utils/MatchQueryUtils";
import ContactLabel from "../../../../contacts-module/ContactLabel";
import BFCheckbox from "../../../forms/checkbox/BFCheckbox";
import BFInput from "../../../forms/input/BFInput";
import BFButton from "../../../general/Button/BFButton";
import BfIcon from "../../../icon/BfIcon";
import { DefaultIcons } from "../../../icon/DefaultIcons";
import "./OverlayContact.scss";
import OverlayTitle from "./OverlayTitle";

export interface ContactValue {
  contacts: string[];
}

interface OverlayContactProps {
  title: string;
  value: ContactValue;
  filterTypes?: string[];
  onChange: (value: ContactValue) => void;
}

// export const getDefaultIdQueryOptions = (
//   value: IDQueryValue,
//   searchTerm: string,
//   filterKey: string,
//   typeLabel: string,
//   additionalKeys?: string[]
// ) => {
//   let options: FilterSearchOption[] = [];
//   if (searchTerm) {
//     const matches = searchTerm.match(ID_EXTRACT_REGEX);
//     if (matches && matches.length > 0) {
//       const onSelect = (
//         optionValue: any,
//         currentValue: any,
//         onChange: (value: any) => void
//       ) => {
//         onChange({ ...(currentValue || {}), ...optionValue });
//       };
//       if (!hasValue(value)) {
//         options.push({
//           filterKey,
//           valueKey: `_${filterKey}_eq`,
//           label: `${matches[0]}`,
//           value: {
//             value: matches[0],
//           },
//           labelAsString: `${matches[0]} ${typeLabel}`,
//           onSelect,
//           typeLabel,
//           searchKeys: [...(additionalKeys || []), ">"],
//         });
//       }
//     }
//   }
//   return options;
// };
export const generateContactLabel = (
  typeLabel: string,
  value: ContactValue
) => ({
  typeLabel: typeLabel,
  valueLabel:
    value === null || value === undefined || value.contacts?.length === 0 ? (
      "-"
    ) : value.contacts.length === 1 ? (
      <ContactLabel contactId={value.contacts[0]} />
    ) : (
      `${value.contacts.length} ${i18n.t("Global.Label.Contacts", "Kontakte")}`
    ),
});
const OverlayContact = (props: OverlayContactProps) => {
  const [ident] = useState(nanoid());
  const dispatch = useDispatch();
  const tableData = useTypedSelector(
    (state) => state.application.infiniteTables[ident]
  );
  useEffect(() => {
    return () => {
      dispatch(InfiniteTable.clearTable(ident));
    };
  }, []);

  const addContact = (contactId: string) => {
    props.onChange({
      ...props.value,
      contacts: [...(props.value?.contacts || []), contactId],
    });
  };

  const removeContact = (contactId: string) => {
    props.onChange({
      ...props.value,
      contacts: (props.value?.contacts || []).filter((e) => e !== contactId),
    });
  };

  return (
    <div className={classNames(`overlay-contact`)}>
      <OverlayTitle title={props.title} onClear={() => props.onChange(null)} />

      <div className={`selected-contact-list`}>
        {(props.value?.contacts || []).length === 0 && (
          <div className={`no-entries`}>
            {i18n.t(
              "BFFIlter.OverlayContact.notRestricted",
              "Kein Kontakt ausgewählt"
            )}
          </div>
        )}
        {props.value?.contacts?.map((contact) => (
          <div className={`selected-contact-entry`}>
            <ContactLabel contactId={contact} />
            <BFButton
              appearance="link"
              className={`remove-contact-button`}
              noPadding
              onClick={() => {
                removeContact(contact);
              }}
            >
              <BfIcon {...DefaultIcons.REMOVE} size="xxs" />
            </BFButton>
          </div>
        ))}
      </div>
      <div className="search">
        <BFInput
          placeholder={i18n.t("BFTableSearch.searchPlaceholder", "Suche...")}
          appearance="clear"
          value={tableData?.searchTerm || ""}
          onChange={(value: string) =>
            dispatch(InfiniteTable.setSearch(ident, value))
          }
        />
      </div>
      <div className="content">
        <ListComponent
          assetType={AssetTypes.Contact}
          identifier={ident}
          params={{
            selectedContacts: props.value?.contacts || [],
            addContact,
            removeContact,
          }}
          additionalMatchQuery={MQ.and(
            when(props.filterTypes, MQ.in("data.type", props.filterTypes))
          )}
          render={(data: Contact, _index, params: any) => (
            <div className={`entry`}>
              <BFCheckbox
                onChange={(_value, checked) => {
                  if (checked) {
                    params.addContact(data._id);
                  } else {
                    params.removeContact(data._id);
                  }
                }}
                checked={params?.selectedContacts?.includes(data._id)}
              >
                <ContactLabel contact={data} withType />
              </BFCheckbox>
            </div>
          )}
        />
      </div>
      <div className="footer">
        <BFButton
          appearance="link"
          onClick={() => props.onChange({ ...props.value, contacts: [] })}
        >
          {i18n.t("BFFilterbar.Multiple.deselectAll", "Alle abwählen")}
        </BFButton>
      </div>
    </div>
  );
};

export default OverlayContact;
