import React, { useEffect } from "react";
import UsageMetricsService, { MetricsDataBlob } from "./UsageMetricsService";
// import "./WithUsageMetrics.scss";

interface WithUsageMetricsProps {
  children: React.ReactNode;
  onMetrics: (metrics: MetricsDataBlob) => void;
  interval?: number;
}
const WithUsageMetrics = (props: WithUsageMetricsProps) => {
  const onKeyDown = (ev: KeyboardEvent) => {
    // Log.info("keydown");
    UsageMetricsService.registerKeystroke();
  };
  const onMouseMove = (ev: MouseEvent) => {
    // Log.info("mousemove");
    UsageMetricsService.registerMouseAction();
  };
  const onScroll = (ev: Event) => {
    // Log.info("scroll");
    UsageMetricsService.registerScrollAction();
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown, true);
    window.addEventListener("mousemove", onMouseMove, true);
    window.addEventListener("scroll", onScroll, true);
    const interval = setInterval(() => {
      const metrics = UsageMetricsService.getData();
      UsageMetricsService.reset();

      props.onMetrics(metrics);
    }, props.interval || 1000 * 5);

    return () => {
      window.removeEventListener("keydown", onKeyDown, true);
      window.removeEventListener("mousemove", onMouseMove, true);
      window.removeEventListener("scroll", onScroll, true);
      UsageMetricsService.reset();

      clearInterval(interval);
    };
  }, []);
  return <>{props.children}</>;
};

export default WithUsageMetrics;
