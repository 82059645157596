import { DASHBOARD_DEFAULT_CARD_SIZE } from "../modules/configurable-dashboard/ConfigurableDashboardConstants";

export const initGlobalDashboardElements = () => {
  (window as any).GlobalDashboard.registerDashboardComponentConfig({
    id: "notifications",
    layout: DASHBOARD_DEFAULT_CARD_SIZE.list,
    render: (selector) =>
      (window as any).ComponentsMapper.createElement({
        _component: "dashboard.general.notifications",
        selector,
      }),
    title: (window as any).i18n.t(
      "dashboard:notifications.title",
      "Deine Nachrichten"
    ),
    description: (window as any).i18n.t(
      "dashboard:notifications.description",
      "Hier findest du alle Nachrichten, die dich betreffen."
    ),
  });

  (window as any).GlobalDashboard.registerDashboardComponentConfig({
    id: "appdrawer-all",
    layout: DASHBOARD_DEFAULT_CARD_SIZE.mid,
    render: (selector) =>
      (window as any).ComponentsMapper.createElement({
        _component: "dashboard.general.appdrawer",
        selector,
      }),
    title: (window as any).i18n.t("dashboard:appdrawer.title", "Deine Apps"),
    description: (window as any).i18n.t(
      "dashboard:appdrawer.description",
      "Hier findest du alle Apps, die dir zugewiesen sind."
    ),
  });

  (window as any).GlobalDashboard.registerDashboardComponentConfig({
    id: "resubmission-all",
    layout: DASHBOARD_DEFAULT_CARD_SIZE.list,
    render: (selector) =>
      (window as any).ComponentsMapper.createElement({
        _component: "dashboard.general.resubmission",
        selector,
      }),
    title: (window as any).i18n.t(
      "dashboard:resubmission.title",
      "Deine Wiedervorlagen"
    ),
    description: (window as any).i18n.t(
      "dashboard:resubmission.description",
      "Hier findest du alle Wiedervorlagen, die dir zugewiesen sind."
    ),
  });
};
