import { AssetTypes } from "@/model/AssetTypes";
import BaseAsset from "@/model/general-assets/BaseAsset";
import BFAssetChooserSelect from "@/modules/abstract-ui/forms/chooser/BFAssetChooserSelect";
import { ValidatorPopoverStyle } from "@/modules/abstract-ui/general/ValidationPopover/ValidationPopover";
import { MatchQuery } from "@/services/DataService";
import LanguageService from "@/services/LanguageService";
import { when } from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import AccountingService from "../../AccountingService";
import {
  AccountingAccount,
  AccountType,
} from "../../interfaces/account.interface";
import "./AccountSelection.scss";

interface AccountSelectionProps {
  value: string;
  onChange: (value: string, asset: BaseAsset) => void;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  labelPosition?: "top" | "left";
  info?: React.ReactNode;
  label?: string;
  labelSuffix?: React.ReactNode;
  placeholder?: string;
  hideSubLabel?: boolean;
  overlayTopComponent?: (close: () => void) => React.ReactNode;
  overlayBottomComponent?: (close: () => void) => React.ReactNode;
  overlayClassName?: string;
  disabled?: boolean;

  matchQuery?: MatchQuery;
  overlayHeight?: string | number;

  accountTypes?: AccountType[];

  entity?: string;
  objectId?: string;
}
const AccountSelection = (props: AccountSelectionProps) => {
  const { accountTypes, entity, objectId, matchQuery, ...otherProps } = props;
  return (
    <BFAssetChooserSelect
      {...otherProps}
      groups={props.accountTypes?.map((type) => ({
        label: AccountingService.getAccountTypeLabel(type),
        matchQuery: MQ.eq("data.accountType", type),
        value: type,
      }))}
      getOption={(node: AccountingAccount) => ({
        label: LanguageService.translateLabel(node.data.displayName),
        group: node.data.accountType,
        value: node._id,
        subLabel: node.data.internalId || "",
      })}
      assetType={AssetTypes.Accounting.Account}
      matchQuery={MQ.and(
        when(entity, MQ.eq("data.entity", entity)),
        when(objectId, MQ.eq("data.objectId", objectId)),
        matchQuery
      )}
    />
  );
};

export default AccountSelection;
