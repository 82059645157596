import ModalManager from "../../../components/ModalComponent/ModalManager";
import i18n from "../../../i18n";
import { BfIconProps } from "../../abstract-ui/icon/BfIcon";
import { Resubmission } from "../RSInterfaces";
import RSService from "../RSService";
import ResubmissionDoneForm from "../components/forms/ResubmissionDoneForm";

const useOpenResubmissionDoneForm = () => {
  const openResubmissionFeedbackForm = (params: {
    identifierToUpdate?: string;
    resubmission: Resubmission;
    closeResubmission: (id?: string) => void;
  }) => {
    ModalManager.show({
      size: "sm",
      noPadding: true,
      backdrop: "static",
      content: (states, setStates, closeModal) => (
        <ResubmissionDoneForm
          onClose={closeModal}
          listIdentifier={params.identifierToUpdate}
          resubmission={params.resubmission}
          closeResubmission={params.closeResubmission}
        />
      ),
    });
  };

  const openResubmissionDoneDialog = (params: {
    identifierToUpdate?: string;
    resubmission: Resubmission;
    closeResubmission: (id?: string) => void;
  }) => {
    if (
      params.resubmission.data.feedbackRecipients?.users?.length > 0 ||
      params.resubmission.data.feedbackRecipients?.teams?.length > 0
    ) {
      openResubmissionFeedbackForm(params);
      return;
    }

    const confirm = async () => {
      const values = {
        id: params.resubmission._id,
      };
      await RSService.submitResubmissionDoneAsync(
        values,
        params.identifierToUpdate
      );
      params.closeResubmission(params.resubmission._id);
    };

    ModalManager.confirm({
      title: i18n.t("Resubmission.Title.Done", "Wiedervorlage abschließen"),
      message: i18n.t(
        "Resubmission.Form.Done.Confirm",
        "Möchten Sie die Wiedervorlage wirklich abschließen?"
      ),
      onConfirm: () => {
        confirm();
      },
      onAbort: () => {},
      size: "sm",
    });
  };

  const openResubmissionDoneBtnIconProps: BfIconProps = {
    type: "light",
    size: "sm",
    data: "check-1",
  };

  return { openResubmissionDoneDialog, openResubmissionDoneBtnIconProps };
};

export default useOpenResubmissionDoneForm;
