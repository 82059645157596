import { MatchQuery } from "../../../../../services/DataService";
import { BFInputProps } from "../../../forms/input/BFInput";
import { BfIconProps } from "../../../icon/BfIcon";
import { FilterOptionWithMatchQueryConverter } from "../BFTableFilterModel";
import OverlayIdQuery, {
  IDQueryValue,
  generateLabelForIdFilter,
  generateMatchQueryForIdFilter,
  getDefaultIdQueryOptions,
} from "../overlays/OverlayIdQuery";

export const IDFilterOption: (opts: {
  field: string;
  label: string;
  icon?: BfIconProps;
  type?: "string" | "number";
  inputProps?: Partial<BFInputProps>;
  matchQueryConverter?: (value: any) => MatchQuery | null;
}) => FilterOptionWithMatchQueryConverter = (opts) => {
  const { field, label, icon } = opts;
  const matchQueryConverter =
    opts.matchQueryConverter ||
    ((value: any) =>
      generateMatchQueryForIdFilter(field, value, opts.type || "number"));

  return {
    matchQueryConverter: matchQueryConverter,
    key: field,
    label: label,
    icon: {
      type: icon?.type || "light",
      data: icon?.data || "symbol-equal",
    },
    renderOverlay: (value, onChange) => (
      <OverlayIdQuery
        title={label}
        value={value}
        onChange={onChange}
        type={opts.type || "number"}
        inputProps={opts.inputProps}
      />
    ),
    renderValueData: (value: IDQueryValue) => ({
      typeLabel: label,
      valueLabel: generateLabelForIdFilter(value) as string,
    }),
    getOptions: (currentValue, searchTerm) =>
      getDefaultIdQueryOptions(currentValue, searchTerm, field, label),
    defaultValue: {
      value: "",
    },
  } as FilterOptionWithMatchQueryConverter;
};
