import React, { Component } from "react";
import { CheckboxGroup } from "rsuite";
import ValidationPopover, { ValidatorPopoverStyle } from "../../general/ValidationPopover/ValidationPopover";
import "./BFCheckGroup.scss";

type Props = {
	defaultValue?: any;
	inline?: boolean;
	name?: string;
	onChange?: (value: any, event: any) => void;
	value?: any;
	children: React.ReactNode[];

	validation?: {
		message: string;
		level: "error" | "warning";
	};
	validatorStyle?: ValidatorPopoverStyle;
};

type States = {};

class BFCheckGroup extends Component<Props, States> {
	render() {
		const { validation, validatorStyle, ...rest } = this.props;

		return (
			<ValidationPopover
				validatorStyle={validatorStyle}
				level={validation ? validation.level : "error"}
				message={validation ? validation.message : null}
			>
				<CheckboxGroup {...rest}>{this.props.children}</CheckboxGroup>
			</ValidationPopover>
		);
	}
}

export default BFCheckGroup;
