import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Customization from "../../../../redux/actions/customization/customization-actions";
import { ColumnConfigWithIdentifier } from "./BFVirtualizedTable";

interface BFVirtualizedTableResizeHandlerProps {
  tableIdentifier: string;
  column: ColumnConfigWithIdentifier;
  persistCustomizations?: boolean;
}

type ResizeState = {
  pageX: number;
  startWidth: number;
};
const BFVirtualizedTableResizeHandler = (
  props: BFVirtualizedTableResizeHandlerProps
) => {
  const ref = useRef<HTMLDivElement>(null);
  const [reziseState, setResizeState] = useState<ResizeState>(null);

  const onMouseMove = (event: MouseEvent) => {
    if (reziseState) {
      var diffX = event.pageX - reziseState.pageX;
      dispatch(
        Customization.customizeInfiniteTableColumns(
          props.tableIdentifier,
          {
            [props.column.identifier]: {
              flexWidth: Math.min(
                Math.max(
                  props.column.resizableOptions?.min || 0,
                  reziseState.startWidth + diffX
                ),
                props.column.resizableOptions?.max || Number.MAX_SAFE_INTEGER
              ),
            },
          },
          props.persistCustomizations
        )
      );
    }
  };
  const onMouseUp = (event: MouseEvent) => {
    if (reziseState) {
      setResizeState(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [reziseState, props.column]);

  const dispatch = useDispatch();
  return (
    <div
      ref={ref}
      className={classNames(`bf-virtualized-table-resize-handler`, {
        active: reziseState !== null,
      })}
      onMouseDown={(e) => {
        setResizeState({
          pageX: e.pageX,
          startWidth: props.column.flexWidth,
        });
      }}
    ></div>
  );
};

BFVirtualizedTableResizeHandler.defaultProps =
  {} as Partial<BFVirtualizedTableResizeHandlerProps>;

export default BFVirtualizedTableResizeHandler;
