import React, { Component } from "react";

interface States {}

const SCROLLING_ACTIVE_CLASSNAME = "scrolling-active";
const MOVING_ACTIVE_CLASSNAME = "moving-active";
class TouchScrollableDiv extends Component<
  {
    onScrollChange: (el: HTMLDivElement) => void;
    wheelHorizontalScroll?: boolean;
    scrollHandlerThreshold?: number;
    onScrollStateHandler?: (state: "start" | "stop") => void;
  } & React.HTMLAttributes<HTMLDivElement>,
  States
> {
  divRef = React.createRef<any>();
  isDown = false;
  startX;
  startY;
  scrollLeft;
  scrollTop;
  scrollStarted = false;
  speedX = 0;
  speedY = 0;
  lastX;
  lastY;

  touchpadScroll = false;
  touchpadScrollTimeout = null;

  animReqFrame = 0;

  thresholdPassed = false;

  getRef() {
    return this.divRef;
  }
  componentDidMount() {
    const { current } = this.divRef;

    const startEndAnim = () => {
      if (!this.thresholdPassed) {
        return;
      }
      var start = 0.2;
      const animate = () => {
        var step = Math.cos(start);
        if (step <= 0) {
          window.cancelAnimationFrame(this.animReqFrame);
          this.speedX = 0;
          this.speedY = 0;
        } else {
          current.scrollTo(
            current.scrollLeft -
              Math.max(-50, Math.min(50, this.speedX)) * 1.4 * step,
            current.scrollTop -
              Math.max(-50, Math.min(50, this.speedY)) * 1.4 * step
          );
          // current.scrollLeft -=
          //   Math.max(-50, Math.min(50, this.speedX)) * 1.4 * step;
          // current.scrollTop -=
          //   Math.max(-50, Math.min(50, this.speedY)) * 1.4 * step;
          start -= 0.05;
          // current;
          this.props.onScrollChange(current);
          this.animReqFrame = window.requestAnimationFrame(animate);
        }
        return 0;
      };
      animate();
    };
    if (this.props.wheelHorizontalScroll) {
      current.addEventListener("mousewheel", (e) => {
        if (this.animReqFrame) {
          window.cancelAnimationFrame(this.animReqFrame);
          this.animReqFrame = 0;
        }
        if (Math.abs(e.wheelDelta) < 100) {
          this.touchpadScroll = true;
        }
        if (this.touchpadScroll) {
          clearTimeout(this.touchpadScrollTimeout);
          this.touchpadScrollTimeout = setTimeout(
            () => (this.touchpadScroll = false),
            100
          );
        }

        if (
          !this.touchpadScroll &&
          current.scrollHeight <= current.getBoundingClientRect().height
        ) {
          e.preventDefault();
          current.scrollTo(current.scrollLeft + e.deltaY, 0, {
            behaviour: "smooth",
          });
        } else {
        }
      });
    }

    current.addEventListener("mousedown", (e) => {
      this.thresholdPassed = false;
      window.cancelAnimationFrame(this.animReqFrame);
      this.isDown = true;
      this.scrollStarted = false;
      current.classList.add(SCROLLING_ACTIVE_CLASSNAME);
      this.startX = e.pageX - current.offsetLeft;
      this.scrollLeft = current.scrollLeft;
      this.startY = e.pageY - current.offsetTop;
      this.scrollTop = current.scrollTop;
    });
    current.addEventListener("mouseleave", (e) => {
      this.isDown = false;
      this.scrollStarted = false;
      current.classList.remove(SCROLLING_ACTIVE_CLASSNAME);
      current.classList.remove(MOVING_ACTIVE_CLASSNAME);
      if (this.props.onScrollStateHandler) {
        this.props.onScrollStateHandler("stop");
      }
      startEndAnim();
    });
    current.addEventListener("mouseup", (e) => {
      const wasScrolling = this.scrollStarted;
      this.isDown = false;
      this.scrollStarted = false;
      current.classList.remove(SCROLLING_ACTIVE_CLASSNAME);
      current.classList.remove(MOVING_ACTIVE_CLASSNAME);
      if (this.props.onScrollStateHandler) {
        this.props.onScrollStateHandler("stop");
      }
      if (wasScrolling) {
        startEndAnim();
      }
    });
    current.addEventListener("mousemove", (e) => {
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - current.offsetLeft;
      const y = e.pageY - current.offsetTop;

      const walkX = (x - this.startX) * 1; //scroll-fast
      const walkY = (y - this.startY) * 1; //scroll-fast

      if ((!this.thresholdPassed && Math.abs(walkX) > 5) || Math.abs(walkY)) {
        this.thresholdPassed = true;

        current.classList.add(MOVING_ACTIVE_CLASSNAME);
      }

      this.speedX = x - this.lastX;
      this.speedY = y - this.lastY;

      this.lastX = x;
      this.lastY = y;
      current.scrollLeft = this.scrollLeft - walkX;
      current.scrollTop = this.scrollTop - walkY;

      if (!this.scrollStarted) {
        const treshold = this.props.scrollHandlerThreshold || 5;
        if (Math.abs(walkX) > treshold || Math.abs(walkY) > treshold) {
          this.scrollStarted = true;
          if (this.props.onScrollStateHandler) {
            this.props.onScrollStateHandler("start");
          }
        }
      }
    });
  }

  render() {
    const {
      children,
      onScrollChange,
      wheelHorizontalScroll,
      scrollHandlerThreshold,
      onScrollStateHandler,
      ...divProps
    } = this.props;
    return (
      <div
        {...divProps}
        ref={this.divRef}
        onScroll={(ev) => {
          const el = ev.target as any;
          this.props.onScrollChange(el);
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default TouchScrollableDiv;
