import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import BFButton from "../../../modules/abstract-ui/general/Button/BFButton";
import ChartComponent, { ChartComponentProps } from "./ChartComponent";
import "./HeatmapChartComponent.scss";

interface HeatmapChartComponentProps
  extends Omit<ChartComponentProps, "options"> {
  data: [Date, number][];
  identifier: string;
  hideLegend?: boolean;
}
const HeatmapChartComponent = (props: HeatmapChartComponentProps) => {
  const chartRef = useRef(null);
  const { data, ...chartProps } = props;
  const dates = data.map((d) => d[0]);
  const min = _.min(dates);
  const max = _.max(dates);
  const yearMin = new Date(min ? min : new Date()).getFullYear();
  const yearMax = new Date(max ? max : new Date()).getFullYear();
  const currentYear = new Date().getFullYear();

  const [yearsArray, setYearsArray] = useState(
    new Array(yearMax - yearMin + 1).fill(0).map((_, i) => yearMin + i)
  );
  const [year, setYear] = useState(
    currentYear > yearMin && currentYear < yearMax ? currentYear : yearMin
  );

  useEffect(() => {
    setYearsArray(
      new Array(yearMax - yearMin + 1).fill(0).map((_, i) => yearMin + i)
    );
    setYear(
      currentYear > yearMin && currentYear < yearMax ? currentYear : yearMin
    );
    setTimeout(() => {
      chartRef?.current?.resize({
        silent: true,
      });
    });
  }, [props.data]);

  return (
    <div className={`heatmap-chart-component`}>
      {yearsArray.length > 1 && (
        <div className={`year-selection`}>
          {yearsArray.map((y) => (
            <div>
              <BFButton
                onClick={() => setYear(y)}
                key={y}
                appearance={y === year ? "primary" : "default"}
              >
                {y}
              </BFButton>
            </div>
          ))}
        </div>
      )}
      <div className={`chart-component-wrapper`}>
        <ChartComponent
          {...chartProps}
          onMounted={(ident, chart) => {
            props.onMounted?.(ident, chart);
            chartRef.current = chart;
          }}
          options={{
            tooltip: {
              position: "top",
            },
            visualMap: {
              show: !props.hideLegend,
              min: 0,
              max: _.max(props.data.map((e) => e[1])) || 1,
              calculable: true,
              orient: "horizontal",
              left: "center",
              top: "top",
            },
            calendar: [
              {
                range: year,
                cellSize: ["auto", 20],
              },
            ],
            series: [
              {
                type: "heatmap",
                coordinateSystem: "calendar",
                data: data.filter((e) => new Date(e[0]).getFullYear() === year),
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default HeatmapChartComponent;
