import { useDatabus } from "@/redux/hooks";
import DataBus from "@/services/DataBus";
import { DataBusSubKeys } from "@/utils/Constants";
import { hasValue, isDefined } from "@/utils/Helpers";
import { useState } from "react";
import { PDFHighlight } from "./PDFViewer";

export type PDFDocumentHighlight = PDFHighlight & {
  index?: number;
  cdnID?: string;
};
export type PDFEditRect = {
  id: string;
  page: number;
  top: number;
  left: number;
  width: number;
  height: number;
};
export type PDFDocumentRect = PDFEditRect & {
  cdnID?: string;
  index?: number;
};

export const usePdfMarkerEditState = (
  value: PDFDocumentRect[],
  onChange: (rects: PDFDocumentRect[]) => void | undefined,
  identifier: string,
  pdfViewerIdentifier: string,
  editHint: string,
  viewHint: string
) => {
  const [editActive, setEditActive] = useState<boolean>(false);

  useDatabus(
    DataBusSubKeys.PDF_VIEWER_EDIT_ABORT,
    (data) => {
      if (
        data.pdfViewerIdentifier === pdfViewerIdentifier &&
        data.identifier === identifier
      ) {
        setEditActive(false);

        DataBus.emit(DataBusSubKeys.PDF_VIEWER_START_VIEW_MODE, {
          identifier,
          pdfViewerIdentifier,
          value: (value || []).map(
            (val, index) =>
              ({
                id: val.id,
                top: val.top,
                left: val.left,
                width: val.width,
                height: val.height,
                page: val.page,
                cdnID: val.cdnID,
                index: val.index,
                // scrollIntoView: index === 0,
                value: "",
              } as PDFHighlight)
          ),
          hint: viewHint,
        });
      }
    },
    [value, onChange, identifier, pdfViewerIdentifier]
  );

  useDatabus(
    DataBusSubKeys.PDF_VIEWER_EDIT_SAVE,
    (data) => {
      if (
        data.pdfViewerIdentifier === pdfViewerIdentifier &&
        data.identifier === identifier
      ) {
        setEditActive(false);
        onChange?.(data.value);

        DataBus.emit(DataBusSubKeys.PDF_VIEWER_START_VIEW_MODE, {
          identifier,
          pdfViewerIdentifier,
          value: (data.value || []).map(
            (val, index) =>
              ({
                id: val.id,
                top: val.top,
                left: val.left,
                width: val.width,
                height: val.height,
                page: val.page,
                cdnID: val.cdnID,
                index: val.index,
                // scrollIntoView: index === 0,
                value: "",
              } as PDFHighlight)
          ),
          hint: viewHint,
        });
      }
    },
    [value, onChange, identifier, pdfViewerIdentifier]
  );

  const startEdit = () => {
    DataBus.emit(DataBusSubKeys.PDF_VIEWER_START_EDIT_MODE, {
      identifier,
      pdfViewerIdentifier,
      value,
      hint: editHint,
    });
    setEditActive(true);
  };
  const abortEdit = () => {
    DataBus.emit(DataBusSubKeys.PDF_VIEWER_STOP_EDIT_MODE, {
      identifier,
      pdfViewerIdentifier,
    });
    setEditActive(false);
  };
  return {
    editActive,
    startEdit,
    abortEdit,
  };
};

export const usePdfMarkerHighlightState = (
  value: PDFDocumentRect[],
  identifier: string,
  pdfViewerIdentifier: string,
  viewHint: string
) => {
  const endShowOnPdf = () => {
    DataBus.emit(DataBusSubKeys.PDF_VIEWER_STOP_VIEW_MODE, {
      identifier,
      pdfViewerIdentifier,
    });
  };
  const showOnPdf = (values?: PDFDocumentRect[]) => {
    if ((isDefined(values) ? values : value || []).length > 0) {
      DataBus.emit(DataBusSubKeys.PDF_VIEWER_START_VIEW_MODE, {
        identifier,
        pdfViewerIdentifier,
        value: (isDefined(values) ? values : value || []).map(
          (val, index) =>
            ({
              id: val.id,
              top: val.top,
              left: val.left,
              width: val.width,
              height: val.height,
              page: val.page,
              // scrollIntoView: index === 0,
              cdnID: val.cdnID,
              index: val.index,
              value: "",
            } as PDFDocumentHighlight)
        ),
        hint: viewHint,
      });
    } else {
      DataBus.emit(DataBusSubKeys.PDF_VIEWER_STOP_VIEW_MODE, {
        identifier,
        pdfViewerIdentifier,
      });
    }
  };

  return {
    showOnPdf,
    endShowOnPdf,
  };
};

export type PDFHightlightState = {
  viewForIdentifier: string;
  viewActive: boolean;
  viewValues: PDFDocumentHighlight[];
  viewHint: string;
  setViewValues: (values: PDFDocumentHighlight[]) => void;
  stopView: () => void;
};
export const usePdfHighlightState = (identifier?: string) => {
  const [viewForIdentifier, setViewForIdentifier] = useState<string>(null);
  const [viewActive, setViewActive] = useState<boolean>(false);
  const [viewHint, setViewHint] = useState<string>(null);
  const [viewValues, setViewValues] = useState<PDFDocumentHighlight[]>(null);
  useDatabus(
    DataBusSubKeys.PDF_VIEWER_START_VIEW_MODE,
    (data) => {
      if (hasValue(identifier) && data.pdfViewerIdentifier === identifier) {
        setViewValues(data.value || []);
        setViewHint(data.hint);
        setViewActive(true);
        setViewForIdentifier(data.identifier);
      }
    },
    [identifier]
  );

  useDatabus(
    DataBusSubKeys.PDF_VIEWER_STOP_VIEW_MODE,
    (data) => {
      if (hasValue(identifier) && data.pdfViewerIdentifier === identifier) {
        stopView();
      }
    },
    [identifier]
  );

  const stopView = () => {
    setViewActive(false);
    setViewForIdentifier(null);
    setViewValues(null);
  };
  if (!identifier) {
    return undefined;
  }
  return {
    viewForIdentifier,
    viewActive,
    setViewValues,
    viewValues,
    viewHint,
    stopView,
  } as PDFHightlightState;
};

export type PDFViewerEditState = {
  editActive: boolean;
  editHint: string;
  value: PDFDocumentHighlight[];
  onChange: React.Dispatch<React.SetStateAction<PDFDocumentHighlight[]>>;
  abort: () => void;
  save: () => void;
};
export const usePdfViewerEditState = (identifier?: string) => {
  const [editForIdentifier, setEditForIdentifier] = useState<string>(null);
  const [editActive, setEditActive] = useState<boolean>(false);
  const [editValues, setEditValues] = useState<PDFDocumentHighlight[]>(null);
  const [editHint, setEditHint] = useState<string>(null);
  // console.log("pdfviewereditstate", {
  //   editForIdentifier,
  //   editActive,
  //   editValues,
  //   editHint,
  // });
  useDatabus(
    DataBusSubKeys.PDF_VIEWER_START_EDIT_MODE,
    (data) => {
      if (hasValue(identifier) && data.pdfViewerIdentifier === identifier) {
        setEditValues(data.value || []);
        setEditHint(data.hint);
        setEditActive(true);
        setEditForIdentifier(data.identifier);
      }
    },
    [identifier]
  );

  useDatabus(
    DataBusSubKeys.PDF_VIEWER_STOP_EDIT_MODE,
    (data) => {
      if (hasValue(identifier) && data.pdfViewerIdentifier === identifier) {
        setEditActive(false);
        setEditValues(null);
        setEditForIdentifier(null);
      }
    },
    [identifier]
  );

  const abort = () => {
    DataBus.emit(DataBusSubKeys.PDF_VIEWER_EDIT_ABORT, {
      identifier: editForIdentifier,
      pdfViewerIdentifier: identifier,
    });
    setEditActive(false);
    setEditValues(null);
    setEditForIdentifier(null);
  };
  const save = () => {
    DataBus.emit(DataBusSubKeys.PDF_VIEWER_EDIT_SAVE, {
      identifier: editForIdentifier,
      pdfViewerIdentifier: identifier,
      value: editValues,
    });
    setEditActive(false);
    setEditValues(null);
    setEditForIdentifier(null);
  };

  if (!identifier) {
    return undefined;
  }
  return {
    editActive,
    editHint,
    value: editValues,
    onChange: setEditValues,
    abort,
    save,
  };
};
