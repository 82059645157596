import { ActivityAbstractStructClass } from "@/redux/actions/struct/implemented/ActivityAbstractStruct";
import moment from "moment";
import { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { Drawer } from "rsuite";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../components/Form/Validation/FormValidators";
import LoadPage from "../../../../../../components/LoadPage/LoadPage";
import i18n from "../../../../../../i18n";
import BFInput from "../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFMessage from "../../../../../../modules/abstract-ui/general/Message/BFMessage";
import PlanTable, {
  PlanTableEntry,
  PlanTableValue,
} from "../../../../../../modules/plan-table/PlanTable";
import CategoryStruct from "../../../../../../redux/actions/struct/implemented/CategoryStruct";
import { useStatisticQuery } from "../../../../../../redux/hooks";
import DataBusDefaults from "../../../../../../services/DataBusDefaults";
import LanguageService from "../../../../../../services/LanguageService";
import { hasValue } from "../../../../../../utils/Helpers";
import { APStatusTags } from "../../../../../AppConfigInterfaces";
import {
  ActivityApplicationConstants,
  useActivityConstants,
} from "../../../ActivityHooks";
import { APActivity } from "../../../ActivityInterfaces";
import ActivityService from "../../../ActivityService";
import { useActivityStruct } from "../../../ActivityStructContext";
import APBudgetDrawer from "./APBudgetDrawer";
import "./APBudgetForm.scss";
import { APBudget, BudgetPlanEntry } from "./APBudgetInterfaces";

interface APBudgetFormProps {
  readonly?: boolean;
  activity: APActivity;
  budget?: APBudget;
  currentBudget?: APBudget;
  showActualData?: boolean;
  onSubmit?: (result: APBudget) => void;
}

// const convertScheduleDataToForm = (
//   scheduleEntries: ProjectScheduleEntry[],
//   scheduleGroups: ProjectScheduleGroup[]
// ) => {
//   const tasks: GanttTask[] = [];
//   scheduleGroups?.forEach((group) => {
//     tasks.push({
//       id: group.id,
//       name: group.name,
//       progress: scheduleEntries?.find((e) => e.groupId === group.id) ? 100 : 0,
//       start: new Date(group.fromDate),
//       end: new Date(group.toDate),
//       parent: group.parentId,
//     });
//   });
//   return tasks;
// };
// const convertScheduleFormToData = (tasks: GanttTask[]) => {
//   const scheduleGroups: ProjectScheduleGroup[] = [];

//   tasks.forEach((task) => {
//     scheduleGroups.push({
//       id: task.id,
//       fromDate: task.start,
//       name: task.name,
//       toDate: task.end,
//       parentId: task.parent,
//     });
//   });

//   return scheduleGroups;
// };

const convertInitialValues = (
  type: string,
  constants: ActivityApplicationConstants,
  activityStruct: ActivityAbstractStructClass<any>,
  budget?: APBudget
) => {
  const conf = activityStruct.getConfig(type);
  const groupIds = conf?.data.planlqBookingCategory || [];

  const categories = groupIds.map((id) =>
    CategoryStruct.getData(type).find((g) => g._id === id)
  );

  return {
    turnusInYears: budget?.data.turnusInYears || 1,
    startYear: budget?.data.startYear || moment().year(),
    budget: {
      entries:
        budget?.data.entries.map((e) => ({
          date: e.date,
          values: Object.fromEntries(e.values.map((v) => [v.groupId, v.value])),
        })) || [],
      groups: categories.map((g) => ({
        id: g._id,
        name: LanguageService.translateLabel(g.data.displayName),
      })),
      from: budget?.data.fromDate || new Date("1970-01-01T00:00:00.000Z"),
      to: budget?.data.toDate || new Date("1970-12-31T00:00:00.000Z"),
    },
  } as FormValues;
};
const convertToSubmit = (values: FormValues) => {
  const result: {
    entries: BudgetPlanEntry[];
    turnusInYears?: number;
    startYear?: number;
    fromDate?: Date;
    toDate?: Date;
  } = {
    entries: [],
    turnusInYears: values.turnusInYears,
    startYear: values.startYear,
    fromDate: values.budget.from,
    toDate: values.budget.to,
  };

  values.budget.entries.map((entry) => {
    result.entries.push({
      date: entry.date,
      values: Object.entries(entry.values).map(([groupId, value]) => {
        return {
          groupId,
          value,
        };
      }),
    });
  });

  return result;
};
interface FormValues {
  budget: PlanTableValue;
  startYear: number;
  turnusInYears: number;
  showActualData?: boolean;
}

const convertActualData = (
  data: {
    _id: { month: number; year: number; categoryId: string };
    value: number;
  }[]
) => {
  const actualValue: PlanTableEntry[] = [];

  data.forEach((entry) => {
    const date = moment()
      .year(entry._id.year)
      .month(entry._id.month)
      .utc(true)
      .startOf("month");

    const actualEntry = actualValue.find((e) =>
      moment(e.date).isSame(date, "month")
    );

    if (!actualEntry) {
      actualValue.push({
        date: date.toDate(),
        values: {
          [entry._id.categoryId]: entry.value,
        },
      });
    } else {
      actualEntry.values[entry._id.categoryId] = entry.value;
    }
  });
  return actualValue;
};

const APBudgetForm = (props: APBudgetFormProps) => {
  const [drawerActive, setDrawerActive] = useState<{
    id: string;
    type: "group" | "subGroup";
    date: Date;
    value: number;
  }>(null);
  const activityStruct = useActivityStruct();
  const constants = useActivityConstants();
  const [iniital, setInitial] = useState(
    convertInitialValues(
      props.activity.data.type,
      constants,
      activityStruct,
      props.budget
    )
  );
  const [convertedActual, setActual] = useState<PlanTableEntry[]>([]);
  const actualData = useStatisticQuery<
    {
      _id: { month: number; year: number; categoryId: string };
      value: number;
    }[]
  >("PROJECT_BOOKED_DETAIL", 1, {
    projectId: props.activity._id,
  });

  useEffect(() => {
    if (actualData.data) {
      setActual(convertActualData(actualData.data));
    }
  }, [actualData.data]);

  useEffect(() => {
    setInitial(
      convertInitialValues(
        props.activity.data.type,
        constants,
        activityStruct,
        props.budget
      )
    );
  }, [props.budget]);

  const status = activityStruct.getStatus(
    props.activity.data.type,
    props.activity.data.status
  );

  const isInApproval = activityStruct
    .getStatus(props.activity.data.type, props.activity.data.status)
    ?.tags.includes(APStatusTags.BUDGET_IN_APPROVAL);

  const isReadOnly = isInApproval || props.readonly;
  if (actualData.loading) {
    return <LoadPage />;
  }
  const renderDrawer = (drawerActive: {
    id: string;
    type: "group" | "subGroup";
    date: Date;
    value: number;
  }) => {
    if (!drawerActive) {
      return null;
    }
    let title, subTitle, ids;
    if (drawerActive.type === "group") {
      // const group = props.budget?.data.groups.find(
      //   (e) => e.id === drawerActive.id
      // );
      // title = group?.name;
      // ids = group?.children.map((e) => e.id);
    }
    if (drawerActive.type === "subGroup") {
      // const groupsFlattened =
      //   props.budget?.data.groups
      //     .map((group) => [
      //       { ...group, parent: null },
      //       ...(group.children?.map((e) => ({ ...e, parent: group.id })) || []),
      //     ])
      //     .flat() || [];
      // const group = groupsFlattened.find((e) => e.id === drawerActive.id);
      // title = group?.name;
      // subTitle = groupsFlattened.find((e) => e.id === group?.parent)?.name;
      // ids = [group.id];
    }

    return (
      <APBudgetDrawer
        activity={props.activity}
        ids={ids}
        title={title}
        subTitle={subTitle}
        date={drawerActive.date}
        sum={drawerActive.value}
      />
    );
  };
  return (
    <FormStruct
      className={`ap-budget-form`}
      notModal
      onSubmit={async (values: FormValues) => {
        const converted = convertToSubmit(values);

        let draft;
        if (constants.fields?.budgetFeatureType === "timespan") {
          draft = await ActivityService.saveBudgetDraftTimespan(
            constants.serviceUrl,
            props.activity._id,
            converted.entries,
            converted.fromDate,
            converted.toDate
            // entries: ProjectBudgetPlanEntry[],
            // fromDate: Date,
            // toDate: Date
          );
        } else {
          draft = await ActivityService.saveBudgetDraft(
            constants.serviceUrl,
            props.activity._id,
            converted.entries,
            converted.startYear,
            converted.turnusInYears
          );
        }

        props.onSubmit(draft);

        DataBusDefaults.toast({
          type: "success",
          text: i18n.t(
            "apTemplate:Activity.Budget.BudgetSaved",
            "Budget wurde gespeichert"
          ),
        });
      }}
      cancelText={i18n.t("apTemplate:Activity.Budget.Cancel", "Abbrechen")}
      onAbort={() => {
        setInitial(
          convertInitialValues(
            props.activity.data.type,
            constants,
            activityStruct,
            props.budget
          )
        );
      }}
      submitText={
        props.budget
          ? i18n.t("apTemplate:Activity.Budget.Save", "Budgetplan speichern")
          : i18n.t("apTemplate:Activity.Budget.Create", "Budgetplan erstellen")
      }
      ignoreSubmitOnEnter
      noPadding
      actionsOnChange
      usePrompt
      initialValues={iniital}
      render={(formProps) => (
        <>
          {!props.readonly && (
            <BFMessage
              type="info"
              closable
              text={
                isInApproval
                  ? i18n.t(
                      "apTemplate:Activity.Budget.InApproval",
                      "Das Budget befindet sich aktuell in der Genehmigung. Es besteht keine Möglichkeit das Budget zu bearbeiten, bis die Genehmigung abgeschlossen ist."
                    )
                  : i18n.t(
                      "apTemplate:Activity.Budget.BudgetInPlanningText",
                      "Das Budget befindet sich in der Planungsphase. Sie haben die Möglichkeit Änderungen im Plan durchzuführen ",
                      {
                        ns: ["apTemplate"],
                      }
                    )
              }
            />
          )}
          {!props.readonly &&
            !status?.tags?.includes(APStatusTags.BUDGET_IN_APPROVAL) &&
            props.budget && (
              <div className={`send-to-approval`}>
                <BFButton
                  disabled={
                    formProps.dirty ||
                    (props.budget.data.entries || []).length === 0
                  }
                  onClick={async () => {
                    const draft = await ActivityService.publishBudgetDraft(
                      constants.serviceUrl,
                      props.activity._id,
                      props.budget._id
                    );
                    props.onSubmit(draft);

                    DataBusDefaults.toast({
                      type: "success",
                      text: i18n.t(
                        "apTemplate:Activity.Budget.SentToApprovalSuccess",
                        "Budget wurde zur Genehmigung gesendet"
                      ),
                    });
                  }}
                >
                  {i18n.t(
                    "apTemplate:Activity.Budget.SendToApproval",
                    "Zur Genehmigung senden"
                  )}
                </BFButton>
              </div>
            )}

          {constants.fields?.budgetFeatureType === "fixYearPlan" && (
            <div className={`further-fields`}>
              <div className={`field start-year`}>
                <Field
                  name="startYear"
                  validate={FormValidators.compose(
                    FormValidators.required(),
                    FormValidators.min(new Date().getFullYear() - 100)
                  )}
                >
                  {({ input, meta }) => (
                    <BFInput
                      {...input}
                      {...FormValidators.getValidation(meta)}
                      readonly={isReadOnly}
                      type="number"
                      prefix={i18n.t(
                        "apTemplate:Activity.Budget.StartYear",
                        "Startjahr"
                      )}
                    />
                  )}
                </Field>
              </div>
              <div className={`field turnus`}>
                <Field
                  name="turnusInYears"
                  validate={FormValidators.compose(
                    FormValidators.required(),
                    FormValidators.min(1)
                  )}
                >
                  {({ input, meta }) => (
                    <BFInput
                      {...input}
                      readonly={isReadOnly}
                      {...FormValidators.getValidation(meta)}
                      type="number"
                      prefix={i18n.t(
                        "apTemplate:Activity.Budget.TurnusInYears",
                        "Turnus"
                      )}
                      suffix={i18n.t(
                        "apTemplate:Activity.Budget.Years",
                        "Jahr(e)"
                      )}
                    />
                  )}
                </Field>
              </div>
            </div>
          )}
          <div className={`budget-plan`}>
            <Field name={"budget"}>
              {({ input, meta }) => (
                <PlanTable
                  planType={constants.fields?.budgetFeatureType || "timespan"}
                  // onDoubleClick={(val, index) => {
                  //   setDrawerActive(val);
                  // }}
                  // startBudget={50000}
                  actualValue={
                    props.showActualData ? convertedActual || [] : null
                  }
                  type="month"
                  readonly={isReadOnly}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>

            <Drawer
              size="md"
              open={hasValue(drawerActive)}
              onClose={() => setDrawerActive(null)}
              className={`budget-plan-drawer`}
            >
              {drawerActive && renderDrawer(drawerActive)}
            </Drawer>
          </div>
        </>
      )}
    />
  );
};

export default APBudgetForm;
