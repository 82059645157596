import classNames from "classnames";
import _ from "lodash";
import i18n from "../../../../../i18n";
import BFSelect from "../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButtonToggle from "../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import { ActivityAbstractStructClass } from "../../../../../redux/actions/struct/implemented/ActivityAbstractStruct";
import CategoryStruct from "../../../../../redux/actions/struct/implemented/CategoryStruct";
import UnitStruct from "../../../../../redux/actions/struct/implemented/UnitStruct";
import LanguageService from "../../../../../services/LanguageService";
import PermissionService from "../../../../../services/PermissionService";
import ArrayUtils from "../../../../../utils/ArrayUtils";
import { useActivityConstants } from "../../ActivityHooks";
// import "./APCategoryQuickfilter.scss";

interface APCategoryQuickfilterProps {
  onChange: (value: string) => void;
  value: string;
  structToUse: ActivityAbstractStructClass<any>;
}

const MAX_COUNT_TOGGLE_GROUP = 7;
const APCategoryQuickfilter = (props: APCategoryQuickfilterProps) => {
  const constants = useActivityConstants();

  const types = PermissionService.hasBusinessUnitRole(
    `${constants?.permissionPrefix}listActivities`
  );
  const categories = ArrayUtils.sortData(
    types
      .map((type) =>
        (
          props.structToUse.getConfig(type)?.data.planlqBookingCategory || []
        ).map((catId) =>
          CategoryStruct.getData(type).find((e) => e._id === catId)
        )
      )
      .flat(),
    [
      {
        dir: "asc",
        key: "data.type",
      },
      {
        dir: "asc",
        key: "data.sortKey",
      },
    ]
  ).map((cat) => ({
    value: cat._id,
    group: UnitStruct.getUnitLabel(cat.data.type),
    label: LanguageService.translateLabel(cat.data.displayName),
  }));

  const hasMultipleRelevantTypes =
    _.uniq(categories.map((e) => e.group)).length > 1;
  const useToggleGroup =
    !hasMultipleRelevantTypes && categories.length <= MAX_COUNT_TOGGLE_GROUP;

  if (categories.length === 0) {
    return null;
  }
  return (
    <div className={classNames(`ap-category-quick-filter`)}>
      {useToggleGroup && (
        <BFButtonToggle
          value={props.value}
          onChange={props.onChange}
          buttons={[
            {
              text: i18n.t("apTemplate:List.Activity.all", "Alle"),
              value: null,
            },
            ...categories.map((cat) => ({
              text: cat.label,
              value: cat.value,
            })),
          ]}
        />
      )}
      {!useToggleGroup && (
        <BFSelect
          cleanable={props.value !== null}
          onChange={(value) => props.onChange(value)}
          value={props.value}
          placeholder="Alle"
          groupBy={hasMultipleRelevantTypes ? "group" : undefined}
          data={[
            // {
            //   value: null,
            //   label: i18n.t("apTemplate:List.Activity.all", "Alle"),
            //   //   group: "None",
            //   group: null,
            // },
            ...categories,
          ]}
        />
      )}
    </div>
  );
};

export default APCategoryQuickfilter;
