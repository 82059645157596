import { AssetTypes } from "../../../../../model/AssetTypes";
import { DocumentStoreAssetParams } from "../../../../../modules/document-store/DSInterfaces";
import DocumentStoreManaged from "../../../../../modules/document-store/DocumentStoreManaged";
import { RAInvoice } from "../../RAInterfaces";
import "./RADocumentsView.scss";

interface RAPaymentProps {
  invoice: RAInvoice;
}

export const RADocumentsView = (props: RAPaymentProps) => {
  const { invoice } = props;
  const documents = invoice.data.attachments || [];
  const assetParams: DocumentStoreAssetParams = {
    asset: invoice,
    assetType: AssetTypes.Invoice,
    type: invoice.data.type,
    documentsFieldPath: "data.attachments",
  };

  return (
    <div className="ra-documents-view">
      <DocumentStoreManaged assetParams={assetParams} appearance="clean" />
    </div>
  );
};
