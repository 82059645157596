import {
  APDunningConfig,
  APDunningLevel,
} from "@/apps/tatar/activityApp/specific/dunning/dunning.interface";
import i18n from "@/i18n";
import { BFChooserOption } from "@/modules/abstract-ui/forms/chooser/BFChooser";
import LanguageService, { TranslatedLabel } from "@/services/LanguageService";
import { HTTP } from "../../../../utils/Http";
import { StructType } from "../../../reducers/struct/StructInterface";
import { DataByUnitType } from "../AbstractStructSelectors";
import { ActivityAbstractStructClass } from "./ActivityAbstractStruct";
import UnitStruct from "./UnitStruct";

export class DunningStructClass extends ActivityAbstractStructClass<"dunning"> {
  getStructType(): StructType {
    return "dunning";
  }
  loadStructData(types: string[]): Promise<DataByUnitType<APDunningConfig>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: "/dunning/getConfig",
          target: "EMPTY",
          queryParams: {
            param: {
              types: types,
            },
          },
        })) as APDunningConfig[];

        const result: DataByUnitType<APDunningConfig> = {};
        types.forEach((type) => {
          result[type] = data.find((config) => config.data.type === type);
        });
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }

  getNextDunningLevelOf(type: string, dunningId?: string) {
    return this.useCache<APDunningLevel>(
      "getNextDunningLevelOf",
      arguments,
      () => {
        if (dunningId) {
          const dunning = this.getDunningLevel(dunningId);
          if (dunning.dunningRunNext) {
            const nextDunning = this.getDunningLevel(dunning.dunningRunNext);
            return nextDunning;
          } else {
            return dunning;
          }
        } else {
          return this.getDunningLevels(type, true)?.[0];
        }
      }
    );
  }
  getDunningLevel(id: string) {
    return this.useCache<APDunningLevel>("getDunningLevel", arguments, () => {
      return (this.getAllData() as APDunningConfig[])
        .map((e) => e.data.dunningLevels)
        .flat()
        .find((e) => e.id === id);
    });
  }

  getDunningLevelOptions(type, filterForDunningRun = false) {
    return this.useCache<BFChooserOption<string>[]>(
      "getDunningLevelOptions",
      arguments,
      () => {
        const dunningLevels = this.getDunningLevels(type, filterForDunningRun);
        return dunningLevels?.map(
          (e) =>
            ({
              value: e.id,
              label: LanguageService.translateLabel(e.name),
            } as BFChooserOption<string>)
        );
      }
    );
  }

  getDunningDispatchLabels(type: "manually" | "epost" | "sms" | "mail") {
    return this.useCache<string>("getDunningDispatchLabels", arguments, () => {
      switch (type) {
        case "epost":
          return i18n.t(
            "Accounting.DunningRun.byEpost",
            "Automatischer Briefversand"
          );

        case "sms":
          return i18n.t("Accounting.DunningRun.bySMS", "Per SMS");

        case "mail":
          return i18n.t("Accounting.DunningRun.byMail", "Per E-Mail");
        case "manually":
          return i18n.t(
            "Accounting.DunningRun.byManually",
            "Manueller Briefversand"
          );
        default:
          return type;
      }
    });
  }

  getDunningLevels(type: string, filterForDunningRun = false) {
    return this.useCache<APDunningLevel[]>(
      "getDunningLevels",
      arguments,
      () => {
        const config = this.getData(type) as APDunningConfig;

        if (filterForDunningRun) {
          const allPredecessors = config?.data.dunningLevels
            .map((e) => e.dunningRunNext)
            .filter((e) => e);

          return config?.data.dunningLevels.filter(
            (e) => e.usedForDunningRun || allPredecessors.includes(e.id)
          );
        } else {
          return config?.data.dunningLevels;
        }
      }
    );
  }
  getDunningLevelOptionsOfTypes(types: string[]) {
    return this.useCache<BFChooserOption<string>[]>(
      "getDunningLevelsOfTypes",
      arguments,
      () => {
        return (this.getAllData() as APDunningConfig[])
          .map((e) =>
            e.data.dunningLevels.map((a) => ({ ...a, type: e.data.type }))
          )
          .flat()
          .filter((e) => types.includes(e.type))
          .map((e) => ({
            value: e.id,
            label: LanguageService.translateLabel(e.name),
            subLabel: UnitStruct.getUnitLabel(e.type),
          }));
      }
    );
  }

  getTypeOfResidue(id: string) {
    return this.useCache<{
      label: TranslatedLabel;
      id: string;
    }>("getTypeOfResidue", arguments, () => {
      return (this.getAllData() as APDunningConfig[])
        .map((e) => e.data.typeOfResidueOptions)
        .flat()
        .find((e) => e.id === id);
    });
  }
  getTypeOfResidueOptions(type: string) {
    return this.useCache<BFChooserOption<string>[]>(
      "getTypeOfResidueOptions",
      arguments,
      () => {
        const config = this.getData(type) as APDunningConfig;
        return config?.data.typeOfResidueOptions.map(
          (e) =>
            ({
              value: e.id,
              label: LanguageService.translateLabel(e.label),
            } as BFChooserOption<string>)
        );
      }
    );
  }
  getTypeOfResidueOptionsOfTypes(type: string[]) {
    return this.useCache<BFChooserOption<string>[]>(
      "getTypeOfResidueOptions",
      arguments,
      () => {
        const configs = this.getAllData().filter((e) =>
          type.includes(e.data.type)
        ) as APDunningConfig[];
        return configs
          .map((e) =>
            e.data.typeOfResidueOptions.map((a) => ({
              type: e.data.type,
              ...a,
            }))
          )
          .flat()
          .map(
            (e) =>
              ({
                value: e.id,
                label: LanguageService.translateLabel(e.label),
                subLabel: UnitStruct.getUnitLabel(e.type),
              } as BFChooserOption<string>)
          );
      }
    );
  }
}
const DunningStruct = new DunningStructClass();
export default DunningStruct;
