import { useEffect } from "react";
import { useDispatch } from "react-redux";
import i18n from "../../../i18n";
import BFTableFilter from "../../../modules/abstract-ui/data/table-filter/BFTableFilter";
import {
  FavoriteFilter,
  FilterOptionWithMatchQueryConverter,
  FilterValue,
} from "../../../modules/abstract-ui/data/table-filter/BFTableFilterModel";
import InfiniteTable from "../../../redux/actions/application/application-infinite-table-actions";
import Customization from "../../../redux/actions/customization/customization-actions";
import { useDatabus, useTypedSelector } from "../../../redux/hooks";
import { InfiniteTableFilterValue } from "../../../redux/reducers/application/ApplicationInterface";
import DataBusDefaults from "../../../services/DataBusDefaults";
import { DataBusSubKeys } from "../../../utils/Constants";

export interface FilterComponentInitialState {
  search?: string;
  filter?: FilterValue[];
}
interface FilterComponentProps {
  hideFavorites?: boolean;
  hideTextSearch?: boolean;
  searchPlaceholder?: string;
  identifier: string;
  filterOptions?: FilterOptionWithMatchQueryConverter[];
  initialState?: FilterComponentInitialState;
}
const InfiniteTableFilter = (props: FilterComponentProps) => {
  const dispatch = useDispatch();

  useDatabus(DataBusSubKeys.FILTER_CHANGED, (filter) => {
    if (filter.identifier === props.identifier) {
      onFilterChange(filter.filter);
    }
  });
  const tableCustomization = useTypedSelector(
    (state) => state.customizations.infiniteTable[props.identifier]
  );
  const favoriteFilters = useTypedSelector(
    (state) => state.customizations.favoriteFilters[props.identifier]
  );

  const filterValue = useTypedSelector(
    (state) => state.application.infiniteTables[props.identifier]?.filterStatus
  );

  useEffect(() => {
    if (props.initialState) {
      onFilterChange(props.initialState?.filter || []);
      dispatch(
        InfiniteTable.setSearch(
          props.identifier,
          props.initialState?.search || ""
        )
      );
    } else {
      if (favoriteFilters && !filterValue) {
        const defaultFilter = favoriteFilters.find((e) => e.isDefault);
        if (defaultFilter) {
          onFilterChange(defaultFilter.filter, defaultFilter);
          DataBusDefaults.toast({
            type: "info",
            text: i18n.t(
              "BFTableFilter.defaultFilterInfo",
              "Standardfilter geladen"
            ),
          });
        }
      }
    }
  }, [props.initialState]);

  const onFilterChange = (
    filters: FilterValue[],
    favoriteFilter?: FavoriteFilter
  ) => {
    const filtersWithMatchQuery: InfiniteTableFilterValue[] = filters?.map(
      (filter) => ({
        ...filter,
        matchQuery:
          props.filterOptions
            ?.find((option) => option.key === filter.filterKey)
            ?.matchQueryConverter(filter.value) || null,
      })
    );

    if (filtersWithMatchQuery) {
      dispatch(
        InfiniteTable.setFilter(props.identifier, filtersWithMatchQuery)
      );

      if (favoriteFilter?.columnCustomization) {
        dispatch(
          Customization.customizeInfiniteTableColumns(
            props.identifier,
            favoriteFilter?.columnCustomization,
            false,
            true
          )
        );
      }
    }
  };

  const onRemoveAllFilters = () => {
    dispatch(InfiniteTable.setFilter(props.identifier, []));
  };

  return (
    <BFTableFilter
      customizations={tableCustomization}
      hideFavorites={props.hideFavorites}
      hideTextSearch={props.hideTextSearch}
      searchPlaceholder={props.searchPlaceholder}
      favoriteFilters={favoriteFilters}
      onFilterAdd={(filter) =>
        dispatch(Customization.addFavoriteFilter(props.identifier, filter))
      }
      onFilterUpdate={(filter) =>
        dispatch(Customization.updateFavoriteFilter(props.identifier, filter))
      }
      value={filterValue}
      onChange={onFilterChange}
      filterOptions={props.filterOptions}
      onFulltextSearch={(searchTerm) =>
        dispatch(InfiniteTable.setSearch(props.identifier, searchTerm))
      }
      onFilterRemove={(id) =>
        dispatch(Customization.removeFavoriteFilter(props.identifier, id))
      }
      onAllFilterRemove={onRemoveAllFilters}
    />
  );
};

export default InfiniteTableFilter;
