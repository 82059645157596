import classNames from "classnames";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { RSDateTagStatus, Resubmission } from "../../RSInterfaces";
import RSService from "../../RSService";
import RSDateTag from "../date-tag/RSDateTag";
import RSSummaryCard from "../summary-card/RSSummaryCard";
import "./RSCategorizedStatusList.scss";

interface RSCategorizedStatusListProps {
  resubmissions: Resubmission[];
  onCloseModal?: () => void;
}

const RSCategorizedStatusList = (props: RSCategorizedStatusListProps) => {
  const { resubmissions } = props;
  const [resubmissionStatusMap, setResubmissionStatusMap] = useState<
    Map<RSDateTagStatus, Resubmission[]>
  >(new Map());

  useEffect(() => {
    /**
     * Puts the resubmissions into the three categories "overdue", "due" and "planned".
     */
    const resubmissionStatusMap: Map<RSDateTagStatus, Resubmission[]> =
      new Map();
    resubmissions.forEach((resubmission) => {
      const today = moment().startOf("day");
      const dueDate = moment(resubmission.data.dueDate).startOf("day");
      // Check for overdue
      if (dueDate.isBefore(today)) {
        if (resubmissionStatusMap.has("overdue")) {
          resubmissionStatusMap.get("overdue")?.push(resubmission);
        } else {
          resubmissionStatusMap.set("overdue", [resubmission]);
        }
      }
      // Check for today
      if (dueDate.isSame(today)) {
        if (resubmissionStatusMap.has("due")) {
          resubmissionStatusMap.get("due")?.push(resubmission);
        } else {
          resubmissionStatusMap.set("due", [resubmission]);
        }
      }

      // check for upcoming tasks
      if (dueDate.isAfter(today)) {
        if (resubmissionStatusMap.has("planned")) {
          resubmissionStatusMap.get("planned")?.push(resubmission);
        } else {
          resubmissionStatusMap.set("planned", [resubmission]);
        }
      }
    });
    setResubmissionStatusMap(resubmissionStatusMap);
  }, [resubmissions]);

  /**
   * Renders the "overdue" section of the dashboard, including the due date tag and the list of overdue resubmissions.
   * The taglabel will be the range from the earliest date to the latest date of the overdue resubmissions.
   * (e.g. "01 June - 05 June")
   * @returns {JSX.Element} - The rendered "overdue" section of the dashboard.
   */
  const renderOverdue = () => {
    const resubmissionsOverdue = resubmissionStatusMap.get("overdue") || [];
    let tagLabel = RSService.formatDueDate(
      resubmissionsOverdue[0].data.dueDate
    );
    if (resubmissionsOverdue.length > 1) {
      const date = moment(resubmissionsOverdue[0].data.dueDate).startOf("day");
      const lastDate =
        resubmissionsOverdue[resubmissionsOverdue.length - 1].data.dueDate;

      if (!date.isSame(moment(lastDate).startOf("day"))) {
        tagLabel += ` - ${RSService.formatDueDate(lastDate)}`;
      }
    }
    return (
      <div className="rs-status-container">
        <RSDateTag text={tagLabel} status={"overdue"} />
        <div className="rs-entry-container">
          {renderResubmissionEntries(
            resubmissionStatusMap.get("overdue") || []
          )}
        </div>
      </div>
    );
  };

  /**
   * Renders all upcoming resubmissions. This will render a tag for each day with upcoming resubmissions.
   * The label will show the date of the day. (e.g. "01 July")
   * @returns {JSX.Element[]} - The rendered "planned" section of the dashboard.
   */
  const renderPlanned = () => {
    const plannedResubmissions = resubmissionStatusMap.get("planned") || [];
    const resubmissionsByDate: Record<string, Resubmission[]> = {};

    plannedResubmissions.forEach((resubmission) => {
      const date = moment(resubmission.data.dueDate).startOf("day");
      if (resubmissionsByDate[date.format("DD.MM.YYYY")]) {
        resubmissionsByDate[date.format("DD.MM.YYYY")].push(resubmission);
      } else {
        resubmissionsByDate[date.format("DD.MM.YYYY")] = [resubmission];
      }
    });

    const keys = Object.keys(resubmissionsByDate);
    return keys.map((key) => {
      const tagLabel = RSService.formatDueDateMoment(moment(key, "DD.MM.YYYY"));
      return (
        <div key={key} className="rs-status-container">
          <RSDateTag text={tagLabel} status={"planned"} />
          <div className="rs-entry-container">
            {renderResubmissionEntries(resubmissionsByDate[key])}
          </div>
        </div>
      );
    });
  };

  /**
   * Renders all resubmissions that are due today. The label will show translated "today".
   * @returns {JSX.Element} - The rendered "due" section of the dashboard.
   */
  const renderDue = () => {
    const resubmissionsDue = resubmissionStatusMap.get("due") || [];
    const tagLabel = RSService.formatDueDate(resubmissionsDue[0].data.dueDate);
    return (
      <div className="rs-status-container">
        <RSDateTag text={tagLabel} status={"due"} />
        <div className="rs-entry-container">
          {renderResubmissionEntries(resubmissionsDue)}
        </div>
      </div>
    );
  };

  const renderResubmissionEntries = (resubmissions: Resubmission[]) => {
    return resubmissions.map((resubmission) => {
      return (
        <div className="rs-categorized-list-entry" key={resubmission._id}>
          {renderResubmissionCard(resubmission)}
        </div>
      );
    });
  };

  const renderResubmissionCard = (resubmission: Resubmission) => {
    return (
      <RSSummaryCard
        resubmission={resubmission}
        onCloseModal={props.onCloseModal}
      />
    );
  };

  return (
    <div className={classNames("rs-categorized-status-list")}>
      <Fragment>
        {resubmissionStatusMap.has("overdue") && renderOverdue()}
        {resubmissionStatusMap.has("due") && renderDue()}
        {resubmissionStatusMap.has("planned") && renderPlanned()}
      </Fragment>
    </div>
  );
};

export default RSCategorizedStatusList;
