import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "rsuite";
import ListComponent from "../../../../configurable/components/ListComponent/ListComponent";
import i18n from "../../../../i18n";
import { TableSort } from "../../../../model/common/CommonInterfaces";
import InfiniteTable from "../../../../redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "../../../../redux/hooks";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import { MatchQuery } from "../../../../services/DataService";
import MQ from "../../../../utils/MatchQueryUtils";
import BFInput from "../../../abstract-ui/forms/input/BFInput";
import BFSelect from "../../../abstract-ui/forms/select/BFSelect";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import BfIcon, { BfIconProps } from "../../../abstract-ui/icon/BfIcon";
import "./EZAssetSelectDropdown.scss";

export interface AssetValue {
  assetType: string;
  assetId: string;
}
export interface AssetSelectProps {
  id: string;
  label: string;
  assetType: string;
  matchQuery?: MatchQuery;
  icon?: BfIconProps;
  renderValue: (value: any) => React.ReactNode;
  renderMenu?: (value: any) => React.ReactNode;
  sort?: TableSort[];
  group?: string;
}
export type AssetSelectStyle = "default" | "bf";
interface EZAssetSelectProps {
  isOpen: boolean;
  additionalMatchQuery?: MatchQuery;
  selectedIds?: string[];
  filterSelectionOut?: boolean;
  identifier: string;
  cleanable?: boolean;
  assetTypes: AssetSelectProps[];
  onSelect: (value: AssetValue) => void;
  initialAssetType?: string;
}

const EZAssetSelectDropdown = (props: EZAssetSelectProps) => {
  const dispatch = useDispatch();
  const tableCache = useTypedSelector(
    (state) => state.application.infiniteTables[props.identifier]
  );
  const [selectedConfigId, setSelectedConfigId] = useState<string>(
    props.assetTypes?.length === 1
      ? props.assetTypes[0].id
      : props.initialAssetType || null
  );
  useEffect(() => {
    if (selectedConfigId) {
      DataBusDefaults.reload({
        identifiers: [props.identifier],
      });
    }
  }, [selectedConfigId]);
  const selectedConfig = props.assetTypes.find(
    (e) => e.id === selectedConfigId
  );

  return (
    <Dropdown.Item panel>
      {props.isOpen && (
        <div className={`ez-select-dropdown-content`} style={{ height: 400 }}>
          {props.cleanable && (
            <div className={`cleanable-action`}>
              <BFButton
                appearance="link"
                size="xs"
                onClick={() => props.onSelect(null)}
              >
                {i18n.t("EZAssetSelect.cleanSelection", "Auswahl aufheben")}
              </BFButton>
            </div>
          )}
          {(props.assetTypes?.length !== 1 || !selectedConfigId) && (
            <div className={`asset-selection`}>
              <BFSelect
                value={selectedConfigId}
                onChange={setSelectedConfigId}
                renderValue={
                  props.assetTypes.some((e) => e.group)
                    ? (value, item, selectedElement) =>
                        item ? (
                          <div className={`value-presentation-container`}>
                            <div className={`value-presentation`}>
                              {item.group ? `${item.group} - ` : ""}
                              {item.label}
                            </div>
                          </div>
                        ) : null
                    : undefined
                }
                data={props.assetTypes.map((e) => ({
                  label: e.label,
                  value: e.id,
                  group: e.group,
                }))}
                groupBy={
                  props.assetTypes.some((e) => e.group) ? "group" : undefined
                }
              />
            </div>
          )}
          {selectedConfigId && selectedConfig && (
            <>
              <div className={`search`}>
                <BFInput
                  appearance="clear"
                  placeholder={i18n.t("Global.Search")}
                  prefix={<BfIcon type="light" data="search" size="xs" />}
                  value={tableCache?.searchTerm || ""}
                  onChange={(value: string) => {
                    dispatch(InfiniteTable.setSearch(props.identifier, value));
                  }}
                />
              </div>
              <div className={`select-content`}>
                <ListComponent
                  dataUrl={`/api/asset/list/${selectedConfig.assetType}`}
                  asPost
                  hiddenSort={selectedConfig.sort}
                  additionalMatchQuery={MQ.combine("and", [
                    props.additionalMatchQuery,
                    selectedConfig.matchQuery,
                    props.filterSelectionOut && props.selectedIds?.length > 0
                      ? ({
                          type: "op",
                          name: "_id",
                          op: "nin",
                          value: props.selectedIds,
                        } as MatchQuery)
                      : undefined,
                  ])}
                  identifier={props.identifier}
                  params={{
                    onSelect: props.onSelect,
                    selectedIds: props.selectedIds,
                  }}
                  render={(item, index, params) => {
                    return (
                      <button
                        type="button"
                        className={classNames(`selection-item`, {
                          selected: params.selectedIds?.includes(item._id),
                        })}
                        onClick={(ev) => {
                          const newValue = {
                            assetId: item._id,
                            assetType: selectedConfig.assetType,
                          };
                          params.onSelect(newValue, ev);
                        }}
                      >
                        <div className={`asset-select-label`}>
                          {selectedConfig.renderMenu
                            ? selectedConfig.renderMenu(item)
                            : selectedConfig.renderValue(item)}
                        </div>
                      </button>
                    );
                  }}
                />
              </div>
            </>
          )}
          {!selectedConfigId && (
            <div className={`no-type-selection`}>
              {i18n.t(
                "EZAssetSelect.NoTypeSelected",
                "Bitte wählen Sie einen Typen aus"
              )}
            </div>
          )}
        </div>
      )}
    </Dropdown.Item>
  );
};

export default EZAssetSelectDropdown;
