import classNames from "classnames";
import Color from "color";
import { useEffect, useRef, useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import i18n from "../../../../../../../i18n";
import { openIconPicker } from "../../../../../../../modules/abstract-ui/data/icon-picker/BFIconPickerModal";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "../../../../../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import BfIcon from "../../../../../../../modules/abstract-ui/icon/BfIcon";
import "./CBTechnicalUnitIcon.scss";

interface CBTechnicalUnitIconProps {
  className?: string;
  editable?: boolean;
  value: {
    type: string;
    icon: string;
    color: string;
  };
  size?: "xxs" | "xs" | "md";
  onChange?: (value: { type: string; icon: string; color: string }) => void;
}
const CBTechnicalUnitIcon = (props: CBTechnicalUnitIconProps) => {
  const [tempColor, setTempColor] = useState(props.value.color);
  const [colorInstance, setColorInstance] = useState(
    Color(props.value.color || "#f0a")
  );

  const timeoutRef = useRef(null);
  useEffect(() => {
    const color = Color(props.value.color || "#f0a");
    setColorInstance(color);
  }, [props.value?.color]);
  const content = (
    <div
      className={classNames(
        `cb-technical-unit-icon`,
        props.className,
        `size-${props.size || "md"}`
      )}
      style={{
        backgroundColor: colorInstance?.hex(),
        color: colorInstance
          ? colorInstance?.isDark()
            ? "#fff"
            : colorInstance.darken(0.7).hex()
          : undefined,
      }}
    >
      <BfIcon
        type={props.value?.type || "light"}
        data={props.value?.icon || "robot"}
        size={"xxs"}
      />
    </div>
  );
  if (props.editable) {
    return (
      <BFDropdown
        className={`cb-technical-unit-icon-dropdown`}
        items={[
          {
            type: "button",
            text: i18n.t(
              "cb:Config.TechnicalUnit.Form.changeIcon",
              "Icon ändern"
            ),
            onSelect: () => {
              openIconPicker((icon) =>
                props.onChange({
                  ...props.value,
                  icon: icon.data,
                  type: icon.type,
                })
              );
            },
          },
          {
            type: "panel",
            children: (
              <div className={`color-picker`}>
                <SketchPicker
                  color={tempColor}
                  onChangeComplete={(color: ColorResult) => {
                    clearTimeout(timeoutRef.current);
                    setTempColor(color.hex);
                    timeoutRef.current = setTimeout(() => {
                      props.onChange({
                        ...props.value,
                        color: color.hex,
                      });
                    }, 300);
                  }}
                />
              </div>
            ),
          },
        ]}
        label=""
        toggleAs={(buttonProps) => (
          <BFButton {...buttonProps}>{content}</BFButton>
        )}
      />
    );
  } else {
    return content;
  }
};

export default CBTechnicalUnitIcon;
