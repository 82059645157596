import { MatchQuery } from "@/services/DataService";
import _ from "lodash";
import { useEffect, useState } from "react";
import CacheService from "../../services/CacheService";
import PromiseLoader from "../PromiseLoader/PromiseLoader";

type AssetQueryEntry =
  | { assetType: string; query: MatchQuery }
  | {
      assetType: string;
      assetId: string;
    };
interface MultipleAssetLoadersProps {
  assets: AssetQueryEntry[];
  inline?: boolean;
  render: (data: any[]) => JSX.Element;
  renderLoading?: () => JSX.Element;
}
const MultipleAssetLoaders = (props: MultipleAssetLoadersProps) => {
  const [assets, setAssets] = useState<AssetQueryEntry[]>(props.assets);
  useEffect(() => {
    if (!_.isEqual(assets, props.assets)) {
      setAssets(props.assets);
    }
  }, [props.assets, assets]);
  return (
    <PromiseLoader
      inline={props.inline}
      param={assets}
      promise={(param) => CacheService.getDataMultiple(param)}
      render={(data) => props.render(data)}
      renderLoading={props.renderLoading}
    />
  );
};

export default MultipleAssetLoaders;
