import classNames from "classnames";
import React, { useState } from "react";
import { Nav } from "rsuite";
import "./BFTabs.scss";

type TabContent = {
  id: string;
  label: React.ReactNode;
  content: () => React.ReactNode;

  activeTab?: string;
  setActiveTab?: (id: string) => void;
  error?: boolean;
};

interface BFTabsProps {
  tabs: TabContent[];
  preventUnmount?: boolean;
}
const BFTabs = (props: BFTabsProps) => {
  const [activeTab, setActiveTab] = useState<string>(props.tabs[0].id);

  return (
    <div className={classNames(`bf-tabs`)}>
      <Nav appearance="tabs" activeKey={activeTab} onSelect={setActiveTab}>
        {props.tabs.map((tab) => (
          <Nav.Item
            key={tab.id}
            eventKey={tab.id}
            className={classNames({
              error: tab.error,
            })}
          >
            {tab.label}
          </Nav.Item>
        ))}
      </Nav>
      <div className={`tab-content`}>
        {props.preventUnmount && (
          <>
            {props.tabs.map((tab) => (
              <div
                key={tab.id}
                style={{ display: tab.id === activeTab ? "block" : "none" }}
              >
                {tab.content()}
              </div>
            ))}
          </>
        )}
        {!props.preventUnmount &&
          props.tabs.find((tab) => tab.id === activeTab)?.content()}
      </div>
    </div>
  );
};

export default BFTabs;
