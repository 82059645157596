import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import { FV } from "@/components/Form/Validation/FormValidators";
import BaseAsset from "@/model/general-assets/BaseAsset";
import { isDefined, isEnumValue } from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import { Field, FieldRenderProps, FormSpy } from "react-final-form";
import {
  CustomField,
  Customfield_attachment,
  Customfield_boolean,
  Customfield_currency,
  Customfield_date,
  Customfield_number,
  Customfield_radio,
  Customfield_row,
  Customfield_section,
  Customfield_select,
  Customfield_string,
  Customfield_textarea,
  CustomFieldEditType,
  CustomFieldLayoutType,
  CustomfieldType_edit,
} from "./CustomField.interface";
import CFAttachment from "./fields/CFAttachment";
import CFBoolean from "./fields/CFBoolean";
import CFCurrency from "./fields/CFCurrency";
import CFDate from "./fields/CFDate";
import CFNumber from "./fields/CFNumber";
import CFRadio from "./fields/CFRadio";
import CFSelect from "./fields/CFSelect";
import CFString from "./fields/CFString";
import CFTextarea from "./fields/CFTextarea";
import CFRow from "./layout/CFRow";
import CFSection from "./layout/CFSection";
// import "./CFBase.scss";

interface CFBaseProps {
  field: CustomField;
  prefix?: string;
  asset?: BaseAsset;
  assetType?: string;
}
const CFBase = (props: CFBaseProps) => {
  let result = null;

  switch (props.field.fieldType) {
    case CustomFieldLayoutType.row:
      result = (
        <CFBaseField
          {...props}
          render={(field) => (
            <CFRow
              data={props.field as Customfield_row}
              {...props}
              renderField={(field) => <CFBase {...props} field={field} />}
            />
          )}
        />
      );
      break;
    case CustomFieldLayoutType.section:
      result = (
        <CFBaseField
          {...props}
          render={(field) => (
            <CFSection
              data={props.field as Customfield_section}
              {...props}
              renderField={(field) => <CFBase {...props} field={field} />}
            />
          )}
        />
      );
      break;

    case CustomFieldEditType.radio:
      result = (
        <CFBaseField
          {...props}
          render={(field) => (
            <CFRadio data={props.field as Customfield_radio} field={field} />
          )}
        />
      );
      break;
    case CustomFieldEditType.number:
      result = (
        <CFBaseField
          {...props}
          render={(field) => (
            <CFNumber data={props.field as Customfield_number} field={field} />
          )}
        />
      );
      break;
    case CustomFieldEditType.textarea:
      result = (
        <CFBaseField
          {...props}
          render={(field) => (
            <CFTextarea
              data={props.field as Customfield_textarea}
              field={field}
            />
          )}
        />
      );
      break;
    case CustomFieldEditType.string:
      result = (
        <CFBaseField
          {...props}
          render={(field) => (
            <CFString data={props.field as Customfield_string} field={field} />
          )}
        />
      );
      break;
    case CustomFieldEditType.boolean:
      result = (
        <CFBaseField
          {...props}
          render={(field) => (
            <CFBoolean
              data={props.field as Customfield_boolean}
              field={field}
            />
          )}
        />
      );
      break;
    case CustomFieldEditType.attachment:
      result = (
        <CFBaseField
          {...props}
          render={(field) => (
            <CFAttachment
              asset={props.asset}
              assetType={props.assetType}
              prefix={props.prefix}
              data={props.field as Customfield_attachment}
              field={field}
            />
          )}
        />
      );
      break;

    case CustomFieldEditType.currency:
      result = (
        <CFBaseField
          {...props}
          render={(field) => (
            <CFCurrency
              data={props.field as Customfield_currency}
              field={field}
            />
          )}
        />
      );
      break;
    case CustomFieldEditType.date:
      result = (
        <CFBaseField
          {...props}
          render={(field) => (
            <CFDate data={props.field as Customfield_date} field={field} />
          )}
        />
      );
      break;
    case CustomFieldEditType.select:
      result = (
        <CFBaseField
          {...props}
          render={(field) => (
            <CFSelect data={props.field as Customfield_select} field={field} />
          )}
        />
      );
      break;
  }

  if (result) {
    return result;
  } else {
    return null;
  }
};

export default CFBase;

const CFBaseField = (
  props: CFBaseProps & {
    render: (field?: FieldRenderProps<any, HTMLElement>) => React.ReactNode;
  }
) => {
  const hasCondition = isDefined(props.field.condition);
  const isEditType = isEnumValue(props.field.fieldType, CustomFieldEditType);
  const isLayoutType = isEnumValue(
    props.field.fieldType,
    CustomFieldLayoutType
  );
  let cmp = null;
  if (isEditType) {
    const field = props.field as CustomfieldType_edit;
    cmp = (
      <div className={`__field`}>
        <Field
          name={`${props.prefix ? `${props.prefix}.` : ""}${props.field.id}`}
          defaultValue={field.defaultValue}
          validate={
            field.validation
              ? FV.compose(
                  ...field.validation?.map((validate) => {
                    if (validate.type === "required") {
                      return FV.required();
                    }
                    if (validate.type === "min") {
                      return FV.min(validate.param);
                    }
                    if (validate.type === "max") {
                      return FV.max(validate.param);
                    }
                    return undefined;
                  })
                )
              : undefined
          }
        >
          {(field) => props.render(field)}
        </Field>
      </div>
    );
  }
  if (isLayoutType) {
    cmp = props.render();
  }

  if (hasCondition) {
    const render = (values: any) => {
      if (MQ.check(values, props.field.condition)) {
        return cmp;
      }
      return null;
    };

    if (props.prefix) {
      // if prefix is used, only select the data of the custom fields object
      return (
        <FormFieldValues names={[props.prefix]}>
          {([data]) => render(data)}
        </FormFieldValues>
      );
    } else {
      // no prefix => full object is used for condiitons
      return (
        <FormSpy
          subscription={{ values: true }}
          render={({ values }) => render(values)}
        />
      );
    }
  } else {
    return cmp;
  }
};
