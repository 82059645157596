import flatten from "flat";
import { LexoRank } from "lexorank";
import moment from "moment";
import React, { CSSProperties } from "react";
import { IComponent } from "../../../configurable/layouts/IComponent";
import { SendEvent } from "../../../utils/abstracts/AbstractComponent";
import { BFButtonProps } from "../../abstract-ui/general/Button/BFButton";
import { ArrayFormFieldValue } from "../../generic-forms-impl/form-fields/ArrayFormField";
import ExpressionHelper from "./ExpressionHelper";
import JsonValidationException from "./JsonValidationException";
import Validators, { ValidatorTypes } from "./Validatos";
export interface JsonProperties {
  [key: string]:
    | JsonPropertyComponent
    | { _component: string; [key: string]: any };
}

export interface Validator {
  type: ValidatorTypes;
  param?: any;
  condition?: string;
  text: string;
  level: "error" | "warning";
}

export interface JsonPropertyCommon {
  _component: JsonPropertyTypes;
  identifier?: string;
  condition?: string;
  validators?: Validator[];
  validatorStyle?: string;
  label?: string;
  containerStyleProps?: CSSProperties;
  defaultValue?: any;
  disabled?: boolean;
  readonly?: boolean;
  submitType?: "difference" | "ignore" | "add";

  // currently not working on each fields
  _blurActions: SendEvent[] | { [key: string]: SendEvent };
  _changeActions: SendEvent[] | { [key: string]: SendEvent };
}

export interface OptionEntry {
  label: string;
  value: any;
  children?: OptionEntry[];
}

export interface OptionConditionGroup {
  condition: string;
  options: OptionEntry[];
}

export type JsonPropertyTypes =
  | "array"
  | "select"
  | "text"
  | "textarea"
  | "number"
  | "toggle"
  | "date"
  | "checkbox"
  | "checkbox-group"
  | "radio"
  | "mail"
  | "website"
  | string;

export interface JsonPropertyArray extends JsonPropertyCommon {
  _component: "array";
  properties: JsonProperties;
  defaultEntryValue?: string;
  max?: number;
  min?: number;
  layout?: any;
  orderable?: boolean;
  addLabel?: string;
  addButtonProps?: BFButtonProps;
  asArray?: boolean;
}
export interface JsonPropertySelect extends JsonPropertyCommon {
  _component: "select";
  options?: (OptionEntry | OptionConditionGroup)[];
  _options?: any;
  renderValuePath?: boolean;
  multiple: boolean;
  labelPosition?: "top" | "left";
  placeholder?: string;
  cleanable?: boolean;
  renderExtraFooter?: IComponent | React.ReactNode;
}

export interface JsonPropertyText extends JsonPropertyCommon {
  _component: "text";
  placeholder?: string;
  appearance?: string;
  autoComplete?: string;
  autoCapitalize?: string;
  autoCorrect?: string;
  style: CSSProperties;
  prefixComp?: string | any;
  suffixComp?: string | any;
  textAlign: "left" | "center" | "right";
  labelPosition?: "top" | "left";
}

export interface JsonPropertyTextArea extends JsonPropertyCommon {
  _component: "textarea";
  placeholder?: string;
  appearance?: string;
  autoResize?: boolean;
  autoComplete?: string;
  autoCapitalize?: string;
  autoCorrect?: string;
  style: CSSProperties;
  labelPosition?: "top" | "left";
}

export interface JsonPropertyNumber extends JsonPropertyCommon {
  _component: "number";
  appearance?: string;
  min?: number;
  max?: number;
  style: CSSProperties;
  decimalFixed?: number;
  textAlign: "left" | "center" | "right";
  placeholder?: string;
  prefixComp?: string | any;
  suffixComp?: string | any;
  labelPosition?: "top" | "left";
}

export interface JsonPropertyMail extends JsonPropertyCommon {
  _component: "mail";
  placeholder?: string;

  appearance?: string;
  style: CSSProperties;
  autoComplete?: string;
  autoCapitalize?: string;
  autoCorrect?: string;
  prefixComp?: string | any;
  suffixComp?: string | any;
  labelPosition?: "top" | "left";
}

export interface JsonPropertyWebsite extends JsonPropertyCommon {
  _component: "website";
  placeholder?: string;

  appearance?: string;
  style: CSSProperties;
  autoComplete?: string;
  autoCapitalize?: string;
  autoCorrect?: string;
  prefixComp?: string | any;
  suffixComp?: string | any;
  labelPosition?: "top" | "left";
}
export interface JsonPropertyRestriction extends JsonPropertyCommon {
  _component: "restriction";
  maxRestrictions?: number;
  canDerestrict?: boolean;
  canRestrictToUsers?: boolean;
  canRestrictToTeams?: boolean;
  whiteListTeams?: string[];
  whiteListUsers?: string[];
  appearance?: "popup" | "static";
}
export interface JsonPropertyUpload extends JsonPropertyCommon {
  _component: "upload";

  appearance?: "default" | "gallery";
  title?: string;
  titleKey?: string;

  accept?: string[];
  multiple?: boolean;
  removable?: boolean;
  folderPermissions?: boolean;
  maxFilesize?: number;
  value?: {
    [id: string]: {
      id?: string;
      key?: string;
      size?: number;
      content_type?: string;
      filename?: string;
      url?: string;
    };
  };
  onChange: (data: {
    [id: string]: {
      id?: string;
      key?: string;
      size?: number;
      content_type?: string;
      filename?: string;
      url?: string;
    };
  }) => void;
}

export interface JsonPropertyToggle extends JsonPropertyCommon {
  _component: "toggle";
  defaultValue?: boolean;
  labelPosition?: "left" | "right";
}

export interface JsonPropertyRadioGroup extends JsonPropertyCommon {
  _component: "radio";
  appearance?: "default" | "picker";
  inline?: boolean;

  _options?: any;
  options: (OptionEntry | OptionConditionGroup)[];
}

export interface JsonPropertyDate extends JsonPropertyCommon {
  _component: "date";
  labelPosition?: "top" | "left";
  min?: Date | string;
  max?: Date | string;
  cleanable?: boolean;
  placeholder?: string;
  format?: string;
  oneTap?: boolean;
  notTimezoned?: boolean;
  disabledDate?: () => boolean | string;
}

export interface JsonPropertyCheckbox extends JsonPropertyCommon {
  _component: "checkbox";
  appearance?: string;
  size?: "xs" | "sm" | "md";
  defaultChecked?: boolean;
}

export interface JsonPropertyCheckboxGroup extends JsonPropertyCommon {
  _component: "checkbox-group";
  inline?: boolean;
  appearance?: string;
  _options?: any;
  options: (OptionEntry | OptionConditionGroup)[];
}

export type JsonPropertyComponent =
  | JsonPropertyArray
  | JsonPropertySelect
  | JsonPropertyText
  | JsonPropertyNumber
  | JsonPropertyToggle
  | JsonPropertyCheckbox
  | JsonPropertyDate
  | JsonPropertyTextArea
  | JsonPropertyCheckboxGroup
  | JsonPropertyMail
  | JsonPropertyRestriction
  | JsonPropertyWebsite
  | JsonPropertyUpload
  | JsonPropertyRadioGroup;

class JsonValidationClass {
  getValue(steps: string[], data: { [key: string]: any }) {
    const step = steps[0];
    if (data === null) {
      return null;
    }
    const tmp = data[step];
    if (tmp === undefined) {
      return undefined;
    }
    if (steps.length === 1) {
      return tmp;
    } else if (typeof data === "object") {
      return this.getValue(steps.slice(1, steps.length), tmp);
    } else {
      return undefined;
    }
  }

  reduceToValidInput(
    jsonValue: { [key: string]: any },
    jsonProperties: JsonProperties
  ) {
    const val = {};
    Object.entries(jsonProperties).forEach(([key, props]) => {
      const value = this.getValue(key.split("|"), jsonValue);

      val[key.replace("\\|", ".")] = value;
    });
    const valFlattened: object = flatten.unflatten(val, {});
    return valFlattened;
  }

  /***
   * validates a json value with given validation json allProperties, will return an object which holds the
   * errors and warnings of the json
   * @param jsonValue
   * @param jsonProperties
   */
  validateJson(
    jsonValue: { [key: string]: any },
    jsonProperties: JsonProperties,
    additionalData: any,
    prefix?: string,
    isArrayEntry = false,
    baseValues?: { [key: string]: any }
  ) {
    jsonValue = { ...jsonValue };
    const output: {
      error: { [key: string]: any };
      warning: { [key: string]: any };
    } = {
      error: {},
      warning: {},
    };
    // set all props that were not set, but were expected by jsonProeprties to undefined,
    // that the validations can work on them too
    Object.keys(jsonProperties).forEach((key) => {
      if (jsonValue[key] === undefined) {
        jsonValue[key] = undefined;
      } else {
        if (
          jsonProperties[key]._component === "array" &&
          jsonValue[key] !== undefined &&
          jsonValue[key] !== null
        ) {
          const arrayProps = jsonProperties[key] as JsonPropertyArray;
          Object.entries(jsonValue[key]).forEach(
            ([entryKey, entryValue], index) => {
              if (entryValue !== null) {
                const newPrefix = `${
                  prefix ? prefix + "." : ""
                }${key}.${entryKey}`;
                const validationArrChild = this.validateJson(
                  { ...(entryValue as any) },
                  arrayProps.properties,
                  additionalData,
                  newPrefix,
                  true,
                  { "!current": entryValue, ...baseValues }
                );
                output.error = { ...output.error, ...validationArrChild.error };
                output.warning = {
                  ...output.warning,
                  ...validationArrChild.warning,
                };
              }
            }
          );
        }
      }
    });

    Object.keys(jsonValue).forEach((key) => {
      const property = jsonProperties[key];
      let useKey = prefix ? `${prefix}.${key}` : key;
      if (property?._component === "array") {
        useKey = `${useKey}.base`;
      }
      const value = jsonValue[key];
      const jsonPropValidation = jsonProperties[key];

      if (!jsonPropValidation) {
        if (key !== "_id") {
          if (!(isArrayEntry && key === "orderIndex")) {
            throw new JsonValidationException(
              useKey,
              "no json validation prop available"
            );
          }
        }
      }

      let skipValidation = jsonPropValidation === undefined;
      if (
        jsonPropValidation &&
        jsonPropValidation.condition &&
        !ExpressionHelper.evaluateExpression(
          jsonPropValidation.condition,
          baseValues
        )
      ) {
        if (value !== undefined && value !== null) {
          throw new JsonValidationException(
            useKey,
            "shouldn't be set by condition"
          );
        } else {
          skipValidation = true;
        }
      }
      if (!skipValidation) {
        const validationErr = Validators.validateType(
          value,
          jsonPropValidation as JsonPropertyComponent,
          baseValues,
          additionalData
        );
        if (validationErr !== true) {
          // throw new JsonValidationException(key, "invalid type");
          output.error[useKey] =
            typeof validationErr === "boolean"
              ? "__not_a_valid_type__"
              : validationErr;
        } else {
          if (jsonPropValidation.validators) {
            let failed = false;
            for (
              let i = 0;
              i < jsonPropValidation.validators.length && !failed;
              i++
            ) {
              const validator = jsonPropValidation.validators[i];
              const success = Validators.validateValue(
                useKey,
                value,
                validator,
                baseValues
              );

              if (!success) {
                output[validator.level][useKey] = validator.text;
                if (validator.level === "error") {
                  failed = true;
                }
              }
            }
          }
        }
      }
    });

    // Log.debug("##JSONVALIDATION ", jsonValue, jsonProperties, prefix, output);
    return output;
  }

  convertValuesToFormData(formValue: any, properties: JsonProperties) {
    if (!formValue) {
      return null;
    }
    const output = Object.entries(
      this.reduceToValidInput(formValue, properties)
    ).map(([key, value]) => {
      const conf = properties?.[key];
      if (conf) {
        if (conf._component === "array" && conf.asArray) {
          return [key, this.convertArrayToFormData(value as any[])];
        }
      }

      return [key, value];
    });

    return {
      ...formValue,
      ...(flatten.unflatten(Object.fromEntries(output), {
        delimiter: "|",
      }) as any),
    };
  }
  convertFormDataToSubmit(formValue: any, properties: JsonProperties) {
    if (!formValue) {
      return null;
    }
    const output = Object.entries(formValue).map(([key, value]) => {
      const conf = properties?.[key];
      if (conf) {
        if (conf._component === "array" && conf.asArray) {
          return [key, this.convertArrayToSubmit(value as ArrayFormFieldValue)];
        }
        if (conf._component === "date" && conf.notTimezoned) {
          return [key, moment(formValue).utc(true).toISOString()];
        }
      }

      return [key, value];
    });

    return Object.fromEntries(output);
  }

  convertArrayToFormData(arrayValue: any[]) {
    if (!arrayValue || !Array.isArray(arrayValue)) {
      return null;
    }
    return Object.fromEntries(
      arrayValue.reduce(
        (prev, current, index) => [
          ...prev,
          [
            `I_${index}`,
            {
              ...current,
              orderIndex:
                index === 0
                  ? LexoRank.middle().toString()
                  : LexoRank.parse(prev[index - 1][1].orderIndex)
                      .genNext()
                      .toString(),
            },
          ],
        ],
        []
      )
    );
  }

  convertArrayToSubmit(arrayValue: ArrayFormFieldValue) {
    if (!arrayValue || Object.values(arrayValue).length === 0) {
      return null;
    }
    return Object.values(arrayValue)
      .filter((e) => !!e)
      .sort((a, b) =>
        LexoRank.parse(a.orderIndex).compareTo(LexoRank.parse(b.orderIndex))
      )
      .map(({ orderIndex, ...data }) => ({ ...data }));
  }
}

const JsonValidation = new JsonValidationClass();
(window as any).JsonValidation = JsonValidation;
export default JsonValidation;
