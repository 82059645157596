import { AssetTypes } from "@/model/AssetTypes";
import ObjectRelatedSelect from "@/modules/structure-components/object/ObjectRelatedSelect";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import { useState } from "react";
import { Field } from "react-final-form";
import FormFieldValues from "../../../../../../../../components/Form/Fields/FormFieldValues";
import FormStruct from "../../../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../../../components/Form/Validation/FormValidators";
import ModalManager from "../../../../../../../../components/ModalComponent/ModalManager";
import StructLoader from "../../../../../../../../components/StructLoader/StructLoader";
import i18n from "../../../../../../../../i18n";
import BFInput from "../../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../../../../modules/abstract-ui/general/Button/BFButton";
import TechnicalUnitStruct from "../../../../../../../../redux/actions/struct/implemented/TechnicalUnitStruct";
import CBTechnicalUnitService from "../../../../config/technical-unit-config/CBTechnicalUnitService";
import {
  CBPortfolioTechnicalUnit,
  CBPortoflioTechnicalUnitData,
} from "../../../interfaces/CBPortfolioAsset";
import "./CBTechnicalUnitForm.scss";
import CBTechnicelUnitFormCustomField from "./CBTechnicelUnitFormCustomField";

interface CBTechnicalUnitFormProps {
  forObjectId?: string;
  asset?: CBPortfolioTechnicalUnit;
  onClose: () => void;
  onSuccess: () => void;
  types?: string[];
  onArchived?: () => void;
}

const getInitialData = (props: CBTechnicalUnitFormProps) => {
  let initialObjectData = {};
  if (props.forObjectId) {
    const obj = OrgaStruct.getObject(props.forObjectId);
    initialObjectData["type"] = obj.type;
    initialObjectData["entity"] = obj.entityId;
    initialObjectData["objectId"] = obj._id;
  }
  if (props.asset) {
    initialObjectData = CBTechnicalUnitService.convertTechnicalUnitDtoToForm(
      props.asset
    );
  } else {
    initialObjectData["rentalUnits"] = [];
  }

  return initialObjectData;
};
const CBTechnicalUnitForm = (props: CBTechnicalUnitFormProps) => {
  const [initial] = useState(getInitialData(props));

  const typeOptions = UnitStruct.getUnitSelectOptions(props.types);
  //     PermissionService.hasBusinessUnitRole("cbc_technicalUnitConfig") || []
  //   );

  return (
    <FormStruct
      className={`cb-technical-unit-form`}
      initialValues={initial}
      submitText={
        props.asset
          ? i18n.t("Global.Buttons.save")
          : i18n.t("Global.Buttons.create")
      }
      additionalActions={
        props.onArchived ? (
          <div>
            <BFButton
              onClick={() => {
                ModalManager.confirm({
                  message: i18n.t(
                    "cb:Portfolio.Objekt.technicalUnit.deleteConfirm",
                    "Mochten Sie diese technische Einheit wirklich entfernen?"
                  ),
                  confirmButtonText: i18n.t(
                    "Global.Buttons.delete",
                    "Entfernen"
                  ),
                  onConfirm: async () => {
                    await CBTechnicalUnitService.disableTechnicalUnit(
                      props.asset
                    );
                    props.onArchived();
                  },
                });
              }}
              appearance="outline"
            >
              {i18n.t("Global.Buttons.delete", "Entfernen")}
            </BFButton>
          </div>
        ) : undefined
      }
      onSubmit={async (values: CBPortoflioTechnicalUnitData) => {
        await CBTechnicalUnitService.submitTechnicalUnitFormData(
          values,
          props.asset?._id
        );

        props.onSuccess();
      }}
      onAbort={props.onClose}
      render={(formProps) => (
        <>
          {!props.forObjectId && (
            <>
              <div className={`__field`}>
                <Field
                  name="type"
                  validate={FormValidators.compose(FormValidators.required())}
                >
                  {({ input, meta }) => (
                    <BFSelect
                      {...input}
                      label={`${i18n.t(
                        "cb:Portfolio.Objekt.technicalUnit.create.type",
                        "Bereich"
                      )}*`}
                      {...FormValidators.getValidation(meta)}
                      data={typeOptions}
                      onChange={(value) => {
                        input.onChange(value);
                        formProps.form.mutators.setValue("entity", null);
                        formProps.form.mutators.setValue("objectId", null);

                        formProps.form.mutators.setValue(
                          "technicalUnitCategory",
                          null
                        );
                        formProps.form.mutators.setValue(
                          "technicalUnitCategorySub",
                          null
                        );
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className={`__field`}>
                <FormFieldValues names={["type"]}>
                  {([type]) => (
                    <Field
                      name="entity"
                      validate={FormValidators.compose(
                        FormValidators.required()
                      )}
                    >
                      {({ input, meta }) => (
                        <BFSelect
                          disabled={!type}
                          {...input}
                          label={`${i18n.t(
                            "cb:Portfolio.Objekt.technicalUnit.create.entity",
                            "Gesellschaft"
                          )}*`}
                          {...FormValidators.getValidation(meta)}
                          data={OrgaStruct.getEntitySelectOptions(type)}
                          onChange={(value) => {
                            input.onChange(value);
                            formProps.form.mutators.setValue("objectId", null);
                          }}
                        />
                      )}
                    </Field>
                  )}
                </FormFieldValues>
              </div>
              <div className={`__field`}>
                <FormFieldValues names={["type", "entity"]}>
                  {([type, entity]) => (
                    <Field
                      name="objectId"
                      validate={FormValidators.compose(
                        FormValidators.required()
                      )}
                    >
                      {({ input, meta }) => (
                        <BFSelect
                          disabled={!type}
                          {...input}
                          label={`${i18n.t(
                            "cb:Portfolio.Objekt.technicalUnit.create.objectId",
                            "Objekt"
                          )}*`}
                          {...FormValidators.getValidation(meta)}
                          data={OrgaStruct.getObjectSelectOptions(entity, [
                            type,
                          ])}
                          onChange={(value) => {
                            input.onChange(value);
                            if (!entity) {
                              const entityObj = OrgaStruct.getEntity(
                                OrgaStruct.getObject(value)?.entityId
                              );
                              formProps.form.mutators.setValue(
                                "entity",
                                entityObj._id
                              );
                            }
                          }}
                        />
                      )}
                    </Field>
                  )}
                </FormFieldValues>
              </div>
            </>
          )}

          <FormFieldValues names={["objectId"]}>
            {([objectId]) => {
              if (!objectId) {
                return null;
              }

              return (
                <Field name="rentalUnits">
                  {({ input, meta }) => {
                    const value = Array.isArray(input.value)
                      ? input.value.map((v) => ({
                          assetId: v,
                          assetType: AssetTypes.Rental.RentalUnit,
                        }))
                      : [];
                    return (
                      <ObjectRelatedSelect
                        block
                        identifier={"ap-unit-relation-selection"}
                        appearance="bf"
                        objectId={objectId}
                        onChange={(value) => {
                          const unitIds = value.map((v) => v.assetId);
                          input.onChange(unitIds);
                        }}
                        value={value}
                        multiple={true}
                        label={i18n.t(
                          "cb:Portfolio.Objekt.technicalUnit.create.rentalUnits",
                          "Mieteinheiten"
                        )}
                        addButtonText={i18n.t(
                          "cb:Portfolio.Objekt.technicalUnits.create.rentalUnitsAdd",
                          "Mieteinheit hinzufügen"
                        )}
                        assets={[AssetTypes.Rental.RentalUnit]}
                      />
                    );
                  }}
                </Field>
              );
            }}
          </FormFieldValues>
          <hr />

          <div className={`__field`}>
            <FormFieldValues names={["type"]}>
              {([type]) => (
                <>
                  {type ? (
                    <StructLoader
                      structType="technicalUnit"
                      unitType={type}
                      render={() => (
                        <Field
                          name="technicalUnitCategory"
                          validate={FormValidators.compose(
                            FormValidators.required()
                          )}
                        >
                          {({ input, meta }) => (
                            <BFSelect
                              disabled={!type}
                              {...input}
                              label={`${i18n.t(
                                "cb:Portfolio.Objekt.technicalUnit.create.technicalUnitCategory",
                                "Art der technischen Einheit"
                              )}*`}
                              {...FormValidators.getValidation(meta)}
                              data={TechnicalUnitStruct.getSelectOptions(type)}
                              onChange={(value) => {
                                input.onChange(value);
                                formProps.form.mutators.setValue(
                                  "technicalUnitCategorySub",
                                  null
                                );
                                formProps.form.mutators.setValue(
                                  "customFields",
                                  {}
                                );
                              }}
                            />
                          )}
                        </Field>
                      )}
                    />
                  ) : (
                    <BFSelect
                      disabled={true}
                      label={`${i18n.t(
                        "cb:Portfolio.Objekt.technicalUnit.create.technicalUnitCategory",
                        "Art der technischen Einheit"
                      )}*`}
                      data={[]}
                    />
                  )}
                </>
              )}
            </FormFieldValues>
          </div>

          <div className={`__field`}>
            <FormFieldValues names={["type", "technicalUnitCategory"]}>
              {([type, technicalUnitCategory]) =>
                type && (
                  <StructLoader
                    structType="technicalUnit"
                    unitType={type}
                    render={() => (
                      <>
                        {technicalUnitCategory &&
                          TechnicalUnitStruct.getSubCategoriesOptions(
                            technicalUnitCategory
                          ).length > 0 && (
                            <Field name="technicalUnitCategorySub">
                              {({ input, meta }) => (
                                <BFSelect
                                  {...input}
                                  label={`${i18n.t(
                                    "cb:Portfolio.Objekt.technicalUnit.create.technicalUnitCategorySub",
                                    "Unterkategorie"
                                  )}`}
                                  {...FormValidators.getValidation(meta)}
                                  data={TechnicalUnitStruct.getSubCategoriesOptions(
                                    technicalUnitCategory
                                  )}
                                  onChange={(value) => {
                                    if (input.value) {
                                      const fields =
                                        TechnicalUnitStruct.getCustomFieldsOfSubCatgeory(
                                          input.value
                                        );

                                      fields.forEach((field) => {
                                        formProps.form.mutators.setValue(
                                          `customFields.${field.id}`,
                                          null
                                        );
                                      });
                                    }

                                    input.onChange(value);
                                  }}
                                />
                              )}
                            </Field>
                          )}
                      </>
                    )}
                  />
                )
              }
            </FormFieldValues>
          </div>

          <div className={`__field`}>
            <Field name="id">
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  label={`${i18n.t(
                    "cb:Portfolio.Objekt.technicalUnit.create.id",
                    "ID"
                  )}*`}
                  validation={
                    meta.error && meta.touched
                      ? { level: "error", message: meta.error }
                      : undefined
                  }
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="displayName">
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  label={`${i18n.t(
                    "cb:Portfolio.Objekt.technicalUnit.create.displayName",
                    "Name"
                  )}*`}
                  validation={
                    meta.error && meta.touched
                      ? { level: "error", message: meta.error }
                      : undefined
                  }
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="note">
              {({ input, meta }) => (
                <BFInput
                  type="textarea"
                  {...input}
                  label={i18n.t(
                    "cb:Portfolio.Objekt.technicalUnit.create.note",
                    "Notiz"
                  )}
                  validation={
                    meta.error && meta.touched
                      ? { level: "error", message: meta.error }
                      : undefined
                  }
                />
              )}
            </Field>
          </div>
          <FormFieldValues
            names={[
              "type",
              "technicalUnitCategory",
              "technicalUnitCategorySub",
            ]}
          >
            {([type, technicalUnitCategory, technicalUnitCategorySub]) => (
              <>
                {type && technicalUnitCategory && (
                  <StructLoader
                    structType="technicalUnit"
                    unitType={type}
                    render={() => (
                      <>
                        {TechnicalUnitStruct.getCustomFields(
                          technicalUnitCategory,
                          technicalUnitCategorySub
                        ).map((field, index) => (
                          <>
                            {index === 0 && <hr />}
                            <CBTechnicelUnitFormCustomField
                              key={field.id}
                              customFieldProps={field}
                            />
                          </>
                        ))}
                      </>
                    )}
                  />
                )}
              </>
            )}
          </FormFieldValues>
        </>
      )}
    />
  );
};

export default CBTechnicalUnitForm;
