import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFInputTable, {
  BFInputTableColumn,
} from "@/modules/abstract-ui/forms/input-table/BFInputTable";
import LanguageService from "@/services/LanguageService";
import StringUtils from "@/utils/StringUtils";
import _ from "lodash";
import { Field } from "react-final-form";
import "./CBRentalAgreementPaymentPositions.scss";

interface CBRentalAgreementPaymentPositionsProps {
  fieldName: string;
  objectId?: string;
}
const CBRentalAgreementPaymentPositions = (
  props: CBRentalAgreementPaymentPositionsProps
) => {
  return (
    <FormFieldValues names={["taxable", "objectId"]}>
      {([taxable, objectId]) => {
        const objectIdToUse = objectId || props.objectId;
        return (
          <>
            {!objectIdToUse && (
              <div className={`select-object-first`}>
                {i18n.t(
                  "cb:RentalAgreement.Form.missingObjectId",
                  "Wählen Sie zuerst ein Objekt aus"
                )}
              </div>
            )}
            {!taxable && (
              <div className={`select-object-first`}>
                {i18n.t(
                  "cb:RentalAgreement.Form.selectTaxableFirst",
                  "Wählen Sie zuerst den Steuerstatus aus"
                )}
              </div>
            )}
            {objectIdToUse && taxable && (
              <AssetLoader
                assetType={AssetTypes.Portfolio.Object}
                id={objectIdToUse}
                render={(object: OAObject) => (
                  <Field name={props.fieldName}>
                    {({ input, meta }) => (
                      <BFInputTable
                        className={`cb-rental-agreement-payment-positions`}
                        value={input.value || []}
                        columns={[
                          {
                            identifier: "net",
                            label: i18n.t(
                              "cb:RentalAgreement.Form.Fields.paymentPositions.net",
                              "Netto"
                            ),
                            flexWidth: 3,
                            defaultValue: 0,
                            inputProps: (rowValue, onRowChange, row) => ({
                              type: "priceInput",
                              textAlign: "left",
                              prefix: StringUtils.getCurrencySymbol(),
                              removeSuffixPadding: true,

                              onChange: (value: number) => {
                                onRowChange({
                                  ...rowValue,
                                  net: value,
                                  gross:
                                    taxable === "taxable"
                                      ? value *
                                        ((row.data?.taxRate || 19) / 100 + 1)
                                      : value,
                                });
                              },
                            }),
                            footer: () => {
                              return (
                                <div className={`footer-sum`}>
                                  {StringUtils.formatCurrency(
                                    _.sum(input.value.map((e) => e.net || 0))
                                  )}
                                </div>
                              );
                            },
                          },
                          ...(taxable === "taxable"
                            ? [
                                {
                                  identifier: "gross",
                                  label: i18n.t(
                                    "cb:RentalAgreement.Form.Fields.paymentPositions.gross",
                                    "Brutto"
                                  ),
                                  flexWidth: 3,
                                  defaultValue: 0,
                                  inputProps: (rowValue, onRowChange, row) => ({
                                    type: "priceInput",
                                    textAlign: "left",
                                    prefix: StringUtils.getCurrencySymbol(),

                                    removeSuffixPadding: true,
                                    onChange: (value: number) => {
                                      onRowChange({
                                        ...rowValue,
                                        gross: value,
                                        net: taxable
                                          ? value /
                                            ((row.data?.taxRate || 19) / 100 +
                                              1)
                                          : value,
                                      });
                                    },
                                  }),
                                  footer: () => {
                                    return (
                                      <div className={`footer-sum`}>
                                        {StringUtils.formatCurrency(
                                          _.sum(
                                            input.value.map((e) => e.gross || 0)
                                          )
                                        )}
                                      </div>
                                    );
                                  },
                                } as BFInputTableColumn,
                                {
                                  identifier: "tax",
                                  label: i18n.t(
                                    "cb:RentalAgreement.Form.Fields.paymentPositions.tax",
                                    "Ust. Betrag"
                                  ),
                                  flexWidth: 2,
                                  render: (rowValue, onChange, row) => {
                                    return (
                                      <div className={`info`}>
                                        {rowValue?.net && rowValue?.gross
                                          ? StringUtils.formatCurrency(
                                              rowValue?.gross - rowValue?.net
                                            )
                                          : "-"}
                                      </div>
                                    );
                                  },

                                  footer: () => {
                                    return (
                                      <div
                                        className={`footer-sum no-left-padding`}
                                      >
                                        {StringUtils.formatCurrency(
                                          _.sum(
                                            input.value.map(
                                              (e) =>
                                                (e.gross || 0) - (e.net || 0)
                                            )
                                          )
                                        )}
                                      </div>
                                    );
                                  },
                                } as BFInputTableColumn,
                              ]
                            : []),
                          {
                            identifier: "type",
                            label: "",
                            flexWidth: 2,
                            render: (value, onChange, row) => (
                              <div className={`info`}>
                                <div className={`info-1`}>{row.data.info1}</div>
                                <div className={`info-2`}>{row.data.info2}</div>
                              </div>
                            ),
                          },
                        ]}
                        rows={
                          //TODO portfolio restruct
                          // OrgaStruct.getRentalTargetPositions(objectId)
                          object.data.feature?.immo?.accounting?.debitposition
                            ?.filter(
                              (position) => position.relevantForAgreement
                            )
                            .filter((position) =>
                              taxable === "taxable"
                                ? position.taxRate !== 0
                                : position.taxRate === 0
                            )
                            .filter(
                              (position) => position.status !== "archived"
                            )
                            .map((position) => ({
                              identifier: position.id,
                              label: LanguageService.translateLabel(
                                position.displayName
                              ),
                              data: {
                                info1: position.automaticDebitPosition
                                  ? i18n.t("cb:", "monatliche Abrechnung")
                                  : "einmalige Abrechnung",
                                taxRate: position.taxRate,
                                info2: ((kind) => {
                                  switch (kind) {
                                    case "rent":
                                      return i18n.t(
                                        "cb:RentalAgreement.Form.Fields.paymentPositions.rent",
                                        "Mieteinnahmen"
                                      );
                                    case "operatingCost":
                                      return i18n.t(
                                        "cb:RentalAgreement.Form.Fields.paymentPositions.operatingCost",
                                        "Betriebskosten"
                                      );
                                    case "operatingCostAllInclusive":
                                      return i18n.t(
                                        "cb:RentalAgreement.Form.Fields.paymentPositions.operatingCostAllInclusive",
                                        "Betriebskostenpauschale"
                                      );
                                    case "additionalPayment":
                                      return i18n.t(
                                        "cb:RentalAgreement.Form.Fields.paymentPositions.additionalPayment",
                                        "Zusatzleistung"
                                      );
                                    default:
                                      return "-";
                                  }
                                })(position.kind),
                              },
                            })) || []
                        }
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                )}
              />
            )}
          </>
        );
      }}
    </FormFieldValues>
  );
};

export default CBRentalAgreementPaymentPositions;
