import { ActivityAbstractStructClass } from "@/redux/actions/struct/implemented/ActivityAbstractStruct";
import classNames from "classnames";
import _ from "lodash";
import MetaRow from "../../../../../components/MetaRow/MetaRow";
import ModalManager from "../../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../../i18n";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import DataBusDefaults from "../../../../../services/DataBusDefaults";
import { APStatusTags } from "../../../../AppConfigInterfaces";
import { ActivityApplicationConstants } from "../../ActivityHooks";
import { APActivity } from "../../ActivityInterfaces";
import ActivityService from "../../ActivityService";
import APActivityData from "./APActivityData";
import "./APActivityDetails.scss";
import APActivityDetailsGallery from "./APActivityDetailsGallery";
import APActivityStartApproval from "./APActivityStartApproval";

interface Props {
  activity: APActivity;
  noCard?: boolean;
  goBack?: () => void;
  constants: ActivityApplicationConstants;
  activityStruct: ActivityAbstractStructClass<any>;
}
const APActivityDetails = (props: Props) => {
  const archiveStatus = props.activityStruct.getStatusByTypeAndTags(
    props.activity.data.type,
    APStatusTags.ARCHIVED
  )?.[0];

  const startApproval = () => {
    const config = props.activityStruct.getConfig(props.activity.data.type);

    if (
      props.activity.data.offers.length >= config.data.offersNeededToStartFlow
    ) {
      ModalManager.show({
        title: i18n.t(
          "apTemplate:ActivitiesStartApproval.Title",
          "Genehmigung starten",
          {
            ns: [props.constants?.assetType, "apTemplate"],
          }
        ),
        buttons: [],
        backdrop: "static",
        size: "md",
        modalClassName: "ap-activity-start-approval-dialog",
        content: (states, setStates, closeModal) => (
          <APActivityStartApproval
            activityStruct={props.activityStruct}
            activity={props.activity}
            onSuccess={(data) => {
              closeModal();
            }}
            onAbort={() => {
              closeModal();
            }}
          />
        ),
      });
    } else {
      DataBusDefaults.toast({
        type: "warning",
        text: i18n.t(
          "apTemplate:ActivitiesStartApproval.NotEnoughOffers",
          "Es sind nicht genügend Angebote vorhanden um die Freigabe zu starten.",
          {
            ns: [props.constants?.assetType, "apTemplate"],
          }
        ),
      });
    }
  };

  const declineDamageClaim = () => {
    return new Promise((resolve, reject) => {
      ModalManager.confirm({
        title: i18n.t(
          "apTemplate:ActivitiesDecline.Title",
          "Schaden ablehnen",
          {
            ns: [props.constants?.assetType, "apTemplate"],
          }
        ),
        message: i18n.t(
          "apTemplate:ActivitiesDecline.Text",
          "Soll die Aktivität wirklich abgelehnt werden?",
          {
            ns: [props.constants?.assetType, "apTemplate"],
          }
        ),
        onConfirm: async () => {
          const result = await ActivityService.declineActivity(
            props.constants?.serviceUrl,
            props.activity
          );
          resolve(result);
        },
        onAbort: () => {
          reject();
        },
        confirmButtonText: i18n.t(
          "apTemplate:ActivitiesDecline.Confirm",
          "Aktivität ablehnen",
          {
            ns: [props.constants?.assetType, "apTemplate"],
          }
        ),
      });
    });
  };

  const archiveDamageClaim = () => {
    return new Promise((resolve, reject) => {
      ModalManager.confirm({
        title: i18n.t(
          "apTemplate:ActivitiesArchive.Title",
          "Aktivität archivieren",
          {
            ns: [props.constants?.assetType, "apTemplate"],
          }
        ),
        message: i18n.t(
          "apTemplate:ActivitiesArchive.Text",
          "Soll die Aktivität wirklich archiviert werden? Die Aktivität wird nicht mehr in den Listen gelistet werden.",
          {
            ns: [props.constants?.assetType, "apTemplate"],
          }
        ),
        onConfirm: async () => {
          const result = await ActivityService.setStatus(
            props.constants?.serviceUrl,
            props.activity._id,
            archiveStatus.id,
            null
          );
          DataBusDefaults.toast({
            type: "success",
            text: i18n.t(
              "apTemplate:ActivitiesArchive.Success",
              "Aktivität wurde als archiviert markiert"
            ),
          });
          resolve(result);
        },
        onAbort: () => {
          reject();
        },
        confirmButtonText: i18n.t(
          "apTemplate:ActivitiesArchive.Confirm",
          "Archivieren",
          {
            ns: [props.constants?.assetType, "apTemplate"],
          }
        ),
      });
    });
  };

  return (
    <div
      className={classNames(`ap-activity-details`, {
        __card: !props.noCard,
      })}
    >
      <APActivityDetailsGallery activity={props.activity} />
      <APActivityData
        constants={props.constants}
        activity={props.activity}
        goBack={props.goBack}
        showStatus
      />

      <div className={`action-row`}>
        {archiveStatus && (
          <BFButton
            className={`archive-button`}
            appearance="outline"
            onClick={archiveDamageClaim}
          >
            {i18n.t(
              "apTemplate:Activity.ArchiveActivity",
              "Aktivität archivieren"
            )}
          </BFButton>
        )}
        {!props.constants?.fields?.disableDeclineButton && (
          <>
            {_.intersection(
              props.activityStruct.getStatus(
                props.activity.data.type,
                props.activity.data.status
              )?.tags || [],
              [APStatusTags.DECLINED_STATUS, APStatusTags.FINISHED_STATUS]
            ).length === 0 && (
              <BFButton
                appearance="outline"
                className={`decline-button`}
                onClick={declineDamageClaim}
              >
                {i18n.t(
                  "apTemplate:Activity.DeclineActivity",
                  "Aktivität ablehnen",
                  {
                    ns: [props.constants?.assetType, "apTemplate"],
                  }
                )}
              </BFButton>
            )}
          </>
        )}
        <div className={`fill`} />
        {!props.constants?.fields?.disableApprovals && (
          <BFButton appearance="outline" onClick={startApproval}>
            {i18n.t(
              "apTemplate:Activity.StartNewApproval",
              "Neue Freigabe starten",
              {
                ns: [props.constants?.assetType, "apTemplate"],
              }
            )}
          </BFButton>
        )}
      </div>

      <div className="meta-container">
        <MetaRow
          meta={props.activity?.meta}
          pretext={i18n.t(
            "apTemplate:Activity.meta.pretext.createdBy",
            "Erstellt von",
            {
              ns: [props.constants?.assetType, "apTemplate"],
            }
          )}
        />
      </div>
    </div>
  );
};

export default APActivityDetails;
