import CDNLink from "../../../../../../components/CDNLink/CDNLink";
import PDFPreview from "../../../../../../components/PDFViewer/PDFPreview";
import InvoiceService from "../../../../invoiceApp/InvoiceService";
import RAInvoiceEntryView from "../../../../invoiceApp/components/RAInvoiceEntryView";
import "./CBIdlyConnectInvoiceSugggestion.scss";
import { InvoiceSuggestion } from "./CBIdlyConnectService";

interface CBIdlyConnectInvoiceSugggestionProps {
  suggestion: InvoiceSuggestion;
}
const CBIdlyConnectInvoiceSugggestion = (
  props: CBIdlyConnectInvoiceSugggestionProps
) => {
  const cdnOriginal = Object.entries(
    props.suggestion.asset.data.invoice.file
  )[0];
  const cdnDataOriginal = {
    filename: InvoiceService.generateFilenameForInvoice(props.suggestion.asset),
    assetField: "data.invoice.file",
    assetId: props.suggestion.asset._id,
    assetType: "invoice",
    cdnData: cdnOriginal[1],
    cdnId: cdnOriginal[0],
  };
  return (
    <div className={`cb-idly-connect-invoice-suggestion`}>
      <div className={`pdf-preview-container`}>
        <CDNLink
          assetType={cdnDataOriginal.assetType}
          assetId={cdnDataOriginal.assetId}
          assetField={cdnDataOriginal.assetField}
          cdnId={cdnDataOriginal.cdnId}
          filename={cdnDataOriginal.cdnData.filename}
          fileKey={cdnDataOriginal.cdnData.key}
          hasFolderReadPermissions={true}
          render={(url) => <PDFPreview url={url} width={100} height={150} />}
        />
      </div>
      <RAInvoiceEntryView
        invoice={props.suggestion.asset}
        ignoreHistory
        showInvoiceId
        simpleView
        hideRelations
        showInvoiceDate
        showInvoiceIdHeader
        hideCostCenter
        hideTags
      />
    </div>
  );
};

export default CBIdlyConnectInvoiceSugggestion;
