import { RentalUnit } from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import CBRentalUnitDetails from "@/apps/tatar/cashBudget/views/tenants/components/rental-unit/CBRentalUnitDetails";
import AssetDetailModal from "@/components/AssetDetailModal/AssetDetailModal";
import StructLoader from "@/components/StructLoader/StructLoader";
import { openDetailsAction } from "@/modals/GlobalDetailsFunctions";
import { AssetTypes } from "@/model/AssetTypes";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

interface Props {}
const ROUTE_NAME = "__asset_rental-unit";
const RentalUnitDetailsModal = (props: Props) => {
  const history = useHistory();
  useEffect(() => {
    openDetailsAction(AssetTypes.Rental.RentalUnit, ROUTE_NAME);
  }, []);
  return (
    <AssetDetailModal
      assetType={AssetTypes.Rental.RentalUnit}
      routeName={ROUTE_NAME}
      size="full"
      className="cb-rental-modal unit"
      render={(asset: RentalUnit) => (
        <div style={{ height: "calc(100vh - 50px)" }}>
          <StructLoader
            unitType={asset.data.type}
            structTypes={["orga", "objectKind"]}
            render={() => {
              const object = OrgaStruct.getObject(asset.data.objectId);
              const kind = ObjectKindStruct.getKind(object.objectKindId);
              return (
                <CBRentalUnitDetails
                  kind={kind}
                  goBack={() => {
                    // DataBusDefaults.closeModal(ROUTE_NAME);
                    history.goBack();
                  }}
                  rentalUnit={asset}
                  integrated
                />
              );
            }}
          />
        </div>
      )}
    />
  );
};

export default RentalUnitDetailsModal;
