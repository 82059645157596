import React, { Component } from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import ActionComponent from "../../configurable/components/ActionComponent/ActionComponent";
import Log from "../../debug/Log";
import { User } from "../../model/db/User";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import GenericForms, { FormDefinition } from "../../modules/generic-forms/GenericForms";
import { AppState } from "../../redux/store";
import { loginUser } from "../../services/AuthenticationService";
import DataBus from "../../services/DataBus";
import { SubmitMessage } from "../../services/SubmitService";
import { DataBusSubKeys } from "../../utils/Constants";
import { handleError } from "../../utils/ErrorCodes";
import "./ResetPasswordForm.scss";

type Props = {
	loginUser: (
		email: string,
		password: string,
		mandator: string,
		onSuccess: (data: User) => void,
		onError: (err: any) => void
	) => void;
	formDefinition: FormDefinition;
} & RouteComponentProps &
	WithTranslation;

type States = {
	submitted: boolean;
};

const actionIDSubmit = "reset-password-submit";
class ResetPasswordForm extends Component<Props, States> {
	static defaultProps = {};
	readonly state: States = {
		submitted: false
	};

	componentDidMount() {}
	render() {
		const { submitted } = this.state;

		return (
			<div className="reset-password-form">
				{submitted ? (
					<>
						<div>
							<Trans i18nKey={"views.forgotPassword.Success"} />
						</div>
						<div className="action-row">
							<div className="fill" />
							<BFButton
								textKey={"views.completeRegistration.backToLogin"}
								onClick={() => DataBus.emit("ROUTE", { route: "/login" })}
							/>
							<div className="fill" />
						</div>
					</>
				) : (
					<>
						<GenericForms
							translateFunc={this.props.i18n.t}
							formValue={{}}
							actionIdMapping={{
								submit: actionIDSubmit
							}}
							formDefinition={this.props.formDefinition}
							onFormSubmit={data => {
								return new Promise((resolve, reject) => {
									DataBus.emit(DataBusSubKeys.SUBMIT, {
										id: "reset-password",
										type: "user",
										initialData: {},
										data,
										pushToCache: true,
										properties: this.props.formDefinition.properties,
										overwriteUrl: "user/requestNewPassword",
										overwriteMethod: "post",
										onSuccess: result => {
											this.setState({
												submitted: true
											});
											resolve(result);
										},
										onError: err => {
											handleError(err);
											reject();
										}
									} as SubmitMessage);
								});
							}}
							onFormCancel={() => Log.debug("canceled")}
						/>

						<div className="action-row">
							<BFButton
								textKey={"views.completeRegistration.backToLogin"}
								onClick={() => DataBus.emit("ROUTE", { route: "/login" })}
							/>
							<div className="fill" />
							<ActionComponent actionId={actionIDSubmit} appearance={"primary"} textKey={"Neues Passwort anfordern"} />
						</div>
					</>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: AppState) => ({
	// user: state.global.user
	formDefinition: state.global.config.standardForms["resetPassword"]
});

export default withTranslation()(connect(mapStateToProps, { loginUser })(withRouter(ResetPasswordForm)));
