import moment from "moment";
import { InfiniteTableSortOption } from "../../../configurable/data/SortComponent/BaseTableSort";
import i18n from "../../../i18n";
import LanguageService from "../../../services/LanguageService";
import StringUtils from "../../../utils/StringUtils";
import ACInvoiceUtils from "../../utils/ACInvoiceUtils";
import { InvoiceStatus } from "./RAInterfaces";

export const RA_UPLOAD_INCOMING_INVOICE_LIST =
  "RA_UPLOAD_INCOMING_INVOICE_LIST";
export const RA_INVOICE_LIST_TABLE_IDENTIFIER =
  "RA_INVOICE_LIST_TABLE_IDENTIFIER";
export const RA_INVOICE_TABLE_IDENTIFIER = "RA_INVOICE_TABLE_IDENTIFIER";
export const RA_APPROVAL_LIST_TABLE_IDENTIFIER =
  "RA_APPROVAL_LIST_TABLE_IDENTIFIER";
export const RA_COMPLETE_FOLLOW_UP_LIST_TABLE_IDENTIFIER = `RA_COMPLETE_FOLLOW_UP_LIST_TABLE_IDENTIFIER`;
export const RA_CHECK_INVOICES_LIST_TABLE_IDENTIFIER = `RA_CHECK_INVOICES_LIST_TABLE_IDENTIFIER`;
export const RA_INVOICE_MAIN_FIELDS = () => ({
  invoiceType: {
    identifier: "invoiceType",
    // extractField: "data.invoice.invoiceType",

    type: "string",
    label: i18n.t("Invoice.invoiceType"),
  },
  entity: {
    identifier: "entity",
    type: "string",
    label: i18n.t("Invoice.entity"),
  },

  objectId: {
    identifier: "objectId",
    type: "string",
    label: i18n.t("Invoice.objectId"),
  },
  activity: {
    identifier: "activity",
    type: "object",
    label: i18n.t("Invoice.activity", "Zuordnung"),
  },

  usage: {
    identifier: "paymentTypeData.usage",
    extractField: "data.invoice.fields.usage",
    type: "string",
    label: i18n.t("Invoice.usage"), // "Verwendungszweck",
  },

  invoiceId: {
    identifier: "invoiceId",
    extractField: "data.invoice.fields.invoiceId",
    type: "string",
    label: i18n.t("Invoice.invoiceId"), //"Rechnungsnummer",
  },

  documentDate: {
    identifier: "documentDate",
    extractField: "data.invoice.fields.documentDate",
    type: "string",
    label: i18n.t("Invoice.documentDate"), // "Rechnungsdatum",
    formatter: (value: Date) =>
      moment(value).format(i18n.t("Formats.dateFormat")),
  },

  amountToPay: {
    identifier: "value",
    extractField: "data.invoice.fields.amountToPay",
    type: "string",
    label: i18n.t("Invoice.invoiceAmount", "Rechnungsbetrag"), // "Betrag",
    formatter: (value: number) => StringUtils.formatCurrency(value),
  },

  contact: {
    identifier: "contact",
    extractField: "data.invoice.fields.paymentRecipient",
    type: "string",
    label: i18n.t("Invoice.paymentRecipient"), // "Zahlungsempfänger",
  },

  tags: {
    identifier: "tags",
    extractField: "data.tags",
    label: i18n.t("Invoice.tags", "Tags"), //  "Fälligkeitsdatum",
  },

  paymentDueDate: {
    identifier: "paymentTypeData.paymentDueDate",
    extractField: "data.invoice.fields.paymentDueDate",
    type: "string",
    label: i18n.t("Invoice.paymentDueDate"), //  "Fälligkeitsdatum",
  },
  iban: {
    identifier: "paymentTypeData.iban",
    extractField: "data.invoice.fields.iban",
    type: "string",
    label: i18n.t("Invoice.iban"), // "Empfänger IBAN",
  },
  account: {
    identifier: "paymentTypeData.account",
    type: "string",
    label: i18n.t("Invoice.account", "Kontonummer"),
  },
  bic: {
    identifier: "paymentTypeData.bic",
    extractField: "data.invoice.fields.bic",
    type: "string",
    label: i18n.t("Invoice.bic", "BIC"),
  },
  routing: {
    identifier: "paymentTypeData.routing",
    type: "string",
    label: i18n.t("Invoice.routing", "Routing"),
  },
  swift: {
    identifier: "paymentTypeData.swift",
    type: "string",
    label: i18n.t("Invoice.swift", "Swift"),
  },
  paymentType: {
    identifier: "paymentType",
    extractField: "data.invoice.fields.paymentType",
    type: "string",
    label: i18n.t("Invoice.paymentType"), // "Bezahlart",
  },
});
export const RA_INVOICE_ADDITIONAL_INFOFIELDS = () => ({
  paymentReference: {
    identfier: "info.paymentReference",
    extractField: "data.invoice.info.paymentReference",

    type: "string",
    label: i18n.t("Invoice.paymentReference"), // "Zahlungsreferenz",
  },

  referenceId: {
    identifier: "info.referenceId",

    extractField: "data.invoice.info.referenceId",
    type: "string",
    label: i18n.t("Invoice.referenceId"), // "Referenz-ID",
  },
  website: {
    identifier: "info.website",
    extractField: "data.invoice.info.website",
    type: "string",
    label: i18n.t("Invoice.website"), //  "Webseite",
  },
  customerId: {
    identifier: "info.customerId",
    extractField: "data.invoice.info.website",
    type: "string",
    label: i18n.t("Invoice.customerId"), //  "Kundennummer",
  },
  orderNumber: {
    identfier: "info.orderNumber",
    extractField: "data.invoice.info.orderNumber",
    type: "string",
    label: i18n.t("Invoice.orderNumber"), // "Bestellnummer",
  },
  email: {
    identifier: "info.email",
    extractField: "data.invoice.info.email",

    type: "string",
    label: i18n.t("Invoice.email"), //  "Email",
  },
  recipientName: {
    identifier: "info.recipientName",
    extractField: "data.invoice.info.recipientName",

    type: "string",
    label: i18n.t("Invoice.recipientName"), // "Empfänger",
  },
  vehiclePlateNumber: {
    identifier: "info.vehiclePlateNumber",
    extractField: "data.invoice.info.vehiclePlateNumber",
    type: "string",
    label: i18n.t("Invoice.vehiclePlateNumber"), // "KFZ-Kennzeichen",
  },
  senderName: {
    identifier: "info.senderName",
    extractField: "data.invoice.info.senderName",
    type: "string",
    label: i18n.t("Invoice.senderName"), // "Absender",
  },
});

export const RA_ALL_STATUS: InvoiceStatus[] = [
  "init",
  "inApproval",
  "approved",
  "declined",
];
export const getInvoiceStatusLabel = (status: InvoiceStatus) => {
  switch (status) {
    case "init":
      return i18n.t("Invoice.Status.init", "Angelegt");
    case "approved":
      return i18n.t("Invoice.Status.approved", "Genehmigt");
    case "inApproval":
      return i18n.t("Invoice.Status.inApproval", "In Genehmigung");
    case "declined":
      return i18n.t("Invoice.Status.declined", "Abgelehnt");
    default:
      return "-";
  }
};

// export const RA_ALL_PAYMENT_TYPE: PaymentType[] = [
//   "debitCharge",
//   "creditTransfer",
//   "creditCompensation",
// ];

// export const RA_ALL_PAYMENT_TYPE_WITH_STORNO: PaymentType[] = [
//   ...RA_ALL_PAYMENT_TYPE,
//   "storno",
// ];
export const getPaymentTypeLabel = (type: string) => {
  return ACInvoiceUtils.getPaymentType(type)?.displayName
    ? LanguageService.translateLabel(
        ACInvoiceUtils.getPaymentType(type)?.displayName
      )
    : type;
};
export const getInvoiceTypeLabel = (type: string) => {
  return ACInvoiceUtils.getInvoiceType(type)?.data.displayName
    ? LanguageService.translateLabel(
        ACInvoiceUtils.getInvoiceType(type).data.displayName
      )
    : type;
};

export enum InvoicePermissions {
  invoice_upload = "invoice_upload",
  invoice_openApprovals = "invoice_openApprovals",
  invoice_followups = "invoice_followups",
  invoice_accounting = "invoice_accounting",
  invoice_unblock = "invoice_unblock",
  invoice_payment = "invoice_payment",
  invoice_list = "invoice_list",
}

export const SOON_DUE_DAYS = 7;

export const DatabusKey_PaymentSubmitFailure =
  "DatabusKey_PaymentSubmitFailure";

export const RAInvoiceTaskListSortOptions: InfiniteTableSortOption[] = [
  {
    definition: {
      key: "data.assignmentDate",
      dir: "desc",
    },
    label: () => i18n.t("ra:TaskListSort.AssignmentDate", "Genehmigungsdatum"),
    icon: "arrange-list-descending",
  },
  {
    definition: {
      key: "data.assignmentDate",
      dir: "asc",
    },
    label: () => i18n.t("ra:TaskListSort.AssignmentDate", "Genehmigungsdatum"),
    icon: "arrange-list-ascending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.invoice.documentDate",
      dir: "desc",
    },
    label: () => i18n.t("ra:TaskListSort.InvoiceDate", "Rechnungsdatum"),
    icon: "arrange-list-descending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.invoice.documentDate",
      dir: "asc",
    },
    label: () => i18n.t("ra:TaskListSort.InvoiceDate", "Rechnungsdatum"),
    icon: "arrange-list-ascending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.invoice.value.converted.amount",
      dir: "desc",
    },
    label: () => i18n.t("ra:TaskListSort.InvoiceAmount", "Betrag"),
    icon: "arrange-list-descending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.invoice.value.converted.amount",
      dir: "asc",
    },
    label: () => i18n.t("ra:TaskListSort.InvoiceAmount", "Betrag"),
    icon: "arrange-list-ascending",
  },
];
