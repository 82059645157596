import moment from "moment";
import i18n from "../../../../../i18n";
import { MatchQuery } from "../../../../../services/DataService";
import { BfIconProps } from "../../../icon/BfIcon";
import { FilterOptionWithMatchQueryConverter } from "../BFTableFilterModel";
import OverlayDateSelection, {
  DefaultDateSelectionValue,
  generateLabelForDateFilter,
  generateMatchQueryForDateFilter,
  getDefaultDateOptions,
} from "../overlays/OverlayDateSelection";

export const DateFilterOption: (opts: {
  field: string;
  label: string;
  icon?: BfIconProps;
  matchQueryConverter?: (value: any) => MatchQuery | null;
}) => FilterOptionWithMatchQueryConverter = (opts) => {
  const { field, label, icon } = opts;
  const matchQueryConverter =
    opts.matchQueryConverter ||
    ((value: any) => generateMatchQueryForDateFilter(field, value));
  return {
    matchQueryConverter: matchQueryConverter,
    key: field,
    label: label,
    icon: {
      type: icon?.type || "light",
      data: icon?.data || "calendar-cash-1",
    },
    defaultValue: DefaultDateSelectionValue,
    renderOverlay: (value, onChange) => (
      <OverlayDateSelection title={label} value={value} onChange={onChange} />
    ),
    renderValueData: (value) => ({
      typeLabel: label,
      valueLabel: generateLabelForDateFilter(value, (value) =>
        moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
      ),
    }),
    getOptions: (currentValue, searchTerm) =>
      getDefaultDateOptions(currentValue, searchTerm, field, label, (value) =>
        moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
      ),
  };
};
