import React, { Component } from "react";
import { Popover } from "rsuite";
import Log from "../../debug/Log";
import { PlacementAll } from "../../modules/abstract-ui/common/Placements";
import BFWhisper from "../../modules/abstract-ui/general/whisper/BFWhisper";
import DataBus from "../../services/DataBus";
import "./ContextContainerComponent.scss";

type Props = {
  container?: HTMLElement | (() => HTMLElement); //	Sets the rendering container
  renderContextMenu: () => React.ReactNode;
  placement?: PlacementAll;
};
type States = {
  show: boolean;
  posX: number;
  posY: number;
};

class ContextContainerComponent extends Component<Props, States> {
  static defaultProps = {
    placement: "auto",
    disabled: false,
  };
  readonly state: States = {
    show: false,
    posX: 0,
    posY: 0,
  };
  ref;
  subId;
  componentDidMount() {
    this.subId = DataBus.subscribe("ContextMenuHide", () => {
      this.closeContextMenu();
    });
  }
  componentWillUnmount() {
    DataBus.unsubscribe(this.subId);
  }

  speaker() {
    return (
      <Popover className={`context-container-popover-container`}>
        <div className={`context-container-popover`}>
          {this.props.renderContextMenu()}
        </div>
      </Popover>
    );
  }

  openContextMenu(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    Log.info("context", ev.nativeEvent);
    ev.preventDefault();
    this.setState({
      show: true,
      posX: ev.nativeEvent.offsetX,
      posY: ev.nativeEvent.offsetY,
    });
    setTimeout(() => {
      this.ref.click();
    });
  }
  closeContextMenu() {
    // (window.document as any).click();
    // this.ref.parent.click();
    (document.querySelector("#root") as any).click();
  }
  render() {
    const {} = this.props;

    return (
      <div
        className="context-container-component"
        onContextMenu={(ev) => this.openContextMenu(ev)}
      >
        <div
          className="context-position-reference"
          style={{ top: this.state.posY, left: this.state.posX }}
        >
          <BFWhisper
            onExiting={() => this.setState({ show: false })}
            // open={this.state.show}
            onBlur={() => this.setState({ show: false })}
            trigger={"click"}
            placement={this.props.placement}
            container={this.props.container}
            speaker={this.speaker()}
          >
            <div ref={(ref) => (this.ref = ref)} className={"faker"}></div>
          </BFWhisper>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default ContextContainerComponent;
