import classNames from "classnames";
import { useState } from "react";
import { Loader } from "rsuite";
import ModalManager from "../../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../../i18n";
import BFDropzone from "../../../../../modules/abstract-ui/dropzone/BFDropzone";
import BFPortal from "../../../../../modules/abstract-ui/general/Portal/BFPortal";
import BfIcon from "../../../../../modules/abstract-ui/icon/BfIcon";
import DataBusDefaults from "../../../../../services/DataBusDefaults";
import InvoiceService, { InvoiceCreationOptions } from "../../InvoiceService";
import { InvoiceDirection } from "../../RAInterfaces";
import "./RAUploadInvoiceContainer.scss";

interface Props {
  types: { label: string; value: string }[];
  render: (open: () => void) => React.ReactNode;
  className?: string;
  forceConfirmation?: boolean;
  reloadTableIdentifiers?: string[];
  invoiceOptions?: InvoiceCreationOptions;
  direction: InvoiceDirection;
}
const RAUploadInvoiceContainer = (props: Props) => {
  const [uploadModalStatus, setUploadModalStatus] = useState<
    "unitSelection" | "uploading"
  >(null);
  const [uploadState, setUploadState] = useState<ProgressEntry[]>(null);

  return (
    <>
      <div
        className={classNames("ra-upload-invoice-container", props.className)}
        style={{ display: "flex", height: "100%", width: "100%" }}
      >
        {!!uploadModalStatus && (
          <BFPortal>
            <RAUploadIncomingInvoiceProgress progress={uploadState} />
          </BFPortal>
        )}
        <BFDropzone
          multipe={true}
          className="dropzone-upload-invoice"
          onDrop={(accepted, rejected, ev) => {
            const startUpload = (type: string) => {
              setUploadState(accepted.map((file) => ({ file, progress: 0 })));
              setUploadModalStatus("uploading");
              InvoiceService.uploadNewInvoices(
                props.direction,
                type,
                accepted,
                (progress, index, file) => {
                  setUploadState((prev) => {
                    const newProgress = [...prev];
                    newProgress[index] = {
                      file,
                      progress,
                    };
                    return newProgress;
                  });
                },
                props.reloadTableIdentifiers,
                props.invoiceOptions
              ).finally(() => {
                setUploadState(null);
                setUploadModalStatus(null);
              });
            };

            const unitSelectOptions = props.types;
            if (accepted.length > 0 && rejected.length === 0) {
              if (unitSelectOptions.length > 1) {
                ModalManager.select({
                  title: i18n.t(
                    "ra:UploadIncomingInvoice.SelectUnitTitle",
                    "Bereich auswählen"
                  ),
                  message: i18n.t(
                    "ra:UploadIncomingInvoice.SelectUnitDescription",
                    "Bitte wählen Sie den Bereich aus, für welchen Sie die Rechnungen hochladen möchten."
                  ),
                  optionsLabel: i18n.t("ra:Label.unit", "Bereich"),
                  defaultValue: unitSelectOptions[0].value,
                  options: unitSelectOptions,
                  optionsProps: {
                    cleanable: false,
                  },
                  onSelect: (value) => {
                    startUpload(value);
                  },
                  confirmButtonText: i18n.t(
                    "Global.Buttons.upload",
                    "Hochladen"
                  ),
                });
              } else if (props.forceConfirmation) {
                ModalManager.confirm({
                  message: i18n.t(
                    "ra:UploadIncomingInvoice.ConfirmUpload",
                    "Möchten Sie die Rechnungen hochladen?"
                  ),
                  onConfirm: () => startUpload(unitSelectOptions[0].value),
                  confirmButtonText: i18n.t(
                    "Global.Buttons.upload",
                    "Hochladen"
                  ),
                });
              } else {
                startUpload(unitSelectOptions[0].value);
              }
            } else {
              DataBusDefaults.toast({
                type: "error",
                text: i18n.t(
                  "ra:UploadIncomingInvoice.UploadError",
                  "Mindestens eine der Datei erfüllt nicht die Anforderungen für den Upload (PDF, max. 20MB)."
                ),
              });
            }
          }}
          accept={{ "application/pdf": [] }}
          render={props.render}
        />
      </div>
    </>
  );
};
export default RAUploadInvoiceContainer;

type ProgressEntry = {
  file: File;
  progress: number;
};
interface RAUploadIncomingInvoiceProgressProps {
  progress: ProgressEntry[];
}
const RAUploadIncomingInvoiceProgress = (
  props: RAUploadIncomingInvoiceProgressProps
) => {
  const current = props.progress.findIndex((e) => e.progress !== 1);

  return (
    <div className="ra-upload-invoice-progress">
      <div className="text">
        {i18n.t("ra:UploadIncomingInvoice.FileUpload", "Dateiupload")} (
        {props.progress.filter((e) => e.progress === 1).length}/
        {props.progress.length})
      </div>
      <div className="progress-entry-container">
        <div
          className="entries"
          style={{
            transform: `translateY(${-(Math.max(0, current - 1) * 53)}px)`,
          }}
        >
          {props.progress.map((entry, index) => {
            return (
              <div className="progress-entry" key={index}>
                <div className="file-name">
                  {entry.file?.name || `Datei ${index}`}
                </div>
                <div className="indicator">
                  {entry.progress !== 1 && <Loader size="xs" />}
                  {entry.progress === 1 && (
                    <BfIcon
                      className="finish-icon"
                      type="light"
                      data="check-1"
                      size="xs"
                    />
                  )}
                </div>
                <div
                  className="progress"
                  style={{ width: `${entry.progress * 100}%` }}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
