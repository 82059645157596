import { APConfigBase } from "../../../../apps/AppConfigInterfaces";
import { HTTP } from "../../../../utils/Http";
import { StructType } from "../../../reducers/struct/StructInterface";
import { DataByUnitType } from "../AbstractStructSelectors";
import { ActivityAbstractStructClass } from "./ActivityAbstractStruct";

class TenantCommunicationStructClass extends ActivityAbstractStructClass<"tenantCommunication"> {
  getStructType(): StructType {
    return "tenantCommunication";
  }
  loadStructData(
    types: string[]
  ): Promise<DataByUnitType<APConfigBase<"tenantCommunication">>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: "/tenantCommunication/getConfig",
          target: "EMPTY",
          queryParams: {
            param: {
              types: types,
            },
          },
        })) as APConfigBase<"tenantCommunication">[];

        const result: DataByUnitType<APConfigBase<"tenantCommunication">> = {};
        types.forEach((type) => {
          result[type] = data.find((config) => config.data.type === type);
        });
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }
}
const TenantCommunicationStruct = new TenantCommunicationStructClass();
export default TenantCommunicationStruct;
