import { Component } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../../redux/store";
import "./DemoDashboardView.scss";

type Props = {};
type States = {};

class DemoDashboardView extends Component<Props, States> {
  render() {
    return <div className="demo-dashboard-view page">DASHBOARD HERE</div>;
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps: any
) => ({
  // setUserData: (user: User, apps: Application[], permissions: Permission[]) => { dispatch(setUserData(user, apps, permissions)) }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemoDashboardView) as any;
