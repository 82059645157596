import i18n from "@/i18n";
import { hasValue } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import Calendar from "rsuite/esm/Calendar";
import BFButton from "../../general/Button/BFButton";
import BFDropdown from "../../general/Dropdown/BFDropdown";
import { ValidatorPopoverStyle } from "../../general/ValidationPopover/ValidationPopover";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import BFInput from "../input/BFInput";
import "./BFDatefield.scss";
import DateFieldUtils from "./DateFieldUtils";

interface BFDatefieldProps {
  className?: string;
  identifier?: string;
  label?: string;
  labelPosition?: "top" | "left";
  info?: string;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  preventNull?: boolean;
  validatorStyle?: ValidatorPopoverStyle;
  disabled?: boolean;
  readonly?: boolean;
  hint?: string;
  //   dateType?: "year" | "month" | "day"; // | "time" | "datetime";
  value: Date;
  asDropdown?: boolean;
  onChange: (value: Date) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}
const BFDatefield = (props: BFDatefieldProps) => {
  const inputRef = useRef<BFInput>(null);
  const [identifier] = useState<string>(
    props.identifier || _.uniqueId("bf-datefield_")
  );
  const [textValue, setTextValue] = useState<string>(
    hasValue(props.value) ? StringUtils.formatDate(props.value) : ""
  );
  useEffect(() => {
    if (props.value) {
      setTextValue(StringUtils.formatDate(props.value));
    } else {
      setTextValue("");
    }
  }, [props.value]);

  let translations = i18n.getResourceBundle(i18n.language, "common");

  return (
    <div className={classNames(`bf-datefield`)}>
      <BFInput
        placeholder={DateFieldUtils.getFormat()}
        ref={inputRef}
        onFocus={() => {
          props?.onFocus?.();
          inputRef.current?.inputRef?.select();
        }}
        onBlur={() => {
          props?.onBlur?.();
          if (textValue) {
            const textDate = DateFieldUtils.getDateOfString(textValue);
            if (textDate) {
              props.onChange(textDate);
              setTextValue(StringUtils.formatDate(textDate));
            } else {
              setTextValue(
                props.value ? StringUtils.formatDate(props.value) : ""
              );
            }
          } else {
            if (!props.preventNull) {
              props.onChange(null);
              setTextValue("");
            }
          }
        }}
        suffix={
          props.readonly ? undefined : (
            <div className={`suffix-buttons`}>
              {!props.preventNull && hasValue(props.value) && (
                <BFButton
                  className={`clear-button`}
                  disabled={props.disabled}
                  appearance="clear-on-white"
                  onClick={() => {
                    props.onChange(null);
                    setTextValue("");
                  }}
                  size="xs"
                >
                  <BfIcon {...DefaultIcons.CLOSE} size="xxs" />
                </BFButton>
              )}
              <BFDropdown
                overlayWidth={300}
                label=""
                asOverlay={!props.asDropdown}
                identifier={identifier}
                placement={"bottomEnd"}
                items={[
                  {
                    type: "panel",
                    render: (context, close) => {
                      return (
                        <div
                          className={`bf-datefield-datepicker`}
                          style={{ width: 300 }}
                        >
                          <Calendar
                            value={props.value}
                            defaultValue={props.value || new Date()}
                            compact
                            onSelect={(date) => {
                              props.onChange(date);
                              close();
                              // DataBus.emit("WHISPER", {
                              //   identifier: identifier,
                              //   type: "CLOSE",
                              // });
                            }}
                            // renderCell={(date: Date) => <div>test</div>}
                            isoWeek
                            locale={translations?.["Global.dates"] || undefined}
                          />
                        </div>
                      );
                    },
                  },
                ]}
                toggleAs={(bprops) => (
                  <BFButton
                    {...bprops}
                    appearance="clear-on-white"
                    disabled={props.disabled}
                  >
                    <BfIcon type="light" data="calendar" size="xxs" />
                  </BFButton>
                )}
              />
              {/* <BFOverlay
                identifier={identifier}
                trigger="click"
                enterable
                placement="bottomEnd"
                speaker={
                  <div
                    className={`bf-datefield-datepicker`}
                    style={{ maxWidth: 300 }}
                  >
                    <Calendar
                      value={props.value}
                      defaultValue={props.value || new Date()}
                      compact
                      onSelect={(date) => {
                        props.onChange(date);
                        DataBus.emit("WHISPER", {
                          identifier: identifier,
                          type: "CLOSE",
                        });
                      }}
                      // renderCell={(date: Date) => <div>test</div>}
                      isoWeek
                      locale={translations?.["Global.dates"] || undefined}
                    />
                  </div>
                }
              >
                <div className={`datefield-suffix`}>
                  <BFButton
                    disabled={props.disabled}
                    appearance="clear-on-white"
                  >
                    <BfIcon type="light" data="calendar" size="xxs" />
                  </BFButton>
                </div>
              </BFOverlay> */}
            </div>
          )
        }
        addonsInside
        disabled={props.disabled}
        readonly={props.readonly}
        removeSuffixPadding
        label={props.label}
        labelPosition={props.labelPosition}
        info={props.info}
        validation={props.validation}
        validatorStyle={props.validatorStyle}
        value={textValue}
        onChange={(textValue: string) => setTextValue(textValue)}
        hint={props.hint}
      />
    </div>
  );
};

export default BFDatefield;
