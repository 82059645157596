import _ from "lodash";
import MultipleAssetLoaders from "../../../../components/AssetLoader/MultipleAssetLoaders";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import BFUseTooltip from "../../../../modules/abstract-ui/general/tooltip/BFUseTooltip";
import BfIcon from "../../../../modules/abstract-ui/icon/BfIcon";
import StringUtils from "../../../../utils/StringUtils";
import { RAInvoice } from "../../invoiceApp/RAInterfaces";
import { LinkedAsset } from "../model/CashBudgetEntry";
import "./CashBudgetConnectionsInvoiceSum.scss";

interface CashBudgetConnectionsInvoiceSumProps {
  value: number;
  assets: LinkedAsset[];
}
const CashBudgetConnectionsInvoiceSum = (
  props: CashBudgetConnectionsInvoiceSumProps
) => {
  const invoiceAssets =
    props.assets?.filter((e) => e.assetType === AssetTypes.Invoice) || [];

  if (invoiceAssets.length === 0) {
    return null;
  }
  return (
    <MultipleAssetLoaders
      assets={invoiceAssets}
      render={(assets: RAInvoice[]) => {
        const sum = _.sum(
          assets
            .filter((e) => e)
            .map((invoice) => invoice.data.invoice.value.converted?.amount || 0)
        );

        const hasPlanInvoices = assets
          .filter((e) => e)
          .some((asset) => asset.data.payment.paymentPlan?.length > 1);

        return (
          <div className={`cash-budget-connections-invoice-sum`}>
            <span className={`label`}>
              {i18n.t("Attachment.invoiceSum", "Rechnungssumme")}{" "}
            </span>
            <span className={`sum`}>{StringUtils.formatCurrency(sum)}</span>
            <span> {i18n.t("Global.Labels.from", "von")} </span>

            <span className={`from`}>
              {StringUtils.formatCurrency(Math.abs(props.value))}
            </span>

            <span className={`difference-label`}>
              {i18n.t("Global.Labels.difference", "Differenz")}{" "}
            </span>
            <span className={`difference`}>
              {StringUtils.formatCurrency(Math.abs(props.value) - sum)}
            </span>

            {hasPlanInvoices && (
              <BFUseTooltip
                tooltip={i18n.t(
                  "Attachment.invoiceSumTooltipHasPlanInvoices",
                  "Die Rechnungssumme beinhatet eine Zahlungsplanung. Die Differenz könnte deswegen abweichen."
                )}
              >
                <div>
                  <BfIcon type="light" data="information-circle" size="xs" />
                </div>
              </BFUseTooltip>
            )}
          </div>
        );
      }}
    />
  );
};

export default CashBudgetConnectionsInvoiceSum;
