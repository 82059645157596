import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import {
  InvoiceDirection,
  RAInvoice,
} from "../../../apps/tatar/invoiceApp/RAInterfaces";
import { getIncomingInvoiceColumns } from "../../../apps/tatar/invoiceApp/components/ListInvoices/RAInvoicesList";
import QueryLoader from "../../../components/QueryLoader/QueryLoader";
import StructLoader from "../../../components/StructLoader/StructLoader";
import VirtualizedTable from "../../../configurable/data/VirtualizedTable/VirtualizedTable";
import i18n from "../../../i18n";
import { AssetTypes } from "../../../model/AssetTypes";
import { Contact } from "../../../model/db/Contact";
import InvoiceStruct from "../../../redux/actions/struct/implemented/InvoiceStruct";
import {
  AggregationStatisticQueryResult,
  AggregationStatisticQuerySelector,
} from "../../../redux/model";
import GlobalActions from "../../../services/GlobalActions";
import MQ from "../../../utils/MatchQueryUtils";
import StringUtils from "../../../utils/StringUtils";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import "./ContactDetailsInvoiceSection.scss";
import ContactDetailsSection from "./ContactDetailsSection";

interface ContactDetailsInvoiceSectionProps {
  contact: Contact;
  direction: InvoiceDirection;
}
const ContactDetailsInvoiceSection = (
  props: ContactDetailsInvoiceSectionProps
) => {
  return (
    <StructLoader
      structTypes={["invoice", "unit", "orga"]}
      unitType={props.contact.data.type}
      render={() => <ContactDetailsInvoiceSectionContent {...props} />}
    />
  );
};

export default ContactDetailsInvoiceSection;

const STATIC_SELECTORS: () => AggregationStatisticQuerySelector[] = () => [
  {
    name: "general",
    op: [
      { key: "count", op: "count" },
      {
        key: "amountToPay",
        op: "sum",
        field: "data.invoice.value.converted.amount",
      },
      {
        key: "outstandingPayment",
        op: "sum",
        field: "data.payment.outstandingPayment",
      },
      {
        key: "payedAmount",
        op: "sum",
        field: "data.payment.payedAmount",
      },
      {
        key: "outstandingToPayed",
        op: "sum",
        field: "data.payment.outstandingToPayed",
      },
    ],
    query: {},
  },
  {
    name: "progress",
    op: [
      { key: "count", op: "count" },
      {
        key: "amountToPay",
        op: "sum",
        field: "data.invoice.value.converted.amount",
      },
    ],
    query: {
      matchQuery: matchQueryOfFilterType("progress"),
    },
  },
  {
    name: "declined",
    op: [
      { key: "count", op: "count" },
      {
        key: "amountToPay",
        op: "sum",
        field: "data.invoice.value.converted.amount",
      },
    ],
    query: {
      matchQuery: matchQueryOfFilterType("declined"),
    },
  },
  {
    name: "payed",
    op: [
      { key: "count", op: "count" },
      {
        key: "amountToPay",
        op: "sum",
        field: "data.invoice.value.converted.amount",
      },
    ],
    query: {
      matchQuery: matchQueryOfFilterType("payed"),
    },
  },
  {
    name: "isChecked",
    op: [{ key: "count", op: "count" }],
    query: {
      matchQuery: {
        type: "op",
        op: "eq",
        name: "data.payment.isChecked",
        value: true,
      },
    },
  },
  {
    name: "booked",
    op: [{ key: "count", op: "count" }],
    query: {
      matchQuery: {
        type: "op",
        op: "eq",
        name: "data.booked",
        value: true,
      },
    },
  },
];

type InvoiceFilterType = "progress" | "declined" | "payed";
const matchQueryOfFilterType = (filterType: InvoiceFilterType) => {
  switch (filterType) {
    case "progress":
      return MQ.and(
        MQ.eq("data.payed", false),
        MQ.ne("data.status", "declined")
      );
    case "declined":
      return MQ.and(MQ.eq("data.status", "declined"));
    case "payed":
      return MQ.and(MQ.eq("data.payed", true));
  }
};
const ContactDetailsInvoiceSectionContent = (
  props: ContactDetailsInvoiceSectionProps
) => {
  const [columns] = useState(
    _.merge({}, getIncomingInvoiceColumns(props.direction), {
      "data.type": {
        hidden: true,
      },
      "expand.contact.data.displayName": null,
    })
  );
  const [invoiceFilters, setInvoiceFilters] = useState<InvoiceFilterType[]>([
    "progress",
    "payed",
  ]);
  const config = InvoiceStruct.getInvoiceConfigByUnit(props.contact.data.type);
  const types = InvoiceStruct.getInvoiceTypesByUnit(props.contact.data.type);

  const toggleInvoiceFilter = (type: InvoiceFilterType) => {
    if (invoiceFilters.includes(type)) {
      setInvoiceFilters(invoiceFilters.filter((f) => f !== type));
    } else {
      setInvoiceFilters([...invoiceFilters, type]);
    }
  };
  return (
    <ContactDetailsSection
      title={
        props.direction === "INCOMING"
          ? i18n.t(
              "Contact.View.Invoice.IncomingTitle",
              "Eingehende Rechnungen"
            )
          : i18n.t(
              "Contact.View.Invoice.OutgoingTitle",
              "Ausgehende Rechnungen"
            )
      }
    >
      <QueryLoader
        assetType={AssetTypes.Invoice}
        matchQuery={MQ.and(
          MQ.eq("data.direction", props.direction),
          MQ.eq("data.invoice.contact", props.contact._id)
        )}
        selectors={STATIC_SELECTORS()}
        render={(result: AggregationStatisticQueryResult) => (
          <div className={classNames(`contact-details-invoice-section`)}>
            <div className={`kpis`}>
              <BFButton
                className={classNames(`kpi-entry`, {
                  active: invoiceFilters.includes("progress"),
                })}
                appearance={
                  invoiceFilters.includes("progress") ? "primary" : "default"
                }
                onClick={() => toggleInvoiceFilter("progress")}
              >
                <div className={`label`}>
                  {i18n.t("Contact.View.Invoice.progress", "Laufend")}
                </div>
                <div className={`count`}>
                  {i18n.t(
                    "Contant.View.Invoice.count",
                    "{{count}} Rechnungen",
                    {
                      count: result?.progress?.count || 0,
                    }
                  )}
                </div>
                <div className={`value`}>
                  {StringUtils.formatCurrency(
                    result?.progress?.amountToPay || 0
                  )}
                </div>
              </BFButton>
              <BFButton
                className={classNames(`kpi-entry`, {
                  active: invoiceFilters.includes("payed"),
                })}
                appearance={
                  invoiceFilters.includes("payed") ? "primary" : "default"
                }
                onClick={() => toggleInvoiceFilter("payed")}
              >
                <div className={`label`}>
                  {i18n.t("Contact.View.Invoice.payed", "Bezahlt")}
                </div>
                <div className={`count`}>
                  {i18n.t(
                    "Contant.View.Invoice.count",
                    "{{count}} Rechnungen",
                    {
                      count: result?.payed?.count || 0,
                    }
                  )}
                </div>
                <div className={`value`}>
                  {StringUtils.formatCurrency(result?.payed?.amountToPay || 0)}
                </div>
              </BFButton>
              <BFButton
                className={classNames(`kpi-entry`, {
                  active: invoiceFilters.includes("declined"),
                })}
                appearance={
                  invoiceFilters.includes("declined") ? "primary" : "default"
                }
                onClick={() => toggleInvoiceFilter("declined")}
              >
                <div className={`label`}>
                  {i18n.t("Contact.View.Invoice.declined", "Abgelehnt")}
                </div>
                <div className={`count`}>
                  {i18n.t(
                    "Contant.View.Invoice.count",
                    "{{count}} Rechnungen",
                    {
                      count: result?.declined?.count || 0,
                    }
                  )}
                </div>
                <div className={`value`}>
                  {StringUtils.formatCurrency(
                    result?.declined?.amountToPay || 0
                  )}
                </div>
              </BFButton>
            </div>

            <div className={`list`}>
              <VirtualizedTable
                assetType={AssetTypes.Invoice}
                additionalMatchQuery={MQ.and(
                  MQ.eq("data.invoice.contact", props.contact._id),
                  MQ.or(
                    ...invoiceFilters.map((filter) =>
                      matchQueryOfFilterType(filter)
                    )
                  )
                )}
                params={{ aggregated: result }}
                cleanupOnUnmount
                selection="single"
                onRowDoubleClick={(node: RAInvoice) => {
                  GlobalActions.openDetails(
                    AssetTypes.Invoice,
                    node._id,
                    node.data.type
                  );
                }}
                identifier="contact-details-invoice-list"
                columns={columns}
              />
            </div>
          </div>
        )}
      />
    </ContactDetailsSection>
  );
};
