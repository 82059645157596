// import "./UserAvatarForm.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import AvatarComponent from "../../../../components/AvatarComponent/AvatarComponent";
import i18n from "../../../../i18n";
import { setUserData } from "../../../../redux/actions/global/global-actions";
import { useTypedSelector } from "../../../../redux/hooks";
import UserService from "../../../../services/UserService";
import BFDropzone from "../../../abstract-ui/dropzone/BFDropzone";
import BFDropdown from "../../../abstract-ui/general/Dropdown/BFDropdown";
import UserAvatarEditor from "./UserAvatarEditor";

interface UserAvatarFormProps {}
const UserAvatarForm = (props: UserAvatarFormProps) => {
  const dispatch = useDispatch();
  const [uploadActive, setUploadActive] = useState<File>(null);

  const user = useTypedSelector((state) => state.global.user);
  return (
    <BFDropzone
      accept={{
        "image/*": [".jpg", ".jpeg", ".png"],
      }}
      multipe={false}
      onDrop={(accepted, rejected) => {
        setUploadActive(accepted[0]);
      }}
      render={(open) => (
        <div className={`user-avatar-form`}>
          {uploadActive && (
            <UserAvatarEditor
              size={150}
              file={uploadActive}
              onSave={async (img: string) => {
                const user = await UserService.updateUserAvatar(img);
                dispatch(setUserData(user));
                setUploadActive(null);
                //fix to prevent invalid permission after user update
                window.location.reload();
              }}
              onAbort={() => {
                setUploadActive(null);
              }}
            />
          )}
          {!uploadActive && (
            <>
              <AvatarComponent
                size={150}
                avatar={user.avatar}
                displayName={user.displayname}
              />
              <BFDropdown
                label={i18n.t(
                  "UserProfile.Profile.ChangeAvatar",
                  "Avatar ändern"
                )}
                appearance="link"
                items={[
                  {
                    type: "button",
                    // disabled: true,
                    text: i18n.t(
                      "UserProfile.Profile.UploadAvatar",
                      "Avatar hochladen"
                    ),
                    onSelect: open,
                  },
                  {
                    type: "button",
                    disabled: !user.avatar,
                    text: i18n.t(
                      "UserProfile.Profile.DeleteAvatar",
                      "Avatar löschen"
                    ),
                    onSelect: async () => {
                      const user = await UserService.deleteUserAvatar();
                      dispatch(setUserData(user));
                    },
                  },
                ]}
              />
            </>
          )}
        </div>
      )}
    />
  );
};

export default UserAvatarForm;
