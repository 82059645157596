import classNames from "classnames";
import ModalManager from "../../components/ModalComponent/ModalManager";
import i18n from "../../i18n";
import "./CommentsModal.scss";
import CommentsModule, { CommentsModuleProps } from "./CommentsModule";

export const openCommentsModal = (props: CommentsModuleProps) => {
  ModalManager.show({
    closable: true,
    headerCloseButton: true,
    modalClassName: "comments-modal-wrapper",
    backdrop: true,
    size: "lg",
    title: i18n.t("CommentsModal.title", "Kommentare"),
    content: (state, setState, close) => <CommentsModal {...props} />,
  });
};
const CommentsModal = (props: CommentsModuleProps) => {
  return (
    <div className={classNames(`comments-modal`)}>
      <CommentsModule {...props} mode="list" />
    </div>
  );
};

export default CommentsModal;
