import classNames from "classnames";
import { APActivity, APProjectActivity } from "../../../ActivityInterfaces";
import "./APActiveProjectBudgetCharts.scss";
import { APProjectBudget } from "./APProjectBudgetInterfaces";
import APProjectBudgetComparisonGraph from "./charts/APProjectBudgetComparisonGraph";
import APProjectBudgetProgress from "./charts/APProjectBudgetProgress";

interface APActiveBudgetChartsProps {
  activity: APActivity;
  budget?: APProjectBudget;
}
const APActiveProjectBudgetCharts = (props: APActiveBudgetChartsProps) => {
  return (
    <div className={classNames(`ap-active-project-budget-charts`)}>
      <div className={`__card progress-card`}>
        <APProjectBudgetProgress
          identifier="ap-active-budget-groups-planned"
          activity={props.activity as APProjectActivity}
          budget={props.budget}
        />
      </div>
      <div className={`__card comparison-card`}>
        <APProjectBudgetComparisonGraph
          activity={props.activity}
          budget={props.budget}
        />
      </div>
    </div>
  );
};

export default APActiveProjectBudgetCharts;
