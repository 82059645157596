import ModalManager from "../../components/ModalComponent/ModalManager";
import i18n from "../../i18n";
import DataBusDefaults from "../../services/DataBusDefaults";
import LoadTemplateModal from "./LoadTemplateModal";
import SaveTemplateModal from "./SaveTemplateModal";
import { TemplateAsset } from "./TemplateInterfaces";

export const loadTemplate = (type: string, templateType: string) => {
  return new Promise<TemplateAsset>((resolve, reject) => {
    ModalManager.show({
      size: "xs",

      onHide: () => {
        resolve(null);
      },
      noPadding: true,
      content: (state, setState, onClose) => (
        <LoadTemplateModal
          onClose={onClose}
          type={type}
          templateType={templateType}
          onSelect={(template) => {
            resolve(template);
            onClose();
          }}
        />
      ),
    });
  });
};

export const saveTemplate = (
  type: string,
  templateType: string,
  data: any,
  initialName?: string,
  onSuccess?: () => void
) => {
  if (data) {
    ModalManager.show({
      noPadding: true,
      size: "xs",
      content: (state, setState, onClose) => (
        <SaveTemplateModal
          onClose={onClose}
          type={type}
          templateType={templateType}
          data={data}
          initialName={initialName}
          onSuccess={onSuccess}
        />
      ),
    });
  } else {
    DataBusDefaults.toast({
      type: "warning",
      text: i18n.t("Template.InvalidDataToSave", "Invalider Datensatz"),
    });
  }
};
