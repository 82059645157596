import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { FilterOptionWithMatchQueryConverter } from "../../../../../modules/abstract-ui/data/table-filter/BFTableFilterModel";
import { BooleanFilterOption } from "../../../../../modules/abstract-ui/data/table-filter/defaults/BooleanFilterOption";
import { ContactFilterOption } from "../../../../../modules/abstract-ui/data/table-filter/defaults/ContactFilterOption";
import { DateFilterOption } from "../../../../../modules/abstract-ui/data/table-filter/defaults/DateFilterOption";
import { DefaultFilterOptions } from "../../../../../modules/abstract-ui/data/table-filter/defaults/DefaultFilterOptions";
import { IDFilterOption } from "../../../../../modules/abstract-ui/data/table-filter/defaults/IDFilterOption";
import { ListFilterOption } from "../../../../../modules/abstract-ui/data/table-filter/defaults/ListFilterOption";
import { NumberFilterOption } from "../../../../../modules/abstract-ui/data/table-filter/defaults/NumberFilterOption";
import LanguageService from "../../../../../services/LanguageService";
import ACInvoiceUtils from "../../../../utils/ACInvoiceUtils";
import ACProjectUtils from "../../../../utils/ACProjectUtils";
import { InvoiceDirection } from "../../RAInterfaces";
import {
  RA_ALL_STATUS,
  RA_INVOICE_MAIN_FIELDS,
  getInvoiceStatusLabel,
} from "../../RAUtils";

export const RAInvoicesListFilterOptions: (
  direction: InvoiceDirection,
  filterTypes?: string[]
) => FilterOptionWithMatchQueryConverter[] = (direction, filterTypes) => {
  const paymentOptions = ACInvoiceUtils.getPaymentTypes(filterTypes).map(
    (e) => ({
      value: e.id,
      label: LanguageService.translateLabel(e.displayName),
      subLabel:
        filterTypes.length > 1 ? UnitStruct.getUnitLabel(e.type) : undefined,
    })
  );
  return [
    ...DefaultFilterOptions({
      filterTypes,
    }),
    IDFilterOption({
      field: "data.invoice.invoiceId",
      label: RA_INVOICE_MAIN_FIELDS().invoiceId.label,
      icon: {
        type: "light",
        data: "keyboard-asterisk-2",
      },
      type: "string",
    }),

    ContactFilterOption({
      field: "data.invoice.contact",
      label: i18n.t("ra:Invoice.contact", "Zahlungsempfänger"),
      filterTypes: filterTypes,
    }),

    ListFilterOption({
      field: "data.activity.assetId",
      label: i18n.t("ra:Invoice.ProjectAssignment", "Projekt"),
      icon: {
        type: "light",
        data: "project-blueprint-idea",
      },
      allowNegation: true,
      data: ACProjectUtils.getAllProjects(filterTypes).map((e) => ({
        label: LanguageService.translateLabel(e.data.displayName),
        value: e._id,
        subLabel:
          filterTypes.length > 1
            ? UnitStruct.getUnitLabel(e.data.type)
            : undefined,
      })),
    }),

    IDFilterOption({
      field: "id",
      label: i18n.t("Global.Labels.ID", "ID"),
    }),

    DateFilterOption({
      field: "data.invoice.documentDate",
      label: RA_INVOICE_MAIN_FIELDS().documentDate.label,
    }),

    DateFilterOption({
      field: "data.paymentDate",
      label: i18n.t("ra:List.payedDate", "Bezahlt am"),
    }),
    NumberFilterOption({
      field: "data.invoice.value.converted.amount",
      label: RA_INVOICE_MAIN_FIELDS().amountToPay.label,
      type: "currency",
    }),

    ListFilterOption({
      field: "data.status",
      label: i18n.t("Global.Labels.Status"),
      icon: {
        type: "light",
        data: "receipt-dollar",
      },
      allowNegation: true,
      data: RA_ALL_STATUS.map((status) => ({
        label: getInvoiceStatusLabel(status),
        value: status,
      })),
    }),

    ListFilterOption({
      field: "data.invoice.invoiceType",
      label: RA_INVOICE_MAIN_FIELDS().invoiceType.label,
      icon: {
        type: "light",
        data: "receipt-dollar",
      },
      allowNegation: true,
      data: ACInvoiceUtils.getInvoiceTypes(direction, filterTypes).map((e) => ({
        label: LanguageService.translateLabel(e.data.displayName),
        value: e._id,
        subLabel: UnitStruct.getUnitLabel(e.data.type),
      })),
    }),

    ListFilterOption({
      field: "data.invoice.paymentType",
      label: RA_INVOICE_MAIN_FIELDS().paymentType.label,
      icon: {
        type: "light",
        data: "receipt-dollar",
      },
      allowNegation: true,
      data: paymentOptions,
    }),
    BooleanFilterOption({
      field: "data.booked",
      label: i18n.t("ra:Invoice.isBooked", "Ist gebucht"),
      defaultValue: true,
    }),

    BooleanFilterOption({
      field: "data.payment.isChecked",
      label: i18n.t("ra:Invoice.isChecked", "Ist freigegeben"),
      defaultValue: true,
    }),

    BooleanFilterOption({
      field: "data.payed",
      label: i18n.t("ra:Invoice.isPayed", "Ist bezahlt"),
      defaultValue: true,
    }),

    BooleanFilterOption({
      field: "data.needsToBePayed",
      label: i18n.t("ra:Invoice.needsToBePayed", "Muss bezahlt werden"),
      defaultValue: true,
    }),

    BooleanFilterOption({
      field: "data.needsToBeBooked",
      label: i18n.t("ra:Invoice.needsToBeBooked", "Muss gebucht werden"),
      defaultValue: true,
    }),

    BooleanFilterOption({
      field: "data.needsToBeChecked",
      label: i18n.t("ra:Invoice.needsToBeChecked", "Muss freigegeben werden"),
      defaultValue: true,
    }),

    ListFilterOption({
      field: "data.tags",
      label: i18n.t("Global.Labels.Tags", "Tags"),
      allowNegation: true,
      data: UnitStruct.getTagConfigForUnits(
        filterTypes,
        AssetTypes.Invoice
      ).map((e) => ({
        label: LanguageService.translateLabel(e.displayName),
        value: e.id,
        subLabel:
          filterTypes.length > 1 ? UnitStruct.getUnitLabel(e.unit) : undefined,
      })),
    }),
  ];
};
