import { useState } from "react";
import { Prompt } from "react-router";
import { Divider } from "rsuite";
import Log from "../../../debug/Log";
import BFCheckGroup from "../../../modules/abstract-ui/forms/checkbox-group/BFCheckGroup";
import BFCheckbox from "../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BfDate from "../../../modules/abstract-ui/forms/date/BFDate";
import BFInput from "../../../modules/abstract-ui/forms/input/BFInput";
import BFRadioGroup from "../../../modules/abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "../../../modules/abstract-ui/forms/radio/BfRadio";
import BfSelect from "../../../modules/abstract-ui/forms/select/BFSelect";
import BFSlider from "../../../modules/abstract-ui/forms/slider/BFSlider";
import BFToggle from "../../../modules/abstract-ui/forms/toggle/BFToggle";
import BfButton from "../../../modules/abstract-ui/general/Button/BFButton";

export const ComponentsOverviewPage = () => {
  return <div>ComponentsOverview</div>;
};

const SELECT_VALUES = [
  {
    label: "Wert 1",
    value: "val_1",
  },
  {
    label: "Wert 2",
    value: "val_2",
  },
  {
    label: "Wert 3",
    value: "val_3",
  },
  {
    label: "Wert 4",
    value: "val_4",
  },
  {
    label: "Wert 5",
    value: "val_6",
  },
  {
    label: "Wert 7",
    value: "val_7",
  },
  {
    label: "Wert 8",
    value: "val_8",
  },
  {
    label: "Wert 9",
    value: "val_9",
  },
  {
    label: "Wert 10",
    value: "val_10",
  },
];

export const ComponentsFormsPage = () => {
  const [checkboxGroup, setCheckboxGroup] = useState(["felix", "franzine"]);

  const [radioGroup, setRadioGroup] = useState();

  return (
    <div>
      <Prompt
        when={radioGroup === "frank"}
        message={(location) =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />

      <BfDate />
      <BfDate />
      <BfDate />
      <Divider />
      <div>
        TestSelect
        <BfSelect data={SELECT_VALUES} />
      </div>
      <div>
        TestSelect
        <BfSelect type={"multiple"} data={SELECT_VALUES} />
      </div>
      <div>
        TestSelect
        <BfSelect type={"search"} data={SELECT_VALUES} />
      </div>
      <div>
        TestSelect
        <BfSelect type={"tags"} data={SELECT_VALUES} />
      </div>
      <div>
        TestSelect
        <BfSelect data={SELECT_VALUES} />
      </div>
      <Divider />
      <div>
        Normal Input
        <BFInput type={"text"} />
      </div>
      <div>
        Placeholder Input
        <BFInput type={"text"} placeholder={"Im a glorious placeholder"} />
      </div>
      <div>
        Default Value Input
        <BFInput type={"text"} defaultValue={"Im a glorious default value"} />
      </div>
      <div>
        Suffix Icon
        <BFInput
          type={"text"}
          // suffix={<Icon icon={"android"} />}
        />
      </div>
      <div>
        Suffix Button
        <BFInput
          type={"text"}
          suffixOnClick={() => {
            Log.debug("suffix clicked");
          }}
          // suffix={<Icon icon={"search"} />}
        />
      </div>
      <div>
        Prefix Icon
        <BFInput
          type={"text"}
          // prefix={<Icon icon={"android"} />}
        />
      </div>
      <div>
        Prefix Button
        <BFInput
          type={"text"}
          prefixOnClick={() => {
            Log.debug("Prefix clicked");
          }}
          // prefix={<Icon icon={"search"} />}
        />
      </div>
      <div>
        Sizes
        <BFInput type={"text"} size={"lg"} />
        <br />
        <BFInput type={"text"} size={"md"} />
        <br />
        <BFInput type={"text"} size={"sm"} />
        <br />
        <BFInput type={"text"} size={"xs"} />
        <br />
      </div>
      <Divider />

      <div>
        TestSelect
        <BfSelect data={SELECT_VALUES} />
      </div>
      <Divider />
      <div>
        <BFCheckbox>Hallo ich bin Checkbox</BFCheckbox>
        <BFCheckbox indeterminate={true}>Hallo ich bin Checkbox</BFCheckbox>
      </div>
      <div>
        Wen magst du?
        <BFCheckGroup
          value={checkboxGroup}
          name="meh"
          onChange={(value) => {
            Log.debug(value);
            setCheckboxGroup(value);
          }}
          inline={true}
        >
          <BFCheckbox value={"frank"}>Frank</BFCheckbox>
          <BFCheckbox value={"felix"}>Felix</BFCheckbox>
          <BFCheckbox value={"franzine"}>Franzine</BFCheckbox>
          <BFCheckbox disabled value={"fabi"}>
            Fabian
          </BFCheckbox>
        </BFCheckGroup>
        <code>{JSON.stringify(checkboxGroup)}</code>
      </div>

      <Divider />

      <BfRadio>Ich bin Radioelement</BfRadio>

      <div>
        Wer ist der beste?
        <BFRadioGroup
          value={radioGroup}
          name="meh"
          onChange={(value) => {
            Log.debug(value);
            setRadioGroup(value);
          }}
          inline={true}
        >
          <BfRadio value={"frank"}>Frank</BfRadio>
          <BfRadio value={"felix"}>Felix</BfRadio>
          <BfRadio value={"franzine"}>Franzine</BfRadio>
          <BfRadio disabled value={"fabi"}>
            Fabian
          </BfRadio>
        </BFRadioGroup>
        <code>{JSON.stringify(radioGroup)}</code>
      </div>

      <div>
        <BFToggle />
        <BFToggle size={"lg"} />
        <BFToggle size={"md"} />
        <BFToggle defaultChecked={true} />
        <BFToggle
          checkedChildren={<div>Du Opfer</div>}
          unCheckedChildren={<div>Fabi ist opfer</div>}
        />
      </div>

      <BfDate />
      <div style={{ padding: "20px" }}>
        <BFSlider />
      </div>
      <div>
        TestSelect
        <BfSelect type={"multiple"} data={SELECT_VALUES} />
      </div>
    </div>
  );
};

export const ComponentsButtonsPage = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <BfButton>Dies ist ein Button</BfButton>
      <br />
      <BfButton appearance={"clear"}>Dies ist ein Button</BfButton>
      <br />
      <BfButton appearance={"link"}>Dies ist ein Button</BfButton>
      <br />
      <BfButton appearance={"outline"}>Dies ist ein Button</BfButton>
      <br />
      <BfButton appearance={"primary"}>Dies ist ein Button</BfButton>
      <br />
      <BfButton size={"lg"} appearance={"primary"}>
        Dies ist ein Button
      </BfButton>
      <br />
    </div>
  );
};
