import ModalManager from "../../../components/ModalComponent/ModalManager";
import i18n from "../../../i18n";
import { Resubmission } from "../RSInterfaces";
import RSPreviewModal from "../components/preview/components/modal/RSPreviewModal";

const useOpenResubmissionPreview = () => {
  const openResubmissionPreview = (resubmission: Resubmission) => {
    ModalManager.show({
      backdrop: "static",
      size: "sm",
      title: i18n.t("Resubmission.Title.Preview", "Vorschau"),
      content: (states, setStates, close) => {
        return <RSPreviewModal close={close} resubmission={resubmission} />;
      },
    });
  };

  return { openResubmissionPreview };
};

export default useOpenResubmissionPreview;
