import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { Whisper } from "rsuite";
import { useDatabus } from "../../redux/hooks";
import DataBus from "../../services/DataBus";
import "./BFContextContainer.scss";

export type ContextContainerPosition = {
  x: number;
  y: number;
};

interface BFContextContainerProps {
  children: React.ReactNode;
  position: ContextContainerPosition;
  onClose: () => void;
}

const DATABUS_KEY_CONTEXT_CONTAINER = "CONTEXT_CONTAINER_OPENED";

const BFContextContainer = (props: BFContextContainerProps) => {
  const [show, setShow] = useState(false);
  const [id] = useState(`CONTEXT_CONTAINER_${nanoid()}`);
  useDatabus(
    DATABUS_KEY_CONTEXT_CONTAINER,
    (data) => {
      if (data.id !== id && props.position) {
        document.getElementById(id).click();
      }
    },
    [id, props.position]
  );
  useEffect(() => {
    if (!show) {
      props.onClose();
    }
  }, [show]);
  useEffect(() => {
    if (props.position) {
      DataBus.emit(DATABUS_KEY_CONTEXT_CONTAINER, { id });
      setShow(true);
      setTimeout(() => {
        document.getElementById(id).click();
      }, 10);
    }
  }, [props.position]);
  if (!props.position) {
    return null;
  }
  return (
    <div
      className="bf-context-container"
      style={{ top: props.position.y, left: props.position.x }}
    >
      <Whisper
        onExiting={() => props.onClose()}
        // open={show}
        onBlur={() => {
          setShow(false);
        }}
        trigger={"click"}
        // placement={this.props.placement}
        // container={this.props.container}
        speaker={
          <div className={`bf-context-container-overlay`}>{props.children}</div>
        }
      >
        <button id={id} className={"faker"}></button>
      </Whisper>
    </div>
  );
};

export const useContextContainer = () => {
  const [position, setPosition] = useState<ContextContainerPosition>(null);

  return {
    position,
    onClose: () => setPosition(null),
    onContextClick: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.preventDefault();
      ev.stopPropagation();

      setPosition({
        x: ev.nativeEvent.clientX,
        y: ev.nativeEvent.clientY,
      });
    },
  };
};

export default BFContextContainer;
