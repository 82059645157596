import { SET_MANDATOR_DATA } from "../redux/actions/global/global-actions-types";
import { store } from "../redux/store";
import { HTTP } from "../utils/Http";

class MandatorServiceClass {
  init() {}

  loadMandatorDataPrivate(
    includePublicData: boolean,
    onSuccess: (mandator) => void,
    onError: (error) => void
  ) {
    HTTP.get({
      url: "/mandator/privateInfo",
      withCredentials: true,
      queryParams: {
        param: {
          includePublicData
        }
      }
    })
      .then(response => {
        store.dispatch({
          type: SET_MANDATOR_DATA,
          mandator: response
        });

        onSuccess(response);
      })
      .catch(err => {
        onError(err);
      });
  }
}

const MandatorService = new MandatorServiceClass();

export default MandatorService;
