import { getDepositPositionType } from "@/apps/tatar/cashBudget/views/tenants/CBTenantsConst";
import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import BFCheckbox from "@/modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import LanguageService from "@/services/LanguageService";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { Field } from "react-final-form";
import {
  ImmoAccountTemplate,
  ObjectKind,
  ObjectKindFeature_IMMO,
} from "../../../types/objectKind.interface";
import "./ObjectFormTemplateSelection.scss";

interface ObjectFormTemplateSelectionProps {
  kind: ObjectKind;
}
const ObjectFormTemplateSelection = (
  props: ObjectFormTemplateSelectionProps
) => {
  const renderTemplateInfo = (template: ImmoAccountTemplate) => {
    return (
      <>
        <div className={`debit-positions`}>
          <table>
            <tr>
              <th>
                {i18n.t("obj:ObjectForm.Immo.DebitPosition", "Sollposition")}
              </th>
              <th>{i18n.t("obj:ObjectForm.Immo.Kind", "Art")}</th>
              <th>{i18n.t("obj:ObjectForm.Immo.TaxRate", "Steuersatz")}</th>
              <th>{i18n.t("obj:ObjectForm.Immo.Priority", "Priorität")}</th>
              <th className={`center`}>
                {i18n.t(
                  "obj:ObjectForm.Immo.AutomaticDebitPosition",
                  "Automatische Sollstellung"
                )}
              </th>
              <th className={`center`}>
                {i18n.t(
                  "obj:ObjectForm.Immo.RelevantForAgreement",
                  "Relevant für Mietvertrag"
                )}
              </th>
              <th className={`center`}>
                {i18n.t(
                  "obj:ObjectForm.Immo.UpdateLastRentChangeDate",
                  "Änderung beeinflusst Mietvertragsdaten"
                )}
              </th>
            </tr>
            {template.debitposition.map((pos) => (
              <tr>
                <td>{LanguageService.translateLabel(pos.displayName)}</td>
                <td>{getDepositPositionType(pos.kind)}</td>
                <td>{StringUtils.formatPercent(pos.taxRate)}</td>
                <td>{pos.priority}</td>
                <td className={`center`}>
                  <BFCheckbox checked={pos.automaticDebitPosition} />
                </td>
                <td className={`center`}>
                  <BFCheckbox checked={pos.relevantForAgreement} />
                </td>
                <td className={`center`}>
                  <BFCheckbox checked={pos.relevantForAgreement} />
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className={`deposit-positions`}>
          <table>
            <tr>
              <th>
                {i18n.t(
                  "obj:ObjectForm.Immo.DepositPosition",
                  "Kautionsposition"
                )}
              </th>
              <th className={`center`}>
                {i18n.t(
                  "obj:ObjectForm.Immo.AutomaticDebitPosition",
                  "Automatische Sollstellung"
                )}
              </th>
            </tr>
            {template.deposit.map((pos) => (
              <tr>
                <td>{LanguageService.translateLabel(pos.displayName)}</td>
                <td className={`center`}>
                  <BFCheckbox checked={pos.automaticDebitPosition} />
                </td>
              </tr>
            ))}
          </table>
        </div>
      </>
    );
  };
  return (
    <Field
      name="feature.immo.accountingTemplate"
      validate={FV.compose(FV.required())}
      render={({ input, meta }) => (
        <div className={classNames(`object-form-template-selection`)}>
          <div className={`description`}>
            {i18n.t(
              "obj:ObjectForm.Templates.Description",
              "Wählen Sie eine Vorlage für die Sollstellungspositionen und Kautionsarten aus. Die Positionen definieren, welche Sollstellungspositionen in einem Mietvertrag angegeben werden können."
            )}
          </div>

          <BFSelect
            {...input}
            label={i18n.t(
              "obj:ObjectForm.Templates.Label",
              "Vorlage für die Sollstellungspositionen"
            )}
            data={ObjectKindStruct.getKindFeature<ObjectKindFeature_IMMO>(
              props.kind._id,
              "immo"
            )?.accountingTemplate.map((e) => ({
              value: e.id,
              label: LanguageService.translateLabel(e.displayName),
            }))}
            {...FV.getValidation(meta)}
          />

          {input.value &&
            renderTemplateInfo(
              ObjectKindStruct.getKindFeature<ObjectKindFeature_IMMO>(
                props.kind._id,
                "immo"
              )?.accountingTemplate.find((e) => e.id === input.value)
            )}
        </div>
      )}
    />
  );
};

export default ObjectFormTemplateSelection;
