import classNames from "classnames";
import { useState } from "react";
import PlanTable, {
  PlainTableGroup,
  PlanTableEntry,
  PlanTableValue,
} from "../../../../modules/plan-table/PlanTable";
import "./KSProjectDemo.scss";

const generateMockEntries = (
  subgroups: PlainTableGroup[]
): PlanTableEntry[] => {
  const entries: PlanTableEntry[] = [];

  const month = 1;
  const year = 2024;

  for (let i = 1; i <= 12; i++) {
    if (i % 3 === 0) continue;
    const entry: PlanTableEntry = {
      date: new Date(`${year}-${String(i).padStart(2, "0")}-01`),
      values: {},
    };
    subgroups.forEach((group) => {
      group.children?.forEach((subgroup) => {
        if (Math.random() < 0.95) {
          entry.values[subgroup.id] =
            Math.random() < 0.55 ? 0 : Math.floor(Math.random() * 500);
        }
      });
    });
    entries.push(entry);
  }

  return entries;
};
const generateMockDataGroups = (): PlainTableGroup[] => {
  const mockData: PlainTableGroup[] = [];

  for (let i = 1; i <= 6; i++) {
    const group: PlainTableGroup = {
      id: `group${i}_id`,
      name: `Gruppe ${i}`,
      children: [],
    };

    const numberOfSubgroups = Math.floor(Math.random() * (8 - 4 + 1)) + 4;

    for (let j = 1; j <= numberOfSubgroups; j++) {
      const subgroup: PlainTableGroup = {
        id: `subgroup${i}_${j}_id`,
        name: `Gewerk ${i}.${j}`,
      };

      group.children.push(subgroup);
    }

    mockData.push(group);
  }

  return mockData;
};

// Beispielaufruf
const mockDataGroups = generateMockDataGroups();
const mockDataEntries = generateMockEntries(mockDataGroups);
const mockActualValue = generateMockEntries(mockDataGroups);

const mockInitialData: PlanTableValue = {
  entries: mockDataEntries,
  groups: mockDataGroups,
  from: new Date("2024-01-01"),
  to: new Date("2024-12-31"),
};
interface KSProjectDemoProps {}
const KSProjectDemo = (props: KSProjectDemoProps) => {
  const [data, setData] = useState<PlanTableValue>({
    entries: [],
    groups: [],
    from: new Date("2024-01-01"),
    to: new Date("2024-12-31"),
  });
  return (
    <div className={classNames(`kd-project-demo`)}>
      <div className={`wrapper-demo`}>
        <PlanTable
          planType="timespanAndGroups"
          startBudget={52000}
          value={data}
          onChange={(value) => {
            setData(value);
          }}
          // actualValue={mockActualValue}
        />
      </div>
    </div>
  );
};

export default KSProjectDemo;
