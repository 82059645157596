import React, { CSSProperties } from "react";
import GenericLayoutComponent from "../GenericLayoutComponent";
import { GenericFormsLayoutProps, GFBaseElement } from "../GFBaseElement";
import "./BootstrapLayout.scss";

type GFColProps = {
	className?: string;
	items: { [key: string]: any };
	condition?: string;
	style: CSSProperties;
	stateSubscriptions: string[];
} & GenericFormsLayoutProps;
export const GFCol: React.FC<GFColProps> = props => {
	const { className, components, condition, style, stateSubscriptions, params } = props;
	return (
		<GenericLayoutComponent
			stateSubscriptions={stateSubscriptions}
			style={style}
			condition={condition}
			render={styleProps => (
				<div style={styleProps} className={`col ${className ? className : ""}`}>
					{Object.values(components).map((item, index) => (
						<GFBaseElement key={index} {...item} params={params} />
					))}
				</div>
			)}
		/>
	);
};

type GFRowProps = {
	items: { [key: string]: any };
	condition?: string;
	style: CSSProperties;
	stateSubscriptions: string[];
} & GenericFormsLayoutProps;
export const GFRow: React.FC<GFRowProps> = props => {
	const { components, condition, style, stateSubscriptions, params } = props;
	return (
		<GenericLayoutComponent
			stateSubscriptions={stateSubscriptions}
			style={style}
			condition={condition}
			render={styleProps => (
				<div style={styleProps} className={"row"}>
					{Object.values(components).map((item, index) => (
						<GFBaseElement key={index} {...item} params={params} />
					))}
				</div>
			)}
		/>
	);
};
