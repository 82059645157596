import { FV } from "@/components/Form/Validation/FormValidators";
import BFRadioGroup from "@/modules/abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "@/modules/abstract-ui/forms/radio/BfRadio";
import LanguageService from "@/services/LanguageService";
import { FieldRenderProps } from "react-final-form";
import { Customfield_radio } from "../CustomField.interface";
// import "./CFRadio.scss";

interface CFRadioProps {
  data: Customfield_radio;
  field: FieldRenderProps<any, HTMLElement>;
}
const CFRadio = (props: CFRadioProps) => {
  return (
    <BFRadioGroup
      value={props.data.id}
      inline={props.data.inline}
      {...props.field.input}
      {...FV.getValidation(props.field.meta)}
    >
      {props.data.options.map((option) => (
        <BfRadio value={option.value}>
          {LanguageService.translateLabel(option.label)}
        </BfRadio>
      ))}
    </BFRadioGroup>
    // <BFChooserSelect
    //   label={props.data.displayName}
    //   {...props.field.input}
    //   {...FV.getValidation(props.field.meta)}
    //   data={props.data.options}
    // />
  );
};

export default CFRadio;
