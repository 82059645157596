import { CBPortfolioTechnicalUnit } from "@/apps/tatar/cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import CBTechnicalUnitCard from "@/apps/tatar/cashBudget/views/portfolio/object/components/technicalUnits/CBTechnicalUnitCard";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import { MatchQuery } from "@/services/DataService";

type Props = {
  identifier: string;
  matchQuery: MatchQuery;
};

const TechnicalUnitCardList = (props: Props) => {
  const { identifier, matchQuery } = props;

  return (
    <div className={`technical-unit-list`}>
      <ListComponent
        dataUrl="/api/asset/portfolio-technical-unit"
        hiddenSort={[{ key: "data.displayName", dir: "asc" }]}
        identifier={identifier}
        render={(entry: CBPortfolioTechnicalUnit) => (
          <CBTechnicalUnitCard asset={entry} key={entry._id} />
        )}
        reloadOnMount
        additionalMatchQuery={matchQuery}
      />
    </div>
  );
};

export default TechnicalUnitCardList;
