import classNames from "classnames";
import { Fragment } from "react";
import i18n from "../../i18n";
import { Contact } from "../../model/db/Contact";
import StringUtils from "../../utils/StringUtils";
import "./ContactView.scss";
import DebugDataComponent from "@/debug/DebugDataComponent";

interface ContactViewProps {
  contact: Contact;
  actions?: React.ReactNode;
  title?: string;
}
const ContactView = (props: ContactViewProps) => {
  return (
    <div className={classNames(`contact-view`)}>
      {props.actions && props.actions}
      {props.title && (
        <span className={"contact-view-label"}>{props.title}</span>
      )}
      <DebugDataComponent data={props} />
      <ContactViewSection
        title={
          props.contact.data.personType === "private"
            ? i18n.t("Contact.View.PrivatePerson", "Privatperson")
            : i18n.t("Contact.View.Organization", "Firma")
        }
      >
        <div className={`name`}>{props.contact.data.displayName}</div>
      </ContactViewSection>

      {props.contact.data.emails?.length > 0 && (
        <ContactViewSection title={i18n.t("Contact.View.Emails", "Email")}>
          {props.contact.data.emails.map((email, index) => (
            <div key={index} className={`email`}>
              {email?.email}
            </div>
          ))}
        </ContactViewSection>
      )}
      {props.contact.data.phone?.length > 0 && (
        <ContactViewSection
          title={i18n.t("Contact.View.Phones", "Telefonnummer")}
        >
          {props.contact.data.phone.map((phone, index) => (
            <div key={index} className={`phone`}>
              {phone?.phone}
            </div>
          ))}
        </ContactViewSection>
      )}

      {props.contact.data.address?.length > 0 && (
        <ContactViewSection title={i18n.t("Contact.View.Address", "Adresse")}>
          {props.contact.data.address.map((address, index) => (
            <div key={index} className={`address`}>
              {address.street && (
                <div className={`street`}>{address.street}</div>
              )}
              {(address.zip || address.city) && (
                <div className={`city`}>
                  {address.zip ? `${address.zip} ` : ""}
                  {address.city || ""}
                </div>
              )}
              {address.additional && (
                <div className={`additional`}>{address.additional}</div>
              )}
              {address.country && (
                <div className={`country`}>{address.country}</div>
              )}
            </div>
          ))}
        </ContactViewSection>
      )}
      {props.contact.data.contactPersons?.length > 0 && (
        <ContactViewSection
          title={i18n.t("Contact.View.ContactPersons", "Kontaktperson")}
        >
          {props.contact.data.contactPersons.map((contactPerson, index) => (
            <div key={index} className={`contact-person`}>
              {(contactPerson.firstName || contactPerson.lastName) && (
                <div className={`name`}>
                  {`${
                    contactPerson.firstName ? contactPerson.firstName + " " : ""
                  }${contactPerson.lastName || ""}`}{" "}
                </div>
              )}
              {contactPerson.email && (
                <div className={`email`}>{contactPerson.email}</div>
              )}
              {contactPerson.phone && (
                <div className={`phone`}>{contactPerson.phone}</div>
              )}
              {contactPerson.note && (
                <div className={`note`}>{contactPerson.note}</div>
              )}
            </div>
          ))}
        </ContactViewSection>
      )}
      {props.contact.data.bankAccount?.length > 0 && (
        <ContactViewSection
          title={i18n.t("Contact.View.BankAccount", "Bankverbindung")}
        >
          {props.contact.data.bankAccount.map((bankAccount, index) => (
            <Fragment key={index}>
              {bankAccount.type === "EU" && (
                <div className={`bank-account eu`}>
                  <div className={`bank-name`}>{bankAccount.bankName}</div>
                  <div className={`account-holder`}>
                    {bankAccount.accountHolder}
                  </div>
                  <div className={`iban`}>
                    {StringUtils.formatIban(bankAccount.iban)}
                  </div>
                  <div className={`bic`}>{bankAccount.bic}</div>
                </div>
              )}
              {bankAccount.type === "US" && (
                <div className={`bank-account 8s`}>
                  <div className={`bank-name`}>{bankAccount.bankName}</div>
                  <div className={`account-holder`}>
                    {bankAccount.accountHolder}
                  </div>
                  <div className={`routing-number`}>
                    {bankAccount.routingNumber}
                  </div>
                  <div className={`account-number`}>
                    {bankAccount.accountNumber}
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        </ContactViewSection>
      )}
    </div>
  );
};

export default ContactView;

const ContactViewSection = (props: {
  title: string;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`contact-view-section ${props.className || ""}`}>
      <div className={`section-title`}>{props.title}</div>
      <div className={`section-content`}>{props.children}</div>
    </div>
  );
};
