import { css } from "emotion";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ContextContainerComponent from "../../../components/ContextMenuComponent/ContextContainerComponent";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import { IComponent } from "../../layouts/IComponent";

type Props = {
  component: IComponent;
  contextComponent: IComponent;
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class ConfigurableContextContainerComponent extends AbstractStylableComponent<
  Props,
  States
> {
  static defaultProps = {};
  readonly state: States = {};

  render() {
    return (
      <div
        className={`ConfigurableContextContainerComponent ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
      >
        <ContextContainerComponent
          renderContextMenu={() =>
            (window as any).ComponentsMapper.createElement(
              this.props.contextComponent,
              this.props.params
            )
          }
        >
          {(window as any).ComponentsMapper.createElement(
            this.props.component,
            this.props.params
          )}
        </ContextContainerComponent>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default withTranslation()(
  connect(
    mapStateToProps,
    {}
  )(withRouter(ConfigurableContextContainerComponent))
);
