import { Field, FormRenderProps } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FilePreviewComponent from "../../../../../../components/FilePreviewComponent/FilePreviewComponent";
import FormFieldValues from "../../../../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import BFFormSection from "../../../../../../modules/abstract-ui/data/form-section/BFFormSection";
import BFDropzone from "../../../../../../modules/abstract-ui/dropzone/BFDropzone";
import BFInput from "../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFRadioGroup from "../../../../../../modules/abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "../../../../../../modules/abstract-ui/forms/radio/BfRadio";
import BFSelect from "../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFSlider from "../../../../../../modules/abstract-ui/forms/slider/BFSlider";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFOverlay from "../../../../../../modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "../../../../../../modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../../../../../../modules/abstract-ui/icon/DefaultIcons";
import DataBusDefaults from "../../../../../../services/DataBusDefaults";
import ObjectIdService from "../../../../../../utils/ObjectIdUtils";
import { APProjectBudget } from "../project-budget/APProjectBudgetInterfaces";
import "./APConstructionDiariesEntriesField.scss";
import {
  getConstructionDiaryDocumentationTypeLabel,
  getSelectOptionsForBudgetProjectScheduleGroups,
} from "./APConstructionDiariesHelpers";
import {
  APProjectConstructionDiary,
  ConstructionDiaryDocumentationType,
} from "./APConstructionDiariesInterfaces";

function compare(a, b) {
  let nameA = a.toUpperCase();
  let nameB = b.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

interface APConstructionDiariesEntriesFieldProps {
  form: FormRenderProps<any>;
  diary?: APProjectConstructionDiary;
  fieldName: ConstructionDiaryDocumentationType;
  budget?: APProjectBudget;
}
const APConstructionDiariesEntriesField = (
  props: APConstructionDiariesEntriesFieldProps
) => {
  const labelWithoutCategory = i18n.t(
    "apTemplate.ConstructionDiary.BudgetGroupNotSorted",
    "Ohne Kategorie"
  );
  return (
    <FieldArray name={props.fieldName}>
      {({ fields }) => (
        <BFFormSection
          className={`ap-construction-diaries-entries`}
          title={`${getConstructionDiaryDocumentationTypeLabel(
            props.fieldName as ConstructionDiaryDocumentationType
          )} (${
            (fields.value || []).filter((e) => e.status === "active").length
          })`}
        >
          <div className={` ${props.fieldName}`}>
            {/* <div className={`entry-title`}>
              {getConstructionDiaryDocumentationTypeLabel(
                props.fieldName as ConstructionDiaryDocumentationType
              )}
            </div> */}
            <div className="entries-content">
              {(fields.value || []).filter((e) => e.status === "active")
                .length === 0 && (
                <div className={`empty-text`}>
                  {i18n.t(
                    "apTemplate:ConstructionDiary.Form.NoData",
                    "Keine Einträge bisher"
                  )}
                </div>
              )}

              {fields.map((field, index) => (
                <FieldArray name={`${field}.images`}>
                  {({ fields: imagesField }) => (
                    <BFDropzone
                      multipe
                      onDrop={(accepted, rejected) => {
                        if (accepted.length > 0) {
                          accepted.forEach((file) =>
                            imagesField.push({
                              type: "file",
                              file: file,
                              note: "",
                            })
                          );
                        }
                        if (rejected.length > 0) {
                          DataBusDefaults.toast({
                            type: "warning",
                            text: i18n.t(
                              "apTemplate:ConstructionDiary.Form.FileWrong",
                              "Einige Dateien konnten nicht hinzugefügt werden."
                            ),
                          });
                        }
                      }}
                      accept={{
                        "image/*": [".heic", ".heif", ".webp"],
                      }}
                      render={(open) => (
                        <FormFieldValues
                          names={[`${field}.id`, `${field}.status`]}
                        >
                          {([entryId, status]) =>
                            status === "active" && (
                              <div className={`documentation-entry`}>
                                <div className={`head`}>
                                  <div className={`group`}>
                                    <Field name={`${field}.group.type`}>
                                      {({ input, meta }) => (
                                        <>
                                          <div className={`toggle`}>
                                            <BFRadioGroup {...input} inline>
                                              <BfRadio value="group">
                                                {i18n.t(
                                                  "apTemplate:ConstructionDiary.Form.Group",
                                                  "Gruppe"
                                                )}
                                              </BfRadio>
                                              <BfRadio value="custom">
                                                {i18n.t(
                                                  "apTemplate:ConstructionDiary.Form.Custom",
                                                  "Benutzerdefiniert"
                                                )}
                                              </BfRadio>
                                            </BFRadioGroup>
                                          </div>

                                          {input.value === "custom" && (
                                            <div className={`more`}>
                                              <Field
                                                name={`${field}.group.displayName`}
                                                validate={FormValidators.compose(
                                                  FormValidators.required(),
                                                  FormValidators.max(150),
                                                  FormValidators.min(3)
                                                )}
                                              >
                                                {({ input, meta }) => (
                                                  <BFInput
                                                    // label={i18n.t(
                                                    //   "apTemplate:ConstructionDiary.Form.DisplayName",
                                                    //   "Name"
                                                    // )}
                                                    {...input}
                                                    {...FormValidators.getValidation(
                                                      meta
                                                    )}
                                                  />
                                                )}
                                              </Field>
                                            </div>
                                          )}
                                          {input.value === "group" && (
                                            <div className={`more`}>
                                              <Field
                                                name={`${field}.group.linkedId`}
                                                validate={FormValidators.compose(
                                                  FormValidators.required()
                                                )}
                                              >
                                                {({ input, meta }) => (
                                                  <BFSelect
                                                    // label={i18n.t(
                                                    //   "apTemplate:ConstructionDiary.Form.Group",
                                                    //   "Gruppe"
                                                    // )}
                                                    renderValue={(
                                                      value,
                                                      item,
                                                      selected
                                                    ) => {
                                                      return item?.displayValue;
                                                    }}
                                                    sort={(isGroup) => {
                                                      if (isGroup) {
                                                        return (a, b) => {
                                                          if (
                                                            a.groupTitle ===
                                                            labelWithoutCategory
                                                          ) {
                                                            return -1;
                                                          }
                                                          if (
                                                            b.groupTitle ===
                                                            labelWithoutCategory
                                                          ) {
                                                            return 1;
                                                          }
                                                          return compare(
                                                            a.groupTitle,
                                                            b.groupTitle
                                                          );
                                                        };
                                                      }

                                                      return (a, b) => {
                                                        return compare(
                                                          a.value,
                                                          b.value
                                                        );
                                                      };
                                                    }}
                                                    groupBy={"group"}
                                                    data={
                                                      props.budget?.data
                                                        ?.scheduleGroups
                                                        ? getSelectOptionsForBudgetProjectScheduleGroups(
                                                            props.budget.data
                                                              .scheduleGroups
                                                          )
                                                        : []
                                                    }
                                                    {...input}
                                                    {...FormValidators.getValidation(
                                                      meta
                                                    )}
                                                  />
                                                )}
                                              </Field>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </div>

                                  <div className={`actions`}>
                                    <BFButton
                                      appearance="link"
                                      size="xs"
                                      onClick={() => {
                                        if (
                                          props.diary?.data.entries.find(
                                            (e) => e._id === entryId
                                          )
                                        ) {
                                          props.form.form.mutators.setValue(
                                            `${field}.status`,
                                            "archived"
                                          );
                                        } else {
                                          props.form.form.mutators.setValue(
                                            `${field}.status`,
                                            "archived"
                                          );
                                          // fields.remove(index);
                                        }
                                      }}
                                    >
                                      <BfIcon
                                        className={`margin-right-5`}
                                        {...DefaultIcons.TRASH}
                                        size="xxs"
                                      />
                                      {i18n.t(
                                        "apTemplate:ConstructionDiary.Form.RemoveTypeEntry",
                                        "{{type}} entfernen",
                                        {
                                          type: getConstructionDiaryDocumentationTypeLabel(
                                            props.fieldName
                                          ),
                                        }
                                      )}
                                    </BFButton>
                                  </div>
                                </div>
                                <div className={`content`}>
                                  {props.fieldName === "workProgress" && (
                                    <div className={`progress`}>
                                      <Field
                                        name={`${field}.progress`}
                                        validate={FormValidators.compose(
                                          FormValidators.required(),
                                          FormValidators.min(0),
                                          FormValidators.max(100)
                                        )}
                                      >
                                        {({ input, meta }) => (
                                          <div className={`progress-input`}>
                                            <div className={`input`}>
                                              <BFInput
                                                type="number"
                                                label={i18n.t(
                                                  "apTemplate:ConstructionDiary.Form.Progress",
                                                  "Fortschritt"
                                                )}
                                                min={0}
                                                max={100}
                                                step={1}
                                                textAlign="right"
                                                suffix={"%"}
                                                {...input}
                                                {...FormValidators.getValidation(
                                                  meta
                                                )}
                                              />
                                            </div>
                                            <div className={`slider`}>
                                              <BFSlider
                                                min={0}
                                                max={100}
                                                step={1}
                                                {...input}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Field>
                                    </div>
                                  )}
                                  <div className={`remark`}>
                                    <Field name={`${field}.note`}>
                                      {({ input, meta }) => (
                                        <BFInput
                                          type="textarea"
                                          autosize
                                          label={i18n.t(
                                            "apTemplate:ConstructionDiary.Form.Remark",
                                            "Bemerkung"
                                          )}
                                          {...input}
                                          {...FormValidators.getValidation(
                                            meta
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                  <div className={`image-section`}>
                                    <div className={`label`}>
                                      {i18n.t(
                                        "apTemplate:ConstructionDiary.Form.Images",
                                        "Bilder"
                                      )}
                                    </div>
                                    <div className={`images`}>
                                      {imagesField.length === 0 && (
                                        <div className={`empty-text`}>
                                          {i18n.t(
                                            "apTemplate:ConstructionDiary.Form.NoImages",
                                            "Bisher keine Bilder hinzugefügt"
                                          )}
                                        </div>
                                      )}
                                      <div className={`images-container`}>
                                        {imagesField.map(
                                          (imageField, index) => (
                                            <div className={`image-entry`}>
                                              <FormFieldValues
                                                names={[
                                                  `${imageField}.type`,
                                                  `${imageField}.cdnLink`,
                                                  `${imageField}.file`,
                                                ]}
                                              >
                                                {([type, cdnLink, file]) => (
                                                  <Field
                                                    name={`${imageField}.note`}
                                                  >
                                                    {({ input, meta }) => (
                                                      <BFOverlay
                                                        enterable
                                                        speaker={
                                                          <div
                                                            className={`padding-10`}
                                                          >
                                                            <BFInput
                                                              type="textarea"
                                                              autosize
                                                              label={i18n.t(
                                                                "apTemplate:ConstructionDiary.Form.Remark",
                                                                "Bemerkung"
                                                              )}
                                                              rows={6}
                                                              {...input}
                                                              {...FormValidators.getValidation(
                                                                meta
                                                              )}
                                                            />
                                                            <div
                                                              className={`image-actions`}
                                                            >
                                                              <BFButton
                                                                appearance="link"
                                                                size="xs"
                                                                onClick={() =>
                                                                  imagesField.remove(
                                                                    index
                                                                  )
                                                                }
                                                              >
                                                                {i18n.t(
                                                                  "apTemplate:ConstructionDiary.Form.RemoveImage",
                                                                  "Bild entfernen"
                                                                )}
                                                              </BFButton>
                                                            </div>
                                                          </div>
                                                        }
                                                      >
                                                        <div
                                                          className={`image-preview`}
                                                        >
                                                          {type === "file" && (
                                                            <FilePreviewComponent
                                                              type="file"
                                                              file={file}
                                                              width={80}
                                                              height={80}
                                                            />
                                                          )}
                                                          {type === "cdn" && (
                                                            <FilePreviewComponent
                                                              type="cdn"
                                                              asset={
                                                                props.diary
                                                              }
                                                              assetField="data.images"
                                                              assetType={
                                                                AssetTypes
                                                                  .Activity
                                                                  .ConstructionDiary
                                                              }
                                                              cdnID={cdnLink}
                                                              hasFolderPermissions={
                                                                true
                                                              }
                                                              height={80}
                                                              width={80}
                                                            />
                                                          )}
                                                          {input.value && (
                                                            <div
                                                              className={`info-indicator`}
                                                            >
                                                              <BfIcon
                                                                type="bold"
                                                                data="messages-bubble"
                                                                size="xxs"
                                                              />
                                                            </div>
                                                          )}
                                                        </div>
                                                      </BFOverlay>
                                                    )}
                                                  </Field>
                                                )}
                                              </FormFieldValues>
                                            </div>
                                            //   <div className={`image-data`}>
                                            //     <div className={`remark`}>
                                            //       <Field
                                            //         name={`${imageField}.note`}
                                            //       >
                                            //         {({ input, meta }) => (
                                            //           <BFInput
                                            //             type="textarea"
                                            //             label={i18n.t(
                                            //               "apTemplate:ConstructionDiary.Form.Remark",
                                            //               "Bemerkung"
                                            //             )}
                                            //             {...input}
                                            //             {...FormValidators.getValidation(
                                            //               meta
                                            //             )}
                                            //           />
                                            //         )}
                                            //       </Field>
                                            //     </div>
                                            //     <div className={`image-actions`}>
                                            //       <BFButton
                                            //         appearance="link"
                                            //         size="xs"
                                            //         onClick={() =>
                                            //           imagesField.remove(index)
                                            //         }
                                            //       >
                                            //         {i18n.t(
                                            //           "apTemplate:ConstructionDiary.Form.RemoveImage",
                                            //           "Bild entfernen"
                                            //         )}
                                            //       </BFButton>
                                            //     </div>
                                            //   </div>
                                            // </div>
                                          )
                                        )}
                                      </div>
                                      <div className={`upload-action`}>
                                        <BFButton
                                          onClick={open}
                                          appearance="link"
                                          size="xs"
                                        >
                                          {i18n.t(
                                            "apTemplate:ConstructionDiary.Form.Upload",
                                            "Bild hochladen"
                                          )}
                                        </BFButton>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        </FormFieldValues>
                      )}
                    />
                  )}
                </FieldArray>
              ))}

              <div className={`add-action`}>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() => {
                    fields.push({
                      _id: ObjectIdService.new(),
                      status: "active",
                      images: [],
                      note: "",
                      group: {
                        type: "group",
                        linkedId: null,
                      },
                    });
                  }}
                >
                  <BfIcon
                    className={`margin-right-5`}
                    {...DefaultIcons.ADD}
                    size="xxs"
                  />
                  {i18n.t(
                    "apTemplate:ConstructionDiary.Form.AddTypeEntry",
                    "{{type}} hinzufügen",
                    {
                      type: getConstructionDiaryDocumentationTypeLabel(
                        props.fieldName
                      ),
                    }
                  )}
                </BFButton>
              </div>
            </div>
          </div>
        </BFFormSection>
      )}
    </FieldArray>
  );
};

export default APConstructionDiariesEntriesField;
