import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import _ from "lodash";
import { Field } from "react-final-form";
import FormValidators from "../../../../../../components/Form/Validation/FormValidators";
import StructLoader from "../../../../../../components/StructLoader/StructLoader";
import i18n from "../../../../../../i18n";
import BFCheckbox from "../../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../../modules/abstract-ui/forms/select/BFSelect";
import ContactSelect from "../../../../../../modules/contacts-module/ContactSelect";
import EZSelect from "../../../../../../modules/ez-form/form-elements/ez-select/EZSelect";
import EZGroup from "../../../../../../modules/ez-form/form-group/ez-form/EZGroup";
import CategoryStruct from "../../../../../../redux/actions/struct/implemented/CategoryStruct";
import InsuranceStruct from "../../../../../../redux/actions/struct/implemented/InsuranceStruct";
import LanguageService from "../../../../../../services/LanguageService";
import StringUtils from "../../../../../../utils/StringUtils";
import { useActivityConfigConstants } from "../../../../activityConfigApp/APConfigHooks";
import { useActivityStruct } from "../../../ActivityStructContext";
import "./APActivityContractFields.scss";
import { APContractActivity } from "./APActivityMainteanceInterfaces";
import APActivityMaintenanceService from "./APActivityMaintenanceService";

interface APActivitiyMaintenanceFieldsProps {
  activity: APContractActivity;
}
const APActivityContractFields = (props: APActivitiyMaintenanceFieldsProps) => {
  const activityStruct = useActivityStruct();
  const constants = useActivityConfigConstants();
  const contactType = constants.fields?.contactType;
  const conf = activityStruct.getConfig(props.activity.data.type);
  const groupIds = conf?.data.planlqBookingCategory || [];
  return (
    <StructLoader
      structType={"category"}
      unitType={props.activity.data.type}
      render={() => (
        <div className={`contract-fields`}>
          <EZGroup
            value={{
              ...props.activity.data.noticePeriod,
              contractId: props.activity.data.contractId,
              contact: props.activity.data.contact,
              contractStartDate: props.activity.data.contractStartDate,
              budgetLqCategories: props.activity.data.budgetLqCategories || [],
              allocatableCost: Math.floor(
                (props.activity.data.allocatableCosts || 0) * 100
              ),
              allocatableCostToggle:
                props.activity.data.allocatableCosts > 0 ? true : false,
              workPrice: constants.fields?.supplyContractFeature
                ? props.activity.data.workPrice || 0
                : undefined,
              fixPrice: constants.fields?.supplyContractFeature
                ? props.activity.data.fixPrice || 0
                : undefined,
              priceNote: constants.fields?.supplyContractFeature
                ? props.activity.data.priceNote || ""
                : undefined,
              consumption: constants.fields?.supplyContractFeature
                ? props.activity.data.consumption || ""
                : undefined,

              insuranceValue: constants.fields?.insuranceFeature
                ? props.activity.data.insuranceValue || 0
                : undefined,
              insuranceValue14: constants.fields?.insuranceFeature
                ? props.activity.data.insuranceValue14 || 0
                : undefined,
              insuranceList: constants.fields?.insuranceFeature
                ? props.activity.data.insuranceList || []
                : undefined,
            }}
            onSubmit={async (values) => {
              await APActivityMaintenanceService.submitContractData(
                constants.serviceUrl,
                props.activity._id,
                values,
                constants
              );
            }}
          >
            {(renderProps) => (
              <div>
                <div className={`__field`}>
                  <Field
                    name="budgetLqCategories"
                    validate={FormValidators.required()}
                  >
                    {({ input, meta }) => (
                      <BFSelect
                        type="multiple"
                        label={
                          i18n.t(
                            "apTemplate:Activity.fields.budgetLqCategories",
                            "Vertragskategorie"
                          ) + "*"
                        }
                        data={_.uniq([...groupIds, ...(input.value || [])])
                          .map((id) =>
                            CategoryStruct.getData(
                              props.activity.data.type
                            ).find((g) => g._id === id)
                          )
                          .filter((e) => e)
                          .map((e) => ({
                            value: e._id,
                            label: LanguageService.translateLabel(
                              e.data.displayName
                            ),
                          }))}
                        {...input}
                        {...FormValidators.getValidation(meta)}
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field`}>
                  <Field name="contractId">
                    {({ input, meta }) => (
                      <BFInput
                        label={
                          i18n.t(
                            "apTemplate:Activity.fields.contractId",
                            "Vertragskonto"
                          ) + "*"
                        }
                        {...input}
                        {...FormValidators.getValidation(meta)}
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field`}>
                  <Field name="contact">
                    {({ input, meta }) => (
                      <ContactSelect
                        placement={"autoVerticalStart"}
                        placeholder={i18n.t(
                          "apTemplate:Activity.fields.contactPlaceholder",
                          "Vertragspartner auswählen"
                        )}
                        businessUnits={[props.activity.data.type]}
                        contactTypes={[
                          contactType,
                          "TRANSIENT",
                          "OTHER",
                          "BILL",
                        ]}
                        label={i18n.t(
                          "apTemplate:Activity.fields.contact",
                          "Vertragspartner"
                        )}
                        {...input}
                        {...FormValidators.getValidation(meta)}
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field`}>
                  <Field
                    name="contractStartDate"
                    validate={FormValidators.compose(FormValidators.required())}
                  >
                    {({ input, meta }) => (
                      <BFDatefield
                        label={
                          i18n.t(
                            "apTemplate:Activity.fields.contractStartDate",
                            "Vertragsbeginn"
                          ) + "*"
                        }
                        {...input}
                        {...FormValidators.getValidation(meta)}
                      />
                    )}
                  </Field>
                </div>
                <Field name="type">
                  {({ input, meta }) => (
                    <>
                      <div className={`__field`}>
                        <EZSelect
                          // dataType="tag"
                          data={[
                            {
                              label: i18n.t(
                                "apTemplate:Activity.fields.maintenance.noticePeriod.unlimited",
                                "Unbefristeter Vertrag"
                              ),
                              value: "unlimited",
                            },
                            {
                              label: i18n.t(
                                "apTemplate:Activity.fields.maintenance.noticePeriod.limited",
                                "Vertragslaufzeit"
                              ),
                              value: "limited",
                            },
                          ]}
                          {...input}
                          onChange={(value) => {
                            if (input.value !== value) {
                              renderProps.form.mutators.setValue(
                                "typeData",
                                value === "limited"
                                  ? {
                                      autorenewal: false,
                                      monthsForCancelation:
                                        renderProps.form.getState().values
                                          ?.typeData?.monthsForCancelation,
                                    }
                                  : {
                                      monthsForCancelation:
                                        renderProps.form.getState().values
                                          ?.typeData?.monthsForCancelation,
                                    }
                              );
                            }
                            input.onChange(value);
                          }}
                        />
                      </div>
                      {input.value === "unlimited" && (
                        <div className={`__field`}>
                          <Field
                            name="typeData.monthsForCancelation"
                            validate={FormValidators.compose(
                              FormValidators.required(),
                              FormValidators.min(0)
                            )}
                          >
                            {({ input, meta }) => (
                              <BFInput
                                type={"number"}
                                step={1}
                                label={
                                  i18n.t(
                                    "apTemplate:Activity.fields.maintenance.noticePeriod.monthsForCancelation",
                                    "Kündigungsfrist in Monaten"
                                  ) + "*"
                                }
                                {...input}
                                {...FormValidators.getValidation(meta)}
                              />
                            )}
                          </Field>
                        </div>
                      )}
                      {input.value === "limited" && (
                        <>
                          <div className={`field-row`}>
                            <Field
                              name="typeData.contractActiveTill"
                              validate={FormValidators.compose(
                                FormValidators.required()
                              )}
                            >
                              {({ input, meta }) => (
                                <BFDatefield
                                  label={i18n.t(
                                    "apTemplate:Activity.fields.maintenance.noticePeriod.contractActiveTill",
                                    "Vertragsende"
                                  )}
                                  {...input}
                                  {...FormValidators.getValidation(meta)}
                                />
                              )}
                            </Field>

                            <Field
                              name="typeData.monthsForCancelation"
                              validate={FormValidators.compose(
                                FormValidators.required(),
                                FormValidators.min(0)
                              )}
                            >
                              {({ input, meta }) => (
                                <BFInput
                                  type={"number"}
                                  step={1}
                                  label={
                                    i18n.t(
                                      "apTemplate:Activity.fields.maintenance.noticePeriod.monthsForCancelation",
                                      "Kündigungsfrist in Monaten"
                                    ) + "*"
                                  }
                                  {...input}
                                  {...FormValidators.getValidation(meta)}
                                />
                              )}
                            </Field>
                          </div>
                          <div className={`toggle-group`}>
                            <Field name="typeData.autorenewal">
                              {({ input, meta }) => (
                                <>
                                  <div>
                                    <BFCheckbox
                                      checked={input.value}
                                      onChange={(_, checked) =>
                                        input.onChange(checked)
                                      }
                                    >
                                      {i18n.t(
                                        "apTemplate:Activity.fields.maintenance.noticePeriod.autorenewal",
                                        "Vertrag wird automatisch verlängert"
                                      )}
                                    </BFCheckbox>
                                  </div>

                                  {input.value && (
                                    <div>
                                      <Field
                                        name="typeData.autorenewalNumberOfMonths"
                                        validate={FormValidators.compose(
                                          FormValidators.required(),
                                          FormValidators.min(1)
                                        )}
                                      >
                                        {({ input, meta }) => (
                                          <BFInput
                                            type={"number"}
                                            step={1}
                                            prefix={i18n.t(
                                              "apTemplate:Activity.fields.maintenance.noticePeriod.autorenewalPrefix",
                                              "Verlängerung"
                                            )}
                                            suffix={i18n.t(
                                              "apTemplate:Activity.fields.maintenance.noticePeriod.autorenewalSuffix",
                                              "Monate"
                                            )}
                                            {...input}
                                            {...FormValidators.getValidation(
                                              meta
                                            )}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Field>

                {constants.fields?.insuranceFeature && (
                  <>
                    <div className={`field-row`}>
                      <div className={`price-field`}>
                        <Field
                          name="insuranceValue"
                          validate={FormValidators.compose(
                            FormValidators.min(0)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              type={"priceInput"}
                              prefix={StringUtils.getCurrencySymbol()}
                              label={i18n.t(
                                "apTemplate:Activity.fields.insurance.insuranceValue",
                                "Versicherungswert"
                              )}
                              {...input}
                              {...FormValidators.getValidation(meta)}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={`price-field`}>
                        <Field
                          name="insuranceValue14"
                          validate={FormValidators.compose(
                            FormValidators.min(0)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              type={"priceInput"}
                              prefix={StringUtils.getCurrencySymbol()}
                              label={i18n.t(
                                "apTemplate:Activity.fields.insurance.insuranceValue14",
                                "Versicherungswert 1914"
                              )}
                              {...input}
                              {...FormValidators.getValidation(meta)}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </>
                )}

                {constants.fields?.supplyContractFeature && (
                  <>
                    <div className={`field-row`}>
                      <div className={`price-field`}>
                        <Field
                          name="workPrice"
                          validate={FormValidators.compose(
                            FormValidators.min(0)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              type={"priceInput"}
                              prefix={StringUtils.getCurrencyCentLabel()}
                              label={i18n.t(
                                "apTemplate:Activity.fields.maintenance.workPrice",
                                "Arbeitspreis"
                              )}
                              {...input}
                              {...FormValidators.getValidation(meta)}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={`price-field`}>
                        <Field
                          name="fixPrice"
                          defaultValue={0}
                          validate={FormValidators.compose(
                            FormValidators.min(0)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              type={"priceInput"}
                              prefix={StringUtils.getCurrencySymbol()}
                              label={i18n.t(
                                "apTemplate:Activity.fields.maintenance.fixPrice",
                                "Grundpreis"
                              )}
                              {...input}
                              {...FormValidators.getValidation(meta)}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={`price-field`}>
                        <Field
                          name="consumption"
                          validate={FormValidators.compose(
                            FormValidators.required(),
                            FormValidators.min(0)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              type={"number"}
                              label={
                                i18n.t(
                                  "apTemplate:Activity.fields.maintenance.consumption",
                                  "Jahresverbrauch geschätzt"
                                ) + "*"
                              }
                              {...input}
                              {...FormValidators.getValidation(meta)}
                            />
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className={`__field`}>
                      <Field name="priceNote">
                        {({ input, meta }) => (
                          <BFInput
                            type={"textarea"}
                            label={i18n.t(
                              "apTemplate:Activity.fields.maintenance.priceNote",
                              "Preisanmerkung"
                            )}
                            {...input}
                            {...FormValidators.getValidation(meta)}
                          />
                        )}
                      </Field>
                    </div>
                  </>
                )}

                <div className={`__field`}>
                  <Field name="allocatableCostToggle">
                    {({ input, meta }) => (
                      <div className={`toggle-group`}>
                        <div className={`toggle`}>
                          <BFCheckbox
                            checked={input.value}
                            onChange={(_, checked) => {
                              input.onChange(checked);

                              if (checked) {
                                renderProps.form.mutators.setValue(
                                  "allocatableCost",
                                  100
                                );
                              } else {
                                renderProps.form.mutators.setValue(
                                  "allocatableCost",
                                  0
                                );
                              }
                            }}
                          >
                            {i18n.t(
                              "apTemplate:Activity.fields.maintenance.allocatableCostToggle",
                              "Kosten umlegbar"
                            )}
                          </BFCheckbox>
                        </div>
                        {input.value && (
                          <div>
                            <Field
                              name="allocatableCost"
                              validate={FormValidators.compose(
                                FormValidators.required(),
                                FormValidators.min(1),
                                FormValidators.max(100)
                              )}
                            >
                              {({ input, meta }) => (
                                <BFInput
                                  type={"number"}
                                  step={1}
                                  prefix={i18n.t(
                                    "apTemplate:Activity.fields.maintenance.allocatableCost",
                                    "Umlegbar"
                                  )}
                                  suffix={"%"}
                                  textAlign="right"
                                  {...input}
                                  {...FormValidators.getValidation(meta)}
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </div>

                {constants.fields?.insuranceFeature && (
                  <InsurancePositionField activity={props.activity} />
                )}
              </div>
            )}
          </EZGroup>
        </div>
      )}
    />
  );
};

export default APActivityContractFields;

const InsurancePositionField = (props: { activity: APContractActivity }) => {
  const data = InsuranceStruct.getInsurancePositions(props.activity.data.type);

  if (data.length === 0) {
    return null;
  }
  return (
    <Field name="insuranceList">
      {({ input, meta }) => (
        <div className={`insurance-list`}>
          <div className={`title`}>
            {i18n.t(
              "apTemplate:Activity.fields.maintenance.insuranceList",
              "Versicherungsarten"
            )}
          </div>
          {data.map((pos) => (
            <div className={`insurance-position`}>
              <div className={`checkbox`}>
                <BFCheckbox
                  checked={(input.value || [])?.some((e) => e.id === pos.id)}
                  onChange={(_, checked) =>
                    input.onChange(
                      checked
                        ? [...(input.value || []), { id: pos.id, value: 0 }]
                        : (input.value || []).filter((e) => e.id !== pos.id)
                    )
                  }
                >
                  {LanguageService.translateLabel(pos.displayName)}
                </BFCheckbox>
              </div>

              {input.value?.some((e) => e.id === pos.id) && (
                <div className={`input-field`}>
                  <Field
                    name={`insuranceList.${(input.value || [])?.findIndex(
                      (e) => e.id === pos.id
                    )}.value`}
                    validate={FormValidators.compose(
                      FormValidators.required(),
                      FormValidators.min(0)
                    )}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        type={"priceInput"}
                        prefix={StringUtils.getCurrencySymbol()}
                        {...input}
                        {...FormValidators.getValidation(meta)}
                      />
                    )}
                  </Field>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </Field>
  );
};
