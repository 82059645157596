export default class JsonValidationException {
  constructor(private key: string, private message: string) {}

  getKey() {
    return this.key;
  }
  getMessage() {
    return this.message;
  }
}
