import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import i18n from "../../../../../../../i18n";
import { FilterOptionWithMatchQueryConverter } from "../../../../../../../modules/abstract-ui/data/table-filter/BFTableFilterModel";
import { DateFilterOption } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/DateFilterOption";
import { DefaultFilterOptions } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/DefaultFilterOptions";
import { ListFilterOption } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/ListFilterOption";
import { NumberFilterOption } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/NumberFilterOption";
import { ListSelectionValueWithNegation } from "../../../../../../../modules/abstract-ui/data/table-filter/overlays/OverlayMultipleSelectionWithNegation";
import LanguageService from "../../../../../../../services/LanguageService";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import {
  getConfigRentalStatus,
  getConfigRentalUnitTypeGroup,
} from "../../CBTenantsConst";
import {
  RentalStatus,
  allRentalStatus,
  allRentalUnitTypeGroups,
} from "../../TenantsInterfaces";

export const CBRentalUnitFilterOptions: (
  objectKindId: string,
  filterTypes?: string[]
) => FilterOptionWithMatchQueryConverter[] = (objectKindId, filterTypes) => {
  const rentalUnitTypeGroups = allRentalUnitTypeGroups.map((group) => {
    const conf = getConfigRentalUnitTypeGroup(group);
    const data =
      filterTypes ||
      ObjectKindStruct.getUnitTypesByGroup(objectKindId, group).map(
        (unitType) => unitType.id
      );
    return {
      value: group,
      label: conf.label,
      data: data,
    };
  });

  const unitTypes = filterTypes
    ? filterTypes.map((type) => ({
        value: type,
        label: UnitStruct.getUnitLabel(type),
      }))
    : ObjectKindStruct.getUnitTypeByObjectKind(objectKindId).map(
        (unitType) => ({
          value: unitType.id,
          label: LanguageService.translateLabel(unitType.displayName),
        })
      );
  return [
    ...DefaultFilterOptions(),
    ListFilterOption({
      field: "data.rentalStatus",
      label: i18n.t("cb:RentalUnit.rentalStatus", "Status"),
      data: allRentalStatus.map((status: RentalStatus) => {
        const conf = getConfigRentalStatus(status);
        return {
          value: status,
          label: conf.label,
        };
      }),
    }),
    ListFilterOption({
      field: "data.unitTypeGroup",
      label: i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart"),
      data: rentalUnitTypeGroups,
      matchQueryConverter: (value: ListSelectionValueWithNegation) =>
        value && value.value && value.value.length > 0
          ? MQ[value.negate ? "in" : "nin"](
              "data.unitType",
              value.value
                .map(
                  (v) => rentalUnitTypeGroups.find((e) => e.value === v)?.data
                )
                .reduce((prev, cur) => [...prev, ...cur], [])
            )
          : undefined,
    }),
    ListFilterOption({
      field: "data.unitType",
      label: i18n.t("cb:RentalUnit.unitType", "Art"),
      data: unitTypes,
    }),
    NumberFilterOption({
      field: "data.quantity",
      label: i18n.t("cb:RentalUnit.quantity", "Anzahl/Fläche"),
      type: "area",
    }),
    NumberFilterOption({
      field: "data.rentGross",
      label: i18n.t("cb:RentalUnit.rentGross"),
      type: "currency",
    }),
    NumberFilterOption({
      field: "data.rentNet",
      label: i18n.t("cb:RentalUnit.rentNet"),
      type: "currency",
    }),
    NumberFilterOption({
      field: "data.operatingCostGross",
      label: i18n.t("cb:RentalUnit.operatingCostGross"),
      type: "currency",
    }),
    NumberFilterOption({
      field: "data.operatingCostNet",
      label: i18n.t("cb:RentalUnit.operatingCostNet"),
      type: "currency",
    }),
    DateFilterOption({
      field: "data.administratedFrom",
      label: i18n.t("cb:RentalUnit.administratedFrom"),
    }),
    DateFilterOption({
      field: "data.administratedTo",
      label: i18n.t("cb:RentalUnit.administratedTo"),
    }),
  ];
};
