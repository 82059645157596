import {
  ObjectKind,
  ObjectKindFeature_IMMO,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFAssetPDFViewer from "@/modules/abstract-ui/forms/pdf-marker/BFAssetPDFViewer";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import { isDefined } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import { useState } from "react";
import { Field } from "react-final-form";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";
// import "./CBRentalAgreementCopaymentForm.scss";

interface CBRentalAgreementCopaymentFormProps {
  force?: boolean;
  kind?: ObjectKind;
  rentalAgreement?: RentalAgreement;
}
const CBRentalAgreementCopaymentForm = (
  props: CBRentalAgreementCopaymentFormProps
) => {
  return (
    <Field name="copayment" allowNull defaultValue={null}>
      {(copayment) => {
        if (!props.force && copayment.input.value === null) {
          return null;
        }
        return (
          <Field
            name="marks.copayment"
            validate={FV.compose(FV.required(), FV.min(1))}
          >
            {({ input, meta }) => (
              <BFPDFMarkerSection
                marginBottom={20}
                {...FV.getValidation(meta)}
                title={
                  <>
                    <span className={`margin-left-5`}>
                      {i18n.t(
                        "cb:RentalAgreement.Form.Sections.copayment.title",
                        "Eigenanteil"
                      )}
                    </span>
                    {!props.force && (
                      <BFButton
                        inline
                        onClick={() => copayment.input.onChange(null)}
                        appearance="link"
                        size="xs"
                        noPadding
                        className={`margin-left-10`}
                      >
                        {i18n.t("Global.Buttons.remove")}
                      </BFButton>
                    )}
                  </>
                }
                {...input}
                editHint={i18n.t(
                  "cb:RentalAgreement.Form.Fields.copayment.editHint",
                  "Markieren Sie die Stellen, wo Sie die Informationen zum Eigenanteil finden."
                )}
                viewHint={i18n.t(
                  "cb:RentalAgreement.Form.Fields.copayment.viewHint",
                  "Informationen zum Eigenanteil"
                )}
                identifier="copayment"
                pdfViewerIdentifier={"rental-agreement-pdf"}
                value={input.value}
                onChange={input.onChange}
              >
                <>
                  <div className={`copayment-form`}>
                    <div className={`__flex`}>
                      <div className={`__flex-1`}>
                        <Field name="copayment.valueByCase">
                          {(valueByCase) => (
                            <BFInput
                              type="priceInput"
                              textAlign="right"
                              suffix={StringUtils.getCurrencySymbol()}
                              label={i18n.t(
                                "cb:RentalAgreement.Form.Fields.valueByCase",
                                "Eigenanteil je Fall"
                              )}
                              {...valueByCase.input}
                              {...FV.getValidation(valueByCase.meta)}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={`__flex-1`}>
                        <Field name="copayment.maxByYear">
                          {(maxByYear) => (
                            <BFInput
                              type="priceInput"
                              textAlign="right"
                              suffix={StringUtils.getCurrencySymbol()}
                              label={i18n.t(
                                "cb:RentalAgreement.Form.Fields.maxByYear",
                                "Maximaler Eigenanteil pro Jahr"
                              )}
                              {...maxByYear.input}
                              {...FV.getValidation(maxByYear.meta)}
                            />
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className={`__flex`}>
                      <div className={`__flex-1`}>
                        <Field name="copayment.note">
                          {(note) => (
                            <BFInput
                              type="textarea"
                              autoResize
                              {...note.input}
                              {...FV.getValidation(note.meta)}
                              label={i18n.t(
                                "cb:RentalAgreement.Form.copayment.noteText",
                                "Notiz"
                              )}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </>
              </BFPDFMarkerSection>
            )}
          </Field>
        );
      }}
    </Field>
  );
};

export default CBRentalAgreementCopaymentForm;
const getInitial = () => ({
  valueByCase: null,
  maxByYear: null,
  note: "",
});
export const CBRentalAgreementCopaymentFormButton = () => {
  return (
    <Field name="copayment" allowNull defaultValue={null}>
      {({ input, meta }) => {
        if (!isDefined(input.value)) {
          return (
            <BFButton
              type="button"
              appearance="outline"
              size="xs"
              onClick={() => input.onChange(getInitial())}
              text={i18n.t(
                "cb:RentalAgreement.Form.Buttons.addCopayment",
                "Eigenanteil hinzufügen"
              )}
            />
          );
        } else {
          return null;
        }
      }}
    </Field>
  );
};

export const CBRentalAgreementcopaymentUpdateForm = (props: {
  rentalAgreement: RentalAgreement;
  onClose: () => void;
}) => {
  const [initialValues] = useState({
    copayment: props.rentalAgreement.data.copayment || getInitial(),
    marks: {
      copayment: props.rentalAgreement.data.marks?.copayment,
    },
  });
  const kind = ObjectKindStruct.getKind(
    OrgaStruct.getObject(props.rentalAgreement.data.objectId)?.objectKindId
  );
  return (
    <FormStruct
      onSubmit={async (values) => {
        await CBRentalService.submitRentalAgreementCopayment(
          props.rentalAgreement._id,
          values.copayment,
          values.marks
        );
        props.onClose();
      }}
      initialValues={initialValues}
      onAbort={props.onClose}
      title={i18n.t(
        i18n.t("cb:RentalAgreement.Form.SectionTitle.copayment", "Eigenanteil")
      )}
      submitText={i18n.t("Global.Buttons.save")}
      additionalActions={
        <BFButton
          appearance="outline"
          onClick={async () => {
            ModalManager.confirm({
              title: i18n.t(
                "cb:RentalAgreement.Form.Delete.copayment.title",
                "Eigenanteil entfernen"
              ),
              message: i18n.t(
                "cb:RentalAgreement.Form.Delete.copayment.description",
                "Wollen Sie den Eigenanteil entfernen?"
              ),
              onConfirm: () => {
                CBRentalService.removeRentalAgreementCopayment(
                  props.rentalAgreement._id
                ).then(() => {
                  props.onClose();
                });
              },
            });
          }}
        >
          {i18n.t("Global.Buttons.delete")}
        </BFButton>
      }
      renderRight={(form) => (
        <div
          className={`pdf-viewer`}
          style={{
            width: "40vw",
            maxWidth: 1000,
            padding: 10,
          }}
        >
          <BFAssetPDFViewer
            identifier="rental-agreement-pdf"
            height={"78vh"}
            label={{
              single: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreement",
                "Mietvertrag"
              ),
              multiple: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreements",
                "Mietverträge"
              ),
            }}
            asset={props.rentalAgreement}
            assetField="data.attachments"
            assetType={AssetTypes.Rental.RentalAgreement}
            directoryId={
              (
                kind.data.features.find(
                  (e) => e.type === "immo"
                ) as ObjectKindFeature_IMMO
              )?.rentalAgreementDepositDirectory
            }
          />
        </div>
      )}
      render={(formProps) => (
        <>
          <CBRentalAgreementCopaymentForm
            rentalAgreement={props.rentalAgreement}
            kind={kind}
            force
          />
        </>
      )}
    />
  );
};
