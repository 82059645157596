import { nanoid } from "nanoid";
import { AssetTypes } from "../../model/AssetTypes";
import CDNService from "../../services/CDNService";
import CacheService from "../../services/CacheService";
import ServiceUtils from "../../services/ServiceUtils";
import { HTTP } from "../../utils/Http";
import CommentUtils from "./CommentUtils";
import { EmailUser } from "./Comments";

interface SendMailParam {
  assetType: string;
  assetId?: string;
  type?: string;
  recipients: EmailUser[];
  cc?: EmailUser[];
  bcc?: EmailUser[];
  subject: string;
  message: string;
  subactivityName?: string;
  attachments?: string[];
  attachmentsFromOtherMail?: {
    assetId: string;
    linksToCdn: string[];
  };
}
class CommentsServiceClass {
  async duplicateMailToOtherActivity(
    commentId: string,
    assetType: string,
    assetId: string
  ) {
    const result = await HTTP.post({
      target: "EMPTY",
      url: `/communication/${commentId}/duplicateMailToOtherActivity`,
      bodyParams: {
        assetType,
        assetId,
      },
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }

  async pinComment(commentId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        target: "EMPTY",
        url: `/communication/${commentId}/pin`,
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }
  async unpinComment(commentId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        target: "EMPTY",
        url: `/communication/${commentId}/unpin`,
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }

  async archiveComment(commentId: string, reason: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        target: "EMPTY",
        url: `/communication/${commentId}/archive`,
        bodyParams: {
          reason,
        },
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }
  async unarchiveComment(commentId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        target: "EMPTY",
        url: `/communication/${commentId}/unarchive`,
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }

  async follow(assetType: string, assetId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        target: "EMPTY",
        url: `/communication/followAsset`,
        bodyParams: {
          assetType,
          assetId,
        },
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }
  async unfollow(assetType: string, assetId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        target: "EMPTY",
        url: `/communication/unfollowAsset`,
        bodyParams: {
          assetType,
          assetId,
        },
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }

  async assignCommentToActivity(
    commentId: string,
    assetType: string,
    assetId: string,
    subactivityName?: string
  ) {
    const result = await HTTP.post({
      url: `/communication/assignCommentToActivity`,
      target: "EMPTY",
      bodyParams: {
        commentId,
        assetType,
        assetId,
        subactivityName,
      },
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }

  async createInternalComment(
    assetType: string,
    assetId: string,
    comment: string,
    subactivityName?: string
  ) {
    const result = await HTTP.post({
      target: "EMPTY",
      url: `/communication/createInternalComment`,
      bodyParams: {
        assetType,
        assetId,
        message: comment,
        mentions: CommentUtils.extractMentions(comment),
        subactivityName: subactivityName || undefined,
      },
    });
    return result;
  }

  async sendMail(param: SendMailParam) {
    if (param.assetId) {
      const result = await HTTP.post({
        target: "EMPTY",
        url: `/communication/createOutgoingMail`,
        bodyParams: {
          ...param,

          bcc: param.bcc || [],
          cc: param.cc || [],
          subactivityName: param.subactivityName || undefined,
          attachments: param.attachments || [],
          attachmentsFromOtherMail: param.attachmentsFromOtherMail || undefined,
        },
      });

      return result;
    } else {
      const result = await HTTP.post({
        target: "EMPTY",
        url: `/communication/createUnassignedOutgoingMail`,
        bodyParams: {
          ...param,

          bcc: param.bcc || [],
          cc: param.cc || [],
          subactivityName: param.subactivityName || undefined,
          attachments: param.attachments || [],
        },
      });

      return result;
    }
  }

  async addAttachmentToComment(
    file: File,
    id: string,
    cancelObj: { cancel: () => void }
  ) {
    return await CDNService.uploadFile({
      file: file,
      filename: file.name,
      assetField: "data.attachments",
      assetType: AssetTypes.Comment,
      tempID: id,
      cancelObj,
      onProgress: (progress) => {},
    });
  }

  async uploadEmails(
    files: File[],
    activityType: string,
    activityId: string,
    context?: string
  ) {
    for (const file of files) {
      await this.uploadEmail(file, activityType, activityId, context);
    }
  }

  async uploadEmail(
    file: File,
    activityType: string,
    activityId: string,
    context?: string
  ) {
    const cdnData = await CDNService.uploadFile({
      file: file,
      filename: file.name,
      assetField: "data.contentRaw",
      assetType: AssetTypes.Comment,
      tempID: nanoid(),
    });

    const result = await HTTP.post({
      target: "EMPTY",
      url: `/communication/uploadMailFile`,
      bodyParams: {
        assetType: activityType,
        assetId: activityId,
        cdnId: cdnData.cdnID,
        subactivityName: context || undefined,
        backupDate: new Date(file.lastModified).toISOString(),
      },
    });
  }
}
const CommentsService = new CommentsServiceClass();
export default CommentsService;
