import React from "react";
import { List } from "rsuite";
import "./ActionSheetEntries.scss";

export interface ActionSheetEntry {
  key: string | number;
  label: string | React.ReactNode;
  value: any;
  disabled?: boolean;
  preRender?: () => React.ReactNode;
  postRender?: () => React.ReactNode;
  contentRender?: (label) => React.ReactNode;
}

type ActionSheetEntriesProps = {
  data: ActionSheetEntry[];
  className?: string;
  onClick?: (value: any) => void;
};
export const ActionSheetEntries: React.FC<ActionSheetEntriesProps> = props => (
  <List
    className={`ActionSheetEntries ${props.className ? props.className : ""}`}
    hover
  >
    {props.data.map(dataEntry => {
      return (
        <List.Item
          key={dataEntry.key}
          onClick={() => {
            if (props.onClick) {
              props.onClick(dataEntry.value);
            }
          }}
        >
          <div className={"action-sheet-row"}>
            {dataEntry.preRender ? dataEntry.preRender() : null}
            <div className={"name"}>
              {dataEntry.contentRender
                ? dataEntry.contentRender(dataEntry.label)
                : dataEntry.label}
            </div>
            {dataEntry.postRender ? dataEntry.postRender() : null}
          </div>
        </List.Item>
      );
    })}
  </List>
);
