import React, { CSSProperties } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Application } from "../../../model/db/Application";
import { setUiConfig } from "../../../redux/actions/ui-config/ui-config-actions";
import { AppState } from "../../../redux/store";
import BFAppPermission, {
  AppPermission,
} from "../../abstract-ui/forms/app-permission/BFAppPermission";
import { GenericFormsLayoutProps } from "../../generic-forms/GFBaseElement";
import GenericFormField from "../../generic-forms/GenericFormField";
import "./PermissionFormFieldValues.scss";

type Props = {
  name: string;
  readonly?: boolean;
  application: Application;
  mandatorApps: Application[];
  style?: CSSProperties;
  units?: string[];
} & GenericFormsLayoutProps &
  WithTranslation;

type States = {
  appSelectionActive;
};

/**
 * For this component, it is necessary to load the mandator apps into the globalCache with the initial config
 * 
        action2: {
          type: "globalCache",
          url: "mandator/apps",
          key: "mandatorApplications",
        },
        
 */
class PermissionFormFieldValues extends React.Component<Props, States> {
  static defaultProps = {};

  render() {
    const { mandatorApps, assetTypes, name, params, i18n, style } = this.props;

    return (
      <GenericFormField
        allProperties={params.allProperties}
        formRoot={params.formProps}
        name={name}
        prefix={params.prefix}
        jsonProperty={this.props as any}
        render={(input, meta, name1, jsonProperty, currentValues) => {
          let value = (input.value as AppPermission[]) || [];

          value = value.filter((app) =>
            mandatorApps.find((entry) => entry._id === app.APP)
          );

          return (
            <BFAppPermission
              readonly={this.props.readonly}
              value={value}
              onChange={input.onChange}
              mandatorApps={this.props.mandatorApps}
              units={
                this.props.application.constants?.businessUnits as string[]
              }
            />
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  application: state.uiConfig.activeApplication,
  mandatorApps:
    state.global.cache &&
    state.global.cache.mandatorApplications &&
    state.global.cache.mandatorApplications.data
      ? state.global.cache.mandatorApplications.data.apps
      : [],
});

export default connect(mapStateToProps, { setUiConfig })(
  withTranslation()(PermissionFormFieldValues)
) as any;
