import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators, {
  FV,
} from "@/components/Form/Validation/FormValidators";
import Log from "@/debug/Log";
import i18n from "@/i18n";
import BFFormSection from "@/modules/abstract-ui/data/form-section/BFFormSection";
import LabeledInput from "@/modules/abstract-ui/forms/LabeledInput";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import LanguageService from "@/services/LanguageService";
import PermissionService from "@/services/PermissionService";
import { isDefined, isNotDefined } from "@/utils/Helpers";
import classNames from "classnames";
import React from "react";
import { Field } from "react-final-form";
import ObjectService from "../../ObjectService";
import { OAObject, ObjectFormValue } from "../../types/object.interface";
import {
  ObjectKind,
  checkAnyFeature,
  checkFeature,
} from "../../types/objectKind.interface";
import "./ObjectForm.scss";
import ObjectFormCustomfields from "./ObjectFormCustomfields";
import ObjectFormFeatureAddress, {
  getInitialValueFeatureAddress,
} from "./feature-forms/ObjectFormFeatureAddress";
import ObjectFormFeatureImmo, {
  getInitialValueFeatureImmo,
} from "./feature-forms/ObjectFormFeatureImmo";
import ObjectFormFeaturePurchasePrice, {
  getInitialValueFeaturePurchasePrice,
} from "./feature-forms/ObjectFormFeaturePurchasePrice";
import ObjectFormTemplateSelection from "./feature-forms/ObjectFormTemplateSelection";

interface ObjectFormProps {
  onClose: () => void;
  onSuccess?: (asset: OAObject) => void;
  asset?: OAObject;
  kind: ObjectKind;
}

const getInitialValue = (asset: OAObject, kind: ObjectKind) => {
  return {
    type: kind.data.type,
    entity: asset?.data.entity,
    bankInfo: {
      mainBankAccount: asset?.data.bankInfo?.mainBankAccount,
      incomingInvoiceBankAccount:
        asset?.data.bankInfo?.incomingInvoiceBankAccount || null,
    },
    id: asset?.data.id || null,
    status: asset?.data.status || "active",
    displayName: asset?.data.displayName,
    objectKindId: isNotDefined(asset) ? kind._id : undefined,
    start: asset?.data.start || new Date(),
    end: asset?.data.end || null,
    custom:
      kind.data.customfields.length > 0
        ? {
            ...Object.fromEntries(
              kind.data.customfields.map((field) => [
                field.id,
                asset?.data?.custom?.[field.id],
              ])
            ),
          }
        : {},
    feature: {
      address: getInitialValueFeatureAddress(asset, kind),
      immo: getInitialValueFeatureImmo(asset, kind),
      purchasePrice: getInitialValueFeaturePurchasePrice(asset, kind),
    },
  } as ObjectFormValue;
};

const transformFormdataToSubmitdata = (
  values: ObjectFormValue,
  kind: ObjectKind
) => {
  return {};
};

const ObjectForm: React.FC<ObjectFormProps> = (props) => {
  return (
    <FormStruct
      className={classNames("object-form")}
      onSubmit={async (values: ObjectFormValue) => {
        Log.info("submit objectform", values);
        const asset = await ObjectService.submitObject(
          values,
          props.asset?._id
        );
        props.onClose();
        props.onSuccess?.(asset);
      }}
      title={
        props.asset
          ? i18n.t("obj:ObjectForm.UpdateTitle", "{{kind}} bearbeiten", {
              kind: LanguageService.translateLabel(props.kind.data.displayName),
            })
          : i18n.t("obj:ObjectForm.CreateTitle", "{{kind}} erstellen", {
              kind: LanguageService.translateLabel(props.kind.data.displayName),
            })
      }
      ignoreSubmitOnEnter
      usePrompt
      //   description={
      //     props.asset
      //       ? i18n.t(
      //           "CBBookingCategoryRuleView.UpdateDescription",
      //           "Ändern Sie die Daten des Assets und speichern Sie."
      //         )
      //       : i18n.t(
      //           "CBBookingCategoryRuleView.CreateDescription",
      //           "Erstellen Sie ein neues Asset und speichern Sie."
      //         )
      //   }
      submitText={
        props.asset
          ? i18n.t("ObjectForm.SubmitUpdate", "{{kind}} speichern", {
              kind: LanguageService.translateLabel(props.kind.data.displayName),
            })
          : i18n.t("ObjectForm.SubmitCreate", "{{kind}} erstellen", {
              kind: LanguageService.translateLabel(props.kind.data.displayName),
            })
      }
      onAbort={props.onClose}
      initialValues={getInitialValue(props.asset, props.kind)}
      render={(formProps) => (
        <>
          <div className={`layout`}>
            <div className={`common-form`}>
              <div className={`__field`}>
                <FormFieldValues names={["type"]}>
                  {([type]) => (
                    <Field
                      name="entity"
                      validate={FormValidators.compose(
                        FormValidators.required()
                      )}
                    >
                      {({ input, meta }) => (
                        <BFSelect
                          disabled={!type || isDefined(props.asset)}
                          {...input}
                          label={`${i18n.t(
                            "obj:ObjectForm.entity",
                            "Gesellschaft"
                          )}*`}
                          {...FormValidators.getValidation(meta)}
                          data={OrgaStruct.getEntitySelectOptions(type)}
                          onChange={(value) => {
                            input.onChange(value);
                            formProps.form.mutators.setValue(
                              "bankInfo.mainBankAccount",
                              null
                            );
                            formProps.form.mutators.setValue(
                              "bankInfo.incomingInvoiceBankAccount",
                              null
                            );
                          }}
                        />
                      )}
                    </Field>
                  )}
                </FormFieldValues>
              </div>
              <div className={`__field`}>
                <FormFieldValues names={["entity"]}>
                  {([entity]) => (
                    <Field
                      name="bankInfo.mainBankAccount"
                      // validate={FormValidators.compose(
                      //   FormValidators.required()
                      // )}
                    >
                      {({ input, meta }) => (
                        <BFSelect
                          disabled={
                            !entity ||
                            isDefined(
                              props.asset?.data.bankInfo.mainBankAccount
                            )
                          }
                          {...input}
                          info={i18n.t(
                            "obj:ObjectForm.bankAccountInfo",
                            "Dieses Bankkonto wird als Standardkonto für das Asset hinterlegt."
                          )}
                          label={`${i18n.t(
                            "obj:ObjectForm.bankAccount",
                            "Bankkonto"
                          )}`}
                          {...FormValidators.getValidation(meta)}
                          data={OrgaStruct.getBankAccountSelectOptions(entity)}
                          // onChange={(value) => {
                          //   input.onChange(value);
                          // }}
                        />
                      )}
                    </Field>
                  )}
                </FormFieldValues>
              </div>
              <FormFieldValues names={["entity"]}>
                {([entity]) => (
                  <Field
                    name="bankInfo.incomingInvoiceBankAccount"
                    allowNull
                    defaultValue={null}
                  >
                    {({ input, meta }) =>
                      isDefined(input.value) && (
                        <div className={`__field`}>
                          <BFSelect
                            {...input}
                            disabled={!entity}
                            label={`${i18n.t(
                              "obj:ObjectForm.incomingInvoiceBankAccount",
                              "Rechnungskonto"
                            )}`}
                            info={i18n.t(
                              "obj:ObjectForm.incomingInvoiceBankAccountInfo",
                              "Eingehende Rechnungen werden standardmäßig von diesem Konto bezahlt und nicht von dem hinterlegten Standardkonto."
                            )}
                            {...FormValidators.getValidation(meta)}
                            data={OrgaStruct.getBankAccountSelectOptions(
                              entity
                            )}
                            // onChange={(value) => {
                            //   input.onChange(value);
                            // }}
                          />
                        </div>
                      )
                    }
                  </Field>
                )}
              </FormFieldValues>

              <FormFieldValues
                names={[
                  "bankInfo.mainBankAccount",
                  "bankInfo.incomingInvoiceBankAccount",
                ]}
              >
                {([mainBankAccount, incomingInvoiceBankAccount]) => (
                  <div className={`bank-actions`}>
                    {isNotDefined(incomingInvoiceBankAccount) && (
                      <BFButton
                        disabled={isNotDefined(mainBankAccount)}
                        appearance="link"
                        size="xs"
                        noPadding
                        onClick={() =>
                          formProps.form.mutators.setValue(
                            "bankInfo.incomingInvoiceBankAccount",
                            mainBankAccount
                          )
                        }
                      >
                        {i18n.t(
                          "obj:ObjectForm.addIncomingInvoiceBankAccount",
                          "Rechnungskonto hinzufügen"
                        )}
                      </BFButton>
                    )}
                  </div>
                )}
              </FormFieldValues>

              <hr />
              <div className={`__field`}>
                <Field
                  name="id"
                  validate={FormValidators.compose(FormValidators.required())}
                >
                  {({ input, meta }) => {
                    return (
                      <BFInput
                        {...input}
                        info={i18n.t(
                          "obj:ObjectForm.idInfo",
                          "Setzen Sie einen eindeutigen Identifikationstext für dieses Asset, welche Ihnen bei der Suche in Listen helfen kann."
                        )}
                        label={`${i18n.t("obj:ObjectForm.id", "ID")}*`}
                        {...FormValidators.getValidation(meta)}
                      />
                    );
                  }}
                </Field>
              </div>
              <div className={`__field`}>
                <Field
                  name="displayName"
                  validate={FormValidators.compose(FormValidators.required())}
                >
                  {({ input, meta }) => {
                    return (
                      <BFInput
                        label={i18n.t("obj:ObjectForm.name", "Name")}
                        {...input}
                        {...FV.getValidation(meta)}
                      />
                    );
                  }}
                </Field>
              </div>
              <hr />
              <LabeledInput
                label={i18n.t(
                  "obj:ObjectForm.administratinPeriod",
                  "Administrationszeitraum"
                )}
                info={i18n.t(
                  "obj:ObjectForm.administratinPeriodInfo",
                  "Der Administrationszeitraum gibt den Zeitraum an, in welchem das Asset von Ihnen verwaltet werden soll. Sollte ein Auslaufdatum gesetzt sein, wird das Asset ab diesem Datum nicht mehr in Ihren Listen angezeigt. Ein Beispiel eines Auslaufsdatum wäre z.B. der Verkauf eines Assets."
                )}
              >
                <div className={`field-row`}>
                  <div className={`__field __flex-1`}>
                    <Field
                      name="start"
                      validate={FormValidators.compose(FV.required())}
                    >
                      {({ input, meta }) => (
                        <BFDatefield
                          {...input}
                          preventNull
                          {...FV.getValidation(meta)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={`__field label-until`}>
                    {i18n.t("Global.Labels.until")}
                  </div>
                  <FormFieldValues names={["start"]}>
                    {([start]) => (
                      <div className={`__field __flex-1`}>
                        <Field name="end" validate={FV.dateAfter(start)}>
                          {({ input, meta }) => (
                            <BFDatefield
                              {...input}
                              {...FV.getValidation(meta)}
                            />
                          )}
                        </Field>
                      </div>
                    )}
                  </FormFieldValues>
                </div>
              </LabeledInput>

              {props.asset && (
                <Field name="status">
                  {({ input, meta }) =>
                    isDefined(input.value) && (
                      <div className={`__field`}>
                        <BFSelect
                          {...input}
                          label={`${i18n.t("obj:ObjectForm.status", "Status")}`}
                          info={i18n.t(
                            "obj:ObjectForm.status",
                            "Archivierte Assets werden nicht mehr in den Listen angezeigt."
                          )}
                          {...FormValidators.getValidation(meta)}
                          data={[
                            {
                              label: i18n.t(
                                "obj:ObjectForm.statusActive",
                                "Aktiv"
                              ),
                              value: "active",
                            },
                            {
                              label: i18n.t(
                                "obj:ObjectForm.statusArchiviert",
                                "Archiviert"
                              ),
                              value: "archived",
                            },
                          ]}
                          // onChange={(value) => {
                          //   input.onChange(value);
                          // }}
                        />
                      </div>
                    )
                  }
                </Field>
              )}
            </div>
            {(checkAnyFeature(
              ["address", "immo", "purchasePrice"],
              props.kind
            ) ||
              (props.kind?.data?.customfields || []).length > 0) && (
              <div className={`feature-forms`}>
                {checkFeature("address", props.kind) && (
                  <BFFormSection
                    initialOpen
                    title={i18n.t(
                      "obj:ObjectForm.Address.SectionTItle",
                      "Adresse"
                    )}
                  >
                    <ObjectFormFeatureAddress />
                  </BFFormSection>
                )}
                {(checkAnyFeature(["immo", "purchasePrice"], props.kind) ||
                  (props.kind?.data?.customfields || []).length > 0) && (
                  <BFFormSection
                    initialOpen
                    title={i18n.t(
                      "obj:ObjectForm.Custom.SectionTItle",
                      "Weitere Daten"
                    )}
                  >
                    <>
                      {checkFeature("immo", props.kind) && (
                        <ObjectFormFeatureImmo formProps={formProps} />
                      )}
                      {checkFeature("purchasePrice", props.kind) &&
                        PermissionService.hasObjectKindPermission(
                          props.kind,
                          "purchasePrice.createEdit"
                        ) && <ObjectFormFeaturePurchasePrice />}
                      {(props.kind?.data?.customfields || []).length > 0 && (
                        <ObjectFormCustomfields kind={props.kind} />
                      )}
                    </>
                  </BFFormSection>
                )}

                {isNotDefined(props.asset) &&
                  checkAnyFeature(["immo"], props.kind) && (
                    <BFFormSection
                      initialOpen
                      title={i18n.t(
                        "obj:ObjectForm.Templates.SectionTItle",
                        "Sollstellung"
                      )}
                    >
                      <ObjectFormTemplateSelection kind={props.kind} />
                    </BFFormSection>
                  )}
              </div>
            )}
          </div>
        </>
      )}
    />
  );
};

export default ObjectForm;
