type EChartsInstance = any;

class ChartServiceClass {
	syncedCharts: {
		[key: string]: {
			data: {
				start: number;
				end: number;
				startValue: number;
				endValue: number;
			};
			charts: EChartsInstance[];
		};
	} = {};

	registerSyncDatazoom(syncId: string, chart: EChartsInstance) {
		if (!this.syncedCharts[syncId]) {
			this.syncedCharts[syncId] = {
				data: { start: 0, end: 0, startValue: 0, endValue: 0 },
				charts: [chart]
			};
		} else {
			this.syncedCharts[syncId].charts.push(chart);
		}
		chart.on("datazoom", zoomObj => {
			const synchedChartConf = this.syncedCharts[syncId];

			if (
				synchedChartConf.data.start !== zoomObj.start ||
				synchedChartConf.data.end !== zoomObj.end ||
				synchedChartConf.data.startValue !== zoomObj.startValue ||
				synchedChartConf.data.endValue !== zoomObj.endValue
			) {
				synchedChartConf.data = zoomObj;
				synchedChartConf.charts.forEach(otherChart => {
					if (otherChart !== chart) {
						otherChart.dispatchAction({ ...zoomObj, type: "dataZoom" });
					}
				});
			}
		});
	}
	unregisterSyncDatazoom(syncId: string, chart: EChartsInstance) {
		const synchedChartConf = this.syncedCharts[syncId];
		if (synchedChartConf) {
			synchedChartConf.charts.filter(e => e != chart);
		}
	}
}
const ChartService = new ChartServiceClass();
export default ChartService;
