import { AccountingData } from "@/apps/tatar/accounting/AccountingLoader";
import AccountingService from "@/apps/tatar/accounting/AccountingService";
import {
  AccountingAccount,
  AccountingBookingType,
} from "@/apps/tatar/accounting/interfaces/account.interface";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import MultipleAssetLoaders from "@/components/AssetLoader/MultipleAssetLoaders";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import { useHttpCache } from "@/redux/hooks";
import LanguageService from "@/services/LanguageService";
import MQ from "@/utils/MatchQueryUtils";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import _ from "lodash";
import { Loader } from "rsuite";
import { RentalAgreement } from "../../../TenantsInterfaces";
import "./CBRentalAgreementOpenAmounts.scss";
import { DebitPositionResult } from "./CBRentalAgreementOpenDebitPositions";
import CBRentalAgreementSaldoGraph from "./CBRentalAgreementSaldoGraph";

interface CBRentalAgreementOpenAmountsProps {
  accountingData: AccountingData;
  rentalAgreement: RentalAgreement;
  debitAccount: AccountingAccount;
  account: AccountingAccount;
  object: OAObject;
}
const CBRentalAgreementOpenAmounts = (
  props: CBRentalAgreementOpenAmountsProps
) => {
  const data = useHttpCache<DebitPositionResult[]>(
    `cb-rental-agreement-open-debit-positions-${props.account._id}`,
    `/api/accounting/${props.rentalAgreement.data.entity}/${props.account._id}/getOpenDebitPositions`,
    "get",
    null
  );

  const debitAccount = props.debitAccount;

  if (!debitAccount) {
    return (
      <div
        className={`cb-rental-agreement-accounting __card no-debit-positions`}
      >
        {i18n.t(
          "cb:RentalAgreement.Accounting.NoDebitPositions",
          "Es wurde bisher kein Mieterkonto angelegt."
        )}
      </div>
    );
  }

  const objectKindId = props.object.data.objectKindId;
  const objectKind = ObjectKindStruct.getKind(objectKindId);

  const debitPositions = (
    props.object.data.feature?.immo?.accounting?.debitposition || []
  ).filter((e) => props.rentalAgreement.data.taxable && e.taxRate !== 0);
  const debitPositionsIds = debitPositions.map((e) => e.id);

  const balance = (debitAccount.data.sumBalance || 0) * -1;

  if (data.state === "loading") {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  if (data.state === "error") {
    return (
      <div>
        <p>error</p>
      </div>
    );
  }

  const bookings = data.data
    .map((e) =>
      e.bookings.map((a) =>
        AccountingService.getCorrectedBooking(e.data.account, a)
      )
    )
    .flat();
  const contraBookings = data.data
    .map((e) =>
      e.contraBookings.map((a) =>
        AccountingService.getCorrectedBooking(e.data.account, a)
      )
    )
    .flat();

  return (
    <MultipleAssetLoaders
      assets={debitPositionsIds.map((position) => ({
        assetType: AssetTypes.Accounting.Account,
        query: MQ.and(
          MQ.eq("data.linkedAsset.assetType", AssetTypes.Portfolio.Object),
          MQ.eq("data.linkedAsset.assetId", props.object._id),
          MQ.eq("data.linkedAsset.extra", position)
        ),
      }))}
      render={(relevantDebitPositionAccounts) => {
        const relevantAccountIds = _.uniq([
          ...(relevantDebitPositionAccounts || [])
            .filter((e) => e)
            .map((e) => e._id),
          ...(bookings || []).map((e) => e.data.contraAccount),
        ]);

        return (
          <div className={classNames(`cb-rental-agreement-open-accounts`)}>
            <DebugDataComponent
              data={{
                debitAccount,
                accountingData: props.accountingData,
                objectKind,
                object: props.object,
                relevantAccountIds,
              }}
            />
            <div className={`head-line`}>
              <div className={`label`}>
                {i18n.t(
                  "cb:RentalAgreement.Labels.Accounting.CurrentDebit",
                  "Aktueller Kontostand"
                )}
              </div>
              <div
                className={classNames(`value`, {
                  negative: balance < 0,
                  postive: balance > 0,
                })}
              >
                {StringUtils.formatCurrency(
                  balance,
                  undefined,
                  undefined,
                  props.accountingData.accounting.data.currency
                )}
              </div>
            </div>
            <div className={`info-container`}>
              <div className={`graph`}>
                <AssetLoader
                  assetType={AssetTypes.Accounting.Account}
                  query={MQ.and(
                    MQ.eq(
                      "data.entity",
                      props.accountingData.accounting.data.entity
                    ),
                    MQ.eq("data.linkedAsset.assetId", props.rentalAgreement._id)
                  )}
                  render={(account: AccountingAccount) => (
                    <CBRentalAgreementSaldoGraph
                      accountingData={props.accountingData}
                      account={account}
                    />
                  )}
                />
              </div>
              <div className={`sub-positions`}>
                <table>
                  {relevantAccountIds.map((accId) => {
                    return (
                      <AssetLoader
                        assetType={AssetTypes.Accounting.Account}
                        id={accId}
                        renderLoading={() => (
                          <tr>
                            <td colSpan={2}>
                              <Loader />
                            </td>
                          </tr>
                        )}
                        render={(account: AccountingAccount) => {
                          const balance = _.sum([
                            ...bookings
                              .filter((e) => e.data.contraAccount === accId)
                              .map(
                                (e) =>
                                  e.data.value.converted.amount *
                                    (e.data.bookingType ===
                                    AccountingBookingType.HABEN
                                      ? 1
                                      : -1) +
                                  _.sum(
                                    contraBookings
                                      .filter(
                                        (a) =>
                                          a.data.costId === e._id &&
                                          a.data.bookingType ===
                                            AccountingBookingType.HABEN
                                      )
                                      .map((a) => a.data.value.converted.amount)
                                  ) -
                                  _.sum(
                                    contraBookings
                                      .filter(
                                        (a) =>
                                          a.data.costId === e._id &&
                                          a.data.bookingType ===
                                            AccountingBookingType.SOLL
                                      )
                                      .map((a) => a.data.value.converted.amount)
                                  )
                              ),
                          ]);
                          return (
                            <>
                              <tr className={`sub-debit`}>
                                <td className={`label`}>
                                  <span>
                                    {LanguageService.translateLabel(
                                      account.data.displayName
                                    )}
                                  </span>
                                </td>
                                <td
                                  className={classNames(`value`, {
                                    negative: balance < 0,
                                    postive: balance > 0,
                                  })}
                                >
                                  {StringUtils.formatCurrency(
                                    balance,
                                    true,
                                    undefined,
                                    props.accountingData.accounting.data
                                      .currency
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        }}
                      />
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default CBRentalAgreementOpenAmounts;
