export const DetailEntryType = {
  MANUAL: "manual", //deletion, comment, category, object change
  IMPORT: "import", // deletion, upload, comment, category change, object change
  FORECAST: "forecast", // deletion
  RENT_FORECAST: "rent_forecast", // deletion
  SHARECAST: "sharepoint_damageclaim", // nothing!!
  // types for comparison§
  COMPARISON_FIX: "fix",
  COMPARISON_ADJUSTMENTS: "comparison_adjustment",
  INVOICE: "invoice",
};
