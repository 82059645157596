import ModalManager from "../../components/ModalComponent/ModalManager";
import i18n from "../../i18n";
import { Contact } from "../../model/db/Contact";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BFOverlay from "../abstract-ui/general/whisper/BFOverlay";
import ContactForm from "./ContactForm";
import ContactUtils from "./ContactUtils";
import ContactView from "./ContactView";

export const openEditContact = (contact: Contact) => {
  ModalManager.show({
    noPadding: true,
    backdrop: "static",
    size: "md",
    content: (states, setStates, closeModal) => (
      <ContactForm
        contact={contact}
        onClose={closeModal}
        businessUnits={[contact.data.type]}
      />
    ),
  });
};

export const ContactSelectMenuItem = (props: {
  contact: Contact;
  closePicker: () => void;
}) => {
  const infoLine = ContactUtils.getInfoLine(props.contact);
  return (
    <div>
      <BFOverlay
        delay={500}
        enterable
        speaker={
          <div className={`contact-view-container`}>
            <ContactView contact={props.contact} />
          </div>
        }
      >
        <div className={`contact-menu-item`}>
          <div className={`left`}>
            <div className={`name`}>{props.contact?.data?.displayName}</div>
            {infoLine && <div className={`info-line`}>{infoLine}</div>}
          </div>
          <div className={`right`}>
            <BFButton
              appearance="link"
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                props.closePicker();
                openEditContact(props.contact);
              }}
            >
              {i18n.t("Global.Buttons.edit")}
            </BFButton>
          </div>
        </div>
      </BFOverlay>
    </div>
  );
};
