import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FilePreviewComponent from "@/components/FilePreviewComponent/FilePreviewComponent";
import Timestamp from "@/components/Timestamp/Timestamp";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import FileUtils from "@/utils/FileUtils";
import classNames from "classnames";
import { useState } from "react";
import { ManualPDFComment } from "../../Comments";
import "./ManualPDFCommentEntry.scss";

interface ManualPDFCommentEntryProps {
  comment: ManualPDFComment;
}
const ManualPDFCommentEntry = (props: ManualPDFCommentEntryProps) => {
  const [url, setUrl] = useState(null);
  return (
    <div className={classNames(`manual-pdf-comment-entry`)}>
      <div className={`header`}>
        <div className={`header-main`}>
          {i18n.t(
            "CommentsModule.ManualPDFCommentEntry.Title",
            "PDF für den manuellen Versand"
          )}
        </div>

        <div className={`date`}>
          <Timestamp date={props.comment.data.date} addDateBehind />
        </div>
      </div>
      <div className={`content`}>
        <AssetLoader
          assetType={AssetTypes.Comment}
          id={props.comment._id}
          render={(comment: ManualPDFComment, selector, reload) => {
            if (comment.data.typeData.status === "CREATED") {
              return (
                <div className={`not-finished-yet`}>
                  <span>
                    {i18n.t(
                      "CommentsModule.ManualPDFCommentEntry.NotFinishedYet",
                      "Die Generierung des PDFs ist noch nicht abgeschlossen."
                    )}
                  </span>{" "}
                  <BFButton noPadding inline appearance="link" onClick={reload}>
                    {i18n.t("Global.Buttons.refresh")}
                  </BFButton>
                </div>
              );
            }
            if (comment.data.typeData.status === "FAILED") {
              return (
                <div className={`failed`}>
                  <span>
                    {i18n.t(
                      "CommentsModule.ManualPDFCommentEntry.Failed",
                      "Die Generierung des PDFs ist fehlgeschlagen."
                    )}
                  </span>
                </div>
              );
            }
            if (comment.data.typeData.status === "SUCCESS") {
              return (
                <div className={`__flex`}>
                  <div className={`preview`}>
                    <FilePreviewComponent
                      type="cdnBucket"
                      assetField="data.typeData.file"
                      assetId={comment._id}
                      assetType={AssetTypes.Comment}
                      cdnID=""
                      hasFolderPermissions={true}
                      height={300}
                      width={200}
                      bucket={comment.data.typeData.file}
                      onUrlLoaded={setUrl}
                    />
                  </div>
                  <div className={`__flex-1`}>
                    <div>
                      <BFButton
                        disabled={url === null}
                        appearance="link"
                        onClick={async () => {
                          FileUtils.downloadFile(url, "file.pdf", true);
                        }}
                      >
                        {i18n.t(
                          "CommentsModule.ManualPDFCommentEntry.Download",
                          "PDF herunterladen"
                        )}
                      </BFButton>
                    </div>
                    {/* <div>
                      {i18n.t(
                        "CommentsModule.ManualPDFCommentEntry.Success",
                        "PDF generiert."
                      )}
                    </div> */}
                  </div>
                </div>
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default ManualPDFCommentEntry;
