import { useState } from "react";
import { useDispatch } from "react-redux";
import { Animation } from "rsuite";
import AssetLoader from "../../../../components/AssetLoader/AssetLoader";
import TableComponent from "../../../../configurable/components/TableComponent/TableComponent";
import DebugDataComponent from "../../../../debug/DebugDataComponent";
import { setTableFulltext } from "../../../../redux/actions/application/application-actions";
import { useTypedSelector } from "../../../../redux/hooks";
import { AppState } from "../../../../redux/store";
import CacheService from "../../../../services/CacheService";
import { MatchQuery } from "../../../../services/DataService";
import { translateNonGenerate } from "../../../../utils/Helpers";
import BFInput from "../../forms/input/BFInput";
import BFButton from "../../general/Button/BFButton";
import { ValidatorPopoverStyle } from "../../general/ValidationPopover/ValidationPopover";
import BfIcon from "../../icon/BfIcon";
import "./AssetAssign.scss";

interface Props {
  tableIdentifier?: string;
  assetType: string;
  multiple: boolean;
  max?: number;
  value: string | string[];
  onChange: (value: string | string[]) => void;
  formatAsset: (asset: any) => React.ReactNode;
  label?: string;
  matchQuery?: MatchQuery;
  tableHeight?: number;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  sort?: {
    dataKey: string;
    sortType: "asc" | "desc";
  }[];
}
const AssetAssign = (props: Props) => {
  const values = props.value
    ? Array.isArray(props.value)
      ? props.value
      : [props.value]
    : [];
  const maxEntries = props.multiple ? props.max || Number.MAX_SAFE_INTEGER : 1;
  const entriesLeft = maxEntries - values.length;
  const { validatorStyle, validation } = props;
  return (
    <>
      <div
        className={`asset-assign ${
          validation?.message ? validation.level : ""
        }`}
      >
        {props.label && (
          <div className="label">
            {translateNonGenerate(props.label, props.label)}
          </div>
        )}
        <div
          className={`values ${values.length > 8 ? "inset" : ""}`}
          style={{ maxHeight: props.tableHeight || 300 }}
        >
          {values.map((value) => (
            <div className={`value-entry`}>
              <div className="content">
                <AssetLoader
                  assetType={props.assetType}
                  id={value}
                  render={(data) => props.formatAsset(data) as JSX.Element}
                />
              </div>
              <div className="action">
                <BFButton
                  onClick={() => {
                    const newList = values.filter((e) => e !== value);
                    props.onChange(props.multiple ? newList : null);
                  }}
                  appearance="clear-on-white"
                  icon={{ type: "light", data: "bin-2", size: "xs" }}
                />
              </div>
            </div>
          ))}
        </div>
        <AssetAssignAddComponent
          sort={props.sort}
          formatAsset={props.formatAsset}
          assetType={props.assetType}
          addsLeft={entriesLeft}
          onAdd={(entry) => {
            CacheService.setData(props.assetType, entry._id, entry);
            props.onChange(props.multiple ? [...values, entry._id] : entry._id);
          }}
          selectedIds={values}
          matchQuery={props.matchQuery}
          tableIdentifier={props.tableIdentifier}
          tableHeight={props.tableHeight || 300}
        />
        {validation?.message && (
          <div className={`error-message ${validation.level}`}>
            {validation.message}
          </div>
        )}
      </div>
    </>
  );
};
export default AssetAssign;

interface AssetAssignAddComponentProps {
  tableIdentifier?: string;
  onAdd: (element: any) => void;
  assetType: string;
  selectedIds: string[];
  matchQuery?: MatchQuery;
  addsLeft: number;
  formatAsset: (asset: any) => React.ReactNode;
  tableHeight: number;
  sort?: {
    dataKey: string;
    sortType: "asc" | "desc";
  }[];
}
const AssetAssignAddComponent = (props: AssetAssignAddComponentProps) => {
  const dispatch = useDispatch();
  const searchText = useTypedSelector(
    (state: AppState) =>
      state.application.tables[props.tableIdentifier]?.fulltextSearch
  );
  const [open, setOpen] = useState(false);

  return (
    <div className="asset-assign-add-component">
      <div className={`action-header`}>
        <div className="left">
          <Animation.Bounce in={open}>
            {(animProps, ref) => (
              <div {...animProps} ref={ref}>
                <BFInput
                  prefix={<BfIcon type="light" data="search" size="xs" />}
                  value={searchText}
                  onChange={(value) =>
                    dispatch(
                      setTableFulltext(props.tableIdentifier, value as string)
                    )
                  }
                />
              </div>
            )}
          </Animation.Bounce>
        </div>
        <div className="center">
          <Animation.Bounce in={!open}>
            {(animProps, ref) => (
              <div {...animProps} ref={ref}>
                <BFButton
                  onClick={() => setOpen(true)}
                  appearance="primary"
                  circle
                  icon={{ type: "light", data: "add", size: "xs" }}
                />
              </div>
            )}
          </Animation.Bounce>
        </div>
        <div className="right">
          <Animation.Bounce in={open}>
            {(animProps, ref) => (
              <div {...animProps} ref={ref}>
                <BFButton
                  onClick={() => setOpen(false)}
                  appearance="clear-on-white"
                  icon={{ type: "light", data: "close", size: "xs" }}
                />
              </div>
            )}
          </Animation.Bounce>
        </div>
      </div>
      <Animation.Collapse in={open}>
        {(animProps, ref) => (
          <div {...animProps} ref={ref}>
            <div className="content" style={{ height: props.tableHeight }}>
              <TableComponent
                params={{
                  selectedIds: props.selectedIds,
                  onAdd: props.onAdd,
                  formatAsset: props.formatAsset,
                }}
                matchQuery={props.matchQuery}
                ignoreMatchQueryExpressions={true}
                reloadOnMatchQueryChange={true}
                initialSort={props.sort}
                initialReload={true}
                appearance={"clear"}
                ignoreInteractionHighlights={true}
                ignoreTableSelection={true}
                useEndlessScrolling={true}
                post
                dataUrl={`/api/asset/list/${props.assetType}`}
                hideColumnHeaders={true}
                hideConfigMenu={true}
                pageSize={30}
                identifier={props.tableIdentifier}
                insetShadow={true}
                hideSelectionControls={true}
                striped={false}
                columns={{
                  file: {
                    columnHeaderTextKey: "",
                    cellRenderer: (row, key, columnConf, params) => {
                      const isSelected = (params.selectedIds || []).find(
                        (e) => e === row.original._id
                      );

                      return (
                        <div className={`element`}>
                          <DebugDataComponent data={row.orioginal} />
                          <div
                            className={`element-content ${
                              isSelected ? "selected" : ""
                            }`}
                          >
                            {params.formatAsset(row.original)}
                          </div>
                          <div className="action">
                            {!isSelected && (
                              <BFButton
                                appearance="clear-on-white"
                                onClick={() => params.onAdd(row.original)}
                                icon={{
                                  type: "light",
                                  data: "add",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    },
                  },
                }}
              />
            </div>
          </div>
        )}
      </Animation.Collapse>
    </div>
  );
};
