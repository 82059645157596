import { useActivityConstants } from "@/apps/tatar/activityApp/ActivityHooks";
import {
  APActivity,
  APOfferApproval,
} from "@/apps/tatar/activityApp/ActivityInterfaces";
import ActivityService from "@/apps/tatar/activityApp/ActivityService";
import { AP_TASKS_TABLE_IDENTIFIER } from "@/apps/tatar/activityApp/ActivityUtils";
import { UrgentBubble } from "@/apps/tatar/invoiceApp/components/UrgentIndidactor/UrgentIndicator";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import Userlabel from "@/components/AvatarComponent/Userlabel";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import ModalManager from "@/components/ModalComponent/ModalManager";
import TaskRestrictions from "@/components/Task/TaskRestrictions";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { ApproveActivityTaskAsset } from "@/model/general-assets/TaskAsset";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFMessage from "@/modules/abstract-ui/general/Message/BFMessage";
import Validators from "@/modules/generic-forms/util/Validatos";
import { useTypedSelector } from "@/redux/hooks";
import DataBus from "@/services/DataBus";
import DataBusDefaults from "@/services/DataBusDefaults";
import PermissionService from "@/services/PermissionService";
import { DataBusSubKeys } from "@/utils/Constants";
import MQ from "@/utils/MatchQueryUtils";
import TaskUtils from "@/utils/TaskUtils";
import { useState } from "react";
import { Field } from "react-final-form";
import { RadioTile } from "rsuite";
import Collapse from "rsuite/esm/Animation/Collapse";
import APOfferApprovalHistory from "../../APOfferApprovalHistory";
import { APOffer } from "../APOffer.Interface";
import APOfferEntry from "../offer/APOfferEntry";
import "./APOfferApprovalEntry.scss";

interface Props {
  activity: APActivity;
  offerApproval?: APOfferApproval;
  task?: ApproveActivityTaskAsset;
}
const APOfferApprovalEntry = (props: Props) => {
  if (
    !props.task &&
    props.offerApproval?.status === "ongoing" &&
    props.offerApproval?.taskId
  ) {
    return (
      <AssetLoader
        //   silentReload
        assetType={"task"}
        id={props.offerApproval?.taskId}
        render={(task: ApproveActivityTaskAsset) => (
          <ActivityOfferApprovalComponent
            offerApproval={props.offerApproval}
            activity={props.activity}
            task={task}
          />
        )}
      />
    );
  } else {
    if (!props.activity) {
      return (
        <div className={`__error`}>{i18n.t("errorCodes.unknownError.msg")}</div>
      );
    }
    const approval =
      props.offerApproval ||
      props.activity.data.offerApproval.find(
        (e) => e.taskId === props.task._id
      );
    if (approval) {
      return (
        <ActivityOfferApprovalComponent
          {...props}
          offerApproval={
            props.offerApproval ||
            props.activity.data.offerApproval.find(
              (e) => e.taskId === props.task._id
            )
          }
        />
      );
    } else {
      return (
        <div className={`ap-finished-approval`}>
          {i18n.t(
            "tatar.activityApp.views.details.APOfferApprovalEntry.finishedApproval",
            "Freigabe abgeschlossen"
          )}
        </div>
      );
    }
  }
};

export default APOfferApprovalEntry;

const ActivityOfferApprovalComponent = (props: Props) => {
  return (
    <div className={`ap-activity-offer-approval`}>
      <APOfferApprovalHistory
        approval={props.offerApproval}
        task={props.task}
        activity={props.activity}
      />
      <hr />
      <div className={`name`}>{props.offerApproval?.displayName}</div>
      <DebugDataComponent data={props} />

      {props.offerApproval?.status === "accepted" && (
        <OfferApprovalAccepted {...props} />
      )}
      {props.offerApproval?.status === "declined" && (
        <OfferApprovalDeclined {...props} />
      )}
      {props.offerApproval?.status === "ongoing" && (
        <OfferApprovalOngoing {...props} />
      )}
    </div>
  );
};

const OfferApprovalDeclined = (props: Props) => {
  const [showDeclinedOffers, setShowDeclinedOffers] = useState<boolean>(false);
  const constants = useActivityConstants();

  const declinedOffers = props.offerApproval?.offersInApproval.filter(
    (e) => e !== props.offerApproval?.chosenOffer?.id
  );
  const declinedBy =
    props.offerApproval?.history[props.offerApproval?.history.length - 1]?.user;
  return (
    <div className={`declined`}>
      <div className={`decline-reason`}>
        <div className={`decline-reason-description`}>
          <Userlabel id={declinedBy} />{" "}
          {i18n.t(
            "apTemplate:Activity.approveTask.declinedBy",
            "hat die Angebote abgelehnt mit folgender Begründung:",
            {
              ns: [constants?.assetType, "apTemplate"],
            }
          )}
        </div>

        <div className={`decline-reason-text`}>
          {props.offerApproval.declineReason || (
            <div className={`empty`}>
              {i18n.t(
                "apTemplate:Activity.approveTask.commentMissing",
                "Kommentar fehlt",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              )}
            </div>
          )}
          <div className={`bubble-indicator`} />
        </div>
      </div>

      {props.task?.data.metaInfos?.urgent?.isUrgent && (
        <UrgentBubble
          urgent={props.task?.data.metaInfos?.urgent}
          title={i18n.t(
            "apTemplate:ActivitiesApprove.UrgentApproval",
            "Dringende Freigabe",
            {
              ns: [constants?.assetType, "apTemplate"],
            }
          )}
        />
      )}

      <div className={`show-more`}>
        <BFButton
          appearance={"link"}
          onClick={() => setShowDeclinedOffers(!showDeclinedOffers)}
          text={
            showDeclinedOffers
              ? i18n.t(
                  "apTemplate:Activity.approveTask.hideDeclinedOffers",
                  "Abgelehnte Angebote verstecken",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )
              : i18n.t(
                  "apTemplate:Activity.approveTask.showDeclinedOffers",
                  "Abgelehnte Angebote anzeigen",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )
          }
        />
      </div>
      <Collapse in={showDeclinedOffers}>
        <div>
          <div className={`offers`}>
            <ListComponent
              assetType={AssetTypes.Activity.Offer}
              identifier={`offer-approval-entry-declined-${props.offerApproval.id}`}
              cleanupOnUnmount
              asPost
              additionalMatchQuery={MQ.and(MQ.in("_id", declinedOffers))}
              render={(offer: APOffer, index, params) => (
                <div>
                  <APOfferEntry
                    offer={offer}
                    constants={constants}
                    userSelections={props.task?.data.metaInfos.userSelections?.filter(
                      (e) => e.id === offer._id
                    )}
                  />
                </div>
              )}
            />
            {/*             
            {declinedOffers.length === 0 && (
              <div className={`__empty`}>
                {i18n.t(
                  "apTemplate:Activity.approveTask.noDeclinedOffers",
                  "Keine abgelehnten Angebote",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )}
              </div>
            )}
            {declinedOffers.map((offer) => (
              <div className={`offer-wrapper`} key={offer.linkToCdn}>
                <APActivityOffer
                  readonly
                  offer={offer}
                  activity={props.activity}
                  hideCheckbox
                  userSelections={props.offerApproval.history
                    .filter(
                      (e) =>
                        e.type === "OFFER_SELECTED" && e.id === offer.linkToCdn
                    )
                    .map((e) => ({ date: e.date, user: e.user, id: e.id }))}
                />
              </div>
            ))} */}
            <div className={`inset-shadow`} />
          </div>
        </div>
      </Collapse>
    </div>
  );
};
const OfferApprovalAccepted = (props: Props) => {
  const [showDeclinedOffers, setShowDeclinedOffers] = useState<boolean>(false);
  const constants = useActivityConstants();
  const declinedOffers = props.offerApproval?.offersInApproval.filter(
    (e) => e !== props.offerApproval?.chosenOffer?.id
  );
  return (
    <div className={`approved`}>
      <div className={`approved-title`}>
        {i18n.t(
          "apTemplate:Activity.approveTask.approved",
          "Freigegebenes Angebot",
          {
            ns: [constants?.assetType, "apTemplate"],
          }
        )}
      </div>
      {props.task?.data.metaInfos?.urgent?.isUrgent && (
        <UrgentBubble
          urgent={props.task?.data.metaInfos?.urgent}
          title={i18n.t(
            "apTemplate:ActivitiesApprove.UrgentApproval",
            "Dringende Freigabe",
            {
              ns: [constants?.assetType, "apTemplate"],
            }
          )}
        />
      )}
      <div className={`offers`}>
        <div className={`offer-wrapper`}>
          <AssetLoader
            assetType={AssetTypes.Activity.Offer}
            id={props.offerApproval.chosenOffer.id}
            render={(offer: APOffer) => (
              <APOfferEntry
                offer={offer}
                constants={constants}
                userSelections={props.task?.data.metaInfos.userSelections?.filter(
                  (e) => e.id === offer._id
                )}
              />
            )}
          />
        </div>
        <div className={`inset-shadow`} />
      </div>

      <div className={`show-more`}>
        <BFButton
          appearance={"link"}
          onClick={() => setShowDeclinedOffers(!showDeclinedOffers)}
          text={
            showDeclinedOffers
              ? i18n.t(
                  "apTemplate:Activity.approveTask.hideDeclinedOffers",
                  "Abgelehnte Angebote verstecken",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )
              : i18n.t(
                  "apTemplate:Activity.approveTask.showDeclinedOffers",
                  "Abgelehnte Angebote anzeigen",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )
          }
        />
      </div>
      <Collapse in={showDeclinedOffers}>
        <div>
          <div className={`offers`}>
            <ListComponent
              assetType={AssetTypes.Activity.Offer}
              identifier={`offer-approval-entry-accepted-${props.offerApproval?.id}`}
              cleanupOnUnmount
              asPost
              additionalMatchQuery={MQ.and(MQ.in("_id", declinedOffers))}
              render={(offer: APOffer, index, params) => (
                <div>
                  <APOfferEntry
                    offer={offer}
                    constants={constants}
                    userSelections={props.task?.data.metaInfos.userSelections?.filter(
                      (e) => e.id === offer._id
                    )}
                  />
                </div>
              )}
            />

            {/* {acceptedOffers.length === 0 && (
              <div className={`__empty`}>
                {i18n.t(
                  "apTemplate:Activity.approveTask.noDeclinedOffers",
                  "Keine abgelehnten Angebote",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )}
              </div>
            )}
            {acceptedOffers.map((offer) => (
              <div className={`offer-wrapper`} key={offer.linkToCdn}>
                  <APOfferEntry
                    offer={offer}
                    constants={constants}
                    userSelections={props.task.data.metaInfos.userSelections?.filter(
                      (e) => e.id === offer._id
                    )}
                  />
                <APActivityOffer
                  readonly
                  offer={offer}
                  activity={props.activity}
                  hideCheckbox
                  userSelections={props.offerApproval.history
                    .filter(
                      (e) =>
                        e.type === "OFFER_SELECTED" && e.id === offer.linkToCdn
                    )
                    .map((e) => ({ date: e.date, user: e.user, id: e.id }))}
                />
              </div>
            ))} */}
            <div className={`inset-shadow`} />
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const OfferApprovalOngoing = (props: Props) => {
  const constants = useActivityConstants();
  const myId = useTypedSelector((state) => state.global.user._id);
  const myTeams = useTypedSelector((state) => state.global.user.teams);
  const [selected, setSelected] = useState<string>(null);
  const approveTask = async () => {
    try {
      const activity = await ActivityService.approveOffer(
        constants?.serviceUrl,
        constants?.assetType,
        props.activity._id,
        "accept",
        props.offerApproval?.id,
        selected
      );
      // const task = await CacheService.getData({
      //   oType: "asset",
      //   id: props.task._id,
      //   assetType: "task",
      //   forceReload: true,
      // });

      DataBus.emit(DataBusSubKeys.SELECT_NEXT, {
        tableIdentifier: AP_TASKS_TABLE_IDENTIFIER,
        smooth: true,
      });
    } catch (err) {
      DataBusDefaults.toast({
        type: "error",
        text: i18n.t(
          "apTemplate:ActivitiesApprove.ErrorAtApproving",
          "Fehler beim Genehmigen",
          {
            ns: [constants?.assetType, "apTemplate"],
          }
        ),
      });
    }
  };
  const declineTask = async () => {
    ModalManager.show({
      noPadding: true,
      content: (states, setStates, closeModal) => (
        <DeclineModalDialog
          onClose={closeModal}
          onSuccess={async (reason) => {
            try {
              const activity = await ActivityService.declineOffer(
                constants?.serviceUrl,
                constants?.assetType,
                props.activity._id,
                "decline",
                props.offerApproval?.id,
                reason
              );
              DataBus.emit(DataBusSubKeys.SELECT_NEXT, {
                tableIdentifier: AP_TASKS_TABLE_IDENTIFIER,
                smooth: true,
              });
              closeModal();
            } catch (err) {
              DataBusDefaults.toast({
                type: "error",
                text: i18n.t(
                  "apTemplate:ActivitiesApprove.ErrorAtDeclining",
                  "Fehler beim Ablehnen",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                ),
              });
            }
          }}
        />
      ),
    });
  };

  const isAssignedToApprove =
    props.offerApproval?.status === "ongoing"
      ? TaskUtils.checkRestriction(props.task, myId, myTeams)
      : false;

  const canEditAnyWay = !isAssignedToApprove
    ? (
        PermissionService.hasBusinessUnitRole(
          constants?.permissionPrefix + "overwriteApprovals",
          true
        ) || []
      ).includes(props.activity.data.type)
    : false;
  return (
    <div className={`approval-form`}>
      {props.task?.data.metaInfos?.urgent?.isUrgent && (
        <UrgentBubble
          urgent={props.task?.data.metaInfos?.urgent}
          title={i18n.t(
            "apTemplate:ActivitiesApprove.UrgentApproval",
            "Dringende Freigabe",
            {
              ns: [constants?.assetType, "apTemplate"],
            }
          )}
        />
      )}
      {!isAssignedToApprove &&
        (canEditAnyWay ? (
          <BFMessage
            type="info"
            text={i18n.t(
              "apTemplate:Activity.approveTask.notAssignedInfoButCanEditAnyWay",
              "Diese Freigabe ist aktuell in Genehmigung. Sie sind nicht als Bearbeiter eingetragen, haben aber trotzdem die Berechtigung, die Freigabe zu erteilen.",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            )}
          />
        ) : (
          <BFMessage
            type="info"
            text={i18n.t(
              "apTemplate:Activity.approveTask.notAssignedInfo",
              "Diese Freigabe ist aktuell in Genehmigung. Sie sind nicht als Bearbeiter eingetragen.",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            )}
          />
        ))}
      {isAssignedToApprove && (
        <div className={`description`}>
          {i18n.t(
            "apTemplate:Activity.approveTask.description",
            "Bitte prüfen Sie die Aktivität und selektieren Sie das Angebot, welches Sie genehmigen möchten.",
            {
              ns: [constants?.assetType, "apTemplate"],
            }
          )}
        </div>
      )}
      <div className={`assignees`}>
        <span className={`label`}>
          {i18n.t(
            "apTemplate:Activity.approveTask.assigneeLabel",
            "Aktuelle Genehmiger",
            {
              ns: [constants?.assetType, "apTemplate"],
            }
          )}
          {": "}
        </span>
        <TaskRestrictions task={props.task} />
      </div>
      <div className={`offers`}>
        <ListComponent
          assetType={AssetTypes.Activity.Offer}
          identifier={`offer-approval-entry-ongoing-${props.offerApproval.id}`}
          cleanupOnUnmount
          asPost
          additionalMatchQuery={MQ.and(
            MQ.in("_id", props.task?.data.metaInfos.offerEntries)
          )}
          render={(offer: APOffer, index, params) => (
            <BFButton
              disabled={canEditAnyWay ? false : !isAssignedToApprove}
              className={`offer-entry`}
              key={offer._id}
              onClick={(ev) => {
                if (ev.target?.classList.contains("show-offer")) {
                  return;
                }
                if (isAssignedToApprove || canEditAnyWay) {
                  setSelected(offer._id);
                }

                // const currentlySelected = (
                //   params.input.value || []
                // ).includes(offer._id);
                // if (currentlySelected) {
                //   params.input.onChange(
                //     params.input.value.filter((e) => e !== offer._id)
                //   );
                // } else {
                //   params.input.onChange([
                //     ...(params.input.value || []),
                //     offer._id,
                //   ]);
                // }
              }}
            >
              <div style={{ pointerEvents: "none" }}>
                <RadioTile
                  checked={selected === offer._id}
                  onChange={(value) => {}}
                >
                  <APOfferEntry
                    offer={offer}
                    constants={constants}
                    userSelections={props.task?.data.metaInfos.userSelections?.filter(
                      (e) => e.id === offer._id
                    )}
                  />
                </RadioTile>
              </div>
            </BFButton>
          )}
        />

        {/* {props.task?.data.metaInfos.offerEntries
          .map((offerId) =>
            props.activity.data.offers.find((e) => e.linkToCdn === offerId)
          )
          .filter((e) => !!e)
          .map((offer) => (
            <BFButton
              key={offer.linkToCdn}
              className={`offer-entry`}
              onClick={(ev) => {
                if (ev.target?.classList.contains("show-offer")) {
                  return;
                }
                if (isAssignedToApprove || canEditAnyWay) {
                  setSelected(offer.linkToCdn);
                }
              }}
            >
              {(isAssignedToApprove || canEditAnyWay) && (
                <div className={`radio-item`}>
                  <BfRadio
                    disabled={canEditAnyWay ? false : !isAssignedToApprove}
                    value={offer.linkToCdn}
                    name={`offer-${offer.linkToCdn}`}
                    checked={selected === offer.linkToCdn}
                    onChange={(val, checked) => {
                      if (isAssignedToApprove || canEditAnyWay) {
                        if (checked) {
                          setSelected(offer.linkToCdn);
                        }
                      }
                    }}
                  />{" "}
                </div>
              )}
              <div className={`offer-wrapper`}>
                <APActivityOffer
                  readonly
                  offer={offer}
                  activity={props.activity}
                  onSelect={() => {
                    if (isAssignedToApprove || canEditAnyWay) {
                      setSelected(offer.linkToCdn);
                    }
                  }}
                  selectable
                  hideCheckbox
                  userSelections={props.task.data.metaInfos.userSelections?.filter(
                    (e) => e.id === offer.linkToCdn
                  )}
                />
              </div>
            </BFButton>
          ))} */}
        <div className={`inset-shadow`} />
      </div>
      {(isAssignedToApprove || canEditAnyWay) && (
        <div className={`task-action-row`}>
          <BFButton
            disabled={canEditAnyWay ? false : !isAssignedToApprove}
            appearance="outline"
            onClick={declineTask}
          >
            {i18n.t(
              "apTemplate:Activity.approveTask.DeclineOffer",
              "Ablehnen",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            )}
          </BFButton>
          <BFButton
            disabled={
              !selected || (canEditAnyWay ? false : !isAssignedToApprove)
            }
            className={`approve-button`}
            appearance="primary"
            onClick={approveTask}
          >
            {i18n.t(
              "apTemplate:Activity.approveTask.ApproveSelected",
              "Genehmigen",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            )}
          </BFButton>
        </div>
      )}
    </div>
  );
};

const DeclineModalDialog = (props: {
  onClose: () => void;
  onSuccess: (reason: string) => Promise<void>;
}) => {
  const constants = useActivityConstants();
  return (
    <FormStruct
      title={i18n.t(
        "apTemplate:Activity.approveTask.DeclineApproval",
        "Freigabe ablehnen",
        {
          ns: [constants?.assetType, "apTemplate"],
        }
      )}
      description={i18n.t(
        "apTemplate:Activity.approveTask.DeclineApprovalDescription",
        "Bitte geben Sie einen Grund für die Ablehnung der Freigabe an.",
        {
          ns: [constants?.assetType, "apTemplate"],
        }
      )}
      onSubmit={async (values) => {
        await props.onSuccess(values.declineReason);
      }}
      onAbort={() => {
        props.onClose();
      }}
      submitText={i18n.t(
        "apTemplate:Activity.approveTask.DeclineOffer",
        "Ablehnen",
        {
          ns: [constants?.assetType, "apTemplate"],
        }
      )}
      render={(form) => {
        return (
          <>
            <div className={`__field`}>
              <Field
                name="declineReason"
                validate={(value) =>
                  !Validators.required(value)
                    ? i18n.t("Global.Labels.required")
                    : ""
                }
              >
                {({ input, meta }) => (
                  <BFInput
                    {...input}
                    placeholder={i18n.t(
                      "apTemplate:Activity.approveTask.Reason",
                      "Grund für die Ablehnung",
                      {
                        ns: [constants?.assetType, "apTemplate"],
                      }
                    )}
                    type="textarea"
                    style={{ minHeight: "100px" }}
                    validation={
                      meta.touched && meta.error
                        ? { level: "error", message: meta.error }
                        : undefined
                    }
                  />
                )}
              </Field>
            </div>
          </>
        );
      }}
    />
  );
};
