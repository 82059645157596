import { FavoriteFilter } from "../../../modules/abstract-ui/data/table-filter/BFTableFilterModel";
import {
  CommentDraft,
  CommentDraftData,
  TableColumnCustomization,
} from "../../reducers/customization/CustomizationInterface";

export const CUSTOMIZATION_INFINITE_TABLE_SET_COLUMNS = `CUSTOMIZATION_INFINITE_TABLE_SET_COLUMNS`;
export const CUSTOMIZATION_INFINITE_TABLE_REVERT = `CUSTOMIZATION_INFINITE_TABLE_REVERT`;
export const CUSTOMIZATION_INFINITE_TABLE_ADD_PERSIST = `CUSTOMIZATION_INFINITE_TABLE_ADD_PERSIST`;
export const CUSTOMIZATION_FAV_FILTER_ADD = `CUSTOMIZATION_FAV_FILTER_ADD`;
export const CUSTOMIZATION_FAV_FILTER_REMOVE = `CUSTOMIZATION_FAV_FILTER_REMOVE`;
export const CUSTOMIZATION_FAV_FILTER_UPDATE = `CUSTOMIZATION_FAV_FILTER_UPDATE`;

export const CUSTOMIZATION_DRAFT_INIT = `CUSTOMIZATION_DRAFT_INIT`;
export const CUSTOMIZATION_DRAFT_SET = `CUSTOMIZATION_DRAFT_SET`;
export const CUSTOMIZATION_DRAFT_REMOVE = `CUSTOMIZATION_DRAFT_REMOVE`;

export const CUSTOMIZATION_SET_FLEX = `CUSTOMIZATION_SET_FLEX`;
interface CustomizationInfiniteTableSetColumns {
  type: typeof CUSTOMIZATION_INFINITE_TABLE_SET_COLUMNS;
  tableIdentifier: string;
  columns: {
    [columnIdentifier: string]: Partial<TableColumnCustomization>;
  };
  overwrite?: boolean;
}
interface CustomizationSetFlex {
  type: typeof CUSTOMIZATION_SET_FLEX;
  key: string;
  value: any;
}
interface CustomizationInfiniteTableRevert {
  type: typeof CUSTOMIZATION_INFINITE_TABLE_REVERT;
  tableIdentifier: string;
}
interface CustomizationInfiniteTableAddPersist {
  type: typeof CUSTOMIZATION_INFINITE_TABLE_ADD_PERSIST;
  tableIdentifier: string;
}
interface CustomizationFavoriteFilterAdd {
  type: typeof CUSTOMIZATION_FAV_FILTER_ADD;
  tableIdentifier: string;
  filter: FavoriteFilter;
}
interface CustomizationFavoriteFilterUpdate {
  type: typeof CUSTOMIZATION_FAV_FILTER_UPDATE;
  tableIdentifier: string;
  filter: FavoriteFilter;
}
interface CustomizationFavoriteFilterRemove {
  type: typeof CUSTOMIZATION_FAV_FILTER_REMOVE;
  tableIdentifier: string;
  id: string;
}

interface CustomizationDraftSet {
  type: typeof CUSTOMIZATION_DRAFT_SET;
  id: string;
  draft: CommentDraftData;
}
interface CustomizationDraftInit {
  type: typeof CUSTOMIZATION_DRAFT_INIT;
  drafts: CommentDraft[];
}
interface CustomizationDraftRemove {
  type: typeof CUSTOMIZATION_DRAFT_REMOVE;
  id: string;
}

export type CustomizationAction =
  | CustomizationFavoriteFilterUpdate
  | CustomizationDraftInit
  | CustomizationDraftSet
  | CustomizationDraftRemove
  | CustomizationFavoriteFilterAdd
  | CustomizationFavoriteFilterRemove
  | CustomizationInfiniteTableAddPersist
  | CustomizationInfiniteTableSetColumns
  | CustomizationInfiniteTableRevert
  | CustomizationSetFlex;
