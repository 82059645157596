import RiskScenarioView from "@/apps/tatar/cashBudget/views/portfolio/object/cards/risk-scenario/RiskScenarioView";
import ObjectDetailView from "@/components/ObjectDetailView/ObjectDetailView";
import StructLoader from "@/components/StructLoader/StructLoader";
import i18n from "@/i18n";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import PermissionService from "@/services/PermissionService";
import { when } from "@/utils/Helpers";
import classNames from "classnames";
import { OAObject } from "../../types/object.interface";
import { checkFeature } from "../../types/objectKind.interface";
import "./ObjectDetail.scss";
import ObjectDetailDamageClaim from "./activities/ObjectDetailDamageClaim";
import ObjectDetailMaintenances from "./activities/ObjectDetailMaintenances";
import ObjectDocumentsView from "./documents/ObjectDocumentsView";
import ObjectDetailGeneral from "./general/ObjectDetailGeneral";
import ObjectDetailLoans from "./loans/ObjectDetailLoans";
import ObjectDetailsLQPlan from "./lq-plan/ObjectDetailsLQPlan";
import ObjectResubmissionView from "./resubmission/ObjectResubmissionView";
import ObjectDetailStackingPlan from "./stackingplan/ObjectDetailStackingPlan";
import ObjectSupplyUnits from "./supply-units/ObjectSupplyUnits";
import ObjectTechnicalUnits from "./technical-units/ObjectTechnicalUnits";
import ObjectDetailRenter from "./tenants/ObjectDetailRenter";
import ObjectDetailVacancy from "./vacancy/ObjectDetailVacancy";

interface ObjectDetailProps {
  goBack?: () => void;
  asset: OAObject;
  integrated?: boolean;
}
const ObjectDetail = (props: ObjectDetailProps) => {
  if (!props.asset) {
    return null;
  }
  return (
    <StructLoader
      unitType={props.asset.data.type}
      structTypes={["unit", "orga", "objectKind", "category"]}
      render={() => {
        const kind = ObjectKindStruct.getKind(props.asset.data.objectKindId);
        return (
          <ObjectDetailView
            integrated={props.integrated}
            className={classNames("object-detail-view")}
            goBack={props.goBack}
            mainTitle={i18n.t("cb:Portfolio.Objekt.object_one")}
            main={() => (
              <div className="detail-container __card">
                <ObjectDetailGeneral
                  goBack={props.goBack}
                  asset={props.asset}
                  kind={kind}
                />
              </div>
            )}
            routes={[
              ...(checkFeature("immo", kind)
                ? [
                    {
                      name: i18n.t(
                        "cb:Portfolio.Objekt.subRoute.stackingPlan",
                        "Stackingplan"
                      ),
                      route: "stackingplan",
                      render: () => (
                        <ObjectDetailStackingPlan asset={props.asset} />
                      ),
                    },
                    {
                      name: i18n.t(
                        "cb:Portfolio.Objekt.subRoute.vacancy",
                        "Leerstand"
                      ),
                      route: "vacancy",
                      render: () => (
                        <ObjectDetailVacancy
                          refId={props.asset._id}
                          assets={[props.asset._id]}
                        />
                      ),
                    },
                    {
                      name: i18n.t(
                        "cb:Portfolio.Objekt.subRoute.tenant",
                        "Mieter"
                      ),
                      route: "renter",
                      render: () => (
                        <ObjectDetailRenter
                          assets={[props.asset._id]}
                          refId={props.asset._id}
                        />
                      ),
                    },
                  ]
                : []),
              ...when(
                PermissionService.hasObjectKindPermission(
                  kind,
                  "loanable.view"
                ),
                [
                  {
                    name: i18n.t(
                      "cb:Portfolio.Objekt.subRoute.finance",
                      "Finanzierung"
                    ),
                    route: "finance",
                    render: () => (
                      <ObjectDetailLoans
                        assets={[props.asset._id]}
                        refId={props.asset._id}
                      />
                    ),
                  },
                ],
                []
              ),
              ...when(
                checkFeature("immo", kind),
                [
                  ...when(
                    PermissionService.hasObjectKindPermission(
                      kind,
                      "risk-management.view"
                    ),
                    [
                      {
                        name: i18n.t(
                          "cb:Portfolio.Objekt.subRoute.riskManagement",
                          "Risikomanagement"
                        ),
                        route: "risk-management",
                        render: () => (
                          <RiskScenarioView
                            assets={[props.asset._id]}
                            refId={props.asset._id}
                            kind={kind}
                          />
                        ),
                      },
                    ],
                    []
                  ),
                  {
                    name: i18n.t(
                      "cb:Portfolio.Objekt.subRoute.damages",
                      "Schadensfälle"
                    ),
                    route: "damages",
                    render: () => (
                      <ObjectDetailDamageClaim
                        assets={[props.asset._id]}
                        refId={props.asset._id}
                        type={props.asset.data.type}
                      />
                    ),
                  },
                  {
                    name: i18n.t(
                      "cb:Portfolio.Objekt.subRoute.maintenance",
                      "Wartungsverträge"
                    ),
                    route: "maintenances",
                    render: () => (
                      <ObjectDetailMaintenances
                        assets={[props.asset._id]}
                        refId={props.asset._id}
                        type={props.asset.data.type}
                      />
                    ),
                  },
                  {
                    name: i18n.t(
                      "cb:Portfolio.Objekt.subRoute.technicalUnit",
                      "Technische Einheiten"
                    ),
                    full: false,
                    route: "technical-units",
                    render: () => <ObjectTechnicalUnits asset={props.asset} />,
                  },
                  {
                    name: i18n.t(
                      "cb:Portfolio.Objekt.subRoute.supplylUnit",
                      "Versorgungseinheiten"
                    ),
                    full: false,
                    route: "supply-units",
                    render: () => <ObjectSupplyUnits asset={props.asset} />,
                  },
                ],
                []
              ),
              {
                name: i18n.t(
                  "cb:Portfolio.Objekt.subRoute.Documents",
                  "Dokumente"
                ),
                route: "attachments",
                render: () => <ObjectDocumentsView asset={props.asset} />,
              },
              {
                name: i18n.t(
                  "cb:Portfolio.Objekt.subRoute.Resubmissions",
                  "Wiedervorlagen"
                ),
                route: "resubmissions",
                render: () => (
                  <ObjectResubmissionView objectAsset={props.asset} />
                ),
              },
              ...when(
                props.asset.data.bankInfo.mainBankAccount &&
                  PermissionService.hasObjectKindPermission(
                    kind,
                    "lq-plan.view"
                  ),
                [
                  {
                    name: i18n.t(
                      "cb:Portfolio.Objekt.subRoute.liquidityPlan",
                      "Liquiditätsplan"
                    ),
                    full: true,
                    route: "lq-plan",
                    render: () => <ObjectDetailsLQPlan asset={props.asset} />,
                  },
                ],
                []
              ),
              // ...(checkFeature("flightradar24", kind)
              //   ? [
              //       {
              //         name: i18n.t(
              //           "cb:Portfolio.Objekt.subRoute.flightHistory",
              //           "Flughistorie"
              //         ),
              //         route: "flight-history",
              //         render: () => (
              //           <ObjectDetailFlightHistory asset={props.asset} />
              //         ),
              //       },
              //     ]
              //   : []),
            ]}
          />
        );
      }}
    />
  );
};

export default ObjectDetail;
