import classNames from "classnames";
import { ConditionalFormattingRule, Style } from "exceljs";
import moment from "moment";
import { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { Modal } from "rsuite";
import i18n from "../../i18n";
import BFCheckbox from "../abstract-ui/forms/checkbox/BFCheckbox";
import BFSelect from "../abstract-ui/forms/select/BFSelect";
import BFButton from "../abstract-ui/general/Button/BFButton";
import "./ExportDialog.scss";
import { TableExcelExportOptions } from "./export.model";
import { exportTableData } from "./export.public";

export type ExportColumnConfigDate = {
  type: "date";
  selector: (node: any) => Date | Promise<Date>;
};
export type ExportColumnConfigNumber = {
  type: "number";
  selector: (node: any) => Number | Promise<Number>;
};
export type ExportColumnConfigString = {
  type: "string";
  selector: (node: any) => string | Promise<string>;
};
export type ExportColumnConfigImage = {
  type: "image";
  height: number;
  selector: (node: any) => string | Promise<string>;
};

export type ExportColumnConfig = (
  | ExportColumnConfigDate
  | ExportColumnConfigNumber
  | ExportColumnConfigString
  | ExportColumnConfigImage
) & {
  label: string;
  width: number;
  style?: Partial<Style>;
  conditionalFormats?: ConditionalFormattingRule[];
  totalFunction?:
    | "average"
    | "sum"
    | "countNums"
    | "count"
    | "max"
    | "min"
    | "stdDev"
    | "var";
};
export type ExportColumnConfigWithID = ExportColumnConfig & {
  id: string;
};
// i18n.t("ExportDialog.exportType.XLSX", "Excel")
// i18n.t("ExportDialog.exportType.CSV", "CSV")
// i18n.t("ExportDialog.exportType.JSON", "JSON")
export enum ExportType {
  XLSX = "xlsx",
  CSV = "csv",
  JSON = "json",
}

export const ALL_EXPORT_TYPES = [
  ExportType.XLSX,
  ExportType.CSV,
  ExportType.JSON,
];
interface ExportDialogProps {
  gatherDataFC: () => Promise<any[]>;
  exportConfig: ExportColumnConfigWithID[];
  exportFileName?: string;
  exportTypes?: ExportType[];
  exportOptions?: TableExcelExportOptions;

  onClose: () => void;
  onSuccess: () => void;
}
const ExportDialog = (props: ExportDialogProps) => {
  useEffect(() => {
    return () => {
      const el = document.querySelector("#root");
      el.removeAttribute("inert");
    };
  }, []);
  return (
    <Modal
      overflow={false}
      className={classNames(`export-dialog`)}
      backdrop={true}
      enforceFocus={true}
      open={true}
      onClose={props.onClose}
      onOpen={() => {
        const el = document.querySelector("#root");
        el.setAttribute("inert", "");
      }}
    >
      <Form
        initialValues={{
          exportType: (props.exportTypes || ALL_EXPORT_TYPES)[0],
          fields: props.exportConfig.map((config) => config.id),
        }}
        onSubmit={async (values) => {
          const data = await props.gatherDataFC();
          const configs = props.exportConfig.filter((config) =>
            values.fields.includes(config.id)
          );

          await exportTableData(
            props.exportFileName || `export_${moment().format("YYYY-MM-DD")}`,
            configs,
            data,
            values.exportType,
            props.exportOptions
          );

          props.onSuccess();
        }}
        render={(renderProps) => (
          <form onSubmit={renderProps.handleSubmit}>
            <Modal.Header closeButton={true}>
              {i18n.t("ExportDialog.header", "Daten exportieren")}
            </Modal.Header>
            <Modal.Body>
              <div className={`description`}>
                {i18n.t(
                  "ExportDialog.description",
                  "Wählen Sie die Felder aus, die Sie exportieren möchten."
                )}
              </div>
              <Field
                name="fields"
                validate={(value) =>
                  value.length === 0
                    ? i18n.t(
                        "ExportDialog.fieldsRequired",
                        "Mindestens ein Feld muss ausgewählt werden für den Export"
                      )
                    : undefined
                }
              >
                {({ input, meta }) => (
                  <>
                    <div className={`fields-to-export`}>
                      {props.exportConfig.map((option) => (
                        <BFCheckbox
                          checked={input.value.includes(option.id)}
                          onChange={(_val, checked) => {
                            if (checked) {
                              input.onChange([...input.value, option.id]);
                            } else {
                              input.onChange(
                                input.value.filter(
                                  (e: string) => e !== option.id
                                )
                              );
                            }
                          }}
                        >
                          {option.label}
                        </BFCheckbox>
                      ))}
                    </div>

                    {meta.error && meta.touched && (
                      <div className={`error`}>{meta.error}</div>
                    )}
                  </>
                )}
              </Field>
              {(props.exportTypes || ALL_EXPORT_TYPES).length > 1 && (
                <div className={`export-type`}>
                  <Field name="exportType">
                    {({ input, meta }) => (
                      <BFSelect
                        {...input}
                        label={i18n.t(
                          "ExportDialog.exportType.label",
                          "Art des Exports"
                        )}
                        cleanable={false}
                        data={(props.exportTypes || ALL_EXPORT_TYPES).map(
                          (e) => ({
                            value: e,
                            label: i18n.t(`ExportDialog.exportType.${e}`, e),
                          })
                        )}
                      />
                    )}
                  </Field>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className={`actions`}>
                <BFButton appearance="outline" onClick={props.onClose}>
                  {i18n.t("Global.Buttons.cancel")}
                </BFButton>
                <BFButton
                  type="submit"
                  loading={renderProps.submitting}
                  appearance="primary"
                >
                  {i18n.t("ExportDialog.Button.Export", "Daten exportieren")}
                </BFButton>
              </div>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

export default ExportDialog;
