import classNames from "classnames";
import i18n from "../../../i18n";
import BFUseTooltip from "../../abstract-ui/general/tooltip/BFUseTooltip";
import BfIcon from "../../abstract-ui/icon/BfIcon";
import { MailOutgoingCommentStatus } from "../Comments";
import "./MailOutgoingStatus.scss";

const configOf = (status: MailOutgoingCommentStatus) => {
  switch (status) {
    case MailOutgoingCommentStatus.CREATED:
      return {
        label: i18n.t(
          "CommentsModule.mailOutgoingStatus.CREATED.label",
          "Wird gesendet"
        ),
        color: "#666",
        icon: "send-email-envelope",
        tooltip: i18n.t(
          "CommentsModule.mailOutgoingStatus.CREATED.tooltip",
          "Die Mail wird gerade versendet. Bitte warten Sie einen Moment und aktualisieren Sie die Seite für einen Statusupdate."
        ),
      };
    case MailOutgoingCommentStatus.SUCCESS:
      return {
        label: i18n.t(
          "CommentsModule.mailOutgoingStatus.SUCCESS.label",
          "Versendet"
        ),
        color: "#196d15",
        icon: "check-1",
        tooltip: i18n.t(
          "CommentsModule.mailOutgoingStatus.SUCCESS.tooltip",
          "Die Mail wurde erfolgreich versendet."
        ),
      };
    case MailOutgoingCommentStatus.OPENED:
      return {
        label: i18n.t(
          "CommentsModule.mailOutgoingStatus.OPENED.label",
          "Gesehen"
        ),
        color: "#196d15",
        icon: "check-double-1",
        tooltip: i18n.t(
          "CommentsModule.mailOutgoingStatus.OPENED.tooltip",
          "Die Mail wurde von mindestens einem Empfänger geöffnet. Dieser Status ist nicht immer verfügbar, je nach Empfänger kann es sein, dass diese Lesebestätigung nicht erhalten werden kann.."
        ),
      };
    case MailOutgoingCommentStatus.BOUNCED:
    case MailOutgoingCommentStatus.FAILED:
      return {
        label: i18n.t(
          "CommentsModule.mailOutgoingStatus.FAILED.label",
          "Fehlgeschlagen"
        ),
        color: "var(--color-negative)",
        icon: "alert-triangle",
        tooltip: i18n.t(
          "CommentsModule.mailOutgoingStatus.FAILED.tooltip",
          "Die Mail konnte nicht erfolgreich versendet werden. Bitte überprüfen Sie ob die Mailadresse korrekt ist und versuchen Sie es erneut."
        ),
      };
    default:
      return {
        label: i18n.t(
          "CommentsModule.mailOutgoingStatus.UNKNOWN.label",
          "Unbekannt"
        ),
        color: "#222",
        icon: "send-mail-envelope",
        tooltip: i18n.t(
          "CommentsModule.mailOutgoingStatus.UNKNOWN.tooltip",
          "Der Status der Mail ist unbekannt."
        ),
      };
  }
};

interface MailOutgoingStatusProps {
  status: MailOutgoingCommentStatus;
}
const MailOutgoingStatus = (props: MailOutgoingStatusProps) => {
  if (!props.status) {
    return null;
  }
  const config = configOf(props.status);
  return (
    <BFUseTooltip tooltip={config.tooltip}>
      <div
        className={classNames(`mail-outgoing-status`)}
        style={{ color: config.color }}
      >
        <BfIcon data={config.icon} type="light" size="xs" />
        <span className={`status-label`}>{config.label}</span>
      </div>
    </BFUseTooltip>
  );
};

export default MailOutgoingStatus;
