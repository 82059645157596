import classNames from "classnames";
import moment from "moment";
import MQ from "../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../utils/StringUtils";
import { APActivity } from "../../../ActivityInterfaces";
import APBudgetInvoices from "../budget-invoices/APBudgetInvoices";
import "./APProjectBudgetDrawer.scss";

interface APBudgetDrawerProps {
  ids: string[];
  title: string;
  subTitle?: string;
  sum: number;
  date: Date;
  activity: APActivity;
}
const APProjectBudgetDrawer = (props: APBudgetDrawerProps) => {
  return (
    <div className={classNames(`ap-project-budget-drawer`)}>
      <div className={`drawer-header`}>
        <div className={`date`}>{moment(props.date).format("MMMM YYYY")}</div>
        <div className={`header-data`}>
          <div className={`left`}>
            <div className={`title`}>{props.title}</div>
            {props.subTitle && (
              <div className={`sub-title`}>{props.subTitle}</div>
            )}
          </div>
          <div className={`right`}>
            <div className={`value`}>
              {StringUtils.formatCurrency(props.sum)}
            </div>
          </div>
        </div>
      </div>
      <div className={`drawer-content`}>
        <APBudgetInvoices
          activity={props.activity}
          hideHeader
          additionalMatchQuery={MQ.in("data.splitValues.categoryId", props.ids)}
        />
      </div>
    </div>
  );
};

export default APProjectBudgetDrawer;
