import {
  ObjectKind,
  ObjectKindFeature_IMMO,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFAssetPDFViewer from "@/modules/abstract-ui/forms/pdf-marker/BFAssetPDFViewer";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import { isDefined } from "@/utils/Helpers";
import { useState } from "react";
import { Field } from "react-final-form";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";
// import "./CBRentalAgreementAutomaticForm.scss";

interface CBRentalAgreementAutomaticFormProps {
  force?: boolean;
  kind?: ObjectKind;
  rentalAgreement?: RentalAgreement;
}
const CBRentalAgreementAutomaticForm = (
  props: CBRentalAgreementAutomaticFormProps
) => {
  return (
    <Field name="automatic" allowNull defaultValue={null}>
      {(automatic) => {
        if (!props.force && automatic.input.value === null) {
          return null;
        }
        return (
          <Field
            name="marks.automatic"
            validate={FV.compose(FV.required(), FV.min(1))}
          >
            {({ input, meta }) => (
              <BFPDFMarkerSection
                marginBottom={20}
                {...FV.getValidation(meta)}
                title={
                  <>
                    <span className={`margin-left-5`}>
                      {i18n.t(
                        "cb:RentalAgreement.Form.Sections.automatic.title",
                        "Auszugsautomatik"
                      )}
                    </span>
                    {!props.force && (
                      <BFButton
                        inline
                        onClick={() => automatic.input.onChange(null)}
                        appearance="link"
                        size="xs"
                        noPadding
                        className={`margin-left-10`}
                      >
                        {i18n.t("Global.Buttons.remove")}
                      </BFButton>
                    )}
                  </>
                }
                {...input}
                editHint={i18n.t(
                  "cb:RentalAgreement.Form.Fields.automatic.editHint",
                  "Markieren Sie die Stellen, wo Sie die Informationen zur Auszugsautomatik finden."
                )}
                viewHint={i18n.t(
                  "cb:RentalAgreement.Form.Fields.automatic.viewHint",
                  "Informationen zur Auszugsautomatik"
                )}
                identifier="automatic"
                pdfViewerIdentifier="rental-agreement-pdf"
                value={input.value}
                onChange={input.onChange}
              >
                <>
                  <div className={`option-form`}>
                    <div className={`__flex`}>
                      <div className={`__flex-1`}>
                        <Field
                          name="automatic.monthBeforeContractEnd"
                          validate={FV.compose(FV.required(), FV.min(1))}
                        >
                          {(monthBeforeContractEnd) => (
                            <BFInput
                              type="number"
                              label={i18n.t(
                                "cb:RentalAgreement.Form.Fields.monthBeforeContractEnd",
                                "Anzahl Monate vor Auszug"
                              )}
                              prefix={i18n.t("Global.Labels.months", "Monate")}
                              {...monthBeforeContractEnd.input}
                              {...FV.getValidation(monthBeforeContractEnd.meta)}
                              step={1}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={`__flex-1`}>
                        <Field
                          name="automatic.extendByMonths"
                          validate={FV.compose(FV.required(), FV.min(1))}
                        >
                          {(extendByMonths) => (
                            <BFInput
                              type="number"
                              label={i18n.t(
                                "cb:RentalAgreement.Form.Fields.extendByMonths",
                                "Auszug verlängern um"
                              )}
                              prefix={i18n.t("Global.Labels.months", "Monate")}
                              {...extendByMonths.input}
                              {...FV.getValidation(extendByMonths.meta)}
                              step={1}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </>
              </BFPDFMarkerSection>
            )}
          </Field>
        );
      }}
    </Field>
  );
};

export default CBRentalAgreementAutomaticForm;
const getInitial = () => ({
  monthBeforeContractEnd: 6,
  extendByMonths: 12,
});
export const CBRentalAgreementAutomaticFormButton = () => {
  return (
    <Field name="automatic" allowNull defaultValue={null}>
      {({ input, meta }) => {
        if (!isDefined(input.value)) {
          return (
            <BFButton
              type="button"
              appearance="outline"
              size="xs"
              onClick={() => input.onChange(getInitial())}
              text={i18n.t(
                "cb:RentalAgreement.Form.Buttons.addAutomatic",
                "Auszugsautomatik hinzufügen"
              )}
            />
          );
        } else {
          return null;
        }
      }}
    </Field>
  );
};

export const CBRentalAgreementAutomaticUpdateForm = (props: {
  rentalAgreement: RentalAgreement;
  onClose: () => void;
}) => {
  const [initialValues] = useState({
    automatic: props.rentalAgreement.data.automatic || getInitial(),
    marks: {
      automatic: props.rentalAgreement.data.marks?.automatic,
    },
  });
  const kind = ObjectKindStruct.getKind(
    OrgaStruct.getObject(props.rentalAgreement.data.objectId)?.objectKindId
  );
  return (
    <FormStruct
      onSubmit={async (values) => {
        await CBRentalService.submitRentalAgreementAutomatic(
          props.rentalAgreement._id,
          values.automatic,
          values.marks
        );
        props.onClose();
      }}
      initialValues={initialValues}
      onAbort={props.onClose}
      title={i18n.t(
        i18n.t(
          "cb:RentalAgreement.Form.SectionTitle.Automatic",
          "Auszugsautomatik"
        )
      )}
      submitText={i18n.t("Global.Buttons.save")}
      additionalActions={
        <BFButton
          appearance="outline"
          onClick={async () => {
            ModalManager.confirm({
              title: i18n.t(
                "cb:RentalAgreement.Form.Delete.Automatic.title",
                "Auszugsautomatik entfernen"
              ),
              message: i18n.t(
                "cb:RentalAgreement.Form.Delete.Automatic.description",
                "Wollen Sie die Auszugsautomatik entfernen?"
              ),
              onConfirm: () => {
                CBRentalService.removeRentalAgreementAutomatic(
                  props.rentalAgreement._id
                ).then(() => {
                  props.onClose();
                });
              },
            });
          }}
        >
          {i18n.t("Global.Buttons.delete")}
        </BFButton>
      }
      renderRight={(form) => (
        <div
          className={`pdf-viewer`}
          style={{
            width: "40vw",
            maxWidth: 1000,
            padding: 10,
          }}
        >
          <BFAssetPDFViewer
            identifier="rental-agreement-pdf"
            height={"78vh"}
            label={{
              single: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreement",
                "Mietvertrag"
              ),
              multiple: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreements",
                "Mietverträge"
              ),
            }}
            asset={props.rentalAgreement}
            assetField="data.attachments"
            assetType={AssetTypes.Rental.RentalAgreement}
            directoryId={
              (
                kind.data.features.find(
                  (e) => e.type === "immo"
                ) as ObjectKindFeature_IMMO
              )?.rentalAgreementDepositDirectory
            }
          />
        </div>
      )}
      render={(formProps) => (
        <>
          <CBRentalAgreementAutomaticForm force />
        </>
      )}
    />
  );
};
