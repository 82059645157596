import classNames from "classnames";
import ModalManager from "../../../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../../../i18n";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import StringUtils from "../../../../../../utils/StringUtils";
import {
  APInvoiceProjectLink,
  APProjectBudget,
} from "../project-budget/APProjectBudgetInterfaces";
import APBudgetInvoiceManagePositions from "./APBudgetInvoiceManagePositions";
import "./APBudgetInvoiceSubrow.scss";

interface APBudgetInvoiceSubrowProps {
  link: APInvoiceProjectLink;
  budget?: APProjectBudget;
  onUpdated: () => void;
}
const APBudgetInvoiceSubrow = (props: APBudgetInvoiceSubrowProps) => {
  if (!props.budget) {
    return (
      <div className={`ap-budget-invoice-subrow-info`}>
        {i18n.t(
          "apTemplate:BudgetInvoices.NoBudget",
          "Es wurde noch kein Budget genehmigt",
          {
            ns: ["apTemplate"],
          }
        )}
      </div>
    );
  }
  return (
    <div className={classNames(`ap-budget-invoice-subrow`)}>
      {props.link.data.splitValues.map((splitValue) => (
        <SubRowEntry className={`subrow-entry split-value`}>
          <span className={`group`}>
            {
              props.budget.data.groups
                .map((e) =>
                  e.children.map((a) => ({
                    ...a,
                    group: e.id,
                    groupName: e.name,
                  }))
                )
                .flat()
                .find((e) => e.id === splitValue.categoryId)?.groupName
            }
          </span>
          <span className={`category`}>
            {
              props.budget.data.groups
                .map((e) => e.children)
                .flat()
                .find((e) => e.id === splitValue.categoryId)?.name
            }{" "}
          </span>
          <div className={`value`}>
            {StringUtils.formatCurrency(splitValue.value)}
          </div>
          {splitValue.comment && (
            <span className={`comment`}>{splitValue.comment}</span>
          )}
          <BFButton
            className={`edit-button`}
            noPadding
            appearance="link"
            size="xs"
            onClick={() => {
              ModalManager.show({
                noPadding: true,
                size: "xxl",
                content: (state, setState, close) => (
                  <APBudgetInvoiceManagePositions
                    link={props.link}
                    budget={props.budget}
                    onUpdated={props.onUpdated}
                    onClose={close}
                  />
                ),
              });
            }}
          >
            {i18n.t("apTemplate:BudgetInvoices.Edit", "Anpassen")}
          </BFButton>
        </SubRowEntry>
      ))}

      {props.link.data.difference !== 0 && (
        <SubRowEntry className={`assign`}>
          <BFButton
            appearance="link"
            onClick={() => {
              ModalManager.show({
                noPadding: true,
                size: "xxl",
                content: (state, setState, close) => (
                  <APBudgetInvoiceManagePositions
                    link={props.link}
                    budget={props.budget}
                    onUpdated={props.onUpdated}
                    onClose={close}
                  />
                ),
              });
            }}
            noPadding
          >
            {i18n.t(
              "apTemplate:BudgetInvoices.AssignDifference",
              "{{difference}} zu Positionen zuordnen",
              {
                difference: StringUtils.formatCurrency(
                  props.link.data.difference
                ),
              }
            )}
          </BFButton>
        </SubRowEntry>
      )}
    </div>
  );
};

export default APBudgetInvoiceSubrow;

const SubRowEntry = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={classNames(props.className, `subrow-entry`)}>
      <div className={`line`}>
        <div className={`line-right`} />
      </div>
      {props.children}
    </div>
  );
};
