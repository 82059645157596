import i18n from "@/i18n";
import ServiceUtils from "@/services/ServiceUtils";
import { TDocumentDefinitions } from "pdfmake/interfaces";

class PDFExporterClass {
  async exportPdf(name: string, docDefinition: TDocumentDefinitions) {
    return ServiceUtils.toastError(async () => {
      //@ts-ignore
      const { exportPDF } = await import("./pdf-export.private");

      await exportPDF(name, docDefinition);
    }, i18n.t("Base.ExportError", "Export fehlgeschlagen"));
  }
}
const PDFExporter = new PDFExporterClass();
export default PDFExporter;
