import { css } from "emotion";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Animation, Loader } from "rsuite";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";
import { User } from "../../../model/db/User";
import BFInput from "../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  RequestListOpts,
  requestListData,
} from "../../../services/DataService";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import "./RestrictComponent.scss";

const { Bounce } = Animation;
export interface RestrictUser {
  id: string;
  avatar: string;
  name: string;
  added?: boolean;
  deleted?: boolean;
}
export interface RestrictTeam {
  id: string;
  name: string;
}
export interface RestrictValue {
  users: RestrictUser[];
  teams: RestrictTeam[];
}

type Props = {
  assetId?: string;
  label?: string;
  requestListData: (
    opts: RequestListOpts,
    cancelObj: { cancel?: () => void }
  ) => void;
  value: RestrictValue;
  onChange: (value) => void;
  maxRestrictions?: number;
  canDerestrict?: boolean;
  canRestrictToUsers?: boolean;
  canRestrictToTeams?: boolean;
  whiteListTeams?: string[];
  whiteListUsers?: string[];
  appearance: "popup" | "static";
} & AbstractStylableProps &
  WithTranslation;

type States = {
  collapsed: boolean;
  searchValue: string;
  loadingUsers: boolean;
  loadingTeams: boolean;
  searchResultUsers: User[];
  searchResultTeam: {}[]; //Team[],
} & AbstractStylableStates;

class RestrictComponent extends AbstractStylableComponent<Props, States> {
  static defaultProps = {
    appearance: "popup",
  };
  readonly state: States = {
    collapsed: this.props.appearance === "popup" ? false : true,
    searchValue: "",
    loadingUsers: false,
    loadingTeams: false,
    searchResultUsers: [],
    searchResultTeam: [],
  };
  searchTimeout: NodeJS.Timeout;
  searchRef;
  cancelToken = { cancel: null };
  initSearch = false;
  //componentDidMount() {
  //}

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  componentDidUpdate(prevProps: Props, prevState: States, snapshot) {
    if (prevProps.assetId !== this.props.assetId) {
      this.initSearch = false;
      this.setState({
        searchValue: "",
      });
    }
    if (prevState.searchValue !== this.state.searchValue) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        if (this.cancelToken.cancel) {
          this.cancelToken.cancel();
        }
      }

      this.searchTimeout = setTimeout(() => {
        this.doSearch();
      }, 300);
      this.setState({
        loadingUsers: true,
      });
    }
  }

  doSearch(ignoreLoading = false) {
    if (!ignoreLoading) {
      this.setState({
        loadingUsers: true,
      });
    }
    this.initSearch = true;
    // type: "op";
    // name: string;
    // op: "eq" | "ne" | "lt" | "lte" | "gt" | "gte" | "in" | "nin";
    // value: any;
    this.props.requestListData(
      {
        limit: 8,
        url: "/api/user",
        textQuery: this.state.searchValue,
        matchQuery: this.props.value.users
          ? {
              type: "op",
              name: "_id",
              op: "nin",
              value: this.props.value.users.map((entry) => entry.id),
            }
          : undefined,
        onSuccess: (data) => {
          this.setState({
            loadingUsers: false,
            searchResultUsers: data.data,
          });
        },
        onError: (err) => {},
      },
      this.cancelToken
    );
  }

  //

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  addClicked() {
    this.setState({
      collapsed: true,
    });
    if (!this.initSearch) {
      this.doSearch(true);
    }

    // Log.debug("##restrict", this.searchRef);
    setTimeout(() => {
      this.searchRef.focus();
    });
  }
  renderUser(user: RestrictUser) {
    if (user.name === undefined) {
      return null;
    }
    return (
      <div
        key={user.id}
        className={`user-entry ${user.added ? "added" : ""} ${
          user.deleted ? "deleted" : ""
        }`}
      >
        <AvatarComponent avatar={user.avatar} displayName={user.name} />
        <div className={`name`}>{user.name}</div>
        <div className={`delete-action`}>
          <BFButton
            onClick={() => {
              this.props.onChange({
                ...this.props.value,
                users: this.props.value.users.map((entry) => {
                  if (entry.id === user.id) {
                    return {
                      ...entry,
                      deleted: true,
                      added: false,
                    };
                  } else {
                    return entry;
                  }
                }),
              });
              setTimeout(() => {
                this.props.onChange({
                  ...this.props.value,
                  users: this.props.value.users.filter(
                    (entry) => entry.id !== user.id
                  ),
                });
                setTimeout(() => {
                  this.doSearch(true);
                });
              }, 500);
            }}
            icon={{ type: "bf", data: "close" }}
          />
        </div>
      </div>
    );
  }
  renderUserSearchResult(user: User) {
    return (
      <div className={`user-result`}>
        <AvatarComponent
          avatar={user.avatar}
          displayName={user.displayname}
          size="lg"
        />
        <div className={`name`}>{user.displayname}</div>
        <BFButton
          className={`hover`}
          icon={{ type: "bf", data: "add", size: "2x" }}
          onClick={() => {
            let currentUsers = this.props.value.users;
            if (!currentUsers.find((entry) => entry.id === user._id)) {
              let newUsers = [
                ...currentUsers,
                {
                  id: user._id,
                  name: user.displayname,
                  avatar: user.avatar,
                  added: true,
                },
              ];
              this.props.onChange({ ...this.props.value, users: newUsers });
              setTimeout(() => {
                this.doSearch(true);
              });
            }
          }}
        />
      </div>
    );
  }

  getSearchPlaceholder() {
    const { t, canRestrictToTeams, canRestrictToUsers } = this.props;

    let placeholderText = "BFComponents.RestrictionComponent.SearchPlaceholder";
    if (canRestrictToTeams && !canRestrictToUsers) {
      placeholderText =
        "BFComponents.RestrictionComponent.SearchPlaceholderOnlyTeam";
    }

    if (!canRestrictToTeams && canRestrictToUsers) {
      placeholderText =
        "BFComponents.RestrictionComponent.SearchPlaceholderOnlyUser";
    }

    return t(placeholderText);
  }
  render() {
    const {
      collapsed,
      searchValue,
      loadingTeams,
      loadingUsers,
      searchResultTeam,
      searchResultUsers,
    } = this.state;
    const {
      value,
      onChange,
      t,
      label,
      canDerestrict,
      canRestrictToTeams,
      canRestrictToUsers,
      maxRestrictions,
      whiteListTeams,
      whiteListUsers,
    } = this.props;

    return (
      <div
        className={`restrict-component ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
      >
        {label ? <div className={`label`}>{label}</div> : null}
        <div className={`restrict-container`}>
          <div className={`current-restrictions`}>
            {value && value.users.map((user) => this.renderUser(user))}

            <div className={`add-restrictions`}>
              <BFButton
                onClick={() => this.addClicked()}
                icon={{ type: "bf", data: "add" }}
              />
            </div>
          </div>
        </div>
        {this.props.appearance === "popup" ? (
          <div
            className={`restrict-backdrop ${collapsed ? "active" : ""}`}
            onClick={() => {
              this.setState({ collapsed: false });
            }}
          ></div>
        ) : null}

        <Bounce in={collapsed} unmountOnExit>
          <div className={`add-form`}>
            <div className={`padding-wrapper`}>
              <div className={`search-bar`}>
                <BFInput
                  ref={(ref) => (this.searchRef = ref)}
                  placeholder={this.getSearchPlaceholder()}
                  prefix={<BfIcon type="bf" data="search" />}
                  value={searchValue}
                  onChange={(value) =>
                    this.setState({ searchValue: value.toString() })
                  }
                />
              </div>
              <div className={`search-entries`}>
                <div className={`results users`}>
                  {searchResultUsers.length !== 0 ? (
                    searchResultUsers.map((user) =>
                      this.renderUserSearchResult(user)
                    )
                  ) : (
                    <div className={`no-results`}>
                      {t("BFComponents.RestrictionComponent.NoSearchResults")}
                    </div>
                  )}

                  {loadingUsers ? (
                    <div className={`loader`}>
                      <Loader />
                    </div>
                  ) : null}
                </div>
                <div className={`results  teams`}></div>
              </div>
            </div>
          </div>
        </Bounce>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default connect(mapStateToProps, { requestListData })(
  withTranslation()(RestrictComponent)
) as any;
