import classNames from "classnames";
import _ from "lodash";
import React, { useRef } from "react";
import i18n from "../../i18n";
import { useTypedSelector } from "../../redux/hooks";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import ArrayUtils from "../../utils/ArrayUtils";
import { isDefined } from "../../utils/Helpers";
import BFButtonToggle from "../abstract-ui/general/Button/BFButtonToggle";
import { GanttTask } from "./BFGanttChart.interface";
import "./BFGanttChart.scss";
import {
  convertGantTasksToTasks,
  convertTasksToGantTasks,
} from "./BFGanttChart.utils";
import BFGanttChartHeader from "./components/BFGanttChartHeader";
import BFGanttChartTable from "./components/BFGanttChartTable";
import { Gantt, Task, ViewMode } from "./lib";

const TIME_STEP = 1000 * 60 * 60 * 24;

interface BFGanttChartProps {
  readonly?: boolean;
  allowProgressChange?: boolean;
  value: GanttTask[];
  onChange: (value: GanttTask[]) => void;
  progressChange?: "manual" | "check";
  includeSaturday?: boolean;
  includeSunday?: boolean;

  fixedTasks?: string[];

  additionalColumn?: {
    renderHeader: () => React.ReactNode;
    renderColumn: (task: Task) => React.ReactNode;
  };
}
const BFGanttChart = (props: BFGanttChartProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const locale = useTypedSelector(
    (state) => state.uiConfig.general[DefaultUIConfigs.SELECTED_LANGUAGE]
  );
  const [view, setView] = React.useState<ViewMode>(ViewMode.Month);

  // useEffect(() => {
  //   ref.current
  //     ?.querySelector("g.today")
  //     ?.scrollIntoView({ behavior: "smooth" });
  // }, []);

  const handleTaskCreate = (task: Task, indexToPush: number) => {
    const newTasks = ArrayUtils.insert(task, indexToPush, tasks);

    props.onChange(convertTasksToGantTasks(newTasks));
  };
  const handleTaskChange = (task: Task, newIndex?: number) => {
    let newTasks;
    if (!isDefined(newIndex)) {
      newTasks = tasks.map((t) =>
        t.id === task.id
          ? {
              ...task,
              start: _.min([task.start, task.end]),
              end: _.max([task.start, task.end]),
            }
          : t
      );
    } else {
      let tasksWithoutChanged = tasks
        .map((e, index) => ({ ...e, index }))
        .filter((e) => e.id !== task.id);

      newTasks = ArrayUtils.sortData(
        ArrayUtils.insert(
          {
            ...task,
            start: _.min([task.start, task.end]),
            end: _.max([task.start, task.end]),
            index: newIndex,
          },
          newIndex,
          tasksWithoutChanged
        ),
        { key: "index", dir: "asc" }
      );
    }
    props.onChange(convertTasksToGantTasks(newTasks));
  };
  const handleProgressChange = (task: Task) => {
    const newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    props.onChange(convertTasksToGantTasks(newTasks));
  };
  const handleExpanderClick = (task: Task) => {
    const newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    props.onChange(convertTasksToGantTasks(newTasks));
  };

  const removeTasks = (tasks: Task[], taskToDelete: string) => {
    const childs = tasks.filter((e) => e.project === taskToDelete);
    let newTasks = tasks;
    for (const child of childs) {
      newTasks = removeTasks(newTasks, child.id);
    }
    newTasks = newTasks.filter((e) => e.id !== taskToDelete);
    return newTasks;
  };
  const handleTaskDelete = (taskId: string) => {
    const newTasks = removeTasks(tasks, taskId);

    props.onChange(convertTasksToGantTasks(newTasks));
  };
  const tasks = convertGantTasksToTasks(props.value).map((e) => ({
    ...e,
    isDisabled: props.readonly ? true : false,
  }));
  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 200;
  } else if (view === ViewMode.Month) {
    columnWidth = 110;
  } else if (view === ViewMode.Week) {
    columnWidth = 100;
  } else if (view === ViewMode.QuarterYear) {
    columnWidth = 100;
  }

  return (
    <div className={classNames(`bf-gantt-chart`)} ref={ref}>
      <div className={`gantt-properties`}>
        <BFButtonToggle
          value={view}
          onChange={setView}
          buttons={[
            {
              value: ViewMode.Day,
              text: i18n.t("GanttChart.ViewMode.Day", "Tag"),
            },
            {
              value: ViewMode.Week,
              text: i18n.t("GanttChart.ViewMode.Week", "Woche"),
            },
            {
              value: ViewMode.Month,
              text: i18n.t("GanttChart.ViewMode.Month", "Monat"),
            },
            {
              value: ViewMode.QuarterYear,
              text: i18n.t(
                "GanttChart.ViewMode.QuarterYear",
                "Vierteljährlich"
              ),
            },
            {
              value: ViewMode.Year,
              text: i18n.t("GanttChart.ViewMode.Year", "Jährlich"),
            },
          ]}
        />
      </div>
      <div className={`gantt-chart`}>
        <Gantt
          locale={locale}
          timeStep={TIME_STEP}
          tasks={tasks}
          viewMode={view}
          onTaskCreate={handleTaskCreate}
          onDateChange={(task) => handleTaskChange(task)}
          onTaskDelete={handleTaskDelete}
          // onDelete={handleTaskDelete}
          onProgressChange={
            props.progressChange === "manual" ? handleProgressChange : undefined
          }
          onTaskUpdate={handleTaskChange}
          // onDoubleClick={handleDblClick}
          // onClick={handleClick}
          // onSelect={handleSelect}
          // listCellWidth={"500px"}
          onExpanderClick={handleExpanderClick}
          columnWidth={columnWidth}
          // ganttHeight={300}
          readonly={props.readonly}
          progressChange={props.progressChange}
          TaskListHeader={BFGanttChartHeader(props.additionalColumn)}
          TaskListTable={BFGanttChartTable(
            props.additionalColumn,
            props.fixedTasks
          )}
          includeSaturday={props.includeSaturday}
          includeSunday={props.includeSunday}
        />
      </div>
    </div>
  );
};

export default BFGanttChart;
