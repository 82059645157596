import moment from "moment";
import LoadPage from "../../../../../../../components/LoadPage/LoadPage";
import ChartComponent from "../../../../../../../configurable/components/ChartComponent/ChartComponent";
import i18n from "../../../../../../../i18n";
import { useStatisticQuery } from "../../../../../../../redux/hooks";
import StringUtils from "../../../../../../../utils/StringUtils";
import { APActivity } from "../../../../ActivityInterfaces";
import { APProjectBudget } from "../APProjectBudgetInterfaces";
import { calculateProjectBudgetData } from "./APProjectBudgetChartHelpers";
// import "./APBudgetComparisonGraph.scss";

interface APBudgetComparisonGraphProps {
  activity: APActivity;
  budget: APProjectBudget;
  identifier?: string;
}
const APProjectBudgetComparisonGraph = (
  props: APBudgetComparisonGraphProps
) => {
  const actualData = useStatisticQuery<
    {
      _id: { month: number; year: number; categoryId: string };
      value: number;
    }[]
  >("PROJECT_BOOKED_DETAIL", 1, {
    projectId: props.activity._id,
  });

  if (actualData.loading) {
    return <LoadPage />;
  }
  if (actualData.error) {
    return (
      <div className={`error`}>{i18n.t("Global.Labels.defaultError")}</div>
    );
  }

  return (
    <ChartComponent
      identifier={props.identifier || "ap-budget-comparison-graph"}
      renderer="canvas"
      options={option(props.budget, actualData.data)}
    />
  );
};

export default APProjectBudgetComparisonGraph;

const option = (
  budget: APProjectBudget,
  actual: {
    _id: { month: number; year: number; categoryId: string };
    value: number;
  }[]
) => {
  const data = calculateProjectBudgetData(budget, actual);

  return {
    tooltip: {
      trigger: "axis",
      valueFormatter: (value) => StringUtils.formatCurrency(value),
    },
    legend: {
      top: 10,
      data: [
        i18n.t(
          "apTemplate:BudgetComparisonGraph.plannedCosts",
          "Geplante Kosten"
        ),
        i18n.t(
          "apTemplate:BudgetComparisonGraph.actualCosts",
          "Tatsächliche Kosten"
        ),
      ],
    },
    grid: { top: 60, left: 40, right: 40, bottom: 20, containLabel: true },
    xAxis: {
      type: "category",
      data: data.map((e) => `${e.date.month}/${e.date.year}`),
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) => StringUtils.formatCurrency(value),
      },
    },
    series: [
      {
        name: i18n.t(
          "apTemplate:BudgetComparisonGraph.plannedCosts",
          "Geplante Kosten"
        ),
        data: data.map((e) => e.budgetAccumulatedAll),
        type: "line",
        smooth: true,
        markLine: {
          silent: true,
          symbol: ["none", "none"],
          lineStyle: {
            color: "#000",
          },
          label: {
            formatter: () => i18n.t("Global.Labels.Today", "Heute"),
            color: "#000",
          },
          data: [
            {
              xAxis: `${moment().month() + 1}/${moment().year()}`,
            },
          ],
        },
      },
      {
        name: i18n.t(
          "apTemplate:BudgetComparisonGraph.actualCosts",
          "Tatsächliche Kosten"
        ),
        data: data.map((e) => e.actualAccumulatedAll),
        type: "line",
        smooth: true,
      },
    ],
  };
};
