import classNames from "classnames";
import { useMemo } from "react";
import BFBreadcrumb, {
  BFBreadcrumbEntry,
} from "../../../abstract-ui/navigation/BFBreadcrumb/BFBreadcrumb";
import {
  DocumentStoreDirectory,
  DocumentStoreDocument,
  DocumentStoreProps,
} from "../../DSInterfaces";
import DSService from "../../DSService";
import DSDirectoryView from "../DSDocumentDirectoryView/DSDocumentDirectoryView";
import DSUnvalidDirectory from "../DSUnvalidDirectoryView/DSUnvalidDirectory";
import "./DSRootView.scss";

type DSRootViewProps = {
  rootDocumentDirectory: DocumentStoreDirectory;
  onClickBreadcrumb: (breadcrumb: BFBreadcrumbEntry) => void;
  onClickBack: () => void;
  onClickFolderEntry: (document: DocumentStoreDirectory) => void;
  unvalidPath?: string;
  currentDirectory?: DocumentStoreDirectory;
  directoryPathObjects: DocumentStoreDirectory[];
  renderPostfixElement?: (document: DocumentStoreDocument) => React.ReactNode;
  showArchivedDocuments?: boolean;
} & Omit<DocumentStoreProps, "rootDocumentDirectory">;

const DSRootView = (props: DSRootViewProps) => {
  const activePath =
    props.unvalidPath === undefined
      ? props.currentDirectory?.pathIdentifier
      : undefined;

  const documents = DSService.getDocumentsFromAsset(props.assetParams);
  const documentIds = documents
    .map(
      (document: DocumentStoreDocument) =>
        `[${document.linkToCdn}#${document.directoryId || "null"}`
    )
    .join(",");

  const directoryDocumentsMap = useMemo(() => {
    return DSService.getDirectoryDocumentMap(
      documents,
      props.rootDocumentDirectory
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.assetParams.asset]);

  return (
    <div
      className={classNames(
        "document-store-root",
        props.appearance || "default",
        { "content-scrollable": props.contentScrollable }
      )}
    >
      <div className="breadcrumb-header">
        <BFBreadcrumb
          entries={props.directoryPathObjects.map((e) => ({
            label: e.name,
            path: e.pathIdentifier,
            id: e.id,
          }))}
          activePath={activePath}
          onClickBreadcrumb={(breadcrumb) =>
            props.onClickBreadcrumb(breadcrumb)
          }
          prefixIcon={{ data: "folder-home-8", type: "color" }}
        />
      </div>
      {!props.unvalidPath && props.currentDirectory && (
        <>
          <DSDirectoryView
            renderPostfixElement={props.renderPostfixElement}
            documentDirectory={props.currentDirectory}
            documentDirectoryRoot={props.rootDocumentDirectory}
            documentsMap={directoryDocumentsMap}
            assetParams={props.assetParams}
            onClickFolderEntry={props.onClickFolderEntry}
            onClickBack={props.onClickBack}
            documentEntryActions={props.documentEntryActions}
            directoryEntryActions={props.directoryEntryActions}
            permissions={props.permissions}
          />
        </>
      )}
      {props.unvalidPath && <DSUnvalidDirectory path={props.unvalidPath} />}
    </div>
  );
};

export default DSRootView;
