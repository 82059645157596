import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { Drawer } from "rsuite";
import TableComponent, {
  TableComponentProps,
} from "../../configurable/components/TableComponent/TableComponent";
import DebugDataComponent from "../../debug/DebugDataComponent";
import { ExistentUploadEntry } from "../../modules/abstract-ui/forms/upload/BFUpload";
import { useDatabus, useViewportSelector } from "../../redux/hooks";
import DataBus from "../../services/DataBus";
import { DataBusSubKeys } from "../../utils/Constants";
import CDNLink from "../CDNLink/CDNLink";
import PDFViewer, { PDFHighlight } from "../PDFViewer/PDFViewer";
import "./OldListViewPDF.scss";

interface OldListViewPDFProps {
  listHeader?: React.ReactNode;
  highlights?: PDFHighlight[];

  emptyPdfText?: string;
  cdnData?: {
    filename: string;
    assetField: string;
    assetType: string;
    assetId: string;
    cdnId: string;
    cdnData: ExistentUploadEntry;
  };

  selected?: {
    asset: any;
    pinned?: boolean;
  };

  onEntryClick?: (
    asset: any,
    force?: boolean,
    ev?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClearSelection: () => void;

  selectionType?: "connected" | "highlight";
  params?: any;
  entryRenderer: (asset, params) => React.ReactNode;
  tableIdentifier: string;
  dataUrl: string;
  asPost?: boolean;
  tableProps?: Partial<TableComponentProps>;
  pdfHeader?: React.ReactNode;
  pdfFooter?: React.ReactNode;
}

export const checkListViewSplitView = (
  viewport: "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
) => {
  const splitView = !["sm", "xs", "md", "lg"].includes(viewport);
  return splitView;
};
const OldListViewPDF = (props: OldListViewPDFProps) => {
  const viewport = useViewportSelector();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  useDatabus(DataBusSubKeys.OPEN_PDF_VIEWER, () => {
    setDrawerOpen(true);
  });

  useEffect(() => {
    DataBus.emit(DataBusSubKeys.COLUMNS_UPDATED, {
      tableIdentifiers: [props.tableIdentifier],
    });
  }, [viewport]);
  const splitView = checkListViewSplitView(viewport);
  return (
    <div
      className={classNames("old-list-view-pdf", {
        "split-view": splitView,
        "drawer-view": !splitView,
      })}
    >
      <div className="form-wrapper">
        {props.listHeader && <div className="header">{props.listHeader}</div>}
        <div className="forms-table">
          <RAUploadIncomingInvoiceTableMemo splitView={splitView} {...props} />
        </div>
      </div>
      {splitView && (
        <div className="pdf-wrapper">
          {props.pdfHeader && (
            <div className="pdf-header">{props.pdfHeader}</div>
          )}
          <div className="pdf-card __card">
            <div className="scroll-wrapper">
              {props.cdnData && (
                <CDNLink
                  assetType={props.cdnData.assetType}
                  assetId={props.cdnData.assetId}
                  assetField={props.cdnData.assetField}
                  cdnId={props.cdnData.cdnId}
                  filename={props.cdnData.cdnData.filename}
                  fileKey={props.cdnData.cdnData.key}
                  hasFolderReadPermissions={true}
                  render={(url) => (
                    <PDFViewer
                      url={url}
                      highlights={props.highlights}
                      filename={props.cdnData.filename}
                    />
                  )}
                />
              )}
              {!props.cdnData && (
                <div className="empty-text-container">
                  <div className="message">
                    {props.emptyPdfText ||
                      "Bitte selektieren Sie ein Element, um das PDF-Dokument anzuzeigen"}
                  </div>
                </div>
              )}
            </div>
          </div>
          {props.pdfFooter && (
            <div className="pdf-footer">{props.pdfFooter}</div>
          )}
        </div>
      )}
      {!splitView && props.cdnData && (
        <Drawer
          className="list-view-pdf-drawer"
          size={"md"}
          placement={"right"}
          open={!!drawerOpen}
          onClose={() => setDrawerOpen(null)}
        >
          <Drawer.Header></Drawer.Header>
          <div className={`pdf-view-container`}>
            {props.cdnData && (
              <CDNLink
                assetType={props.cdnData.assetType}
                assetId={props.cdnData.assetId}
                assetField={props.cdnData.assetField}
                cdnId={props.cdnData.cdnId}
                filename={props.cdnData.cdnData.filename}
                fileKey={props.cdnData.cdnData.key}
                hasFolderReadPermissions={true}
                render={(url) => (
                  <PDFViewer
                    url={url}
                    highlights={props.highlights}
                    filename={props.cdnData.filename}
                  />
                )}
              />
            )}
          </div>
        </Drawer>
      )}
    </div>
  );
};
export default OldListViewPDF;

interface TableProps {
  selected?: {
    asset: any;
    pinned?: boolean;
  };
  selectionType?: "connected" | "highlight";

  onEntryClick?: (
    asset: any,
    force?: boolean,
    ev?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClearSelection: () => void;

  params?: any;
  entryRenderer: (asset, params) => React.ReactNode;
  tableIdentifier: string;
  dataUrl: string;
  asPost?: boolean;
  tableProps?: Partial<TableComponentProps>;
  splitView: boolean;
}
const RAUploadIncomingInvoiceTable = (props: TableProps) => {
  return (
    <TableComponent
      onScroll={(ev) => {
        const containerScrollTop = (ev.target as HTMLDivElement).scrollTop;
        const containerScrollHeight = (ev.target as HTMLDivElement)
          .clientHeight;

        const selected = (
          ev.target as HTMLDivElement
        ).querySelector<HTMLDivElement>(".list-view-pdf-entry.selected.pinned");

        if (selected) {
          if (containerScrollTop > selected.offsetTop) {
            selected.style.transform = `translateY(${
              containerScrollTop - selected.offsetTop
            }px)`;
          } else if (
            containerScrollTop + containerScrollHeight - 20 <
            selected.offsetTop + selected.clientHeight
          ) {
            selected.style.transform = `translateY(${
              containerScrollTop +
              containerScrollHeight -
              20 -
              (selected.offsetTop + selected.clientHeight)
            }px)`;
          } else {
            selected.style.transform = null;
          }
        }
      }}
      params={{
        selected: props.selected,
        onEntryClick: props.onEntryClick,
        onClearSelection: props.onClearSelection,
        ...props.params,
      }}
      post={props.asPost}
      initialReload={true}
      appearance={"clear"}
      ignoreInteractionHighlights={true}
      ignoreTableSelection={true}
      useEndlessScrolling={true}
      dataUrl={props.dataUrl}
      hideColumnHeaders={true}
      hideConfigMenu={true}
      identifier={props.tableIdentifier}
      hideSelectionControls={true}
      striped={false}
      columns={{
        column1: {
          orderIndex: 1,
          columnHeaderTextKey: "Application.Asset.Todo.Name",
          resizable: false,
          sortable: false,
          maxWidth: props.splitView ? 900 : undefined,
          // minWidth: !props.splitView ? 100 : undefined,
          convertParams: (params, asset) => ({
            ...params,
            selected: params.selected?.asset?._id === asset._id,
            pinned:
              params.selected?.asset?._id === asset._id &&
              params.selected?.pinned,
            onEntryClick: params.onEntryClick,
            onClearSelection: params.onClearSelection,
          }),
          cellRenderer: (row, key, columnConf, params) => (
            <ListViewPDFEntry
              selectionType={props.selectionType}
              id={`list-view-pdf_${props.tableIdentifier}_${row.original._id}`}
              asset={row.original}
              selected={params.selected}
              pinned={params.pinned}
              onEntryClick={params.onEntryClick}
              onClearSelection={params.onClearSelection}
            >
              {props.entryRenderer(row.original, params)}
            </ListViewPDFEntry>
          ),
        },
        ...(props.splitView
          ? {
              columnEmpty: {
                orderIndex: 2,
                columnHeaderTextKey: "Application.Asset.Todo.Name",
                resizable: false,
                sortable: false,
                cellRenderer: (row, key, columnConf, params) => (
                  <div className="empty"></div>
                ),
              },
            }
          : {}),
      }}
      {...(props.tableProps || {})}
    />
  );
};
const RAUploadIncomingInvoiceTableMemo = React.memo(
  RAUploadIncomingInvoiceTable
);

interface ListViewPDFEntryProps {
  id: string;
  asset: any;
  selected: boolean;
  pinned: boolean;
  onEntryClick?: (
    asset: any,
    force?: boolean,
    ev?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClearSelection: () => void;
  children?: React.ReactNode;
  selectionType?: "connected" | "highlight";
}
const ListViewPDFEntry = (props: ListViewPDFEntryProps) => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (props.selected && props.asset?._deleted) {
      props.onClearSelection();
    }
    if (props.selected && props.pinned) {
      const scrollbox = ref.current.parentNode.parentNode.parentNode.parentNode;
      const containerScrollTop = (scrollbox as HTMLDivElement).scrollTop;
      const containerScrollHeight = (scrollbox as HTMLDivElement).clientHeight;
      if (containerScrollTop > ref.current.offsetTop) {
        ref.current.style.transform = `translateY(${
          containerScrollTop - ref.current.offsetTop
        }px)`;
      } else if (
        containerScrollTop + containerScrollHeight - 20 <
        ref.current.offsetTop + ref.current.clientHeight
      ) {
        ref.current.style.transform = `translateY(${
          containerScrollTop +
          containerScrollHeight -
          20 -
          (ref.current.offsetTop + ref.current.clientHeight)
        }px)`;
      } else {
        ref.current.style.transform = null;
      }
    } else {
      ref.current.style.transform = null;
    }
  }, [props.pinned, props.selected, props.asset]);

  // removed due to the fact that the scrolling is annoying
  // useEffect(() => {
  //   if (props.selected) {
  //     ref.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "nearest",
  //     });
  //   }
  // }, [props.selected]);

  return (
    <div
      id={props.id}
      ref={ref}
      className={`list-view-pdf-entry __card ${
        props.selected ? "selected" : ""
      } ${props.pinned ? "pinned" : ""} type-${
        props.selectionType || "connected"
      }`}
      onClick={(ev) => {
        if (!props.selected && props.onEntryClick) {
          props.onEntryClick(props.asset, false, ev);
        }
      }}
    >
      <DebugDataComponent data={props.asset} />
      {props.children}
      {(props.selectionType || "connected") === "connected" &&
        props.selected && <div className="transition-to-pdf"></div>}
    </div>
  );
};
