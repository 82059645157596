import { IComponent } from "./../../../configurable/layouts/IComponent";

export const LayoutUserprofile: IComponent = {
  _component: "NavLayout",
  identifier: "profileModal",
  initialPage: "profile",
  appearance: "tabs",
  hideTextBreakpoint: 800,
  routeInterception:
    "${location}.split('__userprofile')[0] + '__userprofile/' + ${routeTo} ",
  subPages: {
    profile: {
      orderIndex: 0,
      textKey: "UserModal.Profile.Title",
      icon: {
        type: "bf",
        data: "single-neutral",
      },
      component: {
        _component: "Div",
        style: {
          paddingLeft: 10,
        },
        components: {
          section1: {
            _component: "CardSection",
            titleKey: "UserModal.Profile.Title",
            contentComponents: {
              formElement: {
                _component: "form",
                oType: "user",
                formSelector: "selfProfile",
                _formData: "#{userProps}",
                submitUrl: "'user'",
                submitMethod: "'patch'",
                identifier: "self-profile-form",
                handleErrorMessaging: true,
                onSubmitSuccess: [
                  {
                    eventKey: "'SET_USER_DATA'",
                    data: '{"user": ${value}}',
                  },
                  {
                    eventKey: "'TOAST'",
                    data: {
                      type: "success",
                      textKey: "UserModal.Profile.SuccessfullyUpdated",
                      duration: 3500,
                    },
                  },
                ],
                onSubmitError: [],
              },
            },
            postHeaderComponents: {
              a1: {
                _component: "action",
                actionId: "selfProfileFormPurpose-edit",
                appearance: "primary",
                style: {
                  marginTop: 5,
                  marginBottom: 5,
                  marginRight: 5,
                },
                icon: {
                  data: "pencil-write-1",
                  type: "bf",
                  style: {
                    fontSize: "1.6em",
                  },
                },
              },
            },
            footerComponents: {
              fill: {
                _component: "fill",
              },
              a3: {
                _component: "action",
                actionId: "selfProfileFormPurpose-abort",
                textKey: "Global.Buttons.cancel",
                iconPosition: "left",
                appearance: "primary",
                style: {
                  marginTop: 5,
                  marginBottom: 5,
                  marginRight: 5,
                },
                icon: {
                  data: "close",
                  style: {
                    marginRight: 10,
                  },
                },
              },
              a4: {
                _component: "action",
                actionId: "selfProfileFormPurpose-submit",
                textKey: "Global.Buttons.submit",
                iconPosition: "left",
                appearance: "primary",
                style: {
                  marginTop: 5,
                  marginBottom: 5,
                  marginRight: 5,
                },
                icon: {
                  data: "save",
                  style: {
                    marginRight: 10,
                  },
                },
              },
            },
          },
          section2: {
            _component: "CardSection",
            titleKey: "UserModal.Profile.Public.Title",
            contentComponents: {
              formElement: {
                _component: "form",
                oType: "user",
                formSelector: "userPublic",
                _formData: "#{userProps}",
                identifier: "user-form",
              },
            },
            postHeaderComponents: {
              a1: {
                _component: "action",
                actionId: "userPublicDataFormPurpose-edit",
                appearance: "primary",
                style: {
                  marginTop: 5,
                  marginBottom: 5,
                  marginRight: 5,
                },
                icon: {
                  data: "pencil-write-1",
                  type: "bf",
                  style: {
                    fontSize: "1.6em",
                  },
                },
              },
            },
            footerComponents: {
              fill: {
                _component: "fill",
              },
              a3: {
                _component: "action",
                actionId: "userPublicDataFormPurpose-abort",
                textKey: "Global.Buttons.cancel",
                iconPosition: "left",
                appearance: "primary",
                style: {
                  marginTop: 5,
                  marginBottom: 5,
                  marginRight: 5,
                },
                icon: {
                  data: "close",
                  style: {
                    marginRight: 10,
                  },
                },
              },
              a4: {
                _component: "action",
                actionId: "userPublicDataFormPurpose-submit",
                textKey: "Global.Buttons.submit",
                iconPosition: "left",
                appearance: "primary",
                style: {
                  marginTop: 5,
                  marginBottom: 5,
                  marginRight: 5,
                },
                icon: {
                  data: "save",
                  style: {
                    marginRight: 10,
                  },
                },
              },
            },
          },
        },
      },
    },
    account: {
      orderIndex: 1,
      textKey: "UserModal.Settings.Account",
      icon: {
        type: "bf",
        data: "shield-key",
      },
      component: {
        _component: "Div",
        style: {
          paddingLeft: 10,
        },
        components: {
          comp: {
            _component: "CardSection",
            titleKey: "UserModal.Settings.Account",
            contentComponents: {
              com: {
                _component: "div",
                components: {
                  a: {
                    _component: "simpleText",
                    textKey: "UserModal.Settings.AccountSetPassword",
                    type: "h5",
                  },
                  setPassword: {
                    _component: "div",
                    style: [
                      {
                        paddingRight: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        justifyContent: "center",
                      },
                    ],
                    components: {
                      formElement: {
                        _component: "form",
                        oType: "user",
                        formSelector: "setOwnPassword",
                        identifier: "user-set-password",
                        stateSubscriptions: ["userProps"],
                        actionIdMapping: {
                          submit: "userSetOwnPassword-submit",
                        },
                        submitUrl:
                          "'/user/' + #{userProps}._id + '/setNewPassword'",
                        submitMethod: "'post'",
                        onSubmitSuccess: {
                          a1: {
                            eventKey: "'TOAST'",
                            data: {
                              type: "success",
                              textKey: "UserService.PasswordChanged",
                              duration: 3500,
                            },
                          },
                          a2: {
                            eventKey: "'CLOSE_MODAL'",
                            data: {
                              identifier: "__setMyPassword",
                            },
                          },
                        },
                      },
                      footer: {
                        _component: "div",
                        style: {
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 20,
                        },
                        components: {
                          addAction: {
                            _component: "action",
                            actionId: "userSetOwnPassword-submit",
                            orderIndex: 2,
                            textKey: "UserService.SetPassword.submit",
                            appearance: "default",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    organization: {
      orderIndex: 2,
      textKey: "UserModal.Organization.Title",
      icon: {
        type: "bf",
        data: "building-1",
      },
      component: {
        _component: "div",
        style: {
          paddingLeft: 10,
        },
        components: {
          section1: {
            _component: "CardSection",
            titleKey: "UserModal.Organization.Title",
            contentComponents: {
              formElement: {
                _component: "form",
                oType: "user",
                formSelector: "organizationView",
                _formData: "#{mandator}",
                submitUrl: "'user'",
                submitMethod: "'patch'",
                identifier: "organization-form",
                handleErrorMessaging: true,
                onSubmitSuccess: [
                  {
                    eventKey: "'SET_ORGANIZATION_DATA'",
                    data: '{"orga": ${value}}',
                  },
                  {
                    eventKey: "'TOAST'",
                    data: {
                      type: "success",
                      textKey: "UserModal.Organization.SuccessfullyUpdated",
                      duration: 3500,
                    },
                  },
                ],
                onSubmitError: [],
                stateSubscriptions: ["mandator"],
              },
            },
          },
        },
      },
    },
    devices: {
      orderIndex: 2,
      textKey: "UserModal.Devices.Title",
      icon: {
        type: "bf",
        data: "mobile-phone-1",
      },
      component: {
        _component: "Div",
        style: {
          paddingLeft: 10,
        },
        components: {
          a: {
            _component: "userManagementTrustedDevices",
          },
        },
      },
    },
    settings: {
      orderIndex: 3,
      textKey: "UserModal.Settings.Title",
      icon: {
        type: "bf",
        data: "cog-1",
      },
      component: {
        _component: "Div",
        style: {
          paddingLeft: 10,
        },
        components: {
          a: {
            _component: "simpleText",
            text: "settings",
          },
        },
      },
    },
    history: {
      orderIndex: 3,
      textKey: "UserModal.History.Title",
      icon: {
        type: "bf",
        data: "time-clock-circle",
      },
      component: {
        _component: "Div",
        style: {
          height: "100%",
        },
        components: {
          logContent: {
            _component: "log",
            className: "log-page",
            targetID: "#{userProps}._id",
            identifier: "profileHistoryLog",
            oType: ["user"],
            style: {
              height: "100%",
            },
          },
        },
      },
    },
  },
};
const colorPalette = [
  "#009fe3",

  "#007d46",
  "#014386",
  "#a2c612",
  "#b53161",
  "#fdc300",
  "#555555",
  "#e84030",
  "#5bc7f1",
  "#60aa87",
  "#6980b4",
  "#ccdb85",
  "#cd889a",
  "#ffdb83",
  "#a0a0a0",
  "#f3977d",
  "#025d6c",
  "#44bace",
  "#a54004",
  "#f5a372",
  "#a92424",
  "#e87070",
  "#0faab3",
  "#76d3d8",
];
// var colorPalette = ["#c12e34", "#e6b600", "#0098d9", "#2b821d", "#005eaa", "#339ca8", "#cda819", "#32a487"];

export const defaultChartConfiguration = {
  color: colorPalette,

  line: {
    lineStyle: {
      width: 2,
    },
  },
};
