import StringUtils from "@/utils/StringUtils";
import { ValidatorPopoverStyle } from "../../general/ValidationPopover/ValidationPopover";
import "./BFCurrency.scss";
import BFCurrencySelect from "./BFCurrencySelect";
import BFInput from "./BFInput";

export type CurrencyValue = {
  amount: number;
  currency: string;
};
export type CurrencyValueWithConversion = {
  amount: number;
  currency: string;
  converted: {
    amount: number;
    currency: string;
    conversionDate: Date;
    conversionRate: number;
  };
};
export const getCurrencyValueOf = (value: CurrencyValueWithConversion) => {
  return {
    amount: value?.amount,
    currency: value?.currency || StringUtils.getCurrency(),
  };
};
interface BFCurrencyProps {
  favoriteCurrencies?: string[];
  label?: string;
  value: CurrencyValue;
  onBlur?: () => void;
  onFocus?: () => void;
  disabled?: boolean;

  readonlyAmountField?: boolean;
  hint?: string;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;

  onChange: (value: CurrencyValue) => void;
}
const BFCurrency = (props: BFCurrencyProps) => {
  return (
    <BFInput
      validation={props.validation}
      validatorStyle={props.validatorStyle}
      label={props.label}
      type={"priceInput"}
      value={props.value?.amount}
      onChange={(value: number) => {
        props.onChange({
          ...props.value,
          amount: value,
        });
      }}
      disabled={props.disabled}
      readonly={props.readonlyAmountField}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      textAlign="right"
      hint={props.hint}
      removeSuffixPadding
      suffix={
        <BFCurrencySelect
          disabled={props.disabled}
          onChange={(currency) => props.onChange({ ...props.value, currency })}
          value={props.value?.currency}
          favoriteCurrencies={props.favoriteCurrencies}
        />
      }
    />
  );
};

export default BFCurrency;
