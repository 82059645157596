export interface IComponent {
	readonly _component: string;
	readonly orderIndex?: number;

	readonly [key: string]: any;
}

export const instanceOfIComponent = (object: any): object is IComponent => {
	return "_component" in object;
};
