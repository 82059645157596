import classNames from "classnames";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Tag } from "rsuite";
import ListComponent from "../../../../configurable/components/ListComponent/ListComponent";
import i18n from "../../../../i18n";
import InfiniteTable from "../../../../redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "../../../../redux/hooks";
import { MatchQuery } from "../../../../services/DataService";
import LabeledInput from "../../../abstract-ui/forms/LabeledInput";
import BFInput from "../../../abstract-ui/forms/input/BFInput";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../../abstract-ui/general/ValidationPopover/ValidationPopover";
import BfIcon, { BfIconProps } from "../../../abstract-ui/icon/BfIcon";
import AssetLoader from "./../../../../components/AssetLoader/AssetLoader";
import "./EZSelectQuery.scss";

export type AssetSelectStyle = "default" | "bf";
interface EZSelectQueryProps {
  identifier: string;
  className?: string;
  assetType: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: (ev, value: string) => void;
  error?: string;
  disabled?: boolean;
  matchQuery?: MatchQuery;
  renderValue: (value: any) => React.ReactNode;
  renderMenu?: (value: any) => React.ReactNode;
  prefixIcon?: BfIconProps;

  label?: string;
  labelPosition?: "top" | "left";
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  appearance?: AssetSelectStyle;
  readonly?: boolean;
}
const EZSelectQuery = (props: EZSelectQueryProps) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const tableCache = useTypedSelector(
    (state) => state.application.infiniteTables[props.identifier]
  );
  const onSelect = (id) => {};

  const renderPrefixIcon = () => {
    return (
      <>
        {props.prefixIcon && (
          <div className={`prefix-icon`}>
            <BfIcon {...props.prefixIcon} />
          </div>
        )}
      </>
    );
  };

  const renderFieldValue = () => {
    return (
      <>
        {props.value && (
          <AssetLoader
            assetType={props.assetType}
            id={props.value}
            render={(asset) => (
              <div className={`label`}>{props.renderValue(asset)}</div>
            )}
            placeholderProps={{ width: 50 }}
          />
        )}
        {!props.value && <div className={`label`}>-</div>}
      </>
    );
  };

  if (props.readonly) {
    return (
      <div
        className={classNames(
          `ez-select-query`,
          props.className,
          `appearance-${props.appearance || "default"}`
        )}
      >
        <Tag className={`readonly-tag`} size="lg">
          <div className={`render-value`}>
            {renderPrefixIcon()}
            {renderFieldValue()}
          </div>
        </Tag>
      </div>
    );
  }

  return (
    <LabeledInput
      label={props.label}
      labelPosition={props.labelPosition}
      error={!!props.validation?.message}
      className="ez-select-query-container"
    >
      <ValidationPopover
        validatorStyle={props.validatorStyle}
        level={props.validation ? props.validation.level : "error"}
        message={props.validation ? props.validation.message : null}
        marginTop={0}
      >
        <div
          className={classNames(
            `ez-select-query`,
            props.className,
            `appearance-${props.appearance || "default"}`
          )}
        >
          <Dropdown
            disabled={props.disabled}
            ref={ref}
            className="select-dropdown"
            title={
              <div className={`render-value`}>
                {props.prefixIcon && (
                  <div className={`prefix-icon`}>
                    <BfIcon {...props.prefixIcon} />
                  </div>
                )}
                {props.value && (
                  <AssetLoader
                    assetType={props.assetType}
                    id={props.value}
                    render={(asset) => (
                      <div className={`label`}>{props.renderValue(asset)}</div>
                    )}
                    placeholderProps={{ width: 50 }}
                  />
                )}
                {!props.value && <div className={`label`}>-</div>}
              </div>
            }
          >
            <Dropdown.Item panel>
              <div
                className={`select-dropdown-content`}
                style={{ height: 400 }}
              >
                <div className={`search`}>
                  <BFInput
                    appearance="clear"
                    placeholder={i18n.t("Global.Search")}
                    prefix={<BfIcon type="light" data="search" size="xs" />}
                    value={tableCache?.searchTerm || ""}
                    onChange={(value: string) => {
                      dispatch(
                        InfiniteTable.setSearch(props.identifier, value)
                      );
                    }}
                  />
                </div>
                <div className={`select-content`}>
                  <ListComponent
                    dataUrl={`/api/asset/list/${props.assetType}`}
                    asPost
                    additionalMatchQuery={props.matchQuery}
                    identifier={props.identifier}
                    params={{ onSelect, selected: props.value }}
                    render={(item, index, params) => {
                      return (
                        <button
                          type="button"
                          className={classNames(`selection-item`, {
                            selected: params.selected === item._id,
                          })}
                          onClick={(ev) => {
                            props.onChange(item._id);
                            props.onBlur && props.onBlur(ev, item._id);
                          }}
                        >
                          <div className={`label`}>
                            {props.renderMenu
                              ? props.renderMenu(item)
                              : props.renderValue(item)}
                          </div>
                        </button>
                      );
                    }}
                  />
                </div>
              </div>
            </Dropdown.Item>
          </Dropdown>
        </div>
      </ValidationPopover>
    </LabeledInput>
  );
};

export default EZSelectQuery;
