import Helmet from "react-helmet";
import { connect } from "react-redux";
import Log from "../../debug/Log";
import { Application } from "../../model/db/Application";
import {
  setActiveApplication,
  setUiConfig,
} from "../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import DataBus from "../../services/DataBus";
import { AbstractComponent } from "../../utils/abstracts/AbstractComponent";
import LoadMask from "../LoadMask/LoadMask";
import { clearAllCaches } from "./../../redux/actions/application/application-actions";
import "./ApplicationContainer.scss";

type Props = {
  clearAllCaches: () => void;
  setUiConfig: (key: string, value: any) => void;
  setActiveApplication: (appConfig: Application) => void;
  applicationConfig: Application;
  application: Application;
};
type States = {};

class ApplicationContainer extends AbstractComponent<Props, States> {
  componentDidMount(): void {
    Log.info(
      "ApplicationContainer.componentDidMount",
      this.props.applicationConfig._id
    );
    this.props.setUiConfig(DefaultUIConfigs.HEADER_APP_DRAWER, true);
    this.props.setActiveApplication(this.props.applicationConfig);
    setTimeout(() => {
      DataBus.emit("__APP", this.props.applicationConfig, true);
    });
  }

  componentWillUnmount(): void {
    super.componentWillUnmount();
    Log.info(
      "ApplicationContainer.componentWillUnmount",
      this.props.applicationConfig._id
    );
    this.props.setUiConfig(DefaultUIConfigs.HEADER_APP_DRAWER, false);
    this.props.setActiveApplication(null);
    this.props.clearAllCaches();
  }

  render() {
    if (
      !this.props.application ||
      this.props.application._id !== this.props.applicationConfig._id
    ) {
      return <LoadMask />;
    }
    return (
      <div className="view-application-container">
        <Helmet>
          <title>{this.props.applicationConfig.displayName}</title>
        </Helmet>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user,
  application: state.uiConfig.activeApplication,
});

export default connect(mapStateToProps, {
  setUiConfig,
  setActiveApplication,
  clearAllCaches,
})(ApplicationContainer) as any;
