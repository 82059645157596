import { useState } from "react";
import BFButton from "../abstract-ui/general/Button/BFButton";
import { CashBudgetValueCell } from "./CashBudgetCells";
import {
  CashBudgetColumnConfig,
  CashBudgetRowConfig,
  ValueData,
} from "./CashBudgetTable";

interface Props {
  mobileView: boolean;
  rowHeight: number;
  data: CashBudgetColumnConfig;
  activeMobileComparisonType?: number;
  elRefs: any;
  rowConfiguration: CashBudgetRowConfig[];
  onCellDblClick?: (index: number, item: any) => void;
  onCellClick: (
    rowIndex: number,
    cmdModifier: boolean,
    shiftModifier: boolean,
    valueData: ValueData,
    columnIndexAdd: number
  ) => void;
  selectedValues: [number, number][];
  columnIndex: number;
  collapsedEntries: string[];
  columnWidth?: number;
}

const reduceToAllValues = (
  data: (null | ValueData)[],
  collapsedEntries: string[],
  depth = 0
): (null | ({ depth: number } & ValueData))[] => {
  const ret = data.reduce(
    (prev, cur) => [
      ...prev,
      { ...cur, depth },
      ...(cur &&
      cur.subValues &&
      collapsedEntries.find((e) => e === cur.subValuesIdent)
        ? reduceToAllValues(cur.subValues, collapsedEntries, depth + 1)
        : []),
    ],
    []
  );
  return ret;
};

const reduceToAllValuesComparison = (
  data: (null | ValueData)[][],
  collapsedEntries: string[],
  depth = 0
): (null | ({ depth: number } & ValueData))[][] => {
  const reduced = data.reduce(
    (prev, cur) => [
      ...prev,
      cur.map((e) => ({ ...e, depth })),
      ...(cur &&
      cur[0] &&
      cur[0].subValues &&
      collapsedEntries.find((e) => e === cur[0].subValuesIdent)
        ? reduceToAllValuesComparison(
            cur[0]?.subValues.map((e, i) =>
              cur.map((entry) => entry.subValues[i])
            ),
            collapsedEntries,
            depth + 1
          )
        : []),
    ],
    []
  ) as (null | ({ depth: number } & ValueData))[][];
  return reduced;
};

const CashBudgetColumnData = (props: Props) => {
  const [selectedRow, setSelectedRow] = useState(
    props.data.subHeaders?.[props.data.subHeaders?.length - 1]
  );
  let comparisonActive = props.data.type === "comparison";

  return (
    <div
      id={props.data.id}
      className={`content-entry ${comparisonActive ? "comparison" : ""} ${
        props.data.highlight ? "highlight-" + props.data.highlight : ""
      } ${props.data.showEndDivider ? "end-of-week" : ""}`}
      style={{
        width:
          (props.data?.subHeaders?.length || 1) * (props.columnWidth || 200),
      }}
    >
      <div
        style={{ height: props.rowHeight * (comparisonActive ? 2 : 3) }}
        className={`head`}
      >
        <div className={`date-header`}>
          <div className="infos">
            {props.data.topHeaderTextLeft && (
              <div className={`left`}>{props.data.topHeaderTextLeft}</div>
            )}
            <div className="fill" />
            {props.data.topHeaderTextCenter && (
              <div className={`center`}>{props.data.topHeaderTextCenter}</div>
            )}
            <div className="fill" />
            {props.data.topHeaderTextRight && (
              <div className="right">{props.data.topHeaderTextRight}</div>
            )}
          </div>
          <div className={`title`}>{props.data.headerText}</div>
        </div>
      </div>

      {comparisonActive ? (
        <div className={`subheader`} style={{ height: props.rowHeight * 1 }}>
          {props.data.subHeaders.map((entry) =>
            props.mobileView ? (
              <BFButton
                type="button"
                key={entry}
                appearance={entry === selectedRow ? "primary" : "default"}
                onClick={() => setSelectedRow(entry)}
              >
                {entry}
              </BFButton>
            ) : (
              <div key={entry} className={`cell`}>
                {entry}
              </div>
            )
          )}
        </div>
      ) : null}

      <div className={`row-container`}>
        {comparisonActive ? (
          props.data.subHeaders.map((subHeader, subHeaderIndex) => {
            if (props.mobileView && subHeader !== selectedRow) {
              return null;
            }
            return (
              <div
                key={`subheader-${subHeaderIndex}`}
                className={`row-wrapper ${
                  props.activeMobileComparisonType === subHeaderIndex
                    ? "active"
                    : ""
                }`}
              >
                {reduceToAllValuesComparison(
                  props.data.values as ValueData[][],
                  props.collapsedEntries
                ).map((entry, index, arr) => (
                  <ValueCell
                    {...props}
                    depth={entry[subHeaderIndex]?.depth}
                    nextDepth={arr[index + 1]?.[subHeaderIndex].depth}
                    key={index}
                    selected={props.selectedValues.some(
                      ([column, row]) =>
                        column === props.columnIndex + subHeaderIndex &&
                        row === index
                    )}
                    entry={entry[subHeaderIndex]}
                    cellBackground={entry[subHeaderIndex]?.cellBackground}
                    index={index}
                    columnIndexAdd={subHeaderIndex}
                    rowIdentString={props.rowConfiguration[index]?.title}
                  />
                ))}
              </div>
            );
          })
        ) : (
          <div className={`row-wrapper active`}>
            {reduceToAllValues(
              props.data.values as ValueData[],
              props.collapsedEntries
            ).map((entry, index, arr) => (
              <ValueCell
                {...props}
                key={index}
                depth={entry.depth}
                nextDepth={arr[index + 1]?.depth}
                columnIndexAdd={0}
                selected={props.selectedValues.some(
                  ([column, row]) =>
                    column === props.columnIndex && row === index
                )}
                entry={entry}
                index={index}
                cellBackground={entry?.cellBackground}
                rowIdentString={props.rowConfiguration[index]?.title}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const ValueCell = (
  props: Props & {
    entry: ValueData;
    index: number;
    rowIdentString: string;
    selected?: boolean;
    cellBackground?: string;
    columnIndexAdd: number;
    depth: number;
    nextDepth?: number;
  }
) => (
  <CashBudgetValueCell
    onChange={props.entry.onChange}
    renderLeft={props.entry.renderLeft}
    depth={props.depth}
    nextDepth={props.nextDepth}
    cellBackground={props.cellBackground}
    progress={props.entry?.progress}
    overdoneClass={props.entry?.overdoneClass}
    renderOnHover={props.entry?.renderOnHover}
    selected={props.selected}
    className={props.entry?.className}
    rowIdentString={props.rowIdentString}
    text={props.entry?.text}
    onHoverEnterFunction={(rowIndex) =>
      props.elRefs.current?.[rowIndex]?.classList.add("hover")
    }
    onHoverLeaveFunction={(rowIndex) =>
      props.elRefs.current?.[rowIndex]?.classList.remove("hover")
    }
    onCellClick={
      props.entry?.value !== null
        ? (cmdModifier, shiftModifier) =>
            props.onCellClick(
              props.index,
              cmdModifier,
              shiftModifier,
              props.entry,
              props.columnIndexAdd
            )
        : undefined
    }
    onCellDblClick={
      props.entry?.clickable && props.onCellDblClick
        ? (rowIndex) => props.onCellDblClick(rowIndex, props.entry?.clickData)
        : undefined
    }
    rowIndex={props.index}
    rowHeight={props.rowHeight}
    value={props.entry?.value}
    opacity={props.entry?.opacity}
    bold={props.entry?.bold || props.rowConfiguration[props.index]?.bold}
    topBorder={props.rowConfiguration[props.index]?.borderTop}
    isNegative={props.entry?.value < 0}
    key={props.index}
    isSubHead={props.rowConfiguration[props.index]?.isSubHead}
  />
);
export default CashBudgetColumnData;
