import TextEditor from "@/modules/ckeditor/TextEditor";
import classNames from "classnames";
import React from "react";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import LabeledInput from "../LabeledInput";
import "./BFTexteditor.scss";

interface BFTexteditorProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  labelSuffix?: React.ReactNode;
  labelPosition?: "top" | "left";
  info?: React.ReactNode;
  placeholder?: string;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  maxHeight?: number | string;
  balloon?: boolean;
}
const BFTexteditor = (props: BFTexteditorProps) => {
  return (
    <LabeledInput
      label={props.label}
      info={props.info}
      labelPosition={props.labelPosition}
      suffix={props.labelSuffix}
      error={!!props.validation?.message}
    >
      <ValidationPopover
        validatorStyle={props.validatorStyle}
        level={props.validation ? props.validation.level : "error"}
        message={props.validation ? props.validation.message : null}
        marginTop={0}
      >
        <div
          className={classNames(`bf-text-editor`)}
          style={{ maxHeight: props.maxHeight }}
        >
          <TextEditor
            balloon={props.balloon}
            placeholder={props.placeholder}
            onChange={(value) => props.onChange(value)}
            value={props.value}
          />
        </div>
      </ValidationPopover>
    </LabeledInput>
  );
};

export default BFTexteditor;
