import classNames from "classnames";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import BFContextContainer, {
  useContextContainer,
} from "../../../components/ContextMenuComponent/BFContextContainer";
import DebugDataComponent from "../../../debug/DebugDataComponent";
import BFButton from "../../../modules/abstract-ui/general/Button/BFButton";
import BFDropdownContent, {
  DropdownItem,
} from "../../../modules/abstract-ui/general/Dropdown/BFDropdownContent";
import BFOverlay from "../../../modules/abstract-ui/general/whisper/BFOverlay";
import DataBus from "../../../services/DataBus";
import "./ListEntryComponent.scss";

interface ListEntryComponentProps<T> {
  renderEntry: () => JSX.Element;
  onClickEntry?: (ev) => void;
  isSelected?: boolean;
  indicatorProps?: {
    showIndicator: boolean;
    indicatorText: string | React.ReactNode;
    indicatorOutlined?: boolean;
    onClickIndicator?: () => void;
  };
  showDebugToggle?: boolean;
  entryData: any;
  entryActions?: DropdownItem[];
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  className?: string;
  disableContextMenu?: boolean;
  disableEntryClick?: boolean;
}

const ListEntryComponent = (props: ListEntryComponentProps<any>) => {
  const { position, onClose, onContextClick } = useContextContainer();
  const [overlayId] = useState(`LIST_ENTRY_ACTION_OVERLAY_${nanoid()}`);

  const renderEntryActions = () => {
    if ((props.entryActions || []).length > 0) {
      return (
        <BFDropdownContent
          contextItem={props.entryData}
          items={props.entryActions}
          onClose={() => {
            DataBus.emit("WHISPER", {
              identifier: overlayId,
              type: "CLOSE",
            });
          }}
        />
      );
    } else {
      return null;
    }
  };

  const allowContextMenu =
    !props.disableContextMenu && (props.entryActions || []).length > 0;
  const renderIndicator = () => {
    if (!props.indicatorProps?.showIndicator) {
      return null;
    }

    return (
      <div
        className={classNames(`list-entry-indicator-container`, {
          outlined: props.indicatorProps.indicatorOutlined,
        })}
      >
        <BFButton
          disabled={props.disableEntryClick}
          tooltip={{
            delay: 200,
            tooltip: props.indicatorProps.indicatorText,
          }}
          tabIndex={-1}
          onClick={(event) => {
            event.stopPropagation();
            if (props.disableEntryClick) {
              return;
            }
            if (props.indicatorProps.onClickIndicator) {
              props.indicatorProps.onClickIndicator();
            }
          }}
          className={`list-entry-indicator`}
        ></BFButton>
      </div>
    );
  };

  return (
    <div className={classNames("list-entry-component", props.className)}>
      {props.showDebugToggle && <DebugDataComponent data={props.entryData} />}

      {props.prefix && <div className={`prefix`}>{props.prefix}</div>}
      {allowContextMenu && (
        <BFContextContainer position={position} onClose={onClose}>
          {renderEntryActions()}
        </BFContextContainer>
      )}
      <BFButton
        onContextMenu={(ev) => {
          if (allowContextMenu) {
            onContextClick(ev);
          }
        }}
        className={classNames(
          "list-entry-component-button",
          {
            selected: props.isSelected,
          },
          {
            "no-indicator": !props.indicatorProps?.showIndicator,
            "with-prefix": props.prefix,
          }
        )}
        onClick={(ev) => {
          if (props.onClickEntry) {
            props.onClickEntry(ev);
          }
        }}
      >
        {renderIndicator()}
        {props.renderEntry()}
      </BFButton>
      {(props.entryActions || []).length > 0 && (
        <BFOverlay
          className="list-entry-action-overlay"
          identifier={overlayId}
          placement="autoVerticalEnd"
          trigger="click"
          zIndex={1200}
          speaker={renderEntryActions()}
        >
          <div className="entry-actions-trigger">
            <BFButton
              onClick={(event) => {}}
              icon={{
                type: "light",
                data: "navigation-menu-vertical",
                size: "xs",
              }}
            />
          </div>
        </BFOverlay>
      )}
      {props.postfix && <div className={`postfix`}>{props.postfix}</div>}
    </div>
  );
};

export default ListEntryComponent;
