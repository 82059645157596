import classNames from "classnames";
import APUnassignedMessageDetails from "../../../../../../apps/tatar/activityApp/views/unassignedMessages/APUnassignedMessageDetails";
import { MailIncomingComment } from "../../../../../comments-module/Comments";
import { Resubmission } from "../../../../RSInterfaces";
import RSAssetDetails from "../../../asset-details/RSAssetDetails";

interface RSPreviewUnassignedMailProps {
  resubmission: Resubmission;
}

const RSPreviewUnassignedMail = (props: RSPreviewUnassignedMailProps) => {
  return (
    <div className={classNames("rs-preview-unassinged-mail")}>
      <RSAssetDetails
        assetParams={props.resubmission.data.linkedAsset}
        renderAssetData={(asset: MailIncomingComment) => {
          return (
            <APUnassignedMessageDetails
              mail={asset}
              showAsDrawer={false}
              restrictFullscreen={true}
              hideResubmissionActionForMail={true}
              hideAllActions={true}
            />
          );
        }}
      />
    </div>
  );
};

export default RSPreviewUnassignedMail;
