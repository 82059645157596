import AssetLoader from "../../../components/AssetLoader/AssetLoader";
import i18n from "../../../i18n";
import BaseAsset from "../../../model/general-assets/BaseAsset";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import { DocumentStoreAssetParams } from "../DSInterfaces";
import DocumentStoreManaged from "../DocumentStoreManaged";
import "./AssetDocumentsView.scss";

export const AssetDocumentsView = (props: {
  assetId: string;
  assetType: string;
  documentsFieldPath: string;
  onClose: () => void;
}) => {
  // const rootDirectory = DSService.getDirectoryConfigurationForAsset(
  //   asset.data.type,
  //   AssetTypes.Rental.RentalOpos,
  //   CB_RENTAL_OPOS_DOCUMENTS_FIELD_PATH
  // );

  // const { openResubmissionBtnIconProps, openResubmissionDialog } =
  //   useOpenResubmissionForm();

  return (
    <div className="asset-document-store">
      <div className={`content`}>
        <AssetLoader
          assetType={props.assetType}
          id={props.assetId}
          render={(asset: BaseAsset) => {
            const assetParams: DocumentStoreAssetParams = {
              asset: asset,
              assetType: props.assetType,
              type: asset?.["data"]?.["type"],
              documentsFieldPath: props.documentsFieldPath,
            };
            return (
              <DocumentStoreManaged
                appearance="clean"
                contentScrollable
                assetParams={assetParams}
                // rootDocumentDirectory={rootDirectory}
              />
            );
          }}
        />
      </div>
      <div className={`actions`}>
        <BFButton appearance="outline" onClick={props.onClose}>
          {i18n.t("Global.Buttons.close")}
        </BFButton>
      </div>
    </div>
  );
};
