import { CB_PORTFOLIO_RENTAL_UNIT_TECHNICAL_UNIT_IDENTIFIER } from "@/apps/tatar/activityApp/specific/maintenance/technical-units/APTechnicalUnitsConst";
import { RentalUnit } from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import TechnicalUnitCardList from "./components/TechnicalUnitCardList";
import "./RentalUnitTechnicalUnits.scss";
type Props = {
  rentalUnit: RentalUnit;
};

const RentalUnitTechnicalUnits = (props: Props) => {
  const { rentalUnit } = props;

  return (
    <div className={classNames(`rental-unit-technical-units __card`)}>
      <TechnicalUnitCardList
        identifier={CB_PORTFOLIO_RENTAL_UNIT_TECHNICAL_UNIT_IDENTIFIER}
        matchQuery={MQ.and(
          MQ.in("data.rentalUnits", [rentalUnit._id]),
          MQ.in("data.status", ["active"])
        )}
      />
    </div>
  );
};

export default RentalUnitTechnicalUnits;
