import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import StringUtils from "@/utils/StringUtils";
import { RentalAgreement } from "../../../TenantsInterfaces";
// import "./CBAgreementRentDuration.scss";

export type RentDurationType = {
  moveIn: Date;
  agreementExpiration?: Date;
  moveOut?: Date;
};
interface CBAgreementRentDurationProps {
  marks: PDFEditRect[] | PDFDocumentRect[];
  data: RentDurationType;
  rentalAgreement?: RentalAgreement;
  editable?: boolean;
}
const CBAgreementRentDuration = (props: CBAgreementRentDurationProps) => {
  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      value={props.marks}
      title={
        <>
          <span className={`padding-right-5`}>
            {i18n.t(
              "cb:RentalAgreement.Form.Fields.rentDuration.title",
              "Vertragszeitraum"
            )}
          </span>
        </>
      }
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.rentDuration.viewHint",
        "Informationen zum Mietzeitraum"
      )}
      identifier="rentDuration"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      <>
        <div className={`__flex-wrap `}>
          <BFValueDisplay
            label={i18n.t(
              "cb:RentalAgreement.Form.Fields.moveIn",
              "Mietbeginn"
            )}
            value={props.data.moveIn}
            formatter={StringUtils.formatDate}
          />
          <BFValueDisplay
            label={i18n.t(
              "cb:RentalAgreement.Form.Fields.agreementExpiration",
              "Vertrag bis"
            )}
            value={props.data.agreementExpiration}
            formatter={StringUtils.formatDate}
          />
          <BFValueDisplay
            label={i18n.t("cb:RentalAgreement.Form.Fields.moveOut", "Auszug")}
            value={props.data.moveOut}
            formatter={StringUtils.formatDate}
          />
        </div>
      </>
    </BFPDFMarkerSection>
  );
};

export default CBAgreementRentDuration;
