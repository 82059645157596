import classNames from "classnames";
import i18n from "../../../../i18n";
import { CurrencyAmount } from "../../../../model/common/CommonInterfaces";
import StringUtils from "../../../../utils/StringUtils";
import BFOverlay from "../../general/whisper/BFOverlay";
import "./CurrencyLabel.scss";

interface CurrencyLabelProps {
  value: CurrencyAmount;
  showConverted?: boolean;
}
const CurrencyLabel = (props: CurrencyLabelProps) => {
  if (!props.value) {
    return <span>-</span>;
  }
  const converted =
    (props.value.currency || "").toLowerCase() !==
    (props.value.converted?.currency || "").toLowerCase();

  const label = (
    <span className={classNames(`currency-label`)}>
      {StringUtils.formatCurrency(
        props.showConverted
          ? props.value.converted?.amount
          : props.value.amount,
        undefined,
        undefined,
        props.showConverted
          ? props.value.converted?.currency
          : props.value.currency
      )}
      {converted ? <span style={{ color: "#888" }}> *</span> : ""}
    </span>
  );

  if (converted) {
    return (
      <BFOverlay
        delay={500}
        enterable
        speaker={
          <div className={`currency-label-overlay`}>
            <div className={`value-entry`}>
              {props.showConverted && (
                <>
                  <div className={`label`}>
                    {i18n.t(
                      "CurrencyLabel.ConvertedDescription",
                      "Originaler Betrag"
                    )}
                  </div>
                  <div className={`value`}>
                    {StringUtils.formatCurrency(
                      props.value.amount,
                      undefined,
                      undefined,
                      props.value.currency
                    )}
                  </div>
                </>
              )}
              {!props.showConverted && (
                <>
                  <div className={`label`}>
                    {i18n.t(
                      "CurrencyLabel.OriginalDescription",
                      "Umgerechneter Betrag"
                    )}
                  </div>
                  <div className={`value`}>
                    {StringUtils.formatCurrency(
                      props.value.converted?.amount,
                      undefined,
                      undefined,
                      props.value.converted?.currency
                    )}
                  </div>
                </>
              )}
            </div>

            <div className={`info`}>
              <div className={`info-entry`}>
                <div className={`label`}>
                  {i18n.t("CurrencyLabel.ConversionDate", "Umrechnungsdatum")}
                </div>
                <div className="value">
                  {StringUtils.formatDate(
                    props.value.converted?.conversionDate
                  )}
                </div>
              </div>
              <div className={`info-entry`}>
                <div className={`label`}>
                  {i18n.t("CurrencyLabel.ConversionRate", "Umrechnungsfaktor")}
                </div>
                <div className="value">
                  {StringUtils.formatNumber(
                    props.value.converted?.conversionRate,
                    undefined,
                    undefined,
                    2,
                    6
                  )}
                </div>
              </div>
            </div>
          </div>
        }
      >
        {label}
      </BFOverlay>
    );
  } else {
    return label;
  }
};

export default CurrencyLabel;
