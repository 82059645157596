import { CBLoanSingleData } from "@/apps/tatar/cashBudget/views/portfolio/object/cards/loan/CBLoanChart";
import {
  CBLoanICR,
  CBLoanLTV,
} from "@/apps/tatar/cashBudget/views/portfolio/object/cards/loan/CBLoanSingleValues";
import CBPortfolioLoanCard from "@/apps/tatar/cashBudget/views/portfolio/object/cards/loan/CBPortfolioLoanCard";
import "./ObjectDetailLoans.scss";

type PropsObject = {
  assets: string[];
  refId: string;
};
type Props = PropsObject;

const ObjectDetailLoans = (props: Props) => {
  return (
    <div className="object-loans">
      <div className="single-kpis">
        <div className="entry">
          <CBLoanICR referenceId={props.refId} objectIds={props.assets} />
        </div>

        <div className="entry">
          <CBLoanLTV referenceId={props.refId} objectIds={props.assets} />
        </div>

        <div className="entry">
          <CBLoanSingleData
            selector="annuity"
            referenceId={props.refId}
            objectIds={props.assets}
          />
        </div>
        <div className="entry">
          <CBLoanSingleData
            selector="repaymentAmount"
            referenceId={props.refId}
            objectIds={props.assets}
          />
        </div>
        <div className="entry">
          <CBLoanSingleData
            selector="interestAmount"
            referenceId={props.refId}
            objectIds={props.assets}
          />
        </div>
        <div className="entry">
          <CBLoanSingleData
            selector="accumulatedInterest"
            referenceId={props.refId}
            objectIds={props.assets}
          />
        </div>
        <div className="entry">
          <CBLoanSingleData
            selector="endBalance"
            referenceId={props.refId}
            objectIds={props.assets}
          />
        </div>
      </div>
      <CBPortfolioLoanCard referenceId={props.refId} objectIds={props.assets} />
    </div>
  );
};
export default ObjectDetailLoans;

//KPI
