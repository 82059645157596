import React, { Component } from "react";

import "./BFRadio.scss";
import { Radio } from "rsuite";

type Props = {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  inline?: boolean;
  name?: string;
  onChange?: (value: any, checked: boolean, event: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  title?: string;
  value?: any;
};

type States = {};

class BfRadio extends Component<Props, States> {
  render() {
    return <Radio {...this.props}>{this.props.children}</Radio>;
  }
}

// dirty fix: changing the display name in order to get the allProperties set by the checkbox group as intended https://github.com/rsuite/rsuite/blob/6095d1f61c8d014349d7e0e3d3e639f202e5fed5/src/RadioGroup.js#L59
(BfRadio as any).displayName = "Radio";

export default BfRadio;
