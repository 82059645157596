import classNames from "classnames";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Dropdown, Loader } from "rsuite";
import TouchScrollableDiv from "../../components/TouchScrollableDiv/TouchScrollableDiv";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BfIcon from "../abstract-ui/icon/BfIcon";
import {
  CashBudgetBackgroundCell,
  CashBudgetRowIdentCell,
} from "./CashBudgetCells";
import CashBudgetColumnData from "./CashBudgetColumnData";
import CashBudgetFixedHeader from "./CashBudgetFixedHeader";
import "./CashBudgetTable.scss";

export const CB_MOBILE_WIDTH = 950;
export type ValueData = {
  value: number;
  text: string;
  clickable?: boolean;
  clickData?: any;
  bold?: boolean;
  className?: string;
  opacity?: number;
  onChange?: (value: number) => void;
  cellBackground?: string;
  progress?: number;
  overdoneClass?: string;
  renderOnHover?: () => React.ReactNode;
  tags?: string[];
  subValues?: ValueData[];
  subValuesIdent?: string;
  renderLeft?: React.ReactNode;
};
type BaseCashBudgetColumnConfig = {
  id: string;
  highlight?: "current" | "future" | "sum";
  headerText: string;
  topHeaderTextLeft?: string;
  topHeaderTextCenter?: string;
  topHeaderTextRight?: string;
  subHeaders?: string[];
  allBold?: boolean;
  className?: string;
  showEndDivider?: boolean;
};
type DefaultCashBudgetColumnConfig = BaseCashBudgetColumnConfig & {
  type: "default";
  values: (null | ValueData)[];
};
type ComparisonCashBudgetColumnConfig = BaseCashBudgetColumnConfig & {
  type: "comparison";
  values: (null | ValueData)[][];
};
export type CashBudgetColumnConfig =
  | DefaultCashBudgetColumnConfig
  | ComparisonCashBudgetColumnConfig;
export interface CashBudgetRowConfig {
  title: string;
  bold?: boolean;
  selectable?: boolean;
  borderTop?: boolean;
  isSubHead?: boolean;
  hints?: CashBudgetRowConfigHint[];
  subRows?: CashBudgetRowConfig[];
  subRowIdent?: string;
  actions?: React.ReactNode;
}
export interface CashBudgetRowConfigHint {
  color: string;
  description: string;
}
interface Props {
  viewportWidth?: number;
  activeMobileComparisonType?: number;
  loading?: boolean;
  rowHeight: number;
  onCellDblClick?: (index: number, item: any) => void;
  onSelectedDateChange?: (newDate: any) => void;
  tableDropdownFirst?: {
    selectedText: string;
    values: { text: string; value: any }[];
  };
  tableDropdownSecond?: {
    selectedText: string;
    values: { text: string; value: any }[];
  };
  tableLeftButton?: {
    disabled: boolean;
    text: string;
    value: any;
  };
  tableRightButton?: {
    disabled: boolean;
    text: string;
    value: any;
  };
  rowConfiguration: CashBudgetRowConfig[];
  data: CashBudgetColumnConfig[];
  onSelectionChange?: (values?: number[]) => void;

  headContent?: React.ReactNode;
  initialCollapsed?: string[];
  columnWidth?: number;
}

interface SelectionModel {
  selectedValues: [number, number][];
  lastReference: [number, number] | null;
  temporaryAddedWithShift: [number, number][] | null;
}

const reduceToAllRows = (
  rowConfigs: CashBudgetRowConfig[],
  collapsedEntries: string[],
  depth = 0
): ({ depth: number } & CashBudgetRowConfig)[] => {
  return rowConfigs.reduce(
    (prev, cur) => [
      ...prev,
      { ...cur, depth },
      ...(cur.subRows && collapsedEntries.find((e) => e === cur.subRowIdent)
        ? reduceToAllRows(cur.subRows, collapsedEntries, depth + 1)
        : []),
    ],
    []
  );
};

const flatValuesWithSubValues = (
  values: ValueData[],
  collapsedEntries: string[]
) => {
  return values.reduce(
    (prev, current) => [
      ...prev,
      current,
      ...(current?.subValues &&
      collapsedEntries.indexOf(current?.subValuesIdent) !== -1
        ? flatValuesWithSubValues(current?.subValues, collapsedEntries)
        : []),
    ],
    []
  );
};

const CashBudgetTable = (props: Props) => {
  const [collapsedEntries, setCollapsedEntries] = useState<string[]>(
    props.initialCollapsed || []
  );
  const [scrollState, setScrollState] = useState<string>("stop");
  const highlightContainerRef = useRef<any>();
  const refOverscrollLeft = useRef<any>();
  const refOverscrollRight = useRef<any>();
  const refScrollableDiv = useRef<any>();
  const rowDefinitionScrollRef = useRef<any>();
  const overscrollLeft = useRef<any>();
  const overscrollRight = useRef<any>();
  const refScrollContainerDiv = useRef<any>();
  const fixedHeaderRefs = useRef([]);
  const [showFixedHeaderOnScrollY, setshowFixedHeaderOnScrollY] = useState(60);

  const elRefs = useRef([]);

  const [selectionModel, setSelectionModel] = useState<SelectionModel>({
    selectedValues: [],
    lastReference: null,
    temporaryAddedWithShift: null,
  });

  useEffect(() => {
    setCollapsedEntries(props.initialCollapsed || []);
  }, [props.initialCollapsed]);

  useEffect(() => {
    if (selectionModel.selectedValues.length > 0) {
      // reduces all nested columns as single columns, so that the selcted column fits
      const allData = props.data.reduce(
        (prevData, currentData) => [
          ...prevData,
          ...(currentData.subHeaders
            ? currentData.subHeaders.map((_, i) => ({
                ...currentData,
                values: flatValuesWithSubValues(
                  (currentData.values as ValueData[][]).map((e) => e[i]),
                  collapsedEntries
                ),
              }))
            : [
                {
                  ...currentData,
                  values: flatValuesWithSubValues(
                    currentData.values as ValueData[],
                    collapsedEntries
                  ),
                },
              ]),
        ],
        []
      ) as Partial<CashBudgetColumnConfig>[];
      const data = selectionModel.selectedValues?.map(
        ([col, row]) =>
          (allData[col]?.values[row] as ValueData)?.value || undefined
      );

      props.onSelectionChange?.(data.filter((e) => e !== undefined));
    } else {
      props.onSelectionChange?.([]);
    }
  }, [selectionModel]);

  const onCellClick = (
    columnIndex: number,
    rowIndex: number,
    cmdModifier: boolean,
    shiftModifier: boolean,
    cellConfig: ValueData
  ) => {
    if (scrollState !== "stop") {
      return;
    }
    if (!cmdModifier && !shiftModifier) {
      if (
        selectionModel.selectedValues.length === 1 &&
        columnIndex === selectionModel.selectedValues[0][0] &&
        rowIndex === selectionModel.selectedValues[0][1]
      ) {
        setSelectionModel({
          lastReference: null,
          selectedValues: [],
          temporaryAddedWithShift: null,
        });
      } else {
        setSelectionModel({
          lastReference: [columnIndex, rowIndex],
          selectedValues: [[columnIndex, rowIndex]],
          temporaryAddedWithShift: null,
        });
      }
    } else if (cmdModifier && !shiftModifier) {
      if (
        selectionModel.selectedValues.some(
          ([column, row]) => columnIndex === column && rowIndex === row
        )
      ) {
        setSelectionModel({
          lastReference: null,
          selectedValues: selectionModel.selectedValues.filter(
            ([column, row]) => columnIndex !== column || rowIndex !== row
          ),
          temporaryAddedWithShift: null,
        });
      } else {
        setSelectionModel({
          lastReference: [columnIndex, rowIndex],
          selectedValues: [
            ...selectionModel.selectedValues,
            [columnIndex, rowIndex],
          ],
          temporaryAddedWithShift: null,
        });
      }
    } else if (!cmdModifier && shiftModifier) {
      if (!selectionModel.lastReference) {
        setSelectionModel({
          lastReference: [columnIndex, rowIndex],
          selectedValues: [
            ...selectionModel.selectedValues,
            [columnIndex, rowIndex],
          ],
          temporaryAddedWithShift: null,
        });
      } else {
        let newSelectedValues = [...selectionModel.selectedValues];
        if (selectionModel.temporaryAddedWithShift) {
          newSelectedValues = newSelectedValues.filter(
            ([column, row]) =>
              !selectionModel.temporaryAddedWithShift.some(
                ([tmpColumn, tmpRow]) => tmpRow === row && tmpColumn === column
              )
          );
        }
        const [refColumn, refRow] = selectionModel.lastReference;
        let addingTemporary = [];
        for (
          var iCol = Math.min(refColumn, columnIndex);
          iCol <= Math.max(refColumn, columnIndex);
          iCol++
        ) {
          for (
            var iRow = Math.min(refRow, rowIndex);
            iRow <= Math.max(refRow, rowIndex);
            iRow++
          ) {
            if (
              selectionModel.lastReference[0] !== iCol ||
              (selectionModel.lastReference[1] !== iRow &&
                !newSelectedValues.some(
                  ([column, row]) => column === iCol && row === iRow
                ))
            ) {
              addingTemporary.push([iCol, iRow]);
            }
          }
        }
        newSelectedValues = [
          ...newSelectedValues,
          // selectionModel.lastReference,
          selectionModel.lastReference,
          ...addingTemporary,
        ];

        newSelectedValues = _.uniqWith(newSelectedValues, _.isEqual);

        setSelectionModel({
          lastReference: selectionModel.lastReference,
          selectedValues: newSelectedValues,
          temporaryAddedWithShift: addingTemporary,
        });
      }
    }
  };

  // const [elRefs, setElRefs] = useState([]);
  // useEffect(() => {
  //   // add or remove refs
  //   setElRefs((elRefs) =>
  //     Array(props.rowConfiguration.length)
  //       .fill(null)
  //       .map((_, i) => elRefs[i] || createRef())
  //   );
  // }, [props.rowConfiguration]);
  // useEffect(() => {
  // }, [elRefs]);

  return (
    <div
      className={classNames("cashbudget-table", {
        "remove-overscroll": !props.onSelectedDateChange,
      })}
    >
      {props.loading ? (
        <div className={`loading-overlay`}>
          <Loader size="md" />
        </div>
      ) : null}
      <div className={`row-highlight-container`}>
        <div ref={highlightContainerRef} className={`scrolling-content`}>
          <div
            style={{
              height: props.rowHeight * 3,
            }}
            className={`head`}
          ></div>

          {reduceToAllRows(props.rowConfiguration, collapsedEntries).map(
            (conf, index) => (
              <CashBudgetBackgroundCell
                rowIndex={index}
                key={index}
                rowHeight={props.rowHeight}
                isSubHead={conf.isSubHead}
                refFC={(el) => {
                  elRefs.current[index] = el;
                }}
              />
            )
          )}
        </div>
      </div>
      <div className={`table-row-definition`}>
        <div ref={rowDefinitionScrollRef} className={`scrolling-content`}>
          <div
            style={{
              height: props.rowHeight * 3,
            }}
            className={`head`}
          >
            {props.headContent && props.headContent}
            {props.onSelectedDateChange && (
              <div className={`date-selection`}>
                <BFButton
                  type="button"
                  disabled={props.tableLeftButton.disabled}
                  onClick={() =>
                    props.onSelectedDateChange(props.tableLeftButton.value)
                  }
                  icon={{ type: "bf", data: "arrow-up-1" }}
                  style={{ transform: "rotate(-90deg)" }}
                />
                {props.tableDropdownFirst && (
                  <Dropdown
                    noCaret
                    className={`head-selection`}
                    title={props.tableDropdownFirst.selectedText}
                  >
                    {props.tableDropdownFirst.values.map((val, index) => (
                      <Dropdown.Item
                        key={index}
                        onSelect={() => props.onSelectedDateChange(val.value)}
                      >
                        {val?.text}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                )}
                {props.tableDropdownSecond && (
                  <Dropdown
                    noCaret
                    className={`head-selection`}
                    title={props.tableDropdownSecond.selectedText}
                  >
                    {props.tableDropdownSecond.values.map((val, index) => (
                      <Dropdown.Item
                        key={index}
                        onSelect={() => props.onSelectedDateChange(val.value)}
                      >
                        {val?.text}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                )}

                <BFButton
                  type="button"
                  disabled={props.tableRightButton.disabled}
                  onClick={() =>
                    props.onSelectedDateChange(props.tableRightButton.value)
                  }
                  icon={{ type: "bf", data: "arrow-up-1" }}
                  style={{ transform: "rotate(90deg)" }}
                />
              </div>
            )}
          </div>
          {reduceToAllRows(props.rowConfiguration, collapsedEntries).map(
            (conf, index, arr) => {
              const collapseProps = {
                collapsingCell: false,
                isCollapsed: undefined,
                onCollapseToggle: undefined,
              };
              if (conf.subRows) {
                collapseProps.collapsingCell = true;
                collapseProps.isCollapsed = collapsedEntries.find(
                  (e) => e === conf.subRowIdent
                );
                collapseProps.onCollapseToggle = () => {
                  if (collapseProps.isCollapsed) {
                    setCollapsedEntries(
                      collapsedEntries.filter((e) => e !== conf.subRowIdent)
                    );
                  } else {
                    setCollapsedEntries([
                      ...collapsedEntries,
                      conf.subRowIdent,
                    ]);
                  }
                  setSelectionModel({
                    lastReference: null,
                    selectedValues: [],
                    temporaryAddedWithShift: null,
                  });
                };
              }

              return (
                <CashBudgetRowIdentCell
                  depth={conf.depth}
                  nextDepth={arr[index + 1]?.depth}
                  rowIndex={index}
                  key={index}
                  isSubHead={conf.isSubHead}
                  rowHeight={props.rowHeight}
                  text={conf.title}
                  bold={conf.bold}
                  onHoverEnterFunction={(rowIndex) => {
                    elRefs.current?.[rowIndex]?.classList.add("hover");
                  }}
                  onHoverLeaveFunction={(rowIndex) =>
                    elRefs.current?.[rowIndex]?.classList.remove("hover")
                  }
                  hints={conf.hints}
                  actions={conf.actions}
                  {...collapseProps}
                />
              );
            }
          )}
        </div>
      </div>
      <TouchScrollableDiv
        ref={refScrollableDiv}
        wheelHorizontalScroll={true}
        onScrollStateHandler={(state) => {
          setTimeout(() => setScrollState(state));
        }}
        className={`table-data-content`}
        onScrollChange={(el) => {
          if (highlightContainerRef.current)
            highlightContainerRef.current.style.transform = `translateY(${-el.scrollTop}px)`;
          if (rowDefinitionScrollRef.current)
            rowDefinitionScrollRef.current.style.transform = `translateY(${-el.scrollTop}px)`;

          if (overscrollLeft.current)
            overscrollLeft.current.style.transform = `translateY(${el.scrollTop}px)`;
          if (overscrollRight.current)
            overscrollRight.current.style.transform = `translateY(${el.scrollTop}px)`;

          fixedHeaderRefs.current?.forEach((entry) => {
            if (entry) {
              entry.style.transform = `translateY(${el.scrollTop}px)`;

              if (
                el.scrollTop > showFixedHeaderOnScrollY &&
                !entry.classList.contains("show")
              ) {
                entry.classList.add("show");
              }
              if (
                el.scrollTop <= showFixedHeaderOnScrollY &&
                entry.classList.contains("show")
              ) {
                entry.classList.remove("show");
              }
            }
          });
          if (refOverscrollLeft && refOverscrollLeft.current) {
            if (el.scrollLeft < 20) {
              refOverscrollLeft.current.classList.add("active");
            } else {
              refOverscrollLeft.current.classList.remove("active");
            }
          }
          const width =
            el.querySelector(".scrolling-content").getBoundingClientRect()
              .width - el.getBoundingClientRect().width;
          if (refOverscrollRight && refOverscrollRight.current) {
            if (width - el.scrollLeft < 20) {
              refOverscrollRight.current.classList.add("active");
            } else {
              refOverscrollRight.current.classList.remove("active");
            }
          }
        }}
      >
        <div className={`scrolling-content`}>
          <div className={`overscroll-container`} ref={overscrollLeft}>
            {props.onSelectedDateChange && props.tableLeftButton && (
              <div className={`switch left`} ref={refOverscrollLeft}>
                <BFButton
                  type="button"
                  disabled={props.tableLeftButton.disabled}
                  onClick={() =>
                    props.onSelectedDateChange(props.tableLeftButton.value)
                  }
                >
                  <div className={`switch-content`}>
                    <div className={`indicator`}>
                      <BfIcon type="bf" data="arrow-up-1" />
                    </div>
                    <div className={`label`}>{props.tableLeftButton.text}</div>
                  </div>
                </BFButton>
              </div>
            )}
          </div>
          {props.data
            .reduce(
              (prev, current) => [
                ...prev,
                {
                  index:
                    prev.length > 0
                      ? prev[prev.length - 1].index +
                        (prev[prev.length - 1].data.subHeaders?.length || 1)
                      : 0,
                  data: current,
                },
              ],
              [] as { index: number; data: CashBudgetColumnConfig }[]
            )
            .map((value, index) => (
              <div
                key={index}
                className={classNames(`row-wrapper`, value.data.className)}
              >
                {(!props.viewportWidth ||
                  props.viewportWidth > CB_MOBILE_WIDTH) && (
                  <div
                    className="fixed-header-container"
                    ref={(el) => {
                      fixedHeaderRefs.current[index] = el;
                    }}
                  >
                    <CashBudgetFixedHeader
                      data={value.data}
                      rowHeight={props.rowHeight}
                    />
                  </div>
                )}
                <CashBudgetColumnData
                  columnWidth={props.columnWidth}
                  mobileView={
                    props.viewportWidth
                      ? props.viewportWidth <= CB_MOBILE_WIDTH
                      : false
                  }
                  selectedValues={selectionModel.selectedValues}
                  columnIndex={value.index}
                  key={value.index}
                  collapsedEntries={collapsedEntries}
                  onCellClick={(
                    rowIndex: number,
                    cmdModifier: boolean,
                    shiftModifier: boolean,
                    valueData: ValueData,
                    columnIndexAdd: number
                  ) =>
                    onCellClick(
                      value.index + columnIndexAdd,
                      rowIndex,
                      cmdModifier,
                      shiftModifier,
                      valueData
                    )
                  }
                  onCellDblClick={props.onCellDblClick}
                  rowHeight={props.rowHeight}
                  data={value.data}
                  activeMobileComparisonType={props.activeMobileComparisonType}
                  elRefs={elRefs}
                  rowConfiguration={reduceToAllRows(
                    props.rowConfiguration,
                    collapsedEntries
                  )}
                />
              </div>
            ))}
          <div className={`overscroll-container`} ref={overscrollRight}>
            {props.onSelectedDateChange && props.tableRightButton && (
              <div className={`switch right`} ref={refOverscrollRight}>
                <BFButton
                  type="button"
                  disabled={props.tableRightButton.disabled}
                  onClick={() =>
                    props.onSelectedDateChange(props.tableRightButton.value)
                  }
                >
                  <div className={`switch-content`}>
                    <div className={`indicator`}>
                      <BfIcon type="bf" data="arrow-up-1" />
                    </div>
                    <div className={`label`}>{props.tableRightButton.text}</div>
                  </div>
                </BFButton>
              </div>
            )}
          </div>
        </div>

        <div className="swiper-scrollbar"></div>
      </TouchScrollableDiv>
    </div>
  );
};

export default CashBudgetTable;
