import classNames from "classnames";
import UserAvatar from "./UserAvatar";
import "./Userlabel.scss";
import Username from "./Username";

interface UserlabelProps {
  id: string;
  avatarSize?: number;
  className?: string;
}
const Userlabel = (props: UserlabelProps) => {
  return (
    <div className={classNames(`user-label`, props.className)}>
      <UserAvatar id={props.id} size={props.avatarSize || 20} />
      <Username id={props.id} />
    </div>
  );
};

export default Userlabel;
