import classNames from "classnames";
import { Field } from "react-final-form";
import { useDispatch } from "react-redux";
import i18n from "../../../../i18n";
import { setUserData } from "../../../../redux/actions/global/global-actions";
import { useTypedSelector } from "../../../../redux/hooks";
import EZInput from "../../../ez-form/form-elements/ez-input/EZInput";
import EZGroup from "../../../ez-form/form-group/ez-form/EZGroup";
import Validators from "../../../generic-forms/util/Validatos";
import UserAvatarForm from "./UserAvatarForm";
import "./UserProfile.scss";
import UserSignatureComponent from "./UserSignatureComponent";

interface UserProfileProps {}
const UserProfile = (props: UserProfileProps) => {
  const user = useTypedSelector((state) => state.global.user);
  const dispatch = useDispatch();

  return (
    <div className={classNames(`user-profile`)}>
      <div className={`user-profile__header __h1`}>
        {i18n.t("UserProfile.Profile.Title", "Profil")}
      </div>
      <div className={`user-profile__content`}>
        <UserAvatarForm />
        <div className={`user-form`}>
          <EZGroup
            value={{
              displayname: user.displayname,
            }}
            overwriteHttpMethod="patch"
            url={`/api/user`}
            onSubmitSuccess={(value) => {
              dispatch(setUserData(value));
            }}
          >
            {(renderProps) => (
              <div>
                <div className={`__field`}>
                  <Field
                    name="displayname"
                    validate={(value) =>
                      !Validators.required(value)
                        ? i18n.t("Global.Labels.required")
                        : null
                    }
                  >
                    {({ input, meta }) => (
                      <EZInput
                        textType={"default"}
                        ignoreLinebreaks={true}
                        appearance="bf"
                        validation={
                          meta.touched && meta.error
                            ? { level: "error", message: meta.error }
                            : null
                        }
                        label={i18n.t(
                          "UserProfile.Profile.DisplayName",
                          "Anzeigename"
                        )}
                        placeholder={i18n.t(
                          "UserProfile.Profile.DisplayName",
                          "Anzeigename"
                        )}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field`}>
                  <EZInput
                    textType={"default"}
                    ignoreLinebreaks={true}
                    // appearance="bf"
                    label={i18n.t("UserProfile.Profile.Email", "E-Mail")}
                    placeholder={i18n.t("UserProfile.Profile.Email", "E-Mail")}
                    value={user.email}
                    onChange={() => {}}
                    readonly={true}
                  />
                </div>
              </div>
            )}
          </EZGroup>
        </div>
      </div>
      <UserSignatureComponent />
    </div>
  );
};

export default UserProfile;
