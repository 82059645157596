import _ from "lodash";
import moment from "moment";

export const when = (condition: any, then: any, otherwise: any = undefined) => {
  if (condition) {
    if (typeof then === "function") {
      return then(condition);
    } else {
      return then;
    }
  } else {
    return otherwise;
  }
};
export const valueOrDefault = <T>(value: T, defaultValue: T) => {
  if (!hasValue(value)) {
    return defaultValue;
  }
  return value;
};
export const hasValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  if (value instanceof Date) {
    return true;
  } else if (value && typeof value === "object") {
    return Object.keys(value).length > 0;
  }

  if (
    value !== false &&
    value !== null &&
    value !== undefined &&
    value !== "" &&
    Number.isNaN(value) === false
  ) {
    return true;
  }
  return false;
};
export const hasNoValue = (value: any) => {
  return !hasValue(value);
};
export const isDefined = (value: any) => {
  if (value !== null && value !== undefined) {
    return true;
  }
  return false;
};
export const isNotDefined = (value: any) => {
  return !isDefined(value);
};
export const waitFor = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const merge = <T1, T2>(obj1: T1, obj2: T2) => {
  return _.merge({}, obj1, obj2);
};

export const latestOf = (...arg: moment.Moment[]) => {
  return arg.reduce((prev, curr) => {
    if (!curr || prev.isAfter(curr)) {
      return prev;
    }
    return curr;
  });
};

export const earliestOf = (...arg: moment.Moment[]) => {
  return arg.reduce((prev, curr) => {
    if (!curr || prev.isBefore(curr)) {
      return prev;
    }
    return curr;
  });
};

export const firstValid = (...arg: any[]) => {
  for (let i = 0; i < arg.length; i++) {
    if (hasValue(arg[i])) {
      return arg[i];
    }
  }
  return null;
};

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
    ? Subset<K[attr]> | null
    : K[attr] extends object | null | undefined
    ? Subset<K[attr]> | null | undefined
    : K[attr];
};

export const translateNonGenerate = (key: string, defaultText?: string) => {
  const tMethod = (window as any).i18n.t.bind((window as any).i18n);
  return tMethod(key, { defaultValue: defaultText });
};

export const includesAny = <T>(arr: T[], checks: T[]) => {
  return checks.some((check) => arr.includes(check));
};

export const isEnumValue = (value: string, enumObj: any) => {
  return Object.values(enumObj).includes(value);
};

export const mapTraverse = <T, E>(
  fields: T[],
  mapFC: (el: T, traverseFc) => E[]
) => {
  const result: E[] = [];
  (fields || []).map((el) => {
    result.push(...mapFC(el, mapFC));
  });
  return result;
};
