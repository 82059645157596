import classNames from "classnames";
import _ from "lodash";
import { Field, FormSpy } from "react-final-form";
import CDNLink from "../../../../../../components/CDNLink/CDNLink";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../components/Form/Validation/FormValidators";
import PDFViewer from "../../../../../../components/PDFViewer/PDFViewer";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import BFFormSection from "../../../../../../modules/abstract-ui/data/form-section/BFFormSection";
import BFInput from "../../../../../../modules/abstract-ui/forms/input/BFInput";
import { hasValue } from "../../../../../../utils/Helpers";
import StringUtils from "../../../../../../utils/StringUtils";
import InvoiceService from "../../../../invoiceApp/InvoiceService";
import { useActivityConstants } from "../../../ActivityHooks";
import ActivityService from "../../../ActivityService";
import {
  APInvoiceProjectLink,
  APProjectBudget,
} from "../project-budget/APProjectBudgetInterfaces";
import "./APBudgetInvoiceManagePositions.scss";

interface APBudgetInvoiceManagePositionsProps {
  link: APInvoiceProjectLink;
  budget: APProjectBudget;
  onClose: () => void;
  onUpdated: () => void;
}
const APBudgetInvoiceManagePositions = (
  props: APBudgetInvoiceManagePositionsProps
) => {
  const constants = useActivityConstants();
  const fileCdnData = props.link.expand.linkedInvoice
    ? Object.entries(props.link.expand.linkedInvoice.data.invoice.file)[0]
    : undefined;
  const cdnData = {
    filename: InvoiceService.generateFilenameForInvoice(
      props.link.expand.linkedInvoice
    ),
    assetField: "data.invoice.file",
    assetId: props.link.expand.linkedInvoice._id,
    assetType: AssetTypes.Invoice,
    cdnData: fileCdnData[1],
    cdnId: fileCdnData[0],
  };
  const iniitial = {
    ...props.link.data.splitValues.reduce(
      (prev, current) => ({
        ...prev,
        [`comment_${current.categoryId}`]: current.comment,
        [`value_${current.categoryId}`]: current.value,
      }),
      {}
    ),
  };
  return (
    <FormStruct
      ignoreSubmitOnEnter
      initialValues={iniitial}
      className={`ap-budget-invoice-manage-positions`}
      title={i18n.t("apTemplate:BudgetInvoices.ManagePositions", "Positionen")}
      description={i18n.t(
        "apTemplate:BudgetInvoices.ManagePositions",
        "Verteilen Sie die Summe der Rechnung auf die geplanten Positionen.",
        {
          ns: ["apTemplate"],
        }
      )}
      onSubmit={async (values) => {
        const toSet = props.budget.data.groups
          .map((e) => e.children)
          .flat()
          .map((category) => ({
            categoryId: category.id,
            comment: values[`comment_${category.id}`],
            value: values[`value_${category.id}`],
          }))
          .filter((e) => hasValue(e.value) && e.value !== 0);
        await ActivityService.updateBudgetInvoicePositions(
          constants.serviceUrl,
          props.link._id,
          toSet
        );
        props.onUpdated();
        props.onClose();
      }}
      onAbort={props.onClose}
      submitText={i18n.t(
        "apTemplate:BudgetInvoices.Assign",
        "Zuordnung speichern"
      )}
      render={(formProps) => {
        return (
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              const entries = props.budget.data.groups
                .map((group) =>
                  group.children.map(
                    (field) => values[`value_${field.id}`] || 0
                  )
                )
                .flat();
              const sumOfAll = _.sum(entries);

              const openAmount =
                props.link.expand.linkedInvoice.data.invoice.value.converted
                  .amount - sumOfAll;

              return (
                <div className={`form-wrapper`}>
                  <div className={`pdf`}>
                    {cdnData && (
                      <CDNLink
                        assetType={cdnData.assetType}
                        assetId={cdnData.assetId}
                        assetField={cdnData.assetField}
                        cdnId={cdnData.cdnId}
                        filename={cdnData.cdnData.filename}
                        fileKey={cdnData.cdnData.key}
                        hasFolderReadPermissions={true}
                        render={(url) => (
                          <PDFViewer url={url} filename={cdnData.filename} />
                        )}
                      />
                    )}
                  </div>
                  <div className={`form`}>
                    {props.budget.data.groups.map((group) => {
                      const entries = group.children.map(
                        (field) => formProps.values[`value_${field.id}`] || 0
                      );
                      const sum = _.sum(entries);
                      return (
                        <BFFormSection
                          initialOpen={sum !== 0}
                          title={group.name}
                          key={group.id}
                          right={StringUtils.formatCurrency(sum)}
                        >
                          <div>
                            {group.children.map((field) => (
                              <div className={`field-row`}>
                                <div className={`name`}>{field.name}</div>
                                <div className={`values`}>
                                  <div className={`value`}>
                                    <Field name={`value_${field.id}`}>
                                      {({ input, meta }) => (
                                        <BFInput
                                          prefix={"∑"}
                                          prefixOnClick={() => {
                                            input.onChange(
                                              openAmount + (input.value || 0)
                                            );
                                          }}
                                          type="priceInput"
                                          textAlign="right"
                                          suffix={StringUtils.getCurrencySymbol()}
                                          {...input}
                                          value={input.value || 0}
                                          {...FormValidators.getValidation(
                                            meta
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                  <div className={`comment`}>
                                    <Field name={`comment_${field.id}`}>
                                      {({ input, meta }) => (
                                        <BFInput
                                          placeholder={i18n.t(
                                            "apTemplate:BudgetInvoices.Comment",
                                            "Kommentar"
                                          )}
                                          type="string"
                                          {...input}
                                          {...FormValidators.getValidation(
                                            meta
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </BFFormSection>
                      );
                    })}

                    <div className={`summary`}>
                      <div
                        className={classNames(`open-amount`, {
                          negative:
                            props.link.expand.linkedInvoice.data.invoice.value
                              .converted.amount -
                              sumOfAll !==
                            0,
                        })}
                      >
                        <div className={`label`}>
                          {i18n.t(
                            "apTemplate:BudgetInvoices.OpenAmount",
                            "Offener Betrag"
                          )}
                        </div>
                        <div className={`value`}>
                          {StringUtils.formatCurrency(
                            props.link.expand.linkedInvoice.data.invoice.value
                              .converted.amount - sumOfAll
                          )}
                        </div>
                      </div>
                      <div className={`assigned-amount`}>
                        <div className={`label`}>
                          {i18n.t(
                            "apTemplate:BudgetInvoices.AssignedAmount",
                            "Zugewiesener Betrag"
                          )}
                        </div>
                        <div className={`value`}>
                          {StringUtils.formatCurrency(sumOfAll)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </FormSpy>
        );
      }}
    />
  );
};

export default APBudgetInvoiceManagePositions;
