import classNames from "classnames";
import moment from "moment";
import { useEffect } from "react";
import ChartComponent from "../../../../../../../../configurable/components/ChartComponent/ChartComponent";
import i18n from "../../../../../../../../i18n";
import BFPlaceholder from "../../../../../../../../modules/abstract-ui/general/Placeholder/BFPlaceholder";
import { useStatisticQuery } from "../../../../../../../../redux/hooks";
import DataBus from "../../../../../../../../services/DataBus";
import NumberUtils from "../../../../../../../../utils/ NumberUtils";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import StringUtils from "../../../../../../../../utils/StringUtils";
import { CBPortfolioColors } from "../../../CBPortfolioConst";
import { CBStatisticImmoVacancyRate } from "../../../interfaces/CBStatisticQueries";
import "./CBVacancyDistributionChart.scss";

interface CBVacancyDistributionChartProps {
  objectIds: string[];
  month: moment.Moment;
  title?: string;
  selector: "count" | "area" | "rent" | "rentNet";
  referenceId?: string;
  className?: string;
  showPlannedRent?: boolean;
}
const CBVacancyDistributionChart = (props: CBVacancyDistributionChartProps) => {
  const queryData = useStatisticQuery<CBStatisticImmoVacancyRate>(
    "IMMO_VACANCY_RATE",
    1,
    {
      fromDate: moment(props.month).startOf("month").utc(true).toISOString(),
      toDate: moment(props.month).endOf("month").utc(true).toISOString(),
      objectIds: props.objectIds,
    }
  );

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          queryData.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });

  return (
    <div
      className={classNames(
        `cb-vacancy-distribution-chart ${props.className || ""}`
      )}
    >
      <BFPlaceholder minHeight={350} loading={queryData.loading} width={"100%"}>
        <div className="chart-wrapper">
          <ChartComponent
            renderer="canvas"
            options={cbVacancyDistributionChartOptions(
              queryData?.data,
              props.selector,
              props.showPlannedRent,
              props.title
            )}
            identifier={`cb-portfolio-dashboard-vacancy-distribution-all`}
          />
        </div>
      </BFPlaceholder>
    </div>
  );
};

export default CBVacancyDistributionChart;

export const cbVacancyDistributionChartOptions = (
  queryData: CBStatisticImmoVacancyRate,
  selector: "count" | "area" | "rent" | "rentNet",
  showPlannedRent: boolean,
  title?: string,
  forPdf?: boolean
) => {
  const data = queryData?.[0]
    ? {
        count: {
          vacant: queryData?.[0]?.kpi?.vacancyRate?.totalVacant,
          nonVacant: queryData?.[0]?.kpi?.vacancyRate?.totalNonVacant,
        },
        area: {
          vacant: queryData?.[0]?.kpi?.vacancyArea?.totalVacant,
          nonVacant: queryData?.[0]?.kpi?.vacancyArea?.totalNonVacant,
        },
        rentNet: {
          vacant: queryData?.[0]?.kpi?.vacancyRentNet?.totalVacant,
          nonVacant: queryData?.[0]?.kpi?.vacancyRentNet?.totalNonVacant,
          nonVacantPlanned:
            queryData?.[0]?.kpi?.vacancyRentNet?.totalNonVacantPlanned,
        },
      }
    : null;

  const vacancyRate = NumberUtils.round(
    (data?.[selector]?.vacant /
      (data?.[selector]?.vacant + data?.[selector]?.nonVacant)) *
      100
  );
  const rentRate = 100 - vacancyRate;

  return {
    animation: forPdf ? false : true,
    title: {
      text: StringUtils.formatPercent(rentRate),
      left: "center",
      top: "center",
      itemGap: -26,
      textStyle: {
        fontSize: 26,
        fontWeight: 400,
        fontFamily: "Roboto",
      },
      subtext: `${i18n.t(`cb:Vacancy.${selector}`)}\n\n\n\n${i18n.t(
        "cb:VacancyDistribution.ChartTitle",
        "vermietet"
      )}`,
      subtextStyle: {
        fontSize: 14,
        lineHeight: 14,
        fontWeight: 400,
        fontFamily: "Roboto",
        color: "#888",
      },
    },
    tooltip: {
      trigger: "item",
      valueFormatter: (val) =>
        selector === "area"
          ? `${StringUtils.formatNumber(val)} ${StringUtils.getAreaUnit()}`
          : selector === "rent" || selector === "rentNet"
          ? StringUtils.formatCurrency(val)
          : val,
    },
    series: [
      {
        name: title || i18n.t("cb:Vacancy.vacancyDistribution", "Leerstand"),
        type: "pie",
        radius: ["30%", "70%"],

        legend: {
          bottom: 10,
          left: "center",
        },
        label: {
          formatter: (entry) => {
            return [
              `{title|${entry.data.name}}`,
              `{value|${
                selector === "area"
                  ? StringUtils.formatNumber(entry.data.value) +
                    " " +
                    StringUtils.getAreaUnit()
                  : selector === "rent" || selector === "rentNet"
                  ? StringUtils.formatCurrency(entry.data.value)
                  : entry.data.value
              }}`,
            ].join("\n");
          },

          rich: {
            title: {
              color: "#888",
              align: "left",
              padding: [3, 0, 2, 0],
            },
            value: {
              color: "#000",
              fontSize: 14,
              align: "left",
            },
          },
        },
        data: [
          {
            name: i18n.t("cb:Vacancy.vacant", "Leerstand"),
            value: data?.[selector]?.vacant,
            itemStyle: {
              color: CBPortfolioColors.immo.VACANT,
            },
          },
          {
            name: i18n.t("cb:Vacancy.occupied", "Vermietet"),
            value: data?.[selector]?.nonVacant,

            itemStyle: {
              color: CBPortfolioColors.immo.NON_VACANT,
            },
            ...(showPlannedRent &&
            (selector === "rent" || selector === "rentNet")
              ? {
                  label: {
                    formatter: (entry) => {
                      return [
                        `{title|${entry.data.name}}`,
                        `{value|${StringUtils.formatCurrency(
                          entry.data.value
                        )}}`,
                        `{title|${i18n.t(
                          "cb:Vacancy.plannedRent",
                          "Geplante Miete"
                        )}}`,
                        `{value|${StringUtils.formatCurrency(
                          (data?.[selector] as any)?.nonVacantPlanned
                        )}}`,
                      ].join("\n");
                    },
                  },
                }
              : {}),
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
};
