import BaseAsset from "@/model/general-assets/BaseAsset";
import classNames from "classnames";
import CFBase from "./CFBase";
import { CustomField } from "./CustomField.interface";
// import "./CustomFields.scss";

interface CustomFieldsProps {
  fields: CustomField[];
  prefix?: string;
  asset?: BaseAsset;
  assetType?: string;
}
const CustomFields = (props: CustomFieldsProps) => {
  if (!props.fields) {
    return null;
  }
  return (
    <div className={classNames(`custom-fields`)}>
      {props.fields.map((field) => (
        <CFBase
          asset={props.asset}
          assetType={props.assetType}
          key={field.id}
          field={field}
          prefix={props.prefix}
        />
      ))}
    </div>
  );
};

export default CustomFields;
