import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import ObjectIdService from "@/utils/ObjectIdUtils";
import { FormApi } from "final-form";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormValidators from "../../../components/Form/Validation/FormValidators";
import i18n from "../../../i18n";
import FormUtils from "../../../utils/FormUtils";
import BFInput from "../../abstract-ui/forms/input/BFInput";
import BFSelect from "../../abstract-ui/forms/select/BFSelect";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import ContactUtils from "../ContactUtils";

export const ContactPersonFormPart = (props: {
  form: FormApi;
  prefix?: string;
  disabled?: boolean;
}) => {
  return (
    <FieldArray name={(props.prefix || "") + "contactPersons"}>
      {({ fields }) => (
        <div className={`section contactPersons-section`}>
          <div className={`section-title __h3 margin-bottom-10 margin-top-20`}>
            {i18n.t("Contact.Form.Sections.ContactPersons", "Kontakt")}
            <BFButton
              disabled={props.disabled}
              className={`margin-left-10`}
              appearance="link"
              size="xs"
              onClick={() =>
                fields.push({
                  id: ObjectIdService.new(),
                  salutationText: ContactUtils.getSalutationString({
                    personType: "private",
                  }),
                })
              }
            >
              {i18n.t(
                "Contact.Form.Fields.ContactPersons.AddAnother",
                "Weiteren Kontakt hinzufügen"
              )}
            </BFButton>
          </div>
          <div className="contactPersons">
            {fields.map((name, index) => (
              <FormFieldValues
                names={[
                  `${name}.salutation`,
                  `${name}.firstName`,
                  `${name}.lastName`,
                ]}
              >
                {([salutation, firstName, lastName]) => (
                  <div className={`contactPersons-entry`} key={index}>
                    <div className={`action-row`}>
                      <BFButton
                        disabled={props.disabled}
                        appearance="link"
                        size="xs"
                        onClick={() => {
                          if (fields.length > 1) {
                            fields.remove(index);
                          } else {
                            props.form.mutators.setValue(
                              (props.prefix || "") + "contactPersons",
                              null
                            );
                            props.form.mutators.setValue(
                              (props.prefix || "") +
                                "helpers.showContactPersons",
                              false
                            );
                          }
                        }}
                      >
                        {i18n.t("Global.Buttons.remove")}
                      </BFButton>
                    </div>

                    <div className={`field-row`}>
                      <div className={`__field __flex-1`}>
                        <Field name={`${name}.salutation`}>
                          {({ input, meta }) => (
                            <BFSelect
                              {...input}
                              disabled={props.disabled}
                              label={`${i18n.t(
                                "Contact.Form.Fields.salutation",
                                "Anrede"
                              )}`}
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                              data={FormUtils.getSalutationOptions()}
                              onChange={(value) => {
                                input.onChange(value);
                                props.form.mutators.setValue(
                                  `${name}.salutationText`,
                                  ContactUtils.getSalutationString({
                                    personType: "private",
                                    persons: [
                                      {
                                        salutation: value,
                                        firstName,
                                        lastName,
                                      },
                                    ],
                                  })
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <div className={`__field  __flex-2`}>
                        <Field
                          name={`${name}.firstName`}
                          validate={FormValidators.compose(
                            FormValidators.max(150)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              disabled={props.disabled}
                              {...input}
                              label={`${i18n.t(
                                "Contact.Form.Fields.firstName",
                                "Vorname"
                              )}`}
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                              onChange={(value: string) => {
                                input.onChange(value);
                                props.form.mutators.setValue(
                                  `${name}.salutationText`,
                                  ContactUtils.getSalutationString({
                                    personType: "private",
                                    persons: [
                                      {
                                        salutation,
                                        firstName: value,
                                        lastName,
                                      },
                                    ],
                                  })
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <div className={`__field __flex-2`}>
                        <Field
                          name={`${name}.lastName`}
                          validate={FormValidators.compose(
                            FormValidators.required(),
                            FormValidators.max(150)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              disabled={props.disabled}
                              {...input}
                              label={`${i18n.t(
                                "Contact.Form.Fields.lastName",
                                "Nachname"
                              )}*`}
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                              onChange={(value: string) => {
                                input.onChange(value);
                                props.form.mutators.setValue(
                                  `${name}.salutationText`,
                                  ContactUtils.getSalutationString({
                                    personType: "private",
                                    persons: [
                                      {
                                        salutation,
                                        firstName,
                                        lastName: value,
                                      },
                                    ],
                                  })
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className={`field-row`}>
                      <div className={`__field __flex-1`}>
                        <Field
                          name={`${name}.email`}
                          validate={FormValidators.compose(
                            FormValidators.email()
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              disabled={props.disabled}
                              {...input}
                              label={`${i18n.t(
                                "Contact.Form.Fields.Contact.Email",
                                "E-Mail"
                              )}`}
                              validation={
                                meta.error && meta.touched
                                  ? {
                                      level: "error",
                                      message: meta.error,
                                    }
                                  : undefined
                              }
                            />
                          )}
                        </Field>
                      </div>
                      <div className={`__field __flex-1`}>
                        <Field
                          name={`${name}.phone`}
                          validate={FormValidators.compose(
                            FormValidators.max(50)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              disabled={props.disabled}
                              {...input}
                              label={`${i18n.t(
                                "Contact.Form.Fields.Contact.Phone",
                                "Telefon/Mobil"
                              )}`}
                              validation={
                                meta.error && meta.touched
                                  ? {
                                      level: "error",
                                      message: meta.error,
                                    }
                                  : undefined
                              }
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={`__field`}>
                      <Field
                        name={`${name}.salutationText`}
                        validate={FormValidators.compose(
                          FormValidators.max(120),
                          FormValidators.required()
                        )}
                      >
                        {({ input, meta }) => (
                          <BFInput
                            disabled={props.disabled}
                            {...input}
                            label={`${i18n.t(
                              "Contact.Form.Fields.SalutationText",
                              "Anredestext"
                            )}`}
                            validation={
                              meta.error && meta.touched
                                ? {
                                    level: "error",
                                    message: meta.error,
                                  }
                                : undefined
                            }
                          />
                        )}
                      </Field>
                    </div>
                    <div className={`__field`}>
                      <Field
                        name={"note"}
                        validate={FormValidators.compose(
                          FormValidators.max(1000)
                        )}
                      >
                        {({ input, meta }) => (
                          <BFInput
                            disabled={props.disabled}
                            type="textarea"
                            {...input}
                            label={`${i18n.t(
                              "Contact.Form.Fields.Note",
                              "Notiz"
                            )}`}
                            validation={
                              meta.error && meta.touched
                                ? {
                                    level: "error",
                                    message: meta.error,
                                  }
                                : undefined
                            }
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                )}
              </FormFieldValues>
            ))}
          </div>
        </div>
      )}
    </FieldArray>
  );
};
