import {
  getConfigRentalStatus,
  getConfigRentalUnitTypeGroup,
} from "@/apps/tatar/cashBudget/views/tenants/CBTenantsConst";
import {
  RentalUnitTypeGroup,
  allRentalStatus,
  allRentalUnitTypeGroups,
} from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import {
  ObjectKind,
  ObjectKindFeature,
  ObjectKindFeature_IMMO,
  ObjectKindType,
  RentalUnitType,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { BFChooserOption } from "@/modules/abstract-ui/forms/chooser/BFChooser";
import { AssetSelectProps } from "@/modules/ez-form/form-elements/ez-asset-select/EZAssetSelectDropdown";
import LanguageService from "@/services/LanguageService";
import { isDefined } from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import { HTTP } from "../../../../utils/Http";
import { StructType } from "../../../reducers/struct/StructInterface";
import {
  AbstractStructSelectors,
  DataByUnitType,
} from "../AbstractStructSelectors";

class ObjectKindStructClass extends AbstractStructSelectors<ObjectKind[]> {
  getStructType(): StructType {
    return "objectKind";
  }
  loadStructData(types: string[]): Promise<DataByUnitType<ObjectKind[]>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: `/api/portfolioObject/getPortfolioObjectKindStructure`,
          target: "EMPTY",
          queryParams: {
            param: {
              types: types,
            },
          },
        })) as ObjectKind[];
        const result: DataByUnitType<ObjectKind[]> = {};
        types.forEach((type) => {
          result[type] = data?.filter((config) => config.data.type === type);
        });
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }
  getKind(id: string) {
    return this.useCache<ObjectKind>("getKind", arguments, () => {
      return this.getAllData()
        .flat()
        .find((kind) => kind._id === id);
    });
  }
  getAllKinds() {
    return this.useCache<ObjectKind[]>("getAllKinds", arguments, () => {
      return this.getAllData().flat();
    });
  }
  getKinds(types: string[]) {
    return this.useCache<ObjectKind[]>("getKinds", arguments, () => {
      return this.getAllData()
        .flat()
        .filter((kind) => !types || types.includes(kind.data.type));
    });
  }

  getKindFeature<T extends ObjectKindFeature>(
    id: string,
    kindType: ObjectKindType
  ) {
    return this.useCache<T>("getKindInfo", arguments, () => {
      const kind = this.getKind(id);

      const feature = kind.data.features?.find((e) => e.type === kindType);

      return feature as T;
    });
  }

  getAllUnitTypes() {
    return this.useCache<RentalUnitType[]>("getAllUnitTypes", arguments, () => {
      return this.getAllData()
        .flat()
        .map(
          (k) =>
            (
              k.data.features?.find(
                (e) => e.type === "immo"
              ) as ObjectKindFeature_IMMO
            )?.rentalUnitTypes || []
        )
        .flat();
    });
  }
  getUnitTypeBy(id: string) {
    return this.useCache<RentalUnitType>("getUnitTypeBy", arguments, () => {
      return this.getAllUnitTypes().find((e) => e.id === id);
    });
  }
  getUnitTypeByObjectKind(objectKindId: string) {
    return this.useCache<RentalUnitType[]>(
      "getUnitTypeByType",
      arguments,
      () => {
        return (
          (
            this.getAllData()
              .flat()
              .find((e) => e._id === objectKindId)
              ?.data?.features?.find(
                (e) => e.type === "immo"
              ) as ObjectKindFeature_IMMO
          )?.rentalUnitTypes || []
        );
      }
    );
  }
  getGroupByStackingPlanUnitType(type: "areal" | "parking" | "other") {
    return this.useCache<RentalUnitTypeGroup[]>(
      "getGroupByStackingPlanUnitType",
      arguments,
      () => {
        switch (type) {
          case "areal":
            return ["commercial", "residental", "storage"];
          case "parking":
            return ["parking"];
          case "other":
          case undefined:
            return ["other"];
        }
      }
    );
  }
  getUnitTypeStackingPlanGroup(id: string) {
    return this.useCache<"areal" | "parking" | "other">(
      "getUnitTypeStackingPlanGroup",
      arguments,
      () => {
        const unit = this.getUnitTypeBy(id);
        switch (unit?.group) {
          case "commercial":
          case "residental":
          case "storage":
            return "areal";
          case "parking":
            return "parking";
          case "other":
          case undefined:
            return "other";
        }
      }
    );
  }

  getUnitTypesByGroup(objectKindId: string, group?: RentalUnitTypeGroup) {
    return this.useCache<RentalUnitType[]>(
      "getUnitTypesByGroup",
      arguments,
      () =>
        this.getUnitTypeByObjectKind(objectKindId)?.filter((e) =>
          isDefined(group) ? e.group === group : true
        )
    );
  }
  getRentalUnitAssetSelectionOptions(
    defaultData: Partial<AssetSelectProps>,
    objectKindId: string
  ) {
    return this.useCache<AssetSelectProps[]>(
      "getRentalUnitAssetSelectionOptions",
      arguments,
      () => {
        const unitTypes = [null, ...allRentalUnitTypeGroups];
        const rentalStatus = [null, ...allRentalStatus];
        const options: AssetSelectProps[] = [];

        unitTypes.forEach((unitType) => {
          const possibleUnitTypes = this.getUnitTypesByGroup(
            objectKindId,
            unitType
          )?.map((e) => e.id);
          if (possibleUnitTypes.length > 0) {
            rentalStatus.forEach((status) => {
              options.push({
                assetType: AssetTypes.Rental.RentalUnit,
                id: `${unitType || "all"}-${status || "all"}`,
                label:
                  status === null
                    ? i18n.t("cb:RentalUnit.status.all", "Gesamt")
                    : getConfigRentalStatus(status).label,
                group:
                  unitType === null
                    ? i18n.t("cb:RentalUnitTypeGroup.all", "Alle Nutzungsarten")
                    : getConfigRentalUnitTypeGroup(unitType).label,
                ...defaultData,
                matchQuery: MQ.combineSpread(
                  "and",
                  defaultData?.matchQuery,
                  unitType !== null && possibleUnitTypes.length > 0
                    ? {
                        type: "op",
                        op: "in",
                        name: "data.unitType",
                        value: possibleUnitTypes,
                      }
                    : null,
                  status !== null
                    ? {
                        type: "op",
                        op: "eq",
                        name: "data.rentalStatus",
                        value: status,
                      }
                    : null
                ),
              } as AssetSelectProps);
            });
          }
        });
        return options;
      }
    );
  }

  getCatalog(
    objectKindId: string,
    kindType: ObjectKindType,
    catalogName: string
  ) {
    return this.useCache<BFChooserOption<string>[]>(
      "getCatalog",
      arguments,
      () => {
        return (
          this.getAllData()
            .flat()
            .find((e) => e._id === objectKindId)
            ?.data?.features?.find((e) => e.type === kindType)?.["catalogs"]?.[
            catalogName
          ] || []
        ).map((entry) => ({
          value: entry.value,
          label: LanguageService.translateLabel(entry.label),
          group: entry.group
            ? LanguageService.translateLabel(entry.group)
            : undefined,
          subLabel: entry.subLabel
            ? LanguageService.translateLabel(entry.subLabel)
            : undefined,
        }));
      }
    );
  }

  getUnitTypeSelectOptions(objectKindId: string, groupFilters?: string[]) {
    return this.useCache<{ label: string; value: string }[]>(
      "getStatusSelectOptions",
      arguments,
      () =>
        this.getUnitTypeByObjectKind(objectKindId)
          ?.map((e) => {
            const group = getConfigRentalUnitTypeGroup(e.group);

            if (groupFilters) {
              if (!groupFilters.includes(e.group)) {
                return null;
              }
            }
            return {
              label: LanguageService.translateLabel(e.displayName),
              value: e.id,
              group: group?.label,
              groupOrder: group?.order,
            };
          })
          .filter((e) => e)
    );
  }
}
const ObjectKindStruct = new ObjectKindStructClass();
(window as any).ObjectKindStruct = ObjectKindStruct;
export default ObjectKindStruct;
