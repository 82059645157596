import { IComponent } from "../../../../configurable/layouts/IComponent";
import { MatchQuery } from "../../../../services/DataService";
import { ComponentsMapper } from "../../../../utils/ComponentsMapper";
import AssetAssign from "../../../abstract-ui/assets/asset-assign/AssetAssign";
import GenericFormField from "../../../generic-forms/GenericFormField";
import { GenericFormsLayoutProps } from "../../../generic-forms/GFBaseElement";
import ExpressionHelper from "../../../generic-forms/util/ExpressionHelper";
import { JsonPropertyCommon } from "../../../generic-forms/util/JsonValidation";

export interface JsonAssetAssignProps extends JsonPropertyCommon {
  _component: "asset";
  assetType: string;
  multiple?: boolean;
  max?: number;
  tableHeight?: number;
  tableIdentifier: string;
  renderAsset: IComponent;
  matchQuery?: MatchQuery;
  _matchQuery?: string;
  sort?: {
    dataKey: string;
    sortType: "asc" | "desc";
  }[];
}
type GFAssetAssignProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonAssetAssignProps;
export const GFAssetAssign: React.FC<GFAssetAssignProps> = (props) => {
  const { name } = props;
  const forceFormSpy = !!props._matchQuery;
  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      forceFormSpy={forceFormSpy}
      jsonProperty={props as any}
      render={(input, meta, name1, jsonProperty, currentValues) => {
        const useMatchQuery = props._matchQuery
          ? ExpressionHelper.evaluateExpression(
              props._matchQuery,
              currentValues,
              props.params
            )
          : props.matchQuery;

        return (
          <div className={"gf-asset-assign"}>
            <AssetAssign
              matchQuery={useMatchQuery}
              validation={{
                level: "error",
                message:
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : undefined,
              }}
              sort={props.sort}
              label={props.label}
              assetType={props.assetType}
              formatAsset={(asset) =>
                ComponentsMapper.createElement(props.renderAsset, asset)
              }
              multiple={props.multiple}
              value={input.value}
              onChange={input.onChange}
              max={input.max}
              tableIdentifier={props.tableIdentifier}
              tableHeight={props.tableHeight}
            />
          </div>
        );
      }}
    />
  );
};
