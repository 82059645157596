import React from "react";
import { useDispatch } from "react-redux";
import SplitPane from "react-split-pane";
import Log from "../../../debug/Log";
import Customization from "../../../redux/actions/customization/customization-actions";
import { useTypedSelector } from "../../../redux/hooks";
import { useDelay } from "../../../utils/Hooks";
import "./PersistentSplitPane.scss";

export type Size = string | number;

export type Split = "vertical" | "horizontal";

const LOCALSTORAGE_KEY_PREFIX = "__split-pane_";

interface PersistentSplitPaneProps {
  identifier: string;

  children: React.ReactNode;
  allowResize?: boolean;
  primary?: "first" | "second";
  minSize?: Size;
  maxSize?: Size;
  defaultSize?: Size;
  split?: Split;
  onSizeChange?: (size: number) => void;
}
const PersistentSplitPane = ({
  identifier,
  onSizeChange,
  ...props
}: PersistentSplitPaneProps) => {
  //   size?: Size;
  const dispatch = useDispatch();
  const { delay } = useDelay((size: number) => {
    if (identifier) {
      Log.info("delayed save of splitpane", identifier, size);
      dispatch(
        Customization.setFlexValue(
          `${LOCALSTORAGE_KEY_PREFIX}${identifier}`,
          size
        )
      );
    }
  }, 500);
  const timeoutRef = React.useRef<number>(null);
  const savedData = useTypedSelector(
    (state) =>
      state.customizations.flex[`${LOCALSTORAGE_KEY_PREFIX}${identifier}`]
  );
  const onChange = (size: number) => {
    onSizeChange?.(size);
    delay(size);
  };

  const defaultSizeToUse = savedData || props.defaultSize;
  return (
    <SplitPane {...props} onChange={onChange} defaultSize={defaultSizeToUse} />
  );
};

export default PersistentSplitPane;
