import classNames from "classnames";
import { Field, FormRenderProps } from "react-final-form";
import FormValidators from "../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../i18n";
import BFAssignmentDefault from "../../../../abstract-ui/forms/assignment/BFAssignmentDefault";
import ValidationPopover from "../../../../abstract-ui/general/ValidationPopover/ValidationPopover";
import RSRemoveAdditionalFieldBtn from "./RSRemoveAdditionalFieldBtn";

interface RSFeedbackFormPartProps {
  formProps: FormRenderProps<any>;
}

const RSFeedbackFormPart = (props: RSFeedbackFormPartProps) => {
  const { formProps } = props;

  return (
    <div className={classNames("rs-feedback-formpart")}>
      <div className={`section-title __h3 margin-bottom-10 margin-top-20`}>
        {i18n.t("Resubmission.Titles.Feedback", "Rückmeldung")}
      </div>
      <div className="row">
        <Field
          name={"feedbackRecipients"}
          validate={FormValidators.requiredBFAssignmentValue()}
        >
          {({ input, meta }) => (
            <div className={`__field`}>
              <ValidationPopover
                level="error"
                message={meta.error && meta.touched ? meta.error : undefined}
              >
                <BFAssignmentDefault
                  label={i18n.t(
                    "Resubmission.Labels.RecipientsFeedback",
                    "Empfänger Rückmeldung"
                  )}
                  asOverlay
                  type="both"
                  value={input.value}
                  onChange={input.onChange}
                  error={meta.touched && meta.error}
                />
              </ValidationPopover>
            </div>
          )}
        </Field>
        <div className="fill" />
        <div className={classNames("additional-field-remove")}>
          <RSRemoveAdditionalFieldBtn
            clearFields={() => {
              formProps.form.mutators.setValue("helpers.showFeedback", false);
              formProps.form.mutators.setValue("feedbackRecipients", null);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RSFeedbackFormPart;
