const defaultBackgroundColors = [
  "994d00",
  "669900",
  "99004d",
  "990000",
  "999900",
  "003d99",
  "003366",
  "5c5c3d",
  "4d0099",
];

export const generateAvatarString = (
  displayName: string,
  renderEmpty: boolean,
  withoutHashtag = false
) => {
  if (!displayName) {
    if (renderEmpty) {
      return " #AAAAAA";
    } else {
      return null;
    }
  }
  let character = displayName.trim()[0];

  let number = 0;

  displayName.split("").forEach((char) => (number += char.charCodeAt(0)));

  const color =
    defaultBackgroundColors[number % defaultBackgroundColors.length];

  if (withoutHashtag) {
    return color;
  } else {
    return `#${color}`;
  }
};
export const getDisplayNameInitials = (displayName: string) => {
  const matches = (displayName ? displayName.split(" ") : []).map(
    (e) => e[0]
  );
  const initials = (
    (matches.shift() || "") + (matches.pop() || "")
  ).toUpperCase();

  return initials;
};

export const getAvatarImageUrl = (
  width: number,
  displayName: string,
  avatar?: string
) => {
  let urlToFetch;
  if (avatar) {
    urlToFetch = avatar;
  } else {
    const initials = getDisplayNameInitials(displayName);
    const color = generateAvatarString(displayName, true, true);

    urlToFetch = `https://ui-avatars.com/api/?name=${initials}&background=${color}&color=ffffff&size=${width}&rounded=true`;
  }

  return urlToFetch;
};
