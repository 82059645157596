import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import moment from "moment";
import { Field } from "react-final-form";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../i18n";
import BFCheckbox from "../../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "../../../../../../modules/abstract-ui/forms/input/BFInput";
import {
  APContractActivity,
  APMaintenanceAppointment,
} from "./APActivityMainteanceInterfaces";
import "./APActivityMaintenanceCancelForm.scss";
import APActivityMaintenanceService from "./APActivityMaintenanceService";

interface APActivityMaintenanceCancelFormProps {
  activity: APContractActivity;
  appointment: APMaintenanceAppointment;

  onClose: () => void;
}
const APActivityMaintenanceCancelForm = (
  props: APActivityMaintenanceCancelFormProps
) => {
  const schedule = props.activity.data.schedulingRules.find(
    (e) => e.id === props.appointment.scheduleRuleId
  );
  let nextDate;
  if (schedule.type === "regular") {
    nextDate = moment(props.appointment.dueDate)
      .add(schedule.typeData.numberOfMonths, "months")
      .toDate();
  } else {
    nextDate = null;
  }
  return (
    <FormStruct
      onSubmit={async (values) => {
        await APActivityMaintenanceService.cancelAppointment(
          props.activity._id,
          props.appointment.id,
          {
            type: "canceled",
            note: values.note,
            // maintenanceDate: values.maintenanceDate,
            statusChangeNote: "",
            appointmentStartDate: values.followAppointment
              ? values.appointmentStartDate
              : null,
          }
        );
        props.onClose();
      }}
      title={i18n.t(
        "apTemplate:Activity.subtitles.maintenance.cencelForm.title",
        "Fälligkeit entfernen"
      )}
      description={i18n.t(
        "apTemplate:Activity.subtitles.maintenance.cencelForm.description",
        "Wollen Sie die Fälligkeit wirklich entfernen? Sie können ein neues Fälligkeitsdatum definieren."
      )}
      initialValues={{
        note: "",
        maintenanceDate: new Date(),
        type: "done",
        appointmentStartDate: nextDate,
        followAppointment: true,
      }}
      submitText={i18n.t(
        "apTemplate:Activity.subtitles.maintenance.cencelForm.submit",
        "Fälligkeit entfernen"
      )}
      onAbort={props.onClose}
      render={(formProps) => (
        <div className={`ap-activity-maintenance-cancel-form`}>
          <div className={`form-content`}>
            {/* <div className={`__field`}>
              <Field
                name="maintenanceDate"
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div style={{ display: "inline-block" }}>
                    <BFDatefield
                      {...input}
                      {...FormValidators.getValidation(meta)}
                      cleanable={false}
                      label={i18n.t(
                        "apTemplate:Activity.subtitles.maintenance.cencelForm.maintenanceDate",
                        "Fertigstellung"
                      )}
                    />
                  </div>
                )}
              </Field>
            </div> */}
            <div className={`__field`}>
              <Field
                name="note"
                // validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <BFInput
                    type="textarea"
                    {...input}
                    {...FormValidators.getValidation(meta)}
                    label={i18n.t(
                      "apTemplate:Activity.subtitles.maintenance.cencelForm.note",
                      "Notiz"
                    )}
                  />
                )}
              </Field>
            </div>
            <Field
              name="followAppointment"
              // validate={FormValidators.compose(FormValidators.required())}
            >
              {({ input, meta }) => (
                <>
                  <div className={`__field`}>
                    <BFCheckbox
                      checked={input.value}
                      onChange={(_, checked) => {
                        input.onChange(checked);
                      }}
                    >
                      {i18n.t(
                        "apTemplate:Activity.subtitles.maintenance.cencelForm.followAppointment",
                        "Erstelle neue Fälligkeit"
                      )}
                    </BFCheckbox>
                  </div>
                  {input.value && (
                    <div className={`__field`}>
                      <Field
                        name="appointmentStartDate"
                        validate={FormValidators.compose(
                          FormValidators.required()
                        )}
                      >
                        {({ input, meta }) => (
                          <div style={{ display: "inline-block" }}>
                            <BFDatefield
                              {...input}
                              {...FormValidators.getValidation(meta)}
                              preventNull
                              label={i18n.t(
                                "apTemplate:Activity.subtitles.maintenance.cencelForm.appointmentStartDate",
                                "Nächste Fälligkeit"
                              )}
                              hint={
                                !meta.dirty
                                  ? i18n.t(
                                      "apTemplate:Activity.subtitles.maintenance.cencelForm.appointmentStartDateHint",
                                      "Berechnet anhand des angegebenen Intervalls."
                                    )
                                  : undefined
                              }
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  )}
                </>
              )}
            </Field>
          </div>
        </div>
      )}
    />
  );
};

export default APActivityMaintenanceCancelForm;
