import ModalManager from "@/components/ModalComponent/ModalManager";
import WrappedText from "@/components/WrappedText/WrappedText";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { RentalAgreement } from "../../../TenantsInterfaces";
// import "./CBAgreementContractBasedata.scss";

export type ContractBaseDataType = {
  contractType: "business" | "private";
  id: string;
  displayName: string;
  note: null | string;
};
interface CBAgreementContractBasedataProps {
  data: ContractBaseDataType;
  rentalAgreement?: RentalAgreement;
  editable?: boolean;
}
const CBAgreementContractBasedata = (
  props: CBAgreementContractBasedataProps
) => {
  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      value={[]}
      title={
        <>
          <span className={`padding-right-5`}>
            {i18n.t(
              "cb:RentalAgreement.Form.Fields.contract.title",
              "Vertragsdaten"
            )}
          </span>
          {props.rentalAgreement && props.editable && (
            <BFButton
              noPadding
              inline
              appearance="link"
              onClick={() => {
                ModalManager.show({
                  size: "fluid",
                  noPadding: true,
                  content: (state, setState, close) => {
                    return (
                      <div>test</div>
                      //   <CBRentalAgreementDepositUpdateForm
                      //     rentalAgreement={props.rentalAgreement}
                      //     onClose={close}
                      //   />
                    );
                  },
                });
              }}
              size="sm"
            >
              {i18n.t("Global.Buttons.Customize", "Anpassen")}
            </BFButton>
          )}
        </>
      }
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.contract.viewHint",
        "Informationen zum Vertrag"
      )}
      identifier="contract"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      <>
        <div className={`__flex-wrap`}>
          <BFValueDisplay
            label={i18n.t(
              "cb:RentalAgreement.Form.Fields.contractType",
              "Vertragsart"
            )}
            value={props.data.contractType}
            formatter={(value) =>
              value === "business"
                ? i18n.t("cb:RentalAgreement.Form.business", "Gewerblich")
                : i18n.t("cb:RentalAgreement.Form.private", "Privat")
            }
          />
          <BFValueDisplay
            label={i18n.t("cb:RentalAgreement.Form.Fields.ID", "ID")}
            value={props.data.id}
          />
          <BFValueDisplay
            label={i18n.t(
              "cb:RentalAgreement.Form.Fields.displayName",
              "Anzeigename"
            )}
            value={props.data.displayName}
          />
        </div>

        <div className={`__flex-wrap margin-top-10`}>
          <BFValueDisplay
            label={i18n.t("cb:RentalAgreement.Form.Fields.note", "Notiz")}
            value={props.data.note}
            formatter={(value) => <WrappedText text={value} />}
          />
        </div>
      </>
    </BFPDFMarkerSection>
  );
};

export default CBAgreementContractBasedata;
