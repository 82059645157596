import { Badge } from "rsuite";

interface Props {
  content?: string;
  children: JSX.Element;
  color?: "red" | "orange" | "yellow" | "green" | "cyan" | "blue" | "violet";
}
const BFBadge = (props: Props) => {
  if (!props.content) {
    return props.children as JSX.Element;
  }
  return (
    <Badge color={props.color} content={props.content}>
      {props.children}
    </Badge>
  );
};
export default BFBadge;
