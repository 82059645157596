import { ActivityAbstractStructClass } from "@/redux/actions/struct/implemented/ActivityAbstractStruct";
import classNames from "classnames";
import i18n from "../../../../../../i18n";
import {
  MailIncomingComment,
  MailOutgoingComment,
} from "../../../../../../modules/comments-module/Comments";
import CommentsService from "../../../../../../modules/comments-module/CommentsService";
import DataBusDefaults from "../../../../../../services/DataBusDefaults";
import { useActivityConstants } from "../../../ActivityHooks";
import APAssignMessageToActivity from "../../unassignedMessages/APAssignMessageToActivity";

interface APDuplicateMessageToActivityProps {
  onClose: () => void;
  activityStruct: ActivityAbstractStructClass<any>;
  mail: MailIncomingComment | MailOutgoingComment;
}

const APDuplicateMessageToActivity = (
  props: APDuplicateMessageToActivityProps
) => {
  const constants = useActivityConstants();

  return (
    <div className={classNames("ap-duplicate-message-to-activity")}>
      <APAssignMessageToActivity
        activityStruct={props.activityStruct}
        onClose={props.onClose}
        mail={props.mail}
        title={i18n.t(
          "apTemplate:Activity.Duplicate.AssignMessageToActivity.Title",
          "E-Mail kopieren und neuer Aktivität zuordnen"
        )}
        description={i18n.t(
          "apTemplate:Activity.Duplicate.AssignMessageToActivity.Description",
          "Bitte wählen Sie die Aktivität aus, zu der die E-Mail kopiert werden soll."
        )}
        assignActionLabel={i18n.t(
          "apTemplate:Activity.Duplicate.AssignMessageToActivity.Assign",
          "E-Mail kopieren und zuordnen"
        )}
        onAssign={async (damage) => {
          try {
            await CommentsService.duplicateMailToOtherActivity(
              props.mail._id,
              constants?.assetType,
              damage._id
            );
            DataBusDefaults.toast({
              type: "success",
              text: i18n.t(
                "apTemplate:Activity.Duplicate.AssignMessageToActivity.Success",
                "E-Mail erfolgreich kopiert zur Aktivität zugeordnet",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
            });

            props.onClose();
          } catch (err) {
            DataBusDefaults.toast({
              type: "error",
              text: i18n.t(
                "apTemplate:Activity.Duplicate.AssignMessageToActivity.Error",
                "Fehler beim Kopieren der E-Mail und beim Zuordnen zur Aktivität",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
            });
          }
        }}
      />
    </div>
  );
};

export default APDuplicateMessageToActivity;
