import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { Nav } from "rsuite";
import BfIcon, { BfIconProps } from "../../../modules/abstract-ui/icon/BfIcon";
import ExpressionHelper from "../../../modules/generic-forms/util/ExpressionHelper";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import { IComponent } from "../IComponent";
import "./NavLayout.scss";

type Props = {
  routePrefix?: string;
  appearance: "tabs" | "subtle";
  routeInterception?: string;
  hideTextBreakpoint?: number;
  subPages: {
    [key: string]: {
      orderIndex?: number;
      icon?: BfIconProps;
      textKey: string;
      component: IComponent;
    };
  };
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class NavLayout extends AbstractStylableComponent<Props, States> {
  static defaultProps = {
    appearance: "tabs",
  };
  readonly state: States = {};

  componentDidMount() {
    if (this.props.identifier) {
      const activeSubpage = Object.entries(this.props.subPages).find(
        ([key, obj]) => this.props.location.pathname.indexOf("/" + key) !== -1
      );
      if (activeSubpage) {
        this.populateComponentState({
          active: activeSubpage[0],
          translated: this.props.i18n.t(activeSubpage[1].textKey),
          textHidden:
            this.props.hideTextBreakpoint &&
            this.props.viewportWidth <= this.props.hideTextBreakpoint,
        });
      }
    }
  }
  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      (prevProps.hideTextBreakpoint &&
        prevProps.hideTextBreakpoint < prevProps.viewportWidth !==
          this.props.hideTextBreakpoint < this.props.viewportWidth)
    ) {
      if (this.props.identifier) {
        const activeSubpage = Object.entries(this.props.subPages).find(
          ([key, obj]) => this.props.location.pathname.indexOf("/" + key) !== -1
        );
        if (activeSubpage) {
          this.populateComponentState({
            active: activeSubpage[0],
            translated: this.props.i18n.t(activeSubpage[1].textKey),
            textHidden:
              this.props.hideTextBreakpoint &&
              this.props.viewportWidth <= this.props.hideTextBreakpoint,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    if (
      nextProps.hideTextBreakpoint &&
      nextProps.hideTextBreakpoint < nextProps.viewportWidth !==
        this.props.hideTextBreakpoint < this.props.viewportWidth
    ) {
      return true;
    }
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  handleSelect(activeKey) {
    if (this.props.routeInterception) {
      this.props.history.push(
        ExpressionHelper.evaluateExpression(this.props.routeInterception, {
          location: this.props.location.pathname,
          routeTo: activeKey,
        })
      );
    } else {
      this.props.history.push(`${this.props.match.url}/${activeKey}`);
    }
  }

  render() {
    if (!this.shoudBeRendered()) {
      return null;
    }
    const {
      subPages,
      appearance,
      i18n,
      match,
      location,
      hideTextBreakpoint,
      viewportWidth,
    } = this.props;

    const activeSubpage = Object.keys(subPages).find(
      (subPage) => location.pathname.indexOf("/" + subPage) !== -1
    );

    return (
      <div className={`nav-layout`} style={this.state.usedStyle}>
        <div className={`nav-bar`}>
          <Nav
            vertical
            appearance={appearance}
            activeKey={activeSubpage}
            onSelect={(eventKey) => this.handleSelect(eventKey)}
          >
            {Object.entries(subPages)
              .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
              .map(([key, subPage]) => {
                return (
                  <Nav.Item
                    className={`${
                      !hideTextBreakpoint || viewportWidth > hideTextBreakpoint
                        ? ""
                        : "hide-text"
                    }`}
                    key={key}
                    eventKey={key}
                    icon={
                      subPage.icon ? <BfIcon {...subPage.icon} /> : undefined
                    }
                  >
                    {!hideTextBreakpoint || viewportWidth > hideTextBreakpoint
                      ? i18n.t(subPage.textKey)
                      : ""}
                  </Nav.Item>
                );
              })}
          </Nav>
        </div>
        <div className={`content`}>
          <Switch>
            {Object.entries(subPages)
              .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
              .map(([key, subPage]) => {
                return (
                  <Route
                    key={key}
                    path={`*/${key}`}
                    children={({ match }) => {
                      return (window as any).ComponentsMapper.createElement(
                        subPage.component,
                        this.props.params
                      );
                    }}
                  />
                );
              })}
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth:
    Array.isArray(props.style) || props.hideTextBreakpoint
      ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
      : null,
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(NavLayout))
);
