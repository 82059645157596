import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import { CustomField } from "@/modules/customfields/CustomField.interface";
import CustomFields from "@/modules/customfields/CustomFields";
import CustomFieldsService from "@/modules/customfields/CustomFieldsService";
import { hasValue, isDefined, translateNonGenerate } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import React, { useState } from "react";
import { Field } from "react-final-form";
import { ActivityApplicationConstants } from "../../../../ActivityHooks";
import ActivityService from "../../../../ActivityService";
import { APOffer, APOfferFormValue } from "../APOffer.Interface";
// import './APOfferForm.scss';

interface APOfferFormProps {
  activityId: string;
  onClose: () => void;
  onSuccess?: (asset: APOffer) => void;
  asset?: APOffer;
  constants: ActivityApplicationConstants;
}

const getInitialValue = (asset: APOffer, customFields: CustomField[]) => {
  return {
    value: asset?.data.value || 0,
    displayName: asset?.data.displayName || "",
    meta: CustomFieldsService.getInitialValuesForCustomfields(
      customFields,
      asset?.data.meta || {}
    ),
    status: isDefined(asset) ? asset.data.status : undefined,
  } as APOfferFormValue;
};

const transformFormdataToSubmitdata = (values: APOfferFormValue) => {
  return {};
};

const APOfferForm: React.FC<APOfferFormProps> = (props) => {
  const constants = props.constants;
  const [initial] = useState(
    getInitialValue(props.asset, constants?.fields?.assetApproval?.fields)
  );
  return (
    <FormStruct
      className={classNames("ap-offer-form")}
      onSubmit={async (values: APOfferFormValue) => {
        const asset = (await ActivityService.submitOffer(
          props.activityId,
          values,
          constants,
          props.asset
        )) as APOffer;

        props.onSuccess?.(asset);
        props.onClose();
      }}
      title={
        props.asset
          ? translateNonGenerate(constants.fields?.assetApproval?.updateTitle)
          : translateNonGenerate(constants.fields?.assetApproval?.createTitle)
      }
      ignoreSubmitOnEnter
      usePrompt
      //			 description={props.asset ? i18n.t("CBBookingCategoryRuleView.UpdateDescription", "Ändern Sie die Daten des Assets und speichern Sie.") : i18n.t("CBBookingCategoryRuleView.CreateDescription", "Erstellen Sie ein neues Asset und speichern Sie.")}
      submitText={
        props.asset
          ? i18n.t("apTemplate:APOfferForm.SubmitUpdate", "Speichern")
          : i18n.t("apTemplate:APOfferForm.SubmitCreate", "Erstellen")
      }
      onAbort={props.onClose}
      initialValues={initial}
      render={(form) => (
        <>
          {/* 
        name
        value
        custom fields
        
        */}
          {props.asset && (
            <div>
              <Field
                name="status"
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div className={`__field`}>
                    <BFChooserSelect
                      {...input}
                      label={i18n.t("apTemplate:APOfferForm.status", "Status")}
                      {...FormValidators.getValidation(meta)}
                      data={[
                        {
                          label: i18n.t("apTemplate:APOfferForm.new", "Neu"),
                          value: "new",
                        },
                        {
                          label: i18n.t(
                            "apTemplate:APOfferForm.statusAccepted",
                            "Angenommen"
                          ),
                          value: "accepted",
                        },
                        {
                          label: i18n.t(
                            "apTemplate:APOfferForm.statusDeclined",
                            "Abgelehnt"
                          ),
                          value: "declined",
                        },
                        {
                          label: i18n.t(
                            "apTemplate:APOfferForm.statusArchived",
                            "Archiviert"
                          ),
                          value: "archived",
                        },
                      ]}
                    />
                  </div>
                )}
              </Field>
            </div>
          )}
          <div className={`__flex`}>
            <Field
              name="displayName"
              validate={FormValidators.compose(FormValidators.required())}
            >
              {({ input, meta }) => (
                <div className="__field __flex-2">
                  <BFInput
                    {...input}
                    label={i18n.t("apTemplate:APOfferForm.displayName", "Name")}
                    {...FormValidators.getValidation(meta)}
                  />
                </div>
              )}
            </Field>
            <Field
              name="value"
              validate={FormValidators.compose(FormValidators.required())}
            >
              {({ input, meta }) => (
                <div className="__field __flex-1">
                  <BFInput
                    type={"priceInput"}
                    suffix={StringUtils.getCurrencySymbol()}
                    textAlign="right"
                    label={
                      constants?.fields?.assetApproval?.valueLabel ||
                      i18n.t("apTemplate:APOfferForm.value", "Wert")
                    }
                    {...input}
                    {...FormValidators.getValidation(meta)}
                  />
                </div>
              )}
            </Field>
          </div>

          {hasValue(constants?.fields?.assetApproval?.fields) && (
            <CustomFields
              asset={props.asset}
              assetType={AssetTypes.Activity.Offer}
              prefix="meta"
              fields={constants?.fields?.assetApproval?.fields}
            />
          )}
        </>
      )}
    />
  );
};

export default APOfferForm;
