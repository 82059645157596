import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import { ACObject } from "../../../../../apps/AppConfigInterfaces";
import i18n from "../../../../../i18n";
import ArrayUtils from "../../../../../utils/ArrayUtils";
import { isDefined } from "../../../../../utils/Helpers";
import MQ from "../../../../../utils/MatchQueryUtils";
import { FilterOptionWithMatchQueryConverter } from "../BFTableFilterModel";
import OverlayMultipleSelection from "../overlays/OverlayMultipleSelection";

export const DefaultFilterOptions: (opts?: {
  filterTypes?: string[];
  unitField?: string;
  entityField?: string;
  objectField?: string;
  hideEntityFilter?: boolean;
  hideObjectFilter?: boolean;
  hideUnitFilter?: boolean;
}) => FilterOptionWithMatchQueryConverter[] = (opts) => {
  const filterTypes = opts?.filterTypes;
  const unitField = opts?.unitField || "data.type";
  const entityField = opts?.entityField || "data.entity";
  const objectField = opts?.objectField || "data.objectId";
  const hideEntityFilter = isDefined(opts?.hideEntityFilter)
    ? opts.hideEntityFilter
    : false;
  const hideObjectFilter = isDefined(opts?.hideObjectFilter)
    ? opts.hideObjectFilter
    : false;
  const hideUnitFilter = isDefined(opts?.hideUnitFilter)
    ? opts.hideUnitFilter
    : false;
  return [
    ...(!hideUnitFilter
      ? [
          {
            matchQueryConverter: (value: any) =>
              value && value.length > 0 ? MQ.in(unitField, value) : null,
            key: unitField,
            label: i18n.t("Base.unit", "Bereich"),
            icon: {
              type: "light",
              data: "hierarchy-6",
            },
            renderOverlay: (value, onChange) => (
              <OverlayMultipleSelection
                title={i18n.t("Base.unit", "Bereich")}
                entries={UnitStruct.getUnitSelectOptions(filterTypes)}
                value={value}
                onChange={onChange}
              />
            ),
            renderValueData: (value) => ({
              typeLabel: i18n.t("Base.unit", "Bereich"),
              valueLabel: value
                ? value.length < 3
                  ? value
                      .map(
                        (valEntry) => UnitStruct.getUnit(valEntry)?.data.label
                      )
                      .join(", ")
                  : `${value.length}/${
                      UnitStruct.getUnitSelectOptions(filterTypes).length
                    }`
                : null,
            }),
            getOptions: (currentValue, searchTerm) => {
              return UnitStruct.getUnitSelectOptions(filterTypes)
                .filter((e) => (currentValue || []).indexOf(e.value) === -1)
                .map((option, index) => ({
                  filterKey: unitField,
                  typeLabel: i18n.t("Base.unit", "Bereich"),
                  label: option.label,
                  labelAsString: option.label,
                  value: option.value,
                  valueKey: option.value,
                  onSelect: (optionValue, currentValue, onChange) => {
                    onChange(
                      currentValue
                        ? [...currentValue, optionValue]
                        : [optionValue]
                    );
                  },
                }));
            },
          },
        ]
      : []),
    ...(!hideEntityFilter
      ? [
          {
            matchQueryConverter: (value: any) =>
              value && value.length > 0 ? MQ.in(entityField, value) : null,
            key: entityField,
            label: i18n.t("Base.Entity"),
            icon: {
              type: "light",
              data: "building-2",
            },
            renderOverlay: (value, onChange, allFilters) => {
              const objectsToFilter = allFilters.find(
                (e) => e.filterKey === objectField
              )?.value;

              let entities;
              if (objectsToFilter && objectsToFilter.length > 0) {
                entities = OrgaStruct.getEntitiesByObjects(objectsToFilter);
              } else {
                entities = OrgaStruct.getEntities(filterTypes);
              }

              return (
                <OverlayMultipleSelection
                  title={i18n.t("Base.Entity")}
                  entries={ArrayUtils.sortData(
                    entities.map((e) => ({
                      label: e.displayName,
                      value: e._id,
                    })),
                    { dir: "asc", key: "label" }
                  )}
                  value={value}
                  onChange={onChange}
                />
              );
            },
            renderValueData: (value, allFilters) => {
              const objectsToFilter = allFilters.find(
                (e) => e.filterKey === objectField
              )?.value;

              let entities;

              if (objectsToFilter && objectsToFilter.length > 0) {
                entities = OrgaStruct.getEntitiesByObjects(objectsToFilter);
              } else {
                entities = OrgaStruct.getEntities(filterTypes);
              }
              const reducedValue =
                value?.filter((e) => entities.find((e2) => e2._id === e)) || [];

              return {
                typeLabel: i18n.t("Base.Entity"),
                valueLabel: reducedValue
                  ? reducedValue.length === 1
                    ? OrgaStruct.getEntity(reducedValue[0])?.displayName
                    : `${reducedValue.length}/${entities.length}`
                  : null,
              };
            },
            getOptions: (currentValue, searchTerm, allFilters) => {
              const objectsToFilter = allFilters.find(
                (e) => e.filterKey === objectField
              )?.value;

              let entities;
              if (objectsToFilter && objectsToFilter.length > 0) {
                entities = OrgaStruct.getEntitiesByObjects(objectsToFilter);
              } else {
                entities = OrgaStruct.getEntities(filterTypes);
              }

              return entities
                .filter((e) => (currentValue || []).indexOf(e._id) === -1)
                .map((entity, index) => ({
                  filterKey: entityField,
                  typeLabel: i18n.t("Base.Entity"),
                  label: entity.displayName,
                  labelAsString: entity.displayName,
                  value: entity._id,
                  valueKey: entity._id,
                  onSelect: (optionValue, currentValue, onChange) => {
                    onChange(
                      currentValue
                        ? [...currentValue, optionValue]
                        : [optionValue]
                    );
                  },
                }));
            },
          },
        ]
      : []),
    ...(!hideObjectFilter
      ? [
          {
            matchQueryConverter: (value: any) =>
              value && value.length > 0 ? MQ.in(objectField, value) : null,
            key: objectField,
            label: i18n.t("Base.Object"),
            icon: {
              type: "light",
              data: "house-4",
            },
            renderOverlay: (value, onChange, allFilters) => {
              const entitiesToFilter = allFilters.find(
                (e) => e.filterKey === entityField
              )?.value;
              let objects;
              if (entitiesToFilter && entitiesToFilter.length > 0) {
                objects = OrgaStruct.getObjectsByEntities(entitiesToFilter);
              } else {
                objects = OrgaStruct.getAllObjects(filterTypes);
              }

              return (
                <OverlayMultipleSelection
                  title={i18n.t("Base.Object")}
                  entries={ArrayUtils.sortData<ACObject>(objects, [
                    { dir: "asc", key: "id" },
                    { dir: "asc", key: "displayName" },
                  ]).map((e) => ({
                    label: `${e.id} - ${e.displayName}`,
                    value: e._id,
                  }))}
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                  }}
                />
              );
            },
            renderValueData: (value, allFilters) => {
              const entitiesToFilter = allFilters.find(
                (e) => e.filterKey === entityField
              )?.value;

              let objects;
              if (entitiesToFilter && entitiesToFilter.length > 0) {
                objects = OrgaStruct.getObjectsByEntities(entitiesToFilter);
              } else {
                objects = OrgaStruct.getAllObjects(filterTypes);
              }
              const reducedValue =
                value?.filter((e) => objects.find((e2) => e2._id === e)) || [];

              return {
                typeLabel: i18n.t("Base.Object"),
                valueLabel: reducedValue
                  ? reducedValue.length === 1
                    ? OrgaStruct.getObject(reducedValue[0])?.displayName
                    : `${reducedValue.length}/${objects.length}`
                  : null,
              };
            },
            getOptions: (currentValue, searchTerm, allFilters) => {
              const entitiesToFilter = allFilters.find(
                (e) => e.filterKey === entityField
              )?.value;

              let objects;
              if (entitiesToFilter && entitiesToFilter.length > 0) {
                objects = OrgaStruct.getObjectsByEntities(entitiesToFilter);
              } else {
                objects = OrgaStruct.getAllObjects(filterTypes);
              }

              return objects
                .filter((e) => (currentValue || []).indexOf(e._id) === -1)
                .map((entity, index) => ({
                  filterKey: objectField,
                  typeLabel: i18n.t("Base.Object"),
                  label: `${entity.id} - ${entity.displayName}`,
                  labelAsString: `${entity.id} - ${entity.displayName}`,
                  value: entity._id,
                  valueKey: entity._id,
                  onSelect: (optionValue, currentValue, onChange) => {
                    onChange(
                      currentValue
                        ? [...currentValue, optionValue]
                        : [optionValue]
                    );
                  },
                }));
            },
          },
        ]
      : []),
  ];
};
