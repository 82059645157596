import React from "react";
import { IComponent } from "./../../configurable/layouts/IComponent";

export interface GenericFormsLayoutProps {
  components?: IComponent;
  params?: any;
  [key: string]: any;
}

export interface GFBaseElementProps
  extends IComponent,
    GenericFormsLayoutProps {}
export const GFBaseElement: React.FC<GFBaseElementProps> = (props) => {
  let { _component, ...rest } = props;

  if (_component === "property") {
    // link to property
    if (!props.name) {
      console.warn(`couldn't link property ${_component}', no name was given`);
      return null;
    }
    if (!props.params || !props.params.allProperties) {
      console.warn(
        `couldn't link property ${props.name}', no proeprties were given in params`
      );
      return null;
    }
    const allProperties = props.params.allProperties;

    if (allProperties[props.name]) {
      const property = { ...allProperties[props.name], ...rest };
      return (window as any).ComponentsMapper.createElement(
        property,
        property.params,
        property.key
      );
    } else {
      console.warn(
        `couldn't link property ${props.name}', no configuration for this property available`
      );
      return null;
    }
  }
  return (window as any).ComponentsMapper.createElement(
    props,
    props.params,
    props.key
  );
};
