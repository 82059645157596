import ModalManager from "@/components/ModalComponent/ModalManager";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import classNames from "classnames";
import _ from "lodash";
import { ReactNode } from "react";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFStatus from "../../../../../../../modules/abstract-ui/data/status/BFStatus";
import { renderCellValue } from "../../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFDetailsButton from "../../../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import LanguageService from "../../../../../../../services/LanguageService";
import { isDefined } from "../../../../../../../utils/Helpers";
import StringUtils from "../../../../../../../utils/StringUtils";
import CBRentalService from "../../CBRentalService";
import {
  getConfigRentalStatus,
  getConfigRentalUnitTypeGroup,
} from "../../CBTenantsConst";
import { RentalUnit } from "../../TenantsInterfaces";
import "./CBRentalAgreementHelper.scss";

export const RentalUnitTableHeader = (props: {
  endNode?: ReactNode;
  forceShowNet?: boolean;
}) => {
  return (
    <div className={`cb-rental-agreement-rental-unit-entry header-row`}>
      <div className={`displayName column`}>
        {i18n.t("cb:RentalUnit.displayName", "Name")}
      </div>
      <div className={`rentalStatus column`}>
        {i18n.t("cb:RentalUnit.rentalStatus", "Status")}
      </div>
      <div className={`usage-type column`}>
        {i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart")}
      </div>
      <div className={`type column`}>
        {i18n.t("cb:RentalUnit.unitType", "Art")}
      </div>

      <div className={`building column`}>
        {i18n.t("cb:RentalUnit.building", "Gebäude")}
      </div>
      <div className={`floor column`}>
        {i18n.t("cb:RentalUnit.floor", "Etage")}
      </div>
      <div className={`quantity column`}>
        {i18n.t("cb:RentalUnit.quantity", "Fläche/Anzahl")}
      </div>
      <div
        className={classNames(`column rentGross`, {
          "hide-if-too-small": props.forceShowNet,
        })}
      >
        {i18n.t("cb:RentalUnit.rentGross", "Planmiete brutto")}
      </div>
      <div
        className={classNames(`column rentNet`, {
          "hide-if-too-small": !props.forceShowNet,
        })}
      >
        {i18n.t("cb:RentalUnit.rentNet", "Planmiete netto")}
      </div>
      <div
        className={classNames(`column operatingCostGross`, {
          "hide-if-too-small": props.forceShowNet,
        })}
      >
        {i18n.t("cb:RentalUnit.operatingCostGross", "Plan-Nebenkosten brutto")}
      </div>
      <div
        className={classNames(`column operatingCostNet`, {
          "hide-if-too-small": !props.forceShowNet,
        })}
      >
        {i18n.t("cb:RentalUnit.operatingCostNet", "Plan-Nebenkosten netto")}
      </div>
      {props.endNode}
    </div>
  );
};
export const RentalUnitTableFooter = (props: {
  units: RentalUnit[];
  forceShowNet?: boolean;
}) => {
  if (props.units.length === 0) return null;
  return (
    <div className={`cb-rental-agreement-rental-unit-entry footer-row`}>
      <div className={`displayName column`}>
        {props.units.length === 1
          ? i18n.t("cb:RentalUnit.oneUnit", "1 Einheit")
          : i18n.t("cb:RentalUnit.multipleUnits", "{{count}} Einheiten", {
              count: props.units.length,
            })}
      </div>
      <div className={`rentalStatus column`}></div>
      <div className={`usage-type column`}></div>
      <div className={`type column`}></div>

      <div className={`building column`}></div>
      <div className={`floor column`}></div>
      <div className={`quantity column`}>
        {StringUtils.formatArea(
          _.sum(props.units.map((unit) => unit.data.area))
        )}{" "}
        / {_.sum(props.units.map((unit) => unit.data.quantity))}
      </div>
      <div
        className={classNames(`column rentGross`, {
          "hide-if-too-small": props.forceShowNet,
        })}
      >
        {StringUtils.formatCurrency(
          _.sum(props.units.map((unit) => unit.data.rentGross))
        )}
      </div>
      <div
        className={classNames(`column rentNet`, {
          "hide-if-too-small": !props.forceShowNet,
        })}
      >
        {StringUtils.formatCurrency(
          _.sum(props.units.map((unit) => unit.data.rentNet))
        )}
      </div>
      <div
        className={classNames(`column operatingCostGross`, {
          "hide-if-too-small": props.forceShowNet,
        })}
      >
        {StringUtils.formatCurrency(
          _.sum(props.units.map((unit) => unit.data.operatingCostGross))
        )}
      </div>
      <div
        className={classNames(`column operatingCostNet`, {
          "hide-if-too-small": !props.forceShowNet,
        })}
      >
        {StringUtils.formatCurrency(
          _.sum(props.units.map((unit) => unit.data.operatingCostNet))
        )}
      </div>
    </div>
  );
};

export const RentalUnitTableEntry = (props: {
  rentalUnit: RentalUnit;
  asLink?: boolean;
  endNode?: ReactNode;
  forceShowNet?: boolean;
}) => {
  const unit = ObjectKindStruct.getUnitTypeBy(props.rentalUnit.data.unitType);

  if (!unit) return null;
  const rentalStatus = getConfigRentalStatus(
    props.rentalUnit.data.rentalStatus
  );
  const status = getConfigRentalUnitTypeGroup(unit.group);

  const unitType = ObjectKindStruct.getUnitTypeBy(
    props.rentalUnit.data.unitType
  );
  const group = getConfigRentalUnitTypeGroup(unitType?.group);
  return (
    <div className={`cb-rental-agreement-rental-unit-entry`}>
      <div className={`displayName column`}>
        <DebugDataComponent data={props.rentalUnit} />
        {props.asLink ? (
          <BFDetailsButton
            noPadding
            size="xs"
            appearance="link"
            data={{
              assetType: AssetTypes.Rental.RentalUnit,
              assetId: props.rentalUnit._id,
              type: props.rentalUnit.data.type,
            }}
          >
            {[
              props.rentalUnit.data.id,
              LanguageService.translateLabel(props.rentalUnit.data.displayName),
            ]
              .filter((e) => isDefined(e))
              .join(" - ")}
          </BFDetailsButton>
        ) : (
          [
            props.rentalUnit.data.id,
            LanguageService.translateLabel(props.rentalUnit.data.displayName),
          ]
            .filter((e) => isDefined(e))
            .join(" - ")
        )}
      </div>
      <div className={`rentalStatus column`}>
        {rentalStatus && (
          <BFStatus
            size="xs"
            color={rentalStatus.color}
            label={rentalStatus.label}
          />
        )}
      </div>
      <div className={`usage-type column`}>
        {status && (
          <BFStatus size="xs" color={status.color} label={status.label} />
        )}
      </div>
      <div className={`type column`}>
        {renderCellValue(
          ObjectKindStruct.getUnitTypeBy(props.rentalUnit.data.unitType)
            ?.displayName,
          "-",
          (value) => LanguageService.translateLabel(value)
        )}
      </div>
      <div className={`building column`}>
        {renderCellValue(props.rentalUnit.data?.building, "-")}
      </div>
      <div className={`floor column`}>
        {renderCellValue(props.rentalUnit.data?.floor, "-")}
      </div>
      <div className={`quantity column`}>
        {group?.areaType === "area"
          ? renderCellValue(props.rentalUnit.data?.area, "-", (value) =>
              StringUtils.formatArea(value)
            )
          : renderCellValue(props.rentalUnit.data?.quantity, "-")}
      </div>

      <div
        className={classNames(`column rentGross`, {
          "hide-if-too-small": props.forceShowNet,
        })}
      >
        {renderCellValue(
          props.rentalUnit.data?.rentGross,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        )}
      </div>

      <div
        className={classNames(`column rentNet `, {
          "hide-if-too-small": !props.forceShowNet,
        })}
      >
        {renderCellValue(props.rentalUnit.data?.rentNet, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        )}
      </div>

      <div
        className={classNames(`column operatingCostGross`, {
          "hide-if-too-small": props.forceShowNet,
        })}
      >
        {renderCellValue(
          props.rentalUnit.data?.operatingCostGross,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        )}
      </div>

      <div
        className={classNames(`column operatingCostNet`, {
          "hide-if-too-small": !props.forceShowNet,
        })}
      >
        {renderCellValue(
          props.rentalUnit.data?.operatingCostNet,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        )}
      </div>
      {props.endNode}
    </div>
  );
};

export const openModalAddDunningStop = (rentalAgreementId: string) => {
  ModalManager.confirm({
    title: i18n.t(
      "cb:RentalAgreement.Action.AddDunningStop.Title",
      "Mahnstop einrichten"
    ),
    message: i18n.t(
      "cb:RentalAgreement.Action.AddDunningStop.Confirm",
      "Wollen Sie, dass dieser Mietvertrag nicht mehr im Mahnlauf berücksichtigt wird, auch wenn eine Forderung besteht?"
    ),
    confirmButtonText: i18n.t(
      "cb:RentalAgreement.Action.AddDunningStop.ConfirmButton",
      "Mahnstop einrichten"
    ),
    onConfirm: async () => {
      await CBRentalService.setRentalAgreementDunningStop(
        rentalAgreementId,
        true
      );
    },
  });
};
export const openModalRemoveDunningStop = (rentalAgreementId: string) => {
  ModalManager.confirm({
    title: i18n.t(
      "cb:RentalAgreement.Action.RemoveDunningStop.Title",
      "Mahnstop entfernen"
    ),
    message: i18n.t(
      "cb:RentalAgreement.Action.RemoveDunningStop.Confirm",
      "Wollen Sie den Mahnstop für diesen Mietvertrag aufheben? Der Vertrag wird dann wieder im Mahnlauf berücksichtigt."
    ),
    confirmButtonText: i18n.t(
      "cb:RentalAgreement.Action.RemoveDunningStop.ConfirmButton",
      "Mahnstop entfernen"
    ),
    onConfirm: async () => {
      await CBRentalService.setRentalAgreementDunningStop(
        rentalAgreementId,
        false
      );
    },
  });
};

export const openModalAddDebitRunStop = (rentalAgreementId: string) => {
  ModalManager.confirm({
    title: i18n.t(
      "cb:RentalAgreement.Action.AddDebitRunStop.Title",
      "Automatische Sollstellung deaktivieren"
    ),
    message: i18n.t(
      "cb:RentalAgreement.Action.AddDebitRunStop.Confirm",
      "Wollen Sie, dass für diesen Mietvertrag keine automatische Sollstellung mehr durchgeführt wird?"
    ),
    confirmButtonText: i18n.t(
      "cb:RentalAgreement.Action.AddDebitRunStop.ConfirmButton",
      "Deaktivieren"
    ),
    onConfirm: async () => {
      await CBRentalService.setRentalAgreementDebitRunStop(
        rentalAgreementId,
        true
      );
    },
  });
};
export const openModalRemoveDebitRunStop = (rentalAgreementId: string) => {
  ModalManager.confirm({
    title: i18n.t(
      "cb:RentalAgreement.Action.RemoveDebitRunStop.Title",
      "Automatische Sollstellung aktivieren"
    ),
    message: i18n.t(
      "cb:RentalAgreement.Action.RemoveDebitRunStop.Confirm",
      "Wollen Sie die automatische Sollstellung für diesen Mietvertrag wieder aktivieren?"
    ),
    confirmButtonText: i18n.t(
      "cb:RentalAgreement.Action.RemoveDebitRunStop.ConfirmButton",
      "Aktivieren"
    ),
    onConfirm: async () => {
      await CBRentalService.setRentalAgreementDebitRunStop(
        rentalAgreementId,
        false
      );
    },
  });
};
