import AssetImages from "@/components/CDNImageGallery/AssetImages";
import POIMapComponent from "@/components/MapComponent/POIMapComponent";
import ModalManager from "@/components/ModalComponent/ModalManager";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "@/modules/abstract-ui/general/Dropdown/BFDropdown";
import { DropdownItem } from "@/modules/abstract-ui/general/Dropdown/BFDropdownContent";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import PermissionService from "@/services/PermissionService";
import { isNotDefined } from "@/utils/Helpers";
import ObjectForm from "../../../components/object-form/ObjectForm";
import { OAObject } from "../../../types/object.interface";
import {
  ObjectKind,
  checkAnyFeature,
  checkFeature,
} from "../../../types/objectKind.interface";
import ObjectExportDialog from "../../export/ObjectExportDialog";
import ObjectDetailData from "./ObjectDetailData";
import "./ObjectDetailGeneral.scss";
import {
  openModalObjectAddDebitRunStop,
  openModalObjectRemoveDebitRunStop,
} from "./ObjectHelpers";

interface ObjectDetailGeneralProps {
  goBack?: () => void;
  asset: OAObject;
  kind: ObjectKind;
}

const ObjectDetailGeneral = (props: ObjectDetailGeneralProps) => {
  return (
    <div className={`object-detail-object-view`}>
      <div className={`header`}>
        <div className="gallery-container">
          <AssetImages
            assetId={props.asset._id}
            assetType={AssetTypes.Portfolio.Object}
            fieldPath="data.images"
            dimension={"md"}
          />
        </div>
      </div>
      <div className="asset-data">
        <div className={`title-bar`}>
          <BFButton
            className="go-back-action"
            appearance={"clear-on-white"}
            icon={{ size: "xs", ...DefaultIcons.BACK }}
            onClick={props.goBack}
          />
          <div className="title">
            <span className={`object-id`}>{props.asset?.data?.id}</span>{" "}
            {props.asset.data.displayName}
          </div>

          <div className="actions">
            <ObjectActionButton asset={props.asset} kind={props.kind} />
          </div>
        </div>
        <DebugDataComponent data={props.asset} />

        <ObjectDetailData asset={props.asset} kind={props.kind} />
      </div>
      {checkFeature("flightradar24", props.kind) && (
        <div className={`flight-container`}>
          <FlightRadarFeature asset={props.asset} />
        </div>
      )}
      {checkFeature("address", props.kind) && (
        <div className={`map-container`}>
          <POIMapComponentPortfolioObject asset={props.asset} />
        </div>
      )}
    </div>
  );
};

export default ObjectDetailGeneral;

export const POIMapComponentPortfolioObject = (props: { asset: OAObject }) => {
  return (
    <POIMapComponent
      POIs={[
        {
          geo: {
            lng: props.asset?.data?.feature?.address?.location?.lng || 0,
            lat: props.asset?.data?.feature?.address?.location?.lat || 0,
          },
        },
      ]}
    />
  );
};

const FlightRadarFeature = (props: { asset: OAObject }) => {
  return (
    <iframe
      src={
        "https://www.flightradar24.com/simple?reg=" +
        props.asset.data.id +
        "&solo=1&z=8"
      }
      width="100%"
      height="100%"
      title="Flightradar24 Tracking"
      frameBorder="0"
    />
  );
};

const ObjectActionButton = (props: { asset: OAObject; kind: ObjectKind }) => {
  const buttons: DropdownItem[] = [
    {
      type: "button",
      text: i18n.t("Global.Button.edit"),
      disabled: PermissionService.hasObjectKindPermission(
        props.kind,
        "asset.createEdit"
      ),
      onSelect: () => {
        const hasFeatures =
          checkAnyFeature(["address", "immo"], props.kind) ||
          (props.kind?.data?.customfields || []).length > 0;

        ModalManager.show({
          noPadding: true,
          size: hasFeatures ? "xl" : "sm",
          content: (states, setStates, closeModal) => (
            <ObjectForm
              kind={props.kind}
              onClose={closeModal}
              asset={props.asset}
              onSuccess={(updatedAsset) => {
                if (isNotDefined(props.asset)) {
                  //reload table, because a new one was added
                }
              }}
            />
          ),
        });
      },
    },
    {
      type: "button",
      text: i18n.t("Global.Button.export", "Exportieren"),
      disabled: PermissionService.hasObjectKindPermission(
        props.kind,
        "asset.export"
      ),
      onSelect: async () => {
        ModalManager.show({
          size: "sm",
          noPadding: true,
          content: (state, setState, close) => (
            <ObjectExportDialog
              assets={[props.asset]}
              referenceId={props.asset._id}
              title={props.asset.data.displayName}
              kind={props.kind}
              onClose={close}
            />
          ),
        });
      },
    },
  ];

  if (checkFeature("immo", props.kind)) {
    buttons.push({
      type: "divider",
    });

    if (props.asset.data.feature.immo?.stopDebitRun) {
      buttons.push({
        type: "button",
        text: i18n.t(
          "Object.Immo.Action.RemoveDebitRunStop",
          "Sollstellungsautomatik aktivieren"
        ),
        disabled: props.asset.data.importObject,
        onSelect: () => {
          openModalObjectRemoveDebitRunStop(props.asset._id);
        },
      });
    } else {
      buttons.push({
        type: "button",
        text: i18n.t(
          "Object.Immo.Action.AddDebitRunStop",
          "Sollstellungsautomatik deaktivieren"
        ),
        disabled: props.asset.data.importObject,
        onSelect: () => {
          openModalObjectAddDebitRunStop(props.asset._id);
        },
      });
    }
  }

  return (
    <div className={`actions padding-right-10`}>
      <BFDropdown
        toggleAs={(bProps) => (
          <BFButton appearance="outline" size="sm" {...bProps} />
        )}
        label={i18n.t("Global.Button.action", "Aktion")}
        placement="bottomEnd"
        items={buttons}
      />
    </div>
  );
};
