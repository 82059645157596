import classNames from "classnames";
import i18n from "../../../../../i18n";
import BFButton from "../../../general/Button/BFButton";
import "./OverlayTitle.scss";

interface OverlayTitleProps {
  title: string;
  onClear: () => void;
}
const OverlayTitle = (props: OverlayTitleProps) => {
  return (
    <div className={classNames(`overlay-title`)}>
      <div className={`title`}>{props.title}</div>
      <div className={`action`}>
        <BFButton appearance="link" onClick={props.onClear} size="xs">
          {i18n.t("Global.Buttons.remove")}
        </BFButton>
      </div>
    </div>
  );
};

export default OverlayTitle;
