import { CSSProperties } from "react";
import Log from "../../debug/Log";
import Tools, { StyleConfiguration } from "../Tools";
import { AbstractComponent, AbstractProps, AbstractStates } from "./AbstractComponent";

export type AbstractStylableProps = {
	style?: StyleConfiguration;
	viewportWidth?: number;
	updateStyleOnChangedFields?: string[];
} & AbstractProps;

export type AbstractStylableStates = {
	usedStyle?: CSSProperties;
} & AbstractStates;

export abstract class AbstractStylableComponent<T, E> extends AbstractComponent<
	T & AbstractStylableProps,
	E & AbstractStylableStates
> {
	constructor(props) {
		super(props);
	}

	shouldComponentUpdate(nextProps: AbstractProps, nextState: AbstractStates) {
		//do not update on every viewport change
		if (nextState.usedStyle !== this.state.usedStyle) {
			return true;
		}

		if (nextProps.viewportWidth !== this.props.viewportWidth) {
			return false;
		}
		return true;
	}

	componentWillMount(): void {
		super.componentWillMount();
		this.recalculateStyles(this.props.viewportWidth, this.props, this.state);
	}

	componentWillReceiveProps(nextProps: T & AbstractStylableProps, nextStates: E & AbstractStylableStates) {
		super.componentWillReceiveProps(nextProps, nextStates);

		if (this.props.updateStyleOnChangedFields && this.props.params !== nextProps.params) {
			this.recalculateStyles(nextProps.viewportWidth, nextProps, nextStates);
		}
		if (this.props.viewportWidth !== nextProps.viewportWidth) {
			this.recalculateStyles(nextProps.viewportWidth, nextProps, nextStates);
		}
	}
	recalculateStyles(viewportWidth, props, states?) {
		if (props.style) {
			if (Array.isArray(props.style)) {
				const styles = [];
				props.style.forEach(style => styles.push(this.calcStyles(style, props, states)));

				this.setState({
					usedStyle: Tools.getActiveBreakpointProperties(viewportWidth, styles) as any
				});
			} else {
				this.setState({
					usedStyle: this.calcStyles(props.style, props, states) as any
				});
			}
		}
	}

	calcStyles(styles: any, props?, state?) {
		const outputStyle: CSSProperties = {};

		Object.keys(styles).forEach(styleKey => {
			const useStyleKey = styleKey.replace(/\|/g, ".");
			const value = styles[styleKey];
			if (styleKey[0] === "_") {
				// value should be calculated by confition

				outputStyle[useStyleKey.substr(1, useStyleKey.length - 1)] = this.evaluateExpression(
					value,
					props.params,
					props,
					state
				);
				// Log.debug( "### DEBUG Expression to calculate",value ,this.)
			} else if (typeof value === "object") {
				// recusive calculation
				outputStyle[useStyleKey] = this.calcStyles(value, props, state);
			} else {
				// normal value
				outputStyle[useStyleKey] = value;
			}
		});

		Log.debug("#Styles", outputStyle);
		return outputStyle;
	}

	subscriptionParamChangedHook(key, data) {
		if (this.props.updateStyleOnChangedFields && this.props.updateStyleOnChangedFields.indexOf(key) !== -1) {
			setTimeout(() => {
				this.recalculateStyles(this.props.viewportWidth, this.props, this.state);
			});
		}
	}

	shoudBeRendered() {
		if (this.state.usedStyle && this.state.usedStyle.display === "none") {
			return false;
		}
		return super.shoudBeRendered();
	}
}
