import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormFieldValues from "../../../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../i18n";
import BFInput from "../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "../../../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import BFUseTooltip from "../../../../../modules/abstract-ui/general/tooltip/BFUseTooltip";
import BfIcon from "../../../../../modules/abstract-ui/icon/BfIcon";
import NumberUtils from "../../../../../utils/ NumberUtils";
import StringUtils from "../../../../../utils/StringUtils";
import ACInvoiceUtils from "../../../../utils/ACInvoiceUtils";
import { RAInvoice } from "../../RAInterfaces";

export const RAInvoiceCostcenterForm = (props: {
  invoice: RAInvoice;
  withDescription?: boolean;
}) => {
  return (
    <div className={`ra-invoice-costcenter-form`}>
      {props.withDescription && (
        <div className={`description`}>
          {i18n.t(
            "ra:InvoiceForm.CostCenter.Description",
            "Sie können die Rechnung auf verschiedene Kostenstellen und Objekte aufteilen. Die Summe der aufgeteilten Kostenstellen muss dem Rechnungsbetrag übereinstimmen. Je nach ausgewählter Rechnungsart sind unterschiedliche Kostenstellen verfügbar."
          )}
        </div>
      )}

      <FormFieldValues
        names={["entity", "objectId", "value", "invoiceType"]}
        allProps
      >
        {([entity, objectId, amountToPay, invoiceType]) => (
          <div className={`cost-centers`}>
            <FieldArray name="costCenter">
              {({ fields }) => (
                <div>
                  <div className="entries">
                    {fields.map((name, index) => (
                      <div key={name} className={`cost-center-entry`}>
                        <div className={`cost-center-entry-header`}>
                          <div className={`entry-title`}>
                            {i18n.t("ra:CostCenter.Title")} {index + 1}
                          </div>

                          <div className="entry-action">
                            <BFButton
                              type="button"
                              appearance={"clear-on-white"}
                              icon={{ type: "bf", data: "close" }}
                              onClick={() => fields.remove(index)}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="costCenterId  __flex-1">
                            <Field
                              name={`${name}.value`}
                              render={({ input: valueInput }) => (
                                <>
                                  <Field
                                    name={`${name}.costCenterId`}
                                    validate={FormValidators.compose(
                                      FormValidators.required()
                                    )}
                                    render={({ input, meta }) => (
                                      <BFSelect
                                        validatorStyle={"default"}
                                        validation={{
                                          level: meta.error
                                            ? "error"
                                            : "warning",
                                          message:
                                            meta.error &&
                                            !meta.active &&
                                            (meta.touched ||
                                              meta.submitError ||
                                              meta.submitFailed)
                                              ? meta.error
                                              : undefined,
                                        }}
                                        label={`${i18n.t(
                                          "ra:CostCenter.Title"
                                        )}*`}
                                        type={"normal"}
                                        cleanable={false}
                                        data={ACInvoiceUtils.getInvoiceTypeCatalogForMainType(
                                          invoiceType.input.value
                                        )}
                                        renderMenuItem={(label, item) => {
                                          return (
                                            <div
                                              className={`cost-center-menu-item`}
                                            >
                                              <div className={`label`}>
                                                {label}
                                              </div>
                                              {item.description && (
                                                <BFUseTooltip
                                                  tooltip={
                                                    <div
                                                      style={{
                                                        textAlign: "left",
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          StringUtils.sanitizeHtml(
                                                            item.description
                                                          ),
                                                      }}
                                                    />
                                                  }
                                                  delay={0}
                                                >
                                                  <div>
                                                    <BfIcon
                                                      type="light"
                                                      data="question-help-circle"
                                                      size="xs"
                                                    />
                                                  </div>
                                                </BFUseTooltip>
                                              )}
                                            </div>
                                          );
                                        }}
                                        {...input}
                                      />
                                    )}
                                  />
                                </>
                              )}
                            />
                          </div>
                          <div className="price-input  __flex-1">
                            <Field
                              name={`${name}.value`}
                              validate={FormValidators.compose(
                                FormValidators.required()
                              )}
                              render={({ input, meta }) => (
                                <BFInput
                                  validation={{
                                    level: meta.error ? "error" : "warning",
                                    message:
                                      meta.error &&
                                      //   !meta.active &&
                                      (meta.touched ||
                                        meta.submitError ||
                                        meta.submitFailed)
                                        ? meta.error
                                        : meta.data.warning,
                                  }}
                                  type={"priceInput"}
                                  label={`${i18n.t("ra:CostCenter.amount")}*`}
                                  suffix={
                                    <BFDropdown
                                      items={[
                                        {
                                          type: "button",
                                          text: i18n.t(
                                            "ra.CostCenter.RestAmount",
                                            "Restbetrag"
                                          ),
                                          onSelect: () => {
                                            const restValue =
                                              (amountToPay?.input?.value
                                                ?.amount || 0) -
                                                fields?.value?.reduce(
                                                  (prev, current) =>
                                                    prev + current.value || 0,
                                                  0
                                                ) || 0;

                                            input.onChange(
                                              NumberUtils.round(
                                                input.value + restValue
                                              )
                                            );
                                          },
                                        },
                                        {
                                          type: "button",
                                          text: i18n.t(
                                            "ra.CostCenter.AddTax19",
                                            "19% Mehrwertsteuer dazurechnen"
                                          ),
                                          onSelect: () => {
                                            input.onChange(
                                              NumberUtils.round(
                                                input.value * 1.19
                                              )
                                            );
                                          },
                                        },
                                        {
                                          type: "button",
                                          text: i18n.t(
                                            "ra.CostCenter.AddTax7",
                                            "7% Mehrwertsteuer dazurechnen"
                                          ),
                                          onSelect: () => {
                                            input.onChange(
                                              NumberUtils.round(
                                                input.value * 1.07
                                              )
                                            );
                                          },
                                        },
                                      ]}
                                      label={i18n.t(
                                        "ra:CostCenter.calculateFunctions",
                                        "Berechnen"
                                      )}
                                      appearance="link"
                                      size="xs"
                                    />
                                    // <BFButton
                                    //   icon={{
                                    //     type: "bf",
                                    //     data: "sigma",
                                    //   }}
                                    //   type="button"
                                    //   appearance={"clear-on-white"}
                                    //   onClick={() => {
                                    //   }}
                                    // />
                                  }
                                  removeSuffixPadding
                                  prefix={StringUtils.getCurrencySymbol(
                                    amountToPay?.input?.value?.currency ||
                                      StringUtils.getCurrency()
                                  )}
                                  {...input}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="objectId __flex-1">
                            <Field
                              name={`${name}.objectId`}
                              render={({ input, meta }) => (
                                <BFSelect
                                  validatorStyle={"default"}
                                  validation={{
                                    level: meta.error ? "error" : "warning",
                                    message:
                                      meta.error &&
                                      !meta.active &&
                                      (meta.touched ||
                                        meta.submitError ||
                                        meta.submitFailed)
                                        ? meta.error
                                        : undefined,
                                  }}
                                  label={i18n.t("ra:CostCenter.object")}
                                  type={"normal"}
                                  cleanable={true}
                                  data={
                                    OrgaStruct.getObjectsOfEntity(
                                      entity?.input?.value
                                    )?.map((obj) => ({
                                      value: obj._id,
                                      label: obj.displayName,
                                    })) || []
                                  }
                                  {...input}
                                />
                              )}
                            />
                          </div>
                          <div className="info  __flex-1">
                            <Field
                              name={`${name}.info`}
                              render={({ input, meta }) => (
                                <BFInput
                                  label={i18n.t("ra:CostCenter.info")}
                                  {...input}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="array-actions">
                    <div className="add-action">
                      <BFButton
                        type="button"
                        appearance={"link"}
                        icon={{ type: "bf", data: "add" }}
                        text={i18n.t(
                          "ra:CostCenter.addCostCenterButton",
                          "Kostenstelle hinzufügen"
                        )}
                        onClick={() =>
                          fields.push({
                            info: "",
                            objectId: objectId?.input?.value || null,
                            // usage: `[split] ${fields.length + 1} - ${
                            //   props.bookingEntry.data.usage
                            // }`,
                            value:
                              (amountToPay?.input?.value?.amount || 0) -
                              (fields?.value?.reduce(
                                (prev, current) => prev + current.value || 0,
                                0
                              ) || 0),
                          })
                        }
                      ></BFButton>
                    </div>
                    {fields?.length > 0 && (
                      <Field
                        name="restSum"
                        render={({ meta }) => (
                          <div className="rest-sum">
                            {i18n.t("ra:CostCenter.amountLeft")}{" "}
                            <span
                              className={`sum-open ${
                                meta.error ? "error" : ""
                              }`}
                            >
                              {StringUtils.formatCurrency(
                                (amountToPay?.input?.value?.amount || 0) -
                                  fields?.value?.reduce(
                                    (prev, current) =>
                                      prev + current.value || 0,
                                    0
                                  ) || 0,
                                true
                              )}
                            </span>
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>
              )}
            </FieldArray>
          </div>
        )}
      </FormFieldValues>
    </div>
  );
};
