import { useEffect, useState } from "react";
import { DateInput, DateInputProps } from "rsuite";
// import './BFDateInput.scss';

type BFDateInputProps = DateInputProps & {
  useTempValue?: boolean;
};
const BFDateInput = (props: BFDateInputProps) => {
  const [tempValue, setTempValue] = useState<Date>(props.value);
  const { useTempValue, ...rest } = props;

  useEffect(() => {
    if (props.useTempValue && props.value !== tempValue) {
      setTempValue(props.value);
    }
  }, [props.value, props.useTempValue]);
  const addProps = {};
  if (useTempValue) {
    addProps["onChange"] = (value: Date) => {
      setTempValue(value);
    };
    addProps["onBlur"] = (ev) => {
      if (tempValue instanceof Date && !isNaN(tempValue as any)) {
        props.onChange(tempValue, ev);
      } else {
        setTempValue(props.value);
      }
      props.onBlur?.(ev);
    };
    addProps["value"] = tempValue;
  }
  return <DateInput {...rest} {...addProps} />;
};

export default BFDateInput;
