import DebugDataComponent from "../../../debug/DebugDataComponent";

import {
  Comment,
  EpostPDFComment,
  InternComment,
  MailIncomingComment,
  MailOutgoingComment,
  ManualPDFComment,
  SystemComment,
} from "../Comments";
import "./CommentEntry.scss";
import EpostPDFCommentEntry from "./comments/EpostPDFCommentEntry";
import { InternCommentEntry } from "./comments/InternCommentEntry";
import { MailIncomingCommentEntry } from "./comments/MailIncomingCommentEntry";
import { MailOutgoingCommentEntry } from "./comments/MailOutgoingCommentEntry";
import ManualPDFCommentEntry from "./comments/ManualPDFCommentEntry";
import { SystemCommentEntry } from "./comments/SystemCommentEntry";

interface CommentEntryProps {
  collapsible?: boolean;
  comment: Comment;
  commentModuleIdentifier?: string;
  context?: {
    id: number;
    name: string;
  };
  onPinChange?: () => void;
  onContextClick?: (contextId: number) => void;
  defaultSignature?: string;
  allowFullscreen?: boolean;
  hideAllActions?: boolean;
  disableFocusMode?: boolean;
  hideResubmissionActionForMail?: boolean;
  apperance?: "normal" | "slim";
  onClose?: () => void;
  renderMailDuplicateAction?: (
    comment: Comment,
    onClose: () => void
  ) => JSX.Element;
}
const CommentEntry = (props: CommentEntryProps) => {
  return (
    <div className={`comment-entry text-selection-all`}>
      <DebugDataComponent data={props.comment} />
      {props.comment.data?.type === "manual_pdf_create" && (
        <ManualPDFCommentEntry comment={props.comment as ManualPDFComment} />
      )}
      {props.comment.data?.type === "epost_pdf_create" && (
        <EpostPDFCommentEntry comment={props.comment as EpostPDFComment} />
      )}
      {props.comment.data?.type === "system" && (
        <SystemCommentEntry comment={props.comment as SystemComment} />
      )}
      {props.comment.data?.type === "intern" && (
        <InternCommentEntry
          collapsible={props.collapsible}
          onPinChange={props.onPinChange}
          onClose={props.onClose}
          onContextClick={props.onContextClick}
          apperance={props.apperance}
          context={props.context}
          comment={props.comment as InternComment}
          commentModuleIdentifier={props.commentModuleIdentifier}
          allowFullscreen={props.allowFullscreen}
          disableFocusMode={props.disableFocusMode}
          hideAllActions={props.hideAllActions}
        />
      )}
      {props.comment.data?.type === "mail_outgoing" && (
        <MailOutgoingCommentEntry
          collapsible={props.collapsible}
          onPinChange={props.onPinChange}
          onClose={props.onClose}
          onContextClick={props.onContextClick}
          apperance={props.apperance}
          context={props.context}
          comment={props.comment as MailOutgoingComment}
          defaultSignature={props.defaultSignature}
          commentModuleIdentifier={props.commentModuleIdentifier}
          allowFullscreen={props.allowFullscreen}
          hideResubmissionActionForMail={props.hideResubmissionActionForMail}
          disableFocusMode={props.disableFocusMode}
          renderMailDuplicateAction={props.renderMailDuplicateAction}
          hideAllActions={props.hideAllActions}
        />
      )}
      {props.comment.data?.type === "mail_incoming" && (
        <MailIncomingCommentEntry
          collapsible={props.collapsible}
          onPinChange={props.onPinChange}
          onClose={props.onClose}
          onContextClick={props.onContextClick}
          apperance={props.apperance}
          context={props.context}
          comment={props.comment as MailIncomingComment}
          defaultSignature={props.defaultSignature}
          commentModuleIdentifier={props.commentModuleIdentifier}
          allowFullscreen={props.allowFullscreen}
          hideResubmissionActionForMail={props.hideResubmissionActionForMail}
          disableFocusMode={props.disableFocusMode}
          renderMailDuplicateAction={props.renderMailDuplicateAction}
          hideAllActions={props.hideAllActions}
        />
      )}
    </div>
  );
};

export default CommentEntry;
