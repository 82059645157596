import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { ColumnConfig } from "./BFVirtualizedTable";

interface ContentProps {
  data: any;
  rowIndex: number;
  allData: any[];
  params?: any;
  column: ColumnConfig;
  columnIndex: number;
  rowRef: React.RefObject<HTMLDivElement>;
}
const BFVirtualizedTableContent = (props: ContentProps) => {
  return (
    <div
      key={props.columnIndex}
      className={classNames(
        "table-column",
        props.column.className,
        props.column.classNameFC?.(props.data, props.rowIndex, props.params),
        `alignment-${props.column.alignment || "left"}`,
        {
          "ignore-row-click": props.column.ignoreRowClick,
          "no-padding": props.column.noPadding,
        }
      )}
      style={{
        flex: !props.column.fixedWidth
          ? `${props.column.flexWidth || 1} 0 auto`
          : undefined,
        width: props.column.flexWidth || props.column.fixedWidth || 1,
        minWidth: props.column.flexWidth || props.column.fixedWidth || 1,
        ...(props.column.columnStyles
          ? props.column.columnStyles(props.data, props.rowIndex, props.params)
          : {}),
      }}
    >
      {props.column.render?.(
        props.data,
        props.rowIndex,
        props.params,
        props.rowRef
      )}
    </div>
  );
};

export default React.memo(BFVirtualizedTableContent, (prev, next) => {
  // fix memo issue with functions - we should consider some general solution for this issue
  return _.isEqualWith(prev, next, (a, b) => {
    if (typeof a === "function" && typeof b === "function") {
      return true;
    }
    return undefined;
  });
});
