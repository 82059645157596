import { FieldState } from "final-form";
import moment from "moment";
import { FieldMetaState } from "react-final-form";
import validator from "validator";
import i18n from "../../../i18n";
import IBAN from "../../../lib-overwrites/iban/iban";
import { BFAssignmentValue } from "../../../modules/abstract-ui/forms/assignment/BFAssignmentDefault";
import ValidatorsUtils from "../../../modules/generic-forms/util/ValidatorsUtils";
import { isDefined } from "../../../utils/Helpers";
import StringUtils from "../../../utils/StringUtils";
import {
  PostalCodeLocale,
  possiblePostalCodeLocales,
} from "./FormValidatorConstants";

type ValidateFc<FieldValue> = (
  value: FieldValue,
  allValues: object,
  meta?: FieldState<FieldValue>
) => string;

class ValidatorClass {
  dateBefore =
    <T>(otherDate: Date, errorText?: string) =>
    (value: T, allValues: object, meta?: FieldState<T>) =>
      value !== null &&
      value !== "" &&
      value !== undefined &&
      otherDate &&
      moment(value).isValid() &&
      moment(otherDate).isValid() &&
      !moment(value).isBefore(moment(otherDate))
        ? errorText ||
          i18n.t("Global.Labels.dateBefore", {
            date: moment(otherDate).format(i18n.t("Formats.dateFormat")),
          })
        : undefined;
  dateAfter =
    <T>(otherDate: Date, errorText?: string) =>
    (value: T, allValues: object, meta?: FieldState<T>) =>
      value !== null &&
      value !== "" &&
      value !== undefined &&
      otherDate &&
      moment(value).isValid() &&
      moment(otherDate).isValid() &&
      !moment(value).isAfter(moment(otherDate))
        ? errorText ||
          i18n.t("Global.Labels.dateAfter", {
            date: moment(otherDate).format(i18n.t("Formats.dateFormat")),
          })
        : undefined;

  required =
    <T>(errorText?: string) =>
    (value: T, allValues: object, meta?: FieldState<T>) =>
      value === null ||
      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? errorText || i18n.t("Global.Labels.required")
        : undefined;
  min =
    <T>(min: number, errorText?: string) =>
    (value: T, allValues: object, meta?: FieldState<T>) =>
      (typeof value === "string" && value.length < min) ||
      (typeof value === "number" && value < min) ||
      (Array.isArray(value) && value.length < min)
        ? errorText ||
          (typeof value === "string"
            ? i18n.t("Global.Labels.MinLength", { min })
            : typeof value === "number"
            ? i18n.t("Global.Labels.MinValue", { min })
            : i18n.t("Global.Labels.MinCount", { min }))
        : undefined;
  max =
    <T>(max: number, errorText?: string) =>
    (value: T, allValues: object, meta?: FieldState<T>) =>
      (typeof value === "string" && value.length > max) ||
      (typeof value === "number" && value > max) ||
      (Array.isArray(value) && value.length > max)
        ? errorText ||
          (typeof value === "string"
            ? i18n.t("Global.Labels.MaxLength", { max })
            : typeof value === "number"
            ? i18n.t("Global.Labels.MaxValue", { max })
            : i18n.t("Global.Labels.MaxCount", { max }))
        : undefined;
  displayName =
    <T>(errorText?: string) =>
    (value: T, allValues: object, meta?: FieldState<T>) => {
      if (!isDefined(value)) {
        return;
      }
      if (typeof value === "string") {
        return value === "" ? errorText : undefined;
      }

      const keys = Object.keys(value);
      const hasEmpty = keys.find((e) => value[e] === "");
      return hasEmpty
        ? errorText ||
            i18n.t(
              "Global.DisplayName.empty",
              "Bitte füllen Sie alle Übersetzungen aus."
            )
        : undefined;
    };
  email =
    <T>(errorText?: string) =>
    (value: T, allValues: object, meta?: FieldState<T>) =>
      typeof value === "string" &&
      value !== "" &&
      !ValidatorsUtils.isEmail(value)
        ? errorText ||
          i18n.t("Global.Labels.NotEmail", "Keine gültige E-Mail-Adresse")
        : undefined;
  iban =
    <T>(errorText?: string) =>
    (value: T, allValues: object, meta?: FieldState<T>) =>
      typeof value === "string" &&
      value !== "" &&
      !IBAN.isValid(StringUtils.ibanToISO(value))
        ? errorText || i18n.t("Global.Labels.NotIBAN", "Keine gültige IBAN")
        : undefined;
  creditCard =
    <T>(errorText?: string) =>
    (value: T, allValues: object, meta?: FieldState<T>) =>
      typeof value === "string" &&
      value !== "" &&
      !validator.isCreditCard(value)
        ? errorText ||
          i18n.t("Global.Labels.NotCreditCard", "Keine gültige Kreditkarte")
        : undefined;
  url =
    <T>(errorText?: string) =>
    (value: T, allValues: object, meta?: FieldState<T>) =>
      typeof value === "string" && value !== "" && !validator.isURL(value)
        ? errorText || i18n.t("Global.Labels.NotURL", "Keine gültige URL")
        : undefined;
  postalcode =
    <T>(countryCode: PostalCodeLocale = "any", errorText?: string) =>
    (value: T, allValues: object, meta?: FieldState<T>) =>
      typeof value === "string" &&
      value !== "" &&
      !validator.isPostalCode(
        value,
        possiblePostalCodeLocales.includes(countryCode) ? countryCode : "any"
      )
        ? errorText ||
          i18n.t("Global.Labels.NotPostalcode", "Keine gültige Postleitzahl")
        : undefined;
  requiredBFAssignmentValue =
    (errorText?: string) =>
    (
      value: BFAssignmentValue,
      allValues: object,
      meta?: FieldState<BFAssignmentValue>
    ) => {
      if (value === null || value === undefined) {
        return errorText || i18n.t("Global.Labels.required");
      }

      let hasValue = false;
      if (value.teams && value.teams.length > 0) {
        hasValue = value.teams.some(
          (e) => e !== null && e !== undefined && e !== ""
        );
      }

      if (hasValue) {
        return undefined;
      }

      if (value.users && value.users.length > 0) {
        hasValue = value.users.some(
          (e) => e !== null && e !== undefined && e !== ""
        );
      }

      return hasValue
        ? undefined
        : errorText || i18n.t("Global.Labels.required");
    };
  compose =
    <T>(...functions: ValidateFc<T>[]) =>
    (value: T, allValues: object, meta?: FieldState<T>) =>
      functions
        .filter((e) => e)
        .map((fc) => fc(value, allValues, meta))
        .find((e) => e != undefined);

  getValidation(meta: FieldMetaState<any>, ignoreTouched = false) {
    return {
      validation:
        (ignoreTouched ? true : meta.touched) &&
        meta.error &&
        typeof meta.error === "string"
          ? ({
              level: "error",
              message: meta.error,
            } as any)
          : undefined,
    };
  }
}
const FormValidators = new ValidatorClass();
export const FV = FormValidators;

export default FormValidators;
