import classNames from "classnames";
import "./EnergyEfficiencyClassIndicator.scss";

export type EnergyClass = "A+" | "A" | "B" | "C" | "D" | "E" | "F" | "G" | "H";
export const getColorForClass = (energyClass: EnergyClass) => {
  switch (energyClass) {
    case "A+":
      return "#76b833";
    case "A":
      return "#a1c620";
    case "B":
      return "#d8db12";
    case "C":
      return "#d8db12";
    case "D":
      return "#ffd80b";
    case "E":
      return "#ffd80b";
    case "F":
      return "#f9ad11";
    case "G":
      return "#e76b15";
    case "H":
      return "#ea2c28";
    default:
      return "red";
  }
};

export const calculateEnergyCertificateClass = (value: number) => {
  if (!value) {
    return null;
  }
  if (value > 250) {
    return "H";
  }
  if (value >= 200) {
    return "G";
  }
  if (value >= 160) {
    return "F";
  }
  if (value >= 130) {
    return "E";
  }
  if (value >= 100) {
    return "D";
  }
  if (value >= 75) {
    return "C";
  }
  if (value >= 50) {
    return "B";
  }
  if (value >= 30) {
    return "A";
  }
  return "A+";
};

interface EnergyEfficiencyClassIndicatorProps {
  energyClass: EnergyClass;
  className?: string;
  size?: "xs" | "sm";
}
const EnergyEfficiencyClassIndicator = (
  props: EnergyEfficiencyClassIndicatorProps
) => {
  if (!props.energyClass) {
    return null;
  }
  return (
    <div
      className={classNames(
        `energy-efficiency-class-indicator`,
        props.className,
        props.size
      )}
      style={{
        background: getColorForClass(props.energyClass),
      }}
    >
      {props.energyClass}
    </div>
  );
};

export default EnergyEfficiencyClassIndicator;
