import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import classNames from "classnames";
import moment from "moment";
import AssetLoader from "../../../../components/AssetLoader/AssetLoader";
import AssignmentLabel from "../../../../components/AssignmentLabel/AssignmentLabel";
import DebugDataComponent from "../../../../debug/DebugDataComponent";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import BFTag from "../../../abstract-ui/data/tag/BFTag";
import LabeledInput from "../../../abstract-ui/forms/LabeledInput";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import EZDate from "../../../ez-form/form-elements/ez-date/EZDate";
import EZSelect from "../../../ez-form/form-elements/ez-select/EZSelect";
import EZTextfield from "../../../ez-form/form-elements/ez-textfield/EZTextfield";
import { Resubmission } from "../../RSInterfaces";
import RSService from "../../RSService";
import useOpenResubmissionDoneForm from "../../hooks/useOpenResubmissionDoneForm";
import useOpenResubmissionForm from "../../hooks/useOpenResubmissionForm";
import "./RSDetailsComponent.scss";

interface RSDetailsComponentProps {
  resubmissionId: string;
  assetTitle?: string;
  preventEdit?: boolean;
  listIdentifier?: string;
  closeResubmission?: (id: string) => void;
  renderAssetDetails?: (resubmission: Resubmission) => React.ReactNode;
  renderSubAssetDataOnly?: boolean;
}

const RSDetailsComponent = (props: RSDetailsComponentProps) => {
  const { openResubmissionDialog } = useOpenResubmissionForm();
  const { openResubmissionDoneDialog } = useOpenResubmissionDoneForm();

  // const getIntervallResubmission = (
  //   resubmission: Resubmission
  // ): IntervalResubmission | undefined => {
  //   if (resubmission.data.resubmissionType === "interval") {
  //     return resubmission as IntervalResubmission;
  //   }
  //   return undefined;
  // };

  const getStatusTagColor = (resubmission: Resubmission) => {
    const today = moment().startOf("day");
    if (resubmission.data.status === "open") {
      if (today.isAfter(moment(resubmission.data.dueDate).startOf("day"))) {
        return "negative";
      }
      if (today.isSame(moment(resubmission.data.dueDate).startOf("day"))) {
        return "warning";
      }
      if (today.isBefore(moment(resubmission.data.dueDate).startOf("day"))) {
        return "default";
      }
    }
    if (resubmission.data.status === "done") {
      return "positive";
    }
  };

  return (
    <div className={classNames("rs-details-component")}>
      <AssetLoader
        assetType={AssetTypes.Resubmission}
        id={props.resubmissionId}
        render={(resubmission: Resubmission) => {
          const resubmissionDetailsComponent = props.renderAssetDetails
            ? props.renderAssetDetails(resubmission)
            : null;

          const shouldRenderActions = ![
            "done",
            "archived",
            "deleted",
            "rejected",
          ].includes(resubmission.data.status);

          const statusColorClass = getStatusTagColor(resubmission);

          // in the RSListComponentView we dont need the information from the asset itself
          let shouldRenderLinkedData =
            !!props.renderAssetDetails && !!resubmission.data.linkedAsset;
          if (props.renderSubAssetDataOnly) {
            shouldRenderLinkedData =
              !!props.renderAssetDetails &&
              (!!resubmission.data.linkedAsset.assetField ||
                !!resubmission.data.linkedAsset.subAsset);
          }

          return (
            <div className="rs-details-content">
              <div className="rs-details-header">
                <div className="rs-details-title">
                  <DebugDataComponent data={resubmission} />
                  <div className={classNames("section-title", "__h3")}>
                    {i18n.t(
                      "Resubmission.Titles.Resubmission",
                      "Wiedervorlage"
                    )}
                  </div>
                  <div
                    className={classNames(
                      "rs-details-status",
                      "__description-text",
                      statusColorClass
                    )}
                  >
                    <BFTag
                      color={getStatusTagColor(resubmission)}
                      textKey={RSService.getResubmissionStatusLabel(
                        resubmission.data.status
                      )}
                      size="sm"
                    />
                  </div>
                </div>
                <div className="rs-details-actions">
                  {shouldRenderActions && (
                    <>
                      <BFButton
                        appearance="link"
                        onClick={() => {
                          openResubmissionDoneDialog({
                            resubmission: resubmission,
                            identifierToUpdate: props.listIdentifier,
                            closeResubmission: props.closeResubmission,
                          });
                        }}
                      >
                        {i18n.t(
                          "Resubmission.Button.Done",
                          "Als erledigt markieren"
                        )}
                      </BFButton>
                      {!props.preventEdit && (
                        <BFButton
                          appearance="link"
                          onClick={() => {
                            openResubmissionDialog({
                              resubmission: resubmission,
                            });
                          }}
                        >
                          {i18n.t("Resubmission.Button.Edit", "Bearbeiten")}
                        </BFButton>
                      )}
                    </>
                  )}
                  <BFButton
                    appearance="link"
                    onClick={() => {
                      if (props.closeResubmission) {
                        props.closeResubmission(props.resubmissionId);
                      }
                    }}
                  >
                    {i18n.t("Resubmission.Button.Close", "Schließen")}
                  </BFButton>
                </div>
              </div>
              <div className="rs-details-row">
                <EZTextfield
                  className="rs-details-title"
                  readonly={true}
                  label={i18n.t("Resubmission.Labels.Title", "Titel")}
                  value={resubmission?.data.title}
                  onChange={() => {}}
                />
              </div>
              <div className="rs-details-row">
                <EZTextfield
                  className="rs-details-description"
                  readonly={true}
                  label={i18n.t(
                    "Resubmission.Labels.Description",
                    "Beschreibung"
                  )}
                  value={resubmission?.data.description}
                  onChange={() => {}}
                />
              </div>
              <div className="rs-details-row">
                <LabeledInput
                  type="sub"
                  label={i18n.t("Resubmission.Labels.Type", "Bereich")}
                >
                  <EZSelect
                    readonly={true}
                    value={resubmission.data.type}
                    data={UnitStruct.getUnitSelectOptions() || []}
                  />
                </LabeledInput>
                <LabeledInput
                  type="sub"
                  label={i18n.t("Resubmission.Labels.Priority", "Priorität")}
                >
                  <EZSelect
                    className="priority-select"
                    readonly={true}
                    value={resubmission.data.priority}
                    data={RSService.getPriorityOptions()}
                  />
                </LabeledInput>
                <LabeledInput
                  type="sub"
                  label={i18n.t("Resubmission.Labels.Category", "Kategorie")}
                >
                  <EZSelect
                    readonly={true}
                    value={resubmission.data.category}
                    data={RSService.getCategoryOptions()}
                  />
                </LabeledInput>
              </div>
              <div
                className={`section-title __h3 margin-bottom-10 margin-top-20`}
              >
                {i18n.t("Resubmission.Titles.Assignees", "Empfänger und Datum")}
              </div>
              <div className="rs-details-row">
                <LabeledInput
                  type="sub"
                  label={i18n.t("Resubmission.Labels.Assignees", "Empfänger")}
                >
                  <AssignmentLabel
                    users={resubmission?.data.assignees.users}
                    teams={resubmission?.data.assignees.teams}
                    clipAtCount={6}
                    asList={true}
                  />
                </LabeledInput>

                <div className="__flex-1" />
                <LabeledInput
                  type="sub"
                  label={i18n.t("Resubmission.Labels.DueDate", "Fällig am")}
                >
                  <EZDate
                    value={resubmission?.data.dueDate}
                    placement="topStart"
                    readonly={true}
                  />
                </LabeledInput>
                {resubmission?.data.remindDate && (
                  <LabeledInput
                    type="sub"
                    label={i18n.t(
                      "Resubmission.Labels.RemindDate",
                      "Erinnern ab"
                    )}
                  >
                    <EZDate
                      value={resubmission?.data.remindDate}
                      placement="topStart"
                      readonly={true}
                    />
                  </LabeledInput>
                )}
              </div>

              {resubmission?.data.feedbackRecipients && (
                <div>
                  <div
                    className={`section-title __h3 margin-bottom-10 margin-top-20`}
                  >
                    {i18n.t("Resubmission.Titles.Feedback", "Rückmeldung")}
                  </div>
                  <div className="rs-details-row">
                    <LabeledInput
                      type="sub"
                      label={i18n.t(
                        "Resubmission.Labels.Assignees",
                        "Empfänger"
                      )}
                    >
                      <AssignmentLabel
                        users={resubmission?.data.feedbackRecipients.users}
                        teams={resubmission?.data.feedbackRecipients.teams}
                        clipAtCount={6}
                        asList={true}
                      />
                    </LabeledInput>
                  </div>
                  {resubmission?.data.feedbackComment && (
                    <div className="rs-details-row">
                      <LabeledInput
                        type="sub"
                        label={i18n.t(
                          "Resubmission.Labels.FeedbackComment",
                          "Kommentar"
                        )}
                      >
                        <EZTextfield
                          className="rs-details-description"
                          readonly={true}
                          value={resubmission?.data.feedbackComment}
                          onChange={() => {}}
                        />
                      </LabeledInput>
                    </div>
                  )}
                </div>
              )}
              {/* {resubmission?.data.resubmissionType === "interval" && (
                <>
                  <div
                    className={`section-title __h3 margin-bottom-10 margin-top-20`}
                  >
                    {i18n.t(
                      "Resubmission.Titles.Repition",
                      "Termin Wiederholung"
                    )}
                  </div>
                  <div className="rs-details-row">
                    <LabeledInput
                      type="sub"
                      label={i18n.t(
                        "Resubmission.Labels.Repitition",
                        "Wiederholung"
                      )}
                    >
                      <EZSelect
                        value={resubmission.data.resubmissionType}
                        data={RSService.getResubmissionTypeOptions()}
                        readonly={true}
                      />
                    </LabeledInput>
                    <LabeledInput
                      type="sub"
                      label={i18n.t(
                        "Resubmission.Labels.IntervalType",
                        "Intervall"
                      )}
                    >
                      <EZSelect
                        value={
                          getIntervallResubmission(resubmission)?.data.interval
                            .type
                        }
                        data={RSService.getIntervalOptions()}
                        readonly={true}
                      />
                    </LabeledInput>
                    <LabeledInput
                      type="sub"
                      label={i18n.t(
                        "Resubmission.Labels.Interval.EndDate",
                        "Endet am"
                      )}
                    >
                      <EZDate
                        readonly={true}
                        value={
                          getIntervallResubmission(resubmission)?.data.interval
                            .endDate
                        }
                      />
                    </LabeledInput>
                  </div>
                  {getIntervallResubmission(resubmission)?.data.interval
                    .type === "daily" && (
                    <div className="rs-details-row">
                      <LabeledInput
                        type="sub"
                        label={i18n.t(
                          "Resubmission.Labels.Interval.Weekdays",
                          "Wochentage"
                        )}
                      >
                        <div className="weekday-tags">
                          {getIntervallResubmission(
                            resubmission
                          )?.data.interval.days?.map((day) => {
                            const label = RSService.getWeekdayLabel(day);
                            return <BFTag textKey={label} />;
                          })}
                        </div>
                      </LabeledInput>
                    </div>
                  )}
                </>
              )} */}
              {shouldRenderLinkedData && (
                <>
                  <div
                    className={`section-title __h3 margin-bottom-10 margin-top-20`}
                  >
                    {i18n.t(
                      "Resubmission.Titles.AssetDetails",
                      "Verknüpfte Daten"
                    )}
                  </div>
                  <div
                    className={classNames("rs-details-row", "asset-details")}
                  >
                    {resubmissionDetailsComponent}
                  </div>
                </>
              )}
            </div>
          );
        }}
      ></AssetLoader>
    </div>
  );
};

export default RSDetailsComponent;
