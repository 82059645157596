import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BfIconProps } from "../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../utils/abstracts/AbstractStylableComponent";
import { StyleConfiguration } from "../../utils/Tools";

type Props = {
  orderIndex?: number;
  stateSubscriptions?: string[];
  actionId: string;
  icon: BfIconProps;
  textKey: string;
  actionParams?: { [key: string]: any };
  style?: StyleConfiguration;
  expanded: boolean;

  initialState?: {
    hidden?: boolean;
    focus?: boolean;
    loading?: boolean;
    disabled?: boolean;
    toggled?: boolean;
  };
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class SidenavAction extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  render() {
    const {
      actionId,
      orderIndex,
      stateSubscriptions,
      icon,
      textKey,
      actionParams,
      initialState,
      style,
      expanded,
    } = this.props;

    const config = {
      stateSubscriptions: stateSubscriptions,
      orderIndex: orderIndex,
      _component: "action",
      appearance: "transparent-light",
      actionId: actionId,
      initialState: initialState,
      iconPosition: "left",
      textKey: expanded ? textKey : undefined,
      icon: {
        ...icon,
        style: {
          paddingRight: expanded ? 10 : 0,
          ...(icon.style ? icon.style : {}),
        },
        size: "xl",
      },
      style: {
        margin: "5px 10px 5px 10px",
        width: "calc(100% - 20px)",
        justifyContent: "flex-start",
      },
      actionParams: actionParams,
    };

    return (window as any).ComponentsMapper.createElement(
      config,
      this.props.params
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(SidenavAction))
);
