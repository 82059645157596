import "./CBCommonComponents.scss";
export const CBRegion = (props: {
  title: string;
  children: React.ReactNode;
  titleButtons?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`cb-region ${props.className ? props.className : ""}`}>
      <div className="region-title">
        <div className="text">{props.title}</div>
        {props.titleButtons && (
          <div className="actions">{props.titleButtons}</div>
        )}
      </div>
      <div className="region-content">{props.children}</div>
    </div>
  );
};
