import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import {
  ObjectKind,
  ObjectKindFeature_IMMO,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import ModalManager from "@/components/ModalComponent/ModalManager";
import { AssetTypes } from "@/model/AssetTypes";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFAssetPDFViewer from "@/modules/abstract-ui/forms/pdf-marker/BFAssetPDFViewer";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import { CustomFieldEditType } from "@/modules/customfields/CustomField.interface";
import CFAttachment from "@/modules/customfields/fields/CFAttachment";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import LanguageService from "@/services/LanguageService";
import { useState } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormFieldValues from "../../../../../../../../components/Form/Fields/FormFieldValues";
import FormValidators, {
  FV,
} from "../../../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../../../i18n";
import BFInput from "../../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../../../../modules/abstract-ui/general/Button/BFButton";
import { isDefined } from "../../../../../../../../utils/Helpers";
import StringUtils from "../../../../../../../../utils/StringUtils";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";
import "./CBRentalAgreementDepositForm.scss";

interface CBRentalAgreementDepositFormProps {
  force?: boolean;
  witboutDocuments?: boolean;
  objectId: string;
  kind?: ObjectKind;
  rentalAgreement?: RentalAgreement;
}
const CBRentalAgreementDepositForm = (
  props: CBRentalAgreementDepositFormProps
) => {
  const objectId = props.objectId;
  return (
    <div className={`cb-rental-agreement-deposit-form`}>
      <AssetLoader
        assetType={AssetTypes.Portfolio.Object}
        id={objectId}
        render={(object: OAObject) => (
          <Field name="deposit" defaultValue={null} allowNull>
            {({ input, meta }) =>
              props.force || isDefined(input.value) ? (
                <Field
                  name="marks.deposit"
                  validate={FV.compose(FV.required(), FV.min(1))}
                >
                  {({ input, meta }) => (
                    <BFPDFMarkerSection
                      title={i18n.t(
                        "cb:RentalAgreement.Form.Fields.deposit.title",
                        "Kaution"
                      )}
                      marginBottom={20}
                      {...input}
                      {...FV.getValidation(meta)}
                      editHint={i18n.t(
                        "cb:RentalAgreement.Form.Fields.deposit.editHint",
                        "Markieren Sie die Stellen, wo Sie die Informationen zur Kaution finden."
                      )}
                      viewHint={i18n.t(
                        "cb:RentalAgreement.Form.Fields.deposit.viewHint",
                        "Informationen zur Kaution"
                      )}
                      identifier="deposit"
                      pdfViewerIdentifier={"rental-agreement-pdf"}
                      value={input.value}
                      onChange={input.onChange}
                    >
                      <>
                        <FieldArray name="deposit">
                          {({ fields }) => (
                            <div className={`cb-rental-agreement-deposit-form`}>
                              {fields.map((name, index) => (
                                <div className={`deposit-entry`}>
                                  <div className={`headline`}>
                                    <BFButton
                                      appearance="link"
                                      size="xs"
                                      onClick={() => {
                                        if (fields.length > 1) {
                                          fields.remove(index);
                                        } else {
                                          input.onChange(null);
                                        }
                                      }}
                                    >
                                      {i18n.t("Global.Buttons.remove")}
                                    </BFButton>
                                  </div>
                                  <div className={`form`}>
                                    <div className={`__flex`}>
                                      <div className={`__field flex-1`}>
                                        <Field
                                          name={`${name}.id`}
                                          validate={FormValidators.compose(
                                            FormValidators.required()
                                          )}
                                        >
                                          {({ input, meta }) => (
                                            <BFSelect
                                              label={`${i18n.t(
                                                "cb:RentalAgreement.Form.Fields.depositType",
                                                "Kautionstyp"
                                              )}*`}
                                              data={object.data.feature?.immo?.accounting?.deposit.map(
                                                (d) => ({
                                                  value: d.id,
                                                  label:
                                                    LanguageService.translateLabel(
                                                      d.displayName
                                                    ),
                                                })
                                              )}
                                              {...FormValidators.getValidation(
                                                meta
                                              )}
                                              {...input}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                      <div className={`__field flex-1`}>
                                        <Field
                                          name={`${name}.depositValue`}
                                          validate={FormValidators.compose(
                                            FormValidators.required(),
                                            FormValidators.min(0)
                                          )}
                                        >
                                          {({ input, meta }) => (
                                            <BFInput
                                              type="priceInput"
                                              prefix={StringUtils.getCurrencySymbol()}
                                              label={`${i18n.t(
                                                "cb:RentalAgreement.Form.Fields.depositValue",
                                                "Wert"
                                              )}*`}
                                              {...FormValidators.getValidation(
                                                meta
                                              )}
                                              {...input}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                      <div className={`__field flex-1`}>
                                        <Field
                                          name={`${name}.depositStart`}
                                          validate={FormValidators.compose(
                                            FormValidators.required()
                                          )}
                                        >
                                          {({ input, meta }) => (
                                            <BFDatefield
                                              label={`${i18n.t(
                                                "cb:RentalAgreement.Form.Fields.depositStart",
                                                "Kautionsbeginn"
                                              )}*`}
                                              {...FormValidators.getValidation(
                                                meta
                                              )}
                                              {...input}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    </div>

                                    {props.witboutDocuments !== true && (
                                      <Field name={`${name}.document`}>
                                        {(field) => (
                                          <div
                                            style={{ maxWidth: 400 }}
                                            className={`margin-bottom-20`}
                                          >
                                            <CFAttachment
                                              field={field}
                                              assetType={
                                                AssetTypes.Rental
                                                  .RentalAgreement
                                              }
                                              data={{
                                                displayName: i18n.t(
                                                  "cb:RentalAgreement.Form.DepositDocument",
                                                  "Kautionsdokumente"
                                                ),
                                                fieldType:
                                                  CustomFieldEditType.attachment,
                                                id: "attachments",
                                                validation: [],
                                              }}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    )}

                                    <div className={`__field flex-1`}>
                                      <Field
                                        name={`${name}.comment`}
                                        validate={FormValidators.compose(
                                          FormValidators.max(400)
                                        )}
                                      >
                                        {({ input, meta }) => (
                                          <BFInput
                                            type="textarea"
                                            label={`${i18n.t(
                                              "cb:RentalAgreement.Form.Fields.comment",
                                              "Notiz"
                                            )}`}
                                            {...FormValidators.getValidation(
                                              meta
                                            )}
                                            {...input}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className={`__flex __justify-center`}>
                                <BFButton
                                  noPadding
                                  onClick={() => fields.push({})}
                                  appearance="link"
                                  size="xs"
                                >
                                  {i18n.t(
                                    "cb:RentalAgreement.Form.Fields.addDepositEntry",
                                    "Weitere Kaution hinzufügen"
                                  )}
                                </BFButton>
                              </div>
                            </div>
                          )}
                        </FieldArray>
                      </>
                    </BFPDFMarkerSection>
                  )}
                </Field>
              ) : null
            }
          </Field>
        )}
      />
    </div>
  );
};

export default CBRentalAgreementDepositForm;

export const CBRentalAgreementDepositButton = () => {
  return (
    <FormFieldValues names={["objectId", "paymentPositions"]}>
      {([objectId, paymentPositions]) => (
        <Field name="deposit" allowNull defaultValue={null}>
          {({ input, meta }) => {
            if (!isDefined(input.value)) {
              return (
                <BFButton
                  type="button"
                  appearance="outline"
                  size="xs"
                  onClick={() => input.onChange([{}])}
                  text={i18n.t(
                    "cb:RentalAgreement.Form.Buttons.addDeposit",
                    "Kaution hinzufügen"
                  )}
                />
              );
            } else {
              return null;
            }
          }}
        </Field>
      )}
    </FormFieldValues>
  );
};

export const CBRentalAgreementDepositUpdateForm = (props: {
  rentalAgreement: RentalAgreement;
  onClose: () => void;
}) => {
  const [initialValues] = useState({
    deposit: props.rentalAgreement.data.deposit,
    marks: {
      deposit: props.rentalAgreement.data.marks?.deposit,
    },
  });
  const kind = ObjectKindStruct.getKind(
    OrgaStruct.getObject(props.rentalAgreement.data.objectId)?.objectKindId
  );
  return (
    <FormStruct
      onSubmit={async (values) => {
        await CBRentalService.submitRentalAgreementDeposit(
          props.rentalAgreement._id,
          values.deposit,
          values.marks
        );
        props.onClose();
      }}
      initialValues={initialValues}
      onAbort={props.onClose}
      title={i18n.t(
        i18n.t("cb:RentalAgreement.Form.SectionTitle.Depost", "Kaution")
      )}
      submitText={i18n.t("Global.Buttons.save")}
      additionalActions={
        <BFButton
          appearance="outline"
          onClick={async () => {
            ModalManager.confirm({
              title: i18n.t(
                "cb:RentalAgreement.Form.Delete.Deposit.title",
                "Kaution entfernen"
              ),
              message: i18n.t(
                "cb:RentalAgreement.Form.Delete.Deposit.description",
                "Wollen Sie die Kaution entfernen?"
              ),
              onConfirm: () => {
                CBRentalService.removeRentalAgreementDeposit(
                  props.rentalAgreement._id
                ).then(() => {
                  props.onClose();
                });
              },
            });
          }}
        >
          {i18n.t("Global.Buttons.delete")}
        </BFButton>
      }
      renderRight={(form) => (
        <div
          className={`pdf-viewer`}
          style={{
            width: "40vw",
            maxWidth: 1000,
            padding: 10,
          }}
        >
          <BFAssetPDFViewer
            identifier="rental-agreement-pdf"
            height={"78vh"}
            label={{
              single: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreement",
                "Mietvertrag"
              ),
              multiple: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreements",
                "Mietverträge"
              ),
            }}
            asset={props.rentalAgreement}
            assetField="data.attachments"
            assetType={AssetTypes.Rental.RentalAgreement}
            directoryId={
              (
                kind.data.features.find(
                  (e) => e.type === "immo"
                ) as ObjectKindFeature_IMMO
              )?.rentalAgreementDepositDirectory
            }
          />
        </div>
      )}
      render={(formProps) => (
        <>
          <CBRentalAgreementDepositForm
            rentalAgreement={props.rentalAgreement}
            kind={kind}
            objectId={props.rentalAgreement.data.objectId}
            force
          />
        </>
      )}
    />
  );
};
