import { ActivityAbstractStructClass } from "@/redux/actions/struct/implemented/ActivityAbstractStruct";
import classNames from "classnames";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { Trans } from "react-i18next";
import Collapse from "rsuite/esm/Animation/Collapse";
import FormFieldValues from "../../../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../i18n";
import AutosizeTextarea from "../../../../../modules/abstract-ui/forms/autosize-textarea/AutosizeTextarea";
import BFInput from "../../../../../modules/abstract-ui/forms/input/BFInput";
import BFToggle from "../../../../../modules/abstract-ui/forms/toggle/BFToggle";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import { useActivityConstants } from "../../ActivityHooks";
import { APActivity } from "../../ActivityInterfaces";
import ActivityService from "../../ActivityService";
import { APActivityOffer } from "./APActivityOffers";
import "./APActivityStartApproval.scss";

const ACTIVATE_URGENT_FEATURE = true;

interface Props {
  activity: APActivity;
  onSuccess: (data: string[]) => void;
  onAbort: () => void;
  activityStruct: ActivityAbstractStructClass<any>;
}
const APActivityStartApproval = (props: Props) => {
  const activityStruct = props.activityStruct;
  const constants = useActivityConstants();
  const [selected, setSelected] = useState<string[]>([]);
  const config = activityStruct.getConfig(props.activity.data.type);

  const onSubmit = async (data) => {
    const result = await ActivityService.startApprovalDeprecated(
      constants?.serviceUrl,
      props.activity._id,
      data.displayName,
      data.offers,
      ACTIVATE_URGENT_FEATURE ? data.urgent : undefined,
      ACTIVATE_URGENT_FEATURE ? data.urgentComment : undefined,
      constants.assetType
    );
    props.onSuccess(selected);
  };
  return (
    <div className={classNames(`ap-activity-start-approval`)}>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          urgent: false,
          urgentComment: "",
        }}
        validate={(data) => {
          const errors: any = {};
          if (!data.displayName) {
            errors["displayName"] = i18n.t("Global.Labels.required");
          }
          // if (!data.checkedForPriceComparison) {
          //   errors["checkedForPriceComparison"] = i18n.t(
          //     "Global.Labels.required"
          //   );
          // }

          const furtherElementsNeeded =
            config.data.offersNeededToStartFlow - (data.offers || []).length;

          if (furtherElementsNeeded > 0) {
            errors["offers"] = "error";
          }
          return errors;
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={`description`}>
              {i18n.t(
                "apTemplate:Activity.StartApproval.Description",
                "Bitte wählen Sie die Angebote aus, die Sie zur Freigabe senden möchten. Bitte vergeben Sie dem Freigabeprozess einen passenden Namen.",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              )}
            </div>
            <div className={`name`}>
              <Field name="displayName">
                {({ input, meta }) => (
                  <BFInput
                    {...input}
                    validation={
                      meta.touched && meta.error
                        ? { level: "error", message: meta.error }
                        : undefined
                    }
                    placeholder={i18n.t(
                      "apTemplate:Activity.StartApproval.DisplayNamePlaceholder",
                      "Gebe einen Namen für die Freigabe ein",
                      {
                        ns: [constants?.assetType, "apTemplate"],
                      }
                    )}
                    label={i18n.t(
                      "apTemplate:Activity.StartApproval.DisplayName",
                      "Name für Freigabe",
                      {
                        ns: [constants?.assetType, "apTemplate"],
                      }
                    )}
                  />
                )}
              </Field>
            </div>

            {ACTIVATE_URGENT_FEATURE && (
              <div className={`urgent-feature`}>
                <Field name="urgent">
                  {({ input, meta }) => (
                    <div className={`urgend-field-container`}>
                      <div
                        className={classNames(`urgend-field`, {
                          urgent: input.value,
                        })}
                      >
                        <BFToggle
                          size="sm"
                          labelPosition="right"
                          checked={input.value}
                          onChange={(checked) => input.onChange(checked)}
                          label={i18n.t(
                            "apTemplate:Activity.StartApproval.UrgentApproval",
                            "Dringende Freigabe",
                            {
                              ns: [constants?.assetType, "apTemplate"],
                            }
                          )}
                        />
                      </div>
                    </div>
                  )}
                </Field>

                <FormFieldValues names={["urgent"]}>
                  {([urgent]) => (
                    <Collapse in={urgent} unmountOnExit>
                      <div>
                        <div className={`urgent-message`}>
                          <div className={`description`}>
                            {i18n.t(
                              "apTemplate:Activity.StartApproval.UrgentApprovalDescription",
                              "Bei einer dringenden Freigabe, werden alle Bearbeiter mit hoher Priorität benachrichtigt. Bitte geben Sie den Grund für die Dringlichkeit an.",
                              {
                                ns: [constants?.assetType, "apTemplate"],
                              }
                            )}
                          </div>
                          <Field
                            name="urgentComment"
                            validate={FormValidators.compose(
                              FormValidators.required()
                            )}
                          >
                            {({ input, meta }) => (
                              <AutosizeTextarea
                                maxHeight={300}
                                type={"textarea"}
                                placeholder={i18n.t(
                                  "apTemplate:Activity.StartApproval.UrgentApprovalPlaceholder",
                                  "Grund der Dringlichkeit",
                                  {
                                    ns: [constants?.assetType, "apTemplate"],
                                  }
                                )}
                                className={`urgent-comment`}
                                {...input}
                                {...FormValidators.getValidation(meta)}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </Collapse>
                  )}
                </FormFieldValues>
              </div>
            )}
            <Field name="offers">
              {({ input, meta }) => (
                <div className={`offer-list`}>
                  {props.activity.data.offers.map((offer) => (
                    <APActivityOffer
                      activity={props.activity}
                      offer={offer}
                      selectable
                      readonly
                      selected={input.value.includes(offer.linkToCdn)}
                      onSelect={(isSelected) =>
                        isSelected
                          ? input.onChange([...input.value, offer.linkToCdn])
                          : input.onChange(
                              input.value.filter((e) => e !== offer.linkToCdn)
                            )
                      }
                    />
                  ))}
                </div>
              )}
            </Field>

            {/* <div className={`checkedForPriceComparison`}>
              <Field name="checkedForPriceComparison">
                {({ input, meta }) => (
                  <BFCheckbox
                    checked={input.value}
                    onChange={(val, checked) => input.onChange(checked)}
                    validation={
                      meta.touched && meta.error
                        ? { level: "error", message: meta.error }
                        : undefined
                    }
                  >
                    {i18n.t(
                      "apTemplate:Activity.StartApproval.CheckedForPriceComparison",
                      "Hiermit bestätige ich, dass ich die Angebote einem online Preisvergleich unterzogen habe."
                    )}
                  </BFCheckbox>
                )}
              </Field>
            </div> */}

            <div className={`actions`}>
              <Field name="offers">
                {({ input, meta }) => {
                  const furtherElementsNeeded =
                    config.data.offersNeededToStartFlow -
                    (input.value || []).length;

                  return furtherElementsNeeded > 0 ? (
                    <div
                      className={`status ${
                        meta.touched && meta.error ? "error" : ""
                      }`}
                    >
                      {furtherElementsNeeded === 1 ? (
                        <Trans i18nKey="apTemplate:Activity.StartApproval.furtherOfferSelectionNeededSingle">
                          <span className="weight-600">
                            {{ furtherElementsNeeded }}
                          </span>{" "}
                          weiteres Angebot selektieren um fortzufahren
                        </Trans>
                      ) : (
                        <Trans i18nKey="apTemplate:Activity.StartApproval.furtherOfferSelectionNeededMultiple">
                          <span className="weight-600">
                            {{ furtherElementsNeeded }}
                          </span>{" "}
                          weitere Angebote selektieren um fortzufahren
                        </Trans>
                      )}
                    </div>
                  ) : null;
                }}
              </Field>
              <BFButton
                appearance="outline"
                type="button"
                onClick={props.onAbort}
              >
                {i18n.t("Global.Buttons.cancel")}
              </BFButton>
              <BFButton appearance="primary" type="submit" loading={submitting}>
                {i18n.t(
                  "apTemplate:Activity.StartApproval.startApproval",
                  "Freigabe starten",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )}
              </BFButton>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default APActivityStartApproval;
