import classNames from "classnames";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Field, FieldInputProps } from "react-final-form";
import FormStruct from "../../components/Form/FormStruct/FormStruct";
import i18n from "../../i18n";
import ObjectIdService from "../../utils/ObjectIdUtils";
import BFGroupedOrderList from "../abstract-ui/data/grouped-order-list/BFGroupedOrderList";
import BFInput from "../abstract-ui/forms/input/BFInput";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BFDropdown from "../abstract-ui/general/Dropdown/BFDropdown";
import BfIcon from "../abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../abstract-ui/icon/DefaultIcons";
import EZTextfield from "../ez-form/form-elements/ez-textfield/EZTextfield";
import { TemplateAsset } from "../template-module/TemplateInterfaces";
import { loadTemplate, saveTemplate } from "../template-module/TemplateMethods";
import { PlainTableGroup } from "./PlanTable";
import "./PlanTableGroupManage.scss";

interface PlanTableGroupManageProps {
  onClose: () => void;
  onUpdate: (data: PlainTableGroup[]) => void;

  groups: PlainTableGroup[];
  disableEntriesDeletion?: string[];
  templateData?: {
    templateType: string;
    type: string;
  };
}
const PlanTableGroupManage = (props: PlanTableGroupManageProps) => {
  const [initial] = useState({
    groups: props.groups.map((group) => ({
      id: group.id,
      name: group.name,
      metaValues: {},
      items: group.children?.map((child) => ({
        id: child.id,
        data: { name: child.name },
      })),
    })),
  });
  return (
    <FormStruct
      initialValues={initial}
      className={classNames("plan-table-group-maanage-form")}
      title={i18n.t("PlanTable.ManageGroup.Title", "Gruppen bearbeiten")}
      description={i18n.t(
        "PlanTable.ManageGroup.Description",
        "Bearbeiten Sie hier die Gruppen und dessen Untergruppen."
      )}
      onAbort={props.onClose}
      submitText={i18n.t("Global.Buttons.save")}
      onSubmit={async (values) => {
        const groups = values.groups;
        const data: PlainTableGroup[] = groups.map((group) => ({
          id: group.id,
          name: group.name,
          children: group.items.map((e) => ({
            id: e.id,
            name: e.data.name,
          })),
        }));
        props.onUpdate(data);
        props.onClose();
      }}
      render={({ form }) => (
        <PlanTableGroupFields
          disableEntriesDeletion={props.disableEntriesDeletion}
          templateData={props.templateData}
        />
      )}
    />
  );
};

export default PlanTableGroupManage;

export const PlanTableGroupFields = (props: {
  disableEntriesDeletion?: string[];
  templateData?: {
    templateType: string;
    type: string;
  };
}) => {
  return (
    <div className="plan-table-group-maanage">
      <Field
        name="groups"
        validate={(value) => {
          if (value.find((g) => (g.name || "").trim().length === 0)) {
            return i18n.t(
              "PlanTable.ManageGroup.NameRequired",
              "Name für Gruppen sind erforderlich"
            );
          } else if (
            value.find((g) =>
              g.items.find((e) => e.data.name.trim().length === 0)
            )
          ) {
            return i18n.t(
              "PlanTable.ManageGroup.NameOfEntriesRequired",
              "Name für Einträge sind erforderlich"
            );
          } else if (_.uniq(value.map((e) => e.name)).length !== value.length) {
            return i18n.t(
              "PlanTable.ManageGroup.GroupNamesUnique",
              "Name für Gruppen dürfen nicht doppelt vorkommen"
            );
          } else if (value.find((group) => (group.items || []).length === 0)) {
            return i18n.t(
              "PlanTable.ManageGroup.GroupEntriesRequired",
              "Es muss mindestens ein Eintrag pro Gruppe geben."
            );
          } else if (value.length === 0) {
            return i18n.t(
              "PlanTable.ManageGroup.GroupRequired",
              "Es muss mindestens eine Gruppe geben."
            );
          }
          return undefined;
        }}
      >
        {({ input, meta }) => (
          <>
            <BFGroupedOrderList
              highlightErrors={meta.touched && meta.error}
              disableRemoveForGroup={input.value
                .filter((group) =>
                  group.items.some((e) =>
                    props.disableEntriesDeletion?.includes(e.id)
                  )
                )
                .map((e) => e.id)}
              value={input.value || []}
              onChange={input.onChange}
              renderGroupActions={
                props.templateData
                  ? (groupId, name) => {
                      return (
                        <BFDropdown
                          className={`manage-group-actions`}
                          toggleAs={(toggleProps) => (
                            <BFButton {...toggleProps} appearance={"link"}>
                              <BfIcon {...DefaultIcons.MORE} size="xs" />
                            </BFButton>
                          )}
                          label="more"
                          items={[
                            {
                              type: "button",
                              text: i18n.t(
                                "PlanTable.ManageGroup.SaveTemplate",
                                "Als Vorlage speichern"
                              ),
                              onSelect: () => {
                                saveTemplate(
                                  props.templateData.type,
                                  props.templateData.templateType,
                                  convertGroupToTemplate(input.value, groupId),
                                  name
                                );
                              },
                            },
                          ]}
                        />
                      );
                    }
                  : undefined
              }
              additionalAddActions={
                props.templateData ? (
                  <BFButton
                    onClick={async () => {
                      const result = (await loadTemplate(
                        props.templateData.type,
                        props.templateData.templateType
                      )) as TemplateAsset;
                      if (result) {
                        const newGroups = [...input.value];
                        newGroups.push({
                          id: ObjectIdService.new(),
                          name: result.data.data.name,
                          items: result.data.data.children?.map((item) => ({
                            id: ObjectIdService.new(),
                            data: { name: item.name },
                          })),
                          metaValues: {},
                        });
                        input.onChange(newGroups);
                      }
                    }}
                    appearance="link"
                  >
                    {i18n.t(
                      "PlanTable.createFromTemplate",
                      "Aus Vorlage hinzufügen"
                    )}
                  </BFButton>
                ) : undefined
              }
              renderItem={(id, data, isDragged, group) => {
                return (
                  <div className={`plan-table-entry`}>
                    <div className={`name`}>
                      <EZTextfield
                        error={
                          meta.touched && meta.error && data.name.trim() === ""
                        }
                        ignoreLinebreaks
                        value={data.name}
                        onChange={(value) => {
                          input.onChange(
                            (input.value || []).map((groupEntry) =>
                              groupEntry.id === group
                                ? {
                                    ...groupEntry,
                                    items: groupEntry.items.map((item) => {
                                      if (item.id === id) {
                                        return {
                                          ...item,
                                          data: {
                                            ...item.data,
                                            name: value,
                                          },
                                        };
                                      }
                                      return item;
                                    }),
                                  }
                                : groupEntry
                            )
                          );
                        }}
                        appearance="default"
                        textType="default"
                        placeholder={i18n.t(
                          "PlanTable.ManageGroup.NamePlaceholder",
                          "Name des Eintrags"
                        )}
                      />
                    </div>
                    <div className={`action`}>
                      <BFButton
                        disabled={(props.disableEntriesDeletion || []).includes(
                          id
                        )}
                        tooltip={
                          (props.disableEntriesDeletion || []).includes(id)
                            ? {
                                tooltip: i18n.t(
                                  "PlanTable.ManageGroup.disabledReason",
                                  "Es wurde bereits auf diese Position gebucht, sie kann nicht gelöscht werden."
                                ),
                              }
                            : undefined
                        }
                        appearance={"link"}
                        size="xxs"
                        onClick={() => {
                          const currentGroupOfId = input.value.find((group) =>
                            group.items.find((e) => e.id === id)
                          );
                          input.onChange(
                            (input.value || []).map((group) =>
                              group.id === currentGroupOfId.id
                                ? {
                                    ...group,
                                    items: group.items.filter(
                                      (e) => e.id !== id
                                    ),
                                  }
                                : group
                            )
                          );
                        }}
                      >
                        <BfIcon size="xxs" {...DefaultIcons.TRASH} />
                      </BFButton>
                    </div>
                  </div>
                );
              }}
              onAddGroup={() => {
                input.onChange([
                  ...input.value,
                  {
                    id: ObjectIdService.createNewObjectIdInstance().toString(),
                    name: "",
                    items: [],
                    metaValues: {
                      // initialCollapsed: false,
                    },
                  },
                ]);
              }}
              // onGroupRemove={(id) => {
              //   const allRemovedItems =
              //     input.value.find((group) => group.id === id)?.items || [];
              //   const newConfig = input.value
              //     .filter((group) => group.id !== id)
              //     .map((group) =>
              //       group.id === "default-entries"
              //         ? {
              //             ...group,
              //             items: [...group.items, ...allRemovedItems],
              //           }
              //         : group
              //     );
              //   input.onChange(newConfig);
              // }}
              renderGroupForm={(id, metaValues, onMetaChange) => (
                <GroupForm id={id} input={input} />
              )}
            />
            {meta && meta.touched && meta.error && (
              <div className={`error`}>{meta.error}</div>
            )}
          </>
        )}
      </Field>
    </div>
  );
};
const GroupForm = (props: {
  input: FieldInputProps<any, HTMLElement>;
  id: string;
}) => {
  const ref = useRef<HTMLDivElement>();
  return (
    <div ref={ref} className={`plan-table-group-manage-add-entry`}>
      <BFDropdown
        onToggle={(open) => {
          if (open) {
            setTimeout(() => {
              ref.current?.querySelector("input").focus();
            }, 50);
          }
        }}
        renderToggle={(props) => (
          <BFButton {...props} appearance="link" noPadding>
            {i18n.t("PlanTable.ManageGroup.AddEntry", "Eintrag hinzufügen")}
          </BFButton>
        )}
        label={i18n.t("PlanTable.ManageGroup.AddEntry", "Eintrag hinzufügen")}
        items={[
          {
            type: "panel",
            children: (
              <AddGroupEntry
                onAdd={(name) => {
                  props.input.onChange(
                    props.input.value.map((e) =>
                      e.id === props.id
                        ? {
                            ...e,
                            items: [
                              ...e.items,
                              {
                                id: ObjectIdService.createNewObjectIdInstance().toString(),
                                data: {
                                  name,
                                },
                              },
                            ],
                          }
                        : e
                    )
                  );
                }}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
const AddGroupEntry = (props: { onAdd: (name: string) => void }) => {
  const [name, setName] = useState<string>("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (showError) {
      if (name.trim().length > 0) {
        setShowError(false);
      }
    }
  }, [name]);
  const onAdd = () => {
    if (name.trim().length === 0) {
      setShowError(true);
    } else {
      props.onAdd(name);
      setName("");
      //   DataBus.emit("WHISPER", {
      //     identifier: IDENT_OF_OVERLAY,
      //     type: "CLOSE",
      //   });
    }
  };
  return (
    <div className={`plan-table-add-entry-form`}>
      <BFInput
        validation={
          showError
            ? {
                level: "error",
                message: i18n.t(
                  "PlanTable.ManageGroup.NameMissing",
                  "Geben Sie den Namen für den Eintrag an"
                ),
              }
            : undefined
        }
        placeholder={i18n.t(
          "PlanTable.ManageGroup.AddEntry",
          "Name des Eintrags"
        )}
        type="text"
        focusOnMount
        value={name}
        onChange={(value: string) => setName(value)}
        onKeyDown={(ev) => {
          if (ev.keyCode === 13) {
            onAdd();
            ev.preventDefault();
            ev.stopPropagation();
          }
        }}
        suffixOnClick={() => {
          onAdd();
        }}
        suffix={<BfIcon type="light" data="add" size="xxs" />}
      />
    </div>
  );
};

const convertGroupToTemplate = (groups: any[], groupId: string) => {
  const groupToSave = groups.find((e) => e.id === groupId);

  if (groupToSave) {
    return {
      name: groupToSave.name,
      children: groupToSave.items?.map((e) => ({
        name: e.data?.name,
      })),
    };
  }
  return null;
};
