import CBVacancyCurrentActivityCard from "@/apps/tatar/cashBudget/views/portfolio/object/cards/vacancy/CBVacancyCurrentActivityCard";
import CBVacancyDistributionCard from "@/apps/tatar/cashBudget/views/portfolio/object/cards/vacancy/CBVacancyDistributionCard";
import CBVacancyHistoryCard from "@/apps/tatar/cashBudget/views/portfolio/object/cards/vacancy/CBVacancyHistoryCard";
import {
  CBVacancyWALE,
  CBVacancyWALT,
} from "@/apps/tatar/cashBudget/views/portfolio/object/cards/vacancy/CBVacancySingleValues";
import CBVacancySoonCard from "@/apps/tatar/cashBudget/views/portfolio/object/cards/vacancy/CBVacancySoonCard";
import "./ObjectDetailVacancy.scss";

type PropsObject = {
  refId: string;
  assets: string[];
};
type Props = PropsObject;
const ObjectDetailVacancy = (props: Props) => {
  return (
    <div className="object-vacancy">
      <div className="single-kpis">
        <div className="entry">
          <CBVacancyWALT referenceId={props.refId} objectIds={props.assets} />
        </div>

        <div className="entry">
          <CBVacancyWALE referenceId={props.refId} objectIds={props.assets} />
        </div>
      </div>
      <CBVacancyDistributionCard
        referenceId={props.refId}
        objectIds={props.assets}
        marginBottom={20}
      />
      <CBVacancyHistoryCard
        referenceId={props.refId}
        objectIds={props.assets}
        marginBottom={20}
      />
      <CBVacancyCurrentActivityCard
        referenceId={props.refId}
        objectIds={props.assets}
        marginBottom={20}
      />
      <CBVacancySoonCard
        referenceId={props.refId}
        objectIds={props.assets}
        marginBottom={20}
      />
    </div>
  );
};
export default ObjectDetailVacancy;

//KPI
