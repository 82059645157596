import i18n from "../../../../i18n";
import BfIcon from "../../../abstract-ui/icon/BfIcon";
import "./DSUnvalidDirectory.scss";

type DSUnvalidDirectoryProps = {
  path: string;
};

const DSUnvalidDirectory = (props: DSUnvalidDirectoryProps) => {
  return (
    <div className="ds-unavialable-directory">
      <div className="message">
        <BfIcon size="3x" data="folder-warning" type="light" />
        {i18n.t(
          "DocumentStore.UnavailableDirectory.NotFound",
          "Der Ordner konnte nicht gefunden werden..."
        )}
      </div>
      <div className="directory-path">
        {i18n.t("DocumentStore.UnavailableDirectory.Path", "Pfad:")}{" "}
        {props.path}
      </div>
    </div>
  );
};

export default DSUnvalidDirectory;
