import classNames from "classnames";
import ModalManager from "../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../i18n";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import { default as LanguageService } from "../../../../services/LanguageService";
import BFDropzone from "../../../abstract-ui/dropzone/BFDropzone";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import BfIcon from "../../../abstract-ui/icon/BfIcon";
import {
  DSListEntryAction,
  DocumentStoreAssetParams,
  DocumentStoreDirectory,
  DocumentStoreDocument,
} from "../../DSInterfaces";
import DSService from "../../DSService";
import DSDocumentList from "../DSDocumentList/DSDocumentList";
import DSDocumentMoveToDirectoryView from "../DSDocumentMoveToDirectoryView/DSDocumentMoveToDirectoryView";
import DSListEntry from "../DSListEntry/DSListEntry";
import DSUploadDialog from "../DSUploadDialog/DSUploadDialog";
import "./DSDocumentDirectoryView.scss";

type DocumentStoreDirectoryViewProps = {
  permissions?: {
    create?: boolean;
    edit?: boolean;
    delete?: boolean;
    move?: boolean;
  };
  documentDirectoryRoot: DocumentStoreDirectory;
  documentDirectory: DocumentStoreDirectory;
  documentsMap: Map<string, DocumentStoreDocument[]>;
  assetParams: DocumentStoreAssetParams;
  onClickFolderEntry: (directory: DocumentStoreDirectory) => void;
  onClickBack: () => void;
  directoryEntryActions?: DSListEntryAction<DocumentStoreDirectory>[];
  documentEntryActions?: DSListEntryAction<DocumentStoreDocument>[];
  renderPostfixElement?: (document: DocumentStoreDocument) => React.ReactNode;
};

const DSDocumentDirectoryView = (props: DocumentStoreDirectoryViewProps) => {
  const documentsForDirectory =
    props.documentsMap.get(props.documentDirectory.id) || [];
  const flattenDirectoryMap = DSService.getFlattenDirectoryMap(
    props.documentDirectory
  );

  const hasUnmetRequirements = !DSService.doesDirectoryMeetRequirements(
    props.documentDirectory,
    props.documentsMap
  );

  const allowUpload = !props.permissions || props.permissions.create;
  const accept = props.documentDirectory.accept || DSService.getDefaultAccept();

  const openUploadDialog = (
    files: File[],
    directory: DocumentStoreDirectory
  ) => {
    if (!allowUpload) {
      DataBusDefaults.toast({
        type: "warning",
        text: i18n.t(
          "ds:DocumentStore.Upload.NotAllowed",
          "Sie haben keine Berechtigung um Dateien hochzuladen"
        ),
      });
      return;
    }
    ModalManager.show({
      title: i18n.t(
        "ds:DocumentStore.UploadDocuments.Title",
        "Dokumente hochladen"
      ),
      buttons: [],
      backdrop: "static",
      size: "lg",
      modalClassName: "ap-activity-upload-attachment-dialog",
      content: (states, setStates, closeModal) => (
        <DSUploadDialog
          directory={directory}
          files={files}
          assetParams={props.assetParams}
          onSuccess={(data) => {
            if (directory.id !== props.documentDirectory.id) {
              props.onClickFolderEntry(directory);
            }
            closeModal();
          }}
          onAbort={() => {
            closeModal();
          }}
        />
      ),
    });
  };

  const documentEntryActions = [...(props.documentEntryActions || [])];

  const allowEdit = !props.permissions || props.permissions.edit;
  const allowDelete = !props.permissions || props.permissions.delete;
  const allowMove = !props.permissions || props.permissions.move;
  const hasPermissionContext = allowEdit || allowDelete || allowMove;
  if (hasPermissionContext) {
    documentEntryActions.push({
      type: "divider",
    });
    if (allowEdit) {
      documentEntryActions.push({
        type: "button",
        text: i18n.t("ds:DocumentStore.EditDocument", "Name ändern"),
        onSelect: (document: DocumentStoreDocument) => {
          ModalManager.input({
            title: i18n.t("ds:DocumentStore.EditDocument.Title", "Dokument"),
            message: i18n.t(
              "ds:DocumentStore.EditDocument.Message",
              "Geben Sie den neuen Namen für das Dokument an"
            ),
            inputProps: {
              placeholder: i18n.t(
                "ds:DocumentStore.EditDocument.Placeholder",
                "Neuer Name"
              ),
              focusOnMount: true,
            },
            defaultValue: document.name,
            confirmButtonText: i18n.t(
              "ds:DocumentStore.EditDocument.Confirm",
              "Name ändern"
            ),
            onFinish: (value: string) => {
              DSService.renameDocument(props.assetParams, document, value);
            },
          });
        },
      });
    }
    if (allowMove) {
      documentEntryActions.push({
        type: "menu",
        text: i18n.t("ds:DocumentStore.MoveDokument", "Dokument verschieben"),
        items: [
          {
            type: "panel",
            render: (document: DocumentStoreDocument) => {
              return (
                <DSDocumentMoveToDirectoryView
                  directoryConfRoot={props.documentDirectoryRoot}
                  onDirectoryClick={(directory: DocumentStoreDirectory) => {
                    DSService.moveDocument(
                      props.assetParams,
                      document,
                      directory.id
                    );
                  }}
                />
              );
            },
          },
        ],
      });
    }
    if (allowDelete) {
      documentEntryActions.push({
        type: "button",
        text: i18n.t("ds:DocumentStore.DeleteDokument", "Dokument löschen"),
        onSelect: (document: DocumentStoreDocument) => {
          ModalManager.confirm({
            message: i18n.t(
              "ds:DocumentStore.DeleteDokument.Confirmation",
              "Wollen Sie das Dokument wirklich löschen?"
            ),
            title: document.name,
            confirmButtonText: i18n.t(
              "ds:DocumentStore.DeleteDokument.Confirm",
              "Dokument löschen"
            ),
            onConfirm: async () => {
              await DSService.deleteDocument(props.assetParams, document);
            },
          });
        },
      });
    }
  }

  const hasSubdirectories =
    props.documentDirectory.subDirectories &&
    props.documentDirectory.subDirectories.length > 0;

  return (
    <div className={classNames("document-directory-view")}>
      <div className="document-directory-header">
        {!props.documentDirectory.isRoot && (
          <BFButton
            className="ds-back-button"
            onClick={() => props.onClickBack()}
          >
            <BfIcon type="light" data="arrow-left" size="xs" />
          </BFButton>
        )}
        {hasUnmetRequirements && (
          <div className="unment-requirements-message">
            <BfIcon type="color" data="alert-triangle" size="sm" />
            <div className="message">
              {LanguageService.translateLabel(
                props.documentDirectory.directoryRequirements
                  .requiredErrorMessage
              )}
            </div>
          </div>
        )}
        {allowUpload && (
          <BFDropzone
            className="ds-upload-button"
            accept={accept}
            multipe
            suppressDragOverlay={true}
            key={`${props.documentDirectory.pathIdentifier}`}
            onDrop={(accepted, rejected, ev) => {
              if (accepted.length > 0 && rejected.length === 0) {
                openUploadDialog(accepted, props.documentDirectory);
              }
            }}
            render={(openUploadDialog, isDragActive) => {
              return (
                <BFButton
                  appearance="clear-on-white"
                  icon={{ data: "folder-upload", type: "color", size: "sm" }}
                  onClick={() => openUploadDialog()}
                  text={i18n.t(
                    "ds:DocumentStore.UploadDocuments.Title",
                    "Hochladen"
                  )}
                />
              );
            }}
          />
        )}
      </div>
      {hasSubdirectories && (
        <div className="ds-directory-list">
          {props.documentDirectory.subDirectories.map((directory) => {
            let indicatorProps = undefined;
            const directoriesWithUnmetRequirements =
              DSService.findUnmetRequirementsInDescendants(
                props.documentsMap,
                directory,
                flattenDirectoryMap
              );

            if (directoriesWithUnmetRequirements.length > 0) {
              const requiredErrorMsg = directoriesWithUnmetRequirements.map(
                (directory) => (
                  <div key={directory.id}>
                    {LanguageService.translateLabel(
                      directory.directoryRequirements.requiredErrorMessage
                    )}
                  </div>
                )
              );
              indicatorProps = {
                showIndicator: true,
                indicatorText: requiredErrorMsg,
              };
            }

            return (
              <BFDropzone
                accept={directory.accept || DSService.getDefaultAccept()}
                suppressDragOverlay={true}
                multipe
                key={`${directory.pathIdentifier}`}
                onDrop={(accepted, rejected, ev) => {
                  if (accepted.length > 0 && rejected.length === 0) {
                    openUploadDialog(accepted, directory);
                  }
                }}
                render={(openUploadDialog, isDragActive) => {
                  const iconName = isDragActive
                    ? "office-folder-6"
                    : "folder-empty-10";
                  return (
                    <>
                      {isDragActive && <div className="drag-overlay"></div>}
                      <DSListEntry
                        type="directory"
                        entryObject={directory}
                        iconName={iconName}
                        name={LanguageService.translateLabel(directory.name)}
                        onClick={() => props.onClickFolderEntry(directory)}
                        indicatorProps={indicatorProps}
                        entryActions={props.directoryEntryActions}
                      />
                    </>
                  );
                }}
              />
            );
          })}
        </div>
      )}

      <BFDropzone
        key={`${props.documentDirectory.pathIdentifier}`}
        accept={accept}
        multipe
        onDrop={(accepted, rejected, ev) => {
          if (accepted.length > 0 && rejected.length === 0) {
            openUploadDialog(accepted, props.documentDirectory);
          }
        }}
        render={(openUploadDialog, isDragActive) => {
          return (
            <div className="ds-document-list-container">
              <DSDocumentList
                renderPostfixElement={props.renderPostfixElement}
                documents={documentsForDirectory}
                assetParams={props.assetParams}
                hideOrphanedIndicator={props.documentDirectory.isVirtual}
                documentEntryActions={documentEntryActions}
              />
              {allowUpload && (
                <div className="ds-document-list-dropzone">
                  <BFButton
                    appearance="clear-on-white"
                    icon={{ type: "light", data: "add", size: "xl" }}
                    className={"dropzone-content"}
                    onClick={() => openUploadDialog()}
                  />
                  <span className="dropzone-content">
                    {i18n.t(
                      "ds:DocumentStore.Dropzone.Description",
                      "Legen Sie hier Dokumente ab um diese hochzuladen."
                    )}
                  </span>
                </div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export default DSDocumentDirectoryView;
