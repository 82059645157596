import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import _ from "lodash";
import moment from "moment";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import AssetLoader from "../../../../../../../../components/AssetLoader/AssetLoader";
import CDNImage from "../../../../../../../../components/CDNImage/CDNImage";
import FormStruct from "../../../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../../../components/Form/Validation/FormValidators";
import LoadPage from "../../../../../../../../components/LoadPage/LoadPage";
import ModalManager from "../../../../../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../../model/AssetTypes";
import BFValueDisplay from "../../../../../../../../modules/abstract-ui/data/value-display/BFValueDisplay";
import BFInput from "../../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../../../../../../modules/abstract-ui/general/Button/BFButton";
import { useStatisticQuery } from "../../../../../../../../redux/hooks";
import SubmitService from "../../../../../../../../services/SubmitService";
import NumberUtils from "../../../../../../../../utils/ NumberUtils";
import StringUtils from "../../../../../../../../utils/StringUtils";
import { CBPortfolioObject } from "../../../interfaces/CBPortfolioAsset";
import RiskScenarioChart from "./RiskScenarioChart";
import "./RiskScenarioForm.scss";
import {
  RiskObjectData,
  RiskObjectLoanData,
  RiskScenario,
  RiskScenarioLoanRiskResponse,
} from "./RiskScenarioInterfaces";

interface FormValue {
  name: string;
  description: string;
  objectData: RiskObjectData[];
}

interface CreateRiskProps {
  objectIds: string[];
  type: "create";
  referenceId: string;
}
interface EditRiskProps {
  riskScenario: RiskScenario;
  type: "edit";
  onDelete: () => void;
}
type RiskScenarioFormProps = (CreateRiskProps | EditRiskProps) & {
  onClose: () => void;
  onSuccess: (riskScenario: RiskScenario) => void;
  onError: (err: any) => void;
};
const RiskScenarioForm = (props: RiskScenarioFormProps) => {
  const loanRiskData = useStatisticQuery<RiskScenarioLoanRiskResponse>(
    "LOAN_RISK",
    null,
    {
      objectIds:
        props.type === "create"
          ? props.objectIds
          : props.riskScenario.data.objectData.map((obj) => obj.objectId),
    }
  );

  if (loanRiskData.loading) {
    return <LoadPage />;
  }
  if (loanRiskData.error) {
    return <div>ERROR</div>;
  }
  return (
    <FormStruct
      className={"risk-scenario-form"}
      additionalActions={
        props.type === "edit" && (
          <>
            <BFButton
              appearance="outline"
              onClick={() => {
                ModalManager.confirm({
                  message: i18n.t(
                    "cb:RiskScenario.Buttons.deleteConfirm",
                    "Sind Sie sicher, dass Sie das Risikoszenario löschen möchten?"
                  ),
                  confirmButtonText: i18n.t(
                    "cb:RiskScenario.Buttons.delete",
                    "Szenario löschen"
                  ),
                  onConfirm: () => {
                    props.onDelete();
                  },
                });
              }}
            >
              {i18n.t("Global.Buttons.delete", "Löschen")}
            </BFButton>
          </>
        )
      }
      initialValues={
        props.type === "create"
          ? {
              referenceId: props.referenceId,
              name: "",
              description: "",
              objectData: loanRiskData.data.objects.map(
                (obj) =>
                  ({
                    objectId: obj.objectId,
                    estimatedRentNetGrowth: 2,
                    estimatedVacancyRate: 15,
                    estimatedCostFactor: 10,
                    loanData: loanRiskData.data.loans
                      .filter((loan) => loan.objectId === obj.objectId)
                      .map(
                        (loan) =>
                          ({
                            simulationForLoan: loan.loanId,
                            startDate: moment(loan.endDate)
                              .add(1, "month")
                              .toDate(),
                            startValue: loan.endValue,
                            interestRate: 4, // loan.interestRate,
                            duration: 10,
                            repaymentRate: 1.0,
                          } as Partial<RiskObjectLoanData>)
                      ),
                  } as Partial<RiskObjectData>)
              ),
            }
          : ({
              name: props.riskScenario.data.name,
              description: props.riskScenario.data.description,
              objectData: props.riskScenario.data.objectData,
            } as FormValue)
      }
      title={
        props.type === "edit"
          ? i18n.t("cb:RiskScenario.Edit.Title", "Riskio Szenario bearbeiten")
          : i18n.t("cb:RiskScenario.Create.Title", "Risiko Szenario erstellen")
      }
      description={i18n.t(
        "cb:RiskScenario.Description",
        "Erstellen Sie ein Risiko Szenario, indem Sie für die Darlehen der ausgewählten Objekte Folgedarlehen konfigurieren. Ändern Sie die Werte der Darlehen um eine Simulation durchzuführen."
      )}
      submitText={
        props.type === "edit"
          ? i18n.t("cb:RiskScenario.Buttons.save", "Änderungen speichern")
          : i18n.t("cb:RiskScenario.Buttons.create", "Risikoszenario speichern")
      }
      onAbort={props.onClose}
      onSubmit={async (values: FormValue) => {
        try {
          const result = (await SubmitService.submitDataAsync({
            type: "asset",
            assetType: AssetTypes.Portfolio.RiskScenario,
            data: {
              _id: props.type === "edit" ? props.riskScenario._id : undefined,
              data: {
                ...values,
              },
            },
            ignorePropChecks: true,
            ignoreSubmitValidation: true,
          })) as RiskScenario;
          props.onSuccess(result);
          props.onClose();
        } catch (err) {
          props.onError?.(err);
        }
      }}
      // validate={validate}
      render={({ form }) => (
        <>
          <div className={`form`}>
            <div className={`scenario-fields`}>
              <div className={`__field`}>
                <Field
                  name="name"
                  validate={FormValidators.compose(
                    FormValidators.required(),
                    FormValidators.max(100)
                  )}
                >
                  {({ input, meta }) => (
                    <BFInput
                      label={
                        i18n.t("cb:RiskScenario.Form.name", "Bezeichnung") + "*"
                      }
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>
              <div className={`__field`}>
                <Field
                  name="description"
                  validate={FormValidators.compose(FormValidators.max(250))}
                >
                  {({ input, meta }) => (
                    <BFInput
                      label={i18n.t(
                        "cb:RiskScenario.Form.description",
                        "Beschreibung"
                      )}
                      type="textarea"
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>
            </div>

            <FieldArray name="objectData">
              {({ fields: objectFields }) => {
                const countObjects = objectFields.length;
                const avgEstimatedRentNetGrowth = NumberUtils.normalizeDecimal(
                  _.sumBy(
                    objectFields.value,
                    (value) => value.estimatedRentNetGrowth
                  ) / countObjects
                );
                const allEstimatedRentNetGrowthAtAvg = !objectFields.value.some(
                  (e) => e.estimatedRentNetGrowth !== avgEstimatedRentNetGrowth
                );

                const avgEstimatedVacancyRate = NumberUtils.normalizeDecimal(
                  _.sumBy(
                    objectFields.value,
                    (value) => value.estimatedVacancyRate
                  ) / countObjects
                );
                const allEstimatedVacancyRateAtAvg = !objectFields.value.some(
                  (e) => e.estimatedVacancyRate !== avgEstimatedVacancyRate
                );
                const avgEstimatedCostFactor = NumberUtils.normalizeDecimal(
                  _.sumBy(
                    objectFields.value,
                    (value) => value.estimatedCostFactor
                  ) / countObjects
                );
                const allEstimatedCostFactorAtAvg = !objectFields.value.some(
                  (e) => e.estimatedCostFactor !== avgEstimatedCostFactor
                );

                const allLoans = _.flatMap(
                  objectFields.value,
                  (value) => value.loanData
                );
                const countLoans = allLoans.length;
                const avgInterestRate = NumberUtils.normalizeDecimal(
                  _.sumBy(allLoans, (value) => value.interestRate) / countLoans
                );
                const allInterestedRateAtAvg = !allLoans.some(
                  (e) => e.interestRate !== avgInterestRate
                );
                const avgRepaymentRate = NumberUtils.normalizeDecimal(
                  _.sumBy(allLoans, (value) => value.repaymentRate) / countLoans
                );
                const allRepaymentRateAtAvg = !allLoans.some(
                  (e) => e.repaymentRate !== avgRepaymentRate
                );
                const avgDuration = NumberUtils.normalizeDecimal(
                  _.sumBy(allLoans, (value) => value.duration) / countLoans
                );
                const allDurationAtAvg = !allLoans.some(
                  (e) => e.duration !== avgDuration
                );

                return (
                  <div className={`field-data-sum`}>
                    <div className={`data-sum-description`}>
                      {i18n.t(
                        "cb:RiskScenario.Form.dataSumDescription",
                        "Die Werte der Objekte und Darlehen werden hier angezeigt. Wenn Sie diese ändern, werden die Werte entsprechend in allen Objekten und Darlehen gleich gesetzt. Sie können die Werte auch einzeln für jedes Objekt und Darlehen in der Liste unterhalb ändern."
                      )}
                    </div>
                    <div className={`change-fields`}>
                      <div className={`object-data`}>
                        <div className={`subtitle`}>
                          {i18n.t(
                            "cb:RiskScenario.Form.valuesOfObjects",
                            "Objekt Plandaten"
                          )}
                          <span className={`count`}>
                            {countObjects}{" "}
                            {countObjects === 1
                              ? i18n.t(
                                  "cb:RiskScenario.Form.countObject",
                                  "Objekt"
                                )
                              : i18n.t(
                                  "cb:RiskScenario.Form.countObjects",
                                  "Objekte"
                                )}
                          </span>
                        </div>
                        <div className={`fields`}>
                          <div className={`__field`}>
                            <BFInput
                              value={avgEstimatedRentNetGrowth}
                              textAlign="right"
                              label={i18n.t(
                                "cb:RiskScenario.Form.estimatedRentNetGrowth",
                                "NKM-Anpassung"
                              )}
                              type="number"
                              prefix={
                                allEstimatedRentNetGrowthAtAvg
                                  ? i18n.t(
                                      "cb:RiskScenario.Form.allSameValue",
                                      "Alle auf"
                                    )
                                  : i18n.t(
                                      "cb:RiskScenario.Form.average",
                                      "Durchschnittlich"
                                    )
                              }
                              suffix={"%"}
                              step={0.01}
                              min={0}
                              onChange={(value: number) => {
                                objectFields.forEach((_name, index) => {
                                  objectFields.update(index, {
                                    ...objectFields.value[index],
                                    estimatedRentNetGrowth: value,
                                  });
                                });
                              }}
                            />
                          </div>
                          <div className={`__field`}>
                            <BFInput
                              value={avgEstimatedVacancyRate}
                              textAlign="right"
                              label={i18n.t(
                                "cb:RiskScenario.Form.estimatedVacancyRate",
                                "Leerstandsquote"
                              )}
                              type="number"
                              prefix={
                                allEstimatedVacancyRateAtAvg
                                  ? i18n.t(
                                      "cb:RiskScenario.Form.allSameValue",
                                      "Alle auf"
                                    )
                                  : i18n.t(
                                      "cb:RiskScenario.Form.average",
                                      "Durchschnittlich"
                                    )
                              }
                              suffix={"%"}
                              step={0.01}
                              min={0}
                              onChange={(value: number) => {
                                objectFields.forEach((_name, index) => {
                                  objectFields.update(index, {
                                    ...objectFields.value[index],
                                    estimatedVacancyRate: value,
                                  });
                                });
                              }}
                            />
                          </div>
                          <div className={`__field`}>
                            <BFInput
                              value={avgEstimatedCostFactor}
                              textAlign="right"
                              label={i18n.t(
                                "cb:RiskScenario.Form.estimatedCostFactor",
                                "Weitere Kosten"
                              )}
                              type="number"
                              prefix={
                                allEstimatedCostFactorAtAvg
                                  ? i18n.t(
                                      "cb:RiskScenario.Form.allSameValue",
                                      "Alle auf"
                                    )
                                  : i18n.t(
                                      "cb:RiskScenario.Form.average",
                                      "Durchschnittlich"
                                    )
                              }
                              suffix={"%"}
                              step={0.01}
                              min={0}
                              onChange={(value: number) => {
                                objectFields.forEach((_name, index) => {
                                  objectFields.update(index, {
                                    ...objectFields.value[index],
                                    estimatedCostFactor: value,
                                  });
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`loan-data`}>
                        <div className={`subtitle`}>
                          {i18n.t(
                            "cb:RiskScenario.Form.valuesOfLoans",
                            "Darlehen Plandaten"
                          )}
                          <span className={`count`}>
                            {countLoans}{" "}
                            {countLoans === 1
                              ? i18n.t(
                                  "cb:RiskScenario.Form.countLoan",
                                  "Darlehen"
                                )
                              : i18n.t(
                                  "cb:RiskScenario.Form.countLoans",
                                  "Darlehen"
                                )}
                          </span>
                        </div>
                        <div className={`fields`}>
                          <div className={`__field`}>
                            <BFInput
                              value={avgInterestRate}
                              textAlign="right"
                              label={i18n.t(
                                "cb:RiskScenario.Form.interestRate",
                                "Sollzins"
                              )}
                              type="number"
                              prefix={
                                allInterestedRateAtAvg
                                  ? i18n.t(
                                      "cb:RiskScenario.Form.allSameValue",
                                      "Alle auf"
                                    )
                                  : i18n.t(
                                      "cb:RiskScenario.Form.average",
                                      "Durchschnittlich"
                                    )
                              }
                              suffix={"%"}
                              step={0.01}
                              min={0}
                              onChange={(value: number) => {
                                objectFields.forEach((_name, index) => {
                                  objectFields.update(index, {
                                    ...objectFields.value[index],
                                    loanData: objectFields.value[
                                      index
                                    ].loanData.map((loan) => ({
                                      ...loan,
                                      interestRate: value,
                                    })),
                                  });
                                });
                              }}
                            />
                          </div>
                          <div className={`__field`}>
                            <BFInput
                              value={avgRepaymentRate}
                              textAlign="right"
                              label={i18n.t(
                                "cb:RiskScenario.Form.repaymentRate",
                                "Anfängliche Tilgung"
                              )}
                              type="number"
                              prefix={
                                allRepaymentRateAtAvg
                                  ? i18n.t(
                                      "cb:RiskScenario.Form.allSameValue",
                                      "Alle auf"
                                    )
                                  : i18n.t(
                                      "cb:RiskScenario.Form.average",
                                      "Durchschnittlich"
                                    )
                              }
                              suffix={"%"}
                              step={0.01}
                              min={0}
                              onChange={(value: number) => {
                                objectFields.forEach((_name, index) => {
                                  objectFields.update(index, {
                                    ...objectFields.value[index],
                                    loanData: objectFields.value[
                                      index
                                    ].loanData.map((loan) => ({
                                      ...loan,
                                      repaymentRate: value,
                                    })),
                                  });
                                });
                              }}
                            />
                          </div>
                          <div className={`__field`}>
                            <BFInput
                              value={avgDuration}
                              textAlign="right"
                              label={i18n.t(
                                "cb:RiskScenario.Form.duration",
                                "Laufzeit"
                              )}
                              type="number"
                              prefix={
                                allDurationAtAvg
                                  ? i18n.t(
                                      "cb:RiskScenario.Form.allSameValue",
                                      "Alle auf"
                                    )
                                  : i18n.t(
                                      "cb:RiskScenario.Form.average",
                                      "Durchschnittlich"
                                    )
                              }
                              suffix={i18n.t("Global.Labels.years", "Jahre")}
                              min={0}
                              max={100}
                              step={1}
                              onChange={(value: number) => {
                                objectFields.forEach((_name, index) => {
                                  objectFields.update(index, {
                                    ...objectFields.value[index],
                                    loanData: objectFields.value[
                                      index
                                    ].loanData.map((loan) => ({
                                      ...loan,
                                      duration: value,
                                    })),
                                  });
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </FieldArray>
            <Field name="objectData">
              {({ input: { value: objectData } }) => (
                <div className={`chart`}>
                  <RiskScenarioChart objectData={objectData} />
                </div>
              )}
            </Field>

            <div className={`title`}>
              {i18n.t(
                "cb:RiskScenario.Form.loansOfObjects",
                "Darlehen der Objekte"
              )}
            </div>
            <div className={`objects`}>
              <FieldArray name="objectData">
                {({ fields: objectFields }) =>
                  objectFields.map((name, index) => (
                    <Field name={`${name}.objectId`}>
                      {({ input: { value: objectId } }) => (
                        <div className={`object-wrapper`}>
                          <div className={`image`}>
                            <AssetLoader
                              assetType={AssetTypes.Portfolio.Object}
                              id={objectId}
                              render={(obj: CBPortfolioObject) => (
                                <CDNImage
                                  style={{
                                    width: "100%",
                                    minHeight: 80,
                                    height: "100%",
                                  }}
                                  imageType={"div"}
                                  assetType={AssetTypes.Portfolio.Object}
                                  assetId={obj._id}
                                  assetField={"data.images"}
                                  cdnId={
                                    Object.entries(
                                      obj?.data?.images || {}
                                    )[0][0]
                                  }
                                  fileKey={
                                    (
                                      Object.entries(
                                        obj?.data?.images || {}
                                      )[0][1] as any
                                    ).key
                                  }
                                  filename={
                                    (
                                      Object.entries(
                                        obj?.data?.images || {}
                                      )[0][1] as any
                                    ).filename
                                  }
                                  hasFolderReadPermissions={true}
                                  dimension="md"
                                />
                              )}
                            />
                          </div>
                          <div className={`object-form`}>
                            <div className={`fields-of-object`}>
                              <div className={`data-of-object`}>
                                <div className={`object-name`}>
                                  ({OrgaStruct.getObject(objectId)?.id}) -{" "}
                                  {OrgaStruct.getObject(objectId)?.displayName}
                                </div>

                                <div className={`object-data`}>
                                  <BFValueDisplay
                                    label={i18n.t(
                                      "cb:RiskScenario.Form.plannedRentNetPA",
                                      "Plan NKM p.a."
                                    )}
                                    value={StringUtils.formatCurrency(
                                      loanRiskData.data.objects.find(
                                        (e) => e.objectId
                                      ).rentNetPlan
                                    )}
                                  />
                                  <BFValueDisplay
                                    label={i18n.t(
                                      "cb:RiskScenario.Form.plannedRentNetPM",
                                      "Plan NKM p.m."
                                    )}
                                    value={StringUtils.formatCurrency(
                                      loanRiskData.data.objects.find(
                                        (e) => e.objectId
                                      ).rentNetPlan / 12
                                    )}
                                  />
                                  <BFValueDisplay
                                    label={i18n.t(
                                      "cb:RiskScenario.Form.currentRentNetPA",
                                      "Ist NKM p.a."
                                    )}
                                    value={StringUtils.formatCurrency(
                                      loanRiskData.data.objects.find(
                                        (e) => e.objectId
                                      ).rentNet
                                    )}
                                  />
                                  <BFValueDisplay
                                    label={i18n.t(
                                      "cb:RiskScenario.Form.currentRentNetPM",
                                      "Ist NKM p.m."
                                    )}
                                    value={StringUtils.formatCurrency(
                                      loanRiskData.data.objects.find(
                                        (e) => e.objectId
                                      ).rentNet / 12
                                    )}
                                  />
                                </div>
                                <div className={`fields`}>
                                  <div className={`object-field`}>
                                    <Field
                                      name={`${name}.estimatedRentNetGrowth`}
                                      validate={FormValidators.compose(
                                        FormValidators.required()
                                      )}
                                    >
                                      {({ input, meta }) => (
                                        <BFInput
                                          step={0.01}
                                          prefix={"%"}
                                          type="number"
                                          label={i18n.t(
                                            "cb:RiskScenario.Form.estimatedRentNetGrowth",
                                            "NKM-Anpassung"
                                          )}
                                          hint={i18n.t(
                                            "cb:RiskScenario.Form.estimatedCostFactorHint",
                                            "jährlich zum 01.01."
                                          )}
                                          {...input}
                                          {...FormValidators.getValidation(
                                            meta
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                  <div className={`object-field`}>
                                    <Field
                                      name={`${name}.estimatedVacancyRate`}
                                      validate={FormValidators.compose(
                                        FormValidators.required(),
                                        FormValidators.min(0),
                                        FormValidators.max(100)
                                      )}
                                    >
                                      {({ input, meta }) => (
                                        <BFInput
                                          min={0}
                                          max={100}
                                          step={0.01}
                                          prefix={"%"}
                                          type="number"
                                          label={i18n.t(
                                            "cb:RiskScenario.Form.estimatedVacancyRate",
                                            "Leerstandsquote"
                                          )}
                                          {...input}
                                          {...FormValidators.getValidation(
                                            meta
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                  <div className={`object-field`}>
                                    <Field
                                      name={`${name}.estimatedCostFactor`}
                                      validate={FormValidators.compose(
                                        FormValidators.required(),
                                        FormValidators.min(0)
                                      )}
                                    >
                                      {({ input, meta }) => (
                                        <BFInput
                                          min={0}
                                          max={100}
                                          step={0.01}
                                          prefix={"%"}
                                          type="number"
                                          label={i18n.t(
                                            "cb:RiskScenario.Form.estimatedCostFactor",
                                            "Weitere Kosten"
                                          )}
                                          hint={i18n.t(
                                            "cb:RiskScenario.Form.estimatedCostFactorHint",
                                            "prozentuell zu NKM"
                                          )}
                                          {...input}
                                          {...FormValidators.getValidation(
                                            meta
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                              {/* TODO custom zeug nachher */}
                            </div>

                            <div className={`loan-title`}>
                              {i18n.t(
                                "cb:RiskScenario.Form.loansOfObject",
                                "Darlehen des Objekts"
                              )}
                            </div>
                            <div className={`loans-of-object`}>
                              <FieldArray name={`${name}.loanData`}>
                                {({ fields: loanFields }) =>
                                  loanFields.map((name, index) => (
                                    <div className={`loan`}>
                                      <Field name={`${name}.simulationForLoan`}>
                                        {({
                                          input: { value: simulationForLoan },
                                        }) => {
                                          const loan =
                                            loanRiskData.data.loans.find(
                                              (e) =>
                                                e.loanId === simulationForLoan
                                            );

                                          return (
                                            <div className={`data-of-loan`}>
                                              <div className={`loan-name`}>
                                                {loan.displayName}
                                              </div>
                                              <div className={`loan-bankName`}>
                                                {loan.bankName}
                                              </div>
                                              <div className={`loan-data`}>
                                                <BFValueDisplay
                                                  label={i18n.t(
                                                    "cb:RiskScenario.Loan.InterestRate",
                                                    "Zinssatz"
                                                  )}
                                                  value={loan.interestRate}
                                                  formatter={(value: number) =>
                                                    StringUtils.formatNumber(
                                                      value
                                                    ) + "%"
                                                  }
                                                />
                                                <BFValueDisplay
                                                  label={i18n.t(
                                                    "cb:RiskScenario.Loan.Annuity",
                                                    "Annuität"
                                                  )}
                                                  value={loan.annuity}
                                                  formatter={(value: number) =>
                                                    StringUtils.formatCurrency(
                                                      value
                                                    )
                                                  }
                                                />
                                                <BFValueDisplay
                                                  label={i18n.t(
                                                    "cb:RiskScenario.Loan.EndValue",
                                                    "End-Saldo"
                                                  )}
                                                  value={loan.endValue}
                                                  formatter={(value: number) =>
                                                    StringUtils.formatCurrency(
                                                      value
                                                    )
                                                  }
                                                />
                                                <BFValueDisplay
                                                  label={i18n.t(
                                                    "cb:RiskScenario.Loan.EndDate",
                                                    "End-Datum"
                                                  )}
                                                  value={loan.endDate}
                                                  formatter={(value: Date) =>
                                                    StringUtils.formatDate(
                                                      value
                                                    )
                                                  }
                                                />
                                                {loan.weight < 100 && (
                                                  <BFValueDisplay
                                                    label={i18n.t(
                                                      "cb:RiskScenario.Loan.WeightOfLoan",
                                                      "Gewichtung des Darlehens zum Objekt"
                                                    )}
                                                    value={loan.weight}
                                                    formatter={(
                                                      value: number
                                                    ) =>
                                                      StringUtils.formatNumber(
                                                        value * 100
                                                      ) + "%"
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }}
                                      </Field>
                                      <div className={`fields-title`}>
                                        {i18n.t(
                                          "cb:RiskScenario.Form.Loan.PlanTitle",
                                          "Plandaten für Folgedarlehen"
                                        )}
                                      </div>
                                      <div className={`fields-loan`}>
                                        <Field
                                          name={`${name}.interestRate`}
                                          validate={FormValidators.compose(
                                            FormValidators.required()
                                          )}
                                        >
                                          {({ input, meta }) => (
                                            <BFInput
                                              type="number"
                                              min={0}
                                              prefix={"%"}
                                              step={0.01}
                                              label={i18n.t(
                                                "cb:RiskScenario.Form.Loan.interestRate",
                                                "Sollzins"
                                              )}
                                              {...input}
                                              {...FormValidators.getValidation(
                                                meta
                                              )}
                                            />
                                          )}
                                        </Field>
                                        <Field
                                          name={`${name}.repaymentRate`}
                                          validate={FormValidators.compose(
                                            FormValidators.required(),
                                            FormValidators.min(0)
                                          )}
                                        >
                                          {({ input, meta }) => (
                                            <BFInput
                                              min={0}
                                              step={0.01}
                                              prefix={"%"}
                                              type="number"
                                              label={i18n.t(
                                                "cb:RiskScenario.Form.Loan.repaymentRate",
                                                "Anfängliche Tilgung"
                                              )}
                                              {...input}
                                              {...FormValidators.getValidation(
                                                meta
                                              )}
                                            />
                                          )}
                                        </Field>
                                        <Field
                                          name={`${name}.duration`}
                                          validate={FormValidators.compose(
                                            FormValidators.required(),
                                            FormValidators.min(0)
                                          )}
                                        >
                                          {({ input, meta }) => (
                                            <BFInput
                                              min={0}
                                              max={100}
                                              step={1}
                                              type="number"
                                              label={i18n.t(
                                                "cb:RiskScenario.Form.Loan.duration",
                                                "Laufzeit"
                                              )}
                                              hint={i18n.t(
                                                "cb:RiskScenario.Form.Loan.durationInYears",
                                                "in Jahren"
                                              )}
                                              {...input}
                                              {...FormValidators.getValidation(
                                                meta
                                              )}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                  ))
                                }
                              </FieldArray>
                            </div>
                          </div>
                        </div>
                      )}
                    </Field>
                  ))
                }
              </FieldArray>
            </div>
          </div>
        </>
      )}
    />
  );
};

export default RiskScenarioForm;
