import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import MQ from "../../../../../../utils/MatchQueryUtils";
import { AssetCashBudgetEntry } from "../../../model/CashBudgetEntry";
import CBIdlyConnect from "./CBIdlyConnect";
import CBIdlyConnectInvoiceSugggestion from "./CBIdlyConnectInvoiceSugggestion";
import CBIdlyConnectService, {
  InvoiceSuggestion,
} from "./CBIdlyConnectService";

interface CBIdlyConnectInvoiceProps {
  booking: AssetCashBudgetEntry;
  initialOpen?: boolean;
}
const CBIdlyConnectInvoice = (props: CBIdlyConnectInvoiceProps) => {
  return (
    <>
      <CBIdlyConnect
        assetType={AssetTypes.Invoice}
        mapSuggestion={CBIdlyConnectService.mapSuggestionInvoice}
        matchQuery={MQ.and(
          MQ.eq("data.entity", props.booking.data.entity),
          MQ.nin("data.status", ["init", "declined"])
        )} // todo check for rent > 0 etc
        className="cb-idly-connect-invoice"
        title={i18n.t("cb:CBIdlyConnect.Invoice.title", "Rechnung verknüpfen")}
        booking={props.booking}
        initialOpen={props.initialOpen}
        connectActionLabel={i18n.t(
          "cb:CBIdlyConnect.Invoice.connect",
          "Rechnung verknüpfen"
        )}
        isConnected={(suggestion: InvoiceSuggestion) =>
          props.booking.data.linkedAsset?.some(
            (e) => e.assetId === suggestion.asset._id
          )
        }
        disconnectActionLabel={i18n.t(
          "cb:CBIdlyConnect.Invoice.disconnect",
          "Verknüpfung aufheben"
        )}
        disconnectActionPromise={async (suggestion: InvoiceSuggestion) =>
          await CBIdlyConnectService.unlink(
            props.booking._id,
            AssetTypes.Invoice,
            suggestion.asset._id
          )
        }
        connectActionPromise={async (suggestion: InvoiceSuggestion) =>
          await CBIdlyConnectService.link(
            props.booking._id,
            AssetTypes.Invoice,
            suggestion.asset._id
          )
        }
        manualActionLabel={i18n.t(
          "cb:CBIdlyConnect.Invoice.manual",
          "Rechnung suchen"
        )}
        allowManualSearch
        renderSuggestion={(suggestion: InvoiceSuggestion) => (
          <CBIdlyConnectInvoiceSugggestion suggestion={suggestion} />
        )}
        renderSuggestionHint={CBIdlyConnectService.getSuggestionHintInvoice}
        ruleActionData={(suggestion: InvoiceSuggestion) => ({
          assetDescription: `${suggestion.asset.id} ${suggestion.asset.data.invoice.invoiceId}`,
          assetName: i18n.t("cb:CBIdlyConnect.Invoice.AssetName", "Rechnung"),
          assetType: AssetTypes.Invoice,
        })}
        suggestionPromise={async (booking: AssetCashBudgetEntry) =>
          await CBIdlyConnectService.fetchSuggestionsForInvoice(props.booking)
        }
      />
    </>
  );
};

export default CBIdlyConnectInvoice;
