import { RentalAgreement } from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import MultipleAssetLoaders from "@/components/AssetLoader/MultipleAssetLoaders";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFToggle from "@/modules/abstract-ui/forms/toggle/BFToggle";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import AssetService from "@/services/AssetService";
import CacheService from "@/services/CacheService";
import SubmitService from "@/services/SubmitService";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import {
  APActivity,
  APRentReduction,
  APRentReductionFormValue,
} from "../../../ActivityInterfaces";
import "./APRentReductionForm.scss";

interface APRentReductionFormProps {
  onClose: () => void;
  onSuccess?: (asset: APRentReduction) => void;
  onDeleted?: () => void;
  asset?: APRentReduction;
  activity: APActivity;
}

const getInitialValue = (
  asset: APRentReduction,
  activity: APActivity,
  rentalAgreements: RentalAgreement[]
) => {
  return {
    activityId: activity._id,
    type: activity.data.type,

    originalAmount:
      asset?.data.originalAmount ||
      _.sum(rentalAgreements.map((e) => e.data.rentGrossTotal || 0)),
    reducedAmount:
      asset?.data.reducedAmount ||
      _.sum(rentalAgreements.map((e) => e.data.rentGrossTotal || 0)),
    damageReported: asset?.data.damageReported || activity.data.creationDate,
    damageSince: asset?.data.damageSince || activity.data.creationDate,
    damageFixed: asset?.data.damageFixed || undefined,

    noticeDamageRecification: false,
    reductionCalculationNote: asset?.data.reductionCalculationNote || "",

    reductionLegalBasis: asset?.data.reductionLegalBasis || "",
  } as APRentReductionFormValue;
};

const transformFormdataToSubmitdata = (values: APRentReductionFormValue) => {
  return values;
};

const APRentReductionForm: React.FC<APRentReductionFormProps> = (props) => {
  return (
    <MultipleAssetLoaders
      assets={props.activity.data.relations?.filter(
        (e) => e.assetType === AssetTypes.Rental.RentalAgreement
      )}
      render={(assets: RentalAgreement[]) => (
        <FormStruct
          className={classNames("ap-rent-reduction-form")}
          onSubmit={async (values: APRentReductionFormValue) => {
            const asset = (await SubmitService.submitDataAsync({
              type: "asset",
              assetType: AssetTypes.Activity.RentReduction,
              data: {
                _id: props.asset?._id,
                data: {
                  ...transformFormdataToSubmitdata(values),
                },
              },
              ignorePropChecks: true,
              ignoreSubmitValidation: true,
            })) as APRentReduction;
            CacheService.update(asset);

            props.onSuccess?.(asset);
            props.onClose();
          }}
          ignoreSubmitOnEnter
          usePrompt
          additionalActions={
            props.asset && props.onDeleted ? (
              <BFButton
                onClick={() => {
                  ModalManager.confirm({
                    confirmButtonText: i18n.t("Global.Buttons.delete"),
                    message: i18n.t(
                      "APRentReductionForm.DeleteConfirm",
                      "Sind Sie sicher, dass Sie die Mietminderung löschen möchten? Sollte die Mietminderung beendet sein, setzen Sie stattdessen bitte das Datum für 'Mangel behoben am'."
                    ),
                    onConfirm: async () => {
                      await AssetService.deleteAsset({
                        assetId: props.asset?._id,
                        assetType: AssetTypes.Activity.RentReduction,
                      });
                      props.onDeleted();
                      props.onClose();
                    },
                  });
                }}
                appearance="outline"
              >
                {i18n.t("Global.Buttons.delete")}
              </BFButton>
            ) : undefined
          }
          description={
            props.asset
              ? undefined
              : // i18n.t("apTemplate:APRentReductionForm.UpdateDescription", "")
                i18n.t(
                  "apTemplate:APRentReductionForm.CreateDescription",
                  "Geben Sie die Details für die Mietminderung an. Achten Sie darauf, dass die aktuelle Miete und die berechnete Mietminderung korrekt angegeben sind."
                )
          }
          title={
            props.asset
              ? i18n.t(
                  "apTemplate:APRentReductionForm.UpdateTitle",
                  "Mietminderung erfassen"
                )
              : i18n.t(
                  "apTemplate:APRentReductionForm.CreateTitle",
                  "Mietminderung bearbeiten"
                )
          }
          submitText={
            props.asset
              ? i18n.t("APRentReductionForm.SubmitUpdate", "Speichern")
              : i18n.t("APRentReductionForm.SubmitCreate", "Erstellen")
          }
          onAbort={props.onClose}
          initialValues={getInitialValue(props.asset, props.activity, assets)}
          render={(form) => (
            <>
              <div className={`field-row`}>
                <div className={`__field __flex-1`}>
                  <Field
                    name="originalAmount"
                    validate={FV.compose(FV.required(), FV.min(0))}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        label={i18n.t(
                          "apTemplate:APRentReductionForm.OriginalAmount",
                          "Miete (Original)"
                        )}
                        type={"priceInput"}
                        prefix={StringUtils.getCurrencySymbol()}
                        {...input}
                        {...FV.getValidation(meta)}
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field __flex-1`}>
                  <Field
                    name="reducedAmount"
                    validate={FV.compose(FV.required(), FV.min(0))}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        label={i18n.t(
                          "apTemplate:APRentReductionForm.ReducedAmount",
                          "Miete (Reduziert)"
                        )}
                        prefix={StringUtils.getCurrencySymbol()}
                        type={"priceInput"}
                        {...input}
                        {...FV.getValidation(meta)}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className={`field-row`}>
                <div className={`__field __flex-1`}>
                  <Field
                    name="damageSince"
                    validate={FV.compose(FV.required())}
                  >
                    {({ input, meta }) => (
                      <BFDatefield
                        preventNull
                        label={i18n.t(
                          "apTemplate:APRentReductionForm.DamageSince",
                          "Mangel besteht seit"
                        )}
                        {...input}
                        {...FV.getValidation(meta)}
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field __flex-1`}>
                  <Field
                    name="damageReported"
                    validate={FV.compose(FV.required())}
                  >
                    {({ input, meta }) => (
                      <BFDatefield
                        preventNull
                        label={i18n.t(
                          "apTemplate:APRentReductionForm.DamageReported",
                          "Mangel gemeldet am"
                        )}
                        {...input}
                        {...FV.getValidation(meta)}
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field __flex-1`}>
                  <Field name="damageFixed">
                    {({ input, meta }) => (
                      <BFDatefield
                        label={i18n.t(
                          "apTemplate:APRentReductionForm.DamageFixed",
                          "Mangel behoben am"
                        )}
                        {...input}
                        {...FV.getValidation(meta)}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className={`__field`}>
                <Field name="noticeDamageRecification">
                  {({ input, meta }) => (
                    <BFToggle
                      label={i18n.t(
                        "apTemplate:APRentReductionForm.NoticeDamageRecification",
                        "Mängelfreimeldung"
                      )}
                      {...input}
                      checked={input.value}
                      onChange={(checked) => input.onChange(checked)}
                      {...FV.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>
              <div className={`__field`}>
                <Field
                  name="reductionCalculationNote"
                  validate={FV.compose(FV.required())}
                >
                  {({ input, meta }) => (
                    <BFInput
                      autosize
                      type={"textarea"}
                      label={i18n.t(
                        "apTemplate:APRentReductionForm.ReductionCalculationNote",
                        "Berechnung der Mietminderung"
                      )}
                      {...input}
                      {...FV.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>
              <div className={`__field`}>
                <Field
                  name="reductionLegalBasis"
                  validate={FV.compose(FV.required())}
                >
                  {({ input, meta }) => (
                    <BFInput
                      autosize
                      type={"textarea"}
                      label={i18n.t(
                        "apTemplate:APRentReductionForm.ReductionLegalBasis",
                        "Gesetzliche Grundlagen der Mietminderung"
                      )}
                      {...input}
                      {...FV.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>
            </>
          )}
        />
      )}
    />
  );
};

export default APRentReductionForm;
