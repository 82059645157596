import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import moment from "moment";
import { Field } from "react-final-form";
import FilePreviewComponent from "../../../../../../components/FilePreviewComponent/FilePreviewComponent";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../i18n";
import BFCheckbox from "../../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "../../../../../../modules/abstract-ui/forms/input/BFInput";
import {
  APContractActivity,
  APMaintenanceAppointment,
} from "./APActivityMainteanceInterfaces";
import "./APActivityMaintenanceFinishForm.scss";
import APActivityMaintenanceService from "./APActivityMaintenanceService";

interface APActivityMaintenanceFinishFormProps {
  activity: APContractActivity;
  appointment: APMaintenanceAppointment;
  file: File;
  onClose: () => void;
}
const APActivityMaintenanceFinishForm = (
  props: APActivityMaintenanceFinishFormProps
) => {
  const schedule = props.activity.data.schedulingRules.find(
    (e) => e.id === props.appointment.scheduleRuleId
  );
  let nextDate;
  if (schedule.type === "regular") {
    nextDate = moment(props.appointment.dueDate)
      .add(schedule.typeData.numberOfMonths, "months")
      .toDate();
  } else {
    nextDate = null;
  }
  return (
    <FormStruct
      onSubmit={async (values) => {
        await APActivityMaintenanceService.finishAppointment(
          props.activity._id,
          props.appointment.id,
          {
            file: props.file,
            type: "done",
            note: props.appointment.note || "",
            statusChangeNote: values.statusChangeNote || "",
            maintenanceDate: values.maintenanceDate,
            appointmentStartDate: values.followAppointment
              ? values.appointmentStartDate
              : null,
          }
        );
        props.onClose();
      }}
      title={i18n.t(
        "apTemplate:Activity.subtitles.maintenance.finishForm.title",
        "Protokoll hochladen"
      )}
      description={i18n.t(
        "apTemplate:Activity.subtitles.maintenance.finishForm.description",
        "Laden Sie das Protokoll für die Fälligkeit hoch. Sie können eine nachfolgende Fälligkeit erstellen. Die Daten für die Fälligkeit werden mit dem hinterlegten Interval berechnet. Sie können die Daten jedoch manuell anpassen."
      )}
      initialValues={{
        statusChangeNote: "",
        maintenanceDate: new Date(),
        type: "done",
        appointmentStartDate: nextDate,
        followAppointment: true,
      }}
      submitText={i18n.t(
        "apTemplate:Activity.subtitles.maintenance.finishForm.submit",
        "Protokoll hochladen"
      )}
      onAbort={props.onClose}
      render={(formProps) => (
        <div className={`ap-activity-maintenance-finish-form`}>
          <div className={`file`}>
            <FilePreviewComponent
              type="file"
              file={props.file}
              width={130}
              height={200}
            />
          </div>
          <div className={`form-content`}>
            <div className={`__field`}>
              <Field
                name="maintenanceDate"
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div style={{ display: "inline-block" }}>
                    <BFDatefield
                      {...input}
                      {...FormValidators.getValidation(meta)}
                      preventNull
                      label={i18n.t(
                        "apTemplate:Activity.subtitles.maintenance.finishForm.maintenanceDate",
                        "Fertigstellung"
                      )}
                    />
                  </div>
                )}
              </Field>
            </div>
            <div className={`__field`}>
              <Field
                name="statusChangeNote"
                validate={FormValidators.compose(
                  FormValidators.required(),
                  FormValidators.max(300)
                )}
              >
                {({ input, meta }) => (
                  <BFInput
                    type="textarea"
                    {...input}
                    {...FormValidators.getValidation(meta)}
                    label={i18n.t(
                      "apTemplate:Activity.subtitles.maintenance.finishForm.note",
                      "Notiz"
                    )}
                  />
                )}
              </Field>
            </div>
            <Field
              name="followAppointment"
              // validate={FormValidators.compose(FormValidators.required())}
            >
              {({ input, meta }) => (
                <>
                  <div className={`__field`}>
                    <BFCheckbox
                      checked={input.value}
                      onChange={(_, checked) => {
                        input.onChange(checked);
                      }}
                    >
                      {i18n.t(
                        "apTemplate:Activity.subtitles.maintenance.finishForm.followAppointment",
                        "Erstelle neue Fälligkeit"
                      )}
                    </BFCheckbox>
                  </div>
                  {input.value && (
                    <div className={`__field`}>
                      <Field
                        name="appointmentStartDate"
                        validate={FormValidators.compose(
                          FormValidators.required()
                        )}
                      >
                        {({ input, meta }) => (
                          <div style={{ display: "inline-block" }}>
                            <BFDatefield
                              {...input}
                              {...FormValidators.getValidation(meta)}
                              preventNull
                              label={i18n.t(
                                "apTemplate:Activity.subtitles.maintenance.finishForm.appointmentStartDate",
                                "Nächste Fälligkeit"
                              )}
                              hint={
                                !meta.dirty
                                  ? i18n.t(
                                      "apTemplate:Activity.subtitles.maintenance.finishForm.appointmentStartDateHint",
                                      "Berechnet anhand des angegebenen Intervalls."
                                    )
                                  : undefined
                              }
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  )}
                </>
              )}
            </Field>
          </div>
        </div>
      )}
    />
  );
};

export default APActivityMaintenanceFinishForm;
