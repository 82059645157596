import { usePrevious } from "@/utils/Hooks";
import { useEffect } from "react";

interface OnChangeProps {
  value: any;
  onChange: (value: any, previousValue: any) => void;
  children: JSX.Element;
}
const OnChange = (props: OnChangeProps) => {
  const previousValue = usePrevious(props.value);

  useEffect(() => {
    props.onChange(props.value, previousValue);
  }, [props.value]);

  return props.children;
};

export default OnChange;
