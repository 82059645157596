export interface MetricsDataBlob {
  keystrokes: number;
  mouseActions: number;
  scrollAction: number;
}

class UsageMetricsServiceClass {
  metrics: MetricsDataBlob = {
    keystrokes: 0,
    mouseActions: 0,
    scrollAction: 0,
  };

  registerMouseAction() {
    this.metrics.mouseActions++;
  }

  registerScrollAction() {
    this.metrics.scrollAction++;
  }
  registerKeystroke() {
    this.metrics.keystrokes++;
  }
  getData() {
    return this.metrics;
  }
  reset() {
    this.metrics = {
      keystrokes: 0,
      mouseActions: 0,
      scrollAction: 0,
    };
  }
}
const UsageMetricsService = new UsageMetricsServiceClass();
export default UsageMetricsService;
