import { CB_PORTFOLIO_RENTAL_UNIT_SUPPLY_UNIT_IDENTIFIER } from "@/apps/tatar/activityApp/specific/maintenance/technical-units/APTechnicalUnitsConst";
import { RentalUnit } from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import SupplyUnitCardList from "./components/SupplyUnitCardList";
import "./RentalUnitSupplyUnits.scss";

type Props = {
  rentalUnit: RentalUnit;
};

const RentalUnitSupplyUnits = (props: Props) => {
  const { rentalUnit } = props;
  return (
    <div className={classNames("supply-unit-list", "__card")}>
      <SupplyUnitCardList
        identifier={CB_PORTFOLIO_RENTAL_UNIT_SUPPLY_UNIT_IDENTIFIER}
        matchQuery={MQ.and(
          MQ.in("data.rentalUnits", [rentalUnit._id]),
          MQ.in("data.status", ["active"])
        )}
      />
    </div>
  );
};

export default RentalUnitSupplyUnits;
