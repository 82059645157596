import classNames from "classnames";
import AppDrawer from "../../../components/AppDrawer/AppDrawer";
import "./DashboardAppDrawer.scss";

interface DashboardAppDrawerProps {}
const DashboardAppDrawer = (props: DashboardAppDrawerProps) => {
  return (
    <div className={classNames(`dashboard-appdrawer`)}>
      <AppDrawer hideSearch />
    </div>
  );
};

export default DashboardAppDrawer;
