import Log from "@/debug/Log";
import { isDefined } from "@/utils/Helpers";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "rsuite";
import i18n from "../../i18n";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import { DefaultIcons } from "../../modules/abstract-ui/icon/DefaultIcons";
import { loadStructData } from "../../redux/actions/struct/struct-actions";
import { STRUCT_MAPPER } from "../../redux/actions/struct/struct-mapper";
import { useTypedSelector } from "../../redux/hooks";
import {
  CommonStructType,
  StructType,
  UnitStructType,
} from "../../redux/reducers/struct/StructInterface";
import LoadPage from "../LoadPage/LoadPage";
import "./StructLoader.scss";

type StructLoaderSingleStructType<T> = {
  structType: T;
};
type StructLoaderMultipleStructType<T> = {
  structTypes: T[];
};
type StructLoaderStructType<T> =
  | StructLoaderSingleStructType<T>
  | StructLoaderMultipleStructType<T>;

type StructLoaderSingleUnitType = {
  unitType: string;
};
type StructLoaderMultipleUnitType = {
  unitTypes: string[];
};
type StructLoaderUnitType =
  | StructLoaderSingleUnitType
  | StructLoaderMultipleUnitType;

type StructLoaderBaseProps<T> = StructLoaderStructType<T> &
  StructLoaderUnitType;

type StructLoaderCommonProps = {
  block?: boolean;
  renderLoading?: () => JSX.Element;
  renderError?: (reload: () => void) => JSX.Element;
};
type StructLoaderRenderProps<T> = StructLoaderCommonProps &
  StructLoaderBaseProps<T> & {
    render: (structs: any) => JSX.Element;
  };

type CommonStructLoaderProps = StructLoaderStructType<CommonStructType> &
  StructLoaderCommonProps & {
    render: (structs: any) => JSX.Element;
  };

type StructLoaderProps<T> = StructLoaderRenderProps<T>;

const AbstractStructLoader = (props: StructLoaderProps<StructType>) => {
  const dispatch = useDispatch();
  const structData = useTypedSelector((state) => state.struct);

  useEffect(() => {
    const useStructTypes = (props as StructLoaderMultipleStructType<StructType>)
      .structTypes || [
      (props as StructLoaderSingleStructType<StructType>).structType,
    ];
    const useUnitTypes = isDefined(
      (props as StructLoaderMultipleUnitType).unitTypes
    )
      ? (props as StructLoaderMultipleUnitType).unitTypes
      : isDefined((props as StructLoaderSingleUnitType).unitType)
      ? [(props as StructLoaderSingleUnitType).unitType]
      : [];
    const haveToLoad = useStructTypes.some((structType) => {
      return useUnitTypes.some((unitType) => {
        return !structData[structType]?.[unitType];
      });
    });

    if (haveToLoad) {
      dispatch(loadStructData(useStructTypes, useUnitTypes));
    }
  }, [
    structData,
    (props as StructLoaderMultipleStructType<StructType>).structTypes,
    (props as StructLoaderSingleStructType<StructType>).structType,
    (props as StructLoaderMultipleUnitType).unitTypes,
    (props as StructLoaderSingleUnitType).unitType,
  ]);

  const useStructTypes = (props as StructLoaderMultipleStructType<StructType>)
    .structTypes || [
    (props as StructLoaderSingleStructType<StructType>).structType,
  ];
  const useUnitTypes = (props as StructLoaderMultipleUnitType).unitTypes || [
    (props as StructLoaderSingleUnitType).unitType,
  ];

  const loadingFound = useStructTypes.some((structType) => {
    return useUnitTypes.some((unitType) => {
      return structData[structType]?.[unitType]?.state === "loading";
    });
  });
  const errorFound = useStructTypes.some((structType) => {
    return useUnitTypes.some((unitType) => {
      return structData[structType]?.[unitType]?.state === "error";
    });
  });

  const haveToLoad = useStructTypes.some((structType) => {
    return useUnitTypes.some((unitType) => {
      return !structData[structType]?.[unitType];
    });
  });

  const reloadFunction = () => {
    dispatch(loadStructData(useStructTypes, useUnitTypes));
  };

  if (haveToLoad || loadingFound) {
    if (props.renderLoading) {
      return props.renderLoading();
    }
    if (props.block) {
      return <LoadPage />;
    }
    return <Loader size="xs" />;
  }
  if (errorFound) {
    Log.error("error at loading structs", structData);
    if (props.renderError) {
      return props.renderError(reloadFunction);
    }
    return (
      <div className={`struct-loader error`}>
        {i18n.t("Global.Labels.defaultError", "Fehler beim Laden der Daten")}
        <BFButton
          icon={{ ...DefaultIcons.RETRY, size: "xxs" }}
          onClick={reloadFunction}
        />
      </div>
    );
  }

  if ((props as StructLoaderRenderProps<StructType>).render) {
    return (props as StructLoaderRenderProps<StructType>).render(
      (props as any).structTypes
        ? (props as any).structTypes.map((key) => STRUCT_MAPPER[key])
        : STRUCT_MAPPER[(props as any).structType]
    );
  }
  return null;
};

const StructLoader = (props: StructLoaderProps<UnitStructType>) => (
  <AbstractStructLoader {...props} />
);
export const CommonStructLoader = (props: CommonStructLoaderProps) => (
  <AbstractStructLoader {...props} unitType="common" />
);

export default StructLoader;
