import StructLoader from "@/components/StructLoader/StructLoader";
import { UnitStructType } from "@/redux/reducers/struct/StructInterface";
import { isDefined, translateNonGenerate } from "@/utils/Helpers";
import classNames from "classnames";
import ObjectDetailView from "../../../../../components/ObjectDetailView/ObjectDetailView";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { ActivityApplicationConstants } from "../../ActivityHooks";
import { APActivity, APProjectActivity } from "../../ActivityInterfaces";
import { ActivityStructContext } from "../../ActivityStructContext";
import APActivityApprovals from "./APActivityApprovals";
import APActivityDetails from "./APActivityDetails";
import "./APActivityDetailsRoot.scss";
import APActivityDocuments from "./APActivityDocuments";
import APActivityInvoices from "./APActivityInvoices";
import APActivityOffers from "./APActivityOffers";
import APActivityTenantData from "./APActivityTenantData";
import APCommunication from "./APCommunication";
import APBudgetView from "./budget-feature/APBudgetView";
import APBudgetInvoices from "./budget-invoices/APBudgetInvoices";
import APConstructionDiariesView from "./construction-diaries/APConstructionDiariesView";
import { APContractActivity } from "./maintenance/APActivityMainteanceInterfaces";
import APActivityMaintenanceAppointmentsTab from "./maintenance/APActivityMaintenanceAppointmentsTab";
import APOfferApprovals from "./offer-feature/approval/APOfferApprovals";
import APOfferList from "./offer-feature/offer/APOfferList";
import APProjectBudgetView from "./project-budget/APProjectBudgetView";
import APRentReductionView from "./rent-reduction/APRentReductionView";
import APActivityResubmissionView from "./resubmission/APActivityResubmissionView";

interface Props {
  activity: APActivity;
  goBack?: () => void;
  constants: ActivityApplicationConstants;
}
const APActivityDetailsRoot = (props: Props) => {
  const { constants } = props;
  return (
    <ObjectDetailView
      goBack={props.goBack}
      className={classNames(`ap-activity-details-root`)}
      mainTitle={i18n.t("apTemplate:Activity.Title", "Aktivität", {
        ns: [constants?.assetType, "apTemplate"],
      })}
      main={(isMobile) => (
        <StructLoader
          structTypes={[constants?.serviceUrl as UnitStructType]}
          unitType={props.activity.data.type}
          render={([activityStruct]) => (
            <ActivityStructContext.Provider value={activityStruct}>
              <APActivityDetails
                constants={constants}
                goBack={props.goBack}
                activity={props.activity}
                activityStruct={activityStruct}
              />
            </ActivityStructContext.Provider>
          )}
        />
        // <ObjectDetailMainPage hideBackButton={isMobile} {...props} />
      )}
      routes={[
        // {
        //   name: "Dashboard",
        //   route: "dashboard",
        //   render: () => <div>Dashboard</div>,
        // },
        {
          name: i18n.t("apTemplate:Activity.Communication", "Kommunikation", {
            ns: [constants?.assetType, "apTemplate"],
          }),
          route: "communication",
          render: () => <APCommunication activity={props.activity} />,
        },
        ...(constants?.fields?.projectBudgetFeature
          ? [
              {
                name: i18n.t("apTemplate:Activity.BudgetTab", "Budget", {
                  ns: [constants?.assetType, "apTemplate"],
                }),
                full: true,
                route: "budget",
                render: () => <APProjectBudgetView activity={props.activity} />,
              },
              {
                name: i18n.t("apTemplate:Activity.Assignments", "Zuordnung", {
                  ns: [constants?.assetType, "apTemplate"],
                }),
                full: false,

                route: "assignments",
                render: () => <APBudgetInvoices activity={props.activity} />,
              },
              // {
              //   name: i18n.t("apTemplate:Activity.Budget", "Budget", {
              //     ns: [constants?.assetType, "apTemplate"],
              //   }),
              //   route: "plan",
              //   render: () => <APBudgetView activity={props.activity} />,
              // },
            ]
          : []),

        ...(constants?.fields?.budgetFeature
          ? [
              {
                name: i18n.t("apTemplate:Activity.BudgetTab", "Budget", {
                  ns: [constants?.assetType, "apTemplate"],
                }),
                full: true,
                route: "budget",
                render: () => <APBudgetView activity={props.activity} />,
              },
              // {
              //   name: i18n.t("apTemplate:Activity.Budget", "Budget", {
              //     ns: [constants?.assetType, "apTemplate"],
              //   }),
              //   route: "plan",
              //   render: () => <APBudgetView activity={props.activity} />,
              // },
            ]
          : []),
        ...(constants?.fields?.maintenanceFeature
          ? [
              {
                name: i18n.t(
                  "apTemplate:Activity.maintenance.tabTitle",
                  "Fälligkeiten",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                ),
                route: "appointments",
                render: () => (
                  <APActivityMaintenanceAppointmentsTab
                    activity={props.activity as APContractActivity}
                  />
                ),
              },
            ]
          : []),

        ...(isDefined(constants?.fields?.assetApproval)
          ? [
              {
                name: i18n.t("apTemplate:Activity.Approvals", "Freigaben", {
                  ns: [constants?.assetType, "apTemplate"],
                }),
                route: "approvals",
                render: () => <APOfferApprovals activity={props.activity} />,
              },
              {
                name: translateNonGenerate(
                  constants?.fields?.assetApproval?.tabTitle
                ),
                route: "offers",
                render: () => <APOfferList activity={props.activity} />,
              },
            ]
          : []),
        ...(!constants?.fields?.disableApprovals
          ? [
              {
                name: i18n.t("apTemplate:Activity.Approvals", "Freigaben", {
                  ns: [constants?.assetType, "apTemplate"],
                }),
                route: "approvals",
                render: () => <APActivityApprovals activity={props.activity} />,
              },
              {
                name: i18n.t("apTemplate:Activity.Offers", "Angebote", {
                  ns: [constants?.assetType, "apTemplate"],
                }),
                route: "offers",
                render: () => <APActivityOffers activity={props.activity} />,
              },
            ]
          : []),

        ...(constants?.fields?.constructionDiary
          ? [
              {
                name: i18n.t(
                  "apTemplate:Activity.ConstructionDiaries",
                  "Bautagebuch",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                ),
                route: "construction-diaries",
                render: () => (
                  <APConstructionDiariesView
                    activity={props.activity as APProjectActivity}
                  />
                ),
              },
            ]
          : []),
        {
          name: i18n.t("apTemplate:Activity.Documents", "Dokumente", {
            ns: [constants?.assetType, "apTemplate"],
          }),
          route: "documents",
          render: () => <APActivityDocuments activity={props.activity} />,
        },
        // ...(!constants?.fields?.projectBudgetFeature
        //   ? [
        ...(!constants?.fields?.hideInvoices
          ? [
              {
                name: i18n.t("apTemplate:Activity.Invoices", "Rechnungen", {
                  ns: [constants?.assetType, "apTemplate"],
                }),
                route: "invoices",
                render: () => <APActivityInvoices activity={props.activity} />,
              },
            ]
          : []),
        //   ]
        // : []),
        {
          name: i18n.t("apTemplate:Activity.Resubmissions", "Wiedervorlagen", {
            ns: [constants?.assetType, "apTemplate"],
          }),
          route: "resubmissions",
          render: () => (
            <APActivityResubmissionView activity={props.activity} />
          ),
        },
        ...((props.activity.data.relations || []).find(
          (e) => e.assetType === AssetTypes.Rental.RentalAgreement
        )
          ? [
              {
                name: i18n.t("apTemplate:Activity.Tenants", "Mieter", {
                  ns: [constants?.assetType, "apTemplate"],
                }),
                route: "relation",
                render: () => (
                  <APActivityTenantData activity={props.activity} />
                ),
              },
            ]
          : []),

        ...(constants?.fields?.rentReduction
          ? [
              {
                name: i18n.t(
                  "apTemplate:Activity.RentReduction",
                  "Mietminderung",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                ),
                route: "rent-reduction",
                render: () => <APRentReductionView activity={props.activity} />,
              },
            ]
          : []),
      ]}
    />
  );
};

export default APActivityDetailsRoot;
