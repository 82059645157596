import LoadPage from "../../../../../../../components/LoadPage/LoadPage";
import ChartComponent from "../../../../../../../configurable/components/ChartComponent/ChartComponent";
import i18n from "../../../../../../../i18n";
import { useStatisticQuery } from "../../../../../../../redux/hooks";
import StringUtils from "../../../../../../../utils/StringUtils";
import { APProjectActivity } from "../../../../ActivityInterfaces";
import { APProjectBudget } from "../APProjectBudgetInterfaces";
// import './APBudgetGroups.scss';

interface APBudgetGroupsProps {
  identifier: string;
  activity: APProjectActivity;
  budget: APProjectBudget;
}
const APProjectBudgetProgress = (props: APBudgetGroupsProps) => {
  const actualData = useStatisticQuery<
    {
      _id: { month: number; year: number; categoryId: string };
      value: number;
    }[]
  >("PROJECT_BOOKED_DETAIL", 1, {
    projectId: props.activity._id,
  });

  if (actualData.loading) {
    return <LoadPage />;
  }
  if (actualData.error) {
    return (
      <div className={`error`}>{i18n.t("Global.Labels.defaultError")}</div>
    );
  }

  return (
    <ChartComponent
      identifier={props.identifier}
      renderer="canvas"
      options={option(props.activity, props.budget)}
    />
  );
};

export default APProjectBudgetProgress;

const option = (activity: APProjectActivity, budget: APProjectBudget) => {
  const budgetSum = activity.calculated.budgetSumShould;
  const actualSum = activity.calculated.budgetSumBooked;
  const difference = budgetSum - actualSum;

  const progressDate = activity.calculated.progressDate;
  const differenceDate = 1 - progressDate;
  return {
    title: [
      {
        text: i18n.t("apTemplate:BudgetProgressChart.Budget", "Budget"),
        textAlign: "center",
        textVerticalAlign: "center",
        top: "32%",
        left: "50%",
        padding: [0, 0, 0, 0],
        textStyle: {
          fontSize: 16,
          fontWeight: "normal",
          color: "#888",
        },
      },
      {
        text: StringUtils.formatPercent(actualSum / budgetSum, true),
        textAlign: "center",
        textVerticalAlign: "center",
        top: "32%",
        left: "50%",
        padding: [24, 0, 0, 0],
        textStyle: {
          fontSize: 22,
          fontWeight: "normal",
          color: "#333",
        },
      },
      {
        text: i18n.t("apTemplate:BudgetProgressChart.Duration", "Projektdauer"),
        textAlign: "center",
        textVerticalAlign: "center",
        top: "50%",
        left: "50%",
        padding: [0, 0, 0, 0],
        textStyle: {
          fontSize: 12,
          fontWeight: "normal",
          color: "#888",
        },
      },
      {
        text: StringUtils.formatPercent(progressDate, true),
        textAlign: "center",
        textVerticalAlign: "center",
        top: "50%",
        left: "50%",
        padding: [20, 0, 0, 0],
        textStyle: {
          fontSize: 18,
          fontWeight: "normal",
          color: "#333",
        },
      },

      {
        text: i18n.t(
          "apTemplate:BudgetProgressChart.plannedBudget",
          "Geplantes Budget"
        ),
        textAlign: "right",
        textVerticalAlign: "bottom",
        top: "73%",
        left: "50%",
        padding: [0, 5, 0, 0],
        textStyle: {
          fontSize: 12,
          fontWeight: "normal",
          color: "#888",
        },
      },
      {
        text: StringUtils.formatCurrency(budgetSum),
        textAlign: "left",
        textVerticalAlign: "bottom",
        top: "73%",
        left: "50%",
        padding: [0, 0, 0, 5],
        textStyle: {
          fontSize: 16,
          fontWeight: "normal",
          color: "#333",
        },
      },

      {
        text: i18n.t(
          "apTemplate:BudgetProgressChart.actualSpend",
          "Tatsächliche Ausgaben"
        ),
        textAlign: "right",
        textVerticalAlign: "bottom",
        top: "82%",
        left: "50%",
        padding: [0, 5, 0, 0],
        textStyle: {
          fontSize: 12,
          fontWeight: "normal",
          color: "#888",
        },
      },
      {
        text: StringUtils.formatCurrency(actualSum),
        textAlign: "left",
        textVerticalAlign: "bottom",
        top: "82%",
        left: "50%",
        padding: [0, 0, 0, 5],
        textStyle: {
          fontSize: 16,
          fontWeight: "normal",
          color: "#333",
        },
      },

      {
        text: i18n.t(
          "apTemplate:BudgetProgressChart.projectDuration",
          "Projektzeitraum"
        ),
        textAlign: "right",
        textVerticalAlign: "bottom",
        top: "91%",
        left: "50%",
        padding: [0, 5, 0, 0],
        textStyle: {
          fontSize: 12,
          fontWeight: "normal",
          color: "#888",
        },
      },
      {
        text: `${StringUtils.formatDate(
          activity.data.dateFrom,
          "MM/YYYY"
        )} - ${StringUtils.formatDate(activity.data.dateTo, "MM/YYYY")}`,
        textAlign: "left",
        textVerticalAlign: "bottom",
        top: "91%",
        left: "50%",
        padding: [0, 0, 0, 5],
        textStyle: {
          fontSize: 16,
          fontWeight: "normal",
          color: "#333",
        },
      },
    ],
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "40%"],
        // adjust the start and end angle
        startAngle: 200,
        endAngle: 340,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: actualSum,
            name: "Actual",
            itemStyle: {
              color: "#1a7a04",
            },
          },
          ...(difference > 0
            ? [
                {
                  value: difference,
                  name: "openBudget",
                  itemStyle: {
                    color: "#cbcbcb",
                  },
                },
              ]
            : []),
          ...(difference < 0
            ? [
                {
                  value: difference * -1,
                  name: "overbooked",
                  itemStyle: {
                    color: "#9b0000",
                  },
                },
              ]
            : []),
        ],
      },
      {
        name: "Time",
        type: "pie",
        radius: ["35%", "40%"],
        center: ["50%", "40%"],
        // adjust the start and end angle
        startAngle: 200,
        endAngle: 340,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: progressDate,
            name: "Actual",
            itemStyle: {
              color: "#5e9053",
            },
          },
          ...(differenceDate > 0
            ? [
                {
                  value: differenceDate,
                  name: "openBudget",
                  itemStyle: {
                    color: "#e3e2e2",
                  },
                },
              ]
            : []),
          ...(differenceDate < 0
            ? [
                {
                  value: differenceDate * -1,
                  name: "overbooked",
                  itemStyle: {
                    color: "#9f4343",
                  },
                },
              ]
            : []),
        ],
      },
    ],
  };
};
