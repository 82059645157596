import moment from "moment";
import { useEffect, useState } from "react";
import HeatmapChartComponent from "../../../../../../configurable/components/ChartComponent/HeatmapChartComponent";
import { APContractActivity } from "./APActivityMainteanceInterfaces";
// import "./APActivityMaintenanceHeatmapTab.scss";

interface APActivityMaintenanceHeatmapTabProps {
  activity: APContractActivity;
}
const APActivityMaintenanceHeatmapTab = (
  props: APActivityMaintenanceHeatmapTabProps
) => {
  const [data, setData] = useState<[Date, number][]>([]);
  useEffect(() => {
    const result: { [date: string]: number } = {};
    const appointments = props.activity.data.appointments || [];
    const oldAppointments = appointments.filter((e) => e.status === "done");

    const yearBegin = moment().year();
    const yearUntil =
      props.activity.data.noticePeriod.type === "unlimited"
        ? yearBegin + 3
        : moment(
            props.activity.data.noticePeriod.typeData.contractActiveTill
          ).year();
    const contractUntil =
      props.activity.data.noticePeriod.type === "unlimited"
        ? moment().add(3, "years")
        : moment(props.activity.data.noticePeriod.typeData.contractActiveTill);

    const addDate = (date: Date) => {
      const datestr = moment(date).format("YYYY-MM-DD");
      if (!result[datestr]) {
        result[datestr] = 1;
      } else {
        result[datestr] += 1;
      }
    };
    oldAppointments.forEach((app) => {
      addDate(app.dueDate);
    });

    const currentAppointments = appointments.filter((e) => e.status === "open");

    currentAppointments.forEach((app) => {
      const dueDate = moment(app.dueDate);
      addDate(app.dueDate);

      const rule = props.activity.data.schedulingRules.find(
        (e) => e.id === app.scheduleRuleId
      );
      if (rule) {
        if (rule.type === "regular") {
          let current = dueDate.clone();
          while (current.isBefore(contractUntil)) {
            current = current.add(rule.typeData.numberOfMonths, "months");
            if (current.isBefore(contractUntil)) {
              addDate(current.toDate());
            }
          }
        }
        if (rule.type === "fixed") {
          rule.typeData.forEach(({ month, date }) => {
            let year = yearBegin;

            while (year <= yearUntil) {
              const d = new Date(year, month - 1, date);
              if (moment(d).isAfter(dueDate)) {
                addDate(d);
              }
              year++;
            }
          });
        }
      }
    });

    const resultArray = Object.keys(result).map((key) => {
      return [new Date(key), result[key]];
    }) as [Date, number][];

    setData(resultArray);
  }, [props.activity]);

  return (
    <HeatmapChartComponent
      identifier={`maintenance-heatmap-${props.activity._id}`}
      data={data}
      renderer="canvas"
      hideLegend
    />
  );
};

export default APActivityMaintenanceHeatmapTab;
