import classNames from "classnames";
import i18n from "../../i18n";
import BFOverlay from "../../modules/abstract-ui/general/whisper/BFOverlay";
import UserAvatar from "./UserAvatar";
import Userlabel from "./Userlabel";
import "./UsersLabeled.scss";

interface UsersLabeledProps {
  userIds: string[];
  avatarSize?: number;
}
const UsersLabeled = (props: UsersLabeledProps) => {
  if ((props.userIds || []).length === 0) {
    return null;
  }
  if (props.userIds.length === 1) {
    return <Userlabel id={props.userIds[0]} avatarSize={props.avatarSize} />;
  }
  return (
    <BFOverlay
      className={`users-labeled-overlay`}
      delay={300}
      speaker={
        <div className={`user-list`}>
          {props.userIds.map((e) => (
            <div>
              <Userlabel key={e} id={e} avatarSize={props.avatarSize} />
            </div>
          ))}
        </div>
      }
    >
      <div className={classNames(`users-labeled`)}>
        <div className={`user-avatars`}>
          {props.userIds
            .filter((e, index) => index < 5)
            .map((id) => (
              <UserAvatar key={id} id={id} size={props.avatarSize} />
            ))}
        </div>
        <div className={`label`}>
          {i18n.t("Userlabeled.Users", `{{count}} Benutzer`, {
            count: props.userIds.length,
          })}
        </div>
      </div>
    </BFOverlay>
  );
};

export default UsersLabeled;
