import _ from "lodash";
import AssetLoader from "../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../components/ModalComponent/ModalManager";
import i18n from "../../../i18n";
import BaseAsset from "../../../model/general-assets/BaseAsset";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import { DocumentStoreDocument } from "../DSInterfaces";
// import "./AssetDocumentsButton.scss";
import { AssetDocumentsView } from "./AssetDocumentsView";

interface AssetDocumentsButtonProps {
  assetId: string;
  assetType: string;
  documentsFieldPath: string;
}
const AssetDocumentsButton = (props: AssetDocumentsButtonProps) => {
  return (
    <AssetLoader
      assetType={props.assetType}
      id={props.assetId}
      render={(asset: BaseAsset) => {
        const documents = getDocumentsOf(asset, props.documentsFieldPath);
        return (
          <BFButton
            appearance="link"
            className={`asset-documents-button`}
            noPadding
            onClick={() => {
              ModalManager.show({
                noPadding: true,
                backdrop: false,
                size: "md",
                content: (state, setStates, onClose) => (
                  <AssetDocumentsView {...props} onClose={onClose} />
                ),
              });
            }}
          >
            {documents.length === 0 &&
              i18n.t("ds:AssetDocuments.Documents", "Dokumente")}
            {documents.length === 1 &&
              `${documents.length} ${i18n.t(
                "ds:AssetDocuments.DocumentsWithCount_one",
                "Dokument"
              )}`}
            {documents.length > 1 &&
              `${documents.length} ${i18n.t(
                "ds:AssetDocuments.DocumentsWithCount_more",
                "Dokumente"
              )}`}
          </BFButton>
        );
      }}
    />
  );
};

const getDocumentsOf = (asset: BaseAsset, fieldPath: string) => {
  const documents = (_.get(asset, fieldPath) || []) as DocumentStoreDocument[];

  return documents.filter((e) => e.status !== "archived");
};

export default AssetDocumentsButton;
