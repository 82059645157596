import classNames from "classnames";
import React from "react";
import i18n from "../../../i18n";
import { Task } from "../lib";
// import './BFGantChartHeader.scss';

interface BFGanttChartHeaderProps {
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  readonly?: boolean;
  progressChange?: "manual" | "check";
}
const BFGanttChartHeader =
  (additionalColumn?: {
    renderHeader: () => React.ReactNode;
    renderColumn: (task: Task) => React.ReactNode;
  }) =>
  (props: BFGanttChartHeaderProps) => {
    return (
      <div
        className={classNames(`bf-gantt-chart-header`)}
        style={{
          height: props.headerHeight,
        }}
      >
        <div className={`col name-wrapper`}>
          {i18n.t("GanttChart.Header.Name", "Tätigkeit")}
        </div>
        <div className={`col from`}>
          {i18n.t("GanttChart.Header.From", "Start")}
        </div>
        <div className={`col days`}>
          {i18n.t("GanttChart.Header.Days", "Tage")}
        </div>
        <div className={`col to`}>
          {i18n.t("GanttChart.Header.End", "Ende")}
        </div>
        {additionalColumn && (
          <div className={`col additional`}>
            {additionalColumn.renderHeader()}
          </div>
        )}
      </div>
    );
  };

export default BFGanttChartHeader;
