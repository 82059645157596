import { css } from "emotion";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import { SendEvent } from "../../../utils/abstracts/AbstractComponent";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import ModalRouteView from "../../../views/modalRoute/ModalRouteView";
import { IComponent } from "../IComponent";

type Props = {
  component: IComponent;
  overflow?: boolean;
  closable?: boolean;
  backdrop?: boolean | "static";
  size?: "lg" | "md" | "sm" | "xs" | "max" | "full";
  headerTextkey?: string;
  headerText?: string;
  hideHeader?: boolean;
  onOpenEvents: { [key: string]: Function | SendEvent };
  onCloseEvents: { [key: string]: Function | SendEvent };
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class ModalContainer extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  componentDidMount() {
    // super.componentDidMount();

    this.subscribeActionEvent("openModal", (data) => {
      if (data.type === "click") {
        this.props.history.push(
          this.props.location.pathname + "/" + this.props.identifier
        );
      }
    });
    this.subscribeActionEvent("closeModal", (data) => {
      if (data.type === "click") {
        this.props.history.push(
          this.props.location.pathname.split("/" + this.props.identifier)[0]
        );
      }
    });
    this.subscribe("CLOSE_MODAL", (data) => {
      if (data.identifier === this.props.identifier) {
        this.props.history.push(
          this.props.location.pathname.split("/" + this.props.identifier)[0]
        );
      }
    });
    this.populateButtonState("closeModal", {
      disabled: false,
      hidden: false,
    });
    this.populateButtonState("openModal", {
      disabled: false,
      hidden: false,
    });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  closeModal() {
    this.handleEvents(this.props.onCloseEvents);

    this.emit(this.getMappingActionId("closeModal"), { type: "click" });
  }
  openModal() {
    this.handleEvents(this.props.onOpenEvents);
  }

  render() {
    if (!this.shoudBeRendered()) {
      return null;
    }
    const {
      size,
      component,
      i18n,
      headerText,
      headerTextkey,
      backdrop,
      overflow,
    } = this.props;
    return (
      <ModalRouteView
        routeName={this.props.identifier}
        size={size}
        titleKey={headerTextkey ? i18n.t(headerTextkey) : headerText}
        backdrop={backdrop}
        className={`modal-container ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
        onEntering={() => this.openModal()}
        onClosing={() => this.closeModal()}
        overflow={overflow}
      >
        {(window as any).ComponentsMapper.createElement(
          component,
          this.props.params
        )}
      </ModalRouteView>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ModalContainer))
);
