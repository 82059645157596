import { LogInterface } from "../../../configurable/components/LogComponent/LogModel";
import DataBus from "../../../services/DataBus";
import { Filter } from "../../../services/DataService";
import { UpdateCommand } from "../../../services/socket/NotificationInterface";
import { InfiniteTableActions } from "./application-infinite-table-action-types";

export const SET_CONSTANT_DATA = "SET_CONSTANT_DATA";
export const SET_LOG_DATA = "SET_LOG_DATA";
export const SET_LOG_LOADING = "SET_LOG_LOADING";
export const CLEAR_APPLICATION_DATA = "CLEAR_APPLICATION_DATA";
export const PATCH_TABLE_DATA = "PATCH_TABLE_DATA";
export const SET_TABLE_DATA = "SET_TABLE_DATA";
export const SET_TABLE_LOADING = "SET_TABLE_LOADING";
export const SET_TABLE_NEW_DATA = "SET_TABLE_NEW_DATA";
export const REMOVE_TABLE_NEW_DATA = "SET_TABLE_REMOVE_DATA";
export const UPDATE_TABLE_DATA = "UPDATE_TABLE_DATA";
export const SET_TABLE_FILTER = "SET_TABLE_FILTER";
export const SET_TABLE_FULLTEXT_SEARCH = "SET_TABLE_FULLTEXT_SEARCH";
export const SET_TABLE_EVENT = "SET_TABLE_EVENT";
export const SET_RELOAD_TABLE = "SET_RELOAD_TABLE";
export const SET_TABLE_SORT = "SET_TABLE_SORT";
export const SET_APPICATION_CACHE_DATA = "SET_APPICATION_CACHE_DATA";
export const SET_APPICATION_CACHE_DEPRECATED =
  "SET_APPICATION_CACHE_DEPRECATED";
export const SET_APPICATION_CACHE_LOADING = "SET_APPICATION_CACHE_LOADING";
export const SET_APPICATION_CACHE_ERROR = "SET_APPICATION_CACHE_ERROR";
export const RESET_LOG_DATA = "RESET_LOG_DATA";
export const PATCH_TABLE_ROW_DATA = "PATCH_TABLE_ROW_DATA";
export const RESET_CACHE_COMPLETE = "RESET_CACHE_COMPLETE";
export const SET_FLEX_CACHE_DATA = "SET_FLEX_CACHE_DATA";
export const SET_FLEX_CACHE_DATA_MULTIPLE = "SET_FLEX_CACHE_DATA_MULTIPLE";
export const CLEAR_FLEX_CACHE_DATA = "CLEAR_FLEX_CACHE_DATA";
export const DATA_QUERY_SET_DATA = "DATA_QUERY_SET_DATA";
export const DATA_QUERY_SET_ERROR = "DATA_QUERY_SET_ERROR";
export const DATA_QUERY_SET_LOADING = "DATA_QUERY_SET_LOADING";
export const DATA_QUERY_DEPRECATE = "DATA_QUERY_DEPRECATE";
export const CLEAR_ALL_CACHES = "CLEAR_ALL_CACHES";
export type TABLE_EVENT_TYPES = "SELECTION" | "DOUBLE_CLICK";

interface ClearAllCaches {
  type: typeof CLEAR_ALL_CACHES;
}

interface SetFlexCacheData {
  type: typeof SET_FLEX_CACHE_DATA;
  category: string;
  identifier: string;
  data: any;
}
interface SetFlexCacheDataMultiple {
  type: typeof SET_FLEX_CACHE_DATA_MULTIPLE;
  flexData: { category: string; identifier: string; data: any }[];
}
interface ClearFlexCacheData {
  type: typeof CLEAR_FLEX_CACHE_DATA;
  category: string;
}

interface SetConstantData {
  type: typeof SET_CONSTANT_DATA;
  key: string;
  value: any;
}
interface ResetCacheComplete {
  type: typeof RESET_CACHE_COMPLETE;
}

interface ResetLogData {
  type: typeof RESET_LOG_DATA;
  identifiers: string[];
}

interface SetLogData {
  type: typeof SET_LOG_DATA;
  logIdentifier: string;
  mode: "append" | "prepend" | "replace";
  data: LogInterface[];
  params: any;
}

interface SetLogLoading {
  type: typeof SET_LOG_LOADING;
  logIdentifier: string;
  mode: "append" | "prepend" | "replace";
}

interface AddTableNewDate {
  type: typeof SET_TABLE_NEW_DATA;
  tableIdentifier: string;
  assetId: string;
}
interface RemoveTableNewDate {
  type: typeof REMOVE_TABLE_NEW_DATA;
  tableIdentifier: string;
  assetId: string;
}
interface SetTableData {
  type: typeof SET_TABLE_DATA;
  tableIdentifier: string;
  data: {
    loading?: boolean;
    url?: string;
    append?: boolean;
    total: number;
    skip: number;
    limit: number;
    filters: { [dataKey: string]: Filter };
    data: Object[];
    sort: {
      dataKey: string;
      sortType: "asc" | "desc";
    };
    fulltextSearch: string;
  };
}
interface SetTableLoading {
  type: typeof SET_TABLE_LOADING;
  tableIdentifier: string;
  loading: boolean;
}

interface PatchTableRowData {
  type: typeof PATCH_TABLE_ROW_DATA;
  tableIdentifier: string;
  rowId: string;
  data: any;
  mode: "overwrite" | "patchRoot" | "merge";
}

interface SetReloadTable {
  type: typeof SET_RELOAD_TABLE;
  tableIdentifier: string;
  reload: boolean;
}

interface ClearApplicationData {
  type: typeof CLEAR_APPLICATION_DATA;
  paths: string[];
}

interface SetTableFilter {
  type: typeof SET_TABLE_FILTER;
  tableIdentifier: string;
  dataKey: string;
  filter: Filter;
}
interface SetTableFulltextSearch {
  type: typeof SET_TABLE_FULLTEXT_SEARCH;
  tableIdentifier: string;
  fulltextSearch: string;
}

interface SetTableEvent {
  type: typeof SET_TABLE_EVENT;
  tableIdentifier: string;
  event: TABLE_EVENT_TYPES;
  data: Object;
}

interface SetTableSort {
  type: typeof SET_TABLE_SORT;
  tableIdentifier: string;
  sort: {
    dataKey: string;
    sortType: "asc" | "desc";
  }[];
}

interface SetApplicationCacheLoading {
  type: typeof SET_APPICATION_CACHE_LOADING;
  oType: "group" | "user" | "asset";
  id: string;
  assetType?: string;
}
interface SetApplicationCacheError {
  type: typeof SET_APPICATION_CACHE_ERROR;
  oType: "group" | "user" | "asset";
  id: string;
  assetType?: string;
  error: any;
}
interface SetApplicationCacheDeprecated {
  type: typeof SET_APPICATION_CACHE_DEPRECATED;
  oType: "group" | "user" | "asset";
  id: string;
  assetType?: string;
  changeCmd: UpdateCommand;
}
interface SetApplicationCacheData {
  type: typeof SET_APPICATION_CACHE_DATA;
  oType: "group" | "user" | "asset";
  id: string;
  data: any;
  assetType?: string;
  ttl?: number;
  global?: boolean;
}

interface QueryCacheSetData {
  type: typeof DATA_QUERY_SET_DATA;
  appId?: string;
  queryId: string;
  deprecateOn?: Date;
  data: any;
  referenceId?: string;
}
interface QueryCacheSetError {
  type: typeof DATA_QUERY_SET_ERROR;
  appId?: string;
  queryId: string;
  error: string;
  referenceId?: string;
}
interface QueryCacheSetLoading {
  type: typeof DATA_QUERY_SET_LOADING;
  appId?: string;
  queryId: string;
  referenceId?: string;
}
interface QueryCacheDeprecate {
  type: typeof DATA_QUERY_DEPRECATE;
  appId?: string;
  queryId: string;
}
export type ApplicationAction =
  | RemoveTableNewDate
  | AddTableNewDate
  | SetTableLoading
  | SetFlexCacheData
  | ClearFlexCacheData
  | SetApplicationCacheLoading
  | SetApplicationCacheError
  | SetApplicationCacheDeprecated
  | ClearApplicationData
  | SetTableData
  | SetTableEvent
  | SetTableFilter
  | SetTableFulltextSearch
  | SetTableSort
  | ClearAllCaches
  | SetApplicationCacheData
  | PatchTableRowData
  | SetLogData
  | SetLogLoading
  | ResetLogData
  | ResetCacheComplete
  | SetConstantData
  | QueryCacheSetLoading
  | QueryCacheSetData
  | QueryCacheSetError
  | QueryCacheDeprecate
  | SetReloadTable
  | SetFlexCacheDataMultiple
  // added infinite actions here as general type
  | InfiniteTableActions;

setTimeout(() => {
  DataBus.subscribeReduxActions([SET_TABLE_EVENT, RESET_LOG_DATA]);
});
