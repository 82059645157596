import classNames from "classnames";
import { Fragment, useRef, useState } from "react";
import { Animation } from "rsuite";
import BFUseTooltip, { TooltipProps } from "../../general/tooltip/BFUseTooltip";
import BFTag from "../tag/BFTag";
import "./BFTagInput.scss";

type TagTrigger = "space" | "comma" | "enter";

export type TagInputEntry = {
  text: string;
  color?: "default" | "success" | "warning" | "error";
  tooltip?: Omit<TooltipProps, "children" | "container">;
  errorMsg?: string;
};

interface BFTagInputProps {
  tagValues?: TagInputEntry[];
  onTagValueAdd?: (newTag: string) => void;
  onTagRemove?: (removedTag: string) => void;
  inputValue?: string;
  onInputValueChange?: (inputValue: string) => void;
  tagTriggers?: TagTrigger[];
  renderInputOverlay?: () => React.ReactNode;
  overwriteKeyDownEvent?: (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => boolean;
  onInputBlur?: () => void;
}

const BFTagInput = (props: BFTagInputProps) => {
  const { inputValue, tagValues } = props;
  const tagTriggers = props.tagTriggers || ["space", "comma", "enter"];

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const autocompleteRef = useRef<HTMLDivElement>();
  const searchInputRef = useRef<HTMLInputElement>();
  const inputTagComponentRef = useRef<HTMLDivElement>();

  // TODO SJ FIX
  const maxLength = inputTagComponentRef?.current?.clientWidth - 10 || 45;
  const inputWidth = Math.min(45 + inputValue.length * 7, maxLength);
  const tags = tagValues || [];

  const addTag = () => {
    if (tags?.map((t) => t.text).includes(inputValue)) {
      return;
    }

    props.onTagValueAdd(inputValue);
    props.onInputValueChange("");
  };

  const removeTag = (removedTag: string) => {
    props.onTagRemove(removedTag);
  };

  const onInputValueChange = (value: string) => {
    if (props.onInputValueChange) {
      props.onInputValueChange(value);
    }

    props.onInputValueChange(value);
  };

  const onInputBlur = () => {
    setTimerId(
      setTimeout(() => {
        setIsInputFocused(false);
        setTimerId(null);
      }, 150)
    );
  };

  const onInputFocus = () => {
    if (timerId !== null) {
      clearTimeout(timerId);
      setTimerId(null);
      return;
    }
    setIsInputFocused(true);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.overwriteKeyDownEvent) {
      if (props.overwriteKeyDownEvent(event)) {
        event.preventDefault();
        return;
      }
    }

    const keyCode = event.keyCode;
    switch (keyCode) {
      case 8: // backspace
        if (inputValue === "") {
          if (tags?.length > 0) {
            props.onTagRemove(tags[tags.length - 1].text);
            event.preventDefault();
          }
        }
        break;
      case 13: // enter
        if (tagTriggers.includes("enter")) {
          addTag();
          event.preventDefault();
        }
        break;
      case 32: // space
        if (tagTriggers.includes("space")) {
          addTag();
          event.preventDefault();
        }
        break;
      case 188: // comma
        if (tagTriggers.includes("comma")) {
          addTag();
          event.preventDefault();
        }
        break;
    }
  };

  return (
    <div className={classNames("bf-tag-input")} ref={inputTagComponentRef}>
      {tags.length > 0 && (
        <Fragment>
          {tags.map((tag) => {
            const color = tag.color || "default";
            return (
              <div className="tag-container" key={tag.text}>
                {/* TODO SJ ASK BENNI IF WE CAN FIX THIS */}
                {/* <ValidationPopover level="error" message={tag.errorMsg}> */}
                <BFUseTooltip {...tag.tooltip}>
                  <div className="bf-tag-wrapper">
                    <BFTag
                      color={color}
                      size="sm"
                      textKey={tag.text}
                      removeAction={() => {
                        removeTag(tag.text);
                      }}
                    />
                  </div>
                </BFUseTooltip>
                {/* </ValidationPopover> */}
              </div>
            );
          })}
        </Fragment>
      )}
      <div
        className="input-container"
        onClick={() => {
          searchInputRef.current.focus();
        }}
      >
        <input
          ref={searchInputRef}
          onFocus={() => onInputFocus()}
          onBlur={() => {
            onInputBlur();
            if (props.onInputBlur) {
              props.onInputBlur();
            }
          }}
          style={{ width: `${inputWidth}px` }}
          type="text"
          value={inputValue}
          onChange={(e) => {
            onInputValueChange(e.target.value);
          }}
          onKeyDown={(e) => {
            handleInputKeyDown(e);
          }}
        />
        <Animation.Bounce
          in={inputValue.trim() !== "" && isInputFocused}
          unmountOnExit
          timeout={200}
        >
          <div className="bf-tag-overlay-wrapper">
            <div ref={autocompleteRef} className={classNames(`bf-tag-overlay`)}>
              {props.renderInputOverlay && props.renderInputOverlay()}
            </div>
          </div>
        </Animation.Bounce>
      </div>
    </div>
  );
};

export default BFTagInput;
