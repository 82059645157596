import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Link,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { Animation, Dropdown, Nav } from "rsuite";
import ApplicationSidenav from "../../../components/ApplicationSidenav/ApplicationSidenav";
import BfIcon, { BfIconProps } from "../../../modules/abstract-ui/icon/BfIcon";
import { setUiConfig } from "../../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import { AbstractComponent } from "../../../utils/abstracts/AbstractComponent";
import { IComponent } from "../IComponent";
import "./ApplicationSidenavLayoutRoutes.scss";

const { Collapse } = Animation;

type ApplicationSidenavLayoutConf = {
  appearance: "default" | "grey";
  subPages: { [key: string]: SubpageConf | IComponent };
  redirectNotFound: {
    path?: string;
    appExtern?: boolean;
    _path?: string;
  };
  idleComponents?: IComponent;
  sidenavHeader?: IComponent;
};

interface SubpageConf {
  orderIndex: number;
  icon?: BfIconProps;
  routeName: string;
  displayName?: string;
  displayNameSelector?: string;
  actions?: {
    [key: string]: IComponent;
  };
  component?: IComponent;
  _condition?: string;

  subPages?: { [key: string]: SubpageConf };
}

type Props = {
  setUiConfig: (key: string, value: any) => void;
} & ApplicationSidenavLayoutConf &
  RouteComponentProps &
  WithTranslation;

type States = {};

class ApplicationSidenavLayoutRoutes extends AbstractComponent<Props, States> {
  componentDidMount() {
    this.setMobileHeader();
  }

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;

    if (prevProps.location.pathname !== location.pathname) {
      this.setMobileHeader();
    }
  }
  componentWillUnmount() {
    this.props.setUiConfig(DefaultUIConfigs.MOBILE_HEADER, null);
  }
  setMobileHeader() {
    const { setUiConfig, location, subPages } = this.props;
    const activeSubpage = Object.values(subPages).find(
      (subPage) => location.pathname.indexOf(subPage.routeName) !== -1
    );
    if (activeSubpage) {
      setUiConfig(DefaultUIConfigs.MOBILE_HEADER, {
        icon: activeSubpage.icon,
        textKey: activeSubpage.displayNameSelector
          ? activeSubpage.displayNameSelector
          : activeSubpage.displayName,
      });
    } else {
      setUiConfig(DefaultUIConfigs.MOBILE_HEADER, null);
    }
  }

  onNavigationItemClick(route) {
    this.props.history.push(route);
  }

  render() {
    const {
      match,
      location,
      subPages,
      redirectNotFound,
      i18n,
      idleComponents,
    } = this.props;

    const activeSubpage = Object.values(subPages).find(
      (subPage) =>
        location.pathname.indexOf(`${match.url}/${subPage.routeName}`) === 0
    );
    const activeKey = activeSubpage
      ? `${match.url}/${activeSubpage.routeName}`
      : undefined;

    return (
      <>
        <div className="application-sidenav-layout app-layout routes">
          <div className={"sidenav"}>
            <ApplicationSidenav
              appearance={this.props.appearance}
              //   onKeySelect={(key) => this.onNavigationItemClick(key)}
              noTransition={false}
              activeKey={activeKey}
              renderChildren={(expanded) => {
                return (
                  <>
                    <Nav>
                      {this.props.sidenavHeader &&
                        (window as any).ComponentsMapper.createElement(
                          this.props.sidenavHeader,
                          null
                        )}
                      {Object.entries(subPages)
                        .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
                        .map(([key, subPage]) => {
                          if ((subPage as any)._component) {
                            return (
                              window as any
                            ).ComponentsMapper.createElement(
                              subPage,
                              null,
                              key
                            );
                          } else {
                            return this.renderNavObj(
                              subPage as SubpageConf,
                              key,
                              subPage === activeSubpage,
                              expanded
                            );
                          }
                        })}
                    </Nav>
                  </>
                );
              }}
            />
          </div>

          <div className={"app-layout-content"}>
            <Switch>
              {Object.entries(subPages)
                .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
                .map(([key, subPage]) => {
                  if ((subPage as any)._component) {
                    return (window as any).ComponentsMapper.createElement(
                      subPage,
                      null,
                      key
                    );
                  } else {
                    return this.renderRoute(subPage as SubpageConf, key);
                  }
                })}

              <Route
                render={() => (
                  <Redirect
                    to={`${redirectNotFound.appExtern ? "" : match.path}/${
                      redirectNotFound._path
                        ? this.evaluateExpression(
                            redirectNotFound._path,
                            this.props
                          )
                        : redirectNotFound.path
                    }`}
                  />
                )}
              />
            </Switch>
          </div>
        </div>

        {idleComponents
          ? Object.entries(idleComponents)
              .sort((a, b) =>
                a[1] && b[1] ? a[1].orderIndex - b[1].orderIndex : 0
              )
              .map(([key, item]) => {
                return (window as any).ComponentsMapper.createElement(
                  { ...item },
                  null,
                  key
                );
              })
          : null}
      </>
    );
  }

  renderRoute(subPageConf: SubpageConf, key: string) {
    const { match } = this.props;

    if (subPageConf._condition) {
      if (!this.evaluateExpression(subPageConf._condition, this.props)) {
        return null;
      }
    }

    if (
      subPageConf.subPages &&
      Object.entries(subPageConf.subPages).length > 0
    ) {
      return Object.entries(subPageConf.subPages).map(([key, subPage]) =>
        this.renderRoute(subPage, key)
      );
    }

    if (
      !subPageConf.component ||
      !(window as any).ComponentsMapper[subPageConf.component._component]
    ) {
      return null;
    }

    return (
      <Route key={key} path={`${match.url}/${subPageConf.routeName}`}>
        {(window as any).ComponentsMapper.createElement(
          subPageConf.component,
          null
        )}
      </Route>
    );
  }

  renderNavObj(
    subPageConf: SubpageConf,
    key: string,
    isActive: boolean,
    expanded: boolean
  ) {
    const { match, i18n } = this.props;

    if (subPageConf._condition) {
      if (!this.evaluateExpression(subPageConf._condition, this.props)) {
        return null;
      }
    }

    if (
      subPageConf.component &&
      !(window as any).ComponentsMapper[subPageConf.component._component]
    ) {
      return null;
    }

    if (subPageConf.subPages) {
      return (
        <Dropdown
          open={
            Object.values(subPageConf.subPages).some((subPage) =>
              this.props.location.pathname.startsWith(
                `${match.url}/${subPage.routeName}`
              )
            ) || undefined
          }
          className={`sidenav-dropdown ${
            Object.values(subPageConf.subPages).some((subPage) =>
              this.props.location.pathname.startsWith(
                `${match.url}/${subPage.routeName}`
              )
            )
              ? "active"
              : ""
          }`}
          key={key}
          data-key={key}
          placement="rightStart"
          eventKey={key}
          icon={
            subPageConf.icon ? (
              <div>
                <BfIcon {...subPageConf.icon} width={21} height={21} />
              </div>
            ) : null
          }
          title={
            <span>
              {subPageConf.displayNameSelector
                ? i18n.t(subPageConf.displayNameSelector)
                : subPageConf.displayName}
            </span>
          }
        >
          {Object.entries(subPageConf.subPages)
            .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
            .map(([key, subPage]) => {
              if (
                subPage.component &&
                !(window as any).ComponentsMapper[subPage.component._component]
              ) {
                return null;
              }
              if (subPage._condition) {
                if (!this.evaluateExpression(subPage._condition, this.props)) {
                  return null;
                }
              }
              const TransMapper = Trans;
              return [
                <Link
                  key={key}
                  draggable="false"
                  to={`${match.url}/${subPage.routeName}`}
                  style={{ width: "fit-content", textDecoration: "none" }}
                >
                  <Dropdown.Item
                    as="button"
                    className="subpage-action"
                    active={this.props.location.pathname.startsWith(
                      `${match.url}/${subPage.routeName}`
                    )}
                    key={key}
                  >
                    {subPage.displayNameSelector ? (
                      <TransMapper>{subPage.displayNameSelector}</TransMapper>
                    ) : (
                      subPage.displayName
                    )}
                  </Dropdown.Item>
                </Link>,
                subPageConf.actions ? (
                  <Collapse in={isActive}>
                    <div className={`sidenav-quick-actions`}>
                      {Object.entries(subPageConf.actions)
                        .sort((a, b) =>
                          a[1] && b[1] ? a[1].orderIndex - b[1].orderIndex : 0
                        )
                        .map(([key, item]) => {
                          return (window as any).ComponentsMapper.createElement(
                            { ...item, expanded },
                            null,
                            key
                          );
                        })}
                    </div>
                  </Collapse>
                ) : null,
              ];
            })}
        </Dropdown>
      );
    } else {
      const TransMap = Trans;
      return [
        <Link
          key={key}
          draggable="false"
          to={`${match.url}/${subPageConf.routeName}`}
          style={{ width: "fit-content", textDecoration: "none" }}
        >
          <Nav.Item
            as="button"
            className={`sidenav-item sidenav-navitem ${
              this.props.location.pathname.startsWith(
                `${match.url}/${subPageConf.routeName}`
              )
                ? "active"
                : ""
            }`}
            key={key}
            data-key={key}
            icon={
              subPageConf.icon ? (
                <BfIcon {...subPageConf.icon} width={21} height={21} />
              ) : null
            }
          >
            {subPageConf.displayNameSelector ? (
              <TransMap>{subPageConf.displayNameSelector}</TransMap>
            ) : (
              subPageConf.displayName
            )}
          </Nav.Item>
        </Link>,
        subPageConf.actions ? (
          <Collapse in={isActive}>
            <div className={`sidenav-quick-actions`}>
              {Object.entries(subPageConf.actions)
                .sort((a, b) =>
                  a[1] && b[1] ? a[1].orderIndex - b[1].orderIndex : 0
                )
                .map(([key, item]) => {
                  return (window as any).ComponentsMapper.createElement(
                    { ...item, expanded },
                    null,
                    key
                  );
                })}
            </div>
          </Collapse>
        ) : null,
      ];
    }
  }
}

const mapStateToProps = (state: AppState) => ({});

export default withRouter(
  connect(mapStateToProps, { setUiConfig })(
    withTranslation()(ApplicationSidenavLayoutRoutes)
  )
);
