import classNames from "classnames";
import { useState } from "react";
import ListViewPDF from "../../../../../components/ListViewPDF/ListViewPDF";
import InfiniteTableFilter, {
  FilterComponentInitialState,
} from "../../../../../configurable/data/FilterComponent/InfiniteTableFilter";
import { InfiniteTableSortOption } from "../../../../../configurable/data/SortComponent/BaseTableSort";
import InfiniteTableSort from "../../../../../configurable/data/SortComponent/InfiniteTableSort";
import i18n from "../../../../../i18n";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import { useAppConfig, useTypedSelector } from "../../../../../redux/hooks";
import { InfineTableCache } from "../../../../../redux/reducers/application/ApplicationInterface";
import DataBus from "../../../../../services/DataBus";
import DataBusDefaults from "../../../../../services/DataBusDefaults";
import { DataBusSubKeys } from "../../../../../utils/Constants";
import MQ from "../../../../../utils/MatchQueryUtils";
import InvoiceService from "../../../invoiceApp/InvoiceService";
import { AppConfigInvoice, RAInvoice } from "../../../invoiceApp/RAInterfaces";
import { RAInvoicesListFilterOptions } from "../../../invoiceApp/components/ListInvoices/RAInvoicesListFilterOptions";
import RAInvoiceEntryView from "../../../invoiceApp/components/RAInvoiceEntryView";
import { useActivityConstants } from "../../ActivityHooks";
import { APActivity } from "../../ActivityInterfaces";
import "./APAssignActivityToInvoice.scss";

const AP_ASSIGN_ACTIVITIY_TO_INVOICE = "AP_ASSIGN_ACTIVITIY_TO_INVOICE";

const sortOptions: InfiniteTableSortOption[] = [
  {
    label: () =>
      i18n.t("cb:SearchInvoiceForBooking.Sort.Sum.desc", "Summe absteigend"),
    icon: "arrange-list-descending",
    definition: {
      dir: "desc",
      key: "data.invoice.value.converted.amount",
    },
  },
  {
    label: () =>
      i18n.t("cb:SearchInvoiceForBooking.Sort.Sum.asc", "Summe aufsteigend"),
    icon: "arrange-list-ascending",
    definition: {
      dir: "asc",
      key: "data.invoice.value.converted.amount",
    },
  },
  {
    definition: {
      key: "meta.ca",
      dir: "desc",
    },
    label: () =>
      i18n.t(
        "cb:SearchInvoiceForBooking.Sort.SortNewestFirst",
        "Datum absteigend"
      ),
    icon: "arrange-list-descending",
  },
  {
    definition: {
      key: "meta.ca",
      dir: "asc",
    },
    label: () =>
      i18n.t(
        "cb:SearchInvoiceForBooking.Sort.SortOldestFirst",
        "Datum aufsteigend"
      ),
    icon: "arrange-list-ascending",
  },
];
interface APAssignActivityToInvoiceProps {
  onClose: (updatedStuff: boolean) => void;
  activity: APActivity;
  initialFilter?: FilterComponentInitialState;
}
const APAssignActivityToInvoice = (props: APAssignActivityToInvoiceProps) => {
  const constants = useActivityConstants();
  const [selected, setSelected] = useState<RAInvoice>(null);
  const [updated, setUpdated] = useState(false);
  const config = useAppConfig<AppConfigInvoice>();

  const cache = useTypedSelector(
    (state) => state.application.infiniteTables[AP_ASSIGN_ACTIVITIY_TO_INVOICE]
  );
  const count =
    cache?.totalCount && cache?.data
      ? cache.totalCount -
        cache.data?.filter(
          (e, i) =>
            i >= cache.lastSkip &&
            (e._approved === true || e._declined === true)
        ).length
      : undefined;

  const fileCdnData = selected
    ? Object.entries(selected.data.invoice.file)[0]
    : undefined;

  const selectFC = (invoice: RAInvoice, force?: boolean) => {
    setSelected(invoice);
  };

  const reload = () => {
    DataBus.emit(DataBusSubKeys.RELOAD, {
      identifiers: [AP_ASSIGN_ACTIVITIY_TO_INVOICE],
    });
  };
  const connectInvoiceToActivity = async (closeAfterwards: boolean) => {
    await InvoiceService.addActivity(selected, {
      assetId: props.activity._id,
      assetType: constants?.assetType,
    });
    setSelected(null);
    setUpdated(true);
    if (closeAfterwards) {
      props.onClose(true);
    } else {
      DataBusDefaults.reload({
        identifiers: [AP_ASSIGN_ACTIVITIY_TO_INVOICE],
      });
    }
  };

  return (
    <div className={classNames(`ap-assign-activity-to-invoice`)}>
      <div className={`modal-title`}>
        <div className={`__h1 `}>
          {i18n.t(
            "apTemplate:ActivitiesInvoices.ConnectInvoice",
            "Beleg verknüpfen",
            {
              ns: [constants?.assetType, "apTemplate"],
            }
          )}
        </div>
        <BFButton
          appearance="clear-on-white"
          onClick={props.onClose}
          size="sm"
          icon={{ type: "bf", data: "close", size: "sm" }}
        />
      </div>
      <div className={`filterbar`}>
        <InfiniteTableFilter
          // hideTextSearch
          hideFavorites
          identifier={AP_ASSIGN_ACTIVITIY_TO_INVOICE}
          filterOptions={RAInvoicesListFilterOptions("INCOMING", [
            props.activity.data.type,
          ])}
          initialState={props.initialFilter}
        />
        <div className={`fill`} />
        <InfiniteTableSort
          identifier={AP_ASSIGN_ACTIVITIY_TO_INVOICE}
          options={sortOptions}
        />
      </div>
      <div className={`invoices-content`}>
        <ListViewPDF
          //   highlights={highlights}
          zoomFactorStorageKey={"pdf_zoom_factor_list_view"}
          cdnData={
            fileCdnData
              ? {
                  filename: InvoiceService.generateFilenameForInvoice(selected),
                  assetField: "data.invoice.file",
                  assetId: selected._id,
                  assetType: "invoice",
                  cdnData: fileCdnData[1],
                  cdnId: fileCdnData[0],
                }
              : undefined
          }
          selected={{ asset: selected }}
          onClearSelection={() => selectFC(null, null)}
          params={{
            setSelected: selectFC,
            activity: props.activity,
            // setHighlights,
          }}
          pdfFooter={
            <div className={`pdf-footer-actions`}>
              <BFButton
                appearance="outline"
                onClick={() => props.onClose(updated)}
              >
                {i18n.t("Global.Buttons.close")}
              </BFButton>
              <div className={`fill`} />
              <BFButton
                disabled={!selected}
                appearance="primary"
                onClick={async () => await connectInvoiceToActivity(true)}
              >
                {i18n.t(
                  "apTemplate:ActivitiesInvoices.ConnectInvoiceAndClose",
                  "Beleg verknüpfen & Schließen",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )}
              </BFButton>
              <BFButton
                disabled={!selected}
                appearance="primary"
                onClick={async () => await connectInvoiceToActivity(false)}
              >
                {i18n.t(
                  "apTemplate:ActivitiesInvoices.ConnectInvoice",
                  "Beleg verknüpfen",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )}
              </BFButton>
            </div>
          }
          entryRenderer={(asset, params) => {
            const alreadyConnected = false;
            return (
              <div
                className={classNames(`invoice-entry`, {
                  connected: alreadyConnected,
                })}
                onClick={() => params.setSelected(asset)}
              >
                {alreadyConnected && (
                  <div className={`already-connected-indicator`}>
                    {i18n.t(
                      "cb:SearchInvoiceForBooking.AlreadyConnected",
                      "Bereits verknüpft"
                    )}
                  </div>
                )}
                <RAInvoiceEntryView
                  invoice={asset}
                  hideRelations
                  ignoreHistory
                  ignoreMoreInfos
                  hideCostCenter
                  showInvoiceIdHeader={true}
                />
              </div>
            );
          }}
          identifier={AP_ASSIGN_ACTIVITIY_TO_INVOICE}
          dataUrl={`/api/asset/list/invoice`}
          asPost
          listProps={{
            reloadOnMount: true,
            overwriteSkip: (cache: InfineTableCache) =>
              (cache?.data || []).length -
              (cache?.data || []).filter(
                (e) => e._approved === true || e._declined === true
              ).length +
              (cache?.newDataIDs || []).length,
            useNewDataWarning: true,
            newDataWarningText: i18n.t(
              "ra:UploadIncomingInvoice.newInvoicesAvailable",
              "Neue Rechnungen wurden hochgeladen. Klicken Sie auf 'Neu laden', um die Liste zu aktualisieren."
            ),
            emptyText: i18n.t("ra:ApproveList.EmptyText"),
            hiddenSort: [
              {
                key: "data.sortKey",
                dir: "desc",
              },
            ],
            additionalMatchQuery: MQ.combineSpread(
              "and",
              {
                type: "op",
                op: "eq",
                name: "data.type",
                value: props.activity.data.type,
              },
              {
                type: "op",
                op: "ne",
                name: "data.activity.assetId",
                value: props.activity._id,
              }
            ),
            limitPerRequest: 10,
          }}
          listHeader={
            <>
              <div className="title">
                {i18n.t("ra:ApproveList.Title")}
                {count ? <span> ({count})</span> : null}
              </div>

              <div className="fill" />
              <div className="actions">
                <BFButton
                  onClick={reload}
                  icon={{
                    type: "light",
                    data: "refresh",
                    size: "xs",
                  }}
                >
                  {i18n.t("Global.Buttons.reload")}
                </BFButton>
              </div>
            </>
          }
        />
      </div>
    </div>
  );
};

export default APAssignActivityToInvoice;
