import { ElementQueries } from "css-element-queries";
import React, { Suspense, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation, withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { Loader } from "rsuite";
import useReactRouter from "use-react-router";
import "./App.scss";
import KitchenSink from "./apps/kitchensink/KitchenSink";
import ApplicationBody from "./components/ApplicationBody/ApplicationBody";
import ApplicationContainer from "./components/ApplicationContainer/ApplicationContainer";
import BlockUiOverlay from "./components/BlockUiOverlay/BlockUiOverlay";
import GlobalCDNImageGallery from "./components/CDNImageGallery/GlobalCDNImageGallery";
import { AboutComponent, PrivacyComponent } from "./components/DataPrivacy";
import DocumentViewer from "./components/DocumentViewer/DocumentViewer";
import LoadMask from "./components/LoadMask/LoadMask";
import ModalComponent from "./components/ModalComponent/ModalComponent";
import NotSeriousContainer from "./components/not-serious/NotSeriousContainer";
import Log from "./debug/Log";
import GlobalDetailModals from "./modals/GlobalDetailModals";
import { User } from "./model/db/User";
import Toast from "./modules/abstract-ui/notification/Toast";
import ToastManager from "./modules/abstract-ui/notification/ToastManager";
import ContactModule from "./modules/contacts-module/ContactModule";
import GFInitializer from "./modules/generic-forms-impl/GFInitializer";
import WithUsageMetrics from "./modules/usage-metrics/WithUsageMetrics";
import UserModalDialog from "./modules/user-profile-dialog/UserModalDialog";
import { setReloadTable } from "./redux/actions/application/application-actions";
import InfiniteTable from "./redux/actions/application/application-infinite-table-actions";
import { setUiConfig } from "./redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "./redux/reducers/ui-config/UiConfig";
import { AppState } from "./redux/store";
import { getUserData } from "./services/AuthenticationService";
import DataBus from "./services/DataBus";
import GlobalEventService from "./services/GlobalEventService";
import { loadGlobalConfig } from "./services/InitActionService";
import SocketService from "./services/socket/SocketService";
import { ComponentsMapper } from "./utils/ComponentsMapper";
import { DataBusSubKeys } from "./utils/Constants";
import DeviceUtils from "./utils/Device";
import { getErrorLocalized } from "./utils/ErrorCodes";
import { isDevelopment, isTestserver } from "./utils/Properties";
import Tools from "./utils/Tools";
import CompleteRegistrationForm from "./views/completeRegistration/CompleteRegistrationForm";
import HomeView from "./views/home/HomeView";
import LoginView from "./views/login/LoginView";
import LoginForm from "./views/login/components/LoginForm/LoginForm";
import ModalRouteView from "./views/modalRoute/ModalRouteView";
import ReconnectOverlay from "./views/reconnect-overlay/ReconnectOverlay";
import ResetPasswordForm from "./views/reset-password/ResetPasswordForm";

ElementQueries.init();

const DebugController = React.lazy(
  () => import("./debug/DebugController/DebugController")
);
const ThemeConfiguratior = React.lazy(
  () => import("./debug/ThemeConfigurator/ThemeConfiguratior")
);
//test
//initialize generic forms components
GFInitializer();

GlobalEventService.init();

const RedirectToApps = () => {
  return <Redirect to={{ pathname: `/home` }} />;
};
const RedirectToAppsToasted = () => {
  const { t } = useTranslation();
  Toast.info(t("routing.routeNotValid"), 3000);

  return <RedirectToApps />;
};
const RedirectToLogin = (redirectTo?: string, toastMessage?: string) => {
  if (typeof redirectTo === "string") {
    Toast.info(toastMessage);
  }

  return (
    <Redirect
      to={{
        pathname: `/login${
          typeof redirectTo === "string"
            ? "?redirectTo=" + encodeURIComponent(redirectTo)
            : ""
        }`,
      }}
    />
  );
};

const RedirectRoute = () => {
  const { history, location, match } = useReactRouter();
  let redirectTo = match.params["redirectTo"];
  if (redirectTo) {
    redirectTo = decodeURIComponent(redirectTo);

    return <Redirect to={{ pathname: redirectTo }} />;
  } else {
    return <Redirect to={{ pathname: "/home" }} />;
  }
};

const PrivateRoutes = ({ user }) => {
  return (
    <ApplicationBody>
      <Switch>
        <Route path="/home" component={HomeView} />
        {user.mandator_info.apps.map((app) => {
          if (ComponentsMapper[app.component]) {
            return (
              <Route
                key={app._id}
                path={`/${app.name}`}
                render={() => {
                  return (
                    <ApplicationContainer applicationConfig={app}>
                      {React.createElement(
                        ComponentsMapper[app.component],
                        app.config
                      )}
                    </ApplicationContainer>
                  );
                }}
              />
            );
          } else {
            Log.warning("component not found: ", app.component, app);
            return null;
          }
        })}

        {(process.env.REACT_APP_STAGE || "local") === "local" && (
          <Route path="/kitchensink" component={KitchenSink} />
        )}
        <Route component={RedirectToAppsToasted} />
      </Switch>
    </ApplicationBody>
  );
};

const FetchUserDataView = () => {
  const { location } = useReactRouter();
  const [errorOccurred, setErrorOccurred] = useState(null);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  dispatch(
    getUserData(
      (data: User) => {
        Log.debug("user data fetched successfully", data);
      },
      (err: any) => {
        Log.error("auth failed on user data fetching", err);
        setErrorOccurred(true);
      }
    )
  );

  if (errorOccurred === null) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#fff",
        }}
      >
        <Loader size={"lg"} />
      </div>
    );
  } else {
    if (errorOccurred) {
      const toastMessage = t("routing.routeIsPrivate");
      return RedirectToLogin(location.pathname, toastMessage);
    } else {
      return null;
    }
  }
};

const XXL = 1600;
const XL = 1200;
const LG = 992;
const MD = 768;
const SM = 576;

class App extends React.Component<any, any> {
  sizeSelector = null;
  sizeCalcTimeout = null;

  subIdReload = null;
  subIdRoute = null;

  constructor(props) {
    super(props);

    this.state = {
      initError: null,
    };
  }

  populateLocation(props?: any) {
    const useProps = props ? props : this.props;
    const queryParamsObj = Tools.queryParams(useProps.location.search);
    const queryParams = {};
    queryParamsObj.forEach((value, key) => {
      queryParams[key] = value;
    });
    DataBus.emit(
      "__LOCATION",
      {
        queryParams: queryParams,
        pathname: useProps.location.pathname,
      },
      true
    );
  }

  shouldComponentUpdate(
    nextProps: Readonly<any>,
    nextState: Readonly<any>,
    nextContext: any
  ): boolean {
    (window as any).router = nextProps.history;

    if (this.props.location !== nextProps.location) {
      this.populateLocation(nextProps);
    }

    if (
      this.props.debug !== nextProps.debug ||
      this.state.initError !== nextState.initError ||
      (this.props.user && !nextProps.user) ||
      (nextProps.user && !this.props.user) ||
      (nextProps.user &&
        this.props.user &&
        nextProps.user._id !== this.props.user._id) ||
      this.props.configInitialized !== nextProps.configInitialized
    ) {
      return true;
    }
    return false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
    }

    // __LOCATION
  }

  componentWillUnmount(): void {
    if (this.subIdReload) {
      DataBus.unsubscribe(this.subIdReload);
    }
    if (this.subIdRoute) {
      DataBus.unsubscribe(this.subIdRoute);
    }
  }

  componentDidMount(): void {
    if (isDevelopment()) {
      document.querySelector("body").classList.add("development");
    }
    if (DeviceUtils.isApple()) {
      document.querySelector("body").classList.add("apple");
    }
    if (isTestserver()) {
      document.querySelector("body").classList.add("testserver");
    }

    this.subIdReload = DataBus.subscribe(
      DataBusSubKeys.RELOAD,
      ({ identifiers }: { identifiers: string[] }) => {
        (identifiers || []).forEach((tableIdentifier) => {
          this.props.setReloadTable(tableIdentifier, true);
          this.props.markForReload(tableIdentifier, true);
        });
      }
    );

    (window as any).mHistory = this.props.history;
    this.subIdRoute = DataBus.subscribe(DataBusSubKeys.ROUTE, (val) => {
      if (val["back"]) {
        this.props.history.goBack();
        return;
      }
      if (val["route"]) {
        let path;
        if (val["append"]) {
          path = this.props.location.pathname + "/" + val["route"];
        } else {
          path = val["route"];
        }
        if (val["stayInApp"]) {
          path = `/${this.props.currentApplication?.name || ""}${val["route"]}`;
        }
        let queryParams = Tools.queryParams();
        if (val["clearQueryParams"]) {
          queryParams = new URLSearchParams();
        }
        if (val["queryParams"]) {
          Object.entries(val["queryParams"]).forEach(([key, val]) =>
            queryParams.set(
              key,
              typeof val === "object" ? JSON.stringify(val) : (val as string)
            )
          );
        }
        if (queryParams.toString().length > 0) {
          path += "?" + queryParams.toString();
        }

        if (val["replace"] === true) {
          this.props.history.replace(path);
        } else {
          this.props.history.push(path);
        }
      }
    });
    this.props.loadGlobalConfig(undefined, (err) => {
      this.setState({
        initError: getErrorLocalized(err),
      });
    });

    this.calculateSizes();
    window.onresize = (ev) => {
      if (this.sizeCalcTimeout !== null) {
        clearTimeout(this.sizeCalcTimeout);
        this.sizeCalcTimeout = null;
      }

      this.sizeCalcTimeout = setTimeout(() => {
        this.calculateSizes();
        this.sizeCalcTimeout = null;
      }, 500);
    };
    this.populateLocation();
  }

  calculateSizes() {
    const currentWidth = window.innerWidth;
    this.props.setUiConfig(DefaultUIConfigs.VIEWPORT_WIDTH, currentWidth);
    this.props.setUiConfig(
      DefaultUIConfigs.VIEWPORT_HEIGHT,
      window.innerHeight
    );

    DataBus.emit(
      "Config." + DefaultUIConfigs.VIEWPORT_WIDTH,
      currentWidth,
      true
    );
    DataBus.emit(
      "Config." + DefaultUIConfigs.VIEWPORT_HEIGHT,
      window.innerHeight,
      true
    );

    let sizeSelector = "";
    if (currentWidth > XXL) {
      sizeSelector = "xxl";
    } else if (currentWidth > XL) {
      sizeSelector = "xl";
    } else if (currentWidth > LG) {
      sizeSelector = "lg";
    } else if (currentWidth > MD) {
      sizeSelector = "md";
    } else if (currentWidth > SM) {
      sizeSelector = "sm";
    } else {
      sizeSelector = "xs";
    }
    if (this.sizeSelector !== sizeSelector) {
      this.sizeSelector = sizeSelector;

      this.props.setUiConfig(
        DefaultUIConfigs.VIEWPORT_SIZE_SELECTOR,
        sizeSelector
      );
    }
  }

  buildHelmet() {
    const { context } = this.props;

    return (
      <Helmet>
        <title>{context.name ? context.name : "iberio"}</title>
        {context.favIcon ? (
          <link rel="icon" type="image/png" href={context.favIcon} />
        ) : (
          <link rel="icon" type="image/png" href="/img/favIcon.png" />
        )}
        <style id="context-styles" type="text/css">
          {`
                            ${
                              context?.styleVariables
                                ? `
                            body {
                                ${Object.entries(context.styleVariables)
                                  .map(
                                    ([varName, value]) =>
                                      "--" + varName + ": " + value + ";"
                                  )
                                  .join("\n")}
                            } `
                                : ""
                            }
                        
                            ${
                              context?.customCSS
                                ? Object.values(context.customCSS).join("\n")
                                : ""
                            }
                        `}
        </style>
      </Helmet>
    );
  }

  render() {
    const { context } = this.props;

    (window as any).i18n = this.props.i18n;
    (window as any).translate = this.props.t;
    (window as any).translateFallback = (key, fallbackKey) => {
      const translation = (window as any).translate(key);
      if (translation === key) {
        return (window as any).translate(fallbackKey);
      }
      return translation;
    };

    if (this.state.initError) {
      return (
        <div
          style={{
            padding: 30,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.state.initError}
        </div>
      );
    }
    if (!this.props.configInitialized) {
      return <LoadMask />;
    }

    const user = this.props.user;

    return (
      <>
        <ModalComponent />

        {user ? (
          <WithUsageMetrics
            onMetrics={(metrics) => {
              SocketService.emitMessage(
                "req",
                "im",
                `${metrics.keystrokes};${metrics.mouseActions};${metrics.scrollAction}`
              );
            }}
          >
            <Switch>
              <Route
                path="/completeRegistration"
                children={
                  <Redirect to={{ pathname: "/home/__userprofile/profile" }} />
                }
              />
              <Route
                path="/resetPassword"
                children={<Redirect to={{ pathname: "/home" }} />}
              />
              <Route
                path="/login?redirectTo=:redirectTo"
                component={RedirectRoute}
              />
              <Route exact path="/login" component={RedirectRoute} />
              <Route exact path="/" component={RedirectToApps} />
              <Route path="/">
                <Switch>
                  <PrivateRoutes user={user} />
                  <Route component={RedirectToAppsToasted} />
                </Switch>
              </Route>
              <Route component={RedirectToAppsToasted} />
            </Switch>
            <ModalRouteView
              className={"user-profile-modal"}
              backdrop={"static"}
              size="max"
              routeName={"__userprofile"}
              titleKey={"UserModal.Title"}
              titleCondition={
                "#{profileModal} && #{profileModal}.textHidden ? #{profileModal}.translated : ${default} "
              }
              stateSubscriptions={["profileModal"]}
            >
              <UserModalDialog />
              {/* {ComponentsMapper.createElement(LayoutUserprofile)} */}
            </ModalRouteView>

            <GlobalDetailModals />
          </WithUsageMetrics>
        ) : (
          <>
            <Switch>
              <Route exact path="/" component={RedirectToLogin} />

              <Route path="/confirmNewEmail/:mandator/:token">
                {
                  //todo do confirm request and redirect to login
                }
              </Route>

              <Route path="/resetPassword/:mandator/:token">
                <LoginView
                  renderContent={(cookiesAccepted, mandatorName) => (
                    <CompleteRegistrationForm />
                  )}
                />
              </Route>

              <Route path="/resetPassword">
                <LoginView
                  renderContent={(cookiesAccepted, mandatorName) => (
                    <ResetPasswordForm />
                  )}
                />
              </Route>
              <Route path="/completeRegistration/:mandator/:token">
                <LoginView
                  renderContent={(cookiesAccepted, mandatorName) => (
                    <CompleteRegistrationForm />
                  )}
                />
              </Route>

              <Route path="/login">
                <LoginView
                  renderContent={(cookiesAccepted, mandatorName) => (
                    <LoginForm
                      cookiesAccepted={cookiesAccepted}
                      mandatorName={mandatorName}
                    />
                  )}
                />
              </Route>
              <Route path="/login?redirectTo=:redirectTo">
                <LoginView
                  renderContent={(cookiesAccepted, mandatorName) => (
                    <LoginForm
                      cookiesAccepted={cookiesAccepted}
                      mandatorName={mandatorName}
                    />
                  )}
                />
              </Route>

              <Route component={FetchUserDataView} />
            </Switch>
          </>
        )}
        <BlockUiOverlay />
        <ModalRouteView
          overflow={false}
          size="max"
          routeName={"__about"}
          titleKey={"Modals.About.title"}
        >
          <AboutComponent />
        </ModalRouteView>
        <ModalRouteView
          overflow={false}
          size="max"
          routeName={"__privacy"}
          titleKey={"Modals.Privacy.title"}
        >
          <PrivacyComponent />
        </ModalRouteView>
        {/* <HelpDrawer /> */}
        <div
          className={`portal-modal`}
          ref={(ref) => DataBus.emit(DataBusSubKeys.PORTAL_MODAL, ref, true)}
        />
        {this.props.debug ? (
          <>
            <Suspense fallback={<LoadMask />}>
              <DebugController />
              <ThemeConfiguratior />
            </Suspense>
          </>
        ) : null}

        <ContactModule />
        <GlobalCDNImageGallery />
        <NotSeriousContainer />
        <DocumentViewer />
        <ToastManager />
        <ReconnectOverlay />
        {this.buildHelmet()}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    debug: state.uiConfig.general[DefaultUIConfigs.DEBUG],
    user: state.global.user,
    configInitialized: state.global.configInitialized,
    context: state.global.context,
    currentApplication: state.uiConfig.activeApplication,
  };
};

const AppOut = withRouter(
  connect(mapStateToProps, {
    setUiConfig,
    loadGlobalConfig,
    setReloadTable,
    markForReload: InfiniteTable.markForReload,
  })(withTranslation()(App))
);

export default AppOut;
