import React, { useRef } from "react";
import { Whisper } from "rsuite";
import { useDatabus } from "../../../../redux/hooks";
import { PlacementAll } from "../../common/Placements";

export type BFWhisperProps = {
  identifier?: string;
  container?: HTMLElement | (() => HTMLElement); //	Sets the rendering container
  delay?: number; //	Delay Time
  delayHide?: number; //	Hidden delay Time
  delayShow?: number; //	Show Delay Time
  onBlur?: () => void; //	Lose Focus callback function
  onClick?: () => void; //	Click on the callback function
  onFocus?: () => void; //	Callback function to get focus
  onMouseOut?: () => void; //	Mouse leave callback function
  onExiting?: () => void; //	Mouse leave callback function
  onEnter?: () => void; //	Mouse leave callback function
  onEntered?: () => void; //	Mouse leave callback function
  onEntering?: () => void; //	Mouse leave callback function
  onExit?: () => void; //	Mouse leave callback function
  onExited?: () => void; //	Mouse leave callback function
  onOpen?: () => void; //	Mouse leave callback function
  placement?: PlacementAll; // PlacementAll ('right')	Dispaly placement
  speaker: any; //	union: Tooltip, Popover	Displayed component
  trigger?: "click" | "hover" | "focus" | "active"; //union: 'click', 'hover', 'focus', 'active' (['hover','focus'])

  enterable?: boolean;
  triggerRef?: React.Ref<any>;
  open?: boolean;
  ref?: any;

  children?: React.ReactElement;
};

const BFWhisper = (props: BFWhisperProps) => {
  const ref = useRef(null);
  useDatabus("WHISPER", (data: any) => {
    if (data.identifier === props.identifier) {
      if (data.type === "OPEN") {
        ref?.current?.open();
      }
      if (data.type === "CLOSE") {
        ref?.current?.close();
      }
    }
  });

  return (
    <Whisper ref={ref} {...props}>
      {props.children}
    </Whisper>
  );
};

export default BFWhisper;
