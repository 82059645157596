import { FormDefinition } from "../../../modules/generic-forms/GenericForms";

export const StandardForms: { [key: string]: FormDefinition } = {
  registerPinLogin: {
    properties: {
      deviceName: {
        _component: "text",
        label: "Gerätename",
        labelPosition: "top",
        submitType: "add",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      appName: {
        _component: "text",
        label: "type",
        labelPosition: "top",
        submitType: "add",
      },
      pin: {
        _component: "pin",
        submitType: "add",
        numberOnly: true,
        length: 4,
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
          {
            type: "minLength",
            level: "error",
            text: "Geben Sie den PIN vollständig ein",
            param: 4,
          },
        ],
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      a1: {
        _component: "div",
        style: {
          display: "flex",
          flexDirection: "column",
        },
        components: {
          a4: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "deviceName",
              },
            },
          },
          description: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "label",
                textStyle: "label",
                textKey: "UserModal.Devices.RegisterPin.Description",
              },
            },
          },
          a6: {
            _component: "div",
            style: {
              marginBottom: 20,
              display: "flex",
              justifyContent: "center",
            },
            components: {
              a: {
                _component: "property",
                name: "pin",
              },
            },
          },
        },
      },
    },
  },
  resetPassword: {
    properties: {
      email: {
        _component: "text",
        label: "Email",
        labelPosition: "top",
        submitType: "add",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
          {
            type: "email",
            level: "error",
            text: "Keine gültige E-Mail Adresse",
          },
        ],
      },
      mandator: {
        _component: "text",
        submitType: "add",
        label: "Organisation",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      a1: {
        _component: "div",
        style: {
          display: "flex",
          flexDirection: "column",
        },
        components: {
          title1: {
            _component: "label",
            textStyle: "label",
            textKey: "views.forgotPassword.Title",
            style: {
              marginBottom: 10,
              color: "#333",
              fontSize: "1.4em",
            },
          },
          desc1: {
            _component: "label",
            textStyle: "label",
            textKey: "views.forgotPassword.Description",
            style: {
              marginBottom: 10,
              color: "#999",
            },
          },
          a4: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "mandator",
              },
            },
          },
          a5: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "email",
              },
            },
          },
        },
      },
    },
  },
  setNewPasswordWithToken: {
    properties: {
      _component: {
        _component: "string",
        submitType: "ignore",
      },
      token: {
        _component: "string",
        submitType: "add",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      mandator: {
        _component: "string",
        submitType: "add",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      password: {
        _component: "password",
        label: "Passwort",
        autoComplete: "new-password",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      passwordRetype: {
        _component: "password",
        label: "Passwort wiederholen",
        autoComplete: "new-password",
        labelPosition: "top",
        submitType: "ignore",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
          {
            type: "condition",
            param: "${password} === ${passwordRetype}",
            level: "error",
            text: "Passwörter stimmen nicht überein",
          },
        ],
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      a1: {
        _component: "div",
        style: {
          display: "flex",
          flexDirection: "column",
        },
        components: {
          title1: {
            condition: "${type} !== 'reset_password'",
            _component: "label",
            textStyle: "label",
            textKey: "views.completeRegistration.Title.added_displayname",
            style: {
              marginBottom: 10,
              color: "#333",
              fontSize: "1.4em",
            },
          },
          title2: {
            condition: "${type} === 'reset_password'",
            _component: "label",
            textStyle: "label",
            textKey: "views.completeRegistration.Title.reset_password",
            style: {
              marginBottom: 10,
              color: "#333",
              fontSize: "1.4em",
            },
          },
          desc1: {
            condition: "${type} !== 'reset_password'",
            _component: "label",
            textStyle: "label",
            textKey: "views.completeRegistration.Description.added_displayname",
            style: {
              marginBottom: 10,
              color: "#999",
            },
          },
          desc2: {
            condition: "${type} === 'reset_password'",
            _component: "label",
            textStyle: "label",
            textKey: "views.completeRegistration.Description.reset_password",
            style: {
              marginBottom: 10,
              color: "#999",
            },
          },
          a4: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "password",
              },
            },
          },
          a5: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "passwordRetype",
              },
            },
          },
        },
      },
    },
  },
  setNewPassword: {
    properties: {
      password: {
        _component: "password",
        label: "Passwort",
        autoComplete: "new-password",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      passwordRetype: {
        _component: "password",
        label: "Passwort wiederholen",
        autoComplete: "new-password",
        labelPosition: "top",
        submitType: "ignore",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
          {
            type: "condition",
            param: "${password} === ${passwordRetype}",
            level: "error",
            text: "Passwörter stimmen nicht überein",
          },
        ],
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      a1: {
        _component: "div",
        style: {
          display: "flex",
          flexDirection: "column",
        },
        components: {
          desc: {
            _component: "label",
            textStyle: "label",
            textKey: "UserService.SetPassword.description",
            style: {
              marginBottom: 10,
              color: "#999",
            },
          },
          a4: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "password",
              },
            },
          },
          a5: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "passwordRetype",
              },
            },
          },
        },
      },
    },
  },
  setOwnPassword: {
    properties: {
      oldPassword: {
        _component: "password",
        label: "Aktuelles Passwort",
        autoComplete: "new-password",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      password: {
        _component: "password",
        label: "Passwort",
        autoComplete: "new-password",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      passwordRetype: {
        _component: "password",
        label: "Passwort wiederholen",
        autoComplete: "new-password",
        labelPosition: "top",
        submitType: "ignore",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
          {
            type: "condition",
            param: "${password} === ${passwordRetype}",
            level: "error",
            text: "Passwörter stimmen nicht überein",
          },
        ],
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      a1: {
        _component: "div",
        style: {
          display: "flex",
          flexDirection: "column",
        },
        components: {
          desc: {
            _component: "label",
            textStyle: "label",
            textKey: "UserService.SetOwnPassword.description",
            style: {
              marginBottom: 10,
              color: "#999",
            },
          },
          a43: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "oldPassword",
              },
            },
          },
          a4: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "password",
              },
            },
          },
          a5: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "passwordRetype",
              },
            },
          },
        },
      },
    },
  },
  setUserEmail: {
    properties: {
      email: {
        _component: "text",
        label: "Neue E-Mail Adresse",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      a1: {
        _component: "div",
        style: {
          display: "flex",
          flexDirection: "column",
        },
        components: {
          desc: {
            _component: "label",
            textStyle: "label",
            textKey: "UserService.SetUserEmail.description",
            style: {
              marginBottom: 10,
              color: "#999",
            },
          },
          a4: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "email",
              },
            },
          },
        },
      },
    },
  },
  selfProfile: {
    properties: {
      avatar: {
        _component: "edit-avatar",
        displaynameField: "displayname",
        size: "flex",
      },
      displayname: {
        _component: "text",
        label: "Anzeigename",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      email: {
        _component: "text",
        label: "Email",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
          {
            type: "email",
            level: "error",
            text: "Keine gültige E-Mail Adresse",
          },
        ],
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      form: {
        _component: "purposeChooser",
        id: "selfProfileFormPurpose",
        handleSubmitId: "self-profile-form",
        actionIdMapping: {
          submit: "selfProfileFormPurpose-submit",
          edit: "selfProfileFormPurpose-edit",
          abort: "selfProfileFormPurpose-abort",
        },
        layoutReadonly: {
          a1: {
            _component: "div",
            style: [
              {
                display: "flex",
                alignItems: "center",
              },
            ],
            components: {
              a1: {
                _component: "div",
                style: [
                  {
                    paddingRight: 10,
                    width: 100,
                  },
                  {
                    breakpoint: 400,
                    width: 75,
                  },
                ],
                components: {
                  el: {
                    _component: "showAvatar",
                    size: "flex",
                    displaynameField: "displayname",
                    avatarField: "avatar",
                  },
                },
              },
              a2: {
                _component: "div",
                style: [
                  {
                    flexGrow: 1,
                  },
                ],
                components: {
                  a1: {
                    _component: "col",
                    components: {
                      a0: {
                        _component: "field-table-view",
                        components: ["displayname", "email"],
                        style: {},
                      },
                    },
                  },
                },
              },
            },
          },
        },
        layoutEdit: {
          a1: {
            _component: "div",
            style: [
              {
                display: "flex",
                alignItems: "center",
              },
            ],
            components: {
              a1: {
                _component: "div",
                style: [
                  {
                    paddingRight: 10,
                    width: 100,
                  },
                  {
                    breakpoint: 400,
                    width: 75,
                  },
                ],
                components: {
                  el: {
                    _component: "property",
                    name: "avatar",
                  },
                },
              },
              a2: {
                _component: "div",
                style: [
                  {
                    flexGrow: 1,
                  },
                ],
                components: {
                  a0: {
                    _component: "field-table-view",
                    editable: ["displayname"],
                    components: ["displayname", "email"],
                    style: {},
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  userProfile: {
    properties: {
      status: {
        _component: "select",
        label: "Status",
        submitType: "ignore",
        options: [
          {
            label: "active",
            value: 0,
          },
          {
            label: "inactive",
            value: 1,
          },
          {
            label: "locked",
            value: 2,
          },
          {
            label: "waitingForActivation",
            value: 3,
          },
          {
            label: "deactivated",
            value: 4,
          },
        ],
      },
      id: {
        _component: "number",
        label: "ID",
        submitType: "ignore",
      },
      avatar: {
        _component: "edit-avatar",
        displaynameField: "displayname",
        size: "flex",
      },
      displayname: {
        _component: "text",
        label: "Anzeigename",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      email: {
        _component: "text",
        label: "Email",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
          {
            type: "email",
            level: "error",
            text: "Keine gültige E-Mail Adresse",
          },
        ],
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      form: {
        _component: "purposeChooser",
        id: "userProfileFormPurpose",
        handleSubmitId: "user-profile-form",
        actionIdMapping: {
          submit: "userProfileFormPurpose-submit",
          edit: "userProfileFormPurpose-edit",
          abort: "userProfileFormPurpose-abort",
        },
        layoutReadonly: {
          a1: {
            _component: "div",
            style: [
              {
                display: "flex",
                alignItems: "center",
              },
            ],
            components: {
              a1: {
                _component: "div",
                style: [
                  {
                    paddingRight: 10,
                    width: 100,
                  },
                  {
                    breakpoint: 400,
                    width: 75,
                  },
                ],
                components: {
                  el: {
                    _component: "showAvatar",
                    size: "flex",
                    displaynameField: "displayname",
                    avatarField: "avatar",
                  },
                },
              },
              a2: {
                _component: "div",
                style: [
                  {
                    flexGrow: 1,
                  },
                ],
                components: {
                  a1: {
                    _component: "col",
                    components: {
                      a0: {
                        _component: "field-table-view",
                        components: ["id", "displayname", "email", "status"],
                        style: {},
                      },
                    },
                  },
                },
              },
            },
          },
        },
        layoutEdit: {
          a1: {
            _component: "div",
            style: [
              {
                display: "flex",
                alignItems: "center",
              },
            ],
            components: {
              a1: {
                _component: "div",
                style: [
                  {
                    paddingRight: 10,
                    width: 100,
                  },
                  {
                    breakpoint: 400,
                    width: 75,
                  },
                ],
                components: {
                  el: {
                    _component: "property",
                    name: "avatar",
                  },
                },
              },
              a2: {
                _component: "div",
                style: [
                  {
                    flexGrow: 1,
                  },
                ],
                components: {
                  a0: {
                    _component: "field-table-view",
                    editable: ["displayname"],
                    components: ["id", "displayname", "email", "status"],
                    style: {},
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  userPublic: {
    properties: {
      name: {
        _component: "text",
        label: "Vorname",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      lastname: {
        _component: "text",
        label: "Nachname",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      department: {
        _component: "text",
        label: "Abteilung",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      jobPosition: {
        _component: "text",
        label: "Position",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      phone: {
        _component: "text",
        label: "Telefonnummer",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      form: {
        _component: "purposeChooser",
        id: "userPublicDataFormPurpose",
        handleSubmitId: "user-public-form",
        actionIdMapping: {
          submit: "userPublicDataFormPurpose-submit",
          edit: "userPublicDataFormPurpose-edit",
          abort: "userPublicDataFormPurpose-abort",
        },
        layoutReadonly: {
          a1: {
            _component: "div",
            style: [
              {
                display: "flex",
              },
            ],
            components: {
              a1: {
                _component: "div",
                style: [
                  {
                    flexGrow: 1,
                  },
                ],
                components: {
                  a1: {
                    _component: "col",
                    components: {
                      a0: {
                        _component: "field-table-view",
                        components: [
                          "name",
                          "lastname",
                          "department",
                          "jobPosition",
                          "phone",
                        ],
                        style: {},
                      },
                    },
                  },
                },
              },
            },
          },
        },
        layoutEdit: {},
      },
    },
  },
  user: {
    properties: {
      avatar: {
        _component: "text",
        label: "Anzeigename",
        labelPosition: "top",
      },
      displayname: {
        _component: "text",
        label: "Anzeigename",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      email: {
        _component: "text",
        label: "Email",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
          {
            type: "email",
            level: "error",
            text: "Keine gültige E-Mail Adresse",
          },
        ],
      },
      username: {
        _component: "text",
        label: "Benutzername",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      firstame: {
        _component: "text",
        label: "Vorname",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      lastname: {
        _component: "text",
        label: "Nachname",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
    },
    layout: [],
  },
  userCreation: {
    properties: {
      email: {
        _component: "text",
        label: "Email",
        labelPosition: "top",
        autoCapitalize: "off",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
          {
            type: "email",
            level: "error",
            text: "Keine gültige E-Mail Adresse",
          },
        ],
      },
      displayname: {
        _component: "text",
        label: "Anzeigename",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      withPassword: {
        _component: "checkbox",
        label: "Passwort setzen",
        submitType: "ignore",
        labelPosition: "top",
      },
      password: {
        _component: "password",
        label: "Passwort",
        autoComplete: "new-password",
        labelPosition: "top",
        condition: "${withPassword} === true",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      passwordRetype: {
        _component: "password",
        label: "Passwort wiederholen",
        autoComplete: "new-password",
        labelPosition: "top",
        condition: "${withPassword} === true",
        submitType: "ignore",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
          {
            type: "condition",
            param: "${password} === ${passwordRetype}",
            level: "error",
            text: "Passwörter stimmen nicht überein",
          },
        ],
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      a1: {
        _component: "div",
        style: {
          display: "flex",
          flexDirection: "column",
        },
        components: {
          a1: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "email",
              },
            },
          },
          a2: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "displayname",
              },
            },
          },
          a3: {
            _component: "div",
            style: {
              marginBottom: 10,
            },
            components: {
              a: {
                _component: "property",
                name: "withPassword",
              },
            },
          },
          desc: {
            _component: "label",
            condition: "${withPassword} === true",
            textStyle: "label",
            textKey:
              "Application.Administration.User.Creation.describeSetPassword",
            style: {
              marginBottom: 10,
              color: "#999",
            },
          },
          a4: {
            _component: "div",
            components: {
              a5: {
                _component: "div",
                condition: "${withPassword} === true",
                style: {
                  marginBottom: 10,
                },
              },
              a: {
                _component: "property",
                name: "password",
              },
            },
          },
          a5: {
            _component: "div",
            components: {
              a5: {
                _component: "div",
                condition: "${withPassword} === true",
                style: {
                  marginBottom: 10,
                },
              },
              a: {
                _component: "property",
                name: "passwordRetype",
              },
            },
          },
        },
      },
    },
  },
  group: {
    properties: {
      _id: {
        _component: "string",
        label: "internalId",
      },
      id: {
        _component: "number",
        label: "Gruppen-ID",
      },
      name: {
        _component: "text",
        label: "Gruppen-Name",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
          {
            type: "maxLength",
            level: "error",
            text: "Nicht mehr als 50 Zeichen erlaubt",
            param: 50,
          },
          {
            type: "minLength",
            level: "error",
            text: "Bitte geben Sie mehr als 3 Zeichen ein.",
            param: 3,
          },
        ],
      },
      permissions: {
        _component: "app-permissions",
        label: "Rechte",
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      form: {
        _component: "purposeChooser",
        id: "groupFormPurpose",
        handleSubmitId: "group-form",

        actionIdMapping: {
          submit: "groupFormPurpose-submit",
          edit: "groupFormPurpose-edit",
          abort: "groupFormPurpose-abort",
        },

        layoutReadonly: {
          a0: {
            _component: "field-table-view",
            components: ["id", "name"],
            style: {
              marginBottom: 15,
            },
          },
          a1: {
            _component: "row",
            components: {
              a1: {
                _component: "col",
                components: {
                  a1: {
                    _component: "property",
                    name: "permissions",
                    readonly: true,
                  },
                },
              },
            },
          },
        },
        layoutEdit: {
          warning: {
            condition: "(#{userProps}.groups.indexOf(${_id}) !== -1)",
            _component: "message",
            messageType: "warning",
            textKey: "Application.Administration.Group.Form.warningUserGroup",
            showIcon: true,
            style: {
              marginBottom: 20,
            },
          },
          a0: {
            _component: "row",
            components: {
              a1: {
                _component: "col",
                components: {
                  a1: {
                    _component: "property",
                    name: "name",
                  },
                },
              },
            },
          },
          a1: {
            _component: "row",
            style: {
              marginTop: 15,
            },
            components: {
              a1: {
                _component: "col",
                components: {
                  a1: {
                    _component: "property",
                    name: "permissions",
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  groupCreation: {
    properties: {
      name: {
        _component: "text",
        label: "",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      a0: {
        _component: "row",
        components: {
          a1: {
            _component: "col",
            components: {
              a1: {
                _component: "label",
                textStyle: "p",
                text: "Bitte geben Sie den Namen der Gruppe ein, die Sie hinzufügen wollen:",
              },
            },
          },
        },
      },
      a1: {
        _component: "row",
        components: {
          a1: {
            _component: "col",
            components: {
              a1: {
                _component: "property",
                name: "name",
              },
            },
          },
        },
      },
    },
  },
  organizationView: {
    properties: {
      "publicData|name": {
        _component: "text",
        label: "Firmenname",
        labelPosition: "top",
        validators: [
          {
            type: "required",
            level: "error",
            text: "Pflichtfeld",
          },
        ],
      },
      "privateData|street": {
        _component: "text",
        label: "Straße/Hnr.",
        labelPosition: "top",
      },
      "privateData|logo": {
        _component: "image",
      },
      "privateData|postalcode": {
        _component: "text",
        label: "Postleitzahl",
        labelPosition: "top",
      },
      "privateData|city": {
        _component: "text",
        label: "Stadt",
        labelPosition: "top",
      },
      "privateData|phoneNumber": {
        _component: "text",
        label: "Telefonnummer",
        labelPosition: "top",
      },
      "privateData|faxNumber": {
        _component: "text",
        label: "Fax",
        labelPosition: "top",
      },
      "privateData|email": {
        _component: "mail",
        label: "Email",
        labelPosition: "top",
      },
      "privateData|website": {
        _component: "website",
        label: "Website",
        labelPosition: "top",
      },
      "privateData|supportHotline": {
        _component: "text",
        label: "Hotline",
        labelPosition: "top",
      },
      "privateData|supportEmail": {
        _component: "text",
        label: "Email",
        labelPosition: "top",
      },
      "privateData|quickLinks": {
        _component: "objects",
        label: "Quicklinks",
        labelPosition: "top",
      },
    },
    layout: {
      prompt: {
        _component: "prompt",
      },
      form: {
        _component: "purposeChooser",
        id: "userPublicDataFormPurpose",
        handleSubmitId: "user-public-form",
        actionIdMapping: {
          submit: "userPublicDataFormPurpose-submit",
          edit: "userPublicDataFormPurpose-edit",
          abort: "userPublicDataFormPurpose-abort",
        },
        layoutReadonly: {
          a1: {
            _component: "div",
            style: [
              {
                display: "flex",
              },
            ],
            components: {
              a1: {
                _component: "div",
                style: [
                  {
                    flexGrow: 1,
                  },
                ],
                components: {
                  a0: {
                    _component: "fieldValue",
                    name: "publicData|name",
                    style: {
                      fontSize: "1.3em",
                    },
                  },
                  a1: {
                    _component: "fieldValue",
                    name: "privateData|street",
                  },
                  a2: {
                    _component: "div",
                    style: {
                      display: "flex",
                    },
                    components: {
                      a0: {
                        _component: "fieldValue",
                        name: "privateData|postalcode",

                        style: {
                          marginRight: "5px",
                        },
                      },
                      a1: {
                        _component: "fieldValue",
                        name: "privateData|city",
                      },
                    },
                  },
                  a3: {
                    _component: "fieldValue",
                    name: "privateData|email",
                  },
                  a4: {
                    _component: "fieldValue",
                    name: "privateData|website",
                  },
                },
              },
            },
          },
        },
        layoutEdit: {},
      },
    },
  },
};
