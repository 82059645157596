export const AP_TECHNICAL_UNITS_TABLE = "ap-technical-units-table";

export const CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER =
  "cb-portfolio-object-technical-units-list";

export const CB_PORTFOLIO_RENTAL_UNIT_TECHNICAL_UNIT_IDENTIFIER =
  "cb-portfolio-rental-unit-technical-units-list";

export const CB_PORTFOLIO_RENTAL_UNIT_SUPPLY_UNIT_IDENTIFIER =
  "cb-portfolio-rental-unit-supply-units-list";
