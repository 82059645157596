import ObjectStackingPlan from "@/apps/tatar/cashBudget/views/tenants/components/stacking-plan/ObjectStackingPlan";
import { RentalAgreement } from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import { AssetTypes } from "@/model/AssetTypes";
import AssetLoader from "../../../../../components/AssetLoader/AssetLoader";
import CDNImageGallery, {
  CDNImagePropsWithContent,
} from "../../../../../components/CDNImageGallery/CDNImageGallery";
import i18n from "../../../../../i18n";
import { useActivityConstants } from "../../ActivityHooks";
import { APActivity } from "../../ActivityInterfaces";

interface Props {
  activity: APActivity;
}

export const mapPortfolioObjectImagesToCDNEntries = (
  obj: OAObject,
  withContent: boolean
) => {
  return (
    Object.entries(obj.data.images || {}).map((e) => ({
      assetField: "data.images",
      assetId: obj._id,
      assetType: AssetTypes.Portfolio.Object,
      cdnId: e[0],
      fileKey: obj.cdn?.find(
        (a) => a._id === e[1].linkToCdn || a.id === e[1].linkToCdn
      )?.key,
      filename: e[1].name,
      hasFolderReadPermissions: true,
      ...(withContent
        ? {
            renderContent: (
              <div className="image-overlay">{obj.data.displayName}</div>
            ),
          }
        : {}),
    })) || []
  );
};
const APActivityDetailsGallery = (props: Props) => {
  const constants = useActivityConstants();
  const renderGallery = (additionalImages: CDNImagePropsWithContent[]) => {
    const additionalPages: React.ReactNode[] = [];

    if (constants?.fields?.stackingPlan) {
      additionalPages.push(
        <>
          {constants?.fields?.linkedAsset ===
          AssetTypes.Rental.RentalAgreement ? (
            <AssetLoader
              assetType={AssetTypes.Rental.RentalAgreement}
              id={props.activity.data.linkedAsset.assetId}
              render={(asset: RentalAgreement) => (
                <ObjectStackingPlan
                  objectId={props.activity.data.objectId}
                  markedUnits={asset.data.rentalUnits}
                />
              )}
            />
          ) : (
            <ObjectStackingPlan
              objectId={props.activity.data.objectId}
              markedUnits={props.activity.data.relations
                ?.filter((e) => e.assetType === AssetTypes.Rental.RentalUnit)
                .map((e) => e.assetId)}
            />
          )}
        </>
      );
    }

    const images = props.activity.data.attachments
      ?.map((attachment) => ({
        attachment,
        cdn: props.activity.cdn?.find((e) => e._id === attachment.linkToCdn),
      }))
      .filter((e) => e.cdn && e.cdn.content_type.match(/image\/.*/))
      .map(
        (e) =>
          ({
            assetField: "data.attachments",
            assetId: props.activity._id,
            assetType: constants?.assetType,
            cdnId: e.cdn?._id,
            fileKey: e.cdn.key,
            hasFolderReadPermissions: true,
            filename: e.cdn.filename,
            renderContent: (
              <div className="image-overlay">{e.attachment.name}</div>
            ),
          } as CDNImagePropsWithContent)
      );
    return (
      <CDNImageGallery
        id={"ap-activity-details-gallery"}
        openable
        usePagination={false}
        useMousewheel
        useNavigationButtons
        cdnImages={[...(images || []), ...additionalImages]}
        dimension="lg"
        additionalPages={additionalPages}
        emptyPage={
          <div>
            <div>
              {i18n.t("CDNImageGallery.NoImages", "Keine Bilder vorhanden")}
            </div>
          </div>
        }
      />
    );
  };

  return (
    <div className={`foto-attachments`}>
      <div className={`gallery-container`}>
        <AssetLoader
          assetType={AssetTypes.Portfolio.Object}
          id={props.activity.data.objectId}
          render={(obj: OAObject) => {
            return renderGallery(
              mapPortfolioObjectImagesToCDNEntries(obj, true)
            );
          }}
          renderError={() => renderGallery([])}
        />
      </div>
    </div>
  );
};

export default APActivityDetailsGallery;
