import classNames from "classnames";
import moment from "moment";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch } from "react-redux";
import UserAvatar from "../../../../../components/AvatarComponent/UserAvatar";
import Username from "../../../../../components/AvatarComponent/Username";
import CDNLink from "../../../../../components/CDNLink/CDNLink";
import FormValidators from "../../../../../components/Form/Validation/FormValidators";
import ModalManager from "../../../../../components/ModalComponent/ModalManager";
import PDFPreview from "../../../../../components/PDFViewer/PDFPreview";
import DebugDataComponent from "../../../../../debug/DebugDataComponent";
import i18n from "../../../../../i18n";
import { ApproveDamangeClaimTaskAssetUserSelection } from "../../../../../model/general-assets/TaskAsset";
import BFDropzone from "../../../../../modules/abstract-ui/dropzone/BFDropzone";
import BFCheckbox from "../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "../../../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import BFOverlay from "../../../../../modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "../../../../../modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../../../../../modules/abstract-ui/icon/DefaultIcons";
import EZDate from "../../../../../modules/ez-form/form-elements/ez-date/EZDate";
import EZInput from "../../../../../modules/ez-form/form-elements/ez-input/EZInput";
import EZTextTag from "../../../../../modules/ez-form/form-elements/ez-text-tag/EZTextTag";
import EZTextfield from "../../../../../modules/ez-form/form-elements/ez-textfield/EZTextfield";
import EZGroup from "../../../../../modules/ez-form/form-group/ez-form/EZGroup";
import CacheService from "../../../../../services/CacheService";
import StringUtils from "../../../../../utils/StringUtils";
import { useActivityConstants } from "../../ActivityHooks";
import { APActivity, APOffer } from "../../ActivityInterfaces";
import "./APActivityOffers.scss";
import APUploadOfferDialog from "./APUploadOfferDialog";

interface Props {
  activity: APActivity;
}
const APActivityOffers = (props: Props) => {
  const constants = useActivityConstants();
  const openUploadDialog = (file?: File) => {
    ModalManager.show({
      title: i18n.t(
        "apTemplate:ActivitiesOffers.UploadOffer",
        "Angebot hochladen",
        {
          ns: [constants?.assetType, "apTemplate"],
        }
      ),
      buttons: [],
      backdrop: "static",
      size: "xxl",
      modalClassName: "ap-activity-upload-offer-dialog",
      content: (states, setStates, closeModal) => (
        <APUploadOfferDialog
          unit={props.activity.data.type}
          activityId={props.activity._id}
          file={file}
          onSuccess={(data) => {
            closeModal();
          }}
          onAbort={() => {
            closeModal();
          }}
        />
      ),
    });
  };
  return (
    <div className={classNames(`ap-activity-offers`)}>
      <BFDropzone
        multipe={false}
        onDrop={(accepted, rejected, ev) => {
          if (accepted.length === 1 && rejected.length === 0) {
            // setUploadFiles(accepted);
            openUploadDialog(accepted[0]);
          }
        }}
        accept={{ "application/pdf": [] }}
        render={(open) => (
          <div className={`dropzone-content`}>
            <div className={`header`}>
              <BFButton onClick={open}>
                {i18n.t(
                  "apTemplate:ActivitiesOffers.UploadOffer",
                  "Angebot hochladen",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )}
              </BFButton>
            </div>
            <div className={`offer-list`}>
              {(props.activity.data.offers || []).length === 0 && (
                <div className={`no-offers`}>
                  {i18n.t(
                    "apTemplate:ActivitiesOffers.NoOffers",
                    "Keine Angebote vorhanden",
                    {
                      ns: [constants?.assetType, "apTemplate"],
                    }
                  )}
                </div>
              )}
              {(props.activity.data.offers || [])
                .filter((e) => e && e.linkToCdn)
                .filter((e) => !!e)
                .map((offer) => (
                  <APActivityOffer
                    key={offer.linkToCdn}
                    offer={offer}
                    activity={props.activity}
                  />
                ))}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default APActivityOffers;

const COMPARISON_INITIAL_VALUE = [
  {
    article: "",
    unitPrice: 0,
    comparisonPrice: 0,
    link: "",
  },
];
export const APActivityOffer = (props: {
  hideCheckbox?: boolean;
  selectable?: boolean;
  onSelect?: (selected: boolean) => void;
  selected?: boolean;
  offer: APOffer;
  activity: APActivity;
  userSelections?: ApproveDamangeClaimTaskAssetUserSelection[];
  readonly?: boolean;
}) => {
  const constants = useActivityConstants();
  const dispatch = useDispatch();
  const showPriceTested = constants?.fields?.comparison?.includes(
    props.activity.data.type
  );

  return (
    <div className={`ap-activity-offer `}>
      <EZGroup
        value={{
          vendor: props.offer.vendor,
          note: props.offer.note,
          value: props.offer.value,
          executionDate: props.offer.executionDate,
          mail: props.offer.mail,
          phone: props.offer.phone,
          didComparison: props.offer.didComparison,
          comparisons: props.offer.comparisons,
        }}
        url={`/${constants?.serviceUrl}/${props.activity._id}/offer/${props.offer.linkToCdn}`}
        onSubmitSuccess={(value) =>
          CacheService.updateDataInCaches(value._id, value)
        }
        validate={(value) => {
          const errors: any = {};
          return errors;
        }}
      >
        {(renderProps) => (
          <div className={`offer-form`}>
            <DebugDataComponent data={{ props }} />
            {props.selectable && !props.hideCheckbox && (
              <div className={`checkbox-wrapper`}>
                <BFCheckbox
                  checked={props.selected}
                  onChange={(val, checked) => props.onSelect(checked)}
                />
              </div>
            )}

            <div className={`offer-action`}>
              <div className={`pdf-preview-container`}>
                <CDNLink
                  assetType={constants?.assetType}
                  assetId={props.activity._id}
                  assetField={"data.offers"}
                  cdnId={
                    props.activity.cdn.find(
                      (e) => e._id === props.offer.linkToCdn
                    ).id
                  }
                  filename={
                    props.activity.cdn.find(
                      (e) => e._id === props.offer.linkToCdn
                    ).filename
                  }
                  fileKey={
                    props.activity.cdn.find(
                      (e) => e._id === props.offer.linkToCdn
                    ).key
                  }
                  hasFolderReadPermissions={true}
                  render={(url) => (
                    <PDFPreview url={url} width={100} height={150} />
                  )}
                />
              </div>
              {/* <BFButton
                className={`show-offer`}
                appearance={"link"}
                onClick={async () => {
                  const cdnEntry = props.activity.cdn.find(
                    (e) => e._id === props.offer.linkToCdn
                  );
                  const url = await CDNService.fetchCDNLink({
                    assetType: constants?.assetType,
                    assetId: props.activity._id,
                    assetField: "data.offers",
                    cdnId: cdnEntry.id,
                    hasFolderReadPermissions: true,
                    fileKey: cdnEntry.key,
                  });

                  dispatch(openOrDownloadDocument(url, "pdf"));
                }}
                size="xs"
              >
                {i18n.t(
                  "apTemplate:ActivitiesOffers.ShowOffer",
                  "Angebot anzeigen",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )}
              </BFButton> */}
            </div>
            <div
              className={`offer-content  ${
                props.selectable ? "selectable" : "text-selection-all"
              }`}
              onClick={() => {
                if (props.selectable) {
                  props.onSelect(!props.selected);
                }
              }}
            >
              <div className={`row-1`}>
                <div className={`vendor`}>
                  <Field name="vendor">
                    {({ input, meta }) => (
                      <EZTextfield
                        readonly={props.readonly}
                        textType={"subHeader"}
                        className="title-input"
                        ignoreLinebreaks
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className={`value`}>
                  <Field name="value">
                    {({ input, meta }) => (
                      <EZInput
                        readonly={props.readonly}
                        className="value-input"
                        textAlign={"right"}
                        suffix={StringUtils.getCurrencySymbol()}
                        type={"priceInput"}
                        textType={"subHeader"}
                        {...input}
                      />
                    )}
                  </Field>

                  {/* {StringUtils.formatCurrency(props.offer.value)} */}
                </div>
              </div>
              <div className={`row-2`}>
                <div className={`note`}>
                  <Field name="note">
                    {({ input, meta }) => (
                      <EZTextfield
                        readonly={props.readonly}
                        textType={"default"}
                        {...input}
                        placeholder={i18n.t(
                          "apTemplate:ActivitiesOffers.NoDescriptionPlaceholder",
                          "Keine Beschreibung",
                          {
                            ns: [constants?.assetType, "apTemplate"],
                          }
                        )}
                      />
                    )}
                  </Field>
                </div>
              </div>

              {showPriceTested && (
                <div className={`row-comparison`}>
                  <Field name="didComparison">
                    {({ input, meta }) => (
                      <>
                        <div className={`checkbox-container`}>
                          <BFCheckbox
                            readOnly={props.readonly}
                            checked={input.value}
                            onChange={(_, checked) => input.onChange(checked)}
                          >
                            {i18n.t(
                              "apTemplate:OfferUpload.DidComparison",
                              "Preisvergleich durchgeführt"
                            )}
                          </BFCheckbox>
                        </div>
                        {input.value && (
                          <div className={`comparisons`}>
                            <FieldArray
                              name="comparisons"
                              initialValue={COMPARISON_INITIAL_VALUE}
                            >
                              {({ fields }) => (
                                <div>
                                  <div className={`comparison-entries`}>
                                    {fields.map((field, index) => (
                                      <div
                                        className={`comparison-entry`}
                                        key={field}
                                      >
                                        <div className={`field __flex-3`}>
                                          <Field
                                            name={`${field}.article`}
                                            validate={FormValidators.required()}
                                          >
                                            {({ input, meta }) => (
                                              <BFInput
                                                readonly={props.readonly}
                                                size="sm"
                                                // appearance="clear"
                                                {...input}
                                                label={i18n.t(
                                                  "apTemplate:OfferUpload.Comparison.ArticleName",
                                                  "Artikel"
                                                )}
                                                {...FormValidators.getValidation(
                                                  meta
                                                )}
                                              />
                                            )}
                                          </Field>
                                        </div>
                                        <div className={`field __flex-2`}>
                                          <Field
                                            name={`${field}.unitPrice`}
                                            validate={FormValidators.compose(
                                              FormValidators.required()
                                            )}
                                          >
                                            {({ input, meta }) => (
                                              <BFInput
                                                size="sm"
                                                // appearance="clear"
                                                textAlign="right"
                                                readonly={props.readonly}
                                                type="priceInput"
                                                suffix={StringUtils.getCurrencySymbol()}
                                                {...input}
                                                label={i18n.t(
                                                  "apTemplate:OfferUpload.Comparison.unitPrice",
                                                  "Artikelpreis"
                                                )}
                                                {...FormValidators.getValidation(
                                                  meta
                                                )}
                                              />
                                            )}
                                          </Field>
                                        </div>
                                        <div className={`field __flex-2`}>
                                          <Field
                                            name={`${field}.comparisonPrice`}
                                            validate={FormValidators.compose(
                                              FormValidators.required()
                                            )}
                                          >
                                            {({ input, meta }) => (
                                              <BFInput
                                                size="sm"
                                                // appearance="clear"
                                                textAlign="right"
                                                suffix={StringUtils.getCurrencySymbol()}
                                                type="priceInput"
                                                {...input}
                                                label={i18n.t(
                                                  "apTemplate:OfferUpload.Comparison.comparisonPrice",
                                                  "Preisvergleich"
                                                )}
                                                {...FormValidators.getValidation(
                                                  meta
                                                )}
                                              />
                                            )}
                                          </Field>
                                        </div>
                                        <div className={`field __flex-3`}>
                                          <Field
                                            name={`${field}.link`}
                                            validate={FormValidators.compose(
                                              FormValidators.required(),
                                              FormValidators.url()
                                            )}
                                          >
                                            {({ input, meta }) => (
                                              <BFInput
                                                size="sm"
                                                // appearance="clear"
                                                readonly={props.readonly}
                                                {...input}
                                                label={i18n.t(
                                                  "apTemplate:OfferUpload.Comparison.link",
                                                  "Link zum Preisergleich"
                                                )}
                                                {...FormValidators.getValidation(
                                                  meta
                                                )}
                                                suffixOnClick={() =>
                                                  window.open(
                                                    input.value,
                                                    "_blank"
                                                  )
                                                }
                                                suffix={
                                                  <BfIcon
                                                    type="light"
                                                    data="share-external-link-1"
                                                    size="xxs"
                                                  />
                                                }
                                              />
                                            )}
                                          </Field>
                                        </div>

                                        {fields.length > 1 && !props.readonly && (
                                          <div className={`remove-action`}>
                                            <BFButton
                                              size="xxs"
                                              noPadding
                                              appearance="link"
                                              onClick={() =>
                                                fields.remove(index)
                                              }
                                            >
                                              <BfIcon
                                                size="xxs"
                                                {...DefaultIcons.CLOSE}
                                              />
                                            </BFButton>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                  {!props.readonly && (
                                    <div className={`add-action`}>
                                      <BFButton
                                        size="xxs"
                                        appearance="link"
                                        onClick={() =>
                                          fields.push({
                                            article: "",
                                            unitPrice: 0,
                                            comparisonPrice: 0,
                                            link: "",
                                          } as any)
                                        }
                                      >
                                        {i18n.t(
                                          "apTemplate:OfferUpload.Comparison.Add",
                                          "Artikel hinzufügen"
                                        )}
                                      </BFButton>
                                    </div>
                                  )}
                                </div>
                              )}
                            </FieldArray>
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </div>
              )}

              <div className={`row-3`}>
                {/* TODO (do not delete me) removed until backend is changing offer status properly  */}
                {/* <BFStatus
                  label={getDamageOfferStatusLabel(props.offer.status || "new")}
                  color={getDamageOfferStatusColor(props.offer.status || "new")}
                /> */}

                <Field name="executionDate">
                  {({ input, meta }) => (
                    <EZDate
                      readonly={props.readonly}
                      className={`entry`}
                      size="xs"
                      cleanable
                      {...input}
                      placement={"bottomStart"}
                    />
                  )}
                </Field>
                <Field name="mail">
                  {({ input, meta }) => (
                    <EZTextTag
                      readonly={props.readonly}
                      className={`entry`}
                      icon={{
                        type: "light",
                        data: "email-action-unread",
                        size: "xs",
                      }}
                      size="xs"
                      {...input}
                    />
                  )}
                </Field>
                <Field name="phone">
                  {({ input, meta }) => (
                    <EZTextTag
                      readonly={props.readonly}
                      className={`entry`}
                      icon={{
                        type: "light",
                        data: "phone",
                        size: "xs",
                      }}
                      size="xs"
                      {...input}
                    />
                  )}
                </Field>
                <div className={`fill`} />
                {props.userSelections?.length > 0 && (
                  <div className={`user-selections`}>
                    <div className={`description`}>
                      {i18n.t(
                        "apTemplate:ActivitiesOffers.UserSelectionLabel",
                        "Auswahl",
                        {
                          ns: [constants?.assetType, "apTemplate"],
                        }
                      )}
                      :
                    </div>
                    {props.userSelections.map((select) => (
                      <div className={`selection-entry`} key={select.id}>
                        <BFOverlay
                          marginTop={5}
                          placement="bottomEnd"
                          trigger="hover"
                          speaker={
                            <div className={`ap-activity-user-select`}>
                              <div className={`description`}>
                                {i18n.t(
                                  "apTemplate:ActivitiesOffers.UserSelectionDescription",
                                  "Ausgewählt bei Genehmigung",
                                  {
                                    ns: [constants?.assetType, "apTemplate"],
                                  }
                                )}
                              </div>
                              <div className={`user-name`}>
                                <Username id={select.user} />
                              </div>
                              <div className={`date`}>
                                {moment(select.date).format(
                                  i18n.t("Formats.dateFormat")
                                )}
                              </div>
                            </div>
                          }
                        >
                          <div>
                            <UserAvatar id={select.user} size={20} />
                          </div>
                        </BFOverlay>
                      </div>
                    ))}
                  </div>
                )}
                {/* {props.offer.mail && (
                  <span className={`entry`}>{props.offer.mail}</span>
                )}
                {props.offer.phone && (
                  <span className={`entry`}>{props.offer.phone}</span>
                )} */}
              </div>
            </div>
          </div>
        )}
      </EZGroup>
    </div>
  );
};
