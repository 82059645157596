import moment from "moment";
import Userlabel from "../../../../components/AvatarComponent/Userlabel";
import i18n from "../../../../i18n";
import BFUseTooltip from "../../../abstract-ui/general/tooltip/BFUseTooltip";
import { DocumentStoreDocument } from "../../DSInterfaces";
import "./DSUploadInfoPrefix.scss";

interface DSUploadInfoPrefixProps {
  document: DocumentStoreDocument;
}
const DSUploadInfoPrefix = (props: DSUploadInfoPrefixProps) => {
  const { document } = props;

  return (
    <div className={"uploaded-text"}>
      <Userlabel avatarSize={16} id={document.uploadedBy} />
      <span>{i18n.t("attachment:UploadDocuments.At", "am")}</span>
      <BFUseTooltip
        delay={100}
        trigger="hover"
        placement="bottom"
        tooltip={
          <div>
            {moment(document.date).format(i18n.t("Formats.dateTimeFormat"))}
          </div>
        }
      >
        <span className={"bold"}>
          {moment(document.date).format(i18n.t("Formats.dateFormat"))}
        </span>
      </BFUseTooltip>
    </div>
  );
};

export default DSUploadInfoPrefix;
