import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { Loader } from "rsuite";
import { AccountingConfig } from "./interfaces/account.interface";
import { useAccounting } from "./useAccounting";
import AddAccountingEntityForm from "./views/root/AddAccountingEntityForm";
// import './AccountingLoader.scss';

export type AccountingData = {
  accounting: AccountingConfig;
  reload: () => Promise<void>;
};
interface AccountingLoaderProps {
  entityId: string;
  render: (accouningData: AccountingData) => JSX.Element;
}
const AccountingLoader = (props: AccountingLoaderProps) => {
  const accountingResult = useAccounting(props.entityId);

  if (accountingResult.state === "loading") {
    return (
      <div className={`accounting-loader loading`}>
        <Loader size="md" />
      </div>
    );
  }
  if (accountingResult.state === "error") {
    return (
      <div className={`accounting-loader error`}>
        <div className={`error-message`}>
          {i18n.t("Acccounting.LoadError", "Fehler beim Laden der Daten")}
        </div>
        <BFButton onClick={async () => await accountingResult.reload()}>
          {i18n.t("Global.Buttons.retry", "Erneut versuchen")}
        </BFButton>
      </div>
    );
  }
  if (accountingResult.data === null) {
    return (
      <div className={`accounting-loader no-accounting-established`}>
        <div className={`message`}>
          {i18n.t(
            "Acccounting.NoAccountingEstablished",
            "´Es wurde bisher keine Buchhaltungskonfiguration für die Gesellschaft eingerichtet."
          )}
        </div>
        <BFButton
          onClick={() => {
            ModalManager.show({
              noPadding: true,
              content: (state, setState, close) => (
                <AddAccountingEntityForm
                  close={close}
                  entity={props.entityId}
                  onSuccess={() => {
                    accountingResult.reload();
                  }}
                />
              ),
            });
          }}
        >
          {i18n.t(
            "Global.Buttons.EstablishAccounting",
            "Buchhaltung einrichten"
          )}
        </BFButton>
      </div>
    );
  }

  return props.render({
    accounting: accountingResult.data,
    reload: accountingResult.reload,
  });
};

export default AccountingLoader;
