import moment from "moment";
import DebugDataComponent from "../../debug/DebugDataComponent";
import i18n from "../../i18n";
import BaseAsset from "../../model/general-assets/BaseAsset";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import UserAvatar from "../AvatarComponent/UserAvatar";
import Username from "../AvatarComponent/Username";
import { FilterEntryRenderOptions } from "./HistoryViewer";
import { HistoryEntryObject } from "./IHistoryEntry";

interface Props extends FilterEntryRenderOptions {
  entry: HistoryEntryObject;
  asset: BaseAsset;
}
const HistoryEntry = (props: Props) => {
  return (
    <div className="history-entry">
      <div className="line-horizontal" />
      <div className="line-vertical" />
      <div className="icon-container">
        {props.icon && <BfIcon {...props.icon} />}
      </div>
      <div className="message-container">
        <div className="message">{props.text}</div>
        <div className="message-footer">
          {props.entry.user && (
            <div className="user">
              <UserAvatar id={props.entry.user} size={18} />
              <div className="name">
                <Username id={props.entry.user} />
              </div>
            </div>
          )}
          <div className="fill" />
          <div className="time">
            {moment(props.entry.date).format(i18n.t("Formats.timeFormat"))}
          </div>
        </div>
        <DebugDataComponent data={props.entry} />
      </div>
    </div>
  );
};

export default HistoryEntry;
