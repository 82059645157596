import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { Drawer, Dropdown } from "rsuite";
import i18n from "../../../../i18n";
import BFButton from "../../../../modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "../../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import BfIcon from "../../../../modules/abstract-ui/icon/BfIcon";
import { AppState } from "../../../../redux/store";
import DataBus from "../../../../services/DataBus";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import LanguageService from "../../../../services/LanguageService";
import { DataBusSubKeys } from "../../../../utils/Constants";
import { hasValue } from "../../../../utils/Helpers";
import StringUtils from "../../../../utils/StringUtils";
import CashBudgetUtils from "../CashBudgetUtils";
import { CashBudgetCategory } from "../model/CashBudgetCofiguration";
import {
  CASH_BUDGET_FILTER_PREFIX_ACCOUNT,
  CASH_BUDGET_FILTER_PREFIX_OBJECT,
} from "../services/CashBudgetService";
import { CashBudgetSpan } from "../views/tableview/TatarCashBudgetTableComponent";
import { setTableSort } from "./../../../../redux/actions/application/application-actions";
import CashBudgetCategoryDetail, {
  CASHBUDGET_CATEGORY_DETAIL_TABLE,
} from "./CashBudgetCategoryDetail";
import "./CashBudgetCategoryDrawer.scss";

type Props = {
  requestType: "starmoney" | "datev";
  span: CashBudgetSpan;
  spanType: "normal" | "comparisonShould" | "comparisonCurrent";
  category: CashBudgetCategory;
  filteredEntities: string[];
  filteredObject: string;
  displayType: "DAILY" | "MONTHLY";
  setTableSort: (
    identifier: string,
    sort: {
      dataKey: string;
      sortType: "asc" | "desc";
    }[]
  ) => void;
  onClose: () => void;
  open: boolean;
  amount: number;
  ignoreCategoryChildren?: boolean;

  tableCount?: number;
};

type States = {
  selectedSorting: { label: string; name: string; sortType: "desc" | "asc" };
};
export const CashBudgetFillDataSubscriptionId =
  "CASHBUDGET_CREATE_BOOKING_FILLDATA";
const CashBudgetFillComparisonFormDataSubscriptionId =
  "CASHBUDGET_CREATE_ADJUSTMENT_FILLDATA";
const DropdownPossibilities = () =>
  [
    {
      label: i18n.t("cb:CategoryDrawer.Sort.valueDesc", "Betrag absteigend"),
      name: "data.value",
      sortType: "desc",
    },
    {
      label: i18n.t("cb:CategoryDrawer.Sort.valueAsc", "Betrag aufsteigend"),
      name: "data.value",
      sortType: "asc",
    },
    {
      label: i18n.t("cb:CategoryDrawer.Sort.dateAsc", "Datum aufsteigend"),
      name: "data.date",
      sortType: "asc",
    },
    {
      label: i18n.t("cb:CategoryDrawer.Sort.dateDesc", "Datum absteigend"),
      name: "data.date",
      sortType: "desc",
    },
  ] as {
    label: string;
    name: string;
    sortType: "desc" | "asc";
  }[];
class CashBudgetCategoryDrawer extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    selectedSorting: DropdownPossibilities()[0],
  };

  componentDidUpdate(prevProps: Props) {
    // if (this.props.span && (prevProps.span !== this.props.span || prevProps.category !== this.props.category)) {
    // 	this.setState({ show: true });
    // }
  }
  componentWillUnmount() {}

  onCreateComparisonAdjustment() {
    DataBus.emit(DataBusSubKeys.ROUTE, {
      route: "__createComparisonAdjustment",
      append: true,
    });

    const {
      span: { dateFrom, dateTo },
    } = this.props;

    let date = null;
    if (dateFrom.isSame(dateTo, "day")) {
      date = dateFrom.clone();
    } else {
      const dateToCalc = dateFrom.clone();
      if (dateToCalc.day() === 0) {
        dateToCalc.day(1);
      }
      if (dateToCalc.day() === 6) {
        dateToCalc.day(8);
      }
      date = dateToCalc;
    }
    date = date.hour(12).toDate();
    setTimeout(() => {
      DataBus.emit(
        CashBudgetFillComparisonFormDataSubscriptionId,
        {
          data: {
            date: date,
            category: this.props.category._id,
            entity:
              this.props.filteredEntities.length === 1
                ? this.props.filteredEntities[0]
                : undefined,
            objectId:
              this.props.filteredObject &&
              this.props.filteredObject.indexOf(
                CASH_BUDGET_FILTER_PREFIX_OBJECT
              ) === 0
                ? this.props.filteredObject.substr(
                    CASH_BUDGET_FILTER_PREFIX_OBJECT.length
                  )
                : undefined,
            bankAccount:
              this.props.filteredObject &&
              this.props.filteredObject.indexOf(
                CASH_BUDGET_FILTER_PREFIX_ACCOUNT
              ) === 0
                ? this.props.filteredObject.substr(
                    CASH_BUDGET_FILTER_PREFIX_ACCOUNT.length
                  )
                : this.props.filteredObject.indexOf(
                    CASH_BUDGET_FILTER_PREFIX_OBJECT
                  ) === 0
                ? CashBudgetUtils.getBankAccountByObject(
                    this.props.filteredObject.substr(
                      CASH_BUDGET_FILTER_PREFIX_OBJECT.length
                    )
                  )?._id
                : undefined,
          },
        },
        true
      );
    });
  }
  onCreateBookingClick(createType: "single" | "multiple" = "single") {
    const {
      span: { dateFrom, dateTo },
    } = this.props;

    let date = null;
    if (dateFrom.isSame(dateTo, "day")) {
      date = dateFrom.clone();
    } else {
      const dateToCalc = moment.max(moment(), dateFrom).clone();
      if (dateToCalc.day() === 0) {
        dateToCalc.day(1);
      }
      if (dateToCalc.day() === 6) {
        dateToCalc.day(8);
      }
      date = dateToCalc;
    }
    date = date.hour(12).toDate();

    if (createType === "single") {
      DataBus.emit(DataBusSubKeys.ROUTE, {
        route: "__createBooking",
        append: true,
      });

      setTimeout(() => {
        DataBus.emit(
          CashBudgetFillDataSubscriptionId,
          {
            data: {
              unit: CashBudgetUtils.getUnitByCategory(this.props.category._id),
              date: date,
              category: this.props.category._id,
              entity:
                this.props.filteredEntities.length === 1
                  ? this.props.filteredEntities[0]
                  : undefined,
              bankAccount:
                this.props.filteredObject &&
                this.props.filteredObject.indexOf(
                  CASH_BUDGET_FILTER_PREFIX_ACCOUNT
                ) === 0
                  ? this.props.filteredObject.substr(
                      CASH_BUDGET_FILTER_PREFIX_ACCOUNT.length
                    )
                  : this.props.filteredObject.indexOf(
                      CASH_BUDGET_FILTER_PREFIX_OBJECT
                    ) === 0
                  ? CashBudgetUtils.getBankAccountByObject(
                      this.props.filteredObject.substr(
                        CASH_BUDGET_FILTER_PREFIX_OBJECT.length
                      )
                    )?._id
                  : undefined,
              objectId:
                this.props.filteredObject &&
                this.props.filteredObject.indexOf(
                  CASH_BUDGET_FILTER_PREFIX_OBJECT
                ) === 0
                  ? this.props.filteredObject.substr(
                      CASH_BUDGET_FILTER_PREFIX_OBJECT.length
                    )
                  : undefined,
            },
          },
          true
        );
      });
    } else {
      const data = {
        type: CashBudgetUtils.getUnitByCategory(this.props.category._id),
        category: this.props.category._id,
        date: date,

        banks:
          this.props.filteredObject &&
          this.props.filteredObject.indexOf(
            CASH_BUDGET_FILTER_PREFIX_ACCOUNT
          ) === 0
            ? [
                {
                  id: this.props.filteredObject.substr(
                    CASH_BUDGET_FILTER_PREFIX_ACCOUNT.length
                  ),
                  amount: 0,
                },
              ]
            : this.props.filteredObject.indexOf(
                CASH_BUDGET_FILTER_PREFIX_OBJECT
              ) === 0
            ? [
                {
                  id: CashBudgetUtils.getBankAccountByObject(
                    this.props.filteredObject.substr(
                      CASH_BUDGET_FILTER_PREFIX_OBJECT.length
                    )
                  )?._id,
                  amount: 0,
                },
              ]
            : [],

        objects:
          this.props.filteredObject &&
          this.props.filteredObject.indexOf(
            CASH_BUDGET_FILTER_PREFIX_OBJECT
          ) === 0
            ? [
                {
                  id: this.props.filteredObject.substr(
                    CASH_BUDGET_FILTER_PREFIX_OBJECT.length
                  ),
                  amount: 0,
                },
              ]
            : [],
      };
      DataBusDefaults.route({
        route: "__batchBooking",
        append: true,
        queryParams: { data },
      });
    }
  }
  render() {
    const { amount } = this.props;
    return (
      <Drawer
        size="md"
        className={`cash-budget-details-drawer`}
        open={!!this.props.open}
        onClose={() => this.props.onClose()}
      >
        <Drawer.Header>
          {this.props.span ? (
            <>
              <div className={`details-drawer-header`}>
                <div className={`header-left`}>
                  <div className={`category`}>
                    {LanguageService.translateLabel(
                      this.props.category.data.displayName
                    )}
                  </div>
                  <div className={`date`}>
                    {this.props.spanType !== "normal" ? (
                      <span className={`comparison-identifier`}>
                        [{" "}
                        {this.props.spanType === "comparisonCurrent"
                          ? i18n.t("cb:CategoryDrawer.Is", "IST")
                          : i18n.t("cb:CategoryDrawer.Should", "SOLL")}{" "}
                        ]
                      </span>
                    ) : null}
                    {this.props.span.dateFrom.format(
                      this.props.displayType === "DAILY"
                        ? "D[.] MMMM YYYY"
                        : "MMMM YYYY"
                    )}
                  </div>
                </div>
                <div className={`header-right`}>
                  {(() => {
                    // let amount =
                    //   this.props.span.categories[this.props.category._id]
                    //     ?.displayValue || 0;

                    return (
                      <div className={`amount ${amount < 0 ? "negative" : ""}`}>
                        {StringUtils.formatCurrency(amount, true)}
                      </div>
                    );
                  })()}
                </div>
              </div>
              <div className={`details-toolbar`}>
                {hasValue(this.props.tableCount) ? (
                  <div className={`booking-count`}>
                    {i18n.t(
                      "cb:CategoryDrawer.BookingCount",
                      "{{count}} Buchungen",
                      { count: this.props.tableCount }
                    )}
                  </div>
                ) : null}
                {this.props.spanType === "comparisonShould" ? (
                  <BFButton
                    onClick={() => this.onCreateComparisonAdjustment()}
                    size={"sm"}
                    appearance={"primary"}
                    icon={{ type: "bf", data: "add" }}
                    text={i18n.t(
                      "cb:CategoryDrawer.addAdaption",
                      "Anpassung hinzufügen"
                    )}
                  />
                ) : (moment().isAfter(this.props.span.dateFrom) &&
                    moment().isBefore(this.props.span.dateTo)) ||
                  moment().isBefore(this.props.span.dateTo) ? (
                  <BFDropdown
                    appearance="primary"
                    label={i18n.t(
                      "cb:PlannedBookings.PlanNewBooking",
                      "Neue Buchung planen"
                    )}
                    items={[
                      {
                        type: "button",
                        text: i18n.t(
                          "cb:PlannedBookings.PlanNewBookingSingle",
                          "Einzelne Buchung"
                        ),
                        onSelect: () => this.onCreateBookingClick("single"),
                      },
                      {
                        type: "button",
                        text: i18n.t(
                          "cb:PlannedBookings.PlanNewBookingMultiple",
                          "Mehrere Buchung"
                        ),
                        onSelect: () => this.onCreateBookingClick("multiple"),
                      },
                    ]}
                  />
                ) : null}
                <div className={`fill`} />
                <div className={`order-by`}>
                  <Dropdown
                    placement={"bottomEnd"}
                    title={
                      <div className={`order-label`}>
                        <BfIcon type="bf" data="data-transfer-vertical" />
                        {i18n.t("cb:CategoryDrawer.sort", "Sortierung")}:{" "}
                        {this.state.selectedSorting.label}
                      </div>
                    }
                    activeKey={`${this.state.selectedSorting.name}#${this.state.selectedSorting.sortType}`}
                  >
                    {DropdownPossibilities().map((entry, index) => (
                      <Dropdown.Item
                        key={index}
                        onSelect={() => {
                          this.setState({ selectedSorting: entry });
                          this.props.setTableSort(
                            CASHBUDGET_CATEGORY_DETAIL_TABLE,
                            [
                              {
                                dataKey: entry.name,
                                sortType: entry.sortType,
                              },
                            ]
                          );
                          setTimeout(() => {
                            DataBus.emit(DataBusSubKeys.RELOAD, {
                              identifiers: [CASHBUDGET_CATEGORY_DETAIL_TABLE],
                            });
                          });
                        }}
                        eventKey={`${entry.name}#${entry.sortType}`}
                      >
                        {entry.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </div>
              </div>
            </>
          ) : null}
        </Drawer.Header>
        <Drawer.Body>
          {this.props.span ? (
            <CashBudgetCategoryDetail
              ignoreCategoryChildren={this.props.ignoreCategoryChildren}
              requestType={this.props.requestType}
              assetType={
                this.props.requestType === "starmoney" &&
                this.props.spanType !== "comparisonShould"
                  ? "lq-booking"
                  : this.props.spanType === "comparisonShould"
                  ? "cashbudget-plan"
                  : "lq-booking"
              }
              filteredObject={this.props.filteredObject}
              filteredEntities={this.props.filteredEntities}
              span={this.props.span}
              category={this.props.category}
              filterBy={this.state.selectedSorting}
            />
          ) : null}
        </Drawer.Body>
      </Drawer>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  tableCount: state.application.tables[CASHBUDGET_CATEGORY_DETAIL_TABLE]?.total,
});
export default connect(mapStateToProps, {
  setTableSort,
})(CashBudgetCategoryDrawer) as any;
