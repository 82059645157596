import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import classNames from "classnames";
import moment from "moment";
import { Nav } from "rsuite";
import Log from "../../../../../debug/Log";
import i18n from "../../../../../i18n";
import { MatchQuery } from "../../../../../services/DataService";
import { hasValue } from "../../../../../utils/Helpers";
import StringUtils from "../../../../../utils/StringUtils";
import BFButton from "../../../general/Button/BFButton";
import { FilterSearchOption } from "../BFTableFilterModel";
import "./OverlayDateSelection.scss";
import OverlayTitle from "./OverlayTitle";

const DATE_EXTRACT_REGEX = /(\b\d+(?:[\.,]\d+)?\b(?!(?:[\.,]\d+)))/gm;

const extractDate = (search: string) => {
  const format = i18n.t("Formats.dateFormat");
};

export type DateSelectionValueRange = {
  type: "range";
  from?: Date;
  to?: Date;
};
export type DateSelectionValueEquals = {
  type: "equals";
  value: Date;
};

export type DateSelectionValue =
  | DateSelectionValueRange
  | DateSelectionValueEquals;

interface OverlayDateSelectionProps {
  title: string;
  value: DateSelectionValue;
  onChange: (value: DateSelectionValue) => void;

  // inputProps?: Partial<>;
}

export const getDefaultDateOptions = (
  value: DateSelectionValue,
  searchTerm: string,
  filterKey: string,
  typeLabel: string,
  dateFormatter: (value: Date) => string = (value: Date) =>
    moment(value).format(i18n.t("Formats.dateFormat")),
  additionalKeys?: string[]
) => {
  let options: FilterSearchOption[] = [];
  if (searchTerm) {
    const matches = StringUtils.extractDatesOfString(searchTerm);

    Log.info("OverlayDateSelection DateSearch", "-----", searchTerm, "-----");
    matches.forEach((m) => {
      Log.info(
        "OverlayDateSelection DateSearch Result:",
        m.type,
        m.date.toISOString()
      );
    });
    if (matches && matches.length > 0) {
      let pushFrom = true,
        pushTo = true,
        pushEquals = true;

      const onSelect = (
        optionValue: any,
        currentValue: any,
        onChange: (value: any) => void
      ) => {
        onChange({ ...(currentValue || {}), ...optionValue });
      };
      if (hasValue(value)) {
        if (value.type === "equals") {
          pushEquals = false;
          pushFrom = false;
          pushTo = false;
        }
        if (value.type === "range") {
          pushEquals = false;
          pushFrom = !hasValue(value.from);
          pushTo = !hasValue(value.to);
        }
      }
      if (pushFrom && pushTo && matches.length > 1) {
        //found at least two dates - show directly range option
        const dateEntry1 = matches[0].date.isBefore(matches[1].date)
          ? matches[0]
          : matches[1];
        const dateEntry2 = matches[0].date.isSameOrAfter(matches[1].date)
          ? matches[0]
          : matches[1];

        let from = dateEntry1.date.utc(true).startOf(dateEntry1.type);
        let to = dateEntry2.date.utc(true).endOf(dateEntry2.type);

        options.push({
          filterKey,
          valueKey: `_${filterKey}_between`,
          label: `von ${dateFormatter(from.toDate())} bis ${dateFormatter(
            to.toDate()
          )}`,
          value: {
            type: "range",
            from: from.toDate(),
            to: to.toDate(),
          },
          labelAsString: `${dateEntry1.text} ${dateEntry2.text} ${typeLabel}`,
          onSelect,
          typeLabel,
          searchKeys: [...(additionalKeys || [])],
        });
      } else {
        if (pushFrom) {
          const dateEntry1 = matches[0];
          let from = dateEntry1.date.utc(true).startOf(dateEntry1.type);

          options.push({
            filterKey,
            valueKey: `_${filterKey}_gt`,
            label: `von ${dateFormatter(from.toDate())}`,
            value: {
              type: "range",
              from: from.toDate(),
            },
            labelAsString: `${dateEntry1.text} von ${typeLabel}`,
            onSelect,
            typeLabel,
            searchKeys: [...(additionalKeys || [])],
          });
        }
        if (pushTo) {
          const dateEntry1 = matches[0];
          let to = dateEntry1.date.utc(true).startOf(dateEntry1.type);

          options.push({
            filterKey,
            valueKey: `_${filterKey}_lt`,
            label: `bis ${dateFormatter(to.toDate())}`,
            value: {
              type: "range",
              to: to.toDate(),
            },
            labelAsString: `${dateEntry1.text} bis ${typeLabel}`,
            onSelect,
            typeLabel,
            searchKeys: [...(additionalKeys || [])],
          });
        }
        if (pushEquals) {
          const dateEntry1 = matches[0];
          let eq = dateEntry1.date.utc(true).startOf(dateEntry1.type);
          let to = null;
          if (dateEntry1.type !== "day") {
            to = eq.clone().endOf(dateEntry1.type);
            options.push({
              filterKey,
              valueKey: `_${filterKey}_eq`,
              label: `von ${dateFormatter(eq.toDate())} bis ${dateFormatter(
                to.toDate()
              )}`,
              value: {
                type: "range",
                from: eq.toDate(),
                to: to.toDate(),
              },
              labelAsString: `${dateEntry1.text} von bis ${typeLabel}`,
              onSelect,
              typeLabel,
              searchKeys: [...(additionalKeys || [])],
            });
          } else {
            options.push({
              filterKey,
              valueKey: `_${filterKey}_eq`,
              label: `am ${dateFormatter(eq.toDate())}`,
              value: {
                type: "equals",
                value: eq.toDate(),
              },
              labelAsString: `${dateEntry1.text} am ${typeLabel}`,
              onSelect,
              typeLabel,
              searchKeys: [...(additionalKeys || [])],
            });
          }
        }
      }
    }
  }
  return options;
};

export const DefaultDateSelectionValue: DateSelectionValue = {
  type: "range",
};

export const generateLabelForDateFilter = (
  filter: DateSelectionValue,
  formatter: (value: Date) => string = (value: Date) => value.toString()
) => {
  let label = null;
  if (filter) {
    if (filter.type === "range") {
      if (hasValue(filter.from) && hasValue(filter.to)) {
        label = `${formatter(filter.from)} - ${formatter(filter.to)}`;
      } else if (hasValue(filter.from)) {
        label = `ab ${formatter(filter.from)}`;
      } else if (hasValue(filter.to)) {
        label = `bis ${formatter(filter.to)}`;
      }
    } else if (filter.type === "equals") {
      if (hasValue(filter.value)) {
        label = `am ${formatter(filter.value)}`;
      }
    }
  }
  return label;
};
export const generateMatchQueryForDateFilter = (
  fieldName: string,
  filter: DateSelectionValue
) => {
  let matchQuery: MatchQuery = null;
  if (filter) {
    if (filter.type === "range") {
      if (hasValue(filter.from) && hasValue(filter.to)) {
        matchQuery = {
          type: "and",
          query: [
            {
              type: "op",
              op: "gte",
              name: fieldName,
              value: moment(filter.from).utc(true).startOf("day").toISOString(),
            },
            {
              type: "op",
              op: "lte",
              name: fieldName,
              value: moment(filter.to).utc(true).endOf("day").toISOString(),
            },
          ],
        };
      } else if (hasValue(filter.from)) {
        matchQuery = {
          type: "op",
          op: "gte",
          name: fieldName,
          value: moment(filter.from).utc(true).startOf("day").toISOString(),
        };
      } else if (hasValue(filter.to)) {
        matchQuery = {
          type: "op",
          op: "lte",
          name: fieldName,
          value: moment(filter.to).utc(true).endOf("day").toISOString(),
        };
      }
    } else if (filter.type === "equals") {
      if (hasValue(filter.value)) {
        matchQuery = {
          type: "and",
          query: [
            {
              type: "op",
              op: "gte",
              name: fieldName,
              value: moment(filter.value)
                .utc(true)
                .startOf("day")
                .toISOString(),
            },
            {
              type: "op",
              op: "lte",
              name: fieldName,
              value: moment(filter.value).utc(true).endOf("day").toISOString(),
            },
          ],
        };
      }
    }
  }
  return matchQuery;
};
const OverlayDateSelection = (props: OverlayDateSelectionProps) => {
  const type = props.value?.type || "range";
  const changeType = (newType: "range" | "equals") => {
    if (newType === "range") {
      props.onChange({
        type: "range",
        from:
          type === "equals"
            ? (props.value as DateSelectionValueEquals).value
            : null,
        to:
          type === "equals"
            ? (props.value as DateSelectionValueEquals).value
            : null,
      });
    } else if (newType === "equals") {
      props.onChange({
        type: "equals",
        value:
          type === "range"
            ? (props.value as DateSelectionValueRange).from
            : null,
      });
    }
  };
  return (
    <div className={classNames(`overlay-date-selection`)}>
      <OverlayTitle title={props.title} onClear={() => props.onChange(null)} />

      <Nav activeKey={type} onSelect={changeType} appearance="subtle">
        <Nav.Item eventKey="range">
          {i18n.t("BFFilterbar.Date.between", "Zwischen")}
        </Nav.Item>
        <Nav.Item eventKey="equals">
          {i18n.t("BFFilterbar.Date.equals", "Gleich")}
        </Nav.Item>
      </Nav>
      <div className="content">
        {type === "range" && (
          <div className="range">
            <div className="from">
              <BFDatefield
                asDropdown
                label="Ab"
                // {...props.inputProps}
                value={(props.value as DateSelectionValueRange)?.from}
                onChange={(value: Date) =>
                  props.onChange({
                    ...((props.value || {}) as DateSelectionValueRange),
                    from: value,
                  })
                }
              />
            </div>
            <div className="to">
              <BFDatefield
                asDropdown
                label="Bis"
                // {...props.inputProps}
                value={(props.value as DateSelectionValueRange)?.to}
                onChange={(value: Date) =>
                  props.onChange({
                    ...((props.value || {}) as DateSelectionValueRange),
                    to: value,
                  })
                }
              />
            </div>

            <div className={`quick-actions`}>
              <div className={`row`}>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() => {
                    props.onChange({
                      type: "range",
                      from: moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .toDate(),
                      to: moment().subtract(1, "month").endOf("month").toDate(),
                    });
                  }}
                >
                  {i18n.t("BFFilterbar.Date.lastMonth", "Letzter Monat")}
                </BFButton>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() => {
                    props.onChange({
                      type: "range",
                      from: moment().startOf("month").toDate(),
                      to: moment().endOf("month").toDate(),
                    });
                  }}
                >
                  {i18n.t("BFFilterbar.Date.currentMonth", "Aktueller Monat")}
                </BFButton>
              </div>

              <div className={`row`}>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() => {
                    props.onChange({
                      type: "range",
                      from: moment()
                        .subtract(1, "year")
                        .startOf("year")
                        .toDate(),
                      to: moment().subtract(1, "year").endOf("year").toDate(),
                    });
                  }}
                >
                  {i18n.t("BFFilterbar.Date.lastYear", "Letztes Jahr")}
                </BFButton>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() => {
                    props.onChange({
                      type: "range",
                      from: moment().startOf("year").toDate(),
                      to: moment().endOf("year").toDate(),
                    });
                  }}
                >
                  {i18n.t("BFFilterbar.Date.currentYear", "Aktuelles Jahr")}
                </BFButton>
              </div>
            </div>
          </div>
        )}
        {type === "equals" && (
          <div className="equals">
            <div className="value">
              <BFDatefield
                asDropdown
                // {...props.inputProps}
                label="Am"
                value={(props.value as DateSelectionValueEquals)?.value}
                onChange={(value: Date) =>
                  props.onChange({
                    ...((props.value || {}) as DateSelectionValueEquals),
                    value: value,
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
      {/* <div className="footer"> */}
      {/* <BFCheckbox
          size="xs"
          onChange={(_value, checked, ev) => {
            props.onChange({
              ...props.value,
              ignoreDecimals: checked,
            });
          }}
        >
          Dezimalstellen ignorieren
        </BFCheckbox> */}
      {/* <BFButton
          appearance="link"
          onClick={() => props.onChange(props.entries.map((e) => e.value))}
        >
          Alle auswählen
        </BFButton>
        <BFButton appearance="link" onClick={() => props.onChange([])}>
          Alle abwählen
        </BFButton> */}
      {/* </div> */}
    </div>
  );
};

export default OverlayDateSelection;
