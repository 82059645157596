import { ActivityAbstractStructClass } from "@/redux/actions/struct/implemented/ActivityAbstractStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import classNames from "classnames";
import moment from "moment";
import i18n from "../../../../../../i18n";
import BFStatus from "../../../../../../modules/abstract-ui/data/status/BFStatus";
import LanguageService from "../../../../../../services/LanguageService";
import StringUtils from "../../../../../../utils/StringUtils";
import { useActivityConstants } from "../../../ActivityHooks";
import { APActivity } from "../../../ActivityInterfaces";
import { getActivityPriorityOptions } from "../../../ActivityUtils";
import "./APDashboardActivityEntry.scss";

interface Props {
  activity: APActivity;
  forceEstimatedCosts?: boolean;
  activityStruct: ActivityAbstractStructClass<any>;
}
const APDashboardActivityEntry = (props: Props) => {
  const status = props.activityStruct.getStatusOf(props.activity);
  const constants = useActivityConstants();

  const prio = getActivityPriorityOptions().find(
    (e) => e.value === props.activity.data.priority
  );

  return (
    <div className={classNames(`ap-dashboard-activity-entry`)}>
      <div className={`infos`}>
        <span className={`activity-id`}>{props.activity.data.activityId}</span>
        <span className={`entity`}>
          {OrgaStruct.getEntity(props.activity.data.entity)?.displayName}
          {props.activity.data.objectId && ","}
        </span>
        {props.activity.data.objectId && (
          <span className={`object`}>{`${
            OrgaStruct.getObject(props.activity.data.objectId)?.id
          } - ${
            OrgaStruct.getObject(props.activity.data.objectId)?.displayName
          }`}</span>
        )}
      </div>
      <div className={`name-container`}>
        <div className={`name`}>{props.activity.data.displayName}</div>
        {props.activity.data?.extra?.sumInvoiceValue &&
        props.forceEstimatedCosts ? (
          <div className={`value-entry`}>
            <span>
              {i18n.t(
                "apTemplate:dashboard.activity.sumInvoiceValue",
                "Angebotsbetrag",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              )}
              :{" "}
            </span>
            {StringUtils.formatCurrency(
              props.activity.data.extra?.sumInvoiceValue
            )}
          </div>
        ) : props.activity.data.estimatedCosts ? (
          <div className={`value-entry`}>
            <span>
              {i18n.t(
                "apTemplate:dashboard.activity.estimatedValue",
                "Geschätzter Betrag",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              )}
              :{" "}
            </span>
            {StringUtils.formatCurrency(props.activity.data.estimatedCosts)}
          </div>
        ) : null}
      </div>
      <div className={`status`}>
        <div className={`field`}>
          {status && (
            <BFStatus
              size="xxs"
              color={status?.color}
              label={LanguageService.translateLabel(status?.displayName)}
              icon={status?.icon}
            />
          )}
        </div>
        <div className={`field`}>
          {prio && (
            <BFStatus
              size="xxs"
              color={prio?.color}
              label={prio?.label}
              icon={prio?.icon}
            />
          )}
        </div>
        {props.activity.data.dueDate && (
          <div className={`field`}>
            <BFStatus
              size="xs"
              label={moment(props.activity.data.dueDate).format(
                i18n.t("Formats.dateFormat")
              )}
            />
          </div>
        )}
        <div className={`creation-date`}>
          {moment(props.activity.data.creationDate).format(
            i18n.t("Formats.dateFormat")
          )}
        </div>
      </div>
    </div>
  );
};

export default APDashboardActivityEntry;
