import classNames from "classnames";
import Userlabel from "../../../../../../components/AvatarComponent/Userlabel";
import ModalManager from "../../../../../../components/ModalComponent/ModalManager";
import WrappedText from "../../../../../../components/WrappedText/WrappedText";
import DebugDataComponent from "../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../i18n";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../../../../../modules/abstract-ui/icon/BfIcon";
import { isDefined } from "../../../../../../utils/Helpers";
import StringUtils from "../../../../../../utils/StringUtils";
import { APProjectBudget } from "../project-budget/APProjectBudgetInterfaces";
import APConstructionDiariesForm from "./APConstructionDiariesForm";
import {
  getConstructionDiaryDocumentationTypeLabel,
  getConstructionDiaryStatusLabel,
  getConstructionDiaryTemperatureUnit,
  getConstructionDiaryTypeLabel,
} from "./APConstructionDiariesHelpers";
import {
  APProjectConstructionDiary,
  ConstructionDiaryDocumentationType,
} from "./APConstructionDiariesInterfaces";
import "./APConstructionDiaryCard.scss";
import APConstructionDiaryEntryRow from "./APConstructionDiaryEntryRow";

interface APConstructionDiaryCardProps {
  diary: APProjectConstructionDiary;
  budget?: APProjectBudget;
}
const APConstructionDiaryCard = (props: APConstructionDiaryCardProps) => {
  return (
    <div className={classNames(`ap-construction-diary-card __card`)}>
      <DebugDataComponent data={props.diary} />
      <div className={`head-line`}>
        <div className={`date`}>
          {StringUtils.formatDate(props.diary.data.entryDate)}
        </div>
        <div className={`fill`} />
        <div className={`diary-type`}>
          {getConstructionDiaryTypeLabel(props.diary.data.diaryType)}
        </div>
        <div className={`fill`} />
        <div className={`status`}>
          {getConstructionDiaryStatusLabel(props.diary.data.status)}
        </div>
        <div className={`user`}>
          <Userlabel id={props.diary.data.author} />
        </div>
      </div>
      {(isDefined(props.diary.data.weather?.rain) ||
        isDefined(props.diary.data.weather?.temperature?.value) ||
        isDefined(props.diary.data.weather?.wind)) && (
        <div className={`weather-row`}>
          {isDefined(props.diary.data.weather?.temperature?.value) && (
            <div className={`weather-item`}>
              <div className={`icon`}>
                <BfIcon type="light" data="temperature-thermometer" size="xs" />
              </div>
              {props.diary.data.weather.temperature.value}{" "}
              {getConstructionDiaryTemperatureUnit(
                props.diary.data.weather.temperature.unit
              )}
            </div>
          )}
          {isDefined(props.diary.data.weather?.rain) && (
            <div className={`weather-item`}>
              <div className={`icon`}>
                <BfIcon
                  type="light"
                  data="weather-cloud-rain-drops"
                  size="xs"
                />
              </div>
              {props.diary.data.weather.rain}{" "}
              {i18n.t("apTemplate:ConstructionDiary.RainUnit", "mm")}
            </div>
          )}
          {isDefined(props.diary.data.weather?.wind) && (
            <div className={`weather-item`}>
              <div className={`icon`}>
                <BfIcon type="light" data="weather-wind-flow-3" size="xs" />
              </div>
              {props.diary.data.weather.wind}{" "}
              {i18n.t("apTemplate:ConstructionDiary.WindUnit", "km/h")}
            </div>
          )}
        </div>
      )}
      {props.diary.data.notes && (
        <div className={`section`}>
          <div className={`section-title`}>
            {i18n.t("apTemplate:ConstructionDiary.Notes", "Bemerkung")}
          </div>
          <div className={`section-content`}>
            <div className={`padding-10`}>
              <WrappedText text={props.diary.data.notes} />
            </div>
          </div>
        </div>
      )}
      {["observations", "workProgress", "defects"]

        .map((selector) => ({
          selector,
          data: props.diary.data.entries
            ?.filter((e) => e.documentationType === selector)
            .filter((e) => e.status === "active"),
        }))
        .filter((e) => e.data?.length > 0)
        .map((e) => (
          <div className={`section`} id={e.selector} key={e.selector}>
            <div className={`section-title`}>
              {getConstructionDiaryDocumentationTypeLabel(
                e.selector as ConstructionDiaryDocumentationType
              )}
            </div>
            <div className={`section-content`}>
              {e.data?.map((entry) => (
                <APConstructionDiaryEntryRow
                  diary={props.diary}
                  entry={entry}
                  budget={props.budget}
                  key={entry._id}
                />
              ))}
            </div>
          </div>
        ))}

      <div className={`actions`}>
        {/* <BFButton appearance="link" onClick={() => {}}>
          {i18n.t("Global.Buttons.archive", "Archivieren")}
        </BFButton> */}
        <div className={`fill`} />
        <BFButton
          appearance="link"
          onClick={() => {
            ModalManager.show({
              noPadding: true,
              size: "md",
              content: (states, setStates, onClose) => (
                <APConstructionDiariesForm
                  diary={props.diary}
                  projectId={props.diary.data.projectId}
                  onClose={onClose}
                  budget={props.budget}
                />
              ),
            });
          }}
        >
          {i18n.t("Global.Buttons.edit", "Bearbeiten")}
        </BFButton>
      </div>
    </div>
  );
};

export default APConstructionDiaryCard;
