import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import Log from "./debug/Log";
import { SET_UI_CONFIG } from "./redux/actions/ui-config/ui-config-actions-types";
import { DefaultUIConfigs } from "./redux/reducers/ui-config/UiConfig";
// import { store } from "./redux/store";
import { TOptions } from "i18next";
import { store } from "./redux/store";
import DataBusDefaults from "./services/DataBusDefaults";
import OverwriteService from "./services/OverwriteService";
import { Properties } from "./utils/Properties";
import StorageUtils from "./utils/StorageUtils";

// const resources = {
//   de: localeDE,
//   en: localeEN,
// };

const fixedNamespacesToLoad = ["common", "at", "dashboard"];
const LSTORAGE_SELECTED = "selectedLanguage";
const language = StorageUtils.LocalStorage.get(LSTORAGE_SELECTED) as string;
export const i18nOptions = {
  // resources,
  lng: language,
  fallbackLng: "en",
  debug: false,
  // debug: process.env.NODE_ENV !== "production",
  saveMissing: false,
  defaultNS: "common",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  // special options for react-i18next
  // learn more: https://react.i18next.com/components/i18next-instance
  react: {
    wait: true,
    useSuspense: false,
  },
};

i18n.use(LanguageDetector);

(i18n as any).translate = i18n.t;

const translationsDone: { [key: string]: string } = {};

i18n.t = function (
  key: string,
  defaultValue?: TOptions<any> | string,
  options?: (TOptions<any> & { unit?: string }) | string
) {
  const useOptions = defaultValue
    ? typeof defaultValue === "object"
      ? defaultValue
      : options
    : undefined;

  const overwrite = OverwriteService.getTranslation(key, {
    language: useOptions?.lng || language,
    type: useOptions?.unit || null,
  });
  let result;
  if (overwrite) {
    result = overwrite;
  } else if (
    key &&
    key.indexOf(":") > -1 &&
    useOptions?.ns &&
    useOptions.ns !== "common" &&
    useOptions?.ns?.[0] !== "common"
  ) {
    result = (i18n as any).translate(
      key.split(":")[1],
      defaultValue,
      useOptions
    );
  } else {
    result = (i18n as any).translate(key, defaultValue, options);
  }
  if (Properties.translateDebug) {
    if (!translationsDone[key]) {
      translationsDone[key] = result;
      Log.info("## Translation: ", key, " = ", result);
    }
  }

  return result;
};
export default i18n;

export const loadLanguagePackageByLanguage = async (
  language: string,
  namespace = "common"
) => {
  fetch(`/locales/${language}/${namespace}.json`)
    .then(async (response) => {
      const useNamespace =
        namespace.indexOf("/") > -1 ? namespace.split("/")[1] : namespace;

      // const mandatorSpecificStuff =
      //   store.getState().global.mandator?.localization?.[language]?.[
      //     useNamespace
      //   ] || {};
      const languageLoaded = await response.json();
      // const merged = _.merge(languageLoaded, mandatorSpecificStuff);
      i18n.addResourceBundle(language, useNamespace, languageLoaded);
    })
    .catch((err) => {
      Log.error(err);
      DataBusDefaults.toast({
        type: "error",
        text: "Error loading language",
      });
    });
};

export const loadLanguagePackage = async (namespace: string) => {
  await loadLanguagePackageByLanguage(i18n.language, namespace);
};

export const setLanguage = async (language: string) => {
  // store.dispatch(setUiConfig(DefaultUIConfigs.SELECTED_LANGUAGE, language));
  store.dispatch({
    type: SET_UI_CONFIG,
    key: DefaultUIConfigs.SELECTED_LANGUAGE,
    value: language,
  });
  moment.locale(language);
  for (const namespace of fixedNamespacesToLoad) {
    await loadLanguagePackageByLanguage(language, namespace);
  }
  i18n.changeLanguage(language);

  document.querySelector("html")?.setAttribute("lang", language);
};
export const changeLanguage = async (lang: string) => {
  const currentLang = i18n.language;

  // if (currentLang) {
  //   const bundles = Object.keys((i18n as any).store.data[currentLang]);

  //   for (const bundle of bundles) {
  //     await loadLanguagePackageByLanguage(lang, bundle);
  //   }
  // }

  store.dispatch({
    type: SET_UI_CONFIG,
    key: DefaultUIConfigs.SELECTED_LANGUAGE,
    value: lang,
  });
  // store.dispatch(setUiConfig(, lang));
  StorageUtils.LocalStorage.save(LSTORAGE_SELECTED, lang);

  window.location.reload();
};

setLanguage(language || "de");
