import { getFeaturesForKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import StructLoader from "@/components/StructLoader/StructLoader";
import i18n from "@/i18n";
import {
  Application,
  PermissionConfigObjectKind,
} from "@/model/db/Application";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import LanguageService from "@/services/LanguageService";
import classNames from "classnames";
import Collapse from "rsuite/esm/Animation/Collapse";
import BFButton from "../../general/Button/BFButton";
import BFCheckbox from "../checkbox/BFCheckbox";
import "./BFObjectKindPermission.scss";

export type KindPermission = {
  id: string;
  label: string;
  types?: string[];
};
const KindPermissions: () => { [key: string]: KindPermission[] } = () => ({
  default: [
    {
      id: "asset",
      label: i18n.t("BFObjectKindPermission.objectKind.asset", "Asset"),
      types: ["createEdit", "export", "groups"],
    },
    {
      id: "lq-plan",
      label: i18n.t("BFObjectKindPermission.objectKind.lq-plan", "LQ-Plan"),
      types: ["view"],
    },
  ],
  immo: [
    {
      id: "rental.units",
      label: i18n.t(
        "BFObjectKindPermission.objectKind.immo.rental-units",
        "Mieteinheiten"
      ),
      types: ["createEdit"],
    },
    {
      id: "rental.vacancy",
      label: i18n.t(
        "BFObjectKindPermission.objectKind.immo.rental-vacancy",
        "Leerstände"
      ),
      types: ["createEdit"],
    },
    {
      id: "rental.agreements",
      label: i18n.t(
        "BFObjectKindPermission.objectKind.immo.rental-agreements",
        "Mietverträge"
      ),
      types: ["createEdit"],
    },
    {
      id: "rental.tenant",
      label: i18n.t(
        "BFObjectKindPermission.objectKind.immo.rental-tenant",
        "Mieter"
      ),
      types: ["createEdit"],
    },
    {
      id: "rental.opos",
      label: i18n.t(
        "BFObjectKindPermission.objectKind.immo.rental-opos",
        "Rückstände"
      ),
      types: ["createEdit", "view"],
    },
    {
      id: "risk-management",
      label: i18n.t(
        "BFObjectKindPermission.objectKind.immo.risk-management",
        "Risikomanagement"
      ),
      types: ["createEdit", "view"],
    },
  ],
  //   communication: [
  //     {
  //       id: "communication",
  //       label: i18n.t(
  //         "BFObjectKindPermission.objectKind.communication",
  //         "Kommunikation"
  //       ),
  //       types: ["view", "create"],
  //     },
  //   ],
  assetValue: [
    {
      id: "assetValue",
      label: i18n.t(
        "BFObjectKindPermission.objectKind.assetValue",
        "Wert des Assets"
      ),
      types: ["createEdit", "view"],
    },
  ],
  loanable: [
    {
      id: "loanable",
      label: i18n.t(
        "BFObjectKindPermission.objectKind.loanable",
        "Darlehen des Assets"
      ),
      types: ["createEdit", "view"],
    },
  ],
  purchasePrice: [
    {
      id: "purchasePrice",
      label: i18n.t(
        "BFObjectKindPermission.objectKind.purchasePrice",
        "Kaufpreis des Asssets"
      ),
      types: ["createEdit", "view"],
    },
  ],
});
export type ObjectKindPermission = {
  objectKindId: string;
  permissions: string[];
};
interface BFObjectKindPermissionProps {
  config: PermissionConfigObjectKind;
  value: ObjectKindPermission[];
  onChange: (value: ObjectKindPermission[]) => void;
  app: Application;
  readonly?: boolean;
  units: string[];
}
const BFObjectKindPermission = (props: BFObjectKindPermissionProps) => {
  const value = props.value || [];
  return (
    <StructLoader
      unitTypes={props.units}
      structTypes={["objectKind", "unit"]}
      render={() => (
        <div className={classNames(`bf-object-kind-permission`)}>
          {ObjectKindStruct.getKinds(props.units)
            .filter((e) =>
              props.readonly
                ? value.some((v) => v.objectKindId === e._id)
                : true
            )
            .map((kind) => (
              <div className={`object-kind-entry`}>
                <div className={`entry-head`}>
                  <BFCheckbox
                    //   size="lg"
                    disabled={props.readonly}
                    checked={value.some((e) => e.objectKindId === kind._id)}
                    onChange={(_, checked) => {
                      props.onChange(
                        checked
                          ? [
                              ...value,
                              { objectKindId: kind._id, permissions: [] },
                            ]
                          : value.filter((e) => e.objectKindId !== kind._id)
                      );
                    }}
                  >
                    <>
                      <span className={`unit-label`}>
                        {UnitStruct.getUnitLabel(kind.data.type)}
                      </span>
                      {LanguageService.translateLabel(kind.data.displayName)}
                    </>
                  </BFCheckbox>
                </div>
                <Collapse in={value.some((e) => e.objectKindId === kind._id)}>
                  <div className={`entry-body`}>
                    {["default", ...getFeaturesForKind(kind)]
                      .map((type) => (KindPermissions()[type] || []).flat())
                      .flat()
                      .map((permission) => {
                        const renderCheckbox = (
                          permissionKey: string,
                          label: string
                        ) => (
                          <BFCheckbox
                            disabled={props.readonly}
                            // size="lg"
                            onChange={(_, checked) => {
                              if (checked) {
                                props.onChange(
                                  value.map((e) =>
                                    e.objectKindId === kind._id
                                      ? {
                                          ...e,
                                          permissions: [
                                            ...e.permissions,
                                            permissionKey,
                                          ],
                                        }
                                      : e
                                  )
                                );
                              } else {
                                props.onChange(
                                  value.map((e) =>
                                    e.objectKindId === kind._id
                                      ? {
                                          ...e,
                                          permissions: e.permissions.filter(
                                            (e) => e !== permissionKey
                                          ),
                                        }
                                      : e
                                  )
                                );
                              }
                            }}
                            checked={value
                              .find((e) => e.objectKindId === kind._id)
                              ?.permissions.includes(permissionKey)}
                          >
                            {label}
                          </BFCheckbox>
                        );

                        return (
                          <>
                            {(permission.types || []).length === 0 && (
                              <div
                                className={`permission-entry single-checkbox`}
                              >
                                {renderCheckbox(
                                  permission.id,
                                  permission.label
                                )}
                              </div>
                            )}
                            {(permission.types || []).length > 0 && (
                              <div
                                className={`permission-entry multipe-checkboxes`}
                              >
                                <div className={`permission-entry-label`}>
                                  {permission.label}
                                </div>
                                {(permission.types || []).map((type) => (
                                  // i18n.t("BFObjectKindPermission.permissions.type.create", "Erstellen")
                                  // i18n.t("BFObjectKindPermission.permissions.type.edit", "Bearbeiten")
                                  // i18n.t("BFObjectKindPermission.permissions.type.createEdit", "Erstellen & Bearbeiten")
                                  // i18n.t("BFObjectKindPermission.permissions.type.view", "Sehen")export
                                  // i18n.t("BFObjectKindPermission.permissions.type.export", "Exportieren")
                                  // i18n.t("BFObjectKindPermission.permissions.type.groups", "Gruppen")
                                  <div>
                                    {renderCheckbox(
                                      permission.id + "." + type,
                                      i18n.t(
                                        "BFObjectKindPermission.permissions.type." +
                                          type
                                      )
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        );
                      })}

                    {!props.readonly && (
                      <div className={`quick-actions`}>
                        <BFButton
                          appearance="link"
                          size="xs"
                          onClick={() => {
                            const features = [
                              "default",
                              ...getFeaturesForKind(kind),
                            ]
                              .map((type) =>
                                (KindPermissions()[type] || []).flat()
                              )
                              .flat();
                            const permissions = features
                              .map((feature) =>
                                (feature.types || []).length === 0
                                  ? [feature.id]
                                  : (feature.types || []).map(
                                      (type) => feature.id + "." + type
                                    )
                              )
                              .flat();

                            props.onChange(
                              value.map((e) =>
                                e.objectKindId === kind._id
                                  ? {
                                      ...e,
                                      permissions: permissions,
                                    }
                                  : e
                              )
                            );
                          }}
                        >
                          {i18n.t(
                            "BFObjectKindPermission.permissions.selectAll",
                            "Alle auswählen"
                          )}
                        </BFButton>
                        <BFButton
                          appearance="link"
                          size="xs"
                          onClick={() => {
                            props.onChange(
                              value.map((e) =>
                                e.objectKindId === kind._id
                                  ? {
                                      ...e,
                                      permissions: [],
                                    }
                                  : e
                              )
                            );
                          }}
                        >
                          {i18n.t(
                            "BFObjectKindPermission.permissions.selectAll",
                            "Alle abwählen"
                          )}
                        </BFButton>
                      </div>
                    )}
                  </div>
                </Collapse>
              </div>
            ))}
        </div>
      )}
    />
  );
};

export default BFObjectKindPermission;
