import { isDefined } from "@/utils/Helpers";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import i18n from "../../../../i18n";
import { openOrDownloadDocument } from "../../../../redux/actions/ui-config/ui-config-actions";
import CDNService from "../../../../services/CDNService";
import DataBus from "../../../../services/DataBus";
import { DataBusSubKeys } from "../../../../utils/Constants";
import FileUtils from "../../../../utils/FileUtils";
import { ExistentUploadEntry, UploadField } from "../../forms/upload/BFUpload";
import BfIcon from "../../icon/BfIcon";
import "./CDNFileViewer.scss";

export const downloadCDNFile = (
  assetId: string,
  assetField: string,
  assetType: string,
  fileKey: string,
  filename: string,
  dispatch
) => {
  CDNService.fetchCDNLink({
    assetField,
    assetId,
    assetType,
    fileKey,
    hasFolderReadPermissions: true,
  })
    .then((url) => {
      dispatch(
        openOrDownloadDocument(url, FileUtils.getFiletype(filename), filename)
      );
    })
    .catch((err) => {
      DataBus.emit(DataBusSubKeys.TOAST_CREATE, {});
    });
};
const getIconType = (filename: string) => {
  const filetype = FileUtils.getFiletype(filename);
  switch (filetype.toLowerCase()) {
    case "pdf":
      return "office-file-pdf-1";
    case "doc":
    case "docx":
    case "docm":
      return "office-file-doc-1";
    case "zip":
    case "rar":
    case "7zip":
      return "zip-file-2";
    case "jpg":
    case "jpeg":
    case "gif":
    case "png":
    case "tif":
      return "image-file-landscape";
    case "xls":
    case "xlsx":
    case "xlsm":
      return "office-file-xls-1";
    case "ppt":
    case "pptx":
    case "pptm":
      return "office-file-ppt-1";
    default:
      return "common-file-question";
  }
};

interface Props {
  assetType: string;
  assetId: string;
  assetField: string;
  files: UploadField;
  emptyText?: string;
  onDelete?: (id: string) => void;
}
const CDNFileViewer = (props: Props) => {
  const dispatch = useDispatch();
  const entries = Object.entries(props.files || {}).filter((e) =>
    isDefined(e[1])
  );

  return (
    <div className="cdn-file-viewer">
      {entries.length === 0 && (
        <div className="empty-text">
          {props.emptyText ||
            i18n.t("CDNFileViewer.noData", "Keine Dateien verfügbar")}
        </div>
      )}
      {entries.map(([cdnId, cdnData]) => (
        <CDNFileEntry
          cdnId={cdnId}
          data={cdnData}
          onDownload={() => {
            downloadCDNFile(
              props.assetId,
              props.assetField,
              props.assetType,
              cdnData.key,
              cdnData.filename,
              dispatch
            );
          }}
        />
      ))}
    </div>
  );
};
export default CDNFileViewer;

interface FileProps {
  onDownload: () => void;
  cdnId: string;
  data: ExistentUploadEntry;
  onDelete?: (id: string) => void;
  className?: string;
  customContent?: React.ReactNode;
}
export const CDNFileEntry = (props: FileProps) => {
  return (
    <div className={classNames("cdn-file-entry", props.className)}>
      <button onClick={() => props.onDownload()}>
        <div className={`filetype`}>
          <BfIcon data={getIconType(props.data?.filename)} type={"bf"} />
        </div>
        <div className={`name`}>
          {props.customContent ? (
            props.customContent
          ) : (
            <span title={props.data.filename}>{props.data?.filename}</span>
          )}
        </div>
      </button>
      {/* <div className={`actions`}>
        <BFButton
          loading={obj._deleting}
          size="xs"
          appearance={"clear-on-white"}
          icon={{ type: "bf", data: "close" }}
          onClick={() => this.unlinkAttachment(id)}
        />
      </div> */}
    </div>
  );
};
