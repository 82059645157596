import { withTranslation, WithTranslation } from "react-i18next";
import { Route, RouteComponentProps, withRouter } from "react-router";
import { Modal } from "rsuite";
import { AppState } from "../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../utils/abstracts/AbstractStylableComponent";
import "./ModalRouteView.scss";

type Props = {
  routeName: string;
  titleKey?: string;
  titleCondition?: string;
  overflow?: boolean;
  backdrop?: boolean | "static";
  size?: "lg" | "md" | "sm" | "xs" | "full" | "max";
  keyboard?: boolean;
  className?: string;
  hideHeader?: boolean;
  closable?: boolean;
  onEntering?: () => void;
  onClosing?: () => void;
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;
type States = {
  active: boolean;
} & AbstractStylableStates;

class ModalRouteView extends AbstractStylableComponent<Props, States> {
  static defaultProps = {
    backdrop: true,
  };
  readonly state: States = {
    active: false,
  };

  root;

  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {
    this.root = document.querySelector("#root");
    const isProfileOpen =
      this.props.location.pathname.indexOf("/" + this.props.routeName) !== -1;
    if (isProfileOpen) {
      this.setState({ active: true });
      this.root.setAttribute("inert", "");
    }
  }

  componentWillUnmount(): void {
    this.root.removeAttribute("inert");
  }
  componentDidUpdate(prevProps: Props) {
    const isProfileOpen =
      this.props.location.pathname.indexOf("/" + this.props.routeName) !== -1;
    const wasProfileOpen =
      prevProps.location.pathname.indexOf("/" + this.props.routeName) !== -1;

    if (isProfileOpen && !wasProfileOpen) {
      this.setState({ active: true });
      this.root.setAttribute("inert", "");
    } else if (!isProfileOpen && wasProfileOpen) {
      this.setState({ active: false });
      this.root.removeAttribute("inert");
    }
  }

  closeModal() {
    this.props.history.push(
      this.props.location.pathname.split("/" + this.props.routeName)[0]
    );
    if (this.props.onClosing) {
      this.props.onClosing();
    }
  }

  render() {
    const title = this.props.titleCondition
      ? this.evaluateExpression(this.props.titleCondition, {
          default: this.props.i18n.t(this.props.titleKey),
        })
      : this.props.i18n.t(this.props.titleKey);
    return (
      <Route
        path={
          this.props.location.pathname.indexOf("/" + this.props.routeName) ===
          -1
            ? this.props.location.pathname
            : this.props.location.pathname.substr(
                0,
                this.props.location.pathname.indexOf("/" + this.props.routeName)
              ) + "/:modalId"
        }
      >
        <Modal
          className={`view-modal-route ${
            this.props.className ? this.props.className : ""
          } ${this.props.size === "max" ? "max" : ""}`}
          keyboard={
            this.props.keyboard === undefined ? false : this.props.keyboard
          }
          size={this.props.size !== "max" ? this.props.size : undefined}
          backdrop={this.props.backdrop}
          overflow={this.props.overflow}
          open={this.state.active}
          onEntering={() =>
            this.props.onEntering ? this.props.onEntering() : undefined
          }
          onClose={() => this.closeModal()}
        >
          {!this.props.hideHeader ? (
            <Modal.Header closeButton={this.props.closable}>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
          ) : null}
          <Modal.Body>{this.props.children}</Modal.Body>
        </Modal>
      </Route>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});
// const mapDispatchToProps = (dispatch: ThunkDispatch<{},{},any>, ownProps:any) => ({
//     setUserData: (user: User, apps: Application[], permissions: Permission[]) => { dispatch(setUserData(user, apps, permissions)) }
// })

export default withTranslation()(withRouter(ModalRouteView));
