import classNames from "classnames";
import md5 from "md5";
import { useEffect, useState } from "react";
import ListEntryComponent from "../../../../configurable/components/ListComponent/ListEntryComponent";
import i18n from "../../../../i18n";
import { Contact } from "../../../../model/db/Contact";
import { useTypedSelector } from "../../../../redux/hooks";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import MQ from "../../../../utils/MatchQueryUtils";
import { TagInputEntry } from "../../../abstract-ui/data/tag-input/BFTagInput";
import BFTagInputSearch from "../../../abstract-ui/data/tag-input/BFTagInputSearch";
import BfIcon from "../../../abstract-ui/icon/BfIcon";
import ValidatorsUtils from "../../../generic-forms/util/ValidatorsUtils";
import { EmailUser } from "../../Comments";
import "./MailContactTagInputSearch.scss";

interface MailContactTagInputSearchProps {
  mailContacts: EmailUser[];
  onAddMailAddress: (newMailAddress: EmailUser) => void;
  onRemoveMailAddress: (removedMailAddress: string) => void;
  noBorder?: boolean;
  type: string;
  preselectFirstFoundMail?: boolean;
}

export const CONTACT_MAIL_ADDRESS_SEARCH = "mail-address-search";

const MailContactTagInputSearch = (props: MailContactTagInputSearchProps) => {
  const initialResultIndex = props.preselectFirstFoundMail ? 1 : 0;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const results = useTypedSelector(
    (state) => state.application.infiniteTables[CONTACT_MAIL_ADDRESS_SEARCH]
  );
  const [resultIndex, setResultIndex] = useState<number>(initialResultIndex);
  const allMails =
    results?.data
      ?.map((contact) => contact.data.emails)
      .map((e) => e.map((e) => e.email))
      .flat() || [];

  useEffect(() => {
    const mail = allMails[resultIndex - 1];
    if (!mail) {
      return;
    }
    const hash = md5(mail);
    document.querySelector(`#mailhash-${hash}`)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }, [resultIndex]);

  const selectedResult: { contact: Contact; email: string } =
    getNthMailFromResults(results?.data, resultIndex);

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (results?.data?.length === 0) {
      return false;
    }
    const keyCode = event.keyCode;
    if (keyCode === 13) {
      if (resultIndex === 0) {
        return false;
      }
      if (
        props.mailContacts
          .map((c) => c.address)
          .includes(allMails[resultIndex - 1])
      ) {
        DataBusDefaults.toast({
          type: "info",
          text: i18n.t(
            "MailContactTagInputSearch.Toast.MailAlreadyAdded",
            "Diese Mailadresse wurde bereits hinzugefügt."
          ),
        });
        return true;
      }
      if (selectedResult) {
        if (searchTerm.trim() === "") {
          return true;
        }

        const mailContact: EmailUser = {
          address: selectedResult.email,
          name: selectedResult.contact.data.displayName,
        };
        props.onAddMailAddress(mailContact);
        setSearchTerm("");
        setResultIndex(initialResultIndex);
        return true;
      }
    }

    if (keyCode === 38) {
      if (resultIndex > 0) {
        setResultIndex((prev) => prev - 1);
      }
      return true;
    }

    if (keyCode === 40) {
      if (resultIndex < allMails.length) {
        setResultIndex((prev) => prev + 1);
      }
      return true;
    }

    return false;
  };

  const onSetSearchTerm = (term: string) => {
    setSearchTerm(term);
    setResultIndex(initialResultIndex);
  };

  const onInputBlur = () => {
    const isValidMail = ValidatorsUtils.isEmail(searchTerm);
    if (isValidMail) {
      const mailContact: EmailUser = {
        address: searchTerm,
        name: "",
      };
      props.onAddMailAddress(mailContact);
      setSearchTerm("");
      setResultIndex(initialResultIndex);
    }
  };

  const tagEntries: TagInputEntry[] =
    props.mailContacts?.map((contact) => {
      const isMailValid = ValidatorsUtils.isEmail(contact.address);

      const tagEntry: TagInputEntry = {
        text: contact.address,
        tooltip: {
          tooltip:
            contact.name.trim() === ""
              ? i18n.t(
                  "MailContactTagInputSearch.unknownContactName",
                  "ohne Kontakt"
                )
              : contact.name,
          trigger: "hover",
          placement: "topStart",
        },
        errorMsg: isMailValid
          ? undefined
          : i18n.t(
              "MailContactTagInputSearch.Error.InvalidMail",
              "Invalid email address"
            ),
        color: isMailValid ? undefined : "error",
      };
      return tagEntry;
    }) || ([] as TagInputEntry[]);

  return (
    <div
      className={classNames("contact-search-tag-input", {
        "no-border": props.noBorder,
      })}
    >
      <BFTagInputSearch
        overwriteKeyDownEvent={handleInputKeyDown}
        debounceTime={500}
        searchTerm={searchTerm}
        onSearchTermChange={onSetSearchTerm}
        listRenderType="render-as-list"
        resultListIdentifier={`${CONTACT_MAIL_ADDRESS_SEARCH}`}
        searchUrl={`/api/asset/list/contact`}
        additionalMatchQuery={MQ.combine("and", [
          MQ.ne("data.emails", []),
          MQ.eq("data.type", props.type),
        ])}
        renderSearchResults={(nodes: Contact[]) => {
          // Filter contacts with no emails or all emails already added
          const filteredContacts = nodes.filter((contact) => {
            // does property exist
            if (!contact.data.emails) {
              return false;
            }
            return true;
          });

          return filteredContacts.map((contact) => {
            const contactDisplayName = contact.data.displayName;
            const iconName =
              contact.data.personType === "private"
                ? "user-multiple-half-female-male"
                : "factory-building-crane-arm";
            return (
              <div className="contact-search-result-entry" key={contact._id}>
                <div className="contact-name-container">
                  <BfIcon data={iconName} size="xs" type="light" />
                  <div className="contact-name">{contactDisplayName}</div>
                </div>
                {contact.data.emails.map((email, j) => {
                  const isMailAlreadyAdded = tagEntries
                    .map((tag) => tag.text)
                    .includes(email?.email);
                  const isEntrySelected =
                    contact._id === selectedResult?.contact._id &&
                    selectedResult.email === email?.email;
                  const hash = md5(email);

                  return (
                    <ListEntryComponent
                      key={`${contact._id}-${j}`}
                      isSelected={isEntrySelected}
                      className={classNames({
                        "mail-added": isMailAlreadyAdded,
                      })}
                      renderEntry={() => (
                        <div
                          className="mail-address-container"
                          id={`mailhash-${hash}`}
                        >
                          <BfIcon
                            size="xs"
                            type="light"
                            data={
                              isMailAlreadyAdded
                                ? "email-action-check"
                                : "email-action-unread"
                            }
                          />
                          <div className="mail-address">{email.email}</div>
                        </div>
                      )}
                      entryData={email.email}
                      onClickEntry={() => {
                        if (isMailAlreadyAdded) {
                          return;
                        }
                        const mailContact: EmailUser = {
                          address: email?.email,
                          name: contactDisplayName,
                        };
                        props.onAddMailAddress(mailContact);
                        onSetSearchTerm("");
                        setResultIndex(initialResultIndex);
                      }}
                    />
                  );
                })}
              </div>
            );
          });
        }}
        tags={tagEntries}
        onTagAdd={(newTag: string) => {
          const mailContact: EmailUser = {
            address: newTag,
            name: "",
          };
          props.onAddMailAddress(mailContact);
        }}
        onTagRemove={(removedTag: string) => {
          props.onRemoveMailAddress(removedTag);
        }}
        listDisableEndlessScroll={true}
        listLimitPerRequest={20}
        onInputBlur={onInputBlur}
      />
    </div>
  );
};

export default MailContactTagInputSearch;

const getNthMailFromResults = (
  contacts: Contact[],
  n: number
): { contact: Contact; email: string } => {
  if (!contacts || contacts.length === 0) {
    return undefined;
  }

  if (n < 1) {
    return undefined;
  }

  for (const contact of contacts) {
    if (n <= contact.data.emails.length) {
      return { contact, email: contact.data.emails[n - 1]?.email };
    } else {
      n -= contact.data.emails.length;
    }
  }
  return undefined;
};
