import classNames from "classnames";
import { nanoid } from "nanoid";
import { Field } from "react-final-form";
import FormStruct from "../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../i18n";
import BFCheckbox from "../../forms/checkbox/BFCheckbox";
import BFInput from "../../forms/input/BFInput";
import "./BFTableFilterCreate.scss";
import {
  FavoriteFilter,
  FilterValue,
  TableCustomization,
} from "./BFTableFilterModel";

interface BFTableFilterCreateProps {
  filter: FilterValue[];
  customizations?: TableCustomization;
  onClose: () => void;
  onFilterAdd: (filter: FavoriteFilter) => void;
}
const BFTableFilterCreate = (props: BFTableFilterCreateProps) => {
  return (
    <FormStruct
      className={classNames(`bf-table-filter-modal bf-table-filter-create`)}
      title={i18n.t(
        "BFTableFilter.CreateFilter",
        "Filter als Favorit speichern"
      )}
      onSubmit={async (values: {
        name: string;
        isDefault: boolean;
        saveCustomization: boolean;
      }) => {
        props.onFilterAdd({
          id: nanoid(),
          name: values.name,
          filter: props.filter,
          columnCustomization: values.saveCustomization
            ? props.customizations
            : undefined,
          isDefault: values.isDefault,
        });
        props.onClose();
      }}
      onAbort={() => props.onClose()}
      initialValues={{
        saveCustomization: props.customizations ? true : false,
        isDefault: false,
      }}
      submitText={i18n.t("BFTableFilter.SaveFilter", "Filter speichern")}
      render={(formProps) => {
        return (
          <>
            <div className={`__field`}>
              <Field name="name" validate={FormValidators.required()}>
                {({ input, meta }) => {
                  return (
                    <BFInput
                      label={i18n.t(
                        "BFTableFilter.FilterName",
                        "Name des Filters"
                      )}
                      focusOnMount
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  );
                }}
              </Field>
            </div>
            {props.customizations && (
              <div className={`__field`}>
                <Field name="saveCustomization">
                  {({ input, meta }) => {
                    return (
                      <>
                        <BFCheckbox
                          checked={input.value}
                          onChange={(_, checked) => input.onChange(checked)}
                        >
                          {i18n.t(
                            "BFTableFilter.SaveCustomization",
                            "Spaltenanpassungen im Favorit speichern"
                          )}
                        </BFCheckbox>
                        <div className={`hint`}>
                          {i18n.t(
                            "BFTableFilter.SaveCustomizationHint",
                            "Die aktuelle Spaltenanpassungen im Favorit werden bei einer neuen Tabelle gespeichert. Sobald dieser Favoritenfilter ausgewählt wird, werden auch die Spaltenanpassungen auf die Tabelle angewendet."
                          )}
                        </div>
                      </>
                    );
                  }}
                </Field>
              </div>
            )}
            <div className={`__field`}>
              <Field name="isDefault">
                {({ input, meta }) => {
                  return (
                    <>
                      <BFCheckbox
                        checked={input.value}
                        onChange={(_, checked) => input.onChange(checked)}
                      >
                        {i18n.t(
                          "BFTableFilter.SetDefault",
                          "Als Standardfilter setzen"
                        )}
                      </BFCheckbox>
                      <div className={`hint`}>
                        {i18n.t(
                          "BFTableFilter.SetDefaultHint",
                          "Ein Standardfilter wird beim initialen Öffnen der Tabelle automatisch gesetzt. Es kann nur ein Filter als Standard markiert sein. Bereits gespeicherte Standard-Einstellungen für diese Tabelle werden überschrieben."
                        )}
                      </div>
                    </>
                  );
                }}
              </Field>
            </div>
          </>
        );
      }}
    />
  );
};

export default BFTableFilterCreate;
