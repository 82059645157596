import { firstValid } from "../utils/Helpers";

const THIS_PLATFORM = "web";

export interface OverwriteEntry {
  _id: {
    key: string;
    kind: "translation" | "config";
    language?: string;
    type: string;
    mandator: string;
    platform: string;
  };
  value?: any;
}
interface OverwriteConfig {
  language?: string;
  defaultValue?: any;
  type?: string;
}

class OverwriteServiceClass {
  data: OverwriteEntry[];

  setData(newData: OverwriteEntry[]) {
    this.data = (newData || []).filter((e) => e._id.platform === THIS_PLATFORM);
  }
  getTranslation(key: string, options?: OverwriteConfig): string {
    return this.getKey("translation", key, options) as string;
  }
  getConfig(key: string, options?: OverwriteConfig) {
    return this.getKey("config", key, options);
  }

  private getKey(
    kind: "translation" | "config",
    key: string,
    options?: OverwriteConfig
  ) {
    const language = options?.language || null;
    const type = options?.type || null;
    const defaultValue = options?.defaultValue || null;

    return firstValid(
      this.get(kind, key, language, type),
      this.get(kind, key, null, type),
      this.get(kind, key, language, null),
      this.get(kind, key, null, null),
      defaultValue
    );
  }
  private get(
    kind: "translation" | "config",
    key: string,
    language?: string,
    type?: string
  ) {
    return (this.data || []).find(
      (e) =>
        e._id.kind === kind &&
        e._id.key === key &&
        e._id.language === language &&
        e._id.type === type
    )?.value;
  }
}
const OverwriteService = new OverwriteServiceClass();
(window as any).OverwriteService = OverwriteService;
export default OverwriteService;
