import { ThunkDispatch } from "redux-thunk";
import Log from "../debug/Log";
import { Application } from "../model/db/Application";
import ExpressionHelper from "../modules/generic-forms/util/ExpressionHelper";
import ObjectTools from "../modules/generic-forms/util/ObjectTools";
import { setGlobalCacheData } from "../redux/actions/global/global-actions";
import { SET_UI_CONFIG } from "../redux/actions/ui-config/ui-config-actions-types";
import { DefaultUIConfigs } from "../redux/reducers/ui-config/UiConfig";
import { store } from "../redux/store";
import { HTTP } from "../utils/Http";

export interface RequestInitAction {
  type: "globalCache" | "applicationCache" | "function";
  readJsonFunctions?: boolean;
  url?: string;
  key?: string;
  ttl?: number;
  function?: any;
}

export type InitAction = RequestInitAction;

export const initializeApplication = (
  application: Application,
  onSuccess?: () => void,
  onError?: (err) => void
) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(
      setUiConfig(DefaultUIConfigs.APPLICATION_IS_INITIALIZING, application)
    );
    dispatch(setUiConfig(DefaultUIConfigs.BLOCK_UI_INPUT, true));

    if (
      application &&
      application.config &&
      application.config["initActions"]
    ) {
      initializeActions(
        Object.values(application.config["initActions"]),
        dispatch
      ).then(() => {
        if (onSuccess) {
          onSuccess();
        }

        dispatch(
          setUiConfig(DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE, false)
        );
        dispatch(
          setUiConfig(DefaultUIConfigs.APPLICATION_IS_INITIALIZING, null)
        );
        dispatch(setUiConfig(DefaultUIConfigs.BLOCK_UI_INPUT, false));
      });
    } else {
      if (onSuccess) {
        onSuccess();
      }

      dispatch(setUiConfig(DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE, false));
      dispatch(setUiConfig(DefaultUIConfigs.APPLICATION_IS_INITIALIZING, null));
      dispatch(setUiConfig(DefaultUIConfigs.BLOCK_UI_INPUT, false));
    }
  };
};

export function setUiConfig(key: string, value: any) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_UI_CONFIG,
      key,
      value,
    });
  };
}

export async function initializeActions(
  actions: InitAction[],
  dispatch: ThunkDispatch<{}, {}, any>
) {
  for (const action of actions) {
    const result = await handleAction(action, dispatch).catch((err) =>
      Log.error(err)
    );
  }
}

const handleAction = (
  action: InitAction,
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  return new Promise<boolean>((resolve, reject) => {
    if (action.type === "function") {
      ExpressionHelper.evaluateExpression(action.function, {
        resolve,
        reject,
        dispatch,
      });
    }

    if (action.type === "globalCache") {
      let oldCache;

      oldCache = store.getState().global.cache[action.key];

      if (
        oldCache &&
        (oldCache.ttl === undefined ||
          (oldCache.ttl !== undefined &&
            oldCache.timestamp + oldCache.ttl > Number(new Date())))
      ) {
        resolve(oldCache);
      } else {
        HTTP.get({
          url: action["url"],
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((result) => {
            let data = result;
            if (action.readJsonFunctions) {
              data = ObjectTools.readJsonFunctions(data);
            }
            dispatch(setGlobalCacheData(action.key, data, action.ttl));
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      }
    }
  });
};
