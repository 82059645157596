import classNames from "classnames";
import i18n from "../../i18n";
import { useTypedSelector } from "../../redux/hooks";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BFTemplateChooser from "./BFTemplateChooser";
import "./LoadTemplateModal.scss";
import { TemplateAsset } from "./TemplateInterfaces";

const TABLE_IDENT = "load-template-table";
interface LoadTemplateModalProps {
  templateType: string;
  type: string;

  onSelect: (template: TemplateAsset) => void;
  onClose: () => void;
}
const LoadTemplateModal = (props: LoadTemplateModalProps) => {
  const userId = useTypedSelector((state) => state.global.user._id);
  return (
    <div className={classNames(`load-template-modal`)}>
      <div className={`header`}>
        {i18n.t("Template.selectTemplate", "Wählen Sie eine Vorlage aus")}
      </div>
      <BFTemplateChooser {...props} />
      {/* <div className={`search`}>
        <BFSearch tableIdentifier={TABLE_IDENT} focusOnMount />
      </div>
      <div className={`table-content`}>
        <ListComponent
          identifier={TABLE_IDENT}
          assetType={AssetTypes.Template}
          additionalMatchQuery={MQ.and(
            MQ.eq("data.templateType", props.templateType),
            MQ.eq("data.type", props.type),
            MQ.or(MQ.eq("data.user", userId), MQ.eq("data.forAll", true))
          )}
          cleanupOnUnmount
          render={(node: TemplateAsset) => (
            <BFButton
              className={`template-button`}
              onClick={() => props.onSelect(node)}
            >
              <div className={`first-row`}>
                <div className={`identifier`}>{node.data.identifier}</div>
                <div className={`fill`} />
                <div className={`for-whom`}>
                  {node.data.forAll
                    ? i18n.t("Template.forAllAvailable", "Geteilte Vorlage")
                    : i18n.t("Template.forUser", "Deine Vorlage")}
                </div>
              </div>

              <div className={`name`}>{node.data.name}</div>
            </BFButton>
          )}
        />
      </div> */}

      <div className={`actions`}>
        <BFButton appearance="outline" onClick={props.onClose}>
          {i18n.t("Global.Labels.cancel", "Abbrechen")}
        </BFButton>
      </div>
    </div>
  );
};

export default LoadTemplateModal;
