import classNames from "classnames";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import {
  useAggregationStatisticQuery,
  useDatabus,
} from "../../../../../../redux/hooks";
import { MatchQuery } from "../../../../../../services/DataService";
import { DataBusSubKeys } from "../../../../../../utils/Constants";
import DKpiCard from "../../../../../common/DKpiCard/DKpiCard";
import "./RSDashboardKpiBtn.scss";

interface RSDashboardKpiBtnProps {
  subTitle: string;
  meta: string;
  matchQuery: MatchQuery;
  onClick?: () => void;
  isSelected?: boolean;
  kpiText?: string;
  className?: string;
}

const RSDashboardKpiBtn = (props: RSDashboardKpiBtnProps) => {
  const aggregations = useAggregationStatisticQuery<{
    general: { count: number };
  }>(AssetTypes.Resubmission, props.matchQuery, [
    {
      name: "general",
      op: [
        {
          key: "count",
          op: "count",
        },
      ],
      query: {},
    },
  ]);

  useDatabus(DataBusSubKeys.RESUBMISSION_COUNT_UPDATE, () => {
    aggregations.reload();
  });

  return (
    <div
      className={classNames(
        "rs-dashboard-kpi-btn",
        {
          selected: props.isSelected,
        },
        props.className
      )}
    >
      <DKpiCard
        loading={aggregations.loading}
        kpi={
          props.kpiText
            ? props.kpiText
            : aggregations.data?.general?.count?.toString() || "0"
        }
        subTitle={props.subTitle}
        meta={props.meta}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
      />
    </div>
  );
};

export default RSDashboardKpiBtn;
