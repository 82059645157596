import classNames from "classnames";
import AssetLoader from "../../../../../components/AssetLoader/AssetLoader";
import BaseAsset from "../../../../../model/general-assets/BaseAsset";
import { Resubmission } from "../../../RSInterfaces";

interface RSSubAssetLoaderProps {
  resubmission: Resubmission;
  renderSubAsset: (asset: BaseAsset) => JSX.Element;
}

const RSSubAssetLoader = (props: RSSubAssetLoaderProps) => {
  const { linkedAsset } = props.resubmission.data;
  if (!linkedAsset || !linkedAsset.subAsset) {
    return null;
  }

  return (
    <div className={classNames("rs-sub-asset-loader")}>
      <AssetLoader
        assetType={linkedAsset.subAsset.assetType}
        id={linkedAsset.subAsset.assetId}
        render={(asset) => {
          return props.renderSubAsset(asset);
        }}
      />
    </div>
  );
};

export default RSSubAssetLoader;
