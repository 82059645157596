import { Component } from "react";
import { Progress } from "rsuite";
import "./ProgressLoader.scss";

type Props = {
  intermediate?: "intermediate" | "query";
} & any;

type States = {};

class ProgressLoader extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  render() {
    const { intermediate, query, ...lineProps } = this.props;

    if (intermediate) {
      (lineProps as any).percent = 0;
      (lineProps as any).status = "active";
      (lineProps as any).showInfo = false;
    }
    return (
      <Progress.Line
        className={`progress-loader-line ${intermediate ? intermediate : ""}`}
        {...lineProps}
      />
    );
  }
}

export default ProgressLoader;
