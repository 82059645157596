import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../utils/abstracts/AbstractStylableComponent";
import { IComponent } from "../layouts/IComponent";

type Props = {
  key?: any;
  orderIndex?: number;
  titleKey?: string;
  titleComponents?: { [key: string]: IComponent };
  postHeaderComponents?: { [key: string]: IComponent };
  preHeaderComponents?: { [key: string]: IComponent };
  contentComponents: { [key: string]: IComponent };

  footerComponents?: { [key: string]: IComponent };
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class ScrollCard extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  render() {
    const {
      orderIndex,
      style,
      titleKey,
      postHeaderComponents,
      preHeaderComponents,
      footerComponents,
      contentComponents,
      titleComponents,
      key,
    } = this.props;

    const config = {
      key: key,
      _component: "Card",
      orderIndex: orderIndex,
      style: [
        {
          padding: 0,
          ...(!Array.isArray(style) ? style : {}),
        },
        ...(Array.isArray(style) ? style : []),
      ],
      component: {
        _component: "Div",
        style: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
        },
        components: {
          header: {
            _component: "div",
            style: {
              display: "flex",
              flexDirection: "row",
              width: "100%",
              boxShadow: "0 3px 4px -2px grey",
              zIndex: 3,
            },
            components: {
              ...(preHeaderComponents ? preHeaderComponents : {}),
              title: titleKey
                ? {
                    _component: "simpleText",
                    textKey: this.evaluateExpression(titleKey),
                    type: "span",
                    style: {
                      padding: "10px",
                      fontSize: "1.4em",
                      flexGrow: 1,
                    },
                  }
                : {
                    _component: "div",
                    style: {
                      flexGrow: 1,
                    },
                    components: titleComponents ? titleComponents : {},
                  },
              ...(postHeaderComponents ? postHeaderComponents : {}),
            },
          },
          content: {
            _component: "scroll",
            style: {
              flexGrow: 1,
              height: 0,
            },
            components: contentComponents,
          },
          footer: footerComponents
            ? {
                _component: "div",
                style: {
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",

                  boxShadow: "0 4px 6px 2px grey",
                  zIndex: 3,
                },
                components: footerComponents,
              }
            : undefined,
        },
      },
    };

    return (window as any).ComponentsMapper.createElement(
      config,
      this.props.params
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ScrollCard))
);
