import classNames from "classnames";
import moment from "moment";
import { useState } from "react";
import FormStruct from "../../components/Form/FormStruct/FormStruct";
import { PlainTableGroup } from "./PlanTable";
import "./PlanTableCreate.scss";
import { PlanTableGroupFields } from "./PlanTableGroupManage";
import { PlanTableTimespanFields } from "./PlanTableTimespanManage";

type Formvalue = {
  groups: PlainTableGroup[];
  from: Date;
  to: Date;
};
interface PlanTableCreateProps {
  onClose: () => void;
  onSubmit: (values: Formvalue) => Promise<void>;
  minDate?: Date;
  maxDate?: Date;
  warnMinDate?: Date;
  warnMaxDate?: Date;

  from?: Date;
  to?: Date;
  groups?: PlainTableGroup[];
  disableEntriesDeletion?: string[];
  templateData?: {
    templateType: string;
    type: string;
  };
  creationTemplateData?: {
    templateType: string;
    type: string;
  };
}
const PlanTableCreate = (props: PlanTableCreateProps) => {
  const [initial] = useState({
    from: props.from,
    to: props.to,
    groups:
      props.groups?.map((group) => ({
        id: group.id,
        name: group.name,
        metaValues: {},
        items: group.children?.map((child) => ({
          id: child.id,
          data: { name: child.name },
        })),
      })) || [],
  });
  return (
    <FormStruct
      initialValues={initial}
      onSubmit={async (values) => {
        const groups = values.groups;
        const data: PlainTableGroup[] = groups.map((group) => ({
          id: group.id,
          name: group.name,
          children: group.items.map((e) => ({
            id: e.id,
            name: e.data.name,
          })),
        }));

        await props.onSubmit({
          groups: data,
          from: moment(values.from).utc(true).startOf("month").toDate(),
          to: moment(values.to).utc(true).endOf("month").toDate(),
        });
      }}
      onAbort={props.onClose}
      noPadding
      ignoreSubmitOnEnter
      render={(formProps) => (
        <div className={classNames(`plan-table-creation`)}>
          <div className={`date-section`}>
            <PlanTableTimespanFields
              minDate={props.minDate}
              maxDate={props.maxDate}
              warnMinDate={props.warnMinDate}
              warnMaxDate={props.warnMaxDate}
            />
          </div>
          {/* will be done later aligator */}
          {/* {(props.disableEntriesDeletion || []).length === 0 &&
            props.creationTemplateData && (
              <Field name="groups">
                {({ input, meta }) => (
                  <div className={`load-template-as-whole`}>
                    <div className={`text`}>
                      {i18n.t(
                        "PlanTable.Create.LoadTemplateAsWholeDescription",
                        "Sie haben die Möglichkeit bereits gespeicherte Budget-Vorlagen zu laden. "
                      )}
                    </div>
                    <BFButton
                      appearance="outline"
                      onClick={async () => {
                        const result: TemplateAsset = await loadTemplate(
                          props.creationTemplateData.templateType,
                          props.creationTemplateData.type
                        );
                        if (result) {
                          const data = result.data.data;
                          input.onChange(
                            data.map((group) => ({
                              id: ObjectIdService.new(),
                              name: group.name,
                              items: group.children.map((child) => ({
                                id: ObjectIdService.new(),
                                data: { name: child.name },
                              })),
                              metaValues: {},
                            }))
                          );
                        }
                      }}
                    >
                      {i18n.t(
                        "PlanTable.Create.LoadFromTemplate",
                        "Vorlage laden"
                      )}
                    </BFButton>
                  </div>
                )}
              </Field>
            )} */}
          <div className={`groups-section`}>
            <PlanTableGroupFields
              templateData={props.templateData}
              disableEntriesDeletion={props.disableEntriesDeletion}
            />
          </div>
        </div>
      )}
    />
  );
};

export default PlanTableCreate;
