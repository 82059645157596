import i18n from "@/i18n";
import { ObjectKind } from "./types/objectKind.interface";

export const getObjectListTableIdentifier = (kind?: ObjectKind) =>
  `object-list-${kind?._id || "all"}`;

export const getObjectGroupListTableIdentifier = (kind?: ObjectKind) =>
  `object-group-list-${kind?._id || "all"}`;

export const getEnergySourceLabel = (key: string) => {
  return getEnergySources().find((e) => e.value === key)?.label;
};

export const getEnergySources = () => {
  return [
    {
      value: "natural_gas",
      label: i18n.t("catalogs.energy.natural_gas", "Erdgas"),
    },
    {
      value: "oil",
      label: i18n.t("catalogs.energy.oil", "Öl"),
    },
    {
      value: "pellets",
      label: i18n.t("catalogs.energy.pellets", "Pellets"),
    },
    {
      value: "electricity",
      label: i18n.t("catalogs.energy.electricity", "Strom"),
    },
    {
      value: "district_heating",
      label: i18n.t("catalogs.energy.district_heating", "Fernwärme"),
    },
    {
      value: "heat_pump",
      label: i18n.t("catalogs.energy.heat_pump", "Wärmepumpe"),
    },
    {
      value: "solar_photovoltaic",
      label: i18n.t("catalogs.energy.solar_photovoltaic", "Photovoltaik"),
    },
    {
      value: "coal",
      label: i18n.t("catalogs.energy.coal", "Kohle"),
    },
    {
      value: "wood",
      label: i18n.t("catalogs.energy.wood", "Holz"),
    },
    {
      value: "solar_thermal",
      label: i18n.t("catalogs.energy.solar_thermal", "Solarthermie"),
    },
    {
      value: "geothermal",
      label: i18n.t("catalogs.energy.geothermal", "Geothermie"),
    },
    {
      value: "biomass",
      label: i18n.t("catalogs.energy.biomass", "Biomasse"),
    },
    {
      value: "biofuel",
      label: i18n.t("catalogs.energy.biofuel", "Biokraftstoff"),
    },
    {
      value: "hydrogen",
      label: i18n.t("catalogs.energy.hydrogen", "Wasserstoff"),
    },
  ];
};
