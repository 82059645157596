import { MatchQuery } from "../../../../../services/DataService";
import MQ from "../../../../../utils/MatchQueryUtils";
import { BfIconProps } from "../../../icon/BfIcon";
import { FilterOptionWithMatchQueryConverter } from "../BFTableFilterModel";
import OverlayContact, {
  ContactValue,
  generateContactLabel,
} from "../overlays/OverlayContact";

export const ContactFilterOption: (opts: {
  field: string;
  label: string;
  icon?: BfIconProps;
  filterTypes: string[];
  matchQueryConverter?: (value: any) => MatchQuery | null;
}) => FilterOptionWithMatchQueryConverter = (opts) => {
  const { field, label, icon, filterTypes } = opts;
  const matchQueryConverter =
    opts.matchQueryConverter ||
    ((value: ContactValue) =>
      value?.contacts?.length > 0 ? MQ.in(field, value.contacts) : null);
  return {
    matchQueryConverter: matchQueryConverter,
    key: field,
    label: label,
    icon: {
      type: icon?.type || "light",
      data: icon?.data || "single-neutral",
    },
    renderOverlay: (value, onChange) => (
      <OverlayContact
        title={label}
        value={value}
        onChange={onChange}
        filterTypes={filterTypes}
      />
    ),
    renderValueData: (value: ContactValue) =>
      generateContactLabel(label, value),
    getOptions: (currentValue, searchTerm) => [],
  };
};
