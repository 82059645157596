import classNames from "classnames";
import { IComponent } from "../../../../configurable/layouts/IComponent";
import BFInput from "../../../abstract-ui/forms/input/BFInput";
import "./EZInput.scss";

interface EZInputProps {
  className?: string;
  value: string;
  onChange: (value: string | number) => void;
  onBlur?: () => void;
  canChange?: boolean;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  ignoreLinebreaks?: boolean;
  textType?: "header" | "subHeader" | "default";
  type?: string;
  label?: string;

  prefix?: React.ReactNode | string | IComponent;
  suffix?: React.ReactNode | string | IComponent;
  prefixOnClick?: () => void;
  suffixOnClick?: () => void;

  validation?: {
    message: string;
    level: "error" | "warning";
  };
  textAlign?: "left" | "center" | "right";
  readonly?: boolean;
  appearance?: "default" | "bf";
}
const EZInput = (props: EZInputProps) => {
  return (
    <div
      className={classNames(
        `ez-input`,
        props.className,
        `type-${props.textType || "default"}`,
        { readonly: props.readonly }
      )}
    >
      <BFInput
        appearance={props.appearance === "bf" ? "default" : "clear"}
        placeholder={props.placeholder}
        value={props.value}
        label={props.label}
        onBlur={props.onBlur}
        onChange={props.onChange}
        type={props.type}
        suffix={props.suffix}
        prefix={props.prefix}
        prefixOnClick={props.prefixOnClick}
        suffixOnClick={props.suffixOnClick}
        validation={props.validation}
        textAlign={props.textAlign}
        readonly={props.readonly}
      />
    </div>
  );
};

export default EZInput;
