import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BFPlaceholder from "../../modules/abstract-ui/general/Placeholder/BFPlaceholder";
import "./KPIDetailCard.scss";

interface Props {
  title: string;
  headRight?: React.ReactNode;
  footer?: React.ReactNode;
  subHead?: React.ReactNode;
  kpis?: {
    name: string;
    value?: string;
    className?: string;
    width?: number;
    loading?: boolean;
  }[];
  childOptions: {
    renderWhileLoading?: boolean;
    height?: number;
    render: (data?: any) => React.ReactNode;
  };
  loading?: boolean;
  className?: string;
  data?: any;
  marginBottom?: number;
  error?: boolean;
  onReload?: () => void;
}
const KPIDetailCard = (props: Props) => {
  return (
    <div
      className={`kpi-detail-card __card ${props.className || ""}`}
      style={{ marginBottom: props.marginBottom }}
    >
      <div className="head">
        <div className="title">{props.title}</div>
        <div className="kpis">
          {props.kpis?.map((kpi, index) => (
            <div key={kpi.name} className={`kpi-entry ${kpi.className || ""}`}>
              <div className="label">{kpi.name}</div>
              {props.error ? (
                <div>-</div>
              ) : (
                <BFPlaceholder
                  width={kpi.width || 80}
                  loading={props.loading || kpi.loading}
                  className="value"
                >
                  {kpi.value}
                </BFPlaceholder>
              )}
            </div>
          ))}
        </div>
        {props.headRight && <div className="head-right">{props.headRight}</div>}
      </div>
      {props.subHead && <div className="sub-head">{props.subHead}</div>}
      {props.error ? (
        <div
          className="error-container"
          style={{ minHeight: props.childOptions.height }}
        >
          <div className="message">Fehler beim Laden der Daten</div>
          {props.onReload && (
            <BFButton onClick={() => props.onReload()}>Neu laden</BFButton>
          )}
        </div>
      ) : (
        <BFPlaceholder
          width={"100%"}
          minHeight={props.childOptions.height}
          loading={
            props.childOptions.renderWhileLoading ? false : props.loading
          }
          className="center"
          style={{ height: props.childOptions.height }}
        >
          {props.childOptions?.render(props.data)}
        </BFPlaceholder>
      )}
      {props.footer && <div className="foot">{props.footer}</div>}
    </div>
  );
};
export default KPIDetailCard;
