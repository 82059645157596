import classNames from "classnames";
import { nanoid } from "nanoid";
import { useRef, useState } from "react";
import ModalManager from "../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../i18n";
import DataBus from "../../../../services/DataBus";
import { DataBusSubKeys } from "../../../../utils/Constants";
import BFButton from "../../general/Button/BFButton";
import BFDropdown from "../../general/Dropdown/BFDropdown";
import {
  DropdownButton,
  DropdownDivider,
  DropdownMenu,
  DropdownPanel,
} from "../../general/Dropdown/BFDropdownContent";
import "./BFTableFilter.scss";
import BFTableFilterCreate from "./BFTableFilterCreate";
import BFTableFilterEntry from "./BFTableFilterEntry";
import {
  FavoriteFilter,
  FilterOption,
  FilterValue,
  TableCustomization,
} from "./BFTableFilterModel";
import BFTableFilterUpdate from "./BFTableFilterUpdate";
import BFTableSearchInput from "./BFTableSearchInput";

interface BFTableFilterProps {
  hideFavorites?: boolean;
  hideTextSearch?: boolean;
  searchPlaceholder?: string;
  filterOptions?: FilterOption[];
  value: FilterValue[];
  searchTerm?: string;
  onChange: (value: FilterValue[], favorite?: FavoriteFilter) => void;

  favoriteFilters?: FavoriteFilter[];
  onFilterAdd: (filter: FavoriteFilter) => void;
  onFilterUpdate: (filter: FavoriteFilter) => void;
  onFilterRemove: (id: string) => void;
  onFulltextSearch: (search: string) => void;
  onAllFilterRemove?: () => void;

  customizations?: TableCustomization;
}
const BFTableFilter = (props: BFTableFilterProps) => {
  const ref = useRef<HTMLDivElement>();
  const [dropDownIdentifier] = useState(nanoid());

  const availableFilters = props.filterOptions?.filter(
    (filter) =>
      !(props.value || []).find(
        (valueEntry) => valueEntry.filterKey === filter.key
      )
  );

  const onAdd = (filter: FilterOption, openDropdown?: boolean) => {
    props.onChange([
      ...(props.value || []),
      { filterKey: filter.key, value: filter.defaultValue || null },
    ]);
    if (openDropdown) {
      // force direct open of dropdown
      setTimeout(() => {
        (
          ref.current?.querySelector(
            `.filter-entry-dropdown.${filter.key.replaceAll(".", "-")} button`
          ) as HTMLButtonElement
        )?.click();
      }, 100);
    }
  };

  return (
    <div className={classNames(`bf-table-filter`)} ref={ref}>
      {!props.hideTextSearch && (
        <BFTableSearchInput
          onChange={props.onChange}
          searchPlaceholder={props.searchPlaceholder}
          value={props.value || []}
          filterOptions={props.filterOptions}
          onSearch={(value) => props.onFulltextSearch(value)}
        />
      )}
      {props.filterOptions && (
        <>
          <div className={`active-filters`}>
            {(props.value || []).map((valueEntry) => (
              <BFTableFilterEntry
                key={valueEntry.filterKey}
                allValues={props.value || []}
                filterOptions={props.filterOptions.find(
                  (option) => option.key === valueEntry.filterKey
                )}
                value={valueEntry}
                onChange={(value: FilterValue) => {
                  const newValue = (props.value || [])
                    .map((oldValue) =>
                      valueEntry.filterKey === oldValue.filterKey
                        ? value
                        : oldValue
                    )
                    .filter((e) => !!e);
                  props.onChange(newValue);
                }}
              />
            ))}
          </div>
          <div className={`add-new-filter`}>
            <BFDropdown
              activeKey={null}
              identifier={dropDownIdentifier}
              items={[
                // only show if there are favorite filters or you have the possibility to add a new one
                ...((props.favoriteFilters || []).length !== 0 ||
                (props.onFilterAdd && !props.hideFavorites)
                  ? [
                      {
                        type: "menu",
                        text: i18n.t("BFTableFilter.favorites", "Favoriten"),
                        icon: {
                          type: "light",
                          data: "love-it",
                          size: "xs",
                        },
                        items: [
                          // show a message if there are no favorite filters
                          ...((props.favoriteFilters || []).length === 0
                            ? [
                                {
                                  type: "panel",
                                  children: (
                                    <div className="no-favorite-filters">
                                      {i18n.t(
                                        "BFTableFilter.NoFavoritesAvailable",
                                        "Keine Favoriten vorhanden"
                                      )}
                                    </div>
                                  ),
                                } as DropdownPanel,
                              ]
                            : []),
                          // show the favorite filters
                          ...(props.favoriteFilters || []).map(
                            (filter) =>
                              ({
                                type: "button",
                                text: filter.name,
                                icon: filter.isDefault
                                  ? {
                                      data: "bookmarks-1",
                                      type: "light",
                                      size: "sm",
                                    }
                                  : undefined,
                                className: "favorite-filter-button",
                                onSelect: () =>
                                  props.onChange(filter.filter, filter),
                                suffixedAction: {
                                  tooltipText: i18n.t(
                                    "BFTableFilter.Delete.Filter",
                                    "Filter löschen"
                                  ),
                                  icon: {
                                    data: "cog-1",
                                    type: "light",
                                    size: "xs",
                                  },
                                  onClick: () => {
                                    ModalManager.show({
                                      noPadding: true,

                                      content: (state, setState, close) => (
                                        <BFTableFilterUpdate
                                          filter={filter}
                                          customizations={props.customizations}
                                          onClose={close}
                                          onFilterUpdate={(filter) =>
                                            props.onFilterUpdate(filter)
                                          }
                                          onFilterRemove={() =>
                                            props.onFilterRemove(filter.id)
                                          }
                                        />
                                      ),
                                    });
                                  },
                                },
                              } as DropdownButton)
                          ),
                          // show the option to add a new favorite filter if you have the possibility to do so
                          ...(props.onFilterAdd
                            ? [
                                { type: "divider" } as DropdownDivider,
                                {
                                  type: "button",
                                  icon: {
                                    type: "light",
                                    data: "add",
                                    size: "xs",
                                  },
                                  text: i18n.t(
                                    "BFTableFilter.AddCurrentFilter",
                                    "Aktuellen Filter hinzufügen"
                                  ),
                                  onSelect: () =>
                                    ModalManager.show({
                                      noPadding: true,

                                      content: (state, setState, close) => (
                                        <BFTableFilterCreate
                                          filter={props.value}
                                          customizations={props.customizations}
                                          onClose={close}
                                          onFilterAdd={(filter) =>
                                            props.onFilterAdd(filter)
                                          }
                                        />
                                      ),
                                    }),
                                } as DropdownButton,
                              ]
                            : []),
                        ],
                      } as DropdownMenu,
                      {
                        type: "divider",
                      } as DropdownDivider,
                    ]
                  : []),

                ...(availableFilters.length !== 0
                  ? availableFilters.map(
                      (filter) =>
                        ({
                          identifier: filter.key,
                          type: "button",
                          text: filter.label,
                          icon: filter.icon,
                          onSelect: () => onAdd(filter, true),
                        } as DropdownButton)
                    )
                  : [
                      {
                        type: "panel",
                        children: (
                          <div className="no-filters">
                            <span>
                              {i18n.t(
                                "BFTableFilter.Dropdown.NoFilter",
                                "Keine weiteren Filter verfügbar"
                              )}
                            </span>
                          </div>
                        ),
                      } as DropdownPanel,
                    ]),
                ...(props.onAllFilterRemove &&
                props.value &&
                props.value.length > 0
                  ? [
                      {
                        type: "divider",
                      } as DropdownDivider,
                      {
                        type: "button",
                        text: i18n.t(
                          "BFTableFilter.RemoveAllFilter",
                          "Alle Filter entfernen"
                        ),
                        icon: { data: "close", type: "light" },
                        onSelect: () => {
                          props.onAllFilterRemove();
                          DataBus.emit(
                            DataBusSubKeys.DROPDOWN_TOGGLE,
                            dropDownIdentifier
                          );
                        },
                      } as DropdownButton,
                    ]
                  : []),
              ]}
              toggleAs={BFButton}
              //   appearance="outline"
              label={i18n.t("BFTableFilter.Dropdown.Label", "Filtern")}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BFTableFilter;
