import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import classNames from "classnames";
import { useState } from "react";
import { Field } from "react-final-form";
import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import BFCheckboxList from "../../../../../../modules/abstract-ui/forms/checkbox-list/BFCheckboxList";
import BFCheckbox from "../../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "../../../../../../modules/abstract-ui/forms/input/BFInput";
import LanguageService from "../../../../../../services/LanguageService";
import { CBPortfolioTechnicalUnit } from "../../../../cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import {
  APContractActivity,
  AddMaintenanceScheduleDto,
} from "./APActivityMainteanceInterfaces";
import "./APActivityMaintenanceAppointmentForm.scss";
import APActivityMaintenanceService from "./APActivityMaintenanceService";

interface APActivityMaintenanceAppointmentFormProps {
  onClose: () => void;
  activity: APContractActivity;
  appointmentId?: string;
}

const getIntialData = (props: APActivityMaintenanceAppointmentFormProps) => {
  const appointment = props.activity.data.appointments?.find(
    (e) => e.id === props.appointmentId
  );
  if (appointment) {
    const schedule = props.activity.data.schedulingRules.find(
      (e) => e.id === appointment.scheduleRuleId
    );

    return {
      note: appointment.note,
      type: schedule.type,
      values: schedule.type === "fixed" ? schedule.typeData : [],
      affectedTechnicalUnits: appointment.affectedTechnicalUnits || [],
      automaticAppointment: schedule.automaticAppointment || false,
      appointmentStartDate: appointment.dueDate,
      numberOfMonths:
        schedule.type === "regular" ? schedule.typeData.numberOfMonths : 0,
      reminderBeforeInDays: schedule.reminderBeforeInDays,
    } as AddMaintenanceScheduleDto;
  } else {
    const allTechnicalUnits =
      props.activity.data.relations
        ?.filter((e) => e.assetType === AssetTypes.Portfolio.TechnicalUnit)
        .map((e) => e.assetId) || [];

    const allTechnicalUnitsWhichAreNotAssignedToAppointmentsYet =
      allTechnicalUnits?.filter(
        (e) =>
          !(props.activity.data.appointments || [])
            .filter((e) => e.status === "open")
            .some((app) => app.affectedTechnicalUnits?.includes(e))
      );
    return {
      type: "regular",
      // set initial all technical units which are not assigned to another appointment yet
      automaticAppointment: false,
      note: "",
      affectedTechnicalUnits:
        allTechnicalUnitsWhichAreNotAssignedToAppointmentsYet,
    };
  }
};
const APActivityMaintenanceAppointmentForm = (
  props: APActivityMaintenanceAppointmentFormProps
) => {
  const [initial] = useState(getIntialData(props));

  const allTechnicalUnits =
    props.activity.data.relations
      ?.filter((e) => e.assetType === AssetTypes.Portfolio.TechnicalUnit)
      .map((e) => e.assetId) || [];

  return (
    <FormStruct
      className={classNames(`ap-activity-maintenance-appointment-form`)}
      onSubmit={async (values: AddMaintenanceScheduleDto) => {
        if (props.appointmentId) {
          const appointment = props.activity.data.appointments?.find(
            (e) => e.id === props.appointmentId
          );
          await APActivityMaintenanceService.updateSchedulingData(
            props.activity,
            appointment,
            values
          );
        } else {
          await APActivityMaintenanceService.submitSchedulingData(
            props.activity._id,
            values
          );
        }
        props.onClose();
      }}
      initialValues={initial}
      onAbort={props.onClose}
      submitText={i18n.t(
        "apTemplate:Activity.maintenance.createAppointment.save",
        "Fälligkeit erstellen"
      )}
      render={() => (
        <>
          <div className={`__field`}>
            <Field
              name="note"
              validate={FormValidators.compose(
                FormValidators.required(),
                FormValidators.max(200)
              )}
            >
              {({ input, meta }) => (
                <BFInput
                  label={i18n.t(
                    "apTemplate:Activity.maintenance.createAppointment.note",
                    "Beschreibung"
                  )}
                  placeholder={i18n.t(
                    "apTemplate:Activity.maintenance.createAppointment.notePlaceholder",
                    "Beschreiben Sie Ihre Fälligkeit"
                  )}
                  {...input}
                  {...FormValidators.getValidation(meta)}
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field
              name="appointmentStartDate"
              validate={FormValidators.required()}
            >
              {({ input, meta }) => (
                <BFDatefield
                  label={i18n.t(
                    "apTemplate:Activity.maintenance.createAppointment.appointmentDate",
                    "Zeitpunkt der Fälligkeit"
                  )}
                  {...input}
                  {...FormValidators.getValidation(meta)}
                />
              )}
            </Field>
          </div>

          <Field name="type">
            {({ input }) => (
              <>
                {input.value === "regular" && (
                  <>
                    <Field
                      name="numberOfMonths"
                      validate={FormValidators.compose(
                        FormValidators.required(),
                        FormValidators.min(1)
                      )}
                    >
                      {({ input, meta }) => (
                        <div className={`__field`}>
                          <BFInput
                            type={"number"}
                            step={1}
                            prefix={i18n.t(
                              "apTemplate:Activity.fields.maintenance.noticePeriod.months",
                              "Monate"
                            )}
                            label={i18n.t(
                              "apTemplate:Activity.maintenance.createAppointment.numberOfMonths",
                              "Intervall der Fälligkeit"
                            )}
                            {...input}
                            {...FormValidators.getValidation(meta)}
                          />
                        </div>
                      )}
                    </Field>
                  </>
                )}

                {input.value === "fixed" && (
                  <>
                    <div>some forms about dates</div>
                  </>
                )}
              </>
            )}
          </Field>

          {allTechnicalUnits.length > 0 && (
            <div className={`__field`}>
              <Field name="affectedTechnicalUnits">
                {({ input, meta }) => (
                  <BFCheckboxList
                    label={i18n.t(
                      "apTemplate:Activity.maintenance.createAppointment.affectedTechnicalUnits",
                      "Betroffene technische Einheiten"
                    )}
                    data={allTechnicalUnits?.map((e) => ({
                      value: e,
                      label: (
                        <AssetLoader
                          assetType={AssetTypes.Portfolio.TechnicalUnit}
                          id={e}
                          render={(asset: CBPortfolioTechnicalUnit) => (
                            <>
                              <span className={`technical-unit-id`}>
                                {asset.data.id}
                              </span>
                              <span className={`technical-unit-name`}>
                                {LanguageService.translateLabel(
                                  asset.data.displayName
                                )}
                              </span>
                            </>
                          )}
                        />
                      ),
                    }))}
                    {...input}
                    {...FormValidators.getValidation(meta)}
                  />
                )}
              </Field>
            </div>
          )}

          <div className={`section-description`}>
            {i18n.t(
              "apTemplate:Activity.maintenance.createAppointment.appointmentDescription",
              "Wollen Sie erinnert werden, dass zu der Fälligkeit ein Termin erstellt werden muss? Sollte der Vertragspartner die Termine verwalten, ist dies nicht notwendig."
            )}
          </div>
          <Field name="automaticAppointment">
            {({ input, meta }) => (
              <>
                <div className={`__field`}>
                  <BFCheckbox
                    checked={input.value}
                    onChange={(_, checked) => input.onChange(checked)}
                  >
                    {i18n.t(
                      "apTemplate:Activity.maintenance.createAppointment.automaticAppointment",
                      "Termin wird von Vertragspartner geregelt"
                    )}
                  </BFCheckbox>
                </div>

                {!input.value && (
                  <div className={`__field`}>
                    <Field
                      name="reminderBeforeInDays"
                      validate={FormValidators.compose(
                        FormValidators.required(),
                        FormValidators.min(0)
                      )}
                    >
                      {({ input, meta }) => (
                        <BFInput
                          type={"number"}
                          step={1}
                          label={i18n.t(
                            "apTemplate:Activity.maintenance.createAppointment.reminderBeforeInDays",
                            "Errinerung an Termin vor Fälligkeit"
                          )}
                          prefix={i18n.t(
                            "apTemplate:Activity.maintenance.createAppointment.days",
                            "Tage"
                          )}
                          {...input}
                          {...FormValidators.getValidation(meta)}
                        />
                      )}
                    </Field>
                  </div>
                )}
              </>
            )}
          </Field>
        </>
      )}
    />
  );
};

export default APActivityMaintenanceAppointmentForm;

// export interface AddMaintenanceScheduleFixedValuesDto {
//     date: number;
//     month: number;
//   }

//   export interface AddMaintenanceScheduleDto {
//     // @IsIn(["regular", "fixed"])
//     type: "regular" | "fixed";

//     // @IsBoolean()
//     automaticAppointment: boolean;

//     // @ValidateIf((o: addMaintenanceScheduleDto) => o.automaticAppointment === false)
//     // @IsNumber()
//     // @IsPositive()
//     reminderBeforeInDays: number;

//     // @ValidateIf((o: addMaintenanceScheduleDto) => o.type === "regular")
//     // @IsNumber()
//     // @IsPositive()
//     numberOfMonths: number;

//     // @ValidateIf((o: addMaintenanceScheduleDto) => o.type === "fixed")
//     // @IsArray()
//     // @Type(()=> addMaintenanceScheduleFixedValuesDto)
//     // @ValidateNested({ each: true })
//     // @ArrayMinSize(1)
//     values: AddMaintenanceScheduleFixedValuesDto[];
//   }
