import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Popover } from "rsuite";
import Collapse from "rsuite/esm/Animation/Collapse";
import {
  hasValue,
  isDefined,
  translateNonGenerate,
} from "../../../../utils/Helpers";
import BfIcon from "../../icon/BfIcon";
import BFMessageBox from "../Message/BFMessageBox";
import BFWhisper from "../whisper/BFWhisper";
import "./ValidationPopover.scss";

export type ValidatorPopoverStyle = "hidden" | "default" | "below" | "behind";

type Props = {
  validatorStyle?: ValidatorPopoverStyle;
  message?: string;
  level: "warning" | "error";
  marginTop?: number;
  children?: any;
  flexGrow?: number;
};

const ValidationPopover = (props: Props) => {
  const [validationText, setValidationText] = useState(
    props.message?.toString() || ""
  );
  const ref = useRef(null);
  useEffect(() => {
    if (props.message) {
      setValidationText(props.message?.toString() || "");
    }
  }, [props.message]);
  const validatorStyle = props.validatorStyle || "below";
  const renderSpeaker = () => {
    return (
      <Popover
        style={{ marginTop: props.marginTop }}
        className={`validation-popover ${props.level}`}
      >
        <div className={"message"}>{translateNonGenerate(props.message)}</div>
      </Popover>
    );
  };
  if (validatorStyle === "hidden") {
    return props.children;
  }
  if (validatorStyle === "below" || validatorStyle === "behind") {
    return (
      <div
        style={{ flexGrow: isDefined(props.flexGrow) ? props.flexGrow : 1 }}
        className={classNames(`validation-popover`, {
          behind: validatorStyle === "behind",
        })}
      >
        <div
          className={classNames(`wrapper`)}
          style={{ position: "relative", width: "100%" }}
          ref={ref}
        >
          {props.children}
        </div>
        <Collapse unmountOnExit in={hasValue(props.message)}>
          <div>
            <div className={`validation-below ${props.level}`}>
              <BFMessageBox small inline type={props.level}>
                {validationText?.toString() || ""}
              </BFMessageBox>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
  return (
    <BFWhisper
      placement="bottomStart"
      open={props.level === "error" && props.message ? true : false}
      speaker={renderSpeaker()}
      container={ref.current}
    >
      <div style={{ flexGrow: 1 }} className="validation-popover">
        <div
          className={`wrapper`}
          style={{ position: "relative", width: "100%" }}
          ref={ref}
        >
          {props.children}
        </div>
        {props.level === "warning" && props.message ? (
          <div className={`validation-warning-below ${props.level}`}>
            <div>
              <BfIcon size={"lg"} data={"exclamation-circle"} />
            </div>
            <div className={"message"}>{props.message}</div>
          </div>
        ) : null}
      </div>
    </BFWhisper>
  );
};
export default ValidationPopover;
