import classNames from "classnames";
import { useState } from "react";
import StructLoader from "../../../../../components/StructLoader/StructLoader";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { LinkCell } from "../../../../../modules/abstract-ui/data/table/TableUtils";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "../../../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import { DefaultIcons } from "../../../../../modules/abstract-ui/icon/DefaultIcons";
import CategoryStruct from "../../../../../redux/actions/struct/implemented/CategoryStruct";
import { AssetCashBudgetEntry } from "../../model/CashBudgetEntry";
import { CBPortfolioObject } from "../portfolio/interfaces/CBPortfolioAsset";
import CBIdlyConnectDocument from "./connections/CBIdlyConnectDocument";
import CBIdlyConnectInvoice from "./connections/CBIdlyConnectInvoice";
import CBIdlyConnectLoan from "./connections/CBIdlyConnectLoan";
import CBIdlyConnectRental from "./connections/CBIdlyConnectRental";
import CBIdlyConnectService, {
  DocumentSuggestion,
} from "./connections/CBIdlyConnectService";
// import "./CBIdlyBookingConnection.scss";

type TagRender = {
  id: string;
  tags: string[];
  render: (
    booking: AssetCashBudgetEntry,
    index: number,
    count: number
  ) => JSX.Element;
  default?: boolean;
  label: string;
};

const RELEVANT_TAGS: (ignoreOpen?: boolean) => TagRender[] = (ignoreOpen) => [
  // {
  //   tags: ["project"],
  //   render: (booking: AssetCashBudgetEntry, index: number, count: number) => (
  //     <CBIdlyConnectProject booking={booking} />
  //   ),
  // },
  {
    id: "invoice",
    tags: ["invoice"],
    render: (booking: AssetCashBudgetEntry, index: number, count: number) => (
      <CBIdlyConnectInvoice
        booking={booking}
        initialOpen={ignoreOpen ? false : count === 1}
      />
    ),
    default: true,
    label: i18n.t("cb:CBIdlyConnect.addAction.invoice", "Rechnung"),
  },
  {
    id: "contract",
    tags: ["contract"],
    render: (booking: AssetCashBudgetEntry, index: number, count: number) => (
      <CBIdlyConnectDocument
        documentsFieldPath="data.attachments"
        suggestionPromise={async (booking: AssetCashBudgetEntry) =>
          await CBIdlyConnectService.fetchSuggestionsForDocuments(booking)
        }
        renderSuggestionData={(
          suggestion: DocumentSuggestion<CBPortfolioObject>
        ) => (
          <div>
            <div className={`data-row`}>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:CBIdlyConnect.object.name", "Objekt")}
                </div>
                <div className={`value`}>
                  <LinkCell
                    text={
                      <>
                        {suggestion.asset.data.id} -{" "}
                        {suggestion.asset.data.displayName}
                      </>
                    }
                    assetType={AssetTypes.Portfolio.Object}
                    id={suggestion.asset._id}
                    type={suggestion.asset.data.type}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        booking={booking}
        initialOpen={ignoreOpen ? false : count === 1}
        assetType={AssetTypes.Portfolio.Object}
      />
    ),
    default: true,
    label: i18n.t("cb:CBIdlyConnect.addAction.contract", "Dokument"),
  },
  {
    id: "rent",
    tags: ["rent"],
    render: (booking: AssetCashBudgetEntry, index: number, count: number) => (
      <CBIdlyConnectRental
        booking={booking}
        initialOpen={ignoreOpen ? false : count === 1}
      />
    ),
    label: i18n.t("cb:CBIdlyConnect.addAction.rent", "Mietvertrag"),
  },
  {
    id: "loan",
    default: true,
    tags: ["loanPayout", "loan"],
    render: (booking: AssetCashBudgetEntry, index: number, count: number) => (
      <CBIdlyConnectLoan
        booking={booking}
        initialOpen={ignoreOpen ? false : count === 1}
      />
    ),

    label: i18n.t("cb:CBIdlyConnect.addAction.loan", "Darlehen"),
  },
];
interface CBIdlyBookingConnectionProps {
  booking: AssetCashBudgetEntry;
}

const CBIdlyBookingConnection = (props: CBIdlyBookingConnectionProps) => {
  return (
    <StructLoader
      structTypes={["category"]}
      unitType={props.booking.data.unit}
      render={() => <CBIdlyBookingConnectionContent {...props} />}
    />
  );
};
const CBIdlyBookingConnectionContent = (
  props: CBIdlyBookingConnectionProps
) => {
  const [addedActions, setAddedActions] = useState<TagRender[]>([]);
  const tags = CategoryStruct.getTagsOf(props.booking.data.category) || [];

  const relevantTags = RELEVANT_TAGS(
    (props.booking.data.linkedAsset || []).length > 0
  ).filter((entry) => entry.tags.some((tag) => tags.includes(tag)));
  if (relevantTags.length === 0) {
    relevantTags.push(
      RELEVANT_TAGS((props.booking.data.linkedAsset || []).length > 0).find(
        (e) => e.tags.includes("invoice")
      )
    );
  }

  const defaults = RELEVANT_TAGS(
    (props.booking.data.linkedAsset || []).length > 0
  )
    .filter((e) => e.default)
    .filter((e) => !relevantTags.find((a) => e.id === a.id))
    .filter((e) => !addedActions.find((a) => e.id === a.id));

  return (
    <div className={classNames(`cb-idly-booking-connection`)}>
      <div className={`suggestions`}>
        {relevantTags.map((entry, index) => (
          <div className={`suggestion-entry`} key={index}>
            {entry.render(props.booking, index, relevantTags.length)}
          </div>
        ))}
        {addedActions.map((entry, index) => (
          <div className={`suggestion-entry`} key={index}>
            {entry.render(props.booking, index, addedActions.length)}
          </div>
        ))}
      </div>
      {defaults.length > 0 && (
        <div className={`actions`}>
          <BFDropdown
            renderToggle={(props) => (
              <BFButton
                {...props}
                appearance="link"
                icon={{
                  ...DefaultIcons.ADD,
                  size: "xs",
                }}
                text={i18n.t(
                  "cb:CBIdlyConnect.actions",
                  "Weitere Verknüpfungen"
                )}
              />
            )}
            label={i18n.t("cb:CBIdlyConnect.actions", "Weitere Verknüpfungen")}
            items={defaults.map((e) => ({
              type: "button",
              text: e.label,
              onSelect: () => {
                setAddedActions([...addedActions, e]);
              },
            }))}
          />
        </div>
      )}
    </div>
  );
};

export default CBIdlyBookingConnection;
