import classNames from "classnames";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AdminUser } from "../../../../../apps/administration/AdminInterfaces";
import PrepUserlabel from "../../../../../components/AvatarComponent/PrepUserlabel";
import Userlabel from "../../../../../components/AvatarComponent/Userlabel";
import ListComponent from "../../../../../configurable/components/ListComponent/ListComponent";
import i18n from "../../../../../i18n";
import InfiniteTable from "../../../../../redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "../../../../../redux/hooks";
import BFCheckbox from "../../../forms/checkbox/BFCheckbox";
import BFInput from "../../../forms/input/BFInput";
import BFButton from "../../../general/Button/BFButton";
import BfIcon from "../../../icon/BfIcon";
import { DefaultIcons } from "../../../icon/DefaultIcons";
import OverlayTitle from "./OverlayTitle";
import "./OverlayUser.scss";

export interface UserValue {
  users: string[];
}

interface OverlayUserProps {
  title: string;
  value: UserValue;
  onChange: (value: UserValue) => void;
}

// export const getDefaultIdQueryOptions = (
//   value: IDQueryValue,
//   searchTerm: string,
//   filterKey: string,
//   typeLabel: string,
//   additionalKeys?: string[]
// ) => {
//   let options: FilterSearchOption[] = [];
//   if (searchTerm) {
//     const matches = searchTerm.match(ID_EXTRACT_REGEX);
//     if (matches && matches.length > 0) {
//       const onSelect = (
//         optionValue: any,
//         currentValue: any,
//         onChange: (value: any) => void
//       ) => {
//         onChange({ ...(currentValue || {}), ...optionValue });
//       };
//       if (!hasValue(value)) {
//         options.push({
//           filterKey,
//           valueKey: `_${filterKey}_eq`,
//           label: `${matches[0]}`,
//           value: {
//             value: matches[0],
//           },
//           labelAsString: `${matches[0]} ${typeLabel}`,
//           onSelect,
//           typeLabel,
//           searchKeys: [...(additionalKeys || []), ">"],
//         });
//       }
//     }
//   }
//   return options;
// };
export const generateUserLabel = (typeLabel: string, value: UserValue) => ({
  typeLabel: typeLabel,
  valueLabel:
    value === null || value === undefined || value.users?.length === 0 ? (
      "-"
    ) : value.users.length === 1 ? (
      <Userlabel id={value.users[0]} avatarSize={12} />
    ) : (
      `${value.users.length} ${i18n.t("Global.Label.Users", "Benutzer")}`
    ),
});
const OverlayUser = (props: OverlayUserProps) => {
  const [ident] = useState(nanoid());
  const dispatch = useDispatch();
  const tableData = useTypedSelector(
    (state) => state.application.infiniteTables[ident]
  );
  useEffect(() => {
    return () => {
      dispatch(InfiniteTable.clearTable(ident));
    };
  }, []);

  const addUser = (userId: string) => {
    props.onChange({
      ...props.value,
      users: [...(props.value?.users || []), userId],
    });
  };

  const removeUser = (userId: string) => {
    props.onChange({
      ...props.value,
      users: (props.value?.users || []).filter((e) => e !== userId),
    });
  };

  return (
    <div className={classNames(`overlay-user`)}>
      <OverlayTitle title={props.title} onClear={() => props.onChange(null)} />

      <div className={`selected-user-list`}>
        {(props.value?.users || []).length === 0 && (
          <div className={`no-entries`}>
            {i18n.t(
              "BFFIlter.OverlayUser.notRestricted",
              "Kein Benutzer ausgewählt"
            )}
          </div>
        )}
        {props.value?.users?.map((user) => (
          <div className={`selected-user-entry`}>
            <Userlabel id={user} avatarSize={16} />
            <BFButton
              appearance="link"
              className={`remove-user-button`}
              noPadding
              onClick={() => {
                removeUser(user);
              }}
            >
              <BfIcon {...DefaultIcons.REMOVE} size="xxs" />
            </BFButton>
          </div>
        ))}
      </div>
      <div className="search">
        <BFInput
          placeholder={i18n.t("BFTableSearch.searchPlaceholder", "Suche...")}
          appearance="clear"
          value={tableData?.searchTerm || ""}
          onChange={(value: string) =>
            dispatch(InfiniteTable.setSearch(ident, value))
          }
        />
      </div>
      <div className="content">
        <ListComponent
          dataUrl="/api/user"
          identifier={ident}
          params={{
            selectedUsers: props.value?.users || [],
            addUser,
            removeUser,
          }}
          render={(data: AdminUser, _index, params: any) => (
            <div className={`entry`}>
              <BFCheckbox
                onChange={(_value, checked) => {
                  if (checked) {
                    params.addUser(data._id);
                  } else {
                    params.removeUser(data._id);
                  }
                }}
                checked={params?.selectedUsers?.includes(data._id)}
              >
                <PrepUserlabel user={data} avatarSize={22} />
              </BFCheckbox>
            </div>
          )}
        />
      </div>
      <div className="footer">
        <BFButton
          appearance="link"
          onClick={() => props.onChange({ ...props.value, users: [] })}
        >
          {i18n.t("BFFilterbar.Multiple.deselectAll", "Alle abwählen")}
        </BFButton>
      </div>
    </div>
  );
};

export default OverlayUser;
