import classNames from "classnames";
import React from "react";
import Collapse from "rsuite/esm/Animation/Collapse";
import i18n from "../../../../i18n";
import BFButton from "../../general/Button/BFButton";
import "./BFExpandData.scss";

interface BFExpandDataProps {
  children: React.ReactNode;
  initialOpen?: boolean;

  showText?: string;
  hideText?: string;
}
const BFExpandData = (props: BFExpandDataProps) => {
  const [open, setOpen] = React.useState(props.initialOpen || false);
  return (
    <div className={classNames(`bf-expand-data`)}>
      <div className={`expand-header`}>
        <BFButton appearance="link" onClick={() => setOpen(!open)}>
          {!open
            ? props.showText || i18n.t("BFExpandData.show", "Mehr")
            : props.hideText || i18n.t("BFExpandData.hide", "Weniger")}
        </BFButton>
      </div>
      <Collapse in={open}>
        <div>{props.children}</div>
      </Collapse>
    </div>
  );
};

export default BFExpandData;
