import React, { Component } from "react";
import { Toggle } from "rsuite";
import ValidationPopover, { ValidatorPopoverStyle } from "../../general/ValidationPopover/ValidationPopover";
import LabeledInput from "../LabeledInput";
import "./BFToggle.scss";

type Props = {
	label?: string;
	labelPosition?: "left" | "right";
	className?: string;
	checked?: boolean;
	defaultChecked?: boolean;
	disabled?: boolean;
	onChange?: (checked: boolean, event: React.SyntheticEvent) => void;
	size?: "lg" | "md" | "sm";
	onBlur?: () => void;
	onFocus?: () => void;
	checkedChildren?: React.ReactNode;
	unCheckedChildren?: React.ReactNode;

	validatorStyle?: ValidatorPopoverStyle;
	validation?: {
		message: string;
		level: "error" | "warning";
	};
};

type States = {};

class BFToggle extends Component<Props, States> {
	render() {
		let { label, labelPosition, validation, validatorStyle, ...toggleProps } = this.props;

		labelPosition = labelPosition || "left";
		return (
			<LabeledInput label={label} labelPosition={labelPosition}>
				<ValidationPopover
					validatorStyle={validatorStyle}
					level={validation ? validation.level : "error"}
					message={validation ? validation.message : null}
				>
					<Toggle {...toggleProps} />
				</ValidationPopover>
			</LabeledInput>
		);
	}
}

export default BFToggle;
