import React, { Component } from "react";
import { Popover } from "rsuite";
import { PlacementAll } from "../../modules/abstract-ui/common/Placements";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BFWhisper from "../../modules/abstract-ui/general/whisper/BFWhisper";
import { BfIconProps } from "../../modules/abstract-ui/icon/BfIcon";
import "./ContextMenuComponent.scss";

type Props = {
  disabled: boolean;
  container?: HTMLElement | (() => HTMLElement); //	Sets the rendering container
  trigger?: "click" | "hover" | "focus" | "active";
  placement?: PlacementAll; // PlacementAll ('right')	Dispaly placement
  payload?: any;
  contextEntries?: {
    icon?: BfIconProps;
    textKey: string;
    onClick: (payload: any) => void;
  }[];
  className?: string;
};
type States = {};

class ContextMenuComponent extends Component<Props, States> {
  static defaultProps = {
    trigger: "click",
    placement: "auto",
    disabled: false
  };
  readonly state: States = {};

  speaker() {
    const { payload, contextEntries } = this.props;
    return (
      <Popover className={`context-menu-popover-container`}>
        <div className={`context-menu-popover`}>
          {contextEntries.map((entry, index) => {
            return (
              <BFButton
                key={index}
                appearance={"clear"}
                icon={entry.icon}
                textKey={entry.textKey}
                onClick={() => entry.onClick(payload)}
              />
            );
          })}
        </div>
      </Popover>
    );
  }

  render() {
    const {
      className,
      container,
      trigger,
      placement,
      contextEntries,
      disabled
    } = this.props;
    return !disabled && contextEntries && contextEntries.length !== 0 ? (
      <BFWhisper
        trigger={trigger}
        placement={placement}
        container={container}
        speaker={this.speaker()}
      >
        <div
          className={`context-menu-item has-entries   ${
            className ? className : ""
          }`}
        >
          {this.props.children}
        </div>
      </BFWhisper>
    ) : (
      <div className={`context-menu-item ${className ? className : ""}`}>
        {this.props.children}
      </div>
    );
  }
}

export default ContextMenuComponent;
