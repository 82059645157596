import { BooleanFilterOption } from "@/modules/abstract-ui/data/table-filter/defaults/BooleanFilterOption";
import { DateFilterOption } from "@/modules/abstract-ui/data/table-filter/defaults/DateFilterOption";
import CustomFieldsService from "@/modules/customfields/CustomFieldsService";
import DunningStruct from "@/redux/actions/struct/implemented/DunningStruct";
import moment from "moment";
import i18n from "../../../../../i18n";
import { FilterOptionWithMatchQueryConverter } from "../../../../../modules/abstract-ui/data/table-filter/BFTableFilterModel";
import { ContactFilterOption } from "../../../../../modules/abstract-ui/data/table-filter/defaults/ContactFilterOption";
import { DefaultFilterOptions } from "../../../../../modules/abstract-ui/data/table-filter/defaults/DefaultFilterOptions";
import { ListFilterOption } from "../../../../../modules/abstract-ui/data/table-filter/defaults/ListFilterOption";
import { UserFilterOption } from "../../../../../modules/abstract-ui/data/table-filter/defaults/UserFilterOption";
import OverlayBoolean, {
  BooleanValue,
  generateBooleanLabel,
} from "../../../../../modules/abstract-ui/data/table-filter/overlays/OverlayBoolean";
import OverlayDateSelection, {
  DefaultDateSelectionValue,
  generateLabelForDateFilter,
  generateMatchQueryForDateFilter,
  getDefaultDateOptions,
} from "../../../../../modules/abstract-ui/data/table-filter/overlays/OverlayDateSelection";
import OverlayIdQuery, {
  generateLabelForIdFilter,
  generateMatchQueryForIdFilter,
  getDefaultIdQueryOptions,
} from "../../../../../modules/abstract-ui/data/table-filter/overlays/OverlayIdQuery";
import OverlayMultipleSelection from "../../../../../modules/abstract-ui/data/table-filter/overlays/OverlayMultipleSelection";
import OverlayNumberSelection, {
  DefaultNumberSelectionValue,
  generateLabelForNumberFilter,
  generateMatchQueryForNumberFilter,
  getDefaultNumberOptions,
} from "../../../../../modules/abstract-ui/data/table-filter/overlays/OverlayNumberSelection";
import { ActivityAbstractStructClass } from "../../../../../redux/actions/struct/implemented/ActivityAbstractStruct";
import CategoryStruct from "../../../../../redux/actions/struct/implemented/CategoryStruct";
import UnitStruct from "../../../../../redux/actions/struct/implemented/UnitStruct";
import LanguageService from "../../../../../services/LanguageService";
import ArrayUtils from "../../../../../utils/ArrayUtils";
import StringUtils from "../../../../../utils/StringUtils";
import { APConfigTag, APStatusTags } from "../../../../AppConfigInterfaces";
import { ActivityApplicationConstants } from "../../ActivityHooks";
import {
  getActivityPriorityLabel,
  getActivityPriorityOptions,
} from "../../ActivityUtils";

export const APListFilterOptions: (
  filterTypes: string[],
  constants: ActivityApplicationConstants,
  structToUse: ActivityAbstractStructClass<any>
) => FilterOptionWithMatchQueryConverter[] = (
  filterTypes,
  constants,
  structToUse
) => [
  {
    key: "data.activityId",
    label: i18n.t("apTemplate:Activity.ActivityId", "Ticketnr."),
    icon: {
      type: "light",
      data: "symbol-equal",
    },
    matchQueryConverter: (value: any) =>
      generateMatchQueryForIdFilter(
        "data.activityId",
        value ? { value: `${constants?.idPrefix}-${value.value}` } : undefined,
        "string"
      ),
    renderOverlay: (value, onChange) => (
      <OverlayIdQuery
        title={i18n.t("apTemplate:Activity.ActivityId", "Ticketnr.")}
        value={value}
        onChange={onChange}
        inputProps={{
          type: "text",
          prefix: constants?.idPrefix + "-",
        }}
      />
    ),
    renderValueData: (value) => ({
      typeLabel: i18n.t("apTemplate:Activity.ActivityId", "Ticketnr."),
      valueLabel: generateLabelForIdFilter(
        value,
        constants?.idPrefix + "-"
      ) as string,
    }),
    getOptions: (currentValue, searchTerm) =>
      getDefaultIdQueryOptions(
        currentValue,
        searchTerm,
        "data.activityId",
        i18n.t("apTemplate:Activity.ActivityId", "Ticketnr.")
      ),
  },

  ...DefaultFilterOptions(),
  {
    matchQueryConverter: (value: any) =>
      generateMatchQueryForDateFilter("data.creationDate", value),
    key: "data.creationDate",
    label: i18n.t("apTemplate:Field.creationDate", "Schadensdatum"),
    icon: {
      type: "light",
      data: "calendar-cash-1",
    },
    defaultValue: DefaultDateSelectionValue,
    renderOverlay: (value, onChange) => (
      <OverlayDateSelection
        title={i18n.t("apTemplate:Field.creationDate", "Schadensdatum")}
        value={value}
        onChange={onChange}
      />
    ),
    renderValueData: (value) => ({
      typeLabel: i18n.t("apTemplate:Field.creationDate", "Schadensdatum"),
      valueLabel: generateLabelForDateFilter(value, (value) =>
        moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
      ),
    }),
    getOptions: (currentValue, searchTerm) =>
      getDefaultDateOptions(
        currentValue,
        searchTerm,
        "data.creationDate",
        i18n.t("apTemplate:Field.creationDate", "Schadensdatum"),
        (value) => moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
      ),
  },

  ...(constants?.fields?.contractData
    ? [
        ContactFilterOption({
          field: "data.contact",
          label: i18n.t(
            "apTemplate:List.Activity.Contract.contact",
            "Vertragspartner"
          ),
          filterTypes: filterTypes,
        }),
        ListFilterOption({
          field: "data.budgetLqCategories",
          label: i18n.t(
            "apTemplate:List.Activity.budgetLqCategories",
            "Vertragskategorie",
            {
              ns: [constants?.assetType, "apTemplate"],
            }
          ),
          data: ArrayUtils.sortData(
            filterTypes
              .map((type) =>
                (
                  structToUse.getConfig(type)?.data.planlqBookingCategory || []
                ).map((catId) =>
                  CategoryStruct.getData(type).find((e) => e._id === catId)
                )
              )
              .flat(),
            [
              {
                dir: "asc",
                key: "data.type",
              },
              {
                dir: "asc",
                key: "data.sortKey",
              },
            ]
          ).map((cat) => ({
            value: cat._id,
            label:
              (filterTypes.length > 1 ? cat.data.type + " - " : "") +
              LanguageService.translateLabel(cat.data.displayName),
          })),
        }),
      ]
    : []),
  ...(constants?.fields?.estimatedCosts
    ? [
        {
          matchQueryConverter: (value: any) =>
            generateMatchQueryForNumberFilter("data.estimatedCosts", value),
          key: "data.estimatedCosts",
          label: i18n.t("apTemplate:Field.estimatedCosts", "Geschätzte Kosten"),
          icon: {
            type: "light",
            data: "currency-euro-circle",
          },
          defaultValue: DefaultNumberSelectionValue,
          renderOverlay: (value, onChange) => (
            <OverlayNumberSelection
              title={i18n.t(
                "apTemplate:Field.estimatedCosts",
                "Geschätzte Kosten"
              )}
              value={value}
              onChange={onChange}
              inputProps={{
                type: "priceInput",
                suffix: StringUtils.getCurrencySymbol(),
                placeholder: i18n.t(
                  "apTemplate:Field.estimatedCosts",
                  "Geschätzte Kosten"
                ),
                textAlign: "right",
                allowNull: true,
              }}
            />
          ),
          renderValueData: (value) => ({
            typeLabel: i18n.t(
              "apTemplate:Field.estimatedCosts",
              "Geschätzte Kosten"
            ),
            valueLabel: generateLabelForNumberFilter(value, (value) =>
              StringUtils.formatCurrency(value)
            ),
          }),
          getOptions: (currentValue, searchTerm) =>
            getDefaultNumberOptions(
              currentValue,
              searchTerm,
              "data.estimatedCosts",
              i18n.t("apTemplate:Field.estimatedCosts", "Geschätzte Kosten"),
              (value) => StringUtils.formatCurrency(value)
            ),
        },
      ]
    : []),

  {
    key: "data.priority",
    label: i18n.t("apTemplate:List.Activities.priority", "Priorität"),
    icon: {
      type: "light",
      data: "signal-full",
    },
    matchQueryConverter: (value: any) =>
      value && value.length > 0
        ? {
            type: "op",
            op: "in",
            name: "data.priority",
            value: value.map((val) => Number(val)),
          }
        : null,
    renderOverlay: (value, onChange) => (
      <OverlayMultipleSelection
        title={i18n.t("apTemplate:List.Activities.priority", "Priorität")}
        entries={getActivityPriorityOptions().map((prioOption) => {
          const entry = {
            label: prioOption.label,
            value: prioOption.value.valueOf().toString(),
          };
          return entry;
        })}
        value={value}
        onChange={onChange}
      />
    ),
    renderValueData: (value) => {
      let valueLabel = "";
      if (value) {
        const prioLabels = value.map((val) =>
          getActivityPriorityLabel(Number(val))
        );
        valueLabel =
          prioLabels.length === 1 ? prioLabels[0] : prioLabels.join(", ");
      }
      return {
        typeLabel: i18n.t("apTemplate:List.Activities.priority", "Priorität"),
        valueLabel,
      };
    },
    getOptions: (currentValue, searchTerm) => {
      const typeLabel = i18n.t(
        "apTemplate:List.Activities.priority",
        "Priorität"
      );
      const filterKey = "data.priority";

      const filterOptions = getActivityPriorityOptions().map((option) => {
        return {
          filterKey,
          typeLabel,
          label: option.label,
          labelAsString: option.label,
          value: option.value.valueOf().toString(),
          valueKey: option.value.valueOf().toString(),
          onSelect: (optionValue, currentValue, onChange) => {
            const currentIds = currentValue?.map((val) => Number(val));
            onChange(
              currentValue
                ? [...currentIds, optionValue]
                : [Number(optionValue)]
            );
          },
        };
      });
      return filterOptions;
    },
  },
  {
    matchQueryConverter: (value: any) =>
      generateMatchQueryForDateFilter("data.dueDate", value),
    key: "data.dueDate",
    label: i18n.t("apTemplate:List.Labels.Activities.dueDate", "Fälligkeit"),
    icon: {
      type: "light",
      data: "4-days-workweek-1",
    },
    defaultValue: DefaultDateSelectionValue,
    renderOverlay: (value, onChange) => (
      <OverlayDateSelection
        title={i18n.t(
          "apTemplate:List.Labels.Activities.dueDate",
          "Fälligkeit"
        )}
        value={value}
        onChange={onChange}
      />
    ),
    renderValueData: (value) => ({
      typeLabel: i18n.t(
        "apTemplate:List.Labels.Activities.dueDate",
        "Fälligkeit"
      ),
      valueLabel: generateLabelForDateFilter(value, (value) =>
        moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
      ),
    }),
    getOptions: (currentValue, searchTerm) =>
      getDefaultDateOptions(
        currentValue,
        searchTerm,
        "data.dueDate",
        i18n.t("apTemplate:List.Activities.dueDate", "Fälligkeit"),
        (value) => moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
      ),
  },
  {
    matchQueryConverter: (value: any) =>
      value && value.length > 0
        ? {
            type: "op",
            op: "in",
            name: "data.status",
            value,
          }
        : null,
    key: "data.status",
    label: i18n.t("common:Global.Labels.Status", "Status"),
    icon: {
      type: "light",
      data: "loading-line",
    },
    renderOverlay: (value, onChange, allFilters) => {
      const statusEntries = structToUse
        .getAllStatus(filterTypes)
        .filter((e) => !e.tags?.includes(APStatusTags.ARCHIVED))
        .map((status) => {
          const typeLabel = UnitStruct.getUnitLabel(status.type);
          return {
            label: `${LanguageService.translateLabel(
              status.displayName
            )} (${typeLabel})`,
            value: status.id,
          };
        });
      return (
        <OverlayMultipleSelection
          title={i18n.t("common:Global.Labels.Status", "Status")}
          entries={statusEntries}
          value={value}
          onChange={onChange}
        />
      );
    },
    renderValueData: (value, allFilters) => {
      let valueText = "";
      if (value) {
        const statusEntries = structToUse
          .getAllStatus()
          .filter((e) => !e.tags?.includes(APStatusTags.ARCHIVED))
          .filter((status) => value.includes(status.id));
        valueText = statusEntries
          .map((e) => LanguageService.translateLabel(e.displayName))
          .join(", ");
      }
      return {
        typeLabel: i18n.t("common:Global.Labels.Status", "Status"),
        valueLabel: valueText,
      };
    },
    getOptions: (currentValue, searchTerm, allFilters) => {
      const allStatus = structToUse
        .getAllStatus()
        .filter((e) => !e.tags?.includes(APStatusTags.ARCHIVED));
      return allStatus.map((status, index) => ({
        filterKey: "data.status",
        typeLabel: i18n.t("common:Global.Labels.Status", "Status"),
        label: `${LanguageService.translateLabel(status.displayName)}`,
        labelAsString: `${LanguageService.translateLabel(status.displayName)}`,
        value: status.id,
        valueKey: status.id,
        onSelect: (optionValue, currentValue, onChange) => {
          onChange(
            currentValue ? [...currentValue, optionValue] : [optionValue]
          );
        },
      }));
    },
  },
  {
    matchQueryConverter: (value: any) =>
      value && value.length > 0
        ? {
            type: "op",
            op: "in",
            name: "data.tags",
            value,
          }
        : null,
    key: "data.tags",
    label: i18n.t("apTemplate:List.Activities.tags", "Tags"),
    icon: {
      type: "light",
      data: "tags-1",
    },
    renderOverlay: (value, onChange) => {
      const tagEntries: APConfigTag[] = [];
      filterTypes.forEach((type) => {
        tagEntries.push(...structToUse.getTags(type));
      });
      return (
        <OverlayMultipleSelection
          title={i18n.t("apTemplate:List.Activities.tags", "Tags")}
          entries={tagEntries.map((e) => ({
            label: LanguageService.translateLabel(e.displayName),
            value: e.id,
          }))}
          value={value}
          onChange={onChange}
        />
      );
    },
    renderValueData: (value) => {
      const tagEntries: APConfigTag[] = [];
      filterTypes.forEach((type) => {
        tagEntries.push(...structToUse.getTags(type));
      });

      let valueLabel = null;
      if (value) {
        valueLabel =
          value.length === 1
            ? LanguageService.translateLabel(
                tagEntries.find((tag) => tag.id === value[0])?.displayName
              )
            : `${value.length}/${
                tagEntries.filter((entry) => value.includes(entry.id)).length
              }`;
      }

      return {
        typeLabel: i18n.t("apTemplate:List.Activities.tags", "Tags"),
        valueLabel,
      };
    },
    getOptions: (currentValue, searchTerm) => {
      const tagEntries: APConfigTag[] = [];
      filterTypes.forEach((type) => {
        tagEntries.push(...structToUse.getTags(type));
      });

      return tagEntries
        .filter((e) => (currentValue || []).indexOf(e.id) === -1)
        .map((tag, index) => ({
          filterKey: "data.tags",
          typeLabel: i18n.t("apTemplate:List.Activities.tags", "Tags"),
          label: LanguageService.translateLabel(tag.displayName),
          labelAsString: LanguageService.translateLabel(tag.displayName),
          value: tag.id,
          valueKey: tag.id,
          onSelect: (optionValue, currentValue, onChange) => {
            onChange(
              currentValue ? [...currentValue, optionValue] : [optionValue]
            );
          },
        }));
    },
  },
  ...(constants?.fields?.insurance
    ? [
        {
          matchQueryConverter: (value: BooleanValue) =>
            value !== null && value !== undefined
              ? {
                  type: "op",
                  op: "eq",
                  name: "data.isInsuranceClaim",
                  value: value.show,
                }
              : null,
          key: "data.isInsuranceClaim",
          label: i18n.t(
            "apTemplate:List.Activities.isInsuranceClaim",
            "Versicherung"
          ),
          icon: {
            type: "light",
            data: "shield-home",
          },
          renderOverlay: (value, onChange) => (
            <OverlayBoolean
              title={i18n.t(
                "apTemplate:List.Activities.isInsuranceClaim",
                "Versicherung"
              )}
              value={value}
              onChange={onChange}
            />
          ),
          defaultValue: {
            show: true,
          },
          renderValueData: (value: BooleanValue) =>
            generateBooleanLabel(
              i18n.t(
                "apTemplate:List.Activities.isInsuranceClaim",
                "Versicherung"
              ),
              value
            ),
          getOptions: (currentValue, searchTerm) => [],
        } as FilterOptionWithMatchQueryConverter,
      ]
    : []),
  UserFilterOption({
    field: "data.assignee",
    label: i18n.t("apTemplate:Activity.List.assignee", "Zugewiesen an"),
  }),
  UserFilterOption({
    field: "data.supervisor",
    label: i18n.t("apTemplate:Activity.List.supervisor", "Verantwortliche"),
  }),

  ...(constants.fields?.dunning
    ? [
        DateFilterOption({
          field: "data.dunning.nextDueDate",
          label: i18n.t("apTemplate:List.Activity.nextDueDate", "Fälligkeit", {
            ns: [constants?.assetType, "apTemplate"],
          }),
        }),
        ListFilterOption({
          field: "data.dunning.dunningLevel",
          label: i18n.t("apTemplate:List.Activity.dunningLevel", "Mahnstufe", {
            ns: [constants?.assetType, "apTemplate"],
          }),
          data: DunningStruct.getDunningLevelOptionsOfTypes(filterTypes),
        }),
        ListFilterOption({
          field: "data.dunning.typeOfResidue",
          label: i18n.t(
            "apTemplate:List.Activity.typeOfResidue",
            "Art der Mahnung",
            {
              ns: [constants?.assetType, "apTemplate"],
            }
          ),
          data: DunningStruct.getTypeOfResidueOptionsOfTypes(filterTypes),
        }),
        BooleanFilterOption({
          field: "data.dunning.automatic",
          label: i18n.t(
            "apTemplate:List.Activity.automatic",
            "durch Mahnlauf",
            {
              ns: [constants?.assetType, "apTemplate"],
            }
          ),
          defaultValue: true,
        }),
      ]
    : []),

  ...(constants.fields?.customFields
    ? CustomFieldsService.getTableCustomFieldFilters(
        constants.fields?.customFields,
        "meta"
      )
    : []),
];
