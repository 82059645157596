import { CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER } from "@/apps/tatar/activityApp/specific/maintenance/technical-units/APTechnicalUnitsConst";
import CBTechnicalUnitForm from "@/apps/tatar/cashBudget/views/portfolio/object/components/technicalUnits/CBTechnicalUnitForm";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import DataBusDefaults from "@/services/DataBusDefaults";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import { OAObject } from "../../../types/object.interface";
import "./ObjectTechnicalUnits.scss";
import TechnicalUnitCardList from "./components/TechnicalUnitCardList";

interface CBPortfolioObjectTechnicalUnitsProps {
  asset: OAObject;
  rentalUnitId?: string;
}
const ObjectTechnicalUnits = (props: CBPortfolioObjectTechnicalUnitsProps) => {
  const onCreate = async () => {
    ModalManager.show({
      noPadding: true,
      content: (states, setStates, closeModal) => (
        <CBTechnicalUnitForm
          onClose={closeModal}
          onSuccess={() => {
            DataBusDefaults.reload({
              identifiers: [CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER],
            });

            closeModal();
          }}
          forObjectId={props.asset._id}
        />
      ),
    });
  };

  return (
    <div className={classNames(`object-technical-units`)}>
      <div className={`action-row`}>
        <BFButton onClick={onCreate}>
          {i18n.t(
            "cb:Portfolio.Objekt.technicalUnit.create.createUnit",
            "Neue Einheit erstellen"
          )}
        </BFButton>
      </div>
      {/* <div className={`technical-unit-floor-plan`}>
        <FloorPlan
          features={[]}
          floorplanBackground={{
            type: "static-image",
            url: "test",
          }}
        />
      </div> */}
      <TechnicalUnitCardList
        identifier={CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER}
        matchQuery={MQ.combine("and", [
          MQ.eq("data.objectId", props.asset?._id),
          MQ.in("data.status", ["active"]),
        ])}
      />
    </div>
  );
};

export default ObjectTechnicalUnits;
