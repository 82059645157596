import classNames from "classnames";
import { useCallback } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { BFBreadcrumbEntry } from "../abstract-ui/navigation/BFBreadcrumb/BFBreadcrumb";
import { DocumentStoreDirectory, DocumentStoreProps } from "./DSInterfaces";
import DSService from "./DSService";
import DSRootView from "./components/DSRootView/DSRootView";
import { useDocumentStorePath } from "./hooks/useDocumentStorePath";

const DocumentStoreRouted = (props: DocumentStoreProps) => {
  const rootDirectory = DSService.getDirectoryConfigurationForAsset(
    props.assetParams.type,
    props.assetParams.assetType,
    props.assetParams.documentsFieldPath,
    true
  );

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  // create a string array with all identifiers of the current path after the docstore path
  const breadcrumbPaths = location.pathname.split(match.url)[1].split("/");
  // remove the first empty string
  breadcrumbPaths.shift();

  const { directoryPathObjects, currentDirectory, unvalidPath } =
    useDocumentStorePath({
      pathEntries: breadcrumbPaths,
      rootDocumentDirectory: rootDirectory,
    });

  const slash = location.pathname.endsWith("/") ? "" : "/";
  const directoryPath = directoryPathObjects
    .map((directory) => directory.pathIdentifier)
    .join("/");

  const onClickBreadcrumb = useCallback(
    (breadcrumb: BFBreadcrumbEntry) => {
      // Just in case there are 2 directories with the same path
      // walk directory array to this id and map into path array
      const directoryIndex = directoryPathObjects.findIndex(
        (directory) => directory.id === breadcrumb.id
      );
      const leftOverDirectories = directoryPathObjects.slice(
        0,
        directoryIndex + 1
      );
      const newPathEntries = leftOverDirectories
        .map((directory) => directory.pathIdentifier)
        .join("/");
      history.push(`${match.url}/${newPathEntries}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, directoryPath]
  );

  const onClickFolderEntry = useCallback(
    (document: DocumentStoreDirectory) => {
      history.push(`${location.pathname}${slash}${document.pathIdentifier}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, directoryPath]
  );

  const onClickBack = useCallback(() => {
    const newPathEntries = directoryPathObjects
      .slice(0, directoryPathObjects.length - 1)
      .map((directory) => directory.pathIdentifier);
    history.push(`${match.url}/${newPathEntries.join("/")}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, directoryPath]);

  return (
    <div className={classNames("document-store-routed")}>
      <Switch>
        <Route path={match.url} exact>
          <Redirect
            to={`${match.url}${slash}${rootDirectory.pathIdentifier}`}
          />
        </Route>
        <Route
          key={rootDirectory.pathIdentifier}
          path={`${match.path}/${rootDirectory.pathIdentifier}`}
        >
          <DSRootView
            {...props}
            rootDocumentDirectory={rootDirectory}
            onClickBreadcrumb={onClickBreadcrumb}
            onClickFolderEntry={onClickFolderEntry}
            directoryPathObjects={directoryPathObjects}
            currentDirectory={currentDirectory}
            unvalidPath={unvalidPath}
            onClickBack={onClickBack}
            documentEntryActions={props.documentEntryActions}
            directoryEntryActions={props.directoryEntryActions}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default DocumentStoreRouted;
