import { Component } from "react";

import { Tooltip } from "rsuite";

type Props = {} & any;

type States = {};

class BFTooltip extends Component<Props, States> {
  render() {
    return (
      <Tooltip className={"bf-tooltip"} {...this.props}>
        {this.props.children}
      </Tooltip>
    );
  }
}

export default BFTooltip;
