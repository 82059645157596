import StorageUtils from "./StorageUtils";

class StorageCacheClass {
  cache: { [key: string]: any } = {};

  get(key: string) {
    if (this.cache[key]) {
      return this.cache[key];
    } else {
      const valueString = StorageUtils.LocalStorage.get(key) as string;
      if (valueString) {
        const value = JSON.parse(valueString);
        this.cache[key] = value;
        return value;
      } else {
        return undefined;
      }
    }
  }
  save(key: string, obj: any) {
    this.cache[key] = obj;
    StorageUtils.LocalStorage.save(key, JSON.stringify(obj));
  }
}
const StorageCache = new StorageCacheClass();
export default StorageCache;
