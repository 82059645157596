import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import arrayMutators from "final-form-arrays";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormValidators, {
  FV,
} from "../../../../../components/Form/Validation/FormValidators";
import PDFViewer from "../../../../../components/PDFViewer/PDFViewer";
import DebugDataComponent from "../../../../../debug/DebugDataComponent";
import i18n from "../../../../../i18n";
import BFCheckbox from "../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "../../../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import DataBusDefaults from "../../../../../services/DataBusDefaults";
import StringUtils from "../../../../../utils/StringUtils";
import { useActivityConstants } from "../../ActivityHooks";
import ActivityService from "../../ActivityService";
import "./APUploadOfferDialog.scss";

interface Props {
  activityId: string;
  unit: string;
  file: File;
  onSuccess: (data: any) => void;
  onAbort: () => void;
}
const COMPARISON_INITIAL_VALUE = [
  {
    article: "",
    unitPrice: 0,
    comparisonPrice: 0,
    link: "",
  },
];

const APUploadOfferDialog = (props: Props) => {
  const constants = useActivityConstants();
  const [fileurl, setFileurl] = useState(null);
  const showPriceTested = constants?.fields?.comparison?.includes(props.unit);
  useEffect(() => {
    if (props.file) {
      const url = URL.createObjectURL(props.file);
      setFileurl(url);
    } else {
      setFileurl(null);
    }
  }, [props.file]);
  return (
    <Form
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={async (data) => {
        try {
          const result = await ActivityService.uploadOffer(
            constants?.assetType,
            props.activityId,
            data
          );
          props.onSuccess(result);
        } catch (err) {
          DataBusDefaults.toast({
            type: "error",
            text: i18n.t(
              "apTemplate:OfferUpload.Error",
              "Fehler beim Hochladen",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
          });
        }

        //   try {
        //     await splitBooking(props.bookingEntry._id, data.bookings);
        //     props.onSuccess();
        //   } catch (err) {
        //     props.onError();
        //   }
      }}
      // validate={validate}
      initialValues={{
        file: props.file,
      }}
      render={({ handleSubmit, values, submitting }) => (
        <div className={`damange-claim-upload-offer-form`}>
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper">
              <div className={`pdf-viewer`}>
                {props.file && fileurl && (
                  <PDFViewer
                    url={fileurl}
                    filename={props.file.name}
                    height={"100%"}
                  />
                )}
              </div>
              <div className={`data-form`}>
                <DebugDataComponent data={values} />
                <div className={`field`}>
                  <Field
                    name="vendor"
                    validate={FormValidators.compose(
                      FormValidators.required(),
                      FormValidators.max(300)
                    )}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        {...input}
                        validation={{
                          level: "error",
                          message: meta.error && meta.touched ? meta.error : "",
                        }}
                        label={`${i18n.t(
                          "apTemplate:OfferUpload.Vendor",
                          "Dienstleister",
                          {
                            ns: [constants?.assetType, "apTemplate"],
                          }
                        )}*`}
                      />
                    )}
                  </Field>
                </div>
                <div className={`field`}>
                  <Field
                    name="value"
                    validate={FormValidators.compose(
                      FormValidators.required(),
                      FormValidators.min(0)
                    )}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        {...input}
                        suffix={StringUtils.getCurrencySymbol()}
                        validation={{
                          level: "error",
                          message: meta.error && meta.touched ? meta.error : "",
                        }}
                        textAlign="right"
                        type="priceInput"
                        label={`${i18n.t(
                          "apTemplate:OfferUpload.Value",
                          "Betrag",
                          {
                            ns: [constants?.assetType, "apTemplate"],
                          }
                        )}*`}
                      />
                    )}
                  </Field>
                </div>
                <div className={`field`}>
                  <Field
                    name="executionDate"
                    validate={FormValidators.compose(
                      FormValidators.dateAfter(new Date())
                    )}
                  >
                    {({ input, meta }) => (
                      <BFDatefield
                        {...input}
                        {...FV.getValidation(meta)}
                        label={`${i18n.t(
                          "apTemplate:OfferUpload.ExecutionDate",
                          "Ausführungsdatum",
                          {
                            ns: [constants?.assetType, "apTemplate"],
                          }
                        )}`}
                      />
                    )}
                  </Field>
                </div>
                <div className={`field`}>
                  <Field name="mail">
                    {({ input, meta }) => (
                      <BFInput
                        {...input}
                        validation={{
                          level: "error",
                          message: meta.error && meta.touched ? meta.error : "",
                        }}
                        label={`${i18n.t(
                          "apTemplate:OfferUpload.Mail",
                          "Kontakt Email",
                          {
                            ns: [constants?.assetType, "apTemplate"],
                          }
                        )}`}
                      />
                    )}
                  </Field>
                </div>
                <div className={`field`}>
                  <Field name="phone">
                    {({ input, meta }) => (
                      <BFInput
                        {...input}
                        validation={{
                          level: "error",
                          message: meta.error && meta.touched ? meta.error : "",
                        }}
                        label={`${i18n.t(
                          "apTemplate:OfferUpload.Phone",
                          "Kontakt Telefon",
                          {
                            ns: [constants?.assetType, "apTemplate"],
                          }
                        )}`}
                      />
                    )}
                  </Field>
                </div>
                <div className={`field`}>
                  <Field name="note">
                    {({ input, meta }) => (
                      <BFInput
                        type="textarea"
                        {...input}
                        validation={{
                          level: "error",
                          message: meta.error && meta.touched ? meta.error : "",
                        }}
                        label={`${i18n.t(
                          "apTemplate:OfferUpload.Note",
                          "Notiz",
                          {
                            ns: [constants?.assetType, "apTemplate"],
                          }
                        )}`}
                      />
                    )}
                  </Field>
                </div>
                {showPriceTested && (
                  <>
                    <Field name="didComparison">
                      {({ input, meta }) => (
                        <>
                          <div className={`field`}>
                            <BFCheckbox
                              checked={input.value}
                              onChange={(_, checked) => input.onChange(checked)}
                            >
                              {i18n.t(
                                "apTemplate:OfferUpload.DidComparison",
                                "Preisvergleich durchgeführt"
                              )}
                            </BFCheckbox>
                          </div>
                          {!input.value && (
                            <div className={`info-box`}>
                              {i18n.t(
                                "apTemplate:OfferUpload.DidComparisonInfo",
                                "Für gelistete Artikel sollen Preise im Internet verglichen werden, damit stark erhöhte Preise erkannt werden und beim Angebotsersteller dementsprechend Preisverhandlungen durchgeführt werden können."
                              )}
                            </div>
                          )}
                          {input.value && (
                            <div className={`field comparisons`}>
                              <FieldArray
                                name="comparisons"
                                initialValue={COMPARISON_INITIAL_VALUE}
                              >
                                {({ fields }) => (
                                  <div>
                                    <div className={`comparison-entries`}>
                                      {fields.map((field, index) => (
                                        <div
                                          className={`comparison-entry`}
                                          key={field}
                                        >
                                          <div className={`field`}>
                                            <Field
                                              name={`${field}.article`}
                                              validate={FormValidators.required()}
                                            >
                                              {({ input, meta }) => (
                                                <BFInput
                                                  {...input}
                                                  label={i18n.t(
                                                    "apTemplate:OfferUpload.Comparison.ArticleName",
                                                    "Artikel"
                                                  )}
                                                  {...FormValidators.getValidation(
                                                    meta
                                                  )}
                                                />
                                              )}
                                            </Field>
                                          </div>
                                          <div className={`row`}>
                                            <div className={`field`}>
                                              <Field
                                                name={`${field}.unitPrice`}
                                                validate={FormValidators.compose(
                                                  FormValidators.required()
                                                )}
                                              >
                                                {({ input, meta }) => (
                                                  <BFInput
                                                    textAlign="right"
                                                    type="priceInput"
                                                    suffix={StringUtils.getCurrencySymbol()}
                                                    {...input}
                                                    label={i18n.t(
                                                      "apTemplate:OfferUpload.Comparison.unitPrice",
                                                      "Artikelpreis"
                                                    )}
                                                    {...FormValidators.getValidation(
                                                      meta
                                                    )}
                                                  />
                                                )}
                                              </Field>
                                            </div>
                                            <div className={`field`}>
                                              <Field
                                                name={`${field}.comparisonPrice`}
                                                validate={FormValidators.compose(
                                                  FormValidators.required()
                                                )}
                                              >
                                                {({ input, meta }) => (
                                                  <BFInput
                                                    textAlign="right"
                                                    suffix={StringUtils.getCurrencySymbol()}
                                                    type="priceInput"
                                                    {...input}
                                                    label={i18n.t(
                                                      "apTemplate:OfferUpload.Comparison.comparisonPrice",
                                                      "Preisvergleich"
                                                    )}
                                                    {...FormValidators.getValidation(
                                                      meta
                                                    )}
                                                  />
                                                )}
                                              </Field>
                                            </div>
                                          </div>
                                          <div className={`field`}>
                                            <Field
                                              name={`${field}.link`}
                                              validate={FormValidators.compose(
                                                FormValidators.required(),
                                                FormValidators.url()
                                              )}
                                            >
                                              {({ input, meta }) => (
                                                <BFInput
                                                  {...input}
                                                  label={i18n.t(
                                                    "apTemplate:OfferUpload.Comparison.link",
                                                    "Link zum Preisergleich"
                                                  )}
                                                  {...FormValidators.getValidation(
                                                    meta
                                                  )}
                                                />
                                              )}
                                            </Field>
                                          </div>

                                          {fields.length > 1 && (
                                            <div className={`remove-action`}>
                                              <BFButton
                                                noPadding
                                                appearance="link"
                                                onClick={() =>
                                                  fields.remove(index)
                                                }
                                              >
                                                {i18n.t(
                                                  "Global.Buttons.remove"
                                                )}
                                              </BFButton>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                    <div className={`add-action`}>
                                      <BFButton
                                        appearance="link"
                                        onClick={() =>
                                          fields.push({
                                            article: "",
                                            unitPrice: 0,
                                            comparisonPrice: 0,
                                            link: "",
                                          } as any)
                                        }
                                      >
                                        {i18n.t(
                                          "apTemplate:OfferUpload.Comparison.Add",
                                          "Artikel hinzufügen"
                                        )}
                                      </BFButton>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </>
                )}
                <div className={`fill`} />
                <div className={`actions`}>
                  <BFButton
                    type="button"
                    appearance="outline"
                    onClick={() => props.onAbort()}
                  >
                    {i18n.t("Global.Buttons.cancel")}
                  </BFButton>
                  <BFButton
                    type="submit"
                    appearance="primary"
                    loading={submitting}
                  >
                    {i18n.t("apTemplate:OfferUpload.submit", "Hochladen", {
                      ns: [constants?.assetType, "apTemplate"],
                    })}
                  </BFButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    />
  );
};

export default APUploadOfferDialog;

// const validate = (data) => {
//   const error = {};
//   if (!data.vendor) {
//     error["vendor"] = i18n.t("Global.Labels.required");
//     // i18n.t("apTemplate:OfferUpload.validation.vendor", "Bitte Dienstleister angeben")
//   } else {
//     if (data.vendor.length > 200) {
//       error["vendor"] = i18n.t("Labels.maxLength200");
//     }
//   }
//   if (!data.value) {
//     error["value"] = i18n.t("Global.Labels.required");
//   } else {
//     if (data.value < 0) {
//       error["value"] = i18n.t("Labels.positiveNumber", "Muss positiv sein");
//     }
//   }

//   if (data.mail && !ValidatorsUtils.isEmail(data.mail)) {
//     error["mail"] = i18n.t("Labels.invalidEmail", "Ungültige Email");
//   }

//   if (data.executionDate && data.executionDate < new Date()) {
//     error["executionDate"] = i18n.t(
//       "Labels.futureDate",
//       "Datum muss in der Zukunft liegen"
//     );
//   }
//   return error;
// };
