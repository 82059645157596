import React, { CSSProperties } from "react";
import { FormSpy } from "react-final-form";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Log from "../../../debug/Log";
import { AppState } from "../../../redux/store";
import { AbstractComponent, AbstractStates } from "../../../utils/abstracts/AbstractComponent";
import GenericLayoutComponent from "../../generic-forms/GenericLayoutComponent";
import { GenericFormsLayoutProps } from "../../generic-forms/GFBaseElement";
import ExpressionHelper from "../../generic-forms/util/ExpressionHelper";

type Props = {
	manipulatorFC: string;
	style?: CSSProperties;
} & WithTranslation &
	RouteComponentProps &
	GenericFormsLayoutProps;

type States = {} & AbstractStates;

class GFFormManipulator extends AbstractComponent<Props, States> {
	static defaultProps = {};
	readonly state: States = {};
	prevFormData = {};

	componentDidMount() {}

	shouldComponentUpdate(nextProps: Props, nextState: States) {
		return super.shouldComponentUpdate(nextProps, nextState);
	}

	render() {
		const { condition, stateSubscriptions, style, manipulatorFC } = this.props;

		return (
			<>
				<GenericLayoutComponent
					stateSubscriptions={stateSubscriptions}
					condition={condition}
					style={style}
					render={styleProps => {
						return (
							<>
								<FormSpy subscription={{ errors: true, values: true }}>
									{({ errors, values }) => {
										// const intialValues = (this.props.params.formRoot as any).formProps.formValues;
										Log.debug("GFManipulator", this.props, values, errors);
										if (manipulatorFC) {
											ExpressionHelper.evaluateExpression(
												manipulatorFC,
												{
													prev: this.prevFormData,
													current: values,
													initialValues: (this.props.params.formRoot as any).formProps.initialValues,
													formProps: (this.props.params.formRoot as any).formProps,
													additionalData: this.props.params.additionalData
												},
												(this.props.params.formRoot as any).formProps.form.mutators
											);
										}
										this.prevFormData = values;
										return null;
									}}
								</FormSpy>
							</>
						);
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: AppState, props: Props) => ({});

export default withRouter(GFFormManipulator);
