import { RentalAgreement } from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import CBRentalAgreementDetails from "@/apps/tatar/cashBudget/views/tenants/components/rental-agreements/CBRentalAgreementDetails";
import AssetDetailModal from "@/components/AssetDetailModal/AssetDetailModal";
import { openDetailsAction } from "@/modals/GlobalDetailsFunctions";
import { AssetTypes } from "@/model/AssetTypes";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

interface Props {}
const ROUTE_NAME = "__asset_rental-agreement";
const RentalAgreementDetailsModal = (props: Props) => {
  const history = useHistory();
  useEffect(() => {
    openDetailsAction(AssetTypes.Rental.RentalAgreement, ROUTE_NAME);
  }, []);
  return (
    <AssetDetailModal
      assetType={AssetTypes.Rental.RentalAgreement}
      routeName={ROUTE_NAME}
      size="full"
      className="cb-rental-modal agreement"
      render={(asset: RentalAgreement) => (
        <div style={{ height: "calc(100vh - 50px)" }}>
          <CBRentalAgreementDetails
            goBack={() => {
              // DataBusDefaults.closeModal(ROUTE_NAME);
              history.goBack();
            }}
            rentalAgreement={asset}
            integrated
          />
        </div>
      )}
    />
  );
};

export default RentalAgreementDetailsModal;
