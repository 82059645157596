import moment from "moment";
import i18n from "../../i18n";
import BFIconTagCard, {
  BF_ICON_TAG_CARD_HEIGHT,
} from "../../modules/abstract-ui/data/icon-tag-card/BFIconTagCard";
import UserAvatar from "../AvatarComponent/UserAvatar";
import Username from "../AvatarComponent/Username";
import "./VerticalProcessHistoryEntry.scss";
import {
  VerticalProcessEntry,
  VerticalProcessEntryCurrent,
  VerticalProcessEntryFinish,
  VerticalProcessEntryFuture,
  VerticalProcessEntryPast,
} from "./VerticalProcessHistoryInterfaces";

const CLASSNAME = "vertical-process-history-entry";
interface VerticalProcessHistoryEntryProps {
  entry: VerticalProcessEntry;
}
const VerticalProcessHistoryEntry = (
  props: VerticalProcessHistoryEntryProps
) => {
  switch (props.entry.type) {
    case "past":
      return <VerticalProcessHistoryPast entry={props.entry} />;
    case "current":
      return <VerticalProcessHistoryCurrent entry={props.entry} />;
    case "future":
      return <VerticalProcessHistoryFuture entry={props.entry} />;
    case "finish":
      return <VerticalProcessHistoryFinish entry={props.entry} />;
  }
};

export default VerticalProcessHistoryEntry;

const VerticalProcessHistoryPast = (props: {
  entry: VerticalProcessEntryPast;
}) => {
  return (
    <div className={`${CLASSNAME} current`}>
      <div className={`vertical-line`} />
      <BFIconTagCard
        disableButton={true}
        icon={props.entry.icon ? props.entry.icon : undefined}
        backgroundColor={props.entry.backgroundColor}
        color={props.entry.color}
        circleComponent={
          props.entry.userId ? (
            <UserAvatar
              id={props.entry.userId}
              size={BF_ICON_TAG_CARD_HEIGHT}
            />
          ) : undefined
        }
      >
        <div className={`content`}>
          <div className={`labels`}>
            <div className={`first-row`}>
              <div className="username">
                <Username id={props.entry.userId} />{" "}
              </div>
              <div className={`date-field`}>
                {moment(props.entry.date).format(i18n.t("Formats.dateFormat"))}
              </div>
            </div>
            <div className={`main-row`}>{props.entry.label}</div>
            <div className={`sub-row`}>{props.entry.subLabel}</div>
          </div>
        </div>
      </BFIconTagCard>
    </div>
  );
};
const VerticalProcessHistoryFinish = (props: {
  entry: VerticalProcessEntryFinish;
}) => {
  return (
    <div className={`${CLASSNAME} finish`}>
      <BFIconTagCard
        icon={props.entry.icon}
        backgroundColor={props.entry.backgroundColor}
        color={props.entry.color}
        disableButton={true}
      >
        <div className={`content`}>
          <div className={`labels`}>
            <div className={`main-row`}>{props.entry.label}</div>
          </div>
        </div>
      </BFIconTagCard>
    </div>
  );
};
const VerticalProcessHistoryCurrent = (props: {
  entry: VerticalProcessEntryCurrent;
}) => {
  return (
    <div className={`${CLASSNAME} current`}>
      <BFIconTagCard
        backgroundColor={props.entry.backgroundColor}
        color={props.entry.color}
        icon={props.entry.icon}
        disableButton={true}
      >
        <div className={`content`}>
          <div className={`labels`}>
            <div className={`main-row`}>{props.entry.label}</div>
            <div className={`sub-row`}>{props.entry.subLabel}</div>
          </div>
        </div>
      </BFIconTagCard>
    </div>
  );
};
const VerticalProcessHistoryFuture = (props: {
  entry: VerticalProcessEntryFuture;
}) => {
  return <div className={`${CLASSNAME} future`}>future</div>;
};
