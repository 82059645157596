import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import moment from "moment";
import { nanoid } from "nanoid";
import i18n from "../../../i18n";
import { AssetTypes } from "../../../model/AssetTypes";
import {
  ApproveInvoiceTaskAsset,
  CompleteInvoiceFollowUpTaskAsset,
} from "../../../model/general-assets/TaskAsset";
import CDNService from "../../../services/CDNService";
import CacheService from "../../../services/CacheService";
import DataBus from "../../../services/DataBus";
import DataBusDefaults from "../../../services/DataBusDefaults";
import LanguageService from "../../../services/LanguageService";
import SubmitService from "../../../services/SubmitService";
import ArrayUtils from "../../../utils/ArrayUtils";
import { DataBusSubKeys } from "../../../utils/Constants";
import FileUtils from "../../../utils/FileUtils";
import { HTTP } from "../../../utils/Http";
import StringUtils from "../../../utils/StringUtils";
import ACInvoiceUtils from "../../utils/ACInvoiceUtils";
import {
  InvoiceDirection,
  PaymentData,
  RABookingInvoice,
  RAIncomingInvoice,
  RAInvoice,
  RAInvoiceCostCenter,
} from "./RAInterfaces";
import { RA_INVOICE_TABLE_IDENTIFIER } from "./RAUtils";
import {
  InvoiceBaseFormModel,
  InvoiceIncomingFormModel,
  InvoiceOutgoingFormModel,
} from "./components/InvoiceForm/RAInvoiceFormModel";

export type InvoiceCreationOptions = {
  linkedInvoice?: string;
  linkedActivity?: {
    assetId: string;
    assetType: string;
  };

  isStorno?: boolean;
};
export type DeclineType = "correction" | "decline";

export type DeclineInvoiceCorrection = {
  declineType: "correction";
  comment: string;
  needStorno: boolean;
  needNewInvoice: boolean;
  userAssignee: string[];
  teamAssignee: string[];
};
export type DeclineInvoiceDecline = {
  declineType: "decline";
  comment: string;
};
export type DeclineInvoiceData = { force?: boolean } & (
  | DeclineInvoiceCorrection
  | DeclineInvoiceDecline
);

class InvoiceServiceClass {
  getInitialDataForInvoice = (invoice: RAInvoice) => {
    const allCustomFields = ACInvoiceUtils.getCustomFields(invoice?.data?.type);
    const base: InvoiceBaseFormModel = {
      direction: invoice?.data?.direction,
      activity: invoice.data.activity || [],
      custom:
        allCustomFields.map((e) => ({
          identifier: e.id,
          value:
            invoice.data.invoice?.custom?.find((a) => a.identifier == e.id)
              ?.value || "",
        })) || [],
      contact: invoice.data.invoice.contact,
      invoiceId: invoice.data.invoice.invoiceId,
      documentDate: invoice.data.invoice.documentDate,
      entity: invoice.data.entity,
      invoiceType: invoice.data.invoice.invoiceType,
      objectId: invoice.data.objectId,
      paymentPlan: invoice.data.payment.paymentPlan || [],
      tags: invoice.data.tags || [],
      value: {
        amount: invoice.data.invoice.value?.amount || 0,
        currency:
          invoice.data.invoice.value?.currency || StringUtils.getCurrency(),
      },
      approvalChainChange: false,
      urgent: invoice.data.urgent?.isUrgent || false,
      urgentComment: invoice.data.urgent?.urgentComment || "",
    };

    if (invoice?.data?.direction === "INCOMING") {
      const formData: InvoiceIncomingFormModel = {
        ...base,
        direction: invoice?.data?.direction,
        costCenter: invoice.data.invoice.costCenter || [],
        paymentType: invoice.data.invoice.paymentType,
        paymentTypeData: invoice.data.invoice.paymentTypeData
          ? (Object.fromEntries(
              Object.entries(invoice.data.invoice.paymentTypeData).map(
                ([key, value]) => [key, value === null ? "" : value]
              )
            ) as PaymentData)
          : {
              type: "NONE",
            },
      };
      return formData;
    } else if (invoice?.data?.direction === "OUTGOING") {
      // TODO
      const formData: InvoiceOutgoingFormModel = {
        ...base,
        direction: invoice?.data?.direction,
        dueDate: invoice.data.invoice.dueDate,
      };
      return formData;
    } else {
      throw new Error("no valid payment direction");
    }
  };

  async setCostCentersForInvoice(
    invoice: RAIncomingInvoice,
    costCenters: RAInvoiceCostCenter[]
  ) {
    if (invoice?.data?.direction === "INCOMING") {
      const invoiceFormData: InvoiceIncomingFormModel =
        this.getInitialDataForInvoice(invoice) as InvoiceIncomingFormModel;

      invoiceFormData.costCenter = costCenters;

      return await this.updateInvoice(invoice._id, invoiceFormData);
    } else {
      throw new Error("invalid payment direction");
    }
  }

  async downloadBookingInvoices(
    invoices: string[],
    splittedByObject: boolean,
    filenameWithoutExtension: string
  ) {
    FileUtils.forceDownloadFile(
      `/invoice/downloadInvoices?param=${encodeURIComponent(
        JSON.stringify({
          invoiceIds: invoices,
          splitObject: splittedByObject,
        })
      )}`,
      `${filenameWithoutExtension}.zip`
    );
  }

  async deprecated_setCheckedOfInvoice(invoiceId: string, checked: boolean) {
    const result = await HTTP.post({
      url: `${invoiceId}/setCheckedStatus`,
      target: "INVOICE",
    });

    return result;
  }

  async setCheckedOfInvoices(invoiceIds: string[]) {
    const result = await HTTP.post({
      url: `setCheckedStatus`,
      target: "INVOICE",
      bodyParams: { invoiceIds },
    });

    return result;
  }

  async queryInvoicesToCheck(types: string[]) {
    const data = await HTTP.get({
      url: `invoicesToCheck`,
      target: "INVOICE",
      queryParams: {
        param: {
          types,
          direction: "INCOMING",
        },
      },
    });

    return data;
  }

  async changeInvoiceAssignment(
    invoice: RAInvoice,
    invoiceData: { invoiceType: string; objectId: string; entity: string }
  ) {
    const defaultData: InvoiceBaseFormModel =
      this.getInitialDataForInvoice(invoice);

    defaultData.invoiceType = invoiceData.invoiceType;
    defaultData.objectId = invoiceData.objectId;
    defaultData.entity = invoiceData.entity;

    defaultData.approvalChainChange = true;

    const data = await this.updateInvoice(invoice._id, defaultData);

    return data;
  }

  async setPaymentDueDate(invoiceIds: string[], date: Date) {
    const result = await HTTP.post({
      url: `/setPaymentDueDate`,
      target: "INVOICE",
      bodyParams: { dueDate: date.toISOString(), invoiceIds },
    });

    return result;
  }

  async markedInvoicesBooked(ids: string[]) {
    const result = await HTTP.post({
      url: `approveBookings`,
      target: "INVOICE",
      bodyParams: {
        invoiceIds: ids,
      },
    });

    return result;
  }

  async declineInvoiceTask(
    invoiceId: string,
    data: DeclineInvoiceData,
    force?: boolean
  ) {
    if (force) {
      data.force = true;
    }
    let result;
    result = await HTTP.post({
      url: `${invoiceId}/declineInvoice`,
      target: "INVOICE",
      bodyParams: data,
    });
    await CacheService.getData({
      id: invoiceId,
      oType: "asset",
      assetType: AssetTypes.Invoice,
      forceReload: true,
      silentReload: true,
    });
    return result;
  }

  async queryBookingInvoices(direction: InvoiceDirection, types: string[]) {
    const data = await HTTP.get({
      url: `invoicesToBook`,
      target: "INVOICE",
      queryParams: {
        param: {
          direction,
          types,
        },
      },
    });

    return data;
  }
  async queryBookingInvoicesAndConvert(
    direction: InvoiceDirection,
    types: string[]
  ) {
    const data = await this.queryBookingInvoices(direction, types);
    return { converted: this.convertDataToCheckTree(data), src: data };
  }

  convertDataToCheckTree(data: RABookingInvoice[]) {
    const types = Array.from(
      data.reduce((prev, current) => prev.add(current.type), new Set<string>())
    );

    return ArrayUtils.sortData(
      types.map((type) => {
        const entries = data.filter((entry) => entry.type === type);
        const entities = Array.from(
          entries.reduce(
            (prev, current) => prev.add(current.entity),
            new Set<string>()
          )
        );

        return {
          value: type,
          label: type,
          children: ArrayUtils.sortData(
            entities.map((entity) => {
              const invoices = data.filter(
                (e) => e.type === type && e.entity === entity
              );
              return {
                value: entity,
                label: OrgaStruct.getEntity(entity)?.displayName,
                children: ArrayUtils.sortData(
                  invoices.reduce((prev, current) => {
                    const objId = current.objectId || `_no_object_${entity}`;
                    const arr = [...prev];

                    if (!arr.find((e) => e.value === objId)) {
                      const name =
                        objId === `_no_object_${entity}`
                          ? i18n.t(
                              "ra:NoObjectAssigned",
                              "Kein Objekt zugewiesen"
                            )
                          : `${OrgaStruct.getObject(objId)?.id} - ${
                              OrgaStruct.getObject(objId)?.displayName
                            }`;
                      arr.push({
                        value: objId,
                        label: name,
                        children: ArrayUtils.sortData(
                          invoices
                            .filter(
                              (e) =>
                                (e.objectId || `_no_object_${entity}`) === objId
                            )
                            .map((invoice) => ({
                              value: invoice._id,
                              label: LanguageService.translateLabel(
                                invoice.displayName
                              ),
                              data: invoice,
                            })),
                          { key: "label", dir: "asc" }
                        ),
                      });
                    }
                    return arr;
                  }, []),
                  { key: "label", dir: "asc" }
                ),
              };
            }),
            { key: "label", dir: "asc" }
          ),
        };
      }),
      { key: "label", dir: "asc" }
    );
  }

  async finishCompleteTask(
    invoice: RAInvoice,
    task: CompleteInvoiceFollowUpTaskAsset,
    comment: string
  ) {
    const result = await HTTP.post({
      url: `${invoice._id}/completeFollowUp`,
      target: "INVOICE",
      bodyParams: {
        comment: comment || undefined,
      },
    });

    return result;
  }
  async approveInvoiceTask(
    invoice: RAInvoice,
    task: ApproveInvoiceTaskAsset,
    comment: string
  ) {
    const result = await HTTP.post({
      url: `${invoice._id}/acceptApproval`,
      target: "INVOICE",
      bodyParams: {
        comment: comment || undefined,
      },
    });

    CacheService.getData({
      oType: "asset",
      id: invoice._id,
      assetType: AssetTypes.Invoice,
      forceReload: true,
      silentReload: true,
    });

    return result;
  }

  generateFilenameForInvoice(invoice: RAInvoice) {
    const invoiceId = invoice.data.invoice?.invoiceId || "";
    const timestamp = moment(invoice.meta.ca).format("YYYY-MM-DD");
    return `${timestamp}_${invoice.id}_${invoiceId}.pdf`;
  }

  async uploadNewInvoices(
    direction: InvoiceDirection,
    type: string,
    files: File[],
    onProgress?: (progress: number, index: number, file: File) => void,
    reloadTableIdentifiers?: string[],
    options?: InvoiceCreationOptions
  ) {
    let index = 0;
    for (const file of files) {
      await this.uploadNewInvoice(
        direction,
        type,
        file,
        (progress) => {
          if (onProgress) onProgress(progress, index, file);
        },
        options
      );
      index++;
    }
    DataBus.emit(DataBusSubKeys.RELOAD, {
      identifiers: [
        RA_INVOICE_TABLE_IDENTIFIER,
        ...(reloadTableIdentifiers || []),
      ],
    });
  }
  async uploadNewInvoice(
    direction: InvoiceDirection,
    type: string,
    file: File,
    onProgress?: (progress: number) => void,
    options?: InvoiceCreationOptions
  ) {
    const tmpId = nanoid();
    const fileUpload = (await CDNService.uploadFile({
      assetField: "data.invoice.file",
      assetType: "invoice",
      file,
      filename: file.name,
      tempID: tmpId,
      onProgress,
    })) as { cdnID: string };

    const cdnID = fileUpload.cdnID;
    const invoice = await this.createNewInvoice(
      direction,
      cdnID,
      type,
      options
    );
    return invoice;
  }

  private createNewInvoice(
    direction: InvoiceDirection,
    cdnId: string,
    type: string,
    options?: InvoiceCreationOptions
  ) {
    return new Promise((resolve, reject) => {
      SubmitService.submitData({
        type: "asset",
        assetType: "invoice",
        data: {
          data: {
            direction,
            type: type,
            cdnId: cdnId,
            ...(options || []),
          },
        },
        ignorePropChecks: true,
        ignoreSubmitValidation: true,

        onError: (err) => reject(err),
        onSuccess: (data) => resolve(data),
      });
    });
  }
  async updateInvoice(invoiceId: string, invoiceData: InvoiceBaseFormModel) {
    try {
      const invoice = await HTTP.post({
        url: `${invoiceId}/updateInvoice`,
        target: "INVOICE",
        bodyParams: {
          ...invoiceData,
          custom: invoiceData.custom.filter((e) => e.value),
          objectId: invoiceData.objectId || undefined,
          paymentPlan:
            invoiceData.paymentPlan?.map(({ payedDate, ...other }) => other) ||
            [],
          urgent: invoiceData.urgent || false,
          urgentComment: invoiceData.urgent ? invoiceData.urgentComment : "",
        },
      });
      CacheService.updateDataInCaches(invoice._id, invoice, "patchRoot");
      return invoice as RAInvoice;
    } catch (err) {
      DataBusDefaults.toast({
        type: "error",
        text: i18n.t(
          "ra:InvoiceService.updateFailed",
          "Die Rechnung konnte nicht gespeichert werden"
        ),
      });
      throw err;
    }
  }

  async deleteInvoice(invoice: RAInvoice) {
    await HTTP.delete({
      url: `${invoice._id}`,
      target: "INVOICE",
    });

    CacheService.updateDataInCaches(
      invoice._id,
      { _deleted: true },
      "patchRoot"
    );
  }
  async startApprovalForInvoice(
    invoiceId: string,
    invoiceData: InvoiceBaseFormModel,
    force: boolean
  ) {
    const { approvalChainChange, ...otherInvoiceData } = invoiceData;
    const approval = await HTTP.post({
      url: `${invoiceId}/startInvoiceProcess`,
      target: "INVOICE",
      bodyParams: {
        forceUpload: force,
        ...otherInvoiceData,
        custom: invoiceData.custom.filter((e) => e.value),
        objectId: invoiceData.objectId || undefined,
      },
    });
    return approval as RAInvoice;
  }
  async abortExtraction(invoice: RAInvoice) {
    const approval = await HTTP.post({
      url: `${invoice._id}/abortExtraction`,
      target: "INVOICE",
    });
    return approval;
  }

  async addActivity(
    invoice: RAInvoice,
    activity: { assetId: string; assetType: string }
  ) {
    const formData = this.getInitialDataForInvoice(invoice);
    formData.activity = [...(formData.activity || []), activity];
    return this.updateInvoice(invoice._id, formData);
  }
  async removeActivity(
    invoice: RAInvoice,
    activity: { assetId: string; assetType: string }
  ) {
    const formData = this.getInitialDataForInvoice(invoice);
    formData.activity = (formData.activity || []).filter(
      (e) => e.assetId !== activity.assetId
    );
    return this.updateInvoice(invoice._id, formData);
  }
}
const InvoiceService = new InvoiceServiceClass();
export default InvoiceService;
