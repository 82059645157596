import classNames from "classnames";
import Page from "../../../components/PageView/Page";
import "./TablePageLayout.scss";

export type TablePageLayoutProps = {
  className?: string;
  pageHeader?: React.ReactNode;
  pageFooter?: React.ReactNode;
  cardFooter?: React.ReactNode;
  cardHeader?: React.ReactNode;
};
type TablePageLayoutWithChildProps = TablePageLayoutProps & {
  children: React.ReactNode;
};
const TablePageLayout = (props: TablePageLayoutWithChildProps) => {
  return (
    <Page className={classNames(`table-page-layout`, props.className)}>
      {props.pageHeader && (
        <div className={`page-header`}>{props.pageHeader}</div>
      )}
      <div className={`__card table-card`}>
        {props.cardHeader && (
          <div className={`table-card-header`}>{props.cardHeader}</div>
        )}
        <div className={`table-card-content`}>{props.children}</div>
        {props.cardFooter && (
          <div className="table-card-footer">{props.cardFooter}</div>
        )}
      </div>
      {props.pageFooter && (
        <div className={`page-footer`}>{props.pageFooter}</div>
      )}
    </Page>
  );
};
export default TablePageLayout;
