import moment from "moment";
import { useEffect, useState } from "react";
import i18n from "../../i18n";
import BaseAsset from "../../model/general-assets/BaseAsset";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import { BfIconProps } from "../../modules/abstract-ui/icon/BfIcon";
import HistoryEntry from "./HistoryEntry";
import "./HistoryViewer.scss";
import { HistoryEntryObject } from "./IHistoryEntry";

export interface FilterEntryRenderOptions {
  text: string | React.ReactNode | JSX.Element;
  icon?: BfIconProps;
  color?: string;
}

interface FilterEntry {
  name: string;
  filterFC: (entry: HistoryEntryObject) => boolean;
}
interface Props {
  asset: BaseAsset;
  entries: HistoryEntryObject[];
  filters?: FilterEntry[];
  entryOptionsFc: (entry: HistoryEntryObject) => FilterEntryRenderOptions;
}

const HistoryViewer = (props: Props) => {
  const [activeFilter, setActiveFilter] = useState<number | null>(null);

  const [visibleEntries, setVisibleEntries] = useState<HistoryEntryObject[]>(
    []
  );
  useEffect(() => {
    const sortedEntries = props.entries
      .sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)))
      .filter((e) => !!props.entryOptionsFc(e));
    if (activeFilter === null) {
      setVisibleEntries(sortedEntries);
    } else {
      setVisibleEntries(
        sortedEntries.filter(props.filters[activeFilter].filterFC)
      );
    }
  }, [props.entries, activeFilter]);

  return (
    <div className="history-viewer">
      {props.filters && (
        <div className="filters">
          <BFButton
            size="xs"
            onClick={() => setActiveFilter(null)}
            appearance={activeFilter === null ? "link" : "clear-on-white"}
          >
            {i18n.t("Global.Labels.all", "Alle")}
          </BFButton>
          {props.filters.map((filter, index) => (
            <BFButton
              key={index}
              size="xs"
              onClick={() => setActiveFilter(index)}
              appearance={activeFilter === index ? "link" : "clear-on-white"}
            >
              {filter.name}
            </BFButton>
          ))}
        </div>
      )}

      <div className="history-entries-scroll">
        <div className="history-entries">
          {visibleEntries.length === 0 && (
            <div className="empty-text">
              {i18n.t("Global.Labels.noEntry", "Kein Eintrag vorhanden")}
            </div>
          )}
          {visibleEntries.map((entry, index) => (
            <>
              {(index === 0 ||
                !moment(entry.date).isSame(
                  moment(visibleEntries[index - 1].date),
                  "day"
                )) && (
                <div className="day-entry">
                  <div className="line-vertical" />
                  <div className="day-entry-text">
                    {moment(entry.date).format(
                      i18n.t("Formats.dateFormatReadable")
                    )}
                  </div>
                </div>
              )}

              <HistoryEntry
                asset={props.asset}
                key={index}
                entry={entry}
                {...props.entryOptionsFc(entry)}
              />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
export default HistoryViewer;
