import _ from "lodash";
import {
  INFINITE_TABLE_ADD_NEW_DATA_ID,
  INFINITE_TABLE_CLEAR_ALL,
  INFINITE_TABLE_CLEAR_TABLE,
  INFINITE_TABLE_INITIALIZE,
  INFINITE_TABLE_MARK_FOR_RELOAD,
  INFINITE_TABLE_PATCH_DATA,
  INFINITE_TABLE_REMOVE_NEW_DATA_ID,
  INFINITE_TABLE_SET_CUSTOMIZE_PARAMS,
  INFINITE_TABLE_SET_ERROR,
  INFINITE_TABLE_SET_FILTER,
  INFINITE_TABLE_SET_LOADING,
  INFINITE_TABLE_SET_MATCHQUERY,
  INFINITE_TABLE_SET_SCROLL,
  INFINITE_TABLE_SET_SEARCH,
  INFINITE_TABLE_SET_SELECTION,
  INFINITE_TABLE_SET_SORT,
  INFINITE_TABLE_SET_SUCCESS,
  InfiniteTableActions,
} from "../../actions/application/application-infinite-table-action-types";
import { AppState } from "../../store";
import { ApplicationReducer } from "./ApplicationInterface";

const DEFAULT_LOAD_DELAY = 400;
const DEFAULT_LIMIT_PER_PAGE = 30;
/**
 * Splitted the reducer for clearity and maintenability.
 * The cache should lay in the application reducer,
 * but the logic should not be added there, its already too overloaded
 */
export default function (
  state: ApplicationReducer,
  action: InfiniteTableActions,
  root: AppState
): ApplicationReducer {
  switch (action.type) {
    case INFINITE_TABLE_CLEAR_TABLE: {
      const { tableIdentifier } = action;
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: undefined,
        },
      };
    }
    case INFINITE_TABLE_SET_SCROLL: {
      const { tableIdentifier, scrollX, scrollY } = action;
      const oldState = state.infiniteTables[tableIdentifier];
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            scroll: {
              x: scrollX,
              y: scrollY,
            },
          },
        },
      };
    }
    case INFINITE_TABLE_SET_SELECTION: {
      const { tableIdentifier, selection } = action;
      const oldState = state.infiniteTables[tableIdentifier];
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            selection,
          },
        },
      };
    }
    case INFINITE_TABLE_SET_MATCHQUERY: {
      const { tableIdentifier, matchQuery } = action;
      const oldState = state.infiniteTables[tableIdentifier];

      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            additionalMatchQuery: matchQuery,
          },
        },
      };
    }
    case INFINITE_TABLE_SET_CUSTOMIZE_PARAMS: {
      const { tableIdentifier, customizeParams } = action;
      const oldState = state.infiniteTables[tableIdentifier];

      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            customizeParams: customizeParams,
          },
        },
      };
    }
    case INFINITE_TABLE_INITIALIZE: {
      const {
        type,
        tableIdentifier,
        loadDelay,
        limitPerRequest,
        ...tableProps
      } = action;
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...(state.infiniteTables[tableIdentifier] || {}),
            searchTerm: "", // defaultvalue for search
            ...tableProps,
            loading: null,
            limitPerRequest: limitPerRequest || DEFAULT_LIMIT_PER_PAGE,
            loadDelay: loadDelay || DEFAULT_LOAD_DELAY,
          },
        },
      };
    }
    case INFINITE_TABLE_SET_SUCCESS: {
      const { type, tableIdentifier, data, totalCount, append, lastSkip } =
        action;
      const oldState = state.infiniteTables[tableIdentifier];
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            totalCount,
            data: append ? [...(oldState?.data || []), ...data] : data,
            loading: null,
            lastSkip,
            lastUpdated: new Date(),
            newDataIDs: [],
          },
        },
      };
    }
    case INFINITE_TABLE_SET_ERROR: {
      const { type, tableIdentifier, error } = action;
      const oldState = state.infiniteTables[tableIdentifier];
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            loading: null,
            error: error,
            data: [],
            lastUpdated: new Date(),
          },
        },
      };
    }
    case INFINITE_TABLE_SET_LOADING: {
      const { type, tableIdentifier, loading } = action;
      const oldState = state.infiniteTables[tableIdentifier];
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            loading,
            reload: loading ? false : oldState.reload,
          },
        },
      };
    }
    case INFINITE_TABLE_SET_FILTER: {
      const { type, tableIdentifier, filter } = action;
      const oldState = state.infiniteTables[tableIdentifier];
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            filterStatus: filter,
          },
        },
      };
    }

    case INFINITE_TABLE_SET_SEARCH: {
      const { type, tableIdentifier, searchTerm } = action;
      const oldState = state.infiniteTables[tableIdentifier];
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            searchTerm,
          },
        },
      };
    }
    case INFINITE_TABLE_SET_SORT: {
      const { type, tableIdentifier, visibleSort } = action;
      const oldState = state.infiniteTables[tableIdentifier];
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            visibleSort,
          },
        },
      };
    }
    case INFINITE_TABLE_MARK_FOR_RELOAD: {
      const { type, tableIdentifier } = action;
      const oldState = state.infiniteTables[tableIdentifier];
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            reload: true,
          },
        },
      };
    }
    case INFINITE_TABLE_PATCH_DATA: {
      const { type, tableIdentifier, data, dataId, mode } = action;
      const oldState = state.infiniteTables[tableIdentifier];
      if (!oldState || !oldState?.data) {
        return state;
      }
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            data: oldState?.data
              ? oldState?.data.map((entry) => {
                  if (entry["_id"] === action.dataId) {
                    if (action.mode === "overwrite") {
                      return action.data;
                    } else if (action.mode === "merge") {
                      return _.merge({}, entry, action.data);
                    } else {
                      return {
                        ...entry,
                        ...action.data,
                      };
                    }
                  } else {
                    return entry;
                  }
                })
              : [],
          },
        },
      };
    }
    case INFINITE_TABLE_ADD_NEW_DATA_ID: {
      const { type, tableIdentifier, dataId } = action;
      const oldState = state.infiniteTables[tableIdentifier];
      if (!oldState) {
        return state;
      }
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            newDataIDs: [
              ...(state.infiniteTables[action.tableIdentifier]?.newDataIDs ||
                []),
              dataId,
            ],
          },
        },
      };
    }
    case INFINITE_TABLE_REMOVE_NEW_DATA_ID: {
      const { type, tableIdentifier, dataId } = action;
      const oldState = state.infiniteTables[tableIdentifier];
      if (!oldState) {
        return state;
      }
      return {
        ...state,
        infiniteTables: {
          ...state.infiniteTables,
          [tableIdentifier]: {
            ...oldState,
            newDataIDs: (
              state.infiniteTables[action.tableIdentifier]?.newDataIDs || []
            ).filter((id) => id !== dataId),
          },
        },
      };
    }
    case INFINITE_TABLE_CLEAR_ALL: {
      const { type } = action;
      return {
        ...state,
        infiniteTables: {},
      };
    }

    default:
      return state;
  }
}
