import { useDispatch } from "react-redux";
import i18n from "../../i18n";
import Customization from "../../redux/actions/customization/customization-actions";
import BFButtonToggle from "../abstract-ui/general/Button/BFButtonToggle";
import { COMMENTS_MODULE_FLEX_PREFIX } from "./CommentUtils";

interface CommentListToggleProps {
  identifier: string;
  value: "list" | "table";
  onChange: (value: "list" | "table") => void;
}
const CommentListToggle = (props: CommentListToggleProps) => {
  const dispatch = useDispatch();
  return (
    <BFButtonToggle
      className={`margin-left-10`}
      value={props.value}
      onChange={(value: "list" | "table") => {
        props.onChange(value);
        dispatch(
          Customization.setFlexValue(
            `${COMMENTS_MODULE_FLEX_PREFIX}${props.identifier}`,
            value
          )
        );
      }}
      buttons={[
        {
          value: "list",
          icon: {
            type: "light",
            data: "navigation-menu-1",
            size: "xs",
          },
          tooltip: {
            tooltip: i18n.t(
              "CommentsModule.ListToggle.List",
              "Nachrichten untereinander anzeigen"
            ),
          },
        },
        {
          value: "table",
          icon: {
            type: "light",
            data: "list-bullets",
            size: "xs",
          },
          tooltip: {
            tooltip: i18n.t(
              "CommentsModule.ListToggle.Table",
              "Nachrichten in einer Tabelle anzeigen"
            ),
          },
        },
      ]}
    />
  );
};

export default CommentListToggle;
