import { css } from "emotion";
import { LexoRank } from "lexorank";
import { isNumber } from "lodash";
import { nanoid } from "nanoid";
import React from "react";
import { Field, FieldInputProps, FieldMetaState } from "react-final-form";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Log from "../../../debug/Log";
import { translateNonGenerate } from "../../../utils/Helpers";
import { AbstractStylableComponent } from "../../../utils/abstracts/AbstractStylableComponent";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import {
  GFBaseElement,
  GenericFormsLayoutProps,
} from "../../generic-forms/GFBaseElement";
import GenericFormField from "../../generic-forms/GenericFormField";
import GenericForms, { FormDefinition } from "../../generic-forms/GenericForms";
import { JsonPropertyArray } from "../../generic-forms/util/JsonValidation";
import "./ArrayFormField.scss";
import { FormFieldWrapper } from "./GenericFormFieldsImpl";

const SortableItem = SortableElement(
  ({ identifier, value, renderElementFc }) => (
    <div key={identifier}>{renderElementFc(identifier, value)}</div>
  )
);

const SortableList = SortableContainer(({ items, renderElementFc }) => {
  return (
    <div style={{ height: "100%" }}>
      {items.map(([key, value], index) => (
        <SortableItem
          key={`item-${key}`}
          identifier={key}
          index={index}
          value={value}
          renderElementFc={renderElementFc}
        />
      ))}
    </div>
  );
});
type Props = {
  input: FieldInputProps<any, any>;
  meta: FieldMetaState<any>;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  name: string;
  currentValues: any;
  addForm?: FormDefinition;
  subFormIdentifier?: string;
  orderable?: boolean;
  defaultEntryValue?: any;
  _defaultEntryValue?: string;
} & GenericFormsLayoutProps &
  JsonPropertyArray;
type States = {};

export interface ArrayFormFieldValue {
  [key: string]: {
    orderIndex: string;
    [key: string]: any;
  };
}
class ArrayFormField extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  sortWrapperId = nanoid();

  componentDidMount() {
    this.subscribeActionEvent("delete-child", (data) => {
      const {
        input,
        meta,
        name,
        containerStyleProps,
        currentValues,
        params,
        layout,
        properties,
        addForm,
      } = this.props;
      const value: ArrayFormFieldValue = input.value ? { ...input.value } : {};

      value[data.data["childId"]] = null;
      input.onChange(value);
    });

    this.populateButtonState("delete-child", {
      hidden: false,
      disabled:
        Object.values(this.props.input?.value || {}).filter((e) => !!e)
          .length <= this.props.min,
    });
  }

  // componentWillUnmount() {
  // 	super.componentWillUnmount();
  // }

  componentDidUpdate(prevProps: Props, prevState: States, snapshot) {
    if (this.props.min !== undefined) {
      if (this.props.input.value !== prevProps.input.value) {
        this.populateButtonState("delete-child", {
          hidden: false,
          disabled:
            Object.values(this.props.input.value).filter((e) => !!e).length <=
            this.props.min,
        });
      }
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    // const difference = diff(this.props, nextProps);
    // Log.debug("DIFF", difference);

    // if (Object.keys(difference).length === 0) {
    // 	return false;
    // }
    return true;
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    // this.setState(({ items }) => ({
    // 	items: arrayMove(items, oldIndex, newIndex)
    // }));
  };
  renderContent() {
    const {
      input,
      meta,
      name,
      containerStyleProps,
      currentValues,
      params,
      layout,
      properties,
      addForm,
      orderable,
    } = this.props;

    const value: ArrayFormFieldValue = input.value ? input.value : {};

    if (!orderable) {
      return Object.entries(value)
        .filter((entry) => entry[1] !== null)
        .sort((a, b) =>
          LexoRank.parse(a[1].orderIndex).compareTo(
            LexoRank.parse(b[1].orderIndex)
          )
        )
        .map(([key, obj]) => {
          return (
            <GFBaseElement
              key={key}
              {...layout}
              params={{
                ...this.props.params,
                childId: key,
                allProperties: properties,
                prefix: params.prefix
                  ? `${params.prefix}.${name}.${key}`
                  : `${name}.${key}`,
              }}
            />
          );
        });
    } else {
      return (
        <div id={this.sortWrapperId} style={{ position: "relative" }}>
          <SortableList
            // getContainer={() => {
            // 	return document.getElementById(this.sortWrapperId);
            // }}
            // helperContainer={() => document.getElementById(this.sortWrapperId)}
            // lockAxis={"y"}
            lockToContainerEdges={true}
            onSortEnd={this.onSortEnd}
            items={Object.entries(value)
              .filter((entry) => entry[1] !== null)
              .sort((a, b) =>
                LexoRank.parse(a[1].orderIndex).compareTo(
                  LexoRank.parse(b[1].orderIndex)
                )
              )}
            renderElementFc={(key, value) => (
              <GFBaseElement
                key={key}
                {...layout}
                params={{
                  ...this.props.params,
                  childId: key,
                  allProperties: properties,
                  prefix: params.prefix
                    ? `${params.prefix}.${name}.${key}`
                    : `${name}.${key}`,
                }}
              />
            )}
          />
        </div>
      );
    }
  }

  render() {
    const {
      input,
      meta,
      name,
      containerStyleProps,
      currentValues,
      params,
      layout,
      properties,
      addForm,
      max,
      validation,
    } = this.props;
    // const propsOfForm = (formProps as any).form.mutators.getProps();
    const value: ArrayFormFieldValue = input.value ? input.value : {};
    return (
      <div
        className={`array-form-field  ${
          this.props.style ? css(this.props.style as any) : ""
        }`}
      >
        {this.props.label && (
          <div className="label">{translateNonGenerate(this.props.label)}</div>
        )}
        <FormFieldWrapper {...containerStyleProps}>
          {this.renderContent()}
          {validation.message && (
            <div className={`validation ${validation.level}`}>
              {validation.message}
            </div>
          )}

          {!isNumber(max) ||
            (Object.values(value || {}).filter((e) => !!e).length < max && (
              <>
                {addForm ? (
                  <GenericForms
                    actionIdMapping={{
                      submit: this.props.subFormIdentifier
                        ? this.props.subFormIdentifier + "-submit"
                        : undefined,
                    }}
                    formDefinition={addForm}
                    resetOnSubmit={true}
                    onFormSubmit={(data) => {
                      return new Promise((resolve, reject) => {
                        const entries = Object.entries(value)
                          .filter((entry) => entry[1] !== null)
                          .sort((a, b) =>
                            LexoRank.parse(a[1].orderIndex).compareTo(
                              LexoRank.parse(b[1].orderIndex)
                            )
                          );
                        const lastLexoRank =
                          entries.length > 0
                            ? LexoRank.parse(
                                entries[entries.length - 1][1].orderIndex
                              )
                            : LexoRank.middle();

                        const newValue = {
                          orderIndex: lastLexoRank.genNext().toString(),
                          ...data,
                        };

                        const val = { ...value, [nanoid()]: newValue };
                        input.onChange(val);
                        resolve(val);
                      });
                    }}
                    onFormCancel={() => {}}
                    translateFunc={(key) => (window as any).translate(key)}
                  />
                ) : (
                  <div className={`array-form-field-add`}>
                    <BFButton
                      type="button"
                      onClick={() => {
                        const entries = Object.entries(value)
                          .filter((entry) => entry[1] !== null)
                          .sort((a, b) =>
                            LexoRank.parse(a[1].orderIndex).compareTo(
                              LexoRank.parse(b[1].orderIndex)
                            )
                          );
                        const lastLexoRank =
                          entries.length > 0
                            ? LexoRank.parse(
                                entries[entries.length - 1][1].orderIndex
                              )
                            : LexoRank.middle();
                        Log.debug("##ARR ", entries, lastLexoRank);
                        input.onChange({
                          ...value,
                          [nanoid()]: {
                            orderIndex: lastLexoRank.genNext().toString(),
                            ...(this.props._defaultEntryValue
                              ? this.evaluateExpression(
                                  this.props._defaultEntryValue,
                                  this.props.input.value
                                )
                              : this.props.defaultEntryValue || {}),
                          },
                        });
                      }}
                      text={"Add"}
                      {...this.props.addButtonProps}
                    />
                  </div>
                )}
              </>
            ))}
        </FormFieldWrapper>
      </div>
    );
  }
}

type GFArrayFormProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonPropertyArray;
export const GFArrayFormField: React.FC<GFArrayFormProps> = (props) => {
  const { name, properties } = props;
  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      forceFormSpy={true}
      jsonProperty={props}
      render={(input, meta, name1, jsonProperty, currentValues, prefix) => {
        return (
          <Field name={`${name}.base`}>
            {({ meta: baseMeta }) => (
              <ArrayFormField
                input={input}
                meta={baseMeta}
                currentValues={currentValues}
                name={name}
                validation={{
                  level: "error",
                  message:
                    !baseMeta.active &&
                    (baseMeta.touched ||
                      baseMeta.submitError ||
                      baseMeta.submitFailed)
                      ? baseMeta.error
                      : undefined,
                }}
                {...props}
              />
            )}
          </Field>
        );
      }}
    />
  );
};

// const mapStateToProps = (state: AppState, props: Props) => ({
// 	viewportWidth: Array.isArray(props.style) ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH] : null
// });

export default GFArrayFormField;

(window as any).LexoRank = LexoRank;

(window as any).nanoid = nanoid;
