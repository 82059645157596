import { TableSort } from "../../../model/common/CommonInterfaces";
import { MatchQuery } from "../../../services/DataService";
import {
  InfiniteTableFilterValue,
  InfiniteTableLoading,
} from "../../reducers/application/ApplicationInterface";
import { InfiniteCustomizeParams } from "./application-infinite-table-actions";

export const INFINITE_TABLE_SET_SCROLL = "INFINITE_TABLE_SET_SCROLL";
export const INFINITE_TABLE_INITIALIZE = "INFINITE_TABLE_INITIALIZE";
export const INFINITE_TABLE_SET_SUCCESS = "INFINITE_TABLE_SET_SUCCESS";
export const INFINITE_TABLE_SET_ERROR = "INFINITE_TABLE_SET_ERROR";
export const INFINITE_TABLE_SET_LOADING = "INFINITE_TABLE_SET_LOADING";
export const INFINITE_TABLE_SET_FILTER = "INFINITE_TABLE_SET_FILTER";
export const INFINITE_TABLE_SET_SEARCH = "INFINITE_TABLE_SET_SEARCH";
export const INFINITE_TABLE_SET_SORT = "INFINITE_TABLE_SET_SORT";
export const INFINITE_TABLE_MARK_FOR_RELOAD = "INFINITE_TABLE_MARK_FOR_RELOAD";
export const INFINITE_TABLE_PATCH_DATA = "INFINITE_TABLE_PATCH_DATA";
export const INFINITE_TABLE_SET_MATCHQUERY = "INFINITE_TABLE_SET_MATCHQUERY";
export const INFINITE_TABLE_SET_CUSTOMIZE_PARAMS =
  "INFINITE_TABLE_SET_CUSTOMIZE_PARAMS";
export const INFINITE_TABLE_CLEAR_ALL = "INFINITE_TABLE_CLEAR_ALL";
export const INFINITE_TABLE_CLEAR_TABLE = "INFINITE_TABLE_CLEAR_TABLE";
export const INFINITE_TABLE_ADD_NEW_DATA_ID = "INFINITE_TABLE_ADD_NEW_DATA_ID";
export const INFINITE_TABLE_REMOVE_NEW_DATA_ID =
  "INFINITE_TABLE_REMOVE_NEW_DATA_ID";
export const INFINITE_TABLE_SET_SELECTION = "INFINITE_TABLE_SET_SELECTION";

interface InfiniteTableInit {
  type: typeof INFINITE_TABLE_INITIALIZE;
  tableIdentifier: string;
  url: string;
  sort?: TableSort[];
  asPost?: boolean;
  limitPerRequest?: number;
  loadDelay?: number;
  additionalMatchQuery?: MatchQuery;
  searchTerm?: string;
  hiddenSortFirst?: boolean;
  filterStatus?: InfiniteTableFilterValue[];
}

interface InfiniteTableSetSelection {
  type: typeof INFINITE_TABLE_SET_SELECTION;
  tableIdentifier: string;
  selection: string[];
}
interface InfiniteTableClearTable {
  type: typeof INFINITE_TABLE_CLEAR_TABLE;
  tableIdentifier: string;
}
interface InfiniteTableSetScroll {
  type: typeof INFINITE_TABLE_SET_SCROLL;
  tableIdentifier: string;
  scrollX: number;
  scrollY: number;
}

interface InfiniteTableSetSuccess {
  type: typeof INFINITE_TABLE_SET_SUCCESS;
  tableIdentifier: string;
  data: any;
  lastSkip: number;
  totalCount: number;
  append?: boolean;
}
interface InfiniteTableSetMatchQuery {
  type: typeof INFINITE_TABLE_SET_MATCHQUERY;
  tableIdentifier: string;
  matchQuery?: MatchQuery;
}
interface InfiniteTableSetCustomizeParams {
  type: typeof INFINITE_TABLE_SET_CUSTOMIZE_PARAMS;
  tableIdentifier: string;
  customizeParams?: InfiniteCustomizeParams;
}
interface InfiniteTableSetError {
  type: typeof INFINITE_TABLE_SET_ERROR;
  tableIdentifier: string;
  error: string;
}
interface InfiniteTableMarkForReload {
  type: typeof INFINITE_TABLE_MARK_FOR_RELOAD;
  tableIdentifier: string;
}

interface InfiniteTableSetLoading {
  type: typeof INFINITE_TABLE_SET_LOADING;
  tableIdentifier: string;
  loading: InfiniteTableLoading;
}
interface InfiniteTableSetFilter {
  type: typeof INFINITE_TABLE_SET_FILTER;
  tableIdentifier: string;
  filter: InfiniteTableFilterValue[];
}
interface InfiniteTableSetSort {
  type: typeof INFINITE_TABLE_SET_SORT;
  tableIdentifier: string;
  visibleSort: TableSort;
}
interface InfiniteTableSetSearch {
  type: typeof INFINITE_TABLE_SET_SEARCH;
  tableIdentifier: string;
  searchTerm: string;
}

interface InfiniteTablePatchData {
  type: typeof INFINITE_TABLE_PATCH_DATA;
  tableIdentifier: string;
  dataId: string;
  data: any;
  mode: "overwrite" | "patchRoot" | "merge";
}
interface InfiniteTableAddNewDataId {
  type: typeof INFINITE_TABLE_ADD_NEW_DATA_ID;
  tableIdentifier: string;
  dataId: string;
}
interface InfiniteTableRemoveNewDataId {
  type: typeof INFINITE_TABLE_REMOVE_NEW_DATA_ID;
  tableIdentifier: string;
  dataId: string;
}
interface InfiniteTableClearAll {
  type: typeof INFINITE_TABLE_CLEAR_ALL;
}
export type InfiniteTableActions =
  | InfiniteTableSetSelection
  | InfiniteTableClearAll
  | InfiniteTableSetMatchQuery
  | InfiniteTablePatchData
  | InfiniteTableAddNewDataId
  | InfiniteTableRemoveNewDataId
  | InfiniteTableClearTable
  | InfiniteTableInit
  | InfiniteTableSetSuccess
  | InfiniteTableSetError
  | InfiniteTableMarkForReload
  | InfiniteTableSetLoading
  | InfiniteTableSetFilter
  | InfiniteTableSetCustomizeParams
  | InfiniteTableSetScroll
  | InfiniteTableSetSort
  | InfiniteTableSetSearch;
