import classNames from "classnames";
import { useState } from "react";
import i18n from "../../../../../i18n";
import ArrayUtils from "../../../../../utils/ArrayUtils";
import BFCheckbox from "../../../forms/checkbox/BFCheckbox";
import BFInput from "../../../forms/input/BFInput";
import BFButton from "../../../general/Button/BFButton";
import "./OverlayMultipleSelection.scss";
import OverlayTitle from "./OverlayTitle";
type Entry = {
  label: string;
  value: string | number;
  searchTags?: string[];
};
interface OverlayMultipleSelectionProps {
  title: string;
  entries: Entry[];
  value: (string | number)[];
  onChange: (value: (string | number)[]) => void;
  embedded?: boolean;
}
const OverlayMultipleSelection = (props: OverlayMultipleSelectionProps) => {
  const [search, setSearch] = useState<string>("");
  const entriesToRender = search
    ? ArrayUtils.fuzzyFilter(search, props.entries, {
        keys: ["label", "searchTags"],
      }).map((e) => e.item)
    : props.entries;
  return (
    <div
      className={classNames(`overlay-multiple-selection`, {
        embedded: props.embedded,
      })}
    >
      <OverlayTitle title={props.title} onClear={() => props.onChange(null)} />
      <div className="search">
        <BFInput
          placeholder={i18n.t("BFTableSearch.searchPlaceholder", "Suche...")}
          appearance="clear"
          value={search}
          onChange={(value: string) => setSearch(value)}
        />
      </div>
      <div className="content">
        {entriesToRender.length === 0 && (
          <div className={`empty-text`}>
            {i18n.t(
              "BFFilterbar.Multiple.noEntries",
              "Keine Einträge gefunden"
            )}
          </div>
        )}
        {entriesToRender.map((e) => (
          <div className="selection-element" key={e.value}>
            <BFCheckbox
              checked={(props.value || [])?.indexOf(e.value) !== -1}
              onChange={(_value, checked) =>
                checked
                  ? props.onChange([...(props.value || []), e.value])
                  : props.onChange(
                      props.value?.filter(
                        (valueEntry) => valueEntry !== e.value
                      )
                    )
              }
            >
              {e.label}
            </BFCheckbox>
          </div>
        ))}
      </div>
      <div className="footer">
        <BFButton
          appearance="link"
          onClick={() => props.onChange(props.entries.map((e) => e.value))}
        >
          {i18n.t("BFFilterbar.Multiple.selectAll", "Alle auswählen")}
        </BFButton>
        <BFButton appearance="link" onClick={() => props.onChange([])}>
          {i18n.t("BFFilterbar.Multiple.deselectAll", "Alle abwählen")}
        </BFButton>
      </div>
    </div>
  );
};

export default OverlayMultipleSelection;
