// ignore typescript errors - don't know why there is an issue with them.
import {
  // @ts-ignore
  MangoQuerySelector,
  // @ts-ignore
  RxCollectionCreator,
  // @ts-ignore
  RxDatabase,
  // @ts-ignore
  addRxPlugin,
  // @ts-ignore
  createRxDatabase,
} from "rxdb";
// @ts-ignore
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";

// @ts-ignore
import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode";
import Log from "../debug/Log";

if ((process.env.REACT_APP_STAGE || "local") === "local") {
  addRxPlugin(RxDBDevModePlugin);
}
export type BaseDBType = {
  id: string;
};
export type IndexDBSchema = RxCollectionCreator<BaseDBType & any>;

class IndexDB {
  db: RxDatabase;
  name: string;
  schema: { [key: string]: IndexDBSchema };

  constructor(name: string, schema: { [key: string]: IndexDBSchema }) {
    this.name = name;
    this.schema = schema;
  }
  async init() {
    this.db = await createRxDatabase({
      name: this.name,
      storage: getRxStorageDexie(),
      ignoreDuplicate: true,
    });
    try {
      await this.db.addCollections(this.schema);
    } catch (err) {
      Log.debug("IndexDB init error", err);
      Log.debug("Relaunched db: ", this.name);
      await this.db.remove();

      this.db = await createRxDatabase({
        name: this.name,
        storage: getRxStorageDexie(),
      });

      await this.db.addCollections(this.schema);
    }
  }

  async destroy() {
    if (this.db && !this.db.destroyed) {
      this.db.destroy();
    }
  }

  async get(collection: string, id: string) {
    return await this.db?.[collection]
      ?.findOne({ selector: { id: id } })
      .exec();
  }
  async upsert<T extends BaseDBType>(collection: string, data: T) {
    await this.db?.[collection]?.upsert(data);
  }
  async insert<T extends BaseDBType>(collection: string, data: T) {
    await this.db?.[collection]?.insert(data);
  }
  async remove(collection: string, id: string) {
    if (await this.get(collection, id)) {
      await this.db?.[collection]?.findOne({ selector: { id: id } }).remove();
    }
  }
  async update<T extends BaseDBType>(
    collection: string,
    id: string,
    data: Partial<T>
  ) {
    await this.db?.[collection]?.findOne({ selector: { id: id } }).update(data);
  }

  async observe(collection: string, selector: MangoQuerySelector<any>) {
    try {
      const observable = await this.db?.[collection]?.find({
        selector: selector,
      })?.$;

      return observable;
    } catch (err) {
      return null;
    }
  }
  async find(collection: string, selector: MangoQuerySelector<any>) {
    try {
      const data = await this.db?.[collection]
        ?.find({
          selector: selector,
        })
        ?.exec();

      return data;
    } catch (err) {
      return null;
    }
  }
  async findOne(collection: string, selector: MangoQuerySelector<any>) {
    try {
      const data = await this.db?.[collection]
        ?.findOne({
          selector: selector,
        })
        ?.exec();

      return data;
    } catch (err) {
      return null;
    }
  }
}
export default IndexDB;
