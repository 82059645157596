import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import * as CryptoJS from "crypto-js";
import moment from "moment";
import Log from "../../../../debug/Log";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import {
  clearFlexCacheData,
  setConstantData,
  setFlexCacheData,
} from "../../../../redux/actions/application/application-actions";
import { store } from "../../../../redux/store";
import DataBus from "../../../../services/DataBus";
import LanguageService from "../../../../services/LanguageService";
import SubmitService from "../../../../services/SubmitService";
import SocketService from "../../../../services/socket/SocketService";
import { BFToast } from "../../../../utils/BFToast";
import { HTTP } from "../../../../utils/Http";
import StringUtils from "../../../../utils/StringUtils";
import CSVUtils from "../../../../utils/abstracts/CSVUtils";
import CashBudgetUtils from "../CashBudgetUtils";
import { CBRentalTargetPosition } from "../views/portfolio/interfaces/CBPortfolioAsset";

type BudgetLoanData = {
  _id: { type: string; year: number; month: number };
  values: {
    interest: number;
    repayment: number;
  };
};
type BudgetValueData = {
  _id: { year: number; month: number };
  categories: {
    actualValue: number;
    category: string;
    displayValue: number;
    targetValue: number;
  }[];
};
type ResultData = {
  values: BudgetValueData[];
  loan: BudgetLoanData[];
};

export interface CashBudgetEntry {
  _id: string;
  guid: string;
  account: string;
  date: moment.Moment;
  amount: number;
  transferReason: string;
  category: string;
  changedCategory: string;
  entity: string;
  plannedBy?: string;
  comments?: { comment: String; user: string; date: moment.Moment }[];
}
export interface CashBudgetSpan {
  balanceStart: number;
  balanceEnd: number;
  cashBudgetEntries: CashBudgetEntry[];
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
}
export type CashBudgetRequestType = "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY";
export const CASH_BUDGET_PROGNOSE_UPDATED = "CASH_BUDGET_PROGNOSE_UPDATED";
export const CASH_BUDGET_DATA_PREFIX = "_CASHBUDGET_DATA_";
export const CASH_BUDGET_PLAN_PREFIX = "_CASHBUDGET_PLAN_";
export const CASH_BUDGET_BALANCES_PREFIX = "_CASHBUDGET_BALANCES_";
export const CASH_BUDGET_ALL_OBJECTS_VAL = "#all";
export const CASH_BUDGET_UNASSIGNED_OBJECTS_VAL = "unassigned";
export const CASH_BUDGET_FILTER_PREFIX_OBJECT = "object#";
export const CASH_BUDGET_FILTER_PREFIX_ACCOUNT = "account#";
export type CashBudgetResultData = {
  state: "error" | "loading" | "success";
  data?: any;
  error?: any;
};

const FORECAST_NOTIFICATION_ID = "_cashBudget_forecast_notification";

class CashBudgetServiceClass {
  timeoutForecase = null;
  forcastStarted = false;
  showedSimpleNotification = false;

  constructor() {
    DataBus.subscribe("cashbudgetConfigLoaded", () => {
      // this.updateNotification();
    });
    SocketService.subscribe("BUDGET_UPDATED", (data) => {
      const {
        obj: { count, users },
      } = data;
      this.updateModificationCounter("cashBudgetConfigImmo", count, users);
    });
    SocketService.subscribe("FORECAST_UPDATED", (data) => {
      this.receivedForecastUpdate();
    });
    SocketService.subscribe("FORECAST_ENDED", (data) => {
      this.receivedForecastResult(false);
    });

    SocketService.subscribe("STARMONEY_UPDATE", (data) => {
      this.clearFlexCaches();
    });
  }

  async createPortfolioObjectConfig(
    objectId: string,
    positions: CBRentalTargetPosition[]
  ) {
    await SubmitService.submitDataAsync({
      assetType: AssetTypes.Portfolio.ObjectConfig,
      data: {
        data: {
          objectId,
          rentalTargetPosition: positions,
        },
      },
      type: "asset",
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    });
  }

  updateModificationCounter(configId, count, modificators) {
    const state = store.getState();
    const config = state.application.constants[configId];
    if (config) {
      store.dispatch(
        setConstantData(configId, {
          ...config,
          countModifications: count,
          modificators: modificators,
        })
      );
    }
  }

  clearFlexCaches() {
    Log.info("Cleared cashbudget flex caches");
    store.dispatch(clearFlexCacheData(CASH_BUDGET_DATA_PREFIX));
    // store.dispatch(clearFlexCacheData(CASH_BUDGET_BALANCES_PREFIX));
  }
  clearFlexCachesPlan() {
    Log.info("Cleared cashbudget flex plan caches");
    store.dispatch(clearFlexCacheData(CASH_BUDGET_PLAN_PREFIX));
    // store.dispatch(clearFlexCacheData(CASH_BUDGET_BALANCES_PREFIX));
  }

  receivedForecastUpdate() {
    clearTimeout(this.timeoutForecase);

    this.timeoutForecase = setTimeout(() => {
      this.receivedForecastResult(true);
    }, 5000);
  }
  receivedForecastResult(timeout: boolean) {
    this.forcastStarted = false;
    clearTimeout(this.timeoutForecase);
    BFToast.open({
      type: timeout ? "warning" : "info",
      duration: timeout ? 10000 : 5000,
      content: timeout
        ? i18n.t(
            "cb:CashBudgetService.timeoutForecastResult",
            "Zeitüberschreitung bei der Prognose, wir versuchen trotzdem die Daten erneut zu laden. Sollten die Daten nicht wie erwartet neu prognosziert sein, laden Sie die Seite bitte in einigen Minuten neu."
          )
        : i18n.t(
            "cb:CashBudgetService.newForecast",
            "Neue Prognose erhalten. Daten werden aktualisiert..."
          ),
    });
    // BFNotification.open({
    // 	type: timeout ? "info" : "success",
    // 	description: timeout
    // 		? "Zeitüberschreitung bei der Prognose, wir versuchen trotzdem die Daten erneut zu laden. Sollten die Daten nicht wie erwartet neu prognosziert sein, laden Sie die Seite in einigen Minuten neu."
    // 		: "Neue Prognose erhalten. Daten werden neugeladen."
    // });
    this.clearFlexCaches();
    this.updateModificationCounter("cashBudgetConfigImmo", 0, []);
    DataBus.emit(CASH_BUDGET_PROGNOSE_UPDATED, {});
  }

  requestBalances(types: string[], force?: boolean) {
    const state = store.getState();

    const param = "balance";
    const identifier = CASH_BUDGET_BALANCES_PREFIX;

    if (
      force ||
      !state.application.cache.flex[identifier] ||
      !state.application.cache.flex[identifier][param] ||
      state.application.cache.flex[identifier][param].state === "error"
    ) {
      store.dispatch(
        setFlexCacheData(identifier, param, {
          state: "loading",
        })
      );

      HTTP.post({
        target: "STATISTIC",
        url: `query/BUDGET_BALANCE/2`,
        bodyParams: { types },
      })
        .then((data) => {
          store.dispatch(
            setFlexCacheData(identifier, param, {
              state: "success",
              data: data,
            })
          );
        })
        .catch((err) => {
          store.dispatch(
            setFlexCacheData(identifier, param, {
              state: "error",
              error: err,
            })
          );
        });
    }
    return param;
  }

  convertFilterData(filterObject: string) {
    if (
      filterObject &&
      filterObject.indexOf(CASH_BUDGET_FILTER_PREFIX_ACCOUNT) === 0
    ) {
      return {
        accounts:
          filterObject.indexOf(CASH_BUDGET_UNASSIGNED_OBJECTS_VAL) === -1
            ? [filterObject.substr(CASH_BUDGET_FILTER_PREFIX_ACCOUNT.length)]
            : CASH_BUDGET_UNASSIGNED_OBJECTS_VAL,
        objects: null,
      };
    }
    if (
      filterObject &&
      filterObject.indexOf(CASH_BUDGET_FILTER_PREFIX_OBJECT) === 0
    ) {
      return {
        accounts: null,
        objects:
          filterObject.indexOf(CASH_BUDGET_UNASSIGNED_OBJECTS_VAL) === -1
            ? [filterObject.substr(CASH_BUDGET_FILTER_PREFIX_OBJECT.length)]
            : CASH_BUDGET_UNASSIGNED_OBJECTS_VAL,
      };
    }
    return {
      accounts: null,
      objects: null,
    };
  }

  getAccountAndObjectByFilter(
    entities: string[],
    accounts: string[] | string,
    objects: string[] | string
  ): { filterAccounts: string[]; filterObjects?: string[] } {
    let filterAccounts;
    if (typeof accounts === "string") {
      if (accounts === CASH_BUDGET_UNASSIGNED_OBJECTS_VAL) {
        throw new Error("not implemented functionality");
      } else {
        throw new Error("not implemented functionality");
      }
    } else if (accounts && accounts.length > 0) {
      filterAccounts = accounts;
    } else {
      filterAccounts = OrgaStruct.getAllBankAccounts(undefined, true)
        .filter((e) => entities.includes(e.entityId))
        .map((e) => e._id);
    }

    let filterObjects = undefined;

    if (typeof objects === "string") {
      if (objects === CASH_BUDGET_UNASSIGNED_OBJECTS_VAL) {
        throw new Error("not implemented functionality");
      } else {
        throw new Error("not implemented functionality");
      }
    } else if (objects && objects.length > 0) {
      filterObjects = objects;
    }

    return {
      filterAccounts,
      filterObjects,
    };
  }

  requestCompleteData(
    types: string[],
    statisticId: string,
    entities: string[],
    accounts: string[] | string,
    objects: string[] | string,
    from: moment.Moment,
    to: moment.Moment,
    comparisonRequest?: boolean
  ) {
    const { filterAccounts, filterObjects } = this.getAccountAndObjectByFilter(
      entities,
      accounts,
      objects
    );

    return this.requestCompleteDataIntern(
      types,
      statisticId,
      filterAccounts,
      filterObjects,
      from,
      to,
      comparisonRequest
    );
  }

  requestCompleteDataIntern(
    types: string[],
    statisticId: string,
    accounts: undefined | string[],
    objects: undefined | string[],
    from: moment.Moment,
    to: moment.Moment,
    comparisonRequest?: boolean
  ) {
    const usedPrefix = comparisonRequest
      ? CASH_BUDGET_PLAN_PREFIX
      : CASH_BUDGET_DATA_PREFIX;
    const state = store.getState();
    const filter = {
      fromDate: from.utc(true).toDate().toISOString(),
      toDate: to.utc(true).toDate().toISOString(),
      // objectIds: objects ? objects : undefined,
      accounts: objects
        ? objects.map(
            (objectId) => CashBudgetUtils.getBankAccountByObject(objectId)?._id
          )
        : accounts
        ? accounts
        : undefined,
      // type: statisticId
    };

    const filterHash = CryptoJS.MD5(
      statisticId + JSON.stringify(filter)
    ).toString();

    if (
      !state.application.cache.flex[usedPrefix] ||
      !state.application.cache.flex[usedPrefix][filterHash] ||
      state.application.cache.flex[usedPrefix][filterHash].state === "error"
    ) {
      store.dispatch(
        setFlexCacheData(usedPrefix, filterHash, { state: "loading" })
      );

      HTTP.post({
        target: "STATISTIC",
        url: `query/BUDGET_BALANCE/2`,
        bodyParams: {
          types,
          date: from.utc(true).startOf("year").toDate().toISOString(),
          accounts: filter.accounts,
        },
      })
        .then((balanceData) => {
          setTimeout(() => {
            HTTP.post({
              target: "STATISTIC",
              url: `query/BUDGET_DATA/3`,
              bodyParams: filter,
            })
              .then((data: ResultData) => {
                const startBalance = balanceData
                  .filter((dataEntry) =>
                    accounts.find((acc) => acc === dataEntry._id)
                  )
                  .map((e) => e.balance)
                  .reduce((prev, current) => prev + current, 0);

                let result;
                if (statisticId === "MONTHLY") {
                  const year = from.year();
                  result = new Array(12)
                    .fill(0)
                    .map((_v, index) => {
                      const month = index + 1;

                      return {
                        _id: {
                          month,
                          year,
                        },
                        categories:
                          data?.values.find(
                            (e) => e._id.month === month && e._id.year === year
                          )?.categories || [],
                        loan:
                          data?.loan
                            ?.filter(
                              (e) =>
                                e._id.month === month && e._id.year === year
                            )
                            .map((e) => ({ type: e._id.type, ...e.values })) ||
                          [],
                      };
                    })
                    .reduce(
                      (prev, current) => [
                        ...prev,
                        {
                          ...current,
                          balance:
                            prev.length === 0
                              ? startBalance
                              : prev[prev.length - 1].balance +
                                prev[prev.length - 1].categories.reduce(
                                  (cPrev, cCur) => cPrev + cCur.displayValue,
                                  0
                                ),
                        },
                      ],
                      []
                    );
                } else if (statisticId === "DAILY") {
                  throw new Error("not implemented functionality");
                } else {
                  throw new Error("not implemented functionality");
                }

                store.dispatch(
                  setFlexCacheData(usedPrefix, filterHash, {
                    state: "success",
                    data: result,
                  })
                );
              })
              .catch((err) => {
                store.dispatch(
                  setFlexCacheData(usedPrefix, filterHash, {
                    state: "error",
                    error: err,
                  })
                );
              });
          }, 100);
        })
        .catch((err) => {
          store.dispatch(
            setFlexCacheData(usedPrefix, filterHash, {
              state: "error",
              error: err,
            })
          );
        });
    }
    return filterHash;
  }

  extraxtDailyData(
    data: CashBudgetSpan,
    unit: "day" | "week" | "month" | "year"
  ): CashBudgetSpan[] {
    const spans: CashBudgetSpan[] = [];
    const current = data.dateFrom.clone();

    let currentAmount = data.balanceStart;
    while (current.isBefore(data.dateTo)) {
      const startAmount = currentAmount;
      const dayStart = current.clone().startOf(unit);
      const dayEnd = current.clone().endOf(unit);

      const entries = data.cashBudgetEntries.filter(
        (entry) => entry.date.isAfter(dayStart) && entry.date.isBefore(dayEnd)
      );
      let endAmount = startAmount;

      entries.forEach((entry) => (endAmount += entry.amount));

      spans.push({
        balanceEnd: endAmount,
        balanceStart: startAmount,
        cashBudgetEntries: entries,
        dateFrom: dayStart,
        dateTo: dayEnd,
      });

      current.add(1, unit);
      currentAmount = endAmount;
    }

    return spans;
  }
  getAvailableYears() {
    let indexYear = CashBudgetUtils.getEarliestStartDate()?.year();
    const endYear = moment().year() + 3;
    const years = [];
    while (indexYear < endYear) {
      years.push(indexYear);
      indexYear++;
    }
    return years;
  }

  generateCSVExport(
    filename: string,
    displayType: "DAILY" | "MONTHLY",
    entries: any[],
    comparisonEntries: any[]
  ) {
    const SUM = i18n.t("Global.Labels.total");

    const adaptToLang = (value: string) => {
      // if (navigator.language === "de-DE") {
      return value.replace(/\./g, ",");
      // }
      // return value;
    };

    const normalize = (val: number | undefined) =>
      val === null ||
      val === undefined ||
      Number.isNaN(val) ||
      StringUtils.normalizeDecimal(val) === 0
        ? ""
        : adaptToLang("" + StringUtils.normalizeDecimal(val));
    const dataFormatter = (date: moment.Moment) => {
      if (displayType === "DAILY") {
        return date.format("DD.MM.YYYY");
      } else {
        return date.format("MMMM YYYY");
      }
    };
    const rows = [];

    const categoriesPositive = CashBudgetUtils.getAllCategories()?.filter(
      (cat) => cat.data.kind === "income"
    );
    const categoriesNegative = CashBudgetUtils.getAllCategories()?.filter(
      (cat) => cat.data.kind === "expense"
    );

    //header
    const headerRow = [""];
    if (comparisonEntries) {
      entries.forEach((entry) => {
        headerRow.push(dataFormatter(entry.dateFrom));
        headerRow.push(dataFormatter(entry.dateFrom));
        headerRow.push(dataFormatter(entry.dateFrom));
      });
    } else {
      entries.forEach((entry) => {
        headerRow.push(dataFormatter(entry.dateFrom));
      });
    }
    if (displayType === "MONTHLY") {
      if (comparisonEntries) {
        headerRow.push(SUM);
        headerRow.push(SUM);
        headerRow.push(SUM);
      } else {
        headerRow.push(SUM);
      }
    }
    rows.push(headerRow);

    //subheader
    if (comparisonEntries) {
      const subRow = [""];
      entries.forEach(() => {
        subRow.push(i18n.t("cb:Label.Is", "IST"));
        subRow.push(i18n.t("cb:Label.Should", "SOLL"));
        subRow.push(i18n.t("cb:Label.Difference", "DIFFERENZ"));
      });
      if (displayType === "MONTHLY") {
        subRow.push(i18n.t("cb:Label.Is", "IST"));
        subRow.push(i18n.t("cb:Label.Should", "SOLL"));
        subRow.push(i18n.t("cb:Label.Difference", "DIFFERENZ"));
      }
      rows.push(subRow);
    }

    //AnfangsKontostand
    let row = [i18n.t("cb:Label.StartBalance", "Anfang Kontostand")];
    if (comparisonEntries) {
      entries.forEach((entry, index) => {
        row.push("" + normalize(entry.balanceStart));
        row.push("" + normalize(comparisonEntries[index].balanceStart));
        row.push(
          "" +
            normalize(
              comparisonEntries[index].balanceStart - entry.balanceStart
            )
        );
      });
    } else {
      entries.forEach((entry, index) => {
        row.push("" + normalize(entry.balanceStart));
      });
    }

    if (displayType === "MONTHLY") {
      if (comparisonEntries) {
        row.push("" + normalize(entries[0].balanceStart));
        row.push("" + normalize(comparisonEntries[0].balanceStart));
        row.push(
          "" +
            normalize(
              comparisonEntries[0].balanceStart - entries[0].balanceStart
            )
        );
      } else {
        row.push("" + normalize(entries[0].balanceStart));
      }
    }

    rows.push(row);
    //Einzahlungen
    row = [i18n.t("cb:Label.CashInflow", "Einzahlungen/Geldflüsse")];
    if (comparisonEntries) {
      entries.forEach(() => {
        row.push("");
        row.push("");
        row.push("");
      });
    } else {
      entries.forEach(() => {
        row.push("");
      });
    }
    if (displayType === "MONTHLY") {
      if (comparisonEntries) {
        row.push("");
        row.push("");
        row.push("");
      } else {
        row.push("");
      }
    }
    rows.push(row);

    //ForEachCategories
    categoriesPositive.forEach((category, index) => {
      row = [LanguageService.translateLabel(category.data.displayName)];
      if (comparisonEntries) {
        entries.forEach((entry, index) => {
          row.push(
            "" + normalize(entry.categories[category._id]?.displayValue)
          );
          row.push(
            "" +
              normalize(
                comparisonEntries[index].categories[category._id]?.displayValue
              )
          );
          row.push(
            "" +
              normalize(
                entry.categories[category._id].displayValue -
                  comparisonEntries[index].categories[category._id].displayValue
              )
          );
        });
      } else {
        entries.forEach((entry, index) => {
          row.push("" + normalize(entry.categories[category._id].displayValue));
        });
      }

      if (displayType === "MONTHLY") {
        const isSum = entries
          .map((entry) => entry.categories[category._id].displayValue || 0)
          .reduce((prev, current) => prev + current, 0);
        if (comparisonEntries) {
          const shouldSum = comparisonEntries
            .map((entry) => entry.categories[category._id].displayValue || 0)
            .reduce((prev, current) => prev + current, 0);
          const diffSum = isSum - shouldSum;

          row.push("" + normalize(isSum));
          row.push("" + normalize(shouldSum));
          row.push("" + normalize(diffSum));
        } else {
          row.push("" + normalize(isSum));
        }
      }

      rows.push(row);
    });

    // Einnahmen Gesamt
    row = [i18n.t("cb:Label.IncomeAll", "Einnahmen Gesamt")];
    if (comparisonEntries) {
      entries.forEach((entry, index) => {
        const isSum = categoriesPositive
          .map((cat) => entry.categories[cat._id].displayValue || 0)
          .reduce((prev, current) => prev + current, 0);
        const shouldSum = categoriesPositive
          .map(
            (cat) =>
              comparisonEntries[index].categories[cat._id].displayValue || 0
          )
          .reduce((prev, current) => prev + current, 0);
        const diffSum = isSum - shouldSum;

        row.push("" + normalize(isSum));
        row.push("" + normalize(shouldSum));
        row.push("" + normalize(diffSum));
      });
    } else {
      entries.forEach((entry, index) => {
        const isSum = categoriesPositive
          .map((cat) => entry.categories[cat._id].displayValue || 0)
          .reduce((prev, current) => prev + current, 0);
        row.push("" + normalize(isSum));
      });
    }

    if (displayType === "MONTHLY") {
      const isSum = categoriesPositive
        .map((cat) =>
          entries
            .map((e) => e.categories[cat._id].displayValue || 0)
            .reduce((prev, current) => prev + current, 0)
        )
        .reduce((prev, current) => prev + current, 0);
      if (comparisonEntries) {
        const shouldSum = categoriesPositive
          .map((cat) =>
            comparisonEntries
              .map((e) => e.categories[cat._id].displayValue || 0)
              .reduce((prev, current) => prev + current, 0)
          )
          .reduce((prev, current) => prev + current, 0);
        const diffSum = isSum - shouldSum;

        row.push("" + normalize(isSum));
        row.push("" + normalize(shouldSum));
        row.push("" + normalize(diffSum));
      } else {
        row.push("" + normalize(isSum));
      }
    }

    rows.push(row);

    // let incomeAll = 0,
    //   outgoingAll = 0;
    // categoriesPositive.forEach((cat) => {
    // 	incomeAll += cashBudgetSpan.categories[cat.id] || 0;
    // });
    // categoriesNegative.forEach((cat) => {
    // 	outgoingAll += cashBudgetSpan.categories[cat.id] || 0;
    // });

    // Summe verfügbarer Mittel
    row = [i18n.t("cb:Label.SumAvailableFunds", "Summe verfügbarer Mittel")];
    if (comparisonEntries) {
      entries.forEach((entry, index) => {
        const isSum =
          categoriesPositive
            .map((cat) => entry.categories[cat._id].displayValue || 0)
            .reduce((prev, current) => prev + current, 0) + entry.balanceStart;
        const shouldSum =
          categoriesPositive
            .map(
              (cat) =>
                comparisonEntries[index].categories[cat._id].displayValue || 0
            )
            .reduce((prev, current) => prev + current, 0) +
          comparisonEntries[index].balanceStart;
        const diffSum = isSum - shouldSum;

        row.push("" + normalize(isSum));
        row.push("" + normalize(shouldSum));
        row.push("" + normalize(diffSum));
      });
    } else {
      entries.forEach((entry, index) => {
        const isSum =
          categoriesPositive
            .map((cat) => entry.categories[cat._id].displayValue || 0)
            .reduce((prev, current) => prev + current, 0) + entry.balanceStart;
        row.push("" + normalize(isSum));
      });
    }

    if (displayType === "MONTHLY") {
      const isSum =
        categoriesPositive
          .map((cat) =>
            entries
              .map((e) => e.categories[cat._id].displayValue || 0)
              .reduce((prev, current) => prev + current, 0)
          )
          .reduce((prev, current) => prev + current, 0) +
        entries[0].balanceStart;
      if (comparisonEntries) {
        const shouldSum =
          categoriesPositive
            .map((cat) =>
              comparisonEntries
                .map((e) => e.categories[cat._id].displayValue || 0)
                .reduce((prev, current) => prev + current, 0)
            )
            .reduce((prev, current) => prev + current, 0) +
          comparisonEntries[0].balanceStart;
        const diffSum = isSum - shouldSum;

        row.push("" + normalize(isSum));
        row.push("" + normalize(shouldSum));
        row.push("" + normalize(diffSum));
      } else {
        row.push("" + normalize(isSum));
      }
    }

    rows.push(row);

    // Auszahlungen / Zahlungsausgänge
    row = [i18n.t("cb:Label.Payout", "Auszahlungen/Zahlungsausgänge")];
    if (comparisonEntries) {
      entries.forEach(() => {
        row.push("");
        row.push("");
        row.push("");
      });
    } else {
      entries.forEach(() => {
        row.push("");
      });
    }
    if (displayType === "MONTHLY") {
      if (comparisonEntries) {
        row.push("");
        row.push("");
        row.push("");
      } else {
        row.push("");
      }
    }
    rows.push(row);

    // ForEachCategoriesNegativ
    categoriesNegative.forEach((category, index) => {
      row = [LanguageService.translateLabel(category.data.displayName)];
      if (comparisonEntries) {
        entries.forEach((entry, index) => {
          row.push("" + normalize(entry.categories[category._id].displayValue));
          row.push(
            "" +
              normalize(
                comparisonEntries[index].categories[category._id].displayValue
              )
          );
          row.push(
            "" +
              normalize(
                entry.categories[category._id].displayValue -
                  comparisonEntries[index].categories[category._id].displayValue
              )
          );
        });
      } else {
        entries.forEach((entry, index) => {
          row.push("" + normalize(entry.categories[category._id].displayValue));
        });
      }

      if (displayType === "MONTHLY") {
        const isSum = entries
          .map((entry) => entry.categories[category._id].displayValue || 0)
          .reduce((prev, current) => prev + current, 0);
        if (comparisonEntries) {
          const shouldSum = comparisonEntries
            .map((entry) => entry.categories[category._id].displayValue || 0)
            .reduce((prev, current) => prev + current, 0);
          const diffSum = isSum - shouldSum;

          row.push("" + normalize(isSum));
          row.push("" + normalize(shouldSum));
          row.push("" + normalize(diffSum));
        } else {
          row.push("" + normalize(isSum));
        }
      }

      rows.push(row);
    });

    // Ausgaben Gesamt

    row = [i18n.t("cb:Label.ExpenseAll", "Ausgaben Gesamt")];
    if (comparisonEntries) {
      entries.forEach((entry, index) => {
        const isSum = categoriesNegative
          .map((cat) => entry.categories[cat._id].displayValue || 0)
          .reduce((prev, current) => prev + current, 0);
        const shouldSum = categoriesNegative
          .map(
            (cat) =>
              comparisonEntries[index].categories[cat._id].displayValue || 0
          )
          .reduce((prev, current) => prev + current, 0);
        const diffSum = isSum - shouldSum;

        row.push("" + normalize(isSum));
        row.push("" + normalize(shouldSum));
        row.push("" + normalize(diffSum));
      });
    } else {
      entries.forEach((entry, index) => {
        const isSum = categoriesNegative
          .map((cat) => entry.categories[cat._id].displayValue || 0)
          .reduce((prev, current) => prev + current, 0);
        row.push("" + normalize(isSum));
      });
    }

    if (displayType === "MONTHLY") {
      const isSum = categoriesNegative
        .map((cat) =>
          entries
            .map((e) => e.categories[cat._id].displayValue || 0)
            .reduce((prev, current) => prev + current, 0)
        )
        .reduce((prev, current) => prev + current, 0);
      if (comparisonEntries) {
        const shouldSum = categoriesNegative
          .map((cat) =>
            comparisonEntries
              .map((e) => e.categories[cat._id].displayValue || 0)
              .reduce((prev, current) => prev + current, 0)
          )
          .reduce((prev, current) => prev + current, 0);
        const diffSum = isSum - shouldSum;

        row.push("" + normalize(isSum));
        row.push("" + normalize(shouldSum));
        row.push("" + normalize(diffSum));
      } else {
        row.push("" + normalize(isSum));
      }
    }

    rows.push(row);

    // Cashflow
    row = [i18n.t("cb:Label.Cashflow", "Cashflow")];
    if (comparisonEntries) {
      entries.forEach((entry, index) => {
        const isSumIncome = categoriesPositive
          .map((cat) => entry.categories[cat._id].displayValue || 0)
          .reduce((prev, current) => prev + current, 0);
        const shouldSumIncome = categoriesPositive
          .map(
            (cat) =>
              comparisonEntries[index].categories[cat._id].displayValue || 0
          )
          .reduce((prev, current) => prev + current, 0);
        const diffSumIncome = isSumIncome - shouldSumIncome;

        const isSumOut = categoriesNegative
          .map((cat) => entry.categories[cat._id].displayValue || 0)
          .reduce((prev, current) => prev + current, 0);
        const shouldSumOut = categoriesNegative
          .map(
            (cat) =>
              comparisonEntries[index].categories[cat._id].displayValue || 0
          )
          .reduce((prev, current) => prev + current, 0);
        const diffSumOut = isSumOut - shouldSumOut;

        row.push("" + normalize(isSumIncome + isSumOut));
        row.push("" + normalize(shouldSumIncome + shouldSumOut));
        row.push("" + normalize(diffSumIncome + diffSumOut));
      });
    } else {
      entries.forEach((entry, index) => {
        const isSumOut = categoriesNegative
          .map((cat) => entry.categories[cat._id].displayValue || 0)
          .reduce((prev, current) => prev + current, 0);
        const isSumIn = categoriesPositive
          .map((cat) => entry.categories[cat._id].displayValue || 0)
          .reduce((prev, current) => prev + current, 0);
        row.push("" + normalize(isSumIn + isSumOut));
      });
    }
    if (displayType === "MONTHLY") {
      const isSumOut = categoriesNegative
        .map((cat) =>
          entries
            .map((e) => e.categories[cat._id].displayValue || 0)
            .reduce((prev, current) => prev + current, 0)
        )
        .reduce((prev, current) => prev + current, 0);
      const isSumIn = categoriesPositive
        .map((cat) =>
          entries
            .map((e) => e.categories[cat._id].displayValue || 0)
            .reduce((prev, current) => prev + current, 0)
        )
        .reduce((prev, current) => prev + current, 0);
      if (comparisonEntries) {
        const shouldSumOut = categoriesNegative
          .map((cat) =>
            comparisonEntries
              .map((e) => e.categories[cat._id].displayValue || 0)
              .reduce((prev, current) => prev + current, 0)
          )
          .reduce((prev, current) => prev + current, 0);
        const shouldSumIn = categoriesPositive
          .map((cat) =>
            comparisonEntries
              .map((e) => e.categories[cat._id].displayValue || 0)
              .reduce((prev, current) => prev + current, 0)
          )
          .reduce((prev, current) => prev + current, 0);
        const diffSumIn = isSumIn - shouldSumIn;
        const diffSumOut = isSumOut - shouldSumOut;

        row.push("" + normalize(isSumIn + isSumOut));
        row.push("" + normalize(shouldSumIn + shouldSumOut));
        row.push("" + normalize(diffSumIn + diffSumOut));
      } else {
        row.push("" + normalize(isSumIn + isSumOut));
      }
    }
    rows.push(row);

    // Liquide Mittel (Kontostand)
    row = [i18n.t("cb:Label.LiquidResources", "Liquide Mittel (Kontostand)")];
    if (comparisonEntries) {
      entries.forEach((entry, index) => {
        row.push("" + normalize(entry.balanceEnd));
        row.push("" + normalize(comparisonEntries[index].balanceEnd));
        row.push(
          "" + normalize(comparisonEntries[index].balanceEnd - entry.balanceEnd)
        );
      });
    } else {
      entries.forEach((entry, index) => {
        row.push("" + normalize(entry.balanceEnd));
      });
    }
    if (displayType === "MONTHLY") {
      if (comparisonEntries) {
        row.push("" + normalize(entries[entries.length - 1].balanceEnd));
        row.push(
          "" +
            normalize(
              comparisonEntries[comparisonEntries.length - 1].balanceEnd
            )
        );
        row.push(
          "" +
            normalize(
              comparisonEntries[comparisonEntries.length - 1].balanceEnd -
                entries[entries.length - 1].balanceEnd
            )
        );
      } else {
        row.push("" + normalize(entries[entries.length - 1].balanceEnd));
      }
    }
    rows.push(row);

    CSVUtils.downloadCSVFile(filename, rows);
  }
}

const CashBudgetService = new CashBudgetServiceClass();
export default CashBudgetService;
(window as any).CashBudgetService = CashBudgetService;
