import classNames from "classnames";
import AssignmentLabel from "../../../../components/AssignmentLabel/AssignmentLabel";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import GlobalActions from "../../../../services/GlobalActions";
import BFIconTagCard from "../../../abstract-ui/data/icon-tag-card/BFIconTagCard";
import JumpToButton from "../../../abstract-ui/general/Button/jump-to/JumpToButton";
import BFUseTooltip from "../../../abstract-ui/general/tooltip/BFUseTooltip";
import { BfIconProps } from "../../../abstract-ui/icon/BfIcon";
import { Resubmission } from "../../RSInterfaces";
import RSService from "../../RSService";
import useOpenResubmissionPreview from "../../hooks/useOpenResubmissionPreview";
import RSPriorityTag from "../priority-tag/RSPriorityTag";
import "./RSSummaryCard.scss";

interface RSSummaryCardProps {
  resubmission: Resubmission;
  color?: string;
  backgroundColor?: string;
  onCloseModal?: () => void;
}

const RSSummaryCard = (props: RSSummaryCardProps) => {
  const { linkedAsset } = props.resubmission.data;

  const { openResubmissionPreview } = useOpenResubmissionPreview();

  const categoryIcon: BfIconProps = {
    size: "sm",
    ...(RSService.getCategoryOptions().find(
      (category) => category.value === props.resubmission.data.category
    )?.icon || { data: "task-checklist", type: "light" }),
  };

  const appDisplayName =
    linkedAsset?.appDisplayName ||
    i18n.t("Resubmission.Titles.Resubmission", "Wiedervorlage");

  const onSummaryCLick = () => {
    openResubmissionPreview(props.resubmission);
  };

  const getAppIconStyle = () => {
    const style = {
      color: props.color || "#fff",
      backgroundColor: props.backgroundColor || "#003468",
    };

    if (props.resubmission.data.linkedAsset?.appLogoUrl) {
      style[
        "backgroundImage"
      ] = `url(${props.resubmission.data.linkedAsset.appLogoUrl})`;
    }
    return style;
  };

  const showJumptToButton =
    props.resubmission.data.linkedAsset &&
    props.resubmission.data.linkedAsset.assetType !== AssetTypes.Comment;

  return (
    <div className={classNames("rs-summary-card")}>
      <BFIconTagCard
        onClick={onSummaryCLick}
        icon={categoryIcon}
        tooltipText={appDisplayName}
      >
        <div className="rs-summary-card-container">
          <div className="rs-summary-card-content">
            <div className={`first-row`}>{props.resubmission.data.title}</div>
            <div className={`main-row`}>
              {props.resubmission.data.description}
            </div>
            <div className={`sub-row`}>
              <RSPriorityTag resubmission={props.resubmission} />
              {linkedAsset && (
                <div className="asset-detail-container">
                  {linkedAsset.assetDescription}
                </div>
              )}
              <AssignmentLabel
                users={props.resubmission.data.assignees.users}
                teams={props.resubmission.data.assignees.teams}
                clipAtCount={1}
              />
            </div>
          </div>

          <div className="icon-button-column">
            {props.resubmission.data.linkedAsset && (
              <BFUseTooltip
                delay={100}
                trigger="hover"
                placement="bottom"
                tooltip={props.resubmission.data.linkedAsset?.appDisplayName}
              >
                <div className="rs-app-icon" style={getAppIconStyle()}></div>
              </BFUseTooltip>
            )}
            {showJumptToButton && (
              <JumpToButton
                onClick={() => {
                  if (props.onCloseModal) {
                    props.onCloseModal();
                  }
                  GlobalActions.openResubmission({
                    linkedAsset: linkedAsset,
                    type: props.resubmission.data.type,
                    resubmissionId: props.resubmission._id,
                  });
                }}
                stopPropagation={true}
                tooltipText={i18n.t(
                  "Resubmission.Buttons.OpenAsset",
                  "Verknüpfte Daten öffnen"
                )}
              />
            )}
          </div>
        </div>
      </BFIconTagCard>
    </div>
  );
};

export default RSSummaryCard;
