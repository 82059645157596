import classNames from "classnames";
import { useEffect, useState } from "react";
import ListComponent from "../../../../configurable/components/ListComponent/ListComponent";
import { AssetTypes } from "../../../../model/AssetTypes";
import { MatchQuery } from "../../../../services/DataService";
import MQ from "../../../../utils/MatchQueryUtils";
import { Resubmission } from "../../RSInterfaces";
import "./RSList.scss";

interface RSListProps {
  identifier: string;
  title?: string;
  selectedResubmissoin?: Resubmission;
  renderEntry: (entry: Resubmission) => JSX.Element;
  additionalMatchQuery?: MatchQuery;
}

const RSList = (props: RSListProps) => {
  const defaultMatchQuery: MatchQuery = {
    type: "op",
    op: "eq",
    name: "data.status",
    value: "open",
  };

  const [matchQuery, setMatchQuery] = useState<MatchQuery>(defaultMatchQuery);

  useEffect(() => {
    if (props.additionalMatchQuery) {
      const newQuery = MQ.combine("and", [
        defaultMatchQuery,
        props.additionalMatchQuery,
      ]);
      setMatchQuery(newQuery);
    } else {
      setMatchQuery(defaultMatchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.additionalMatchQuery]);

  return (
    <div className={classNames("rs-list")}>
      <ListComponent
        identifier={props.identifier}
        dataUrl={`/api/asset/list/${AssetTypes.Resubmission}`}
        asPost
        additionalMatchQuery={matchQuery}
        hiddenSort={[
          {
            key: "data.dueDate",
            dir: "asc",
          },
        ]}
        render={(node: Resubmission) => {
          return props.renderEntry(node);
        }}
      />
    </div>
  );
};

export default RSList;
