import { css } from "emotion";
import { FormSpy } from "react-final-form";
import { SubmitMessage, SubmitResponse } from "../../../services/SubmitService";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import { DataBusSubKeys } from "../../../utils/Constants";
import { GenericFormsLayoutProps, GFBaseElement } from "../GFBaseElement";
import FormUtils from "../util/FormUtils";

type Props = {
  id?: string;
  handleSubmitId: string;
  layoutReadonly: { [key: string]: any };
  layoutEdit: { [key: string]: any };
} & GenericFormsLayoutProps &
  AbstractStylableProps;

type States = {
  editMode: boolean;
} & AbstractStylableStates;

class FormPurposeChooser extends AbstractStylableComponent<Props, States> {
  oldState = {
    hasValidationErrors: null,
  };

  static defaultProps = {};

  readonly state: States = {
    editMode: false,
  };

  componentDidMount(): void {
    this.subscribe(DataBusSubKeys.SUBMIT, (data: SubmitMessage) => {
      this.populateButtonState("submit", {
        loading: true,
      });
    });

    this.subscribe(DataBusSubKeys.SUBMIT_RESPONSE, (data: SubmitResponse) => {
      this.populateButtonState("submit", {
        loading: false,
      });
      if (data.success) {
        this.setState({ editMode: false }, () => {
          this.updateEditmodeState();

          this.updateActionState(
            this.props.params.formRoot.formProps.hasValidationErrors
          );
        });
      }
    });

    this.subscribeActionEvent("edit", (actionData) => {
      this.onEdit();
    });
    this.subscribeActionEvent("abort", (actionData) => {
      this.onAbort();
    });
    this.subscribeActionEvent("submit", (actionData) => {
      this.onSubmit();
    });

    this.subscribe("RESET", (data) => {
      if (data.identifiers) {
        if (
          data.identifiers.indexOf(this.props.id) !== -1 ||
          data.identifiers.indexOf(this.props.identifier) !== -1
        ) {
          this.onAbort();
        }
      }
    });

    const { params } = this.props;
    this.updateEditmodeState();

    this.updateActionState(params.formRoot.formProps.hasValidationErrors);
  }

  updateEditmodeState() {
    const { id } = this.props;
    this.emit(
      id,
      {
        editMode: this.state.editMode,
      },
      true
    );
  }

  onAbort() {
    const {
      id,
      params,
      // registerStatusChange,
      layoutEdit,
    } = this.props;
    //todo reset changes

    (this.props.params.formRoot as any).formProps.form.reset();

    const currentPageFields = FormUtils.getPropertiesOf(
      Object.values(layoutEdit)
    );

    currentPageFields.forEach((field) => {
      (params.formRoot.formProps as any).form.mutators.setValue(
        field,
        params.formRoot.formProps.initialValues &&
          params.formRoot.formProps.initialValues[field]
          ? params.formRoot.formProps.initialValues[field]
          : undefined
      );
    });

    this.setState({ editMode: false }, () => {
      this.updateEditmodeState();

      this.updateActionState(params.formRoot.formProps.hasValidationErrors);
    });
  }

  onEdit() {
    const {
      id,
      // registerStatusChange,
      params,
    } = this.props;
    this.setState({ editMode: true }, () => {
      this.updateActionState(params.formRoot.formProps.hasValidationErrors);
      this.updateEditmodeState();
    });
  }

  onSubmit() {
    (this.props.params.formRoot as any).formProps.form.submit();
  }

  updateActionState(hasValidationErrors: boolean) {
    const { editMode } = this.state;
    const { id } = this.props;
    this.oldState.hasValidationErrors = hasValidationErrors;

    this.populateButtonState("edit", { hidden: editMode });
    this.populateButtonState("abort", { hidden: !editMode });
    this.populateButtonState("submit", { hidden: !editMode });
  }

  render() {
    const { layoutReadonly, layoutEdit, params } = this.props;
    const { editMode } = this.state;

    const translateFunc = (params.formRoot as any).formProps.form.mutators
      .translateFunc;

    return (
      <div
        className={`form-wizard ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
      >
        <div className={"page-content"}>
          {Object.values(editMode ? layoutEdit : layoutReadonly).map(
            (item, index) => (
              <GFBaseElement key={index} {...item} params={this.props.params} />
            )
          )}
        </div>

        <FormSpy
          subscription={{
            hasValidationErrors: true,
            values: true,
            errors: true,
          }}
          onChange={({ values, errors, hasValidationErrors }) => {
            this.updateActionState(hasValidationErrors);
            if (this.oldState.hasValidationErrors !== hasValidationErrors) {
              this.oldState.hasValidationErrors = hasValidationErrors;
              this.updateActionState(hasValidationErrors);
            }
          }}
        />
      </div>
    );
  }
}

export default FormPurposeChooser;
