import classNames from "classnames";
import { useEffect, useState } from "react";
import AssignmentLabel from "../../../../../components/AssignmentLabel/AssignmentLabel";
import VerticalProcessHistory from "../../../../../components/VerticalProcessHistory/VerticalProcessHistory";
import { VerticalProcessEntry } from "../../../../../components/VerticalProcessHistory/VerticalProcessHistoryInterfaces";
import i18n from "../../../../../i18n";
import { ApproveActivityTaskAsset } from "../../../../../model/general-assets/TaskAsset";
import StringUtils from "../../../../../utils/StringUtils";
import { APActivity, APOfferApproval } from "../../ActivityInterfaces";
import "./APOfferApprovalHistory.scss";

const convertApprovalHistoryToVerticalProcessEntries = (
  approval: APOfferApproval,
  activity: APActivity,
  task?: ApproveActivityTaskAsset
) => {
  const history: VerticalProcessEntry[] = [];
  const offers = activity.data.offers.filter((offer) =>
    approval.offersInApproval.includes(offer.linkToCdn)
  );

  approval.history?.forEach((historyEntry) => {
    // approval.offersInApproval.

    if (historyEntry.type === "OFFER_STARTED") {
      history.push({
        type: "past",
        date: historyEntry.date,
        userId: historyEntry.user,
        label: i18n.t(
          "apTemplate:Approval.History.OfferStarted",
          "Freigabe gestartet"
        ),
      });
    }

    if (historyEntry.type === "OFFER_SELECTED") {
      const selectedOffer = historyEntry.id
        ? offers.find((offer) => offer.linkToCdn === historyEntry.id)
        : undefined;
      history.push({
        type: "past",
        date: historyEntry.date,
        userId: historyEntry.user,
        label: historyEntry.id
          ? i18n.t(
              "apTemplate:Approval.History.OfferApproved",
              "Angebot ausgewählt"
            )
          : i18n.t(
              "apTemplate:Approval.History.OfferDeclined",
              "Angebote abgelehnt"
            ),
        subLabel: selectedOffer
          ? historyEntry.id
            ? `${selectedOffer.vendor} - ${StringUtils.formatCurrency(
                selectedOffer.value
              )}`
            : ""
          : undefined,
      });
    }
  });
  if (approval.status === "ongoing") {
    history.push({
      type: "current",
      icon: {
        data: "cursor-choose-1",
        type: "light",
      },
      label: i18n.t(
        "apTemplate:Approval.History.OfferSelection",
        "Angebote werden geprüft"
      ),
      subLabel: task ? (
        <AssignmentLabel
          teams={task.data.restrictTo.teams}
          users={task.data.restrictTo.users}
        />
      ) : undefined,
      color: "#27386c",
      backgroundColor: "#d4e2ed",
    });
  }
  if (approval.status === "accepted") {
    history.push({
      type: "finish",
      icon: {
        data: "check-1",
        type: "light",
      },
      label: i18n.t(
        "apTemplate:Approval.History.SuccessOfferSelection",
        "Freigabe erfolgreich beendet"
      ),
      color: "#1a712a",
      backgroundColor: "#aac6ab",
    });
  }
  if (approval.status === "declined") {
    history.push({
      type: "finish",
      icon: {
        data: "close",
        type: "light",
      },
      label: i18n.t(
        "apTemplate:Approval.History.RejectOfferSelection",
        "Freigabe wurde abgebrochen"
      ),
      color: "#803030",
      backgroundColor: "#e8bebe",
    });
  }
  return history;
};

interface Props {
  approval: APOfferApproval;
  activity: APActivity;
  task?: ApproveActivityTaskAsset;
}
const APOfferApprovalHistory = (props: Props) => {
  const [history, setHistory] = useState<VerticalProcessEntry[]>([]);

  useEffect(() => {
    setHistory(
      convertApprovalHistoryToVerticalProcessEntries(
        props.approval,
        props.activity,
        props.task
      )
    );
  }, [props.approval]);
  if (!props.approval) {
    return null;
  }
  return (
    <div className={classNames(`ap-offer-approval-history`)}>
      <VerticalProcessHistory
        useMousewheel
        useFreeMode
        data={history}
        id={`approval-${props.approval?.id}`}
      />
    </div>
  );
};

export default APOfferApprovalHistory;
