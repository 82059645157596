import {
  FieldCustomField,
  TechnicalUnitCategoryGroup,
} from "../../../../apps/tatar/cashBudget/views/config/technical-unit-config/CBTechnicalUnitConfigInterfaces";
import LanguageService from "../../../../services/LanguageService";
import { HTTP } from "../../../../utils/Http";
import { StructType } from "../../../reducers/struct/StructInterface";
import {
  AbstractStructSelectors,
  DataByUnitType,
} from "../AbstractStructSelectors";

class TechnicalUnitStructClass extends AbstractStructSelectors<
  TechnicalUnitCategoryGroup[]
> {
  getStructType(): StructType {
    return "technicalUnit";
  }
  loadStructData(
    units: string[]
  ): Promise<DataByUnitType<TechnicalUnitCategoryGroup[]>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: `/maintenance/getTechnicalUnitGroupsStruct`,
          target: "EMPTY",
          queryParams: {
            param: {
              types: units,
            },
          },
        })) as TechnicalUnitCategoryGroup[];

        const result: DataByUnitType<TechnicalUnitCategoryGroup[]> = {};
        units.forEach((unit) => {
          result[unit] = data.filter((tucg) => tucg.data.type === unit);
        });
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }

  getCategories(types?: string[]) {
    return this.useCache<{ label: string; value: string }[]>(
      "getCategories",
      arguments,
      () => {
        return this.getAllData()
          .flat()
          .filter((cat) => !types || types.includes(cat.data.type))
          .map((cat) => ({
            label: LanguageService.translateLabel(cat.data.displayName),
            value: cat._id,
          }));
      }
    );
  }

  getSubCategories(types?: string[]) {
    return this.useCache<{ label: string; value: string }[]>(
      "getSubCategories",
      arguments,
      () => {
        return this.getAllData()
          .flat()
          .filter((cat) => !types || types.includes(cat.data.type))
          .map((cat) =>
            cat.data.categories.map((e) => ({
              label: `${LanguageService.translateLabel(
                cat.data.displayName
              )} - ${LanguageService.translateLabel(e.displayName)}`,
              value: e.id,
            }))
          )
          .flat();
      }
    );
  }

  getSelectOptions(unit: string, addSubCategories = false) {
    return this.useCache<{ value: string; label: string }[]>(
      "getSelectOptions",
      arguments,
      () => {
        const output: {
          value: string;
          label: string;
        }[] = [];

        (this.getData(unit) || []).forEach((categoryGroup) => {
          output.push({
            value: categoryGroup._id,
            label: LanguageService.translateLabel(
              categoryGroup.data.displayName
            ),
          });
          if (addSubCategories) {
            (categoryGroup.data.categories || []).forEach((subCategory) => {
              output.push({
                value: subCategory.id,
                label: `${LanguageService.translateLabel(
                  categoryGroup.data.displayName
                )} - ${LanguageService.translateLabel(
                  subCategory.displayName
                )}`,
              });
            });
          }
        });

        return output;
      }
    );
  }
  getSubCategoriesOptions(categoryId: string) {
    return this.useCache<{ value: string; label: string }[]>(
      "getSubCategoriesOptions",
      arguments,
      () => {
        const output: {
          value: string;
          label: string;
        }[] = [];
        const category = this.getCategory(categoryId);
        if (category) {
          (category.data.categories || []).forEach((subCategory) => {
            output.push({
              value: subCategory.id,
              label: LanguageService.translateLabel(subCategory.displayName),
            });
          });
        }
        return output;
      }
    );
  }

  getCustomFieldsOfSubCatgeory(
    subCategoryId: string,
    showAll = false
  ): FieldCustomField[] {
    return this.useCache<{}[]>(
      "getCustomFieldsOfSubCatgeory",
      arguments,
      () => {
        for (const tcArr of this.getAllData()) {
          for (const tc of tcArr) {
            const subCat = tc.data.categories.find(
              (c) => c.id === subCategoryId
            );
            if (subCat) {
              return (subCat.fields || []).filter((e) =>
                showAll ? true : e.status !== "archived"
              );
            }
          }
        }
        return [];
      }
    ) as FieldCustomField[];
  }

  getCustomFields(
    categoryId: string,
    subCategoryId?: string,
    showAll = false
  ): FieldCustomField[] {
    return this.useCache<{}[]>("getCustomFields", arguments, () => {
      const customFields: FieldCustomField[] = [];
      const category = this.getCategory(categoryId);
      if (category) {
        if (category.data.fields?.length > 0) {
          customFields.push(...category.data.fields);
        }
        if (subCategoryId) {
          const subCategory = category.data.categories.find(
            (c) => c.id === subCategoryId
          );

          if (subCategory?.fields?.length > 0) {
            customFields.push(...subCategory.fields);
          }
        }
      }
      return customFields.filter((e) =>
        showAll ? true : e.status !== "archived"
      );
    }) as FieldCustomField[];
  }

  getCategory(categoryId: string) {
    return this.useCache<TechnicalUnitCategoryGroup>(
      "getCategory",
      arguments,
      () => {
        const allData = this.getAllData();
        for (const data of allData) {
          for (const categoryGroup of data) {
            if (categoryGroup._id === categoryId) {
              return categoryGroup;
            }
          }
        }
        return null;
      }
    );
  }
}
const TechnicalUnitStruct = new TechnicalUnitStructClass();
export default TechnicalUnitStruct;
