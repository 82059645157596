import { FV } from "@/components/Form/Validation/FormValidators";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import LanguageService from "@/services/LanguageService";
import { FieldRenderProps } from "react-final-form";
import { Customfield_string } from "../CustomField.interface";
// import "./CFString.scss";

interface CFStringProps {
  data: Customfield_string;
  field: FieldRenderProps<any, HTMLElement>;
}
const CFString = (props: CFStringProps) => {
  return (
    <BFInput
      label={LanguageService.translateLabel(props.data.displayName)}
      {...props.field.input}
      info={LanguageService.translateLabel(props.data.info)}
      {...FV.getValidation(props.field.meta)}
    />
  );
};

export default CFString;
