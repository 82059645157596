import { css } from "emotion";
import flatten from "flat";
import _ from "lodash";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DebugDataComponent from "../../debug/DebugDataComponent";
import Log from "../../debug/Log";
import { CacheData } from "../../redux/reducers/application/ApplicationInterface";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import CacheService, { CacheLoadData } from "../../services/CacheService";
import DataBus from "../../services/DataBus";
import { SubmitMessage } from "../../services/SubmitService";
import { DataBusSubKeys } from "../../utils/Constants";
import { handleError } from "../../utils/ErrorCodes";
import { SendEvent } from "../../utils/abstracts/AbstractComponent";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../utils/abstracts/AbstractStylableComponent";
import GenericForms, { FormDefinition } from "../generic-forms/GenericForms";
import JsonValidation, {
  JsonProperties,
} from "../generic-forms/util/JsonValidation";
import ObjectTools from "../generic-forms/util/ObjectTools";

type Props = {
  oType: "asset" | "user" | "group";
  assetType?: string;
  _initialValuesObjectId: string;
  formDefinition:
    | FormDefinition
    | { properties: JsonProperties; formDefinitions: { [key: string]: any } };
  cachedContainer: { [objId: string]: CacheData<any> };
  formSelector?: string;
  _formData?: any;
  formData: any;
  _additionalData?: any;
  additionalData?: any;
  ignoreDeleteMessage?: boolean;
  _addToSubmitData?: string;
  _transformSubmitData?: any;
  handleErrorMessaging?: boolean;
  onSubmitSuccess:
    | { [key: string]: Function | SendEvent }
    | ((value: any) => void);
  onSubmitError:
    | { [key: string]: Function | SendEvent }
    | ((error: any) => void);
  submitUrl?: string;
  submitTarget?: string;
  submitMethod?: string;
  resetOnSubmit?: boolean;
  pushToTableCache?: string;
  _pushToTableCache?: string;
  ignoreReduceToValidInput?: boolean;
  ignoreValidationKeys?: string[];
  ignoreValidationKeysBesides?: string[];
  ignorePropChecks?: boolean;
  ignoreSubmitValidation?: boolean;
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {
  submitCount: number;
  formValue: any;
  additionalData: any;
  error: any;
  loading: boolean;
  currentId: string;
} & AbstractStylableStates;

class FormWrapper extends AbstractStylableComponent<Props, States> {
  static defaultProps = {
    handleErrorMessaging: true,
    ignoreDeleteMessage: false,
  };
  readonly state: States = {
    submitCount: 0,
    formValue: null,
    error: null,
    loading: false,
    currentId: null,
    additionalData: {},
  };
  constructor(props) {
    super(props);

    const {
      _initialValuesObjectId: initialValuesObjectId,
      formData,
      _formData,
      additionalData,
      _additionalData,
    } = this.props;

    if (initialValuesObjectId) {
      this.loadCachedData();
    } else if (_formData || formData) {
      let formValue = null;
      formValue = _formData
        ? this.evaluateExpression(_formData)
        : formData || null;

      this.state = {
        formValue: JsonValidation.convertValuesToFormData(
          formValue,
          this.props.formDefinition?.properties
        ),
        additionalData: _additionalData
          ? this.evaluateExpression(_additionalData)
          : additionalData || {},
        submitCount: 0,
        error: null,
        loading: false,
        currentId: null,
      };
    } else if (_additionalData || additionalData) {
      // this.setState({
      // 	additionalData: _additionalData ? dataObj : null
      // });
      this.state = {
        formValue: null,
        additionalData: _additionalData
          ? this.evaluateExpression(_additionalData)
          : additionalData || {},
        submitCount: 0,
        error: null,
        loading: false,
        currentId: null,
      };
    }
    this.subscribe(DataBusSubKeys.CACHE_CHANGED, (data) => {
      if (
        data.oType === this.props.oType &&
        data.assetType === this.props.assetType &&
        this.state.formValue &&
        this.state.formValue._id === data.id
      ) {
        this.loadCachedData();
      }
    });
  }

  componentDidMount() {}

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  loadCachedData() {
    const {
      oType,
      _initialValuesObjectId,
      assetType,
      additionalData,
      _additionalData,
    } = this.props;
    let id = _initialValuesObjectId
      ? this.evaluateExpression(_initialValuesObjectId)
      : null;
    if (id) {
      this.setState({
        loading: true,
        error: null,
        currentId: id,
      });
      CacheService.getData({
        oType,
        id,
        assetType,
      } as CacheLoadData)
        .then((data) => {
          this.setState({
            formValue: JsonValidation.convertValuesToFormData(
              data,
              this.props.formDefinition?.properties
            ),
            additionalData: _additionalData
              ? this.evaluateExpression(_additionalData)
              : additionalData || {},
            loading: false,
            error: null,
          });
        })
        .catch((err) => {
          this.setState({
            formValue: null,
            loading: false,
            error: err,
          });
        });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

  //

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    const {
      _initialValuesObjectId,
      _formData,
      additionalData,
      _additionalData,
    } = this.props;
    let update = super.shouldComponentUpdate(nextProps, nextState);

    //todo check on change
    let newFormValue = null;
    if (nextProps._initialValuesObjectId) {
      const nextId = this.evaluateExpression(
        nextProps._initialValuesObjectId,
        undefined,
        nextProps,
        nextState
      );

      if (nextId !== this.state.currentId) {
        this.loadCachedData();
      }
    } else if (nextProps._formData || nextProps.formData) {
      newFormValue = JsonValidation.convertValuesToFormData(
        nextProps._formData
          ? this.evaluateExpression(
              nextProps._formData,
              undefined,
              nextProps,
              nextState
            )
          : nextProps.formData || null,
        this.props.formDefinition?.properties
      );

      if (!_.isEqual(newFormValue, this.state.formValue)) {
        this.setState({
          formValue: newFormValue,
          additionalData: _additionalData
            ? this.evaluateExpression(_additionalData)
            : additionalData || {},
        });
        return true;
      } else {
        return false;
      }
    }

    if (nextState.submitCount !== this.state.submitCount) {
      this.loadCachedData();
      return true;
    }
    if (nextProps._initialValuesObjectId) {
      let id = this.evaluateExpression(
        nextProps._initialValuesObjectId,
        undefined,
        nextProps,
        nextState
      );
      const cachedDataOj = nextProps.cachedContainer
        ? nextProps.cachedContainer[id]
        : null;
      const beforeCachedDataOj = this.props.cachedContainer
        ? nextProps.cachedContainer[id]
        : null;

      if (
        cachedDataOj !== beforeCachedDataOj ||
        (cachedDataOj && cachedDataOj["deprecated"] === true)
      ) {
        this.setState({ submitCount: this.state.submitCount + 1 });
        return true;
      }
    }

    return update;
  }

  render() {
    const { submitCount, formValue, additionalData } = this.state;
    const {
      i18n,
      formDefinition,
      oType,
      _initialValuesObjectId: initialValuesObjectId,
      handleErrorMessaging,
      assetType,
      formSelector,
      resetOnSubmit,
    } = this.props;

    // if (submitted) {
    //     return <Trans i18nKey="Global.Labels.submitSuccess" />
    // }
    // const formValue = this.evaluateExpression(initialValues, { store: store.getState() });
    if (!formDefinition) {
      return <div>error: no form definition loaded</div>;
    }
    let formDefinitionToLoad: FormDefinition = null;
    if (oType === "asset") {
      formDefinitionToLoad = {
        properties: formDefinition.properties,
        layout: (formDefinition as any).formDefinitions[
          formSelector ? formSelector : "default"
        ],
      };
    } else {
      formDefinitionToLoad = formDefinition as FormDefinition;
    }

    return (
      <div
        className={`form-wrapper  ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
      >
        {formValue ? (
          <DebugDataComponent text={"form-input"} data={formValue} />
        ) : null}
        <GenericForms
          ignoreValidationKeys={this.props.ignoreValidationKeys}
          ignoreValidationKeysBesides={this.props.ignoreValidationKeysBesides}
          identifier={this.getIdentifier()}
          id={"" + submitCount}
          ignoreDeleteMessage={this.props.ignoreDeleteMessage}
          assetType={this.props.assetType}
          style={this.state.usedStyle}
          translateFunc={i18n.t}
          formValue={formValue}
          resetOnSubmit={resetOnSubmit}
          actionIdMapping={this.props.actionIdMapping}
          additionalData={this.state.additionalData}
          formDefinition={formDefinitionToLoad}
          ignoreReduceToValidInput={this.props.ignoreReduceToValidInput}
          onFormSubmit={(dataResult) => {
            const dataResultRaw = dataResult;
            return new Promise((resolve, reject) => {
              if (this.props._addToSubmitData) {
                Log.debug("addToSubmitData");
                const addData = this.evaluateExpression(
                  this.props._addToSubmitData,
                  { value: dataResult }
                );

                Log.debug("addToSubmitData2", addData);
                dataResult = ObjectTools.mergeObjects(dataResult, addData);
              }
              dataResult = JsonValidation.convertFormDataToSubmit(
                dataResult,
                this.props.formDefinition?.properties
              );
              if (this.props._transformSubmitData) {
                dataResult = this.evaluateExpression(
                  this.props._transformSubmitData,
                  dataResult
                );
              }

              const dataObj = flatten.unflatten(dataResult, {
                delimiter: "|",
              });
              DataBus.emit(DataBusSubKeys.SUBMIT, {
                id: this.getIdentifier(),
                type: oType,
                assetType: assetType,
                initialData: formValue,
                data: dataResult,
                ignoreSubmitValidation: this.props.ignoreSubmitValidation,
                pushToCache: true,
                pushToTableCache: this.props._pushToTableCache
                  ? this.evaluateExpression(this.props._pushToTableCache)
                  : this.props.pushToTableCache,
                properties: formDefinition.properties,

                ignorePropChecks: this.props.ignorePropChecks,
                target: this.props.submitTarget,
                overwriteUrl: this.props.submitUrl
                  ? this.evaluateExpression(this.props.submitUrl, {
                      value: dataResult,
                    })
                  : undefined,
                overwriteMethod: this.props.submitMethod
                  ? this.evaluateExpression(this.props.submitMethod, {
                      value: dataResult,
                    })
                  : undefined,
                onSuccess: (submitResult) => {
                  this.setState({
                    submitCount: submitCount + 1,
                  });
                  if (!this.props.resetOnSubmit) {
                    this.setState({
                      formValue: dataObj,
                    });
                  }
                  DataBus.emit("FORM_SUBMITTED", {
                    identifiers: [this.getIdentifier()],
                  });
                  if (typeof this.props.onSubmitSuccess === "function") {
                    this.props.onSubmitSuccess(submitResult);
                  } else {
                    this.handleEvents(this.props.onSubmitSuccess, {
                      value: submitResult,
                      formData: dataResult,
                      formDataRaw: dataResultRaw,
                    });
                  }
                  resolve(submitResult);
                },
                onError: (err) => {
                  if (handleErrorMessaging) {
                    handleError(err);
                  }
                  if (typeof this.props.onSubmitError === "function") {
                    this.props.onSubmitError(err);
                  } else {
                    this.handleEvents(this.props.onSubmitError, { error: err });
                  }

                  reject();
                },
              } as SubmitMessage);
            });
          }}
          onFormCancel={() => Log.debug("canceled")}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
  formDefinition:
    props.oType !== "asset"
      ? state.global.config.standardForms[
          props.formSelector ? props.formSelector : props.oType
        ]
      : state.global.config.assetTypeForms[props.assetType],
  cachedContainer:
    state.application.cache[
      props.oType !== "asset" ? props.oType : props.assetType
    ],
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(FormWrapper))
) as any;
