import classNames from "classnames";
import { useEffect, useState } from "react";
import ListComponent from "../../../configurable/components/ListComponent/ListComponent";
import ListEntryComponent from "../../../configurable/components/ListComponent/ListEntryComponent";
import DContentCard from "../../../dashboard/common/DContentCard/DContentCard";
import RSDashboardKpiBtn from "../../../dashboard/implemented/general/resubmission/components/kpi/RSDashboardKpiBtn";
import i18n from "../../../i18n";
import { AssetTypes } from "../../../model/AssetTypes";
import { TableSort } from "../../../model/common/CommonInterfaces";
import { MatchQuery } from "../../../services/DataService";
import MQ from "../../../utils/MatchQueryUtils";
import { RSListIdentifiers } from "../RSConstants";
import { Resubmission } from "../RSInterfaces";
import RSService from "../RSService";
import RSCreateButton from "../components/actions/RSCreateButton";
import RSCategorizedStatusList from "../components/categorized-status-list/RSCategorizedStatusList";
import RSListEntry from "../components/list-entry/RSListEntry";
import useOpenResubmissionPreview from "../hooks/useOpenResubmissionPreview";
import "./ResubmissionDashboardComponent.scss";

interface ResubmissionDashboardComponentProps {
  onCloseModal?: () => void;
}

const ResubmissionDashboardComponent = (
  props: ResubmissionDashboardComponentProps
) => {
  const { openResubmissionPreview } = useOpenResubmissionPreview();
  const [filter, setFilter] = useState<
    "own" | "feedback" | "done" | "feedback-done"
  >("own");
  const defaultMatchQuery = MQ.combine("and", [
    RSService.getDefaultMatchQuery(),
    RSService.getAssigneeMatchQuery("own"),
  ]);
  const [additionalMatchQuery, setAdditionalMatchQuery] =
    useState<MatchQuery>(defaultMatchQuery);

  useEffect(() => {
    let field = "data.assignees";
    if (filter === "feedback" || filter === "feedback-done") {
      field = "data.feedbackRecipients";
    }

    let status = ["open"];
    if (filter === "done" || filter === "feedback-done") {
      status = ["done"];
    }
    const newAssigneeMatchQuery = MQ.combine("and", [
      RSService.getDefaultMatchQuery({ status: status }),
      RSService.getAssigneeMatchQuery("own", field),
    ]);

    setAdditionalMatchQuery(newAssigneeMatchQuery);
  }, [filter]);

  const renderActions = () => {
    return <RSCreateButton identifier={RSListIdentifiers.GlobalDashboard} />;
  };

  const dataUrl = `/api/asset/list/${AssetTypes.Resubmission}`;
  const hiddenSort: TableSort[] = [
    {
      key: "data.dueDate",
      dir: "asc",
    },
  ];

  return (
    <DContentCard
      title={i18n.t("dashboard:resubmission.title", "Wiedervorlagen")}
      className={classNames(`resubmission-dashboard-component`)}
      actions={renderActions()}
    >
      <div className="dashboard-resubmission-kpis">
        <RSDashboardKpiBtn
          isSelected={filter === "own"}
          meta={i18n.t("Resubmission.Labels.Own", "Eigene")}
          subTitle={i18n.t("Resubmission.Status.Open", "Offen")}
          onClick={() => setFilter("own")}
          matchQuery={MQ.combine("and", [
            {
              type: "op",
              op: "in",
              name: "data.status",
              value: ["open"],
            },
            RSService.getAssigneeMatchQuery("own"),
          ])}
        />
        <RSDashboardKpiBtn
          isSelected={filter === "feedback"}
          meta={i18n.t("Resubmission.Labels.Feedback", "Rückmeldung")}
          subTitle={i18n.t("Resubmission.Status.Open", "Offen")}
          onClick={() => setFilter("feedback")}
          matchQuery={MQ.combine("and", [
            {
              type: "op",
              op: "in",
              name: "data.status",
              value: ["open"],
            },
            RSService.getAssigneeMatchQuery("own", "data.feedbackRecipients"),
          ])}
        />
        <RSDashboardKpiBtn
          className="feedback-done-kpi-card"
          isSelected={filter === "feedback-done"}
          meta={i18n.t("Resubmission.Labels.Feedback", "Rückmeldung")}
          subTitle={i18n.t("Resubmission.Labels.Done", "Erledigt")}
          kpiText={i18n.t("Global.Labels.all", "Alle")}
          onClick={() => setFilter("feedback-done")}
          matchQuery={MQ.combine("and", [
            {
              type: "op",
              op: "in",
              name: "data.status",
              value: ["done"],
            },
            RSService.getAssigneeMatchQuery("own", "data.feedbackRecipients"),
          ])}
        />
        <RSDashboardKpiBtn
          className="done-kpi-card"
          isSelected={filter === "done"}
          meta={i18n.t("Resubmission.Labels.Own", "Eigene")}
          subTitle={i18n.t("Resubmission.Labels.Done", "Erledigt")}
          kpiText={i18n.t("Global.Labels.all", "Alle")}
          onClick={() => setFilter("done")}
          matchQuery={MQ.combine("and", [
            {
              type: "op",
              op: "in",
              name: "data.status",
              value: ["done"],
            },
            RSService.getAssigneeMatchQuery("own"),
          ])}
        />
      </div>
      <div className="dashboard-resubmission-content">
        {/* Render categorized resubmission list */}
        {filter !== "done" && filter !== "feedback-done" && (
          <ListComponent
            dataRenderType="render-as-list"
            dataUrl={dataUrl}
            asPost
            identifier={RSListIdentifiers.GlobalDashboard}
            additionalMatchQuery={additionalMatchQuery}
            hiddenSort={hiddenSort}
            render={(resubmissions: Resubmission[]) => {
              return (
                <RSCategorizedStatusList
                  resubmissions={resubmissions || []}
                  onCloseModal={props.onCloseModal}
                />
              );
            }}
          />
        )}
        {/* Render done resubmission list in the default way, node by node */}
        {(filter === "done" || filter === "feedback-done") && (
          <ListComponent
            dataRenderType="default"
            dataUrl={dataUrl}
            asPost
            identifier={RSListIdentifiers.GlobalDashboard}
            additionalMatchQuery={additionalMatchQuery}
            hiddenSort={hiddenSort}
            render={(resubmission: Resubmission) => {
              return (
                <ListEntryComponent
                  renderEntry={() => {
                    return <RSListEntry resubmission={resubmission} />;
                  }}
                  entryData={resubmission}
                  onClickEntry={() => {
                    openResubmissionPreview(resubmission);
                  }}
                />
              );
            }}
          />
        )}
      </div>
    </DContentCard>
  );
};

export default ResubmissionDashboardComponent;
