import i18n from "@/i18n";
import classNames from "classnames";
import { AssetCashBudgetEntry } from "../model/CashBudgetEntry";
import "./CashBudgetLinks.scss";

interface CashBudgetLinksProps {
  booking: AssetCashBudgetEntry;
}

const calculateLinks = (booking: AssetCashBudgetEntry) => {
  const links: { url: string; label: string }[] = [];

  if (booking.data.recipient?.toLowerCase().includes("amazon")) {
    const orderNumberRegex = /\d{3}-\d{7}-\d{7}/;
    const match = booking.data.usage?.match(orderNumberRegex);
    if (match) {
      links.push({
        label: i18n.t("cb:CashBudgetLinks.AmazonOrder", "Amazon Bestellung"),
        url: `https://www.amazon.de/gp/your-account/order-details/ref=ppx_yo_dt_b_order_details_o00?ie=UTF8&orderID=${match}`,
      });
    }
  }
  return links;
};
const CashBudgetLinks = (props: CashBudgetLinksProps) => {
  const links = calculateLinks(props.booking);
  if (links.length === 0) {
    return null;
  }
  return (
    <div className={classNames(`cashbudget-links`)}>
      {links.map((link) => (
        <a href={link.url} target="__blank">
          {link.label}
        </a>
      ))}
    </div>
  );
};

export default CashBudgetLinks;
