import { useDispatch } from "react-redux";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { setUiConfig } from "../../services/ApplicationService";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BfIcon from "../abstract-ui/icon/BfIcon";

interface ContactsButtonProps {}
const ContactsButton = (props: ContactsButtonProps) => {
  const dispatch = useDispatch();

  return (
    <BFButton
      appearance="clear-on-white"
      onClick={() => {
        dispatch(setUiConfig(DefaultUIConfigs.OPEN_CONTACT_DRAWER, true));
      }}
    >
      <BfIcon type="light" data="single-neutral-phone-book" size="xs" />
    </BFButton>
  );
};

export default ContactsButton;
