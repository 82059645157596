import i18n from "../../../../../i18n";
import { RentalStatus, RentalUnitTypeGroup } from "./TenantsInterfaces";

export const CB_RENTAL_OBJECT_LIST = "CB_RENTAL_OBJECT_LIST";
export const CB_TENANTS_LIST = "CB_TENANTS_LIST";
export const CB_RENTAL_UNIT_LIST = "CB_RENTAL_UNIT_LIST";
export const CB_RENTAL_AGREEMENT_LIST = "CB_RENTAL_AGREEMENT_LIST";
export const CB_RENTAL_VACANCY_LIST = "CB_RENTAL_VACANCY_LIST";
export const CB_RENTAL_OPOS_LIST = "CB_RENTAL_OPOS_LIST";

export const getConfigRentalStatus = (status: RentalStatus) => {
  switch (status) {
    case "occupied":
      return {
        label: i18n.t("cb:RentalUnit.status.occupied", "Vermietet"),
        color: "#618c6b",
        stackingPlanColor: "#bbefc8",
      };
    case "blocked":
      return {
        label: i18n.t("cb:RentalUnit.status.blocked", "Umbau"),
        color: "#8b774b",
        stackingPlanColor: "#f6e8c8",
      };
    case "reserved":
      return {
        label: i18n.t("cb:RentalUnit.status.reserved", "Reserviert"),
        color: "#6e9b91",
        stackingPlanColor: "#ccf8ef",
      };
    case "vacant":
      return {
        label: i18n.t("cb:RentalUnit.status.vacant", "Leerstand"),
        color: "#8f7878",
        stackingPlanColor: "#f3cdcd",
      };
    default:
      return null;
  }
};

export const getDepositPositionType = (
  kind:
    | "rent"
    | "operatingCost"
    | "operatingCostAllInclusive"
    | "additionalPayment"
) => {
  switch (kind) {
    case "rent":
      return i18n.t(
        "cb:RentalAgreement.Form.Fields.paymentPositions.rent",
        "Mieteinnahmen"
      );
    case "operatingCost":
      return i18n.t(
        "cb:RentalAgreement.Form.Fields.paymentPositions.operatingCost",
        "Betriebskosten"
      );
    case "operatingCostAllInclusive":
      return i18n.t(
        "cb:RentalAgreement.Form.Fields.paymentPositions.operatingCostAllInclusive",
        "Betriebskostenpauschale"
      );
    case "additionalPayment":
      return i18n.t(
        "cb:RentalAgreement.Form.Fields.paymentPositions.additionalPayment",
        "Zusatzleistung"
      );
    default:
      return "-";
  }
};

export const getConfigRentalUnitTypeGroup = (group: RentalUnitTypeGroup) => {
  switch (group) {
    case "commercial":
      return {
        label: i18n.t("cb:RentalUnitTypeGroup.commercial", "Gewerbe"),
        color: "#bae8c5",
        areaType: "area",
        order: 0,
      };
    case "residental":
      return {
        label: i18n.t("cb:RentalUnitTypeGroup.residental", "Wohnung"),
        color: "#dfcfad",
        areaType: "area",
        order: 1,
      };
    case "parking":
      return {
        label: i18n.t("cb:RentalUnitTypeGroup.parking", "Parkplatz"),
        color: "#afcce2",
        areaType: "count",
        order: 2,
      };
    case "storage":
      return {
        label: i18n.t("cb:RentalUnitTypeGroup.storage", "Lager"),
        color: "#dfcef0",
        areaType: "area",
        order: 3,
      };
    case "other":
      return {
        label: i18n.t("cb:RentalUnitTypeGroup.other", "Sonstiges"),
        color: "#c8eadf",
        areaType: "count",
        order: 4,
      };
    default:
      return null;
  }
};

export const getRentPaymentTimingOption = () => {
  return [
    {
      label: i18n.t(
        "cb:RentalAgreement.Form.Fields.paymentTiming.startOfMonth",
        "Monatsanfang"
      ),
      value: "startOfMonth",
    },
    {
      label: i18n.t(
        "cb:RentalAgreement.Form.Fields.paymentTiming.endOfMonth",
        "Monatsende"
      ),
      value: "endOfMonth",
    },
    {
      label: i18n.t(
        "cb:RentalAgreement.Form.Fields.paymentTiming.middleOfMonth",
        "Mitte des Monats"
      ),
      value: "middleOfMonth",
    },
  ];
};
