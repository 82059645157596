import APList from "@/apps/tatar/activityApp/views/list/APList";
import StructLoader from "@/components/StructLoader/StructLoader";
import {
  BaseConstants,
  ConstantsContext,
  useSpecificConstants,
} from "@/redux/hooks";
import DataBusDefaults from "@/services/DataBusDefaults";
import MQ from "@/utils/MatchQueryUtils";
import "./CBVacancyCurrentActivityCard.scss";

interface VacancyCurrentActivityCardProps {
  objectIds: string[];
  marginBottom?: number;
  referenceId?: string;
}
const CBVacancyCurrentActivityCard = (
  props: VacancyCurrentActivityCardProps
) => {
  const vacancyConstants = useSpecificConstants<
    { serviceUrl: string; businessUnits: string[] } & BaseConstants
  >("app-vacancy");

  if (!props.objectIds || props.objectIds.length === 0) {
    return null;
  }
  const additionalMatchQuery = MQ.eq("data.objectId", props.objectIds[0]);
  return (
    <StructLoader
      unitTypes={vacancyConstants.businessUnits as any}
      structTypes={[vacancyConstants.serviceUrl as any, "unit", "orga"]}
      render={([structToUse]) => {
        return (
          <ConstantsContext.Provider value={vacancyConstants}>
            <div className="cb-vacancy-current-activity-card">
              <APList
                hideActions
                structToUse={structToUse}
                activityStatus="active"
                additionalMatchQuery={additionalMatchQuery}
                hideColumns={["data.type", "data.entity", "data.objectId"]}
                overWriteDetailsAction={(activityId) => {
                  DataBusDefaults.route({
                    route: `__asset_activity-vacancy/${activityId}`,
                    append: true,
                  });
                }}
              />
            </div>
          </ConstantsContext.Provider>
        );
      }}
    />
  );
};
export default CBVacancyCurrentActivityCard;
