import _ from "lodash";
import moment from "moment";
import { nanoid } from "nanoid";
import Papa from "papaparse";
import i18n from "../../../../../../i18n";
import {
  AnnuityLoanData,
  LoanValueEntry,
} from "../../../../../../modules/abstract-ui/loan/BFLoanData";
import NumberUtils from "../../../../../../utils/ NumberUtils";

export type LoanDataFields = {
  loanAmount: number;
  loanPayoutDate: Date;
  loanStartDate: Date;
  annualInterest: number;
  loanTermYears: number;
  paymentAmount: number;
};
interface CSVEntry {
  date: string;
  start_balance: number;
  payout: number;
  extra_cost: number;
  repayment: number;
  interest: number;
  payment: number;
  end_balance: number;
}
interface CSVEntryWithAccumulatedInterest extends CSVEntry {
  accumulatedInterest: number;
}

class CBLoanUtilsClass {
  async uploadCSVFileForLoan(csvFile: File, loan: AnnuityLoanData) {
    const csvText = await csvFile.text();
    const parsed = Papa.parse<CSVEntry>(csvText, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
    });

    if (parsed.errors.length > 0) {
      throw new Error("CSV Parsing Error");
    }

    const dataWithAccumulatedInterest = [
      ...parsed.data.map((e) => ({ ...e, accumulatedInterest: 0 })),
    ].map((e, index, arr) => {
      const accumulatedInterest =
        index === 0 ? 0 : arr[index - 1].accumulatedInterest;
      return {
        ...e,
        accumulatedInterest,
      } as CSVEntryWithAccumulatedInterest;
    });

    const extractedLoanValues: LoanValueEntry[] =
      dataWithAccumulatedInterest.map((e) => {
        return {
          id: nanoid(),
          comment: null,
          date: moment(e.date, "DD.MM.YYYY").utc(true).toISOString(),
          startBalance: NumberUtils.round(e.start_balance),
          payout: NumberUtils.round(e.payout),
          otherCost: NumberUtils.round(e.extra_cost),
          repaymentAmount: NumberUtils.round(e.repayment),
          interestAmount: NumberUtils.round(e.interest),
          totalPayment: NumberUtils.round(e.payment),
          endBalance: NumberUtils.round(e.end_balance),
          repaymentInPercent: (e.repayment / e.payment) * 100,

          edited: true,

          accumulatedInterest: NumberUtils.round(e.accumulatedInterest),
        };
      });

    const payoutDate =
      extractedLoanValues.findIndex((e) => e.payout > 0) >= 0
        ? moment(
            extractedLoanValues[
              extractedLoanValues.findIndex((e) => e.payout > 0)
            ].date
          )
            .utc(true)
            .toISOString()
        : undefined;
    const repaymentDate =
      extractedLoanValues.findIndex((e) => e.repaymentAmount > 0) >= 0
        ? moment(
            extractedLoanValues[
              extractedLoanValues.findIndex((e) => e.repaymentAmount > 0)
            ].date
          )
            .utc(true)
            .toISOString()
        : undefined;

    const repaymentRates = _.countBy(
      extractedLoanValues,
      (e) => e.totalPayment
    );
    // check where repaymentRate count is highest
    const repaymentRate = _.maxBy(
      Object.keys(repaymentRates),
      (e) => repaymentRates[e]
    );
    const loanTermYears = repaymentDate
      ? NumberUtils.round(
          moment(extractedLoanValues[extractedLoanValues.length - 1].date)
            .utc(true)
            .diff(moment(repaymentDate).utc(true), "days") / 365
        )
      : undefined;

    const entryWithRepaymentRate = extractedLoanValues.find(
      (e) => e.totalPayment === Number(repaymentRate) && e.interestAmount > 0
    );

    const allEntriesWhereRepayedSomething = extractedLoanValues.filter(
      (e) => e.repaymentAmount > 0
    );

    const interestRateAvg = NumberUtils.round(
      _.sum(
        allEntriesWhereRepayedSomething.map((e) =>
          NumberUtils.round(
            (e.interestAmount / ((e.startBalance || 0) + (e.payout || 0))) *
              12 *
              100
          )
        )
      ) / allEntriesWhereRepayedSomething.length
    );

    const metaData: LoanDataFields = {
      loanAmount: _.sumBy(extractedLoanValues, (e) => e.payout),
      loanPayoutDate: payoutDate ? new Date(payoutDate) : undefined,
      loanStartDate: repaymentDate ? new Date(repaymentDate) : undefined,
      loanTermYears,
      annualInterest: interestRateAvg,
      paymentAmount: Number(repaymentRate),
    };

    return { loanValues: extractedLoanValues, meta: metaData };
  }

  recalculateAnnuityLoan(payments: LoanValueEntry[], loan: AnnuityLoanData) {
    const output: LoanValueEntry[] = [];

    payments.forEach((payment, index, entries) => {
      output.push(this.recalculateEntry(payment, output, loan));
    });
    const paymentsCountMax =
      loan.loanData.paymentsPerYear * loan.loanData.loanTermYears;
    let paymentNumber =
      [...output].reverse().find((e) => e?.paymentNumber !== undefined)
        ?.paymentNumber || 0;

    // fill rest of payments - can be null and then be filtered out
    while (paymentNumber < paymentsCountMax) {
      output.push(
        this.recalculateEntry(
          { edited: false, id: nanoid() } as LoanValueEntry,
          output,
          loan
        )
      );
      paymentNumber++;
    }

    return output.filter((e) => e != null);
  }
  recalculateEntry(
    payment: LoanValueEntry,
    beforePayments: LoanValueEntry[],
    loan: AnnuityLoanData
  ) {
    const blockChange = payment.edited || payment.confirmed;
    const beforePayment =
      beforePayments.length > 0
        ? beforePayments[beforePayments.length - 1]
        : undefined;
    if (beforePayment === null || beforePayment?.endBalance === 0) {
      return null;
    }
    const startBalance = beforePayment?.endBalance || 0;

    const payout = blockChange
      ? payment.payout
      : !beforePayment
      ? loan.loanData.loanAmount
      : 0;

    const otherCost = blockChange ? payment.otherCost : 0;

    let date;
    if (blockChange) {
      date = payment.date;
    } else {
      if (beforePayment) {
        const calcDate = moment(beforePayment.date)
          .utc(true)
          .startOf("day")
          .add(1, "month");
        if (
          calcDate.isSame(
            moment(loan.loanData.loanStartDate).utc(true),
            "month"
          )
        ) {
          date = moment(loan.loanData.loanStartDate)
            .utc(true)
            .startOf("day")
            .toISOString();
        } else {
          date = calcDate.toISOString();
        }
      } else {
        date = moment(loan.loanData.loanPayoutDate)
          .utc(true)
          .startOf("day")
          .toISOString();
      }
    }
    // const date = payment.edited
    //   ? payment.date
    //   : beforePayment &&
    //     moment(beforePayment.date)
    //       .add(1, "month")
    //       .isSameOrAfter(moment(loan.loanData.loanStartDate))
    //   ? moment(loan.loanData.loanStartDate).toISOString()
    //   : beforePayment &&
    //     !moment(beforePayment.date)
    //       .add(1, "month")
    //       .isSameOrAfter(moment(loan.loanData.loanStartDate))
    //   ? moment(beforePayment.date).add(1, "month").toISOString()
    //   : !beforePayment
    //   ? moment(loan.loanData.loanPayoutDate).toISOString()
    //   : payment.date;

    const interestAmount = blockChange
      ? payment.interestAmount
      : ((beforePayment?.endBalance || 0) *
          (1 / (loan.loanData.paymentsPerYear || 12)) *
          loan.loanData.annualInterest) /
        100;
    const repaymentAmount = blockChange
      ? payment.repaymentAmount
      : moment(date)
          .utc(true)
          .isSameOrAfter(moment(loan.loanData.loanStartDate).utc(true))
      ? Math.min(loan.loanData.paymentAmount, startBalance + interestAmount) -
        interestAmount
      : 0;

    const totalPayment = interestAmount + repaymentAmount;
    const endBalance =
      startBalance + (payout || 0) + (otherCost || 0) - repaymentAmount;
    const accumulatedInterest =
      (beforePayment?.accumulatedInterest || 0) + interestAmount;

    const repaymentInPercent = (repaymentAmount / totalPayment) * 100;
    const paymentNumber =
      ([...beforePayments].reverse().find((e) => e.paymentNumber !== undefined)
        ?.paymentNumber || 0) + 1;
    const paymentsCountMax =
      loan.loanData.paymentsPerYear * loan.loanData.loanTermYears;

    if (paymentNumber > paymentsCountMax) {
      return null;
    }
    return {
      ...payment,
      payout: NumberUtils.round(payout),
      paymentNumber,
      startBalance: NumberUtils.round(startBalance),
      date,
      totalPayment: NumberUtils.round(totalPayment),
      endBalance: NumberUtils.round(endBalance),
      accumulatedInterest: NumberUtils.round(accumulatedInterest),
      interestAmount: NumberUtils.round(interestAmount),
      repaymentAmount: NumberUtils.round(repaymentAmount),
      repaymentInPercent: repaymentInPercent,
    } as LoanValueEntry;
  }

  calculateAnnuityLoan(loan: AnnuityLoanData) {
    const output: LoanValueEntry[] = [
      {
        id: nanoid(),
        date: loan.loanData.loanPayoutDate
          .startOf("day")
          .utc(true)
          .toISOString(),
        startBalance: 0,
        payout: NumberUtils.round(loan.loanData.loanAmount),
        accumulatedInterest: 0,
        comment: i18n.t("cb:Loan.initialPayout", "Initiale Auszahlung"),
        endBalance: NumberUtils.round(loan.loanData.loanAmount),
        interestAmount: 0,
        //   (moment(loan.loanData.loanPayoutDate).diff(
        //     moment(loan.loanData.loanPayoutDate).endOf("month"),
        //     "day"
        //   ) /
        //     moment(loan.loanData.loanPayoutDate).daysInMonth()) *
        //   loan.loanData.loanAmount *
        //   loan.loanData.annualInterest,
        repaymentAmount: 0,
        otherCost: 0,
        repaymentInPercent: 0,
        totalPayment: 0,
        paymentNumber: undefined,
        edited: false,
      },
    ];
    let run = moment(loan.loanData.loanPayoutDate).utc(true);
    const end = moment(loan.loanData.loanPayoutDate)
      .utc(true)
      .add(loan.loanData.loanTermYears, "year");

    let amountLeft = loan.loanData.loanAmount;

    while (
      output[output.length - 1] !== null &&
      moment(output[output.length - 1].date)
        .utc(true)
        .isBefore(end)
    ) {
      output.push(this.calculateInitialNext(output, loan));
    }

    return output.filter((e) => e !== null);
  }
  calculateInitialNext(
    payments: LoanValueEntry[],
    loan: AnnuityLoanData
  ): LoanValueEntry {
    const loanEntry = payments[payments.length - 1];

    if (loanEntry === null || loanEntry?.endBalance === 0) {
      return null;
    }
    let date = moment(loanEntry.date).utc(true).add(1, "month").startOf("day");

    if (date.isSame(moment(loan.loanData.loanStartDate).utc(true), "month")) {
      date = moment(loan.loanData.loanStartDate).startOf("day").utc(true);
    }

    const interestAmount =
      (loanEntry.endBalance *
        (1 / (loan.loanData.paymentsPerYear || 12)) *
        loan.loanData.annualInterest) /
      100;
    // const repaymentAmount = moment(date).isSameOrAfter(
    //   moment(loan.loanData.loanStartDate)
    // )
    //   ? loan.loanData.paymentAmount - interestAmount
    //   : 0;

    const repaymentAmount = moment(date)
      .utc(true)
      .isSameOrAfter(moment(loan.loanData.loanStartDate).utc(true))
      ? Math.min(
          loan.loanData.paymentAmount,
          loanEntry.endBalance + interestAmount
        ) - interestAmount
      : 0;

    const totalPayment = interestAmount + repaymentAmount;
    const endBalance = loanEntry.endBalance - repaymentAmount;
    return {
      paymentNumber:
        repaymentAmount > 0
          ? ([...payments].reverse().find((e) => e.paymentNumber !== undefined)
              ?.paymentNumber || 0) + 1
          : undefined,
      id: nanoid(),
      date: date.startOf("day").utc(true).toISOString(),
      startBalance: NumberUtils.round(loanEntry.endBalance),
      interestAmount: NumberUtils.round(interestAmount),
      repaymentAmount: NumberUtils.round(repaymentAmount),
      comment: "",
      accumulatedInterest: NumberUtils.round(
        loanEntry.accumulatedInterest + interestAmount
      ),
      otherCost: 0,
      payout: 0,
      totalPayment: NumberUtils.round(totalPayment),
      endBalance: NumberUtils.round(endBalance),
      repaymentInPercent: (repaymentAmount / totalPayment) * 100,
      edited: false,
    };
  }
}
const CBLoanUtils = new CBLoanUtilsClass();
(window as any).CBLoanUtils = CBLoanUtils;
export default CBLoanUtils;
