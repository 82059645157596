import React, { CSSProperties } from "react";
import { FormSpy } from "react-final-form";
import { AbstractComponent } from "../../utils/abstracts/AbstractComponent";

type Props = {
	condition?: string;
	style?: CSSProperties;
	render: (styleProps: CSSProperties) => React.ReactNode;
};

type States = {};

// condition: "${val} === true && #{global.xs} === true"

class GenericLayoutComponent extends AbstractComponent<Props, States> {
	render() {
		const { condition, render, style } = this.props;

		if (condition) {
			return (
				<FormSpy subscription={{ values: true }}>
					{({ values }) => {
						if (values && this.evaluateExpression(condition, values)) {
							return render({
								...style,
								display: style ? style.display : undefined
							});
						} else {
							return render({ ...style, display: "none" });
						}
					}}
				</FormSpy>
			);
		} else {
			return render({ ...style, display: style ? style.display : undefined });
		}
	}
}

export default GenericLayoutComponent;
