import { Component } from "react";
import { Loader } from "rsuite";
import { AppState } from "../../redux/store";
import "./LoadMask.scss";

type Props = {};

type States = {};

class LoadMask extends Component<Props, States> {
  render() {
    const {} = this.props;

    return (
      <div className="load-mask">
        <div>
          <Loader size="lg" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

export default LoadMask;
