import {
  ExportTableColumn,
  ExportTreeData,
  TableExcelExportOptions,
} from "./export.model";

export const exportTableData = async (
  filename: string,
  columns: ExportTableColumn[],
  data: any[],
  exportAs: "json" | "xlsx" | "csv" = "xlsx",
  otherOptions?: TableExcelExportOptions
) => {
  const { exportTableWorker: exportTableWorker } = await import(
    "./export.private"
  );

  await exportTableWorker(filename, columns, data, exportAs, otherOptions);
};

export const exportTreeData = async (
  filename: string,
  dataColumns: ExportTableColumn[],
  data: ExportTreeData[]
) => {
  const { exportTreeDataWorker: exportTreeDataWorker } = await import(
    "./export.private"
  );

  await exportTreeDataWorker(filename, dataColumns, data);
};
