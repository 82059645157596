import classNames from "classnames";
import React from "react";
import "./ContactDetailsSection.scss";

interface ContactDetailsSectionProps {
  title: string;
  children: React.ReactNode;
}
const ContactDetailsSection = (props: ContactDetailsSectionProps) => {
  return (
    <div className={classNames(`contact-details-section`)}>
      <div className={`title-of-section`}>{props.title}</div>
      <div className={`content-of-section`}>{props.children}</div>
    </div>
  );
};

export default ContactDetailsSection;
