import Log from "@/debug/Log";
import i18n from "@/i18n";
import BaseAsset from "@/model/general-assets/BaseAsset";
import CDNService from "@/services/CDNService";
import LanguageService from "@/services/LanguageService";
import {
  hasValue,
  isDefined,
  isEnumValue,
  isNotDefined,
  mapTraverse,
} from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import StringUtils from "@/utils/StringUtils";
import _ from "lodash";
import { BooleanFilterOption } from "../abstract-ui/data/table-filter/defaults/BooleanFilterOption";
import { DateFilterOption } from "../abstract-ui/data/table-filter/defaults/DateFilterOption";
import { ListFilterOption } from "../abstract-ui/data/table-filter/defaults/ListFilterOption";
import { NumberFilterOption } from "../abstract-ui/data/table-filter/defaults/NumberFilterOption";
import { renderCellValue } from "../abstract-ui/data/table/TableUtils";
import { ColumnConfig } from "../abstract-ui/data/virtualized-table/BFVirtualizedTable";
import DSService from "../document-store/DSService";
import {
  CustomField,
  CustomFieldEditType,
  CustomFieldLayoutType,
  CustomfieldType_edit,
  CustomfieldType_layout,
} from "./CustomField.interface";
import { AttachmentValue, NewAttachmentValue } from "./fields/CFAttachment";

class CustomFieldsServiceClass {
  getInitialValuesForCustomfields(fields: CustomField[], value: any) {
    if (!value || !fields) {
      return {};
    }
    const fieldsTraversed = this.getTraversedFields(fields);

    const valueOutput = {};

    fieldsTraversed.forEach((field) => {
      if (field.fieldType === "attachment") {
        valueOutput[field.id] = (value[field.id] || [])
          .filter((e) => e.status !== "archived")
          .map((entry) => ({
            type: "cdn",
            name: entry.name || "",
            linkToCdn: entry.linkToCdn || "",
          }));
      } else {
        valueOutput[field.id] = value[field.id];
      }
    });

    return valueOutput;
  }

  getTraversedFields(fields: CustomField[]) {
    const fieldsTraversed = mapTraverse<CustomField, CustomfieldType_edit>(
      fields,
      (field, traverseFc) => {
        const isEditType = isEnumValue(field.fieldType, CustomFieldEditType);
        const isLayoutType = isEnumValue(
          field.fieldType,
          CustomFieldLayoutType
        );

        if (isEditType) {
          return [field];
        } else if (isLayoutType) {
          const fieldLayout = field as CustomfieldType_layout;
          return (fieldLayout.fields || [])
            .map((e) => traverseFc(e, traverseFc))
            .flat();
        } else {
          return [];
        }
      }
    );

    return fieldsTraversed;
  }
  getTraversedValues(fields: CustomField[], values: any | undefined) {
    const fieldsTraversed = mapTraverse<CustomField, CustomfieldType_edit>(
      fields,
      (field, traverseFc) => {
        const isEditType = isEnumValue(field.fieldType, CustomFieldEditType);
        const isLayoutType = isEnumValue(
          field.fieldType,
          CustomFieldLayoutType
        );

        const hasCondition = isDefined(field.condition);

        if (hasCondition) {
          if (!MQ.check(values, field.condition)) {
            // if condition is not set, return empty so childs and self are not added to
            // be displayed
            return [];
          }
        }

        if (isEditType) {
          return [field];
        } else if (isLayoutType) {
          const fieldLayout = field as CustomfieldType_layout;
          return (fieldLayout.fields || [])
            .map((e) => traverseFc(e, traverseFc))
            .flat();
        } else {
          return [];
        }
      }
    );

    return fieldsTraversed;
  }
  formatCustomFieldValue(field: CustomField, value: any) {
    if (isNotDefined(value)) {
      return "-";
    }
    switch (field.fieldType) {
      case CustomFieldEditType.number: {
        const isArea =
          (field.suffix as any)?.area || (field.prefix as any)?.area;

        if (isArea) {
          return StringUtils.formatArea(value);
        } else {
          return "" + value;
        }
      }
      case CustomFieldEditType.currency:
        return StringUtils.formatCurrency(
          value,
          true,
          undefined,
          field.currency
        );
      case CustomFieldEditType.textarea:
      case CustomFieldEditType.string:
        return value;
      case CustomFieldEditType.date:
        return StringUtils.formatDate(value);
      case CustomFieldEditType.boolean:
        return value === true
          ? i18n.t("Global.Labels.yes")
          : i18n.t("Global.Labels.no");
      case CustomFieldEditType.select:
      case CustomFieldEditType.radio:
        return (
          LanguageService.translateLabel(
            field.options.find((e) => e.value === value)?.label
          ) || value
        );
      default:
        return value;
    }
  }

  private getTableCustomFieldAggregation(
    field: CustomfieldType_edit,
    prefix?: string
  ) {
    if (field.table && (field.table as any)?.aggregate) {
      const path = `data.${prefix ? prefix + "." : ""}${field.id}`;

      switch (field.fieldType) {
        case CustomFieldEditType.number:
        case CustomFieldEditType.currency:
          return {
            key: field.id,
            op: "sum",
            field: path,
          };
      }
    }

    return null;
  }

  getTableCustomFieldAggregations(
    customFields: CustomField[],
    prefix?: string
  ) {
    if (customFields?.length > 0) {
      const fieldsTraversed = this.getTraversedFields(customFields);

      const fields = fieldsTraversed
        .filter((e) => hasValue(e.table))
        .map((field) => this.getTableCustomFieldAggregation(field, prefix))
        .filter((e) => e);

      if (fields.length > 0) {
        return [
          {
            name: "customfields",
            op: fields,
            query: {},
          },
        ];
      }
    }
    return [];
  }
  private getTableCustomFieldFilter(
    field: CustomfieldType_edit,
    prefix?: string
  ) {
    if (field.table) {
      const path = `data.${prefix ? prefix + "." : ""}${field.id}`;

      switch (field.fieldType) {
        case CustomFieldEditType.number:
          return NumberFilterOption({
            field: path,
            label: LanguageService.translateLabel(field.displayName),
            type: "number",
          });

        case CustomFieldEditType.currency:
          return NumberFilterOption({
            field: path,
            label: LanguageService.translateLabel(field.displayName),
            type: "currency",
          });

        case CustomFieldEditType.textarea:
        case CustomFieldEditType.string:
          return null;
        // todo add/create regex string filter
        // return NumberFilterOption({
        //   field: path,
        //   label: LanguageService.translateLabel(field.displayName),
        //   type: "currency",
        // });
        case CustomFieldEditType.date:
          return DateFilterOption({
            field: path,
            label: LanguageService.translateLabel(field.displayName),
          });
        case CustomFieldEditType.boolean:
          return BooleanFilterOption({
            field: path,
            label: LanguageService.translateLabel(field.displayName),
            defaultValue: true,
          });
        case CustomFieldEditType.select:
        case CustomFieldEditType.radio:
          return ListFilterOption({
            field: path,
            label: LanguageService.translateLabel(field.displayName),
            data: field.options.map((e) => ({
              value: e.value,
              label: LanguageService.translateLabel(e.label),
            })),
          });
        default:
          return null;
      }
    }
    return null;
  }
  getTableCustomFieldFilters(customFields: CustomField[], prefix?: string) {
    const fieldsTraversed = this.getTraversedFields(customFields);

    return fieldsTraversed
      .filter((e) => hasValue(e.table))
      .map((field) => this.getTableCustomFieldFilter(field, prefix))
      .filter((e) => e);
  }
  getTableCustomFields(customFields: CustomField[], prefix?: string) {
    const fields: { [columnId: string]: ColumnConfig } = {};

    const fieldsTraversed = this.getTraversedFields(customFields);

    fieldsTraversed
      .filter((e) => hasValue(e.table))
      .map((field) => {
        const fieldProps = field.table === true ? {} : field.table;
        const { aggregate, disableExport, ...otherTableProps } = fieldProps;

        const path = `data.${prefix ? prefix + "." : ""}${field.id}`;

        fields[path] = {
          label: LanguageService.translateLabel(field.displayName),
          flexWidth: 120,
          sortable: true,
          resizable: true,
          ...otherTableProps,

          render: (data: any) => {
            const value = _.get(data, path);
            const formattedValue = this.formatCustomFieldValue(field, value);
            return renderCellValue(formattedValue);
          },
          ...(aggregate
            ? ({
                renderFooter: (params: any) => {
                  const value =
                    params?.aggregated?.data?.["customfields"]?.[field.id];

                  if (value) {
                    const formattedValue = this.formatCustomFieldValue(
                      field,
                      value
                    );

                    return renderCellValue(formattedValue);
                  } else {
                    return null;
                  }
                },
              } as any)
            : {}),
          export: disableExport
            ? undefined
            : {
                label: LanguageService.translateLabel(field.displayName),
                type: "string",
                width: 100,
                totalFunction: aggregate ? "sum" : undefined,
                // conditionalFormats

                selector: (data: any) => {
                  const value = _.get(data, path);
                  return value?.toString();
                },
              },
        };
      });

    return fields;
  }

  async handleCustomFieldAttachments(props: {
    assetType: string;
    values: any;
    customFields: CustomField[];
    submitFc: (transformValues: any) => Promise<BaseAsset>;
    prefix?: string;
    asset?: BaseAsset;
  }) {
    let valuesCopy = _.cloneDeep(props.values);
    const metaValues = {};

    const fieldsTraversed = this.getTraversedValues(
      props.customFields,
      props.prefix ? _.get(valuesCopy, props.prefix) : valuesCopy
    );
    const attachmentCustomFields = fieldsTraversed
      // ?.filter((e) => e.fieldType === "attachment")
      .map((e) => {
        const path = `${props.prefix ? props.prefix + "." : ""}${e.id}`;
        if (e.fieldType === "attachment") {
          const attachmentValue = _.get(valuesCopy, path) as AttachmentValue[];
          _.set(valuesCopy, path, undefined);

          return {
            ...e,
            path,
            value: attachmentValue || [],
            preChangeValue: props.asset
              ? _.get(props.asset, `data.${path}`) || []
              : [],
          };
        } else {
          metaValues[e.id] = _.get(valuesCopy, path);
          return null;
        }
      })
      .filter((e) => e);

    if (props.prefix) {
      _.set(valuesCopy, props.prefix, metaValues);
    } else {
      valuesCopy = {
        ...valuesCopy,
        ...metaValues,
      };
    }
    const asset = await props.submitFc(valuesCopy);

    let result = asset;
    for (const attachmentCustomField of attachmentCustomFields) {
      const deletedAttachments = attachmentCustomField.preChangeValue.filter(
        (e: any) =>
          !(attachmentCustomField.value || []).find(
            (a) => a.type === "cdn" && a.linkToCdn === e.linkToCdn
          )
      );
      const newAttachments = attachmentCustomField.value.filter(
        (e) => e.type === "new"
      ) as NewAttachmentValue[];

      if (deletedAttachments.length > 0) {
        // await asset.removeAttachments(deletedAttachments);

        for (const attachment of deletedAttachments) {
          Log.info("delete new attachment", attachment, "bot implamenebt");
          //   await CDNService.deleteAttachment(attachment.id);
          result = await DSService.deleteDocument(
            {
              asset: props.asset,
              assetType: props.assetType,
              type: (props.asset as any)?.data?.type,
              documentsFieldPath: `data.${attachmentCustomField.path}`,
            },
            {
              ...attachment,
            }
          );
        }
      }
      for (const attachment of newAttachments) {
        Log.info("upload new attachment", attachment);
        result = (await CDNService.uploadAttachment(
          asset._id,
          {
            file: attachment.file,
            name: attachment.file.name,
          },
          props.assetType,
          `data.${attachmentCustomField.path}`,
          attachment.id,
          (progres: number) => {}
        )) as BaseAsset;
      }
    }
    return result;
  }
}
const CustomFieldsService = new CustomFieldsServiceClass();
export default CustomFieldsService;
