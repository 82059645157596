import classNames from "classnames";
import { useState } from "react";
import i18n from "../../../../../i18n";
import ArrayUtils from "../../../../../utils/ArrayUtils";
import BFCheckbox from "../../../forms/checkbox/BFCheckbox";
import BFInput from "../../../forms/input/BFInput";
import BFToggle from "../../../forms/toggle/BFToggle";
import BFButton from "../../../general/Button/BFButton";
import "./OverlayMultipleSelectionWithNegation.scss";
import OverlayTitle from "./OverlayTitle";
type Entry = {
  label: string;
  value: string | number;
  searchTags?: string[];
  color?: string;
  subLabel?: string;
};
export type ListSelectionValueWithNegation = {
  negate: boolean;
  value: (string | number)[];
};
interface OverlayMultipleSelectionProps {
  title: string;
  entries: Entry[];
  value: ListSelectionValueWithNegation;
  onChange: (value: ListSelectionValueWithNegation) => void;
  allowNegation?: boolean;
  embedded?: boolean;
}
const OverlayMultipleSelectionWithNegation = (
  props: OverlayMultipleSelectionProps
) => {
  const [search, setSearch] = useState<string>("");
  const entriesToRender = search
    ? ArrayUtils.fuzzyFilter(search, props.entries, {
        keys: ["label", "searchTags"],
      }).map((e) => e.item)
    : props.entries;
  return (
    <div
      className={classNames(`overlay-multiple-selection-with-negation`, {
        embedded: props.embedded,
      })}
    >
      <OverlayTitle title={props.title} onClear={() => props.onChange(null)} />
      {props.allowNegation && (
        <div className={`negate-input`}>
          <BFToggle
            labelPosition="right"
            size="sm"
            checked={!props.value.negate}
            onChange={(checked) => {
              props.onChange({
                ...props.value,
                negate: !checked,
              });
            }}
            label={
              !props.value.negate
                ? i18n.t("BFFIlterbar.Multiple.negateTrue", "enthält")
                : i18n.t("BFFIlterbar.Multiple.negateFalse", "enthält nicht")
            }
          />
        </div>
      )}
      <div className="search">
        <BFInput
          placeholder={i18n.t("BFTableSearch.searchPlaceholder", "Suche...")}
          appearance="clear"
          value={search}
          onChange={(value: string) => setSearch(value)}
        />
      </div>
      <div className="content">
        {entriesToRender.length === 0 && (
          <div className={`empty-text`}>
            {i18n.t(
              "BFFilterbar.Multiple.noEntries",
              "Keine Einträge gefunden"
            )}
          </div>
        )}
        {entriesToRender.map((e) => (
          <div className="selection-element" key={e.value}>
            <BFCheckbox
              checked={(props.value?.value || [])?.indexOf(e.value) !== -1}
              onChange={(_value, checked) =>
                checked
                  ? props.onChange({
                      ...props.value,
                      value: [...(props.value?.value || []), e.value],
                    })
                  : props.onChange({
                      ...props.value,
                      value: props.value?.value?.filter(
                        (valueEntry) => valueEntry !== e.value
                      ),
                    })
              }
            >
              {e.color && (
                <div className={`color-indicator-wrapper`}>
                  <div
                    className={classNames(`color-indicator`)}
                    style={{ backgroundColor: e.color }}
                  ></div>
                </div>
              )}
              {e.label}
              {e.subLabel && <div className={`sub-label`}>{e.subLabel}</div>}
            </BFCheckbox>
          </div>
        ))}
      </div>
      <div className="footer">
        <BFButton
          appearance="link"
          onClick={() =>
            props.onChange({
              ...props.value,
              value: props.entries.map((e) => e.value),
            })
          }
        >
          {i18n.t("BFFilterbar.Multiple.selectAll", "Alle auswählen")}
        </BFButton>
        <BFButton
          appearance="link"
          onClick={() => props.onChange({ ...props.value, value: [] })}
        >
          {i18n.t("BFFilterbar.Multiple.deselectAll", "Alle abwählen")}
        </BFButton>
      </div>
    </div>
  );
};

export default OverlayMultipleSelectionWithNegation;
