import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import { AssetTypes } from "../../../../../model/AssetTypes";
import EZTags from "../../../../../modules/ez-form/form-elements/ez-tags/EZTags";
import EZField from "../../../../../modules/ez-form/form-group/ez-field/EZField";
import CacheService from "../../../../../services/CacheService";
import LanguageService from "../../../../../services/LanguageService";
import InvoiceService from "../../InvoiceService";
import { RAInvoice } from "../../RAInterfaces";
import "./RAUpdateInvoiceEntry.scss";

export const RAInvoiceChangeTags = (props: {
  asset: RAInvoice;
  readonly?: boolean;
}) => {
  return (
    <>
      {UnitStruct.getTagConfig(props.asset?.data.type, AssetTypes.Invoice)
        ?.length > 0 && (
        <div className={`tags`}>
          <EZField
            value={props.asset?.data.tags}
            url={`/invoice/${props.asset?._id}/updateInvoice`}
            onSubmitSuccess={(value) =>
              CacheService.updateDataInCaches(value._id, value)
            }
            transformSubmitData={(value) => {
              const invoiceData = InvoiceService.getInitialDataForInvoice(
                props.asset
              );

              return {
                ...invoiceData,
                custom: invoiceData.custom.filter((e) => e.value),
                objectId: invoiceData.objectId || undefined,
                paymentPlan:
                  invoiceData.paymentPlan?.map(
                    ({ payedDate, ...other }) => other
                  ) || [],
                urgent: invoiceData.urgent || false,
                urgentComment: invoiceData.urgent
                  ? invoiceData.urgentComment
                  : "",
                tags: value,
              };
            }}
            delayedSubmit={1000}
          >
            {({ value, onChange, onBlur, loading, submitData }) => (
              <EZTags
                readonly={props.readonly}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                data={UnitStruct.getTagConfig(
                  props.asset?.data.type,
                  AssetTypes.Invoice
                ).map((e) => ({
                  value: e.id,
                  label: LanguageService.translateLabel(e.displayName),
                  disabled: e.status === "archived",
                }))}
              />
            )}
          </EZField>
        </div>
      )}
    </>
  );
};
