import { ActivityAbstractStructClass } from "@/redux/actions/struct/implemented/ActivityAbstractStruct";
import { createContext, useContext } from "react";

export const ActivityStructContext =
  createContext<ActivityAbstractStructClass<any>>(null);

export const useActivityStruct = (): ActivityAbstractStructClass<any> => {
  const data = useContext(
    ActivityStructContext
  ) as ActivityAbstractStructClass<any>;

  return data;
};
