const fixAnimationForElementQueries = () => {
  let animationStart = "";
  if (
    typeof document.documentElement.style["webkitAnimationName"] !== "undefined"
  ) {
    animationStart = "webkitAnimationStart";
  } else if (
    typeof document.documentElement.style["MozAnimationName"] !== "undefined"
  ) {
    animationStart = "mozanimationstart";
  } else if (
    typeof document.documentElement.style["OAnimationName"] !== "undefined"
  ) {
    animationStart = "oanimationstart";
  }
  if (animationStart) {
    (window as any).document.body.addEventListener("animationstart", (e) => {
      var event; // The custom event that will be created
      var element = e.target;
      if (document.createEvent) {
        event = document.createEvent("HTMLEvents");
        event.initEvent(animationStart, true, true);
        event.eventName = animationStart;
        element.dispatchEvent(event);
      } else {
        event = (document as any).createEventObject();
        event.eventName = animationStart;
        event.eventType = animationStart;
        element.fireEvent("on" + event.eventType, event);
      }
    });
  }
};

const init = () => {
  fixAnimationForElementQueries();
};
export default init;
