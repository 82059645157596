import AssetLoader from "@/components/AssetLoader/AssetLoader";
import { AssetTypes } from "@/model/AssetTypes";
import classNames from "classnames";
import { useState } from "react";
import i18n from "../../i18n";
import { Contact } from "../../model/db/Contact";
import BFButton from "../abstract-ui/general/Button/BFButton";
import "./ContactDetailModal.scss";
import ContactForm from "./ContactForm";
import ContactView from "./ContactView";
import ContactDetailsInvoiceSection from "./contact-details/ContactDetailsInvoiceSection";
import ContactDetailsTenantSection from "./contact-details/ContactDetailsTenantSection";
import ContactMerge from "./contacts-merge/ContactMerge";
interface ContactDetailModalProps {
  contact: Contact;
}
const ContactDetails = (props: ContactDetailModalProps) => {
  const [contact, setContact] = useState<Contact>(props.contact);
  const [mergeContact, setMergeContact] = useState<Contact>(null);
  const [editActive, setEditActive] = useState(false);
  const [mergeActive, setMergeActive] = useState(false);

  return (
    <AssetLoader
      assetType={AssetTypes.Contact}
      id={contact._id}
      silentReload
      ignoreDelay
      ignoreViewportDelay
      render={(contact) => (
        <div className={classNames(`contact-detail-modal __card`)}>
          <div className={`contact-details`}>
            {editActive && (
              <ContactForm
                noPadding
                onClose={() => setEditActive(false)}
                businessUnits={[contact.data.type]}
                onSuccessfulSubmit={() => setEditActive(false)}
                contact={contact}
              />
            )}
            {!editActive && (
              <ContactView
                contact={contact}
                title={
                  mergeActive
                    ? i18n.t("Contacts.Merge.TargetContact", "Zielkontakt")
                    : i18n.t("Contacts.Detail.Contact", "Kontaktdaten")
                }
                actions={
                  <div className={`contact-view-actions`}>
                    <BFButton
                      noPadding
                      appearance={"link"}
                      onClick={() => setEditActive(true)}
                    >
                      {i18n.t("Global.Buttons.edit")}
                    </BFButton>
                    <BFButton
                      noPadding
                      appearance={"link"}
                      onClick={() => setMergeActive(!mergeActive)}
                    >
                      {mergeActive
                        ? i18n.t("Contacts.Detail.Relations", "Verknüpfungen")
                        : i18n.t(
                            "Contacts.Detail.mergeDuplicates",
                            "Dubletten"
                          )}
                    </BFButton>
                  </div>
                }
              />
            )}
          </div>
          {mergeActive ? (
            <ContactMerge
              baseContact={contact}
              mergeContact={mergeContact}
              setContacts={(baseContact, mergeContact) => {
                setContact(baseContact);
                setMergeContact(mergeContact);
              }}
            />
          ) : (
            <div className={`contact-connections`}>
              {contact.data.contactType?.includes("INVOICE") && (
                <ContactDetailsInvoiceSection
                  contact={contact}
                  direction="INCOMING"
                />
              )}
              {contact.data.contactType?.includes("BILL") && (
                <ContactDetailsInvoiceSection
                  contact={contact}
                  direction="OUTGOING"
                />
              )}
              {contact.data.contactType?.includes("TENANT") && (
                <ContactDetailsTenantSection contact={contact} />
              )}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default ContactDetails;
