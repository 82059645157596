import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import "./HTMLText.scss";
interface HTMLTextProps {
  text: string;
  withBorder?: boolean;
  maxHeight?: number;
}
const HTMLText = (props: HTMLTextProps) => {
  return (
    <div
      className={classNames(`html-text`, {
        "with-border": props.withBorder,
      })}
      style={{
        maxHeight: props.maxHeight,
      }}
      dangerouslySetInnerHTML={{ __html: StringUtils.sanitizeHtml(props.text) }}
    ></div>
  );
};

export default HTMLText;
