import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { Contact, ContactType } from "@/model/db/Contact";
import BFStatus from "@/modules/abstract-ui/data/status/BFStatus";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "@/modules/abstract-ui/general/Dropdown/BFDropdown";
import { DropdownButton } from "@/modules/abstract-ui/general/Dropdown/BFDropdownContent";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import InfiniteTable from "@/redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "@/redux/hooks";
import PermissionService from "@/services/PermissionService";
import MQ from "@/utils/MatchQueryUtils";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ContactLabel from "../ContactLabel";
import ContactUtils from "../ContactUtils";

import { hasValue, when } from "@/utils/Helpers";
import "./ContactSearch.scss";
import { MatchQuery } from "@/services/DataService";

export type ContactTypeFilter = { label: string; contactType: ContactType[] };
export const getContactTypeFilter: () => ContactTypeFilter[] = () => [
  {
    label: i18n.t("Contacts.DrawerTitle", "Alle Kontakte"),
    contactType: null,
  },
  {
    label: ContactUtils.getContactTypeName("EMPLOYEE"),
    contactType: ["EMPLOYEE"],
  },
  {
    label: ContactUtils.getContactTypeName("INVOICE"),
    contactType: ["INVOICE"],
  },
  {
    label: ContactUtils.getContactTypeName("TENANT"),
    contactType: ["TENANT"],
  },
  {
    label: ContactUtils.getContactTypeName("BILL"),
    contactType: ["BILL"],
  },
  {
    label: ContactUtils.getContactTypeName("OTHER"),
    contactType: ["OTHER", "TRANSIENT"],
  },
];

interface ContactSearchProps {
  onResultItemClick: (result: Contact) => void;
  identifier: string;
  excludeIds?: string[];
  additionalMatchQuery?: MatchQuery;
}

const ContactSearch = (props: ContactSearchProps) => {
  const TABLE_IDENTIFIER = props.identifier;
  const [filters] = useState(getContactTypeFilter());
  const [contactFilter, setContactFilter] = useState<ContactTypeFilter>(
    filters[0]
  );
  const tableSearch =
    useTypedSelector(
      (state) => state.application.infiniteTables[TABLE_IDENTIFIER]?.searchTerm
    ) || "";
  const dispatch = useDispatch();

  return (
    <div className={`contact-search-container`}>
      <div className={`header`}>
        <div className={`title`}>
          <BFDropdown
            className="contact-drawer-dropdown"
            items={filters.map(
              (contactType) =>
                ({
                  type: "button",
                  active: contactFilter === contactType,
                  text: contactType.label,
                  onSelect: () => setContactFilter(contactType),
                } as DropdownButton)
            )}
            label={contactFilter.label}
          />
        </div>
        <div className={`search`}>
          <BFInput
            focusOnMount
            value={tableSearch}
            placeholder={i18n.t("Global.Labels.Search")}
            onChange={(value: string) => {
              dispatch(InfiniteTable.setSearch(TABLE_IDENTIFIER, value));
            }}
            prefix={<BfIcon {...DefaultIcons.SEARCH} size="xs" />}
          />
        </div>
      </div>
      <div className={`search-result`}>
        <ListComponent
          assetType={AssetTypes.Contact}
          identifier={TABLE_IDENTIFIER}
          additionalMatchQuery={MQ.and(
            MQ.in("data.type", PermissionService.getAllPermissionUnits()),
            contactFilter?.contactType
              ? MQ.in("data.contactType", contactFilter.contactType)
              : undefined,
            when(hasValue(props.excludeIds), MQ.nin("_id", props.excludeIds)),
            when(
              hasValue(props.additionalMatchQuery),
              props.additionalMatchQuery
            )
          )}
          render={(contact: Contact) => {
            const infoLine = ContactUtils.getInfoLine(contact);
            return (
              <BFButton
                appearance="clear-on-white"
                className={`contact-entry`}
                style={
                  contact.data.status === "archived" ? { opacity: "0.5" } : {}
                }
                onClick={() => {
                  props.onResultItemClick(contact);
                }}
              >
                <div className={`left`}>
                  <div className={`contact-name`}>
                    <ContactLabel
                      contact={contact}
                      withType
                      placement="leftStart"
                    />
                  </div>

                  {infoLine && <div className={`info-line`}>{infoLine}</div>}
                </div>
                <div className={`right`}>
                  <div className={`tags`}>
                    {ContactUtils.reduceContactTypes(
                      contact.data?.contactType || []
                    ).map((tag) => (
                      <BFStatus
                        key={tag}
                        size="xxs"
                        label={ContactUtils.getContactTypeName(tag)}
                      />
                    ))}
                  </div>
                </div>
              </BFButton>
            );
          }}
        />
      </div>
    </div>
  );
};

export default ContactSearch;
