import React from "react";
import { FormSpy } from "react-final-form";
import { GenericFormsLayoutProps, GFBaseElement } from "../GFBaseElement";
import ExpressionHelper from "../util/ExpressionHelper";

interface PageProps {
	items: { [key: string]: any };
}

type Props = {
	pages: {
		[pageId: string]: PageProps;
	};
	id?: string;
	pageStart: string;
	routes: {
		[pageId: string]: {
			[followingPageId: string]: boolean | string;
		};
	};
} & GenericFormsLayoutProps;
type States = {
	activePage: string;
	nextPage?: string;
	history: string[];
};

class FormWizard extends React.Component<Props, States> {
	oldState = {
		errors: null,
		values: null
	};

	static defaultProps = {};

	readonly state: States = {
		activePage: null,
		nextPage: null,
		history: []
	};

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
		const {
			id
			// registerStatusChange,
		} = this.props;
		if (prevState.activePage !== this.state.activePage) {
			// registerStatusChange(id, {
			//     statusName: "page",
			//     value: this.state.activePage
			// })
		}
	}

	componentWillMount(): void {
		const {
			id
			// registerActionChange,
			// registerStatusChange
		} = this.props;
		this.setState(
			{
				activePage: this.props.pageStart
			},
			() => {
				// registerStatusChange(id, {
				//     statusName: "page",
				//     value: this.state.activePage
				// })
			}
		);

		// registerActionChange(id, {
		//         actionName: "back",
		//         actionProps: {
		//             onClick: () => this.onBack(),
		//             state: "hidden"
		//         }
		//     },
		//     {
		//         actionName: "next",
		//         actionProps: {
		//             onClick: () => this.onContinue(),
		//             state: "enabled"
		//         }
		//     },
		//     {
		//         actionName: "submit",
		//         actionProps: {
		//             onClick: () => this.onContinue(),
		//             state: "hidden"
		//         }
		//     }
		// );
	}

	componentDidMount(): void {
		//dirty fix for initial problem
		setTimeout(() => {
			this.checkNextPage(this.props.formRoot.formProps.errors, this.props.formRoot.formProps.values);
		});
	}

	getPropertiesOf(items) {
		let fields = [];

		for (const item of items) {
			if (item.type === "property") {
				fields.push(item.name);
			} else {
				if (item.items) {
					fields = fields.concat(Object.values(this.getPropertiesOf(Object.values(item.items))));
				}
			}
		}
		return fields;
	}

	onBack() {
		const { history, activePage } = this.state;
		const { formRoot } = this.props;
		const historyCopy = [...history];
		const lastPage = historyCopy.pop();

		const currentPage = this.props.pages[activePage];

		const currentPageFields = this.getPropertiesOf(Object.values(currentPage.items));

		currentPageFields.forEach(field => {
			(formRoot.formProps as any).form.mutators.setValue(
				field,
				formRoot.formProps.initialValues && formRoot.formProps.initialValues[field]
					? formRoot.formProps.initialValues[field]
					: undefined
			);
		});

		// todo revert changes in current page (improve history: dont delete history, only on route change)

		this.setState({ activePage: lastPage, history: historyCopy }, () => {
			this.checkNextPage(this.props.formRoot.formProps.errors, this.props.formRoot.formProps.values);
		});
	}

	getElementsOfPage() {
		const { pages } = this.props;
		const { activePage } = this.state;
		const currentPage = pages[activePage];

		const currentPageFields = this.getPropertiesOf(Object.values(currentPage.items));

		return currentPageFields;
	}

	updateActionState() {
		const { activePage, nextPage } = this.state;
		const {
			id,
			// registerActionChange,
			pageStart,
			formRoot: formProps
		} = this.props;

		// const pageFields = this.getElementsOfPage();

		// let hasValidationErrorOnPage = Object.keys(errors).find(errorFieldName => pageFields.indexOf(errorFieldName) !== -1) !== undefined;

		// registerActionChange(id, {
		//         actionName: "back",
		//         actionProps: {
		//             onClick: () => this.onBack(),
		//             state: activePage !== pageStart ? "enabled" : "hidden"
		//         }
		//     },
		//     {
		//         actionName: "next",
		//         actionProps: {
		//             onClick: () => this.onContinue(),
		//             state: nextPage !== "_end" ? (nextPage === null ? "disabled" : "enabled") : "hidden"
		//         }
		//     },
		//     {
		//         actionName: "submit",
		//         actionProps: {
		//             onClick: () => this.onContinue(),
		//             state: nextPage === "_end" ? "enabled" : "hidden"
		//         }
		//     }
		// );
	}

	onContinue() {
		const { nextPage, activePage, history } = this.state;
		if (nextPage === "_end") {
			(this.props.formRoot as any).form.submit();
		} else {
			this.setState({ activePage: nextPage, history: [...history, activePage] }, () => {
				this.checkNextPage(this.props.formRoot.formProps.errors, this.props.formRoot.formProps.values);
				// this.updateActionState(this.props.formProps.errors);
			});
		}
	}

	checkNextPage(errors, values) {
		const { routes } = this.props;
		const { activePage, nextPage } = this.state;

		let nextPageCalc = null;

		const pageFields = this.getElementsOfPage();
		let hasValidationErrorOnPage =
			Object.keys(errors).find(errorFieldName => pageFields.indexOf(errorFieldName) !== -1) !== undefined;

		if (!hasValidationErrorOnPage) {
			nextPageCalc = Object.keys(routes[activePage]).find(route => {
				const condition = routes[activePage][route];
				if (typeof condition === "string") {
					return ExpressionHelper.evaluateExpression(condition, values);
				} else {
					return route;
				}
			});
		}

		if (nextPage !== nextPageCalc) {
			this.setState({ nextPage: nextPageCalc }, () => {
				this.updateActionState();
			});
		}
	}

	render() {
		const { pages, allProperties, formRoot: formProps, prefix } = this.props;
		const { activePage } = this.state;

		const currentPage = pages[activePage];

		return (
			<div className={"form-wizard"}>
				<div className={"page-content"}>
					{Object.values(currentPage.items).map((item, index) => (
						<GFBaseElement key={index} {...item} prefix={prefix} formRoot={formProps} allProperties={allProperties} />
					))}
				</div>

				<FormSpy
					subscription={{ values: true, errors: true }}
					onChange={({ values, errors }) => {
						if (this.oldState.values !== values || this.oldState.errors !== errors) {
							this.checkNextPage(errors, values);
							// this.updateActionState(errors);
						}
						this.oldState.values = values;
						this.oldState.errors = errors;
					}}
				/>
			</div>
		);
	}
}

export default FormWizard;
