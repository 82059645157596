import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import _ from "lodash";
import moment from "moment";
import Log from "../../../../../../debug/Log";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import { ListResponse } from "../../../../../../model/common/HttpModel";
import BaseAsset, {
  TypedBaseAsset,
} from "../../../../../../model/general-assets/BaseAsset";
import CacheService from "../../../../../../services/CacheService";
import DataBusDefaults from "../../../../../../services/DataBusDefaults";
import SubmitService from "../../../../../../services/SubmitService";
import NumberUtils from "../../../../../../utils/ NumberUtils";
import { hasValue, when } from "../../../../../../utils/Helpers";
import { HTTP } from "../../../../../../utils/Http";
import MQ from "../../../../../../utils/MatchQueryUtils";
import { RAInvoice } from "../../../../invoiceApp/RAInterfaces";
import { AssetCashBudgetEntry } from "../../../model/CashBudgetEntry";
import {
  CBPortfolioObject,
  CBStatisticLoanAsset,
} from "../../portfolio/interfaces/CBPortfolioAsset";
import { RentalAgreement } from "../../tenants/TenantsInterfaces";
import { BidlyCreateRuleForm, SuggestionHint } from "../CBIdlyBookingsConst";

type MatchFound = number;
export type Suggestion<T, U> = {
  asset: T;
  matches: U;
};
export type InvoiceSuggestion = Suggestion<
  RAInvoice,
  {
    invoiceId: MatchFound;
    date: MatchFound;
    amount: MatchFound;
  }
>;
export type RentalAgreementSuggestion = Suggestion<
  RentalAgreement,
  {
    object: MatchFound;
    contact: MatchFound;
    amountRentNet: MatchFound;
    amountRentGross: MatchFound;
    amountOperatingCostGross: MatchFound;
    amountOperatingCostNet: MatchFound;
    rentGrossTotal: MatchFound;
    rentNetTotal: MatchFound;
  }
>;

export type DocumentLinkData = {
  documentsPath: string;
  cdnId: string;
  filename: string;
  contentType: string;
};
export type DocumentSuggestion<T extends BaseAsset> = Suggestion<T, {}> & {
  documents: DocumentLinkData[];
};

export type LoanSuggestion = Suggestion<
  CBStatisticLoanAsset,
  {
    loanId: MatchFound;
    amount: MatchFound;
  }
>;

const checkObject = (objectId: string, checkValue: string) => {
  if (objectId === null || checkValue === null) {
    return 0.5;
  }
  if (objectId === checkValue) {
    return 1;
  }
  return 0;
};

const checkAmount = (amount: number, checkValue: number, factor?: number) => {
  if (NumberUtils.equalsNormalized(amount, checkValue)) {
    return 1;
  }
  if (factor) {
    if (
      NumberUtils.isBetween(
        amount,
        checkValue - factor * checkValue,
        checkValue + factor * checkValue
      )
    ) {
      return 0.5;
    }
  } else {
    if (Math.floor(amount) === Math.floor(checkValue)) {
      return 0.5;
    }
  }
  return 0;
};
const checkDate = (
  date: Date,
  checkValue: Date,
  borderFound: number = 30,
  borderPartially: number = 60
) => {
  const dayDiff = moment(date).diff(moment(checkValue), "days");

  if (Math.abs(dayDiff) <= borderFound) {
    return 1;
  }
  if (Math.abs(dayDiff) <= borderPartially) {
    return 0.5;
  }
  return 0;
};

const checkString = (value: string, checkString: string) => {
  const tokens = (value || "").trim().split(" ");

  const matches = tokens.filter(
    (token) =>
      checkString.trim().toLowerCase().indexOf(token.toLowerCase()) !== -1
  );
  return matches.length / tokens.length;
};

class CBIdlyConnectServiceClass {
  async createRule(form: BidlyCreateRuleForm) {
    try {
      await SubmitService.submitDataAsync({
        assetType: AssetTypes.CashBudget.BookingLink,
        type: "asset",
        ignorePropChecks: true,
        ignoreSubmitValidation: true,
        data: { data: form },
      });
    } catch (err) {
      if (err.response.status === 300) {
        const response = err.response.data
          .message as TypedBaseAsset<BidlyCreateRuleForm>;

        const actionsToAdd = form.action;

        const newActions = _.uniqBy(
          [...response.data.action, ...actionsToAdd],
          (e) => `${e.type}#${JSON.stringify(e.val)}`
        );

        const newForm = {
          ...form,
          action: newActions,
        };
        try {
          await SubmitService.submitDataAsync({
            assetType: AssetTypes.CashBudget.BookingLink,
            type: "asset",
            ignorePropChecks: true,
            ignoreSubmitValidation: true,
            data: { _id: response._id, data: newForm },
          });
        } catch (err) {
          DataBusDefaults.toast({
            type: "error",
            text: i18n.t(
              "app:CBIdlyConnectService.link.error",
              "Fehler beim Verknüpfen der Daten"
            ),
          });
          throw err;
        }
      } else {
        DataBusDefaults.toast({
          type: "error",
          text: i18n.t(
            "app:CBIdlyConnectService.link.error",
            "Fehler beim Verknüpfen der Daten"
          ),
        });
        throw err;
      }
    }
  }
  getSuggestionHintLevel(matchLevel: number) {
    if (matchLevel >= 1) {
      return "fully";
    } else if (matchLevel === 0) {
      return "none";
    }
    if (matchLevel >= 0.75) {
      return "almostFully";
    }
    return "partially";
  }
  async fetchSuggestionsForDocuments(booking: AssetCashBudgetEntry) {
    const objectsIds = OrgaStruct.getAllObjectsByBankAccount(
      booking.data.bankAccount
    ).map((e) => e._id);
    const response = (await HTTP.post({
      url: `/asset/list/${AssetTypes.Portfolio.Object}`,
      bodyParams: {
        limit: 10,
        matchQuery: MQ.and(
          MQ.eq("data.type", booking.data.unit),
          MQ.eq("data.entity", booking.data.entity),
          when(
            hasValue(booking.data.objectId),
            MQ.eq("data.lqObject", booking.data.objectId)
          ),
          when(
            hasValue(!booking.data.objectId),
            MQ.in("data.lqObject", objectsIds)
          )
        ),
      },
    })) as ListResponse<CBPortfolioObject>;

    const mapped = response.data.map((asset) =>
      this.mapSuggestionDocument(asset)
    );

    return mapped;
  }
  mapSuggestionDocument(data: CBPortfolioObject) {
    return {
      asset: data,
      matches: {},
      documents: [],
    };
  }
  async fetchSuggestionsForLoan(booking: AssetCashBudgetEntry) {
    const response = (await HTTP.post({
      url: `/asset/list/${AssetTypes.Portfolio.Loan}`,
      bodyParams: {
        limit: 10,
        matchQuery: MQ.and(
          MQ.eq("data.type", booking.data.unit),
          MQ.eq("data.entity", booking.data.entity),
          MQ.eq("data.bankAccount", booking.data.bankAccount),
          when(
            hasValue(booking.data.objectId),
            MQ.eq("data.objects.objectId", booking.data.objectId)
          )
        ),
      },
    })) as ListResponse<CBStatisticLoanAsset>;
    const mapped = response.data
      .map((loan) => this.mapSuggestionLoan(loan, booking))
      .sort((a, b) => {
        return (
          (b.matches.loanId - a.matches.loanId) * 40 +
          (b.matches.amount - a.matches.amount) * 40
        );
      });

    return mapped;
  }
  mapSuggestionLoan(loan: CBStatisticLoanAsset, booking: AssetCashBudgetEntry) {
    const releventMonths = [
      moment(booking.data.date).subtract(1, "month"),
      moment(booking.data.date),
      moment(booking.data.date).add(1, "month"),
    ];

    const relevantPlanData =
      loan?.data.values.filter((e) => {
        const month = moment(e.date);
        return releventMonths.some((monthOfBooking) =>
          monthOfBooking.isSame(month, "month")
        );
      }) || [];

    return {
      asset: loan,
      matches: {
        loanId: Math.max(
          checkString(loan?.data.loanID, booking.data.usage),
          checkString(loan?.data.loanID.replace(" ", ""), booking.data.usage)
        ),
        amount: Math.max(
          ...relevantPlanData.map((e) =>
            Math.max(
              booking.data.value > 0
                ? checkAmount(e.payout, booking.data.value, 0.1)
                : 0,
              booking.data.value < 0
                ? checkAmount(
                    e.interestAmount,
                    Math.abs(booking.data.value),
                    0.1
                  )
                : 0,
              booking.data.value < 0
                ? checkAmount(
                    e.repaymentAmount,
                    Math.abs(booking.data.value),
                    0.1
                  )
                : 0,
              booking.data.value < 0
                ? checkAmount(e.totalPayment, Math.abs(booking.data.value), 0.1)
                : 0
            )
          )
        ),
      },
    } as LoanSuggestion;
  }
  async fetchSuggestionsForRental(booking: AssetCashBudgetEntry) {
    let response = (await HTTP.post({
      url: `/asset/list/${AssetTypes.Rental.RentalAgreement}`,
      bodyParams: {
        limit: 100,
        matchQuery: MQ.and(
          MQ.eq("data.type", booking.data.unit),
          MQ.eq("data.entity", booking.data.entity),
          MQ.or(
            ...[
              "data.rentNet",
              "data.rentGross",
              "data.operatingCostNet",
              "data.operatingCostGross",
              "data.rentGrossTotal",
              "data.rentNetTotal",
            ].map((field) =>
              MQ.and(
                MQ.gte(field, Math.abs(booking.data.value) * 0.9),
                MQ.lte(field, Math.abs(booking.data.value) * 1.1)
              )
            ),
            MQ.eq("data.nested.tenantDisplayName", booking.data.recipient)
          ),
          when(
            hasValue(booking.data.objectId),
            MQ.eq("data.objectId", booking.data.objectId)
          )
        ),
      },
    })) as ListResponse<RentalAgreement>;

    if (response.data.length === 0) {
      response = (await HTTP.post({
        url: `/asset/list/${AssetTypes.Rental.RentalAgreement}`,
        bodyParams: {
          limit: 30,

          matchQuery: MQ.and(
            MQ.eq("data.type", booking.data.unit),
            MQ.eq("data.entity", booking.data.entity),
            MQ.gt("data.rentNetTotal", 0),
            when(
              hasValue(booking.data.objectId),
              MQ.eq("data.objectId", booking.data.objectId)
            )
          ),
        },
      })) as ListResponse<RentalAgreement>;
    }

    const mapped = response.data
      .map((rentalAgreement) =>
        this.mapSuggestionRental(rentalAgreement, booking)
      )
      .sort((a, b) => {
        return (
          (b.matches.contact - a.matches.contact) * 30 +
          (b.matches.rentNetTotal - a.matches.rentNetTotal) * 20 +
          (b.matches.rentGrossTotal - a.matches.rentGrossTotal) * 20 +
          (b.matches.amountRentNet - a.matches.amountRentNet) * 10 +
          (b.matches.amountRentGross - a.matches.amountRentGross) * 10 +
          (b.matches.amountOperatingCostNet -
            a.matches.amountOperatingCostNet) *
            10 +
          (b.matches.amountOperatingCostGross -
            a.matches.amountOperatingCostGross) *
            10 +
          (b.matches.object - a.matches.object) * 10
        );
      });

    return mapped;
  }
  mapSuggestionRental(
    rentalAgreement: RentalAgreement,
    booking: AssetCashBudgetEntry
  ) {
    return {
      asset: rentalAgreement,
      matches: {
        amountOperatingCostGross: checkAmount(
          rentalAgreement.data.operatingCostGross,
          booking.data.value
        ),
        amountOperatingCostNet: checkAmount(
          rentalAgreement.data.operatingCostNet,
          booking.data.value
        ),
        amountRentGross: checkAmount(
          rentalAgreement.data.rentGross,
          booking.data.value
        ),
        amountRentNet: checkAmount(
          rentalAgreement.data.rentNet,
          booking.data.value
        ),
        rentNetTotal: checkAmount(
          rentalAgreement.data.rentNetTotal,
          booking.data.value
        ),
        rentGrossTotal: checkAmount(
          rentalAgreement.data.rentGrossTotal,
          booking.data.value
        ),

        contact: checkString(
          rentalAgreement.nested?.tenantName,
          booking.data.recipient + " " + booking.data.usage
        ),
        object: checkObject(
          OrgaStruct.getObject(rentalAgreement.data.objectId)?._id,
          booking.data.objectId
        ),
      },
    } as RentalAgreementSuggestion;
  }

  async fetchSuggestionsForInvoice(booking: AssetCashBudgetEntry) {
    const data = (await HTTP.post({
      url: `/asset/list/${AssetTypes.Invoice}`,
      bodyParams: {
        limit: 10,
        matchQuery: MQ.and(
          MQ.eq("data.type", booking.data.unit),
          MQ.or(
            MQ.eq(
              "data.payment.paymentPlan.amount",
              Math.abs(booking.data.value)
            ),
            MQ.eq(
              "data.invoice.value.converted.amount",
              Math.abs(booking.data.value)
            )
            // MQ.between(
            //   "data.invoice.value.converted.amount",
            //   Math.abs(booking.data.value) - 0.99,
            //   Math.abs(booking.data.value) + 0.99
            // )
          ),
          MQ.between(
            "data.invoice.documentDate",
            moment(booking.data.date).subtract(6, "month").toISOString(),
            moment(booking.data.date).add(6, "month").toISOString()
          ),
          //   MQ.eq("data.invoice.value.converted.amount", booking.data.value),
          MQ.nin("data.status", ["init", "declined"]),
          MQ.eq("data.entity", booking.data.entity)
          //   when(
          //     booking.data.objectId,
          //     MQ.eq("data.objectId", booking.data.objectId)
          //   )
        ),
      },
    })) as ListResponse<RAInvoice>;

    const mapped = data.data
      .map((invoice) => this.mapSuggestionInvoice(invoice, booking))
      .filter((e) => e)
      .sort((a, b) => {
        return (
          (b.matches.amount - a.matches.amount) * 40 +
          (b.matches.date - a.matches.date) * 20 +
          (b.matches.invoiceId - a.matches.invoiceId) * 40
        );
      });

    return mapped;
  }
  mapSuggestionInvoice(invoice: RAInvoice, booking: AssetCashBudgetEntry) {
    let amount = null;
    let date = null;

    if (!invoice) {
      return null;
    }

    if ((invoice?.data?.payment?.paymentPlan || []).length > 0) {
      const bestMatch = invoice.data.payment.paymentPlan
        .map((payment) => {
          return {
            amount: checkAmount(payment.amount, Math.abs(booking.data.value)),
            date: checkDate(payment.date, booking.data.date),
          };
        })
        .sort((a, b) => {
          return (a.amount - b.amount) * 100 - (a.date - b.date) * 30;
        });
      amount = bestMatch[0].amount;
      date = bestMatch[0].date;
    } else {
      amount = checkAmount(
        invoice.data.invoice.value.converted.amount,
        Math.abs(booking.data.value)
      );
      date = checkDate(invoice.data.invoice.documentDate, booking.data.date);
    }

    const invoiceId = checkString(
      invoice.data.invoice.invoiceId,
      booking.data.usage
    );
    return {
      asset: invoice,
      matches: {
        amount,
        date,
        invoiceId,
      },
    } as InvoiceSuggestion;
  }

  async link(
    bookingId: string,
    assetType: string,
    assetId: string,
    extra?: any
  ) {
    try {
      const result = await HTTP.post({
        url: `/liquiplanservice/${bookingId}/linkAsset`,
        target: "EMPTY",
        bodyParams: {
          assetType,
          assetId,
          extra,
        },
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    } catch (err) {
      DataBusDefaults.toast({
        type: "error",
        text: i18n.t(
          "app:CBIdlyConnectService.link.error",
          "Fehler beim Verknüpfen der Daten"
        ),
      });
      throw err;
    }
  }
  async unlink(bookingId: string, assetType: string, assetId: string) {
    const result = await HTTP.post({
      url: `/liquiplanservice/${bookingId}/unlinkAsset`,
      target: "EMPTY",
      bodyParams: {
        assetType,
        assetId,
      },
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }

  async updateLinkExtra(
    bookingId: string,
    assetType: string,
    assetId: string,
    extra: any
  ) {
    try {
      await this.unlink(bookingId, assetType, assetId);
    } catch (err) {
      Log.error("###CBIdlyConnectService unlink failed", err);
    } finally {
      try {
        await this.link(bookingId, assetType, assetId, extra);
      } catch (err) {
        Log.error("###CBIdlyConnectService link failed", err);
      }
    }
  }
  getSuggestionHintInvoice(suggestion: InvoiceSuggestion): SuggestionHint[] {
    if (!suggestion) {
      return [];
    }
    return [
      {
        level: CBIdlyConnectService.getSuggestionHintLevel(
          suggestion.matches.invoiceId
        ),
        text: i18n.t(
          "cb:CBIdlyConnect.Invoice.hint.invoiceId",
          "Rechnungsnummer im Verwendungszweck"
        ),
      },
      {
        level: CBIdlyConnectService.getSuggestionHintLevel(
          suggestion.matches.amount
        ),
        text: i18n.t(
          "cb:CBIdlyConnect.Invoice.hint.amount",
          "Passender Rechnungsbetrag"
        ),
      },
      {
        level: CBIdlyConnectService.getSuggestionHintLevel(
          suggestion.matches.date
        ),
        text: i18n.t(
          "cb:CBIdlyConnect.Invoice.hint.date",
          "Rechnungsdatum im Zahlungszeitraum"
        ),
      },
    ];
  }
  getSuggestionHintLoan(suggestion: LoanSuggestion): SuggestionHint[] {
    return [
      {
        level: CBIdlyConnectService.getSuggestionHintLevel(
          suggestion.matches.loanId
        ),
        text: i18n.t(
          "cb:CBIdlyConnect.loan.suggestion.loanId",
          "Darlehensnummer im Verwendungszweck"
        ),
      },
      {
        level: CBIdlyConnectService.getSuggestionHintLevel(
          suggestion.matches.amount
        ),
        text: i18n.t(
          "cb:CBIdlyConnect.loan.suggestion.loanPlan",
          "Betrag passend zum Tilgungsplan"
        ),
      },
    ];
  }

  getSuggestionHintRental(
    suggestion: RentalAgreementSuggestion
  ): SuggestionHint[] {
    return [
      suggestion.matches.rentNetTotal > 0
        ? {
            level: CBIdlyConnectService.getSuggestionHintLevel(
              suggestion.matches.rentNetTotal
            ),
            text: i18n.t(
              "cb:CBIdlyConnect.rental.hint.rentNetTotal",
              "Gesamtmiete Netto passend bezahlt"
            ),
          }
        : suggestion.matches.rentGrossTotal > 0
        ? {
            level: CBIdlyConnectService.getSuggestionHintLevel(
              suggestion.matches.rentGrossTotal
            ),
            text: i18n.t(
              "cb:CBIdlyConnect.rental.hint.rentGrossTotal",
              "Gesamtmiete Brutto passend bezahlt"
            ),
          }
        : suggestion.matches.amountRentNet > 0
        ? {
            level: CBIdlyConnectService.getSuggestionHintLevel(
              suggestion.matches.amountRentNet
            ),
            text: i18n.t(
              "cb:CBIdlyConnect.rental.hint.amountRentNet",
              "Miete Netto passend bezahlt"
            ),
          }
        : suggestion.matches.amountRentGross > 0
        ? {
            level: CBIdlyConnectService.getSuggestionHintLevel(
              suggestion.matches.amountRentGross
            ),
            text: i18n.t(
              "cb:CBIdlyConnect.rental.hint.amountRentGross",
              "Miete Brutto passend bezahlt"
            ),
          }
        : suggestion.matches.amountOperatingCostNet > 0
        ? {
            level: CBIdlyConnectService.getSuggestionHintLevel(
              suggestion.matches.amountOperatingCostNet
            ),
            text: i18n.t(
              "cb:CBIdlyConnect.rental.hint.amountOperatingCostNet",
              "Nebenkosten Netto passend beahlt"
            ),
          }
        : suggestion.matches.amountOperatingCostGross > 0
        ? {
            level: CBIdlyConnectService.getSuggestionHintLevel(
              suggestion.matches.amountOperatingCostGross
            ),
            text: i18n.t(
              "cb:CBIdlyConnect.rental.hint.amountOperatingCostGross",
              "Nebenkosten Brutto passend bezahlt"
            ),
          }
        : {
            level: CBIdlyConnectService.getSuggestionHintLevel(
              suggestion.matches.amountOperatingCostNet
            ),
            text: i18n.t(
              "cb:CBIdlyConnect.rental.hint.amountSum",
              "Gesamtmiete passend bezahlt"
            ),
          },

      {
        level: CBIdlyConnectService.getSuggestionHintLevel(
          suggestion.matches.contact
        ),
        text: i18n.t(
          "cb:CBIdlyConnect.rental.hint.contact",
          "Name vom Kontakt vorhanden"
        ),
      },
      {
        level: CBIdlyConnectService.getSuggestionHintLevel(
          suggestion.matches.object
        ),
        text: i18n.t(
          "cb:CBIdlyConnect.rental.hint.object",
          "Gleichem Objekt zugewiesen"
        ),
      },
    ];
  }
}
const CBIdlyConnectService = new CBIdlyConnectServiceClass();
export default CBIdlyConnectService;
