import classNames from "classnames";
import Color from "color";
import { CSSProperties } from "react";
import { isDefined } from "../../../../utils/Helpers";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import "./BFStatus.scss";

interface BFStatusProps {
  className?: string;
  label: string;
  color?: string;
  icon?: {
    type: string;
    data: string;
  };
  showIcon?: boolean;
  size?: "default" | "sm" | "xs" | "xxs";
  style?: CSSProperties;
  onRemove?: () => void;
  onClick?: () => void;
}
const BFStatus = (props: BFStatusProps) => {
  let color;
  if (props.color) {
    color = Color(props.color);
  }
  return (
    <div
      role={props.onClick ? "button" : undefined}
      className={classNames(`bf-status`, props.className, props.size, {
        "as-button": isDefined(props.onClick),
      })}
      style={{
        backgroundColor: color?.hex(),
        color: color
          ? color?.isDark()
            ? "#fff"
            : color.darken(0.7).hex()
          : undefined,
        ...(props.style || {}),
      }}
      onClick={props.onClick}
    >
      {props.showIcon && props.icon && <BfIcon {...props.icon} size="xs" />}
      {props.label}
      {props.onRemove && (
        <button className={`remove-button`} onClick={props.onRemove}>
          <BfIcon {...DefaultIcons.REMOVE} />
        </button>
      )}
      {isDefined(props.onClick) && <div className={`hover`} />}
    </div>
  );
};

export default BFStatus;
