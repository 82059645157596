import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import ResponsiveNav from "@rsuite/responsive-nav";
import { useEffect, useRef } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import i18n from "../../i18n";
import { useTypedSelector } from "../../redux/hooks";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import "./ObjectDetailView.scss";

export interface ObjectDetailSubRoute {
  route: string;
  name: string;
  full?: boolean;
  render: (isFullView: boolean) => React.ReactNode;
}
interface Props {
  className?: string;
  main: (isFullView: boolean) => React.ReactNode;
  mainTitle?: string;
  routes: ObjectDetailSubRoute[];
  mobileBreakpoint?: number;
  mainFlex?: string;
  tabFlex?: string;
  goBack?: () => void;
  integrated?: boolean;
}
const ObjectDetailView = (props: Props) => {
  const tabContentRef = useRef(null);
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const viewportWidth = useTypedSelector(
    (state: AppState) => state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
  );

  const activeRoute = props.routes.find(
    (e) => location.pathname.indexOf(`/${e.route}`) !== -1
  );

  const isFullView =
    viewportWidth < (props.mobileBreakpoint || 1200) || activeRoute?.full;

  useEffect(() => {
    tabContentRef.current?.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div
      className={`object-detail-view ${isFullView ? "mobile" : ""} ${
        props.className || ""
      } ${props.integrated ? "integrated" : ""}`}
    >
      {!isFullView && (
        <div className="main-view" style={{ flex: props.mainFlex }}>
          {" "}
          {props.main(isFullView)}
        </div>
      )}
      <div className="tab-view" style={{ flex: props.tabFlex }}>
        <div className="outfader" />
        <div className="outfader bottom" />
        <div className="tab-nav">
          <ResponsiveNav
            moreText={i18n.t("Global.Buttons.further", "Weitere")}
            activeKey={
              props.routes.find(
                (route) => location.pathname.indexOf(`/${route.route}`) !== -1
              )?.route || ""
            }
            onSelect={(key) =>
              key
                ? key === "back"
                  ? props.goBack()
                  : history.replace(
                      `${match.url}${key !== "#root" ? "/" + key : ""}`
                    )
                : undefined
            }
            appearance="tabs"
          >
            {isFullView && (
              <>
                {props.goBack && (
                  <ResponsiveNav.Item
                    key={"back"}
                    eventKey={"back"}
                    style={{ padding: "11px 14px" }}
                  >
                    <BfIcon type="light" data="arrow-left" size="xs" />
                  </ResponsiveNav.Item>
                )}

                <ResponsiveNav.Item key={"#root"} eventKey={"#root"}>
                  {props.mainTitle || "Home"}
                </ResponsiveNav.Item>
              </>
            )}
            {props.routes.map((item) => (
              <ResponsiveNav.Item key={item.route} eventKey={item.route}>
                {item.name}
              </ResponsiveNav.Item>
            ))}
          </ResponsiveNav>
        </div>
        <div className="tab-content" ref={tabContentRef}>
          <Switch>
            <Route
              exact
              path={`${match.path}`}
              render={(innerMatch) => {
                if (!isFullView) {
                  history.replace(`${match.url}/${props.routes[0].route}`);
                }
                //todo - if not mobile, redirect to first routes entry
                return props.main(isFullView);
              }}
            />
            {props.routes.map((route) => (
              <Route
                key={route.route}
                path={`${match.path}/${route.route}`}
                render={(innerMatch) => route.render(isFullView)}
              />
            ))}
          </Switch>
        </div>
      </div>
    </div>
  );
};
export default ObjectDetailView;
