import React, { Component } from "react";
import { PlacementAll } from "../../common/Placements";
import BFWhisper from "../whisper/BFWhisper";
import BFTooltip from "./BFTooltip";

export type TooltipProps = {
  tooltip: string | React.ReactNode;
  delay?: number; //	Delay Time
  delayHide?: number; //	Hidden delay Time
  delayShow?: number; //	Show Delay Time
  placement?: PlacementAll; // PlacementAll ('right')	Dispaly placement
  trigger?: "click" | "hover" | "focus" | "active"; //union: 'click', 'hover', 'focus', 'active' (['hover','focus'])
  container?: HTMLElement | (() => HTMLElement);
  children: React.ReactElement;
};

type States = {};

class BFUseTooltip extends Component<TooltipProps, States> {
  static defaultProps = {
    delay: 1000,
    placement: "auto",
    trigger: "hover",
  };

  render() {
    const { tooltip, ...whisperProps } = this.props;
    const tooltipEl = <BFTooltip>{tooltip}</BFTooltip>;
    return (
      <BFWhisper speaker={tooltipEl} {...whisperProps}>
        {this.props.children}
      </BFWhisper>
    );
  }
}

export default BFUseTooltip;
