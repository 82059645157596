import md5 from "md5";
import { isDefined } from "../../../utils/Helpers";

export const getQueryId = (
  name: string,
  version: number | null,
  variables: any,
  referenceId?: string,
  textQuery?: string
) => {
  const identifier = `${name}#${
    isDefined(version) ? version : "null"
  }#${JSON.stringify(variables || null)}#${referenceId || ""}#${
    textQuery || ""
  }`;

  return md5(identifier);
};
