import _ from "lodash";
import ManagedVirtualizedTable from "../../../../../../../configurable/data/VirtualizedTable/ManagedVirtualizedTable";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFStatus from "../../../../../../../modules/abstract-ui/data/status/BFStatus";
import {
  LinkCell,
  renderCellValue,
} from "../../../../../../../modules/abstract-ui/data/table/TableUtils";
import { getDefaultCurrencyNumberFormat } from "../../../../../../../modules/export/export.model";
import { useHttpCache } from "../../../../../../../redux/hooks";
import StringUtils from "../../../../../../../utils/StringUtils";
import { DEFAULT_RENTAL_UNITS_OVERVIEW_IDENTIFIER } from "../../../portfolio/CBPortfolioConst";
import { getConfigRentalStatus } from "../../../tenants/CBTenantsConst";
import {
  EnrichtedRentalUnit,
  EnrichtedRentalUnitKPISGathered,
  convertEnrichtedRentalUnitToEnrichtedRentalUnitKPISGathered,
} from "../../../tenants/TenantsInterfaces";
import { RENTAL_UNIT_FIELDS } from "../../../tenants/components/rental-unit/CBRentalUnitList";
import "./CBRentalUnitsOverviewTable.scss";

interface CBRentalUnitsOverviewTablePropsSingle {
  objectId: string;
  tableIdentifier?: string;
}
interface CBRentalUnitsOverviewTablePropsMultiple {
  objectIds: string[];
  tableIdentifier?: string;
}
type CBRentalUnitsOverviewTableProps =
  | CBRentalUnitsOverviewTablePropsMultiple
  | CBRentalUnitsOverviewTablePropsSingle;
const CBRentalUnitsOverviewTable = (props: CBRentalUnitsOverviewTableProps) => {
  const data = useHttpCache<EnrichtedRentalUnit[]>(
    `object-stacking-plan-${
      (props as CBRentalUnitsOverviewTablePropsSingle).objectId
        ? (props as CBRentalUnitsOverviewTablePropsSingle).objectId
        : (props as CBRentalUnitsOverviewTablePropsMultiple).objectIds.join(",")
    }`,
    `/rental/getStackingPlan`,
    "post",
    null,
    {
      objectIds: (props as CBRentalUnitsOverviewTablePropsSingle).objectId
        ? [(props as CBRentalUnitsOverviewTablePropsSingle).objectId]
        : (props as CBRentalUnitsOverviewTablePropsMultiple).objectIds,
    }
  );

  const convData = data.data
    ? convertEnrichtedRentalUnitToEnrichtedRentalUnitKPISGathered(data.data)
    : [];
  return (
    <ManagedVirtualizedTable
      data={convData}
      className={`cb-rental-units-overview-table`}
      loading={data?.state === "loading" ? "general" : undefined}
      identifier={
        props.tableIdentifier || DEFAULT_RENTAL_UNITS_OVERVIEW_IDENTIFIER
      }
      initialVisibleSort={{
        key: "data.id",
        dir: "asc",
      }}
      params={{
        aggregated: {
          rentPerAreaCurrentAVG:
            _.sumBy(
              convData.filter((e) => e.data.area > 0),
              (item) => item.kpis.rentNetCurrent
            ) / _.sumBy(convData, (item) => item.data.area),
          rentPerQuantityCurrentAVG:
            _.sumBy(
              convData.filter((e) => e.data.quantity > 0),
              (item) => item.kpis.rentNetCurrent
            ) / _.sumBy(convData, (item) => item.data.quantity),
          rentPerAreaPlanAVG:
            _.sumBy(
              convData.filter((e) => e.data.area > 0),
              (item) => item.data.rentNet
            ) / _.sumBy(convData, (item) => item.data.area),
          rentPerQuantityPlanAVG:
            _.sumBy(
              convData.filter((e) => e.data.quantity > 0),
              (item) => item.data.rentNet
            ) / _.sumBy(convData, (item) => item.data.quantity),

          rentGrossDeficit: _.sumBy(
            convData,
            (item) => item.kpis.rentGrossDeficitCurrent
          ),
          area: _.sumBy(convData, (item) => item.data.area),
          rentNetPerAreaPlan:
            _.sumBy(
              convData.filter((e) => e.data.area > 0),
              (item) => item.data.rentNet
            ) / _.sumBy(convData, (item) => item.data.area),
          rentNetPerAreaCurrent:
            _.sumBy(
              convData.filter((e) => e.data.area > 0),
              (item) => item.kpis.rentNetCurrent
            ) / _.sumBy(convData, (item) => item.data.area),

          rentNetPerQuantityPlan:
            _.sumBy(
              convData.filter((e) => e.data.quantity > 0),
              (item) => item.data.rentNet
            ) / _.sumBy(convData, (item) => item.data.quantity),
          rentNetPerQuantityCurrent:
            _.sumBy(
              convData.filter((e) => e.data.quantity > 0),
              (item) => item.kpis.rentNetCurrent
            ) / _.sumBy(convData, (item) => item.data.quantity),

          quantity: _.sumBy(convData, (item) => item.data.quantity),
          rentNet: _.sumBy(convData, (item) => item.data.rentNet),
          rentGross: _.sumBy(convData, (item) => item.data.rentGross),
          rentNetCurrent: _.sumBy(convData, (item) => item.kpis.rentNetCurrent),
          rentNetDeficitCurrent: _.sumBy(
            convData,
            (item) => item.kpis.rentNetDeficitCurrent
          ),
          rentGrossCurrent: _.sumBy(
            convData,
            (item) => item.kpis.rentGrossCurrent
          ),
        },
      }}
      columns={STACKINGPLAN_INFODATA()}
    />
  );
};

export default CBRentalUnitsOverviewTable;

const STACKINGPLAN_INFODATA = () =>
  _.merge({}, RENTAL_UNIT_FIELDS(), {
    "data.displayName": {
      render: (node: EnrichtedRentalUnitKPISGathered, index, params) => (
        <LinkCell
          assetType={AssetTypes.Rental.RentalUnit}
          id={node._id}
          text={renderCellValue(node?.data?.displayName, "-")}
        />
      ),
    },
    "data.area": {
      renderFooter: (params) => {
        return renderCellValue(
          params?.aggregated?.area,
          "-",
          (value: number) => StringUtils.formatArea(value),
          false
        );
      },
    },
    "data.quantity": {
      renderFooter: (params) => {
        return renderCellValue(params?.aggregated?.quantity, "-");
      },
    },

    "data.rentNet": {
      label: i18n.t("cb:RentalUnit.rentNetPlan", "NKM SOLL"),
      renderFooter: (params) => {
        return renderCellValue(
          params?.aggregated?.rentNet,
          "-",
          (value: number) => StringUtils.formatCurrency(value),
          false
        );
      },
      export: {
        label: i18n.t("cb:RentalUnit.rentNetPlan", "NKM SOLL"),
      },
    },
    "data.rentGross": {
      label: i18n.t("cb:RentalUnit.rentGrossPlan", "Bruttomiete SOLL"),
      renderFooter: (params) => {
        return renderCellValue(
          params?.aggregated?.rentGross,
          "-",
          (value: number) => StringUtils.formatCurrency(value),
          false
        );
      },
      export: {
        label: i18n.t("cb:RentalUnit.rentGrossPlan", "Bruttomiete SOLL"),
      },
    },

    "kpis.rentNetCurrent": {
      label: i18n.t("cb:RentalUnit.rentNetCurrent", "NKM IST"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      alignment: "right",
      render: (node: EnrichtedRentalUnitKPISGathered, index, params) =>
        renderCellValue(node?.kpis.rentNetCurrent, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      defaultOrderIndex: 8.3,
      renderFooter: (params) => {
        return renderCellValue(
          params?.aggregated?.rentNetCurrent,
          "-",
          (value: number) => StringUtils.formatCurrency(value),
          false
        );
      },
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.rentNetCurrent", "NKM IST"),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: EnrichtedRentalUnitKPISGathered) => {
          return node?.kpis?.rentNetCurrent;
        },
      },
    },
    "kpis.rentPerCurrent": {
      label: i18n.t("cb:RentalUnit.rentPerCurrent", "IST pro (m²|Stk)"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      alignment: "right",
      render: (node: EnrichtedRentalUnitKPISGathered, index, params) =>
        renderCellValue(node?.kpis.rentPerCurrent, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      defaultOrderIndex: 8.4,
      renderFooter: (params) => {
        return (
          <div className={`footer-averages`}>
            <div className={`entry`}>
              <span className={`label`}>
                {i18n.t("cb:RentalUnit.perQM", "Ø pro m²")}
              </span>
              <span className={`value`}>
                {renderCellValue(
                  params?.aggregated?.rentPerAreaCurrentAVG,
                  "-",
                  (value: number) => StringUtils.formatCurrency(value),
                  false
                )}
              </span>
            </div>
            <div className={`entry`}>
              <span className={`label`}>
                {i18n.t("cb:RentalUnit.perQuantity", "Ø pro Stk")}
              </span>
              <span className={`value`}>
                {renderCellValue(
                  params?.aggregated?.rentPerQuantityCurrentAVG,
                  "-",
                  (value: number) => StringUtils.formatCurrency(value),
                  false
                )}
              </span>
            </div>
          </div>
        );
      },
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.rentPerCurrent", "IST pro (m²|Stk)"),
        type: "number",
        totalFunction: "average",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: EnrichtedRentalUnitKPISGathered) => {
          return node?.kpis.rentPerCurrent;
        },
      },
    },

    "kpis.rentPerPlan": {
      label: i18n.t("cb:RentalUnit.rentPerPlan", "SOLL pro (m²|Stk)"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      alignment: "right",
      render: (node: EnrichtedRentalUnitKPISGathered, index, params) =>
        renderCellValue(node?.kpis.rentPerPlan, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      defaultOrderIndex: 9.5,
      renderFooter: (params) => {
        return (
          <div className={`footer-averages`}>
            <div className={`entry`}>
              <span className={`label`}>
                {i18n.t("cb:RentalUnit.perQM", "Ø pro m²")}
              </span>
              <span className={`value`}>
                {renderCellValue(
                  params?.aggregated?.rentPerAreaPlanAVG,
                  "-",
                  (value: number) => StringUtils.formatCurrency(value),
                  false
                )}
              </span>
            </div>
            <div className={`entry`}>
              <span className={`label`}>
                {i18n.t("cb:RentalUnit.perQuantity", "Ø pro Stk")}
              </span>
              <span className={`value`}>
                {renderCellValue(
                  params?.aggregated?.rentPerQuantityPlanAVG,
                  "-",
                  (value: number) => StringUtils.formatCurrency(value),
                  false
                )}
              </span>
            </div>
          </div>
        );
      },
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.rentPerPlan", "SOLL pro (m²|Stk)"),
        type: "number",
        totalFunction: "average",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: EnrichtedRentalUnitKPISGathered) => {
          return node?.kpis.rentPerPlan;
        },
      },
    },

    "kpis.rentNetDeficitCurrent": {
      label: i18n.t("cb:RentalUnit.rentNetDeficitCurrent", "NKM Differenz"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      alignment: "right",
      render: (node: EnrichtedRentalUnitKPISGathered, index, params) =>
        renderCellValue(
          node?.kpis.rentNetDeficitCurrent,
          "-",
          (value: number) => StringUtils.formatCurrency(value),
          false,
          node?.kpis.rentNetDeficitCurrent > 0 ? "green" : "red"
        ),
      defaultOrderIndex: 9.6,
      renderFooter: (params) => {
        return renderCellValue(
          params?.aggregated?.rentNetDeficitCurrent,
          "-",
          (value: number) => StringUtils.formatCurrency(value),
          false,
          params?.aggregated?.rentNetDeficitCurrent > 0 ? "green" : "red"
        );
      },
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.rentNetDeficitCurrent", "NKM Differenz"),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: EnrichtedRentalUnitKPISGathered) => {
          return node?.kpis.rentNetDeficitCurrent;
        },
      },
    },

    "kpis.rentGrossCurrent": {
      label: i18n.t("cb:RentalUnit.rentGrossCurrent", "Bruttomiete IST"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      alignment: "right",
      render: (node: EnrichtedRentalUnitKPISGathered, index, params) =>
        renderCellValue(node?.kpis.rentGrossCurrent, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      defaultOrderIndex: 10.1,
      renderFooter: (params) => {
        return renderCellValue(
          params?.aggregated?.rentGrossCurrent,
          "-",
          (value: number) => StringUtils.formatCurrency(value),
          false
        );
      },
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.rentGrossCurrent", "Bruttomiete IST"),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: EnrichtedRentalUnitKPISGathered) => {
          return node?.kpis.rentGrossCurrent;
        },
      },
    },
    "kpis.rentGrossDeficitCurrent": {
      label: i18n.t(
        "cb:RentalUnit.rentGrossDeficitCurrent",
        "Bruttomiete Differenz"
      ),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      alignment: "right",
      render: (node: EnrichtedRentalUnitKPISGathered, index, params) =>
        renderCellValue(
          node?.kpis.rentGrossDeficitCurrent,
          "-",
          (value: number) => StringUtils.formatCurrency(value),
          false,
          node?.kpis.rentGrossDeficitCurrent > 0 ? "green" : "red"
        ),
      defaultOrderIndex: 10.2,
      renderFooter: (params) => {
        return renderCellValue(
          params?.aggregated?.rentGrossDeficit,
          "-",
          (value: number) => StringUtils.formatCurrency(value),
          false,
          params?.aggregated?.rentGrossDeficit > 0 ? "green" : "red"
        );
      },
      export: {
        width: 20,
        label: i18n.t(
          "cb:RentalUnit.rentGrossDeficitCurrent",
          "Bruttomiete Differenz"
        ),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: EnrichtedRentalUnitKPISGathered) => {
          return node?.kpis.rentGrossDeficitCurrent;
        },
      },
    },

    "data.building": { hidden: true },
    "data.rentalStatus": {
      label: i18n.t("cb:RentalUnit.rentalStatus", "Status"),
      // sortKey: "",
      flexWidth: 240,
      sortable: true,
      resizable: true,
      render: (node: EnrichtedRentalUnitKPISGathered, index, params) => {
        const status = getConfigRentalStatus(node.data.rentalStatus);
        if (status) {
          return (
            <div className={`status-cell`}>
              <BFStatus size="sm" color={status.color} label={status.label} />
              <LinkCell
                assetType={
                  node.data.rentalStatus === "vacant"
                    ? AssetTypes.Rental.RentalUnit
                    : AssetTypes.Rental.RentalAgreement
                }
                id={
                  node.data.rentalStatus === "vacant"
                    ? node._id
                    : node.data?.agreement?._id
                }
                text={
                  node.data.rentalStatus === "vacant"
                    ? ``
                    : `${node.data.agreement?.data.id} - ${node.data.agreement?.data.displayName}`
                }
              />
            </div>
          );
        }
        return null;
      },

      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.rentalStatus", "Status"),
        type: "string",
        selector: (node: EnrichtedRentalUnitKPISGathered) => {
          return getConfigRentalStatus(node.data.rentalStatus)?.label;
        },
      },
    },
  });
