import { useCallback, useState } from "react";
import { BFBreadcrumbEntry } from "../abstract-ui/navigation/BFBreadcrumb/BFBreadcrumb";
import { DocumentStoreDirectory, DocumentStoreProps } from "./DSInterfaces";
import DSService from "./DSService";
import DSRootView from "./components/DSRootView/DSRootView";
import { useDocumentStorePath } from "./hooks/useDocumentStorePath";

const DocumentStoreManaged = (props: DocumentStoreProps) => {
  const rootDirectory = DSService.getDirectoryConfigurationForAsset(
    props.assetParams.type,
    props.assetParams.assetType,
    props.assetParams.documentsFieldPath,
    true
  );
  const [pathEntries, setPathEntries] = useState<string[]>([
    rootDirectory.pathIdentifier,
  ]);

  const { directoryPathObjects, currentDirectory, unvalidPath } =
    useDocumentStorePath({
      pathEntries: pathEntries,
      rootDocumentDirectory: rootDirectory,
    });

  const onClickBreadcrumb = useCallback(
    (breadcrumb: BFBreadcrumbEntry) => {
      // walk directory array to this id and map into path array
      // Just in case there are 2 directories with the same path
      const directoryIndex = directoryPathObjects.findIndex(
        (directory) => directory.id === breadcrumb.id
      );
      const leftOverDirectories = directoryPathObjects.slice(
        0,
        directoryIndex + 1
      );
      const newPathEntries = leftOverDirectories.map(
        (directory) => directory.pathIdentifier
      );
      setPathEntries(newPathEntries);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathEntries.length]
  );

  const onClickFolderEntry = useCallback(
    (directory: DocumentStoreDirectory) => {
      setPathEntries((prev) => [...prev, directory.pathIdentifier]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathEntries.length]
  );

  return (
    <DSRootView
      {...props}
      rootDocumentDirectory={rootDirectory}
      onClickBreadcrumb={onClickBreadcrumb}
      onClickFolderEntry={onClickFolderEntry}
      directoryPathObjects={directoryPathObjects}
      currentDirectory={currentDirectory}
      unvalidPath={unvalidPath}
      onClickBack={() => {
        setPathEntries((prev) => prev.slice(0, prev.length - 1));
      }}
      documentEntryActions={props.documentEntryActions}
    />
  );
};

export default DocumentStoreManaged;
