import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import { Field } from "react-final-form";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../i18n";
import {
  APContractActivity,
  APMaintenanceAppointment,
} from "./APActivityMainteanceInterfaces";
import APActivityMaintenanceService from "./APActivityMaintenanceService";
// import "./APActivityMaintenanceSetAppointmentForm.scss";

interface APActivityMaintenanceSetAppointmentFormProps {
  activity: APContractActivity;
  appointment: APMaintenanceAppointment;
  onClose: () => void;
}
const APActivityMaintenanceSetAppointmentForm = (
  props: APActivityMaintenanceSetAppointmentFormProps
) => {
  return (
    <FormStruct
      title={i18n.t(
        "apTemplate:Activity.subtitles.maintenance.setAppointmentForm.title",
        "Termin setzen"
      )}
      description={i18n.t(
        "apTemplate:Activity.subtitles.maintenance.setAppointmentForm.description",
        "Geben Sie an, welcher Termin für diese Fälligkeit definiert werden soll."
      )}
      onSubmit={async (values) => {
        await APActivityMaintenanceService.setAppointmentDate(
          props.activity,
          props.appointment,
          values.appointmentDate
        );
        props.onClose();
      }}
      submitText={i18n.t(
        "apTemplate:Activity.subtitles.maintenance.setAppointmentForm.submitText",
        "Termin speichern"
      )}
      onAbort={props.onClose}
      initialValues={{
        appointmentDate:
          props.appointment.appointmentDate || props.appointment.dueDate,
      }}
      render={(formProps) => (
        <>
          <Field name={"appointmentDate"}>
            {({ input, meta }) => (
              <BFDatefield {...input} {...FormValidators.getValidation(meta)} />
            )}
          </Field>
        </>
      )}
    />
  );
};

export default APActivityMaintenanceSetAppointmentForm;
