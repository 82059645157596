import ModalManager from "../../components/ModalComponent/ModalManager";
import i18n from "../../i18n";
import { Contact } from "../../model/db/Contact";
import BFButton from "../abstract-ui/general/Button/BFButton";
import ContactForm from "./ContactForm";
import ContactView from "./ContactView";

interface ContactViewWithEditProps {
  contact: Contact;
  onClose?: () => void;
}
const ContactViewWithEdit = (props: ContactViewWithEditProps) => {
  return (
    <ContactView
      contact={props.contact}
      actions={
        <div className={`actions`}>
          <BFButton
            noPadding
            appearance="link"
            size="xs"
            onClick={() => {
              props.onClose?.();
              ModalManager.show({
                noPadding: true,
                backdrop: "static",
                size: "md",
                content: (states, setStates, closeModal) => (
                  // show edit button in view form when showActions = true
                  <ContactForm
                    contact={props.contact}
                    onClose={closeModal}
                    businessUnits={[props.contact.data.type]}
                  />
                ),
              });
            }}
          >
            {i18n.t("Contact.View.Edit", "Bearbeiten")}
          </BFButton>
        </div>
      }
    />
  );
};

export default ContactViewWithEdit;
