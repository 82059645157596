import DataBus from "@/services/DataBus";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";
import "./BFOverlay.scss";
import BFWhisper, { BFWhisperProps } from "./BFWhisper";
type Props = Partial<BFWhisperProps> & {
  identifier?: string;
  zIndex?: number;
  marginTop?: number;
  marginLeft?: number;
  className?: string;
  overflowAllow?: boolean;

  speakerFC?: (close: () => void) => JSX.Element;
};

const BFOverlay = (props: Props) => {
  const [identifier, setIdentifier] = useState(props.identifier || nanoid());
  const [fixPosition, setFixPosition] = useState({ x: 0, y: 0 });
  const overlayRef = useRef<HTMLDivElement>(null);

  const checkOverlayBounds = () => {
    if (overlayRef.current) {
      const bounds = overlayRef.current.getBoundingClientRect();
      // fix bounds if they are out of the screen
      if (bounds.left < 0) {
        overlayRef.current.style.marginLeft = `${bounds.left * -1}px`;
      }
      if (bounds.left > window.innerWidth - bounds.width) {
        overlayRef.current.style.marginLeft = `${
          window.innerWidth - bounds.width - bounds.left
        }px`;
      }
      if (bounds.top < 0) {
        overlayRef.current.style.marginTop = `${bounds.top * -1}px`;
      }
      if (bounds.top > window.innerHeight - bounds.height) {
        overlayRef.current.style.marginTop = `${
          window.innerHeight - bounds.height - bounds.top
        }px`;
      }
    }
  };

  return (
    <BFWhisper
      delay={10}
      {...props}
      identifier={identifier}
      onExiting={() => {
        overlayRef.current = null;
        props.onExiting?.();
      }}
      onEntering={
        ((target) => {
          overlayRef.current = target;
          props.onEntering?.();
        }) as any
      }
      speaker={
        <div
          className={props.className}
          style={{
            position: "absolute",
            zIndex: props.zIndex || 9999,
            marginTop: props.marginTop,
            marginLeft: props.marginLeft,
          }}
        >
          <OverlaySpeaker
            {...props}
            identifier={identifier}
            onResize={() => checkOverlayBounds()}
          />
        </div>
      }
    />
  );
};
export default BFOverlay;

const OverlaySpeaker = (props: Props & { onResize: () => void }) => {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  useEffect(() => {
    props.onResize();
    setTimeout(() => {
      props.onResize();
    });
  }, [width, height]);

  const close = () => {
    DataBus.emit("WHISPER", {
      identifier: props.identifier,
      type: "CLOSE",
    });
  };
  return (
    <div
      ref={ref}
      className="bf-overlay-container"
      style={{ overflow: props.overflowAllow ? "visible" : undefined }}
    >
      {props.speakerFC ? props.speakerFC(close) : props.speaker}
    </div>
  );
};
