import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../../../../components/ModalComponent/ModalManager";
import DebugDataComponent from "../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import BaseAsset from "../../../../../../model/general-assets/BaseAsset";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import { DocumentStoreDocument } from "../../../../../../modules/document-store/DSInterfaces";
import DSDocumentList from "../../../../../../modules/document-store/components/DSDocumentList/DSDocumentList";
import ObjectTools from "../../../../../../modules/generic-forms/util/ObjectTools";
import MQ from "../../../../../../utils/MatchQueryUtils";
import { AssetCashBudgetEntry } from "../../../model/CashBudgetEntry";
import CBIdlyConnect from "./CBIdlyConnect";
import "./CBIdlyConnect.scss";
import "./CBIdlyConnectDocument.scss";
import CBIdlyConnectService, {
  DocumentSuggestion,
} from "./CBIdlyConnectService";
import CBIdlyDocumentChooser from "./CBIdlyDocumentChooser";

interface CBIdlyConnectDocumentProps {
  booking: AssetCashBudgetEntry;
  initialOpen?: boolean;

  suggestionPromise: (booking: AssetCashBudgetEntry) => Promise<any[]>;
  assetType: string;
  documentsFieldPath: string;
  renderSuggestionData: (
    suggestion: DocumentSuggestion<BaseAsset>
  ) => React.ReactNode;
}
const CBIdlyConnectDocument = (props: CBIdlyConnectDocumentProps) => {
  return (
    <CBIdlyConnect
      assetType={AssetTypes.Portfolio.Object}
      mapSuggestion={CBIdlyConnectService.mapSuggestionDocument}
      matchQuery={MQ.and(MQ.eq("data.entity", props.booking.data.entity))}
      className="cb-idly-connect-document"
      title={i18n.t("cb:CBIdlyConnect.document.title", "Dokument verknüpfen")}
      booking={props.booking}
      initialOpen={props.initialOpen}
      connectActionLabel={i18n.t(
        "cb:CBIdlyConnect.document.connect",
        "Dokument verknüpfen"
      )}
      isConnected={(suggestion: DocumentSuggestion<BaseAsset>) =>
        props.booking.data.linkedAsset?.some(
          (e) => e.assetId === suggestion.asset._id
        )
      }
      disconnectActionLabel={i18n.t(
        "cb:CBIdlyConnect.document.disconnect",
        "Verknüpfung aufheben"
      )}
      disconnectActionPromise={async (
        suggestion: DocumentSuggestion<BaseAsset>
      ) =>
        await CBIdlyConnectService.unlink(
          props.booking._id,
          props.assetType,
          suggestion.asset._id
        )
      }
      connectActionPromise={async (suggestion: DocumentSuggestion<BaseAsset>) =>
        await CBIdlyConnectService.link(
          props.booking._id,
          props.assetType,
          suggestion.asset._id,
          suggestion.documents
        )
      }
      renderSuggestion={(
        suggestion: DocumentSuggestion<BaseAsset>,
        updateSuggestion: (suggestion: DocumentSuggestion<BaseAsset>) => void
      ) => {
        return (
          <div className={`document-data`}>
            <DebugDataComponent data={suggestion} />
            <div className={`asset-data`}>
              {props.renderSuggestionData(suggestion)}
            </div>
            <div className={`selected-documents`}>
              {suggestion.documents.length === 0 && (
                <div className={`no-documents-yet`}>
                  {i18n.t(
                    "cb:CBIdlyConnect.document.no-documents-yet",
                    "Noch keine Dokumente ausgewählt. Klicken Sie auf 'Dokument hinzufügen', um ein Dokument aus."
                  )}
                </div>
              )}

              {suggestion.documents.length > 0 && (
                <DSDocumentList
                  renderPostfixElement={(document) => (
                    <div className={`remove-document-container`}>
                      <BFButton
                        onClick={() => {
                          updateSuggestion({
                            ...suggestion,
                            documents: suggestion.documents.filter(
                              (doc) => doc.cdnId !== document.linkToCdn
                            ),
                          });
                        }}
                        appearance="link"
                        size="xs"
                      >
                        {i18n.t(
                          "cb:CBIdlyConnect.document.remove",
                          "Entfernen"
                        )}
                      </BFButton>
                    </div>
                  )}
                  documents={suggestion.documents
                    .map((doc) => {
                      const { cdnId, contentType, documentsPath, filename } =
                        doc;

                      const documentsEntries = ObjectTools.select(
                        documentsPath,
                        suggestion.asset
                      ) as DocumentStoreDocument[];

                      const item = documentsEntries?.find(
                        (e) => e.linkToCdn === cdnId
                      );

                      return item;
                    })
                    .filter((e) => e)}
                  assetParams={{
                    asset: suggestion.asset,
                    assetType: props.assetType,
                    type: suggestion.asset?.["data"]?.["type"],
                    documentsFieldPath: props.documentsFieldPath,
                  }}
                  hideOrphanedIndicator
                  hideUploadData
                />
              )}
            </div>
            <div className={`actions`}>
              <BFButton
                appearance="link"
                onClick={() => {
                  ModalManager.show({
                    size: "lg",
                    backdrop: true,
                    headerCloseButton: true,
                    title: i18n.t(
                      "cb:CBIdlyConnect.document.ConnectModalTitle",
                      "Dokument hinzufügen"
                    ),
                    content: (state, setState, close) => (
                      <AssetLoader
                        assetType={props.assetType}
                        id={suggestion.asset._id}
                        render={(asset) => (
                          <CBIdlyDocumentChooser
                            unit={props.booking.data.unit}
                            asset={asset}
                            addedDocuments={suggestion.documents}
                            assetType={props.assetType}
                            documentsFieldPath={props.documentsFieldPath}
                            onClose={close}
                            onSelect={(document) => {
                              updateSuggestion({
                                ...suggestion,
                                documents: [
                                  ...(suggestion.documents || []),
                                  {
                                    cdnId: document.linkToCdn,
                                    contentType: suggestion.asset.cdn?.find(
                                      (e) => e._id === document.linkToCdn
                                    )?.content_type,
                                    documentsPath: props.documentsFieldPath,
                                    filename: document.name,
                                  },
                                ],
                              });
                            }}
                          />
                        )}
                      />
                    ),
                  });
                }}
              >
                {i18n.t(
                  "cb:CBIdlyConnect.document.manual",
                  "Dokument hinzufügen"
                )}
              </BFButton>
            </div>
          </div>
        );
      }}
      connectionActionDisabledState={(
        suggestion: DocumentSuggestion<BaseAsset>
      ) => suggestion.documents.length === 0}
      renderSuggestionHint={(suggestion: DocumentSuggestion<BaseAsset>) => {
        return [];
      }}
      ruleActionData={(suggestion: DocumentSuggestion<BaseAsset>) => ({
        assetDescription: `Document`,
        assetName: i18n.t("cb:CBIdlyConnect.document.AssetName", "Dokument"),
        assetType: props.assetType,
        extra: suggestion.documents,
      })}
      suggestionPromise={props.suggestionPromise}
    />
  );
};

export default CBIdlyConnectDocument;
