import { CSSProperties } from "react";
import { AbstractComponent } from "../../../utils/abstracts/AbstractComponent";
import { IComponent } from "../IComponent";
import "./GridLayout.scss";

export interface GridLayoutConf {
  gridTemplateColumns: string;
  gridTemplateRows: string;
  gridColumnGap?: string;
  gridRowGap?: string;

  height?: number | string;
  width?: number | string;
  minHeight?: number | string;
  minWidth?: number | string;

  justifyItems?: string;
  alignItems?: string;

  padding?: string;
  margin?: string;
  className?: string;

  breakLayoutOn?: "xs" | "sm" | "md";
  components: { [key: string]: GridLayoutChildConf };
}

export interface GridLayoutChildConf {
  orderIndex: number;
  col: number;
  row: number;
  colSpan: number;
  rowSpan: number;
  minHeight?: string;
  component: IComponent;
  className?: string;
}

type States = {};

class GridLayout extends AbstractComponent<GridLayoutConf, States> {
  render() {
    const {
      className,
      components,
      breakLayoutOn,

      ...styles
    } = this.props;

    return (
      <div
        className={`grid-layout ${className ? className : ""} ${
          breakLayoutOn ? "grid-layout-break-on-" + breakLayoutOn : ""
        }`}
        style={styles}
      >
        {Object.entries(components)
          .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
          .map(([key, item]) => {
            return this.renderGridLayoutEntry(item, key);
          })}
      </div>
    );
  }

  renderGridLayoutEntry(entryConf: GridLayoutChildConf, key) {
    if (
      entryConf.component &&
      (window as any).ComponentsMapper[entryConf.component._component]
    ) {
      const styles: CSSProperties = {
        gridColumn: `${entryConf.col} / ${entryConf.col + entryConf.colSpan}`,
        gridRow: `${entryConf.row} / ${entryConf.row + entryConf.rowSpan}`,
        minHeight: entryConf.minHeight,
      };

      return (
        <div
          className={`grid-layout-item ${
            entryConf.className ? entryConf.className : ""
          }`}
          key={key}
          data-key={key}
          style={styles}
        >
          {(window as any).ComponentsMapper.createElement(
            entryConf.component,
            this.props.params
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default GridLayout;
