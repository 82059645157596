import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swiper from "swiper";
import i18n from "../../i18n";
import { openGallery } from "../../redux/actions/ui-config/ui-config-actions";
import CDNImage, { CDNImageProps } from "../CDNImage/CDNImage";
import SwiperComponent from "../Swiper/SwiperComponent";
import SwiperPage from "../Swiper/SwiperPage";
import "./CDNImageGallery.scss";
export type CDNImagePropsWithContent = CDNImageProps & {
  renderContent?: React.ReactNode;
};
interface Props {
  shadow?: boolean;
  cdnImages: CDNImagePropsWithContent[];

  autoplay?: boolean;
  usePagination?: boolean;
  useNavigationButtons?: boolean;
  useMousewheel?: boolean;
  id: string;
  dimension?: "thumb" | "sl" | "lg" | "md" | "sm" | "xs";
  openable?: boolean;
  imgType?: "img" | "div";
  zoom?: boolean;
  withoutContainer?: boolean;

  useFreeMode?: boolean;
  useThumbs?: boolean;
  thumbsSwiper?: Swiper;
  spaceBetween?: number;
  onSwiper?: (swiper: Swiper) => void;
  swiperWidth?: number | string;
  ignoreContent?: boolean;
  watchSlidesProgress?: boolean;
  useKeyboard?: boolean;

  slidesPerView?: number;
  loadTreshold?: number;
  initialIndex?: number;

  emptyPage?: React.ReactNode;

  additionalPages?: React.ReactNode[];
}

const pushToArray = (array: number[], numberToPush: number) =>
  !array.find((e) => e === numberToPush) && array.push(numberToPush);

const CDNImageGallery = (props: Props) => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState<number[]>([]);

  useEffect(() => {
    const newLoadedImages = [...loadedImages];
    new Array(props.loadTreshold || 1).fill(0).forEach((_, index) => {
      pushToArray(newLoadedImages, activeIndex - (index + 1));
      pushToArray(newLoadedImages, activeIndex + (index + 1));
    });
    pushToArray(newLoadedImages, activeIndex);
    setLoadedImages(newLoadedImages);
  }, [activeIndex]);
  return (
    <div
      className={classNames(`cdn-image-gallery`, {
        "has-shadow": props.shadow,
      })}
    >
      <SwiperComponent
        style={{ height: "100%" }}
        id={`${props.id}-cdn-image-gallery-swiper`}
        usePagination={props.usePagination}
        useNavigationButtons={props.useNavigationButtons}
        useMousewheel={props.useMousewheel}
        onSlideChange={(index) => setActiveIndex(index)}
        useZoom={props.zoom}
        useFreeMode={props.useFreeMode}
        useThumbs={props.useThumbs}
        thumbsSwiper={props.thumbsSwiper}
        spaceBetween={props.spaceBetween || 0}
        watchSlidesProgress={props.watchSlidesProgress}
        onSwiper={props.onSwiper}
        useKeyboard={props.useKeyboard}
        slidesPerView={props.slidesPerView || 1}
        initialIndex={props.initialIndex}
      >
        {props.cdnImages.length + (props.additionalPages?.length || 0) ===
          0 && (
          <div className="empty-page">
            {props.emptyPage
              ? props.emptyPage
              : i18n.t("CDNImageGallery.NoImages", "Keine Bilder vorhanden")}
          </div>
        )}
        {props.additionalPages?.map((page, index) => (
          <SwiperPage
            key={index}
            zoom={props.zoom}
            swiperWidth={props.swiperWidth}
          >
            {page}
          </SwiperPage>
        ))}
        {props.cdnImages?.map((page, index) => (
          <SwiperPage
            key={page.cdnId}
            zoom={props.zoom}
            swiperWidth={props.swiperWidth}
          >
            {/* <div style={{ height: "100%", width: "100%" }}> */}
            {loadedImages.indexOf(index) === -1 ? (
              <div></div>
            ) : (
              <CDNImage
                {...page}
                shadow={props.shadow}
                imageType={props.imgType || "div"}
                dimension={props.dimension}
                withoutContainer={props.withoutContainer}
                ignoreContent={props.ignoreContent}
                onClick={
                  props.openable
                    ? (ev) => {
                        const target = ev.target as HTMLElement;
                        if (
                          !target.classList.contains("swiper-button-next") &&
                          !target.classList.contains("swiper-button-prev") &&
                          !target.classList.contains("swiper-pagination-bullet")
                        ) {
                          dispatch(
                            openGallery({
                              id: props.id,
                              images: props.cdnImages,
                              initialActiveIndex: index,
                            })
                          );
                        }
                      }
                    : undefined
                }
              />
            )}
            {/* </div> */}
          </SwiperPage>
        ))}
      </SwiperComponent>
    </div>
  );
};
export default CDNImageGallery;
