import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import Log from "../../../debug/Log";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import { requestListData } from "../../../services/DataService";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import { IComponent } from "../../layouts/IComponent";

type RouteProps = {
  _path: string;
  components: { [key: string]: IComponent };
};
type Props = {
  routes: { [key: string]: RouteProps };
  useSwitch?: boolean;
} & AbstractStylableProps &
  WithTranslation &
  RouteComponentProps;

type States = {} & AbstractStylableStates;

class RouteComponent extends AbstractStylableComponent<Props, States> {
  static defaultProps = {
    useSwitch: true,
  };
  readonly state: States = {};

  renderRoutes() {
    const { identifier, match, routes } = this.props;

    return Object.values(routes).map((route) => {
      return (
        <Route
          path={this.evaluateExpression(route._path, { match })}
          children={({ match }) => {
            const paramsToAdd = identifier
              ? { [identifier]: match.params }
              : {};
            Log.debug("RoutesComponent match found", match, route);
            return Object.entries(route.components)
              .sort((a, b) =>
                a[1] && b[1] ? a[1].orderIndex - b[1].orderIndex : 0
              )
              .map(([key, item]) => {
                Log.debug("RoutesComponent render", item, {
                  ...this.props.params,
                  ...paramsToAdd,
                });

                return (window as any).ComponentsMapper.createElement(
                  item,
                  { ...this.props.params, ...paramsToAdd },
                  key
                );
              });
          }}
        />
      );
    });
  }
  render() {
    const { useSwitch, match, routes } = this.props;

    if (useSwitch) {
      return <Switch>{this.renderRoutes()}</Switch>;
    } else {
      return <>{this.renderRoutes()}</>;
    }
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default withRouter(
  connect(mapStateToProps, { requestListData })(
    withTranslation()(RouteComponent)
  )
);
