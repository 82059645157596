import classNames from "classnames";
import _ from "lodash";
import { useRef, useState } from "react";
import AssetLoader from "../../components/AssetLoader/AssetLoader";
import ModalManager from "../../components/ModalComponent/ModalManager";
import InfiniteTableFilter from "../../configurable/data/FilterComponent/InfiniteTableFilter";
import InfiniteTableSort from "../../configurable/data/SortComponent/InfiniteTableSort";
import i18n from "../../i18n";
import { AssetTypes } from "../../model/AssetTypes";
import {
  Signature,
  Textblock,
  UserConfigAsset,
} from "../../model/db/UserConfigAsset";
import BaseAsset from "../../model/general-assets/BaseAsset";
import { useTypedSelector } from "../../redux/hooks";
import DataBusDefaults from "../../services/DataBusDefaults";
import BFDropzone from "../abstract-ui/dropzone/BFDropzone";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BfIcon from "../abstract-ui/icon/BfIcon";
import CommentListToggle from "./CommentListToggle";
import { COMMENTS_MODULE_FLEX_PREFIX } from "./CommentUtils";
import { Comment } from "./Comments";
import {
  CommentsFilterOptions,
  CommentsListSortOptions,
} from "./CommentsFilterOptions";
import CommentsList from "./CommentsList";
import "./CommentsModule.scss";
import CommentsService from "./CommentsService";
import CommentEmailUploadDialog from "./components/CommentEmailUploadDialog";
import CommentInput from "./components/CommentInput";
export interface CommentsModuleProps {
  identifier: string;
  assetType: string;
  assetId: string;
  type: string;
  subactivity?: "allow" | "force" | "force-on-mail";
  allowMailUpload?: boolean;
  disableEmailCommunication?: boolean;
  hideResubmissionActionForMail?: boolean;
  mode?: "list" | "table" | "both";
  renderMailDuplicateAction?: (
    comment: Comment,
    onClose: () => void
  ) => JSX.Element;
}
const CommentsModule = (props: CommentsModuleProps) => {
  const modeComment = useTypedSelector(
    (state) =>
      state.customizations.flex[
        `${COMMENTS_MODULE_FLEX_PREFIX}${props.identifier}`
      ]
  );
  const [mode, setMode] = useState<"list" | "table">(
    ["list", "table"].includes(props.mode) ? props.mode : modeComment || "list"
  );
  const userId = useTypedSelector((state) => state.global.user._id);
  const [inputActive, setInputActive] = useState(false);
  const openRef = useRef<() => void>();
  return (
    <AssetLoader
      assetType={AssetTypes.UserConfig}
      query={{
        type: "op",
        name: "data.user",
        op: "eq",
        value: userId,
      }}
      render={(userConfig: UserConfigAsset) => {
        const signatures: Signature[] = userConfig?.data.signatures || [];
        const defaultSignature = userConfig?.data.defaultSignature;
        const textblocks: Textblock[] = userConfig?.data.textblocks || [];
        return (
          <div className={classNames(`comments-module`)}>
            <AssetLoader
              assetType={props.assetType}
              id={props.assetId}
              render={(asset: BaseAsset, selector, reload) => {
                return (
                  <>
                    <div className={`additional-actions`}>
                      <InfiniteTableFilter
                        // hideTextSearch
                        hideFavorites
                        identifier={props.identifier}
                        filterOptions={CommentsFilterOptions(
                          props.assetType === AssetTypes.CashBudget.Booking
                            ? [(asset as any)?.data?.unit]
                            : [(asset as any)?.data?.type],
                          asset
                        )}
                      />
                      <div className={`fill`} />
                      <InfiniteTableSort
                        identifier={props.identifier}
                        options={CommentsListSortOptions}
                      />
                      {props.allowMailUpload && (
                        <BFButton
                          className="margin-left-10"
                          onClick={() => openRef.current?.()}
                          tooltip={{
                            tooltip: i18n.t(
                              "CommentsModule.UploadEmail",
                              "E-Mail hochladen"
                            ),
                          }}
                        >
                          <BfIcon
                            type="light"
                            data="email-action-upload"
                            size="xs"
                          />
                        </BFButton>
                      )}
                      {!["list", "table"].includes(props.mode) && (
                        <CommentListToggle
                          identifier={props.identifier}
                          value={mode}
                          onChange={setMode}
                        />
                      )}
                    </div>
                    <div className={`new-comment-input`}>
                      <CommentInput
                        resizeImages={[2000, 2000]}
                        localStorageIdentifier={`${props.identifier}-${props.assetId}`}
                        linkedAsset={{
                          assetType: props.assetType,
                          assetId: props.assetId,
                        }}
                        type={props.type}
                        disableEmailCommunication={
                          props.disableEmailCommunication
                        }
                        onActiveChange={(active) => setInputActive(active)}
                        signatures={signatures}
                        defaultSignature={defaultSignature}
                        subactivity={props.subactivity}
                        textblocks={textblocks}
                        possibleContexts={
                          _.uniq(asset.communication?.subactivities) || []
                        }
                        identifier={props.identifier}
                        allowMentions
                        placeholder={i18n.t(
                          "CommentsModule.placeholder",
                          "Schreiben Sie einen Kommentar..."
                        )}
                        onSend={async (
                          message,
                          mailAddressFields,
                          subactivityName,
                          attachments
                        ) => {
                          try {
                            if (mailAddressFields) {
                              const attachmentsFromOtherAssets =
                                attachments?.filter((e) => e.fromtAssetId);
                              await CommentsService.sendMail({
                                assetType: props.assetType,
                                assetId: props.assetId,
                                message,
                                ...mailAddressFields,
                                subactivityName,
                                attachments: attachments
                                  ?.filter((e) => !e.fromtAssetId)
                                  .map((e) => e.cdnId),
                                attachmentsFromOtherMail:
                                  attachmentsFromOtherAssets &&
                                  attachmentsFromOtherAssets.length > 0
                                    ? {
                                        assetId:
                                          attachmentsFromOtherAssets[0]
                                            .fromtAssetId,
                                        linksToCdn:
                                          attachmentsFromOtherAssets.map(
                                            (e) => e.cdnId
                                          ),
                                      }
                                    : undefined,
                              });
                            } else {
                              await CommentsService.createInternalComment(
                                props.assetType,
                                props.assetId,
                                message,
                                subactivityName
                              );
                            }
                            DataBusDefaults.reload({
                              identifiers: [props.identifier],
                            });
                            // check if the subactivity was created, if yes, reload the asset
                            if (
                              !asset.communication?.subactivities?.find(
                                (e) => e.displayName === subactivityName
                              )
                            ) {
                              reload(true);
                            }
                          } catch (err) {
                            if (err?.response?.status === 413) {
                              DataBusDefaults.toast({
                                type: "error",
                                text: i18n.t(
                                  "CommentsModule.ErrorCreatingCommentTooLarge",
                                  "Leider ist ein Fehler beim Erstellen des Kommentars aufgetreten. Die Nachricht (inklusive Anhängen) ist zu groß."
                                ),
                              });
                            } else {
                              DataBusDefaults.toast({
                                type: "error",
                                text: i18n.t(
                                  "CommentsModule.ErrorCreatingComment",
                                  "Leider ist ein Fehler beim Erstellen des Kommentars aufgetreten."
                                ),
                              });
                            }
                            throw err;
                          }
                        }}
                      />
                    </div>
                    <div className={`comment-list-wrapper`}>
                      {props.allowMailUpload ? (
                        <BFDropzone
                          multipe={true}
                          accept={{
                            "message/rfc822": [],
                          }}
                          onDrop={async (acceptedFiles, fileRejections) => {
                            try {
                              if (
                                acceptedFiles.length > 0 &&
                                fileRejections.length === 0
                              ) {
                                ModalManager.show({
                                  noPadding: true,
                                  content: (states, setStates, closeModal) => (
                                    <CommentEmailUploadDialog
                                      assetId={props.assetId}
                                      assetType={props.assetType}
                                      files={acceptedFiles}
                                      onClose={closeModal}
                                      onSuccess={() => {
                                        DataBusDefaults.toast({
                                          type: "success",
                                          text: i18n.t(
                                            "CommentsModule.UploadEmailErrorUploadSuccess",
                                            "E-Mails erfolgreich hochgeladen. Sie werden zeitnah verarbeitet und als Kommentare angezeigt."
                                          ),
                                        });
                                      }}
                                      onError={(err) => {
                                        DataBusDefaults.toast({
                                          type: "error",
                                          text: i18n.t(
                                            "CommentsModule.UploadEmailErrorUploadFailed",
                                            "Fehler beim Hochladen der E-Mails. Bitte überprüfen Sie die Dateien auf Korrektheit."
                                          ),
                                        });
                                      }}
                                    />
                                  ),
                                });
                              } else {
                                DataBusDefaults.toast({
                                  type: "error",
                                  text: i18n.t(
                                    "CommentsModule.UploadEmailErrorWrongFile",
                                    "Bitte E-Mails mit dem Format .eml hochladen."
                                  ),
                                });
                              }
                            } catch (err) {
                              DataBusDefaults.toast({
                                type: "error",
                                text: i18n.t(
                                  "CommentsModule.UploadEmailErrorUploadFailed",
                                  "Fehler beim Hochladen der E-Mails. Bitte überprüfen Sie die Dateien auf Korrektheit."
                                ),
                              });
                            }
                          }}
                          render={(open) => {
                            openRef.current = open;
                            return (
                              <CommentsList
                                {...props}
                                mode={mode}
                                asset={asset}
                                open={open}
                                defaultSignature={
                                  defaultSignature >= 0
                                    ? signatures[defaultSignature].signature
                                    : undefined
                                }
                                renderMailDuplicateAction={
                                  props.renderMailDuplicateAction
                                }
                              />
                            );
                          }}
                        />
                      ) : (
                        <CommentsList
                          {...props}
                          mode={mode}
                          asset={asset}
                          defaultSignature={
                            defaultSignature >= 0
                              ? signatures[defaultSignature].signature
                              : undefined
                          }
                          hideResubmissionActionForMail={
                            props.hideResubmissionActionForMail
                          }
                          renderMailDuplicateAction={
                            props.renderMailDuplicateAction
                          }
                        />
                      )}
                    </div>
                  </>
                );
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default CommentsModule;
