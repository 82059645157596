import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import ModalManager from "@/components/ModalComponent/ModalManager";
import CacheService from "@/services/CacheService";
import moment from "moment";
import ObjectDetailView from "../../../../../../../components/ObjectDetailView/ObjectDetailView";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import CDNFileViewer from "../../../../../../../modules/abstract-ui/data/cdn/CDNFileViewer";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import { DefaultIcons } from "../../../../../../../modules/abstract-ui/icon/DefaultIcons";
import BFLoanData, {
  LoanValueEntry,
} from "../../../../../../../modules/abstract-ui/loan/BFLoanData";
import DataBus from "../../../../../../../services/DataBus";
import { DataBusSubKeys } from "../../../../../../../utils/Constants";
import StringUtils from "../../../../../../../utils/StringUtils";
import CBPortfolioUtils from "../../CBPortfolioUtils";
import { CBRegion } from "../../common/CBCommonComponents";
import { CBStatisticLoanAsset } from "../../interfaces/CBPortfolioAsset";
import "./CBPortfolioLoanDetailPage.scss";
import CBPortfolioLoanForm from "./CBPortfolioLoanForm";
interface Props {
  asset: CBStatisticLoanAsset;
  integrated?: boolean;
  kind?: ObjectKind;
  goBack: () => void;
}
const CBPortfolioLoanDetailPage = (props: Props) => {
  if (!props.asset) {
    return null;
  }

  return (
    <ObjectDetailView
      integrated={props.integrated}
      goBack={props.goBack}
      className="cb-portfolio-loan-detail-view"
      mainTitle={i18n.t("cb:Loan.loan", "Darlehen")}
      main={(isMobile) => (
        <ObjectDetailMainPage hideBackButton={isMobile} {...props} />
      )}
      routes={[
        // {
        //   name: "Dashboard",
        //   route: "dashboard",
        //   render: () => <div>Dashboard</div>,
        // },
        {
          name: i18n.t("cb:Loan.repaymentPlan", "Tilgungsplan"),
          route: "plan",
          render: () => <PayPlan {...props} />,
        },
      ]}
    />
  );
};
export default CBPortfolioLoanDetailPage;

const ObjectDetailMainPage = (props: Props & { hideBackButton: boolean }) => {
  return (
    <div className="object-detail-main-page __card">
      <div className="head">
        <div className="action-left">
          {!props.hideBackButton && (
            <BFButton
              appearance={"clear-on-white"}
              icon={{ size: "xs", ...DefaultIcons.BACK }}
              onClick={props.goBack}
            />
          )}
        </div>
        <DebugDataComponent data={props.asset} />
        <div className="title">
          {props.asset?.data.loanID} - {props.asset?.data.lender}
        </div>
        <div className="action-right">
          <BFButton
            appearance={"clear-on-white"}
            icon={{ size: "xs", ...DefaultIcons.EDIT }}
            onClick={() => {
              ModalManager.show({
                size: "xxl",
                content: (state, setState, close) => (
                  <CBPortfolioLoanForm
                    kind={props.kind}
                    asset={props.asset}
                    onClose={close}
                    onSuccess={(result) => {
                      CacheService.update(result);
                    }}
                    onDelete={() => {
                      props.goBack();
                      close();
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      </div>
      <div className="content">
        <CBRegion title={i18n.t("cb:Loan.data", "Daten")}>
          {[
            [i18n.t("cb:Loan.id", "ID"), props.asset?.data.loanID || "-"],
            [i18n.t("cb:Loan.bank", "Bank"), props.asset?.data.lender || "-"],
            [
              i18n.t("cb:Loan.description", "Beschreibung"),
              props.asset?.data.text || "-",
            ],
            [
              i18n.t("cb:Loan.entity", "Gesellschaft"),
              CBPortfolioUtils.findEntity(props.asset?.data.entity)
                ?.displayName || "-",
            ],
          ].map(([title, value], index) => (
            <div className="value-entry" key={index}>
              <div className="label">{title}</div>
              <div className="value">{value}</div>
            </div>
          ))}
        </CBRegion>

        <CBRegion title={i18n.t("cb:Loan.loan", "Darlehen")}>
          {[
            [
              i18n.t("cb:Loan.reason", "Grund"),
              props.asset?.data.reason || "-",
            ],
            [
              i18n.t("cb:Loan.typeOfLoan", "Darlehensart"),
              props.asset?.data.typeOfLoan || "-",
            ],
            [
              i18n.t("cb:Loan.loanAmount", "Darlehensumme"),
              StringUtils.formatCurrency(
                props.asset?.data.loanData.loanAmount,
                true
              ),
            ],
            [
              i18n.t("cb:Loan.monthlyPayment", "Monatliche Zahlung"),
              StringUtils.formatCurrency(
                props.asset?.data.loanData.paymentAmount,
                true
              ),
            ],
            [
              i18n.t("cb:Loan.annualInterest", "Zinssatz"),
              `${
                StringUtils.formatNumber(
                  props.asset?.data.loanData.annualInterest
                ) || "-"
              } %`,
            ],
            [
              i18n.t("cb:Loan.loanTermYears", "Darlehenslaufzeit in Jahren"),
              props.asset?.data.loanData.loanTermYears,
            ],
            [
              i18n.t("cb:Loan.loanPayoutDate", "Darlehen Auszahlung"),
              moment(props.asset?.data.loanData.loanPayoutDate).format(
                i18n.t("Formats.dateFormat")
              ),
            ],
            [
              i18n.t("cb:Loan.loanStart", "Darlehen Tilgungsbeginn"),
              moment(props.asset?.data.loanData.loanStartDate).format(
                i18n.t("Formats.dateFormat")
              ),
            ],
            [
              i18n.t("cb:Loan.loanEnd"),
              moment(props.asset?.data.calculated.loanEndDate).format(
                i18n.t("Formats.dateFormat")
              ),
            ],
            [
              "Letzte Rate",
              StringUtils.formatCurrency(
                props.asset?.data.calculated.scheduledLastRate,
                true
              ),
            ],
            [
              i18n.t("cb:Loan.sumInterest", "Summe Zinsen"),
              StringUtils.formatCurrency(
                props.asset?.data.calculated.sumInterest,
                true
              ),
            ],
            [
              i18n.t("cb:Loan.refinancingSum", "Refinanizerbare Summe"),
              StringUtils.formatCurrency(
                props.asset?.data.calculated.refinancingSum,
                true
              ),
            ],
            [
              i18n.t("cb:Loan.countPayments", "Anzahl Zahlungen"),
              props.asset?.data.calculated.scheduledPayments || 0,
            ],

            [
              i18n.t("cb:Loan.entity", "Gesellschaft"),
              CBPortfolioUtils.findEntity(props.asset?.data.entity)
                ?.displayName || "-",
            ],
          ].map(([title, value], index) => (
            <div className="value-entry" key={index}>
              <div className="label">{title}</div>
              <div className="value">{value}</div>
            </div>
          ))}
        </CBRegion>

        <CBRegion title={i18n.t("cb:Loan.objects", "Objekte")}>
          {(!props.asset?.data.objects ||
            props.asset?.data.objects?.length === 0) && (
            <div className="no-object-assigned">
              {i18n.t("cb:Loan.noObjectAssigned", "Kein Objekt zugeordnet")}
            </div>
          )}
          {props.asset?.data.objects?.map((objectAssignment) => (
            <div className="object-assignment">
              <div className="object-data">
                {
                  CBPortfolioUtils.findObject(objectAssignment.objectId)
                    ?.displayName
                }
                <BFButton
                  appearance="link"
                  onClick={() => {
                    DataBus.emit(DataBusSubKeys.ROUTE, {
                      append: false,
                      stayInApp: true,
                      route: `/objects/${objectAssignment.objectId}`,
                    });
                  }}
                >
                  {i18n.t("Global.Buttons.open")}
                </BFButton>
              </div>
              <div className="share">
                {StringUtils.formatNumber(objectAssignment.share)} %
              </div>
              <div className="actions"></div>
            </div>
          ))}
        </CBRegion>

        <CBRegion title={i18n.t("cb:Loan.files", "Dateien")}>
          <CDNFileViewer
            assetField="data.files"
            assetId={props.asset?._id}
            assetType="portfolio-loan"
            files={props.asset?.data.files}
          />
        </CBRegion>
      </div>
    </div>
  );
};

const PayPlan = (props: Props) => {
  return (
    <div className="object-detail-payplan __card">
      <BFLoanData
        readonly
        value={props.asset?.data.values as LoanValueEntry[]}
        type={props.asset?.data.typeOfLoan}
        loanData={props.asset?.data.loanData as any}
        // height="100%"
      />
    </div>
  );
};
