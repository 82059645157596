import { RentalUnitType } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import StructLoader from "@/components/StructLoader/StructLoader";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import _ from "lodash";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormFieldValues from "../../../../../../../components/Form/Fields/FormFieldValues";
import FormStruct from "../../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../../components/Form/Validation/FormValidators";
import LoadPage from "../../../../../../../components/LoadPage/LoadPage";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFCheckbox from "../../../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFOverlay from "../../../../../../../modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "../../../../../../../modules/abstract-ui/icon/BfIcon";
import { useHttpCache } from "../../../../../../../redux/hooks";
import LanguageService from "../../../../../../../services/LanguageService";
import NumberUtils from "../../../../../../../utils/ NumberUtils";
import StringUtils from "../../../../../../../utils/StringUtils";
import CBRentalService from "../../CBRentalService";
import { getConfigRentalUnitTypeGroup } from "../../CBTenantsConst";
import { EnrichtedRentalUnit } from "../../TenantsInterfaces";
import "./CBRentalObjectChangePlandata.scss";

interface CBRentalObjectChangePlandataProps {
  objectId: string;
  unitType: string;
  onClose: () => void;
}
const CBRentalObjectChangePlandata = (
  props: CBRentalObjectChangePlandataProps
) => {
  const data = useHttpCache<EnrichtedRentalUnit[]>(
    `object-stacking-plan-${props.objectId}`,
    `/rental/getStackingPlan`,
    "post",
    null,
    {
      objectIds: [props.objectId],
    }
  );

  if (data.state === "success") {
    const initialValues = {
      units: _.sortBy(data.data, (a) =>
        a.data.agreement
          ? isNaN(Number(a.data.agreement?.data.id))
            ? a.data.agreement?.data.id
            : Number(a.data.agreement?.data.id)
          : isNaN(Number(a.data.vacant?.data.id))
          ? a.data.vacant?.data.id
          : Number(a.data.vacant?.data.id)
      ).map((unit) => ({
        selected: true,
        rentalUnitId: unit._id,
        operatingCostGross: unit.data.operatingCostGross,
        operatingCostNet: unit.data.operatingCostNet,
        rentGross: unit.data.rentGross,
        rentNet: unit.data.rentNet,
      })),
    };

    const aggregated = {
      ...data.data
        .map((unit) => {
          const unitType = ObjectKindStruct.getUnitTypeBy(unit.data.unitType);
          const group = getConfigRentalUnitTypeGroup(unitType?.group);
          const isAreal = group?.areaType === "area";

          return {
            area: isAreal ? unit.data.area : 0,
            quantity: isAreal ? 0 : unit.data.quantity,
          };
        })
        .reduce(
          (acc, curr) => ({
            area: acc.area + (curr.area || 0),
            quantity: acc.quantity + curr.quantity,
          }),
          {
            area: 0,
            quantity: 0,
          }
        ),
      nonVacant: data.data.filter((e) => e.data.agreement).length,
      vacant: data.data.filter((e) => e.data.vacant).length,
      rentNetSum: data.data
        .map((e) => e.data.agreement)
        .filter((e) => e)
        .reduce((acc, curr) => acc + curr.data.rentNet, 0),
      operatingCostSum: data.data
        .map((e) => e.data.agreement)
        .filter((e) => e)
        .reduce((acc, curr) => acc + curr.data.operatingCostNet, 0),
    };

    return (
      <StructLoader
        structType="objectKind"
        unitType={props.unitType}
        render={() => (
          <FormStruct
            className={"cb-rental-object-change-plandata-form"}
            submitText={i18n.t(
              "cb:RentalObject.PlanDataForm.SubmitPlanData",
              "Planmieten speichern"
            )}
            title={i18n.t(
              "cb:RentalObject.PlanDataForm.Title",
              "Planmieten bearbeiten"
            )}
            description={i18n.t(
              "cb:RentalObject.PlanDataForm.Description",
              "Bitte selektieren Sie die Mieteinheiten, bei welchen Sie Änderungen an den Planmieten durchführen wollen und ändern Sie die Planmieten und Nebenkosten. Die Änderungen werden erst nach dem Speichern wirksam. Die Planmieten werden für die Berechnung der Leerstandsverluste verwendet."
            )}
            subscription={{ submitting: true }}
            initialValues={initialValues}
            onSubmit={async (values) => {
              // todo submit data
              const result = await CBRentalService.submitRentalUnitPlanData(
                values.units
                  ?.filter((e) => e.selected)
                  .map((e) => {
                    const rentalUnit = data.data.find(
                      (a) => a._id === e.rentalUnitId
                    );
                    const unitType = ObjectKindStruct.getUnitTypeBy(
                      rentalUnit.data.unitType
                    );

                    return {
                      rentalUnitId: e.rentalUnitId,
                      operatingCostGross:
                        unitType.taxRate === 0
                          ? e.operatingCostNet
                          : e.operatingCostGross,
                      operatingCostNet: e.operatingCostNet,
                      rentGross:
                        unitType.taxRate === 0 ? e.rentNet : e.rentGross,
                      rentNet: e.rentNet,
                    };
                  }),
                {
                  assetType: AssetTypes.Portfolio.Object,
                  id: props.objectId,
                }
              );
              data.reload();
              props.onClose();
            }}
            onAbort={props.onClose}
            render={({ form }) => {
              return (
                <div className={`plandata-wrapper`}>
                  {/* <div className={`object-stacking`}>stackingplan</div> */}
                  <div className={`form-data`}>
                    <FieldArray name="units">
                      {({ fields }) => (
                        <table className={`form-data-table`}>
                          <thead>
                            <tr className={`head-line`}>
                              <th className={`selection`}>
                                <BFCheckbox
                                  indeterminate={
                                    fields.value.some((e) => e.selected) &&
                                    fields.value.some((e) => !e.selected)
                                  }
                                  checked={fields.value.every(
                                    (e) => e.selected
                                  )}
                                  onChange={(_val, checked) =>
                                    fields.forEach((e, i) => {
                                      fields.update(i, {
                                        ...fields.value[i],
                                        selected: checked,
                                      });
                                    })
                                  }
                                />
                              </th>
                              <th className={`unit`}>
                                {i18n.t(
                                  "cb:RentalObject.PlanDataForm.Unit",
                                  "Einheit"
                                )}
                              </th>
                              <th className={`unitType`}>
                                {i18n.t(
                                  "cb:RentalObject.PlanDataForm.UnitType",
                                  "Art"
                                )}
                              </th>
                              <th className={`quantity`}>
                                {i18n.t(
                                  "cb:RentalObject.PlanDataForm.Quantity",
                                  "Fläche/Anzahl"
                                )}
                              </th>
                              <th className={`floor`}>
                                {i18n.t(
                                  "cb:RentalObject.PlanDataForm.Floor",
                                  "Etage"
                                )}
                              </th>
                              <th className={`agreement`} colSpan={3}>
                                {i18n.t(
                                  "cb:RentalObject.PlanDataForm.rentalAgreement",
                                  "Aktueller Mietvertrag"
                                )}
                              </th>
                              <th className={`rentNet`} colSpan={2}>
                                {i18n.t(
                                  "cb:RentalObject.PlanDataForm.RentNet",
                                  "Planmiete Netto"
                                )}
                              </th>
                              <th className={`rentGross`}>
                                {i18n.t(
                                  "cb:RentalObject.PlanDataForm.RentGross",
                                  "Planmiete Brutto"
                                )}
                              </th>
                              <th className={`operatingCostNet`}>
                                {i18n.t(
                                  "cb:RentalObject.PlanDataForm.operatingCostNet",
                                  "Plan Nebenkosten Netto"
                                )}
                              </th>
                              <th className={`operatingCostGross`}>
                                {i18n.t(
                                  "cb:RentalObject.PlanDataForm.operatingCostGross",
                                  "Plan Nebenkosten Brutto"
                                )}
                              </th>
                              <th></th>
                            </tr>
                          </thead>

                          <tbody>
                            {fields.map((name, index) => (
                              <FormFieldValues
                                key={index}
                                names={[`${name}.rentalUnitId`]}
                              >
                                {([rentalUnitId]) => {
                                  const rentalUnit = data.data.find(
                                    (e) => e._id === rentalUnitId
                                  );
                                  const unitType =
                                    ObjectKindStruct.getUnitTypeBy(
                                      rentalUnit.data.unitType
                                    );
                                  const group = getConfigRentalUnitTypeGroup(
                                    unitType?.group
                                  );
                                  const isAreal = group?.areaType === "area";

                                  return (
                                    <Field name={`${name}.selected`}>
                                      {({ input: selectedInput }) => (
                                        <tr
                                          className={`content-line ${
                                            selectedInput.value === false
                                              ? "not-selected"
                                              : ""
                                          }`}
                                        >
                                          <td className={`selection`}>
                                            <BFCheckbox
                                              checked={selectedInput.value}
                                              onChange={(_val, checked) =>
                                                selectedInput.onChange(checked)
                                              }
                                            />
                                          </td>
                                          <td className={`unit`}>
                                            <div className={`id`}>
                                              {rentalUnit.data.id}
                                            </div>
                                            <div>
                                              {rentalUnit.data.displayName}
                                            </div>
                                          </td>
                                          <td className={`unitType`}>
                                            {LanguageService.translateLabel(
                                              unitType?.displayName
                                            )}
                                          </td>
                                          <td className={`quantity`}>
                                            {isAreal
                                              ? `${StringUtils.formatNumber(
                                                  rentalUnit.data.area || 0
                                                )} ${StringUtils.getAreaUnit()}`
                                              : rentalUnit.data.quantity}
                                          </td>
                                          <td className={`floor`}>
                                            {rentalUnit.data.floor}
                                          </td>
                                          <td className={`agreement no-border`}>
                                            {rentalUnit.data.agreement ? (
                                              <div className={`entry renter`}>
                                                <div className={`label`}>
                                                  {i18n.t(
                                                    "cb:RentalObect.PlanDataForm.Renter",
                                                    "Mieter"
                                                  )}
                                                </div>
                                                <div className={`value`}>
                                                  {
                                                    rentalUnit.data.agreement
                                                      ?.data.id
                                                  }
                                                  {" - "}
                                                  {
                                                    rentalUnit.data.tenant?.data
                                                      .displayName
                                                  }
                                                </div>
                                              </div>
                                            ) : (
                                              "-"
                                            )}
                                          </td>
                                          <td className={`rent no-border`}>
                                            {rentalUnit.data.agreement && (
                                              <div className={`entry`}>
                                                <div className={`label`}>
                                                  {i18n.t(
                                                    "cb:RentalObect.PlanDataForm.Rent",
                                                    "Kaltmiete"
                                                  )}
                                                </div>
                                                <div className={`value`}>
                                                  {StringUtils.formatCurrency(
                                                    rentalUnit.data.agreement
                                                      .data.rentNet
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </td>
                                          <td className={`operatingCost`}>
                                            {rentalUnit.data.agreement && (
                                              <div className="entry">
                                                <div className={`label`}>
                                                  {i18n.t(
                                                    "cb:RentalObect.PlanDataForm.OperatingCost",
                                                    "Nebenkosten"
                                                  )}
                                                </div>
                                                <div className={`value`}>
                                                  {StringUtils.formatCurrency(
                                                    rentalUnit.data.agreement
                                                      .data.operatingCostNet
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </td>
                                          <Field name={`${name}.rentGross`}>
                                            {(rentGross) => (
                                              <Field name={`${name}.rentNet`}>
                                                {({ input, meta }) => {
                                                  const updateGrossField = (
                                                    value: number
                                                  ) => {
                                                    rentGross.input.onChange(
                                                      value *
                                                        ((unitType.taxRate ||
                                                          0) /
                                                          100 +
                                                          1)
                                                    );
                                                  };

                                                  const count =
                                                    (isAreal
                                                      ? rentalUnit.data.area
                                                      : rentalUnit.data
                                                          .quantity) || 1;
                                                  return (
                                                    <>
                                                      <td
                                                        className={`rentNet no-border`}
                                                      >
                                                        <BFInput
                                                          className={`rent-direct`}
                                                          disabled={
                                                            !selectedInput.value
                                                          }
                                                          type="priceInput"
                                                          textAlign="left"
                                                          prefix={StringUtils.getCurrencySymbol()}
                                                          //   label={i18n.t("cb:PlannedBookingBatch.amountToPlan", "Planwert")}
                                                          {...input}
                                                          onChange={(
                                                            value: number
                                                          ) => {
                                                            updateGrossField(
                                                              value
                                                            );
                                                            input.onChange(
                                                              value
                                                            );
                                                          }}
                                                          {...FormValidators.getValidation(
                                                            meta
                                                          )}
                                                        />
                                                      </td>
                                                      <td
                                                        className={`rentNetPerQuantity`}
                                                      >
                                                        <BFInput
                                                          className={`rent-by-quantity`}
                                                          disabled={
                                                            !selectedInput.value
                                                          }
                                                          type="priceInput"
                                                          textAlign="right"
                                                          suffix={
                                                            isAreal
                                                              ? `${i18n.t(
                                                                  "cb:RentalObject.PlanDataForm.perArea",
                                                                  "pro"
                                                                )} ${StringUtils.getAreaUnit()}`
                                                              : i18n.t(
                                                                  "cb:RentalObject.PlanDataForm.perUnit",
                                                                  "pro Einheit"
                                                                )
                                                          }
                                                          //   label={i18n.t("cb:PlannedBookingBatch.amountToPlan", "Planwert")}
                                                          value={NumberUtils.normalizeDecimal(
                                                            input.value / count
                                                          )}
                                                          onChange={(
                                                            value: number
                                                          ) => {
                                                            if (
                                                              NumberUtils.normalizeDecimal(
                                                                input.value /
                                                                  count
                                                              ) !== value
                                                            ) {
                                                              input.onChange(
                                                                NumberUtils.normalizeDecimal(
                                                                  value * count
                                                                )
                                                              );

                                                              updateGrossField(
                                                                NumberUtils.normalizeDecimal(
                                                                  value * count
                                                                )
                                                              );
                                                            }
                                                          }}
                                                          {...FormValidators.getValidation(
                                                            meta
                                                          )}
                                                        />
                                                      </td>
                                                    </>
                                                  );
                                                }}
                                              </Field>
                                            )}
                                          </Field>
                                          <td className={`rentGross`}>
                                            <Field name={`${name}.rentNet`}>
                                              {(rentNet) => (
                                                <Field
                                                  name={`${name}.rentGross`}
                                                >
                                                  {({ input, meta }) => {
                                                    const updateNetField = (
                                                      value: number
                                                    ) => {
                                                      rentNet.input.onChange(
                                                        value /
                                                          ((unitType.taxRate ||
                                                            0) /
                                                            100 +
                                                            1)
                                                      );
                                                    };

                                                    return (
                                                      <BFInput
                                                        disabled={
                                                          !selectedInput.value ||
                                                          unitType.taxRate === 0
                                                        }
                                                        type="priceInput"
                                                        textAlign="left"
                                                        prefix={StringUtils.getCurrencySymbol()}
                                                        removeSuffixPadding
                                                        //   label={i18n.t("cb:PlannedBookingBatch.amountToPlan", "Planwert")}
                                                        {...input}
                                                        value={
                                                          unitType.taxRate === 0
                                                            ? rentNet.input
                                                                .value
                                                            : input.value
                                                        }
                                                        onChange={(
                                                          value: number
                                                        ) => {
                                                          updateNetField(value);
                                                          input.onChange(value);
                                                        }}
                                                        {...FormValidators.getValidation(
                                                          meta
                                                        )}
                                                      />
                                                    );
                                                  }}
                                                </Field>
                                              )}
                                            </Field>
                                          </td>
                                          <td className={`operatingCostNet`}>
                                            <Field
                                              name={`${name}.operatingCostGross`}
                                            >
                                              {(operatingCostGross) => (
                                                <Field
                                                  name={`${name}.operatingCostNet`}
                                                >
                                                  {({ input, meta }) => {
                                                    const updateGrossField = (
                                                      value: number
                                                    ) => {
                                                      operatingCostGross.input.onChange(
                                                        value *
                                                          ((unitType.taxRate ||
                                                            0) /
                                                            100 +
                                                            1)
                                                      );
                                                    };

                                                    return (
                                                      <BFInput
                                                        disabled={
                                                          !selectedInput.value
                                                        }
                                                        type="priceInput"
                                                        textAlign="left"
                                                        prefix={StringUtils.getCurrencySymbol()}
                                                        //   label={i18n.t("cb:PlannedBookingBatch.amountToPlan", "Planwert")}
                                                        {...input}
                                                        onChange={(
                                                          value: number
                                                        ) => {
                                                          updateGrossField(
                                                            value
                                                          );
                                                          input.onChange(value);
                                                        }}
                                                        {...FormValidators.getValidation(
                                                          meta
                                                        )}
                                                      />
                                                    );
                                                  }}
                                                </Field>
                                              )}
                                            </Field>
                                          </td>
                                          <td className={`operatingCostGross`}>
                                            <Field
                                              name={`${name}.operatingCostNet`}
                                            >
                                              {(operatingCostNet) => (
                                                <Field
                                                  name={`${name}.operatingCostGross`}
                                                >
                                                  {({ input, meta }) => {
                                                    const updateNetField = (
                                                      value: number
                                                    ) => {
                                                      operatingCostNet.input.onChange(
                                                        value /
                                                          ((unitType.taxRate ||
                                                            0) /
                                                            100 +
                                                            1)
                                                      );
                                                    };
                                                    return (
                                                      <BFInput
                                                        disabled={
                                                          !selectedInput.value ||
                                                          unitType.taxRate === 0
                                                        }
                                                        type="priceInput"
                                                        textAlign="left"
                                                        prefix={StringUtils.getCurrencySymbol()}
                                                        removeSuffixPadding
                                                        //   label={i18n.t("cb:PlannedBookingBatch.amountToPlan", "Planwert")}
                                                        {...input}
                                                        value={
                                                          unitType.taxRate === 0
                                                            ? operatingCostNet
                                                                .input.value
                                                            : input.value
                                                        }
                                                        onChange={(
                                                          value: number
                                                        ) => {
                                                          updateNetField(value);
                                                          input.onChange(value);
                                                        }}
                                                        {...FormValidators.getValidation(
                                                          meta
                                                        )}
                                                      />
                                                    );
                                                  }}
                                                </Field>
                                              )}
                                            </Field>
                                          </td>

                                          <td style={{ width: 50 }}>
                                            <FormFieldValues
                                              allProps
                                              names={[
                                                `${name}.rentalUnitId`,
                                                `${name}.rentNet`,
                                                `${name}.rentGross`,
                                                `${name}.operatingCostNet`,
                                                `${name}.operatingCostGross`,
                                              ]}
                                            >
                                              {([
                                                rentalUnitId,
                                                rentNet,
                                                rentGross,
                                                operatingCostNet,
                                                operatingCostGross,
                                              ]) => {
                                                if (
                                                  rentNet.meta.dirty ||
                                                  rentGross.meta.dirty ||
                                                  operatingCostNet.meta.dirty ||
                                                  operatingCostGross.meta.dirty
                                                ) {
                                                  return (
                                                    <BFButton
                                                      appearance="link"
                                                      tooltip={{
                                                        tooltip: i18n.t(
                                                          "cb:RentalObject.PlanDataForm.RevertTooltip",
                                                          "Änderungen verwerfen"
                                                        ),
                                                      }}
                                                      onClick={() => {
                                                        const initialValue =
                                                          initialValues.units.find(
                                                            (e) =>
                                                              e.rentalUnitId ===
                                                              rentalUnitId.input
                                                                .value
                                                          );

                                                        rentNet.input.onChange(
                                                          initialValue.rentNet
                                                        );
                                                        rentGross.input.onChange(
                                                          initialValue.rentGross
                                                        );
                                                        operatingCostNet.input.onChange(
                                                          initialValue.operatingCostNet
                                                        );
                                                        operatingCostGross.input.onChange(
                                                          initialValue.operatingCostGross
                                                        );
                                                      }}
                                                    >
                                                      <BfIcon
                                                        type="light"
                                                        data="undo"
                                                        size="xxs"
                                                      />
                                                    </BFButton>
                                                  );
                                                } else {
                                                  return null;
                                                }
                                              }}
                                            </FormFieldValues>
                                          </td>
                                        </tr>
                                      )}
                                    </Field>
                                  );
                                }}
                              </FormFieldValues>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr className={`footer-line`}>
                              <td className={`selection`}></td>
                              <td className={`unit`}>
                                {fields.length}{" "}
                                {i18n.t(
                                  "cb:RentalObject.PlanDataForm.Units",
                                  "Einheiten"
                                )}
                              </td>
                              <td className={`unitType`}></td>
                              <td className={`quantity`}>
                                <div className={`__flex __text-align-left`}>
                                  <div className={`entry margin-right-5`}>
                                    <div className={`label`}>
                                      {i18n.t(
                                        "cb:RentalObject.PlanDataForm.Area",
                                        "Fläche"
                                      )}
                                    </div>
                                    <div className={`value`}>
                                      {StringUtils.formatNumber(
                                        aggregated.area
                                      )}{" "}
                                      {StringUtils.getAreaUnit()}
                                    </div>
                                  </div>
                                  <div className={`entry`}>
                                    <div className={`label`}>
                                      {i18n.t(
                                        "cb:RentalObject.PlanDataForm.QuantityNumber",
                                        "Anzahl"
                                      )}
                                    </div>
                                    <div className={`value`}>
                                      {aggregated.quantity}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className={`floor`}></td>
                              <td className={`agreement no-border`}>
                                <div className={`__flex`}>
                                  <div className={`entry  margin-right-5`}>
                                    <div className={`label`}>
                                      {i18n.t(
                                        "cb:RentalObject.PlanDataForm.Agreements",
                                        "Mietverträge"
                                      )}
                                    </div>
                                    <div className={`value`}>
                                      {aggregated.nonVacant}
                                    </div>
                                  </div>
                                  <div className={`entry`}>
                                    <div className={`label`}>
                                      {i18n.t(
                                        "cb:RentalObject.PlanDataForm.Vacant",
                                        "Leerstände"
                                      )}
                                    </div>
                                    <div className={`value`}>
                                      {aggregated.vacant}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className={`agreement no-border`}>
                                <div className={`entry`}>
                                  <div className={`label`}>
                                    {i18n.t(
                                      "cb:RentalObject.PlanDataForm.RentNetCurrent",
                                      "Kaltmiete"
                                    )}
                                  </div>
                                  <div className={`value`}>
                                    {StringUtils.formatCurrency(
                                      aggregated.rentNetSum
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className={`agreement`}>
                                <div className={`entry`}>
                                  <div className={`label`}>
                                    {i18n.t(
                                      "cb:RentalObject.PlanDataForm.OpertingCost",
                                      "Nebenkosten"
                                    )}
                                  </div>
                                  <div className={`value`}>
                                    {StringUtils.formatCurrency(
                                      aggregated.operatingCostSum
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className={`rentNet __text-align-center`}>
                                {StringUtils.formatCurrency(
                                  fields.value
                                    .map((e) => e.rentNet)
                                    .reduce((acc, curr) => acc + (curr || 0), 0)
                                )}
                              </td>
                              <td
                                className={`rentNetByQuantity __text-align-left __flex`}
                              >
                                <div className={`entry margin-right-5`}>
                                  <div className={`label`}>
                                    Ø{" "}
                                    {i18n.t(
                                      "cb:RentalObject.PlanDataForm.RentNetPerArea",
                                      "Pro m²"
                                    )}
                                  </div>
                                  <div className={`value`}>
                                    {StringUtils.formatCurrency(
                                      fields.value
                                        .filter((e) =>
                                          [
                                            "residental",
                                            "commercial",
                                            "storage",
                                          ].includes(
                                            ObjectKindStruct.getUnitTypeBy(
                                              data.data.find(
                                                (a) => a._id === e.rentalUnitId
                                              ).data.unitType
                                            ).group
                                          )
                                        )
                                        .map((e) => e.rentNet)
                                        .reduce(
                                          (acc, curr) => acc + (curr || 0),
                                          0
                                        ) / aggregated.area
                                    )}
                                  </div>
                                </div>
                                <div className={`entry`}>
                                  <div className={`label`}>
                                    Ø{" "}
                                    {i18n.t(
                                      "cb:RentalObject.PlanDataForm.RentPerQuantity",
                                      "Pro Einheit"
                                    )}
                                  </div>
                                  <div className={`value`}>
                                    {StringUtils.formatCurrency(
                                      fields.value
                                        .filter(
                                          (e) =>
                                            ![
                                              "residental",
                                              "commercial",
                                              "storage",
                                            ].includes(
                                              ObjectKindStruct.getUnitTypeBy(
                                                data.data.find(
                                                  (a) =>
                                                    a._id === e.rentalUnitId
                                                ).data.unitType
                                              ).group
                                            )
                                        )
                                        .map((e) => e.rentNet)
                                        .reduce(
                                          (acc, curr) => acc + (curr || 0),
                                          0
                                        ) / aggregated.quantity
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className={`rentGross`}>
                                {StringUtils.formatCurrency(
                                  fields.value
                                    .map((e) => e.rentGross)
                                    .reduce((acc, curr) => acc + (curr || 0), 0)
                                )}
                              </td>
                              <td className={`operatingCostNet`}>
                                {StringUtils.formatCurrency(
                                  fields.value
                                    .map((e) => e.operatingCostNet)
                                    .reduce((acc, curr) => acc + (curr || 0), 0)
                                )}
                              </td>
                              <td className={`operatingCostGross`}>
                                {StringUtils.formatCurrency(
                                  fields.value
                                    .map((e) => e.operatingCostGross)
                                    .reduce((acc, curr) => acc + (curr || 0), 0)
                                )}
                              </td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </table>
                      )}
                    </FieldArray>
                  </div>
                </div>
              );
            }}
          />
        )}
      />
    );
  }
  if (data.state === "loading") {
    return <LoadPage />;
  }
  if (data.state === "error") {
    return <div>Fehler, beschwer dich bei fabi.</div>;
  }
};

export default CBRentalObjectChangePlandata;

const InfoOverlay = (props: {
  isValue: number;
  shouldValue: number;
  unitType: RentalUnitType;
  fieldLabel: string;
  onCorrectValue: (value: number) => void;
}) => {
  return (
    <BFOverlay
      enterable
      placement="bottom"
      speaker={
        <div className={`rental-plandata-info-overlay`}>
          <div className={`description`}>
            {i18n.t(
              "cb:RentalObject.PlanDataForm.InfoOverlay.Description",
              "Der angegebene Betrag für die {{label}} entspricht nicht dem hinterlegten Steuersatz für diese Mieteinheit. Bitte überprüfen Sie den Betrag und korrigieren Sie diesen falls notwendig.",
              {
                label: props.fieldLabel,
              }
            )}
          </div>
          <div className={`action`}>
            <BFButton
              onClick={() => props.onCorrectValue(props.shouldValue)}
              appearance="link"
            >
              {i18n.t(
                "cb:RentalObject.PlanDataForm.InfoOverlay.CorrectValue",
                "Korrektur anwenden: {{value}}",
                {
                  value: StringUtils.formatCurrency(props.shouldValue),
                }
              )}
            </BFButton>
          </div>
        </div>
      }
    >
      <div className={`info-overlay-icon`}>
        <BfIcon data="information-circle" type="light" size="xs" />
      </div>
    </BFOverlay>
  );
};
