import ModalManager from "@/components/ModalComponent/ModalManager";
import WrappedText from "@/components/WrappedText/WrappedText";
import i18n from "@/i18n";
import BFStatus from "@/modules/abstract-ui/data/status/BFStatus";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { useActivityConstants } from "../../../ActivityHooks";
import ActivityService from "../../../ActivityService";
import { APAdvertisementActivity } from "../maintenance/APActivityMainteanceInterfaces";
import "./APAdvertisement.scss";
import APAdvertisementForm from "./APAdvertisementForm";

interface APAdvertisementProps {
  activity: APAdvertisementActivity;
}
const APAdvertisement = (props: APAdvertisementProps) => {
  const constants = useActivityConstants();
  return (
    <div className={classNames(`ap-advertisement`)}>
      <div className={`__ez-section-title subtitle`}>
        {i18n.t(
          "apTemplate.Activity.advertisementFeature..advertisement",
          "Inserate"
        )}
      </div>
      <div className={`vacancy-entries`}>
        {(props.activity.data.advertisements || []).length === 0 && (
          <div className={`__empty`}>
            {i18n.t(
              "apTemplate.Activity.advertisementFeature..noAdvertisements",
              "Keine Inserate vorhanden"
            )}
          </div>
        )}
        {(props.activity.data.advertisements || []).map((advertisement) => (
          <div className={`vacant-advertisement-entry`}>
            <div className={`href`}>
              <a href={advertisement.link} target="_blank">
                {advertisement.link}
              </a>
            </div>
            <div className={`margin-top-10 entry-list`}>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalVacancy.Advertisement.Status", "Status")}
                </div>
                <div className={`value`}>
                  <BFStatus
                    label={
                      advertisement.status === "active"
                        ? i18n.t(
                            "cb:RentalVacancy.Advertisement.Active",
                            "Aktiv"
                          )
                        : i18n.t(
                            "cb:RentalVacancy.Advertisement.Inactive",
                            "Inaktiv"
                          )
                    }
                    color={
                      advertisement.status === "active" ? "#d6ffd5" : "#efa9a9"
                    }
                    size={"sm"}
                  />
                </div>
              </div>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t(
                    "cb:RentalVacancy.Advertisement.platform",
                    "Plattform"
                  )}
                </div>
                <div className={`value`}>{advertisement.platform || "-"}</div>
              </div>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalVacancy.Advertisement.from", "Aktiv ab")}
                </div>
                <div className={`value`}>
                  {advertisement.from
                    ? StringUtils.formatDate(advertisement.from)
                    : "-"}
                </div>
              </div>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalVacancy.Advertisement.to", "Aktiv bis")}
                </div>
                <div className={`value`}>
                  {advertisement.to
                    ? StringUtils.formatDate(advertisement.to)
                    : "-"}
                </div>
              </div>
              <div className={`actions`}>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() => {
                    ModalManager.show({
                      size: "md",
                      backdrop: "static",
                      noPadding: true,
                      content: (states, setStates, closeModal) => (
                        <APAdvertisementForm
                          serviceUrl={constants.serviceUrl}
                          advertisement={advertisement}
                          activity={props.activity}
                          onSuccess={(data) => {
                            closeModal();
                          }}
                          onClose={() => {
                            closeModal();
                          }}
                        />
                      ),
                    });
                  }}
                >
                  {i18n.t("Global.Buttons.edit")}
                </BFButton>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() => {
                    ModalManager.confirm({
                      message: i18n.t(
                        "cb:RentalVacancy.Advertisement.RemoveConfirm",
                        "Wollen Sie das Inserat aus diesem Leerstand entfernen?"
                      ),
                      confirmButtonText: i18n.t(
                        "Global.Buttons.delete"
                      ) as string,
                      title: i18n.t(
                        "cb:RentalVacancy.Advertisement.RemoveConfirmTitle",
                        "Inserat entfernen"
                      ),
                      onConfirm: () => {
                        ActivityService.removeAdvertisement(
                          constants.serviceUrl,
                          props.activity,
                          advertisement.id
                        );
                      },
                    });
                  }}
                >
                  {i18n.t("Global.Buttons.remove")}
                </BFButton>
              </div>
            </div>
            {advertisement.note && (
              <div className={`entry-list`}>
                <div className={`entry`}>
                  <div className={`label`}>
                    {i18n.t("cb:RentalVacancy.Advertisement.note", "Notiz")}
                  </div>
                  <div className={`value`}>
                    <WrappedText text={advertisement.note} />
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}

        <div className={`add-action`}>
          <BFButton
            appearance="link"
            size="md"
            onClick={() => {
              ModalManager.show({
                size: "md",
                backdrop: "static",
                noPadding: true,
                content: (states, setStates, closeModal) => (
                  <APAdvertisementForm
                    serviceUrl={constants.serviceUrl}
                    activity={props.activity}
                    onSuccess={(data) => {
                      closeModal();
                    }}
                    onClose={() => {
                      closeModal();
                    }}
                  />
                ),
              });
            }}
          >
            {i18n.t("Global.Add", "Hinzufügen")}
          </BFButton>
        </div>
      </div>
    </div>
  );
};

export default APAdvertisement;
