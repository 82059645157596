export enum CSS_CLASSES {
  COLOR_NEGATIVE = "__color-negative",
  COLOR_POSTIIVE = "__color-positive",
  COLOR_NEUTRAL = "__color-neutral",
}
class CSSUtilsClass {
  getColorClassForNumber(val: number) {
    if (val > 0) {
      return CSS_CLASSES.COLOR_POSTIIVE;
    } else if (val < 0) {
      return CSS_CLASSES.COLOR_NEGATIVE;
    } else {
      return CSS_CLASSES.COLOR_NEUTRAL;
    }
  }
  getColorClassForNumberPercent(val: number) {
    if (val > 1) {
      return CSS_CLASSES.COLOR_POSTIIVE;
    } else if (val < 1) {
      return CSS_CLASSES.COLOR_NEGATIVE;
    } else {
      return CSS_CLASSES.COLOR_NEUTRAL;
    }
  }
}
const CSSUtils = new CSSUtilsClass();
export default CSSUtils;
