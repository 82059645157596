import classNames from "classnames";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ConfigurableDashboardByAsset from "../../modules/configurable-dashboard/ConfigurableDashboardByAsset";
import { setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { useTypedSelector } from "../../redux/hooks";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import GlobalDashboard from "../../services/GlobalDashboard";
import "./HomeView.scss";

const DASHBOARD_ID = "home-dashboard";
interface HomeViewProps {}
const HomeView = (props: HomeViewProps) => {
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => state.global.user._id);
  const context = useTypedSelector((state) => state.global.context);
  useEffect(() => {
    dispatch(setUiConfig(DefaultUIConfigs.HEADER_TRANSPARENT, true));
    return () => {
      dispatch(setUiConfig(DefaultUIConfigs.HEADER_TRANSPARENT, false));
    };
  });
  return (
    <div className={classNames(`home-view`)}>
      <div
        className={`background`}
        style={{
          backgroundImage:
            context && context.login ? context.login.background : undefined,
        }}
      />
      <ConfigurableDashboardByAsset
        dashboardId={DASHBOARD_ID}
        globalAsset
        configs={GlobalDashboard.getComponents()}
        defaultData={{
          layouts: {
            xs: [
              {
                w: 12,
                h: 3,
                x: 0,
                y: 0,
                i: "notifications",
              },
              {
                w: 12,
                h: 3,
                x: 0,
                y: 3,
                i: "appdrawer-all",
              },
            ],
            sm: [
              {
                w: 12,
                h: 6,
                x: 0,
                y: 4,
                i: "notifications",
              },
              {
                w: 12,
                h: 4,
                x: 0,
                y: 0,
                i: "appdrawer-all",
              },
            ],
            md: [
              {
                i: "notifications",
                x: 0,
                y: 3,
                w: 12,
                h: 4,
              },
              {
                i: "appdrawer-all",
                x: 0,
                y: 0,
                w: 12,
                h: 3,
              },
            ],
            lg: [
              {
                i: "notifications",
                x: 0,
                y: 0,
                w: 4,
                h: 6,
              },
              {
                i: "appdrawer-all",
                x: 4,
                y: 0,
                w: 5,
                h: 3,
              },
            ],
            xl: [
              {
                i: "notifications",
                x: 0,
                y: 0,
                w: 4,
                h: 6,
              },
              {
                i: "appdrawer-all",
                x: 4,
                y: 0,
                w: 5,
                h: 3,
              },
            ],
          },
          data: ["notifications", "appdrawer-all"],
        }}
      />
    </div>
  );
};

export default HomeView;
