import classNames from "classnames";
import i18n from "../../../../i18n";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import { RSAssetBaseParams } from "../../RSInterfaces";
import useOpenResubmissionForm from "../../hooks/useOpenResubmissionForm";

interface RSCreateButtonProps {
  identifier: string;
  linkedAsset?: RSAssetBaseParams;
  typePermissions?: string[];
}
const RSCreateButton = (props: RSCreateButtonProps) => {
  const { identifier, linkedAsset } = props;

  const { openResubmissionBtnIconProps, openResubmissionDialog } =
    useOpenResubmissionForm();

  return (
    <div className={classNames("rs-create-button")}>
      <BFButton
        appearance="clear-on-white"
        tooltip={{
          tooltip: i18n.t(
            "Resubmission.Buttons.Create",
            "Wiedervorlage erstellen"
          ),
        }}
        icon={openResubmissionBtnIconProps}
        size="xs"
        onClick={() =>
          openResubmissionDialog({
            identifierToUpdate: identifier,
            linkedAsset,
            typePermissions: props.typePermissions,
          })
        }
      />
    </div>
  );
};

export default RSCreateButton;
