import classNames from "classnames";
import _ from "lodash";
import React from "react";
import BFInput, { BFInputProps } from "../input/BFInput";
import "./BFInputTable.scss";

type BFInputTableValueAsArray = { id: string; [key: string]: any }[];

type BFInputTableColumnValue = any;
type BFInputTableRowValue = {
  [columnIdentifier: string]: BFInputTableColumnValue;
};
type BFInputTableValue = {
  [rowIdentifier: string]: BFInputTableRowValue;
};
export type BFInputTableColumn = {
  fixedWidth?: number | string;
  flexWidth?: number;
  identifier: string;
  label: string;
  defaultValue?: any;
  inputProps?: (
    rowValue: BFInputTableRowValue,
    onChange: (value: BFInputTableRowValue) => void,
    row: BFInputTableRow
  ) => Partial<BFInputProps>;
  render?: (
    value: BFInputTableRowValue,
    onChange: (value: BFInputTableRowValue) => void,
    row: BFInputTableRow
  ) => React.ReactNode;
  footer?: () => React.ReactNode;
};
export type BFInputTableRow = {
  identifier: string;
  label: string;
  data?: any;
};
interface BFInputTableProps {
  className?: string;
  columns: BFInputTableColumn[];
  rows: BFInputTableRow[];
  // the value can be in array structure with a defined type or in object map structure -
  // - the return value will be defined by the input type. If it is an array, it will return as an arry
  value: BFInputTableValue | BFInputTableValueAsArray;
  onChange: (value: BFInputTableValue | BFInputTableValueAsArray) => void;
}

const transformArrayToValue = (arr: BFInputTableValueAsArray) => {
  const result: BFInputTableValue = {};

  arr.forEach(({ id, ...data }) => {
    if (id) {
      result[id] = data;
    }
  });

  return result;
};
const transformValueToArray = (value: BFInputTableValue) => {
  const result: BFInputTableValueAsArray = [];

  Object.keys(value).forEach((key) => {
    result.push({
      id: key,
      ...value[key],
    });
  });

  return result;
};

const PIXEL_PER_CHARACTER = 8;
const BFInputTable = (props: BFInputTableProps) => {
  const isMobile = false;
  const rowLabelWidth =
    _.sortBy(props.rows.map((e) => e.label.length))[props.rows.length - 1] *
      PIXEL_PER_CHARACTER +
    20;

  const currentValue = Array.isArray(props.value)
    ? transformArrayToValue(props.value)
    : props.value;
  const onChange = (value: BFInputTableValue) => {
    if (Array.isArray(props.value)) {
      props.onChange(transformValueToArray(value));
    } else {
      props.onChange(value);
    }
  };

  return (
    <div className={classNames(`bf-input-table`, props.className)}>
      <div className="table-header">
        <div className={`row-label-header`} style={{ width: rowLabelWidth }} />
        {props.columns.map((column) => {
          return (
            <div
              className={`table-column-header label-cell`}
              style={{
                width: column.fixedWidth,
                flex: !column.fixedWidth
                  ? `${column.flexWidth || 1} 0`
                  : undefined,
              }}
            >
              {column.label}
            </div>
          );
        })}
      </div>
      {props.rows.map((row) => {
        const rowValue = currentValue[row.identifier];
        const onRowChange = (value: BFInputTableRowValue) => {
          onChange({
            ...(currentValue || {}),
            [row.identifier]: value,
          });
        };
        return (
          <div className={`table-row`} key={row.identifier}>
            <div
              className={`row-label label-cell`}
              style={{ width: rowLabelWidth }}
            >
              {row.label}
            </div>
            {props.columns.map((column) => {
              const columnValue =
                (rowValue || {})[column.identifier] || column.defaultValue;
              const onColumnChange = (value: BFInputTableColumnValue) => {
                onRowChange({
                  ...(currentValue[row.identifier] || {}),
                  [column.identifier]: value,
                });
              };
              return (
                <div
                  className={`table-column`}
                  style={{
                    width: column.fixedWidth,
                    flex: !column.fixedWidth
                      ? `${column.flexWidth || 1} 0`
                      : undefined,
                  }}
                  key={`${row.identifier}_${column.identifier}`}
                >
                  {column.render ? (
                    column.render(rowValue, onRowChange, row)
                  ) : (
                    <BFInput
                      appearance="clear"
                      onChange={onColumnChange}
                      value={columnValue}
                      label={isMobile ? column.label : undefined}
                      {...(column.inputProps?.(rowValue, onRowChange, row) ||
                        {})}
                      className={`table-column-input ${
                        column.inputProps?.(rowValue, onRowChange, row)
                          .className || ""
                      }`}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
      {props.columns.map((e) => e.footer).filter((e) => e).length > 0 && (
        <div className={`table-row`}>
          <div
            className={`row-label label-cell`}
            style={{ width: rowLabelWidth }}
          ></div>
          {props.columns.map((column) => {
            return (
              <div
                className={`table-column`}
                style={{
                  width: column.fixedWidth,
                  flex: !column.fixedWidth
                    ? `${column.flexWidth || 1} 0`
                    : undefined,
                }}
                key={`sum_${column.identifier}`}
              >
                {column.footer?.()}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BFInputTable;
