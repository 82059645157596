import classNames from "classnames";
import { debounce } from "lodash";
import { Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import ListComponent from "../../../../configurable/components/ListComponent/ListComponent";
import ListEntryComponent from "../../../../configurable/components/ListComponent/ListEntryComponent";
import InfiniteTable from "../../../../redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "../../../../redux/hooks";
import { MatchQuery } from "../../../../services/DataService";
import BFTagInput, { TagInputEntry } from "./BFTagInput";
import "./BFTagInputSearch.scss";

interface BFTagInputSearchBaseProps {
  searchTerm: string;
  onSearchTermChange: (term: string) => void;
  resultListIdentifier: string;
  searchUrl: string;
  additionalMatchQuery?: MatchQuery;
  tags: TagInputEntry[];
  onTagAdd: (newTag: string) => void;
  onTagRemove: (removedTag: string) => void;
  listLimitPerRequest?: number;
  listDisableEndlessScroll?: boolean;
  debounceTime?: number;
  overwriteKeyDownEvent?: (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => boolean;
  onInputBlur?: () => void;
}

interface RenderSearchAsEntries extends BFTagInputSearchBaseProps {
  listRenderType: "default" | undefined;
  renderSearchResults: (node: any) => React.ReactNode;
  onClickSearchEntry: (node: any) => string;
}

interface RenderSearchAsList extends BFTagInputSearchBaseProps {
  listRenderType: "render-as-list";
  renderSearchResults: (nodes: any[]) => React.ReactNode;
}

type BFTagInputSearchProps = RenderSearchAsEntries | RenderSearchAsList;

const BFTagInputSearch = (props: BFTagInputSearchProps) => {
  const dispatch = useDispatch();
  const infiniteTable = useTypedSelector(
    (state) => state.application.infiniteTables[props.resultListIdentifier]
  );

  useEffect(() => {
    if (props.searchTerm === "") {
      dispatch(InfiniteTable.clearTable(props.resultListIdentifier));
    }
  }, [props.searchTerm]);

  const debounceTime = props.debounceTime || 0;
  const debouncedSearch = useRef(
    debounce((term: string) => {
      if (term.trim() === "") {
        return;
      }
      dispatch(InfiniteTable.setSearch(props.resultListIdentifier, term));
    }, debounceTime)
  ).current;

  const onChangeSearchTerm = (term: string) => {
    props.onSearchTermChange(term);
    debouncedSearch(term);
  };

  const onClickEntry = (entry: any) => {
    if (props.listRenderType !== "render-as-list") {
      const tag = props.onClickSearchEntry(entry);
      props.onTagAdd(tag);
      props.onSearchTermChange("");
    }
  };

  const renderResultsAsList = (results: any[], params: any) => {
    return (
      <div className="bf-tag-input-search-list">
        {props.renderSearchResults(infiniteTable?.data || [])}
      </div>
    );
  };

  const renderResultsAsEntry = (node: any, index: number, params: any) => {
    return (
      <ListEntryComponent
        onClickEntry={() => {
          onClickEntry(node);
        }}
        renderEntry={() => {
          return <Fragment>{props.renderSearchResults(node)}</Fragment>;
        }}
        entryData={node}
      />
    );
  };

  const renderOverlay = () => {
    const listComponentProps = {
      identifier: props.resultListIdentifier,
      dataUrl: props.searchUrl,
      reloadOnMount: true,
      asPost: true,
      additionalMatchQuery: props.additionalMatchQuery,
      limitPerRequest: props.listLimitPerRequest,
      disableEndlessScroll: props.listDisableEndlessScroll,
      isSearchMode: true,
    };

    if (props.listRenderType === "render-as-list") {
      return (
        <ListComponent
          dataRenderType={"render-as-list"}
          render={renderResultsAsList}
          {...listComponentProps}
        />
      );
    } else {
      return (
        <ListComponent
          dataRenderType={"default"}
          render={renderResultsAsEntry}
          {...listComponentProps}
        />
      );
    }
  };

  const tags = props.tags || [];
  return (
    <div className={classNames("bf-tag-input-search")}>
      <BFTagInput
        overwriteKeyDownEvent={props.overwriteKeyDownEvent}
        tagTriggers={["enter"]}
        tagValues={tags}
        onInputValueChange={onChangeSearchTerm}
        renderInputOverlay={renderOverlay}
        inputValue={props.searchTerm}
        onTagValueAdd={(newTag: string) => {
          props.onTagAdd(newTag);
        }}
        onTagRemove={(removedTag: string) => {
          props.onTagRemove(removedTag);
        }}
        onInputBlur={props.onInputBlur}
      />
    </div>
  );
};

export default BFTagInputSearch;
