import StructLoader from "@/components/StructLoader/StructLoader";
import classNames from "classnames";
import React from "react";
import Collapse from "rsuite/esm/Animation/Collapse";
import AssetLoader from "../../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../../components/ModalComponent/ModalManager";
import Log from "../../../../debug/Log";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import BFDropzone from "../../../../modules/abstract-ui/dropzone/BFDropzone";
import BFButton from "../../../../modules/abstract-ui/general/Button/BFButton";
import { hasValue } from "../../../../utils/Helpers";
import { AssetCashBudgetEntry } from "../model/CashBudgetEntry";
import BookingService from "../services/BookingService";
import CBIdlyBookingEntry from "../views/idly-bookings/CBIdlyBookingEntry";
import "./CashBudgetBookingCard.scss";
import CashBudgetBookingFooter from "./CashBudgetBookingFooter";
import { DetailEntryType } from "./CashBudgetCategoryDetailEntryTypes";
import CashBudgetDetailEntryCardContent from "./CashBudgetDetailEntryCardContent";

interface CashBudgetBookingCardProps {
  booking: AssetCashBudgetEntry;
  hideEntityData?: boolean;
  showAccountData?: boolean;
  showCategory?: boolean;
  expanded?: boolean;
  simple?: boolean;
  allowRevertCategoryChange?: boolean;
  disableUpload?: boolean;
  hideFooter?: boolean;
  onSelect?: () => void;
}
const CashBudgetBookingCard = (props: CashBudgetBookingCardProps) => {
  const [uploading, setUploading] = React.useState(false);
  const [iniitalCategory] = React.useState(props.booking.data.category);
  const categoryChanged =
    props.allowRevertCategoryChange &&
    props.booking.data.category !== iniitalCategory;

  const renderCard = (open?: () => void) => {
    return (
      <div
        className="__card"
        onClick={props.onSelect ? () => props.onSelect() : undefined}
      >
        <CashBudgetDetailEntryCardContent
          entry={props.booking}
          simple={props.simple}
          showAccountData={props.showAccountData}
          hideEntityData={props.hideEntityData}
          showCategory={props.showCategory}
          isUploading={uploading}
        />
        {!props.hideFooter && (
          <Collapse in={hasValue(props.expanded) ? props.expanded : true}>
            <div>
              <CashBudgetBookingFooter
                booking={props.booking}
                expanded={props.expanded}
                onUpload={open}
                onConnect={() =>
                  ModalManager.show({
                    backdrop: true,
                    closable: true,
                    headerCloseButton: true,
                    title: i18n.t(
                      "cb:CashBudgetBookingFooter.linkAssetTitle",
                      "Buchung verknüpfen"
                    ),
                    size: "xxl",
                    content: (state, setState, onClose) => (
                      <div className={`modal-booking-footer`}>
                        <AssetLoader
                          assetType={AssetTypes.CashBudget.Booking}
                          id={props.booking._id}
                          render={(booking) => (
                            <CBIdlyBookingEntry
                              booking={booking}
                              showConnect={false}
                            />
                          )}
                        />
                      </div>
                    ),
                  })
                }
                uploading={uploading}
              />
            </div>
          </Collapse>
        )}

        {categoryChanged ? (
          <div className={`category-changed-overlay`}>
            <div>
              {i18n.t(
                "CategoryDetailsEntry.categoryHasBeenChanged",
                "Wurde umkategorisiert"
              )}
            </div>
            <BFButton
              onClick={async () =>
                await BookingService.submitCategory(
                  props.booking._id,
                  iniitalCategory
                )
              }
            >
              {i18n.t("Global.Buttons.reset")}
            </BFButton>
          </div>
        ) : null}
      </div>
    );
  };

  const render = () => {
    return (
      <div
        className={classNames(` cb-booking-card`, {
          forecast:
            props.booking.data.type &&
            props.booking.data.type.indexOf("forecast") !== -1,
          sharepoint:
            props.booking.data.type &&
            props.booking.data.type.indexOf("sharepoint") !== -1,
          "fixed-comparison":
            props.booking.data.type === DetailEntryType.COMPARISON_FIX,
          planned: props.booking.data.type === DetailEntryType.MANUAL,
        })}
      >
        {props.disableUpload && renderCard()}
        {!props.disableUpload && (
          <BFDropzone
            onDrop={
              (acceptedFiles, rejectedFiles, event) => {
                Log.info(
                  "###BFUpload drophandler",
                  acceptedFiles,
                  rejectedFiles,
                  event
                );
                if (acceptedFiles && acceptedFiles.length > 0) {
                  setUploading(true);
                  BookingService.uploadBookingAttachments(
                    props.booking,
                    acceptedFiles
                  )
                    .then(() => {})
                    .finally(() => {
                      setUploading(false);
                    });
                }
              }
              // this.onDropHandler(acceptedFiles, rejectedFiles, event)
            }
            multipe
            accept={{
              "image/*": [],
              "application/pdf": [],
              "application/msword": [],
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                [],
            }}
            maxSize={20000000}
            render={(open) => renderCard(open)}
          />
        )}
      </div>
    );
  };

  return props.booking.data.unit ? (
    <StructLoader
      structTypes={["orga", "category", "unit"]}
      unitType={props.booking.data.unit}
      render={() => render()}
    />
  ) : (
    render()
  );
};

export default CashBudgetBookingCard;
