import { Field } from "react-final-form";
import FormStruct from "../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../components/Form/Validation/FormValidators";
import i18n from "../../i18n";
import { AssetTypes } from "../../model/AssetTypes";
import { useTypedSelector } from "../../redux/hooks";
import SubmitService from "../../services/SubmitService";
import BFCheckbox from "../abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "../abstract-ui/forms/input/BFInput";
import { TemplateAsset, TemplateData } from "./TemplateInterfaces";
// import "./SaveTemplateModal.scss";

interface SaveTemplateModalProps {
  templateType: string;
  type: string;
  data: any;
  initialName?: string;
  onClose: () => void;
  onSuccess?: () => void;
}
const SaveTemplateModal = (props: SaveTemplateModalProps) => {
  const user = useTypedSelector((state) => state.global.user._id);
  return (
    <FormStruct
      onSubmit={async (data) => {
        const result: TemplateAsset = (await SubmitService.submitDataAsync({
          type: "asset",
          assetType: AssetTypes.Template,
          ignorePropChecks: true,
          ignoreSubmitValidation: true,
          data: {
            data,
          },
        })) as TemplateAsset;

        props.onSuccess?.();
        props.onClose();
      }}
      onAbort={props.onClose}
      submitText={i18n.t("Template.saveTemplate", "Vorlage speichern")}
      ignoreSubmitOnEnter
      className={`save-template-modal`}
      description={i18n.t(
        "Template.saveDescription",
        "Geben Sie an, unter welchem Namen Sie die Vorlage speichern wollen. Sie können Sie dann beim nächsten mal mit den von Ihnen hinterlegten Daten finden."
      )}
      title={i18n.t("Template.saveTemplate", "Vorlage speichern")}
      initialValues={
        {
          name: props.initialName || "",
          data: props.data,
          type: props.type,
          templateType: props.templateType,
          forAll: false,
          user: user,
          identifier: "",
        } as TemplateData
      }
      render={(formProps) => (
        <>
          <div className={`__field`}>
            <Field
              name="identifier"
              validate={FormValidators.compose(FormValidators.max(100))}
            >
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  label={i18n.t("Template.identifier", "Suchkürzel")}
                  {...FormValidators.getValidation(meta)}
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field
              name="name"
              validate={FormValidators.compose(
                FormValidators.required(),
                FormValidators.max(160),
                FormValidators.min(3)
              )}
            >
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  label={i18n.t("Template.templateName", "Vorlagenname") + "*"}
                  {...FormValidators.getValidation(meta)}
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="forAll">
              {({ input, meta }) => (
                <BFCheckbox
                  checked={input.value}
                  onChange={(_, checked) => input.onChange(checked)}
                >
                  {i18n.t(
                    "Template.forAll",
                    "Vorlage für alle verfügbar machen"
                  )}
                </BFCheckbox>
              )}
            </Field>
          </div>
        </>
      )}
    />
  );
};

export default SaveTemplateModal;
