import { useCallback } from "react";
import i18n from "../../../../i18n";
import {
  DSListEntryAction,
  DocumentStoreAssetParams,
  DocumentStoreDocument,
} from "../../DSInterfaces";
import DSService from "../../DSService";
import DSListEntry from "../DSListEntry/DSListEntry";
import DSUploadInfoPrefix from "../DSUploadInfoPrefix/DSUploadInfoPrefix";
import "./DSDocumentList.scss";

type DSDocumentListProps = {
  documents: DocumentStoreDocument[];
  assetParams: DocumentStoreAssetParams;
  hideUploadData?: boolean;
  hideOrphanedIndicator?: boolean;
  documentEntryActions?: DSListEntryAction<DocumentStoreDocument>[];
  renderPostfixElement?: (document: DocumentStoreDocument) => React.ReactNode;
};

const DSDocumentList = (props: DSDocumentListProps) => {
  const { documents } = props;

  const renderPostFixedElements = (document: DocumentStoreDocument) => {
    if (props.hideUploadData) {
      return null;
    }

    return <DSUploadInfoPrefix document={document} />;
  };

  const onOpenAttachment = useCallback(
    (document: DocumentStoreDocument) => {
      DSService.openDocument(document, props.assetParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.assetParams.asset]
  );

  return (
    <div className="ds-document-list">
      {documents.map((document) => {
        const contentType = DSService.getContentTypeFromDocument(
          document,
          props.assetParams.asset
        );
        const icon = DSService.getIconFromContentType(contentType);

        const showOrphanedIndicator =
          document.isOrphaned && !props.hideOrphanedIndicator;
        const orphanIndicatorProps = {
          showIndicator: showOrphanedIndicator,
          indicatorText: i18n.t(
            "ds:DocumentStore.OrphanedDocument",
            "Dieses Dokument ist keinem Verzeichnis zugeordnet."
          ),
        };

        return (
          <DSListEntry
            type="document"
            entryObject={document}
            key={document.linkToCdn}
            iconName={icon}
            name={document.name}
            onClick={() => {
              onOpenAttachment(document);
            }}
            postfix={props.renderPostfixElement?.(document)}
            postFixedElements={renderPostFixedElements(document)}
            indicatorProps={orphanIndicatorProps}
            entryActions={props.documentEntryActions}
          />
        );
      })}
    </div>
  );
};

export default DSDocumentList;
