import { useEffect } from "react";
interface LifeCycleProps {
  onMount?: () => void;
  onUnmount?: () => void;

  children: JSX.Element;
}
const LifeCycle = (props: LifeCycleProps) => {
  useEffect(() => {
    props.onMount?.();
    return () => {
      props.onUnmount?.();
    };
  }, []);
  return props.children;
};

export default LifeCycle;
