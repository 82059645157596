import classNames from "classnames";
import { useState } from "react";
import ModalManager from "../../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../../i18n";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import BFButtonToggle from "../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import DataBusDefaults from "../../../../../services/DataBusDefaults";
import { useActivityConstants } from "../../ActivityHooks";
import { APActivity } from "../../ActivityInterfaces";
import { useActivityStruct } from "../../ActivityStructContext";
import "./APActivityApprovals.scss";
import APActivityOfferApproval from "./APActivityOfferApproval";
import APActivityStartApproval from "./APActivityStartApproval";

type ApprovalTab = "all" | "ongoing" | "accepted" | "declined";
interface Props {
  activity: APActivity;
}
const APActivityApprovals = (props: Props) => {
  const activityStruct = useActivityStruct();
  const [activeTab, setActiveTab] = useState<ApprovalTab>("all");
  const constants = useActivityConstants();

  const startApproval = () => {
    const config = activityStruct.getConfig(props.activity.data.type);

    if (
      props.activity.data.offers.length >= config.data.offersNeededToStartFlow
    ) {
      ModalManager.show({
        title: i18n.t(
          "apTemplate:ActivitiesStartApproval.Title",
          "Genehmigung starten",
          {
            ns: [constants?.assetType, "apTemplate"],
          }
        ),
        buttons: [],
        backdrop: "static",
        size: "md",
        modalClassName: "ap-activity-start-approval-dialog",
        content: (states, setStates, closeModal) => (
          <APActivityStartApproval
            activityStruct={activityStruct}
            activity={props.activity}
            onSuccess={(data) => {
              closeModal();
            }}
            onAbort={() => {
              closeModal();
            }}
          />
        ),
      });
    } else {
      DataBusDefaults.toast({
        type: "warning",
        text: i18n.t(
          "apTemplate:ActivitiesStartApproval.NotEnoughOffers",
          "Es sind nicht genügend Angebote vorhanden um die Freigabe zu starten.",
          {
            ns: [constants?.assetType, "apTemplate"],
          }
        ),
      });
    }
  };

  const approvalsToShow = props.activity.data.offerApproval.filter(
    (approval) => {
      if (activeTab === "all") {
        return true;
      } else if (activeTab === "ongoing") {
        return approval.status === "ongoing";
      } else if (activeTab === "accepted") {
        return approval.status === "accepted";
      } else if (activeTab === "declined") {
        return approval.status === "declined";
      }
    }
  );

  return (
    <div className={classNames(`ap-activity-approvals`)}>
      <div className={`actions`}>
        <BFButton onClick={startApproval}>
          {i18n.t(
            "apTemplate:Activity.StartNewApproval",
            "Neue Freigabe starten",
            {
              ns: [constants?.assetType, "apTemplate"],
            }
          )}
        </BFButton>
        <div className="fill" />
        <BFButtonToggle
          value={activeTab}
          onChange={setActiveTab}
          buttons={[
            {
              value: "all",
              text: i18n.t("apTemplate:Activity.all", "Alle", {
                ns: [constants?.assetType, "apTemplate"],
              }),
            },
            {
              value: "ongoing",
              text: i18n.t("apTemplate:Activity.ongoing", "In Freigabe", {
                ns: [constants?.assetType, "apTemplate"],
              }),
            },
            {
              value: "accepted",
              text: i18n.t("apTemplate:Activity.accepted", "Freigegeben", {
                ns: [constants?.assetType, "apTemplate"],
              }),
            },
            {
              value: "declined",
              text: i18n.t("apTemplate:Activity.declined", "Abgelehnt", {
                ns: [constants?.assetType, "apTemplate"],
              }),
            },
          ]}
        />
      </div>
      <div className={`approvals`}>
        {approvalsToShow.length === 0 && (
          <div className={`__empty`}>
            {i18n.t(
              "apTemplate:Activity.NoApprovals",
              "Keine Freigaben vorhanden",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            )}
          </div>
        )}
        {[...approvalsToShow].reverse().map((approval) => (
          <div className={`approval-entry __card`}>
            <APActivityOfferApproval
              offerApproval={approval}
              activity={props.activity}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default APActivityApprovals;
