import { useDispatch } from "react-redux";
import BFButton from "../modules/abstract-ui/general/Button/BFButton";
import { useTypedSelector } from "../redux/hooks";
import { DefaultUIConfigs } from "../redux/reducers/ui-config/UiConfig";
import { setUiConfig } from "./../redux/actions/ui-config/ui-config-actions";
import "./DebugDataComponent.scss";

type DebugDataComponentProps = {
  data: any;
  text?: string;
};

const DebugDataComponent = (props: DebugDataComponentProps) => {
  const dispatch = useDispatch();
  const debugActive = useTypedSelector(
    (state) => state.uiConfig.general[DefaultUIConfigs.DEBUG_COMPONENTS]
  );

  const { data, text } = props;

  if (!debugActive) {
    return null;
  }

  return (
    <>
      <BFButton
        className={`debug-data-button`}
        text={text}
        style={{ padding: 0, overflow: "visible" }}
        appearance="debug"
        onClick={() =>
          dispatch(setUiConfig(DefaultUIConfigs.DEBUG_DATA_JSON, data))
        }
        icon={{
          type: "bf",
          className: "debug-icon",
          data: "robot",
          fontSize: 8,
        }}
      ></BFButton>
    </>
  );
};

export default DebugDataComponent;
