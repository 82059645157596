import classNames from "classnames";
import i18n from "../../i18n";
import SwiperComponent from "../Swiper/SwiperComponent";
import SwiperPage from "../Swiper/SwiperPage";
import "./VerticalProcessHistory.scss";
import VerticalProcessHistoryEntry from "./VerticalProcessHistoryEntry";
import { VerticalProcessEntry } from "./VerticalProcessHistoryInterfaces";

interface VerticalProcessHistoryProps {
  //   showPagination?: boolean;
  showNavigation?: boolean;
  useMousewheel?: boolean;
  useFreeMode?: boolean;

  id: string;
  emptyComponent?: React.ReactNode;
  data: VerticalProcessEntry[];
}
const VerticalProcessHistory = (props: VerticalProcessHistoryProps) => {
  return (
    <div className={classNames(`vertical-process-history`)}>
      <SwiperComponent
        id={props.id}
        usePagination={false}
        useNavigationButtons={props.showNavigation}
        useMousewheel={props.useMousewheel}
        useFreeMode={props.useFreeMode}
        useZoom={false}
        spaceBetween={0}
        centeredSlides={false}
        slidesPerView={"auto"}
        initialIndex={props.data.length}
      >
        {props.data.length === 0 && (
          <div className={`empty-page`}>
            {props.emptyComponent ? (
              props.emptyComponent
            ) : (
              <div>
                {i18n.t("ProcessHistory.Empty", "Keine Einträge vorhanden")}
              </div>
            )}
          </div>
        )}
        {props.data.map((dataEntry, index) => (
          <SwiperPage key={index}>
            <VerticalProcessHistoryEntry entry={dataEntry} />
          </SwiperPage>
        ))}
      </SwiperComponent>
    </div>
  );
};

export default VerticalProcessHistory;
