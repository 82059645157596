import classNames from "classnames";
import { css } from "emotion";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DefaultUIConfigs } from "../../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../../utils/abstracts/AbstractStylableComponent";
import BFButton from "../../general/Button/BFButton";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import "./BFTag.scss";

export type BFTagColor = "default" | "success" | "warning" | "error";

type Props = {
  textKey: string;
  icon?: BfIconProps;
  size?: "sm" | "md";
  removeAction?: () => void;
  color?: BFTagColor;
} & AbstractStylableProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class BFTag extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  //componentDidMount() {
  //}

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //componentDidUpdate(prevProps:Props, prevState:States, snapshot) {}

  //

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  render() {
    const { i18n, textKey, icon } = this.props;

    const color = this.props.color || "default";
    if (!this.shoudBeRendered()) {
      return;
    }
    return (
      <div
        className={`BFTag ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        } ${this.props.size || "md"} ${color}`}
      >
        {icon ? (
          <div className={`icon`}>
            <BfIcon {...icon} />
          </div>
        ) : null}
        <div
          className={classNames(
            "text",
            { "has-icon": this.props.icon },
            { "has-close-icon": this.props.removeAction }
          )}
        >
          {i18n.t(textKey)}
        </div>
        {this.props.removeAction && (
          <div className="close-button">
            <BFButton
              className="close-button"
              size="xs"
              appearance="clear-on-white"
              icon={{ type: "light", data: "close", size: "xs" }}
              onClick={this.props.removeAction}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default connect(mapStateToProps, {})(withTranslation()(BFTag)) as any;
