import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./MapComponent.scss";
import { LatLong, calculateBounds, calculateCenter } from "./MapUtils";

type POI = {
  geo: LatLong;
  label?: string;
};
type Props = {
  POIs: POI[];
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};
const PADDING = 0.06;

const POIMapComponent: React.FC<Props> = (props) => {
  let history = useHistory();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBz4ujoaakqAwfNiGrRLgLorrAZxM51Ank",
  });
  const [map, setMap] = React.useState(null);
  const [selectedStores, setSelectedStores] = React.useState([]);

  const centerPOIS = () => {
    if (map) {
      map.fitBounds(calculateBounds(props.POIs.map((e) => e.geo)));
    } else {
      setTimeout(centerPOIS, 10);
    }
  };
  useEffect(() => {
    if (map) {
      centerPOIS();
    }
  }, [props.POIs]);

  const onLoad = React.useCallback((map) => {
    setMap(map);

    setTimeout(() => {
      centerPOIS();
    });
  }, []);

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  return (
    <div className="map-component">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={calculateCenter(props.POIs?.map((e) => e.geo))}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
          }}
          zoom={props.POIs.length === 1 ? 10 : 6}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {props.POIs.map((poi, index) => (
            <MarkerComp poi={poi} index={index} key={index} />
          ))}
        </GoogleMap>
      )}
    </div>
  );
};
export default POIMapComponent;

const MarkerComp = (props: { poi: POI; index: number }) => {
  const [hovered, setHovered] = React.useState(false);
  return (
    <Marker
      label={hovered ? props.poi.label : undefined}
      key={props.index}
      // opacity={
      //   selectedStores.map((e) => e.id).indexOf(e.id) === -1 ? 0.5 : 1
      // }
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      position={props.poi.geo}
      clickable
      // icon={
      //   selectedStores.map((e) => e.id).indexOf(e.id) === -1
      //     ? "https://d4sxwz0gz1e3r.cloudfront.net/marker/marker_unselected.png"
      //     : "https://d4sxwz0gz1e3r.cloudfront.net/marker/marker_selected.png"
      // }
      // onClick={() =>
      //   history.push(history.location.pathname.replace(objectId, e.id))
      // }
      // zIndex={
      //   selectedStores.map((e) => e.id).indexOf(e.id) === -1
      //     ? undefined
      //     : 100
      // }
      cursor={"pointer"}
    />
  );
};
