import { Plugin } from "@ckeditor/ckeditor5-core";
import {
  addListToDropdown,
  createDropdown,
  Model,
} from "@ckeditor/ckeditor5-ui";
import { add } from "@ckeditor/ckeditor5-utils/src/translation-service";

import { Collection } from "@ckeditor/ckeditor5-utils";

add("en", {
  Templates: "Templates",
});
add("de", {
  Templates: "Textbausteine",
});

export default class TextblockPlugin extends Plugin {
  init() {
    const editor = this.editor;
    if (!editor.config._config.textblocks) {
      return;
    }
    const textblocks = editor.config._config.textblocks;
    const { t } = editor.locale;

    editor.ui.componentFactory.add("textboxDropdown", (locale) => {
      const collection = new Collection();
      textblocks.forEach((textblock) => {
        collection.add({
          type: "button",
          model: new Model({
            id: textblock.id,
            textblock: textblock.textblock,
            label: textblock.title,
            withText: true,
          }),
        });
      });

      const listDropdown = createDropdown(locale);
      listDropdown.buttonView.set({
        label: t("Templates"),
        withText: true,
      });
      addListToDropdown(listDropdown, collection);

      listDropdown.on("execute", (eventInfo) => {
        const { id, label, textblock } = eventInfo.source;
        const currentPosition =
          editor.model.document.selection.getFirstPosition();

        editor.model.change((writer) => {
          var viewFragment = editor.data.processor.toView(textblock);
          var modelFragment = editor.data.toModel(viewFragment);

          editor.model.insertContent(modelFragment, currentPosition);
        });
      });
      return listDropdown;
    });
  }
}
