import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import GlobalActions from "@/services/GlobalActions";
import moment from "moment";
import { useEffect, useState } from "react";
import StructLoader from "../../../../../components/StructLoader/StructLoader";
import InfiniteTableFilter, {
  FilterComponentInitialState,
} from "../../../../../configurable/data/FilterComponent/InfiniteTableFilter";
import VirtualizedTableExportButton from "../../../../../configurable/data/VirtualizedTable/VirtualizedTableExportButton";
import TablePage from "../../../../../configurable/layouts/TablePageLayout/TablePage";
import DebugDataComponent from "../../../../../debug/DebugDataComponent";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { Contact } from "../../../../../model/db/Contact";
import BFStatus from "../../../../../modules/abstract-ui/data/status/BFStatus";
import { renderCellValue } from "../../../../../modules/abstract-ui/data/table/TableUtils";
import { ColumnConfig } from "../../../../../modules/abstract-ui/data/virtualized-table/BFVirtualizedTable";
import BFCheckbox from "../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import CurrencyLabel from "../../../../../modules/abstract-ui/forms/input/CurrencyLabel";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import { ActivitiesLabel } from "../../../../../modules/activity-components/ActivityLabel/ActivityLabel";
import CommentCountBubble from "../../../../../modules/comments-module/CommentCountBubble";
import ContactLabel from "../../../../../modules/contacts-module/ContactLabel";
import { getDefaultCurrencyNumberFormat } from "../../../../../modules/export/export.model";
import InvoiceStruct from "../../../../../redux/actions/struct/implemented/InvoiceStruct";
import UnitStruct from "../../../../../redux/actions/struct/implemented/UnitStruct";
import {
  useAggregationStatisticQuery,
  useConstants,
  useTypedSelector,
} from "../../../../../redux/hooks";
import { AggregationStatisticQuerySelector } from "../../../../../redux/model";
import DataBusDefaults from "../../../../../services/DataBusDefaults";
import { MatchQuery } from "../../../../../services/DataService";
import LanguageService from "../../../../../services/LanguageService";
import PermissionService from "../../../../../services/PermissionService";
import { useRestrictionsMatchQuery } from "../../../../../services/RestrictionService";
import { valueOrDefault, when } from "../../../../../utils/Helpers";
import MQ from "../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../utils/StringUtils";
import Tools from "../../../../../utils/Tools";
import {
  InvoiceDirection,
  InvoiceStatus,
  RAConstants,
  RAIncomingInvoice,
  RAInvoice,
  RAOutgoingInvoice,
} from "../../RAInterfaces";
import {
  RA_INVOICE_LIST_TABLE_IDENTIFIER,
  RA_INVOICE_MAIN_FIELDS,
  getInvoiceStatusLabel,
  getPaymentTypeLabel,
} from "../../RAUtils";
import UrgentIndicator from "../UrgentIndidactor/UrgentIndicator";
import InvoicePaymentPlan from "./InvoicePaymentPlan";
import "./RAInvoicesList.scss";
import { RAInvoicesListFilterOptions } from "./RAInvoicesListFilterOptions";

const STATIC_SELECTORS: AggregationStatisticQuerySelector[] = [
  {
    name: "general",
    op: [
      { key: "count", op: "count" },
      {
        key: "amountToPay",
        op: "sum",
        field: "data.invoice.value.converted.amount",
      },
      {
        key: "outstandingPayment",
        op: "sum",
        field: "data.payment.outstandingPayment",
      },
      {
        key: "payedAmount",
        op: "sum",
        field: "data.payment.payedAmount",
      },
      {
        key: "outstandingToPayed",
        op: "sum",
        field: "data.payment.outstandingToPayed",
      },
    ],
    query: {},
  },
  {
    name: "booked",
    op: [{ key: "count", op: "count" }],
    query: {
      matchQuery: {
        type: "op",
        op: "eq",
        name: "data.booked",
        value: true,
      },
    },
  },
  {
    name: "payed",
    op: [{ key: "count", op: "count" }],
    query: {
      matchQuery: {
        type: "op",
        op: "eq",
        name: "data.payed",
        value: true,
      },
    },
  },
  {
    name: "isChecked",
    op: [{ key: "count", op: "count" }],
    query: {
      matchQuery: {
        type: "op",
        op: "eq",
        name: "data.payment.isChecked",
        value: true,
      },
    },
  },
];

const generateAggregationFilter = (
  textQuery: string,
  matchQueries: MatchQuery[]
) => {
  return {
    textQuery,
    matchQuery:
      matchQueries.length === 0
        ? null
        : matchQueries.length === 1
        ? matchQueries[0]
        : ({ type: "and", query: matchQueries } as MatchQuery),
  };
};

const RAInvoicesList = (props: { direction: InvoiceDirection }) => {
  const restrictionMatchQuery = useRestrictionsMatchQuery({
    useLQObjects: true,
  });
  const [initialState, setInitialState] =
    useState<FilterComponentInitialState>(undefined);
  const tableCache = useTypedSelector(
    (e) =>
      e.application.infiniteTables[
        RA_INVOICE_LIST_TABLE_IDENTIFIER + "_" + props.direction
      ]
  );
  const [aggregationFilter, setAggregationFilter] = useState<{
    textQuery?: string;
    matchQuery: MatchQuery;
  }>(
    generateAggregationFilter(
      tableCache?.searchTerm,
      tableCache?.filterStatus?.map((e) => e.matchQuery).filter((e) => !!e) ||
        []
    )
  );
  useEffect(() => {
    const params = Tools.queryParams(window.location.search);
    const filter = params.get("filter");
    const search = params.get("search");
    if (filter || search) {
      const initialData: FilterComponentInitialState = {
        search,
        filter: filter ? JSON.parse(filter) : null,
      };
      setInitialState(initialData);
    }
  }, []);
  const constants = useConstants<RAConstants>();
  const aggregated = useAggregationStatisticQuery(
    "invoice",
    MQ.and(
      MQ.eq("data.direction", props.direction),
      restrictionMatchQuery,
      MQ.in(
        "data.type",
        PermissionService.hasBusinessUnitRole(
          props.direction === "INCOMING"
            ? "inv_list_incoming"
            : "inv_list_outgoing"
        )
      ),
      aggregationFilter.matchQuery
    ),
    STATIC_SELECTORS,
    undefined,
    aggregationFilter.textQuery,
    undefined
  );
  useEffect(() => {
    setAggregationFilter(
      generateAggregationFilter(
        tableCache?.searchTerm,
        tableCache?.filterStatus?.map((e) => e.matchQuery).filter((e) => !!e) ||
          []
      )
    );
  }, [tableCache?.searchTerm, tableCache?.filterStatus]);

  return (
    <TablePage
      layout={{
        className: "ra-incoming-invoices-list",
        pageHeader: (
          <div className={`ra-invoices-list-header`}>
            <InfiniteTableFilter
              initialState={initialState}
              identifier={
                RA_INVOICE_LIST_TABLE_IDENTIFIER + "_" + props.direction
              }
              filterOptions={RAInvoicesListFilterOptions(
                props.direction,
                PermissionService.hasBusinessUnitRole(
                  props.direction === "INCOMING"
                    ? "inv_list_incoming"
                    : "inv_list_outgoing"
                )
              )}
            />

            <div className={`fill`} />
            <VirtualizedTableExportButton
              identifier={
                RA_INVOICE_LIST_TABLE_IDENTIFIER + "_" + props.direction
              }
              appearance="default"
            />
          </div>
        ),
      }}
      table={{
        selection: "multiple-checkbox",
        selectionAggregations: [
          {
            type: "number",
            label: i18n.t("ra:Invoice.sumOfSelection", "Anzahl"),
            selector: () => 1,
            aggregationType: "sum",
            format: "number",
          },
          {
            type: "number",
            label: i18n.t("ra:Invoice.sumOfAmount", "Summe Rechnungsbetrag"),
            selector: "data.invoice.value.converted.amount",
            aggregationType: "sum",
            format: "currency",
          },
          {
            type: "date",
            label: i18n.t("ra:Invoice.rangeOfDate", "Rechnungsdatum"),
            selector: "data.invoice.documentDate",
            aggregationType: "range",
            format: "date",
          },
        ],
        reloadOnMount: true,
        identifier: RA_INVOICE_LIST_TABLE_IDENTIFIER + "_" + props.direction,
        dataUrl: "/api/asset/list/invoice",
        asPost: true,
        expandKeys: ["data.invoice.contact"],
        hover: true,
        onRowDoubleClick: (node: RAIncomingInvoice, index) => {
          GlobalActions.openDetails(
            AssetTypes.Invoice,
            node._id,
            node.data.type
          );
        },
        rowClass: (data: RAInvoice) =>
          data.data?.urgent?.isUrgent ? "urgent-row" : "",

        additionalMatchQuery: MQ.and(
          MQ.eq("data.direction", props.direction),
          MQ.in(
            "data.type",
            PermissionService.hasBusinessUnitRole("inv_list_incoming")
          ),
          restrictionMatchQuery
        ),
        params: {
          aggregated,
        },
        exportOptions: {
          filename: `invoices_${moment().format("YYYY-MM-DD")}`,
        },
        columns: {
          ...getIncomingInvoiceColumns(props.direction),
          actions: {
            label: "",
            fixedWidth: 70,
            fixed: "right",
            render: (node: RAIncomingInvoice, index, params) => (
              <>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() =>
                    DataBusDefaults.route({
                      route: `__details/${node._id}`,
                      append: true,
                    })
                  }
                >
                  {i18n.t("Global.Buttons.details")}
                </BFButton>
              </>
            ),
          },
        },
      }}
    />
  );
};
export default RAInvoicesList;

export const getIncomingInvoiceColumns = (direction: InvoiceDirection) =>
  ({
    id: {
      label: "ID",
      sortable: true,
      flexWidth: 70,
      resizable: true,
      render: (node, index, params) => (
        <>
          <DebugDataComponent data={node} />
          <UrgentIndicator
            urgent={node.data.urgent}
            title={i18n.t("ra:Invoice.Indicator.Urgent", "Dringende Rechnung")}
          />
          {renderCellValue(node.id)}
        </>
      ),
      renderFooter: (params) =>
        params.aggregated
          ? renderCellValue(
              `${i18n.t("ra:List.count", "Anz.")}: ${
                params?.aggregated?.data?.["general"]?.count || "-"
              }`
            )
          : undefined,
      export: {
        width: 30,
        label: i18n.t("ra:List.id"),
        type: "number",
        selector: (node: RAIncomingInvoice) => node.id,
      },
    },
    "data.type": {
      label: i18n.t("Base.Unit"),
      sortable: true,
      flexWidth: 120,
      resizableOptions: {
        min: 40,
        max: 200,
      },
      resizable: true,
      render: (node: RAIncomingInvoice, index, params) => (
        <StructLoader
          structType="unit"
          unitType={node.data.type}
          render={() => {
            return renderCellValue(node?.data?.type, "-", (value: string) =>
              UnitStruct.getUnitLabel(value)
            );
          }}
        />
      ),
      export: {
        width: 30,
        label: i18n.t("Base.Unit"),
        type: "string",
        selector: (node: RAIncomingInvoice) =>
          valueOrDefault(
            LanguageService.translateLabel(
              UnitStruct.getUnit(node.data.type)?.data?.label
            ),
            "-"
          ),
      },
    },
    "data.entity": {
      label: i18n.t("Base.Entity"),
      // sortKey: "",
      flexWidth: 200,
      resizable: true,
      render: (node: RAIncomingInvoice, index, params) => (
        <StructLoader
          structType="orga"
          unitType={node.data.type}
          render={() => {
            return renderCellValue(
              node?.data?.entity,
              "-",
              (value: string) => OrgaStruct.getEntity(value)?.displayName
            );
          }}
        />
      ),
      export: {
        width: 30,
        label: i18n.t("Base.Entity"),
        type: "string",
        selector: (node: RAIncomingInvoice) =>
          valueOrDefault(
            OrgaStruct.getEntity(node.data.entity)?.displayName,
            "-"
          ),
      },
    },
    "data.objectId": {
      label: i18n.t("Base.Object"),
      flexWidth: 200,
      resizable: true,
      render: (node: RAIncomingInvoice, index, params) => (
        <StructLoader
          structType="orga"
          unitType={node.data.type}
          render={() => {
            return renderCellValue(
              node?.data?.objectId,
              "-",
              (value: string) =>
                `${OrgaStruct.getObject(value)?.id} - ${
                  OrgaStruct.getObject(value)?.displayName
                }`
            );
          }}
        />
      ),
      export: {
        width: 30,
        label: i18n.t("Base.Object"),
        type: "string",
        selector: (node: RAIncomingInvoice) =>
          OrgaStruct.getObject(node.data.objectId)
            ? OrgaStruct.getObject(node.data.objectId)?.id +
              "-" +
              OrgaStruct.getObject(node.data.objectId)?.displayName
            : "-",
      },
    },
    "data.invoice.activity": {
      label: i18n.t("Base.Assignment", "Zuordnung"),
      flexWidth: 260,
      resizable: true,
      sortable: false,
      className: "activity-label-container",
      render: (node: RAIncomingInvoice, index, params) =>
        node.data.activity ? (
          <ActivitiesLabel withType activities={node.data.activity} />
        ) : null,
    },
    "data.invoice.invoiceType": {
      label: RA_INVOICE_MAIN_FIELDS().invoiceType.label,
      flexWidth: 200,
      sortable: true,
      resizable: true,
      render: (node: RAIncomingInvoice, index, params) => (
        <StructLoader
          structType="invoice"
          unitType={node.data.type}
          render={() => (
            <>
              {renderCellValue(
                node?.data?.invoice.invoiceType,
                "-",
                (value: string) =>
                  LanguageService.translateLabel(
                    InvoiceStruct.getInvoiceType(value)?.data.displayName
                  )
              )}
            </>
          )}
        />
      ),

      export: {
        width: 30,
        label: RA_INVOICE_MAIN_FIELDS().invoiceType.label,
        type: "string",
        selector: (node: RAIncomingInvoice) =>
          valueOrDefault(
            LanguageService.translateLabel(
              InvoiceStruct.getInvoiceType(node.data?.invoice?.invoiceType)
                ?.data.displayName
            ),
            "-"
          ),
      },
    },
    "data.status": {
      label: i18n.t("Global.Labels.Status"),
      sortable: true,
      flexWidth: 130,
      resizable: true,
      render: (node: RAIncomingInvoice, index, params) =>
        renderCellValue(
          node?.data?.status,
          "-",
          (value: InvoiceStatus) => getInvoiceStatusLabel(value)
          //fixme add label
          // (value: number) => StringUtils.formatCurrency(value)
        ),

      export: {
        width: 30,
        label: i18n.t("Global.Labels.Status"),
        type: "string",
        selector: (node: RAIncomingInvoice) =>
          valueOrDefault(getInvoiceStatusLabel(node?.data?.status), "-"),
      },
    },
    "data.tags": {
      label: i18n.t("Global.Labels.Tags", "Tags"),
      sortable: false,
      flexWidth: 160,
      resizable: true,
      render: (node: RAIncomingInvoice, index, params) => (
        <StructLoader
          unitType={node.data.type}
          structType="unit"
          render={() => {
            const tags = UnitStruct.getTagConfig(
              node.data.type,
              AssetTypes.Invoice
            )?.map((e) => ({
              value: e.id,
              label: LanguageService.translateLabel(e.displayName),
              disabled: e.status === "archived",
            }));

            if (tags?.length > 0) {
              const usedTags = node.data?.tags
                ?.map((e) => tags.find((t) => t.value === e))
                .filter((e) => !!e);
              return (
                <div className={`tags-list`}>
                  {(usedTags || []).map((tag) => (
                    <BFStatus label={tag.label} key={tag.value} size="xs" />
                  ))}
                </div>
              );
            } else {
              return null;
            }
          }}
        />
      ),
      export: {
        width: 30,
        label: i18n.t("Global.Labels.Tags", "Tags"),
        type: "string",
        selector: (node: RAIncomingInvoice) => {
          const tags = UnitStruct.getTagConfig(
            node.data.type,
            AssetTypes.Invoice
          )?.map((e) => ({
            value: e.id,
            label: LanguageService.translateLabel(e.displayName),
            disabled: e.status === "archived",
          }));

          const usedTags = node.data?.tags
            ?.map((e) => tags.find((t) => t.value === e))
            .filter((e) => !!e);
          return usedTags?.map((e) => e.label).join(", ") || "";
        },
      },
    },
    "data.booked": {
      label: i18n.t("ra:List.booked"),
      sortable: true,
      alignment: "center",
      resizable: true,
      flexWidth: 100,
      render: (node: RAIncomingInvoice, index, params) => (
        <div className="checkbox-wrapper">
          <BFCheckbox readOnly checked={node.data.booked} />
        </div>
      ),
      renderFooter: (params) =>
        params.aggregated
          ? renderCellValue(
              `${params?.aggregated?.data?.["booked"]?.count || "0"} / ${
                params?.aggregated?.data?.["general"]?.count || "0"
              }`
            )
          : undefined,

      export: {
        width: 30,
        label: i18n.t("ra:List.booked"),
        type: "string",
        selector: (node: RAIncomingInvoice) =>
          node.data.booked ? "TRUE" : "FALSE",
      },
    },
    ...when(
      direction === "INCOMING",
      {
        "data.invoice.paymentData.isChecked": {
          label: i18n.t("ra:List.isChecked"),
          sortable: true,
          alignment: "center",
          resizable: true,
          flexWidth: 100,
          render: (node: RAIncomingInvoice, index, params) => (
            <div className="checkbox-wrapper">
              {/* Todo invoice - change is Checked behaviour */}
              <BFCheckbox readOnly checked={node.data.payment?.isChecked} />
            </div>
          ),
          renderFooter: (params) =>
            params.aggregated
              ? renderCellValue(
                  `${params?.aggregated?.data?.["isChecked"]?.count || "0"} / ${
                    params?.aggregated?.data?.["general"]?.count || "0"
                  }`
                )
              : undefined,
          export: {
            width: 30,
            label: i18n.t("ra:List.isChecked"),
            type: "string",
            selector: (node: RAIncomingInvoice) =>
              node.data?.payment?.isChecked ? "TRUE" : "FALSE",
          },
        },
      },
      {}
    ),
    "data.payed": {
      label: i18n.t("ra:List.payed"),
      sortable: true,
      alignment: "center",
      resizable: true,
      flexWidth: 100,
      render: (node: RAIncomingInvoice, index, params) => (
        <div className="checkbox-wrapper">
          <BFCheckbox readOnly checked={node.data.payed} />
        </div>
      ),
      renderFooter: (params) =>
        params.aggregated
          ? renderCellValue(
              `${params?.aggregated?.data?.["payed"]?.count || "0"} / ${
                params?.aggregated?.data?.["general"]?.count || "0"
              }`
            )
          : undefined,
      export: {
        width: 30,
        label: i18n.t("ra:List.payed"),
        type: "string",
        selector: (node: RAIncomingInvoice) =>
          node.data?.payed ? "TRUE" : "FALSE",
      },
    },
    "data.invoice.value.converted.amount": {
      label: RA_INVOICE_MAIN_FIELDS().amountToPay.label,
      alignment: "right",
      sortable: true,
      flexWidth: 220,
      resizable: true,
      render: (node: RAIncomingInvoice, index, params) => (
        <div className={`amount-info`}>
          <InvoicePaymentPlan asset={node} />

          <CurrencyLabel value={node.data.invoice.value} />
        </div>
      ),
      renderFooter: (params) =>
        params.aggregated
          ? renderCellValue(
              params?.aggregated?.data?.["general"]?.amountToPay,
              "-",
              (value: number) => StringUtils.formatCurrency(value)
            )
          : undefined,
      export: (node: RAIncomingInvoice) => ({
        width: 30,
        label: RA_INVOICE_MAIN_FIELDS().amountToPay.label,
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        //TODO fix currency to specitifc one - we need to add possibility to set specific currency for each invoice
        selector: (node: RAIncomingInvoice) =>
          node.data.invoice.value.converted.amount,
      }),
    },
    ...when(
      direction === "OUTGOING",
      {
        "data.payment.payedAmount": {
          label: i18n.t("ra:List.Outgoing.payedAmount", "Bezahlter Betrag"),
          alignment: "right",
          sortable: true,
          flexWidth: 130,
          resizable: true,
          render: (node: RAOutgoingInvoice, index, params) =>
            renderCellValue(
              node.data.payment.payedAmount,
              "-",
              (value: number) => StringUtils.formatCurrency(value)
            ),
          renderFooter: (params) =>
            params.aggregated
              ? renderCellValue(
                  params?.aggregated?.data?.["general"]?.payedAmount,
                  "-",
                  (value: number) => StringUtils.formatCurrency(value)
                )
              : undefined,
          export: (node: RAOutgoingInvoice) => ({
            width: 30,
            label: i18n.t("ra:List.Outgoing.payedAmount", "Bezahlter Betrag"),
            type: "number",
            totalFunction: "sum",
            style: {
              numFmt: getDefaultCurrencyNumberFormat(),
            },
            //TODO fix currency to specitifc one - we need to add possibility to set specific currency for each invoice
            selector: (node: RAOutgoingInvoice) =>
              node.data.payment.payedAmount,
          }),
        },
        "data.payment.outstandingToPayed": {
          label: i18n.t(
            "ra:List.Outgoing.outstandingToPayed",
            "Offene Forderung"
          ),
          alignment: "right",
          sortable: true,
          flexWidth: 130,
          resizable: true,
          render: (node: RAOutgoingInvoice, index, params) =>
            renderCellValue(
              node.data.payment.outstandingToPayed,
              "-",
              (value: number) => StringUtils.formatCurrency(value)
            ),
          renderFooter: (params) =>
            params.aggregated
              ? renderCellValue(
                  params?.aggregated?.data?.["general"]?.outstandingToPayed,
                  "-",
                  (value: number) => StringUtils.formatCurrency(value)
                )
              : undefined,
          export: (node: RAOutgoingInvoice) => ({
            width: 30,
            label: i18n.t(
              "ra:List.Outgoing.outstandingToPayed",
              "Offene Forderung"
            ),
            type: "number",
            totalFunction: "sum",
            style: {
              numFmt: getDefaultCurrencyNumberFormat(),
            },
            //TODO fix currency to specitifc one - we need to add possibility to set specific currency for each invoice
            selector: (node: RAOutgoingInvoice) =>
              node.data.payment.outstandingToPayed,
          }),
        },
        "data.payment.outstandingPayment": {
          label: i18n.t(
            "ra:List.Outgoing.outstandingPayment",
            "Fällige Forderung"
          ),
          alignment: "right",
          sortable: true,
          flexWidth: 130,
          resizable: true,
          render: (node: RAOutgoingInvoice, index, params) =>
            renderCellValue(
              node.data.payment.outstandingPayment,
              "-",
              (value: number) => StringUtils.formatCurrency(value)
            ),
          renderFooter: (params) =>
            params.aggregated
              ? renderCellValue(
                  params?.aggregated?.data?.["general"]?.outstandingPayment,
                  "-",
                  (value: number) => StringUtils.formatCurrency(value)
                )
              : undefined,
          export: (node: RAOutgoingInvoice) => ({
            width: 30,
            label: i18n.t(
              "ra:List.Outgoing.outstandingPayment",
              "Fällige Forderung"
            ),
            type: "number",
            totalFunction: "sum",
            style: {
              numFmt: getDefaultCurrencyNumberFormat(),
            },
            //TODO fix currency to specitifc one - we need to add possibility to set specific currency for each invoice
            selector: (node: RAOutgoingInvoice) =>
              node.data.payment.outstandingPayment,
          }),
        },
      },
      {}
    ),
    "expand.contact.data.displayName": {
      label: when(
        direction === "INCOMING",
        RA_INVOICE_MAIN_FIELDS().contact.label,
        i18n.t("ra:List.invoiceReiver", "Rechnungsempfänger")
      ),
      sortable: true,
      flexWidth: 200,
      resizable: true,
      render: (node: RAIncomingInvoice, index, params) =>
        node.expand?.contact ? (
          <ContactLabel contact={node.expand?.contact as Contact} />
        ) : (
          "-"
        ),
      export: {
        width: 30,
        label: RA_INVOICE_MAIN_FIELDS().contact.label,
        type: "string",
        selector: (node: RAIncomingInvoice) =>
          (node.expand?.contact as Contact)?.data?.displayName,
      },
    },
    ...when(
      direction === "INCOMING",
      {
        "data.invoice.paymentType": {
          label: RA_INVOICE_MAIN_FIELDS().paymentType.label,
          flexWidth: 200,
          sortable: true,
          resizable: true,
          render: (node: RAIncomingInvoice, index, params) =>
            renderCellValue(
              node?.data?.invoice?.paymentType,
              "-",
              (value: string) => getPaymentTypeLabel(value)
            ),
          export: {
            width: 30,
            label: RA_INVOICE_MAIN_FIELDS().paymentType.label,
            type: "string",
            selector: (node: RAIncomingInvoice) =>
              valueOrDefault(
                getPaymentTypeLabel(node?.data?.invoice?.paymentType),
                "-"
              ),
          },
        },

        "data.invoice.paymentTypeData.usage": {
          label: RA_INVOICE_MAIN_FIELDS().usage.label,
          sortable: true,
          resizable: true,
          flexWidth: 220,
          render: (node: RAIncomingInvoice, index, params) =>
            renderCellValue(
              (node?.data?.invoice?.paymentTypeData as any)?.usage,
              "-"
            ),
          export: {
            width: 30,
            label: RA_INVOICE_MAIN_FIELDS().usage.label,
            type: "string",
            selector: (node: RAIncomingInvoice) =>
              valueOrDefault(
                (node?.data?.invoice?.paymentTypeData as any)?.usage,
                "-"
              ),
          },
        },
      },
      {}
    ),
    "data.invoice.invoiceId": {
      label: RA_INVOICE_MAIN_FIELDS().invoiceId.label,
      sortable: true,
      resizable: true,
      flexWidth: 220,
      render: (node: RAIncomingInvoice, index, params) =>
        renderCellValue(node?.data?.invoice?.invoiceId, "-"),

      export: {
        width: 30,
        label: RA_INVOICE_MAIN_FIELDS().invoiceId.label,
        type: "string",
        selector: (node: RAIncomingInvoice) =>
          valueOrDefault(node?.data?.invoice?.invoiceId, "-"),
      },
    },
    "data.invoice.documentDate": {
      label: RA_INVOICE_MAIN_FIELDS().documentDate.label,
      sortable: true,
      resizable: true,
      flexWidth: 130,
      render: (node: RAIncomingInvoice, index, params) =>
        renderCellValue(
          node?.data?.invoice?.documentDate,
          "-",
          (value: string) =>
            moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
        ),

      export: {
        width: 30,
        label: RA_INVOICE_MAIN_FIELDS().documentDate.label,
        type: "date",
        selector: (node: RAIncomingInvoice) =>
          node?.data?.invoice?.documentDate
            ? new Date(node?.data?.invoice?.documentDate)
            : node?.data?.invoice?.documentDate,
      },
    },
    ...when(
      direction === "INCOMING",
      {
        "data.invoice.paymentData.paymentDueDate": {
          label: RA_INVOICE_MAIN_FIELDS().paymentDueDate.label,
          flexWidth: 120,
          resizable: true,
          sortable: true,
          render: (node: RAIncomingInvoice, index, params) =>
            renderCellValue(
              (node?.data?.invoice?.paymentTypeData as any)?.paymentDueDate,
              "-",
              (value: string) =>
                moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
            ),
          export: {
            width: 30,
            label: RA_INVOICE_MAIN_FIELDS().paymentDueDate.label,
            type: "date",
            selector: (node: RAIncomingInvoice) =>
              (node?.data?.invoice?.paymentTypeData as any).paymentDueDate
                ? new Date(
                    (node?.data?.invoice?.paymentTypeData as any).paymentDueDate
                  )
                : (node?.data?.invoice?.paymentTypeData as any).paymentDueDate,
          },
        },
      },
      {
        "data.invoice.dueDate": {
          label: RA_INVOICE_MAIN_FIELDS().paymentDueDate.label,
          flexWidth: 120,
          resizable: true,
          sortable: true,
          render: (node: RAOutgoingInvoice, index, params) =>
            renderCellValue(
              node?.data?.invoice?.dueDate,
              "-",
              (value: string) =>
                moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
            ),
          export: {
            width: 30,
            label: RA_INVOICE_MAIN_FIELDS().paymentDueDate.label,
            type: "date",
            selector: (node: RAOutgoingInvoice) =>
              node?.data?.invoice.dueDate
                ? new Date(node?.data?.invoice.dueDate)
                : node?.data?.invoice.dueDate,
          },
        },
      }
    ),
    "data.bookingDate": {
      label: i18n.t("ra:List.bookingDate", "Gebucht am"),
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RAIncomingInvoice, index, params) =>
        renderCellValue(node?.data?.bookingDate, "-", (value: string) =>
          moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
        ),
      export: {
        width: 30,
        label: i18n.t("ra:List.bookingDate", "Gebucht am"),
        type: "date",
        selector: (node: RAIncomingInvoice) =>
          node?.data?.bookingDate
            ? new Date(node?.data?.bookingDate)
            : node?.data?.bookingDate,
      },
    },
    "data.paymentDate": {
      label: i18n.t("ra:List.payedDate", "Bezahlt am"),
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RAIncomingInvoice, index, params) =>
        renderCellValue(node?.data?.paymentDate, "-", (value: string) =>
          moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
        ),
      export: {
        width: 30,
        label: i18n.t("ra:List.payedDate", "Bezahlt am"),
        type: "date",
        selector: (node: RAIncomingInvoice) =>
          node?.data?.paymentDate
            ? new Date(node?.data?.paymentDate)
            : node?.data?.paymentDate,
      },
    },
    "communication.numComments": {
      label: i18n.t("ra:List.comments", "Kommentare"),
      flexWidth: 80,
      sortable: true,
      resizable: true,
      render: (node: RAIncomingInvoice, index, params) => (
        <CommentCountBubble
          assetId={node._id}
          assetType={AssetTypes.Invoice}
          type={node.data.type}
          numComments={node.communication?.numComments}
        />
      ),
    },
  } as { [columnId: string]: ColumnConfig });
