import React, { Component } from "react";
import "./HorizontalLoader.scss";

type Props = {
  size: "xs" | "md" | "lg" | "xl";
  color?: string;
};

type States = {};

class HorizontalLoader extends Component<Props, States> {
  static defaultProps = {
    color: "rgba(255, 255, 255, 0.7)"
  };
  readonly state: States = {};

  render() {
    return (
      <div className={`horizontal-loader size-${this.props.size}`}>
        <div className="bounce1" style={{ background: this.props.color }}></div>
        <div className="bounce2" style={{ background: this.props.color }}></div>
        <div className="bounce3" style={{ background: this.props.color }}></div>
      </div>
    );
  }
}

export default HorizontalLoader;
