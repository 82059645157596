import React from "react";
// import "./FormWizard.scss";

export type FormWizardEntry = {
  render: (
    getState: () => {
      current: any;
      previous: any[];
    },
    finish: (result: any) => Promise<void>,
    goBack: () => void,
    saveValue?: (index: number, value: any) => void
  ) => React.ReactElement;
};
interface FormWizardProps {
  entries: FormWizardEntry[];
  initialValues?: any[];
  onSubmit: (
    results: any[],
    setFormResults?: (value: any) => void
  ) => Promise<void>;
}
const FormWizard = (props: FormWizardProps) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [formResults, setFormResults] = React.useState(
    props.initialValues || props.entries.map(() => ({}))
  );

  const currentEntry = props.entries[currentIndex];

  return currentEntry.render(
    () => ({
      current: formResults[currentIndex],
      previous: formResults.slice(0, currentIndex),
    }),
    async (result) => {
      const formResultToSet = [
        ...formResults.slice(0, currentIndex),
        result,
        ...formResults.slice(currentIndex + 1),
      ];
      setFormResults(formResultToSet);
      if (currentIndex < props.entries.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        await props.onSubmit(formResultToSet, setFormResults);
      }
    },
    () => {
      if (currentIndex === 0) {
        setFormResults(props.entries.map(() => ({})));
      } else {
        setCurrentIndex(currentIndex - 1);
      }
    },
    (index, value) => {
      const formResultToSet = [
        ...formResults.slice(0, index),
        value,
        ...formResults.slice(index + 1),
      ];
      setFormResults(formResultToSet);
    }
  );
};

export default FormWizard;
