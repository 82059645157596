import Log from "@/debug/Log";
import i18n from "@/i18n";
import DataBusDefaults from "@/services/DataBusDefaults";
import { CSSProperties } from "react";
import {
  Accept,
  DropEvent,
  FileError,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import BfIcon from "../icon/BfIcon";
import "./BFDropzone.scss";

interface Props {
  uploadText?: string;
  className?: string;
  style?: CSSProperties;
  accept?: Accept;
  minSize?: number;
  maxSize?: number;
  maxFiles?: number;
  disabled?: boolean;
  multipe?: boolean;
  validator?: (file: File) => FileError | Array<FileError> | null;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  render: (
    openUploadDialog: () => void,
    isDragActive: boolean
  ) => React.ReactNode;
  acceptText?: string;
  rejectText?: string;
  overlayIconSize?: "xs" | "sm" | "lg" | "xl" | "2x" | "3x" | "4x" | "5x";
  suppressDragOverlay?: boolean;
}
const BFDropzone = (props: Props) => {
  const onDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    Log.info("BFDropzone.onDrop", acceptedFiles, fileRejections);
    if (fileRejections.length > 0) {
      DataBusDefaults.toast({
        type: "error",
        text: i18n.t(
          "dropzone.rejectedFiles",
          "{{count}} Dateien konnten nicht hochgeladen werden!",
          { count: fileRejections.length }
        ),
      });
    }
    props.onDrop(acceptedFiles, fileRejections, event);
  };

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    validator: props.validator,
    noClick: true,
    accept: props.accept,
    onDrop: onDrop,
    maxFiles: props.maxFiles || undefined,
    maxSize: props.maxSize,
    minSize: props.minSize,
    noKeyboard: true,
    disabled: props.disabled,
    multiple: props.multipe,
  });

  return (
    <div
      className={`bf-dropzone ${props.className || ""}`}
      {...getRootProps()}
      style={props.style}
    >
      <input {...getInputProps()} />
      <div className="content-wrapper">
        {props.render(open, isDragActive)}

        <div
          className={`overlay-drop-indicator ${isDragActive ? "active" : ""} ${
            isDragAccept ? "accept" : ""
          } ${props.suppressDragOverlay ? "suppress-drag" : ""}`}
        >
          <div className="border">
            {isDragActive && (
              <div className="drag-info accept">
                <BfIcon
                  type="light"
                  data="upload-bottom"
                  size={props.overlayIconSize || "lg"}
                />
                <div className={`text`}>
                  {props.acceptText || "Dateien hochladen"}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BFDropzone;
