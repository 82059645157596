import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import KPIDetailCard from "../../../../../../../../components/KPIDetailCard/KPIDetailCard";
import i18n from "../../../../../../../../i18n";
import {
  useAggregationTableQuery,
  useAssetCache,
  useConstants,
} from "../../../../../../../../redux/hooks";
import DataBus from "../../../../../../../../services/DataBus";
import { MatchQuery } from "../../../../../../../../services/DataService";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import { RENTAL_AGREEMENT_STATIC_SELECTORS } from "../../../../tenants/components/rental-agreements/CBRentalAgreementsList";
import CBRentalUnitsOverviewTable from "../../../../tenants/components/stacking-plan/CBRentalUnitsOverviewTable";
import { CBPortfolioImmoConfig } from "../../../interfaces/CBPortfolioAsset";
import "./CBRenterListCard.scss";

type DetailFilter = null | "business" | "private" | "parking";
const TABLE_IDENT = `cb-renter-list-table`;

const generateRenterListMatchQuery = (
  typeFilter: string,
  // vacantFilter: "all" | "vacant" | "nonVacant",
  config: CBPortfolioImmoConfig,
  objectIds?: string[]
) => {
  return {
    type: "and",
    query: [
      { type: "op", name: "data.objectId", op: "in", value: objectIds || [] },
      // ...(vacantFilter !== "all"
      //   ? [
      //       {
      //         type: "op",
      //         name: "data.tags",
      //         op: vacantFilter === "vacant" ? "eq" : "ne",
      //         value: "vacant",
      //       },
      //     ]
      //   : []),
      {
        type: "op",
        name: "data.moveIn",
        op: "lte",
        value: moment().startOf("day").toISOString(),
      },
      {
        type: "or",

        query: [
          {
            type: "op",
            name: "data.moveOut",
            op: "gte",
            value: moment().endOf("day").toISOString(),
          },
          {
            type: "op",
            name: "data.moveOut",
            op: "in", // need this new operator
            value: [null],
          },
        ],
      },
      ...(typeFilter !== null
        ? [
            {
              type: "op",
              name: "data.type",
              op: "in",
              value: Object.entries(config.data.typeMappings)
                .filter(([type, filter]) => filter === typeFilter)
                .map(([type]) => type),
            },
          ]
        : []),
    ],
  } as MatchQuery;
};

interface RenterListCardProps {
  objectIds: string[];
  marginBottom?: number;
  referenceId?: string;
}
const CBRenterListCard = (props: RenterListCardProps) => {
  const [matchQuery, setMatchQuery] = useState<MatchQuery>();
  const aggregated = useAggregationTableQuery(
    TABLE_IDENT,
    RENTAL_AGREEMENT_STATIC_SELECTORS
  );
  const dispatch = useDispatch();
  const constants = useConstants();

  const configCache = useAssetCache<CBPortfolioImmoConfig>("portfolio-config", {
    type: "op",
    name: "data.type",
    op: "in",
    value: constants.currency === "USD" ? ["immo-us"] : ["immo"],
  });
  // const [vacantFilter, setVacantFilter] = useState<
  //   "all" | "vacant" | "nonVacant"
  // >("all");
  const [detailFilter, setDetailFilter] = useState<DetailFilter>(null);
  const loading = configCache.state === "loading";

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          aggregated.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });

  useEffect(() => {
    setMatchQuery(
      configCache.state === "cached"
        ? generateRenterListMatchQuery(
            detailFilter,
            // vacantFilter,
            configCache.data,
            props.objectIds
          )
        : undefined
    );
  }, [configCache.state, detailFilter]);
  // useEffect(() => {
  //   DataBus.emit(DataBusSubKeys.RELOAD, { identifiers: [TABLE_IDENT] });
  // }, [sort]);
  return (
    <KPIDetailCard
      marginBottom={props.marginBottom}
      className="cb-renter-list-card"
      title={i18n.t("cb:Renter.RentList", "Mieterliste")}
      error={configCache.error}
      onReload={configCache.reload}
      data={
        configCache.state === "cached"
          ? {
              query: matchQuery,
            }
          : undefined
      }
      loading={loading}
      // headRight={

      // }
      childOptions={{
        height: 700,
        render: (data) => (
          <div className="renter-list-table">
            <div className="table-wrapper">
              <CBRentalUnitsOverviewTable
                tableIdentifier="cb-renter-lsit-card-table"
                objectIds={props.objectIds}
              />
            </div>
          </div>
        ),
      }}
    />
  );
};
export default CBRenterListCard;
