import { AssetCell } from "@/modules/abstract-ui/data/table/TableUtils";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import { BFDetailsIconButton } from "@/modules/abstract-ui/general/Button/BFDetailsButton";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import classNames from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { Field } from "react-final-form";
import { Loader } from "rsuite";
import Collapse from "rsuite/esm/Animation/Collapse";
import DebugDataComponent from "../../../../../debug/DebugDataComponent";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import BFAssignment from "../../../../../modules/abstract-ui/forms/assignment/BFAssignment";
import BFInput from "../../../../../modules/abstract-ui/forms/input/BFInput";
import BFToggle from "../../../../../modules/abstract-ui/forms/toggle/BFToggle";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import { DefaultIcons } from "../../../../../modules/abstract-ui/icon/DefaultIcons";
import FollowButton from "../../../../../modules/comments-module/components/FollowButton";
import EZAssignment from "../../../../../modules/ez-form/form-elements/ez-assignment/EZAssignment";
import EZDate from "../../../../../modules/ez-form/form-elements/ez-date/EZDate";
import EZSelect from "../../../../../modules/ez-form/form-elements/ez-select/EZSelect";
import EZStatusSelect from "../../../../../modules/ez-form/form-elements/ez-status-select/EZStatusSelect";
import EZTags from "../../../../../modules/ez-form/form-elements/ez-tags/EZTags";
import EZTextfield from "../../../../../modules/ez-form/form-elements/ez-textfield/EZTextfield";
import EZField from "../../../../../modules/ez-form/form-group/ez-field/EZField";
import EZGroup from "../../../../../modules/ez-form/form-group/ez-form/EZGroup";
import ObjectRelatedSelect from "../../../../../modules/structure-components/object/ObjectRelatedSelect";
import CacheService from "../../../../../services/CacheService";
import DataBus from "../../../../../services/DataBus";
import LanguageService from "../../../../../services/LanguageService";
import PermissionService from "../../../../../services/PermissionService";
import { DataBusSubKeys } from "../../../../../utils/Constants";
import StringUtils from "../../../../../utils/StringUtils";
import { ActivityApplicationConstants } from "../../ActivityHooks";
import { APActivity } from "../../ActivityInterfaces";
import ActivityService from "../../ActivityService";
import { useActivityStruct } from "../../ActivityStructContext";
import {
  getActivityPriorityOptions,
  getAssetSelectOptionsByType,
} from "../../ActivityUtils";
import APBaseData from "./activity-data-forms/APBaseData";
import APAdvertisement from "./advertisement-feature/APAdvertisement";
import "./APActivityData.scss";
import APActivityContractFields from "./maintenance/APActivityContractFields";
import {
  APAdvertisementActivity,
  APContractActivity,
} from "./maintenance/APActivityMainteanceInterfaces";
import APRatingViewButton from "./rating/APRatingViewButton";

interface Props {
  activity: APActivity;
  showStatus?: boolean;
  additionalActions?: React.ReactNode;
  goBack?: () => void;
  collapsible?: boolean;
  constants: ActivityApplicationConstants;
}
const APActivityData = (props: Props) => {
  const activityStruct = useActivityStruct();
  const [collapsed, setCollapsed] = React.useState(false);
  useEffect(() => {
    setTimeout(() => {
      DataBus.emit(DataBusSubKeys.UPDATE_AUTOSIZE_TEXTAREA, {});
    });
  }, [collapsed]);
  if (!props.activity) {
    return (
      <div className={`__error`}>{i18n.t("errorCodes.unknownError.msg")}</div>
    );
  }
  const content = (
    <div className={`content`}>
      <DebugDataComponent data={props.activity} />

      <div className={`status-bar`}>
        <div className={`assignee`}>
          <EZField
            value={props.activity.data.assignee}
            url={`/${props.constants?.serviceUrl}/${props.activity._id}/setAssignee`}
            onSubmitSuccess={(value) =>
              CacheService.updateDataInCaches(value._id, value)
            }
          >
            {({ value, onChange, onBlur, loading, submitData }) => (
              <EZAssignment
                type="user"
                identifier="ap-assignee"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          </EZField>
        </div>
        {props.showStatus && (
          <div className={`status`}>
            <EZField
              value={{
                status: props.activity.data.status,
                subStatus: props.activity.data.subStatus || null,
              }}
              url={`/${props.constants?.serviceUrl}/${props.activity._id}/setStatus`}
              transformSubmitData={(value) => ({
                newStatus: value.status,
                newSubStatus: value.subStatus,
              })}
              onSubmitSuccess={(value) =>
                CacheService.updateDataInCaches(value._id, value)
              }
              shouldSubmit={(value) => {
                return (
                  value.newStatus !== props.activity.data.status ||
                  value.newSubStatus !== props.activity.data.subStatus
                );
              }}
            >
              {({ value, onChange, onBlur, loading, submitData }) => (
                <EZStatusSelect
                  data={activityStruct
                    .getStatusEntries(
                      props.activity.data.type,
                      props.activity.data.status
                    )
                    .filter((e) => e.status !== "archived")
                    .map((e) => ({
                      value: e.id,
                      label: LanguageService.translateLabel(e.displayName),
                      color: e.color,
                      icon: e.icon,
                      subStatus: e.subStatus
                        ?.filter((e) => e.status !== "archived")
                        .map((s) => ({
                          label: LanguageService.translateLabel(s.displayName),
                          value: s.id,
                        })),
                    }))}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    onBlur(null, value);
                  }}

                  //   value={value}
                  //   onChange={onChange}
                  //   onBlur={onBlur}
                />
                // <EZSelect
                //   dataType="tag"
                //   data={activityStruct.getStatusEntries(
                //     props.activity.data.type,
                //     props.activity.data.status
                //   ).map((e) => ({
                //     value: e.id,
                //     label: LanguageService.translateLabel(e.displayName),
                //     color: e.color,
                //     icon: e.icon,
                //   }))}
                //   value={value}
                //   onChange={onChange}
                //   onBlur={onBlur}
                // />
              )}
            </EZField>
          </div>
        )}
        <div className={`priority`}>
          <EZField
            value={props.activity.data.priority}
            url={`/${props.constants?.serviceUrl}/${props.activity._id}/setPriority`}
            onSubmitSuccess={(value) =>
              CacheService.updateDataInCaches(value._id, value)
            }
          >
            {({ value, onChange, onBlur, loading, submitData }) => (
              <EZSelect
                dataType="tag"
                data={getActivityPriorityOptions() as any[]}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          </EZField>
        </div>
        {!props.constants?.fields?.disableDueDate && (
          <div className={`due-date`}>
            <EZField
              value={props.activity.data.dueDate}
              url={`/${props.constants?.serviceUrl}/${props.activity._id}/setDueDate`}
              transformSubmitData={(value) => ({
                value: moment(value).utc(true).toISOString(),
              })}
              onSubmitSuccess={(value) =>
                CacheService.updateDataInCaches(value._id, value)
              }
            >
              {({ value, onChange, onBlur, loading, submitData }) => (
                <EZDate
                  cleanable
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  placement={"bottomStart"}
                />
              )}
            </EZField>
          </div>
        )}
        {(PermissionService.hasSpecificBusinessUnitRole(
          props.constants?.permissionPrefix + "ratingEdit",
          props.activity.data.type,
          undefined,
          false
        ) ||
          PermissionService.hasSpecificBusinessUnitRole(
            props.constants?.permissionPrefix + "ratingView",
            props.activity.data.type,
            undefined,
            false
          )) && (
          <div className={`rating`}>
            <APRatingViewButton
              readonly={
                !PermissionService.hasSpecificBusinessUnitRole(
                  props.constants?.permissionPrefix + "ratingEdit",
                  props.activity.data.type,
                  undefined,
                  false
                )
              }
              activity={props.activity}
            />
          </div>
        )}
      </div>
      <div
        className={`__ez-section-title subtitle`}
        style={{ paddingBottom: 5 }}
      >
        {i18n.t(
          "apTemplate:Activity.subtitles.supervisors",
          "Verantwortliche",
          {
            ns: [props.constants?.assetType, "apTemplate"],
          }
        )}
      </div>
      <EZGroup
        value={{
          supervisors: (props.activity.data.supervisor || []).map((userId) => ({
            id: userId,
            type: "user",
          })),
        }}
        onSubmit={async (values) => {
          await ActivityService.updateSupervisors(
            props.constants.serviceUrl,
            props.activity._id,
            props.activity.data.supervisor || [],
            (values.supervisors || []).map((e) => e.id)
          );
        }}
      >
        {(renderProps) => (
          <div className={`supervisors-container`}>
            <Field name="supervisors">
              {({ input, meta }) => (
                <BFAssignment
                  appearance="ez"
                  value={input.value}
                  onChange={input.onChange}
                  type="user"
                  identifier="ap-supervisors"
                />
              )}
            </Field>
          </div>
        )}
      </EZGroup>

      <div
        className={`__ez-section-title subtitle`}
        style={{ paddingBottom: 5 }}
      >
        {i18n.t("apTemplate:Activity.subtitles.relation", "Verknüpfungen", {
          ns: [props.constants?.assetType, "apTemplate"],
        })}
      </div>
      <div className={`linked-data`}>
        <EZGroup
          value={{
            entity: props.activity.data.entity,
            objectId: props.activity.data.objectId,
            relations: props.activity.data.relations,
          }}
          url={`/${props.constants?.serviceUrl}/${props.activity._id}/setRelations`}
          onSubmitSuccess={(value) =>
            CacheService.updateDataInCaches(value._id, value)
          }
          validate={(value) => {
            const errors: any = {};
            if (!value.entity) {
              errors.entity = i18n.t("Global.Labels.required");
            }
            if (props.constants?.fields?.objectIdMandatory) {
              if (!value.objectId) {
                errors.objectId = i18n.t("Global.Labels.required");
              }
            }
            return errors;
          }}
        >
          {(renderProps) => (
            <div className={`group-container`}>
              <Field name="entity">
                {({ input, meta }) => (
                  <div className={`__flex`}>
                    <div className={`__flex-1`}>
                      <EZSelect
                        {...input}
                        prefixIcon={{
                          type: "light",
                          size: "xs",
                          data: "buildings-2",
                        }}
                        error={
                          meta.error && meta.touched ? meta.error : undefined
                        }
                        data={OrgaStruct.getEntitySelectOptions(
                          props.activity.data.type
                        )}
                        onChange={(value) => {
                          if (input.value !== value) {
                            renderProps.form.mutators.setValue(
                              "objectId",
                              undefined
                            );
                            renderProps.form.mutators.setValue("relations", []);
                          }
                          input.onChange(value);
                        }}
                      />
                    </div>
                  </div>
                )}
              </Field>

              <div>
                <Field name="entity">
                  {({ input: { value: entityValue } }) => (
                    <Field name="objectId">
                      {({ input, meta }) => (
                        <div className={`__flex`}>
                          <div className={`__flex-1`}>
                            <EZSelect
                              {...input}
                              prefixIcon={{
                                type: "light",
                                size: "xs",
                                data: "house-3",
                              }}
                              disabled={!entityValue}
                              error={
                                meta.error && meta.touched
                                  ? meta.error
                                  : undefined
                              }
                              data={OrgaStruct.getObjectSelectOptions(
                                entityValue
                              )}
                              onChange={(value) => {
                                if (input.value !== value) {
                                  renderProps.form.mutators.setValue(
                                    "relations",
                                    []
                                  );
                                }
                                input.onChange(value);
                              }}
                            />
                          </div>

                          <div>
                            <BFDetailsIconButton
                              disabled={!input.value}
                              data={{
                                assetType: AssetTypes.Portfolio.Object,
                                assetId: input.value,
                                type: props.activity.data.type,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Field>
                  )}
                </Field>
              </div>
              <div>
                <Field name="objectId">
                  {({ input: { value: objectValue } }) => (
                    <Field name="relations">
                      {({ input, meta }) => (
                        <ObjectRelatedSelect
                          block
                          showJump
                          identifier={"ap-activity-relation-selection"}
                          appearance="default"
                          objectId={objectValue}
                          onChange={(value) => input.onChange(value)}
                          value={input.value || []}
                          multiple={true}
                          addButtonText={i18n.t(
                            "apTemplate:Activity.addRelation",
                            "Verknüpfung hinzufügen",
                            {
                              ns: [props.constants?.assetType, "apTemplate"],
                            }
                          )}
                          disabledAssetTypes={[
                            AssetTypes.Portfolio.Renter,
                            `${AssetTypes.Portfolio.Renter}_old`,
                          ]}
                          assets={
                            props.constants.fields?.relationTypes || [
                              AssetTypes.Rental.RentalAgreement,
                              `${AssetTypes.Rental.RentalAgreement}_old`,
                              AssetTypes.Portfolio.Renter,
                              `${AssetTypes.Portfolio.Renter}_old`,
                              AssetTypes.Portfolio.TechnicalUnit,
                              AssetTypes.Portfolio.SupplyUnit,
                            ]
                          }
                        />
                      )}
                    </Field>
                  )}
                </Field>
              </div>
            </div>
          )}
        </EZGroup>
      </div>

      <div className={`__ez-section-title subtitle`}>
        {i18n.t("apTemplate:Activity.subtitles.data", "Daten", {
          ns: [props.constants?.assetType, "apTemplate"],
        })}
      </div>
      <div className={`data-fields`}>
        {props.constants?.fields?.insurance && (
          <div className={`field`}>
            <EZField
              value={props.activity.data.isInsuranceClaim}
              url={`/${props.constants?.serviceUrl}/${props.activity._id}/setInsuranceClaim`}
              onSubmitSuccess={(value) =>
                CacheService.updateDataInCaches(value._id, value)
              }
            >
              {({ value, onChange, onBlur, loading, submitData }) => (
                <BFToggle
                  checked={value}
                  onChange={(checked) => {
                    onChange(checked);
                    onBlur(undefined, checked);
                  }}
                  label={i18n.t(
                    "apTemplate:Activity.fields.isInsuranceClaim",
                    "Ist ein Versicherungsfall",
                    {
                      ns: [props.constants?.assetType, "apTemplate"],
                    }
                  )}
                />
              )}
            </EZField>
          </div>
        )}
        {props.constants?.fields?.estimatedCosts && (
          <div className={`field`}>
            <EZField
              value={props.activity.data.estimatedCosts}
              url={`/${props.constants?.serviceUrl}/${props.activity._id}/setEstimatedCost`}
              onSubmitSuccess={(value) =>
                CacheService.updateDataInCaches(value._id, value)
              }
            >
              {({ value, onChange, onBlur, loading, submitData }) => (
                <BFInput
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    onBlur(undefined, value);
                  }}
                  suffix={loading && <Loader size="xs" />}
                  type="priceInput"
                  prefix={StringUtils.getCurrencySymbol()}
                  textAlign="left"
                  labelPosition="left"
                  label={i18n.t(
                    "apTemplate:Activity.fields.estimatedCost",
                    "Geschätzte Kosten",
                    {
                      ns: [props.constants?.assetType, "apTemplate"],
                    }
                  )}
                />
              )}
            </EZField>
          </div>
        )}
        {!props.constants?.fields?.disableApprovals && (
          <div className={`field`}>
            <BFInput
              value={props.activity.data.extra?.sumInvoiceValue}
              readonly
              type="priceInput"
              prefix={StringUtils.getCurrencySymbol()}
              textAlign="left"
              labelPosition="left"
              label={i18n.t(
                "apTemplate:Activity.fields.sumInvoices",
                "Genehmigte Angebote",
                {
                  ns: [props.constants?.assetType, "apTemplate"],
                }
              )}
            />
          </div>
        )}
        {!props.constants?.fields?.disableCreationDate && (
          <div className={`field`}>
            <EZField
              value={props.activity.data.creationDate}
              url={`/${props.constants?.serviceUrl}/${props.activity._id}/setCreationDate`}
              onSubmitSuccess={(value) =>
                CacheService.updateDataInCaches(value._id, value)
              }
            >
              {({ value, onChange, onBlur, loading, submitData }) => (
                <BFDatefield
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    onBlur(undefined, value);
                  }}
                  preventNull
                  labelPosition="left"
                  label={i18n.t(
                    "apTemplate:Activity.fields.creationDate",
                    "Aufnahmedatum",
                    {
                      ns: [props.constants?.assetType, "apTemplate"],
                    }
                  )}
                />
              )}
            </EZField>
          </div>
        )}
        {props.constants?.fields?.enableFinishDate && (
          <div className={`field`}>
            <EZField
              value={props.activity.data.finishDate}
              url={(value) =>
                value
                  ? `/${props.constants?.serviceUrl}/${props.activity._id}/setFinishDate`
                  : `/${props.constants?.serviceUrl}/${props.activity._id}/unsetFinishDate`
              }
              transformSubmitData={(value) => (value ? { date: value } : null)}
              onSubmitSuccess={(value) =>
                CacheService.updateDataInCaches(value._id, value)
              }
            >
              {({ value, onChange, onBlur, loading, submitData }) => (
                <BFDatefield
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    onBlur(undefined, value);
                  }}
                  labelPosition="left"
                  label={i18n.t(
                    "apTemplate:Activity.fields.finishDate",
                    "Fertigstellungsdatum",
                    {
                      ns: [props.constants?.assetType, "apTemplate"],
                    }
                  )}
                />
              )}
            </EZField>
          </div>
        )}

        <APBaseData activity={props.activity} constants={props.constants} />
        {/* {props.constants?.fields?.customFields && ( */}
        {/* )} */}
      </div>

      {props.constants?.fields?.contractData && (
        <>
          <APActivityContractFields
            activity={props.activity as APContractActivity}
          />
        </>
      )}

      {props.constants?.fields?.advertisementFeature && (
        <APAdvertisement activity={props.activity as APAdvertisementActivity} />
      )}

      <div className={`__ez-section-title subtitle`}>
        {i18n.t("apTemplate:Activity.subtitles.tags", "Tags")}
      </div>

      <div className={`tags`}>
        <EZField
          value={props.activity.data.tags}
          url={`/${props.constants?.serviceUrl}/${props.activity._id}/setTags`}
          onSubmitSuccess={(value) =>
            CacheService.updateDataInCaches(value._id, value)
          }
        >
          {({ value, onChange, onBlur, loading, submitData }) => (
            <EZTags
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              data={activityStruct
                .getTags(props.activity.data.type)
                .map((e) => ({
                  value: e.id,
                  label: LanguageService.translateLabel(e.displayName),
                  disabled: e.status === "archived",
                }))}
            />
          )}
        </EZField>
      </div>

      <div className={`__ez-section-title subtitle`}>
        {i18n.t("Labels.description", "Beschreibung")}
      </div>
      <div className={`description`}>
        <EZField
          value={props.activity.data.shortDescription}
          url={`/${props.constants?.serviceUrl}/${props.activity._id}/setDescription`}
          onSubmitSuccess={(value) =>
            CacheService.updateDataInCaches(value._id, value)
          }
        >
          {({ value, onChange, onBlur, loading, submitData }) => (
            <EZTextfield
              // label={}
              textType={"default"}
              className="title-input"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        </EZField>
      </div>
    </div>
  );
  return (
    <div className={classNames(`ap-activity-data`)}>
      <div className={`header`}>
        {props.goBack && (
          <div className={`back-button`}>
            <BFButton
              appearance={"clear-on-white"}
              icon={{ size: "xs", ...DefaultIcons.BACK }}
              onClick={props.goBack}
            />
          </div>
        )}

        <div className={`title`}>
          <div className={`first-row`}>
            <div className={`activity-id text-selection`}>
              {props.activity.data.activityId}
            </div>
            <div className={`actions`}>
              <div className={`follow-button`}>
                <FollowButton
                  assetType={props.constants?.assetType}
                  assetId={props.activity._id}
                />
              </div>
              {props.additionalActions}
            </div>
          </div>
          <EZField
            value={props.activity.data.displayName}
            url={`/${props.constants?.serviceUrl}/${props.activity._id}/setName`}
            onSubmitSuccess={(value) =>
              CacheService.updateDataInCaches(value._id, value)
            }
          >
            {({ value, onChange, onBlur, loading, submitData }) => (
              <EZTextfield
                textType={"header"}
                className="title-input"
                ignoreLinebreaks
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          </EZField>
          {props.constants.fields?.linkedAsset && (
            <div className={`linked-asset`}>
              <AssetCell
                id={props.activity.data.linkedAsset.assetId}
                assetType={props.activity.data.linkedAsset.assetType}
                render={(node) =>
                  getAssetSelectOptionsByType(
                    props.activity.data.linkedAsset.assetType,
                    node
                  ).label
                }
              />
            </div>
          )}
        </div>
      </div>

      {props.collapsible ? (
        <>
          <Collapse in={collapsed}>
            <div>{content}</div>
          </Collapse>
          <div className={`collapse-action`}>
            <BFButton
              appearance="link"
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed
                ? i18n.t(
                    "apTemplate:ActivityData.showLess",
                    "Weniger anzeigen",
                    {
                      ns: [props.constants?.assetType, "apTemplate"],
                    }
                  )
                : i18n.t("apTemplate:ActivityData.showMore", "Mehr anzeigen", {
                    ns: [props.constants?.assetType, "apTemplate"],
                  })}
            </BFButton>
          </div>
        </>
      ) : (
        <>{content}</>
      )}
    </div>
  );
};

export default APActivityData;
