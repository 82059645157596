import { CSSProperties } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Loader } from "rsuite";
import BfIcon from "../../../modules/abstract-ui/icon/BfIcon";
import { CacheData } from "../../../redux/reducers/application/ApplicationInterface";
import { AppState } from "../../../redux/store";
import CacheService, { CacheLoadData } from "../../../services/CacheService";
import { AbstractComponent } from "../../../utils/abstracts/AbstractComponent";
import { getErrorLocalized, handleError } from "../../../utils/ErrorCodes";
import { IComponent } from "../IComponent";

type Props = {
  detailsObject: CacheData<any>;
  objectId: string;
  oType: "user" | "asset" | "group";
  assetType?: string;
  cssDetailPage: CSSProperties;
  component: IComponent;
  goToRoot: () => void;
  onObjectFetch: (obj) => void;
  onBeforeObjectFetch: (id) => void;
} & RouteComponentProps;

type States = {};

class SplitPageDetailsPage extends AbstractComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  componentDidMount() {
    this.props.onBeforeObjectFetch(this.props.objectId);
    CacheService.getData({
      id: this.props.objectId,
      oType: this.props.oType,
      assetType: this.props.assetType,
    } as CacheLoadData).then((data) => {
      this.props.onObjectFetch(data);
    });
  }
  componentDidUpdate(prevProps: Props, prevStates: States) {
    if (this.props.objectId && prevProps.objectId !== this.props.objectId) {
      this.props.onBeforeObjectFetch(this.props.objectId);
      CacheService.getData({
        id: this.props.objectId,
        oType: this.props.oType,
        assetType: this.props.assetType,
      } as CacheLoadData).then((data) => {
        this.props.onObjectFetch(data);
      });
    }
    if (prevProps.detailsObject !== this.props.detailsObject) {
      if (
        this.props.detailsObject &&
        this.props.detailsObject.state === "cached"
      ) {
        this.props.onObjectFetch(this.props.detailsObject.data);
      }
    }

    if (
      prevProps.detailsObject &&
      this.props.detailsObject &&
      prevProps.detailsObject.state === "loading" &&
      this.props.detailsObject.state === "error"
    ) {
      handleError(this.props.detailsObject.error);
      this.props.goToRoot();
    }
  }

  render() {
    const { component, detailsObject, cssDetailPage, objectId, params } =
      this.props;

    if (detailsObject && detailsObject.state === "error") {
      return (
        <div className={`detail-page error`} style={cssDetailPage}>
          <div className="errorText">
            <BfIcon size="2x" type="bf" data="alert-triangle" />
            <div>{getErrorLocalized(detailsObject.error)}</div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`detail-page ${
          !detailsObject || detailsObject.state === "loading" ? "loading" : ""
        }`}
        style={cssDetailPage}
      >
        <div className={"loadmask"}>
          <Loader size="md" />
        </div>
        {(window as any).ComponentsMapper.createElement(component, {
          ...(params ? params : {}),
          routeId: objectId,
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  detailsObject: state.application.cache[
    props.oType !== "asset" ? props.oType : props.assetType
  ]
    ? state.application.cache[
        props.oType !== "asset" ? props.oType : props.assetType
      ][props.objectId]
    : null,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(SplitPageDetailsPage)) as any;
