import "./BFPlaceholder.scss";
interface Props {
  width: number | string;
  rows?: number;
  minHeight?: number;
  loading: boolean;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  widthOnlyAtLoading?: boolean;
}
const BFPlaceholder = (props: Props) => {
  if (!props.loading) {
    return (
      <div
        className={`bf-placeholder ${props.className || ""}`}
        style={{
          minWidth: props.widthOnlyAtLoading
            ? props.loading
              ? props.width
              : "auto"
            : props.width,
          height: props.minHeight,
          ...(props.style || {}),
        }}
      >
        {props.children}
      </div>
    );
  }

  return (
    <div className={`bf-placeholder loading ${props.className || ""}`}>
      {props.rows ? (
        new Array(props.rows).fill(null).map((_, index) => (
          <div
            className="placeholder-entry paragraph"
            style={{
              height: props.minHeight,
              width:
                typeof props.width === "number"
                  ? (props.width * (props.rows - index)) / props.rows
                  : props.width,
            }}
          >
            -
            <div className="anim-container">
              <div className="anim" />
            </div>
          </div>
        ))
      ) : (
        <div
          className="placeholder-entry"
          style={{
            ...props.style,
            width: props.width,
            height: props.minHeight,
          }}
        >
          -
          <div className="anim-container">
            <div className="anim" />
          </div>
        </div>
      )}
    </div>
  );
};
export default BFPlaceholder;
