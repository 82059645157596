import classNames from "classnames";
import BFTag from "../../../abstract-ui/data/tag/BFTag";
import { Resubmission } from "../../RSInterfaces";
import RSService from "../../RSService";
import "./RSPriorityTag.scss";

interface RSPriorityTagProps {
  resubmission: Resubmission;
}

const RSPriorityTag = (props: RSPriorityTagProps) => {
  const priority = props.resubmission.data.priority;
  const priorityOption = RSService.getPriorityOptions().find(
    (option) => option.value === priority
  );
  return (
    <div
      className={classNames(
        "rs-priority-tag",
        props.resubmission.data.priority
      )}
    >
      <BFTag
        style={{ backgroundColor: "pink" }}
        icon={{ ...priorityOption?.icon, size: "xs" }}
        textKey={
          RSService.getPriorityOptions().find(
            (option) => option.value === props.resubmission.data.priority
          )?.label || ""
        }
        size="sm"
      />
    </div>
  );
};

export default RSPriorityTag;
