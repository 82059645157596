import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import BaseAsset from "../model/general-assets/BaseAsset";
import CDNService from "../services/CDNService";

export function useGlobalKeyEvent(
  keys: string[],
  callback: (event: KeyboardEvent) => void,
  deps: any[] = []
) {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (keys.includes(event.key)) {
        callback(event);
      }
    };
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, deps);
}

export function useCDNLinkAndFetch(
  assetType: string,
  field: string,
  cdnId: string,
  asset: BaseAsset,
  hasFolderReadPermissions = true,
  as: "text" | "json" | "blob" | "arrayBuffer" | "formData" = "text"
) {
  const link = useCDNLink(
    assetType,
    field,
    cdnId,
    asset,
    hasFolderReadPermissions
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [data, setData] = useState<any>(undefined);
  useEffect(() => {
    if (link.link) {
      setLoading(true);
      fetch(link.link)
        .then((response) => {
          if (response.ok) {
            response[as]()
              .then((text) => {
                setData(text);
              })
              .catch((err) => {
                setError("Error reading data");
              });
          } else {
            setError("Error fetching data");
          }
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [link.link]);

  return {
    loading: link.loading || loading,
    error: link.error || error,
    data,
  };
}
export function useCDNLink(
  assetType: string,
  field: string,
  cdnId: string,
  asset: BaseAsset,
  hasFolderReadPermissions = true
) {
  const [link, setLink] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (cdnId && asset) {
      setLoading(true);
      setLink(undefined);
      setError(undefined);
      const cdnEntry = asset.cdn.find((e) => e._id === cdnId);
      if (!cdnEntry) {
        setLoading(false);
        setError("CDN entry not found");
        return;
      }

      CDNService.fetchCDNLink({
        assetType,
        assetId: asset._id,
        assetField: field,
        cdnId,
        fileKey: cdnEntry.key,
        hasFolderReadPermissions,
      })
        .then((link) => {
          setLink(link);
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [cdnId, asset, field, assetType]);

  return {
    link,
    loading,
    error,
  };
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function useStateCallback<T>(
  initialState: T
): [T, (state: T, cb?: (state: T) => void) => void] {
  const [state, setState] = useState(initialState);
  const cbRef = useRef<((state: T) => void) | undefined>(undefined); // init mutable ref container for callbacks

  const setStateCallback = useCallback((state: T, cb?: (state: T) => void) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `undefined` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = undefined; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

export function useDelay<T>(
  delayedFunction: (value: T) => void,
  delay: number = 500
) {
  const [delayedValue, setDelayedValue] = useState<T>();

  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const setFunction = useCallback(
    (value: T, force = false) => {
      clear();
      timeoutRef.current = setTimeout(() => {
        if (delayedValue) {
          delayedFunction(delayedValue);
        }
      }, delay);

      if (force) {
        delayedFunction(value);
      } else {
        setDelayedValue(value);
      }
    },
    [delayedValue, delayedFunction, delay, clear]
  );

  return {
    delay: setFunction,
    clear,
  };
}
