import classNames from "classnames";
import React, { useEffect } from "react";
import ModalManager from "../../../../../../../components/ModalComponent/ModalManager";
import StructLoader from "../../../../../../../components/StructLoader/StructLoader";
import i18n from "../../../../../../../i18n";
import BFExpandData from "../../../../../../../modules/abstract-ui/data/expand-data/BFExpandData";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../../../../../../modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../../../../../../../modules/abstract-ui/icon/DefaultIcons";
import StringUtils from "../../../../../../../utils/StringUtils";
import {
  PortfolioSupplUnitEntry,
  PortfolioSupplyUnit,
} from "../../../../../cashBudget/views/config/supply-unit-config/SupplyUnitConfigInterfaces";
import SupplyUnitService from "../../../../../cashBudget/views/config/supply-unit-config/SupplyUnitService";
import APSupplyUnitMeterReadingForm from "./APSupplyUnitMeterReadingForm";
import "./APSupplyUnitMeterReadings.scss";

interface APSupplyUnitMeterReadingsProps {
  asset: PortfolioSupplyUnit;
}

const getSortedHistory = (asset: PortfolioSupplyUnit) => {
  if (asset.data.history) {
    return asset.data.history.sort((a: any, b: any) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
  }
  return [] as PortfolioSupplUnitEntry[];
};
const APSupplyUnitMeterReadings = (props: APSupplyUnitMeterReadingsProps) => {
  const [entries, setEntries] = React.useState<PortfolioSupplUnitEntry[]>(
    getSortedHistory(props.asset)
  );

  useEffect(() => {
    setEntries(getSortedHistory(props.asset));
  }, [props.asset.data.history]);

  const currentEntry = props.asset.data.currentEntry;

  const onAddEntry = () => {
    ModalManager.show({
      size: "xs",
      noPadding: true,
      content: (state, setState, close) => (
        <APSupplyUnitMeterReadingForm
          asset={props.asset}
          onClose={close}
          lastReadingValue={currentEntry?.value}
        />
      ),
    });
  };
  return (
    <div className={classNames(`ap-supply-unit-meter-readings`)}>
      {!currentEntry && (
        <div className={`no-entry-yet`}>
          <div className={`text`}>
            {i18n.t(
              "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.noEntryYet",
              "Bisher wurde kein Zählerstand erfasst"
            )}
          </div>
          <BFButton appearance="link" onClick={onAddEntry} size="xs" noPadding>
            {i18n.t(
              "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.addEntry",
              "Zählerstand hinzufügen"
            )}
          </BFButton>
        </div>
      )}
      {currentEntry && (
        <>
          <div className={`reading-header`}>
            <div className={`reading-title`}>
              {i18n.t(
                "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.readingTitle",
                "Zählerstand"
              )}
            </div>
            <div className={`action`}>
              <BFButton
                appearance="link"
                onClick={onAddEntry}
                noPadding
                size="xs"
              >
                <BfIcon {...DefaultIcons.ADD} size="xxs" />
              </BFButton>
            </div>
          </div>
          <div className={`current-entry`}>
            <MeterReading asset={props.asset} reading={currentEntry} />
          </div>
        </>
      )}
      {entries.length > 0 && (
        <BFExpandData
          showText={i18n.t(
            "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.show",
            "Historie anzeigen"
          )}
          hideText={i18n.t(
            "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.hide",
            "Historie verbergen"
          )}
        >
          <>
            {entries.map((entry) => (
              <MeterReading
                key={entry.id}
                asset={props.asset}
                reading={entry}
              />
            ))}
          </>
        </BFExpandData>
      )}
    </div>
  );
};

export default APSupplyUnitMeterReadings;

const MeterReading = (props: {
  asset: PortfolioSupplyUnit;
  reading: PortfolioSupplUnitEntry;
}) => {
  return (
    <StructLoader
      structType="supplyUnit"
      unitType={props.asset.data.type}
      render={() => (
        <div className={`meter-reading`}>
          <div className={`data`}>
            <span className={`reading-value`}>
              {StringUtils.formatNumber(props.reading.value)}{" "}
              {props.asset.data.unit}
            </span>
            <span className={`reading-date`}>
              {StringUtils.formatDate(props.reading.date)}
            </span>

            <div className={`action`}>
              <BFButton
                appearance="link"
                onClick={() => {
                  ModalManager.confirm({
                    title: i18n.t(
                      "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.delete.title",
                      "Zählerstandeintrag löschen"
                    ),
                    message: i18n.t(
                      "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.delete.message",
                      "Wollen Sie den Zählerstandeintrag wirklich löschen?"
                    ),
                    confirmButtonText: i18n.t(
                      "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.delete.confirm",
                      "Löschen"
                    ),
                    onConfirm: async () => {
                      await SupplyUnitService.deleteSupplyUnitMeaterReading(
                        props.asset._id,
                        props.reading.id
                      );
                    },
                  });
                }}
                noPadding
                size="xs"
              >
                <BfIcon {...DefaultIcons.TRASH} size="xxs" />
              </BFButton>
            </div>
          </div>
        </div>
      )}
    />
  );
};
