import classNames from "classnames";
import { MatchQuery } from "../../../../../services/DataService";
import { hasValue } from "../../../../../utils/Helpers";
import BFInput, { BFInputProps } from "../../../forms/input/BFInput";
import { FilterSearchOption } from "../BFTableFilterModel";
import "./OverlayIdQuery.scss";
import OverlayTitle from "./OverlayTitle";

const ID_EXTRACT_REGEX = /\b\d+/gm;

export type IDQueryValue = {
  value: number | string;
};

interface OverlayIdQueryProps {
  title: string;
  value: IDQueryValue;
  onChange: (value: IDQueryValue) => void;

  inputProps?: Partial<BFInputProps>;
  type?: "string" | "number";
}

export const getDefaultIdQueryOptions = (
  value: IDQueryValue,
  searchTerm: string,
  filterKey: string,
  typeLabel: string,
  additionalKeys?: string[]
) => {
  let options: FilterSearchOption[] = [];
  if (searchTerm) {
    const matches = searchTerm.match(ID_EXTRACT_REGEX);
    if (matches && matches.length > 0) {
      const onSelect = (
        optionValue: any,
        currentValue: any,
        onChange: (value: any) => void
      ) => {
        onChange({ ...(currentValue || {}), ...optionValue });
      };
      if (!hasValue(value)) {
        options.push({
          filterKey,
          valueKey: `_${filterKey}_eq`,
          label: `${matches[0]}`,
          value: {
            value: matches[0],
          },
          labelAsString: `${matches[0]} ${typeLabel}`,
          onSelect,
          typeLabel,
          searchKeys: [...(additionalKeys || []), ">"],
        });
      }
    }
  }
  return options;
};

export const DefaultIdQueryValue: IDQueryValue = {
  value: null,
};

export const generateLabelForIdFilter = (
  filter: IDQueryValue,
  prefix?: string
) => {
  let label: string = null;
  if (filter && filter.value) {
    label = `${prefix || ""}${filter.value.toString()}`;
  }
  return label;
};
export const generateMatchQueryForIdFilter = (
  fieldName: string,
  filter: IDQueryValue,
  type?: "string" | "number"
) => {
  let matchQuery: MatchQuery = null;
  if (filter && filter.value) {
    matchQuery = {
      type: "op",
      op: "eq",
      name: fieldName,
      value:
        (type || "number") === "number" ? Number(filter.value) : filter.value,
    };
  }
  return matchQuery;
};
const OverlayIdQuery = (props: OverlayIdQueryProps) => {
  return (
    <div className={classNames(`overlay-id-query`)}>
      <OverlayTitle title={props.title} onClear={() => props.onChange(null)} />

      <div className="content">
        <BFInput
          focusOnMount
          type={props.type || "number"}
          {...props.inputProps}
          value={props.value?.value}
          step={1}
          onChange={(value: number | string) =>
            props.onChange({
              ...(props.value || {}),
              value: value,
            })
          }
        />
      </div>
    </div>
  );
};

export default OverlayIdQuery;
