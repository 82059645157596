import React from "react";
import { Trans } from "react-i18next";
import ReactResizeDetector from "react-resize-detector";
import { Animation } from "rsuite";
import SearchField from "../../configurable/components/SearchField/SearchField";
import { Application } from "../../model/db/Application";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import { useFlexCustomization } from "../../redux/actions/customization/customization-actions";
import { useTypedSelector } from "../../redux/hooks";
import AppDrawerIcon from "../AppDrawerIcon/AppDrawerIcon";
import ModalManager from "../ModalComponent/ModalManager";
import SwiperComponent from "../Swiper/SwiperComponent";
import SwiperPage from "../Swiper/SwiperPage";
import "./AppDrawer.scss";
import AppDrawerOrderList, { APP_DRAWER_SORT } from "./AppDrawerOrderList";
const { Bounce } = Animation;

type Props = {
  userAsOverlay?: boolean;
  hideSearch?: boolean;
  smallIcons?: boolean;
};

const AppDrawer = (props: Props) => {
  const [searchText, setSearchText] = React.useState<string>("");
  const [pages, setPages] = React.useState<Application[][]>([]);
  const [appsPerPage, setAppsPerPage] = React.useState<number>(0);
  const [buttonWidth, setButtonWidth] = React.useState<number>(150);
  const orderList: string[] = useFlexCustomization(APP_DRAWER_SORT, []) as any;
  const apps = useTypedSelector((state) =>
    state.global.user && state.global.user.mandator_info
      ? state.global.user.mandator_info.apps
      : null
  );
  const onResize = (width: number, height: number) => {
    const buttonWidth = props.smallIcons ? 100 : 150;

    const columns = Math.max(1, Math.floor(width / buttonWidth));
    const rows = Math.max(1, Math.floor((height - 80) / buttonWidth));
    const appsPerPage = columns * rows;

    assignFilter(searchText, appsPerPage, buttonWidth, orderList);
  };
  const assignFilter = (
    searchText: string,
    appsPerPage: number,
    buttonWidth: number,
    orderList: string[]
  ) => {
    const pages = [];
    let currentPage;

    const searchBlocks = searchText.toLowerCase().split(" ");

    apps
      .filter((app) => app.platform !== "mobile")
      .sort((a, b) => {
        let indexA = orderList.indexOf(a._id);
        let indexB = orderList.indexOf(b._id);
        if (indexA === -1 && indexB === -1) {
          return a.displayName.localeCompare(b.displayName);
        }
        indexA = indexA === -1 ? Number.MAX_VALUE : indexA;
        indexB = indexB === -1 ? Number.MAX_VALUE : indexB;
        return indexA - indexB;
      })
      .filter((app) => {
        let find = true;
        searchBlocks.forEach((searchBlock) => {
          find =
            find && app.displayName.toLowerCase().indexOf(searchBlock) !== -1;
        });
        return find;
      })
      .forEach((app, index) => {
        if (index % appsPerPage === 0) {
          currentPage = [];
          pages.push(currentPage);
        }
        currentPage?.push(app);
      });

    setSearchText(searchText);
    setAppsPerPage(appsPerPage);
    setButtonWidth(buttonWidth);
    setPages(pages);
  };

  // useEffect(() => {
  //   if (orderList) {
  //     assignFilter(searchText, appsPerPage, buttonWidth);
  //   }
  // }, [orderList]);
  return (
    <div className="application-drawer-app-outer-container">
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={(width, height) => onResize(width, height)}
      />
      <div className={"application-drawer-app-container"}>
        <div className="header">
          <div className={`title`}>
            <Trans i18nKey="AppDrawer.title" />
          </div>
          <div className={`actions`}>
            <BFButton
              appearance="clear-on-white"
              size="xs"
              onClick={() => {
                ModalManager.show({
                  backdrop: "static",
                  size: "sm",
                  noPadding: true,
                  content: (states, setStates, closeModal) => (
                    <AppDrawerOrderList
                      onSuccess={(orderList) => {
                        assignFilter(
                          searchText,
                          appsPerPage,
                          buttonWidth,
                          orderList
                        );
                      }}
                      apps={apps
                        .filter((app) => app.platform !== "mobile")
                        .sort((a, b) => {
                          let indexA = orderList.indexOf(a._id);
                          let indexB = orderList.indexOf(b._id);
                          if (indexA === -1 && indexB === -1) {
                            return a.displayName.localeCompare(b.displayName);
                          }
                          indexA = indexA === -1 ? Number.MAX_VALUE : indexA;
                          indexB = indexB === -1 ? Number.MAX_VALUE : indexB;
                          return indexA - indexB;
                        })}
                      onClose={closeModal}
                    />
                  ),
                });
              }}
            >
              <BfIcon type="light" data="cog-1" size="xxs" />
            </BFButton>
            {props.hideSearch !== true && (
              <SearchField
                sizes={[
                  {
                    focusWidth: 200,
                    blurWidth: 30,
                  },
                ]}
                value={searchText}
                onChanged={(value) =>
                  assignFilter(value, appsPerPage, buttonWidth, orderList)
                }
                appearance="on-white-as-button"
              />
            )}
          </div>
        </div>
        <div className={"apps"}>
          <SwiperComponent
            style={{ height: "100%" }}
            id={"appdrawer-swiper"}
            usePagination={true}
            useNavigationButtons={false}
            useMousewheel={true}
            slidesPerView={1}
            spaceBetween={0}
            useFreeMode={false}
            useZoom={false}
          >
            {pages.length === 0 && (
              <div className="empty-page">
                <Trans i18nKey="AppDrawer.emptyApps" />
              </div>
            )}
            {pages.map((page, index) => (
              <SwiperPage key={index}>
                <div style={{ height: "100%", width: "100%" }}>
                  <div className="app-page">
                    <AppDrawerPage
                      page={page}
                      appsPerPage={appsPerPage}
                      buttonWidth={buttonWidth}
                      smallIcons={props.smallIcons}
                    />
                  </div>
                </div>
              </SwiperPage>
            ))}
          </SwiperComponent>
        </div>
      </div>
    </div>
  );
};

const AppDrawerPage = (props: {
  page: Application[];
  appsPerPage: number;
  buttonWidth: number;
  smallIcons: boolean;
}) => {
  const { appsPerPage, buttonWidth, smallIcons, page } = props;
  const renderEmptyDivs = appsPerPage - page.length;
  const divs = [];
  for (let i = 0; i < renderEmptyDivs; i++) {
    divs.push(
      <div key={i} style={{ width: buttonWidth, height: buttonWidth }} />
    );
  }

  return (
    <>
      {page.map((app, index) => {
        return (
          <AppDrawerIcon
            key={index + app._id}
            applicationObj={app}
            small={smallIcons}
          />
        );
      })}
      {divs}
    </>
  );
};

export default AppDrawer;
