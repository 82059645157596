import { useEffect } from "react";
import { CBPortfolioTechnicalUnit } from "../../../apps/tatar/cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import CBTechnicalUnitCard from "../../../apps/tatar/cashBudget/views/portfolio/object/components/technicalUnits/CBTechnicalUnitCard";
import AssetDetailModal from "../../../components/AssetDetailModal/AssetDetailModal";
import { AssetTypes } from "../../../model/AssetTypes";
import { openDetailsAction } from "../../GlobalDetailsFunctions";
import "./APTechnicalUnitDetailModal.scss";

interface APTechnicalUnitDetailModalProps {}
const ROUTE_NAME = "__asset_technical-unit";

const APTechnicalUnitDetailModal = (props: APTechnicalUnitDetailModalProps) => {
  useEffect(() => {
    openDetailsAction(AssetTypes.Portfolio.TechnicalUnit, ROUTE_NAME);
  }, []);
  return (
    <AssetDetailModal
      assetType={AssetTypes.Portfolio.TechnicalUnit}
      routeName={ROUTE_NAME}
      className="ap-technical-unit-detail-modal"
      render={(node: CBPortfolioTechnicalUnit) => (
        <CBTechnicalUnitCard asset={node} />
      )}
    />
  );
};

export default APTechnicalUnitDetailModal;
