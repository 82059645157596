import classNames from "classnames";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { Slider } from "rsuite";
import i18n from "../../../../i18n";
import NumberUtils from "../../../../utils/ NumberUtils";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import "./UserAvatarEditor.scss";

interface UserAvatarEditorProps {
  onSave: (img) => Promise<void>;
  onAbort: () => void;
  file: File;
  size: number;
}
const UserAvatarEditor = (props: UserAvatarEditorProps) => {
  const editorRef = useRef<AvatarEditor>(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  return (
    <div className={classNames(`user-avatar-editor`)}>
      <AvatarEditor
        ref={editorRef}
        image={props.file}
        border={20}
        borderRadius={props.size / 2}
        width={props.size}
        height={props.size}
        color={[0, 0, 0, 0.6]} // RGBA
        scale={scale}
        rotate={rotate}
      />
      <div className="controls">
        <div className={`label`}>
          {i18n.t("UserProfile.Profile.Scale", "Skalierung")}
        </div>
        <Slider
          value={scale}
          onChange={setScale}
          step={0.01}
          min={1}
          max={2.5}
          renderTooltip={(value) => `${NumberUtils.normalizeDecimal(value)}x`}
        />
        <div className={`label`}>
          {i18n.t("UserProfile.Profile.Rotate", "Rotation")}
        </div>
        <Slider
          value={rotate}
          onChange={setRotate}
          step={1}
          min={1}
          max={359}
          renderTooltip={(value) => `${value}°`}
        />
      </div>
      <div className={`actions`}>
        <BFButton appearance="link" onClick={props.onAbort}>
          {i18n.t("Global.Buttons.cancel")}
        </BFButton>
        <BFButton
          appearance="link"
          onClick={async () => {
            if (editorRef.current) {
              const dataUrl = editorRef.current
                .getImageScaledToCanvas()
                .toDataURL();

              await props.onSave(dataUrl);
            }
          }}
        >
          {i18n.t("Global.Buttons.save")}
        </BFButton>
      </div>
    </div>
  );
};

export default UserAvatarEditor;
