import MultiplePDFViewer from "@/components/PDFViewer/MultiplePDFViewer";
import { PDFDocumentHighlight } from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import BaseAsset from "@/model/general-assets/BaseAsset";
import _ from "lodash";
// import "./BFAssetPDFViewer.scss";

interface BFPDFOverlayProps {
  asset: BaseAsset;
  assetField: string;
  assetType: string;
  identifier: string;
  directoryId?: string;
  height?: number | string;
  highlights?: PDFDocumentHighlight[];
  label?: {
    single: string;
    multiple: string;
  };
}
const BFAssetPDFViewer = (props: BFPDFOverlayProps) => {
  const cdnIds = _.get(props.asset, props.assetField, [])
    .filter((e) =>
      props.directoryId ? e.directoryId === props.directoryId : true
    )
    .map((e) => e.linkToCdn)
    .filter(
      (e) =>
        props.asset.cdn.find((a) => a._id === e)?.content_type ===
        "application/pdf"
    )
    .map((e) => props.asset.cdn.find((a) => a._id === e)?.id);

  if (cdnIds.length > 0) {
    return (
      <MultiplePDFViewer
        //   asset={props.asset}
        //   assetField={props.assetField}
        //   assetType={props.assetType}
        //   cdnID={cdnIds[index]}
        highlights={props.highlights}
        label={props.label}
        identifier={props.identifier}
        height={props.height}
        pdfs={cdnIds.map((id) => ({
          type: "cdn",
          asset: props.asset,
          cdnID: id,
          assetField: props.assetField,
          assetType: props.assetType,
        }))}
      />
    );
  } else {
    return (
      <div className={`padding-20`}>
        {i18n.t("BFPDFMarker.noPDFs", "Keine PDFs zum Anzeigen verfügbar")}
      </div>
    );
  }
};

export default BFAssetPDFViewer;
