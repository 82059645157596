import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import ObjectService from "../../../ObjectService";

export const openModalObjectAddDebitRunStop = (objId: string) => {
  ModalManager.confirm({
    title: i18n.t(
      "Object.Immo.Action.AddDebitRunStop.Title",
      "Automatische Sollstellung deaktivieren"
    ),
    message: i18n.t(
      "Object.Immo.Action.AddDebitRunStop.Confirm",
      "Wollen Sie, dass für die Mietverträge von dieser Immobilie keine automatische Sollstellung mehr durchgeführt werden?"
    ),
    confirmButtonText: i18n.t(
      "Object.Immo.Action.AddDebitRunStop.ConfirmButton",
      "Deaktivieren"
    ),
    onConfirm: async () => {
      await ObjectService.setDebitRunStopStatus(objId, true);
    },
  });
};
export const openModalObjectRemoveDebitRunStop = (objId: string) => {
  ModalManager.confirm({
    title: i18n.t(
      "Object.Immo.Action.RemoveDebitRunStop.Title",
      "Automatische Sollstellung aktivieren"
    ),
    message: i18n.t(
      "Object.Immo.Action.RemoveDebitRunStop.Confirm",
      "Wollen Sie die automatische Sollstellung für die Mietverträge von dieser Immobilie wieder aktivieren?"
    ),
    confirmButtonText: i18n.t(
      "Object.Immo.Action.RemoveDebitRunStop.ConfirmButton",
      "Aktivieren"
    ),
    onConfirm: async () => {
      await ObjectService.setDebitRunStopStatus(objId, false);
    },
  });
};
