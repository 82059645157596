import AssetLoader from "@/components/AssetLoader/AssetLoader";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { ApproveInvoiceTaskAsset } from "@/model/general-assets/TaskAsset";
import BFDetailsButton from "@/modules/abstract-ui/general/Button/BFDetailsButton";
import { RAInvoice } from "../../RAInterfaces";
import RAApproveInvoiceEntry from "../ApproveInvoiceList/RAApproveInvoiceEntry";
import "./RAInvoiceCurrentTask.scss";

interface RAInvoiceCurrentTaskProps {
  invoice: RAInvoice;
}
const RAInvoiceCurrentTask = (props: RAInvoiceCurrentTaskProps) => {
  const notNeeded =
    props.invoice.data.status === "init" ||
    props.invoice.data.status === "declined";
  const isInApproval = !notNeeded && props.invoice.data.status === "inApproval";
  const isInBooking = !isInApproval && props.invoice.data.needsToBeBooked;
  const isInPaymentRelease =
    !isInApproval &&
    props.invoice.data.direction === "INCOMING" &&
    props.invoice.data.needsToBeChecked;
  const isInPayment = !isInApproval && props.invoice.data.needsToBePayed;

  if (isInApproval) {
    return (
      <AssetLoader
        assetType={AssetTypes.Task}
        id={props.invoice.intern.invoiceTaskId}
        render={(task: ApproveInvoiceTaskAsset) => (
          <div>
            <RAApproveInvoiceEntry asset={task} selected taskViewOnly />
          </div>
        )}
      />
    );
  }
  if (isInBooking) {
    return (
      <div className={`ra-invoice-current-task button-center`}>
        <BFDetailsButton
          appearance="link"
          data={{
            assetId: props.invoice._id,
            type: props.invoice.data.type,
            assetType: AssetTypes.Invoice,
            params: {
              subPage:
                props.invoice.data.direction === "INCOMING"
                  ? "incoming-accounting"
                  : "outgoing-accounting",
            },
          }}
        >
          {i18n.t("ra:CurrentTask.goToBookkeeping", "Zur Buchhaltung")}
        </BFDetailsButton>
      </div>
    );
  }
  if (isInPaymentRelease) {
    return (
      <div className={`ra-invoice-current-task button-center`}>
        <BFDetailsButton
          appearance="link"
          data={{
            assetId: props.invoice._id,
            type: props.invoice.data.type,
            assetType: AssetTypes.Invoice,
            params: {
              subPage: "check-invoices",
              focus: props.invoice._id,
            },
          }}
        >
          {i18n.t("ra:CurrentTask.goToPaynmentCheck", "Zur Zahlungsfreigabe")}
        </BFDetailsButton>
      </div>
    );
  }
  if (isInPayment) {
    return (
      <div className={`ra-invoice-current-task button-center`}>
        <BFDetailsButton
          appearance="link"
          data={{
            assetId: props.invoice._id,
            assetType: AssetTypes.Invoice,
            type: props.invoice.data.type,
            params: {
              subPage: "pay-invoices",
            },
          }}
        >
          {i18n.t("ra:CurrentTask.goToPayInvoices", "Zum Ausgangskorb")}
        </BFDetailsButton>
      </div>
    );
  }
  return null;
};

export default RAInvoiceCurrentTask;
