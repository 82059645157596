import "./CashBudgetFixedHeader.scss";
import { CashBudgetColumnConfig } from "./CashBudgetTable";

interface Props {
  rowHeight: number;
  data: CashBudgetColumnConfig;
}
const CashBudgetFixedHeader = (props: Props) => {
  let comparisonActive = props.data.type === "comparison";
  return (
    <div className={`cb-fixed-header `}>
      <div style={{ height: comparisonActive ? 22 : 35 }} className={`head`}>
        <div className={`title-container`}>
          <span className="title">{props.data.headerText}</span>
          {/* <span className="info">{props.data.topHeaderTextCenter}</span> */}
        </div>
      </div>

      {comparisonActive ? (
        <div className={`subheader`} style={{ height: 13 }}>
          {props.data.subHeaders.map((entry) => (
            <div key={entry} className={`cell`}>
              {entry}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
export default CashBudgetFixedHeader;
