import { TARGET } from "../../utils/Http";
import { ACProject, ProjectStructure } from "../AppConfigInterfaces";
import AbstractAppConfigUtils from "./AbstractAppConfigUtils";

class ACProjectUtilsClass extends AbstractAppConfigUtils<ProjectStructure> {
  getName(): string {
    return "projects";
  }
  getUrl() {
    return "/liquiplanservice/getProjectStructure";
  }
  getTarget(): TARGET {
    return "EMPTY";
  }
  convertData(data: any): ProjectStructure {
    return data as ProjectStructure;
  }
  getAllProjects(type?: string[]) {
    return this.useCache<ACProject[]>("getAllProjects", arguments, () =>
      this.getData().filter((e) => (type ? type.includes(e.data.type) : true))
    );
  }

  getProjectsForType(type: string) {
    return this.useCache<ACProject[]>("getProjectsForType", arguments, () =>
      this.getData()?.filter((e) => e.data.type === type)
    );
  }
  getProjectsForEntity(entity: string) {
    return this.useCache<ACProject[]>("getProjectsForEntity", arguments, () =>
      this.getData()?.filter((e) => e.data.entity === entity)
    );
  }
  getProject(projectId: string) {
    return this.useCache<ACProject>("getProject", arguments, () =>
      this.getData().find((project) => project._id === projectId)
    );
  }
}
const ACProjectUtils = new ACProjectUtilsClass();
export default ACProjectUtils;
(window as any).ACProjectUtils = ACProjectUtils;
