import BaseAsset from "@/model/general-assets/BaseAsset";
import BFButtonToggle from "@/modules/abstract-ui/general/Button/BFButtonToggle";
import { CustomField } from "@/modules/customfields/CustomField.interface";
import CustomFieldsService from "@/modules/customfields/CustomFieldsService";
import ObjectRelatedUtils from "@/modules/structure-components/object/ObjectRelatedUtils";
import DunningStruct from "@/redux/actions/struct/implemented/DunningStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import CacheService from "@/services/CacheService";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Userlabel from "../../../../../components/AvatarComponent/Userlabel";
import UsersLabeled from "../../../../../components/AvatarComponent/UsersLabeled";
import ModalManager from "../../../../../components/ModalComponent/ModalManager";
import StructLoader from "../../../../../components/StructLoader/StructLoader";
import InfiniteTableFilter, {
  FilterComponentInitialState,
} from "../../../../../configurable/data/FilterComponent/InfiniteTableFilter";
import VirtualizedTableExportButton from "../../../../../configurable/data/VirtualizedTable/VirtualizedTableExportButton";
import TablePage from "../../../../../configurable/layouts/TablePageLayout/TablePage";
import DebugDataComponent from "../../../../../debug/DebugDataComponent";
import i18n from "../../../../../i18n";
import BFStatus from "../../../../../modules/abstract-ui/data/status/BFStatus";
import {
  AssetCell,
  ProgressCell,
  renderCellValue,
} from "../../../../../modules/abstract-ui/data/table/TableUtils";
import { ColumnConfig } from "../../../../../modules/abstract-ui/data/virtualized-table/BFVirtualizedTable";
import BFCheckbox from "../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import ContactLabel from "../../../../../modules/contacts-module/ContactLabel";
import { getDefaultCurrencyNumberFormat } from "../../../../../modules/export/export.model";
import { ObjectRelatedMultipleLabel } from "../../../../../modules/structure-components/object/ObjectRelatedLabel";
import { ActivityAbstractStructClass } from "../../../../../redux/actions/struct/implemented/ActivityAbstractStruct";
import CategoryStruct from "../../../../../redux/actions/struct/implemented/CategoryStruct";
import InsuranceStruct from "../../../../../redux/actions/struct/implemented/InsuranceStruct";
import UnitStruct from "../../../../../redux/actions/struct/implemented/UnitStruct";
import {
  useAggregationTableQuery,
  useTypedSelector,
} from "../../../../../redux/hooks";
import { AggregationStatisticQuerySelector } from "../../../../../redux/model";
import DataBusDefaults from "../../../../../services/DataBusDefaults";
import { MatchQuery } from "../../../../../services/DataService";
import LanguageService from "../../../../../services/LanguageService";
import PermissionService from "../../../../../services/PermissionService";
import { useRestrictionsMatchQuery } from "../../../../../services/RestrictionService";
import { valueOrDefault, when } from "../../../../../utils/Helpers";
import MQ from "../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../utils/StringUtils";
import Tools from "../../../../../utils/Tools";
import { APStatusTags } from "../../../../AppConfigInterfaces";
import {
  ActivityApplicationConstants,
  useActivityConstants,
} from "../../ActivityHooks";
import { APActivity, APProjectActivity } from "../../ActivityInterfaces";
import {
  AP_LIST_TABLE_IDENTIFIER,
  getActivityPriorityOptions,
  getAssetLabelByType,
  getAssetSelectOptionsByType,
} from "../../ActivityUtils";
import APCreationComponent from "../../modals/APCreationComponent";
import { APDunningActivity } from "../../specific/dunning/dunning.interface";
import {
  APContractActivity,
  APNoticePeriodLimited,
} from "../details/maintenance/APActivityMainteanceInterfaces";
import APRatingViewButton from "../details/rating/APRatingViewButton";
import APCategoryQuickfilter from "./APCategoryQuickfilter";
import "./APList.scss";
import { APListFilterOptions } from "./APListFilterOptions";

export const AP_STATIC_SELECTORS = (
  customFields?: CustomField[],
  constants?: ActivityApplicationConstants
) =>
  [
    ...CustomFieldsService.getTableCustomFieldAggregations(
      customFields,
      "meta"
    ),
    {
      name: "general",
      op: [
        { key: "count", op: "count" },
        {
          key: "estimatedCosts",
          op: "sum",
          field: "data.estimatedCosts",
        },
        {
          key: "sumInvoiceValue",
          op: "sum",
          field: "data.extra.sumInvoiceValue",
        },
        {
          key: "budgetSumShould",
          op: "sum",
          field: "calculated.budgetSumShould",
        },
        {
          key: "budgetSumBooked",
          op: "sum",
          field: "calculated.budgetSumBooked",
        },
        {
          key: "notLinkedInvoiceValue",
          op: "sum",
          field: "calculated.notLinkedInvoiceValue",
        },

        {
          key: "allocatableCostsForBudget",
          op: "sum",
          field: "calculated.allocatableCostsForBudget",
        },
        {
          key: "nonAllocatableCostsForBudget",
          op: "sum",
          field: "calculated.nonAllocatableCostsForBudget",
        },
        {
          key: "fixPrice",
          op: "sum",
          field: "data.fixPrice",
        },
        {
          key: "workPrice",
          op: "avg",
          field: "data.workPrice",
        },
        {
          key: "consumption",
          op: "sum",
          field: "data.consumption",
        },
      ],
      query: {},
    },
  ] as AggregationStatisticQuerySelector[];

const generateAggregationFilter = (
  structToUse: ActivityAbstractStructClass<any>,
  textQuery: string,
  matchQueries: MatchQuery[],
  activityStatus?: "active" | "inactive" | "waiting"
) => {
  return {
    textQuery,
    matchQuery: MQ.and(
      ...(matchQueries || []),
      when(
        activityStatus,
        MQ.in(
          "data.status",
          structToUse
            .getAllStatus()
            .filter((e) => e.activity_status === activityStatus)
            .map((e) => e.id)
        )
      )
    ),
  };
};

interface APListProps {
  activityStatus?: "active" | "inactive" | "waiting";
  archiveOptional?: boolean;
  structToUse: ActivityAbstractStructClass<any>;
  additionalMatchQuery?: MatchQuery;
  hideActions?: boolean;
  overWriteDetailsAction?: (activityId: string) => void;
  hideColumns?: string[];
}
const APList = (props: APListProps) => {
  const [categoryQuickFilter, setCategoryQuickFilter] = useState<string>(null);
  const [timeframeQuickfilter, setTimeFrameQuickfilter] = useState<
    "all" | "currentMonth" | "lastMonth"
  >("all");
  const [hideArchived, setHideArchived] = useState(true);
  const location = useLocation();
  const restrictionMatchQuery = useRestrictionsMatchQuery();
  const constants = useActivityConstants();

  const showVacancyColumn = (constants.fields?.showVacancyRate || []).some(
    (unit) =>
      PermissionService.hasBusinessUnitRole(
        `${constants?.permissionPrefix}listActivities`,
        undefined,
        false
      ).includes(unit)
  );
  const [initialState, setInitialState] =
    useState<FilterComponentInitialState>(undefined);

  const archiveStatus = props.structToUse.getStatusByTags(
    APStatusTags.ARCHIVED
  );
  const tableCache = useTypedSelector(
    (e) =>
      e.application.infiniteTables[
        AP_LIST_TABLE_IDENTIFIER +
          constants?.assetType +
          (props.activityStatus || "")
      ]
  );
  const [aggregationFilter, setAggregationFilter] = useState<{
    textQuery?: string;
    matchQuery: MatchQuery;
  }>(
    generateAggregationFilter(
      props.structToUse,
      tableCache?.searchTerm,
      tableCache?.filterStatus?.map((e) => e.matchQuery).filter((e) => !!e) ||
        [],
      props.activityStatus
    )
  );

  const aggregated = useAggregationTableQuery(
    AP_LIST_TABLE_IDENTIFIER +
      constants?.assetType +
      (props.activityStatus || ""),
    AP_STATIC_SELECTORS(constants?.fields?.customFields, constants)
  );
  // const aggregated = useAggregationStatisticQuery(
  //   constants?.assetType,
  //   MQ.and(
  //     restrictionMatchQuery,
  //     MQ.in(
  //       "data.type",
  //       PermissionService.hasBusinessUnitRole(
  //         `${constants?.permissionPrefix}listActivities`
  //       )
  //     ),
  //     aggregationFilter.matchQuery
  //   ),
  //   AP_STATIC_SELECTORS,
  //   undefined,
  //   aggregationFilter.textQuery
  // );

  // useEffect(() => {
  //   setAggregationFilter(
  //     generateAggregationFilter(
  //       props.structToUse,
  //       tableCache?.searchTerm,
  //       tableCache?.filterStatus?.map((e) => e.matchQuery).filter((e) => !!e) ||
  //         [],
  //       props.activityStatus
  //     )
  //   );
  // }, [tableCache?.searchTerm, tableCache?.filterStatus]);

  useEffect(() => {
    const params = Tools.queryParams(window.location.search);
    const filter = params.get("filter");
    const search = params.get("search");
    if (filter || search) {
      const initialData: FilterComponentInitialState = {
        search,
        filter: filter ? JSON.parse(filter) : null,
      };
      setInitialState(initialData);
    }
  }, []);
  const openCreate = (from?: APActivity) => {
    ModalManager.show({
      backdrop: "static",
      noPadding: true,
      size: constants.fields?.stackingPlan ? "lg" : undefined,
      content: (states, setStates, closeModal) => (
        <APCreationComponent
          constants={constants}
          fromActivity={from}
          onClose={closeModal}
          onError={(error) => {
            DataBusDefaults.toast({
              type: "error",
              text: i18n.t(
                "apTemplate:List.Activity.createError",
                "Fehler beim Erstellen der Aktivität",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
            });
          }}
          onSuccess={(activityId: string) => {
            DataBusDefaults.toast({
              type: "success",
              text: i18n.t(
                "apTemplate:List.Activity.createSuccess",
                "Aktivität erfolgreich angelegt",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
            });
            DataBusDefaults.route({
              route: `${activityId}`,
              append: true,
            });
            DataBusDefaults.reload({
              identifiers: [
                AP_LIST_TABLE_IDENTIFIER +
                  constants?.assetType +
                  (props.activityStatus || ""),
              ],
            });
          }}
        />
      ),
    });
  };
  const fields = AP_FIELDS(constants, props.structToUse, showVacancyColumn);
  const columnsToHide = props.hideColumns || [];
  const filteredColumns = Object.keys(fields).reduce((acc, key) => {
    if (!columnsToHide.includes(key)) {
      acc[key] = fields[key];
    }
    return acc;
  }, {} as { [columnId: string]: ColumnConfig });

  return (
    <StructLoader
      structType="category"
      unitTypes={PermissionService.hasBusinessUnitRole(
        `${constants?.permissionPrefix}listActivities`,
        undefined,
        false
      )}
      render={() => (
        <TablePage
          layout={{
            className: "ap-list",
            pageHeader: (
              <>
                {!props.hideActions && (
                  <div className={`ap-list-header`}>
                    <div className={`ap-list-header-left`}>
                      <InfiniteTableFilter
                        initialState={initialState}
                        identifier={
                          AP_LIST_TABLE_IDENTIFIER +
                          constants?.assetType +
                          (props.activityStatus || "")
                        }
                        filterOptions={APListFilterOptions(
                          PermissionService.hasBusinessUnitRole(
                            `${constants?.permissionPrefix}listActivities`,
                            undefined,
                            false
                          ),
                          constants,
                          props.structToUse
                        )}
                      />
                    </div>
                    <div className={`ap-list-header-right`}>
                      {!constants.fields?.disableCreation && (
                        <BFButton
                          appearance="default"
                          onClick={() => openCreate()}
                        >
                          {i18n.t(
                            "apTemplate:List.Activity.create",
                            "Aktivität anlegen",
                            {
                              ns: [constants?.assetType, "apTemplate"],
                            }
                          )}
                        </BFButton>
                      )}
                      <VirtualizedTableExportButton
                        identifier={
                          AP_LIST_TABLE_IDENTIFIER +
                          constants?.assetType +
                          (props.activityStatus || "")
                        }
                        appearance="default"
                      />
                    </div>
                  </div>
                )}
              </>
            ),
            cardHeader:
              (props.archiveOptional && archiveStatus?.length > 0) ||
              constants?.fields?.categoryQuickfilter ||
              constants?.fields?.timeframeQuickfilter ? (
                <div className={`ap-card-header`}>
                  {constants?.fields?.categoryQuickfilter && (
                    <div>
                      <APCategoryQuickfilter
                        structToUse={props.structToUse}
                        value={categoryQuickFilter}
                        onChange={(value) => setCategoryQuickFilter(value)}
                      />
                    </div>
                  )}
                  {constants?.fields?.timeframeQuickfilter && (
                    <div>
                      <BFButtonToggle
                        value={timeframeQuickfilter}
                        onChange={setTimeFrameQuickfilter}
                        buttons={[
                          {
                            text: i18n.t(
                              "apTemplate:List.Activity.all",
                              "Alle"
                            ),
                            value: "all",
                          },
                          {
                            text: i18n.t(
                              "apTemplate:List.Activity.currentMonth",
                              "Aktueller Monat"
                            ),
                            value: "currentMonth",
                          },
                          {
                            text: i18n.t(
                              "apTemplate:List.Activity.lastMonth",
                              "Letzter Monat"
                            ),
                            value: "lastMonth",
                          },
                        ]}
                      />
                    </div>
                  )}
                  <div className={`fill`} />
                  {props.archiveOptional && archiveStatus?.length > 0 && (
                    <div>
                      <BFCheckbox
                        checked={hideArchived}
                        onChange={(value, checked) => setHideArchived(checked)}
                      >
                        {i18n.t(
                          "apTemplate:List.Activity.hideArchived",
                          "Archivierte Aktivitäten ausblenden"
                        )}
                      </BFCheckbox>
                    </div>
                  )}
                </div>
              ) : null,
          }}
          table={{
            selection: "single",
            // reloadOnMount: true,
            identifier:
              AP_LIST_TABLE_IDENTIFIER +
              constants?.assetType +
              (props.activityStatus || ""),
            dataUrl: `/api/asset/list/${constants?.assetType}`,
            asPost: true,
            hover: true,
            expandKeys: showVacancyColumn ? ["data.objectId"] : undefined,
            // calculateSize: (entry, index) => {
            //   if (entry.data?.invoice?.fields?.amountToPay?.value > 1000) {
            //     return 60;
            //   }
            // },
            additionalMatchQuery: MQ.and(
              props.additionalMatchQuery,
              restrictionMatchQuery,
              when(
                timeframeQuickfilter === "currentMonth",
                MQ.between(
                  "data.creationDate",
                  moment().startOf("month").utc(true).toISOString(),
                  moment().endOf("month").utc(true).toISOString()
                )
              ),
              when(
                timeframeQuickfilter === "lastMonth",
                MQ.between(
                  "data.creationDate",
                  moment()
                    .subtract(1, "month")
                    .startOf("month")
                    .utc(true)
                    .toISOString(),
                  moment()
                    .subtract(1, "month")
                    .endOf("month")
                    .utc(true)
                    .toISOString()
                )
              ),
              when(
                categoryQuickFilter !== null,
                MQ.eq("data.budgetLqCategories", categoryQuickFilter)
              ),
              when(
                archiveStatus?.length > 0 && hideArchived,
                MQ.nin(
                  "data.status",
                  archiveStatus?.map((e) => e.id)
                )
              ),
              MQ.in(
                "data.type",
                PermissionService.hasBusinessUnitRole(
                  `${constants?.permissionPrefix}listActivities`,
                  undefined,
                  false
                )
              ),
              when(
                props.activityStatus,
                MQ.in(
                  "data.status",
                  props.structToUse
                    .getAllStatus()
                    .filter((e) => e.activity_status === props.activityStatus)
                    .map((e) => e.id)
                )
              )
            ),
            params: {
              aggregated,
            },
            exportOptions: {
              filename: `${constants?.assetType}_${moment().format(
                "YYYY-MM-DD"
              )}`,
            },
            onRowDoubleClick: (node: APActivity) => {
              DataBusDefaults.route({
                route: `${node._id}`,
                append: true,
              });
            },
            columns: {
              ...filteredColumns,
              ...(PermissionService.hasBusinessUnitRole(
                `${constants?.permissionPrefix}ratingEdit`,
                undefined,
                false
              ) ||
              PermissionService.hasBusinessUnitRole(
                `${constants?.permissionPrefix}ratingView`,
                undefined,
                false
              )
                ? {
                    rating: {
                      label: i18n.t(
                        "apTemplate:List.Activity.rating",
                        "Bewertung",
                        {
                          ns: [constants?.assetType, "apTemplate"],
                        }
                      ),
                      sortable: false,
                      flexWidth: 150,
                      resizable: true,
                      render: (node: APActivity, index, params) => (
                        <div className={`rating-wrapper`}>
                          <APRatingViewButton
                            activity={node}
                            readonly
                            renderedInList
                            placement="bottomEnd"
                          />
                        </div>
                      ),
                    },
                  }
                : {}),

              actions: {
                label: "",
                fixedWidth: constants?.fields?.allowDuplicate ? 150 : 70,
                fixed: "right",
                render: (node: APActivity, index, params) => (
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                    }}
                  >
                    <BFButton
                      href={
                        props.overWriteDetailsAction
                          ? undefined
                          : `${location.pathname}/${node._id}`
                      }
                      appearance="link"
                      size="xs"
                      onClick={() => {
                        if (props.overWriteDetailsAction) {
                          props.overWriteDetailsAction(node._id);
                        }
                      }}
                    >
                      {i18n.t("Global.Buttons.details")}
                    </BFButton>
                    {constants?.fields?.allowDuplicate && (
                      <BFButton
                        appearance="link"
                        size="xs"
                        onClick={() => openCreate(node)}
                      >
                        {i18n.t("Global.Buttons.duplicate", "Duplizieren")}
                      </BFButton>
                    )}
                  </div>
                ),
              },
            },
          }}
        />
      )}
    />
  );
};

APList.defaultProps = {} as Partial<APListProps>;

export default APList;

export const renderActivityStatus = (
  node: APActivity,
  structToUse: ActivityAbstractStructClass<any>
) => {
  const status = structToUse.getStatusOf(node);
  if (status) {
    let label = LanguageService.translateLabel(status.displayName);
    let subStatues = null;
    if (status.subStatus) {
      subStatues = status.subStatus.find((e) => e.id === node.data.subStatus);
      if (subStatues) {
        label = `${label} - ${LanguageService.translateLabel(
          subStatues.displayName
        )}`;
      }
    }
    return (
      <div style={{ maxWidth: "100%", overflow: "hidden" }}>
        <BFStatus
          size="sm"
          color={status.color}
          label={label}
          icon={status.icon}
        />
      </div>
    );
  }
  return null;
};
export const renderActivityPriority = (node: APActivity) => {
  const prio = getActivityPriorityOptions().find(
    (e) => e.value === node.data.priority
  );

  if (prio) {
    return (
      <div style={{ maxWidth: "100%", overflow: "hidden" }}>
        <BFStatus
          size="sm"
          color={prio.color}
          label={prio.label}
          icon={prio.icon}
        />
      </div>
    );
  }
  return null;
};

export const renderActivitySupervisors = (node: APActivity) => {
  if (node.data.supervisor) {
    return (
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
        }}
      >
        <UsersLabeled userIds={node.data.supervisor} avatarSize={18} />
      </div>
    );
  }
  return "-";
};

export const renderActivityAssignee = (node: APActivity) => {
  const assignee = node.data.assignee;
  if (assignee) {
    return (
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Userlabel id={assignee} avatarSize={18} />
      </div>
    );
  }
  return "-";
};

export const renderActivityTags = (
  node: APActivity,
  structToUse: ActivityAbstractStructClass<any>
) => {
  const tags = structToUse.getTagsOf(node);

  if (tags) {
    return (
      <div style={{ maxWidth: "100%", overflow: "auto" }}>
        {tags.map((tag) => (
          <BFStatus
            size={"xs"}
            label={LanguageService.translateLabel(tag.displayName)}
            style={{ marginRight: 3 }}
          />
        ))}
      </div>
    );
  }

  return null;
};

export const AP_FIELDS: (
  constants: ActivityApplicationConstants,
  structToUse: ActivityAbstractStructClass<any>,
  showVacancyColumn?: boolean
) => {
  [columnId: string]: ColumnConfig;
} = (constants, structToUse, showVacancyColumn) =>
  ({
    "data.activityId": {
      label: i18n.t("apTemplate:List.Activity.activityId", "Ticketnr.", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 90,
      resizable: true,
      render: (node: APActivity, index, params) => (
        <>
          <DebugDataComponent data={node} />
          {renderCellValue(node.data.activityId)}
        </>
      ),
      renderFooter: (params) =>
        renderCellValue(
          `${i18n.t("apTemplate:List.Activity.count", "Anz.", {
            ns: [constants?.assetType, "apTemplate"],
          })}: ${params?.aggregated?.data?.["general"]?.count || "-"}`
        ),

      export: {
        width: 20,
        label: i18n.t("apTemplate:List.Activity.activityId", "Ticketnr.", {
          ns: [constants?.assetType, "apTemplate"],
        }),
        type: "string",
        selector: (node: APActivity) =>
          valueOrDefault(node?.data?.activityId, "-"),
      },
    },
    "data.displayName": {
      label: i18n.t("apTemplate:List.Activity.displayName", "Name", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 200,
      resizable: true,
      render: (node: APActivity, index, params) => (
        <>{renderCellValue(node.data.displayName)}</>
      ),
      export: {
        width: 30,
        label: i18n.t("apTemplate:List.Activity.displayName", "Name", {
          ns: [constants?.assetType, "apTemplate"],
        }),
        type: "string",
        selector: (node: APActivity) =>
          valueOrDefault(node?.data?.displayName, "-"),
      },
    },
    ...(constants?.fields?.activityCostCenter
      ? {
          "data.activityCostCenter": {
            label: i18n.t(
              "apTemplate:List.Activity.activityCostCenter",
              "Kostenstelle",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 200,
            resizable: true,
            render: (node: APActivity, index, params) => (
              <>{renderCellValue(node.data.activityCostCenter)}</>
            ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.activityCostCenter",
                "Kostenstelle",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "string",
              selector: (node: APActivity) =>
                valueOrDefault(node?.data?.activityCostCenter, "-"),
            },
          },
        }
      : {}),
    "data.type": {
      label: i18n.t("apTemplate:List.Activity.type", "Bereich", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 80,
      resizable: true,
      render: (node: APActivity, index, params) =>
        renderCellValue(
          node?.data?.type,
          "-",
          (value: string) => UnitStruct.getUnit(value)?.data.label
        ),
      export: {
        width: 30,
        label: i18n.t("apTemplate:List.Activity.type", "Bereich", {
          ns: [constants?.assetType, "apTemplate"],
        }),
        type: "string",
        selector: (node: APActivity) =>
          valueOrDefault(UnitStruct.getUnit(node.data.type)?.data?.label, "-"),
      },
    },
    "data.entity": {
      label: i18n.t("apTemplate:List.Activity.entity", "Gesellschaft", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 120,
      resizable: true,
      render: (node: APActivity, index, params) =>
        renderCellValue(
          node?.data?.entity,
          "-",
          (value: string) => OrgaStruct.getEntity(value)?.displayName
        ),
      export: {
        width: 30,
        label: i18n.t("apTemplate:List.Activity.entity", "Gesellschaft", {
          ns: [constants?.assetType, "apTemplate"],
        }),
        type: "string",
        selector: (node: APActivity) =>
          valueOrDefault(
            OrgaStruct.getEntity(node.data.entity)?.displayName,
            "-"
          ),
      },
    },
    "data.objectId": {
      label: i18n.t("apTemplate:List.Activity.objectId", "Objekt", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 120,
      resizable: true,
      render: (node: APActivity, index, params) =>
        renderCellValue(
          node?.data?.objectId,
          "-",
          (value: string) =>
            `${OrgaStruct.getObject(value)?.id} - ${
              OrgaStruct.getObject(value)?.displayName
            }`
        ),
      export: {
        width: 30,
        label: i18n.t("apTemplate:List.Activity.objectId", "Objekt", {
          ns: [constants?.assetType, "apTemplate"],
        }),
        type: "string",
        selector: (node: APActivity) => {
          const obj = OrgaStruct.getObject(node.data.objectId);
          if (obj) {
            return `${obj.id} - ${obj.displayName}`;
          }
          return "-";
        },
      },
    },
    "data.relations": {
      label: i18n.t("apTemplate:List.Activity.relations", "Zuordnungen", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 150,
      resizable: true,
      render: (node: APActivity, index, params) =>
        node.data.relations ? (
          <div className={`ap-list-relation-wrapper`}>
            <ObjectRelatedMultipleLabel
              objectRelatedList={node.data.relations}
            />
          </div>
        ) : (
          "-"
        ),
      export: {
        width: 30,
        label: i18n.t("apTemplate:List.Activity.relations", "Zuordnungen", {
          ns: [constants?.assetType, "apTemplate"],
        }),
        type: "string",
        selector: async (node: APActivity) => {
          const assets = await Promise.all(
            node.data.relations?.map((relation) =>
              CacheService.getData({
                id: relation.assetId,
                oType: "asset",
                assetType: relation.assetType,
              })
            ) || []
          );

          const label =
            assets
              .map((asset, index) => {
                const obj = ObjectRelatedUtils.getDataOfObjectRelated(
                  node.data.relations[index].assetType,
                  asset
                );
                return `${obj.id ? `${obj.id} - ` : ""}${obj.name}`;
              })
              .join(", ") || "";

          return label;
        },
      },
    },
    "data.status": {
      label: i18n.t("apTemplate:List.Activity.status", "Status", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 200,
      resizable: true,
      alignment: "center",
      render: (node: APActivity, index, params) => (
        <>{renderActivityStatus(node, structToUse)}</>
      ),
      export: {
        width: 30,
        label: i18n.t("apTemplate:List.Activity.status", "Status", {
          ns: [constants?.assetType, "apTemplate"],
        }),
        type: "string",
        selector: (node: APActivity) => {
          const status = structToUse.getStatusOf(node);

          return valueOrDefault(
            LanguageService.translateLabel(status?.displayName),
            "-"
          );
        },
      },
    },
    "data.priority": {
      label: i18n.t("apTemplate:List.Activity.priority", "Priorität", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 80,
      alignment: "center",
      resizable: true,
      render: (node: APActivity, index, params) => (
        <>{renderActivityPriority(node)}</>
      ),
      export: {
        width: 30,
        label: i18n.t("apTemplate:List.Activity.priority", "Priorität", {
          ns: [constants?.assetType, "apTemplate"],
        }),
        type: "string",
        selector: (node: APActivity) => {
          const prio = getActivityPriorityOptions().find(
            (e) => e.value === node.data.priority
          );

          return valueOrDefault(
            LanguageService.translateLabel(prio?.label),
            "-"
          );
        },
      },
    },
    "meta.ca": {
      label: i18n.t("apTemplate:List.Activity.createdAt", "Aufnahme", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 90,
      resizable: true,
      render: (node: APActivity, index, params) => (
        <>
          {renderCellValue(node.meta.ca, "-", (value) =>
            moment(value).format(i18n.t("Formats.dateFormat"))
          )}
        </>
      ),
      export: {
        width: 30,
        label: i18n.t("apTemplate:List.Activity.createdAt", "Aufnahme", {
          ns: [constants?.assetType, "apTemplate"],
        }),
        type: "date",
        selector: (node: APActivity) => {
          return node.meta.ca ? new Date(node.meta.ca) : null;
        },
      },
    },
    ...(!constants?.fields?.disableCreationDate
      ? {
          "data.creationDate": {
            label: i18n.t(
              "apTemplate:List.Activity.creationDate",
              "Schadensdatum",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 90,
            resizable: true,
            render: (node: APActivity, index, params) => (
              <>
                {renderCellValue(node.data.creationDate, "-", (value) =>
                  moment(value).format(i18n.t("Formats.dateFormat"))
                )}
              </>
            ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.creationDate",
                "Schadensdatum",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "date",
              selector: (node: APActivity) => {
                return node.data.creationDate
                  ? new Date(node.data.creationDate)
                  : null;
              },
            },
          },
        }
      : {}),
    ...(!constants?.fields?.disableDueDate
      ? {
          "data.dueDate": {
            label: i18n.t("apTemplate:List.Activity.dueDate", "Fälligkeit", {
              ns: [constants?.assetType, "apTemplate"],
            }),
            sortable: true,
            flexWidth: 90,
            resizable: true,
            render: (node: APActivity, index, params) => (
              <>
                {renderCellValue(node.data.dueDate, "-", (value) =>
                  moment(value).format(i18n.t("Formats.dateFormat"))
                )}
              </>
            ),
            export: {
              width: 30,
              label: i18n.t("apTemplate:List.Activity.dueDate", "Fälligkeit", {
                ns: [constants?.assetType, "apTemplate"],
              }),
              type: "date",
              selector: (node: APActivity) => {
                return node.data.dueDate ? new Date(node.data.dueDate) : null;
              },
            },
          },
        }
      : {}),

    ...(constants.fields?.linkedAsset
      ? {
          "data.linkedAsset.assetId": {
            label: getAssetLabelByType(constants.fields.linkedAsset),
            sortable: true,
            flexWidth: 140,
            resizable: true,
            render: (node: APDunningActivity, index, params) =>
              node.data.linkedAsset ? (
                <AssetCell
                  assetType={node.data.linkedAsset.assetType}
                  id={node.data.linkedAsset.assetId}
                  render={(asset: BaseAsset) =>
                    getAssetSelectOptionsByType(
                      node.data.linkedAsset.assetType,
                      asset
                    ).label
                  }
                />
              ) : (
                "-"
              ),
            export: {
              width: 30,
              label: getAssetLabelByType(constants.fields.linkedAsset),
              type: "string",
              selector: async (node: APDunningActivity) => {
                if (!node.data.linkedAsset) {
                  return "-";
                }
                const asset = await CacheService.getData({
                  oType: "asset",
                  assetType: node.data.linkedAsset.assetType,
                  id: node.data.linkedAsset.assetId,
                });

                return getAssetSelectOptionsByType(
                  node.data.linkedAsset.assetType,
                  asset
                ).label;
              },
            },
          },
          "data.dunning.automatic": {
            label: i18n.t(
              "apTemplate:List.Activity.automatic",
              "durch Mahnlauf",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            alignment: "center",
            sortable: true,
            flexWidth: 140,
            resizable: true,
            render: (node: APDunningActivity, index, params) => (
              <div>
                <BFCheckbox readOnly checked={node.data.dunning?.automatic} />
              </div>
            ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.automatic",
                "durch Mahnlauf",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "string",
              selector: async (node: APDunningActivity) => {
                if (node.data.dunning?.automatic) {
                  return "TRUE";
                }
                return "FALSE";
              },
            },
          },
          "data.dunning.nextDueDate": {
            label: i18n.t(
              "apTemplate:List.Activity.nextDueDate",
              "Fälligkeit",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 140,
            resizable: true,
            render: (node: APDunningActivity, index, params) =>
              renderCellValue(
                node.data.dunning?.nextDueDate,
                "-",
                StringUtils.formatDate
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.nextDueDate",
                "Fälligkeit",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "date",
              selector: async (node: APDunningActivity) => {
                if (node.data.dunning?.nextDueDate) {
                  return new Date(node.data.dunning?.nextDueDate);
                }
                return null;
              },
            },
          },
          "data.dunning.dunningLevel": {
            label: i18n.t(
              "apTemplate:List.Activity.dunningLevel",
              "Mahnstufe",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 140,
            resizable: true,
            render: (node: APDunningActivity, index, params) =>
              renderCellValue(
                DunningStruct.getDunningLevel(node.data.dunning?.dunningLevel)
                  ?.name,
                "-",
                LanguageService.translateLabel
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.dunningLevel",
                "Mahnstufe",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "string",
              selector: async (node: APDunningActivity) => {
                if (node.data.dunning?.dunningLevel) {
                  return "-";
                }
                return LanguageService.translateLabel(
                  DunningStruct.getDunningLevel(node.data.dunning?.dunningLevel)
                    ?.name
                );
              },
            },
          },
          "data.dunning.typeOfResidue": {
            label: i18n.t(
              "apTemplate:List.Activity.typeOfResidue",
              "Art der Mahnung",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 140,
            resizable: true,
            render: (node: APDunningActivity, index, params) =>
              renderCellValue(
                DunningStruct.getTypeOfResidue(node.data.dunning?.typeOfResidue)
                  ?.label,
                "-",
                LanguageService.translateLabel
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.typeOfResidue",
                "Art der Mahnung",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "string",
              selector: async (node: APDunningActivity) => {
                if (node.data.dunning?.typeOfResidue) {
                  return "-";
                }
                return LanguageService.translateLabel(
                  DunningStruct.getTypeOfResidue(
                    node.data.dunning?.typeOfResidue
                  )?.label
                );
              },
            },
          },
          "data.dunning.reason": {
            label: i18n.t(
              "apTemplate:List.Activity.reason",
              "Grund der Mahnung",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 140,
            resizable: true,
            render: (node: APDunningActivity, index, params) =>
              renderCellValue(node.data.dunning?.reason, "-"),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.reason",
                "Grund der Mahnung",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "string",
              selector: async (node: APDunningActivity) => {
                return node.data.dunning?.reason;
              },
            },
          },
          "data.dunning.action": {
            label: i18n.t("apTemplate:List.Activity.action", "Maßnahme", {
              ns: [constants?.assetType, "apTemplate"],
            }),
            sortable: true,
            flexWidth: 140,
            resizable: true,
            render: (node: APDunningActivity, index, params) =>
              renderCellValue(node.data.dunning?.action, "-"),
            export: {
              width: 30,
              label: i18n.t("apTemplate:List.Activity.action", "Maßnahme", {
                ns: [constants?.assetType, "apTemplate"],
              }),
              type: "string",
              selector: async (node: APDunningActivity) => {
                return node.data.dunning?.action;
              },
            },
          },
        }
      : {}),

    ...(constants?.fields?.contractData
      ? {
          "data.budgetLqCategories": {
            label: i18n.t(
              "apTemplate:List.Activity.budgetLqCategories",
              "Vertragskategorie",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 150,
            resizable: true,
            render: (node: APContractActivity, index, params) => {
              const groupIds = node.data.budgetLqCategories || [];
              const groupNames =
                groupIds
                  .map((id) =>
                    CategoryStruct.getData(node.data.type).find(
                      (g) => g._id === id
                    )
                  )
                  .filter((e) => e)
                  .map((e) =>
                    LanguageService.translateLabel(e.data.displayName)
                  ) || [];

              return (
                <>
                  {renderCellValue(
                    groupNames,
                    "-",
                    (value: string[]) => value.join(", ") || "-"
                  )}
                </>
              );
            },
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.budgetLqCategories",
                "Vertragskategorie",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "string",
              selector: (node: APContractActivity) => {
                const groupIds = node.data.budgetLqCategories || [];
                const groupNames =
                  groupIds
                    .map((id) =>
                      CategoryStruct.getData(node.data.type).find(
                        (g) => g._id === id
                      )
                    )
                    .filter((e) => e)
                    .map((e) =>
                      LanguageService.translateLabel(e.data.displayName)
                    ) || [];

                return valueOrDefault(groupNames.join(", "), "-");
              },
            },
          },
          "data.contractId": {
            label: i18n.t(
              "apTemplate:List.Activity.contractId",
              "Vertragskonto",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 150,
            resizable: true,
            render: (node: APContractActivity, index, params) => (
              <>{renderCellValue(node.data.contractId, "-")}</>
            ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.contractId",
                "Vertragskonto",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "string",
              selector: (node: APContractActivity) => {
                return valueOrDefault(node.data.contractId, "-");
              },
            },
          },

          "nested.contactDisplayName": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.contact",
              "Vertragspartner"
            ),
            sortable: true,
            flexWidth: 200,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              node.data.contact ? (
                <ContactLabel contactId={node.data.contact} />
              ) : (
                "-"
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.contact",
                "Vertragspartner"
              ),
              type: "string",
              selector: (node: APContractActivity) =>
                node.nested.contactDisplayName,
            },
          },
          "calculated.nextScheduleDate": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.nextScheduleDate",
              "Nächste Fälligkeit"
            ),
            sortable: true,
            flexWidth: 90,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(node.calculated?.nextScheduleDate, "-", (val) =>
                StringUtils.formatDate(val)
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.nextScheduleDate",
                "Nächste Fälligkeit"
              ),
              type: "date",
              selector: (node: APContractActivity) =>
                node.calculated?.nextScheduleDate
                  ? new Date(node.calculated.nextScheduleDate)
                  : null,
            },
          },
          "data.noticePeriod.type": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.noticePeriodType",
              "Vertragsart"
            ),
            sortable: true,
            flexWidth: 160,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(node.data.noticePeriod?.type, "-", (val) =>
                val === "limited"
                  ? i18n.t(
                      "apTemplate:Activity.fields.maintenance.noticePeriod.limited",
                      "Vertragslaufzeit"
                    )
                  : i18n.t(
                      "apTemplate:Activity.fields.maintenance.noticePeriod.unlimited",
                      "Unbefristeter Vertrag"
                    )
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.noticePeriodType",
                "Vertragsart"
              ),
              type: "string",
              selector: (node: APContractActivity) =>
                node.data.noticePeriod?.type
                  ? node.data.noticePeriod?.type === "limited"
                    ? i18n.t(
                        "apTemplate:Activity.fields.maintenance.noticePeriod.limited",
                        "Vertragslaufzeit"
                      )
                    : i18n.t(
                        "apTemplate:Activity.fields.maintenance.noticePeriod.unlimited",
                        "Unbefristeter Vertrag"
                      )
                  : "-",
            },
          },

          "data.noticePeriod.typeData.contractActiveTill": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.contractActiveTill",
              "Vertrag bis"
            ),
            sortable: true,
            flexWidth: 90,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(
                (node.data.noticePeriod as APNoticePeriodLimited).typeData
                  ?.contractActiveTill,
                "-",
                (val) => StringUtils.formatDate(val)
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.contractActiveTill",
                "Vertrag bis"
              ),
              type: "date",
              selector: (node: APContractActivity) =>
                (node.data.noticePeriod as APNoticePeriodLimited).typeData
                  ?.contractActiveTill
                  ? new Date(
                      (
                        node.data.noticePeriod as APNoticePeriodLimited
                      ).typeData?.contractActiveTill
                    )
                  : null,
            },
          },

          "data.noticePeriod.typeData.monthsForCancelation": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.monthsForCancelation",
              "Kündigungsfrist (Monate)"
            ),
            sortable: true,
            flexWidth: 130,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(
                (node.data.noticePeriod as APNoticePeriodLimited).typeData
                  ?.monthsForCancelation,
                "-"
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.monthsForCancelation",
                "Kündigungsfrist (Monate)"
              ),
              type: "number",
              selector: (node: APContractActivity) =>
                (node.data.noticePeriod as APNoticePeriodLimited).typeData
                  ?.monthsForCancelation || "-",
            },
          },

          "data.noticePeriod.typeData.earliestCancelationDate": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.earliestCancelationDate",
              "Kündigung bis"
            ),
            sortable: true,
            flexWidth: 120,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(
                (node.data.noticePeriod as APNoticePeriodLimited).typeData
                  ?.earliestCancelationDate,
                "-",
                (val) => StringUtils.formatDate(val),
                undefined,
                moment(
                  (node.data.noticePeriod as APNoticePeriodLimited).typeData
                    ?.earliestCancelationDate
                ).isBefore(moment())
                  ? "red"
                  : moment(
                      (node.data.noticePeriod as APNoticePeriodLimited).typeData
                        ?.earliestCancelationDate
                    )
                      .subtract(1, "month")
                      .isBefore(moment())
                  ? "orange"
                  : undefined
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.earliestCancelationDate",
                "Kündigung bis"
              ),
              type: "date",
              selector: (node: APContractActivity) =>
                (node.data.noticePeriod as APNoticePeriodLimited).typeData
                  ?.earliestCancelationDate
                  ? new Date(
                      (
                        node.data.noticePeriod as APNoticePeriodLimited
                      ).typeData?.earliestCancelationDate
                    )
                  : null,
            },
          },
          "data.noticePeriod.typeData.autorenewalNumberOfMonths": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.autorenewalNumberOfMonths",
              "Verlängerung (Anzahl Monate)"
            ),
            sortable: true,
            flexWidth: 120,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(
                (node.data.noticePeriod as APNoticePeriodLimited).typeData
                  ?.autorenewalNumberOfMonths,
                "-"
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.autorenewalNumberOfMonths",
                "Verlängerung (Monate)"
              ),
              type: "number",
              selector: (node: APContractActivity) =>
                (node.data.noticePeriod as APNoticePeriodLimited).typeData
                  ?.autorenewalNumberOfMonths || "-",
            },
          },
          "calculated.budgetSumShould": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.contactCost",
              "Vertragskosten"
            ),
            sortable: true,
            flexWidth: 120,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(node.calculated?.budgetSumShould, "-", (val) =>
                StringUtils.formatCurrency(val)
              ),
            renderFooter: (params) =>
              renderCellValue(
                params?.aggregated?.data?.["general"]?.budgetSumShould,
                "-",
                (value: number) => StringUtils.formatCurrency(value)
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.contactCost",
                "Vertragskosten"
              ),
              type: "number",
              totalFunction: "sum",
              style: {
                numFmt: getDefaultCurrencyNumberFormat(),
              },
              selector: (node: APContractActivity) =>
                node.calculated?.budgetSumShould,
            },
          },

          ...when(
            constants?.fields?.insuranceFeature,
            {
              "data.insuranceValue": {
                label: i18n.t(
                  "apTemplate:List.Activity.Contract.insuranceValue",
                  "Versicherungswert"
                ),
                sortable: true,
                flexWidth: 140,
                resizable: true,
                render: (node: APContractActivity, index, params) =>
                  renderCellValue(node.data?.insuranceValue, "-", (val) =>
                    StringUtils.formatCurrency(val)
                  ),
                renderFooter: (params) =>
                  renderCellValue(
                    params?.aggregated?.data?.["general"]?.insuranceValue,
                    "-",
                    (value: number) => StringUtils.formatCurrency(value)
                  ),

                export: {
                  width: 30,
                  label: i18n.t(
                    "apTemplate:List.Activity.Contract.insuranceValue",
                    "Versicherungswert"
                  ),
                  type: "number",
                  totalFunction: "sum",
                  style: {
                    numFmt: getDefaultCurrencyNumberFormat(),
                  },
                  selector: (node: APContractActivity) =>
                    node.data?.insuranceValue,
                },
              },
              "data.insuranceValue14": {
                label: i18n.t(
                  "apTemplate:List.Activity.Contract.insuranceValue14",
                  "Versicherungswert 1914"
                ),
                sortable: true,
                flexWidth: 140,
                resizable: true,
                render: (node: APContractActivity, index, params) =>
                  renderCellValue(node.data?.insuranceValue14, "-", (val) =>
                    StringUtils.formatCurrency(val)
                  ),
                renderFooter: (params) =>
                  renderCellValue(
                    params?.aggregated?.data?.["general"]?.insuranceValue14,
                    "-",
                    (value: number) => StringUtils.formatCurrency(value)
                  ),

                export: {
                  width: 30,
                  label: i18n.t(
                    "apTemplate:List.Activity.Contract.insuranceValue14",
                    "Versicherungswert 1914"
                  ),
                  type: "number",
                  totalFunction: "sum",
                  style: {
                    numFmt: getDefaultCurrencyNumberFormat(),
                  },
                  selector: (node: APContractActivity) =>
                    node.data?.insuranceValue14,
                },
              },
              "data.insuranceList": {
                label: i18n.t(
                  "apTemplate:List.Activity.Contract.insuranceList",
                  "Versicherungsarten"
                ),
                sortable: false,
                flexWidth: 600,
                resizable: true,
                render: (node: APContractActivity, index, params) => {
                  const data =
                    InsuranceStruct.getInsurancePositions(node.data.type) || [];

                  const positions = data
                    .map((pos) => ({
                      pos,
                      entry: node.data.insuranceList?.find(
                        (e) => e.id === pos.id
                      ),
                    }))
                    .filter((e) => e.entry);

                  if (positions.length === 0) {
                    return null;
                  }

                  return (
                    <div className={`insurance-positions`}>
                      {positions.map((pos) => (
                        <div className={`insurance-position`}>
                          <span className={`label`}>
                            {LanguageService.translateLabel(
                              pos.pos.displayName
                            )}
                          </span>{" "}
                          <span className={`value`}>
                            {StringUtils.formatCurrency(pos.entry.value)}
                          </span>
                        </div>
                      ))}
                    </div>
                  );
                },

                export: {
                  width: 30,
                  type: "string",
                  label: i18n.t(
                    "apTemplate:List.Activity.Contract.insuranceList",
                    "Versicherungsarten"
                  ),
                  selector: (node: APContractActivity) =>
                    node.data?.insuranceValue14,
                },
              },
            },
            {}
          ),

          ...when(
            constants?.fields?.budgetFeatureType === "fixYearPlan",
            {
              "calculated.budgetTurnus": {
                label: i18n.t(
                  "apTemplate:List.Activity.Contract.budgetTurnus",
                  "Turnus (Jahre)"
                ),
                sortable: true,
                flexWidth: 120,
                resizable: true,
                render: (node: APContractActivity, index, params) =>
                  renderCellValue(node.calculated?.budgetTurnus, "-"),
                export: {
                  width: 30,
                  label: i18n.t(
                    "apTemplate:List.Activity.Contract.budgetTurnus",
                    "Turnus (Jahre)"
                  ),
                  type: "number",
                  selector: (node: APContractActivity) =>
                    node.calculated?.budgetTurnus,
                },
              },
            },
            {}
          ),

          "data.allocatableCosts": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.allocatableCosts",
              "Umlegbar"
            ),
            sortable: true,
            flexWidth: 120,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(node.data.allocatableCosts, "-", (val) =>
                StringUtils.formatPercent(val, true)
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.allocatableCosts",
                "Umlegbar"
              ),
              type: "number",
              selector: (node: APContractActivity) =>
                node.data.allocatableCosts,
            },
          },

          "calculated.allocatableCostsForBudget": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.allocatableCostsForBudget",
              "Umlegbare Kosten"
            ),
            sortable: true,
            flexWidth: 120,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(
                node.calculated?.allocatableCostsForBudget,
                "-",
                (val) => StringUtils.formatCurrency(val)
              ),
            renderFooter: (params) =>
              renderCellValue(
                params?.aggregated?.data?.["general"]
                  ?.allocatableCostsForBudget,
                "-",
                (value: number) => StringUtils.formatCurrency(value)
              ),

            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.allocatableCostsForBudget",
                "Umlegbare Kosten"
              ),
              type: "number",
              totalFunction: "sum",
              style: {
                numFmt: getDefaultCurrencyNumberFormat(),
              },
              selector: (node: APContractActivity) =>
                node.calculated?.allocatableCostsForBudget,
            },
          },

          "calculated.nonAllocatableCostsForBudget": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.nonAllocatableCostsForBudget",
              "Eigene Kosten"
            ),
            sortable: true,
            flexWidth: 120,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(
                node.calculated?.nonAllocatableCostsForBudget,
                "-",
                (val) => StringUtils.formatCurrency(val)
              ),
            renderFooter: (params) =>
              renderCellValue(
                params?.aggregated?.data?.["general"]
                  ?.nonAllocatableCostsForBudget,
                "-",
                (value: number) => StringUtils.formatCurrency(value)
              ),

            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.nonAllocatableCostsForBudget",
                "Eigene Kosten"
              ),
              type: "number",
              totalFunction: "sum",
              style: {
                numFmt: getDefaultCurrencyNumberFormat(),
              },
              selector: (node: APContractActivity) =>
                node.calculated?.nonAllocatableCostsForBudget,
            },
          },
        }
      : {}),

    ...(constants?.fields?.customFields
      ? CustomFieldsService.getTableCustomFields(
          constants?.fields?.customFields,
          "meta"
        )
      : {}),

    ...when(
      showVacancyColumn,
      {
        "expand.objectId.kpi.VACANT_RATE": {
          label: i18n.t(
            "apTemplate:List.Activity.Contract.vacancyRate",
            "Leerstandsquote"
          ),
          sortable: true,
          flexWidth: 120,
          resizable: true,
          render: (node: APContractActivity, index, params) =>
            renderCellValue(
              (node.expand?.objectId as any)?.kpi?.VACANT_RATE,
              "-",
              (val) => StringUtils.formatPercent(val, true)
            ),
          export: {
            width: 30,
            label: i18n.t(
              "apTemplate:List.Activity.Contract.vacancyRate",
              "Leerstandsquote"
            ),
            type: "number",
            style: {
              numFmt: getDefaultCurrencyNumberFormat(),
            },
            selector: (node: APContractActivity) =>
              (node.expand?.objectId as any)?.kpi?.VACANT_RATE || null,
          },
        },
      },
      {}
    ),

    ...(constants?.fields?.supplyContractFeature
      ? {
          "data.workPrice": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.workPrice",
              "Arbeitspreis"
            ),
            sortable: true,
            flexWidth: 120,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(node.data.workPrice, "-", (val) =>
                StringUtils.formatCurrencyCents(val)
              ),
            renderFooter: (params) =>
              renderCellValue(
                params?.aggregated?.data?.["general"]?.workPrice,
                "-",
                (value: number) => StringUtils.formatCurrencyCents(value)
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.workPrice",
                "Arbeitspreis"
              ),
              type: "number",
              totalFunction: "sum",
              style: {
                numFmt: getDefaultCurrencyNumberFormat(),
              },
              selector: (node: APContractActivity) => node.data?.workPrice,
            },
          },
          "data.fixPrice": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.fixPrice",
              "Grundpreis"
            ),
            sortable: true,
            flexWidth: 120,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(node.data.fixPrice, "-", (val) =>
                StringUtils.formatCurrency(val)
              ),
            renderFooter: (params) =>
              renderCellValue(
                params?.aggregated?.data?.["general"]?.fixPrice,
                "-",
                (value: number) => StringUtils.formatCurrency(value)
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.fixPrice",
                "Grundpreis"
              ),
              type: "number",
              totalFunction: "sum",
              style: {
                numFmt: getDefaultCurrencyNumberFormat(),
              },
              selector: (node: APContractActivity) => node.data?.fixPrice,
            },
          },
          "data.priceNote": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.priceNote",
              "Preisanmerkung"
            ),
            sortable: true,
            flexWidth: 200,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(node?.data.priceNote, "-"),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.priceNote",
                "Preisanmerkung"
              ),
              type: "string",
              selector: (node: APContractActivity) =>
                node.data.priceNote || "-",
            },
          },

          "data.consumption": {
            label: i18n.t(
              "apTemplate:List.Activity.Contract.consumption",
              "Jahresverbrauch geschätzt"
            ),
            sortable: true,
            flexWidth: 120,
            resizable: true,
            render: (node: APContractActivity, index, params) =>
              renderCellValue(node.data.consumption, "-", (val) =>
                StringUtils.formatNumber(val)
              ),
            renderFooter: (params) =>
              renderCellValue(
                params?.aggregated?.data?.["general"]?.consumption,
                "-",
                (value: number) => StringUtils.formatNumber(value)
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.Contract.consumption",
                "Jahresverbrauch geschätzt"
              ),
              type: "number",
              totalFunction: "sum",
              style: {
                numFmt: getDefaultCurrencyNumberFormat(),
              },
              selector: (node: APContractActivity) => node.data?.consumption,
            },
          },
        }
      : {}),
    ...(constants?.fields?.projectBudgetFeature
      ? {
          "data.dateFrom": {
            label: i18n.t(
              "apTemplate:List.Activity.projectDateFrom",
              "Projektbeginn",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 90,
            resizable: true,
            render: (node: APProjectActivity, index, params) => (
              <>
                {renderCellValue(node.data.dateFrom, "-", (value) =>
                  moment(value).format(i18n.t("Formats.dateFormat"))
                )}
              </>
            ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.dateFrom",
                "Projektbeginn",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "date",
              selector: (node: APProjectActivity) => {
                return node.data.dateFrom ? new Date(node.data.dateFrom) : null;
              },
            },
          },
          "data.dateTo": {
            label: i18n.t(
              "apTemplate:List.Activity.projectDateTo",
              "Geplantes Projektende",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 90,
            resizable: true,
            render: (node: APProjectActivity, index, params) => (
              <>
                {renderCellValue(node.data.dateTo, "-", (value) =>
                  moment(value).format(i18n.t("Formats.dateFormat"))
                )}
              </>
            ),
            export: {
              width: 30,
              label: i18n.t("apTemplate:List.Activity.dateTo", "Projektende", {
                ns: [constants?.assetType, "apTemplate"],
              }),
              type: "date",
              selector: (node: APProjectActivity) => {
                return node.data.dateTo ? new Date(node.data.dateTo) : null;
              },
            },
          },

          ...when(
            constants?.fields?.enableFinishDate,
            {
              "data.finishDate": {
                label: i18n.t(
                  "apTemplate:List.Activity.finishDate",
                  "Fertigstellungsdatum",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                ),
                sortable: true,
                flexWidth: 90,
                resizable: true,
                render: (node: APProjectActivity, index, params) => (
                  <>
                    {renderCellValue(node.data.finishDate, "-", (value) =>
                      moment(value).format(i18n.t("Formats.dateFormat"))
                    )}
                  </>
                ),
                export: {
                  width: 30,
                  label: i18n.t(
                    "apTemplate:List.Activity.finishDate",
                    "Fertigstellungsdatum",
                    {
                      ns: [constants?.assetType, "apTemplate"],
                    }
                  ),
                  type: "date",
                  selector: (node: APProjectActivity) => {
                    return node.data.finishDate
                      ? new Date(node.data.finishDate)
                      : null;
                  },
                },
              },
            },
            {}
          ),
          "calculated.progressDate": {
            label: i18n.t(
              "apTemplate:List.Activity.progressDate",
              "Fertigstellungsdauer",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 140,
            resizable: true,
            render: (node: APProjectActivity, index, params) =>
              node?.calculated?.progressDate ? (
                <ProgressCell progress={node?.calculated?.progressDate || 0} />
              ) : null,
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.progressDate",
                "Fertigstellungsdauer",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "string",
              selector: (node: APProjectActivity) => {
                return node?.calculated?.progressDate
                  ? StringUtils.formatPercent(node?.calculated?.progressDate)
                  : "-";
              },
            },
          },

          "calculated.budgetSumShould": {
            label: i18n.t(
              "apTemplate:List.Activity.budgetSumShould",
              "Genehmigtes Budget",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 100,
            resizable: true,
            render: (node: APProjectActivity, index, params) => (
              <>
                {renderCellValue(
                  node.calculated?.budgetSumShould,
                  "-",
                  (value) => StringUtils.formatCurrency(value)
                )}
              </>
            ),
            renderFooter: (params) =>
              renderCellValue(
                params?.aggregated?.data?.["general"]?.budgetSumShould,
                "-",
                (value: number) => StringUtils.formatCurrency(value)
              ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.budgetSumShould",
                "Genehmigtes Budget",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "number",
              totalFunction: "sum",
              style: {
                numFmt: getDefaultCurrencyNumberFormat(),
              },
              selector: (node: APProjectActivity) => {
                return node.calculated?.budgetSumShould || null;
              },
            },
          },

          "calculated.budgetSumBooked": {
            label: i18n.t(
              "apTemplate:List.Activity.budgetSumBooked",
              "Verbrauchtes Budget",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 100,
            resizable: true,
            render: (node: APProjectActivity, index, params) => (
              <>
                {renderCellValue(
                  node.calculated?.budgetSumBooked,
                  "-",
                  (value) => StringUtils.formatCurrency(value)
                )}
              </>
            ),
            renderFooter: (params) =>
              renderCellValue(
                params?.aggregated?.data?.["general"]?.budgetSumBooked,
                "-",
                (value: number) => StringUtils.formatCurrency(value)
              ),

            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.budgetSumBooked",
                "Verbrauchtes Budget",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "number",
              totalFunction: "sum",
              style: {
                numFmt: getDefaultCurrencyNumberFormat(),
              },
              selector: (node: APProjectActivity) => {
                return node.calculated?.budgetSumBooked || null;
              },
            },
          },

          "calculated.progressBudget": {
            label: i18n.t("apTemplate:List.Activity.progressBudget", "Budget", {
              ns: [constants?.assetType, "apTemplate"],
            }),
            sortable: true,
            flexWidth: 140,
            resizable: true,
            render: (node: APProjectActivity, index, params) => (
              <ProgressCell progress={node?.calculated?.progressBudget || 0} />
            ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.progressBudget",
                "Budget",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "string",
              selector: (node: APProjectActivity) => {
                return node?.calculated?.progressBudget
                  ? StringUtils.formatPercent(node?.calculated?.progressBudget)
                  : "-";
              },
            },
          },
          "calculated.progressSchedule": {
            label: i18n.t(
              "apTemplate:List.Activity.progressSchedule",
              "Zeitplan",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 140,
            resizable: true,
            render: (node: APProjectActivity, index, params) => (
              <ProgressCell
                progress={node?.calculated?.progressSchedule || 0}
              />
            ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.progressSchedule",
                "Zeitplan",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "string",
              selector: (node: APProjectActivity) => {
                return node?.calculated?.progressSchedule
                  ? StringUtils.formatPercent(
                      node?.calculated?.progressSchedule
                    )
                  : "-";
              },
            },
          },

          "calculated.notLinkedInvoiceValue": {
            label: i18n.t(
              "apTemplate:List.Activity.notLinkedInvoiceValue",
              "Noch zuzuweisen",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 100,
            resizable: true,

            renderFooter: (params) =>
              renderCellValue(
                params?.aggregated?.data?.["general"]?.notLinkedInvoiceValue,
                "-",
                (value: number) => StringUtils.formatCurrency(value)
              ),
            render: (node: APProjectActivity, index, params) => (
              <>
                {renderCellValue(
                  node.calculated?.notLinkedInvoiceValue,
                  "-",
                  (value) => StringUtils.formatCurrency(value)
                )}
              </>
            ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.notLinkedInvoiceValue",
                "Noch zuzuweisen",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "number",
              totalFunction: "sum",
              style: {
                numFmt: getDefaultCurrencyNumberFormat(),
              },
              selector: (node: APProjectActivity) => {
                return node.calculated?.notLinkedInvoiceValue || null;
              },
            },
          },
        }
      : {}),
    ...(constants?.fields?.estimatedCosts
      ? {
          "data.estimatedCosts": {
            label: i18n.t(
              "apTemplate:List.Activity.estimatedCosts",
              "Geschätzte Kosten",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            alignment: "right",
            flexWidth: 130,
            resizable: true,
            render: (node: APActivity, index, params) => (
              <>
                {renderCellValue(node.data.estimatedCosts, "-", (value) =>
                  StringUtils.formatCurrency(value)
                )}
              </>
            ),

            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.estimatedCosts",
                "Geschätzte Kosten",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "number",
              style: {
                numFmt: getDefaultCurrencyNumberFormat(),
              },
              totalFunction: "sum",
              selector: (node: APActivity) => {
                return node.data.estimatedCosts;
              },
            },

            renderFooter: (params) =>
              renderCellValue(
                params?.aggregated?.data?.["general"]?.estimatedCosts,
                "-",
                (value: number) => StringUtils.formatCurrency(value)
              ),
          },
        }
      : {}),
    ...(!constants?.fields?.disableApprovals
      ? {
          "data.extra.sumInvoiceValue": {
            label: i18n.t(
              "apTemplate:List.Activity.sumInvoiceValue",
              "Angebotskosten",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            alignment: "right",
            flexWidth: 130,
            resizable: true,
            render: (node: APActivity, index, params) => (
              <>
                {renderCellValue(
                  node.data.extra?.sumInvoiceValue,
                  "-",
                  (value) => StringUtils.formatCurrency(value)
                )}
              </>
            ),

            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.sumInvoiceValue",
                "Angebotskosten",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "number",
              style: {
                numFmt: getDefaultCurrencyNumberFormat(),
              },
              totalFunction: "sum",
              selector: (node: APActivity) => {
                return node.data.extra?.sumInvoiceValue;
              },
            },

            renderFooter: (params) =>
              renderCellValue(
                params?.aggregated?.data?.["general"]?.sumInvoiceValue,
                "-",
                (value: number) => StringUtils.formatCurrency(value)
              ),
          },
        }
      : {}),
    "data.tags": {
      label: i18n.t("apTemplate:List.Activity.tags", "Tags", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 200,
      resizable: true,
      render: (node: APActivity, index, params) =>
        renderActivityTags(node, structToUse),

      export: {
        width: 30,
        label: i18n.t("apTemplate:List.Activity.tags", "Tags", {
          ns: [constants?.assetType, "apTemplate"],
        }),
        type: "string",
        selector: (node: APActivity) => {
          const tags = structToUse.getTagsOf(node);
          return tags
            .map((e) => LanguageService.translateLabel(e.displayName))
            .join(", ");
        },
      },
    },
    "data.shortDescription": {
      label: i18n.t(
        "apTemplate:List.Activity.shortDescription",
        "Beschreibung",
        {
          ns: [constants?.assetType, "apTemplate"],
        }
      ),
      sortable: true,
      flexWidth: 150,
      resizable: true,
      render: (node: APActivity, index, params) => (
        <>
          {renderCellValue(node.data.shortDescription, "-", (value: string) =>
            value.replaceAll("\n", " ")
          )}
        </>
      ),
      export: {
        width: 30,
        label: i18n.t(
          "apTemplate:List.Activity.shortDescription",
          "Beschreibung",
          {
            ns: [constants?.assetType, "apTemplate"],
          }
        ),
        type: "string",
        selector: (node: APActivity) => {
          return valueOrDefault(node.data.shortDescription, "-");
        },
      },
    },
    "data.assignee": {
      label: i18n.t("apTemplate:List.Activity.assignee", "Zugewiesen an", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 150,
      resizable: true,
      render: (node: APActivity, index, params) => renderActivityAssignee(node),
    },
    "data.supervisor": {
      label: i18n.t("apTemplate:List.Activity.supervisor", "Verantwortliche", {
        ns: [constants?.assetType, "apTemplate"],
      }),
      sortable: true,
      flexWidth: 150,
      resizable: true,
      render: (node: APActivity, index, params) =>
        renderActivitySupervisors(node),
    },
    ...(constants?.fields?.insurance
      ? {
          "data.isInsuranceClaim": {
            label: i18n.t(
              "apTemplate:List.Activity.isInsuranceClaim",
              "Versicherung",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            ),
            sortable: true,
            flexWidth: 100,
            alignment: "center",
            resizable: true,
            render: (node: APActivity, index, params) => (
              <div className="checkbox-wrapper">
                <BFCheckbox readOnly checked={node.data.isInsuranceClaim} />
              </div>
            ),
            export: {
              width: 30,
              label: i18n.t(
                "apTemplate:List.Activity.isInsuranceClaim",
                "Versicherung",
                {
                  ns: [constants?.assetType, "apTemplate"],
                }
              ),
              type: "string",
              selector: (node: APActivity) => {
                return node.data.isInsuranceClaim
                  ? i18n.t("Global.Labels.yes", "Ja")
                  : i18n.t("Global.Labels.no", "Nein");
              },
            },
          },
        }
      : {}),
  } as {
    [columnId: string]: ColumnConfig;
  });
