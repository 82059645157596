import i18n from "@/i18n";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import DataBusDefaults from "@/services/DataBusDefaults";
import classNames from "classnames";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Modal } from "rsuite";
import { ModalSize } from "rsuite/esm/Modal";
import BaseAsset from "../../model/general-assets/BaseAsset";
import { useDatabus } from "../../redux/hooks";
import { DataBusSubKeys } from "../../utils/Constants";
import AssetLoader from "../AssetLoader/AssetLoader";
import "./AssetDetailModal.scss";

interface AssetDetailModalProps {
  className?: string;
  assetType: string;
  size?: ModalSize;
  routeName: string;
  render: (node: BaseAsset) => JSX.Element;
}
const AssetDetailModal = (props: AssetDetailModalProps) => {
  const location = useLocation();
  const history = useHistory();

  useDatabus(
    DataBusSubKeys.CLOSE_MODAL,
    (data) => {
      if (data.identifier === props.routeName) {
        history.push(location.pathname.split("/" + props.routeName)[0]);
      }
    },
    [location]
  );

  return (
    <Route
      path={
        location.pathname.indexOf("/" + props.routeName) === -1
          ? location.pathname
          : location.pathname.substr(
              0,
              location.pathname.indexOf("/" + props.routeName)
            ) + "/:modalId"
      }
      render={(routeProps) => {
        return (
          <>
            <Route
              path={`${routeProps.match.url}/:bookingId`}
              render={(routeProps) => (
                <Modal
                  size={props.size}
                  open={true}
                  backdropClassName="asset-details__backdrop"
                  onClose={() =>
                    history.push(
                      location.pathname.split("/" + props.routeName)[0]
                    )
                  }
                  className={classNames(
                    "asset-details__modal",
                    props.className
                  )}
                >
                  <div className={`asset-details-actions`}>
                    <BFButton
                      appearance="outline-white"
                      size="xs"
                      onClick={() => {
                        DataBusDefaults.closeModal(props.routeName);
                      }}
                    >
                      {i18n.t("Global.Buttons.close")}
                    </BFButton>
                  </div>
                  <AssetLoader
                    assetType={props.assetType}
                    id={routeProps.match.params.bookingId}
                    render={(node: BaseAsset) => (
                      <div>{props.render(node)}</div>
                    )}
                  />
                </Modal>
              )}
            />
          </>
        );
      }}
    />
  );
};

export default AssetDetailModal;
