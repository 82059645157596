import moment from "moment";
import Log from "../debug/Log";
import { store } from "../redux/store";
import DataBusDefaults from "./DataBusDefaults";
import IndexDB, { IndexDBSchema } from "./IndexDB";

const SSHARED_SCHEMA: {
  [collection: string]: IndexDBSchema;
} = {
  "logged-user": {
    schema: {
      version: 0,
      primaryKey: "id",
      type: "object",
      properties: {
        id: {
          type: "string",
          maxLength: 100,
        },
        userId: {
          type: "string",
        },
        lastLogin: {
          type: "string",
          format: "date-time",
        },
      },
      required: ["id", "userId", "lastLogin"],
    },
  },
};
const COMMENTS_SCHEMA: { [collection: string]: IndexDBSchema } = {
  drafts: {
    schema: {
      version: 0,
      primaryKey: "id",
      type: "object",
      properties: {
        id: {
          type: "string",
          maxLength: 100,
        },
        content: {
          type: "string",
        },
        assetType: {
          type: "string",
        },
        assetId: {
          type: "string",
        },
        data: {
          type: "object",
        },
        timestamp: {
          type: "string",
          format: "date-time",
        },
      },
      required: ["id", "content", "timestamp", "assetType", "assetId"],
    },
  },
};
type BaseData = {
  id: string;
  timestamp: string;
};
type CommentsDataBase = {
  content: string;
  assetType: string;
  assetId: string;
  data: {
    emailAddressFields: any;
    sendAsMail: any;
    subactivity: any;
    attachmentCDNIds: any[];
  };
};
type CommentsData = CommentsDataBase & BaseData;

class IndexDBServiceClass {
  commentsDB: IndexDB;
  sharedDB: IndexDB;

  async initShared() {
    // return;

    try {
      if (!this.sharedDB) {
        this.sharedDB = new IndexDB("shared-db", SSHARED_SCHEMA);
        await this.sharedDB.init();

        await this.observeLoginUsers();
      }
    } catch (err) {
      Log.error("failed to initialize shared db", err);
    }
  }
  async initUserDB(userId: string) {
    try {
      await this.destroyAllInstances();
      this.commentsDB = new IndexDB(`comments-${userId}`, COMMENTS_SCHEMA);

      await this.commentsDB.init();
    } catch (err) {
      Log.error("failed to initialize user db", err);
    }
  }

  private async observeLoginUsers() {
    // return;
    const observable = await this.sharedDB.observe("logged-user", {
      id: "loggedInUser",
    });
    observable.subscribe((data) => {
      const currentId = store.getState().global.user?._id;
      Log.info("indexdb shared - observeLoginUsers", data);
      if (data.length === 0) {
        if (currentId) {
          window.location.reload();
        }
      } else {
        const user = data[0];
        const lastLogin = user?._data.date;
        const userId = user?._data.userId;
        if (moment().diff(moment(lastLogin), "hours") > 2) {
          return;
        }
        if (!currentId) {
          if (
            window.location.pathname === "/" ||
            window.location.pathname === ""
          ) {
            DataBusDefaults.route({
              route: "/home",
              stayInApp: false,
            });
          }
        }
        if (currentId && userId && currentId !== userId) {
          window.location.reload();
        }
      }
    });
  }
  async loginUser(userId: string) {
    // return;
    Log.info("indexdb shared - loginUser", userId);
    await this.sharedDB.upsert("logged-user", {
      id: "loggedInUser",
      userId,
      lastLogin: new Date().toISOString(),
    });
  }
  async logoutUser() {
    // return;

    Log.info("indexdb shared - logoutUser");
    await this.sharedDB.remove("logged-user", "loggedInUser");
  }

  async destroyAllInstances() {
    if (this.commentsDB) {
      await this.commentsDB.destroy();
    }
  }

  async getCommentDraft(id: string) {
    return (await this.commentsDB.get("drafts", id)) as CommentsData;
  }
  async removeCommentDraft(id: string) {
    await this.commentsDB.remove("drafts", id);
  }
  async upsertCommentDraft(id: string, data: CommentsDataBase) {
    await this.commentsDB.upsert("drafts", {
      id,

      timestamp: new Date().toISOString(),
      ...data,
    });
  }
}
const IndexDBService = new IndexDBServiceClass();
export default IndexDBService;
