/** @format */

import classNames from "classnames";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Animation } from "rsuite";
import ProgressLoader from "../../components/Loaders/ProgressLoader/ProgressLoader";
import DebugDataComponent from "../../debug/DebugDataComponent";
import i18n from "../../i18n";
import { Notification } from "../../model/notifications/Notification";
import {
  removeNotification,
  setNotificationSeen,
  toggleExpandState,
} from "../../redux/actions/notifications/notifications-actions";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BFDetailsButton from "../abstract-ui/general/Button/BFDetailsButton";
import BFUseTooltip from "../abstract-ui/general/tooltip/BFUseTooltip";
import "./NotificationEntry.scss";

const { Collapse, Slide } = Animation;

interface NotificationEntryProps {
  notification: Notification;
  dense?: boolean;
}
const NotificationEntry = (props: NotificationEntryProps) => {
  const dispatch = useDispatch();
  const { notification, dense } = props;

  const canExpaned = notification.expanded !== undefined;
  let canDelete = notification.deletable !== false;
  const headerActions = canExpaned || canDelete;
  return (
    <Slide
      transitionAppear={true}
      key={notification.id}
      in={!notification.isDeleting}
    >
      <div className={`notification-entry ${dense ? "dense" : ""}`}>
        <div
          className={classNames(`seen-indicator-container`, {
            seen: notification.seen,
          })}
        >
          <BFButton
            tooltip={{
              delay: 200,
              tooltip: notification.seen
                ? i18n.t(
                    "Notification.notificationSeen",
                    "Benachrichtigung wurde bereits gelesen"
                  )
                : i18n.t(
                    "Notification.notificationNotSeen",
                    "Benachrichtigung wurde noch nicht gelesen"
                  ),
            }}
            tabIndex={-1}
            onClick={async () =>
              await dispatch(
                setNotificationSeen(notification.id, !notification.seen)
              )
            }
            className={`seen-indicator`}
          ></BFButton>
        </div>
        <div className={`icon`}></div>
        <div className={`content`}>
          <div className={`head`}>
            <div className={`title`}>{notification.title}</div>
            <BFUseTooltip
              tooltip={moment(notification.created).format(
                i18n.t("Formats.dateTimeFormat")
              )}
            >
              <div className={`date`}>
                {moment(notification.created).fromNow()}
              </div>
            </BFUseTooltip>
            {headerActions ? (
              <div className={`header-actions`}>
                {canDelete ? (
                  <div className={`delete`}>
                    <BFButton
                      className={`action delete-action`}
                      onClick={() =>
                        dispatch(removeNotification(notification.id))
                      }
                      appearance={"clear-on-white"}
                      icon={{ type: "bf", data: "close" }}
                    />
                  </div>
                ) : null}

                {canExpaned ? (
                  <div className={`expand`}>
                    <BFButton
                      className={`action expand-action ${
                        !notification.expanded ? "rotated" : ""
                      }`}
                      onClick={() =>
                        dispatch(
                          toggleExpandState(
                            notification.id,
                            !notification.expanded
                          )
                        )
                      }
                      appearance={"clear-on-white"}
                      icon={{
                        type: "bf",
                        data: "arrow-up-1",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <DebugDataComponent data={notification} />
          {notification.message && canExpaned ? (
            <Collapse in={notification.expanded}>
              {notification.message ? (
                <div className={`message`}>{notification.message}</div>
              ) : null}
            </Collapse>
          ) : (
            <div className={`message`}>{notification.message}</div>
          )}
          {notification.type === "custom" && notification.component
            ? notification.component
            : null}
          <NotificiationProgressBar {...props} />
          {notification.buttons && notification.buttons.length > 0 && (
            <div className={`actions`}>
              {notification.buttons.map((button, index) =>
                button.type === "asset" ? (
                  <BFDetailsButton
                    appearance="link"
                    key={index}
                    data={button.data}
                    onClick={() => {
                      button?.onClick?.();
                      dispatch(setNotificationSeen(notification.id, true));
                    }}
                  >
                    {button.label}
                  </BFDetailsButton>
                ) : (
                  <BFButton
                    appearance="link"
                    key={index}
                    href={(button as any)?.href}
                    onClick={() => {
                      button?.onClick?.();
                      dispatch(setNotificationSeen(notification.id, true));
                    }}
                  >
                    {button.label}
                  </BFButton>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </Slide>
  );
};

export default NotificationEntry;

const NotificiationProgressBar = (props: NotificationEntryProps) => {
  const { notification } = props;
  if (notification.type === "progress" && notification.state === "inprogress") {
    let progressType = null;
    if (notification.progressType === "indeterminated") {
      progressType = "intermediate";
    } else if (notification.progressType === "query") {
      progressType = "query";
    }
    const value = notification.progress ? notification.progress * 100 : null;

    return (
      <div className={`progress`}>
        <ProgressLoader value={value} intermediate={progressType} />
      </div>
    );
  } else {
    return null;
  }
};
