import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CheckPicker,
  InputPicker,
  Loader,
  SelectPicker,
  TagPicker,
  TreePicker,
} from "rsuite";
import uuid from "uuid";
import i18n from "../../../../i18n";
import { DefaultUIConfigs } from "../../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../../redux/store";
import ArrayUtils from "../../../../utils/ArrayUtils";
import { translateNonGenerate } from "../../../../utils/Helpers";
import Tools from "../../../../utils/Tools";
import { Placement } from "../../common/Placements";
import BFButton from "../../general/Button/BFButton";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import LabeledInput from "../LabeledInput";
import BFCheckbox from "../checkbox/BFCheckbox";
import "./BFSelect.scss";

interface CommonProps {
  ignoreDataSort?: boolean;
  className?: string;
  loading?: boolean;
  label?: string;
  labelPosition?: "top" | "left";
  renderValuePath?: boolean;
  viewportHeight: number;

  cleanable?: boolean;
  // container?:HTMLElement|(() => HTMLElement),
  data: any[];
  defaultValue?: any;
  disabled?: boolean;
  expandItemValues?: string[];
  disabledItemValues?: string[];
  groupBy?: string;
  labelKey?: string;
  icon?: BfIconProps;
  // menuClassName:any,
  // menuStyle:any,
  menuMaxHeight?: number;
  onClose?: () => void;
  onClean?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onGroupTitleClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onOpen?: () => void;
  onSearch?: (keyword: string, ev: React.SyntheticEvent<HTMLElement>) => void;
  onSelect?: (
    value: string,
    item: any,
    event: React.SyntheticEvent<HTMLElement>
  ) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  info?: React.ReactNode;
  placement?: Placement;
  renderMenuGroup?: (groupTitle: React.ReactNode, item: any) => React.ReactNode;
  renderMenuItem: (label: React.ReactNode, item: any) => React.ReactNode;
  renderValue: (
    value: any[],
    items: any[],
    selectedElement: React.ReactNode
  ) => React.ReactNode;
  searchable?: boolean;
  sort?: (isGroup: boolean) => (a: any, b: any) => number;
  // toggleComponentClass:any,
  value?: any;
  valueKey?: string;

  renderExtraFooter?: () => React.ReactNode;
  validatorStyle?: ValidatorPopoverStyle;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  onTypeChange?: () => void;
  typeChangeText?: string;
}

interface NormalProps extends CommonProps {
  type: "normal";
  creatable?: boolean;
  renderMenu?: (menu: React.ReactNode) => React.ReactNode;
  onChange?: (
    value: string,
    event: React.SyntheticEvent<HTMLElement>
  ) => void | Promise<void>;
}

interface SearchableProps extends CommonProps {
  type: "search";
  renderMenu?: (menu: React.ReactNode) => React.ReactNode;
  onChange?: (
    value: string,
    event: React.SyntheticEvent<HTMLElement>
  ) => void | Promise<void>;
}

interface TagsProps extends CommonProps {
  type: "tags";
  creatable?: boolean;
  cacheData?: any[];
  onChange?: (
    value: string[],
    event: React.SyntheticEvent<HTMLElement>
  ) => void;
}

interface MultipleProps extends CommonProps {
  type: "multiple";
  renderMenu?: (menu: React.ReactNode) => React.ReactNode;
  onChange?: (
    value: any[],
    event: React.SyntheticEvent<HTMLElement>
  ) => void | Promise<void>;
  renderSelectAllFooter?: boolean;
  renderMultipleValueCount?: boolean;
}

interface TreeProps extends CommonProps {
  type: "tree";
  renderTreeNode: (nodeData: any) => React.ReactNode;
  renderMenu?: (menu: React.ReactNode) => React.ReactNode;
  onChange?: (
    value: string,
    event: React.SyntheticEvent<HTMLElement>
  ) => void | Promise<void>;
}

export type BFSelectProps =
  | NormalProps
  | SearchableProps
  | TagsProps
  | MultipleProps
  | TreeProps;

type States = {
  loading: boolean;
  data: any[];
};

class BFSelect extends Component<BFSelectProps, States> {
  genId: string;
  container;

  static defaultProps = {
    type: "normal",
    placeholder: "-",
    renderMenuItem: (label: React.ReactNode, item: any) => {
      return (
        <div className="item-value">
          <div className="label">{label}</div>
          {item.subLabel && <div className="sub-label">{item.subLabel}</div>}
        </div>
      );
    },
    renderTreeNode: (item: any) =>
      item.listLabel ? item.listLabel : item.label,
  };

  readonly state: States = {
    loading: false,
    data: [],
  };
  updateData() {
    let data = this.props.data;

    if (!this.props.ignoreDataSort) {
      data = ArrayUtils.sortData(this.props.data, {
        dir: "asc",
        key: "label",
      });
    }

    this.setState({ data: data });
  }
  componentDidMount(): void {
    this.updateData();
  }
  componentDidUpdate(
    prevProps: Readonly<BFSelectProps>,
    prevState: Readonly<States>,
    snapshot?: any
  ): void {
    if (prevProps.data !== this.props.data) {
      this.updateData();
    }
  }
  componentWillMount(): void {
    this.genId = uuid();
  }

  render() {
    const {
      label,
      labelPosition,
      placeholder,
      validation,
      validatorStyle,
      className,
    } = this.props;

    return (
      <LabeledInput
        info={this.props.info}
        label={label}
        labelPosition={labelPosition}
        error={!!validation?.message}
      >
        <ValidationPopover
          validatorStyle={validatorStyle}
          level={validation ? validation.level : "error"}
          message={validation ? validation.message : null}
          marginTop={0}
        >
          <div
            className={`bf-select ${className || ""}  ${
              validation?.message ? "error" : ""
            }  ${
              !placeholder || placeholder.trim() === "" ? "no-placeholder" : ""
            }`}
            id={this.genId}
            style={{ position: "relative" }}
            ref={(ref) => {
              this.container = ref;
            }}
          >
            {this.renderSelect()}
          </div>
        </ValidationPopover>
      </LabeledInput>
    );
  }

  renderSelect() {
    const {
      viewportHeight,
      label,
      labelPosition,
      validation,
      renderValuePath,
      onTypeChange,
      typeChangeText,
      renderMultipleValueCount,
      renderSelectAllFooter,
      loading,
      onChange,
      useDefaultRenderValue,
      data,
      ...rest
    } = this.props as any;
    rest.data = this.state.data;
    rest.onChange = async (value, event) => {
      if (onChange) {
        const onChangeResult = onChange(value, event);
        if (Tools.isPromise(onChangeResult)) {
          this.setState({ loading: true });
          onChangeResult.finally(() => {
            this.setState({ loading: false });
          });
        }
      }
    };

    if (loading || this.state.loading) {
      rest.caretAs = () => (
        <div className="loading-indicator">
          <Loader size="xs" />
        </div>
      );
    }

    rest.placement = rest.placement || "bottomStart";
    if (!rest.placeholder || rest.placeholder.trim() === "") {
      rest.placeholder = "-";
    }
    if (
      rest.renderExtraFooter ||
      (rest.type === "multiple" && renderSelectAllFooter) ||
      (typeChangeText && onTypeChange)
    ) {
      const otherRenderExtraFooter = rest.renderExtraFooter;
      let renderSelectAll =
        rest.type === "multiple" && renderSelectAllFooter ? (
          <div className="select-all">
            <BFCheckbox
              inline
              indeterminate={
                (rest.value || []).length > 0 &&
                (rest.value || []).length < rest.data.length
              }
              checked={(rest.value || []).length === rest.data.length}
              onChange={(value, checked, ev) => {
                if (checked) {
                  rest.onChange(
                    rest.data.map((e) => e.value),
                    ev
                  );
                } else {
                  rest.onChange([], ev);
                }
              }}
            >
              {i18n.t("Global.Labels.selectAll", "Alle auswählen")}
            </BFCheckbox>
          </div>
        ) : null;

      let renderTypeChange =
        typeChangeText && onTypeChange ? (
          <div className="type-change">
            <BFButton appearance={"link"} onClick={() => onTypeChange()}>
              {typeChangeText}
            </BFButton>
          </div>
        ) : null;

      rest.renderExtraFooter = () => (
        <div className="popup-footer">
          {renderSelectAll}
          {otherRenderExtraFooter ? otherRenderExtraFooter() : null}
          {renderTypeChange}
        </div>
      );
    }

    if (
      rest.type === "multiple" &&
      renderMultipleValueCount &&
      !rest.renderValue
    ) {
      rest.renderValue = (value, items, selectedItems) => {
        return (
          <div className="value-presentation-container">
            <div className="value-presentation">
              {value.length === rest.data.length
                ? i18n.t("Global.Labels.all", "Alle")
                : value.length === 0
                ? rest.placeholder || "-"
                : value.length === 1
                ? rest.data.find((e) => _.isEqual(e.value, value[0]))?.label
                : i18n.t("Global.Labels.countSelected", "{{count}} selected", {
                    count: value.length,
                  })}
            </div>
          </div>
        );
      };
    }

    const propsToGo: any = {
      menuMaxHeight: 320,
      block: true,
      placeholder: translateNonGenerate(rest.placeholder),
      container: () => {
        return document.getElementById(this.genId); //this.container;
      },
      onClean: (event: React.SyntheticEvent<HTMLElement>) => {
        if (this.props.onClean) {
          this.props.onClean(event);
        }

        if (this.props.onBlur) {
          this.props.onBlur();
        }
      },
      onBlur: (event: React.SyntheticEvent<HTMLElement>) => {
        if (this.props.onBlur) {
          this.props.onBlur();
        }

        if (this.props.value !== null && this.props.type === "normal") {
          let path = null;
          const findOption = (pathObj: any[], options: any[]) => {
            const foundItem = options.find((e) => e.value === this.props.value);
            if (foundItem) {
              path = [...pathObj, foundItem];
            } else {
              options.forEach((e) =>
                e.children ? findOption([...pathObj, e], e.children) : null
              );
            }
          };

          findOption([], this.props.data);

          if (path === null) {
            this.props.onChange(null, event);
          }
        }
      },
      renderValue: (value: string | string[], item, selectedElement) => {
        let displayLabel;
        if (Array.isArray(item)) {
          displayLabel = item?.map((e) => e.label).join(", ");
        } else {
          displayLabel = item ? item.label : "";
          if (renderValuePath && item) {
            const options = rest.data;
            if (options) {
              let path = null;

              const findOption = (pathObj: any[], options: any[]) => {
                const foundItem = options.find((e) => e.value === item.value);
                if (foundItem) {
                  path = [...pathObj, foundItem];
                } else {
                  options.forEach((e) =>
                    e.children ? findOption([...pathObj, e], e.children) : null
                  );
                }
              };

              findOption([], options);

              if (path && path.length > 1) {
                displayLabel = `[${path
                  .filter((e, index) => index < path.length - 1)
                  .map((e) => e?.label)
                  .join(" - ")}] ${path[path.length - 1]?.label}`;
              }
            }
          }
        }
        return (
          <div className={`value-presentation-container`}>
            {this.props.icon ? (
              <div className={`icon`}>
                <BfIcon {...this.props.icon} />
              </div>
            ) : null}
            <div className={`value-presentation`}>
              {displayLabel
                ? typeof displayLabel === "string"
                  ? translateNonGenerate(displayLabel)
                  : displayLabel
                : "-"}
            </div>
          </div>
        );
      },
      ...rest,
    };

    switch (this.props.type) {
      case "tree":
        return React.createElement(TreePicker, propsToGo);
      case "multiple":
        return React.createElement(CheckPicker, propsToGo);
      case "normal":
        return React.createElement(InputPicker, propsToGo);
      case "search":
        return React.createElement(SelectPicker, propsToGo);
      case "tags":
        return React.createElement(TagPicker, propsToGo);
    }
  }
}

const mapStateToProps = (state: AppState, ownProps: BFSelectProps) => ({
  viewportHeight: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_HEIGHT],
});
export default connect(mapStateToProps, {})(BFSelect) as any;
