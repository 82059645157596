import i18n from "@/i18n";
import BaseAsset from "@/model/general-assets/BaseAsset";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import { isDefined } from "@/utils/Helpers";
import classNames from "classnames";
import { useEffect, useState } from "react";
import FocusedEditComponent from "../FocusedEditComponent/FocusedEditComponent";
import CDNPDFViewer from "./CDNPDFViewer";
import FilePDFViewer from "./FilePDFViewer";
import "./MultiplePDFViewer.scss";
import PDFViewer from "./PDFViewer";
import {
  PDFDocumentHighlight,
  PDFHightlightState,
  PDFViewerEditState,
  usePdfHighlightState,
  usePdfViewerEditState,
} from "./PDFViewerHooks";

interface MultiplePDFViewerProps {
  pdfs: (
    | {
        type: "file";
        file: File;
      }
    | {
        type: "url";
        url: string;
        filename: string;
      }
    | {
        type: "cdn";
        asset: BaseAsset;
        cdnID?: string;
        assetField: string;
        assetType: string;
      }
  )[];
  identifier: string;
  height: number | string;
  width?: number | string;
  border?: boolean;
  highlights?: PDFDocumentHighlight[];
  label?: {
    single: string;
    multiple: string;
  };
}
const MultiplePDFViewer = (props: MultiplePDFViewerProps) => {
  const {
    viewActive,
    viewForIdentifier,
    viewHint,
    viewValues,
    stopView,
    setViewValues,
  } = usePdfHighlightState(props.identifier);
  const {
    editActive,
    editHint,
    value: editValue,
    onChange: editOnChange,
    abort: editAbort,
    save: editSave,
  } = usePdfViewerEditState(props.identifier);

  useEffect(() => {
    if (viewValues) {
      const firstViewValue = viewValues?.[0];
      if (firstViewValue) {
        if (isDefined(firstViewValue.index)) {
          setPDFIndex(firstViewValue.index);
        } else if (isDefined(firstViewValue.cdnID)) {
          const index = props.pdfs.findIndex(
            (e) => e.type === "cdn" && e.cdnID === firstViewValue.cdnID
          );
          setPDFIndex(index);
        }
      }
    }
  }, [viewValues]);
  const [pdfIndex, setPDFIndex] = useState(0);

  const currentPdf = props.pdfs[pdfIndex];
  const pdfHighlightState: PDFHightlightState = {
    setViewValues: setViewValues,
    stopView: stopView,
    viewActive: viewActive,
    viewForIdentifier: viewForIdentifier,
    viewHint: viewHint,
    viewValues: (viewValues || []).filter(
      (e) => e.index === pdfIndex
      //  || currentPdf.type === "cdn"
      //     ? (currentPdf as any).cdnID === e.cdnID
      //     : false
    ),
  };

  const pdfViewerEditState: PDFViewerEditState = {
    editActive: editActive,
    editHint: editHint,
    value: (editValue || []).filter((e) => e.index === pdfIndex),
    onChange: (marks: PDFDocumentHighlight[]) => {
      editOnChange([
        ...(editValue || []).filter((e) => e.index !== pdfIndex),
        ...marks.map((e) => ({
          ...e,
          index: pdfIndex,
          cdnID: currentPdf.type === "cdn" ? currentPdf.cdnID : undefined,
        })),
      ]);
      setTimeout(() => {
        setViewValues(
          marks.map((e) => ({
            ...e,
            index: pdfIndex,
            cdnID: currentPdf.type === "cdn" ? currentPdf.cdnID : undefined,
          }))
        );
      });
    },
    abort: editAbort,
    save: editSave,
  };
  return (
    <div
      className={classNames(`multiple-pdf-viewer`)}
      style={{ height: props.height, width: props.width }}
    >
      <FocusedEditComponent editActive={editActive}>
        <div className={`pdf-outer-container`}>
          <div className={`pdf-selection-bar`}>
            <BFChooserSelect
              labelPosition="left"
              label={`${props.pdfs.length} ${
                props.pdfs.length > 1
                  ? props.label?.multiple ||
                    i18n.t("Global.Labels.documents", "Dokumente")
                  : props.label?.single ||
                    i18n.t("Global.Labels.document", "Dokument")
              }`}
              onChange={(value: number) => setPDFIndex(value)}
              value={pdfIndex}
              data={props.pdfs.map((pdf, index) => {
                const pdfHasMark =
                  viewValues?.find((e) => e.index === index) ||
                  (pdf.type === "cdn"
                    ? viewValues?.find((e) => e.cdnID === pdf.cdnID)
                    : false);
                return {
                  value: index,
                  subLabel: pdfHasMark
                    ? i18n.t("Global.Labels.marked", "Markiert")
                    : undefined,
                  label:
                    pdf.type === "url"
                      ? pdf.filename
                      : pdf.type === "file"
                      ? pdf.file.name
                      : pdf.asset.cdn?.find((e) => e.id === pdf.cdnID)
                          ?.filename,
                };
              })}
            />
          </div>
          <div className={`pdf-container`}>
            {currentPdf.type === "url" ? (
              <PDFViewer
                height={"100%"}
                url={currentPdf.url}
                filename={currentPdf.filename}
                pdfHighlightState={pdfHighlightState}
                pdfViewerEditState={pdfViewerEditState}
              />
            ) : currentPdf.type === "file" ? (
              <FilePDFViewer
                height={"100%"}
                file={currentPdf.file}
                pdfHighlightState={pdfHighlightState}
                pdfViewerEditState={pdfViewerEditState}
              />
            ) : (
              <CDNPDFViewer
                asset={currentPdf.asset}
                assetField={currentPdf.assetField}
                assetType={currentPdf.assetType}
                cdnID={currentPdf.cdnID}
                height={"100%"}
                pdfHighlightState={pdfHighlightState}
                pdfViewerEditState={pdfViewerEditState}
                highlights={props.highlights
                  ?.filter((e) => e.cdnID === currentPdf.cdnID)
                  .map((e, index) => ({
                    ...e,
                    scrollIntoView: index === 0,
                  }))}
              />
            )}
          </div>
        </div>
      </FocusedEditComponent>
      {/* {editActive && <div className={`pdf-edit-overlay`}></div>} */}
      {/* <div
        className={classNames(`pdf-container`, { "to-top": editActive })}
      ></div> */}
    </div>
  );
};

export default MultiplePDFViewer;
