import classNames from "classnames";
import React, { useState } from "react";
import Log from "../../../debug/Log";
import { evaluateDepthOfTask, getParentIdsOf } from "../BFGanttChart.utils";
import { Task } from "../lib";
import BFGanttChartRow from "./BFGanttChartRow";
// import './BFGantChartTable.scss';

interface BFGantChartTableProps {
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  /**
   * Sets selected task by id
   */
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
  onTaskUpdate: (task: Task, newIndex?: number) => void;
  onTaskDelete: (taskId: string) => void;
  onTaskCreate: (task: Task, indexToPush: number) => void;
  readonly?: boolean;
  progressChange?: "manual" | "check";
}
const BFGanttChartTable =
  (
    additionalColumn?: {
      renderHeader: () => React.ReactNode;
      renderColumn: (task: Task) => React.ReactNode;
    },
    fixedTasks?: string[]
  ) =>
  (props: BFGantChartTableProps) => {
    return BFGanttChartTableCmp({
      ...props,
      additionalColumn,
      fixedTasks,
    });
  };

const BFGanttChartTableCmp = (
  props: BFGantChartTableProps & {
    additionalColumn?: {
      renderHeader: () => React.ReactNode;
      renderColumn: (task: Task) => React.ReactNode;
    };
    fixedTasks?: string[];
  }
) => {
  const [dragging, setDragging] = useState<Task>(null);
  const [draggingOver, setDraggingOver] = useState<Task>(null);
  return (
    <div className={classNames(`bf-gantt-chart-table`)}>
      {props.tasks.map((t, index) => (
        <BFGanttChartRow
          index={index}
          parentIds={getParentIdsOf(t.id, props.tasks)}
          onTaskCreate={props.onTaskCreate}
          onTaskUpdate={props.onTaskUpdate}
          onTaskDelete={props.onTaskDelete}
          onExpanderClick={props.onExpanderClick}
          task={t}
          fixedRow={props.fixedTasks?.includes(t.id)}
          rowHeight={props.rowHeight}
          rowWidth={props.rowWidth}
          setSelectedTask={props.setSelectedTask}
          depth={evaluateDepthOfTask(t, props.tasks)}
          readonly={props.readonly}
          additionalColumn={props.additionalColumn}
          isDraggingOver={draggingOver?.id === t.id}
          onDragOver={(task) => {
            setDraggingOver(task);
          }}
          onDragStart={(task) => {
            setDragging(task);
          }}
          onDragEnd={() => {
            setDragging(null);
            setDraggingOver(null);
          }}
          onDrop={(taskId, position) => {
            Log.info("onDrop", "move", taskId, "as", position, "to", t.id);

            const dragTask = dragging;

            const newIndex = position === "above" ? index - 0.5 : index + 0.5;
            props.onTaskUpdate(
              {
                ...dragTask,
                project: position === "child" ? t.id : t?.project,
              },
              newIndex
            );
          }}
          currentDraggingTask={dragging}
        />
      ))}
    </div>
  );
};

export default BFGanttChartTable;
