import { Fragment } from "react";

interface WrappedTextProps {
  text: string;
}
const WrappedText = (props: WrappedTextProps) => {
  const texts = (props.text || "").split("\n");
  return (
    <>
      {texts.map((text, index) => (
        <Fragment key={index}>
          <span>{text}</span>
          {index < texts.length - 1 ? <br /> : null}
        </Fragment>
      ))}
    </>
  );
};

export default WrappedText;
