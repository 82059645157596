import { Component, CSSProperties } from "react";
import { Loader } from "rsuite";
import "./AvatarComponent.scss";
import {
  generateAvatarString,
  getDisplayNameInitials,
} from "./AvatarFunctions";
type Props = {
  avatar: string;
  displayName: string;
  renderEmpty: boolean;
  size: "xs" | "sm" | "md" | "lg" | "xl" | "flex" | number;
  className?: string;
  style?: CSSProperties;
  loading?: boolean;
};

type States = {};

class AvatarComponent extends Component<Props, States> {
  static defaultProps = {
    size: "md",
    renderEmpty: false,
  };
  readonly state: States = {};

  generateAvatarString() {
    const { displayName, renderEmpty } = this.props;
    return generateAvatarString(displayName, renderEmpty);
  }

  getDisplayNameInitials() {
    const { displayName } = this.props;
    return getDisplayNameInitials(displayName);
  }

  render() {
    const { className, avatar, size, style, loading } = this.props;

    if (loading) {
      return (
        <div
          className={`avatar size-${size} ${
            className ? className : ""
          } loading`}
          style={{
            ...(style || {}),
            ...(typeof size === "number"
              ? {
                  width: size,
                  height: size,
                  maxWidth: size,
                  maxHeight: size,
                  minWidth: size,
                  minHeight: size,
                  fontSize: size * 0.525,
                }
              : {}),
          }}
        >
          <Loader size="xs" />
        </div>
      );
    }

    let useAvatar = avatar;
    if (!useAvatar) {
      useAvatar = this.generateAvatarString();
    }
    if (!useAvatar) {
      return null;
    }

    const isGenerated = /#[0-9A-Fa-f]{6}/.test(useAvatar);

    return (
      <div
        className={`avatar size-${size} ${className ? className : ""}`}
        style={{
          ...(style || {}),
          ...(typeof size === "number"
            ? {
                width: size,
                height: size,
                maxWidth: size,
                maxHeight: size,
                minWidth: size,
                minHeight: size,
                fontSize: size * 0.525,
              }
            : {}),
        }}
      >
        {isGenerated ? (
          <div className={`generated`} style={{ backgroundColor: useAvatar }}>
            {/* {useAvatar.split("#")[0]} */}
            <svg width="100%" height="100%" viewBox="0 0 200 200">
              {/* couldnt use props: dominantBaseline="central" alignmentBaseline="central", had to use dy instead*/}
              <text
                x="100"
                y="100"
                dy=".4em"
                textAnchor="middle"
                fontSize="110"
                fill="white"
              >
                {this.getDisplayNameInitials()}
              </text>
            </svg>
          </div>
        ) : (
          <div
            className={`image`}
            style={{ backgroundImage: `url(${useAvatar})` }}
          />
        )}
      </div>
    );
  }
}

export default AvatarComponent;
