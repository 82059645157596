import { store } from "../redux/store";
import { DataBusSubKeys } from "../utils/Constants";
import DataBus from "./DataBus";
import { emptyListData } from "./DataService";

export interface ReloadMessage {
  identifiers: string[];
  delay?: boolean;
}
export interface ClearTableMessage {
  identifiers: string[];
}

class GlobalEventServiceClass {
  init() {
    DataBus.subscribe(
      DataBusSubKeys.CLEAR_TABLE,
      ({ identifiers }: ClearTableMessage) => {
        const dispatch = store.dispatch;
        identifiers.forEach((identifier) =>
          dispatch(emptyListData(identifier))
        );
      }
    );
  }
}
const GlobalEventService = new GlobalEventServiceClass();

export default GlobalEventService;
