import i18n from "@/i18n";
import { Contact } from "@/model/db/Contact";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import ContactService from "@/services/ContactService";
import DataBusDefaults from "@/services/DataBusDefaults";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import ContactSearch from "../contact-search/ContactSearch";
import ContactView from "../ContactView";
import "./ContactMerge.scss";

interface ContactMergeProps {
  baseContact: Contact;
  mergeContact: Contact | null;

  setContacts?: (baseContact: Contact, mergeContact: Contact) => void;
  // close?: () => void;
}

const TABLE_IDENTIFIER = "CONTACTS_DUPLICATES_LIST";
const ContactMerge = (props: ContactMergeProps) => {
  if (props.baseContact) {
    return (
      <div className={classNames("contact-merge")}>
        {props.mergeContact && (
          <div className={"selected-contact"}>
            <ContactView
              contact={props.mergeContact}
              title={i18n.t("Contacts.Merge.SourceContact", "Quellkontakt")}
              actions={
                <div className={`contact-view-actions`}>
                  <div
                    title={i18n.t(
                      "Contacts.Merge.SwapSourceTarget",
                      "Quellkontakt <-> Zielkontakt"
                    )}
                  >
                    <BFButton
                      noPadding
                      appearance={"link"}
                      onClick={() => {
                        props.setContacts(
                          props.mergeContact,
                          props.baseContact
                        );
                      }}
                    >
                      <BfIcon
                        type="light"
                        data="data-transfer-horizontal"
                        size="sm"
                      />
                    </BFButton>
                  </div>
                </div>
              }
            />
            <div className="merge-button-container">
              <BFButton
                appearance={"primary"}
                className={classNames("merge-button")}
                onClick={async () => {
                  const result = await ContactService.mergeContacts(
                    props.baseContact,
                    props.mergeContact,
                    true
                  );
                  DataBusDefaults.reload({ identifiers: [TABLE_IDENTIFIER] });
                  if (result.status === 200) {
                    DataBusDefaults.toast({
                      text: i18n.t("Contacts.Merge.MergeSuccessful"),
                      type: "success",
                    });
                    props.setContacts(props.baseContact, null);
                  }
                }}
              >
                <BfIcon type="light" data="login-1" size="xs" />
                &nbsp;
                {i18n.t("Contacts.Merge.Merge")}
              </BFButton>
            </div>
          </div>
        )}

        <div className={"contact-select"}>
          <ContactSearch
            onResultItemClick={(contactToMerge: Contact) => {
              props.setContacts(props.baseContact, contactToMerge);
            }}
            identifier={TABLE_IDENTIFIER}
            excludeIds={[props.baseContact._id]}
            additionalMatchQuery={MQ.and(
              MQ.ne("data.status", "archived"),
              MQ.eq("data.type", props.baseContact.data.type)
            )}
          />
        </div>
      </div>
    );
  }
};

export default ContactMerge;
