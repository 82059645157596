import { useState } from "react";
import { useDispatch } from "react-redux";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BFPortal from "../../modules/abstract-ui/general/Portal/BFPortal";
import { closeGallery } from "../../redux/actions/ui-config/ui-config-actions";
import { useTypedSelector } from "../../redux/hooks";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import CDNImageGallery, { CDNImagePropsWithContent } from "./CDNImageGallery";
import "./GlobalCDNImageGallery.scss";
export type GalleryData = {
  images: CDNImagePropsWithContent[];
  initialActiveIndex: number;
  id: string;
};
const GlobalCDNImageGallery = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const dispatch = useDispatch();
  const galleryData: GalleryData = useTypedSelector(
    (state: AppState) =>
      state.uiConfig.general[DefaultUIConfigs.GLOBAL_CDN_GALLERY]
  );

  if (!galleryData) {
    return null;
  }

  return (
    <BFPortal>
      <div
        className={`global-cdn-image-gallery`}
        onClick={(e) => {
          const target = e.target as HTMLElement;
          if (
            target.nodeName !== "IMG" &&
            !target.classList.contains("swiper-button-next") &&
            !target.classList.contains("swiper-button-prev") &&
            !target.classList.contains("swiper-pagination-bullet")
          ) {
            dispatch(closeGallery());
          }
        }}
      >
        <div className="head">
          <BFButton
            onClick={() => dispatch(closeGallery())}
            icon={{ type: "light", data: "close", size: "md" }}
            appearance="clear"
            //   text={"Schließen"}
          />
        </div>
        <div className="content">
          {/* {thumbsSwiper && ( */}
          <CDNImageGallery
            id={`${galleryData.id}-main`}
            cdnImages={galleryData.images}
            usePagination={true}
            useNavigationButtons={true}
            useMousewheel={false}
            imgType="img"
            withoutContainer
            zoom
            spaceBetween={20}
            //   thumbsSwiper={thumbsSwiper}
            useKeyboard
            initialIndex={galleryData.initialActiveIndex || 0}
          />
          {/* )} */}
        </div>
        {/* <div className="thumb-gallery">
        <CDNImageGallery
          id={`${galleryData.id}-thumbs`}
          cdnImages={galleryData.images}
          usePagination={false}
          useNavigationButtons={true}
          useMousewheel={true}
          imgType="img"
          dimension={"thumb"}
          withoutContainer
          onSwiper={setThumbsSwiper}
          //   swiperWidth={150}
          watchSlidesProgress
          useThumbs
          ignoreContent
          slidesPerView={10}
          loadTreshold={100}
        />
      </div> */}
      </div>
    </BFPortal>
  );
};
export default GlobalCDNImageGallery;
