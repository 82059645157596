import Log from "@/debug/Log";
import FileUtils from "@/utils/FileUtils";
import StringUtils from "@/utils/StringUtils";
import { decompressRTF } from "@kenjiuno/decompressrtf";
import MsgReader from "@kenjiuno/msgreader";
import { Buffer } from "buffer";
import iconv from "iconv-lite";
import PostalMime, { Email } from "postal-mime";
import { useEffect, useState } from "react";
import { RTFJS } from "rtf.js";

export type useEmailFileProps = {
  data: string;
  fileType: "eml" | "msg";
};

export type useEmailCommentProps = {
  data: EmailFile;
  fileType: "comment";
};

export type EmailContact = {
  name?: string;
  address?: string;
};

export type ConvertedAttachment = {
  mimeType: string;
  fileName: string;
  contentId?: string;
  base64Data: string;
};

export type EmailFile = {
  date: Date;
  from?: EmailContact;
  to?: EmailContact[];
  cc?: EmailContact[];
  bcc?: EmailContact[];
  subject?: string;
  text?: string;
  html?: string;
  attachments?: ConvertedAttachment[];
};

export const useEmailFile = (
  props: useEmailFileProps | useEmailCommentProps
) => {
  const [email, setEmail] = useState<EmailFile>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.fileType === "comment") {
      return;
    }
    const fetchData = async () => {
      if (email === undefined) {
        const response = await fetch(props.data);

        if (props.fileType === "eml") {
          const content = await PostalMime.parse(response.body);
          return await emlContentToEmailFile(content);
        }

        if (props.fileType === "msg") {
          const msgReader: MsgReader = new MsgReader(
            await response.arrayBuffer()
          );
          return await msgContentToEmailFile(msgReader);
        }
      }
    };

    setLoading(true);
    setEmail(undefined);

    fetchData().then((email: EmailFile) => {
      let formattedHtml = formatHtmlBody(email);
      setEmail({
        ...email,
        html: formattedHtml,
      });
      setLoading(false);
    });
  }, [(props as useEmailFileProps).data]);

  useEffect(() => {
    if (props.fileType === "comment") {
      setEmail(props.data);
    }
  }, [(props as useEmailCommentProps).data]);

  return { email, loading, fileType: props.fileType };
};

const emlContentToEmailFile = async (emlContent: Email): Promise<EmailFile> => {
  const attachments: ConvertedAttachment[] = await Promise.all(
    emlContent.attachments?.map(async (a) => {
      let blob = new Blob([a.content], {
        type: a.mimeType,
      });
      const base64Data = await FileUtils.toBase64(blob);
      return {
        mimeType: a.mimeType,
        fileName: a.filename,
        contentId: a.contentId,
        base64Data: base64Data,
      } as ConvertedAttachment;
    }) || []
  );

  return {
    date: new Date(emlContent.date),
    from: {
      name: emlContent.from.name,
      address: emlContent.from.address,
    },
    to: emlContent.to as EmailContact[],
    cc: emlContent.cc as EmailContact[],
    bcc: emlContent.bcc as EmailContact[],
    subject: emlContent.subject,
    text: emlContent.text,
    html: emlContent.html,
    attachments:
      attachments?.map((a) => ({
        ...a,
        contentId: StringUtils.getBetween(a.contentId, "<", ">"),
      })) || [],
  };
};

const msgContentToEmailFile = async (
  msgReader: MsgReader
): Promise<EmailFile> => {
  const msgContent = msgReader.getFileData();
  const { html, text } = await getMsgBody(msgReader);

  let attachments = await Promise.all(
    msgContent.attachments?.map(async (a) => {
      const attach = await msgReader.getAttachment(a);
      const blob = new Blob([attach.content], {
        type: a.dataType,
      });
      const base64Data = await FileUtils.toBase64(blob);
      return {
        mimeType: a.dataType,
        fileName: a.fileName,
        extension: a.extension,
        contentId: a.pidContentId,
        base64Data: base64Data,
      } as ConvertedAttachment;
    }) || []
  );

  return {
    date: new Date(msgContent.messageDeliveryTime),
    from: {
      name: msgContent.senderName,
      address: msgContent.senderSmtpAddress,
    },
    to: msgContent.recipients
      .filter((r) => r.recipType === "to")
      .map((r) => ({
        name: r.name,
        address: r.smtpAddress,
      })) as EmailContact[],
    cc: msgContent.recipients
      .filter((r) => r.recipType === "cc")
      .map((r) => ({
        name: r.name,
        address: r.email,
      })) as EmailContact[],
    bcc: msgContent.recipients
      .filter((r) => r.recipType === "bcc")
      .map((r) => ({
        name: r.name,
        address: r.email,
      })) as EmailContact[],
    subject: msgContent.subject,
    text: text,
    html: html,
    attachments: attachments,
  };
};

const getMsgBody = async (
  msgContent: MsgReader
): Promise<{ html: string; text: string; rtf?: string }> => {
  const { compressedRtf, body } = msgContent.getFileData();
  let result = {
    html: "",
    text: body,
    rtf: "",
  };

  if (compressedRtf) {
    const rtfBlob = decompressRTF(Array.from(compressedRtf));
    result.rtf = iconv.decode(Buffer.from(rtfBlob), "latin1");
    if (result.rtf.length !== 0) {
      try {
        RTFJS.loggingEnabled(false);
        const doc = new RTFJS.Document(stringToArrayBuffer(result.rtf), {});
        let htmlElements = formatMsgHtmlElements(
          await doc.render(),
          result.rtf
        );
        result.html = htmlElements.map((e) => e.outerHTML).join("");
      } catch (ex) {
        console.error(ex);
      }
    }
  }
  return result;
};

const formatMsgHtmlElements = (
  htmlElements: HTMLElement[],
  rtf: string
): HTMLElement[] => {
  let result: HTMLElement[] = htmlElements.filter((e) => e.innerHTML !== "");

  const imgTagRegex = /<img[^>]*src="([^"]+)"[^>]*>/g;
  let imgTags = Array.from(rtf.matchAll(imgTagRegex)); // no deduplication because recurring urls can have different styles

  let stats = {
    html: { replaced: [], total: [], notFound: [], imgs: [] },
    rtf: {
      links: Array.from(
        rtf.matchAll(/<a\s+[^>]*?\bhref="([^"]+)"[^>]*?>/g)
      ).map((i) => i[1]),
      imgs: Array.from(rtf.matchAll(imgTagRegex)).map((i) => i[1]),
    },
  };

  const aTagRegex = /<a\s+[^>]*?\bhref="([^"]+)"[^>]*?>.*?<\/a>/g;
  result = result.map((htmlElem, index) => {
    // replace all <a> with <img> from rtf version
    const aTags = Array.from(htmlElem.innerHTML.matchAll(aTagRegex));
    if (aTags.length > 0) {
      aTags.forEach((aTag) => {
        const imgIndex = imgTags.findIndex((img) => img[1] === aTag[1]);
        const imgTag = imgTags[imgIndex]?.[0];
        if (imgTag) {
          stats.html.replaced.push(aTag[0]);
          stats.html.imgs.push(imgTag);
          imgTags.splice(imgIndex, 1);
          htmlElem.innerHTML = htmlElem.innerHTML.replace(aTag[0], imgTag);
        } else {
          stats.html.notFound.push(aTag[1]);
        }
        stats.html.total.push(aTag[1]);
      });
    }
    return htmlElem;
  });

  return result;
};

//? auslagern??
const formatHtmlBody = (email: EmailFile) => {
  let newHtml = email.html;
  newHtml = replaceContentIdsWithAttachments(newHtml, email.attachments);
  newHtml = updateAnchorTargets(newHtml);
  return newHtml;
};

const replaceContentIdsWithAttachments = (
  html: string,
  attachments: ConvertedAttachment[]
) => {
  let newHtml = html;

  const cidRegex = /<img\s+[^>]*src\s*=\s*["'](?:cid:)([^"']*)["'][^>]*>/g;
  Array.from(newHtml.matchAll(cidRegex)).forEach((cidMatch) => {
    const cid = cidMatch[1];
    const attachment = attachments?.find(
      (a) => a.contentId && a.contentId === cid
    );
    if (attachment) {
      newHtml = newHtml.replaceAll(`cid:${cid}`, attachment.base64Data);
    } else {
      Log.warning("attachment for content id not found", cid);
    }
  });

  return newHtml;
};

const updateAnchorTargets = (html: string) => {
  let newHtml = html;
  const anchors = newHtml.match(/<a[^>]*>/g);
  if (anchors) {
    anchors.forEach((anchor) => {
      const targetIndex = anchor.indexOf("target=");
      let replacement;
      if (targetIndex === -1) {
        replacement =
          anchor.substring(0, anchor.length - 1) + ' target="_blank"' + ">";
      } else {
        replacement = anchor.replace(
          /target\s*=\s*["']([^"']*)["']/,
          'target="_blank"'
        );
      }
      newHtml = newHtml.replace(anchor, replacement);
    });
  }
  return newHtml;
};

function stringToArrayBuffer(string) {
  const buffer = new ArrayBuffer(string.length);
  const bufferView = new Uint8Array(buffer);
  for (let i = 0; i < string.length; i++) {
    bufferView[i] = string.charCodeAt(i);
  }
  return buffer;
}
